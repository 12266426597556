import ROOM from "@src/engine/room";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { vec2 } from "gl-matrix";

//import RoomEditor from "@src/controllers/editor";

import FilterKey from "./GraphComponent/FilterKey";
import LGraphComponent from "./GraphComponent/LGraphComponent";
import LGraphEntity from "./GraphComponent/LGraphEntity";
import LGraphTransform from "./GraphComponent/LGraphTransform";
import LGraphGUIButton from "./GraphComponent/LGraphGUIButton";
import LGraphGUISlider from "./GraphComponent/LGraphGUISlider";
import LGraphGUIStatic from "./GraphComponent/LGraphGUIStatic";
import LGraphGUIToggle from "./GraphComponent/LGraphGUIToggle";
import LGraphGUITextField from "./GraphComponent/LGraphGUITextField";
import LGraphLocatorProperty from "./GraphComponent/LGraphLocatorProperty";
import LGraphMaterial from "./GraphComponent/LGraphMaterial";
import LGraphParticipant from "./GraphComponent/LGraphParticipant";
import LGraphSpace from "./GraphComponent/LGraphSpace";
import LGraphSpaceArea from "./GraphComponent/LGraphSpaceArea";
import LGraphSpaceMaterialTransition from "./GraphComponent/LGraphSpaceMaterialTransition";
import LGraphSpaceNode from "./GraphComponent/LGraphSpaceNode";
import LGraphSpaceNodeMove from "./GraphComponent/LGraphSpaceNodeMove";
import LGraphSignal from "./GraphComponent/LGraphSignal";
import Notify from "./GraphComponent/Notify";
//import TextureResource from "./GraphComponent/TextureResource";

function GraphComponent()
{
	this.enabled = true;
	this.mode = GraphComponent.ALWAYS_MODE;
	if (typeof(LiteGraph) !== "undefined")
	{
		this._graph = new LiteGraph.LGraph();
		this._graph.component = this;
	}
	else
		console.error("LiteGraph not installed");

	LEvent.bind(this, "graph_trigger", this.onEventTrigger, this );
}

GraphComponent.componentName = "GraphComponent";

GraphComponent.item_drop_types = {};

GraphComponent.icon = [ 14,7 ];
GraphComponent.type = ROOM_TYPES.CAMERA;
GraphComponent.last_index = 0;
//GraphComponent.widgets = {};

GraphComponent.ALWAYS_MODE = 0;
GraphComponent.TRIGGER_MODE = 1;
GraphComponent.RENDER_MODE = 2;
GraphComponent.modes_str = [ "ALWAYS","TRIGGER","RENDER" ];

GraphComponent.prototype.onAdded = function(parent)
{
	if (!this._graph)
		return;

	this._graph._entity = parent;
	this._graph.space = parent.space;
}

GraphComponent.prototype.onRemoved = function(parent)
{
	this._graph.clear();
	if (this._dialog_window)
		this._dialog_window.close();
}

GraphComponent.prototype.onEventTrigger = function(event, params)
{
	console.debug(params);
	var node = this._graph.getNodeById( params[0] );
	if (!node)
		return;
	node.triggerSlot( params[1] );
}

GraphComponent.prototype.serialize = function(o)
{
	o.enabled = this.enabled;
	if (this._graph)
		o.graph = this._graph.serialize();
}

GraphComponent.prototype.configure = function(o)
{
	this.enabled = o.enabled;
	if (this._graph)
		this._graph.configure( o.graph );
}

GraphComponent.prototype.preRender = function(view)
{
	if (!this.enabled)
		return;
}

GraphComponent.prototype.onRenderGraph = function(ctx, GUI, tex )
{
	if ( !this.enabled )
		return;

	this._graph.sendEventToAllNodes("onRenderGUI", [ ctx,GUI,tex ] );
}

GraphComponent.prototype.onRenderInspector = function(ctx, x,y,w,h, editor )
{
	var starty = y;
	Label.call(GUI,x,y,w,20, "Mode");
	this.mode = GUI.ComboLine( x + 100,y,w - 100,20, this.mode, GraphComponent.modes_str );
	y+= 24;

	Label.call(GUI,x,y,w,20, "Nodes");
	GUI.TextField(x + 80,y,100,24, this._graph._nodes.length, null, false);

	if ( Button.call(GUI,x+210,y,100,24,"Edit") )
		editor.showGraph(true, this);

	y+= 24;
	editor.showGraphNodeInspector(x+5,y+20,w-10,h-100);

	/*
	if ( Button.call(GUI,x+210,y,100,24,"Edit") )
	{
		if ( GraphEditorDialog.selected == this )
		{
			if( GraphEditorDialog.active )
				editor.detachDialog( GraphEditorDialog );
			else
				editor.attachDialog( GraphEditorDialog );
		}
		else
		{
			GraphEditorDialog.selected = this;
			editor.attachDialog( GraphEditorDialog );
		}
	}

	y+= 30;

	if ( Button.call(GUI,x+100,y,w-120,30,"In Window") )
	{
		this.openGraphInWindow();
	}
	y+= 50;

	if (!GraphEditorDialog.graphcanvas)
		return y;

	var selected_nodes = Object.values( GraphEditorDialog.graphcanvas.selected_nodes );
	if (selected_nodes.length !== 1)
		return;

	ctx.fillColor = [ 0.3,0.2,0.2,1 ];
	ctx.beginPath();
	ctx.roundRect(x+5,y,w-10,h-(y-starty)-20,[ 4 ]);
	ctx.fill();

	var node = selected_nodes[0];
	this.onRenderNodeInspector(x+10,y+5,w-20,h-(y-starty)-30,node);
	*/

	return y;
}

GraphComponent.prototype.renderGizmo = function( view, editor, selected )
{

}

GraphComponent.prototype.update = function(dt,time)
{
	if (!this.enabled || !this._graph )
		return;

	if ( this._root.constructor !== ROOM.Space && this._root.enabled === false )
		return;

	this._graph.runStep(1);
}

GraphComponent.prototype.getPropertyFromPath = function( path, index )
{
	if (path[index] === "graph" )
	{
		var id = path[index+1];
		var graphnode = this._graph.getNodeById( id );
		if (!graphnode)
			return;

		if ( path.length > (index+2) )
			return graphnode[ path[index+2] ];
		return graphnode;
	}
	else
		return BaseComponent.prototype.getPropertyFromPath.call( this, path, index );
}

GraphComponent.prototype.resolveLocatorFromPath = function( path, index )
{
	if (path[index] === "graph" && path.length > index + 2)
	{
		var id = path[index+1];
		var graphnode = this._graph.getNodeById( id );
		if (!graphnode)
			return null;

		var propname = path[index+2];
		var type = typeof( graph[ propname ] );

		return {
			target: graph,
			name: "graphnode",
			property: propname,
			entity: this,
			type: type
		};
	}
	else
		return BaseComponent.prototype.resolveLocatorFromPath.call( this, path, index );
}

GraphComponent.registerItemDropType = function( type, callback )
{
	this.item_drop_types[ type ] = callback;
}

//NODES *******************************

if (typeof(LiteGraph) !== "undefined")
{

	LiteGraph.registerNodeType("gui/button", LGraphGUIButton );
	LiteGraph.registerNodeType("gui/slider", LGraphGUISlider );
	LiteGraph.registerNodeType("gui/static", LGraphGUIStatic );
	LiteGraph.registerNodeType("gui/toggle", LGraphGUIToggle );
	LiteGraph.registerNodeType("gui/textfield", LGraphGUITextField );
	LiteGraph.registerNodeType("input/filterkey", FilterKey);
	LiteGraph.registerNodeType("space/area", LGraphSpaceArea );
	LiteGraph.registerNodeType("space/component", LGraphComponent );
	LiteGraph.registerNodeType("space/entity", LGraphEntity );
	LiteGraph.registerNodeType("space/transform", LGraphTransform );
	LiteGraph.registerNodeType("space/material", LGraphMaterial);
	LiteGraph.registerNodeType("space/material_transition", LGraphSpaceMaterialTransition );
	LiteGraph.registerNodeType("space/node_move", LGraphSpaceNodeMove );
	LiteGraph.registerNodeType("space/node", LGraphSpaceNode );
	LiteGraph.registerNodeType("space/notify", Notify);
	LiteGraph.registerNodeType("space/participant", LGraphParticipant );
	LiteGraph.registerNodeType("space/property", LGraphLocatorProperty );
	LiteGraph.registerNodeType("space/space", LGraphSpace );
	
	//LiteGraph.registerNodeType("space/texture", TextureResource );
	LiteGraph.registerNodeType("space/signal", LGraphSignal );

	GraphComponent.registerItemDropType( "Entity", function(e) {
		var graphnode = LiteGraph.createNode("space/entity");
		var item_uid = e.dataTransfer.getData("uid");
		graphnode.setProperty("uid",item_uid);
		return graphnode;
	});

	GraphComponent.registerItemDropType( "component", function(e) {
		var graphnode = LiteGraph.createNode("space/component");
		var entity_uid = e.dataTransfer.getData("entity_uid");
		var component_uid = e.dataTransfer.getData("component_uid");
		graphnode.setProperty("entity_uid",entity_uid);
		graphnode.setProperty("component_uid",component_uid);
		return graphnode;
	});

	GraphComponent.registerItemDropType( "property", function(e) {
		var graphnode = LiteGraph.createNode("space/property");
		var locator = e.dataTransfer.getData("uid");
		graphnode.setProperty("locator",locator);
		return graphnode;
	});

	GraphComponent.registerItemDropType( "material", function(e) {
		var graphnode = LiteGraph.createNode("space/material");
		var item_uid = e.dataTransfer.getData("locator");
		if ( !item_uid )
			return;
		if (item_uid[0] === ROOM.locator_chars.material )
			item_uid = item_uid.substr(1);
		graphnode.setProperty("uid",item_uid);
		return graphnode;
	});

	GraphComponent.registerItemDropType( "area", function(e) {
		var graphnode = LiteGraph.createNode("space/area");
		var locator = e.dataTransfer.getData("uid");
		graphnode.setProperty("locator",locator);
		graphnode.setProperty("area_id",e.dataTransfer.getData("area_id") );
		return graphnode;
	});

	GraphComponent.registerItemDropType( "Transform", function(e) {
		var graphnode = LiteGraph.createNode("space/transform");
		var item_uid = e.dataTransfer.getData("uid");
		graphnode.setProperty("entity_id",item_uid);
		var node_name = e.dataTransfer.getData("node_name");
		graphnode.setProperty("node_id",node_name || "");
		return graphnode;
	});	

	GraphComponent.registerItemDropType( "Node", function(e) {
		var graphnode = LiteGraph.createNode("space/node");
		var entity_id = e.dataTransfer.getData("entity_id");
		graphnode.setProperty("entity_id",entity_id);
		var node_id = e.dataTransfer.getData("node_id");
		graphnode.setProperty("node_id",node_id);
		return graphnode;
	});		
}//litegraph available

export default GraphComponent;
