import LoadingManager from "@src/engine/helpers/loadingManager";
import ROOM from "@src/engine/room";

import ImageElement from "./ui/imageElement";
import { canvasRoundedRect } from "./ui/roundedRect";

function Home( surface )
{
	this.xyz = XYZLauncher.instance;
	this.surface = surface;
	this.apps_db = Home.AppsDB;
	this.initialized = false;
	this.images_loaded = false;
	this.elements = [];
	this.loadImages();
	this.mouse_action = null;
	this.click_timeout = null;
	this.hovered_icon = null;
	this.is_subapp = this.surface.app_name !== Home.app_name;
}

Home.output_mode = "canvas";
Home.app_name = "Home";

Home.prototype.loadImages = function()
{
	const manager = new LoadingManager( null, () => {
		this.images_loaded = true;
	});

	for (var i = 0; i < this.apps_db.apps.length; i++)
	{
		ROOM.getImage( this.apps_db.apps[i].icon, manager );
	}
}

Home.prototype.init = function( tex )
{
	var size = 120;
	var hitbox_size = size + 40;

	for (var i = 0; i < this.apps_db.apps.length; i++)
	{
		this.elements[this.apps_db.apps[i].name] = new ImageElement( ROOM.images[this.apps_db.apps[i].icon], {
			width: size,
			height: size,
			hitbox: { width: hitbox_size, height: hitbox_size }
		});
	}

	this.initialized = true;
}

Home.prototype.render = function (ctx, tex, GUI, mouse, viewport)
{
	if ( !this.initialized )
		this.init(tex);

	const w = tex.width;
	const h = tex.height;

	ctx.fillStyle = "#f2f2f2";
	ctx.fillRect(viewport[0], viewport[1], viewport[2], viewport[3]);

	ctx.fillStyle = "black";
	ctx.font = "32px " + this.xyz.options.fontFamily;
	ctx.textAlign = "left";
	ctx.fillText("Applications", 100, 150);

	var size = 150;
	var sx = 100;

	if (xyz.mobile)
	{
		this.postRender();
		return;
	}

	for (var i = 0; i < this.apps_db.apps.length; i ++)
	{
		var app_name = this.apps_db.apps[i].name;

		ctx.fillStyle = "white";
		ctx.save();
		ctx.shadowBlur = 20;
		ctx.beginPath();
		if (i !== this.hovered_icon )
		{
			ctx.shadowColor = "rgba(0,0,0,.09)";
			canvasRoundedRect(ctx, sx, 200, size, size, 40 );
		}
		else
		{
			ctx.shadowColor = "rgba(0,0,0,.06)";
			var extra_size = 5;
			canvasRoundedRect( ctx, sx - extra_size / 2, 200 - extra_size / 2, size + extra_size, size + extra_size, 40 );
		}
		ctx.fill();
		ctx.restore();
		ctx.font = "300 18px " + this.xyz.options.fontFamily;
		ctx.fillStyle = "black";
		ctx.textAlign = "center";
		var text_x = sx + size / 2;
		ctx.fillText( app_name, text_x, 388  );
		ctx.globalAlpha = 1.0;

		if (i !== this.hovered_icon )
		{
			this.elements[app_name].width = this.elements[app_name].height = 120;
			this.elements[app_name].render( ctx, sx + 15, 215 );
		}
		else
		{
			var extra_size = 5;
			this.elements[app_name].width = this.elements[app_name].height = 120 + extra_size;
			this.elements[app_name].render( ctx, sx + 15 - extra_size/2, 215 - extra_size/2);
		}

		sx += 200
	}

	this.postRender();
}

Home.prototype.postRender = function()
{
	if ( this.mouse_action === "click" )
	{
		this.mouse_action = null;
	}

	//ROOM.cursor_style = this.cursor_style;
}


Home.prototype.update = function(dt, t, mouse)
{
	var GUI = this.surface.GUI;

	//this.cursor_style = "";
	this.hovered_icon = null;

	if ( !this.images_loaded || !this.initialized)
		return;

	if (!xyz.mobile)
		for (var i = 0; i < this.apps_db.apps.length; i ++)
		{
			var app_name = this.apps_db.apps[i].name;

			if ( this.elements[ app_name ].isHovered( GUI ) )
			{
				//this.cursor_style = "pointer";
				this.hovered_icon = i;
				if ( this.mouse_action === "click" )
				{
					if ( this.is_subapp )
					{
						var message = {
							action: "surface_update",
							surface_entity: this.surface.root.uid,
							subaction: "app_action",
							app_action: {
								action: "load_subapp",
								data: {
									app_name: app_name,
									initiator: this.xyz.space.local_participant.id
								}
							}
						};
						this.xyz.call_controller.broadcastMessage( message );
						this.surface._app.processAction( {
							action: "load_subapp",
							data: {
								app_name: app_name,
								initiator: this.xyz.space.local_participant.id
							}
						} );
					}
					else
					{
						var message = {
							action: "surface_update",
							surface_entity: this.surface.root.uid,
							subaction: "load_app",
							app_name: app_name,
							initiator: this.xyz.space.local_participant.id
						};
						if (app_name === "MediaPlayer" )
						{
							message.media_url = "videos/disney.mp4";
						}
						this.xyz.call_controller.triggerAction( message );
					}
				}
			}
		}
}

Home.prototype.onMouse = function(ev, active)
{
	if ( ev.eventType === "mouseup" )
	{
		if ( this.click_timeout )
		{
			this.mouse_action = "click";
		}
	}
	else if ( ev.eventType === "mousedown" )
	{
		if ( !this.click_timeout )
		{
			this.click_timeout = setTimeout(() => {
				this.click_timeout = null;
			}, 200);
		}
	}
}

Home.AppsDB = {
	"apps": [
		{
			"name": "Whiteboard",
			"icon": "/textures/apps/home/whiteboard.png"
		},
		/* {
            "name": "Poll",
            "icon": "/textures/apps/home/poll.png"
        }, */
		{
			"name": "Tennis",
			"icon": "/textures/apps/home/pong.png"
		}/*,
        {
            "name": "Fluid",
            "icon": "/textures/apps/home/fluid.png"
        },
        {
            "name": "Terminal",
            "icon": "/textures/apps/home/terminal.png"
        },
        {
            "name": "Controls",
            "icon": "/textures/apps/home/controls.png"
        },
        {
            "name": "Settings",
            "icon": "/textures/apps/home/settings.png"
        },
        {
            "name": "Map",
            "icon": "/textures/apps/home/map.png"
        } */
	]
};

export default Home;
