const shuffleArray = function(array) {
	var new_array = [];
	while (array.length)
	{
		var index = Math.floor(Math.random() * array.length);
		var item = array[index];
		array.splice(index,1);
		new_array.push(item);
	}
	return new_array;
}

export default shuffleArray;
