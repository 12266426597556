import { GL } from "@src/libs/litegl";
import Canvas from "@src/libs/LiteGL/Canvas";

import DrawFragmentShader from "./Tennis/Draw.fs.glsl";
import PostDrawFragmentShader from "./Tennis/PostDraw.fs.glsl";
import Label from "@src/libs/GLUI/Elements/Label";

function TennisApp( surface, state )
{
	this.xyz = XYZLauncher.instance;
	this.surface = surface;

	this.ready = false;

	this.mouse_down = false;
	this.mouse_pos = [ 0, 0 ];
	this.width = null;
	this.height = null;
	this.dt = 0;
	this.t = 0;
	this.frame = 0;

	this.thickness = 0.001;
	this.paddleHalfSize = [ 0.015, 0.08 ];
	this.ballRadius = 0.015;
	this.halfFieldHeight = 0.49;
	this.halfWallWidth = 0.01;
	this.limits = this.halfFieldHeight - this.halfWallWidth;
	this.start = getTime();
	this.deltaTime = this.start;
	this.screenShake = 0.0;
	this.velocity = 0.0;
	this.scoreHighLightP1 = 0.0;
	this.scoreHighLightP2 = 0.0;

	this.scorep1 = 0;
	this.scorep2 = 0;
	this.p1PaddlePos = [ -0.9, 0.0 ];
	this.p2PaddlePos = [ 0.9, 0.0 ];
	this.ballData = [ 0.0, 0.0, 1.0, Math.random() - 0.5 ]; // vec4(x:posX, y:posY, z:dirX, w:dirY);
	this.ballSpeed = 0.0007;

	this.player_1 = null;
	this.player_1_name = null;
	this.player_2 = null;
	this.player_2_name = null;
	this.is_player_1 = false;
	this.is_player_2 = false;
	this.wait_for_player_2 = false;

	if (typeof TennisApp.compute_surface === "undefined") {

		TennisApp.compute_surface = surface;
	}

	if (TennisApp.compute_surface.app_name !== "Tennis") {

		TennisApp.compute_surface = surface;
	}

	this.draw_frag = DrawFragmentShader;

	this.post_draw_frag = PostDrawFragmentShader;

	if (typeof gl.shaders["tennis_draw"] === "undefined")
	{
		gl.shaders["tennis_draw"] = new GL.Shader(GL.Shader.SCREEN_VERTEX_SHADER, this.draw_frag);
	}

	if (typeof gl.shaders["tennis_post_draw"] === "undefined")
	{
		gl.shaders["tennis_post_draw"] = new GL.Shader(GL.Shader.SCREEN_VERTEX_SHADER, this.post_draw_frag);
	}

	gl.shaders["tennis_draw"].uniforms({
		scorep1: this.scorep1,
		scorep2: this.scorep2,
		thickness: this.thickness,
		paddleHalfSize: this.paddleHalfSize,
		ballRadius: this.ballRadius,
		halfFieldHeight: this.halfFieldHeight,
		halfWallWidth: this.halfWallWidth,
		limits: this.limits,
		p1PaddlePos: this.p1PaddlePos,
		p2PaddlePos: this.p2PaddlePos,
		ballData: this.ballData,
		ballSpeed: this.ballSpeed
	})

	window.tennis = this; // debug
	this.is_subapp = this.surface.app_name !== TennisApp.app_name;

	LEvent.bind(this.xyz.space, "keydown", this.processKeyDown.bind(this));

	if (state) {

		this.JSONToState(state);
	}
}

TennisApp.prototype.round = function(n, digits) {
	return Number(n.toFixed(digits));
};

TennisApp.prototype.ease = function(target, n, factor) {
	return this.round((target - n) * factor, 4);
};

TennisApp.prototype.rand = function(low, high) {
	return Math.random() * (high - low) + low;
};

TennisApp.prototype.initialize = function(_ctx) {
	console.debug(`${TennisApp.app_name} initialize function called`);
};

TennisApp.prototype.createCanvasFromString = function(text) {
	const canvas = Canvas.create();
	const ctx = canvas.getContext("2d");
	const font_size = 56;
	const font = `${font_size}px monospace`;
	const text_metrics = ctx.measureText(text);
	var width = text_metrics.width;
	var height = font_size;
	canvas.width = width;
	canvas.height = height;
	canvas.style.width = `${width}px`;
	canvas.style.height = `${height}px`;
	ctx.textAlign = "center";
	ctx.textBaseline = "middle";
	ctx.fillStyle = "transparent";
	ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
	ctx.fillStyle = "white";
	ctx.fillText(text, width / 2, height / 2);
	return canvas;
};

TennisApp.prototype.onEnter = function() {

	var local_participant_id = this.xyz.space.local_participant.id;
	var initiator = null;
	if ( this.is_subapp ) {
		initiator = this.surface._app.apps[ TennisApp.app_name ].initiator || null;}

	if (!this.player_1 && initiator === local_participant_id
			|| !this.player_1 && !initiator ) {

		this.player_1 = local_participant_id;
		var data = {
			player: "player_1",
			participant_id: local_participant_id
		};
		this.sendAction({ action: "set_player", data: data });
	}
	else if ( !this.player_2 && !this.is_player_1 )
	{
		this.wait_for_player_2 = true;
	}
};

TennisApp.prototype.onLeave = function() {

	if ( this.is_player_1 ) {

		this.removePlayer( "player_1" );
		this.sendAction({ action: "remove_player", data: { player: "player_1" } });
	}
	if ( this.is_player_2 )
	{
		this.removePlayer( "player_2" );
		this.sendAction({ action: "remove_player", data: { player: "player_2" } });
	}
	this.wait_for_player_2 = true;
};

TennisApp.prototype.removePlayer = function( player ) {

	if (player === "player_1") {

		var initiator = false;
		if ( this.surface && this.surface.os && this.surface.os.apps && this.surface.os.apps[ TennisApp.app_name] ) {
			initiator = this.surface.os.apps[TennisApp.app_name].initiator;
		}

		if (initiator) {
			this.surface.os.apps[TennisApp.app_name].initiator = null;
		}

		if ( this.player_2 ) {

			this.player_1 = this.player_2;
			this.player_1_name = this.player_2_name;
			this.is_player_1 = this.is_player_2;
			this.player_2 = null;
			this.player_2_name = null;
			this.is_player_2 = false;

		} else
		{
			this.player_1 = null;
			this.player_1_name = null;
			this.is_player_1 = false;
		}
	}
	if (player === "player_2" )
	{
		this.player_2 = null;
		this.player_2_name = null;
		this.is_player_2 = false;
	}
	this.scorep1 = 0.0;
	this.scorep2 = 0.0;
};

TennisApp.prototype.JSONToState = function(state, is_json = true) {

	if (!state) {
		return;}

	var data;
	if (is_json) {

		data = JSON.parse(state);
	}
	else
	{
		data = state;
	}

	this.player_1 = data.player_1;
	this.player_1_name = data.player_1_name;
	this.scorep1 = data.player_1_score;
	this.player_2 = data.player_2;
	this.player_2_name = data.player_2_name;
	this.scorep2 = data.player_2_score;
};

TennisApp.prototype.stateToJSON = function() {

	var state =
    {
    	player_1: this.player_1,
    	player_1_name: this.player_1_name,
    	player_1_score: this.scorep1,
    	player_2: this.player_2,
    	player_2_name: this.player_2_name,
    	player_2_score: this.scorep2
    };
	state.ready = this.ready;
	return JSON.stringify(state);
};

TennisApp.prototype.sendAction = function( app_action ) {

	// used by the os in case this app is a subapp
	app_action.subapp = this.app_name;

	this.xyz.call_controller.broadcastMessage({
		action: "surface_update",
		surface_entity: this.surface.root.uid,
		subaction: "app_action",
		app_action: app_action
	});
};

TennisApp.prototype.processAction = function( app_action ) {

	if ( !app_action ) {
		return;
	}

	const action = app_action.action;
	const data = app_action.data;

	if (action === "set_player") {

		if (data.player === "player_1") {

			this.player_1 = data.participant_id;
		}
		else if (data.player === "player_2") {

			this.player_2 = data.participant_id;
			this.wait_for_player_2 = false;

		}
	} else if (action === "remove_player" )
	{
		this.removePlayer( data.player );
		this.wait_for_player_2 = true;
	}
	else if (action === "update_player_position") {

		if (data.player === "player_1") {

			this.updatePlayerPosition( "player_1", data.mouse );

		} else if (data.player === "player_2" )
		{
			if ( data.p2PaddlePos ) // sent by player 1 playing against the cpu
			{
				this.p2PaddlePos = data.p2PaddlePos;
			}
			if ( data.mouse ) // sent by a human player 2
			{
				this.updatePlayerPosition( "player_2", data.mouse );
			}
		}
	}
	else if (action === "update_ball") {

		this.ballData = data.ball_data;
	}
	else if (action === "update_score" )
	{
		if ( data.scorep1 )
		{
			this.scorep1 = data.scorep1;
		}
		if ( data.scorep2 ) {

			this.scorep2 = data.scorep2;
		}
	}
	else if (action === "send_ready") {

		if ( this.is_player_1 )
		{
			this.ready = true;
		}
	}
};

TennisApp.prototype.processKeyDown = function(e, ev) {

	if (ev.key === "Enter") {
	}
};

TennisApp.prototype.clamp = function(n, min, max) {

	return Math.min(Math.max(n, min), max);
};

TennisApp.prototype.onMouse = function(ev, active) {

	if ( !active ) {
		return;}

	this.mouse_pos = [
		(ev.surfaceX / this.width) * 2.0 - 1.0,
		this.clamp(
			-1.0 * ((ev.surfaceY / this.height) * 2.0 - 1.0),
			-this.halfFieldHeight + this.paddleHalfSize[1],
			this.halfFieldHeight - this.paddleHalfSize[1]
		)
	];

	if (ev.eventType === "mouseup" && ev.button === 0) {

		this.mouse_down = false;
	}
	else if (ev.eventType === "mousedown" && ev.button === 0 )
	{
		this.mouse_down = true;
		if ( active && !this.ready )
		{
			if ( this.is_player_1 ) {

				this.ready = true;

			} else if ( this.wait_for_player_2 )
			{
				this.wait_for_player_2 = false;
				this.player_2 = this.xyz.space.local_participant.id;
				var data = {
					player: "player_2",
					participant_id: this.player_2
				};
				this.sendAction({ action: "set_player", data: data });
			}
			else if ( this.is_player_2 )
			{
				this.sendAction({ action: "send_ready", data: {} });

			}
		}
	} else if (ev.eventType === "mousemove") {

		if ( this.is_player_1 )
		{
			this.updatePlayerPosition( "player_1", this.mouse_pos );

			this.sendAction({
				action: "update_player_position",
				data: { player: "player_1", mouse: this.mouse_pos }
			});
		}
		else if ( this.is_player_2 )
		{
			this.updatePlayerPosition( "player_2", this.mouse_pos );

			this.sendAction({
				action: "update_player_position",
				data: { player: "player_2", mouse: this.mouse_pos }
			});
		}
	}
};

TennisApp.prototype.updatePlayerPosition = function(player, mouse_pos) {

	if (player === "player_1")
	{
		this.p1PaddlePos[1] = mouse_pos[1];

		gl.shaders["tennis_draw"].uniforms({
			mouse: mouse_pos,
			p1PaddlePos: this.p1PaddlePos,
			ballData: this.ballData

		});
	} else if (player === "player_2")
	{
		this.p2PaddlePos[1] = mouse_pos[1];

		gl.shaders["tennis_draw"].uniforms({
			mouse: mouse_pos,
			p2PaddlePos: this.p1PaddlePos,
			ballData: this.ballData
		});
	}
};

TennisApp.prototype.ease = function(target, n, factor) {
	return this.round((target - n) * factor, 5);
};

TennisApp.prototype.magnitude = function(x, y) {
	return Math.sqrt(x * x + y * y);
};

TennisApp.prototype.normalize = function(x, y) {
	const mag = this.magnitude(x, y);
	var rx, ry;
	if (Math.abs(mag) < 1e-9) {
		rx = 0;
		ry = 0;
	} else {
		rx = x / mag;
		ry = y / mag;
	}
	return [ rx, ry ];
};

TennisApp.prototype.update = function(dt, t, _mouse) {
	var local_id = this.xyz.space.local_participant.id;
	this.is_player_1 = local_id === this.player_1;
	this.is_player_2 = local_id === this.player_2;

	this.cursor_style = "";
	this.dt = dt;
	this.t = t;

	if ( this.player_1 && !this.player_1_name ) {

		this.player_1_name = this.xyz.space.getParticipant( this.player_1 ).getUsername();
	}

	if (this.player_2 && !this.player_2_name) {
		this.player_2_name = this.xyz.space.getParticipant(this.player_2).getUsername();
	}
};

TennisApp.prototype.getVelocity = function() {

	return Math.max(Math.abs(this.ballData[2]), Math.abs(this.ballData[3]));
};

TennisApp.prototype.render = function(ctx, tex, _GUI, _mouse) {
	const w = tex.width;
	const h = tex.height;
	this.width = tex.width;
	this.height = tex.height;
	this.frame++;

	const t = getTime();
	this.deltaTime = this.start - t;
	this.start = t;

	if (tex) {

		this.width = tex.width;
		this.height = tex.height;
	}

	var textures = [ ":tennis_texture_a", ":tennis_texture_b" ];
	const time = t * 0.25;

	this.scoreHighLightP1 += this.ease(0.0, this.scoreHighLightP1, 0.01);
	this.scoreHighLightP2 += this.ease(0.0, this.scoreHighLightP2, 0.01);
	if (this.ready === true) {

		this.screenShake += this.ease(0.0, this.screenShake, 0.21);
		if ( !this.player_2 )
		{
			this.p2PaddlePos[1] += this.ease(
				this.ballData[1],
				this.p2PaddlePos[1],
				0.1 * this.clamp(this.ballData[0] + 0.25, 0.0, 1.0)
			);
			this.p2PaddlePos[1] = this.clamp(
				this.p2PaddlePos[1],
				-this.halfFieldHeight + this.paddleHalfSize[1],
				this.halfFieldHeight - this.paddleHalfSize[1]
			);
		}
		this.ballData[0] += this.deltaTime * this.ballSpeed * -this.ballData[2];
		this.ballData[1] += this.deltaTime * this.ballSpeed * this.ballData[3];

		if (this.ballData[1] + this.ballRadius >= this.limits) {

			this.velocity = this.getVelocity();
			this.ballData[1] = this.limits - this.ballRadius;
			this.ballData[3] *= -1.0;
		}
		else if (this.ballData[1] - this.ballRadius <= -this.limits)
		{
			this.velocity = this.getVelocity();
			this.ballData[1] = this.ballRadius - this.limits;
			this.ballData[3] *= -1.0;
		}

		if (this.ballData[0] + this.ballRadius >= (this.p2PaddlePos[0] - this.paddleHalfSize[0])) {

			if (Math.abs(this.ballData[1] - this.p2PaddlePos[1]) <= this.paddleHalfSize[1] + this.ballRadius) {

				this.velocity = this.getVelocity();
				this.ballData[0] = this.p2PaddlePos[0] - this.paddleHalfSize[0] - this.ballRadius;
				this.ballData[2] *= -1.05;
				this.ballData[3] += (this.ballData[1] - this.p2PaddlePos[1]) * -7.0;
			}
		}
		else if (this.ballData[0] - this.ballRadius <= this.p1PaddlePos[0] + this.paddleHalfSize[0]) {

			if (Math.abs(this.ballData[1] - this.p1PaddlePos[1]) <= this.paddleHalfSize[1] + this.ballRadius)
			{
				this.velocity = this.getVelocity();
				this.ballData[0] = this.p1PaddlePos[0] + this.paddleHalfSize[0] + this.ballRadius;
				this.ballData[2] *= -1.05;
				this.ballData[3] += (this.ballData[1] - this.p1PaddlePos[1]) * -7.0;
			}
		}
		if (this.ballData[0] - this.ballRadius * 2.0 > this.p2PaddlePos[0])
		{
			this.scorep1++;
			this.scoreHighLightP1 = 1.0;
			this.ready = false;
			this.ballData = [ 0.0, 0.0, 1.0, Math.random() - 0.5 ];
			if ( this.is_player_1 ) {

				this.sendAction({ action: "update_score", data: { scorep1: this.scorep1 } });
			}
		}
		else if (this.ballData[0] + this.ballRadius * 2.0 < this.p1PaddlePos[0]) {

			this.scorep2++;
			this.scoreHighLightP2 = 1.0;
			this.ready = false;
			this.ballData = [ 0.0, 0.0, -1.0, Math.random() - 0.5 ];
			if ( this.is_player_1 )
			{
				this.sendAction({ action: "update_score", data: { scorep2: this.scorep2 } });
			}
		}
	}

	if (this.frame % 2 !== 0) {

		var tmp = textures[1];
		textures[1] = textures[0];
		textures[0] = tmp;
	}

	if (this.surface._index === TennisApp.compute_surface._index) {

		if (typeof gl.textures[":tennis_texture_a"] === "undefined")
		{
			gl.textures[":tennis_texture_a"] = new GL.Texture(w, h, { type: gl.FLOAT });
		}
		if (typeof gl.textures[":tennis_texture_b"] === "undefined")
		{
			gl.textures[":tennis_texture_b"] = new GL.Texture(w, h, { type: gl.FLOAT });
		}
		if ( this.is_player_1 )
		{
			this.sendAction({ action: "update_ball", data: { ball_data: this.ballData } });

			if ( !this.player_2 ) {

				this.sendAction({
					action: "update_player_position",
					data: { player: "player_2", p2PaddlePos: this.p2PaddlePos }
				});
			}
		}
		gl.shaders["tennis_draw"].uniforms({
			resolution: [ w, h ],
			time: time,
			ballData: this.ballData,
			p2PaddlePos: this.p2PaddlePos,
			scorep1: this.scorep1,
			scorep2: this.scorep2,
			screenShake: this.screenShake,
			velocity: this.velocity,
			scoreHighLightP1: this.scoreHighLightP1,
			scoreHighLightP2: this.scoreHighLightP2
		});
		gl.shaders["tennis_post_draw"].uniforms({
			resolution: [ w, h ],
			time: getTime(),
			glitchAmount: 0.0
		});
		gl.textures[textures[1]].copyTo( gl.textures[textures[1]], gl.shaders[ "tennis_draw" ] );
	}
	gl.textures[textures[0]].blit(tex, gl.shaders["tennis_post_draw"]);
	gl.start2D();
	ctx.textAlign = "center";
	ctx.font = "50px Monospace";
	ctx.fillColor = [ 0.5, 0.6, 0.5, ctx.globalAlpha ];
	if (this.player_1_name)
	{
		ctx.fillText(`${this.player_1_name}   ${this.scorep1}`, (this.width / 4) - 40, 165);

	} else {

		ctx.fillText(`PLAYER_1   ${this.scorep1}`, (this.width / 4) - 40, 165);
	}

	if (this.player_2_name) {

		ctx.fillText(`${this.scorep2}   ${this.player_2_name}`, this.width - this.width / 4, 165);
	}
	else
	{
		ctx.fillText(`${this.scorep2}   PLAYER_2`, this.width - this.width / 4, 165);
	}
	if ( !this.player_2 && !this.is_player_1 ) {
		ctx.fillText("CLICK TO JOIN", this.width - this.width / 4, 220);
	}
};

TennisApp.output_mode = null;
TennisApp.app_name = "Tennis";

export default TennisApp;
