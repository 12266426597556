import ControlsApp      from "./controls";
import Home             from "./home";
import LockScreenApp    from "./lockscreen";
import MediaPlayerApp   from "./mediaPlayer";
import OSApp            from "./os";
import ScreenSharingApp from "./screensharing";
import SurfaceAppsContainer from "./SurfaceAppsContainer";
import TennisApp        from "./tennis";
import TerminalApp      from "./terminal";
import WhiteboardApp    from "./whiteboard/whiteboard";

/**
 * Register all apps
 */
SurfaceAppsContainer.registerApp(OSApp);
SurfaceAppsContainer.registerApp(ControlsApp);
SurfaceAppsContainer.registerApp(MediaPlayerApp);
SurfaceAppsContainer.registerApp(Home);
SurfaceAppsContainer.registerApp(LockScreenApp);
SurfaceAppsContainer.registerApp(ScreenSharingApp);
SurfaceAppsContainer.registerApp(TerminalApp);
SurfaceAppsContainer.registerApp(TennisApp);
SurfaceAppsContainer.registerApp(WhiteboardApp);
