export class RoomController {
    constructor() {
        this.isRoomController = true;
    }
    onEnter(previous) {
    }
    onLeave(replacement) {
    }
    exit() {
    }
    onFixedUpdate(delta_in_seconds) {
    }
    onUpdate(delta_in_seconds) {
    }
    onBeforeUnload() {
    }
    onDrag(event) {
    }
    onDropItem(event) {
    }
    onKeyDown(event) {
    }
    onMouse(event) {
    }
    onNodeClicked(node, item, event) {
        return false;
    }
    onPaste(paste, event) {
    }
    onRender() {
    }
    onRoomReady(room) {
    }
    onBridgeAvailable(bridge) {
    }
    preRender(camera) {
    }
}
