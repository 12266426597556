//this component is used by Call to handle transitions of participatns between seats
import { vec3 } from "gl-matrix";

export function TransitionToSeat(controller )
{
	TransitionToSeat.instance = this;
	this.controller = controller;
	this.enabled = false;
	this.factor = 0;
	this.speed = 0.1; //path speed?
	this.lerp_factor = 0.5;
	this.reverse = false;

	this.path = null;
	this.seat = null;
}

TransitionToSeat.instance = null;

TransitionToSeat.prototype.start = function( path, seat, reverse )
{
	var participant = xyz.space.local_participant;
	if ( !participant )
		return;

	if ( participant.seat )
	{
		//exit seat
		//participant.exitSeat(); //to force in case backend is dead
		this.controller.setComponentController( null );
		this.controller.requestChangeSeat( null );
	}

	this.factor = reverse ? 1 : 0;
	this.enabled = true;
	this.path = path;
	this.seat = seat;
	this.reverse = reverse || false;
	console.debug("start path");
}

TransitionToSeat.prototype.update = function(dt)
{
	if (!this.enabled || !this.path || !this.seat )
		return;

	var participant = xyz.space.local_participant;

	//move
	var pos = this.path.computePoint( this.factor );
	var target_pos = null;
	target_pos = this.path.computePoint( this.factor + (this.reverse ? -1 : 1) );
	if ( this.seat )
		vec3.lerp( target_pos, target_pos, this.seat.entity.position, this.lerp_factor );

	participant.position = pos;
	if ( vec3.distance( pos, target_pos ) > 0.1 )
		participant.lookAt( target_pos );
	this.factor += dt * this.speed * (this.reverse ? -1 : 1);

	var has_finished = false;

	//close to seat
	if ( this.seat )
	{
		var dist = vec3.distance( pos, this.seat.entity.position );
		if ( dist < 2 )
			has_finished = true;
	}

	//ending of path
	if ( (this.reverse && this.factor < 0) ||
		(!this.reverse && this.factor > 1) )
		has_finished = true;

	//
	if ( has_finished )
	{
		if (this.seat)
			this.controller.requestChangeSeat( this.seat );
		this.enabled = false;
		console.debug("end of path");
	}
}

window.TransitionToSeat = TransitionToSeat;
