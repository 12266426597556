export function getObjectClassName(obj) {
	if (!obj)
		return;

	// custom to enable mangling
	if (obj.constructor.componentName) {
		return obj.constructor.componentName;
	}

	if (obj.constructor.fullname) //this is to overwrite the common name "Prefab" for a global name "ONE.Prefab"
		return obj.constructor.fullname;

	if (obj.constructor.name)
		return obj.constructor.name;

	var arr = obj.constructor.toString().match(
		/function\s*(\w+)/);

	if (arr && arr.length === 2) {
		return arr[1];
	}
}