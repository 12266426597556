import { mat3, mat4, vec2, vec3 } from "gl-matrix";

import AppSettingsDialog from "@src/controllers/panels/appSettings";
import SurfaceAppsContainer from "@src/engine/apps/SurfaceAppsContainer";
import { SurfaceOutputModeType } from "@src/engine/apps/SurfaceOutputModeType";
import BaseComponent from "@src/engine/components/baseComponent";
import { ViewCore } from "@src/engine/render/view";
import ROOM from "@src/engine/room";
import { getFullPath } from "@src/engine/Room/file-utils";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { GL } from "@src/libs/litegl";
import { RD } from "@src/libs/rendeer";
import { Direction3Constants } from "@src/libs/rendeer/Direction3Constants";
import { Material } from "@src/libs/rendeer/Material";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import clamp from "@src/math/clamp";
import remap from "@src/math/remap";

import PopupStyle from "./Surface/Popup.css";
import PopupTemplate from "./Surface/Popup.html";

class Surface extends BaseComponent {

	static componentName = "Surface";

	static icon = [ 1, 7 ];
	static type = ROOM_TYPES.SURFACE;

	static last_id = 0;	//autoincremental index
	static block_apps = false;	//blocks all apps
	static no_apps_on_tablets = false;	//apps only in TVs
	static texture_settings = { no_flip: false };	//global
	static verbose = true;
	static gizmo_vertices = new Float32Array([ -.5,-.5,0, .5,-.5,0, .5,.5,0, -.5,.5,0 ]);

	static inv_model = mat4.create();

	enabled = true;
	_index = Surface.last_id++;
	showScreenshareBubble = false;

	power = true; 		//on or off the tv, if off it looks black, it is turned on once clicked
	app_name = "";		//default app to run
	apps_settings = {};//to store info about the apps running in this surface
	seat = "";			//to which seat belongs, only person seated in this seat can see this tablet/interact
	background_image = ""; //custom bg image, maybe move somehwere else?
	resolution_factor = 1;	//in case it must be rendered in lower quality

	brightness = 0.9;	//to control the emissive brightness (useful in dark rooms)
	alpha_mode = "OPAQUE";	//change to make the material semitransparent (for cool translucent glass surfaces)

	proxy_node = ""; 	//to which node to apply this surface texture, if nothing then a plane is created
	proxy_material_name = "";	//to which material apply

	//flags
	interactive = true;//if it can be clicked
	no_focus = false;	//blocks users from focusing in it
	fix_aspect = true; //fixes the aspect ratio if the surface has stretched UVs
	block_gl_texture = false; //if it can show gl textures generated from the scene
	skip_screenshare = false; //avoid showing in this surface screen share
	allow_progressive_resolution = false;	//allows to reduce texture size if far away (feature in progress)

	/**
	 * @deprecated
	 * @type {boolean}
	 */
	loop = true;
	/**
	 * @deprecated
	 * @type {boolean}
	 */
	no_proxy = false;
	/**
	 * @deprecated
	 * @type {{}}
	 */
	viewport = {};

	//internal use

	/**
	 * current app being executed
	 * @type {SurfaceApp|null}
	 */
	_app = null;
	mouse = [ 0, 0 ];	//last mouse position inside app
	local_point = vec3.create();	//mouse in local coordinates
	is_mouse_inside = false; //if the mouse is inside the surface area
	was_active = false; //it was being used in the prev frame
	active = false; //being used by local_participant (means if should process user actions)

	//here the texture that contains the image
	_texture = null;
	_texture_name = ":surface_" + this._index;
	/**
	 *
	 * @type {SceneNode|null}
	 * @private
	 */
	_target_node = null; //assigned from VIEW.preRender

	//every surface has its own material stuff (which collides with MediaPlayer which also has its own material stuff...)
	_material = new Material({
		model: "nopbr", //"pbrMetallicRoughness",
		alphaMode: "MASK",
		color: [ 0, 0, 0 ],
		emissive: [ 1, 1, 1 ],
		metallicFactor: 0,
		roughnessFactor: 0,
		textures: {
			emissive: null
		}
	});

	_frame = Math.floor(Math.random() * 60);

	call_controller = null;

	/**
	 * In charge of displaying info over a surface in the scene
	 * could be video stream, interactive app, etc
	 */
	constructor(call_controller) {
		super();

		this.call_controller = call_controller;

		this._material.name = ":surface_" + this._index;
		StaticMaterialsTable[this._material.name] = this._material;

		//some global flags
		Surface.block_apps |= (xyz.params.no_apps === "true");
		Surface.no_apps_on_tablets = xyz.params.no_apps_on_tablets === "true";

		// for disabling apps on mobile devices
		if (xyz.mobile)
			Surface.block_apps = true;
	}

	onAdded(parent) {
		parent.surface = this;
		//autoassign type
		if (parent.type === ROOM_TYPES.NULL)
			parent.type = ROOM_TYPES.SURFACE;
	}

	onRemoved(parent) {
		parent.surface = null;
	}

	serialize(o) {
		o.enabled = this.enabled;
		o.app_name = this.app_name || null;
		o.proxy_node = this.proxy_node;
		o.proxy_material_name = this.proxy_material_name;
		o.brightness = this.brightness;
		o.seat = this.seat;
		o.block_gl_texture = this.block_gl_texture;
		o.background_image = this.background_image;
		o.interactive = this.interactive;
		o.skip_screenshare = this.skip_screenshare;
		o.allow_progressive_resolution = this.allow_progressive_resolution;
		o.alpha_mode = this.alpha_mode;
		o.apps_settings = this.apps_settings;
		o.resolution_factor = this.resolution_factor;
		o.no_focus = this.no_focus;
	}

	configure(o) {
		this.enabled = o.enabled !== undefined ? o.enabled : true;
		this.app_name = o.app_name;
		this.brightness = o.brightness || 1;
		this.seat = (!o.seat || o.seat === "none") ? "" : o.seat;
		this.block_gl_texture = Boolean(o.block_gl_texture);
		this.background_image = o.background_image;
		this.interactive = typeof o.interactive === "boolean" ? o.interactive : true;
		this.skip_screenshare = o.skip_screenshare || false;
		this.allow_progressive_resolution = o.allow_progressive_resolution || false;
		this.alpha_mode = o.alpha_mode || "OPAQUE";
		this.apps_settings = o.apps_settings || {};
		this.startup_app = o.apps_settings ? o.apps_settings.startup_app : "";
		this.resolution_factor = o.resolution_factor || 1;
		this.no_focus = Boolean(o.no_focus);
		this.proxy_node = o.proxy_node;
		this.proxy_material_name = o.proxy_material_name || "";
	}

	getInteractiveNodes(container) {
		if (!this.enabled || !this.entity.enabled) //|| this.no_focus )
			return;

		const local_participant = this.space.local_participant;
		//only seated can interact
		if (!local_participant || !this.interactive) //|| !this.space.local_participant.seat )
			return;

		if (!local_participant.seat && !local_participant.last_seat)
			return;

		let is_valid_surface = true;
		if (this.seat && (!local_participant.seat || this.seat !== local_participant.seat.entity.name))
			is_valid_surface = false;

		if (is_valid_surface && this._target_node) {
			if (this._target_node.native)
				container.push(this.entity.node);
			else if (this._target_node) {
				this._target_node._skip_outline = this.no_outline;
				container.push(this._target_node);
			}
		}
	}

	/**
	 * returns the node that should have the surface applied to
	 * this function should be refactored a little
	 */
	getTargetNode() {
		if (window.TmrwEngine && this._target_node_native)
			return this.entity.node;

		if (this._target_node != null && this._target_node.name === this.proxy_node)
			return this._target_node;

		let node = null;
		if (!this.proxy_node) // used for tablets without a node
		{
			ViewCore.instance.loadTexture("textures/room.jpg");
			if (!this._target_node) {
				if (Surface.verbose)
					console.debug("[SURFACE] Created surface plane", this.entity.name);
				node = new SceneNode();
				this.entity.node.addChild(node);
				node.name = "surface_plane";
				node.mesh = "plane";
				node.mustSync(true);
				node.position = [ 0, 0, 0.002 ];
				node.material = ":planeSurface" + this._index;
				this._plane_material = new Material({
					model: "pbrMetallicRoughness",
					name: "planeSurface",
					opacity: 1,
					metallicFactor: 1,
					roughnessFactor: 0.1,
					albedo: [ 0, 0, 0 ],
					textures: {
						emissive: "textures/room.jpg"
					},
					emissive: [ 2, 2, 2 ]
				});
				StaticMaterialsTable[node.material] = this._plane_material;
				this._target_node = node;
			} else
				node = this._target_node;
		} else
			node = this.space.scene.root.findNodeByName(this.proxy_node);

		//if (node == null && window.ModuleEngine)
		if (node === null && window.TmrwModule) {
			node = nativeEngine._room.findNodeByName(this.proxy_node);
			this._target_node = node;
		} else {
			if (!node) {
				this._target_node = null;
				this._target_node_native = 0;
			} else {
				this._target_node = node;
			}
		}
		return this._target_node;
	}

	/**
	 * called from {@link ViewCore.preRenderEntities}
	 */
	preRender(view) {
		if (!this.enabled || !this.entity.enabled)
			return;

		var space = this.space;
		var participant = this.space.local_participant;
		var current_frame = this.space.scene.frame;
		var user_in_focus = Boolean(space.local_participant && space.local_participant.focus_item);
		var user_in_focus_in_this = Boolean(space.local_participant && space.local_participant.focus_item === this.entity.node );

		//find to which node should this surface be displayed into
		const node = this.getTargetNode();
		const native = (node && node.native) || false;
		if (!node) //??
			return;

		//check if that node was visible last frame (to avoid updating texture to not visible nodes)
		let was_rendered_last_frame = false;
		if (native)
			was_rendered_last_frame = node.isVisible()
		else
			was_rendered_last_frame = node._last_rendered_frame !== undefined && node._last_rendered_frame > (space.frame - 10);
		//was_rendered_last_frame = true;

		//find to which material we should apply (usually to this._material but there are exceptions)
		let material = this._material;
		if (this.proxy_material_name && StaticMaterialsTable[this.proxy_material_name])
			material = StaticMaterialsTable[this.proxy_material_name];
		if (!material)
			return;

		//check if user is focused in this surface
		this.was_active = this.active;
		this.active = participant && //there is a participant
			this.enabled && //surface is enabled
			(node || this._target_node_native) && //is mapped to a scene object
			this.entity.node === participant.focus_item; //and the user is interacting with this surface

		//if it should be visible??
		var visible = (this._target_node && was_rendered_last_frame) ||
						ROOM.skip_visibility ||
						this.root._force_visible;

		//visible = true; //HACK TO FIX UNTIL FURTHER NOTICE

		//check if there is a shared screen to display
		let texture = null;
		if (space.surface_stream) {
			XYZLauncher.instance.globalFeedTexture = texture = space.surface_stream.texture;
		}
		else {
			XYZLauncher.instance.globalFeedTexture = null;
		}
		if (texture) {
			//Draw the text to identify who is screen sharing and a background inside the whiteboard in case of a surface stream
			texture.drawTo(this.renderScreenShareElements.bind(this));
		}
		else //render the app
		{
			//fills the texture with whatever, after: this._texture should contain whatever to show
			if (!Surface.block_apps &&
				visible &&
				this.power &
				(!user_in_focus || (user_in_focus && user_in_focus_in_this))) //stop updating screens when you are working on one
				this.renderContent(view);
			texture = this._texture; //done here because the texture is created in renderContent
		}
		if (!texture && this.background_image) // not tested
			texture = view.loadTexture(this.background_image);

		this.updateMaterial(node, material, texture, view);

		//special case
		if (this._html_popup) {
			const info = ROOM.enable3DContainer();
			RD.alignDivToNode(info.container, info.cameraElement, this._html_popup, this.entity.node, view._last_camera, this.interactive);
		}
	}

	/**
	 * applies texture to material and material to node
	 */
	updateMaterial(node, material, texture, view) {
		const space = this.space;
		const overrideTex = true;

		//update material properties, mostly tweak the emissive brightness
		const user_in_focus = (space.local_participant && space.local_participant.focus_item);
		const brightness = user_in_focus ? 1 : this.brightness;
		let factor = brightness * (space && space.fx.emissive_factor != null ? space.fx.emissive_factor : 1);
		if (user_in_focus)
			factor = Math.min(1.0, factor); //avoid burning it
		if (view._render_quality === ROOM.QUALITY.LOW)
			factor = 1.0;
		if (!this.power)
			factor = 0.0;
		material.emissive[0] = material.emissive[1] = material.emissive[2] = factor;
		material.alphaMode = this.alpha_mode;
		material.overlay = this.active; //this is to render the node above all

		//assign texture as emissive
		if (texture && !texture.name)
			console.error("surface texture without a name");
		if (texture) {
			if (!material.textures.emissive || material.textures.emissive.constructor !== Object)
				material.textures.emissive = {};
			material.textures.emissive.texture = texture.name;
		} else if (material.textures.emissive && material.textures.emissive.texture && material.textures.emissive.texture[0] === ":")
			material.textures.emissive = null; //remove previously assigned

		//assign material to node
		if (texture && !texture.name)
			console.error("surface material without a name");
		//material holder because there are two possibilities in nodes to store material, as node.material or as node.primitives[].material
		const material_holder = (node.primitives && node.primitives.length) ? node.primitives[0] : node;
		material_holder.material = material.name;

		//correct aspect ratio in material and center on the screen
		if (this.fix_aspect)
			this.fixAspectAndCenter(material);
		else {
			//reset to full size
			if (material.textures.emissive)
				material.textures.emissive.uv_channel = 0;
		}

		if (!overrideTex) //transfer info to native
			return;

		// Native node material setup
		const texture_mat = material.textures.emissive;
		if (node && node.native) {
			if (texture_mat) {
				const name = texture_mat.texture || texture_mat;
				var texture = gl.textures[name];
				nativeEngine.setNodeEmissiveTexture(node, texture);

				node.setEmissiveFactor(factor, factor, factor);
				node.setBlend(this.alpha_mode, 1.0, true);
				node.setOverlay(this.active);

				if (material.uv_transform && texture_mat.uv_channel === 2) {
					node.setEmissiveTransform(material.uv_transform, true);
				} else {
					// Andrey: We have to flip all JS textures passed to native
					const identMat = mat3.create();
					node.setEmissiveTransform(identMat, true);
				}
				//node.
			} else //remove
			{
				node.setEmissiveFactor(0, 0, 0);

			}
		}
	}

	/**
	 * called from this.preRender called from view.preRenderEntities
	 * it prepares texture, materials and if app, executes app
	 */
	renderContent(view) {
		const force_app_render = false;

		//CASE 2: HAS AN APP (and user is seated on the same chair)
		let app_name = this.app_name;

		//in case this surface is disabled for apps
		if (Surface.block_apps ||
			(this.seat && this.space.settings.allow_apps_on_tablets === false) || //not your tablet
			(this.seat && Surface.no_apps_on_tablets))
			app_name = null;

		//no app, nothing then
		if (!app_name)
			return;

		let app = null;
		if (app_name) //this will create the app if it doesnt exists
			app = this.getApp(app_name);
		if (!app)
			return;

		if (this.active || !this.seat || (this.space.local_participant.seat && this.seat === this.space.local_participant.seat.entity.name)) {
			if (force_app_render || (this._frame % 10) === 0 || this.active || (this._app && this._app.render_always)) {
				const texture = this.updateTargetTexture();
				this.renderApp(texture);
			}
			this._frame++;
		} else {
			this.app_name = "";
			this._app = null; // forces the texture back to background image
		}
	}

	/**
	 * in charge of creating a texture to store the surface result if required
	 * @returns {Texture}
	 */
	updateTargetTexture() {
		//otherwisde create texture if we dont have one or the size doesnt match
		let resolution_factor = 2;
		if (this._app && this._app.resolution_factor)
			resolution_factor = this._app.resolution_factor;

		resolution_factor *= this.resolution_factor;
		let width = 1024 * resolution_factor;
		let height = width;

		const progressive_app = this._app && this._app.progressive;

		//dynamic resize based on distance, not in use
		if (this.allow_progressive_resolution && !this.isInFocus() && (!this._app || progressive_app)) {
			const view = ViewCore.instance;
			const cam = view._last_camera;
			const pos = this.entity.node.getGlobalPosition();
			const dist = vec3.distance(cam.position, pos);
			let size = Math.round(clamp(1024 / (dist * 0.5), 256, 1024));
			size = Math.round(size / 64) * 64; //quantize to avoid doing it constantly
			width = height = size;
		}

		//extract aspect from node and apply to height
		const aspect = this.entity.node.scaling[0] / this.entity.node.scaling[1];
		if (aspect < 1)
			width = Math.floor(width * aspect);
		else
			height = Math.floor(height / aspect);

		//create texture
		if (!this._texture || this._texture.width !== width || this._texture.height !== height) {
			if (Surface.verbose)
				console.debug("[SURFACE] Creating new texture for surface", this.entity.name, [ width, height ]);
			if (this._texture)
				this._texture.delete();
			this._texture = new GL.Texture(width, height, {
				format: GL.RGB,
				wrap: GL.CLAMP_TO_EDGE,
				magFilter: GL.LINEAR,
				minFilter: GL.LINEAR_MIPMAP_LINEAR,
				anisotropic: 8
			});
			this._texture.name = this._texture_name;
			gl.textures[this._texture_name] = this._texture; //overwrite old
		}

		return this._texture;
	}

	/**
	 * returns the app, it also check if previous must be destroyed
	 */
	getApp(app_name) {
		if (this._app && this._app.app_name !== app_name)
			this._app = null; //remove

		if (app_name && !this._app)
			this._app = this.instantiateApp(app_name);

		return this._app;
	}

	/**
	 * helps create an app in a safe way
	 */
	instantiateApp(app_name) {
		let app = null;
		const state = null;

		const app_ctor = SurfaceAppsContainer.getAppByName(app_name);
		if (app_ctor && !app_ctor.has_errors) {
			try {
				if (Surface.verbose)
					console.debug("[SURFACE] App instantiated", this.entity.name, app_name);
				app = new app_ctor(this, state);
				app.app_name = app_name;
				this.GUI = null;
				if (state && app.JSONToState)
					app.JSONToState(state);
			} catch (err) {
				app_ctor.has_errors = true;
				console.error("error creating surface app: ", app_name, err);
			}
		}

		//this.prevent_app_update = false;
		if (this.active && app.onEnter)
			app.onEnter();

		if (this.app_instanciation_callback)
			this.app_instanciation_callback(app);

		return app;
	}

	/**
	 *
	 * @param {Texture} tex
	 */
	renderApp(tex) {
		if (!tex) {
			return;
		}
		const app = this._app;

		app.width = tex.width;
		app.height = tex.height;

		if (!this.was_active && this.active) {
			// activating
			xyz.call_controller.broadcastMessage({
				action: "surface_update",
				surface_entity: this.root.uid,
				subaction: "in_use_enter"
			});

			if (app.onEnter)
				app.onEnter();
		}
		if (this.was_active && !this.active) {
			// deactivating
			xyz.call_controller.broadcastMessage({
				action: "surface_update",
				surface_entity: this.root.uid,
				subaction: "in_use_leave"
			});
			if (app.onLeave)
				app.onLeave();
		}

		//in canvas mode it will create HTML canvas and upload image afterwards to GPU
		if (app.constructor.output_mode === SurfaceOutputModeType.Canvas) {
			if (!app.canvas || app.canvas.width !== tex.width || app.canvas.height !== tex.height) {
				if (Surface.verbose)
					console.debug("[SURFACE] create canvas for surface", this.entity.name);
				app.canvas = document.createElement("canvas");
				app.canvas.width = tex.width;
				app.canvas.height = tex.height;
				app.ctx = app.canvas.getContext("2d");
			}
			//apps fill the content
			this.renderInsideUI(app.canvas);
			//canvas upload to GPU
			tex.uploadImage(app.canvas, Surface.texture_settings);
		} else if (app.render) {
			tex.drawTo(this.renderInsideUI.bind(this));
		}

		this._must_render_app_ui = Boolean(app.renderGlobalUI);
	}

	/**
	 *
	 * @param {Texture|HTMLCanvasElement} output
	 */
	renderInsideUI(output) {
		let ctx = null;
		if (output.constructor === GL.Texture) {
			ctx = gl;
		}
		else if (output.constructor === HTMLCanvasElement)
			ctx = output.getContext("2d");

		this.viewport = [ 0, 0, output.width, output.height ];

		if (!this.GUI) {
			this.GUI = new GLUIContext();
			this.GUI.name = "surface_GUI";
			this.GUI.icons = getFullPath(this._app.icons || "textures/icons.png"); // app spritesheet || or global one
			this.GUI.init(ctx);
		}

		this.GUI.resetGUI();

		if (this.active || this.no_focus) {
			this.GUI.setMouse({
				position: this.mouse,
				mousex: this.mouse[0],
				mousey: this.mouse[1],
				buttons: gl.mouse.buttons,
				dragging: gl.mouse.dragging
			});
		}

		// renders the app
		if (this._app.render)
			this._app.render(ctx, output, this.GUI, this.GUI.mouse, this.viewport);
	}

	/**
	 * Renders the name of the person sharing its screen at the bottom left position of the whiteboard
	 */
	renderScreenShareElements() {
		// const xyzLauncher = XYZLauncher.instance;
		// let name;
		// name = xyzLauncher.globalFeedOwner || "Guest";
		//
		// const shortName = `${name.length > 8? name.substring(0, 8) + "..." : name}`;
		// const text = `${shortName} is screen sharing`;
		//
		// if (name === xyzLauncher.getLocalParticipant().getUsername()
		// 	|| this.mobile
		// 	|| xyzLauncher.globalFeedOwner !== undefined
		// 	|| this._app === null) {
		// 	return;
		// }
		//
		// gl.start2D();
		//
		// const rectX = 95;
		// const rectY = this._app.height - 105;
		// const rectWidth = gl.measureText(text).width + 2;
		// const rectHeight = gl.measureText(text).height + 10;
		// const cornerRadius = 5;
		//
		// let gradient = gl.createLinearGradient(rectX, rectY, rectX, rectY + rectHeight);
		// gradient.addColorStop(0, "rgb(171,169,176)");
		// gradient.addColorStop(1, "rgb(129,125,136)")
		// gl.fillStyle = gradient;
		// this.drawRoundedRect(rectX, rectY, rectWidth, rectHeight, cornerRadius);
		//
		// gradient = gl.createLinearGradient(rectX, rectY, rectX, rectY + rectHeight);
		// gradient.addColorStop(0, "rgb(45,39,57)");
		// gradient.addColorStop(.33, "rgb(87,82,97)");
		// gradient.addColorStop(.66, "rgb(129,125,136)");
		// gradient.addColorStop(1, "rgb(171,169,176)");
		//
		// gl.fillStyle = gradient;
		// this.drawRoundedRect(rectX + 1, rectY + 1, rectWidth - 2, rectHeight - 2, cornerRadius);
		//
		// gl.fillStyle = "white";
		// gl.font = "24px Arial";
		// gl.textAlign = "left";
		// gl.textBaseline = "center";
		// gl.fillText(text, 100 + gl.measureText(text).width * 0.02, this._app.height - 78);
		//
		// gl.finish2D();
	}

	/**
	 * Draws a rectangle with rounded corners based on a radius
	 * @param {number} x
	 * @param {number} y
	 * @param {number} width
	 * @param {number} height
	 * @param {number} radius
	 */
	drawRoundedRect(x, y, width, height, radius) {
		gl.beginPath();
		gl.moveTo(x + radius, y);
		gl.lineTo(x + width - radius, y);
		gl.quadraticCurveTo(x + width, y, x + width, y + radius);
		gl.lineTo(x + width, y + height - radius);
		gl.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
		gl.lineTo(x + radius, y + height);
		gl.quadraticCurveTo(x, y + height, x, y + height - radius);
		gl.lineTo(x, y + radius);
		gl.quadraticCurveTo(x, y, x + radius, y);
		gl.closePath();
		gl.fill();
	}

	fixAspectAndCenter(mat) {
		if (!mat.textures.emissive || !mat.textures.emissive.texture)
			return;

		const tex = gl.textures[mat.textures.emissive.texture];
		if (!tex)
			return;
		mat.emissive_clamp_to_edge = true;
		let texture_aspect = tex.width / tex.height;
		if (this._feed && this._feed._original_texture_size)
			texture_aspect = this._feed._original_texture_size[0] / this._feed._original_texture_size[1];
		const node = this.entity.node;
		const surface_aspect = node.scaling[0] / node.scaling[1];
		if (!mat.uv_transform)
			mat.uv_transform = mat3.create();
		mat.textures.emissive.uv_channel = 2;

		let scalex = 1;
		let scaley = 1;
		let offsetx = 0;
		let offsety = 0;

		if (surface_aspect > texture_aspect)
			scalex = surface_aspect / texture_aspect;
		else
			scaley = texture_aspect / surface_aspect;

		offsetx = (1.0 - scalex) * 0.5;
		offsety = (1.0 - scaley) * 0.5;

		if (this._feed && this._feed._original_texture_size) {
			const aspect_fix_w = this._feed._original_texture_size[0] / tex.width;
			const aspect_fix_h = this._feed._original_texture_size[1] / tex.height;
			scalex *= aspect_fix_w;
			scaley *= aspect_fix_h;
			offsetx *= aspect_fix_w;
			offsety *= aspect_fix_h;
		}

		mat.uv_transform[0] = scalex;
		mat.uv_transform[4] = scaley;
		mat.uv_transform[6] = offsetx;
		mat.uv_transform[7] = offsety;
	}

	/**
	 * render just after rendering the whole scene
	 */
	postRender(view) {
		if (this.enabled && this.active && this._app && this._must_render_app_ui && this._app.renderGlobalUI) {
			this._app.renderGlobalUI(gl, GUI);
			this._must_render_app_ui = false;
		}
	}

	/**
	 * for editor
	 */
	renderGizmo(view, editor, selected) {
		if (!this.enabled || !this.entity.enabled)
			return;

		const model = this.root.node.getGlobalMatrix();
		mat4.translate(model, model, [ 0, 0, 0.0001 ]);
		var color = selected ? [ 1, 1, 0, 0.9 ] : [ 0.3, 0.3, 0.3, 0.5 ];
		view.renderer.color = color;

		if (editor.use_depth_in_gizmos)
			gl.enable(gl.DEPTH_TEST);
		else
			gl.disable(gl.DEPTH_TEST);
		gl.enable( gl.BLEND );
		gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );

		view.renderer.renderPoints(
			Surface.gizmo_vertices,
			null,
			view._last_camera,
			4,
			null,
			0.01,
			gl.LINE_LOOP,
			null,
			model
		);

		/*
		if (!Surface.gizmo_material) {
			const mat = new Material();
			mat.textures.albedo = { texture: "textures/surface-guide.png" };
			mat.flags.two_sided = true;
			mat.alphaMode = "BLEND";
			mat.name = "surface_guide";
			StaticMaterialsTable[mat.name] = mat;
			Surface.gizmo_material = mat;
		}

		const model = this.root.node.getGlobalMatrix();
		mat4.translate(model, model, [ 0, 0, 0.0001 ]);
		const mesh = gl.meshes["plane"];
		Surface.gizmo_material.color = selected ? [ 1, 1, 0, 0.9 ] : [ 0.3, 0.3, 0.3, 0.5 ];
		if (view.pbrpipeline)
			view.pbrpipeline.renderMeshWithMaterial(model, mesh, Surface.gizmo_material);

		//render campos
		if (selected) {
			const eye = this.entity.node.localToGlobal([ 0, 0, 0.75 ]);
			editor.renderIcon3D(eye, GLUI.icons.circle, -44, [ 0.5, 0.75, 1, 0.5 ]);
			editor.renderIcon3D(eye, [ 8, 7 ], -32, [ 2, 2, 2, 0.5 ]);

			if (0) {
				editor.flat_material.primitive = GL.LINES;
				const target = this.entity.node.localToGlobal([ 0, 0, 0 ]);
				const matrix = mat4.create();
				mat4.lookAt(matrix, eye, target, Direction3Constants.UP);
				mat4.invert(matrix, matrix);
				mat4.scale(matrix, matrix, this.entity.node.scaling);
				view.pbrpipeline.renderMeshWithMaterial(matrix, editor.viewcone_mesh, editor.flat_material);
				editor.flat_material.primitive = GL.TRIANGLES;
			}
		}
		*/
	}

	/**
	 * called when you want a feed to be visible in this surface
	 * DEPRECATED: now it is more used from FeedManager
	 * @deprecated
	 */
	assignFeed(feed, no_autoplay) {
		// do nothing
	}

	/**
	 * called from entity.update
	 */
	update(dt, t) {
		if (this._app && this.GUI && this.power)
			this._app.update(dt, t, this.GUI.mouse); //, this._texture, this.GUI.context, this.viewport

		if (this.root._force_visible)
			this.root._force_visible = false;
	}

	convertRayToLocalSpace(ray, result) {
		const entity = this.root;
		return entity.node.testRayWithLocalPlane(ray, null, result, true);
	}

	convertSurfaceCoordToWorldSpace(pos) {
		const texture = this._texture;
		const local_pos = [ (pos[0] / texture.width) - 0.5, (pos[1] / texture.height) * -1 + 0.5, 0 ];
		return this.entity.node.localToGlobal(local_pos);
	}

	/**
	 * called from
	 */
	testRay(ray, participant) {
		if (!this._target_node && this.proxy_node)
			this._target_node = this.space.getSceneNode(this.proxy_node);

		return this._target_node &&
			this._target_node.visible &&
			this._target_node.testRay(ray, vec3.create(), Infinity, 0xFF, true);
	}

	onMouseEnter(e) {
		if (this._app && this._app.onMouseEnter)
			this._app.onMouseEnter(e);
	}

	onMouseLeave(e) {
		if (this._app && this._app.onMouseLeave)
			this._app.onMouseLeave(e);
	}

	/**
	 * only called if mouse hovers the surface
	 */
	onMouse(e) {
		if (Surface.block_apps)
			return;

		if (this._app) {
			e.surfaceX = this.mouse[0];
			e.surfaceY = this.mouse[1];
			if (this._app.onMouse)
				this._app.onMouse(e, this.active);
		}

		//pass mouse to GUI
		if (this.GUI && (this.active || this.no_focus)) {
			this.GUI.onMouse(e);
			return this.GUI.isPositionBlocked(this.mouse, true);
		}
	}

	/**
	 * called from Call.testRayRoomInteraction before sending it to onMouse
	 */
	onMouseRay(ray) {
		if (!this.interactive) {
			this.is_mouse_inside = false;
			return;
		}

		const local_point = this.convertRayToLocalSpace(ray, this.local_point);
		if (!local_point)
			return;

		let w = this.width;
		let h = this.height;
		if ((!w || !h) && this._texture) {
			w = this._texture.width;
			h = this._texture.height;
		}

		const x = remap(local_point[0], -0.5, 0.5, 0, w);
		const y = remap(local_point[1], 0.5, -0.5, 0, h);
		if (x >= 0 && x < w && y >= 0 && y < h) {
			this.mouse[0] = x;
			this.mouse[1] = y;
			this.is_mouse_inside = true;

			if (this._material && this._material.uv_transform) {
				const uvmat = this._material.uv_transform;
				vec2.transformMat3(this.mouse, this.mouse, uvmat);
			}
		} else
			this.is_mouse_inside = false;
	}

	/**
	 * called from entity.processClick
	 */
	processClick(e) {
		if (!this.interactive)
			return;

		const space = this.space;
		const view = ViewCore.instance;
		const local_participant = space.local_participant;
		if (!local_participant)
			return;

		const is_valid_surface = (!this.seat || this.seat === local_participant.seat?.entity.name);

		if (!is_valid_surface) //is seated and is not using someone else's tablet
			return;

		//clicked here but has a focused entity
		if (local_participant.focus_item) {

		} else if (!this.no_focus) {
			//focus on this surface
			const aspect = gl.canvas.width / gl.canvas.height;

			view.hard_camera.fov = 45 / aspect;

			local_participant.focusOn(this.entity.node, local_participant);

			if (!this.app_name && this.interactive && this.startup_app) {

				this.call_controller.triggerAction({
					action: "surface_update",
					surface_entity: this.root.uid,
					subaction: "load_app",
					app_name: this.startup_app
				});

			}
		}
	}

	/**
	 * called from ActionListener
	 */
	processSurfaceAction(evt) {
		if (evt.subaction === "app_action") {
			const app = this._app;
			if (app && app.processAction) {
				app.processAction(evt.app_action);
			}
		} else if (evt.subaction === "load_app") {
			// if the app is already loaded,
			// we just need to load the state
			if (this.app_name === evt.app_name) {
				if (evt.media_url)
					this.media_url = evt.media_url;

				if (evt.app_state && this._app && this._app.JSONToState)
					this._app.JSONToState(evt.app_state);
			} else {
				if (evt.media_url)
					this.media_url = evt.media_url;
				if (evt.app_state) {
					// loads app and state if provided
					if (this.apps) {
						if (!this.apps[evt.app_name]) {
							this.apps[evt.app_name] = {};
						}
						this.apps[evt.app_name].state = evt.app_state;
						if (evt.initiator) {
							this.apps[evt.app_name].initiator = evt.initiator;
						}
					}
				} else {
					if (evt.initiator) {
						if (!this.apps[evt.app_name]) {
							this.apps[evt.app_name] = {};
						}
						this.apps[evt.app_name].initiator = evt.initiator;
					}
				}
				this.app_name = evt.app_name;
			}
		} else if (evt.subaction === "turn_off") {
			this.power = false;
		}
	}

	onKeyDown(e) {
		if (Surface.block_apps)
			return;

		if (this.GUI && this.active) {
			const r = this.GUI.onKey(e);
			if (r)
				return r;
		}

		if (this._app && this._app.onKeyDown) {
			const r = this._app.onKeyDown(e, this.active);
			if (r)
				return r;
		}
	}

	onEnterFocus() {
		this.power = true;
		if (!this.app_name)
			this.app_name = "LockScreen";
	}

	onExitFocus() {
	}

	/**
	 * tells you if the local participant is focused on this surface
	 */
	isInFocus() {
		const space = this.space;
		const local_participant = space.local_participant;
		if (!local_participant)
			return false;
		return local_participant.focus_item === this.entity.node;
	}

	/**
	 * to stop being focused
	 */
	exitFocus() {
		const space = this.space;
		const local_participant = space.local_participant;
		local_participant.focusOn(null); //stop focusing
	}

	isPlayingFeed() {
		return !!(this.space._global_feed && this.space._global_feed._feed === this._texture);
	}

	/**
	 * for editor
	 */
	onRenderInspector(ctx, x, y, w, h, editor) {
		const component = this;
		const entity = component.root;
		const that = this;
		const line_height = 24;

		//TARGET
		Label.call(GUI, x, y, 140, line_height, "Proxy");
		GUI.next_tooltip = "Proxy node name";
		component.proxy_node = GUI.TextField(x + 70, y, w - 140, line_height, component.proxy_node || "", null, true);
		GUI.next_tooltip = "Autoselect";
		if (Button.call(GUI, x + w - 55, y, line_height, line_height, [ 7, 6 ])) {
			const node = editor.getNodeBehindEntity(entity);
			if (node && node.name)
				component.proxy_node = node.name;
		}
		GUI.next_tooltip = "Select node";
		if (Button.call(GUI, x + w - line_height, y, line_height, line_height, [ 6, 4 ])) {
			const tool = editor.selectTool("select");
			editor.enableSelectNodeTool(function (node) {
				if (node && node.name)
					that.proxy_node = node.name;
			});
		}
		y += line_height + 10;

		Label.call(GUI, x, y, 140, line_height, "Proxy Mat");
		GUI.next_tooltip = "Proxy material";
		component.proxy_material_name = GUI.TextField(x + 100, y, w - 130, line_height, component.proxy_material_name || "", null, true);
		GUI.next_tooltip = "Use selected mat";
		if (Button.call(GUI, x + w - line_height, y, line_height, line_height, [ 6, 4 ])) {
			if (editor.selected_material)
				component.proxy_material_name = editor.selected_material.name;
		}
		y += line_height + 30;

		//Setup
		Label.call(GUI, x, y, 140, line_height, "Seat");
		GUI.next_tooltip = "Attributed seat name";
		component.seat = GUI.TextField(x + 70, y, w - 140, line_height, component.seat || "", null, true);
		y += line_height + 10;

		//
		Label.call(GUI, x, y, 140, line_height, "Brightness");
		this.brightness = GUI.Slider(x + 120, y, w - 120, line_height, this.brightness, 0, 10, 0.1, 1);
		y += line_height + 10;
		Label.call(GUI, x, y, 140, line_height, "Resolution");
		this.resolution_factor = GUI.Number(x + 120, y, w - 120, line_height, this.resolution_factor) || 1;
		y += line_height + 10;
		Label.call(GUI, x, y, 140, line_height, "BG Image");
		GUI.next_tooltip = "Background Image";
		component.background_image = GUI.TextField(120, y, w - 160, line_height, component.background_image || "", null, true);
		if (Button.call(GUI, w - line_height, y, line_height, line_height, [ 2, 0 ])) {
			editor.selectFile(function (file) {
				component.background_image = file ? file.localpath : null;
			}, [ "png", "jpg", "webp" ]);
		}
		y += line_height + 30;

		//APPS
		Label.call(GUI, x, y, 140, line_height, "App.");
		GUI.next_tooltip = "Application to run";
		//component.app_name = GUI.TextField( x + 70,y,w-120,line_height, component.app_name || "", null, true );
		this.app_name = GUI.TextField(x + 60, y, w - 120, line_height, this.app_name);
		if (Button.call(GUI, x + w - line_height * 2 - 5, y, line_height, line_height, [ 2, 0 ])) {
			const apps = SurfaceAppsContainer.getAppNames();
			apps.unshift("");
			GUI.ShowContextMenu(apps, function (i, v) {
				component.app_name = v;
			}, "surface_apps");
		}
		if (Button.call(GUI, x + w - line_height, y, line_height, line_height, [ 5, 0 ])) {
			editor.attachDialog(AppSettingsDialog);
		}
		y += line_height + 10;

		GUI.next_tooltip = "Blending mode";
		Label.call(GUI, x, y, 140, line_height, "Alpha Mode");
		if (Button.call(GUI, x + 140, y, 85, line_height, this.alpha_mode)) {
			if (this.alpha_mode === "OPAQUE")
				this.alpha_mode = "MASK";
			else if (this.alpha_mode === "MASK")
				this.alpha_mode = "BLEND";
			else
				this.alpha_mode = "OPAQUE";
		}

		y += line_height + 10;

		let sx = 20;

		/*
		GUI.next_tooltip = "Block GL texture to be displayed on surface";
		//Label.call(GUI,x, y, w - line_height * 3, line_height, "Block GL texture");
		if (Button.call(GUI, sx, y, 100, line_height, "Block GL", this.block_gl_texture)) {
			this.block_gl_texture = !this.block_gl_texture;
		}
		sx += 110;

		GUI.next_tooltip = "HTML in Surface (WIP)";
		if (Button.call(GUI, sx, y, 85, line_height, "HTML", this._html_popup)) {
			if (this._html_popup) {
				this._html_popup.close();
			} else {
				const url = prompt("URL");
				if (url)
					this.openHTML(url, "External App");
			}
		}

		y += line_height + 5;
		sx = 20;
		GUI.next_tooltip = "Loop video";
		if (Button.call(GUI, sx, y, 85, line_height, "Loop", this.loop))
			this.loop = !this.loop;

		*/

		GUI.next_tooltip = "Enable surface interactivity";
		if (Button.call(GUI, sx, y, 105, 20, "Interactive", this.interactive)) {
			this.interactive = !this.interactive;
		}

		sx += 110;
		GUI.next_tooltip = "Skip Screenshare";
		if (Button.call(GUI, sx, y, 85, 20, "No share", this.skip_screenshare))
			this.skip_screenshare = !this.skip_screenshare;

		sx += 90;

		GUI.next_tooltip = "No focus on click";
		if (Button.call(GUI, sx, y, 120, 20, "No focus", this.no_focus))
			this.no_focus = !this.no_focus;

		y += line_height + 10;
		sx = 20;

		GUI.next_tooltip = "Align with proxy";
		if (Button.call(GUI, sx, y, 200, line_height, "Align with node"))
			this.align();

		return y;
	}

	align()	{
		var node = null;
		if (window.nativeEngine)
			node = nativeEngine._room.findNodeByName(this.proxy_node);
		else
			node = xyz.space.scene.root.findNodeByName(this.proxy_node);
		if (!node || !node.mesh)
			return; //bad node
		var ent = this.entity;
		var scale = node._scale;
		var zaspect = scale[2] / scale[1];
		//ent.node.transform = node.transform;

		if (window.nativeEngine)
		{
			ent.node._position = node.position;
			ent.node._scale = node.scaling;
			ent.node._rotation = node.rotation;
		}
		else
		{
			ent.node.position = node.position;
			ent.node.scaling = node.scaling;
			ent.node.rotation = node.rotation;
		}
		ent.node.scale([ 0.02, 0.02 * zaspect, 0.02 * 2 ]);
		ent.node.rotate(Math.PI * -0.5, [ 1, 0, 0 ]);
	}

	testTexture() {
		this._texture_name = "data/textures/desktop.png";
		this._material.textures.emissive.texture = this._texture_name;
	}

	openHTML(url, title) {
		const that = this;
		title = title || "";

		if (!ROOM.style) {
			const style = ROOM.style = document.createElement("style");
			style.innerHTML = PopupStyle.toString();
			document.head.appendChild(style);
		}

		if (!this._html_popup) {
			const scaling = this.entity.node.scaling;
			const aspect = scaling[0] / scaling[1];
			const div = document.createElement("div");
			div.style.width = "1024px";
			div.style.height = (1024 / aspect) + "px";
			div.innerHTML = PopupTemplate;
			div.className = "ingame-popup";
			div.close = function () {
				that._html_popup = null;
				this.parentNode.removeChild(this);
			}
			const title_elem = div.querySelector(".titlebar .title");
			if (title)
				title_elem.innerText = title;
			const close = div.querySelector(".close");
			close.addEventListener("click", function (e) {
				div.close();
			});
			document.body.appendChild(div);
			this._html_popup = div;
		}

		const iframe = this._html_popup.querySelector("iframe");
		iframe.src = url;
	}

	updateAppsSettings(app_name, attr, value) {
		if (!this.apps_settings.apps)
			this.apps_settings.apps = {};
		if (!this.apps_settings.apps[app_name])
			this.apps_settings.apps[app_name] = {};

		if (value === "true") value = true;
		else if (value === "false") value = false;

		this.apps_settings.apps[app_name][attr] = value;

		// update app instance if already running
		if (this.app_name === app_name) {
			if (value === "")
				delete this.apps_settings.apps[app_name][attr];

			this._app[attr] = value;
		}
	}
}


export default Surface;
