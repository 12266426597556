// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ingame-popup {
	border: 1px solid black;
	background-color: #333;
}

.ingame-popup .titlebar {
	width: 100%;
	height: 30px;
	color: white;
	background-color: black;
}

.ingame-popup .titlebar .close {
	float: right;
	cursor: pointer;
	color: white;
	padding: 2px;
}

.ingame-popup .content {
	width: 100%;
	height: calc( 100% - 20px );
	overflow: hidden;
}

.ingame-popup iframe {
	width: 100%;
	height: 100%;
	border: 0;
}
`, "",{"version":3,"sources":["webpack://./src/engine/components/Surface/Popup.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,sBAAsB;AACvB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,YAAY;CACZ,uBAAuB;AACxB;;AAEA;CACC,YAAY;CACZ,eAAe;CACf,YAAY;CACZ,YAAY;AACb;;AAEA;CACC,WAAW;CACX,2BAA2B;CAC3B,gBAAgB;AACjB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,SAAS;AACV","sourcesContent":[".ingame-popup {\n\tborder: 1px solid black;\n\tbackground-color: #333;\n}\n\n.ingame-popup .titlebar {\n\twidth: 100%;\n\theight: 30px;\n\tcolor: white;\n\tbackground-color: black;\n}\n\n.ingame-popup .titlebar .close {\n\tfloat: right;\n\tcursor: pointer;\n\tcolor: white;\n\tpadding: 2px;\n}\n\n.ingame-popup .content {\n\twidth: 100%;\n\theight: calc( 100% - 20px );\n\toverflow: hidden;\n}\n\n.ingame-popup iframe {\n\twidth: 100%;\n\theight: 100%;\n\tborder: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
