import BaseComponent from "@src/engine/components/baseComponent";
import { generateUID } from "@src/engine/generateUID";
import { UID_PREFIX } from "@src/engine/rendeer/UID_PREFIX";
import { checkVersion } from "@src/engine/Room/checkVersion";
import { clone } from "@src/engine/Room/clone";
import { cloneObject } from "@src/engine/Room/cloneObject";
import { extendClass } from "@src/engine/Room/extendClass";
import { getFullPath } from "@src/engine/Room/file-utils";
import { getClassName } from "@src/engine/Room/getClassName";
import { getExtension } from "@src/engine/Room/getExtension";
import { getObjectClassName } from "@src/engine/Room/getObjectClassName";
import { locator_chars } from "@src/engine/Room/locator_chars";
import { openLink } from "@src/engine/Room/openLink";
import { ROOM_LAYERS } from "@src/engine/Room/ROOM_LAYERS";
import { ROOM_PREFABS } from "@src/engine/Room/ROOM_PREFABS";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { ROOM_TYPES_STR } from "@src/engine/Room/ROOM_TYPES_STR";
import { ROOM_VERSION } from "@src/engine/Room/ROOM_VERSION";
import { RoomComponents } from "@src/engine/RoomComponents";
import { LEvent } from "@src/libs/LEvent";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import clamp from "@src/math/clamp";

import HTMLDialogStyles from "./Room/html-dialog.css";
import InGamePopupStyles from "./Room/ingame-popup.css";
import InGamePopupTemplate from "./Room/ingame-popup.html";
import RoomProxy from "./Room/RoomProxy";

import isFunction from "lodash.isfunction";

const ROOM = {};

export default ROOM;

ROOM.version = ROOM_VERSION; //main version, current version, variation
ROOM.version_str = BUILD_VERSION;
ROOM.build_timestamp = BUILT_TS; //this gets generated automatically from build shell command
ROOM.verbose = false; //log all!

ROOM.TYPES = ROOM_TYPES;

ROOM.DATA_TYPES = {
	BOOLEAN: "boolean",
	NUMBER : "number",
	STRING : "string",
	VEC2 : "vec2",
	VEC3 : "vec3",
	VEC4 : "vec4",
	COLOR : "color",
	COLOR4 : "color4",
	EVENT : "event",
	RESOURCE: "resource",
	TEXTURE : "texture",
	MESH: "mesh",
	OBJECT: "object",
	SCENE: "scene",
	NODE: "node",
	SCENENODE: "node",
	SCENENODE_ID: "node_id",
	COMPONENT: "component",
	COMPONENT_ID: "component_id",
	MATERIAL: "material",
	ANIMATION: "animation",
	ARRAY: "array",
	QUAT : "quat",
	TRANS10 : "trans10",
	POSITION : "position"
};

ROOM.settings = ROOM_SETTINGS;
ROOM.params = {}; //url parameters are stored here

/**
 * @deprecated use direct import instead
 * @readonly
 */
ROOM.TYPES_STR = ROOM_TYPES_STR;

ROOM.QUALITY = {
	LOW: 1,
	MEDIUM: 2,
	HIGH: 3
};

ROOM.PLAY_MODES = {
	STOP: 0,
	PLAY: 1,
	PAUSE: 2
};

ROOM.QUALITY_STR = {};
for (var i in ROOM.QUALITY)
	ROOM.QUALITY_STR[ ROOM.QUALITY[i] ] = i;

/**
 * @deprecated use `UID_PREFIX` directly
 * @type {string}
 * @readonly
 */
ROOM._uid_prefix = UID_PREFIX;

/**
 * @deprecated use direct import instead
 */
ROOM.LAYERS = ROOM_LAYERS;

//globals
ROOM.view = null;
ROOM.credentials = null; //used for fetch
ROOM.crossOrigin = "anonymous";
ROOM.root_path = "data/";
ROOM.proxy = "";
ROOM.images = [];
ROOM.cursor_style = "";
ROOM.webfonts_loaded = false;  // now provided from outside
ROOM.user_clicked_once = false; //changed once the user clicks
ROOM.participant_colors = [
	[ 0, 180/255, 144/255 ], 	// cyan
	[ 0, 75/255, 180/255 ], 	// dark blue
	[ 43/255, 180/255, 0 ], 	// green
	[ 0, 137/255, 255/255 ], 	// light blue
	[ 85/255, 238/255, 9/255 ], 	// light green
	[ 255/255, 173/255, 0 ], 	// orange
	[ 255/255, 0, 52/255 ], 	// red
	[ 147/255, 0, 180/255 ], 	// violet
	[ 255/255, 204/255, 0 ], 	// yellow
];
/**
 * Used by {@link RoomCall}
 * @type {number}
 */
ROOM.inactive_opacity = 1;
ROOM.windows = []; //used from editor mostly
ROOM.stats = {};

ROOM.proxies = RoomProxy.getProxies();
ROOM.proxy = RoomProxy.getCurrentProxy();

ROOM.scene = null;


/**
 * @deprecated use direct import instead
 */
ROOM.locator_chars = locator_chars;

/**
 * @deprecated use `ROOM_PREFABS` directly instead
 * @readonly
 */
ROOM.prefabs = ROOM_PREFABS;

//to generate a unique universal identifier
ROOM.generateUID = generateUID;

ROOM.use_two_step_proxy = false;

ROOM.two_step_domains = {};

ROOM.getTwoStepProxy = function( url, callback )
{
	//extract domain
	var protocol_index = url.indexOf("://");
	if (protocol_index === -1 ) //thats a relative address
	{
		if (callback)
			callback(url);
		return url;
	}

	var end_index = url.indexOf( "/", protocol_index + 3 );
	var domain = url.substr(0,end_index);

	//same domain
	if (domain === location.origin)
	{
		if (callback)
			callback(url);
		return url;
	}

	var params = url.substr(end_index+1);

	//console.debug(domain,params);

	//check if proxy already available
	var existing_uuid = ROOM.two_step_domains[ domain ];
	if ( existing_uuid )
	{
		var final_url = location.origin + "/api/" + existing_uuid + "/" + params;
		if (callback)
			callback( final_url );
		return final_url;
	}

	var payload = {
	  "msgType": -1302021219,
	  "vox": null,
	  "cmd": "redirect",
	  "reqId": null,
	  "resTopic": null,
	  "target": domain
	};

	//fetch url
	fetch("/api/landing",
		{
			headers: {
		  "Accept": "application/json",
		  "Content-Type": "application/json"
			},
			method: "POST",
			body: JSON.stringify(payload)
		})
		.then(function(res) {
			if (res.status === 200)
				return res.json();
		})
		.then(function(json) {
			if (!json)
				return;
			var existing_uuid = json.url;
			//console.debug("uuid: ",existing_uuid);
			ROOM.two_step_domains[ domain ] = existing_uuid;
			var final_url = location.origin + "/api/" + existing_uuid + "/" + params;
			//console.debug("new url:",final_url);
			if (callback)
				callback( final_url );
		})
		.catch(function(res) {
			console.error(res)
		});
}

if (typeof(window) !== "undefined" && location.host.indexOf("room3d.com") !== -1 )
{
	ROOM.use_two_step_proxy = true;
}

ROOM.enable3DContainer = function()
{
	if (!this._html3D_data)
	{
		var container = document.createElement("div");
		gl.canvas.parentNode.appendChild( container );
		container.id = "roomContainer3D";
		container.style.position = "absolute";
		container.style.left = 0;
		container.style.top = 0;
		container.style.width = gl.canvas.width + "px";
		container.style.height = gl.canvas.height + "px";
		container.style.display = "block";
		container.style.overflow = "hidden";

		var cameraElement = document.createElement("div");
		cameraElement.id = "roomCamera3D";
		container.appendChild( cameraElement );

		this._html3D_data = {
			container: container,
			cameraElement: cameraElement
		};
	}

	return this._html3D_data;
}

// Utils *************************************

if ( !String.prototype.hasOwnProperty( "hashCode" ) )
{
	Object.defineProperty( String.prototype, "hashCode", {
		value: function() {
			var hash = 0, i, c, l;
			if (this.length === 0) return hash;
			for (i = 0, l = this.length; i < l; ++i) {
				c  = this.charCodeAt(i);
				hash  = ((hash<<5)-hash)+c;
				hash |= 0; // Convert to 32bit integer
			}
			return hash;
		},
		enumerable: false
	});
}

/**
 * @deprecated use `getExtension` directly
 * @readonly
 */
ROOM.getExtension = getExtension

ROOM.replaceExtension = function(path,extension)
{
	if (!path)
		return path;
	if (!extension)
		return this.removeExtension(path);
	return this.removeExtension(path) + "." + extension;
}

ROOM.removeExtension = function(path)
{
	if (!path)
		return path;
	var index = path.lastIndexOf(".");
	if (index !== -1)
		return path.substr(0,index);
	return path;
}

/**
 * @deprecated use direct import
 * @readonly
 */
ROOM.clone = clone;

/**
 * @deprecated use direct import
 * @readonly
 */
ROOM.cloneObject = cloneObject;

/**
 * @deprecated use direct import
 * @readonly
 */
ROOM.extendClass = extendClass;

ROOM.loadScripts = function(scripts, callback, use_assets_folder )
{
	if (!scripts)
	{
		if (callback)
			callback();
		return;
	}

	if (scripts.constructor === String)
		scripts = [ scripts ];
	else
		scripts = scripts.concat(); //clone because we emptied

	inner();
	function inner()
	{
		if (!scripts.length)
		{
			if (callback)
				callback();
			return;
		}
		var script = document.createElement("script");
		var url = scripts.shift();
		if (use_assets_folder)
			url = getFullPath(url,true);
		else
			url += "?nocache=" + (getTime().toFixed(6)) + Math.random();
		script.src = url;
		script.onload = inner;
		script.onerror = inner_error;
		document.head.appendChild(script);
	}

	function inner_error()
	{
		console.error("error fetching script");
		inner();
	}
}

/**
 * @deprecated use direct import
 * @readonly
 */
ROOM.openLink = openLink

ROOM.openPopup = function( url, title, entity )
{
	if (!ROOM.style) {
		ROOM.style = ROOM.addStyle(InGamePopupStyles.toString());
	}
	var div = document.createElement("div");
	div.innerHTML = InGamePopupTemplate;
	div.className = "ingame-popup";
	div.close = function() { ROOM.popup = null; this.parentNode.removeChild( this ); }
	var title_elem = div.querySelector(".titlebar .title");
	if (title)
		title_elem.innerText = title;
	var close = div.querySelector(".close");
	close.addEventListener("click", function(e) {
		div.close();
	});

	var iframe = div.querySelector("iframe");
	iframe.src = url;

	document.body.appendChild( div );
	ROOM.popup = div;

	if ( !entity )
		return;
	if (entity.constructor === String)
		entity = xyz.room.getEntity( entity );
	if ( !entity || !entity.node )
		return;
	ROOM.popup.transform_node = entity.node;
}

/**
 * use import directly
 * @deprecated
 */
ROOM.getClassName = getClassName;

/**
 * use import directly
 * @deprecated
 */
ROOM.getObjectClassName = getObjectClassName;

ROOM.setObjectProperty = function( obj, name, value )
{
	if (obj.setProperty)
		return obj.setProperty(name, value);

	if (obj[name] && obj[name].set)
		obj[name].set(value);
	else
		obj[ name ] = value;
	if (obj.onPropertyChanged)
		obj.onPropertyChanged( name, value );
	if(obj.mustUpdate === false)
		obj.mustUpdate = true;
}

ROOM.getObjectProperty = function( obj, name )
{
	if (obj.getProperty)
		return obj.getProperty(name);
	return obj[ name ];
}


ROOM.getObjectProperties = function( object )
{
	if (object.getPropertiesInfo)
		return object.getPropertiesInfo();
	var class_object = object.constructor;
	if (class_object.properties)
		return class_object.properties;

	var o = {};
	for (var i in object)
	{
		//ignore some
		if (i[0] === "_" || i[0] === "@" || i.substr(0,6) === "jQuery") //skip vars with _ (they are private)
			continue;

		if (class_object !== Object)
		{
			var hint = class_object["@"+i];
			if (hint && hint.type)
			{
				o[i] = hint.type;
				continue;
			}
		}

		var v = object[i];
		if (v == null)
			o[i] = null;
		else if ( isFunction(v) )//&& Object.getOwnPropertyDescriptor(object, i) && Object.getOwnPropertyDescriptor(object, i).get )
			continue; //o[i] = v;
		else if (  v.constructor === Boolean )
			o[i] = ROOM.DATA_TYPES.BOOLEAN;
		else if (  v.constructor === Number )
			o[i] = ROOM.DATA_TYPES.NUMBER;
		else if ( v.constructor === String )
			o[i] = ROOM.DATA_TYPES.STRING;
		else if ( v.buffer && v.buffer.constructor === ArrayBuffer ) //typed array
		{
			if (v.length === 2)
				o[i] = ROOM.DATA_TYPES.VEC2;
			else if (v.length === 3)
				o[i] = ROOM.DATA_TYPES.VEC3;
			else if (v.length === 4)
				o[i] = ROOM.DATA_TYPES.VEC4;
			else if (v.length === 9)
				o[i] = ROOM.DATA_TYPES.MAT3;
			else if (v.length === 16)
				o[i] = ROOM.DATA_TYPES.MAT4;
			else
				o[i] = 0;
		}
		else
			o[i] = 0;
	}
	return o;
}

//allows to load using credentials
//if no callback, it returns the promise
ROOM.fetchAsset = function(url, callback)
{
	var full_url = getFullPath(url, true);
	var p = fetch( full_url, ROOM.credentials );
	if (!callback)
		return p;

	p.then(function(response) {
		if (!response.ok)
			throw new Error("HTTP " + response.status + ":" + response.statusText );
		response.text().then(callback);
	});
}

ROOM.addStyle = function( css )
{
	var style_tag = document.createElement("style");
	style_tag.innerHTML = css;
	document.head.append(style_tag);
	return style_tag;
}

//LOCATORS ***************************************

//it returns the object referenced by this locator
ROOM.resolveLocator = function( locator )
{
	var path = locator.split("/");
	if (path[0][0] === ROOM.locator_chars.material )
	{
		var target = StaticMaterialsTable[ path[0].substr(1) ];
		if (path.length === 1)
			return target;
		else if (path.length === 2)
		{
			return target[path[1]];
		}
		return null;
	}
	return ROOM.scene.resolveLocatorFromPath( path, 0 );
}


ROOM.set = function(locator, v)
{
	var path = locator.constructor === String ? locator.split("/") : locator;
	ROOM.space.setPropertyFromPath(path,0,v);
}

ROOM.get = function(locator)
{
	var path = locator.constructor === String ? locator.split("/") : locator;
	return ROOM.space.getPropertyFromPath(path,0);
}

ROOM.toggle = function(locator)
{
	var path = locator.constructor === String ? locator.split("/") : locator;
	var v = ROOM.space.getPropertyFromPath(path,0);
	if ( v != null && v.constructor === Boolean )
		ROOM.space.setPropertyFromPath(path,0,!v);
}

ROOM.getFadeFactor = function( time, duration )
{
	duration = duration || 1;
	return clamp( ((getTime() * 0.001) - time) / duration,0.0,1.0);
}

ROOM.checkVersion = checkVersion

ROOM.getAudioContext = function()
{
	if (this.audio_context)
		return this.audio_context;
	if ( !ROOM.user_clicked_once )
		return null;

	this.audio_context = new AudioContext();
	return this.audio_context;
}

ROOM.lockMouse = function(v)
{
	if (v || v === undefined)
		gl.canvas.requestPointerLock();
}

//called from XYZLauncher.prototype.onBeforeUnload
ROOM.beforeUnload = function()
{
	for (var i in ROOM.windows)
		ROOM.windows[i].close();
	ROOM.windows = [];
}

ROOM.createHTMLDialog = function(options) {
	options = options || {};
	var style_prefix = "__room-dialog-";

	var HTML = {
		options: options
	};

	HTML.close = function()
	{
		this.container.parentNode.removeChild(this.container);
	}

	HTML.show = function()
	{
		this.container.classList.remove(`${style_prefix}hide`);
	}

	HTML.hide = function()
	{
		this.container.classList.add(`${style_prefix}hide`);
	}

	HTML.clear = function()
	{
		this.items_container.innerHTML = "";
		this.container_list = document.createElement("ul");
		this.container_list.className = `${style_prefix}search-results-list`;
		this.items_container.append( this.container_list);
	}

	HTML.addItem = function(text,img_url,on_click,item)
	{
		const model_item = document.createElement("li");
		model_item.className = `${style_prefix}model-item`;

		model_item.addEventListener("click", (e) => {
			if (on_click)
				on_click(text,item);
		});
		this.container_list.append(model_item);

		const model_thumbnail = document.createElement("img");
		model_thumbnail.src = img_url;
		model_thumbnail.width = 256;
		model_item.append(model_thumbnail);

		const model_name = document.createElement("h2");
		model_name.innerHTML = text;
		model_item.append(model_name);
	}

	HTML.container = document.createElement("div");
	HTML.container.id = `${style_prefix}container`;

	if ( options.width )
		HTML.container.style.width = options.width + "px";
	if ( options.height )
		HTML.container.style.height = options.height + "px";

	// container inner
	HTML.container_inner = document.createElement("div");
	HTML.container_inner.id = `${style_prefix}container-inner`;
	HTML.container.append(HTML.container_inner);

	// close button
	HTML.close_button = document.createElement("div");
	HTML.close_button.id = `${style_prefix}close-button`;
	HTML.close_button.innerText = "X";
	HTML.close_button.addEventListener("click", () => {
		HTML.close();
	})
	HTML.container_inner.append(HTML.close_button);

	HTML.parent_container = document.createElement("div");
	HTML.parent_container.id = `${style_prefix}parent-container`;
	HTML.container_inner.append( HTML.parent_container );

	HTML.container_list = document.createElement("ul");
	HTML.container_list.className = `${style_prefix}items-list`;
	HTML.parent_container.append( HTML.container_list );

	if (!ROOM.div_style)
	{
		var css = HTMLDialogStyles.toString().replaceAll("__HTML_PREFIX__", style_prefix);
		ROOM.div_style = ROOM.addStyle(css);
	}

	var root = document.body;
	root.append( HTML.container );

	return HTML;
}

var terminal = {
	log: function(msg) //could be overwritten by TerminalApp
	{
		console.debug(msg);
	}
}

ROOM.loadImage = function( image_url, loading_manager, load_callback )
{
	var image = new Image();
	if ( loading_manager ) {
		loading_manager.itemStart( image_url );
	}
	image.addEventListener("load", function() {
		if ( loading_manager ) {
			loading_manager.itemEnd( image_url );
		}
		if ( load_callback ) {
			load_callback( this );
		}
	});
	image.src = getFullPath( image_url );
	image.crossOrigin = ROOM.crossOrigin;
	ROOM.images[ image_url ] = image;
}

ROOM.getImage = function( image_url, loading_manager, load_callback )
{
	// creates the image if it doesn't exist
	if ( !ROOM.images[ image_url ] )
	{
		ROOM.loadImage( image_url, loading_manager, load_callback );
	}

	// image has already been created
	else
	{
		// already loaded
		if ( ROOM.images[ image_url ].naturalWidth !== 0 && loading_manager )
		{
			loading_manager.itemStart( image_url );
			loading_manager.itemEnd( image_url );
		}
		// loading is still in progress
		else {
			if ( loading_manager )
			{
				loading_manager.itemStart( image_url );
				ROOM.images[ image_url ].addEventListener("load", () => {
					loading_manager.itemEnd( image_url );
					if ( load_callback ) {
						load_callback( this );
					}
				});
			}
			else if ( load_callback )
			{
				ROOM.images[ image_url ].addEventListener("load", load_callback );
			}
		}
	}

	return ROOM.images[ image_url ];
}

ROOM.downloadFile = function( filename, data, dataType )
{
	if (!data)
	{
		console.warn("No file provided to download");
		return;
	}

	if (!dataType)
	{
		if (data.constructor === String )
			dataType = "text/plain";
		else
			dataType = "application/octet-stream";
	}

	var file = null;
	if (data.constructor !== File && data.constructor !== Blob)
		file = new Blob( [ data ], { type : dataType });
	else
		file = data;

	var url = URL.createObjectURL( file );
	var element = document.createElement("a");
	element.setAttribute("href", url);
	element.setAttribute("download", filename );
	element.style.display = "none";
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
	setTimeout( function() { URL.revokeObjectURL( url ); }, 1000*60 ); //wait one minute to revoke url
}

ROOM.waitForWebfonts = function( fonts, callback )
{
	var loadedFonts = 0;
	for (var i = 0, l = fonts.length; i < l; ++i)
	{
		(function (font) {
			var node = document.createElement("span");
			// Characters that vary significantly among different fonts
			node.innerHTML = "giItT1WQy@!-/#";
			// Visible - so we can measure it - but not on the screen
			node.style.position = "absolute";
			node.style.left = "100px";
			node.style.top = "100px";
			// Large font size makes even subtle changes obvious
			node.style.fontSize = "300px";
			// Reset any font properties
			node.style.fontFamily = "sans-serif";
			node.style.fontVariant = "normal";
			node.style.fontStyle = "normal";
			node.style.fontWeight = "normal";
			node.style.letterSpacing = "0";
			document.body.appendChild(node);

			// Remember width with no applied web font
			var width = node.offsetWidth;

			node.style.fontFamily = font;

			var interval;
			function checkFont()
			{
				// Compare current width with original width
				if (node && node.offsetWidth !== width) {
					++loadedFonts;
					node.parentNode.removeChild(node);
					node = null;
				}

				// If all fonts have been loaded
				if (loadedFonts >= fonts.length) {
					if (interval) {
						clearInterval(interval);
					}
					if (loadedFonts === fonts.length) {
						callback();
						return true;
					}
				}
			}

			if (!checkFont())
			{
				interval = setInterval(checkFont, 50);
			}
		})(fonts[i]);
	}
}

//Hack to send messages across instances opened of our domain

ROOM.broadcastCrossTabMessage = function(msg)
{
	localStorage.setItem("crossTabMessage",JSON.stringify(msg));
	localStorage.removeItem("crossTabMessage");
}

ROOM.onCrossTabMessage = function(ev)
{
	if ( !ev.originalEvent || ev.originalEvent.key !== "crossTabMessage" )
		return; // ignore other keys
	var message = JSON.parse( ev.originalEvent.newValue );
	if (!message)
		return; // ignore empty msg or msg reset
	LEvent.trigger( xyz, "crossTabMessage", message );
}

/**
 * use import directly
 * @deprecated
 */
ROOM.Components = RoomComponents;

///@INFO: BASE
/*
	A component container is someone who could have components attached to it.
	Mostly used for SceneNodes but it could be used for other classes (like Scene or Project).
*/

//To add support for components use ROOM.extendClass( myclass, ComponentContainer );

//registers one component to make it available to the system
//it checks the components has the right properties and adds the missing ones
ROOM.registerComponent = function( component, old_classname ) {

	//to know from what file does it come from
	//console.debug( document.currentScript.src );

	//allows to register several at the same time
	var name = getClassName( component );

	if (old_classname && old_classname.constructor !== String)
		throw ("old_classname must be null or a String");

	//register
	RoomComponents[ name ] = component;
	component.is_component = true;
	component.resource_type = "Component";
	component.class_type = "component";

	//Helper: checks for errors
	if ( !!component.prototype.onAdded !== !!component.prototype.onRemoved ||
		!!component.prototype.onAddedToSpace !== !!component.prototype.onRemovedFromSpace )
		console.warn("%c Component "+name+" could have a bug, check events: " + name , "font-size: 2em");
	if ( component.prototype.getResources && !component.prototype.onResourceRenamed )
		console.warn("%c Component "+name+" could have a bug, it uses resources but doesnt implement onResourceRenamed, this could lead to problems when resources are renamed.", "font-size: 1.2em");

	//add stuff to the class
	if (!component.actions)
		component.actions = {};

	if (!(component.prototype instanceof BaseComponent)) {
		// try monkey-patching
		if (Object.getPrototypeOf(component.prototype) === Object.prototype) {

			Object.setPrototypeOf(component.prototype,BaseComponent.prototype);

		} else {
			throw new Error(`All components must extend class BaseComponent, '${name}' component does not extend BaseComponent`);
		}
	}

	//event
	LEvent.trigger(ROOM, "component_registered", component );
}

//browser stuff
if ( typeof(window) !== "undefined")
{
	window.addEventListener("storage", ROOM.onCrossTabMessage );
	window.ROOM = ROOM;
}

