import { quat, vec3 } from "gl-matrix";

import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";


function SplineMeshObject()
{
	this._enabled = true;
	this._on_focus = false;

	this._preset = "Blue-Pipe";
	this._url = null;
	this._curUrl = null;

	this._native = null;
	this._dirty = false;
	this._dirtyUrl = false;

	this._meshScale = 1.0;

	this._vector = vec3.fromValues(0, 0, 0);

	if (xyz.native_mode)
		this._sm = TmrwModule.NativeRoomAPI.SplineMeshBuilder();
	else
		this._sm = null;

	this._index = SplineMeshObject.last_index++;
}

SplineMeshObject.componentName = "SplineMeshObject";
SplineMeshObject.last_index = 1;

SplineMeshObject.icon = [ 6,3 ];

SplineMeshObject.widgets = {
	"preset": {
		type: "enum", values: [ "Blue-Pipe", "Rope-Fence", "Wood-Bridge", "Luxury-Fence", "Red-Pipe", "Old-Fence", "Wire-Fence", "Metal-Fence", "Fence", "Old-Tiles", "Concrete-Barrier", "Big-Pipe", "Railroad", "Stick-Fence", "Hazard-Barrier", "Concrete-Fence" ] },
	url: { type: "asset", extensions: "glb" },
	"meshScale": { type: "slider", min: 0.01, max: 20, mark: 1 },
	"Reload": { type: "button", title: "Reload" },
}

SplineMeshObject.prototype.Reload = function () {
	if (!this._sm)
		return;
	this._sm.reload();
}


Object.defineProperty(SplineMeshObject.prototype, "enabled", {
	get: function () {
		return this._enabled;
	},
	set: function (v) {
		if (this._enabled == v)
			return;
		this._dirty = true;
		this._enabled = v;
	}
});

Object.defineProperty(SplineMeshObject.prototype, "meshScale", {
	get: function () {
		return this._meshScale;
	},
	set: function (v) {
		if (this._meshScale == v)
			return;
		this._dirty = true;
		this._meshScale = v;
	}
});

Object.defineProperty(SplineMeshObject.prototype, "preset", {
	get: function () {
		return this._preset;
	},
	set: function (v) {
		if (this._preset == v)
			return;
		this._dirty = true;
		this._preset = v;
		this._url = null;

		if (this._sm) {
			var preset = this.getUrl();
			this._meshScale = this._sm.meshScaleForPreset(this._native, preset);
		}
	}
});

Object.defineProperty(SplineMeshObject.prototype, "url", {
	get: function () {
		return this._url;
	},
	set: function (v) {
		if (v != null)
			v = v.trim();
		if (v == this._url)
			return;
		this._url = v;
		this._dirtyUrl = true;
	}
});

SplineMeshObject.prototype.getUrl = function () {
	if (this._url && this._url != "")
		return this._url;

	var splineMeshName = null;
	switch (this._preset) {
		case "Blue-Pipe":
			splineMeshName = "pipe.glb";
			break;
		case "Rope-Fence":
			splineMeshName = "rope_fence.glb";
			break;
		case "Wood-Bridge":
			splineMeshName = "long_wood_bridge.glb";
			break;
		case "Luxury-Fence":
			splineMeshName = "luxury_gate.glb";
			break;
		case "Red-Pipe":
			splineMeshName = "red_pipe.glb";
			break;
		case "Old-Fence":
			splineMeshName = "old_fence.glb";
			break;
		case "Wire-Fence":
			splineMeshName = "wire_fence.glb";
			break;
		case "Metal-Fence":
			splineMeshName = "metal_fence_1.glb";
			break;
		case "Fence":
			splineMeshName = "fence.glb";
			break;
		case "Old-Tiles":
			splineMeshName = "old_tiles_01.glb";
			break;
		case "Concrete-Barrier":
			splineMeshName = "damaged_concrete_barrier_low_poly_for_game.glb";
			break;
		case "Big-Pipe":
			splineMeshName = "bigpipe_v2_a_big_pipe.glb";
			break;
		case "Railroad":
			splineMeshName = "abandoned_railroad.glb";
			break;
		case "Stick-Fence":
			splineMeshName = "modular_stick_fence.glb";
			break;
		case "Hazard-Barrier":
			splineMeshName = "zombies_hazard_barrier.glb";
			break;
		case "Concrete-Fence":
			splineMeshName = "concrete_fence_textured.glb";
			break;
	}

	return splineMeshName;
}

SplineMeshObject.prototype.onAdded = function (parent) {
	LEvent.bind(parent, "clicked", this.onClicked, this);

	this._node = parent.node;
}

SplineMeshObject.prototype.createNative = function () {
	if (this._root && nativeEngine && !this._native) {
		if (!this._root._native) {
			// Andrey FIXME: Hack to place new spline-mesh entity in front of camera
			// Btw for some reason this camera position doesn't changed when we fly in the editor mode!
			var camera = ViewCore.instance.hard_camera;
			var cam_pos = camera.position;
			var cam_front = camera.getFront();
			var dist = 1.0;

			var offset = vec3.scale(vec3.create(), cam_front, dist);
			vec3.add(this._node.position, cam_pos, offset);
			this._node.mustUpdate = true;

			this._root.createNativeEntity(this._root.index);
		}

		var url = this.getUrl();

		if (url && this._sm && !this._native) {
			this._native = this._root._native;

			this._meshScale = this._sm.meshScaleForPreset(this._native, url);

			if (!this._url)
				url = "meshes/spline/" + url;

			// Creates native entity spline-mesh component
			this._sm.nameMesh(url);
			this._sm.builderMeshScale(this._meshScale);
			this._sm.position(this._node.position);
			this._sm.rotation(this._node.rotation);
			this._sm.build(this._native);

			this._dirty = false;

			// Create initial 3-pt spline (values are in object space)
			var cam_pos = vec3.fromValues(0, 0, 0);
			var cam_front = camera.getFront();

			var pos0 = vec3.fromValues(0, 0, 0);
			var offset = vec3.scale(vec3.create(), cam_front, 2.0);
			vec3.add(pos0, cam_pos, offset);
			pos0[0] = pos0[0] + 1.0;

			var pos1 = vec3.fromValues(0, 0, 0);
			var offset1 = vec3.scale(vec3.create(), cam_front, 6.0);
			vec3.add(pos1, cam_pos, offset1);

			var pos2 = vec3.fromValues(0, 0, 0);
			var offset1 = vec3.scale(vec3.create(), cam_front, 8.0);
			vec3.add(pos2, cam_pos, offset1);

			this._sm.createSpline(this._native, pos0, pos1);
			this._sm.addPoint(this._native, pos2, 1);

			this._sm.editable = true;
		}
	}
}

SplineMeshObject.prototype.onRenderExtraInspector = function (ctx, x, y, w, h, editor) {
	if (!this._sm)
		return;

	var sidewidth = 310;

	var numPts = this._sm.numPoints(this._native);
	if (numPts < 2)
		return;

	var line_height = 28;

	Label.call(GUI, x + 15, y, 100, 24, "Editable");
	this._sm.editable = GUI.Toggle(140, y, sidewidth - 150, 24, null, this._sm.editable);
	y += 30;

	if (!this._sm.editable)
		return;

	var selPt = this._sm.currentPoint(this._native);
	if (selPt >= 0 && numPts > 2) {
		if (Button.call(GUI, x, y, sidewidth, 24, "Delete Point")) {
			this._sm.deletePoint(this._native);
		}
		y += 30;
	}
	selPt = this._sm.currentPoint(this._native);
	numPts = this._sm.numPoints(this._native);

	if (selPt == 0 || selPt == numPts - 1) {
		if (Button.call(GUI, x, y, sidewidth, 24, "Add Point")) {
			this._sm.newPoint(this._native);
		}
		y += 30;
  }

	if (selPt >= 0) {
		if (selPt != numPts - 1) {
			if (Button.call(GUI, x, y, sidewidth, 24, "Split Next")) {
				this._sm.splitNext(this._native);
			}
			y += 30;
		}
		if (selPt != 0) {
			if (Button.call(GUI, x, y, sidewidth, 24, "Split Prev")) {
				this._sm.splitPrev(this._native);
			}
			y += 30;
		}
	}

	Label.call(GUI, x + 10, y, 100, 24, "Show Helpers");
	this._sm.drawHelpers = GUI.Toggle(140, y, sidewidth - 150, 24, null, this._sm.drawHelpers);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Show BBOX");
	this._sm.drawBoundingBox = GUI.Toggle(140, y, sidewidth - 150, 24, null, this._sm.drawBoundingBox);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Show Normals");
	this._sm.drawMeshNormals = GUI.Toggle(140, y, sidewidth - 150, 24, null, this._sm.drawMeshNormals);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Show Tangents");
	this._sm.drawMeshTangents = GUI.Toggle(140, y, sidewidth - 150, 24, null, this._sm.drawMeshTangents);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Auto Tangents");
	this._sm.automaticTangents = GUI.Toggle(140, y, sidewidth - 150, 24, null, this._sm.automaticTangents);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Norm. Tangents");
	this._sm.normalizedTangents = GUI.Toggle(140, y, sidewidth - 150, 24, null, this._sm.normalizedTangents);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Orig Normals");
	this._sm.origNormals = GUI.Toggle(140, y, sidewidth - 150, 24, null, this._sm.origNormals);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Follow Landscape");
	this._sm.followLandscape = GUI.Toggle(140, y, sidewidth - 150, 24, null, this._sm.followLandscape);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Height Offset");
	this._sm.heightOffset = GUI.SliderVal(150, y, sidewidth - 120, 24, this._sm.heightOffset, -4.0, 10.0);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Mesh Tiling");
	this._sm.meshTile = GUI.SliderVal(150, y, sidewidth - 120, 24, this._sm.meshTile, 0.01, 10.0);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Roll Angle");
	this._sm.rollAngle = GUI.SliderVal(150, y, sidewidth - 120, 24, this._sm.rollAngle, 0.0, 360.0);
	y += 30;

	Label.call(GUI, x + 10, y, 100, 24, "Mesh Scale");
	this._sm.meshScale = GUI.SliderVal(150, y, sidewidth - 120, 24, this._sm.meshScale, 0.001, 10.0);
	y += 30;

	var axis = this._sm.forwardAxis;
	Label.call(GUI, x + 10, y, 100, 24, "Forward Axis");
	var ax_types = [
		"Auto-Select",
		"X-Axis",
		"Y-Axis",
		"Z-Axis"
	];
	axis = axis + 1;
	axis = GUI.ComboLine(160, y, sidewidth - 150, 24, axis, ax_types);
	this._sm.forwardAxis = axis - 1;
	y += 30;
}

SplineMeshObject.prototype.onRemoved = function(parent)
{
	LEvent.unbind( parent,"clicked", this.onClicked, this );

	this._node = null;
	if (this._native) {
		//nativeEngine._room.destroyEntityObj(this._native);
		this._native = null;
	}
	if (this._sm)
		this._sm.delete();
	this._sm = null;
}

SplineMeshObject.prototype.serialize = function(o)
{
	o.enabled = this._enabled;
	o.url = this._url;
	o.preset = this._preset;
	o.meshScale = this._meshScale;

	if (!this._sm)
		return;

}

SplineMeshObject.prototype.configure = function(o)
{
	this._enabled = o.enabled;
	this._url = o.url;
	this._preset = o.preset;
	this._meshScale = o.meshScale;

	this._index = SplineMeshObject.last_index++;
}

SplineMeshObject.prototype.onClicked = function(e)
{
	//console.debug("clicked",this.entity.name);
	if (!this._on_focus)
		this.enterFocus();
	else //leaving
		this.exitFocus();
}


SplineMeshObject.prototype.preRender = function(view)
{
	if (!this._native) {
		if (xyz.native_json_loaded)
			this.createNative();
	}
	if (!this._native) {
		return;
	}

	var n = this._native.getRootNode();
	if (this._dirtyUrl) {
		if (this._url.includes(".glb")) {
			this._dirty = true;
			this._dirtyUrl = false;
		}
	}

	if (!vec3.equals(n.position, this._node.position) ||
		!vec3.equals(n.scaling, this._node.scaling) ||
		!quat.equals(n.rotation, this._node.rotation) ||
		this._dirty) {

		this._dirty = false;

		var url = this.getUrl();

		if (url) {

			if (!this._url)
				url = "meshes/spline/" + url;

			this._sm.url = url;
			this._sm.meshScale = this._meshScale;

			this._native.setScale(this._node.scaling);
			this._native.setPosition(this._node.position);
			this._native.setRotation(this._node.rotation);

			this._sm.update(2);
		}
	}
}


SplineMeshObject.prototype.enterFocus = function()
{
	if (this._on_focus)
		return;

	LEvent.bind( this.space, "keydown", this.onKey, this );
	LEvent.bind( this.space, "mouse", this.onGlobalMouse, this );

	//compute radius
	var original_pos = this.entity.node.getGlobalPosition();
	var bb = node.updateBoundingBox();
	var center = BBox.getCenter(bb);
	var halfsize = BBox.getHalfsize(bb);
	vec3.mul( halfsize, halfsize, node.scaling );
	var radius = vec3.length( halfsize );// / this.entity.node.scaling[0];
	console.debug(radius);
	radius *= 1.5;
}

SplineMeshObject.prototype.exitFocus = function()
{
	if (!this._on_focus)
		return;

	LEvent.unbind(this.space, "keydown", this.onKey, this);
	LEvent.unbind(this.space, "mouse", this.onGlobalMouse, this);

	this._on_focus = false;

	if (this._sm)
		this._sm.editable = false;
}

SplineMeshObject.prototype.onKey = function(type,e)
{
	if (e.key == "Escape")
	{
		this.exitFocus();
	}
	return true;
}

SplineMeshObject.prototype.onGlobalMouse = function(type,e)
{
	//console.debug(e);
	if (!this._on_focus)
		return;

	var speed = 0.02;

	if ( e.dragging )
	{
		//var node = this.entity.node;
		//var node = this._inspect_node;
		//var local_up = node.globalVectorToLocal( [0,1,0] );
		//var local_right = node.globalVectorToLocal( [1,0,0] );
		//node.rotate( e.deltax * speed, local_up );
		//node.rotate( e.deltay * speed, local_right );
	}

	if ( e.dragging && e.type == "mousemove")
		return true;
}

export default SplineMeshObject;

