import { vec3 } from "gl-matrix";
import lerp        from "lerp";

import { DEG2RAD } from "@src/constants";
import clamp       from "@src/math/clamp";
import remap       from "@src/math/remap";

export function HeadTracker( controller )
{
	HeadTracker.instance = this;

	this.enabled = false;
	this.call = controller;
	this.space = controller.space;

	//setup
	this.leaning = [ 0,0,0 ];
	this.head_tracking_pitch = 0;
	this.head_tracking_yaw = 0;
	this.leaning_threshold = this.leaning_init_threshold = 0.25;
	this.yaw_factor = 1;
	this.yaw_continuous_velocity = 1; //scalar to head_tracking_yaw
	this.yaw_continuous_threshold = 15;
	this.yaw_threshold = this.yaw_init_threshold = 10 * this.yaw_factor;
	this.pitch_threshold = this.pitch_init_threshold = 10;
	this.continuous_mode = true;

	this.last_time = getTime();
	this.lerpFactor = 1;

	//init head tracking library, only available in testing/production
	if (1)
	{
		if ( window.AIFEED)
			this.initLibrary();
		else
			console.debug("no AIFEED headtracking lib present");
	}
}

HeadTracker.prototype.toggleRoiDetection = function(v)
{
	if (!window.cutout)
		return;
	if (v == null)
		v = !window.cutout.roiDetection;
	window.cutout.roiDetection = v;
}

HeadTracker.prototype.initLibrary = function()
{
	if ( !window.AIFEED )
		return console.error("no AIFEED headtracking lib present");

	AIFEED.subscribe("roi", inner.bind(this) );

	function inner(roi)
	{
		AIFEED.publish("headtracking", { fps: 15 });
		this.updateFromROI(roi);
		if ( !this.enabled )
			return;
	}
}

// roi.head.x goes from 0 to 1.
// 0 = you're on the right; 1 = you're on the left
// on y : 0 = you're on the top, 1 = you're on the bottom
// radius: 0 you're far away, ~.3 = you're very close
HeadTracker.prototype.updateFromROI = function(roi)
{
	//compute delta time
	var now = getTime();
	var delta = Math.max(now - this._last_time, 1); // avoids diving by 0
	if ( delta < 60 )
		return;
	this._last_time = now;
	/*
		The best lerp factor depends on how many times per seconds roiCallback is triggered.
		By default it's 2 times per sec, but for testing purposes we can set it at 15x/sec.
		But we don't know what this value will be in the future, so here we use a remap function
		to define the lerpFactor based on the current time delta.
		With 2 fps, the best lerp factor is .025
		With 15 fps, the best lerp factor is .075
	*/
	var currentFps = 1000 / delta;
	var lerpFactor = this.lerpFactor = remap( currentFps, 2, 15, .025, .075);

	// normalizing data
	var newLeaning = [ 0,0,0 ];
	newLeaning[0] = (.5 - roi.head.x) * 2;
	newLeaning[1] = (.5 - roi.head.y) * 2;
	newLeaning[2] = (.15 - roi.head.r) * 1 / .15
	newLeaning[2] = clamp( newLeaning[2], -1, 1);
	newLeaning[2] *= 2;

	var dist = vec3.dist( newLeaning, this.leaning );

	if (dist > this.leaning_threshold)
	{
		vec3.lerp( this.leaning, this.leaning, newLeaning, .3);
		this.leaning_threshold = .05;
	}
	else if ( this.leaning_threshold < this.leaning_init_threshold )
	{
		this.leaning_threshold += 60 / delta * .03;
	}

	// roi.view.x > 0: I'm looking to my left
	// roi.view.x < 0: I'm looking to my right
	var new_yaw = roi.view.x * 40 * this.yaw_factor;
	if ( Math.abs(new_yaw - this.head_tracking_yaw) > this.yaw_threshold )
	{
		this.head_tracking_yaw = new_yaw;
		this.yaw_threshold = 1;
	}
	else if ( this.yaw_threshold < this.yaw_init_threshold )
	{
		this.yaw_threshold += 60 / delta * (1.1 * this.yaw_factor);
	}

	var new_pitch = roi.view.y * -40;

	if ( Math.abs( new_pitch - this.head_tracking_pitch) > this.pitch_threshold )
	{
		this.head_tracking_pitch = new_pitch;
		this.pitch_threshold = 1;
	}
	else if ( this.pitch_threshold < this.pitch_init_threshold )
	{
		this.pitch_threshold += 60 / delta * 1.1;
	}


}

//called from Call.onUpdate
HeadTracker.prototype.applyOffsets = function( participant, view, dt )
{
	if ( !this.enabled || !participant )
		return;

	if (	this.continuous_mode && this.call.camera_mode == "on_tracks" && participant.seat )
	{
		participant._internal_pitch = 0;
		participant._internal_yaw = 0;
		if ( Math.abs( this.head_tracking_yaw ) > this.yaw_continuous_threshold )
		{
			var delta = this.yaw_continuous_velocity * dt * this.head_tracking_yaw;
			view = view.hard_camera.orbit( DEG2RAD * delta, [ 0,1,0 ] );
		}
	}
	else
	{
		participant._internal_yaw = lerp( participant._internal_yaw, this.head_tracking_yaw, this.lerpFactor );
		participant._internal_pitch = lerp( participant._internal_pitch, this.head_tracking_pitch, this.lerpFactor );
	}
}

window.HeadTracker = HeadTracker;
