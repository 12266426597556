var EnumOrientation;
(function (EnumOrientation) {
    EnumOrientation[EnumOrientation["NO_ORIENTATION"] = 0] = "NO_ORIENTATION";
    EnumOrientation[EnumOrientation["USER_ORIENTATION"] = 1] = "USER_ORIENTATION";
    EnumOrientation[EnumOrientation["USER_FACING_ORIENTATION"] = 2] = "USER_FACING_ORIENTATION";
    EnumOrientation[EnumOrientation["FACING_ORIENTATION"] = 3] = "FACING_ORIENTATION";
    EnumOrientation[EnumOrientation["BILLBOARD_ORIENTATION"] = 4] = "BILLBOARD_ORIENTATION";
    EnumOrientation[EnumOrientation["SEAT_ORIENTATION"] = 5] = "SEAT_ORIENTATION";
    EnumOrientation[EnumOrientation["SEAT_FACING_ORIENTATION"] = 6] = "SEAT_FACING_ORIENTATION";
})(EnumOrientation || (EnumOrientation = {}));
export default EnumOrientation;
