import Animation from "@src/libs/rendeer/animation";
import Track from "@src/libs/rendeer/animationTrack";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import clamp from "@src/math/clamp";

//plays keyframe animations (stored inside tracks) defined in the editor
function PlayAnimation()
{
	this.enabled = true;
	this.mode = PlayAnimation.PAUSE_ANIM;
    this.current_time = 0;
    this.animation = new Animation();
}

PlayAnimation.icon = [ 5,1 ];
PlayAnimation.PAUSE_ANIM = 0; //not animated
PlayAnimation.LOOP_ANIM = 1;
PlayAnimation.ONCE_ANIM = 2;
PlayAnimation.LOOP_REVERSE_ANIM = 3;
PlayAnimation.ONCE_REVERSE_ANIM = 4;
PlayAnimation.modes_str = [ "PAUSE", "LOOP", "ONCE", "LOOP_REVERSE", "ONCE_REVERSE" ];

Object.defineProperty( PlayAnimation.prototype, "duration", {
	set: function(v) {
		this.animation.duration = v;
	},

	get: function()
	{
		return this.animation.duration;
	}
});


PlayAnimation.prototype.onAdded = function(parent)
{

}

PlayAnimation.prototype.onRemoved = function(parent)
{

}

PlayAnimation.prototype.onRenderInspector = function(ctx, x,y,w,h, editor )
{
	var starty = y;

	Label.call(GUI, x,y,100,24,"Mode");
	this.mode = GUI.ComboLine(x+120,y,w-120,24, this.mode, PlayAnimation.modes_str, "mode" );
	y += 30;

	editor.AnimBullet( x,y,20, this, "current_time");
	Label.call(GUI, x+30,y,100,24,"Current Time");
	this.current_time = Math.max(0,GUI.Number(x+150,y,w-150,24, this.current_time ));
	y += 30;

	editor.AnimBullet( x,y,20, this, "Duration");
	Label.call(GUI, x+30,y,100,24,"Duration");
	this.duration = Math.max(0,GUI.Number(x+150,y,w-150,24, this.duration ));
	y += 30;    

	if ( Button.call(GUI,x+210,y,100,24,"Edit") )
		editor.showTimeline(true, this);
}

PlayAnimation.prototype.update = function(dt,t)
{
	var duration = this.duration;
    var anim = this.animation;

	if (duration <= 0.0)
		return;

	var old_time = this.current_time;

	var delta = dt;

	if (this.mode === PlayAnimation.LOOP_REVERSE_ANIM || this.mode === PlayAnimation.ONCE_REVERSE_ANIM )
		delta = -dt;

	if (this.mode !== PlayAnimation.PAUSE_ANIM)
		this.current_time += delta;

	if (this.mode === PlayAnimation.LOOP_ANIM || this.mode === PlayAnimation.LOOP_REVERSE_ANIM)
	{
		this.current_time = this.current_time % duration;
		if ( this.current_time < 0 )
			this.current_time += duration;
	}

	var before_clamp = this.current_time;
	this.current_time = clamp( this.current_time, 0, duration );

	if (before_clamp !== this.current_time && this.mode !== PlayAnimation.LOOP_ANIM && this.mode !== PlayAnimation.LOOP_REVERSE_ANIM )
	{
		LEvent.trigger( this, "anim_finished", this );
	}

	if (anim && anim.enabled === false)
		this.current_time = 0;

	if (this._last_animation_time !== this.current_time)
	{
		this._last_animation_time = this.current_time;
        this.animation.applyAnimation( this.entity, this.current_time, RD.LINEAR );
	}
}

PlayAnimation.prototype.onAction = function( name )
{
	if(name == "play")
	{
		this.mode = PlayAnimation.ONCE_ANIM;
	}
	else if(name == "loop")
		this.mode = PlayAnimation.LOOP_ANIM;
	else if(name == "pause")
		this.mode = PlayAnimation.PAUSE_ANIM;
	else if(name == "stop")
	{
		this.mode = PlayAnimation.PAUSE_ANIM;
		this.current_time = 0;
	}
}

PlayAnimation.prototype.configure = function(o)
{
    if(o.animation)
        this.animation.configure(o.animation);
}

PlayAnimation.prototype.serialize = function(o)
{
    o.animation = this.animation.serialize();
    return o;
}

PlayAnimation.prototype.getActions = function()
{
	return [ "play","stop","pause","loop" ];
}

PlayAnimation.prototype.getEvents = function()
{
	return [ "anim_finished" ];
}


export default PlayAnimation;