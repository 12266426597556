function LoadingManager( onStart, onLoad, onProgress )
{
	this.isLoading = false;
	this.itemsLoaded = 0;
	this.itemsTotal = 0;

	this.onStart = onStart;
	this.onLoad = onLoad;
	this.onProgress = onProgress;
}

LoadingManager.prototype.itemStart = function (url)
{
	this.itemsTotal++;

	if (this.isLoading === false)
	{
		if (this.onStart)
		{
			this.onStart(url, this.itemsLoaded, this.itemsTotal);
		}
	}

	this.isLoading = true;
};

LoadingManager.prototype.itemEnd = function (url)
{
	this.itemsLoaded++;

	if (this.onProgress !== undefined)
	{
		this.onProgress(url, this.itemsLoaded, this.itemsTotal);
	}

	if (this.itemsLoaded === this.itemsTotal)
	{
		this.isLoading = false;

		if (this.onLoad !== undefined)
		{
			this.onLoad();
		}
	}
};

export default LoadingManager;
