const isMobile = {
    Android: () => /Android/i.test(navigator.userAgent),
    BlackBerry: () => /BlackBerry/i.test(navigator.userAgent),
    iOS: () => /iPad|iPhone|iPod/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream),
    iPad: () => /iPad/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream),
    Opera: () => /Opera Mini/i.test(navigator.userAgent),
    Samsung: () => /SamsungBrowser/i.test(navigator.userAgent),
    Windows: () => /Windows Phone/i.test(navigator.userAgent),
    any: () => (isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.iPad() ||
        isMobile.Opera() ||
        isMobile.Samsung() ||
        isMobile.Windows()),
};
export default isMobile;
