//this controller is to test ideas

import { mat4, vec3 }   from "gl-matrix";
import Entity           from "../engine/entity";
import ROOM             from "../engine/room";
import RoomMediaStream  from "../engine/helpers/mediaStream";


function TestController(launcher, space, view, settings )
{
	this.xyz = this.launcher = launcher;
	this.name = "test";

	this.space = space;
	this.view = view;

	TestController.instance = this;
	ROOM.test = this;
}

TestController.prototype.onEnter = function()
{
	var participant = this.space.local_participant;
}

TestController.prototype.onLeave = function()
{
	var participant = this.space.local_participant;
}

TestController.prototype.onRender = function()
{
	var participant = this.space.local_participant;
	var camera = this.view.hard_camera;

	//render scene
	this.view.render();

    var ctx = gl;
	ctx.start2D();

	if( GUI.Button(20,20,40,40,[1,1]))
		this.testNativeAPI();

    if( GUI.CircleIconButton( gl.canvas.width - 100,50, GLUI.icons.x ) )
		this.exit();
}

TestController.prototype.onUpdate = function (dt) {
    this.updateCamera( this.view.hard_camera, dt );
}

TestController.prototype.exit = function () {
	this.launcher.setControllerToPrevious();
}

TestController.prototype.onMouse = function(e)
{
	//send to UI
	var r = GUI.onMouse(e);
	if (r)
		return true;	
	var camera = this.view.hard_camera;
	if(e.dragging)
		camera.applyController(null,e);
}

TestController.prototype.onKeyDown = function(e)
{
	if ( GUI.onKey(e) )
		return;
			
	if(e.code == "Escape")
		this.exit();
}

TestController.prototype.updateCamera = function (camera, dt) {
	camera.applyController(dt,null,1,true);
}

TestController.prototype.testClock = function () {

	//create node
	var node = ROOM.RenderAPI.createNode();
	var url = "https://tamat.room.xyz/assets/users/tamat/timer.glb";
	node.loadURL(url, function(id){
		console.log("loaded",id);
		dumpInfo(node);
		ready();
	});
	window.node = node;
	var s = 1;
	node.scaling = [s,s,s];
	node.position = [-1,.62,0];
	var selected = null;

	var canvas = document.createElement("canvas");
	canvas.width = canvas.height = 512;
	var ctx = canvas.getContext("2d");
	ctx.fillStyle = "#FF00FF";
	ctx.fillRect(0,0,canvas.width,canvas.height);

	var canvas_texture = GL.Texture.fromImage(canvas);

	function dumpInfo(node,level)
	{
		level = level || 0;
		console.log("-".repeat(level) + "::" + node.name);
		var children = node.getChildren();
		for(var i = 0;i < children.length; ++i)
			dumpInfo(children[i],level+1);
	}

	function ready()
	{
		setInterval(tick,10);
	}

	function tick()
	{
		/*
		var hand = node.findNodeByName("BUDILNIK_chasovaya_0");
		if(hand)
			hand.rotate(0.04/60,[0,0,-1]);
		var hand = node.findNodeByName("BUDILNIK_minutnaya_0");
		if(hand)
			hand.rotate(0.04,[0,0,-1]);
		var body = node.findNodeByName("BUDILNIK_korpus_0");
		if(body)
		{
			var mat = body.getMaterial();
			mat.color = [1,1,Math.sin( getTime() / 1000 ) * 0.5 + 0.5];
		}
		*/
	}
}


TestController.prototype.testNativeAPI = function () {

	//create node
	var node = ROOM.RenderAPI.createNode();
	//var url = "https://tamat.room.xyz/assets/rooms/presentation/scene_room_10p_09062020.glb";
	//var url = "https://tamat.room.xyz/assets/props/timer.glb";
	var url = "https://tamat.room.xyz/assets/props/old_clock.glb";
	node.loadURL(url, function(id){
		console.log("loaded",id);
		dumpInfo(node);
		ready();
	});
	window.node = node;
	var s = 0.1;
	node.scaling = [s,s,s];
	node.position = [-1,.62,0];
	var selected = null;

	var canvas = document.createElement("canvas");
	canvas.width = canvas.height = 512;
	var ctx = canvas.getContext("2d");
	ctx.fillStyle = "#FF00FF";
	ctx.fillRect(0,0,canvas.width,canvas.height);

	var canvas_texture = GL.Texture.fromImage(canvas);

	function dumpInfo(node,level)
	{
		level = level || 0;
		console.log("-".repeat(level) + "::" + node.name);
		//var q = [0,0,0,0];
		//quat.rotateY(q,node.rotation,Math.random()*10);
		//node.rotation = q;
		var children = node.getChildren();
		for(var i = 0;i < children.length; ++i)
		{
			dumpInfo(children[i],level+1);
		}
	}

	function ready()
	{
		setInterval(tick,10);
	}

	function tick()
	{
		node.position = [Math.sin(getTime()*0.001),0,0];
		var hand = node.findNodeByName("BUDILNIK_chasovaya_0");
		if(hand)
			hand.rotate(0.04/60,[0,0,-1]);
		var hand = node.findNodeByName("BUDILNIK_minutnaya_0");
		if(hand)
			hand.rotate(0.04,[0,0,-1]);
		var body = node.findNodeByName("BUDILNIK_korpus_0");
		if(body)
		{
			var mat = body.getMaterial();
			mat.color = [1,1,Math.sin( getTime() / 1000 ) * 0.5 + 0.5];
		}
	}
}

export default TestController;

