import { getClassName } from "@src/engine/Room/getClassName";
import Button from "@src/libs/GLUI/Elements/Button";

import TimelineWidget from "./WorkPanel/TimelineWidget";

function WorkPanel( editor, space )
{
	this.editor = editor;
	this.space = space;

	this.collapsed = true;
	this.height = 300;

	this.rect = [ 0,0,100,300 ];

	this.active_tab = null;
	this.tabs = [];

	this.createTab("TimelineWidget");

}

WorkPanel.prototype.render = function(ctx)
{
	const editor = this.editor;
	if (this.collapsed)
	{
		return;
	}

	const x = this.rect[0] = editor.sidewidth;
	const y = this.rect[1] = gl.canvas.height - this.height;
	const w = this.rect[2] = gl.canvas.width - editor.sidewidth;
	const h = this.rect[3] = this.height;

	ctx.fillColor = [ 0.2,0.2,0.2,0.95 ];

	ctx.fillRect(x,y,w,h);

	// render topbar
	let ix = x + 10;
	for (let i = 0; i < this.tabs.length; ++i)
	{
		const tab = this.tabs[i];
		if ( Button.call(GUI, ix, y + 10, 100, 24, tab.name ) )
			this.active_tab = tab;
		ix += 110;
	}


	if ( 0 && Button.call(GUI, ix, y + 10, 24, 24, "+" ) )
	{
		this.createTab("TimelineWidget");
	}

	// render content
	if (this.active_tab && this.active_tab)
		this.active_tab.draw(ctx,x,y + 30,w,h - 30);
}

WorkPanel.prototype.createTab = function(widget_name)
{
	const ctor = WorkPanel.registed_widgets[widget_name];
	if (!ctor)
		return;

	const widget = new ctor(this, this.editor, this.space);
	this.tabs.push( widget );
	this.active_tab = widget;
	return widget;
}

WorkPanel.prototype.processMouse = function(e)
{
	if (this.collapsed)
		return;

	if (this.active_tab && this.active_tab.processMouse )
	{
		const r = this.active_tab.processMouse(e);
		if ( r )
			return r;
	}
	//inside
	const pos = [ e.canvasx, e.canvasy ];
	return pos[0] > this.rect[0] && pos[0] < (this.rect[0] + this.rect[2]) &&
				 pos[1] > this.rect[1] && pos[1] < (this.rect[1] + this.rect[3]);
}

WorkPanel.registed_widgets = {};

WorkPanel.registerWidget = function(class_ctor)
{
	const class_name = getClassName(class_ctor);
	WorkPanel.registed_widgets[class_name] = class_ctor;
}

WorkPanel.registerWidget(TimelineWidget);

export default WorkPanel;
