/* eslint-disable simple-import-sort/imports */
import { mat4, quat, vec3, vec4 } from "gl-matrix";
import "./controllers/call";
import "./controllers/components/actionListener";
import "./controllers/components/autoperformance";
import "./controllers/components/bubblesManager";
import "./controllers/components/fakeBridge";
import "./controllers/components/feedManager";
import "./controllers/components/headTracker";
import "./controllers/components/htmlRenderer";
import "./controllers/components/introLoader";
import "./controllers/components/sync";
import "./controllers/components/transitionToSeat";
import "./controllers/selfview";
import "./controllers/tableView";
import "./engine/apps";
import "./engine/apps/os";
// other system components
import "./engine/componentContainer";
import "./engine/components";
import "./engine/helpers/avatar";
import "./engine/helpers/inventory";
import "./engine/helpers/scene2D";
import "./engine/helpers/tween";
import "./engine/render/histogram";
import "./engine/render/view";
import "./engine/RoomSpace";
import "./libs/glui";
import "./libs/glui-extra";
import "./libs/litegl";
import "./libs/rendeer";
import "./libs/rendeer-gizmo";
import "./libs/rendeer-gltf";
import "./libs/rendeer-pbr";
// load 1st party libs
import "./libs/stream";
import "./polyfills";
import XYZLauncher from "./XYZLauncher";
// expose for native
window.mat4 = mat4;
window.vec3 = vec3;
window.vec4 = vec4;
window.quat = quat;
// publicly available fixtures
//export { default as CoreBridgeFake } from "./controllers/components/fakeBridge";
//export { default as DebugCallPanel } from "./controllers/panels/debugCallPanel";
export default XYZLauncher;
