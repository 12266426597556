import isFunction from "lodash.isfunction";
import HttpRequest from "@src/libs/LiteGL/HttpRequest";
import processFileAtlas from "@src/libs/LiteGL/File/processFileAtlas";
/**
 * allows to pack several (text)files inside one single file (useful for shaders)
 * every file must start with \filename.ext  or /filename.ext
 */
const loadFileAtlas = function (url, callback, sync = undefined) {
    let deferred_callback;
    HttpRequest(url, null, function (data) {
        const files = processFileAtlas(data);
        if (isFunction(callback)) {
            callback(files);
        }
        if (isFunction(deferred_callback)) {
            deferred_callback(files);
        }
    }, alert, sync);
    return {
        done: function (callback) {
            deferred_callback = callback;
        },
    };
};
export default loadFileAtlas;
