import { UID_PREFIX } from "@src/engine/rendeer/UID_PREFIX";
import { cloneObject } from "@src/engine/Room/cloneObject";
import { getClassName } from "@src/engine/Room/getClassName";
import { getObjectClassName } from "@src/engine/Room/getObjectClassName";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { RoomComponents } from "@src/engine/RoomComponents";

///@INFO: BASE

/*
*  Components are elements that attach to Nodes or other objects to add functionality
*  Some important components are Transform, Light or Camera
*
*	*  ctor: must accept an optional parameter with the serialized data
*	*  onAdded: triggered when added to node
*	*  onRemoved: triggered when removed from node
*	*  serialize: returns a serialized version packed in an object
*	*  configure: recieves an object to unserialize and configure this instance
*	*  getResources: adds to the object the resources to load
*	*  _root contains the node where the component is added
*
*	*  use the LEvent system to hook events to the node or the scene
*	*  never share the same component instance between two nodes
*
*/

class BaseComponent {
	_uid = undefined

	get uid() {
		return this._uid;
	}

	set uid(uid) {
		if (!uid)
			return;

		if (uid[0] !== UID_PREFIX) {
			console.warn("Invalid UID, renaming it to: " + uid);
			uid = UID_PREFIX + uid;
		}

		this._uid = uid;
	}

	/**
	 *
	 * @type {Entity}
	 * @private
	 */
	_root = undefined

	get root() {
		return this._root;
	}

	set root(v) {
		throw ("root cannot be set, call addComponent to the root");
	}

	get entity() {
		return this._root;
	}

	set entity(v) {
		throw ("entity cannot be set, call addComponent to the entity");
	}

	/**
	 * Same as `root`
	 */
	get parentNode() {
		return this._root;
	}

	set parentNode(v) {
		throw ("parentNode cannot be set, call addComponent to the parentNode");
	}

	get space() {
		return this._root ? this._root._space : null;
	}

	set space(v) {
		throw ("space cannot be set, call addComponent to the root");
	}

	get room() {
		return this._root ? this._root._space : null;
	}

	set room(v) {
		throw ("room cannot be set, call addComponent to the root");
	}

	/**
	 * This is an example class for a component, should never be instantiated by itself,
	 * instead components get all the methods from this class attached when the component is registered.
	 * Components can overwrite this methods if they want.
	 *
	 * @class  BaseComponent
	 * @namespace  LS
	 */
	constructor(o) {
		if (o)
			this.configure(o);
	}

	/**
	 * Returns the node where this components is attached
	 * @method getRootNode
	 **/
	getRootNode() {
		return this._root;
	}

	/**
	 * Configures the components based on an object that contains the serialized info
	 * @method configure
	 * @param {Object} o object with the serialized info
	 **/
	configure(o) {
		if (!o)
			return;

		if (o.uid)
			this.uid = o.uid;

		cloneObject(o, this, false, true, true);

		if (this.onConfigure)
			this.onConfigure(o);
	}

	/**
	 * Returns an object with all the info about this component in an object form
	 * @method serialize
	 * @return {Object} object with the serialized info
	 **/
	serialize(o) {
		o = o || {};
		cloneObject(this, o, false, false, true);
		if (this.uid) //special case, not enumerable
			o.uid = this.uid;
		if (!o.object_class)
			o.object_class = getObjectClassName(this);

		if (this.onSerialize)
			this.onSerialize(o);

		return o;
	}

	/**
	 * Create a clone of this node (the UID is removed to avoid collisions)
	 * @method clone
	 * @return {*} component clone
	 **/
	clone() {
		const data = this.serialize();
		data.uid = null; //remove id when cloning
		return new this.constructor(data);
	}

	/**
	 * To create a new property for this component adding some extra useful info to help the editor
	 * @method createProperty
	 * @param {String} name the name of the property as it will be accessed
	 * @param {*} value the value to assign by default to this property
	 * @param {String|Object} type [optional] an string identifying the type of the variable, could be "number","string","Texture","vec3","mat4", or an object with all the info
	 * @param {Function} setter [optional] setter function, otherwise one will be created
	 * @param {Function} getter [optional] getter function, otherwise one will be created
	 **/
	createProperty(name, value, type, setter, getter) {
		if (this[name] !== undefined)
			return; //console.warn("createProperty: this component already has a property called " + name );

		//if we have type info, we must store it in the constructor, useful for GUIs
		if (type) {
			//control errors
			if (type === "String" || type === "Number" || type === "Boolean") {
				console.warn("createProperty: Basic types must be in lowercase -> " + type);
				type = type.toLowerCase();
			}

			if (typeof (type) === "object")
				this.constructor["@" + name] = type;
			else
				this.constructor["@" + name] = { type: type };

			//is a component
			if (type === ROOM_TYPES.COMPONENT || RoomComponents[type] || type.constructor.is_component || type.type === ROOM_TYPES.COMPONENT) {
				const property_root = this; //with proto is problematic, because the getters cannot do this.set (this is the proto, not the component)
				const private_name = "_" + name;
				Object.defineProperty(property_root, name, {
					get: function () {
						if (!this[private_name])
							return null;
						// TODO: validate logic here, ROOM.GlobalScene was in place of undefined
						const scene = this._root && this._root.scene ? this._root._in_tree : undefined;
						return LSQ.get(this[private_name], null, scene);
					},
					set: function (v) {
						if (!v)
							this[private_name] = v;
						else
							this[private_name] = v.constructor === String ? v : v.uid;
					},
					enumerable: true
					//writable: false //cannot be set to true if setter/getter
				});

				if (RoomComponents[type] || type.constructor.is_component) //passing component class name or component class constructor
					type = {
						type: ROOM_TYPES.COMPONENT,
						component_class: type.constructor === String ? type : getClassName(type)
					};

				if (typeof (type) === "object")
					this.constructor["@" + name] = type;
				else
					this.constructor["@" + name] = { type: type };
				return;
			}
		}

		//basic type
		if ((value === null || value === undefined || value.constructor === Number || value.constructor === String || value.constructor === Boolean) && !setter && !getter) {
			this[name] = value;
			return;
		}

		const private_name = "_" + name;

		if (Object.hasOwnProperty(this, private_name))
			return;


		const property_root = this; //with proto is problematic, because the getters cannot do this.set (this is the proto, not the component)

		//vector type has special type with setters and getters to avoid replacing the container during assignations
		if (value && value.constructor === Float32Array) {
			value = new Float32Array(value); //clone
			this[private_name] = value; //this could be removed...

			//create setter
			Object.defineProperty(property_root, name, {
				get: getter || function () {
					return value;
				},
				set: setter || function (v) {
					value.set(v);
				},
				enumerable: true
				//writable: false //cannot be set to true if setter/getter
			});
		} else //this is for vars that has their own setter/getter
		{
			//define private (writable because it can be overwriten with different values)
			Object.defineProperty(property_root, private_name, {
				value: value,
				enumerable: false,
				writable: true
			});

			const that = this;

			//define public
			Object.defineProperty(property_root, name, {
				get: getter || function () {
					return this[private_name];
				},
				set: setter || function (v) {
					this[private_name] = v;
				},
				enumerable: true
				//writable: false //cannot be set to true if setter/getter
			});
		}
	}

//not finished
	createAction(name, callback, options) {
		if (!callback)
			console.error("action '" + name + "' with no callback associated. Remember to create the action after the callback is defined.");
		const safe_name = name.replace(/ /gi, "_"); //replace spaces
		this[safe_name] = callback;
		this.constructor["@" + safe_name] = options || {
			type: "function",
			button_text: name,
			widget: "button",
			callback: callback
		};
	}

	/**
	 * Returns the locator string of this component
	 * @method getLocator
	 * @param {string} property_name [optional] you can pass the name of a property in this component
	 * @param {Boolean} use_names [optional] if true it will use the node name instead of the node uid (easy to read but prone to collisions)
	 * @return {String} the locator string of this component
	 **/
	getLocator(property_name, use_names) {
		if (!this._root)
			return "";

		let parent_locator = null;
		if (this._root.getLocator)
			parent_locator = this._root.getLocator(null, use_names);
		else
			parent_locator = use_names ? this._root.name : this._root.uid;
		const comp_locator = use_names ? this.constructor.name : this.uid;

		if (property_name) {
			if (this[property_name] === undefined && property_name.indexOf("/") === -1)
				console.warn("No property found in this component with that name:", property_name);
			return parent_locator + "/" + comp_locator + "/" + property_name;
		}
		return parent_locator + "/" + comp_locator;
	}

	setPropertyFromPath(path, index, v) {
		if (index === path.length - 1 && this[path[index]] !== undefined)
			this[path[index]] = v;
	}

	getPropertyFromPath(path, index) {
		if (index === path.length - 1)
			return this[path[index]];
	}

	getPropertyInfoFromPath(path, index) {
		if (!path.length)
			return null;

		const varname = path[index];
		let v;

		//to know the value of a property of the given target
		if (this.getPropertyValue)
			v = this.getPropertyValue(varname);
		else if (this.getPropertyFromPath)
			v = this.getPropertyFromPath(path, index);

		//special case when the component doesnt specify any locator info but the property referenced does
		//used in TextureFX
		if (v === undefined && path.length > index + 1 && this[varname] && this[varname].getPropertyInfoFromPath) {
			const r = this[varname].getPropertyInfoFromPath(path, index + 1);
			if (r) {
				r.node = this.root;
				return r;
			}
		}

		if (v === undefined && Object.hasOwnProperty(this, varname))//this[ varname ] === undefined )
			return null;

		//if we dont have a value yet then take it directly from the object
		const value = v !== undefined ? v : this[varname];

		const extra_info = this.constructor["@" + varname];
		let type = "";
		if (extra_info)
			type = extra_info.type;
		if (!type && value !== null && value !== undefined) {
			if (value.constructor === String)
				type = "string";
			else if (value.constructor === Boolean)
				type = "boolean";
			else if (value.length)
				type = "vec" + value.length;
			else if (value.constructor === Number)
				type = "number";
		}

		return {
			node: this.root,
			target: this,
			property: varname,
			value: value,
			type: type
		};
	}

	/**
	 * returns the first component of type class_name of the SceneNode where this component belongs
	 * @method getComponent
	 * @param {String|Component} class_name the name of the class in string format or the component class itself
	 * @return {*} Component or null
	 **/
	getComponent(class_name) {
		if (!this._root)
			return null;
		return this._root.getComponent(class_name);
	}

	/**
	 * Bind one object event to a method in this component
	 * @method bind
	 * @param {*} object the dispatcher of the event you want to react to
	 * @param {String} event the name of the event to bind to
	 * @param {Function} callback the callback to call
	 * @param {String|Object} type [optional] an string identifying the type of the variable, could be "number","string","Texture","vec3","mat4", or an object with all the info
	 * @param {Function} setter [optional] setter function, otherwise one will be created
	 * @param {Function} getter [optional] getter function, otherwise one will be created
	 **/
	bind(object, method, callback) {
		const instance = this;
		if (arguments.length > 3) {
			console.error("Component.bind cannot use a fourth parameter, all callbacks will be binded to the component");
			return;
		}

		if (!object) {
			console.error("Cannot bind to null.");
			return;
		}

		if (!callback) {
			console.error("You cannot bind a method before defining it.");
			return;
		}

		/*
		var found = false;
		for(var i in this)
		{
			if(this[i] == callback)
			{
				found = true;
				break;
			}
		}
		if(!found)
			console.warn("Callback function not found in this object, this is dangerous, remember to unbind it manually or use LEvent instead.");
		*/

		//store info about which objects have events pointing to this instance
		if (!this.__targeted_instances)
			Object.defineProperty(this, "__targeted_instances", { value: [], enumerable: false, writable: true });
		const index = this.__targeted_instances.indexOf(object);
		if (index === -1)
			this.__targeted_instances.push(object);

		return LEvent.bind(object, method, callback, instance);
	}

	unbind(object, method, callback) {
		const instance = this;

		LEvent.unbind(object, method, callback, instance);

		//erase from targeted instances
		const instances = this.__targeted_instances;

		if (!instances) {
			return;
		}

		if (!LEvent.hasBindTo(object, this))
			return;

		const index = instances.indexOf(object);

		if (index === -1) {
			instances.splice(index, 1);
		}

		if (instances.length === 0) {
			delete this.__targeted_instances;
		}
	}

	unbindAll() {
		const instances = this.__targeted_instances;

		if (!instances)
			return;

		for (let i = 0; i < instances.length; ++i) {
			LEvent.unbindAll(instances[i], this);
		}

		this.__targeted_instances = null; //delete dont work??
	}

	static addNewMethod(name, func) {
		BaseComponent.prototype[name] = func;
		for (let i in RoomComponents) {
			const comp = RoomComponents[i];
			comp.prototype[name] = func;
		}
	}


	/**
	 * called by register component to add setters and getters to registered Component Classes
	 * @deprecated
	 */
	static addExtraMethods(component) {
		throw new Error("deprecated, extend the class instead")
	}
}

// Aliases
BaseComponent.prototype.on = BaseComponent.prototype.bind;
BaseComponent.prototype.off = BaseComponent.prototype.unbind;
BaseComponent.prototype.resolveLocatorFromPath = BaseComponent.prototype.getPropertyInfoFromPath;

export default BaseComponent;
