import { ActionListener } from "@src/controllers/components/actionListener";
import Seat from "@src/engine/components/seat";
import { getFullPath } from "@src/engine/Room/file-utils";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import RoomCropModes from "@src/engine/Room/RoomCropModes";
import shuffleArray from "@src/engine/Room/shuffleArray";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import TestController from "@src/controllers/testing";
import { GL } from "@src/libs/litegl";
import { vec4 } from "gl-matrix";

function DebugCallPanel( controller )
{
	this.launcher = controller.launcher;
	this.call = controller;
	this.space = controller.space;

	this.enable_debug_gui = true;
	this.show_settings_panel = false;
	this.bindNetworkActions();

	this.last_cutout_id = 0;
	this.fake_cutout_count = 10;
	this.fake_videos_path = "videos/cutouts/";
	this.fake_profile_url = "textures/fake_profile_picture.png";
}

DebugCallPanel.prototype.bindNetworkActions = function()
{
	const that = this;
	ActionListener.registerAction("fillRoomWithDemoParticipants", function(evt) {
		that.fillRoomWithDemoParticipants(evt.starting_index, evt.path, evt.count);
	});

	ActionListener.registerAction("removeAllDemoParticipants", function(evt) {
		that.removeAllDemoParticipants();
	});
}

DebugCallPanel.prototype.render = function()
{
	this.renderDebugUI();

	if ( this.show_settings_panel )
		this.renderDebugSettingsUI();
}

//stuff that appears inside the room
DebugCallPanel.prototype.renderDebugUI = function()
{
	const local_participant = this.space.local_participant;

	if (local_participant && !local_participant.is_on_hold)
		this.renderToolbar();

	const gl = GL.ctx;

	const w = gl.canvas.width;
	const h = gl.canvas.height;

	let px = w - 110;
	let py = h * 0.5 - 350;
	if (this.enable_debug_gui)
		GUI.Panel( px, py, 140, 600 );
	const buttonsize = 40;
	const buttonmargin = 50;

	const starty = py;

	px += 10;
	py += 10;

	if ( Button.call(GUI, px + (this.enable_debug_gui ? -buttonmargin : buttonmargin), py + 300, buttonsize, buttonsize,[ this.enable_debug_gui ? 5 : 4,3 ] ) )
		this.enable_debug_gui = !this.enable_debug_gui;

	if (!this.enable_debug_gui)
		return;

	if ( Button.call(GUI, px + (this.enable_debug_gui ? -buttonmargin : buttonmargin), py + 300, buttonsize, buttonsize,[ this.enable_debug_gui ? 5 : 4,3 ] ) )
		this.enable_debug_gui = !this.enable_debug_gui;

	if (!this.enable_debug_gui)
		return;

	if ( Button.call(GUI, px + buttonmargin - 5,py - buttonmargin - 5, buttonsize, buttonsize, [ 5,0 ], false,[ 0.1,0.7,0.1,0.9 ],[ 1,1,1 ],5) )
		this.show_settings_panel = !this.show_settings_panel;

	if ( Button.call(GUI, px + buttonmargin,py - buttonmargin - 55, buttonsize, buttonsize, [ 0,0 ], false,[ 0.4,0.3,0.4,0.9 ],[ 1,1,1 ],5) )
		this.testDev();


	//add single user
	if ( Button.call(GUI, px,py, buttonsize, buttonsize, [ 8,5 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
		this.addDemoParticipant(null,true);

	//toggle feed
	if ( Button.call(GUI, px + buttonmargin,py, buttonsize, buttonsize, [ 6,7 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
		this.assignFeedToLastParticipant();

	py += 50;

	//remove last participant added
	if ( Button.call(GUI, px,py, buttonsize, buttonsize, [ 9,5 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
		this.removeDemoParticipant();

	//participant tablet stream: share screen example
	if ( Button.call(GUI, px + buttonmargin,py, buttonsize, buttonsize, [ 1,7 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
	{
		//this.toggleDemoStream("/textures/room_portrait.png");
		this.toggleDemoStream("textures/tablet_desktop.png");
	}

	py += 50;

	// fill room with demo participants
	if ( Button.call(GUI, px,py, buttonsize, buttonsize, [ 6,8 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
	{
		this.triggerAction({ action: "fillRoomWithDemoParticipants", starting_index: Math.ceil(Math.random()*this.fake_cutouts_count) });
	}

	//holoh
	if ( Button.call(GUI, px + buttonmargin,py, buttonsize, buttonsize, [ 9,6 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
	{
		this.assignFeedToLastParticipant(null,"holoh");
	}


	if ( Button.call(GUI, px,py + 50, buttonsize, buttonsize, [ 7,8 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
	{
		this.triggerAction({ action: "removeAllDemoParticipants" });
	}

	if ( Button.call(GUI, px + buttonmargin,py + 50, buttonsize, buttonsize, [ 5,2 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
		this._show_debug_participant_panel = !this._show_debug_participant_panel;

	py += 50;

	py += 100;

	//GLOBAL screenshare
	const color = [ 0.8, 0.5, 0.2, 0.9 ];
	if ( Button.call(GUI, px,py, buttonsize, buttonsize, [ 1,7 ], false,color,[ 1,1,1 ],5) )
		this.toggleDemoStream("textures/desktop.png",true);

	if ( Button.call(GUI, px + buttonmargin,py, buttonsize, buttonsize, [ 4,8 ], false,color,[ 1,1,1 ],5) )
		this.toggleDemoStream("textures/room_portrait.png",true);

	py += 50;

	if ( Button.call(GUI, px,py, buttonsize, buttonsize, [ 11,7 ], false,color,[ 1,1,1 ],5) )
	{
		this.toggleDemoStream("videos/room.mp4",true);
	}

	if ( Button.call(GUI, px + buttonmargin,py, buttonsize, buttonsize, [ 4,2 ], false,color,[ 1,1,1 ],5) )
	{
		this.toggleDemoStream(null,true);
	}

	py += 50;

	if ( Button.call(GUI, px,py, buttonsize, buttonsize, [ 7,0 ], false,color,[ 1,1,1 ],5) && window.last_participant )
		window.last_participant.showAsGlobalFeed();

	if ( this.space.main_media && Button.call(GUI, px + buttonmargin,py, buttonsize, buttonsize, this.space.main_media.volume ? [ 5,7 ] : [ 4,7 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
		this.space.main_media.volume = this.space.main_media.volume ? 0 : 0.25;

	py += 50;

	if ( this.launcher.bridge && this.launcher.bridge.connect && Button.call(GUI, px,py, 80, 80, this.launcher.bridge.is_connected ? [ 13,5 ] : [ 12,5 ], false,[ 0.3,0.3,0.3,0.9 ],[ 1,1,1 ],5) )
	{
		if (!this.launcher.bridge.is_connected)
		{
			const room_name = prompt("Room name") || "";
			if (room_name !== null)
				this.launcher.bridge.connect( room_name );
		}
		else
			this.launcher.bridge.disconnect();
	}

	py += 90;

	if ( Button.call(GUI, px,py, buttonsize, buttonsize,[ 10,7 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
		this.call.takeSnapshot(2048,1024);
	if ( Button.call(GUI, px + buttonmargin,py, buttonsize, buttonsize,[ 10,7 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
		this.call.takeSnapshot(1024,2048);

	py += 50;

	if (this.dialog_callback !== this.drawSettingsDialog )
		if ( Button.call(GUI, px + buttonmargin,py, buttonsize, buttonsize,[ 5,0 ] ) )
			this.dialog_callback = this.drawSettingsDialog;

	py += 60;


	if (this.launcher.editor_controller)
		if ( Button.call(GUI, w-60,py, 50, 50,[ 0,5 ],false,[ 0.2,0.2,0.2,0.9 ],[ 1,1,1 ],6) )
			this.launcher.setController( this.launcher.editor_controller );

	py += 60;

	if (this.launcher.editor_controller2)
	{
		if ( Button.call(GUI, w-40,py, 30, 30,[ 0,5 ],false,[ 0.2,0.2,0.2,0.9 ],[ 1,1,1 ],6) )
			this.launcher.setController( this.launcher.editor_controller2 );
		py += 60;
	}

	if (this.launcher.director_controller)
	{
		if ( Button.call(GUI, w-80,py, 30, 30,[ 0,6 ],false,[ 0.2,0.2,0.2,0.9 ],[ 1,1,1 ],6) )
			this.launcher.setController( this.launcher.director_controller );
		py += 60;
	}	

	if ( this._show_debug_participant_panel )
	{
		const participant = this.selected_participant || window.last_participant;

		if (participant)
		{
			px = w - 120 - 220;
			py = starty;
			GUI.Panel( px, py, 210, 220 );
			px += 10;
			GUI.Vector( px, py, 180, 30, participant.orientation );
			py += 30;
			GUI.Vector( px, py, 180, 30, participant.position );
			py += 30;
			if ( Button.call(GUI, px,py, buttonsize, buttonsize, participant.seat ? [ 2,2 ] : [ 3,2 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
			{
				if (participant.seat)
					participant.exitSeat();
				else if ( participant.last_seat )
					participant.enterSeat( participant.last_seat );
				else
					participant.enterSeat( "seat1" );
			}
			if ( Button.call(GUI, px + buttonsize + 10,py, buttonsize, buttonsize, !participant.is_on_hold ? GLUI.icons.pause : GLUI.icons.play, false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
				participant.is_on_hold = !participant.is_on_hold;
			if ( Button.call(GUI, px + (buttonsize + 10) * 2, py, buttonsize, buttonsize, [ 0,1 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
				participant.is_founder = !participant.is_founder;
			if ( Button.call(GUI, px + (buttonsize + 10) * 3, py, buttonsize, buttonsize, [ 6,7 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) )
			{
				if (participant.feed_info)
					participant.assignFeed(null);
				else
					participant.assignFeed( getFullPath( this.fake_videos_path + "fakeperson1.webm" ) );
			}

			if ( Button.call(GUI, px + (buttonsize + 10) * 3, py + 100, buttonsize, buttonsize, [ 6,7 ], false,[ 1,0.2,0.2,0.9 ],[ 1,1,1 ],5) ) {
				this.assignFeedToLastParticipant(null, "chroma");
			}

			py += buttonsize + 10;
			const f = 0.1;
			const delta = [ 0, 0 ];
			if ( Button.call(GUI, px,py, buttonsize, buttonsize, [ 0,3 ] ) )
				delta[0] = -f;
			if ( Button.call(GUI, px + 1*(buttonsize + 5),py, buttonsize, buttonsize, [ 1,3 ] ) )
				delta[0] = f;
			if ( Button.call(GUI, px + 2*(buttonsize + 5),py, buttonsize, buttonsize, [ 2,3 ] ) )
				delta[1] = -f;
			if ( Button.call(GUI, px + 3*(buttonsize + 5),py, buttonsize, buttonsize, [ 3,3 ] ) )
				delta[1] = f;

			if (delta[0] || delta[1])
			{
				if (participant.seat)
					participant.target_yaw += delta[0] * 10;
				else
					participant.node.moveLocal([ delta[0],0,delta[1] ]);
			}

			py += buttonsize + 10;
			if ( Button.call(GUI, px,py, buttonsize, buttonsize, [ 8,9 ] ) )
				this.shuffleParticipantSeat( participant );

			//switch audio
			if ( Button.call(GUI, px + (buttonsize + 5),py, buttonsize, buttonsize, [ participant.is_audio_muted ? 2 : 3,7 ] ) )
				participant.is_audio_muted ? participant.unmute() : participant.mute();

		}
	}


	const ctx = GL.ctx;

	if (this.launcher.view.num_resources_loading)
		this.call.renderLoadingSpinner(ctx);

	if ( this.dialog_callback )
		this.dialog_callback();

	ctx.finish2D();
}

DebugCallPanel.prototype.shuffleParticipantSeat = function( participant )
{
	participant.exitSeat();
	this.enterRandomSeat( participant );
}


DebugCallPanel.prototype.toggleOnHold = function()
{
	return this.call.toggleOnHold();
}

DebugCallPanel.prototype.renderToolbar = function()
{
	const local_participant = this.space.local_participant;
	if (!local_participant)
		return;

	const gl = GL.ctx;

	const w = gl.canvas.width;
	const h = gl.canvas.height;
	const x = w * 0.5 - 115;
	const y = h - 100;
	GUI.TranslucentPanel(x,y,230,84,40,[ 0.8,0.8,0.8,1 ]);

	if ( Button.call(GUI, x + 10, y + 10, 64, 64, [ 2,7 ], false,[ 0.5,0.5,0.5,0.6 ],[ 1,1,1 ],32) )
	{
	}

	if ( Button.call(GUI, x + 80, y + 10, 64, 64, local_participant.is_on_hold ? [ 1,11 ] : [ 0,11 ], false,[ 0.5,0.5,0.5,0.6 ],[ 1,1,1 ],32) )
		this.toggleOnHold();

	if ( Button.call(GUI, x + 150, y + 10, 64, 64, [ 3,11 ], false,[ 0.5,0.5,0.5,0.6 ],[ 1,1,1 ],32) )
	{
	}
}

DebugCallPanel.prototype.renderDebugSettingsUI = function()
{
	const ctx = GL.ctx;
	let y = 0;
	const w = 300;
	const h = ctx.canvas.height;

	GUI.blockArea(0, 0, w, h );

	ctx.fillColor = [ 0.1,0.1,0.1,0.9 ];
	ctx.fillRect(0,0,w,h);

	y += 10;

	for (let i in ROOM_SETTINGS)
	{
		const section = ROOM_SETTINGS[i];
		Label.call(GUI,10,y,w,24, i.toUpperCase() );
		y += 24;
		for (let j in section)
		{
			Label.call(GUI,30,y,w-30,20, j );
			const v = section[j];

			if (v === null) {
				continue;
			}

			if ( v.constructor === Number )
			{
				section[j] = GUI.Number( 150,y,w-160,20, v );
			}
			else if ( v.constructor === Boolean )
			{
				section[j] = GUI.Toggle( 150,y,w-160,20,"", v );
			}
			else if ( v.length )
			{
				GUI.Vector( 150,y,w-160,20, v );
			}
			y += 20;
		}
		y += 10;
	}
}

DebugCallPanel.prototype.toggleDemoStream = function( url, global )
{
	if (global)
	{
		this.launcher.assignGlobalFeed( url );
		//notify to all
		xyz.notify("REMOTE_ACTION",{ action: "SCREENSHARE_URL", url: url });
	}
	else
	{
		if (!window.last_participant)
			return;
		const entity = this.launcher.getEntity("surface" + window.last_participant.seat_num);
		if (!entity)
			return;
		if (!entity.feed)
		{
			//example of assigning a video stream
			/*
			var video = document.createElement("video");
			video.src = this.space.root_path + "/videos/earth.webm";
			entity.assignFeed( video );
			*/
			entity.assignFeed( url ) ;//"/textures/tablet_desktop.png");
		}
		else
		{
			entity.assignFeed( null );
		}
	}
}

DebugCallPanel.prototype.addDemoParticipant = function(seat_entity, add_feed )
{
	const num = this.space.participants.length + 1;
	const id = "participant" + num;
	const timestamp = (new Date()) * 1; //to epoch

	//creating participant
	const participant = this.launcher.createParticipant(id, {
		nameInMeeting: "Jean Doe " + num,
		profile_picture: this.fake_profile_url,// (num % 2) == 0 ? null : "textures/fake_profile_picture.png",
		is_demo: true,
		enterTimestamp: timestamp
	});
	this.launcher.addParticipant( participant );

	//assign seat
	if ( !seat_entity )
	{
		this.enterRandomSeat( participant );
	}
	else
	{
		participant.enterSeat(seat_entity);
	}

	window.last_participant = participant;

	if ( add_feed )
		this.assignFeedToLastParticipant();
}

DebugCallPanel.prototype.enterRandomSeat = function( participant )
{
	let available_seats = this.launcher.space.getEntitiesByComponent(Seat.componentName);
	available_seats = shuffleArray( available_seats );
	let seat = null;
	for (let i = 0; i < available_seats.length; ++i)
	{
		const ent = available_seats[i];
		if ( !ent.seat || ent.seat.participant )
			continue;
		participant.enterSeat( ent.name );
		participant.seat_num = Number(ent.name.substr(4));
		seat = ent;
		break;
	}
	if (!seat)
		console.warn("no more seats available");
	return seat;
}

DebugCallPanel.prototype.fillRoomWithDemoParticipants = function( starting_index, path, count )
{
	const seats = this.space.getEntitiesOfType(ROOM_TYPES.SEAT);
	const available_seats = [];

	for (var i = 0; i < seats.length; i++)
	{
		const seat_entity = seats[i];
		const seat = seat_entity.seat;
		if ( !seat )
			return;
		const valid_seat_type = seat.seat_type === Seat.SEAT_TYPES["PARTICIPANT"] || seat.seat_type === Seat.SEAT_TYPES["HOST"];
		if ( valid_seat_type && !seat.participant )
			available_seats.push( seat_entity );
	}

	for (var i = 0; i < available_seats.length; i++)
	{
		this.addDemoParticipant(null,true);
	}
}

DebugCallPanel.prototype.removeAllDemoParticipants = function()
{
	const that = this;
	let demo_participant_found = false;
	for (let i in this.space.participants )
	{
		if ( this.space.participants[i].is_demo ) {
			demo_participant_found = true;
			that.launcher.removeParticipant( that.space.participants[i] );
			break;
		}
	}

	window.last_participant = this.space.participants[ this.space.participants.length - 1];

	if (demo_participant_found)
		this.removeAllDemoParticipants();
}

DebugCallPanel.prototype.assignFeedToLastParticipant = function( participant, mode, fake_cutout_id )
{
	if (!window.last_participant && !participant )
		return;

	participant = participant || window.last_participant;
	const feed_options = {};

	if (participant.feed_info && !mode)
	{
		participant.assignFeed( null );
		return;
	}

	if ( xyz.space.participants.length > 20)
	{
		participant.assignFeed( "data/textures/fake_profile_picture_nobg.png", feed_options );
		participant.crop_mode = RoomCropModes.GRADIENT;
		return;
	}

	//example of assigning a video stream
	const video = document.createElement("video");

	fake_cutout_id = fake_cutout_id || this.last_cutout_id + 1;
	if ( fake_cutout_id >= this.fake_cutout_count )
		fake_cutout_id = (fake_cutout_id % this.fake_cutout_count) + 1;
	this.last_cutout_id = fake_cutout_id;

	const folder_path = this.fake_videos_path;
	let video_path = folder_path + "fakeperson" + fake_cutout_id + ".webm";

	if(mode == "chroma")
	{
		video_path = "videos/fake_chroma.mp4";
	}

	if (0) //test mcu
	{
		video_path = "videos/cutouts/cutout-mcu.webm";
		feed_options.mcu = true;
		feed_options.rectangle = vec4.fromValues(0,0,0.5,0.5);
		feed_options.rectangle[0] = Math.random() > 0.5 ? 0.5 : 0;
		feed_options.rectangle[1] = Math.random() > 0.5 ? 0.5 : 0;
		feed_options.normalized = true;
	}

	if (mode === "holoh")
		video_path = "videos/fake_rgbxyz.mp4";

	video.src = getFullPath( video_path );
	video.loop = true;

	feed_options.mode = mode;
	if (mode === "holoh" )
		feed_options.holoh_info = {};

	participant.assignFeed( video, feed_options );
	participant.crop_mode = RoomCropModes.GRADIENT;
}

DebugCallPanel.prototype.removeDemoParticipant = function()
{
	if (this.space.participants.length < 2)
		return;
	const participant = this.space.participants[this.space.participants.length - 1];
	this.launcher.removeParticipant( participant );

	window.last_participant = this.launcher.space.participants[ this.launcher.space.participants.length - 1];
}

DebugCallPanel.prototype.enableWebcamToLocalParticipant = function()
{
	console.log("requesting webcam");
	var participant = this.space.local_participant;
	if(!participant)
		return;
	navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then(function(mediaStream) {
		console.log("assigning webcam");
		var video = document.createElement("video");
		video.autoplay = true;
		video.srcObject = mediaStream;
		video.play();
		participant.assignFeed( video );
	}).catch(function(err) { 
		  console.log("Cant open webcam");
		  console.error(err);
	}); // always check for errors at the end.
}

DebugCallPanel.prototype.triggerAction = function(payload)
{
	this.call.triggerAction(payload);
}

//used to test WIP features
DebugCallPanel.prototype.testDev = function()
{
	if(!ROOM.test_controller)
		ROOM.test_controller = new TestController(this.launcher, this.launcher.space, this.launcher.view, null);
	xyz.setController( ROOM.test_controller );
}

export default DebugCallPanel;
