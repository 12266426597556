class Canvas {
    static create(width = 800, height = 600) {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        return canvas;
    }
    static clone(c) {
        const canvas = document.createElement("canvas");
        canvas.width = c.width;
        canvas.height = c.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(c, 0, 0);
        return canvas;
    }
}
export default Canvas;
