import { RayGlobals } from "@src/libs/rendeer/RayGlobals";
import { vec3 } from "gl-matrix";

/**
 * it returns to which node of the array collided (even if it collided with a child)
 * if get_collision_node is true, then it will return the exact node it collided
 * @param {Ray} ray
 * @param {ISceneNode[]} nodes
 * @param {vec3} [coll]
 * @param {number} max_dist
 * @param {number} layers
 * @param {boolean} test_against_mesh
 * @param {boolean} get_collision_node
 * @returns {ISceneNode|null}
 */
export function testRayWithNodes(
	ray,
	nodes,
	coll,
	max_dist = Infinity,
	layers = 0xFFFF,
	test_against_mesh = false,
	get_collision_node = false
) {
	if (typeof max_dist !== "number") {
		throw new Error(`max_distance must be a number, instead was '${typeof max_dist}'(=${max_dist})`);
	}

	if (typeof layers !== "number") {
		throw new Error(`max_distance must be a number, instead was '${typeof layers}'(=${layers})`);
	}

	testRayWithNodes.coll_node = null; //hack to store a temp var

	RayGlobals.tested_objects = 0;

	if (!coll) {
		coll = ray.collision_point;
	}

	if (!testRayWithNodes.local_result) {
		testRayWithNodes.local_result = vec3.create();
	}

	const local_result = testRayWithNodes.local_result;

	/**
	 *
	 * @type {ISceneNode|null}
	 */
	let coll_node = null;

	//test against nodes
	for (let i = 0; i < nodes.length; ++i) {
		const node = nodes[i];

		if (node.native) {
			continue;
		}

		const child_collided = node.testRay(ray, local_result, max_dist, layers, test_against_mesh);

		if (!child_collided) {
			continue;
		}

		const distance = vec3.distance(ray.origin, local_result);

		if (distance > max_dist) {
			continue;
		}

		max_dist = distance; //adjust distance
		coll.set(local_result);

		testRayWithNodes.coll_node = child_collided;

		if (!get_collision_node) {
			//child_collided;
			coll_node = node;
		} else {
			coll_node = child_collided;
		}
	}

	return coll_node;
}