import { DEG2RAD } from "@src/constants";
import BaseComponent from "@src/engine/components/baseComponent";
import { ViewCore } from "@src/engine/render/view";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { Camera } from "@src/libs/rendeer/Camera";
import { Direction3Constants } from "@src/libs/rendeer/Direction3Constants";
import { GL } from "@src/libs/litegl";
function flat_array(a) { return a.reduce((a, b) => { a === null || a === void 0 ? void 0 : a.concat(b); }); }
class CameraViewpoint extends BaseComponent {
    constructor() {
        super(...arguments);
        this.enabled = true;
        this.camera = new Camera({ type: Camera.PERSPECTIVE, fov: 60, near: 0.1, far: 25 });
        this.fx = "";
    }
    serialize(o = {}) {
        o.enabled = this.enabled;
        o.camera = this.camera.serialize();
        o.fx = this.fx;
        return o;
    }
    configure(o) {
        this.enabled = o.enabled;
        this.camera.configure(o.camera);
        this.fx = o.fx || "";
    }
    assignFromCamera(camera) {
        this.entity.node.position = camera.position;
        this.entity.node.orientTo(camera.target);
        this.camera.fov = camera.fov;
        //var q = quat.create();
        //this.entity.rotation = q;
    }
    getActions() {
        return ["View from Here"];
    }
    onAction(v) {
        if (v == "View from Here") {
            this.viewFromHere();
        }
    }
    assignToCamera(camera) {
        camera.position = this.entity.node.position;
        camera.target = this.entity.node.localToGlobal([0, 0, -1]);
        camera.fov = this.camera.fov;
    }
    onRenderInspector(ctx, x, y, w, h, editor) {
        //use automatic interface generator
        y = editor.renderDefaultInspector(ctx, x, y, w, h, this);
        //add view
        //editor.getCustomView()
        return y;
    }
    copyFromCurrent() {
        const cam = ViewCore.instance.renderer._camera; //last rendered camera
        this.entity.node.lookAt(cam.position, cam.localToGlobal(Direction3Constants.FRONT));
    }
    viewFromHere() {
        const cam = ViewCore.instance.renderer._camera; //last rendered camera
        //this.entity.node.lookAt(cam.position, cam.localToGlobal(Direction3Constants.FRONT));
        this.assignToCamera(cam);
    }
    renderGizmo(view, editor, selected = false) {
        //if(!selected)return;
        var s = 1;
        var x = Math.tan(this.camera.fov * DEG2RAD * .5);
        var y = x;
        var z = s;
        var corners = [[-x, y, -z], [x, y, -z], [x, -y, -z], [-x, -y, -z]];
        var data = [];
        for (var i = 0; i < corners.length; ++i)
            data.push([0, 0, 0], corners[i]);
        for (var i = 0; i < corners.length; ++i)
            data.push(corners[i], corners[(i + 1) % 4]);
        var points = new Float32Array(flat_array(data));
        const model = this.entity.node.getGlobalMatrix();
        const gl = GL.ctx;
        gl.enable(gl.DEPTH_TEST);
        //draw path
        view.renderer.color = selected ? [1, 2, 3, 1] : [.3, .3, .4, 0.7];
        view.renderer.renderPoints(points, null, view._last_camera, points.length / 3, null, 0.01, gl.LINES, null, model);
        gl.disable(gl.DEPTH_TEST);
    }
    set fov(v) {
        this.camera.fov = v;
    }
    get fov() {
        return this.camera.fov;
    }
}
CameraViewpoint.componentName = "CameraViewpoint";
CameraViewpoint.icon = [0, 4];
CameraViewpoint.type = ROOM_TYPES.CAMERA;
CameraViewpoint.widgets = {
    "fov": { type: "slider", min: 30, max: 120, mark: 90 },
    "fx": { type: "string" },
    "copyFromCurrent": { type: "button", title: "Copy From Current" },
    "viewFromHere": { type: "button", title: "View from here" }
};
CameraViewpoint.high_quality_render = true;
CameraViewpoint.postfx_uniforms = null;
export default CameraViewpoint;
