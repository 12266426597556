//This APP allows to test all features
function TerminalApp(surface )
{
	this.xyz = XYZLauncher.instance;
	this.surface = surface;

	//example of local data
	this.bg_color = [ 0.1,0.1,0.1,1 ];
	this.text_color = [ 1,1,1,1 ];

	this.on_focus = false;
	this.current_command = "";
	this._last_command = "";

	//size will be assigned by Surface
	this.width = 1;
	this.height = 1;
}

TerminalApp.MAX_MESSAGES = 100;
TerminalApp.history = [];

TerminalApp.log = function(msg)
{
	if ( TerminalApp.history.length > TerminalApp.MAX_MESSAGES )
		TerminalApp.history.splice(0,1);
	TerminalApp.history.push(msg);
}

if (typeof(window) !== "undefined" )
	window.terminal = TerminalApp;

//if "canvas" it will be rendered inside a regular canvas2D and uploaded as a texture every frame, otherwise inside a WebGL texture
//TerminalApp.output_mode = "canvas";  // or null for default (webgl)

//only called if active
TerminalApp.prototype.onMouse = function(e, active)
{
}

TerminalApp.prototype.onEnter = function()
{
	this.on_focus = true;
}

TerminalApp.prototype.onLeave = function()
{
	this.on_focus = false;
}


TerminalApp.prototype.update = function(dt, t)
{
}

TerminalApp.prototype.render = function(ctx, tex, GUI, mouse, viewport )
{
	var xyz = XYZLauncher.instance;
	var w = this.width = tex.width;
	var h = this.height = tex.height;
	var that = this;

	//example of using regular Canvas calls
	ctx.fillColor = this.bg_color;
	ctx.fillRect(0,0,w,h);

	ctx.fillColor = this.text_color;
	ctx.font = "bold 30px Monospace";

	var y = viewport.y + 40;
	var num = Math.floor((viewport.height - y - 30) / 34);

	var history = TerminalApp.history;
	var start = Math.max(0,history.length - num);
	var end = Math.min( start + num, history.length )
	for (var i = start; i < end; ++i )
	{
		var line = history[i];
		ctx.fillText( line, 20, y );
		y += 34;
	}

	if ( !this.surface.active )
		return;

	ctx.globalOpacity = 0.5;
	ctx.fillRect(10,h-80,w-20,2);
	ctx.globalOpacity = 1;

	var cursor = "";
	var t = Math.floor(getTime() * 0.002);
	if ( t % 2 && this.on_focus)
		cursor = "|";

	ctx.fillText( this.current_command + cursor, 20, h-40 );
}

TerminalApp.prototype.onMouse = function(e)
{
	if (e.type === "mousedown")
		this.on_focus = true;
}

TerminalApp.prototype.onKeyDown = function(e)
{
	if (!this.on_focus)
		return;

	//console.debug("character",e.character,"key",e.key,"code",e.code);

	//console.debug(e);
	if (e.code === "ArrowUp")
		this.current_command = this._last_command;
	else if (e.code === "Escape")
		this.on_focus = false;
	else if (e.code === "ArrowDown")
		this.current_command = "";
	else if (e.code === "ArrowDown")
		this.current_command = "";
	else if (e.code === "Enter")
	{
		terminal.log( this.current_command );
		this._last_command = this.current_command;
		TerminalApp.onCommand( this.current_command );
		this.current_command = "";
	}
	else if (e.code === "Backspace")
		this.current_command = this.current_command.substr(0, this.current_command.length - 1);
	else if (e.key.length === 1) //to avoid F2 or other keystrokes throwing chars
		this.current_command += e.key;
	else
		return false;
	return true;
}

TerminalApp.onCommand = function( cmd )
{
	//??
	if (!xyz.bridge)
		return;
	xyz.bridge.notify("ON_TERMINAL_CMD", { text: cmd });
}

TerminalApp.app_name = "Terminal";

export default TerminalApp;
