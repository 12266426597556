import { mat4, vec3 } from "gl-matrix";
import lerp from "lerp";
import { DEG2RAD } from "@src/constants";
import { BubblesManager } from "@src/controllers/components/bubblesManager";
import { HeadTracker } from "@src/controllers/components/headTracker";
import { TransitionToSeat } from "@src/controllers/components/transitionToSeat";
import { RoomController } from "@src/controllers/RoomController";
import { RoomSelfView } from "@src/controllers/selfview";
import Seat from "@src/engine/components/seat";
import Surface from "@src/engine/components/surface";
import CameraViewpoint from "@src/engine/components/cameraViewpoint";
import Entity from "@src/engine/entity";
import RoomMediaStream from "@src/engine/helpers/mediaStream";
import { Tween } from "@src/engine/helpers/tween";
import { RoomParticipant } from "@src/engine/Participant/RoomParticipant";
import ROOM from "@src/engine/room";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { ROOM_FLAGS } from "@src/engine/Room/ROOM_FLAGS";
import { RoomComponents } from "@src/engine/RoomComponents";
import { RoomSpace } from "@src/engine/RoomSpace";
import EnumOrientation from "@src/EnumOrintation";
import Label from "@src/libs/GLUI/Elements/Label";
import { LEvent } from "@src/libs/LEvent";
import { GL } from "@src/libs/litegl";
import { RD } from "@src/libs/rendeer";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import clamp from "@src/math/clamp";
import remap from "@src/math/remap";
import { getTime } from "@utils/time/getTime";
import DebugCallPanel from "./panels/debugCallPanel";
import PerformancePanel from "./panels/performancePanel";
//The CONTROLLER for a ROOM session, handles user interaction with the environment
//Handles cameras, and interaction
let urlParams = {};
if (typeof window !== "undefined")
    urlParams = new URLSearchParams(window.location.search);
const RIGHT_MOUSE_BUTTON = GL.RIGHT_MOUSE_BUTTON;
const LEFT_MOUSE_BUTTON = GL.LEFT_MOUSE_BUTTON;
export class RoomCall extends RoomController {
    /**
     *
     * @param {XYZLauncher} xyz
     * @param space
     * @param view
     * @param options
     */
    constructor(xyz, space, view, options) {
        var _a, _b;
        super();
        this.enable_render_scene = true;
        this.enable_gui = true; //gui
        this.allow_debug = false;
        this.enable_debug_gui = false;
        this.enable_version = false;
        this.enable_native_menu = false;
        this.enable_native_stats = false;
        this.enable_native_profiler = false;
        this.native_profiler_stalls_threshold = 100;
        this.enable_border_nametags = false;
        this.enable_border_voice_markers = false;
        this.enable_auto_look = false;
        this.enable_outlines = true;
        this.enable_debug_performance = false;
        this.prerendered_background = false;
        this.show_bubbles = true;
        this.request_seat_change_to_backend = true;
        this.show_escape_button = false;
        this.force_camera_track = true;
        this.camera_mode = "orbit"; //"seat" or "free"
        this.camera_views = {}; //to store camera views
        this.freecam_speed = 1;
        //current active tool
        this.tool = null;
        this.mic_enabled = false;
        this.video_enabled = false;
        this.audio_enabled = true;
        this.participants_loudness = {};
        this.loudness_average = 0.3;
        this.active_speakers = {};
        this.cooldown_time = 0;
        this.gizmos_fade = 0;
        this.camview_size = 150;
        this.camview_margin = 30;
        this.sidebar_scroll = 0;
        this.sidebar_scroll_smooth = 0;
        this.initial_cam_fov = 60;
        this.target_cam_fov = 90;
        this.tween_completion = { v: 0 };
        this.tween = null;
        this.participant_idle = false;
        this.auto_look_mode = false;
        this.timeFromReady = 0;
        this.accumulated_movement = 0; //used to cancel click if dragging
        //callbacks
        this.controller_from_component = null;
        //WIP
        this.popup_message = null;
        // For Quick Misc. Stat Debugging (CUTOUT)- Gustavo
        this.debug_stats_helper = new Map();
        this.hasBeenSeated = false;
        this.performance_panel = null;
        this.debug_panel = null;
        this.dragging_camera = false;
        this._last_focus_plane_collision = null;
        /**
         * THREE.js Stats component
         * @private
         */
        this.stats = null;
        RoomCall.instance = this;
        this.xyz = this.launcher = xyz;
        this.name = "call";
        this.space = space;
        this.room = space; //legacy
        this.view = view;
        this.block_orbit_mode = options.block_orbit_mode !== undefined ? options.block_orbit_mode : true;
        this.enable_flatcall = ((_b = (_a = xyz.options) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.flat_mode) === "true";
        LEvent.bind(this.space, "seat_clicked", this.onSeatClicked, this);
        LEvent.bind(this.space, "participant_leave", this.onParticipantLeave, this);
        LEvent.bind(this.space, "BROADCAST", this.processBroadcastIN, this);
        this.selfview_controller = new RoomSelfView(this.xyz, space, view, options);
        //this.selectSeat_controller = new ROOM.SelectSeat( this.xyz, space, view, options );
        this.bubbles_manager = new BubblesManager(this.xyz, this);
        this.head_tracker = new HeadTracker(this);
        this.transition_to_seat = new TransitionToSeat(this);
        //force use of mipmaps in streams
        //if (this.xyz.native_mode)
        //	RoomMediaStream.force_mipmaps = true;
        // model importer
        //this.importer = new RoomImporter(this.xyz, space, view, options, this);
        if (this.xyz.bridge)
            this.onBridgeAvailable(this.xyz.bridge);
        if (options.params.feed_atlas) {
            throw new Error("FeedAtlas is not supported");
        }
        if (xyz.mobile) {
            Seat.allow_chair_click = false;
        }
        document.addEventListener("pointerlockchange", this.onChangePointerLockChange, false);
        this.gamepad = null;
        window.addEventListener("gamepadconnected", (e) => {
            const gamepad = e.gamepad;
            console.debug("Gamepad connected at index %d: %s. %d buttons, %d axes.", gamepad.index, gamepad.id, gamepad.buttons.length, gamepad.axes.length);
            RoomCall.instance.gamepad = navigator.getGamepads()[gamepad.index];
        });
    }
    /**
     * object's lifecycle: shutdown
     * Stops the RoomCall service and resets its values; call it when the current room is no longer needed
     */
    shutdown() {
        this.setComponentController(null);
        this.camera_mode = "orbit";
        this.hasBeenSeated = false;
    }
    onEnter() {
        const view = this.view;
        view.render_gizmos = true; //render ghosts
        this.timeFromReady = 0;
        if (view.pbrpipeline) {
            view.pbrpipeline.onRenderGizmos = null;
        }
        this.last_mousemove_time = getTime();
        LEvent.trigger(this.space, "start");
        this.space.processAction("onStart");
        const space = this.space;
        const participant = space.local_participant;
        if (participant && participant.focus_item) {
            participant.clientEvent("ON_SHOW_LIST_VIDEO_FEED", {
                isShowListVideoFeed: true,
            });
        }
        this.tick_timer = setInterval(this.onTick.bind(this), 3000);
        //avoid clicking stuff when entering by mistake
        this.cooldown_time = getTime() + 500;
    }
    onLeave() {
        const space = this.space;
        LEvent.trigger(this.space, "finish");
        space.processAction("onFinish");
        const participant = space.local_participant;
        if (participant && participant.focus_item) {
            participant.clientEvent("ON_SHOW_LIST_VIDEO_FEED", {
                isShowListVideoFeed: false,
            });
        }
        clearInterval(this.tick_timer);
        this.tick_timer = null;
        RoomComponents.gizmos_visible = true;
        ROOM.lockMouse(false);
        if (participant.focus_item)
            participant.focusOn(null);
        this.view.setDOF(false, 1);
        //this.setComponentController(null);
        if (this.view.item_hover && this.view.item_hover._native)
            this.view.item_hover._native.hover = false;
    }
    onSeatClicked(e, seat) {
        if (!this.launcher.allowSeatClick)
            return;
        const participant = this.space.local_participant;
        //cannot change seats while focused
        if (participant.focus_item)
            return;
        //already has a seat
        if (participant.seat) {
            //display popup
            this.select_seat_popup = seat;
            return;
        }
        if (!seat.participant)
            //seat here
            this.requestChangeSeat(seat);
    }
    onParticipantLeave(e, participant) {
        const space = this.space;
        const local_participant = space.local_participant;
        const focused_entity = local_participant.focus_item
            ? local_participant.focus_item.getParentEntity()
            : null;
        if (participant !== local_participant &&
            (focused_entity === participant ||
                (focused_entity &&
                    focused_entity.seat &&
                    !focused_entity.seat.participant))) {
            local_participant.focusOn(null);
            local_participant.clientEvent("ON_SHOW_LIST_VIDEO_FEED", {
                isShowListVideoFeed: false,
            });
        }
    }
    onBridgeAvailable(bridge) {
        bridge.subscribe("EVENT_ON_MUTE_UPDATE", this.processParticipantFeedChange.bind(this));
        //EventHandler.ON_CAM, { status: toggleVideoStatus });
        bridge.subscribe("USER_ON_HOLD", this.processUserOnHold.bind(this));
    }
    processParticipantFeedChange(payload) {
        console.debug("EVENT_ON_MUTE_UPDATE", payload);
        const participant = this.space.getParticipant(payload.userSessionId);
        if (participant) {
            participant.is_audio_muted = payload.audioMuted;
            participant.is_video_muted = payload.videoMuted;
            if (participant.is_local_participant) {
                //disable if user disables
                const cutout = window.cutout;
                if (cutout) {
                    cutout.roiDetection = !participant.is_video_muted;
                    cutout.segmentation = !participant.is_video_muted;
                }
            }
        }
    }
    processUserOnHold(payload) {
        this.toggleOnHold(payload.on_hold);
    }
    //called from xyz.onUpdate
    onUpdate(dt) {
        LEvent.trigger(this.space, "preUpdate", dt);
        this.last_dt = dt;
        if (this.hasBeenSeated && this.space.ready_assets)
            this.timeFromReady += dt;
        if (!this.initial_cam_fov) {
            this.initial_cam_fov = this.view.hard_camera.fov;
        }
        //fade in
        this.gizmos_fade = this.launcher.ready && this.space.ready_assets
            ? ROOM.getFadeFactor(this.launcher.ready_time + 3, 2)
            : 0;
        if (StaticMaterialsTable["ghost"])
            StaticMaterialsTable["ghost"].opacity = this.gizmos_fade;
        if (StaticMaterialsTable["ghost_spectator"])
            StaticMaterialsTable["ghost_spectator"].opacity = this.gizmos_fade;
        this.view.fade_factor -= dt;
        this.transition_to_seat.update(dt);
        if (!this.enable_flatcall) {
            this.space.update(dt);
            if (this.head_tracker.enabled)
                this.head_tracker.applyOffsets(this.space.local_participant, this.view, dt);
            //shouldnt this be on space.update??
            for (let i = 0; i < this.space.participants.length; ++i)
                this.space.participants[i].update(dt, this.space.global_time);
            //check if same controller before update to be sure we are not in selfview controller
            if (this.xyz.active_controller === this)
                this.updateCamera(dt); //update camera
            //update stuff
            LEvent.trigger(this.space, "update", dt);
            LEvent.trigger(this.space, "postUpdate", dt);
        }
        //this.syncClients(dt);
    }
    //called from XYZLauncher.onFixedUpdate()
    onFixedUpdate(dt) {
        LEvent.trigger(this.space, "preFixedUpdate", dt);
        this.space.fixedUpdate(dt, this.view.hard_camera);
        LEvent.trigger(this.space, "fixedUpdate", dt);
    }
    //called from onUpdate
    updateCamera(dt) {
        const space = this.space;
        const participant = space.local_participant;
        if (participant) {
            //used for the track camera
            if (this.controller_from_component && !participant.focus_item) {
                this.controller_from_component.updateCamera(this.view.hard_camera, this.view);
            }
            else if (this.camera_mode === "orbit") {
            }
            else if (this.camera_mode === "free") {
                const speed = this.freecam_speed * dt;
                const delta_offset = [0, 0, 0];
                const gl = GL.ctx;
                if (gl.keys["HOME"])
                    this.freecam_speed *= 1.01;
                else if (gl.keys["END"])
                    this.freecam_speed *= 0.99;
                if (gl.keys["PAGEUP"])
                    delta_offset[1] += speed;
                else if (gl.keys["PAGEDOWN"])
                    delta_offset[1] -= speed;
                if (this.gamepad && this.gamepad.axes.length >= 4) {
                    this.gamepad = navigator.getGamepads()[this.gamepad.index]; //update
                    if (Math.abs(this.gamepad.axes[0]) > 0.2) //X1:left
                        delta_offset[0] += this.gamepad.axes[0] * speed;
                    if (Math.abs(this.gamepad.axes[1]) > 0.2) //Y1:front
                        delta_offset[2] += this.gamepad.axes[1] * speed;
                    if (Math.abs(this.gamepad.axes[2]) > 0.2) //X2:yaw
                        this.view.hard_camera.rotate(this.gamepad.axes[2] * -speed, [0, 1, 0]);
                    if (Math.abs(this.gamepad.axes[3]) > 0.2) //X2:pitch
                        this.view.hard_camera.rotateLocal(this.gamepad.axes[3] * -speed, [1, 0, 0]);
                    if (this.gamepad.buttons[4].pressed)
                        delta_offset[1] += speed;
                    if (this.gamepad.buttons[5].pressed)
                        delta_offset[1] -= speed;
                    if (this.gamepad.buttons[3].pressed)
                        this.freecam_speed *= 1.01;
                    else if (this.gamepad.buttons[1].pressed)
                        this.freecam_speed *= 0.99;
                    this.view.hard_camera.fov += (this.gamepad.buttons[6].value - this.gamepad.buttons[7].value) * dt * 10;
                }
                if (vec3.length(delta_offset)) {
                    this.view.hard_camera.getLocalVector(delta_offset, delta_offset);
                    vec3.add(this.view.hard_camera.position, this.view.hard_camera.position, delta_offset);
                    vec3.add(this.view.hard_camera.target, this.view.hard_camera.target, delta_offset);
                    this.view.hard_camera.updateMatrices();
                }
                this.view.hard_camera.applyController(speed * (gl.keys["SHIFT"] ? 3 : 1), null, 1, true);
            }
            else { //regular camera
                if (participant.seat)
                    this.leanMovement();
                else
                    this.userMovement(dt);
                if (space.ready)
                    participant.updateCameraView(this.view.hard_camera);
                else {
                    this.view.hard_camera.fov = this.view.camera.fov =
                        space.camera_info.fov;
                    this.view.camera.lookAt(space.camera_info.position, space.camera_info.target, [0, 1, 0]);
                    this.view.hard_camera.lookAt(space.camera_info.position, space.camera_info.target, [0, 1, 0]);
                }
            }
        }
        if (this.enable_auto_look)
            this.updateAutoLook();
        LEvent.trigger(this.space, "cameraUpdate", this.view.hard_camera);
    }
    updateAutoLook() {
        if (this.participant_idle) {
            this.auto_look_mode = true;
            this.tween = Tween.easeProperty(this.tween_completion, "v", 1, 0.3, Tween.EASE_IN_OUT, () => {
                this.tween = null;
                this.tween_completion.v = 0;
            }, () => {
                this.view.hard_camera.fov = lerp(this.initial_cam_fov, this.target_cam_fov, this.tween_completion.v);
            });
        }
        else {
            if (this.view.hard_camera.fov !== this.initial_cam_fov &&
                this.auto_look_mode === true) {
                this.view.camera_smooth_factor = 0.15;
                this.auto_look_mode = false;
                this.tween = Tween.easeProperty(this.tween_completion, "v", 1, 0.3, Tween.EASE_IN_OUT, () => {
                    this.tween = null;
                    this.tween_completion.v = 0;
                    this.auto_look_mode = false;
                }, () => {
                    this.view.hard_camera.fov = lerp(this.target_cam_fov, this.initial_cam_fov, this.tween_completion.v);
                });
            }
            else {
                if (this.tween) {
                    this.tween_completion = { v: 0 };
                    this.tween.cancel();
                    this.tween = null;
                    this.initial_cam_fov = 60;
                    this.target_cam_fov = 90;
                }
            }
        }
    }
    onTick() {
        this.updateSimCast();
    }
    checkParticipantsAudio() {
        const registeredVolumes = Object.keys(this.participants_loudness).length;
        for (let i = 0; i < this.space.participants.length; ++i) {
            var participant = this.space.participants[i];
            const loudness = participant.smooth_loudness - 0.1;
            if (loudness > this.loudness_average * 0.5) {
                this.participants_loudness[participant.id] = loudness;
                if (i > 0) {
                    this.renderVoiceMarkers(participant);
                }
                if (this.enable_auto_look) {
                    if (this.participant_idle === true && i > 0) {
                        // skip yourself
                        this.onParticipantSpeaking(participant);
                    }
                }
            }
            else {
                delete this.participants_loudness[participant.id];
            }
        }
        if (registeredVolumes > 1) {
            let volume = 0.0;
            for (let j = 0; j < registeredVolumes; j++) {
                volume += this.participants_loudness[participant.id];
            }
            this.loudness_average = volume / registeredVolumes;
            if (isNaN(this.loudness_average))
                this.loudness_average = 0.0;
        }
    }
    onParticipantSpeaking(participant) {
        const local_participant = this.space.local_participant;
        const camera = this.view.hard_camera;
        const hasNoSeat = !local_participant || !local_participant.seat;
        if (hasNoSeat) {
            return;
        }
        if (local_participant === participant) {
            if (local_participant.seat) {
                this.launcher.setController(this.selfview_controller);
            }
        }
        else {
            if (local_participant.focus_item !== participant._profile_node &&
                participant.seat) {
                this.view.camera_smooth_factor = 0.007;
                local_participant.lookAt(participant);
            }
        }
    }
    //called from updateCamera
    userMovement(dt) {
        const participant = this.controlled_participant || this.space.local_participant;
        if (!participant)
            // || !participant.seat )  //not seated
            return;
        vec3.set(participant.leaning_offset, 0, 0, 0);
        const delta = [0, 0, 0];
        let walking_speed = 1;
        const gl = GL.ctx;
        if (gl.keys["SHIFT"])
            walking_speed *= 2.5;
        // Moving
        if (gl.keys["UP"] || gl.keys[87]) {
            //W
            delta[2] = -1 * walking_speed;
        }
        else if (gl.keys["DOWN"] || gl.keys[83]) {
            //S
            delta[2] = walking_speed;
        }
        if (gl.keys["LEFT"] || gl.keys[65]) {
            //A
            delta[0] = -1 * walking_speed;
        }
        else if (gl.keys["RIGHT"] || gl.keys[68]) {
            //D
            delta[0] = walking_speed;
        }
        vec3.scale(delta, delta, dt * 2);
        //not moving
        if (vec3.sqrLen(delta) === 0)
            return;
        participant.node.translate(delta, true);
        vec3.copy(participant.target_position, participant.node.position);
        participant.interpolate_state = false;
        //reset fov to walking fov
        const camera = this.view.hard_camera;
        camera.fov = ROOM_SETTINGS.participants.walking_fov;
    }
    leanMovement() {
        const participant = this.controlled_participant || this.space.local_participant;
        if (!participant || !participant.seat)
            //not seated
            return;
        if (this.controller_from_component)
            return;
        const delta = participant.leaning_offset;
        vec3.set(delta, 0, 0, 0);
        if (ROOM_SETTINGS.participants.auto_lean_back)
            delta[2] = this.autoLeanBack();
        const gl = GL.ctx;
        if (gl.keys["UP"] || gl.keys[87])
            //W
            delta[1] = 1;
        else if (gl.keys["DOWN"] || gl.keys[83])
            //S
            delta[2] = 1;
        if (gl.keys["LEFT"] || gl.keys[65])
            //A
            delta[0] = -1;
        else if (gl.keys["RIGHT"] || gl.keys[68])
            //D
            delta[0] = 1;
        vec3.scale(delta, delta, 0.3);
    }
    autoLeanBack() {
        const participant = this.controlled_participant || this.space.local_participant;
        const current_yaw = participant.yaw % 360;
        let relative_yaw = (current_yaw - participant.init_yaw) % 360;
        // maps relative_yaw between +180 (left) and -180 (right)
        if (relative_yaw > 180)
            relative_yaw = -360 + relative_yaw;
        else if (relative_yaw < -180)
            relative_yaw = 360 + relative_yaw;
        let auto_lean_ratio = 0;
        // right
        if (relative_yaw < 0)
            auto_lean_ratio = remap(relative_yaw, 0, -180, 0, 1);
        // left
        else if (relative_yaw > 0)
            auto_lean_ratio = remap(relative_yaw, 0, 180, 0, 1);
        // keeps value between 0 and 1, with a peak at 50%
        if (auto_lean_ratio)
            auto_lean_ratio =
                0.5 + Math.cos(Math.PI + auto_lean_ratio * 2 * Math.PI) / 2;
        return lerp(0, 1, auto_lean_ratio);
    }
    //renders one frame
    onRender() {
        const participant = this.space.local_participant;
        if (this.launcher.params && this.launcher.params.flatcall === "true") {
            const gl = GL.ctx;
            gl.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
            this.enable_flatcall = true;
        }
        ROOM.inactive_opacity = clamp(5 - 0.5 * (getTime() - this.last_mousemove_time) * 0.001, 0, 1);
        Seat.gizmos_visible = participant && !participant.seat;
        if (this.camera_mode === "free")
            participant.force_self_render_one_frame = true;
        //if(this.camera_mode == "seat") //?????
        //is in focus
        this.view.blur_background = false;
        if (participant && participant.focus_item) {
            const ent = participant.focus_item.getParentEntity();
            if (ent && ent.surface)
                this.view.blur_background = true;
            if (participant.focus_item.room_entity)
                RoomParticipant.global_opacity = RoomParticipant.global_opacity * 0.9;
            else
                RoomParticipant.global_opacity +=
                    (1 - RoomParticipant.global_opacity) * 0.1;
        }
        else
            RoomParticipant.global_opacity +=
                (1 - RoomParticipant.global_opacity) * 0.1;
        //special case to precompute background image and blur it
        if (this.prerendered_background) {
            this.view.use_prerendered_cubemap = true;
            if (!this.view._cubemap)
                this.view.updateCubemap();
            this.hide_prefabs = true;
        }
        else {
            this.view.use_prerendered_cubemap = false;
            this.hide_prefabs = false;
        }
        this.onPreRender();
        if (participant && participant.tablet)
            //this.camera_mode == "seat"
            this.updatePersonalTablet();
        const is_on_hold = participant && participant.is_on_hold;
        const gl = GL.ctx;
        //**** RENDER SCENE *******************
        if (!this.enable_flatcall && !is_on_hold) {
            //ROOM.Surface.block_apps = false;
            if (this.enable_render_scene) {
                this.preRender(this.view.camera);
                this.view.render();
            }
            else {
                gl.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
            }
            LEvent.trigger(this.space, "renderCallGizmos", this.view);
        } //REUSE PREV FRAME to save resources
        else {
            //ROOM.Surface.block_apps = true;
            this.view.frame++;
            this.view.resizeBuffer();
            this.view.preRender(this.view.camera); //why?
            this.view.renderLastFrame(true);
        }
        //***********************************
        //in case something requires special user interaction
        if (this.tool && this.tool.onRender)
            this.tool.onRender(this.space, this.view);
        //mouse was hovering some entity or participant
        if (this.enable_outlines &&
            this.view.item_hover &&
            !participant.focus_item &&
            !this.enable_flatcall &&
            !is_on_hold) {
            let highlight_node = null;
            if (this.view.node_hover)
                highlight_node = this.view.node_hover;
            if (highlight_node) {
                //participants and non interactive surfaces have are a special case
                const entity = highlight_node.getParentEntity();
                if (entity) {
                    if (entity instanceof RoomParticipant ||
                        (entity.surface && !entity.surface.interactive)) {
                        entity.mouse_hover = true;
                        highlight_node = null;
                    }
                }
            }
            if (highlight_node && !highlight_node._skip_outline && !participant.focus_item) {
                highlight_node.visible = true;
                const blinking_alpha = Math.pow(Math.sin(getTime() * 0.0075) * 0.5 + 0.5, 1.5) * this.gizmos_fade;
                //this wont do anything in native
                this.view.renderOutline(this.view.renderer, this.view.scene, this.view.camera, [highlight_node], [1, 1, 1, blinking_alpha]);
            }
        }
        //render 2D info
        const ctx = gl;
        ctx.start2D();
        //render crosshair: when mouse locked
        if (document.pointerLockElement === gl.canvas && this.camera_mode !== "free" && !is_on_hold) {
            ctx.fillColor = [1, 1, 1, 1];
            ctx.fillRect(gl.canvas.width * 0.5 - 2, gl.canvas.height * 0.5 - 2, 4, 4);
        }
        //render markers about who is speaking in 3D
        if (this.enable_border_voice_markers &&
            participant &&
            !participant.focus_item)
            this.checkParticipantsAudio();
        if (this.enable_version)
            this.renderVersion();
        //render nametags on corner
        if (this.enable_border_nametags && (!participant || !participant.focus_item))
            this.renderBorderNameTags();
        if (is_on_hold)
            this.renderOnHoldUI();
        if (this.enable_gui) {
            this.renderUI(); //this renders the bubbles
            if (this.enable_debug_gui)
                this.renderDebugUI();
            if (this.enable_debug_performance) {
                if (!this.performance_panel)
                    this.performance_panel = new PerformancePanel(this);
                this.enable_debug_performance = this.performance_panel.render();
            }
        }
        if (this.tool && this.tool.onRenderGUI)
            this.tool.onRenderGUI(this.space, this.view, ctx);
        if (this.onPostRender)
            this.onPostRender();
        //debug
        //this.view.getTranslucentTexture().toViewport();
        //GUI.TranslucentPanel(100,100,400,200,20,[0.8,0.8,0.8,1]);
    }
    /**
     * @deprecated do not use in production, mainly because it uses a very slow "Canvas2DtoWebGL" under the hood
     */
    renderVersion() {
        const gl = GL.ctx;
        const ctx = gl;
        let num_rcs = 0;
        let res = gl.canvas.width + "x" + gl.canvas.height;
        const pbr_pipeline = this.view.pbrpipeline;
        if (pbr_pipeline) {
            num_rcs = pbr_pipeline.rendered_render_calls;
            res = pbr_pipeline.final_fbo ? pbr_pipeline.final_fbo.width + "x" + pbr_pipeline.final_fbo.height : gl.canvas.width + "x" + gl.canvas.height;
            this.frame_time = this.view.frame % 10 === 0 ? pbr_pipeline.frame_time : this.frame_time;
            pbr_pipeline.rendered_render_calls = 0;
        }
        const ft = this.frame_time || 0;
        let str = ROOM.version_str + " " + ROOM.build_timestamp;
        str += " FPS: " + this.view.fps;
        if (num_rcs)
            str += " (" + num_rcs + " RCs)";
        str += " Res:[" + res + "]";
        str += " Ping: " + this.space.ping;
        str += " (FT: " + ft.toFixed(1) + "ms)";
        ctx.font = "14px " + this.xyz.options.fontFamily;
        ctx.textAlign = "left";
        ctx.fillStyle = "rgba(0,0,0,0.3)";
        if (this.xyz.native_mode)
            ctx.fillRect(0, 0, 550, 70 + this.debug_stats_helper.size * 20);
        else
            ctx.fillRect(0, 0, 550, 50 + this.debug_stats_helper.size * 20);
        ctx.fillStyle = "white";
        ctx.fillText(str, 10, 20);
        ctx.fillText("ROOM (" +
            this.space.timestamp +
            ")* " +
            this.space.folder +
            "/" +
            this.space.filename, 10, 40);
        if (this.xyz.native_mode) {
            const nativeEngine = window.nativeEngine;
            if (nativeEngine && nativeEngine._engine) {
                if (this.enable_native_menu != this.enabled_native_menu) {
                    this.enabled_native_menu = this.enable_native_menu;
                    nativeEngine._engine.setNativeMenu(this.enable_native_menu);
                }
                const engVers = nativeEngine._engine.getVersion();
                const engFps = nativeEngine._engine.getEngineFPS();
                const engGpu = nativeEngine._engine.getGPUTime();
                ctx.fillText("Native: (Engine Vers: " +
                    engVers +
                    ", Engine FPS: " +
                    engFps.toFixed(1) +
                    ", GPU Time: " +
                    engGpu.toFixed(1) +
                    "ms)", 10, 60);
            }
        }
        let i = 0;
        for (const stat of this.debug_stats_helper.entries()) {
            ctx.fillText(stat[0] + ": " +
                stat[1], 10, 60 + i * 20);
            i++;
        }
        RoomMediaStream.images_uploaded_to_gpu = 0;
    }
    renderOnHoldUI() {
        const gl = GL.ctx;
        const w = gl.canvas.width;
        const h = gl.canvas.height;
        const circleIconButton = GUI.CircleIconButton(w * 0.5 - 64, h * 0.5 - 64, [1, 11], null, null, 128);
        if (circleIconButton) {
            this.toggleOnHold();
        }
    }
    renderDebugUI() {
        if (!this.debug_panel)
            this.debug_panel = new DebugCallPanel(this);
        this.debug_panel.render();
    }
    //called from here, as a clean way to only call prerender when rendering the scene
    preRender(camera) {
        const participant = this.space.local_participant;
        let focus_parent_entity = null;
        if (participant && participant.focus_item)
            focus_parent_entity = participant.focus_item.getParentEntity();
        //setup focus stuff
        if (focus_parent_entity) {
            //&& focus_entity.seat && focus_entity.seat.participant )
            const focused_entity = participant.focus_item;
            let focus_point;
            //focused on a seat that has a participant
            if (focus_parent_entity &&
                focus_parent_entity.seat &&
                focus_parent_entity.seat.participant) {
                focus_point = focus_parent_entity.seat.participant
                    .getProfileFocusNode()
                    .getGlobalPosition();
            }
            else {
                focus_point = focused_entity.getGlobalPosition();
            }
            //remove focus when person stands up
            if (focus_parent_entity &&
                focus_parent_entity.seat &&
                !focus_parent_entity.seat.participant) {
                participant.focusOn(null);
            }
            //remove outline if focus (on native)
            if (participant && participant.focus_item &&
                this.view.item_hover && this.view.item_hover._native)
                this.view.item_hover._native.hover = false;
            //compute focal distance
            const focal_distance = vec3.distance(camera.position, focus_point);
            //enable DOF
            if (ROOM_SETTINGS.call.DOF.allow)
                this.view.setDOF(true, focal_distance * 1.02);
            else {
                this.view.setDOF(false, 1);
            }
        }
    }
    onPostRender() {
        if (this.view.canvas.style.cursor === "none") {
            // wrong cursor, skip
            return;
        }
        if (!this.view.item_hover) {
            // no hover effect (?)
            return;
        }
        const participant = this.space.local_participant;
        const focused_entity = participant.focus_item && participant.focus_item.room_entity;
        const type = focused_entity && focused_entity.type;
        let is_app = false;
        if (type === ROOM_TYPES.SURFACE) {
            const surface = focused_entity.getComponent("Surface");
            if (surface.os_app && surface.os_app._app) {
                is_app = true;
            }
            else
                ROOM.cursor_style = "";
        }
        if (!is_app) {
            ROOM.cursor_style = "pointer";
        }
    }
    //UI for some special situations
    renderUI() {
        const local_participant = this.space.local_participant;
        const in_focus_mode = Boolean(local_participant && local_participant.focus_item);
        this.participant_idle = in_focus_mode
            ? false
            : ROOM.inactive_opacity <= 0;
        const fade_alpha = in_focus_mode ? 1 : ROOM.inactive_opacity;
        const is_on_hold = local_participant && local_participant.is_on_hold;
        const gl = GL.ctx;
        const ctx = gl;
        ctx.start2D();
        const w = gl.canvas.width;
        //exit seat
        if (this.show_escape_button) {
            if (GUI.CircleIconButton(w - 260, 20, GLUI.icons.x)) {
                this.escapeAction();
                this.cooldown_time = getTime() + 500;
            }
        }
        LEvent.trigger(this.space, "renderUI");
        if (local_participant && fade_alpha > 0) {
            //button to exit focus mode
            if (in_focus_mode) {
                const entity = local_participant.focus_item.getParentEntity();
                if (entity && entity.surface) {
                    // This logic is added to hide close button on tutorial mode untill the Whiteboard is clicked
                    const didWhiteBoardTutorialStart = entity.surface.didWhiteBoardTutorialStart;
                    if (this.launcher.is_in_tutorial_flow && typeof didWhiteBoardTutorialStart !== "undefined" && !didWhiteBoardTutorialStart) {
                        return;
                    }
                    //focus on a surface
                    const pos2D = entity.localToScreen([0.5, 0.5, 0], this.view.camera);
                    if (pos2D[2] <= 1) {
                        if (GUI.CircleIconButton(clamp(pos2D[0], 30, gl.canvas.width - 70), clamp(pos2D[1], 30, gl.canvas.height - 30), GLUI.icons.x, fade_alpha)) {
                            local_participant.focusOn(null);
                            this.cooldown_time = getTime() + 500;
                        }
                    }
                }
            }
        }
        if (this.show_bubbles) {
            this.bubbles_manager.blur_all = is_on_hold;
            if (gl.mouse.buttons)
                this.bubbles_manager.freeze_next_frame = true;
            this.bubbles_manager.render(1.0, this.enable_flatcall ? BubblesManager.FLAT_MODE : null);
        }
        if (this.popup_message)
            this.renderPopupMessages();
        if (this.select_seat_popup && local_participant && local_participant.seat) {
            local_participant.lookAt(this.select_seat_popup.entity.node.localToGlobal([0, 0.5, 0]));
            this.requestChangeSeat(this.select_seat_popup);
            this.select_seat_popup = null;
        }
    }
    //sends message to backend to change seat
    requestChangeSeat(seat) {
        const local_participant = this.space.local_participant;
        if (!local_participant)
            return;
        const seat_index = seat ? seat.getSeatId() : 0;
        //change seat directly
        if (this.launcher.bridge && this.request_seat_change_to_backend) {
            //send message to server
            this.launcher.bridge.notify("CHANGE_SEAT_REQ", { newSeat: seat_index });
        }
        else {
            //change directly
            if (seat_index !== 0)
                local_participant.enterSeat("seat" + seat_index);
            else
                local_participant.exitSeat();
            return;
        }
    }
    renderBorderNameTags() {
        const local_participant = this.space.local_participant;
        const camera = this.view.camera;
        const campos = camera.position;
        //compute distance to cam
        const people_by_distance = this.space.participants.concat();
        people_by_distance.forEach(function (p) {
            p._distance_to_camera = vec3.distance(campos, p.position);
        });
        //sort by distance
        people_by_distance.sort(function (a, b) {
            return b._distance_to_camera - a._distance_to_camera;
        });
        for (let i = 0; i < people_by_distance.length; ++i) {
            const p = people_by_distance[i];
            if (p !== local_participant)
                p.updateTopNameTag(camera);
        }
    }
    renderPopupMessages() {
        const gl = GL.ctx;
        if (!this.popup_message)
            return;
        const w = gl.canvas.width;
        const pw = gl.canvas.width * 0.5;
        const y = gl.canvas.height - 160;
        const ctx = gl;
        ctx.fillColor = [0, 0, 0, 0.3];
        ctx.beginPath();
        ctx.roundRect((w - pw) * 0.5, y, pw, 60, 10);
        ctx.fill();
        Label.call(GUI, (w - pw) * 0.5 + 20, y + 20, pw - 40, 40, this.popup_message.text);
        if (this.popup_message.time < getTime() * 0.001)
            this.popup_message = null;
    }
    renderVoiceMarkers(participant) {
        const gl = GL.ctx;
        const ctx = gl;
        const margin = 0;
        const out_margin = 64;
        const canvas_width = gl.canvas.width;
        const marker_offset = 0;
        const view = this.view;
        const alpha = clamp(participant.smooth_loudness - 0.1, 0, 1); // * Math.sin( getTime() * 0.005 ) * 0.5 + 0.5;
        if (alpha <= 0) {
            // fully transparent
            return;
        }
        const screen_position = participant.screen_position;
        const is_outside = screen_position[0] < -out_margin ||
            screen_position[0] > canvas_width + out_margin;
        if (!is_outside) {
            // off-screen
            return;
        }
        const is_left_side = screen_position[0] < canvas_width * 0.5;
        const pos = [screen_position[0], screen_position[1]];
        pos[0] = clamp(pos[0], margin, canvas_width - margin);
        pos[1] = clamp(pos[1], margin, gl.canvas.height - margin);
        pos[0] += is_left_side ? 64 : -64;
        pos[1] += marker_offset;
        //show aura
        ctx.fillColor = [0.5, 0.6, 1, 1];
        gl.tintImages = true;
        const texture = view.loadTexture(is_left_side
            ? "textures/sound-aura-marker.png"
            : "textures/sound-aura-marker_right.png", { wrap: gl.CLAMP_TO_EDGE });
        view.drawTexture2D(texture, pos, [128, 256], alpha);
        gl.tintImages = false;
        //render circular marker
        const marker_texture = view.loadTexture(is_left_side
            ? "textures/border-marker-left.png"
            : "textures/border-marker-right.png");
        let profile_texture = null;
        if (participant.feed) {
            profile_texture = participant.feed.getTexture();
        }
        if (profile_texture) {
            ctx.save();
            ctx.globalAlpha = 1.0;
            ctx.fillColor = [1, 1, 1, 1];
            ctx.beginPath();
            ctx.arc(pos[0], pos[1], 34, 0, Math.PI * 2);
            ctx.clip();
            const aspect = profile_texture.width / profile_texture.height;
            view.drawTexture2D(profile_texture, pos, [64 * aspect, 64], alpha);
            ctx.restore();
            view.drawTexture2D(marker_texture, pos, [128, 128], alpha);
        }
    }
    renderLoadingSpinner(ctx) {
        const gl = GL.ctx;
        const x = gl.canvas.width - 128;
        const y = gl.canvas.height - 128;
        const texture = this.view.loadTexture("textures/spinner.png");
        ctx.save();
        ctx.translate(x + 64, y + 64);
        ctx.rotate(getTime() * 0.006);
        ctx.drawImage(texture, -64, -64, 128, 128);
        ctx.restore();
    }
    drawSettingsDialog() {
        const gl = GL.ctx;
        const w = 400;
        const h = 200;
        const canvas = gl.canvas;
        const x = canvas.width - 480;
        let y = canvas.height - 210;
        GUI.blockArea(x, y, w, h);
        if (GUI.Panel(x, y, w, h, "Settings", true) === false) {
            this.dialog_callback = null;
            return;
        }
        y += 60;
        Label.call(GUI, x + 20, y, 100, 30, "Quality");
        const view = this.view;
        view.render_quality =
            GUI.Slider(x + 140, y, w - 150, 30, view.render_quality, 1, 3) | 0;
        y += 34;
        Label.call(GUI, x + 20, y, 100, 30, "Resolution");
        view.resolution_factor = GUI.Slider(x + 140, y, w - 150, 30, view.resolution_factor, 0.2, 1, 0.2);
        y += 34;
        Label.call(GUI, x + 20, y, 100, 30, "Prerendered");
        this.prerendered_background = GUI.Toggle(x + 140, y, w - 150, 30, null, this.prerendered_background);
    }
    //called fron onMouse when it was a fast click
    //item could be an entity or a participant
    processItemClicked(item, node, e) {
        //in case a tool wants to intercept
        const tool = this.tool;
        if (tool && tool.onNodeClicked)
            tool.onNodeClicked(this.space, this.view, GL.ctx);
        const local_participant = this.space.local_participant;
        if (!local_participant) {
            return;
        }
        //others
        if (this.onNodeClicked(node, item, e) === true) {
            return;
        }
        if (node) {
            // Check whether we are in tutorial flow so that clicks on items can be processed differently
            if (this.launcher.is_in_tutorial_flow) {
                //Throw a event when click is made on whiteboard and return without processing
                if (node.name.includes("tv")) {
                    this.xyz.notify("TUTORIAL_WHITEBOARD_START_CLICK", {});
                    return;
                }
            }
            if (LEvent.trigger(node, "click", e) === true) {
                return;
            }
            if (LEvent.trigger(this.space, "node_clicked", e) === true) {
                return;
            }
            if (node !== this.view.subnode_hover && this.view.subnode_hover) {
                if (LEvent.trigger(this.view.subnode_hover, "click", e) === true) {
                    return;
                }
            }
        }
        if (!item) {
            const focused_item = local_participant.focus_item;
            if (focused_item) {
                const surface = focused_item.room_entity && focused_item.room_entity.surface;
                const is_participant = focused_item._parent.room_entity &&
                    focused_item._parent.room_entity instanceof RoomParticipant;
                if (surface) {
                    surface.exitFocus();
                }
                else if (is_participant) {
                    const participant = focused_item._parent.room_entity;
                    participant.exitFocus();
                }
                else {
                    local_participant.focusOn(null);
                }
            }
            return;
        }
        //focus on participant
        if (item && item instanceof RoomParticipant) {
            if (item.walking) {
                //when walking cannot click on people
                return;
            }
            this.onParticipantClicked(item, false, e);
            this.selected_participant = item;
        }
        //pass the action to the item
        if (item.processClick) {
            item.processClick(e);
        }
    }
    //called when clicking the webcam feed of somebody (in 3D or attendes list)
    onParticipantClicked(participant, in_list, e) {
        if (LEvent.trigger(this.space, "participant_clicked", participant))
            return;
        const xyz = this.xyz;
        //if the active controller allows exit, then exit
        if (xyz.active_controller !== this && xyz.active_controller.exit)
            xyz.active_controller.exit();
        console.debug("clicked participant", participant);
        const local_participant = this.space.local_participant;
        //focus in participant
        if (local_participant === participant &&
            (participant.seat || participant.walking) &&
            ROOM_SETTINGS.call.allow_focus) {
            //if(local_participant.seat)
            this.launcher.setController(this.selfview_controller);
        }
        else {
            if (e.shiftKey) {
                if (participant !== local_participant) {
                    local_participant.focusOn(null);
                    //local_participant.lookAt( participant );
                }
            }
            else {
                if (participant !== local_participant && participant.walking)
                    return;
                if (!ROOM_SETTINGS.call.allow_focus)
                    return;
                const focusable_node = participant.getProfileFocusNode();
                //if not focused to that object, focus on that
                if (local_participant.focus_item !== focusable_node) {
                    if (this.enable_flatcall)
                        //if in flatcall, exit
                        this.toggleFlatCall();
                    local_participant.focusOn(focusable_node);
                    local_participant.lookAt(focusable_node); //so when you quit you are still looking in his direction
                    if (ROOM_SETTINGS.call.DOF.allow)
                        this.view.setDOF(true);
                    this.view.hard_camera.fov = 30;
                } //if already focused, exit
                else {
                    local_participant.focusOn(null);
                    this.cooldown_time = getTime() + 500;
                }
            }
        }
    }
    //called from XYZLauncher
    onMouse(e) {
        const participant = this.space.local_participant;
        const event_type = e.type;
        if (this.xyz.native_mode && ROOM_FLAGS.allow_painting && e.dragging && e.altKey) {
            if (e.button === LEFT_MOUSE_BUTTON) {
                var roomAPI = nativeEngine._room;
                let cursor_x = e.mousex;
                let cursor_y = e.mousey;
                var flags = 0;
                if (event_type == "mousedown") {
                    flags |= 1;
                }
                if (event_type == "mouseup") {
                    flags |= 2;
                }
                roomAPI.decalPaint(flags, cursor_x, cursor_y);
                return;
            }
        }
        if (e.type == "mousedown") {
            this.accumulated_movement = 0;
        }
        else if (e.dragging) {
            this.accumulated_movement += Math.abs(e.deltax) + Math.abs(e.deltay);
        }
        const time_now = getTime();
        if (event_type === "mousemove") {
            this.last_mousemove_time = time_now;
        }
        //before testing areas blocked
        if (event_type === "wheel" && this.bubbles_manager.onMouseWheel(e)) {
            return;
        }
        if (GUI.onMouse(e)
            || GUI.isPositionBlocked(e.canvasx, e.canvasy)) {
            return true;
        }
        if (event_type === "mousedown") {
            // clear selected seat popup
            this.select_seat_popup = null;
        }
        let cursor_x = e.canvasx;
        let cursor_y = e.canvasy;
        const gl = GL.ctx;
        const is_mouse_locked = document.pointerLockElement === gl.canvas;
        if (is_mouse_locked) {
            // pointer is locked, assume cursor is at the center of the viewport
            cursor_x = gl.canvas.width * 0.5;
            cursor_y = gl.canvas.height * 0.5;
        }
        const view = this.view;
        //compute mouse ray to scene
        const ray = e.ray = view.camera.getRay(cursor_x, cursor_y, null, view.last_ray);
        vec3.scaleAndAdd(ray.origin, ray.origin, ray.direction, 0.1); //push ray a little bit forward to avoid near errors
        const is_on_hold = participant && participant.is_on_hold;
        const is_on_focus = participant && participant.focus_item;
        //in case a module wants to replace it temporary
        if (this.onCustomMouse && this.onCustomMouse(e)) {
            return true;
        }
        //trigger event
        if (LEvent.trigger(this.space, "mouse", e) === true) {
            return true;
        }
        //in case a tool requires special user interaction
        if (this.tool && this.tool.onMouse && this.tool.onMouse(e, ray)) {
            return true;
        }
        //send ray to all entities in case they want to highlight anything
        if (participant && !this.enable_flatcall && !is_on_hold) {
            this.testRayRoomInteraction(ray);
        }
        //check which entity/participant is below the mouse
        // Andrey: please do not uncomment this 4 lines
        //if( this.xyz.native_mode )
        //{
        //	//TODO
        //}
        //else
        {
            if (participant && !this.enable_flatcall && !is_on_hold && !is_on_focus) {
                view.testNodeHover(ray, e, view.camera);
            }
        }
        //change hover
        const prev = this.item_hover;
        this.item_hover = view.item_hover;
        if (prev !== this.item_hover) {
            // hover item changed
            if (prev && prev.onMouseLeave) {
                prev.onMouseLeave(e);
            }
            if (this.item_hover && this.item_hover.onMouseEnter) {
                this.item_hover.onMouseEnter(e);
            }
        }
        //in case the entity wants to process the event
        if (this.item_hover
            && !this.enable_flatcall
            && !is_on_hold
            && this.item_hover.onMouse
            && this.item_hover.onMouse(e)) {
            return true;
        }
        //otherwise handle to camera
        //console.debug(e);
        if (event_type === "mousedown" && !GUI.isPositionBlocked(e.mousex, e.mousey)) {
            this.dragging_camera = true;
            if (participant && participant.focus_item && (e.buttons & 2)) {
                //test ray with focus plane
                this._last_focus_plane_collision = participant.testRayWithFocusPlane(ray);
            }
        }
        else if (event_type === "mouseup") {
            this.dragging_camera = false;
            this._last_focus_plane_collision = null;
            //teleport
            if (e.button === RIGHT_MOUSE_BUTTON &&
                e.click_time < 200 &&
                !participant.seat)
                this.castTeleportRay(e);
        }
        //if fast click, the entity was clicked
        const click_detected = this.camera_mode !== "free" &&
            event_type === "mouseup" &&
            e.button === LEFT_MOUSE_BUTTON &&
            e.click_time < 500 && this.accumulated_movement < 40 &&
            this.cooldown_time < time_now &&
            !GUI.isPositionBlocked([e.mousex, e.mousey]);
        const touch_detected = this.camera_mode !== "free" &&
            e.is_touch &&
            event_type === "mousedown" &&
            e.touch_e_type === "singletap" &&
            this.cooldown_time < time_now &&
            !GUI.isPositionBlocked([e.mousex, e.mousey]);
        if (click_detected || touch_detected) {
            //click
            this.processItemClicked(view.item_hover, view.node_hover, e);
            if (touch_detected) {
                // To prevent automatic click of close button due to the order the mouse events are processed
                GUI.discardTouchInput();
            }
        }
        //mouse drags are usually for camera movement
        if (participant &&
            !participant.focus_item &&
            this.controller_from_component &&
            this.controller_from_component.onMouse) {
            this.controller_from_component.onMouse(e, view);
        }
        else {
            if (event_type === "wheel") {
                if (participant &&
                    (participant.seat || participant.walking) &&
                    this.camera_mode !== "free") {
                    this.userCameraChangeFOV(e);
                }
                else
                    view.processMouseWheel(e);
            }
            if ((participant && this.camera_mode === "seat") ||
                this.camera_mode === "on_tracks") {
                //not focused
                if (!participant.focus_item &&
                    (this.dragging_camera || participant.walking)) {
                    this.userCameraRotation(e);
                }
                else {
                    //pan camera in focus mode
                    if (participant.focus_item && this.dragging_camera) {
                        const focus_entity = participant.focus_item.getParentEntity();
                        if (!focus_entity.surface && e.buttons) {
                            //any button
                            const offset = this.panFocusCamera(e);
                            if (Math.abs(offset[0]) > 0.25) {
                                participant.focusOn(null);
                            }
                        }
                        else if (focus_entity && this.isDragAllowed(focus_entity.surface)) {
                            if ((e.buttons & 0b1) !== 0) {
                                // left mouse button
                                this.panFocusCamera(e);
                            }
                            else if (this.xyz.mobile && e.type === "mousemove" && (e.buttons_mask & 0b1) !== 0) {
                                //pan camera when is mobile
                                this.panFocusCamera(e);
                            }
                        }
                    }
                }
            }
            else if (this.dragging_camera || is_mouse_locked) {
                view.processCameraController(e, this.camera_mode, is_mouse_locked);
            }
        }
    }
    isDragAllowed(surface) {
        const app = surface._app;
        if (app && app.allowDragMouseAction) {
            return app.allowDragMouseAction();
        }
        return true;
    }
    panFocusCamera(e) {
        const ray = e.ray;
        const participant = this.space.local_participant;
        let coll = participant.testRayWithFocusPlane(ray);
        const delta = vec3.create();
        if (this._last_focus_plane_collision) {
            vec3.sub(delta, coll, this._last_focus_plane_collision);
        }
        else {
            this._last_focus_plane_collision = vec3.create();
        }
        vec3.copy(this._last_focus_plane_collision, coll);
        if (!participant.focus_offset) {
            participant.focus_offset = [0, 0, 0];
        }
        const offset = participant.focus_offset;
        const dist = vec3.distance(this.view.camera.position, this.view.camera.target);
        const max_offset = dist * 0.5;
        this.view.camera.globalVectorToLocal(delta, delta);
        offset[0] = clamp(offset[0] - delta[0], -max_offset, max_offset);
        offset[1] = clamp(offset[1] - delta[1], -max_offset, max_offset);
        coll = participant.testRayWithFocusPlane(ray);
        if (this._last_focus_plane_collision) {
            vec3.copy(this._last_focus_plane_collision, coll);
        }
        return offset;
    }
    //to leap inside the room
    castTeleportRay(e) {
        const ray = this.view.camera.getRay(e.mousex, e.mousey, null, this.view.last_ray);
        const coll = vec3.create();
        const test = this.view.scene.testRay(ray, coll, 1000, 0xffff, true);
        if (!test) {
            return;
        }
        const participant = this.space.local_participant;
        //adjust to same floor
        coll[1] = participant.position[1];
        //move to collision point
        participant.position = coll;
    }
    //from mouse wheel event
    userCameraChangeFOV(e) {
        const participant = this.space.local_participant;
        const camera = this.view.hard_camera;
        const direction = e.wheel > 0 ? 1 : -1;
        const offset = 1 + direction * -0.05;
        let max_fov = 75;
        const min_fov = 20;
        let new_fov = camera.fov * offset;
        let new_head_offset = participant.head_offset - direction * 0.1;
        if (participant && participant.focus_item) {
            new_head_offset = participant.head_offset;
            max_fov = 90;
            if (e.is_touch) {
                max_fov = 120;
                new_fov = e.pinchScale * (max_fov - min_fov);
                new_fov = max_fov - new_fov; // flipping direction on pinch gestures
            }
        }
        if (participant.head_offset === 0 ||
            (participant.head_offset < 1 && direction > 0) ||
            (participant && participant.focus_item)) {
            if (new_fov < max_fov) {
                camera.fov = clamp(new_fov, min_fov, max_fov);
                if (direction > 0) {
                    participant.head_offset = 0;
                }
            }
            else {
                camera.fov = max_fov;
                participant.head_offset = clamp(new_head_offset, 0, 2);
            }
        }
        else {
            camera.fov = max_fov;
            if (new_head_offset < 0) {
                participant.head_offset = 0;
                camera.fov = clamp(new_fov, min_fov, max_fov);
            }
            else {
                if (new_head_offset < 1 && direction > 0) {
                    participant.head_offset = 0;
                }
                else {
                    if (new_head_offset > 1) {
                        camera.fov = max_fov - 10 * (new_head_offset - 1);
                    }
                    else {
                        camera.fov = max_fov;
                    }
                    participant.head_offset = clamp(new_head_offset, 0, 2);
                }
            }
        }
        if (participant.walking) {
            participant.head_offset = 0;
        }
    }
    //called from onMouse if no focus and seated
    userCameraRotation(e) {
        const participant = this.space.local_participant;
        const moved = e.deltax || e.deltay;
        const button_pressed = e.buttons & 1;
        const is_mouse_locked = document.pointerLockElement === GL.ctx.canvas;
        if (!moved)
            return;
        let rotation_speed = 0.1;
        if (!button_pressed && !is_mouse_locked)
            rotation_speed = 0.002;
        participant.yaw += e.deltax * -rotation_speed;
        participant.pitch = clamp(participant.pitch + e.deltay * -rotation_speed, -89, 89);
        participant.target_yaw = participant.yaw; //no animation
        participant.target_pitch = participant.pitch; //no animation
        participant.interpolate_state = false;
    }
    followPath(path, seat, reverse) {
        this.transition_to_seat.start(path, seat, reverse);
    }
    takeScreenshot() {
        this.view.takeSnapshot(0, 0, function (data, encoding) {
            const extension = encoding.split("/")[1];
            ROOM.downloadFile("screenshot." + extension, data);
        }, null, null, () => {
            // TODO it's not clear what this is for
            const gl = GL.ctx;
            gl.start2D();
        });
    }
    onChangePointerLockChange() {
        //console.debug("pointer lock change",e);
        if (document.pointerLockElement === GL.ctx.canvas) {
            //console.debug("ON!");
        }
        else {
            //console.debug("OFF");
        }
    }
    enableOrbitMode() {
        if (this.space.local_participant && this.space.local_participant.seat) {
            this.space.local_participant.exitSeat();
        }
        this.camera_mode = "orbit";
        Seat.gizmos_visible = true;
        this.view.lookAt(this.space.camera_info.position, this.space.camera_info.target);
    }
    enableFreeCamMode(v = true) {
        if (v == null)
            v = true;
        this.setComponentController(null); //to ensure exit
        if (v) {
            if (this.camera_mode === "free")
                return;
            this.hideInterface();
            this.camera_mode = "free";
            this.view.hard_camera.moveLocal([0, 0.1, -0.1]);
            this.view.allow_freecam = true;
            this.enable_outlines = false;
            this.show_bubbles = false;
        }
        else {
            this.setMinimalInterface(false);
            if (this.force_camera_track &&
                this.space.local_participant &&
                this.space.local_participant.seat)
                this.toggleCameraOnTracks();
            else
                this.camera_mode =
                    (this.space.local_participant && this.space.local_participant.seat) ||
                        this.space.local_participant.walking
                        ? "seat"
                        : "orbit";
            this.view.allow_freecam = false;
            this.enable_outlines = true;
            this.show_bubbles = true;
        }
    }
    /**
     * sets a component which is going to control the camera
     * it should be better named as: setControllingComponent
     * @param comp
     */
    setComponentController(comp) {
        var _a;
        if (this.controller_from_component === comp) {
            // no change
            return;
        }
        if (comp === null) {
            (_a = this.controller_from_component) === null || _a === void 0 ? void 0 : _a.resetConstraints();
        }
        if (this.controller_from_component !== null) {
            this.controller_from_component.onLeave(this);
            this.controller_from_component = null;
        }
        if (comp) {
            this.controller_from_component = comp;
            this.controller_from_component.onEnter(this);
            this.setConstraints();
        }
    }
    setConstraints() {
        var _a;
        const constraints = (_a = this.controller_from_component) === null || _a === void 0 ? void 0 : _a.travelingTrackToConstraints();
        constraints === null || constraints === void 0 ? void 0 : constraints.forEach((constraint) => {
            this.controller_from_component.addConstraint(constraint);
        });
    }
    toggleOnHold(force = false) {
        if (!this.space.local_participant)
            return;
        let v = !this.space.local_participant.is_on_hold;
        if (force !== undefined)
            v = force;
        if (v === this.space.local_participant.is_on_hold)
            return;
        this.space.local_participant.is_on_hold = v;
        if (this.space.local_participant.is_on_hold)
            this.captureBlurredFrame();
    }
    toggleCameraOnTracks(force = false) {
        var _a, _b, _c;
        //enable
        if (this.camera_mode !== "on_tracks" || force) {
            const space = this.space;
            const participant = space.local_participant;
            if (participant.focus_item) {
                participant.focusOn(null);
            }
            const pos = (_c = (_b = (_a = participant.seat) === null || _a === void 0 ? void 0 : _a.entity) === null || _b === void 0 ? void 0 : _b.position) !== null && _c !== void 0 ? _c : participant.position;
            const comp = space.findNearestCameraTrack(pos);
            if (comp) {
                this.setComponentController(comp);
            }
        } //disable
        else {
            this.setComponentController(null);
        }
    }
    toggleFlatCall(force = false) {
        this.enable_flatcall = !this.enable_flatcall;
        if (!this.enable_flatcall && !force)
            return;
        ROOM.lockMouse(false);
        RoomParticipant.global_opacity = 0;
        if (!this.space.local_participant.focus_item) {
            for (let i = 1; i < this.space.participants.length; ++i) {
                const participant = this.space.participants[i];
                participant._bubble[0] = participant.screen_position[0];
                participant._bubble[1] = participant.screen_position[1];
                participant._bubble[3] = 1;
            }
        }
        this.captureBlurredFrame();
    }
    captureBlurredFrame() {
        const view = this.view;
        const pbr = view.pbrpipeline;
        if (!pbr) {
            return;
        }
        pbr.allow_overlay = false;
        view.camera.configure(view.hard_camera.serialize());
        view.render();
        view.renderLastFrame(true); //captures frame
        pbr.allow_overlay = true;
    }
    toggleOrientMode() {
        RoomParticipant.orientation_mode =
            RoomParticipant.orientation_mode === EnumOrientation.FACING_ORIENTATION
                ? EnumOrientation.USER_FACING_ORIENTATION
                : EnumOrientation.FACING_ORIENTATION;
    }
    togglePersonalTablet(v) {
        const participant = this.space.local_participant;
        if (!participant)
            return;
        if (!participant.tablet) {
            const tablet = new Entity();
            tablet.name = "personal_tablet";
            tablet.skip_save = true;
            const comp = new Surface(this);
            comp.app_name = "OS";
            comp.brightness = 0.75;
            comp.alpha_mode = "OPAQUE";
            // @ts-ignore
            comp._participant = participant;
            // @ts-ignore
            tablet.addComponent(comp);
            this.space.addEntity(tablet);
            participant.tablet = tablet;
            return;
        }
        if (v === undefined) {
            v = !participant.tablet.enabled;
        }
        if (v === false) {
            participant.tablet.enabled = false;
            participant.focusOn(null);
        }
        else if (v === true) {
            participant.tablet.enabled = true;
        }
    }
    updatePersonalTablet() {
        const participant = this.space.local_participant;
        if (!participant || !participant.tablet)
            return;
        const tablet = participant.tablet;
        tablet.surface._material.opacity = participant.focus_item ? 0.95 : 0.9;
        if (!participant.focus_item) {
            this.view.camera.updateMatrices();
            const m = mat4.clone(this.view.camera._model_matrix);
            mat4.translate(m, m, [0, -0.05 + participant.pitch * -0.001 - 0.04, -0.1]);
            const angle = clamp(-45 + participant.pitch * -1, -30, 0);
            mat4.rotateX(m, m, angle * DEG2RAD);
            mat4.scale(m, m, [0.1, 0.06, 0.12]);
            tablet.node.matrix = m;
        }
    }
    saveCameraView(cam_id) {
        console.debug("camera saved", cam_id);
        var ent = this.getCameraView(cam_id);
        if (!ent) {
            ent = new Entity();
            ent.name = "view_" + cam_id;
            this.space.addEntity(ent);
        }
        var comp = ent.getComponent(CameraViewpoint);
        if (!comp) {
            comp = new CameraViewpoint();
            ent.addComponent(comp);
        }
        comp.assignFromCamera(this.view.hard_camera);
        //comp.camera.configure( this.view.hard_camera.serialize() );
        //this.camera_views[cam_id] = this.view.hard_camera.serialize();
    }
    getCameraView(cam_id) {
        return this.space.getEntity("view_" + cam_id);
    }
    restoreCameraView(cam_id, skip_transition = false) {
        var ent = this.getCameraView(cam_id);
        if (!ent)
            return;
        var comp = ent.getComponent(CameraViewpoint);
        if (!comp)
            return;
        //this.view.hard_camera.configure(comp.camera.serialize());
        //if (skip_transition) this.view.camera.configure(comp.camera.serialize());
        comp.assignToCamera(this.view.hard_camera);
        if (skip_transition)
            comp.assignToCamera(this.view.camera);
        /*
        const cam_info = this.camera_views[cam_id];
        if (!cam_info) return;
        console.debug("restore camera", cam_id);
        this.view.hard_camera.configure(cam_info);
        if (skip_transition) this.view.camera.configure(cam_info);
        */
    }
    //called when pressing Escape
    escapeAction(e) {
        const space = this.space;
        this.show_escape_button = false;
        if (LEvent.trigger(this.space, "escape", e) === true)
            return true;
        if (this.enable_flatcall) //exit flat calls
            this.enable_flatcall = false;
        else if (space.local_participant.focus_item) //exit focus mode
            space.local_participant.focusOn(null);
        else if (this.controller_from_component) { //exit controller_component (camera track)
            this.setComponentController(null);
            if (this.force_camera_track)
                this.requestChangeSeat(null);
        }
        else if (this.camera_mode === "on_tracks") //exit track?
            this.camera_mode = "seat";
        //else if( this.view.assigned_camera )
        //	this.view.assigned_camera = null;
        else if (this.camera_mode === "free") //exit free camera
            this.enableFreeCamMode(false);
        else if (ROOM_SETTINGS.call.allow_walking && space.local_participant.seat) { //exit seat
            this.requestChangeSeat(null);
        }
        else if (!space.local_participant.seat && space.local_participant.last_seat) { //back to seat
            this.requestChangeSeat(space.local_participant.last_seat);
        }
        else if (this.select_seat_popup) { //exit popup?
            //modal
            this.select_seat_popup = null;
        }
        else if (!this.block_orbit_mode) {
            this.enableOrbitMode();
        }
    }
    onKeyDown(e) {
        const space = this.space;
        //in case something requires special user interaction
        const tool = this.tool;
        if (tool && tool.onKeyDown && tool.onKeyDown(e)) {
            return true;
        }
        if (LEvent.trigger(this.space, "keydown", e) === true) {
            return true;
        }
        if (GUI.onKey(e)) {
            return true;
        }
        if (this.controller_from_component &&
            this.controller_from_component.onKeyDown &&
            this.controller_from_component.onKeyDown(e)) {
            return true;
        }
        //ignore mouse if inside html element
        const localName = e.target.localName;
        if (localName === "textarea" || localName === "input") {
            return;
        }
        //resend to focus node
        const local_participant = space.local_participant;
        if (local_participant &&
            local_participant.focus_item &&
            !this.enable_flatcall) {
            const focus_entity = local_participant.focus_item.getParentEntity();
            if (focus_entity.onKeyDown && focus_entity.onKeyDown(e)) {
                return;
            }
        }
        //to help modules to catch keypresses safely
        const callback = RoomCall.key_callbacks[e.code];
        if (callback && callback(e))
            return true;
        switch (e.code) {
            case "Digit1":
            case "Digit2":
            case "Digit3":
            case "Digit4":
            case "Digit5":
            case "Digit6":
            case "Digit7":
            case "Digit8":
            case "Digit9":
            case "Digit0":
                const num = Number(e.code.substr(5));
                if (e.shiftKey)
                    this.saveCameraView(num);
                else if (e.ctrlKey || e.metaKey)
                    return; //ignore for tab switch
                else if (this.camera_mode === "free")
                    this.restoreCameraView(num, true);
                e.preventDefault();
                break;
            case "KeyA":
                if (e.ctrlKey) {
                    this.xyz.setController(this.xyz.broadcast_controller);
                    this.launcher.is_in_broadcast_mode = true;
                }
                e.preventDefault();
                break;
            case "KeyS":
                if (local_participant.focus_item)
                    local_participant.focusOn(null);
                break;
            case "KeyR":
                let v = document.pointerLockElement !== GL.ctx.canvas &&
                    (local_participant.walking || this.camera_mode === "free");
                if (local_participant.focus_item || this.enable_flatcall)
                    v = false;
                ROOM.lockMouse(v);
                e.preventDefault();
                break;
            case "KeyC":
                if (!e.shiftKey && !e.ctrlKey && !e.metaKey)
                    this.toggleCameraOnTracks();
                this.force_camera_track = !this.force_camera_track;
                break;
            case "KeyF":
                if (e.shiftKey) {
                    this.toggleUIAction("go_fullscreen");
                }
                else {
                    this.toggleFlatCall();
                }
                break;
            case "KeyM":
                if (!e.shiftKey) {
                    this.toggleUIAction("mute_user");
                }
                break;
            case "KeyH":
                if (!e.shiftKey) {
                    this.toggleUIAction("report_problem");
                }
                else if (this.head_tracker) {
                    this.head_tracker.enabled = !this.head_tracker.enabled;
                    if (!this.head_tracker.enabled) {
                        //reset on exit
                        this.space.local_participant._internal_yaw = 0;
                        this.space.local_participant._internal_pitch = 0;
                    }
                }
                break;
            case "KeyO":
                this.toggleOrientMode();
                break;
            case "KeyG":
                if (this.enable_flatcall)
                    //if in flatcall, exit
                    this.toggleFlatCall();
                if (!this.space.local_participant.focus_item)
                    this.focusOnNearestSurface();
                else
                    this.space.local_participant.focusOn(null);
                break;
            case "KeyB":
                if (e.ctrlKey)
                    this.enableFreeCamMode();
                e.preventDefault();
                break;
            case "KeyQ":
            case "Escape":
                this.escapeAction(e);
                e.preventDefault();
                break;
            case "KeyE":
                if (this.camera_mode !== "free")
                    this.castMarker([0, 1], [1, 1, 1, 1], true);
                break;
            case "KeyL":
                this.emitMarker([2, 8], [1, 0.5, 0.5, 1], true);
                break;
            case "F2":
                e.preventDefault();
                if (!this.stats) {
                    const script = document.createElement("script");
                    script.onload = () => {
                        const Stats = window.Stats;
                        const stats = new Stats();
                        this.stats = stats;
                        document.body.appendChild(stats.dom);
                        requestAnimationFrame(function loop() {
                            stats.update();
                            requestAnimationFrame(loop);
                        });
                    };
                    script.src = "//mrdoob.github.io/stats.js/build/stats.min.js";
                    document.head.appendChild(script);
                }
                else {
                    if (this.stats.dom.parentNode)
                        document.body.removeChild(this.stats.dom);
                    else
                        document.body.appendChild(this.stats.dom);
                }
                break;
            case "F9":
                if (e.ctrlKey) {
                    this.enable_native_stats = !this.enable_native_stats;
                    if (nativeEngine && nativeEngine._engine)
                        nativeEngine._engine.setNativeStats(this.enable_native_stats);
                }
                else if (e.shiftKey) {
                    this.enable_native_profiler = !this.enable_native_profiler;
                    if (nativeEngine && nativeEngine._engine) {
                        nativeEngine._engine.setProfiler(this.enable_native_profiler);
                        nativeEngine._engine.setProfileStallsThreshold(this.native_profiler_stalls_threshold);
                    }
                }
                else if (this.allow_debug) {
                    this.enable_debug_gui = !this.enable_debug_gui;
                }
                e.preventDefault();
                break;
            case "F10":
                if (e.ctrlKey) {
                    this.enable_native_menu = !this.enable_native_menu;
                    if (nativeEngine && nativeEngine._engine)
                        nativeEngine._engine.setNativeMenu(this.enable_native_menu);
                }
                else if (e.shiftKey)
                    this.enable_debug_performance = !this.enable_debug_performance;
                else
                    this.enable_version = !this.enable_version;
                e.preventDefault();
                break;
            case "F3":
                this.launcher.setController(this.selfview_controller);
                e.preventDefault();
                break;
            case "F4":
                if (this.view.resolution_factor !== 1)
                    this.view.resolution_factor = 1;
                else
                    this.view.resolution_factor = 0.5;
                e.preventDefault();
                break;
            case "KeyT":
                if (e.shiftKey) {
                    this.togglePersonalTablet();
                    e.preventDefault();
                }
                else
                    this.toggleUIAction("show_manual");
                break;
            case "KeyK":
                this.toggleUIAction("show_device_settings");
                break;
            case "KeyP":
                this.toggleUIAction("show_participants");
                break;
            case "F8":
                this.takeScreenshot();
                e.preventDefault();
                break;
            case "Tab":
            default:
                return false;
        }
        return true;
    }
    toggleUIAction(action) {
        if (this.xyz.bridge)
            this.xyz.bridge.notify("UI_" + action.toUpperCase());
    }
    focusOnNearestSurface() {
        if (!ROOM_SETTINGS.call.allow_focus)
            return;
        let surface = this.space.getEntity("tv");
        if (!surface)
            surface = this.xyz.space.findComponents("Surface", [])[0];
        if (!surface)
            return;
        this.space.local_participant.focusOn(surface.entity.node);
    }
    //called from launcher.onRoomReady
    onRoomReady(space) {
        if (!space.local_participant) {
            this.createLocalParticipant(space);
            space.processActionInComponents("onStart");
        }
        if (this.xyz.options.native !== "true" || this.xyz.options.debug) {
            this.handleURLParams();
        }
    }
    createLocalParticipant(space) {
        const timestamp = Date.now(); //to epoch
        //create own participant
        const user_data = {
            name: "Anonymous",
            enterTimestamp: timestamp,
        };
        const participant = (this.participant = this.launcher.createParticipant("", user_data));
        participant.is_local_participant = true;
        participant.on("changeSeat", this.onPartipantChangeSeat, this);
        space.addParticipant(participant);
        return participant;
    }
    //executed on a seat change of local participant
    onPartipantChangeSeat(type, event) {
        if (this.xyz.is_in_broadcast_mode)
            return; //don't change camera position if in broadcast mode
        const space = this.space;
        const local_participant = space.local_participant;
        const participant = event.author;
        if (participant !== local_participant)
            return;
        //enters a seat
        const view = this.view;
        if (participant.seat) {
            // seat exists
            ROOM.lockMouse(false);
            if (!this.hasBeenSeated) {
                this.hasBeenSeated = true;
                LEvent.trigger(space, "first_time_seat");
            }
            if (this.camera_mode === "orbit")
                this.camera_mode = "seat";
            if (event.prev_fov)
                view.hard_camera.fov = event.prev_fov;
            else if (!event.prev_seat)
                view.hard_camera.fov = 60 * participant.seat.fov_factor;
            const node = participant.seat.entity.node;
            // This is done to fix camera moved on user appearance
            if (node && !this.launcher.is_in_tutorial_flow) {
                //change camera
                let eye = vec3.fromValues(0, ROOM_SETTINGS.participants.view_height || 0, 0);
                eye = node.localToGlobal(eye);
                let target = vec3.fromValues(0, ROOM_SETTINGS.participants.view_height || 0, 1);
                target = node.localToGlobal(target);
                view.lookAt(eye, target);
            }
            if (participant.seat.cubemap_folder) {
                view.scene_skybox = view.getMatterportView("data/photos/" + participant.seat.cubemap_folder + "/");
                view.scene_skybox.rotation = participant.seat.entity.rotation;
                const bg_entity = space.getEntity("background");
                if (bg_entity)
                    bg_entity.enabled = false;
            }
            if (this.force_camera_track && participant.seat && !participant.seat.walking_start)
                this.toggleCameraOnTracks(true);
        }
        else {
            // seat does not exist
            view.hard_camera.fov = ROOM_SETTINGS.participants.walking_fov;
            view.scene_skybox = null;
            const bg_entity = space.getEntity("background");
            if (bg_entity)
                bg_entity.enabled = true;
            view.lookAt(space.camera_info.position, space.camera_info.target);
        }
    }
    //called from onMouse at the end if nobody used the mouse event
    testRayRoomInteraction(ray) {
        const local_participant = this.space.local_participant;
        // Andrey: In native mode ray-casting with basic entities is working using JS code
        // For prefab need native implementation
        // Anyway please do not uncomment this 2 lines
        //if( this.xyz.native_mode )
        //	return; //not supported yet
        const entities = this.space.root.getAllEntities();
        for (let i = 0; i < entities.length; ++i) {
            const entity = entities[i];
            entity.processMouseRay(ray, local_participant);
        }
    }
    //sends a signal to server to tell which people should have high quality stream (people close to you or being broadcasted to the screens)
    updateSimCast() {
        const bridge = this.launcher.bridge;
        if (!bridge) {
            return;
        }
        const space = this.space;
        const local_participant = this.space.local_participant;
        const campos = this.view.camera.position;
        //compute distance to cam
        let people_by_distance = this.space.participants.concat();
        people_by_distance.forEach(function (p) {
            p._distance = vec3.distance(campos, p.position);
            if (space._global_feed && space._global_feed === p.feed)
                p._distance = 0;
        });
        //sort by distance
        people_by_distance.sort(function (a, b) {
            return a._distance - b._distance;
        });
        //remove people too far and myself
        people_by_distance = people_by_distance.filter(function (p) {
            return p._distance < 3 && p.id && p !== local_participant;
        });
        //trim list
        if (people_by_distance.length > RoomCall.MAX_SIMCAST_USERS)
            people_by_distance.length = RoomCall.MAX_SIMCAST_USERS;
        //get ids
        const payload = people_by_distance.map(function (p) {
            return p.id;
        });
        bridge.notify("EVENT_SIMCAST_UPDATE", payload);
    }
    enableAssistant() {
        const ent = new Entity();
        ent.name = "Assistant";
        const json = {
            uid: "@ENT-ca1bcb-37c8-163fc47-6c",
            name: "Assistant",
            index: 63,
            type: "MEDIA",
            layers: 1,
            position: [7.103959560394287, 1.136897325515747, 4.928309440612793],
            rotation: [0, -0.6139346361160278, 0, 0.7893568277359009],
            custom: {},
            flags: { interactive: false },
            scale: [1.4473365545272827, 1, 0.9999579787254333],
            components: [
                [
                    "MediaPlayer",
                    {
                        uid: "@COMP--ca1bcb-37c8-14f3691-6d",
                        volume: 0.1499999999999999,
                        url: "/videos/draft.webm",
                        interactive: false,
                        restart_on_play: true,
                        texture_channel: "emissive",
                        material: {
                            flags: { two_sided: true, depth_test: true, depth_write: true },
                            textures: {
                                // @ts-ignore
                                albedo: null,
                                emissive: "mediaPlayer_1",
                                // @ts-ignore
                                opacity: null,
                            },
                            color: [0, 0, 0, 1],
                            name: "mediaPlayer_1",
                            alphaMode: "BLEND",
                            alphaCutoff: 0,
                        },
                        target_material: "",
                        object_class: "MediaPlayer",
                    },
                ],
                [
                    "InteractiveObject",
                    {
                        uid: "@COMP--ca1bcb-20479-18e8b6c-6c",
                        enabled: true,
                        state: true,
                        audio_url: "",
                        volume: 0.4,
                        clickable: true,
                        no_outline: true,
                        link_url: "",
                        last_action_id: 1,
                        max_distance: 0,
                        actions: [{ restart: true, id: 0, action_type: "video_toggle" }],
                        object_class: "InteractiveObject",
                    },
                ],
                [
                    "FaceTo",
                    {
                        uid: "@COMP--ef2aa7-3857-1c43734-6f",
                        enabled: true,
                        mode: "cylindrical",
                        smooth: true,
                        object_class: "FaceTo",
                    },
                ],
            ],
        };
        this.space.addEntity(ent);
        ent.configure(json);
        const seat = this.space.getEntity("seat1");
        ent.position = seat.position;
        ent.node.move([0, 1, 0]);
        ent.skip_save = true;
    }
    onDropItem(evt) {
        const that = this;
        const files = evt.dataTransfer.files;
        const ext = files[0].name.split(".").pop().toLowerCase();
        if (ext === "hdre") {
            const url = URL.createObjectURL(files[0]);
            this.view.setEnvironment(url);
        }
        else if (ext === "json") {
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = JSON.parse(e.target.result);
                that.space.fromJSON(data);
            };
            reader.readAsText(files[0]);
        }
        else if (ext === "png" || ext === "jpg") {
            //check collision with meshes
        } //multiple files
        else {
            if (nativeEngine) {
                let ent = that.space.getEntity("background");
                if (!ent) {
                    ent = new Entity();
                    that.space.addEntity(ent);
                    ent.configure({ type: "PREFAB", name: "background" });
                }
                for (let i = 0; i < files.length; ++i) {
                    const file = files[i];
                    const reader = new FileReader();
                    const t = file.name.split(".");
                    const extension = t[t.length - 1].toLowerCase();
                    reader.onload = inner;
                    reader.filename = file.name;
                    reader.extension = extension;
                    reader.entity = ent;
                    if (extension === "gltf")
                        reader.readAsText(file);
                    else
                        reader.readAsArrayBuffer(file);
                }
                function inner(e) {
                    const data = e.target.result;
                    const name = this.filename;
                    const entity = this.entity;
                    if (entity._native) {
                        const nativeNode = ent._native.getRootNode();
                        const uint8View = new Uint8Array(data);
                        nativeNode.loadPrefab(uint8View, name, function (status) {
                            //console.print("Prefab loaded with status" + status);
                        });
                    }
                }
            }
            else {
                RD.GLTF.loadFromFiles(files, function (node) {
                    let ent = that.space.getEntity("background");
                    if (!ent) {
                        ent = new Entity();
                        that.space.addEntity(ent);
                        ent.configure({ type: "PREFAB", name: "background" });
                    }
                    ent.assignPrefabNode(node);
                });
            }
        }
    }
    takeSnapshot(width, height, callback) {
        const gl = GL.ctx;
        const canvas = gl.canvas;
        const old_w = canvas.width;
        const old_h = canvas.height;
        canvas.width = width;
        canvas.height = height;
        this.view.resize_mode = "custom";
        this.view.pbrpipeline.resolution_factor = 2;
        gl.viewport(0, 0, width, height);
        this.view.render();
        canvas.toBlob(function (blob) {
            if (callback) {
                callback(blob);
            }
            else {
                ROOM.downloadFile("preview.png", blob);
            }
        });
        this.view.pbrpipeline.resolution_factor = 1;
        this.view.resize_mode = "auto";
        canvas.width = old_w;
        canvas.height = old_h;
    }
    showMessage(msg, duration) {
        duration = duration || 5;
        this.popup_message = {
            text: msg || "",
            time: getTime() * 0.001 + duration,
        };
    }
    //from scene
    castMarker(type, color, grow) {
        const coll = vec3.create();
        const ray = this.view.last_ray;
        const test = this.view.scene.testRay(ray, coll, 1000, 0xffff, true);
        if (!test)
            return;
        if (!type)
            type = [0, 1];
        let icon = type;
        if (typeof type === "string") {
            icon = GLUI.icons[type];
        }
        if (!icon)
            return;
        const data = this.space.addMarker(coll, "", 1, {
            icon: icon,
            size: 0.5,
            color: color,
            grow: grow,
        });
        if (this.space.network)
            this.space.network.syncGenericObject("marker", data);
    }
    //from user
    emitMarker(type, color, rise) {
        if (!type)
            type = [0, 1];
        let icon = type;
        if (typeof type === "string") {
            icon = GLUI.icons[type];
        }
        if (!icon)
            return;
        const pos = this.space.local_participant.getProfilePosition();
        pos[0] += (Math.random() - 0.5) * 0.5;
        pos[1] += (Math.random() - 0.5) * 0.5;
        pos[2] += (Math.random() - 0.5) * 0.5;
        const data = this.space.addMarker(pos, "", 3, {
            icon: icon,
            color: color,
            rise: rise,
        });
        if (this.space.network)
            this.space.network.syncGenericObject("marker", data);
    }
    //transitions to a new space but trying to keep this one intact
    //url must not be fullpath
    transitionToSpace(url) {
        const that = this;
        const main_space = this.space;
        this.secondary_space = new RoomSpace(this.view);
        LEvent.trigger(main_space, "start_loading"); //to force loader
        this.secondary_space.load(url, inner);
        function inner(new_space) {
            console.debug(new_space);
            //move seats
            const seats = main_space.getEntitiesByComponent("Seat");
            seats.forEach(function (a) {
                const new_ent = new_space.getEntity(a.name);
                if (new_ent) {
                    if (a.seat && new_ent.seat) {
                        const o = {};
                        new_ent.seat.serialize(o);
                        a.seat.configure(o);
                    }
                    a.moveTo(new_ent);
                    if (a.seat && a.seat.participant)
                        a.seat.participant.moveToSeat();
                }
                else
                    a.enabled = false;
            });
            //move tablets
            const surfaces = main_space.getEntitiesByComponent("Surface");
            surfaces.forEach(function (a) {
                const new_ent = new_space.getEntity(a.name);
                if (new_ent) {
                    if (a.surface && new_ent.surface) {
                        const o = {};
                        new_ent.surface.serialize(o);
                        a.surface.configure(o);
                    }
                    a.moveTo(new_ent);
                }
                else
                    a.enabled = false;
            });
            //change prefabs
            const old_prefabs = main_space.getEntitiesByComponent("PrefabRenderer");
            old_prefabs.forEach((a) => (a.visible = false)); //maybe remove them?
            const new_prefabs = new_space.getEntitiesByComponent("PrefabRenderer");
            for (const i in new_prefabs) {
                const p = new_prefabs[i];
                let ent = main_space.getEntity(p.name);
                if (ent)
                    ent.visible = true;
                else {
                    ent = new Entity();
                    main_space.addEntity(ent);
                }
                ent.configure(p.serialize());
            }
            //root components
            main_space.removeAllComponents();
            main_space.configureComponents(new_space.serializeComponents());
            main_space.environment = new_space.environment;
            main_space.fx = new_space.fx;
            main_space.camera_info = new_space.camera_info;
            //change enviro
            that.view.updateFromSpace(main_space);
        }
    }
    processBroadcastIN() {
    }
    triggerAction(evt) {
        if (this.xyz.action_listener)
            this.xyz.action_listener.processEvent(evt);
        this.broadcastMessage(evt);
    }
    broadcastMessage(msg) {
        if (!msg.type)
            msg.type = "director";
        // participants surfaces should not be synced
        if (msg.surface_entity &&
            msg.subaction !== "in_use_enter" &&
            msg.subaction !== "in_use_leave") {
            const entity = this.xyz.space.getEntityById(msg.surface_entity);
            if (entity && entity.surface && entity.surface.seat)
                return;
        }
        this.xyz.notify("EVENT_BCAST_OUT", JSON.stringify(msg));
    }
    hideInterface() {
        if (!this.xyz.bridge)
            return;
        this.xyz.bridge.notify(this.xyz.bridge.ON_BUTTONS_IN_MEETING_ALLOWED, {
            isButtonAllowed: false,
            muteButtonStatus: false,
            videoButtonStatus: false,
            screenshareButtonStatus: false,
            chatButtonStatus: false,
            attendeeListStatus: false,
            moreOptionsStatus: false,
            exitButtonStatus: false,
            fullscreenAllowedStatus: false, // Hide Full screen Button
        });
    }
    setMinimalInterface(v = false) {
        const bridge = this.xyz.bridge;
        if (!bridge) {
            return;
        }
        if (v) {
            bridge.notify(bridge.ON_BUTTONS_IN_MEETING_ALLOWED, {
                isButtonAllowed: true,
                muteButtonStatus: false,
                videoButtonStatus: false,
                screenshareButtonStatus: false,
                chatButtonStatus: false,
                attendeeListStatus: false,
                moreOptionsStatus: true,
                exitButtonStatus: true,
                fullscreenAllowedStatus: true, // Hide Full screen Button
            });
        }
        else {
            bridge.notify(bridge.ON_BUTTONS_IN_MEETING_ALLOWED, {
                isButtonAllowed: true,
                muteButtonStatus: true,
                videoButtonStatus: true,
                screenshareButtonStatus: true,
                chatButtonStatus: true,
                attendeeListStatus: true,
                moreOptionsStatus: true,
                exitButtonStatus: true,
                fullscreenAllowedStatus: true, // Hide Full screen Button
            });
        }
    }
    handleURLParams() {
        // room_name
        const url_room_name = urlParams.get("room_name");
        if (url_room_name) {
            const bridge = this.launcher.bridge;
            if (bridge && bridge.connect) {
                if (!bridge.is_connected) {
                    bridge.connect(url_room_name);
                }
                else {
                    bridge.disconnect();
                    bridge.connect(url_room_name);
                }
            }
        }
        // seat
        const url_seat = urlParams.get("seat");
        if (url_seat) {
            let seat_name = url_seat;
            const is_num = /^\d+$/.test(seat_name);
            // if the url param is only numbers, we look for the index of seat
            if (is_num)
                seat_name = "seat" + seat_name;
            const entity = this.xyz.space.getEntity(seat_name);
            if (entity && entity.seat) {
                this.requestChangeSeat(entity.seat);
            }
        }
    }
    setTool(tool) {
        this.tool = tool;
    }
    static registerKeyCallback(keycode, callback) {
        RoomCall.key_callbacks[keycode] = callback;
    }
    onPreRender() {
    }
}
RoomCall.MAX_SIMCAST_USERS = 5;
RoomCall.key_callbacks = {};
RoomCall.instance = null;
window.RoomCall = RoomCall;
