import ROOM from "@src/engine/room";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { RD } from "@src/libs/rendeer";

function LGraphSpaceNodeMove()
{
	this.addInput("state","boolean");
	this.addInput("change_state",LiteGraph.ACTION);
	this.properties = { state: false, entity_id: "", node_id:"", duration: 1 };
	this._off_transform = new Float32Array( RD.TRANS10_IDENTITY );
	this._on_transform = new Float32Array( RD.TRANS10_IDENTITY );
	this._old_state = this.properties.state;

	this.addWidget("button","Select Node",null, this.selectTarget.bind(this) );
	this.addWidget("button","Capture ON state",null, this.captureONState.bind(this) );
	this.addWidget("button","Capture OFF state",null, this.captureOFFState.bind(this) );
}

LGraphSpaceNodeMove.title = "Node Transform";
LGraphSpaceNodeMove.desc = "Allows to transition a scene node position between two states";

LGraphSpaceNodeMove.prototype.getSceneNode = function()
{
	if (!this.properties.entity_id || !this.properties.node_id)
		return null;
	var ent = ROOM.space.getEntityById( this.properties.entity_id );
	if (!ent)
		return null;
	var node = ent.node.findNode( this.properties.node_id );
	if (!node)
		return null;
	return node;
}

LGraphSpaceNodeMove.prototype.selectTarget = function()
{
	if (typeof(RoomEditor) === "undefined")
		return;

	var editor = xyz.editor_controller;
	var node = editor.selected_node;
	if (!node)
		return;

	var entity = node.getParentEntity(null,true);
	if (!entity)
		return;

	this.properties.entity_id = entity.uid;
	this.properties.node_id = node.id;
	this.captureONState();
	this.captureOFFState();
}

LGraphSpaceNodeMove.prototype.captureONState = function()
{
	var node = this.getSceneNode();
	if (!node)
		return;
	this._on_transform.set( node.transform );
}

LGraphSpaceNodeMove.prototype.captureOFFState = function()
{
	var node = this.getSceneNode();
	if (!node)
		return;
	this._off_transform.set( node.transform );
}

LGraphSpaceNodeMove.prototype.onAction = function(action)
{
	if (action === "change_state")
		this.properties.state = !this.properties.state;
}

LGraphSpaceNodeMove.prototype.onSerialize = function(o)
{
	o.on_transform = typedArrayToArray(this._on_transform);
	o.off_transform = typedArrayToArray(this._off_transform);
}

LGraphSpaceNodeMove.prototype.onConfigure = function(o)
{
	if (o.on_transform)
		this._on_transform.set(o.on_transform);
	if (o.off_transform)
		this._off_transform.set(o.off_transform);
}

LGraphSpaceNodeMove.prototype.onExecute = function()
{
	var node = this.getSceneNode();
	if (!node)
		return;

	var v = this.getInputData(0);
	if ( v !== undefined )
		this.properties.state = Boolean(v);

	var duration = this.properties.duration;
	if (duration == null)
		duration = 1;

	//to avoid doing it constanty
	if (this._old_state !== this.properties.state)
	{
		if (0)
		{
			if ( this.properties.state )
				node.transform = this._on_transform;
			else
				node.transform = this._off_transform;
		}
		else
		{
			if ( this.properties.state )
				Tween.easeProperty( node, "transform", this._on_transform, duration );
			else
				Tween.easeProperty( node, "transform", this._off_transform, duration );
		}

		this._old_state = this.properties.state;
	}

	LGraphSpaceNodeMove.prototype.onDrawBackground = function(ctx)
	{
		var node = this.getSceneNode();
		if (!node)
		{
			this.boxcolor = "orange";
			return;
		}
		this.boxcolor = null;

		if (node.name)
		{
			ctx.font = "20px Arial";
			ctx.textAlign = "left";
			ctx.fillColor = [ 1,1,1,0.75 ];
			ctx.fillText( node.name, 0,-40);
		}
	}
}

LGraphSpaceNodeMove.prototype.onGetInputs = function() {
	return [ [ "change_state",LiteGraph.ACTION ] ];
}

export default LGraphSpaceNodeMove;
