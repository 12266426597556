import ROOM from "@src/engine/room";

function FormattedText(text, max_width, options = {})
{
	this._prev_text = null;
	this.text = text;
	this.max_width = max_width;
	this.scale = 1;
	this.position = [ 0, 0 ];
	this.color = "black";
	this.max_font_size = options.max_font_size || 200;
	this.font_size = options.max_font_size || 200;
	this.textBaseline = options.textBaseline || "middle";
	this.text_align = options.text_align || "center";
	this.rotation = 0;
	this.fontface = options.font;
	this._ready_to_render = false; // false before the right font_size is found
	this._words = [];
	this._lines = [];
	this.line_height_factor = 1;
	this._largest_line_index = 0;
}

FormattedText.prototype._parseWords = function(ctx)
{
	this._largest_word_index = 0;
	var words = this._wordsArray(this.text);
	var largest_width = 0;
	for ( var i = 0; i < words.length; i++ )
	{
		var measure = ctx.measureText(words[i]);
		var width = measure.width;
		if ( width > largest_width )
		{
			this._largest_word_index = i;
			largest_width = width;
		}
		this._words[i] = {
			text_content: words[i],
			width: width,
			length: words[i].length
		};
	}
}

FormattedText.prototype._makeLinesFromWords = function(ctx)
{
	if ( this._words.length === 0 )
	{
		this._lines = [];
	}
	else
	{
		var space_width = ctx.measureText(" ").width;
		var total_length = this.text.length;
		var max_char = 5;
		if ( total_length <= 10 )
		{
			max_char = 5;
		}
		else if ( total_length <= 20 )
		{
			max_char = 7;
		}
		else if ( total_length <= 30 )
		{
			max_char = 10;
		}
		else {
			max_char = 15;
		}
		var current_line_index = 0;
		var max_line_width = 0;
		for ( var i = 0; i < this._words.length; i++ )
		{
			var word = this._words[i];
			if (this._lines[current_line_index] === undefined)
			{
				this._lines[current_line_index] = {
					text_content: word.text_content,
					length: word.length,
					width: word.width
				};
			}
			else if ( this._lines[current_line_index].length + word.length < max_char )
			{
				this._lines[current_line_index].text_content += " " + word.text_content;
				this._lines[current_line_index].length += word.length + 1;
				this._lines[current_line_index].width += word.width + space_width;
			}
			else {
				current_line_index++;
				this._lines[current_line_index] = {
					text_content: word.text_content,
					length: word.length,
					width: word.width
				};
			}
			if ( this._lines[current_line_index].width > max_line_width )
			{
				max_line_width = this._lines[current_line_index].width;
				this._largest_line_index = current_line_index;
			}
		}
	}
}

FormattedText.prototype._dichotomicFontSizeSearch = function(ctx, text, fontface, min, max, desiredWidth)
{
	if ( max - min < 1)
	{
		this._ready_to_render = true;
		return min;
	}
	var test = min + ( ( max - min ) / 2 ); // Find half interval
	ctx.font = test + "px " + fontface;
	var measureTest = ctx.measureText(text).width;

	return ( measureTest > desiredWidth )
		? this._dichotomicFontSizeSearch(ctx, text, fontface, min, test, desiredWidth)
		: this._dichotomicFontSizeSearch(ctx, text, fontface, test, max, desiredWidth);
}

FormattedText.prototype.render = function(ctx)
{
	this.updateContent(ctx);

	ctx.font = this.font_size * this.scale + "px " + this.fontface;
	ctx.textAlign = this.text_align;
	ctx.textBaseline = this.textBaseline;
	ctx.fillStyle = this.color;

	ctx.save();
	if ( this.text_align === "left")
	{
		ctx.translate( this.position[0] - this.max_width / 2, this.position[1] );
	}
	else
	{
		ctx.translate( this.position[0], this.position[1] );
	}
	if ( this.rotation )
	{
		ctx.rotate( this.rotation );
	}
	for (var i = 0; i < this._lines.length; i++)
	{
		var line_height = this.line_height_factor * this.font_size;
		var y = (- (this._lines.length * line_height ) / 2) + line_height / 2 + line_height * i;

		ctx.fillText(this._lines[i].text_content, 0, y);
	}

	ctx.restore();
}

FormattedText.prototype.updateContent = function(ctx)
{
	if ( !ROOM.webfonts_loaded )
	{
		this._ready_to_render = false;
		return;
	}

	// Detects if the text has changed
	if ( this._prev_text !== this.text )
	{
		this._ready_to_render = false;
		this.font_size = this.max_font_size;
		this._prev_text = this.text = this.text.trim();
		this._words = [];
		this._lines = [];
		this._parseWords(ctx);
		this._makeLinesFromWords(ctx);
	}

	if ( !this._ready_to_render )
	{
		ctx.font = this.max_font_size + "px " + this.fontface;
		if ( ctx.measureText(this.text).width > this.max_width )
		{
			this._ready_to_render = false;
			this.font_size = this._dichotomicFontSizeSearch(ctx, this._lines[this._largest_line_index].text_content, this.fontface, 0, this.max_font_size, this.max_width);
		}
		else
		{
			this._ready_to_render = true;
		}
	}
}


// Returns array of words in text
// For CJK languages almost every char is a word,
// for other languages words are separated by spaces
FormattedText.prototype._wordsArray = function(text)
{
	// Test for CJK characters
	if (/[\u3400-\u9FBF]/.test(text))
	{
		// Contains CJK characters
		var words = []
		const characters = text.split("");
		for (var i = 0; i <= characters.length - 1; i++)
			if (!this._containsPunctations(characters[i + 1])) {
				// Next character is "normal"
				words.push(characters[i])
			} else {
				// Next character isn't a single word
				words.push(characters[i] + characters[i + 1])
				i++;
			}
		return words
	}
	else
	{
		// Other language
		// Converts returns in spaces, removes double spaces
		text = text.replace(/(\r\n|\n|\r)/gm," ").replace(/\s+/g," ")
		// Simply split by spaces
		return text.split(" ")
	}
}

// Returns true if text contains puntaction characters
FormattedText.prototype._containsPunctations = function(text)
{
	// Test string against regexp for many punctactions characters, including CJK ones
	return /[\uFF01-\uFF07,\u0021,\u003F,\u002E,\u002C,\u003A,\u003B,\uFF1A-\uFF1F,\u3002,\uFF0C-\uFF0E,\u2000-\u206F,\uFFED-\uFFEF,\u0028,\u0029]/.test(text)
}

export default FormattedText;
