class RoomProxy {
    static getProxies() {
        return RoomProxy.proxies;
    }
    static setProxies(value) {
        RoomProxy.proxies = value;
    }
    static getCurrentProxy() {
        return typeof (window) !== "undefined" ? (this.proxies[location.host] || "") : null;
    }
}
//setup a default proxy
RoomProxy.proxies = {
    "tamats.com": "https://tamats.com/proxy.php?url="
};
export default RoomProxy;
