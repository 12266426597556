import clamp from "@src/math/clamp";
import { DataType } from "./DataType";
//import { TYPE_SIZES } from "@src/libs/rendeer/TYPE_SIZES";
import { mat3, mat4, quat, vec2, vec3, vec4 } from "gl-matrix";
import { RD } from "@src/libs/rendeer";

//a Track stores a set of keyframes that affect a single property of an object (usually transform info from nodes)
function Track()
{
	this.enabled = true;
	this.target_node = ""; //id of target name
	this.target_property = ""; //name of property
	this.type = DataType.SCALAR; //value_size per keyframe is derived from this type using RD.TYPE_SIZES[ type ]. 0 means an object/string/boolean
	this.data = [];
	this.packed_data = false;//tells if data is in Array format (easy to manipulate) or Typed Array format (faster)

	this._target = null; //the object that will receive the samples
}

Track.TYPE_SIZES = [ 0, 1, 2, 3, 4, 4, 9, 10, 16, 0 ]; //cant make it work with the inclusion

//Animation.Track = Track;

Object.defineProperty( Track.prototype, "value_size", {
	set: function(v)
	{
		throw("cannot be set, use type instead");
	},
	get: function()
	{
		return Track.TYPE_SIZES[ this.type ];
	}
});


/**
* Adds a new keyframe to this track given a value
* @method addKeyframe
* @param {Number} time time stamp in seconds
* @param {*} value anything you want to store, if omited then the current value is used
* @param {Boolean} skip_replace if you want to replace existing keyframes at same time stamp or add it next to that
* @return {Number} index of keyframe
*/
Track.prototype.addKeyframe = function( time, value, skip_replace )
{
    if(time === undefined)
        throw("time cannot be undefined");
	var value_size = this.value_size;
	if( value_size > 1 )
		value = new Float32Array( value ); //clone

	//if(this.packed_data)
	//	this.unpackData();

	for(var i = 0; i < this.data.length; ++i)
	{
		if(this.data[i][0] < time )
			continue;
		if(this.data[i][0] == time && !skip_replace )
			this.data[i][1] = value;
		else
			this.data.splice(i,0, [time,value]);
		return i;
	}

	this.data.push( [time,value] );
	return this.data.length - 1;
}

Track.prototype.deleteKeyframe = function( index )
{
	if(index < this.data.length)
		this.data.splice(index,1);
}

/**
* returns a keyframe given an index
* @method getKeyframe
* @param {Number} index
* @return {Array} the keyframe in [time,data] format
*/
Track.prototype.getKeyframe = function( index )
{
	if(index < 0 || index >= this.data.length)
	{
		console.warn("keyframe index out of bounds");
		return null;
	}

	var value_size = Track.TYPE_SIZES[ this.type ];

	if(this.packed_data)
	{
		var pos = index * (1 + value_size );
		if(pos > (this.data.length - value_size) )
			return null;
		return [ this.data[pos], this.data.subarray(pos+1, pos+value_size+1) ];
		//return this.data.subarray(pos, pos+this.value_size+1) ];
	}

	return this.data[ index ];
}

/**
* Returns nearest index of keyframe with time equal or less to specified time (Dichotimic search)
* @method findTimeIndex
* @param {number} time
* @return {number} the nearest index (lower-bound)
*/
Track.prototype.findTimeIndex = function(time)
{
	var data = this.data;
	if(!data || data.length == 0)
		return -1;

	var value_size = Track.TYPE_SIZES[ this.type ];

	if(this.packed_data)
	{
		var offset = value_size + 1; //data size plus timestamp
		var l = data.length;
		var n = l / offset; //num samples
		var imin = 0;
		var imid = 0;
		var imax = n;

		if(n == 0)
			return -1;
		if(n == 1)
			return 0;

		//time out of duration
		if( data[ (imax - 1) * offset ] < time )
			return (imax - 1);

		//dichotimic search
		// continue searching while [imin,imax] are continuous
		while (imax >= imin)
		{
			// calculate the midpoint for roughly equal partition
			imid = ((imax + imin)*0.5)|0;
			var t = data[ imid * offset ]; //get time
			if( t == time )
				return imid; 
			//when there are no more elements to search
			if( imin == (imax - 1) )
				return imin;
			// determine which subarray to search
			if (t < time)
				// change min index to search upper subarray
				imin = imid;
			else         
				// change max index to search lower subarray
				imax = imid;
		}
		return imid;
	}

	//unpacked data
	var n = data.length; //num samples
	var imin = 0;
	var imid = 0;
	var imax = n;

	if(n == 0)
		return -1;
	if(n == 1)
		return 0;

	//time out of duration
	if( data[ (imax - 1) ][0] < time )
		return (imax - 1);

	while (imax >= imin)
	{
		// calculate the midpoint for roughly equal partition
		imid = ((imax + imin)*0.5)|0;
		var t = data[ imid ][0]; //get time
		if( t == time )
			return imid; 
		//when there are no more elements to search
		if( imin == (imax - 1) )
			return imin;
		// determine which subarray to search
		if (t < time)
			// change min index to search upper subarray
			imin = imid;
		else         
			// change max index to search lower subarray
			imax = imid;
	}

	return imid;
}

//returns value given a time and a interpolation method
Track.prototype.getSample = function( time, interpolate, result )
{
	if(!this.data || this.data.length === 0)
		return undefined;

	if(this.packed_data)
		return this.getSamplePacked( time, interpolate, result );
	return this.getSampleUnpacked( time, interpolate, result );
}

//used when sampling from a unpacked track (where data is an array of arrays)
Track.prototype.getSampleUnpacked = function( time, interpolation, result )
{
	var value_size = Track.TYPE_SIZES[ this.type ];
	var duration = this.data[ this.data.length - 1 ][0];
	time = clamp( time, 0, duration );

	var index = this.findTimeIndex( time );
	if(index === -1)
		index = 0;

	var index_a = index;
	var index_b = index + 1;
	var data = this.data;

	if(!interpolation || value_size == 0 || (data.length == 1) || index_b == data.length || (index_a == 0 && this.data[0][0] > time)) //(index_b == this.data.length && !this.looped)
		return this.data[ index ][1];

	var a = data[ index_a ];
	var b = data[ index_b ];

	var t = (b[0] - time) / (b[0] - a[0]);

	//multiple data
	if( value_size > 1 )
	{
		result = result || this._result;
		if( !result || result.length != value_size )
			result = this._result = new Float32Array( value_size );
	}

	if(interpolation === RD.LINEAR)
	{
		if( value_size == 1 )
			return a[1] * t + b[1] * (1-t);

		return RD.interpolateLinear( a[1], b[1], t, result, this.type, value_size, this );
	}
	else if(interpolation === RD.CUBIC)
	{
		var pre_a = index > 0 ? data[ index - 1 ] : a;
		var post_b = index < data.length - 2 ? data[ index + 2 ] : b;

		if(value_size === 1)
			return RD.EvaluateHermiteSpline(a[1],b[1],pre_a[1],post_b[1], 1 - t );

		result = RD.EvaluateHermiteSplineVector( a[1], b[1], pre_a[1], post_b[1], 1 - t, result );

		if(this.type == RD.QUAT)
		{
			quat.slerp( result, b[1], a[1], t ); //force quats without CUBIC interpolation
			quat.normalize( result, result );
		}
		else if(this.type == RD.TRANS10)
		{
			var rotR = result.subarray(3,7);
			var rotA = a[1].subarray(3,7);
			var rotB = b[1].subarray(3,7);
			quat.slerp( rotR, rotB, rotA, t );
			quat.normalize( rotR, rotR );
		}

		return result;
	}

	return null;
}

//used when sampling from a packed track (where data is a typed-array)
Track.prototype.getSamplePacked = function( time, interpolation, result )
{
	if(!this.data.length)
		return null;

	var value_size = Track.TYPE_SIZES[ this.type ];
	var duration = this.data[ this.data.length - value_size - 1 ];
	time = clamp( time, 0, duration );

	var index = this.findTimeIndex( time );
	if(index == -1)
		index = 0;

	var offset = (value_size+1);
	var index_a = index;
	var index_b = index + 1;
	var data = this.data;
	var num_keyframes = data.length / offset;

	if( !interpolation || num_keyframes == 1 || index_b == num_keyframes || (index_a == 0 && this.data[0] > time)) //(index_b == this.data.length && !this.looped)
		return this.getKeyframe( index )[1];

	//multiple data
	if( value_size > 1 )
	{
		result = result || this._result;
		if( !result || result.length != value_size )
			result = this._result = new Float32Array( value_size );
	}

	var a = data.subarray( index_a * offset, (index_a + 1) * offset );
	var b = data.subarray( index_b * offset, (index_b + 1) * offset );

	var t = (b[0] - time) / (b[0] - a[0]);

	if(interpolation === RD.LINEAR)
	{
		if( value_size == 1 ) //simple case
			return a[1] * t + b[1] * (1-t);

		var a_data = a.subarray(1, value_size + 1 );
		var b_data = b.subarray(1, value_size + 1 );
		return RD.interpolateLinear( a_data, b_data, t, result, this.type, value_size, this );
	}
	
	if(interpolation === RD.CUBIC)
	{
		if( value_size === 0 ) //CUBIC not supported in interpolators
			return a[1];

		var pre_a = index > 0 ? data.subarray( (index-1) * offset, (index) * offset ) : a;
		var post_b = index_b < (num_keyframes - 1) ? data.subarray( (index_b+1) * offset, (index_b+2) * offset ) : b;

		if( value_size === 1 )
			return RD.EvaluateHermiteSpline( a[1], b[1], pre_a[1], post_b[1], 1 - t );

		var a_value = a.subarray(1,offset);
		var b_value = b.subarray(1,offset);

		result = RD.EvaluateHermiteSplineVector( a_value, b_value, pre_a.subarray(1,offset), post_b.subarray(1,offset), 1 - t, result );

		if(this.type == RD.QUAT )
		{
			quat.slerp( result, b_value, a_value, t );
			quat.normalize( result, result ); //is necesary?
		}
		else if(this.type == RD.TRANS10 )
		{
			var rotR = result.subarray(3,7);
			var rotA = a_value.subarray(3,7);
			var rotB = b_value.subarray(3,7);
			quat.slerp( rotR, rotB, rotA, t );
			quat.normalize( rotR, rotR ); //is necesary?
		}

		return result;
	}

	return null;
}

//it samples and applies the result to the given node
//root can be a RD.SceneNode or a RD.Skeleton (if skeleton only mat4 work)
Track.prototype.applyTrack = function( root, time, interpolation )
{
	if(!root)
		return;

	//reads value stored in track
	var sample = this.getSample( time, interpolation );

	//tryes to apply it to target
	if( root.applyAnimationValue )
		root.applyAnimationValue( this.target_node, this.target_property, sample );
	else if( root.constructor === RD.SceneNode ) //apply to scene ierarchy
	{
		var node = null;
		if( root.name == this.target_node)
			node = root;
		else
			node = root.findNodeByName( this.target_node );
		if(node)
		{
			this._node = node;
			node[ this.target_property ] = sample;
		}
	}
	else if( root.constructor === RD.Skeleton )
	{
		var bone = root.getBone( this.target_node );
		if( bone && this.type == RD.MAT4 )
		{
			this._bone = bone;
			bone.model.set( sample );
		}
	}

	return sample;
}

//in case time was modified
Track.prototype.sortKeyframes = function()
{
	this.data.sort( function(a,b){ return a[0] - b[0] });
}

Track.prototype.serialize = function()
{
	//if( this.packed_data ) //TODO
		//unpack

	var o = {
		enabled: this.enabled,
		target_node: this.target_node, 
		target_property: this.target_property, 
		type: this.type,
		data: this.data.constructor == Array ? this.data.concat() : typedArrayToArray(this.data), //clone!
		packed_data: this.packed_data
	};
	return o;
}

Track.prototype.configure = function(o)
{
	this.enabled = o.enabled;
	this.target_node = o.target_node;
	this.target_property = o.target_property;
	if(o.property) //in case it comes as "nodename/propname"
	{
		var index = o.property.indexOf("/");
		this.target_node = o.property.substr(0,index);
		this.target_property = o.property.substr(index+1);
	}
	if(o.type != null)
	{
		if(o.type.constructor === String)
			this.type = RD.TYPES[ o.type.toUpperCase() ] || 0;
		else
			this.type = o.type;
	}

	//clone data
	if(o.packed_data || o.data.constructor === Float32Array) 
	{
		this.packed_data = true;
		this.data = new Float32Array( o.data );
	}
	else
	{
		this.packed_data = false;
		this.data = o.data.concat();
	}
}

RD.interpolateLinear = function( a, b, t, result, type, value_size, track )
{
	if(value_size == 1)
		return a * t + b * (1-t);

	result = result || track._result;

	if(!result || result.length != value_size)
		result = track._result = new Float32Array( value_size );

	switch( type )
	{
		case RD.QUAT:
			quat.slerp( result, b, a, t );
			quat.normalize( result, result );
			break;
		case RD.TRANS10: 
			for(var i = 0; i < 3; i++) //this.value_size should be 10
				result[i] = a[i] * t + b[i] * (1-t);
			for(var i = 7; i < 10; i++) //this.value_size should be 10
				result[i] = a[i] * t + b[i] * (1-t);
			var rotA = a.subarray(3,7);
			var rotB = b.subarray(3,7);
			var rotR = result.subarray(3,7);
			quat.slerp( rotR, rotB, rotA, t );
			quat.normalize( rotR, rotR );
			break;
		default:
			for(var i = 0; i < value_size; i++)
				result[i] = a[i] * t + b[i] * (1-t);
	}
	return result;
}

RD.EvaluateHermiteSpline = function( p0, p1, pre_p0, post_p1, s )
{
	var s2 = s * s;
	var s3 = s2 * s;
	var h1 =  2*s3 - 3*s2 + 1;          // calculate basis function 1
	var h2 = -2*s3 + 3*s2;              // calculate basis function 2
	var h3 =   s3 - 2*s2 + s;         // calculate basis function 3
	var h4 =   s3 -  s2;              // calculate basis function 4
	var t0 = p1 - pre_p0;
	var t1 = post_p1 - p0;

	return h1 * p0 + h2 * p1 + h3 * t0 + h4 * t1;
}

RD.EvaluateHermiteSplineVector = function( p0, p1, pre_p0, post_p1, s, result )
{
	result = result || new Float32Array( result.length );

	var s2 = s * s;
	var s3 = s2 * s;
	var h1 =  2*s3 - 3*s2 + 1;          // calculate basis function 1
	var h2 = -2*s3 + 3*s2;              // calculate basis function 2
	var h3 =   s3 - 2*s2 + s;         // calculate basis function 3
	var h4 =   s3 -  s2;              // calculate basis function 4

	for(var i = 0, l = result.length; i < l; ++i)
	{
		var t0 = p1[i] - pre_p0[i];
		var t1 = post_p1[i] - p0[i];
		result[i] = h1 * p0[i] + h2 * p1[i] + h3 * t0 + h4 * t1;
	}

	return result;
}

export default Track;