function WhiteboardRow(whiteboard, index, options = {})
{
	this.whiteboard = whiteboard;
	this.line_is_hovered = false;
	this.rowCountMax = 7; // max number of columns
	this.line_is_dragged = false;
	this.color = "rgba(0, 200, 0, 0.5)";
	this.line_width = 4;
	this.columnHeaderHeight = 200;
	this.y = options.y ?? null;
	this.index = index;
	this.resizing = false;
	this.min_row_height = 30;
}

WhiteboardRow.prototype.update = function(i, header_offset, rowHeight)
{
	// reset column line hover flag
	this.line_is_hovered = false;

	// default position
	if (this.y === null) {
		this.y = ((this.index + 1) * rowHeight) + header_offset;
	}
}

WhiteboardRow.prototype.render = function(ctx, tex, GUI, mouse)
{
	ctx.beginPath();
	ctx.moveTo(0, this.y);
	ctx.lineTo(tex.width, this.y);
	ctx.stroke();

	// create hover area for lines
	const lineHoverArea = GUI.HoverArea(0, this.y - 10, tex.width, this.line_width + 10);
	if (lineHoverArea === GLUI.HOVER)
	{
		this.line_is_hovered = true;
	}

	if (lineHoverArea === GLUI.CLICKED)
	{
		this.resizing = true;
		this.whiteboard.row_line_is_dragged = true;
	}

	if ( this.resizing )
	{
		if ( mouse.dragging === true )
		{
			let nextRowY = this.whiteboard.row_area_height + this.whiteboard.row_header_offset;
			if (typeof this.whiteboard.page.rows[this.index + 1] !== "undefined") {
				nextRowY = this.whiteboard.page.rows[this.index + 1].y;
			}

			let prevRowY = 0;
			if (typeof this.whiteboard.page.rows[this.index - 1] !== "undefined") {
				prevRowY = this.whiteboard.page.rows[this.index - 1].y;
			}

			// prevent rows being smaller than minRowHeight
			if (
				(nextRowY - mouse.position[1] >= this.min_row_height) &&
                (mouse.position[1] - prevRowY >= this.min_row_height)
			) {
				this.y = mouse.position[1];
			}
		}
		else
		{
			this.resizing = false;
			this.whiteboard.row_line_is_dragged = false;
		}
	}
}

export default WhiteboardRow;
