export var DataType;
(function (DataType) {
    DataType[DataType["UNKNOWN"] = 0] = "UNKNOWN";
    DataType[DataType["SCALAR"] = 1] = "SCALAR";
    DataType[DataType["NUMBER"] = 1] = "NUMBER";
    DataType[DataType["VEC2"] = 2] = "VEC2";
    DataType[DataType["VEC3"] = 3] = "VEC3";
    DataType[DataType["VEC4"] = 4] = "VEC4";
    DataType[DataType["QUAT"] = 5] = "QUAT";
    DataType[DataType["MAT3"] = 6] = "MAT3";
    DataType[DataType["TRANS10"] = 7] = "TRANS10";
    DataType[DataType["MAT4"] = 8] = "MAT4";
    DataType[DataType["STRING"] = 9] = "STRING";
})(DataType || (DataType = {}));
