import ROOM from "@src/engine/room";
import { getFullPath } from "@src/engine/Room/file-utils";
import Button from "@src/libs/GLUI/Elements/Button";
import { GL } from "@src/libs/litegl";
import { Camera } from "@src/libs/rendeer/Camera";
import { Material } from "@src/libs/rendeer/Material";
import { Scene } from "@src/libs/rendeer/Scene";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { vec3 } from "gl-matrix";
import Figurine from "./Figurine";


function FigurineManager()
{
	this.enabled = true;
	this.scale = 1;

	this._last_figurine_id = -1;

	this._figurines = [];

	this._node = new SceneNode(); //root

	//material for shadows
	if (!StaticMaterialsTable[ "figurine_shadow" ] )
	{
		var shadow_material = new Material();
		shadow_material.albedo = [ 0,0,0,0.5 ];
		shadow_material.textures.opacity = "textures/gauss_white2.png";
		shadow_material.alphaMode = "BLEND";
		shadow_material.name = "figurine_shadow";
		StaticMaterialsTable[ shadow_material.name ] = shadow_material;
	}

	if (!FigurineManager.db_ready)
		FigurineManager.loadDatabase( FigurineManager.base_folder + "info.json");

	this._table_panel_collapsed = true;

	this._selected_marker_node = new SceneNode();
	this._selected_marker_node.position = [ 0,0.001,0 ];
	this._selected_marker_node.loadGLTF( getFullPath("meshes/disc_marker_texture.glb"), function() {
		var mat = StaticMaterialsTable["SelectionMarker"];
		if (mat)
		{
			mat.opacity = 0.5;
			mat.emissive = [ 6,10,2 ];
			mat.blendMode = "ADD";
		}
	});
}

FigurineManager.componentName = "FigurineManager";
FigurineManager.db_ready = false;
FigurineManager.database = {};
FigurineManager.base_folder = "props/figurines/";

FigurineManager.Figurine = Figurine;

/*
FigurineManager.list = [
	"dwarf.glb",
	"elfarcher.glb",
	"halfling.glb",
	"pawn_barrel.glb",
	"pawn_base.glb",
	"pawn_basic.glb",
	"pawn_coin.glb",
	"pawn_cube.glb",
	"pawn_heart.glb",
	"pawn_house.glb",
	"pawn_mepel.glb",
	"skeleton.glb",
	"undead.glb",
	"wizard.glb"
];
*/

FigurineManager.loadDatabase = function(url)
{
	ROOM.fetchAsset(url)
		.then(function(data) { return data.json(); })
		.then(function(json) {
			FigurineManager.database = json;
			console.debug("database loaded",FigurineManager.database);
		});
}

FigurineManager.prototype.onAdded = function(parent)
{
	parent.node.addChild( this._node );
	LEvent.bind( parent, "tableSetScroll", this.onTableScroll, this );
}

FigurineManager.prototype.onRemoved = function(parent)
{
	parent.node.removeChild( this._node );
	LEvent.unbind( parent, "tableSetScroll", this.onTableScroll, this );
}

FigurineManager.prototype.onTableScroll = function()
{
	var tabletop = this.entity.tabletop;
	if (!tabletop)
		return;
	for ( var i = 0; i < this._figurines.length; ++i )
	{
		var fig = this._figurines[i];
		//TODO
	}
}

FigurineManager.prototype.serialize = function(o)
{
	o.enabled = this.enabled;
	o.last_figurine_id = this._last_figurine_id;

	//figurines
	var figurines = [];
	for ( var i = 0; i < this._figurines.length; ++i )
	{
		var fig = this._figurines[i];
		var data = {};
		fig.serialize(data)
		figurines.push(data);
	}
	o.figurines = figurines;
	o.scale = this.scale;
}

FigurineManager.prototype.configure = function(o)
{
	this.enabled = o.enabled;

	this.scale = o.scale || 1;

	//figurines
	this.clearFigurines();
	if ( o.figurines )
		for ( var i = 0; i < o.figurines.length; ++i )
		{
			var fig = new FigurineManager.Figurine();
			this.addFigurine( fig );
			fig.configure( o.figurines[i] );
		}

	this._last_figurine_id = o.last_figurine_id;
}

FigurineManager.prototype.preRender = function(view)
{
	//move marker up and down
	var time = getTime()*0.001;
	this._selected_marker_node.position = [ 0,0.001 + (Math.sin(time)*0.5 + 0.5)*0.002,0 ];
	if (this._selected_figurine)
	{
		var scale = this._selected_figurine.scale * 0.1;
		this._selected_marker_node.scaling = [ scale,scale,scale ];
	}

	//update figurines
	for ( var i = 0; i < this._figurines.length; ++i )
	{
		var fig = this._figurines[i];
		fig.preRender( view );
	}
}

FigurineManager.prototype.onFigurineSelected = function( figurine )
{
	this._selected_figurine = figurine;

	if ( this._selected_marker_node.parentNode )
		this._selected_marker_node.parentNode.removeChild( this._selected_marker_node );

	if (!figurine)
		return;

	figurine._node.addChild( this._selected_marker_node );
}

FigurineManager.prototype.spawnFigurine = function( url, skip_event )
{
	var figurine = new FigurineManager.Figurine();
	figurine.setURL( url );
	figurine.base_scale = 0.075;

	this.addFigurine( figurine );

	if (!skip_event)
		this.syncData({
			action: "figurine",
			subaction: "add",
			info: figurine.serialize({})
		});

	return figurine;
}

//figurines stuff
FigurineManager.prototype.findFigurine = function(figurine_id)
{
	for (var i = 0; i < this._figurines.length; ++i )
	{
		var figurine = this._figurines[i];
		if (figurine.id === figurine_id )
			return figurine;
	}
	return null;
}

FigurineManager.prototype.addFigurine = function(figurine)
{
	this._figurines.push(figurine);
	figurine._manager = this;
	figurine.id = this._last_figurine_id++;
	this._node.addChild( figurine._node );
}

FigurineManager.prototype.removeFigurine = function(figurine, skip_event)
{
	if (figurine._manager !== this)
	{
		console.error("figurine doesnt belong to this tabletop");
		return;
	}

	var index = this._figurines.indexOf(figurine);
	if (index === -1)
		return;
	this._figurines.splice( index, 1 );
	figurine._manager = null;
	this._node.removeChild( figurine._node );

	if ( !skip_event )
		this.syncData({
			action: "figurine",
			subaction: "remove",
			figurine_id: figurine.id
		});
}

FigurineManager.prototype.clearFigurines = function()
{
	this._last_figurine_id = 0;
	while ( this._figurines.length )
		this.removeFigurine( this._figurines[ this._figurines.length - 1 ] );
}

FigurineManager.prototype.deleteSelection = function()
{
	if ( !this._selected_figurine )
		return;
	this.removeFigurine( this._selected_figurine );
	this._selected_figurine = null;
}

FigurineManager.prototype.renderTableContent = function( controller )
{
	var camera = controller.view._last_camera;
	var space = controller.space;
	var ctx = gl;

	var bubble_size = 0.03;
	var pos3D = vec3.create();
	var bubbles = [];

	for ( var i = 0; i < this._figurines.length; ++i )
	{
		var fig = this._figurines[i];
		if (!fig.owner_id)
			continue;
		var height = fig.base_scale * fig.scale * fig._max_height;
		fig.localToGlobal([ 0,height,0 ], pos3D);
		var pos2D = camera.project( pos3D, null, fig._screenpos );
		if ( pos2D[2] > 1 )
			continue;
		var size = fig._screenpos[3] = camera.computeProjectedRadius( pos3D, bubble_size * fig.scale,null,true );

		var participant = space.getParticipant(fig.owner_id);
		if (!participant)
			continue;

		var tex = participant.getBubbleTexture(true);
		if (!tex)
			continue;

		fig._bubble_texture = tex;
		bubbles.push( fig );
	}

	//sort by Z
	bubbles.sort(function(a,b) { return b._screenpos[2] - a._screenpos[2]; });

	//draw them
	for (var i = 0; i < bubbles.length; ++i)
	{
		var fig = bubbles[i];
		var size = fig._screenpos[3];
		ctx.drawImage( fig._bubble_texture, fig._screenpos[0]-size*0.5, (gl.canvas.height - fig._screenpos[1])-size*0.5, size, size );
	}
}

FigurineManager.prototype.renderTableUI = function( controller )
{
	var view = controller.view;

	var x = 0;
	var y = controller._last_panel_y;
	var w = 300;
	var h = 40;

	if (this._table_panel_collapsed) //hidden
		x = x - w + h + 10;

	var ctx = gl;
	GUI.TranslucentPanel(x-40,y,w+40,h,20,[ 0.6,0.6,0.6,1 ]);

	ctx.globalAlpha = 0.5;
	if (GUI.DrawIcon( x + w - 24, y + h * 0.5, 0.5, [ 4,9 ],false,null,null,true ) === GLUI.CLICKED )
	{
		this._table_panel_collapsed = !this._table_panel_collapsed;
	}
	ctx.globalAlpha = 1;

	if ( GUI.CircleIconButton( x+20,y, GLUI.icons.plus, 1 ) )
		this.showDialog();

	if ( this._selected_figurine )
		this.renderToolbar(controller,view);

	//scaling
	this.scale = GUI.Slider( x + 100,y,100,40, this.scale,0.1,2 );

	controller._last_panel_y += 70;
}

FigurineManager.prototype.renderToolbar = function(controller,view)
{
	if (!this._selected_figurine)
		return;

	//toolbar
	var ctx = gl;
	var centerx = gl.canvas.width * 0.5;
	var y = gl.canvas.height - 200;
	var w = 540;
	var h = 74;
	var x = centerx - w*0.5;
	GUI.TranslucentPanel(x,y,w,h,40,[ 0.7,0.7,0.7,1 ]);

	//logo
	ctx.globalAlpha = 0.5;
	GUI.DrawIcon( x + 36, y + 36, 0.5, [ 4,9 ] );
	ctx.globalAlpha = 1;

	//controls
	this._selected_figurine.color = GUI.ColorPalette(x + 100,y+36,20, this._selected_figurine.color, Figurine.palette );
	if ( GUI.value_changed )
		this.syncData({
			action: "figurine",
			subaction: "color",
			figurine_id: this._selected_figurine.id,
			value: this._selected_figurine.color
		});

	//assigned
	if ( GUI.CircleIconButton( x + 130, y + 8, [ 7,0 ] ) )
		this._owner_toolbar_visible = !this._owner_toolbar_visible;

	//size
	this._selected_figurine.scale = GUI.Slider( x + 200,y+10,140,40, this._selected_figurine.scale,0.5,2 );

	//trash
	if ( GUI.CircleIconButton( x + w - 130, y + 10, GLUI.icons.trash, [ 0.8,0,0.2,1 ], true ) )
	{
		this.deleteSelection();
		return;
	}

	//close
	if ( GUI.CircleIconButton( x + w - 70, y + 10, GLUI.icons.x, 1 ) )
		controller.selectItem(null);

	if (this._owner_toolbar_visible)
		this.drawParticipantsToolbar(y - 120);
}

FigurineManager.prototype.drawParticipantsToolbar = function(y)
{
	var circle_size = 80;
	var space = this.entity.space;
	var w = (space.participants.length + 1) * (circle_size) + 15;
	var h = circle_size + 20;
	var x = gl.canvas.width * 0.5 - w * 0.5;
	var ctx = gl;

	GUI.TranslucentPanel(x,y,w,h,h*0.5,[ 0.7,0.7,0.7,1 ]);
	GUI.DrawIcon( x + w*0.5, y + 9 + h, 1, [ 3,3 ], true );

	ctx.tintImages = true;

	for (var i = 0; i <= space.participants.length; ++i)
	{
		var participant = space.participants[i];

		var itemx = x + 10 + (circle_size) * i;
		var itemy = y + 10;
		var r = GUI.HoverArea( itemx, itemy, circle_size, circle_size);
		if (participant && this._selected_figurine && this._selected_figurine.owner_id === participant.id )
			GUI.DrawIcon( itemx + circle_size*0.5+2, itemy + circle_size*0.5-2, 1.8, GLUI.icons.circle,false,[ 0.5,0.7,1,0.5 ] );

		if (participant)
		{
			participant._force_bubble_update = true;
			var tex = participant._bubble_texture || participant.getProfileTexture(true);
			ctx.fillColor = r === GLUI.HOVER ? [ 1.5, 1.5, 1.5, 1 ] : [ 1, 1, 1, 1 ];
			if (tex)
				ctx.drawImage( tex, itemx, itemy - 5, circle_size, circle_size );
		}
		else
		{
			GUI.CircleIconButton( itemx+10, itemy+10, [ 12,1 ], [ 0,0,0,0.1 ],true );
		}

		if (r === GLUI.CLICKED )
		{
			this._selected_figurine.owner_id = participant ? participant.id : null;
			this._owner_toolbar_visible = false;
			this.syncData({
				action: "figurine",
				figurine_id: this._selected_figurine.id,
				property: "owner_id",
				value: this._selected_figurine.owner_id
			});
		}
	}

	ctx.fillColor = [ 1,1,1,1 ];
	ctx.tintImages = false;
}

FigurineManager.prototype.getTableItems = function( items )
{
	items.push.apply( items, this._figurines );
}

FigurineManager.prototype.onSyncData = function( data )
{
	if (data.action === "figurine" )
	{
		if (data.subaction === "add" )
		{
			var figurine = new Figurine();
			this.addFigurine( figurine );
			figurine.configure( data.info );
			return;
		}

		var figurine = this.findFigurine( data.figurine_id );
		if (!figurine)
			return;

		if (data.subaction === "remove" )
		{
			this.removeFigurine( figurine, true );
			return;
		}

		switch ( data.property )
		{
		case "position": figurine.move( data.value, 0.1, true ); break;
		case "rotation": figurine.orientTo( data.value, 0.1, true ); break;
		default: if ( figurine[ data.property ] !== undefined )
			figurine[ data.property ] = data.value;
			break;
		}
	}
}

FigurineManager.prototype.onRenderExtraInspector = function( ctx,x,y,w,h, editor )
{
	//return;

	if ( Button.call(GUI,x,y,w,20,"Clear Figurines") )
		this.clearFigurines();
	y+=24;

	if ( Button.call(GUI,x,y,w,20,"Recompute Previews") )
	{
		this.recomputePreviews();
	}

}

FigurineManager.prototype.onTableKeyDown = function(e)
{
	if (e.code === "Delete" )
	{
		if ( this._selected_figurine )
		{
			this.deleteSelection();
			return true;
		}
	}

	if (e.code === "KeyD" && e.shiftKey )
	{
		if ( this._selected_figurine )
		{
			var figurine = new FigurineManager.Figurine();
			this.addFigurine(figurine); //must be added first!
			var data = this._selected_figurine.serialize({});
			delete data.id;
			figurine.configure( data );
			figurine.position = [ figurine.position[0] + 0.03, figurine.position[1],figurine.position[2] ];
			//sync
			this.syncData({
				action: "figurine",
				subaction: "add",
				info: figurine.serialize({})
			});
		}
	}
	else
		return false;
	return true;
}

FigurineManager.prototype.generateListJSON = function()
{
	for (var i in FigurineManager.list)
	{
		var item = FigurineManager.list[i];
		var path = FigurineManager.base_folder + item;

		var node = new SceneNode();
		node.name = item;
		node.loadGLTF( getFullPath( path ) );

		var info = {
			name: ROOM.removeExtension( item ),
			url: path,
			node: node
		};
		FigurineManager.database[ info.name ] = info;
	}
}

FigurineManager.prototype.recomputePreviews = function()
{
	var texture = new GL.Texture(512,512);
	var fbo = new GL.FBO([ texture ]);
	var view = xyz.view;
	var renderer = view.renderer;
	var scene = new Scene();
	var camera = new Camera();
	camera.lookAt([ -0.5,2,2 ],[ 0,0.5,0 ],[ 0,1,0 ]);
	camera.orthographic(0.75,0.1,100,1);

	var editor = xyz.editor_controller;

	for (var i in FigurineManager.database)
	{
		var info = FigurineManager.database[i];
		if (info.preview)
			continue;

		if (info.node.loading)
			continue;

		fbo.bind();
		gl.clearColor(0,0,0,0);
		gl.clear( WebGL2RenderingContext.COLOR_BUFFER_BIT | WebGLRenderingContext.DEPTH_BUFFER_BIT );

		//draw here
		view.pbrpipeline.environment_texture = null;
		view.pbrpipeline.environment_sh_coeffs = null;
		view.pbrpipeline.gamma = 1.0;
		view.pbrpipeline.skip_background = true;
		scene.root.addChild( info.node );
		renderer.render( scene, camera, null, 0xFFFF, view.pbrpipeline, true );
		scene.root.removeChild( info.node );

		fbo.unbind();

		//save to disk
		var file = texture.toBlob(true);
		var filename = FigurineManager.base_folder + info.name + ".png";
		editor.saveFileInBackend( filename, file );
		info.preview_url = filename;
	}

	console.debug(FigurineManager.database);
}

FigurineManager.prototype.showDialog = function()
{
	var that = this;
	var html = ROOM.createHTMLDialog();

	for (var i in FigurineManager.database)
	{
		var item = FigurineManager.database[i];
		html.addItem( item.name, getFullPath( item.preview_url ), inner, item );
	}

	function inner(name, item)
	{
		//add figurine
		that.spawnFigurine( item.url );
		html.close();
	}
}

export default FigurineManager;
