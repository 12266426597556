import { RoomController } from "@src/controllers/RoomController";
import {
	CommonButtonParams,
	frameSize,
	getBackgroundColor,
	getHoverColor,
	getLinesGradientStartColor,
	getResizeButtonParams
} from "@src/controllers/SelfView/CommonButtonParams";
import { makeSliderTheme } from "@src/controllers/SelfView/SelfViewSliderTheme";
import { shouldUseLargeControls } from "@src/controllers/SelfView/shouldUseLargeControls";
import { RoomParticipant } from "@src/engine/Participant/RoomParticipant";
import { RoomComponents } from "@src/engine/RoomComponents";
import { ThemeDark } from "@src/libs/GLUI/Button/ThemeDark";
import { ThemeLight } from "@src/libs/GLUI/Button/ThemeLight";
import { Material } from "@src/libs/rendeer/Material";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import clamp from "@src/math/clamp";

//To configure how others see yourself
export class RoomSelfView extends RoomController {
	constructor(launcher, space, view, _settings) {
		super();

		this.xyz = this.launcher = launcher;
		this.name = "selfview";

		this.space = space;
		this.room = space; //legacy
		this.view = view;

		this.show_guide = false;
		this.allow_positioning = false;
		this.show_other_people = false;

		this.camera_distance = 2;

		RoomSelfView.instance = this;

		var material = new Material();
		material.name = "customize_profile_guide";
		material.emissive = [ 1, 1, 1 ];
		material.alphaMode = "BLEND";
		material.opacity = 0.12;
		material.render_priority = -100;
		material.textures.albedo = "textures/customize_profile_guide.png";
		StaticMaterialsTable[material.name] = material;

		this._guide = new SceneNode();
		this._guide.mesh = "plane";
		this._guide.position = [ 0, 0, -0.02 ];
		this._guide.rotate(Math.PI, [ 0, 1, 0 ]);
		this._guide.scaling = 0.6;
		this._guide.material = material.name;

		this.profile_area = [ 0, 0, 1, 1 ];
	}

	onEnter(prev_controller) {
		this._prev_controller = prev_controller;
		this._prev_camera = this.view.hard_camera.serialize();

		var participant = this.space.local_participant;
		//participant.yaw = 0;
		participant.resetYaw();
		//this.view._skip_transition = true;
		RoomParticipant.global_opacity = 1;
		this._old_face_forward = RoomParticipant.face_forward_of_seat;
		RoomParticipant.face_forward_of_seat = true;
		this._was_two_sided = RoomParticipant.two_sided;
		RoomParticipant.two_sided = false;
		this._old_fov = this.view.hard_camera.fov;
		this.view.blur_background = false;
		var dist = this.camera_distance;
		this.view.setDOF(false);

		if (this.show_guide) {
			participant.getPivotNode().addChild(this._guide);
			if (RoomComponents.Seat)
				RoomComponents.gizmos_visible = false;
		}

		this.updateCamera();
	}

	onLeave() {
		var participant = this.space.local_participant;
		participant.force_self_render = false;
		RoomParticipant.face_forward_of_seat = this._old_face_forward;
		RoomParticipant.two_sided = this._was_two_sided;
		this.view.hard_camera.fov = this._old_fov || 60;
		this.view.setDOF(false);

		if (RoomCall.instance.enable_flatcall) {
			//to avoid bug of using old frame
			RoomCall.instance.toggleFlatCall(); //disable
			RoomCall.instance.toggleFlatCall(); //reenable
		}

		if (this._guide.parentNode)
			this._guide.parentNode.removeChild(this._guide);

		if (this._prev_camera)
			this.view.hard_camera.configure(this._prev_camera);
	}

	onParticipantLeave() {
		RoomCall.instance.onParticipantLeave();
	}

	onUpdate(dt) {
		RoomCall.instance.onUpdate(dt);
	}

	onTick() {
		RoomCall.instance.onTick();
	}

	updateCamera() {
		var camera = this.view.hard_camera;
		var participant = this.space.local_participant;
		var pivot_node = participant.getPivotNode();
		var pos = pivot_node.localToGlobal([ 0, -0.15, this.camera_distance ]);
		var target = pivot_node.localToGlobal([ 0, 0, 0 ]);
		camera.lookAt(pos, target, [ 0, 1, 0 ]);
		camera.fov = 30;
	}

	onRender() {
		var camera = this.view.hard_camera;

		if (StaticMaterialsTable["ghost"])
			StaticMaterialsTable["ghost"].opacity = 0;
		this.space.local_participant.force_self_render = true; //lasts one frame

		//render scene
		this.view.render();

		//render toolbar
		this.renderUI(camera);

		if (this.feed_atlas && this.feed_atlas.enabled)
			this.feed_atlas.generateAtlas();
	}

	renderUI(camera) {
		var participant = this.space.local_participant;
		const focusNode = participant.getProfileFocusNode();
		const focus_entity = focusNode.getParentEntity();
		const feed_node = participant.avatar.feed_node;
		const areControlsLarge = shouldUseLargeControls();

		//toolbar
		const posBottomCenter = focus_entity.localToScreenNode(
			[ 0.0, -0.5, 0 ],
			this.view.camera,
			undefined,
			feed_node
		);
		var y = posBottomCenter[1];

		const controlButtonSize = areControlsLarge ? 80 : 40; // in px
		const halfAButton = controlButtonSize / 2;
		gl.start2D();

		// button for closing
		const posTopLeft = focus_entity.localToScreenNode(
			[ -0.4, 0.4, 0 ],
			this.view.camera,
			undefined,
			feed_node
		);

		const closeButtonPosX = posTopLeft[0] - halfAButton;
		const closeButtonPosY = posTopLeft[1] - controlButtonSize;

		GUI.Button(
			closeButtonPosX,
			closeButtonPosY,
			controlButtonSize,
			controlButtonSize,
			GLUI.icons.x,
			true,
			ThemeLight.background,
			ThemeLight.hover,
			-1,
			CommonButtonParams
		);
		const exitButtonArea = GUI.HoverArea(
			closeButtonPosX,
			closeButtonPosY,
			controlButtonSize,
			controlButtonSize
		);
		if (exitButtonArea === GLUI.PRESSED || exitButtonArea === GLUI.CLICKED)
			this.exit();

		// detail panel lines
		const posTopRight = focus_entity.localToScreenNode(
			[ 0.4, 0.4, 0 ],
			this.view.camera,
			undefined,
			feed_node
		);

		const resizeButtonPosX = posTopRight[0] - halfAButton;
		const resizeButtonPosY = posTopRight[1] - controlButtonSize;
		const gradientStyle = {
			points: [ resizeButtonPosX, resizeButtonPosY, resizeButtonPosX - frameSize.x, resizeButtonPosY ],
			colorStops: [
				getLinesGradientStartColor(this.launcher.is_in_tutorial_flow),
				"rgba(0, 0, 0, 0)"
			]
		};

		// horisontal gradient bar
		GUI.Panel(
			resizeButtonPosX - frameSize.x,
			resizeButtonPosY + halfAButton,
			frameSize.x,
			frameSize.thickness,
			null,
			null,
			0,
			null,
			null,
			gradientStyle
		);

		// vertical gradient bar
		GUI.Panel(
			resizeButtonPosX + halfAButton - 2,
			resizeButtonPosY + halfAButton,
			frameSize.thickness,
			frameSize.y,
			null,
			null,
			0,
			null,
			null,
			{
				...gradientStyle,
				points: [
					resizeButtonPosX - 2,
					resizeButtonPosY + halfAButton,
					resizeButtonPosX - 2,
					resizeButtonPosY + halfAButton + frameSize.y,
				]
			}
		);

		// Button for Resizing
		GUI.Button(
			resizeButtonPosX,
			resizeButtonPosY,
			controlButtonSize,
			controlButtonSize,
			GLUI.icons.resize,
			true,
			getBackgroundColor(this.launcher.is_in_tutorial_flow),
			getHoverColor(this.launcher.is_in_tutorial_flow),
			-1,
			getResizeButtonParams([
				resizeButtonPosX + halfAButton, resizeButtonPosY,
				resizeButtonPosX + halfAButton, resizeButtonPosY + controlButtonSize,
			], this.launcher.is_in_tutorial_flow)
		);
		const resizeBtnHoverArea = GUI.HoverArea(
			resizeButtonPosX,
			resizeButtonPosY,
			controlButtonSize,
			controlButtonSize
		);
		if (
			resizeBtnHoverArea === GLUI.PRESSED ||
			(this.xyz.mobile && resizeBtnHoverArea === GLUI.CLICKED)
		) {
			this.resizing = true;
		}

		const sliderXOffset = 2;
		const controlsAndPadding = 2 * (sliderXOffset + controlButtonSize);
		const sliderWidth = Math.min(300, 0.8 * window.innerWidth - controlsAndPadding);
		const w = sliderWidth + controlsAndPadding;
		const h = 75;
		const x = posBottomCenter[0] - w * 0.5;

		GUI.TranslucentPanel(x, y, w, h, 40, [ 1, 1, 1, 1 ]);

		if (!this.launcher.is_in_tutorial_flow) {
			//brightness
			const iconCoefficient = areControlsLarge ? 0.65 : 0.5;
			GUI.DrawIcon(
				x,
				y + h / 2,
				iconCoefficient,
				GLUI.icons.brightness,
			);

			GUI.pushStyle();
			const sliderHeight = areControlsLarge ? 60 : 30;

			participant.face_brightness = GUI.Slider(
				x + GUI.icon_size * iconCoefficient + sliderXOffset,
				y + h / 2 - sliderHeight / 2,
				sliderWidth,
				sliderHeight,
				participant.face_brightness,
				0.5,
				2,
				0,
				null,
				makeSliderTheme(xyz.options.fontFamily)
			);
			GUI.popStyle();

			//settings
			const configButtonPositionX = x + w - controlButtonSize;
			const configButtonPositionY = y + h / 2 - halfAButton;

			GUI.Button(
				configButtonPositionX,
				configButtonPositionY,
				controlButtonSize,
				controlButtonSize,
				GLUI.icons.cog,
				true,
				ThemeDark.background,
				ThemeDark.hover,
				-1,
				{
					...CommonButtonParams,
					icon_color: ThemeDark.icon,
				}
			);

			const configureButtonArea = GUI.HoverArea(
				configButtonPositionX,
				configButtonPositionY,
				controlButtonSize,
				controlButtonSize
			);

			if (
				configureButtonArea === GLUI.PRESSED ||
				configureButtonArea === GLUI.CLICKED
			) {
				if (this.xyz.bridge)
					this.xyz.bridge.notify("ON_SETTINGS_MODAL_VIEW", { status: true });

				if (this.xyz.mobile) {
					// To prevent automatic click on button
					GUI.discardTouchInput();
				}
			}
		}
	}

	exit() {
		this.launcher.setController(this._prev_controller || this.launcher.call_controller);
	}

	scaleParticipant(e) {
		var participant = this.space.local_participant;
		if (this.resizing) {
			if (e.dragging) {
				const mouse_x = e.deltax;
				const mouse_y = -e.deltay;
				let acc_m = mouse_x + mouse_y;
				acc_m /= Math.hypot(mouse_x, mouse_y);

				if (mouse_x != 0 && mouse_y != 0)
					participant.profile_scaling = clamp(
						participant.profile_scaling + acc_m * 0.01,
						-0.3,
						0.25
					);
			}
		}
	}

	onMouse(e) {
		if (this.launcher.is_in_tutorial_flow) {
			this.onMouseInTutorial(e);
			return;
		}

		if (this.resizing && !e.dragging) this.resizing = false;

		var r = GUI.onMouse(e);
		if (r || GUI.isPositionBlocked(e.mousex, e.mousey))
			return true;

		this.scaleParticipant(e);

		if (this.allow_positioning) {
			var participant = this.space.local_participant;
			var area = this.profile_area;
			if (e.dragging && GUI.isInsideRect([ e.canvasx, e.canvasy ], area[0], area[1], area[2], area[3])) {
				participant.face_offset[0] = clamp(participant.face_offset[0] + e.deltax * 0.01, -1, 1);
				participant.face_offset[1] = clamp(participant.face_offset[1] + e.deltay * 0.01, -1, 1);
			}
		} else if (e.type === "mouseup" && e.click_time < 250) {
			this.exit();
		}
	}

	onKeyDown(e) {
		if (this.launcher.is_in_tutorial_flow) return;
		switch (e.code) {
		case "KeyQ":
		case "Escape":
			this.launcher.setController(this.launcher.call_controller);
			break;
		}
	}

	onMouseInTutorial(e) {
		console.debug("DDEBUGG - RoomSelfView - onMouse");
		if (this.resizing && !e.dragging) this.resizing = false;
		var r = GUI.onMouse(e);
		if (r || GUI.isPositionBlocked(e.mousex, e.mousey)) return true;
		this.scaleParticipant(e);
	}

	//executed on a seat change
	onPartipantChangeSeat(type, event) {
		RoomCall.instance.onPartipantChangeSeat(type, event);
	}
}

RoomSelfView.instance = null;

