/*
*   Alex Rodriguez
*   @jxarco
*/

// hdre.js
import HDREBuilder               from "./HDRE/HDREBuilder";
import HDREImage                 from "./HDRE/HDREImage";
import { parse }                 from "./HDRE/parse";
import * as HDREConstants        from "./HDRE/constants";


/**
 * Main namespace
 * @namespace HDRE
 */

var FLO2BYTE = 4;
var BYTE2BITS = 8;

var U_BYTE = 1;
var HALF_FLOAT = 2;
var FLOAT = 3;
var U_BYTE_RGBE = 4;

var HDRE = global.HDRE = {

	version: 3.0,	// v1.5 adds spherical harmonics coeffs for the skybox
	// v2.0 adds byte padding for C++ uses
	// v2.5 allows mip levels to be smaller than 8x8
	// v2.75 RGB format supported
	// v3.0 HDREImage and HDREBuilder
	maxFileSize: 60e6 // bytes
};

HDRE.DEFAULT = HDREConstants.DEFAULT;
HDRE.EXR = HDREConstants.EXR;
HDRE.RADIANCE = HDREConstants.RADIANCE;

HDRE.CUBE_MAP_POSITIVE_X = HDREConstants.CUBE_MAP_POSITIVE_X;
HDRE.CUBE_MAP_POSITIVE_Y = HDREConstants.CUBE_MAP_POSITIVE_Y;
HDRE.CUBE_MAP_POSITIVE_Z = HDREConstants.CUBE_MAP_POSITIVE_Z;
HDRE.CUBE_MAP_NEGATIVE_X = HDREConstants.CUBE_MAP_NEGATIVE_X;
HDRE.CUBE_MAP_NEGATIVE_Y = HDREConstants.CUBE_MAP_NEGATIVE_Y;
HDRE.CUBE_MAP_NEGATIVE_Z = HDREConstants.CUBE_MAP_NEGATIVE_Z;

HDRE.setup = function(o) {
	o = o || {};
	if (HDRE.configuration) {
		throw ("setup already called");
	}
	HDRE.configuration = o;
};

/** HEADER STRUCTURE (256 bytes)
 * Header signature ("HDRE" in ASCII)     4 bytes
 * Format Version                         4 bytes
 * Width                                  2 bytes
 * Height                                 2 bytes
 * Max file size                          4 bytes
 * Number of channels                     1 byte
 * Bits per channel                       1 byte
 * Header size                            1 byte
 * Max luminance                          4 byte
 * Flags                                  1 byte
 */

HDRE.HDREImage = HDREImage;
HDRE.HDREBuilder = HDREBuilder;

/*
  General HDRE Functions: Write, load, parse
*/

/**
 * Write and download an HDRE
 * @method write
 * @param {Object} mips_data - [lvl0: { w, h, pixeldata: [faces] }, lvl1: ...]
 * @param {Number} width
 * @param {Number} height
 * @param {Object} options
 */
HDRE.write = function(mips_data, width, height, options) {
	options = options || {};

	var array_type = Float32Array;

	if (options.type && options.type.BYTES_PER_ELEMENT) {
		array_type = options.type;
	}

	var RGBE = options.rgbe !== undefined ? options.rgbe : false;

	/*
  *   Create header
  */

	// get total pixels
	var size = 0;
	for (var i = 0; i < mips_data.length; i++)
		size += mips_data[i].width * mips_data[i].height;

	// File format information
	var numFaces = 6;
	var numChannels = options.channels || 4;
	var headerSize = 256; // Bytes (256 in v2.0)
	var contentSize = size * numFaces * numChannels * array_type.BYTES_PER_ELEMENT; // Bytes
	var fileSize = headerSize + contentSize; // Bytes
	var bpChannel = array_type.BYTES_PER_ELEMENT * BYTE2BITS; // Bits

	var contentBuffer = new ArrayBuffer(fileSize);
	var view = new DataView(contentBuffer);

	var LE = true;// little endian

	// Signature: "HDRE" in ASCII
	// 72, 68, 82, 69

	// Set 4 bytes of the signature
	view.setUint8(0, 72);
	view.setUint8(1, 68);
	view.setUint8(2, 82);
	view.setUint8(3, 69);

	// Set 4 bytes of version
	view.setFloat32(4, this.version, LE);

	// Set 2 bytes of width, height
	view.setUint16(8, width, LE);
	view.setUint16(10, height, LE);
	// Set max file size
	view.setFloat32(12, this.maxFileSize, LE);

	// Set rest of the bytes
	view.setUint16(16, numChannels, LE); // Number of channels
	view.setUint16(18, bpChannel, LE); // Bits per channel
	view.setUint16(20, headerSize, LE); // max header size
	view.setUint16(22, LE ? 1 : 0, LE); // endian encoding

	/*
  *   Create data
  */

	var data = new array_type(size * numFaces * numChannels);
	var offset = 0;

	for (var i = 0; i < mips_data.length; i++) {
		const _env = mips_data[i],
			w = _env.width,
			h = _env.height,
			s = w * h * numChannels;

		var suboff = 0;

		for (var f = 0; f < numFaces; f++) {
			var subdata = _env.pixelData[f];

			// remove alpha channel to save storage
			if (numChannels === 3) {
				subdata = _removeAlphaChannel(subdata);
			}

			data.set(subdata, offset + suboff);
			suboff += subdata.length;
		}

		// Apply offset
		offset += (s * numFaces);
	}

	// set max value for luminance
	view.setFloat32(24, _getMax(data), LE);

	var type = FLOAT;
	if (array_type === Uint8Array) {
		type = U_BYTE;
	}
	if (array_type === Uint16Array) {
		type = HALF_FLOAT;
	}

	if (RGBE) {
		type = U_BYTE_RGBE;
	}

	// set write array type
	view.setUint16(28, type, LE);

	// SH COEFFS
	if (options.sh) {

		var SH = options.sh;

		view.setUint16(30, 1, LE);
		view.setFloat32(32, SH.length / 3, LE); // number of coeffs
		var pos = 36;
		for (var i = 0; i < SH.length; i++) {
			view.setFloat32(pos, SH[i], LE);
			pos += 4;
		}
	} else {
		view.setUint16(30, 0, LE);
	}

	/*
  *  END OF HEADER
  */

	offset = headerSize;

	// Set data into the content buffer
	for (let i = 0; i < data.length; i++) {
		if (type === U_BYTE || type === U_BYTE_RGBE) {
			view.setUint8(offset, data[i]);
		} else if (type === HALF_FLOAT) {
			view.setUint16(offset, data[i], true);
		} else {
			view.setFloat32(offset, data[i], true);
		}

		offset += array_type.BYTES_PER_ELEMENT;
	}

	// Return the ArrayBuffer with the content created
	return contentBuffer;
};

function _getMax(data) {
	return data.reduce((max, p) => p > max ? p : max, data[0]);
}

function _removeAlphaChannel(data) {
	var tmp_data = new Float32Array(data.length * 0.75);
	let index = 0, k = 0;
	data.forEach(function(a, b) {
		if (index < 3) {
			tmp_data[k++] = a;
			index++;
		} else {
			index = 0;
		}
	});
	return tmp_data;
}

window.getMaxOfArray = _getMax;

/**
 * Read file
 * @method read
 * @param {String} url
 * @param {Function} callback
 */
HDRE.load = function(url, callback) {
	var xhr = new XMLHttpRequest();
	xhr.responseType = "arraybuffer";
	xhr.open("GET", url, true);
	xhr.onload = (e) => {
		if (e.target.status === 404) {
			return;
		}
		var data = HDRE.parse(e.target.response);
		if (callback) {
			callback(data);
		}
	};
	xhr.send();
};

//legacy
HDRE.read = function(url, callback) {
	console.warn("Legacy function, use HDRE.load instead of HDRE.read");
	return HDRE.load(url, callback);
};

HDRE.parse = parse;

export default HDRE;
