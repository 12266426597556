import { DEG2RAD } from "@src/constants";
import { ComponentContainer } from "@src/engine/componentContainer";
import { generateUID } from "@src/engine/generateUID";
import { UID_PREFIX } from "@src/engine/rendeer/UID_PREFIX";
import { checkVersion } from "@src/engine/Room/checkVersion";
import { extendClass } from "@src/engine/Room/extendClass";
import { getFullPath } from "@src/engine/Room/file-utils";
import { ROOM_LAYERS } from "@src/engine/Room/ROOM_LAYERS";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { ROOM_TYPES_STR } from "@src/engine/Room/ROOM_TYPES_STR";
import { RoomComponents } from "@src/engine/RoomComponents";
import { quatToEuler } from "@src/gl-matrix/quat";
import { LEvent } from "@src/libs/LEvent";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { RD } from "@src/libs/rendeer";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { quat, vec3 } from "gl-matrix";

import ROOM from "@src/engine/room";


/**
 * Entities carry the heavy lifting of defining what's in the room
 * Like meshes, interactive properties, etc
 *
 * Entities can be PREFABs (GLTFs), Markers for SEATS, Interactive Surfaces, Reflection Probes, etc
 */
function Entity()
{
	ComponentContainer.call(this);

	this.uid = generateUID("ENT");
	this.index = -1;

	this._space = null;

	this._name = "";
	this.type = ROOM_TYPES.NULL;
	this.layers = ROOM_LAYERS.STATIC; //single layer
	this.light_channels = 1; // default - sun channel
	this.collection = null;

	this._enabled = true;

	this._native = null; //filled with the NativeEntity from NativeEngine

	//space node associated to this entity
	this.node = new SceneNode();
	this.node.name = "entity_root";
	this.node.room_entity = this;

	this.description = ""; //info that appears in the editor only

	this.custom = {}; //everything here gets serialized
	this.flags = {
		interactive: false //LEGACY
	};

	//used by SEAT or SURFACE
	this.participant = null;

	this.in_use = false;
	this.is_on_focus = false;
	this.hover = false;
	this.skip_save = false;

	this._distance_to_camera = 100;

	//children entities
	this._parent = null;
	this.collapsed = false;
	this.children = [];
}

Entity.class_type = "entity";
ROOM.Entity = Entity;

//adds components support to this class
extendClass( Entity, ComponentContainer );

Object.defineProperty( Entity.prototype, "name", {
	get: function() {
		return this._name;
	},
	set: function(v) {
		if (v == this._name)
			return;
		if (	this.space )
		{
			delete this.space.entities_by_name[ this._name ];
			this.space.entities_by_name[ v ] = this;
		}
		this._name = v;
	}
});

Object.defineProperty( Entity.prototype, "enabled", {
	get: function() {
		return this._enabled;
	},
	set: function(v) {
		this._enabled = this.node.visible = v;
		//check native
		if(this._native)
			this._native.enabled = v;
	}
});

Object.defineProperty( Entity.prototype, "type_name", {
	get: function() {
		return ROOM_TYPES_STR[ this.type ];
	},
	set: function(v) {
		this.type = ROOM_TYPES[v];
	},
	enumerable: false
});

Object.defineProperty( Entity.prototype, "position", {
	get: function() {
		return this.node.position;
	},
	set: function(v) {
		this.node.position = v;
		if(this._native)
			this._native.node.position = v;
	}
});

Object.defineProperty( Entity.prototype, "rotation", {
	get: function() {
		return this.node.rotation;
	},
	set: function(v) {
		this.node.rotation = v;
		if(this._native)
			this._native.node.rotation = v;
	}
});

Object.defineProperty( Entity.prototype, "scaling", {
	get: function() {
		return this.node.scaling;
	},
	set: function(v) {
		this.node.scaling = v;
		if(this._native)
			this._native.node.scale = v;
	}
});


Object.defineProperty( Entity.prototype, "angle", {
	get: function() {
		return quatToEuler([ 0,0,0 ], this.node.rotation )[0];
	},
	set: function(v) {
		quat.identity( this.node.rotation );
		this.node.rotate( v * DEG2RAD, [ 0,1,0 ] );
		if(this._native)
			this._native.node.scale = this.scaling;
	},
	enumerable: false
});

Object.defineProperty( Entity.prototype, "mustUpdate", {
	get: function() {
		return false;
	},
	set: function(v) {
		if (v)
		{
			this.node._must_update_matrix = true;
			this.syncNative();
		}
	}
});

Object.defineProperty(Entity.prototype, "_layers", {
	get: function () {
		return this.layers;
	},
	set: function (v) {
		this.layers = v;
		if (this._native)
			this._native.node.setLayers(v, true);
	}
});

Object.defineProperty(Entity.prototype, "_light_channels", {
	get: function () {
		return this.light_channels;
	},
	set: function (v) {
		this.light_channels = v;
		if (this._native)
			this._native.node.setLightChannels(v, true);
	}
});

Object.defineProperty( Entity.prototype, "space", {
	get: function() {
		return this._space;
	},
	set: function(_v) {
		throw ("space cannot be set to entity, use space.addChild instead");
	}
});

Object.defineProperty( Entity.prototype, "parent", {
	get: function() {
		return this._parent;
	},
	set: function(_v) {
		throw ("parent cannot be set to entity, use scene.addChild instead");
	}
});


//legacy
Object.defineProperty( Entity.prototype, "room", {
	get: function() {
		return this._space;
	},
	set: function(_v) {
		throw ("room cannot be set to entity, use scene.addChild instead");
	}
});

//called from room.update
Entity.prototype.update = function( dt, t )
{
	this.processActionInComponents("update",[ dt,t ],true);
}

Entity.prototype.serialize = function()
{
	var o = {
		uid: this.uid,
		class_type: "entity",
		name: this.name,
		index: this.index,
		type: this.type_name,
		layers: this.layers,
		light_channels: this.light_channels,
		collection: this.collection,
		position: typedArrayToArray( this.node.position ),
		rotation: typedArrayToArray( this.node.rotation ),
		custom: this.custom,
		flags: this.flags,
		entities: [] //children
	};

	for (var i = 0; i < this.children.length; ++i)
	{
		var entity = this.children[i];
		if (entity.skip_save)
			continue;
		var ent_data = entity.serialize();
		o.entities.push( ent_data );
	}

	if (this.description)
		o.description = this.description;

	if ( this.node.scaling[0] != 1 || this.node.scaling[1] != 1 || this.node.scaling[2] != 1 )
		o.scale = typedArrayToArray( this.node.scaling );

	if ( this.enabled === false )
		o.enabled = false;

	this.serializeComponents(o);

	return o;
}

Entity.prototype.configure = function(o, json)
{
	if (!this._space)
		throw ("cannot configure an entity before adding it to the space, it requires access to the space");
	var space = this.space;

	if (o.uid)
	{
		this.uid = o.uid;
		if (this.uid[0] !== UID_PREFIX)
			this.uid = generateUID("SCN"); //LEGACY
		space.entities_by_uid[this.uid] = this;
	}

	this.type = ROOM_TYPES[ o.type ];

	//positioning
	this.node.fromMatrix( RD.IDENTITY );
	if (o.name)
	{
		this.name = o.name;
		if (space && this.name)
			space.entities_by_name[this.name] = this;
		this.node.name = "entity_root_" + this.name;
	}
	if (o.description)
		this.description = o.description;

	if (o.transform)
		this.node.fromMatrix( o.transform );
	if (o.position)
		this.node.position = o.position;
	if (o.rotation)
		this.node.rotation = o.rotation;
	if (o.angle)
		this.node.rotate(o.angle * DEG2RAD, [ 0,1,0 ] );
	if (o.scale)
		this.node.scaling = o.scale;
	if (o.flags)
		this.flags = o.flags;

	if (o.layers)
		this.layers = o.layers;
	if (o.light_channels)
		this.light_channels = o.light_channels;
	this.collection = o.collection || null;

	if (o.custom)
		this.custom = o.custom;

	this.enabled = o.enabled !== false;

	var entities = o.children || o.entities;
	if (entities)
		for (var i = 0; i < entities.length; ++i)
		{
			var entity_info = entities[i];

			if ( space.getEntityById( entity_info.uid ) )
			{
				console.warn("there is alredy an entity with this id, regenerating it" );
				entity_info.uid = generateUID("ENT");
			}

			var entity = new Entity();
			entity.index = entity_info.index != null ? entity_info.index : -1;
			this.addChild( entity );

			entity.configure( entity_info, json ); //this will trigger the recursivity
			if (entity_info.index != null)
				entity.index = entity_info.index;
			else //legacy
				entity.index = space.last_index++;
		}

	//if native engine available, create a NativeEntity
	if (globalThis.nativeEngine !== undefined)
	{
		this.node.type = o.type;
		this.createNativeEntity(o.index);
	}

	//LEGACY
	if ( json && !checkVersion(json.version,[ 0,2,0 ]) && !o.components )
	{
		o.components = [];
		if (o.type === "PREFAB")
			o.components.push( [ "PrefabRenderer", { url: o.prefab_url, materials: o.materials } ] );
		else if (o.type === "SURFACE" )
			o.components.push( [ "Surface", { proxy_node: o.proxy_node, app_name: o.app_name, media_url: o.media_url } ] );
		else if (o.type === "SEAT" )
			o.components.push( [ "Seat", {} ] );
	}

	if (o.flags && !o.flags.remote_model) // don't reload models from a remote location when changing properties
	{
		this.removeAllComponents();
		this.configureComponents( o );
	}
}

//remove all children
Entity.prototype.clear = function()
{
	while (this.children.length)
		this.removeEntity(this.children[0]);
}

//return number of child entities
Entity.prototype.numEntities = function()
{
	var num = 0;
	var entities = this.children;
	for (var i = 0; i < entities.length; ++i)
	{
		var entity = entities[i];
		if ( entity.numEntities)
			num += entity.numEntities();
	}
	return num + entities.length;
}

//returns the list with all the collections based on entities collection value
Entity.prototype.getCollections = function(o)
{
	o = o || {};
	for (var i = 0; i < this.children.length; ++i)
	{
		var entity = this.children[i];
		if (entity.getCollections)
			entity.getCollections(o);
		if (entity.collection)
			o[ entity.collection ] = true;
	}
	return o;
}

//creates a native entity and links it based on its index with 
//the one already existing inside the nativeEngine with same index
Entity.prototype.createNativeEntity = function (index) {
	if (index >= 0) {
		this._native = nativeEngine._room.createEntityByID(index);
		this._nativeNodesDirty = true;
	}
}

//not tested
Entity.prototype.destroy = function()
{
	this.removeAllComponents();
	if(this.parentNode)
		this.parentNode.removeChild(this);
	if(this._native)
		this.releaseNativeEntity();
}

//called from removeChild
Entity.prototype.releaseNativeEntity = function () {
	if (nativeEngine && this._native) {
		nativeEngine._room.destroyEntityObj(this._native);
		this._nativeNodesDirty = false;
		this._native = null;
	}
}

//sends info to native
Entity.prototype.updateNativeEntity = function () {
	if (this._native) {
		if (this._nativeNodesDirty && this.node) {
			this._nativeNodesDirty = false;
			var nativeNode = this._native.getRootNode();
			nativeNode._scene = this.node._scene;

			this.populateNativeMaterials(nativeNode);

			this.node.addChild(nativeNode);
		}
	}
}

Entity.prototype.populateNativeMaterials = function (node) {
	if (node.native) {
		var mat_name = node.material;
		if (mat_name != "") {
			var mat = StaticMaterialsTable[mat_name];
			if (!mat) {
				var nativeMat = node.materialObject;
				if (nativeMat) {
					StaticMaterialsTable[mat_name] = nativeMat;
				}
			}
		}
	}
	for (var i = 0; i < node.children.length; i++) {
		this.populateNativeMaterials(node.children[i]);
	}
}

Entity.prototype.addChild = function (entity)
{
	if (!this._space)
		throw ("cannot add entities without space");
	if ( entity._parent || entity.node._parent )
		throw ("cannot add entities that belongs to other entity, remove first");

	var space = this._space;
	if ( entity.node )
	{
		if (this.node)
			this.node.addChild( entity.node );
		else
			space.scene.root.addChild( entity.node );
	}
	this.children.push(entity);

	if(entity.index == -1)
		entity.index = space.last_index++;
		
	entity._parent = this;

	if (entity.name)
		space.entities_by_name[ entity.name ] = entity;
	if (entity.uid)
		space.entities_by_uid[ entity.uid ] = entity;
	space.entities_by_index[ entity.index ] = entity;

	assign_space(entity,space);

	//recursive
	function assign_space(entity,space)
	{
		var old_space = entity._space;
		entity._space = space;
		if(space)
			space.entities_by_index[entity.index] = entity;
		else if(old_space && old_space != space)
			delete old_space.entities_by_index[entity.index];

		for (var i = 0; i < entity.children.length; ++i)
			assign_space(entity.children[i],space);
	}

}

Entity.prototype.addEntity = Entity.prototype.addChild; //legacy

Entity.prototype.getEntity = function( name, filter )
{
	//search by name
	for (var i = 0; i < this.children.length; ++i)
	{
		var ent = this.children[i];
		if (ent.getEntity)
		{
			var r = ent.getEntity(name, filter);
			if ( r )
				return r;
		}
		if ( filter != null && filter != ent.type )
			continue;
		if (ent.name && ent.name == name)
			return ent;
	}
	return null;
}

Entity.prototype.sortEntities = function()
{
	this.entities = this.children.sort( function(a,b) { return a.name.localeCompare(b.name) });
	//propagate
	for (var i = 0; i < this.children.length; ++i)
	{
		var ent = this.children[i];
		if (ent.sortEntities)
			ent.sortEntities();
	}
}

Entity.prototype.getEntityById = function( id )
{
	for (var i = 0; i < this.children.length; ++i)
	{
		var ent = this.children[i];
		if (ent.id == id)
			return ent;
		if (ent.getEntityById)
		{
			var r = ent.getEntityById(id);
			if (r)
				return r;
		}
	}
	return null;
}

/**
 *
 * @param {Entity[]} [output]
 * @returns {Entity[]}
 */
Entity.prototype.getAllEntities = function( output=[] )
{
	const children = this.children;

	for (let i = 0; i < children.length; ++i)
	{
		const child = children[i];

		output.push(child);

		if (child.getAllEntities && child.children && child.children.length) {
			child.getAllEntities(output);
		}

	}

	return output;
}

Entity.prototype.findNodeByName = function( name )
{
	if( this._native )
		return this._native.getRootNode().findNodeByName(name);
	return this.node.findNodeByName(name);
}

Entity.prototype.findComponents = function( comp_name, out )
{
	out = out || [];
	if (!comp_name)
		return out;
	if ( comp_name.constructor === String )
		comp_name = RoomComponents[ comp_name ];
	if (!comp_name)
		return out;

	for (var i = 0; i < this._components.length; ++i )
	{
		var comp = this._components[i];
		if(comp.constructor == comp_name)
			out.push(comp);
	}

	for (var i = 0; i < this.children.length; ++i )
		this.children[i].findComponents( comp_name, out );
	return out;
}

Entity.prototype.removeChild = function( entity )
{
	var parent = entity._parent;
	if (parent != this)
	{
		console.error("this entity doenst belong to this group");
		return;
	}

	if (!this._space)
		throw ("cannot remove entities without space");

	var space = this._space;
	var index = this.children.indexOf(entity);
	if (index == -1)
		return;

	if (entity._native)
		entity.releaseNativeEntity();

	//remove from parent
	this.children.splice( index, 1 );
	//remove from search containers
	if (entity.name && space.entities_by_name[ entity.name ] == entity)
		delete space.entities_by_name[ entity.name ];
	if (entity.uid && space.entities_by_uid[ entity.uid ] == entity)
		delete space.entities_by_uid[ entity.uid ];
	//remove from render scene
	if ( entity.node && entity.node._parent)
		entity.node._parent.removeChild( entity.node );
	entity._parent = null;
	//update space recursively
	assign_space(entity,null);

	function assign_space(entity,space)
	{
		entity._space = space;
		for (var i = 0; i < entity.children.length; ++i)
			assign_space(entity.children[i],space);
	}
}

Entity.prototype.removeEntity = Entity.prototype.removeChild;

Entity.prototype.getEntitiesOfType = function(type, r)
{
	r = r || [];
	for (var i = 0; i < this.children.length; ++i)
	{
		var ent = this.children[i];
		if (ent.type == type)
			r.push(this.children[i]);
		else if ( ent.getEntitiesOfType )
			ent.getEntitiesOfType( type, r );
	}
	return r;
}

Entity.prototype.getEntityByComponent = function( comp )
{
	return this.children.find(function(ent) {
		if (ent.getEntityByComponent)
		{
			var r = ent.getEntityByComponent(comp);
			if (r)
				return r;
		}
		return ent.hasComponent(comp);
	});
}

Entity.prototype.getEntitiesByComponent = function( comp, r )
{
	r = r || [];
	for (var i = 0; i < this.children.length; ++i)
	{
		var ent = this.children[i];
		if (ent.getComponent( comp ))
			r.push(ent);
		if ( ent.getEntitiesByComponent )
			ent.getEntitiesByComponent( comp, r );
	}
	return r;
}

Entity.prototype.getEntityByIndex = function( index )
{
	//search by index
	for (var i = 0; i < this.children.length; ++i)
	{
		var ent = this.children[i];
		if (ent.index == index)
			return ent;
		if (ent.getEntityByIndex)
		{
			var r = ent.getEntityByIndex(index);
			if (r)
				return r;
		}
	}
	return null;
}

//HELPER
Entity.prototype.assignFeed = function (feed)
{
	if (!ROOM_FLAGS.allow_feeds)
		return;

	var surface = this.surface;
	if (!surface)
	{
		console.warn("this entity doesnt have a Surface component");
		return;
	}

	//append relative path
	feed = getFullPath( feed );
	return surface.assignFeed(feed);
}

//HELPER
Entity.prototype.assignPrefab = function( node_or_url )
{
	var pr = this.getComponent("PrefabRenderer");
	if (!pr)
	{
		pr = new RoomComponents.PrefabRenderer();
		this.addComponent( pr );
	}
	if ( node_or_url.constructor === String )
		pr.url = node_or_url;
	else if ( node_or_url.constructor === SceneNode )
		pr.setPrefabNode( node_or_url );
}

Entity.prototype.assignPrefabNode = Entity.prototype.assignPrefab; //LEGACY

Entity.prototype.processActionInComponents = function( func_name, parameters, expand_parameters  )
{
	ComponentContainer.prototype.processActionInComponents.call(this, func_name, parameters, expand_parameters );
	for (var i = 0; i < this.children.length; ++i)
	{
		var entity = this.children[i];
		entity.processActionInComponents( func_name ,parameters, expand_parameters);
	}
}

//sends mouse ray to entity in case it wants to use it to interact with the environment
//from Call.testRayRoomInteraction
Entity.prototype.processMouseRay = function( ray, _participant )
{
	return this.processActionInComponents("onMouseRay", ray);
}

Entity.prototype.onMouseEnter = function( e )
{
	return this.processActionInComponents("onMouseEnter", e );
}

Entity.prototype.onMouseLeave = function( e)
{
	return this.processActionInComponents("onMouseLeave", e );
}

//passes the mouse event to components
Entity.prototype.onMouse = function( e )
{
	return this.processActionInComponents("onMouse",e);
}

//passes the mouse event to components
Entity.prototype.onKeyDown = function( e )
{
	return this.processActionInComponents("onKeyDown",e);
}

//called from RoomCall.prototype.processItemClicked
Entity.prototype.processClick = function( evt )
{
	this.processActionInComponents("processClick", evt);
}

/**
 *
 * @param {ISceneNode[]} container
 */
Entity.prototype.getInteractiveNodes = function(container=[])
{
	const count = container.length;
	this.processActionInComponents("getInteractiveNodes", container);
	//mark they belong to this entity
	for (let i = count; i < container.length; ++i){
		container[i]._target_entity = this;
	}
}

Entity.prototype.moveTo = function(v)
{
	if (!v)
		return;
	if (v.constructor.is_component)
		v = v.entity;
	if (!v)
		return;

	if ( v.constructor === Entity )
	{
		this.node.fromMatrix( v.node.getGlobalMatrix() );
	}
	else if ( v.length === 3 )
	{
		this.node.position = v;
	}
}


//called from room.testRayWithInteractiveEntities when mouse moves
//if true this entity is marked as hover
Entity.prototype.testRayInteraction = function(ray, _participant)
{
	for (var i = 0; i < this._components.length; ++i)
	{
		var comp = this._components[i];
		if (comp.testRay)
		{
			var r = comp.testRay(ray);
			if (r)
				return true;
		}
	}

	return false;
}

//used from animation tracks
Entity.prototype.applyAnimationValue = function( target, property, value )
{
	ROOM.set(target + "/" + property, value );
	/*
	var ent = this.getEntityById( target );
	if(ent)
		ent.setPropertyFromPath( property.split("/"), 0, value );
	//var path = (target + "/" + property).split("/");
	*/
}

//returns a string identifying this node
Entity.prototype.getLocator = function( property, use_name )
{
	if (property)
	{
		if ( this[ property ] && this[ property ].getLocator )
			return this[ property ].getLocator( null, use_name );
	}

	return (use_name ? this.name : this.uid) + (property ? "/" + property : "");
}

Entity.prototype.setPropertyFromPath = function( path, index, v )
{
	var comp = null;

	if ( path.length <= index )
		return;

	if ( path[index][0] === UID_PREFIX )
		comp = this.getComponentByUId( path[index] );
	else
	{
		if ( path.length === index + 1)
		{
			switch ( path[index] )
			{
			case "enabled": this.enabled = v; return;
			case "position": this.position = v; return;
			case "rotation": this.rotation = v; return;
			case "scaling": this.scaling = v; return;
			}
		}
		else
			comp = this.getComponent( path[index] ); //it was the name of the component
	}

	if (!comp) //not found
		return;

	if (path.length === index + 1) //assigning the component??
	{
		console.error("cannot assign a component from a path");
		return;
	}

	if ( comp.setPropertyFromPath( path, index+1, v ) === false )
	{
		BaseComponent.prototype.setPropertyFromPath.call( comp, path, index, v );
	}
}

Entity.prototype.getPropertyFromPath = function( path, index )
{
	var comp = null;

	if ( path.length <= index )
		return;

	if ( path[index][0] === UID_PREFIX )
		comp = this.getComponentByUId( path[index] );
	else
	{
		if ( path.length === index + 1) //last element
		{
			switch ( path[index] )
			{
			case "enabled": return this.enabled;
			case "position": return this.position;
			case "rotation": return this.rotation;
			case "scaling": return this.scaling;
			}
		}

		comp = this.getComponent( path[index] ); //it was the name of the component
	}

	if (!comp) //not found
		return;

	if (path.length === index + 1) //assigning the component??
		return comp;

	return comp.getPropertyFromPath( path, index+1 );
}

//returns INFO based on a locator
Entity.prototype.resolveLocatorFromPath = function( path, offset )
{
	var varname = path[offset];
	if ( this[varname] !== undefined && (path.length - 1) === offset )
	{
		return {
			target: this,
			property: varname,
			entity: this,
			value: this[varname]
		}
	}

	var comp = null;
	if (varname[0] === "@")
		comp = this.getComponentByUId( varname );
	else
		comp = this.getComponent( varname );

	if (!comp)
		return null;

	if ( path.length === offset + 1 )
		return {
			target: comp,
			property: varname,
			entity: this,
			type: "component"
		};

	return comp.resolveLocatorFromPath(path,offset+1);
}

Entity.prototype.syncNative = function()
{
	if(!this._native)
		return;
	var n = this._native.node;
	n.position = this.position;
	n.rotation = this.rotation;
	n.scaling = this.scaling;
}

//forces this item to appear in other people's space
//works for entities, scenenode and participants
Entity.prototype.sync = function(tween)
{
	if (!this.space || !this.space.network)
		return;
	this.space.network.syncItem(this,tween);
}

Entity.prototype.on = function( event_type, callback, instance )
{
	LEvent.bind( this, event_type, callback, instance );
}

Entity.prototype.off = function( event_type, callback, instance )
{
	LEvent.unbind( this, event_type, callback, instance );
}

Entity.prototype.dispatch = function( event_type, param )
{
	LEvent.trigger( this, event_type, param );
}

Entity.prototype.localToScreen = function( pos, camera, viewport )
{
	var pos2D = vec3.create();
	camera.project( this.node.localToGlobal(pos), viewport, pos2D );
	pos2D[1] = gl.canvas.height - pos2D[1]; //flip Y
	return pos2D;
}

Entity.prototype.localToScreenNode = function( pos, camera, viewport, node )
{
	if (!node)
		return;
	var pos2D = vec3.create();
	camera.project( node.localToGlobal(pos), viewport, pos2D );
	pos2D[1] = gl.canvas.height - pos2D[1]; //flip Y
	return pos2D;
}

//to send data between entities
Entity.prototype.syncData = function(data)
{
	if (!this.space || !this.space.network)
		return;
	this.space.network.syncData(this,data);
}

Entity.prototype.getActions = function()
{
	return ["toggle visibility"];
}

Entity.prototype.onAction = function(v)
{
	if(v == "toggle visibility")
		this.enabled = !this.enabled;
}


export default Entity;
