function Notify() {
	this.addInput("in", LiteGraph.ACTION);
	this.properties = { event: "", payload: "" };
	this.addWidget("text","Event","","event");
	this.addWidget("text","Payload","","payload");
}

Notify.title = "Notify";
Notify.desc = "Notifies event to UX layer";

Notify.prototype.onAction = function( action, str )
{
	if ( !this.properties.event )
		return;

	var bridge = xyz.bridge;
	if (!bridge)
		return;

	if (this.properties.event === "ON_DEMO_ROOM_SIGNUP" )
		bridge.notify( "ON_DEMO_ROOM_SIGNUP", {
			showDemoRoomSignUpModal: true
		});
	else
	{
		var payload = {};
		if ( this.properties.payload )
		{
			try
			{
				payload = JSON.parse( this.properties.payload );
				this.boxcolor = null;
			}
			catch (err)
			{
				console.error("payload parsing error: " + err );
				this.boxcolor = "red";
			}
		}
		bridge.notify( this.properties.event, payload );
	}
}

export default Notify;
