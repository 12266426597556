import ROOM from "@src/engine/room";
import { getClassName } from "@src/engine/Room/getClassName";
import { RoomComponents } from "@src/engine/RoomComponents";
import Button from "@src/libs/GLUI/Elements/Button";

function LGraphComponent()
{
	this.properties = {
		entity_uid: "",
		component_uid: ""
	};

	//this.addInput("Component", undefined, { locked: true });
	this._component = null;
}

LGraphComponent.title = "Component";
LGraphComponent.desc = "A component from an entity";
LGraphComponent.highlight_color = "#CCC";

LGraphComponent.prototype.onRemoved = function()
{
	this.bindComponentEvents(null); //remove binding
}

LGraphComponent.prototype.onConnectionsChange = function( _side )
{
	this.bindComponentEvents( this._component );
}

LGraphComponent.prototype.onInit = function()
{
	var compo = this.getComponent();
	if (!compo)
		return;
	this.processOutputs( compo );
}

//write data to outputs reading them from the component properties
LGraphComponent.prototype.processOutputs = function( compo )
{
	if (!this.outputs || !this.outputs.length  )
		return;

	//write outputs
	for (var i = 0; i < this.outputs.length; i++)
	{
		var output = this.outputs[i];
		if (!output.links || !output.links.length || output.type === LiteGraph.EVENT )
			continue;
		if (output.name === "Component")
			this.setOutputData(i, compo );
		else
		{
			if (compo.getProperty)
			{
				var v = compo.getProperty(output.name);
				if (v !== undefined)
					this.setOutputData(i, v );
				else
					this.setOutputData(i, compo[ output.name ] );
			}
			else
				this.setOutputData(i, compo[ output.name ] );
		}
	}
}


LGraphComponent.prototype.onExecute = function()
{
	var compo = this.getComponent();
	if (!compo)
		return;

	//read inputs (skip 1, is the component)
	if (this.inputs)
		for (var i = 0; i < this.inputs.length; i++)
		{
			var input = this.inputs[i];
			if ( input.type === LiteGraph.ACTION )
				continue;
			var v = this.getInputData(i);
			if (v === undefined)
				continue;
			//safe assign property taking into account possible callbacks
			ROOM.setObjectProperty( compo, input.name, v );
		}

	//write outputs (done in a function so it can be reused by other methods)
	this.processOutputs( compo );
}

LGraphComponent.updateOutputData = function( slot )
{
	if (!this.outputs || slot >= this.outputs.length  )
		return;

	var output = this.outputs[i];
	if (!output.links || !output.links.length || output.type === LiteGraph.EVENT )
		return;

	var compo = this.getComponent();
	if (!compo)
		return;

	if (output.name === "Component")
		this.setOutputData( slot, compo );
	else
		this.setOutputData( slot, compo[ output.name ] );
}

LGraphComponent.prototype.onDrawBackground = function(ctx)
{
	var comp = this.getComponent();
	if (comp && comp._root)
	{
		this.boxcolor = null;
		var color = null;
		if (comp._root._is_selected)
			color = LGraphComponent.highlight_color;
		if (comp._is_selected)
			color = "#39F";

		if (color)
		{
			this.boxcolor = color;
			if (!this.flags.collapsed)
			{
				ctx.fillStyle = color;
				ctx.fillRect(0,0,this.size[0],2);
			}
		}

		this.title = getClassName( comp.constructor );

		if (comp._root.name)
		{
			ctx.font = "20px Arial";
			ctx.textAlign = "left";
			ctx.fillColor = [ 1,1,1,0.75 ];
			ctx.fillText( comp._root.name, 0,-40);
		}
	}
	else
		this.boxcolor = "red";
}

LGraphComponent.prototype.onConnectionsChange = function( type, slot, created, link_info, slot_info )
{
	if (type === LiteGraph.INPUT && slot_info && slot_info.name === "Component" )
	{
		var node = this.getInputNode(slot);
		if (node && node.onExecute)
		{
			node.onExecute();
			this.setDirtyCanvas(true,true);
		}
	}
}

LGraphComponent.prototype.getComponent = function()
{
	var xyz = XYZLauncher.instance;
	var space = this.graph && this.graph.space || xyz.space;
	//TODO: if no graph found, then crawl up in the graph hierarchy because probalby it is a subgraph

	var entity_uid = this.properties.entity_uid;
	if (!entity_uid)
	{
		if ( this.inputs && this.inputs.length )
		{
			var slot = this.findInputSlot("Component");
			if (slot !== -1)
			{
				var component = this.getInputData(slot);
				return component ? component : null;
			}
		}

		return null;
	}

	//find node
	var entity = space.getEntityById( entity_uid );
	if (!entity)
		return null;

	//find compo
	var compo_id = this.properties.component_uid;
	var compo = null;
	if (compo_id.charAt(0) === "@")
		compo = entity.getComponentByUId( compo_id );
	else if ( RoomComponents[ compo_id ] )
		compo = entity.getComponent( RoomComponents[ compo_id ] );
	else
		return null;

	if (compo && !compo.constructor.is_component)
		return null;

	if (this._component !== compo)
		this.bindComponentEvents( compo );

	this._component = compo;
	return compo;
}

//bind events attached to this component
LGraphComponent.prototype.bindComponentEvents = function( component )
{
	if (this._component)
		LEvent.unbindAll( this._component, this );

	this._component = component;
	if ( !this._component )
		return;

	//iterate outputs
	if (this.outputs && this.outputs.length)
		for (var i = 0; i < this.outputs.length; ++i )
		{
			var output = this.outputs[i];
			if ( output.type !== LiteGraph.EVENT )
				continue;
			var event_name = output.name.substr(3);
			LEvent.bind( this._component, event_name, this.onComponentEvent, this );
		}
}

LGraphComponent.prototype.onOutputAdded = function()
{
	this.bindComponentEvents();
}

LGraphComponent.prototype.onComponentEvent = function ( e, params )
{
	this.trigger( "on_" + e, params );
}

LGraphComponent.prototype.getComponentProperties = function( get_inputs, result )
{
	var compo = this.getComponent();
	if (!compo)
		return null;

	var attrs = null;
	if (compo.getPropertiesInfo)
		attrs = compo.getPropertiesInfo( get_inputs );
	else
		attrs = ROOM.getObjectProperties( compo );

	result = result || [];
	for (var i in attrs)
		result.push( [ i, attrs[i] ] );

	if (compo.constructor.getExtraProperties)
		compo.constructor.getExtraProperties( result );

	if (compo.getExtraProperties)
		compo.getExtraProperties( result );

	return result;
}

LGraphComponent.prototype.onAction = function( action_name, params ) {
	if (!action_name)
		return;
	var compo = this.getComponent();
	if (!compo)
		return;
	if (compo.onAction)
		compo.onAction( action_name, params );
	else if ( compo[ action_name ] && compo[ action_name ].constructor === Function )
		compo[ action_name ](); //params will be mostly MouseEvent, so for now I wont pass it
}

//used by the LGraphSetValue node
LGraphComponent.prototype.onSetValue = function( property_name, value ) {
	var compo = this.getComponent();
	if (!compo)
		return;

	var current = compo[ property_name ];
	var final_value;

	if ( current == null)
	{
		if (value && value.constructor === String)
			final_value = value;
	}
	else
	{
		switch ( current.constructor )
		{
		case Number: final_value = Number( value ); break;
		case Boolean: final_value = (value === "true" || value === "1"); break;
		case String: final_value = String( value ); break;
		case Array:
		case Float32Array:
			if ( value != null )
			{
				if ( value.constructor === String )
					final_value = JSON.parse("["+value+"]");
				else if ( value.constructor === Number )
					final_value = [ value ];
				else
					final_value = value;
			}
			else
				final_value = value;
			break;
		}
	}

	if (final_value === undefined)
		return;

	if (compo.setPropertyValue)
		compo.setPropertyValue( property_name, final_value );
	else
		compo[ property_name ] = final_value;
}

LGraphComponent.prototype.onGetInputs = function()
{
	var inputs = [ [ "Node",0 ],[ "Component",0 ],[ "Trigger",LiteGraph.ACTION ],null ];

	this.getComponentProperties("input", inputs);

	var compo = this.getComponent();
	if (compo && compo.getActions)
	{
		var actions = compo.getActions({});
		if (actions)
		{
			if (actions.constructor === Array)
				for (var i = 0; i < actions.length; ++i)
					inputs.push( [ actions[i], LiteGraph.ACTION ] );
			else
				for (var i in actions)
					inputs.push( [ i, LiteGraph.ACTION ] );
		}
	}

	return inputs;
}

LGraphComponent.prototype.onGetOutputs = function()
{
	var outputs = [];
	outputs.push( [ "Component", "Component" ], null ); //compo + separator

	this.getComponentProperties( "output", outputs);

	var compo = this.getComponent();
	if (compo && compo.getEvents)
	{
		var events = compo.getEvents();
		if (events)
		{
			if (events.constructor === Array)
				for (var i = 0; i < events.length; ++i)
					outputs.push( [ "on_" + events[i], LiteGraph.EVENT ] );
			else
				for (var i in events)
					outputs.push( [ "on_" + i, LiteGraph.EVENT ] );
		}
	}
	return outputs;
}

LGraphComponent.prototype.onExtraInspector = function(x, y, w)
{
	var that = this;
	if ( Button.call(GUI, x, y, w, 20, "Focus on Entity" ) )
	{
		var compo = this.getComponent();
		if (compo)
			ROOM.Editor.instance.focusOnEntity( compo.entity );
	}

	y += 24;
}

export default LGraphComponent;
