import { locator_chars } from "@src/engine/Room/locator_chars";
import { GL } from "@src/libs/litegl";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { BlendType } from "@src/libs/rendeer/BlendType";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { vec4 } from "gl-matrix";

export class Material {
	/**
	 * Material is a data container about the properties of an objects material
	 * @class Material
	 * @constructor
	 * @param {object} [json] optional configuration settings for material
	 */
	constructor(json) {
		this._color = vec4.fromValues(1, 1, 1, 1);
		this.shader_name = null;

		this.uniforms = {
			u_color: this._color
		};
		this.textures = {};

		this.primitive = GL.TRIANGLES;

		this.blend_mode = BlendType.None;

		this.flags = {
			two_sided: false,
			depth_test: true,
			depth_write: true
		};

		if (json)
			this.configure(json);
	}

	configure(o) {
		for (var i in o) {
			var v = o[i];
			if (v) {
				if (v.constructor === Object) //avoid sharing objects between materials
					v = JSON.parse(JSON.stringify(v)); //clone
				else if (v.constructor === Array)
					v = v.concat();
				else if (v.constructor === Float32Array)
					v = new Float32Array(v);
			}
			this[i] = v;
		}
	}

	/**
	 * Stores this material in the global `StaticMaterialsTable` container
	 * @param {string} [name] if no name is passed it will use this.name
	 */
	register(name) {
		if (name)
			this.name = name;
		if (!this.name)
			throw("cannot register material without name");
		StaticMaterialsTable[this.name] = this;
		return this; //to chain
	}

	serialize() {
		var o = {
			flags: JSON.parse(JSON.stringify(this.flags)),
			textures: JSON.parse(JSON.stringify(this.textures)) //clone
		};

		o.color = typedArrayToArray(this._color);
		if (this.name)
			o.name = this.name;
		if (this.alphaMode)
			o.alphaMode = this.alphaMode;
		if (this.blendMode)
			o.blendMode = this.blendMode;
		if (this.alphaCutoff != 0.5)
			o.alphaCutoff = this.alphaCutoff;
		if (this.uv_transform)
			o.uv_transform = this.uv_transform;
		if (this.normalFactor)
			o.normalFactor = this.normalFactor;
		if (this.displacementFactor)
			o.displacementFactor = this.displacementFactor;
		if (this.backface_color)
			o.backface_color = typedArrayToArray(this.backface_color);
		if (this.emissive)
			o.emissive = typedArrayToArray(this.emissive);
		if (this.model) {
			o.model = this.model;
			o.metallicFactor = this.metallicFactor;
			o.roughnessFactor = this.roughnessFactor;
		}

		return o;
	}

	render(renderer, model, mesh, indices_name, group_index, skeleton, node) {
		//get shader
		var shader_name = this.shader_name;
		if (!shader_name) {
			if (this.model == "pbrMetallicRoughness")
				shader_name = skeleton ? "texture_albedo_skinning" : "texture_albedo";
			else {
				if (skeleton)
					shader_name = null;
				else
					shader_name = renderer.default_shader_name || Material.default_shader_name;
			}
		}
		var shader = null;
		if (renderer.on_getShader)
			shader = renderer.on_getShader(node, renderer._camera);
		else
			shader = gl.shaders[shader_name];

		if (!shader) {
			var color_texture = this.textures.color || this.textures.albedo;
			if (skeleton)
				shader = color_texture ? renderer._texture_skinning_shader : renderer._flat_skinning_shader;
			else
				shader = color_texture ? renderer._texture_shader : renderer._flat_shader;
		}

		//get texture
		var slot = 0;
		var texture = null;
		for (var i in this.textures) {
			var texture_name = this.textures[i];
			if (!texture_name)
				continue;
			if (texture_name.constructor === Object) //in case it has properties for this channel
				texture_name = texture_name.texture;
			var texture_uniform_name = "u_" + i + "_texture";

			if (shader && !shader.samplers[texture_uniform_name]) //texture not used in shader
				continue; //do not bind it

			texture = gl.textures[texture_name];
			if (!texture) {
				if (renderer.autoload_assets && texture_name.indexOf(".") != -1)
					renderer.loadTexture(texture_name, renderer.default_texture_settings);
				texture = gl.textures["white"];
			}

			this.uniforms[texture_uniform_name] = texture.bind(slot++);
		}

		//weird case of mesh without textures
		if (!texture) {
			if (shader.samplers.u_albedo_texture || shader.samplers.u_color_texture)
				gl.textures["white"].bind(0);
		}

		//flags
		renderer.enableItemFlags(this);

		renderer._uniforms.u_model.set(model);
		if (skeleton && shader.uniformInfo.u_bones) {
			this.bones = skeleton.computeFinalBoneMatrices(this.bones, mesh);
			shader.setUniform("u_bones", this.bones);
		}
		shader.uniforms(renderer._uniforms); //globals
		shader.uniforms(this.uniforms); //locals

		var group = null;
		if (group_index != null && mesh.info && mesh.info.groups && mesh.info.groups[group_index])
			group = mesh.info.groups[group_index];

		if (group)
			shader.drawRange(mesh, this.primitive, group.start, group.length, indices_name);
		else
			shader.draw(mesh, this.primitive, indices_name);

		renderer.disableItemFlags(this);
		renderer.draw_calls += 1;
	}

	get color() {
		return this._color;
	}

	set color(value) {
		this._color.set(value);
	}

	get opacity() {
		return this._color[3];
	}

	set opacity(value) {
		this._color[3] = value;
	}

	get albedo() {
		return this._color;
	}

	set albedo(value) {
		this._color.set(value);
	}


	/**
	 *
	 * @param {string} property
	 * @param {boolean} [use_name]
	 * @returns {string}
	 */
	getLocator( property, use_name =false )
	{
		if (property)
		{
			if ( this[ property ] && this[ property ].getLocator )
				return this[ property ].getLocator( null, use_name );
		}

		return locator_chars.material + (( !use_name && this.uid) ? this.uid : this.name ) + (property ? "/" + property : "");
	}

}

Material.class_type = "material";
Material.default_shader_name = "texture";