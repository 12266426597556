import { ROOM_LAYERS } from "@src/engine/Room/ROOM_LAYERS";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";

//Dialog displayed when users access their backlog from the editor
var LayersDialog = {

	area: [ -300,-300, 400,320 ],

	target: null,

	render: function(editor)
	{
		var ctx = gl;
		var target = this.target || editor.selected_item;
		if ( !target || target.layers === undefined )
			return false;

		var w = this.area[2];
		var h = this.area[3];
		var x = this.area[0] = gl.canvas.width * 0.5 - w*0.5;
		var y = this.area[1] = gl.canvas.height * 0.5 - h*0.5;

		if ( GUI.Panel( x, y, w,h, "Layers", true ) == false )
		{
			this.target = null;
			return false;
		}

		var sy = y + 50;
		for (var i in ROOM_LAYERS)
		{
			Label.call(GUI, x + 60,sy,w - 40,20, i );
			var mask = ROOM_LAYERS[i];
			target.layers = (target.layers & ~mask) | (GUI.Toggle(x + 30,sy,20,20, null, Boolean(target.layers & mask) ) ? mask : 0);
			sy += 24;
		}

		if ( Button.call(GUI,  x + 20, y + h - 60,120,40, "Close" ) )
		{
			this.target = null;
			return false;
		}
	}
};

export default LayersDialog;
