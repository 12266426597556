import { DEG2RAD } from "@src/constants";
import Entity from "@src/engine/entity";
import ROOM from "@src/engine/room";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import extendClass from "@src/libs/LiteGL/extendClass";

function EntityReference()
{
	Entity.call(this);

	this.url = "";
	this.remote_space = null;
}

EntityReference.type = ROOM_TYPES.REFERENCE;

extendClass(EntityReference, Entity); //inherit

EntityReference["@url"] = { type: "asset", extensions: [ "json" ] };

Object.defineProperty( EntityReference.prototype, "position", {
	get: function() {
		return this.node.position;
	},
	set: function(v) {
		this.node.position = v;
	}
});

Object.defineProperty( EntityReference.prototype, "rotation", {
	get: function() {
		return this.node.rotation;
	},
	set: function(v) {
		this.node.rotation = v;
	}
});

Object.defineProperty( EntityReference.prototype, "scaling", {
	get: function() {
		return this.node.scaling;
	},
	set: function(v) {
		this.node.scaling = v;
	}
});


Object.defineProperty( EntityReference.prototype, "angle", {
	get: function() {
		return quat.toEuler([ 0,0,0 ], this.node.rotation )[0];
	},
	set: function(v) {
		quat.identity( this.node.rotation );
		this.node.rotate( v * DEG2RAD, [ 0,1,0 ] );
	},
	enumerable: false
});

EntityReference.prototype.load = function(url)
{
	if (!this.remote_space)
		this.remote_space = new ROOM.Space();
	this.remote_space.load(url,this.assignSpace.bind(this));
}

EntityReference.prototype.assignSpace = function(space)
{
	this.clear();

	for (var i = 0; i < space.root.children.length; ++i)
	{
		var entity = space.root.children[i];
		space.root.removeEntity( entity );
		this.addChild( entity );
	}
}

export default EntityReference;
