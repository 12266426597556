import ROOM from "@src/engine/room";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import HoloCamClient from "@src/libs/holocamClient";
import { GL } from "@src/libs/litegl";
import { Material } from "@src/libs/rendeer/Material";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import clamp from "@src/math/clamp";
import { mat4, quat, vec3 } from "gl-matrix";

import LandmarksFragmentShader from "./HolohCam/LandmarksFragmentShader.glsl";
import LandmarksVertexShader from "./HolohCam/LandmarksVertexShader.glsl";
import RgbxyzFragmentShader from "./HolohCam/RgbxyzFragmentShader.glsl";
import RgbxyzVertexShader from "./HolohCam/RgbxyzVertexShader.glsl";

function HoloCam()
{
	this._index = HoloCam.last_index++;
	this.enabled = true;
	this.url = HoloCam.default_url;
	this.username = "";
	this.password = "";
	this.point_size = 0.5; //in cm
	this.brightness = 1.0;
	this.use_blend = false;
	this.ignore_vector = false;
	this.smooth_vector = true;

	this._holocam_client = null;
	this._node = new SceneNode();
	this._node.material = ":holocam_" + this._index;
	this._material = new Material({ name: this._node.material, albedo: [ 1,1,1 ] });
	StaticMaterialsTable[ this._node.material ] = this._material;
	this._mesh = null;

	this._vector = vec3.fromValues(0,-1,0);

	this._reconnect = true;
}

HoloCam.componentName = "HoloCam";

HoloCam.default_url = "";

HoloCam.icon = [ 4,0 ];
HoloCam.type = ROOM_TYPES.CAMERA;
HoloCam.last_index = 0;

HoloCam.prototype.onAdded = function(parent)
{
	parent.node.addChild( this._node );
}

HoloCam.prototype.onRemoved = function(parent)
{
	parent.node.removeChild( this._node );

	//clear
	var mesh_name = ":holocam_" + this._index + "_mesh";
	delete gl.meshes[ mesh_name ];

	this.close();
}


HoloCam.prototype.preRender = function(view)
{
	this._node.visible = this.enabled;
	if (!this.enabled)
		return;

	this._material.albedo[0] = this.brightness;
	this._material.albedo[1] = this.brightness;
	this._material.albedo[2] = this.brightness;

	this._material.point_size = this.point_size * 0.01;
	this._material.alphaMode = this.use_blend ? "BLEND" : "OPAQUE";
	this._material.additive = this.use_blend;

	if (!this.ignore_vector)
	{
		var acos = vec3.dot( this._vector, [ 0,-1,0 ] );
		var ang = Math.acos( clamp( acos, -1,1) );
		if ( Math.abs(ang) > 0.0001 )
		{
			var axis = vec3.cross( vec3.create(), this._vector, [ 0,-1,0 ] );
			vec3.normalize( axis, axis );
			var q = quat.setAxisAngle( quat.create(), axis, ang + Math.PI );
			if (this.smooth_vector)
				quat.slerp( this._node._rotation, this._node._rotation, q, 0.02 );
			else
				this._node._rotation.set( q );
			this._node._must_update_matrix = true;
		}
	}
	else
	{
		quat.identity( this._node._rotation );
		this._node.rotate(Math.PI,[ 1,0,0 ]);
		this._node._must_update_matrix = true;
	}

	if (this.url && !this._holocam_client)
		this.connect();
}

HoloCam.prototype.serialize = function(o)
{
	o.enabled = this.enabled;
	o.url = this.url;
	o.username = this.username;
	o.password = this.password;
	o.brightness = this.brightness;
	o.point_size = this.point_size;
	o.use_blend = this.use_blend;
	o.ignore_vector = this.ignore_vector;
}

HoloCam.prototype.configure = function(o)
{
	this.enabled = o.enabled;
	this.url = o.url;
	this.username = o.username;
	this.password = o.password;
	this.brightness = o.brightness || 1;
	this.point_size = o.point_size || 1;
	this.use_blend = o.use_blend;
	this.ignore_vector = o.ignore_vector;
}

HoloCam.prototype.connect = function()
{
	this._holocam_client = new HoloCamClient();
	this._holocam_client.username = this.username;
	this._holocam_client.password = this.password;
	this._holocam_client.onMeshData = this.parseMeshData.bind(this);
	this._holocam_client.onMetadata = this.parseMetaData.bind(this);
	this._holocam_client.onError = this.onDisconnected.bind(this);
	this._holocam_client.onClose = this.onDisconnected.bind(this);
	this._holocam_client.connect( this.url );
	this._reconnect = true;
}

HoloCam.prototype.close = function()
{
	if (this._holocam_client)
	{
		if (this._timeout)
		{
			clearTimeout(this._timeout);
			this._timeout = null;
		}
		this._holocam_client.close();
		this._reconnect = false;
	}
}

HoloCam.prototype.onDisconnected = function()
{
	if (this._reconnect)
	{
		var that = this;
		this._reconnect = false;
		//wait three seconds
		if (this._timeout === null)
			this._timeout = setTimeout(function() {
				that._timeout = null;
				that.connect();
			},3000);
	}
}


HoloCam.prototype.parseMetaData = function(metadata)
{
	if (metadata.rot_x !== null)
	{
		this._vector[0] = metadata.rot_x;
		this._vector[1] = metadata.rot_y;
		this._vector[2] = metadata.rot_z;
		vec3.normalize( this._vector, this._vector );
		//TODO: rotation
		//this._node.
	}
}

HoloCam.prototype.parseMeshData = function(mesh_data)
{
	if (!mesh_data.COLOR || !mesh_data.POSITION)
		return;

	//console.debug( mesh_data );
	var mesh_buffers = {
		vertices: mesh_data.POSITION,
	};

	if (mesh_data.INDICES)
		mesh_buffers.triangles = mesh_data.INDICES;

	if (mesh_data.COLOR)
	{
		var color3 = mesh_data.COLOR;
		var color4 = new Float32Array( (color3.length / 3) * 4 );
		var i2 = 0;
		for (var i = 0, l = color3.length; i < l; i += 3)
		{
			color4[i2] = color3[i];
			color4[i2+1] = color3[i+1];
			color4[i2+2] = color3[i+2];
			color4[i2+3] = 1;
			i2 += 4;
		}
		mesh_buffers.colors = color4;
	}

	//create mesh
	var mesh = GL.Mesh.load(mesh_buffers,null,this._mesh);
	if (mesh)
	{
		//if(this._mesh)
		//	this._mesh.delete();
		this._mesh = mesh;
		var mesh_name = ":holocam_" + this._index + "_mesh";
		gl.meshes[mesh_name] = mesh;
		if (this._node)
			this._node.mesh = mesh_name;
	}

	if (mesh_data.INDICES)
		this._material.primitive = GL.TRIANGLES;
	else
		this._material.primitive = GL.POINTS;
}

HoloCam.prototype.onRenderInspector = function(ctx, x,y,w,h, editor )
{
	var is_connected = this._holocam_client && this._holocam_client.socket;
	Label.call(GUI,x,y,w,20, "URL");
	this.url = GUI.TextField( x + 100,y,w - 100,20, this.url, null, true );
	y+= 24;
	Label.call(GUI,x,y,w,20, "Username");
	this.username = GUI.TextField( x + 100,y,w - 100,20, this.username, null, true );
	y+= 24;
	Label.call(GUI,x,y,w,20, "Password");
	this.password = GUI.TextField( x + 100,y,w - 100,20, this.password, null, true );
	y+= 24;
	Label.call(GUI,x,y,100,20, is_connected ? "Connected" : "Not connected");
	if ( Button.call(GUI,x + 100,y,w-100,20, is_connected ? "Disconnect" : "Connect") )
	{
		if (is_connected)
			this.close();
		else
			this.connect();
	}
	y+= 24;

	Label.call(GUI,x,y,w,20, "Point Size");
	this.point_size = GUI.Slider( x + 100,y,w - 100,20, this.point_size, 0.01,5,0 );
	y+= 24;

	Label.call(GUI,x,y,w,20, "Brightness");
	this.brightness = GUI.Slider( x + 100,y,w - 100,20, this.brightness, 0,5,0,1 );
	y+= 24;

	Label.call(GUI,x,y,w,20, "Blend");
	this.use_blend = GUI.Toggle( x + 100,y,w - 100,20, null, this.use_blend );
	y+= 24;

	Label.call(GUI,x,y,w,20, "Ignore Vector");
	this.ignore_vector = GUI.Toggle( x + 100,y,w - 100,20, null, this.ignore_vector );
	y+= 24;

	if (1)
	{
		Label.call(GUI,x,y,w,20, "Debug");
		if ( Button.call(GUI, x + 100,y,w - 100,20, "Test RGBA" ) )
		{
			var id = "1622454146202";
			var folder = "other/test/";
			var image_url = folder + id + ".jpg";
			var data_url = folder + id + ".json";
			this.loadRGBData( image_url, data_url );
		}
		y+= 24;
	}

	return y;
}

HoloCam.prototype.renderGizmo = function( view, editor, selected )
{
	//editor.renderIcon3D( this.entity.node.position, [0,0], -64, [0,0,0, selected ? 0.9 : 0.6] );
	//editor.renderIcon3D( this.entity.node.position, CameraComponent.icon, -64, selected ? [2,2,2, 0.9] : [1,1,1,1] );
}

HoloCam.prototype.update = function(dt,time)
{

}

HoloCam.prototype.postRender = function(view)
{
	if (this._RGB_texture)
		this.renderRGBXYZ( view.getCurrentCamera(), this._RGB_texture );
}

HoloCam.prototype.loadRGBData = function( url_image, url_json )
{
	var that = this;
	var xyz = XYZLauncher.instance;
	this._RGB_texture = xyz.view.loadTexture( url_image, { texture: this._RGB_texture } );
	ROOM.fetchAsset( url_json, function(data) {
		var json = JSON.parse( data );
		that.setRGBData( json );
	});
}

HoloCam.prototype.setRGBData = function( info )
{
	console.debug(info);
	this._markers_info = info;

	this._face_vertices = new Float32Array( info.face_landmarks.flat() );
	this._face_mesh = GL.Mesh.load( { vertices: this._face_vertices } );

	this._pose_vertices = new Float32Array( info.pose_landmarks.flat() );
	this._pose_mesh = GL.Mesh.load( { vertices: this._pose_vertices } );

	if ( info.has_rhand_landmarks )
	{
		this._rhand_vertices = new Float32Array( info.rhand_landmarks.flat() );
		this._rhand_mesh = GL.Mesh.load( { vertices: this._rhand_vertices } );
	}

	if ( info.has_lhand_landmarks )
	{
		this._lhand_vertices = new Float32Array( info.lhand_landmarks.flat() );
		this._lhand_mesh = GL.Mesh.load( { vertices: this._lhand_vertices } );
	}

	this._meshes = [ this._face_mesh,this._pose_mesh,this._rhand_mesh,this._lhand_mesh ];
}

HoloCam.prototype.renderRGBXYZ = function( camera, texture, use_triangles )
{
	var mesh = this._plane_mesh;
	var res = [ texture.width, Math.floor(texture.height / 2) ];
	if (texture.width > 1 && (!mesh || mesh.res[0] !== res[0]|| mesh.res[1] !== res[1]) )
	{
		mesh = this._plane_mesh = GL.Mesh.plane({ detailX: res[0], detailY: res[1] });
		mesh.res = res; //store
	}

	var vp = camera._viewprojection_matrix;
	var model = this.entity.node.getGlobalMatrix();

	//render point cloud
	if (mesh)
	{
		var shader = HoloCam.rgbxyz_shader;
		if (!shader)
			shader = HoloCam.rgbxyz_shader = new GL.Shader( HoloCam.rgbxyz_vertex_shader, HoloCam.rgbxyz_fragment_shader);

		shader.uniforms({
			u_texture: texture.bind(0),
			u_color: [ 1,1,1,1 ],
			u_model: model,
			u_vp: vp,
			u_pointSize: this.point_size,
			u_ires: [ 1/texture.width, 1/texture.height ]
		}).draw(mesh, use_triangles ? gl.TRIANGLES : gl.POINTS );
	}

	//render markers
	if ( this._meshes )
	{
		mat4.scale( model, model, [ 1,-1,1 ] );

		var shader = HoloCam.landmarks_shader;
		if (!shader)
			shader = HoloCam.landmarks_shader = new GL.Shader( HoloCam.landmarks_vertex_shader, HoloCam.landmarks_fragment_shader);

		for (var i = 0; i < this._meshes.length; ++i)
		{
			var mesh = this._meshes[i];
			if (!mesh)
				continue;
			shader.uniforms({
				u_color: HoloCam.colors[i],
				u_model: model,
				u_vp: vp,
				u_pointSize: 3
			}).draw( mesh, gl.POINTS );
		}
	}
}

HoloCam.colors = [ [ 1,0,1,1 ],[ 1,1,0,1 ],[ 0,0.5,1,1 ],,[ 0,1,0.5,1 ] ];

HoloCam.landmarks_vertex_shader = LandmarksVertexShader;

HoloCam.landmarks_fragment_shader = LandmarksFragmentShader;

HoloCam.rgbxyz_vertex_shader = RgbxyzVertexShader;

HoloCam.rgbxyz_fragment_shader = RgbxyzFragmentShader;

export default HoloCam;
