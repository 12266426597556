import Entity from "@src/engine/entity";
import ROOM from "@src/engine/room";
import { getClassName } from "@src/engine/Room/getClassName";
import Button from "@src/libs/GLUI/Elements/Button";
import { RD } from "@src/libs/rendeer";

function LGraphEntity()
{
	this.properties = { uid: "" };
	this.size = [ 140,30 ];
	this._entity = null;
}

LGraphEntity.title = "Entity";
LGraphEntity.desc = "An Entity of the scene";
LGraphEntity.highlight_color = "#CCC";

LGraphEntity.prototype.onRemoved = function()
{
	if (this._entity)
		this.bindEntityEvents(null);
}

LGraphEntity.prototype.onConnectionsChange = function()
{
	this.bindEntityEvents( this._entity );
}

LGraphEntity.prototype.onOutputAdded = function()
{
	this.bindEntityEvents( this._entity );
}

LGraphEntity.prototype.getTitle = function()
{
	var entity = this._entity || this.getEntity();
	if (entity)
		return entity.name;
	return this.title;
}

LGraphEntity.prototype.getEntity = function()
{
	var uid = null;

	if (!this.graph)
		return null;

	var graph = this.graph;

	//first check input
	if (this.inputs && this.inputs[0])
		uid = this.getInputData(0);

	//hardcoded entity
	if ( uid && uid.constructor === Entity )
	{
		if (this._entity !== uid)
			this.bindEntityEvents(uid);
		return uid;
	}

	if (uid && uid.constructor === String)
		this.properties.uid = uid;

	//then check properties
	if (	!uid && this.properties.uid )
		uid = this.properties.uid;

	if (uid === "@" || !uid )
	{
		if ( graph._entity )
			return graph._entity;
		return null;
	}

	//get entity from scene
	var space = graph && graph.space;
	if (!space)
		return;

	var entity = null;
	if (uid)
		entity = space.getEntityById( uid );

	//hook events
	if (this._entity !== entity)
		this.bindEntityEvents(entity);

	return entity;
}

LGraphEntity.prototype.onExecute = function()
{
	var entity = this.getEntity();
	if (!entity)
		return;

	//read inputs
	if (this.inputs) //there must be inputs always but just in case
	{
		for (var i = 0; i < this.inputs.length; ++i)
		{
			var input = this.inputs[i];
			if ( input.type === LiteGraph.ACTION )
				continue;
			var v = this.getInputData(i);
			if (v === undefined)
				continue;
			switch ( input.name )
			{
			case "UID": this.properties.uid = v; break;
			case "Entity": this.properties.uid = v ? v.uid : null; if (v) entity = v; break;
			case "Transform": entity.node.transform = v; break;
			case "Enabled": entity.enabled = v; break;
			default:
				break;
			}
		}
	}

	//write outputs
	if (this.outputs)
		for (var i = 0; i < this.outputs.length; ++i)
		{
			var output = this.outputs[i];
			if (!output.links || !output.links.length || output.type === LiteGraph.EVENT )
				continue;
			switch ( output.name )
			{
			case "Enabled": this.setOutputData(i, entity.enabled ); break;
			case "Entity": this.setOutputData( i, entity ); break;
			case "Transform": this.setOutputData( i, entity.node.transform ); break;
			case "Global Model": this.setOutputData( i, entity.node.transform ? entity.node._global_matrix : RD.IDENTITY ); break;
			case "Name": this.setOutputData( i, entity.name ); break;
			case "UID": this.setOutputData( i, entity.uid ); break;
			default:
				//this must be refactored
				var compo = entity.getComponentByUId( output.name );
				if (!compo)
				{
					//SPECIAL CASE: maybe the entity id changed so the output.name contains the uid of another entity, in that case replace it
					var old_compo = entity.space.findComponentByUId( output.name );
					if (old_compo)
					{
						var class_name = getClassName( old_compo.constructor );
						compo = entity.getComponent( class_name );
						if ( compo )
							output.name = compo.uid; //replace the uid
					}
				}

				this.setOutputData(i, compo );
				break;
			}
		}
}

LGraphEntity.prototype.onDrawBackground = function(ctx)
{
	var entity = this.getEntity();
	if (!entity)
	{
		this.boxcolor = "red";
		return;
	}

	var highlight = entity._is_selected;

	if (highlight)
	{
	}
	else
		this.boxcolor = null;
}

LGraphEntity.prototype.getComponents = function(result)
{
	result = result || [];
	var entity = this.getEntity();
	if (!entity)
		return result;
	var compos = entity.getComponents();
	if (!compos)
		return result;

	for (var i = 0; i < compos.length; ++i)
	{
		var name = getClassName( compos[i].constructor );
		result.push( [ compos[i].uid, name, { label: name } ] );
	}

	return result;
}

LGraphEntity.prototype.onPropertyChanged = function(name,value)
{
	if (name === "uid")
		this.getEntity(); //updates this._node and binds events
}

//bind events attached to this component
LGraphEntity.prototype.bindEntityEvents = function( entity )
{
	if (this._entity)
		LEvent.unbindAll( this._entity, this );

	this._entity = entity;
	if ( !this._entity )
		return;

	//iterate outputs
	if (this.outputs && this.outputs.length)
		for (var i = 0; i < this.outputs.length; ++i )
		{
			var output = this.outputs[i];
			if ( output.type !== LiteGraph.EVENT )
				continue;
			var event_name = output.name.substr(3);
			LEvent.bind( this._entity, event_name, this.onEntityEvent, this );
		}
}

LGraphEntity.prototype.onEntityEvent = function( e, params )
{
	//trigger event
	this.trigger( "on_" + e, params );
}

LGraphEntity.prototype.onGetInputs = function()
{
	var inputs = [ [ "Enabled","boolean" ],[ "UID","string" ],[ "Entity","Entity" ] ];
	
	var entity = this._entity;
	if (entity && entity.getActions)
	{
		var actions = entity.getActions({});
		if (actions)
		{
			if (actions.constructor === Array)
				for (var i = 0; i < actions.length; ++i)
					inputs.push( [ actions[i], LiteGraph.ACTION ] );
			else
				for (var i in actions)
					inputs.push( [ i, LiteGraph.ACTION ] );
		}
	}

	inputs.push(null);

	return this.getComponents(inputs);
}

LGraphEntity.prototype.onGetOutputs = function()
{
	var result = [ [ "Entity","Entity" ],[ "Enabled","boolean" ],[ "Name","string" ],[ "UID","string" ],[ "Global Model","mat4" ],[ "on_clicked",LiteGraph.EVENT ] ];
	return this.getComponents(result);
}

LGraphEntity.prototype.onAction = function( action_name, params ) {
	if (!action_name)
		return;
	if(this._entity)
		this._entity.onAction( action_name, params );
}

LGraphEntity.prototype.onExtraInspector = function(x, y, w)
{
	if ( Button.call(GUI, x, y, w, 20, "Focus on Entity" ) )
	{
		if (this._entity)
			ROOM.Editor.instance.focusOnEntity( this._entity );
	}

	y += 24;
}

export default LGraphEntity;
