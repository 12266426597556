import { GL } from "@src/libs/litegl";
import { vec3 } from "gl-matrix";

import LinePixelShader from "./Histogram/LinePixelShader.glsl";
import LineVertexShader from "./Histogram/LineVertexShader.glsl";
import PixelShader from "./Histogram/PixelShader.glsl";
import VertexShader from "./Histogram/VertexShader.glsl";

function Histogram()
{
	this.scale = 0.5;
	this.histogram_bins = 256; //do not change

	this._texture = null; //stored the histogram
}

Histogram.prototype.compute = function( input_texture )
{
	if ( !input_texture )
		return; //saves work

	if (!this._points_mesh)
	{
		var w = 512;
		var h = 256;
		var vertices = new Float32Array(w*h*3);
		for (var y = 0; y < h; ++y)
			for (var x = 0; x < w; ++x)
				vertices.set([ x/w,y/h,0 ], y*w*3 + x*3);
		this._points_mesh = GL.Mesh.load({ vertices: vertices });
	}

	var histogram_bins = this.histogram_bins;

	if (!this._texture)
		this._texture = new GL.Texture( histogram_bins,1,{ type: gl.FLOAT, magFilter: gl.LINEAR, format: gl.RGB });

	if (!Histogram._shader)
		Histogram._shader = new GL.Shader( Histogram.vertex_shader, Histogram.pixel_shader );

	var mesh = this._points_mesh;
	var shader = Histogram._shader;
	var scale = this.scale;
	shader.setUniform("u_texture",0);
	shader.setUniform("u_factor",1/512);
	input_texture.bind(0);

	gl.disable(gl.DEPTH_TEST);
	gl.enable(gl.BLEND);
	gl.blendFunc(gl.ONE,gl.ONE);

	//compute
	this._texture.drawTo( function() {
		gl.clearColor(0,0,0,1);
		gl.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
		for (var i = 0; i < 3; ++i)
		{
			gl.colorMask( i == 0, i == 1, i == 2, true );
			shader.setUniform("u_mask",Histogram.masks[i]);
			shader.draw( mesh, gl.POINTS );
		}
		gl.colorMask( true,true,true, true );
	});
	gl.blendFunc(gl.SRC_ALPHA,gl.ONE_MINUS_SRC_ALPHA);
	gl.enable(gl.BLEND);
}

Histogram.prototype.draw = function( position, size, no_blend )
{
	var histogram_bins = this.histogram_bins;

	if (!this._line_mesh)
	{
		var vertices = new Float32Array(histogram_bins*3);
		for (var x = 0; x < histogram_bins; ++x)
			vertices.set([ x/histogram_bins,0,0 ], x*3);
		this._line_mesh = GL.Mesh.load({ vertices: vertices });
	}

	if (!Histogram._line_shader)
		Histogram._line_shader = new GL.Shader( Histogram.line_vertex_shader, Histogram.line_pixel_shader );

	var mesh = this._line_mesh;
	var shader = Histogram._line_shader;
	shader.setUniform("u_texture",0);
	shader.setUniform("u_scale", this.scale );
	this._texture.bind(0);

	if ( no_blend )
	{
		gl.disable(gl.BLEND);
	}
	else
	{
		gl.enable(gl.BLEND);
		gl.blendFunc(gl.ONE,gl.ONE);
	}

	gl.viewport( position[0] , gl.canvas.height - position[1] - size[1],
		size[0], size[1]);

	for (var i = 0; i < 3; ++i)
	{
		shader.setUniform("u_mask",Histogram.masks[i]);
		shader.draw( mesh, gl.LINE_STRIP );
	}

	gl.viewport( 0,0, gl.canvas.width, gl.canvas.height );
	gl.blendFunc(gl.SRC_ALPHA,gl.ONE_MINUS_SRC_ALPHA);
	gl.enable(gl.BLEND);
}

Histogram.masks = [ vec3.fromValues(1,0,0),vec3.fromValues(0,1,0),vec3.fromValues(0,0,1) ];

Histogram.vertex_shader = VertexShader;
Histogram.pixel_shader = PixelShader;
Histogram.line_vertex_shader = LineVertexShader;
Histogram.line_pixel_shader = LinePixelShader;

window.Histogram = Histogram;
