//this class provides helpers to build simple UIs from JS, UIs meant to be
//used by the editor to show the panels

//scope to avoid name crashing
function HTMLUI( parent )
{
	this.root = HTMLUI.newTag("div", { className:"ui-root" });
	if (parent)
		parent.appendChild( this.root );
	this.current = this.root;
	HTMLUI.instance = this;
	HTMLUI.root = this.root;

	var style = document.createElement("style")
	style.innerText = HTMLUI.css;
	document.head.appendChild(style);

	HTMLUI.templates = this.templates = document.createElement("div")
	this.templates.innerHTML = HTMLUI.templates_html_code;
	//this.templates.id = "ui-inner-templates";
	//document.head.appendChild(this.templates);
}

HTMLUI.prototype.get = function(selector)
{
	return this.root.querySelector(selector);
}

HTMLUI.prototype.setupLayout = function(html, css)
{
	if (html)
		this.root.innerHTML = html;
	if (css)
	{
		var style = document.createElement("style")
		style.innerText = css;
		document.head.appendChild(style);
	}
}

HTMLUI.prototype.cloneTemplate = function(selector)
{
	var elem = this.templates.querySelector(selector);
	if (!elem)
		throw ("template in HTMLUI not found:" + selector);
	return elem.cloneNode(true);
}

HTMLUI.prototype.add = function(w, selector)
{
	var parent = this.current;
	if ( selector )
		parent = this.root.querySelector( selector );
	if (!parent)
		return;

	if (w.root)
		parent.appendChild( w.root );
	else
		parent.appendChild( w );
}

Object.defineProperty( HTMLUI.prototype, "visible", {
	set: function(v) { this.root.style.display = v ? "" : "none"; },
	get: function() { return this.root.style.display == "none"; },
	enumerable: true
});

global.HTMLUI = HTMLUI;

HTMLUI.newTag = function(tagname, properties)
{
	var elem = document.createElement(tagname);
	for (var i in properties)
		elem[i] = properties[i];
	return elem;
}

HTMLUI.sizeToCSS = function( v )
{
	if ( v ===  undefined || v === null )
		return null;
	if (v.constructor === String )
		return v;
	if (v >= 0 )
		return (v|0) + "px";
	return "calc( 100% - " + Math.abs(v|0) + "px )";
}

// ******************************************

function Panel(options)
{
	options = options || {};
	this.options = options;
	this.root = HTMLUI.newTag("div", { className:"ui-panel" });

	if (options.width)
		this.root.style.width = HTMLUI.sizeToCSS( options.width );
	if (options.height)
		this.root.style.height = HTMLUI.sizeToCSS( options.height );
	if (options.position)
	{
		this.root.style.position = "absolute";
		this.root.style.left = HTMLUI.sizeToCSS( options.position[0] );
		this.root.style.top = HTMLUI.sizeToCSS( options.position[1] );
	}
}

Panel.prototype.add = HTMLUI.prototype.add;

HTMLUI.Panel = Panel;

// ******************************************

function Dialog(options)
{
	options = options || {};
	this.options = options;
	this.root = HTMLUI.instance.cloneTemplate(".ui-dialog");

	if (options.width)
		this.root.style.width = HTMLUI.sizeToCSS( options.width );
	if (options.height)
		this.root.style.height = HTMLUI.sizeToCSS( options.height );
	if (options.position)
	{
		this.root.style.position = "absolute";
		this.root.style.left = HTMLUI.sizeToCSS( options.position[0] );
		this.root.style.top = HTMLUI.sizeToCSS( options.position[1] );
	}

	this.content_element = this.root.querySelector(".content");
	this.close_element = this.root.querySelector(".header .btn-close");
	this.close_element.onclick = this.close.bind(this);
}

Dialog.prototype.close = function()
{
	if (!this.root.parentNode)
		return;
	this.root.parentNode.removeChild( this.root );
	if (this.onclose)
		this.onclose(this);
}

Dialog.prototype.show = function(v)
{
	if (!this.root.parentNode && v)
	{
		HTMLUI.instance.add( this );
		return;
	}
	this.root.style.display = v ? "block" : "hidden";
}

Dialog.prototype.add = function(elem)
{
	this.content.appendChild(elem.root ? elem.root : elem);
}

Dialog.prototype.center = function()
{
	var rect = this.root.getBoundingClientRect();
	this.root.style.position = "fixed";
	this.root.style.left = ((document.body.offsetWidth - rect.width) / 2) + "px";
	this.root.style.top = ((document.body.offsetHeight - rect.height) / 2) + "px";
}

Dialog.prototype.setContent = function(str)
{
	this.content_element.innerHTML = str;
}

Dialog.prototype.modal = function()
{
	this.show(true);
	this.center();
	this.root.classList.add("modal-dialog");
}

HTMLUI.Dialog = Dialog;

// *********************************************
//An inspector is like in Unity, the bar with all the properties of an object

function Inspector(options)
{
	options = options || {};
	this.options = options;

	this.root = HTMLUI.newTag("div",{ className:"ui-inspector" });
	this.widgets_container = HTMLUI.newTag("div",{ className:"widgets" });
	this.root.appendChild(this.widgets_container);
	this.widgets_by_name = {};
	this.widgets = [];
	this.addContainer("");
}

Inspector.widgets = {};

Inspector.prototype.clear = function()
{
	this.widgets_container.innerHTML = "";
	this.widgets_by_name = {};
	this.widgets = [];
	this.addContainer("");
	this.object = null;
}

Inspector.prototype.addContainer = function(name, options)
{
	options = options || {};
	var elem = HTMLUI.newTag("div",{ className:"container" });
	if (name)
		this.addTitle(name, options);
	this.widgets_container.appendChild(elem);
	this.current_container = elem;
	elem.options = options;
}

//adds title to current container
Inspector.prototype.addTitle = function(name, options)
{
	var elem = HTMLUI.newTag("div",{ className:"container-title" });
	elem.innerText = name;
	this.current_container.appendChild(elem);
	if (options.collapsable)
	{

	}
}

//updates inspector with data
Inspector.prototype.inspect = function(object, skip_clear)
{
	if (!skip_clear)
		this.clear();
	this.object = object;
	if (!object)
		return;
	//check if class has its own inspect
	if (object.constructor.inspectObject)
		return object.constructor.inspectObject( this, object );
	if (object.inspectObject)
		return object.inspectObject( this );
	return this.showDefaultInspector( object );
}

Inspector.prototype.showDefaultInspector = function(object)
{
	//default inspector
	var properties = Object.keys(object);
	for (var i = 0; i < properties.length; ++i)
	{
		var name = properties[i];
		if (name[0] == "_") //private properties
			continue;
		this.addObjectProperty( object, name );
	}
}

//adds a property and includes the modification of the property from the object
Inspector.prototype.addObjectProperty = function( object, property, options )
{
	var value = object[property];
	//extract variable type
	var type_info = object.constructor["@"+property];
	var type = type_info && type_info.type ? type_info.type : typeof(value);
	var widget = type;
	if ( type_info && type_info.widget )
		widget = type_info.widget;
	else if ( value && value.constructor === Float32Array )
		type = "vec";
	if ( !type_info )
		type_info = options;
	else
		for (var i in options)
			type_info[i] = options[i];
	var widget = this.add( type, property, value, type_info );
	if (!widget)
		return null;
	widget.onChange = this.processPropertyChange.bind(this);
	widget.target = object;
	widget.property = property;
	return widget;
}

Inspector.prototype.processPropertyChange = function( value, widget )
{
	widget.target[ widget.property ] = value;
	if (this.onChange)
		this.onChange(widget.target, widget.property. value);
}

Inspector.prototype.refresh = function()
{
	if (!this.object)
		return;
	for (var i = 0; i < this.widgets.length; ++i)
	{
		var w = this.widgets[i];
		if (w.refresh)
			w.refresh();
		else
		{
			var object = w.target || this.object;
			var v = object[ w.property ];
			if (v !== undefined)
				w.setValue(v);
		}
	}
}

// ***** ALL WIDGETS ********************
Inspector.prototype.add = function( type, name, value, options )
{
	options = options || {};
	var ctor = Inspector.widgets[type];
	if (!ctor)
	{
		console.warn("widget not found",type);
		return null;
	}

	//call ctor
	var widget = new ctor(name,value,options,type);
	widget.type = type;

	//add to inspector
	this.current_container.appendChild( widget.root );
	this.widgets.push(widget);
	this.widgets_by_name[name] = widget;

	return widget;
}

// add new widgets
Inspector.registerWidget = function( ctor )
{
	var types = ctor.type.split(",");
	for (var i = 0; i < types.length; ++i)
		Inspector.widgets[types[i]] = ctor;
}

//ctor for all widgets
Inspector.newWidget = function(w, name, options)
{
	var container = HTMLUI.newTag("div",{ className:"ui-widget" });
	w.root = container;
	w.root.innerHTML = "<div class='title'></div><div class='value'></div>";
	w.title_element = w.root.querySelector(".title");
	w.value_element = w.root.querySelector(".value");

	if (name != null)
		w.title_element.innerText = name;
	else
	{
		w.title_element.style.display = "none";
		w.value_element.classList.add("full");
	}
	w.options = options || {};
}

HTMLUI.Inspector = Inspector;

// GLOBAL WIDGETS ********************************


//this button is mostly used for special places
function Button(value,options)
{
	options = options || {};
	this.options = options;
	this.root = HTMLUI.newTag("button");
	this.root.innerHTML = "<span class='icon'></span><span class='text'></span>";
	this.root.classList.add("btn");
	if (options.type)
		this.root.classList.add(options.type);
	this.icon_element = this.root.querySelector(".icon");
	this.text_element = this.root.querySelector(".text");
	this.value = value || "";
	if (options.full)
		this.root.classList.add("full");
	this.root.addEventListener("click", this.processClick.bind(this));
	this.icon_off = options.icon_off || options.icon || "";
	this.icon_on = options.icon_on || options.icon || "";
	if (options.mini)
		this.root.classList.add("mini");
	this.active = false;
	if (options.button_icon)
	{
		this.icon_element.style.backgroundImage = "url('"+options.button_icon+"')";
		this.icon_element.classList.add("enabled");
	}
}

Object.defineProperty( Button.prototype, "value", {
	set: function(v) { this.text_element.innerText = v; },
	get: function() { return this.text_element.innerText; },
	enumerable: true
});

Object.defineProperty( Button.prototype, "active", {
	set: function(v) { if (v)
		this.root.classList.add("active");
	else
		this.root.classList.remove("active");
	if (v && this.icon_on)
		this.root.style.backgroundImage = "url('"+this.icon_on+"')";
	else if (!v && this.icon_off)
		this.root.style.backgroundImage = "url('"+this.icon_off+"')";
	},
	get: function() { return this.root.classList.has("active"); },
	enumerable: true
});

/*
Object.defineProperty( Button.prototype, "icon", {
    set: function(v) { this.root.style.backgroundImage = "url('"+v+"')"; },
    get: function() { return this.root.style.backgroundImage; },
    enumerable: true
});
*/

Button.prototype.processClick = function(e)
{
	if (this.callback)
		this.callback(e,this);
}

HTMLUI.Button = Button;

// INSPECTOR WIDGETS **********************************
function TextWidget( name, value, options )
{
	this._value = value;
	Inspector.newWidget(this, name, options);
	this.root.classList.add("ui-text");
	this.root.classList.add("double");
	this.value_element.innerHTML = "<input type='text'/>";
	this.input_element = this.value_element.querySelector("input");
	this.input_element.onchange = this.processChange.bind(this);
	this.setValue(value);
}

TextWidget.prototype.processChange = function(e)
{
	this._value = e.target.value;
	if (this.onChange)
		this.onChange(this._value,this,e);
}

TextWidget.prototype.setValue = function(v)
{
	this._value = v;
	if (v==null)
		v = "";
	this.input_element.value = String(v);
}

TextWidget.type = "string";
Inspector.registerWidget( TextWidget );

// Boolean WIDGET
function BooleanWidget( name, value, options )
{
	Inspector.newWidget(this, name, options);
	this._value = value;
	this.root.classList.add("ui-boolean");
	this.value_element.innerHTML = "<input type='checkbox'/>";
	this.input_element = this.value_element.querySelector("input");
	this.input_element.onchange = this.processChange.bind(this);
	this.setValue(value);
}

BooleanWidget.prototype.processChange = function(e)
{
	this._value = e.target.checked;
	if (this.onChange)
		this.onChange(this._value,this,e);
}

BooleanWidget.prototype.setValue = function(v)
{
	if (v==null)
		v = "";
	this.input_element.checked = v;
}

BooleanWidget.type = "boolean";
Inspector.registerWidget( BooleanWidget );

// Number WIDGET
function NumberWidget( name, value, options )
{
	Inspector.newWidget(this, name, options);
	this.root.classList.add("ui-number");
	this.root.classList.add("double");
	if (this.options.range)
	{
		this.root.classList.add("ui-range");
		this.value_element.innerHTML = "<input class='range' type='range'/><input class='number' type='number'/>";
	}
	else
		this.value_element.innerHTML = "<input class='number' type='number'/>";
	this.input_element = this.value_element.querySelector("input.number");
	this.input_element.onchange = this.processChange.bind(this);
	this.setValue(value);
}

NumberWidget.prototype.processChange = function(e)
{
	this._value = e.target.value;
	if (this.onChange)
		this.onChange(this._value,this,e);
}

NumberWidget.prototype.setValue = function(v)
{
	this._value = v;
	if (v==null)
		v = "";
	this.input_element.value = Number(v).toFixed(2);
}

NumberWidget.type = "number";
Inspector.registerWidget( NumberWidget );

// *********************************************

// Vector WIDGET to store numbers
function VectorWidget( name, value, options )
{
	Inspector.newWidget(this, name, options);
	this.root.classList.add("ui-vector");
	var step = this.options.step || 0.1;
	if (!value && !this.options.length)
		throw ("Vector Widget requires value or options.length");
	var num = value ? value.length : this.options.length;
	if (!this.options.double || num > 3)
		this.root.classList.add("double");
	else
		this.root.classList.add("single");
	var htmlsrc = "";
	for (var i = 0; i < num; ++i)
		htmlsrc += "<input class='number' "+ (i==0?"class='first'":"")+" data-i='"+i+"'type='number' step='"+step+"'/>";
	this.value_element.innerHTML = htmlsrc;
	this.input_elements = this.value_element.querySelectorAll("input.number");
	for (var i = 0; i < num; ++i)
	{
		var elem = this.input_elements[i];
		elem.onchange = this.processChange.bind(this);
		elem.style.width = (100/num).toFixed(1) + "%";
	}
	this._value = new Float32Array( num );
	this.setValue(value);
}

VectorWidget.prototype.processChange = function(e)
{
	for (var i = 0; i < this.input_elements.length; ++i)
		this._value[i] = parseFloat(this.input_elements[i].value);
	if (this.onChange)
		this.onChange(this._value,this,e);
}

VectorWidget.prototype.setValue = function(v)
{
	this._value.set(v);
	for (var i = 0; i < this.input_elements.length; ++i)
		this.input_elements[i].value = this._value[i].toFixed(2);
}

VectorWidget.type = "vec,vec2,vec3,vec4,quat";
Inspector.registerWidget( VectorWidget );

// *********************************************

function TreeView(options)
{
	options = options || {};
	this.options = options;
	this.root = HTMLUI.newTag("div",{ className:"ui-treeview" });
	this.rows = [];

	this.onEntryClicked = null;
}

TreeView.prototype.clear = function()
{
	this.root.innerHTML = "";
	this.rows = [];
}

TreeView.prototype.update = function( tree )
{
	this.clear();
	this.insertChildren(tree);
}

//selection should be an array of the items (not entries)
TreeView.prototype.setSelection = function( selection )
{
	var selected = this.root.querySelectorAll(".selected");
	for (var i = 0; i < selected.length; ++i)
		selected[i].classList.remove("selected");

	if (!selection)
		return;

	if (selection.constructor !== Array)
		selection = [ selection ];

	for (var i = 0; i < this.rows.length; ++i)
	{
		var entry = this.rows[i];
		if ( selection.indexOf(entry.item) == -1 )
			continue;
		entry.selected = true;
	}
}

TreeView.prototype.insertChildren = function(root, level)
{
	if (!root.children)
		return;
	for (var i = 0; i < root.children.length; ++i)
	{
		var child = root.children[i];
		var entry = new TreeView.Entry(child,level,this.options);
		entry.tree = this;
		this.rows.push(entry);
		this.root.appendChild(entry.root);
		this.insertChildren(child,level+1);
	}
}

function TreeViewEntry( item, level, options )
{
	var that = this;
	this.tree = null;
	this.item = item;
	this.level = level;
	this.root = HTMLUI.newTag("div", { className:"ui-treeview-entry" });
	this.root.innerHTML = "</span><span class='icon'></span><span class='content'></span><span class='side'></span>";
	this.icon_element = this.root.querySelector(".icon");
	this.content_element = this.root.querySelector(".content");
	this.root.style.marginLeft = (level * 15)|0 + "px";
	this.root.style.width = "calc( 100% - " + ((level * 15)|0) + "px)";

	this.root.addEventListener("click", function(e) {
		if (that.tree && that.tree.onEntryClicked)
			that.tree.onEntryClicked(that,e);
	});

	this.content = item.name;
	if (options.default_icon)
		this.icon = options.default_icon;

	if (item._is_selected)
		this.selected = true;
}

Object.defineProperty( TreeViewEntry.prototype, "content", {
	set: function(v) { this.content_element.innerText = v; },
	get: function() { return this.content_element.innerText; },
	enumerable: true
});

Object.defineProperty( TreeViewEntry.prototype, "icon", {
	set: function(v) { this.icon_element.style.backgroundImage = "url('"+v+"')"; },
	get: function() { return this.icon_element.style.backgroundImage; },
	enumerable: true
});

Object.defineProperty( TreeViewEntry.prototype, "selected", {
	set: function(v) { if (v) this.root.classList.add("selected"); else this.root.classList.remove("selected"); },
	get: function() { return this.root.classList.has("selected"); },
	enumerable: true
});

TreeView.Entry = TreeViewEntry;
HTMLUI.TreeView = TreeView;

//Hardcoded here to make it more portable (but less editable by artists)
HTMLUI.css = `
    .ui-root input, .ui-root div { margin: 0; padding: 0; box-sizing: border-box; font-family: 'Inter'; }

    .ui-root {
        width: 100%;
        height: 100%;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
    }

	.ui-root .btn {
		border: 1px solid #CCC;
		border-radius: 100px;
        color: white;
		background-color:  #0088FF;
        background-repeat: no-repeat;
        background-position: center;        
		text-align: center;
		padding-top: 6px;
        padding-bottom: 2px;
		cursor: pointer;
	}

	.ui-root .btn:hover { background-color: #4AAAFF; }
	.ui-root .btn.secondary { color: #333; background-color: white; }
	.ui-root .btn.secondary:hover { background-color: #C8C8C8; }
	.ui-root .btn.alert { background-color: #E66152; }
	.ui-root .btn.alert:hover { background-color: #F57B6D;}
    .ui-root .btn.mini { width: 26px; height: 26px; border-radius: 6px; }

    .ui-root .btn .icon.enabled {
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
    }    

    .ui-root .buttons .btn {
        margin-right: 10px;
    }

    .ui-panel {
        background-color: #EEE;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .ui-dialog {
        margin: auto;
        width: 300px;
        height: 300px;
        border-radius: 10px;
        background-color: #FFF;
    }

    .ui-dialog.modal-dialog {
        position: fixed;
        top: 100px;
        left: 500px;
    }

    .ui-dialog > .header {
        position: relative;
        height: 40px;
    }


    .ui-inspector {
        width: 100%;
        height: 100%;
        font-family: Tahoma;
    }

    .ui-inspector .container {
        margin: 2px;
    }

    .ui-inspector .container-title {
        color: #4F4F4F;
        margin-top: 8px;
        margin-bottom: 8px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
    }

    .ui-widget {
        width: 100%;
        height: 26px;
    }

    .ui-widget.double {
        width: 100%;
        height: 48px;
    }

    .ui-widget.ui-boolean .value {
        text-align: right;
    }

    .ui-widget input {
        color: #4F4F4F;
        border: 1px solid #DEDEDE;
        border-radius: 5px;
        width: calc( 100% - 4px );
        margin: 0;
        padding: 4px;
        text-align: center;
    }

    .ui-widget.ui-number.ui-range .range, .ui-widget.ui-number.ui-range .number, .ui-widget.ui-vector .number {
        vertical-align: middle;
    }

    .ui-widget.ui-number.ui-range .range {
        width: calc(60% - 4px);
        margin-right: 4px;
    }

    .ui-widget.ui-number.ui-range .number {
        width: 40%;
    }    

    .ui-widget.ui-boolean input {
        width: 20px;
    }

    .ui-button {
        border-radius: 5px;
        background-color: #DDD;
    }

    .ui-button.active {
        background-color: #0088FF;
    }

    .ui-button.full {
        width: 100%;
        height: 100%;
    }

    .ui-widget .title {
        display: inline-block;
        width: calc( 50% - 10px );
        margin: 0;
        padding: 0;

        color: #767676;
        font-size: 12px;
        text-transform: capitalize;
    }

    .ui-widget.double .title {
        display: block;
        width: calc( 100% - 10px );
    }

    .ui-widget .value {
        display: inline-block;
        width: 50%;
        margin: 0;
        padding: 0;
    }

    .ui-widget.double .value {
        display: block;
        width: calc( 100% - 10px );
        margin-top: 4px;
    }

    .ui-widget .value.full {
        width: 100%;
    }

    .ui-treeview {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .ui-treeview-entry {
        color: #4F4F4F;
        cursor: pointer;
        user-select: none;
    }

    .ui-treeview-entry .icon {
        margin-left: 20px;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        display: inline-block;     
        transform: translateY(4px);
        background-size: cover;
    }

    .ui-treeview-entry.selected {
        color: #EC8001;
        font-weight: bold;
    }

    .ui-treeview-entry .content {
        transform: translateY(-2px);
    }

    .ui-treeview-entry:nth-child(odd) {
        background-color: #f1f1f1;
    }

    .ui-root input[type="range"] {
        height: 4px;
        background: #ECECEC;
        border-radius: 2px;
    }
    
    .ui-root input[type="range"]::-webkit-slider-thumb 
    {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        color: white;
        background-color: white;
        cursor: ew-resize;
        box-shadow: 0 0 4px #333;
    }
    
    .ui-root input[type=range]::-webkit-slider-runnable-track  {
        background-color: red;
    }

    .ui-dialog {

    }

    .ui-dialog h2 {
        color: #464646;
        text-align: center;
        font-size: 30px;
        margin-bottom: 5px;
    }

    .ui-dialog h3 {
        color: #8A8A8A;
        text-align: center;
        font-size: 18px;
        margin-top: 5px;
    }

    .btn-close {
        position: absolute;
        top: 14px;
        right: 14px;
        border: 0;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('icons/icon-close.png');
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

`;

HTMLUI.templates_html_code = `
<div class='ui-dialog'>
    <div class="header"><button class="btn-close"></div>
    <div class="content"></div>
</div>
`;

export default HTMLUI;
