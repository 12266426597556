export function parseTextConfig(text) {
	var lines = text.split("\n");
	var root = { data: "", children: [] };
	inner(root, 0);

	function inner(parent, depth) {
		var last_line = lines.shift();
		while (last_line) {
			if (last_line.trim().length == 0) {
				last_line = lines.shift();
				continue;
			}

			var tabs = 0;
			while (last_line[tabs] == "\t" && tabs < last_line.length)
				tabs++;
			if (tabs < depth)
				break;

			var node = { children: [] };
			try {
				var info = last_line.trim();
				if (info.indexOf(":") != -1)
					info = "{" + info + "}";
				var func = new Function("return " + info);
				node.data = func();
			} catch (err) {
				console.error(err);
			}

			if (tabs >= depth) {
				if (parent)
					parent.children.push(node);
				last_line = inner(node, tabs + 1);
			}
		}
		return last_line;
	}

	return root;
}