import lerp                 from "lerp";

import FormattedText from "@src/engine/apps/ui/formattedText";
import ImageElement from "@src/engine/apps/ui/imageElement";
import { canvasRoundedRect } from "@src/engine/apps/ui/roundedRect";
import ROOM from "@src/engine/room";

import DialogStylesTemplate from "./dialog.css";
import DialogHtmlTemplate   from "./dialog.html";

function WhiteboardNote(whiteboard, id, options={})
{
	this.xyz = XYZLauncher.instance;
	this.whiteboard = whiteboard;
	this.id = id;
	this.page_index = options.page_index;
	const colors = [ "yellow", "orange", "pink", "blue", "green" ];
	this.color = options.color || colors[this.id % colors.length];
	this.width = 150 * this.whiteboard.scale_factor;
	this.height = 150 * this.whiteboard.scale_factor;
	const rotation_direction = this.id % 2 === 0 ? 1 : -1;
	this.rotation = options.rotation || rotation_direction * (Math.random() * 5) * Math.PI / 180;
	this.position = options.position || [
		whiteboard.width / 2 + (-200 + Math.random() * 400),
		whiteboard.height / 2 + (-100 + Math.random() * 200)
	];
	this.text_size = 25 * this.whiteboard.scale_factor;
	this.scale = 1;
	this.tween_completion = { v: 0 };
	this.tween = null;
	this.selected = false;
	this.focused = false;
	this.border_radius = 0;
	this.text = new FormattedText(options.text || "", this.width - this.text_size, {
		max_font_size: this.text_size,
		text_align: "center",
		font: this.xyz.options.fontFamily
	});
	this.html_prefix = "whiteboard-html-";
	this.html_renderer = this.xyz.html_renderer;
	this.elements = [];
	this.elements["edit-btn"] = new ImageElement(ROOM.getImage( "/textures/apps/whiteboard/interface/edit.png" ), {
		width: 20 * this.whiteboard.scale_factor,
		height: 20 * this.whiteboard.scale_factor });
	this.elements["remove-btn"] = new ImageElement(ROOM.getImage( "/textures/apps/whiteboard/interface/remove.png" ), {
		width: 16 * this.whiteboard.scale_factor,
		height: 20 * this.whiteboard.scale_factor });
	this.blocked = false;
}

WhiteboardNote.prototype.rescale = function()
{
	this.width = 150 * this.whiteboard.scale_factor;
	this.height = 150 * this.whiteboard.scale_factor;
	this.text.font_size = this.text_size;
	this.text.max_width = this.width - this.text_size;
	this.text._ready_to_render = false;
}

WhiteboardNote.prototype.render = function(ctx, tex, GUI)
{
	const scale = this.whiteboard.scale_factor;
	const left_x = -this.width / 2;
	const right_x = this.width / 2;

	ctx.save();
	ctx.translate(
		this.position[0],
		this.position[1] );
	if ( this.rotation ) {
		ctx.rotate( this.rotation );
	}
	ctx.drawImage( ROOM.images["/textures/apps/whiteboard/notes/note--" + this.color + "-1.png"], left_x * this.scale, - this.height / 2 * this.scale, this.width * this.scale, this.height * this.scale );

	if ( this.blocked )
	{
		ctx.font = `${ Math.floor(10 * scale) }px ${this.xyz.options.fontFamily}`;
		ctx.textBaseline = "middle";
		const padding = 10 * scale;
		const measure = ctx.measureText(this.blocked.name);
		const actualHeight = measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent + padding;
		ctx.fillStyle = ctx.strokeStyle = "#00b48f";
		const x = left_x + 5 * scale;
		canvasRoundedRect(ctx, x, - this.height / 2 - actualHeight, ( measure.width + padding * 2), actualHeight, actualHeight / 2 );
		ctx.fill();
		ctx.fillStyle = "white";
		ctx.fillText( this.blocked.name, x + padding, - this.height / 2 - actualHeight / 2.2 );

		ctx.lineWidth = 2 * scale;
		const inset = 9 * scale;
		canvasRoundedRect(ctx, (left_x + inset / 2) * this.scale, (- this.height / 2 + inset / 2) * this.scale, (this.width - inset) * this.scale, (this.height - inset) * this.scale, 5 * scale )
		ctx.stroke();
	}

	ctx.restore();

	ctx.save();
	this.text.render(ctx);
	ctx.restore();

	// select mode
	if ( this.selected && !this.focused )
	{
		ctx.save();
		ctx.shadowOffsetX = 0;
		ctx.shadowOffsetY = 2 * scale;
		ctx.shadowBlur = 6 * scale;
		ctx.shadowColor = "rgba(0,0,0,.05)";
		ctx.fillStyle = "white";
		ctx.beginPath();
		ctx.arc(this.position[0] + (this.width / 2 + 10 * scale), (this.position[1] - (this.height / 2) + 30* scale), 15 * scale, 0, Math.PI * 2);
		ctx.fill();
		ctx.beginPath();
		ctx.arc(this.position[0] + (this.width / 2 + 10 * scale), (this.position[1] - (this.height / 2) + (30 + 32) * scale), 15 * scale, 0, Math.PI * 2);
		ctx.fill();
		ctx.restore();
		this.elements["edit-btn"].render( ctx );
		this.elements["remove-btn"].render( ctx );
	}
}


WhiteboardNote.prototype.updateContent = function()
{
	const GUI = this.whiteboard.surface.GUI;
	this.text.scale = this.scale;
	this.text.rotation = this.rotation;
	this.text.position[0] = this.position[0];
	this.text.position[1] = this.position[1];

	if ( this.selected && !this.focused )
	{
		this.elements["edit-btn"].x = (this.position[0] + (this.width / 2));
		this.elements["edit-btn"].y = (this.position[1] - (this.height / 2) + 20 * this.whiteboard.scale_factor);

		this.elements["remove-btn"].x = (this.position[0] + (this.width / 2) + 2* this.whiteboard.scale_factor);
		this.elements["remove-btn"].y = (this.position[1] - (this.height / 2) + (20 + 32)* this.whiteboard.scale_factor);

		if ( this.elements["edit-btn"].isHovered( GUI ) )
		{
			this.whiteboard.cursor_style = "pointer";
			if ( this.whiteboard.mouse_action === "click" )
			{
				this.whiteboard._keep_note_selected = true;
				if ( !this.whiteboard.dragging ) // fix for weird bug
				{
					this.enterFocus();
				}
			}
		}

		else if ( this.elements["remove-btn"].isHovered( GUI ) )
		{
			this.whiteboard.cursor_style = "pointer";
			if ( this.whiteboard.mouse_action === "click" )
			{
				this.whiteboard._keep_note_selected = true;
				if ( !this.whiteboard.dragging ) // fix for weird bug
				{
					this.whiteboard.removeNote(this.id);
					const data = { page: this.page_index, id: this.id };
					this.whiteboard.sendAction({ action: "remove_note", data: data });
				}
			}
		}
	}
}

WhiteboardNote.prototype.enterSelect = function ()
{
	if ( this.blocked ) {
		return;
	}

	if ( this.whiteboard.selected_note && this.whiteboard.selected_note !== this )
	{
		this.whiteboard.selected_note.exitSelect();
	}

	this.whiteboard.selected_note = this;
	this.selected = true;
	this.focused = false;

	// move selected note to end of array, so it will be rendered above the others
	this.whiteboard.page.notes.push(
		this.whiteboard.page.notes.splice(this.whiteboard.page.notes.indexOf(this), 1)[0]
	);
}

WhiteboardNote.prototype.exitSelect = function ()
{
	this.whiteboard.selected_note = null;
	this.selected = false;
}

WhiteboardNote.prototype.enterFocus = function ()
{
	if ( this.whiteboard.focused_note && this.whiteboard.focused_note !== this )
	{
		this.whiteboard.focused_note.exitFocus();
	}
	this.whiteboard.focused_note = this;
	this.focused = true;
	this.whiteboard.sendAction({ action: "block_note", data: { id: this.id, participant: this.xyz.space.local_participant.id } });

	// stores current position
	this.init_position = this.position.slice();

	const start = { scale: 1, position: this.position };
	const end = { scale: 2, position: [ this.whiteboard.width / 2, this.whiteboard.height / 2 ] };

	this.animate(start, end);

	// html dialog
	this.dialog = this.htmlDialog();

	this.dialog.input.value = this.text.text.replace(/\n/g, " ");
	this.dialog.input.setAttribute("placeholder", "Write here...");

	this.html_renderer.append( this.dialog.html, this.dialog.styles );

	this.dialog.input.focus();

	this.dialog.background.addEventListener("click", (ev) => {
		this.exitFocus();
	});

	this.dialog.palette.forEach( col => {
		col.addEventListener("click", () => {
			// remove previously selected
			const selected = this.dialog.html.querySelector(`.${this.html_prefix}dialog__color--selected`);
			selected.classList.remove(`${this.html_prefix}dialog__color--selected`);
			// select new one
			col.classList.add(`${this.html_prefix}dialog__color--selected`);
			this.dialog.input.style.backgroundColor = this.whiteboard.info.colors[col.dataset.color];
			if ( this.whiteboard.info.colors[col.dataset.color] === "#ffffff" )
			{
				this.dialog.input.style.borderColor = "#efefef";
			}
			this.changeColor( col.dataset.color );
		});
	});

	this.dialog.input.addEventListener("keydown", (ev) => {
		if (ev.key === "Enter") {
			ev.preventDefault();
			this.text.text = String(ev.target.value);
			this.exitFocus();
		}
	});

	this.dialog.submit.addEventListener("click", (ev) => {
		this.text.text = String(this.dialog.input.value);
		this.exitFocus();
	});

	this.dialog.cancel.addEventListener("click", (ev) => {
		this.exitFocus();
	});
}

WhiteboardNote.prototype.changeColor = function( color )
{
	const image = ROOM.images[this.whiteboard.info.images["note--" + color + "-1"]];
	if ( image )
	{
		this.color = color;
		this.image = image;
	}
}

WhiteboardNote.prototype.exitFocus = function()
{
	if ( this.dialog )
	{
		this.dialog.html.classList.add(this.html_prefix + "dialog--out");
		setTimeout(this.html_renderer.clear.bind(this.html_renderer), 150);
	}

	this.focused = false;
	this.text.focused = false;
	if (this.whiteboard.focused_note)
	{
		this.whiteboard.focused_note = null;
	}

	const start = { scale: 2, position: this.position };
	const end = { scale: 1, position: this.init_position };

	const data = { id: this.id, text: this.text.text, color: this.color, page: this.page_index };
	this.whiteboard.sendAction({ action: "update_note", data: data });
	this.animate( start, end );
}

WhiteboardNote.prototype.animate = function(start, end, callback)
{
	if (this.tween) {
		this.tween.cancel();
	}

	this.tween = Tween.easeProperty( this.tween_completion, "v", 1, .3, Tween.EASE_IN_OUT_QUAD,
		() => {
			this.tween = null;
			this.tween_completion.v = 0;
			if ( callback )
			{
				callback();
			}
		},
		()=> {
			this.scale = lerp( start.scale, end.scale, this.tween_completion.v );
			this.position[0] = lerp( start.position[0], end.position[0], this.tween_completion.v );
			this.position[1] = lerp( start.position[1], end.position[1], this.tween_completion.v );
		}
	);
}

WhiteboardNote.prototype.htmlDialog = function()
{
	const styles = document.createElement("style");
	styles.type = "text/css";
	styles.id = this.html_prefix + "dialog__styles";

	styles.innerHTML = DialogStylesTemplate
		.toString()
		.replaceAll("__HTML_PREFIX__", this.html_prefix)
		.replaceAll("__COLOR_YELLOW__", this.whiteboard.info.colors.yellow)
		.replaceAll("__COLOR_GREEN__", this.whiteboard.info.colors.green)
		.replaceAll("__COLOR_BLUE__", this.whiteboard.info.colors.blue)
		.replaceAll("__COLOR_PINK__", this.whiteboard.info.colors.pink)
		.replaceAll("__COLOR_ORANGE__", this.whiteboard.info.colors.orange)
		.replaceAll("__COLOR_WHITE__", this.whiteboard.info.colors.white);

	const dialog = document.createElement("div");
	dialog.id = dialog.className = `${this.html_prefix}dialog`;

	dialog.innerHTML = DialogHtmlTemplate.replaceAll("__HTML_PREFIX__", this.html_prefix);

	const colors = Array.from(dialog.querySelectorAll(`.${this.html_prefix}dialog__color`));
	colors.forEach( color => {
		if (color.dataset.color === this.color)
		{
			color.classList.add(`${this.html_prefix}dialog__color--selected`);
			dialog.querySelector(`.${this.html_prefix}dialog__input`).classList.add(`${this.html_prefix}dialog__color-${this.color}`);
		}
	});

	return {
		html: dialog,
		styles: styles,
		background: dialog.querySelector(`.${this.html_prefix}dialog__background`),
		palette: Array.from(dialog.querySelectorAll(`.${this.html_prefix}dialog__color`)),
		input: dialog.querySelector(`.${this.html_prefix}dialog__input`),
		submit: dialog.querySelector(`.${this.html_prefix}dialog__submit`),
		cancel: dialog.querySelector(`.${this.html_prefix}dialog__cancel`)
	};
}

export default WhiteboardNote;
