/**
 * @param {CanvasRenderingContext2D} context
 * @param x
 * @param y
 * @param w
 * @param h
 * @param r
 * @returns {CanvasRenderingContext2D}
 * @constructor
 */
export const canvasRoundedRect = function (context, x, y, w, h, r) {
	if (w < 2 * r) r = w / 2;
	if (h < 2 * r) r = h / 2;
	context.beginPath();
	context.moveTo(x+r, y);
	context.arcTo(x+w, y,   x+w, y+h, r);
	context.arcTo(x+w, y+h, x,   y+h, r);
	context.arcTo(x,   y+h, x,   y,   r);
	context.arcTo(x,   y,   x+w, y,   r);
	context.closePath();
	return context;
}
