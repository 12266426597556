import { DEG2RAD } from "@src/constants";
import { getFullPath } from "@src/engine/Room/file-utils";
import { vec3ToArray } from "@src/gl-matrix/vec3";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { RD } from "@src/libs/rendeer";
import { ClipType } from "@src/libs/rendeer/ClipType";
import { Scene } from "@src/libs/rendeer/Scene";
import { vec3, vec4 } from "gl-matrix";

function Portal()
{
	this.enabled = true;
	this._url = "";
	this.offset = vec3.create();
	this.angle = 0;
	this.scale = 1;
	this.follow_node = true;
	this.portal_scene = new Scene();
	this.portal_info = {
		scene: this.portal_scene,
		node: null
	};


}

Portal.componentName = "Portal";
Portal.icon = [ 7,4 ];

Object.defineProperty( Portal.prototype, "url", {
	set: function(v) {
		if (v === this._url)
			return;
		this._url = v;
		this.loadPrefab( v );
	},
	get: function() { return this._url; },
	enumerable: true
});

Portal.prototype.onAdded = function(parent)
{
	LEvent.bind( xyz.view.pbrpipeline, "renderOpaque", this.onRenderOpaque, this );
}

Portal.prototype.onRemoved = function(parent)
{
	LEvent.unbind( xyz.view.pbrpipeline, "renderOpaque", this.onRenderOpaque, this );
}

Portal.prototype.serialize = function(o)
{
	o.enabled = this.enabled;
	o.url = this._url;
	o.follow_node = this.follow_node;
	o.offset = vec3ToArray( this.offset );
	o.angle = this.angle;
	o.scale = this.scale;
}

Portal.prototype.configure = function(o)
{
	this.enabled = o.enabled;
	this.url = o.url;
	this.follow_node = o.follow_node;
	this.offset.set( o.offset );
	this.angle = o.angle || 0;
	this.scale = o.scale || 1;
}

Portal.prototype.update = function(dt)
{
}

//called from view.preRender
Portal.prototype.preRender = function(view)
{
	this.portal_info.node = this.entity.node;
	this._must_render_portal = false;

	if (this.enabled && this._url && this.entity.enabled )
	{
		this.portal_scene.root.flags.no_transform = false;

		if ( this.follow_node)
		{
			this.portal_scene.root.resetTransform();
			this.portal_scene.root.position = [ this.entity.node.position[0] + this.offset[0],this.offset[1],this.entity.node.position[2] + this.offset[2] ];
			this.portal_scene.root.rotation = this.entity.node.rotation;
			this.portal_scene.root.rotate( this.angle * DEG2RAD, [ 0,1,0 ] );
			this.portal_scene.root.scale( this.scale );
			this.portal_scene.root.updateGlobalMatrix();
		}
		else
		{
			this.portal_scene.root.resetTransform();
			this.portal_scene.root.position = this.offset;
			this.portal_scene.root.rotate( this.angle * DEG2RAD, [ 0,1,0 ] );
			this.portal_scene.root.scale( this.scale );
			this.portal_scene.root.updateGlobalMatrix();
		}

		//check visibility
		var mesh = gl.meshes["plane"];
		var portal_node = this.portal_info.node;
		var model = portal_node.getGlobalMatrix();
		var camera = view.camera;
		//check if portal outside frustum
		if (camera.testMesh( mesh, model ) !== ClipType.Outside )
			this._must_render_portal = true;
	}
}

Portal.prototype.onRenderOpaque = function(e, pipeline)
{
	if (this._must_render_portal)
		this.preRenderPortal( pipeline, pipeline.renderer );
}

Portal.prototype.preRenderPortal = function( pipeline, renderer )
{
	var portal_node = this.portal_info.node;
	var scene = this.portal_info.scene;
	var camera = xyz.view.camera;
	var mesh = gl.meshes["plane"];
	var model = portal_node.getGlobalMatrix();

	//check if portal outside frustum
	if (camera.testMesh( mesh, model ) === ClipType.Outside )
		return;

	//set up mask writing
	gl.enable( gl.STENCIL_TEST );
	gl.stencilFunc( gl.ALWAYS, 1, 0xFF );
	gl.stencilOp(gl.KEEP, gl.KEEP, gl.REPLACE);
	gl.stencilMask(0xFF);
	gl.depthMask(false);
	gl.colorMask(false, false,false, false);
	gl.clear( WebGL2RenderingContext.STENCIL_BUFFER_BIT );
	gl.disable( gl.CULL_FACE );

	//render mask
	renderer.renderMesh( model, mesh, null, [ 1,1,1,1 ] );
	gl.colorMask(true,true,true,true);
	gl.depthMask(true);
	gl.stencilFunc(gl.EQUAL, 1, 0xFF);
	gl.stencilMask(0x00);

	//render secondary scene
	var secondary_nodes = scene._root.getVisibleChildren();
	var node_pos = this.entity.node.getGlobalPosition();
	var cam_front = vec3.sub( vec3.create(), node_pos, camera.position );//camera._front;
	var node_front = this.entity.node.getGlobalVector([ 0,0,-1 ]);
	var front_dot = vec3.dot( cam_front, node_front );
	if ( front_dot < 0 )
		vec3.scale( node_front, node_front, -1 ); //reverse

	var old_clip_plane = vec4.clone( pipeline.global_uniforms.u_clipping_plane );
	pipeline.setClippingPlane( node_pos, node_front );
	pipeline.renderNodes( secondary_nodes, camera ); //render all
	pipeline.global_uniforms.u_clipping_plane.set(old_clip_plane);

	//disable all
	gl.disable( gl.STENCIL_TEST );

	//block depth
	gl.colorMask(false, false,false, false);
	gl.disable( gl.CULL_FACE );
	renderer.renderMesh( model, mesh, null, [ 1,1,1,1 ] );
	gl.colorMask(true,true,true,true);
	gl.stencilMask(0xFF);
}


Portal.prototype.onRenderInspector = function(ctx, x,y,w,h, editor )
{
	//use automatic interface generator
	//y = editor.renderDefaultInspector( ctx, x,y,w,h, this );
	var component = this;
	Label.call(GUI, x,y,100,24,"url");
	component.url = GUI.TextField( x + 100,y,w-140,24, component.url || "", null, true );
	if ( Button.call(GUI, x + w - 30,y,24,24, [ 2,0 ] ) )
	{
		editor.selectFile(function(file) {
			component.url = file ? file.localpath : null;
		},[ "glb","gltf" ]);
	}
	y += 30;

	this.follow_node = GUI.Toggle( x, y, w, 24, "Follow Node", this.follow_node );
	y += 30;

	Label.call(GUI, x,y,100,24,"Offset");
	GUI.Vector(x + 100, y, w - 100, 24, this.offset);
	y += 30;
	Label.call(GUI, x,y,100,24,"Angle");
	this.angle = GUI.Number(x + 100, y, w - 100, 24, this.angle);
	y += 30;
	Label.call(GUI, x,y,100,24,"Scale");
	this.scale = GUI.Number(x + 100, y, w - 100, 24, this.scale);
	y += 30;
}

Portal.prototype.renderGizmo = function( view, editor, selected )
{
}

Portal.prototype.loadPrefab = function( url )
{
	RD.GLTF.rename_assets = true
	this.portal_scene.root.removeAllChildren();
	this.portal_scene.root.loadGLTF( getFullPath(url), function() {
		RD.GLTF.rename_assets = false;
	});
}

export default Portal;
