import { vec3 } from "gl-matrix";

function CloudsObject()
{
	this._on_focus = false;

	this._active = true;
	this._clb = TmrwModule.NativeRoomAPI.CloudsBuilder();

	this._color = vec3.create();
	vec3.set(this._color, 1.0, 1.0, 1.0);

	this._native = null;
	this._dirty = false;

	this._weather = "Type 1";
	this._resolution = "Half Res";

	this._gen = 0;


	this._index = CloudsObject.last_index++;
}

CloudsObject.componentName = "CloudsObject";
CloudsObject.last_index = 1;

CloudsObject.icon = [ 6,3 ];

CloudsObject.widgets = {
	"Active": { type: "toggle" },
	"Box Min": { type: "vector3", min: -100.0, max: 100.0 },
	"Box Max": { type: "vector3", min: -100.0, max: 100.0 },
	"Blue Noise": { type: "toggle" },
	"Wind Animation": { type: "toggle" },
	"Global Wind": { type: "toggle" },
	"High Noise": { type: "toggle" },
	"Recv Shadows": { type: "toggle" },
	"Weather Type": { type: "enum", values: ["Type 1", "Type 2"] },
	"Resolution": { type: "enum", values: ["Native", "Half Res", "Quarter Res"] },
	"Tile Detail": { type: "slider", min: 0.01, max: 10.0, mark: 0.3 },
	"Tile High": { type: "slider", min: 0.01, max: 10.0, mark: 0.75 },
	"Tile Weather": { type: "slider", min: 0.01, max: 10.0, mark: 1.0 },
	"Global Coverage": { type: "slider", min: 0.0, max: 1.0, mark: 0.2 },
	"Global Density": { type: "slider", min: 0.01, max: 1.0, mark: 1.0 },
	"Light Absorption": { type: "slider", min: 0.0, max: 1.0, mark: 0.3 },
	"In-Scatter": { type: "slider", min: 0.0, max: 1.0, mark: 0.43 },
	"Out-Scatter": { type: "slider", min: 0.0, max: 1.0, mark: 0.06 },
	"Scatter Ambient": { type: "slider", min: 0.0, max: 1.0, mark: 0.01 },
	"Scatter Ratio": { type: "slider", min: 0.0, max: 1.0, mark: 0.1 },
	"Silver Intensity": { type: "slider", min: 0.0, max: 10.0, mark: 5.0 },
	"Silver Exponent": { type: "slider", min: 0.0, max: 10.0, mark: 2.3 },
	"Wind Speed": { type: "slider", min: 0.0, max: 10.0, mark: 0.3 },
	"Wind Direction": { type: "vector3", min: -1.0, max: 1.0 },
	"Color": { type: "color" },
	"GenNoise": { type: "button", title: "Generate Noise" },
}


Object.defineProperty(CloudsObject.prototype, "Active", {
	get: function () {
		return this._clb.active;
	},
	set: function (v) {
		if (this._clb.active == v)
			return;
		this._dirty = true;
		this._clb.active = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Blue Noise", {
	get: function () {
		return this._clb.useBlueNoise;
	},
	set: function (v) {
		if (this._clb.useBlueNoise == v)
			return;
		this._dirty = true;
		this._clb.useBlueNoise = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Wind Animation", {
	get: function () {
		return this._clb.useWindAnimation;
	},
	set: function (v) {
		if (this._clb.useWindAnimation == v)
			return;
		this._dirty = true;
		this._clb.useWindAnimation = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Global Wind", {
	get: function () {
		return this._clb.useGlobalWind;
	},
	set: function (v) {
		if (this._clb.useGlobalWind == v)
			return;
		this._dirty = true;
		this._clb.useGlobalWind = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "High Noise", {
	get: function () {
		return this._clb.useHighNoise;
	},
	set: function (v) {
		if (this._clb.useHighNoise == v)
			return;
		this._dirty = true;
		this._clb.useHighNoise = v;
	}
});
Object.defineProperty(CloudsObject.prototype, "Recv Shadows", {
	get: function () {
		return this._clb.receiveShadows;
	},
	set: function (v) {
		if (this._clb.receiveShadows == v)
			return;
		this._dirty = true;
		this._clb.receiveShadows = v;
	}
});

CloudsObject.prototype.GenNoise = function () {
	var gen = this._clb.genNoise;
	gen = gen + 1;
	this._clb.genNoise = gen;
}

Object.defineProperty(CloudsObject.prototype, "Weather Type", {
	get: function () {
		return this._weather;
	},
	set: function (v) {
		if (this._weather == v)
			return;
		this._dirty = true;
		if (v == "Type 1")
			this._clb.weatherType = 0;
		else
			this._clb.weatherType = 1;
	}
});

Object.defineProperty(CloudsObject.prototype, "Resolution", {
	get: function () {
		return this._resolution;
	},
	set: function (v) {
		if (this._resolution == v)
			return;
		this._dirty = true;
		if (v == "Native")
			this._clb.resolution = 0;
		else
		if (v == "Half Res")
			this._clb.resolution = 1;
		else
			this._clb.resolution = 2;
	}
});

Object.defineProperty(CloudsObject.prototype, "Tile Detail", {
	get: function () {
		return this._clb.tileDetail;
	},
	set: function (v) {
		if (this._clb.tileDetail == v)
			return;
		this._dirty = true;
		this._clb.tileDetail = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Tile High", {
	get: function () {
		return this._clb.tileWind;
	},
	set: function (v) {
		if (this._clb.tileWind == v)
			return;
		this._dirty = true;
		this._clb.tileWind = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Tile Weather", {
	get: function () {
		return this._clb.tileWeather;
	},
	set: function (v) {
		if (this._clb.tileWeather == v)
			return;
		this._dirty = true;
		this._clb.tileWeather = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Wind Speed", {
	get: function () {
		return this._clb.windSpeed;
	},
	set: function (v) {
		if (this._clb.windSpeed == v)
			return;
		this._dirty = true;
		this._clb.windSpeed = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Wind Direction", {
	get: function () {
		return this._clb.windDirection;
	},
	set: function (v) {
		if (vec3.equals(this._clb.windDirection, v))
			return;
		this._dirty = true;
		vec3.copy(this._clb.windDirection, v);
	}
});

Object.defineProperty(CloudsObject.prototype, "Box Min", {
	get: function () {
		return this._clb.boxMin;
	},
	set: function (v) {
		if (vec3.equals(this._clb.boxMin, v))
			return;
		this._dirty = true;
		vec3.copy(this._clb.boxMin, v);
	}
});

Object.defineProperty(CloudsObject.prototype, "Box Max", {
	get: function () {
		return this._clb.boxMax;
	},
	set: function (v) {
		if (vec3.equals(this._clb.boxMax, v))
			return;
		this._dirty = true;
		vec3.copy(this._clb.boxMax, v);
	}
});

Object.defineProperty(CloudsObject.prototype, "Global Coverage", {
	get: function () {
		return this._clb.globalCoverage;
	},
	set: function (v) {
		if (this._clb.globalCoverage == v)
			return;
		this._dirty = true;
		this._clb.globalCoverage = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Global Density", {
	get: function () {
		return this._clb.globalDensity;
	},
	set: function (v) {
		if (this._clb.globalDensity == v)
			return;
		this._dirty = true;
		this._clb.globalDensity = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Light Absorption", {
	get: function () {
		return this._clb.globalLightAbsorption;
	},
	set: function (v) {
		if (this._clb.globalLightAbsorption == v)
			return;
		this._dirty = true;
		this._clb.globalLightAbsorption = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "In-Scatter", {
	get: function () {
		return this._clb.cloudInScatter;
	},
	set: function (v) {
		if (this._clb.cloudInScatter == v)
			return;
		this._dirty = true;
		this._clb.cloudInScatter = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Out-Scatter", {
	get: function () {
		return this._clb.cloudOutScatter;
	},
	set: function (v) {
		if (this._clb.cloudOutScatter == v)
			return;
		this._dirty = true;
		this._clb.cloudOutScatter = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Scatter Ambient", {
	get: function () {
		return this._clb.cloudOutScatterAmbient;
	},
	set: function (v) {
		if (this._clb.cloudOutScatterAmbient == v)
			return;
		this._dirty = true;
		this._clb.cloudOutScatterAmbient = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Scatter Ratio", {
	get: function () {
		return this._clb.cloudScatterRatio;
	},
	set: function (v) {
		if (this._clb.cloudScatterRatio == v)
			return;
		this._dirty = true;
		this._clb.cloudScatterRatio = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Silver Intensity", {
	get: function () {
		return this._clb.cloudSilverIntensity;
	},
	set: function (v) {
		if (this._clb.cloudSilverIntensity == v)
			return;
		this._dirty = true;
		this._clb.cloudSilverIntensity = v;
	}
});

Object.defineProperty(CloudsObject.prototype, "Silver Exponent", {
	get: function () {
		return this._clb.cloudSilverExponent;
	},
	set: function (v) {
		if (this._clb.cloudSilverExponent == v)
			return;
		this._dirty = true;
		this._clb.cloudSilverExponent = v;
	}
});


Object.defineProperty(CloudsObject.prototype, "Color", {
	get: function () {
		return this._color;
	},
	set: function (v) {
		vec3.copy(this._color, v);
	}
});


CloudsObject.prototype.onAdded = function (parent) {
	LEvent.bind(parent, "clicked", this.onClicked, this);

	this._node = parent.node;
}

CloudsObject.prototype.createNative = function () {
	if (this._root && nativeEngine && !this._native) {
		if (!this._root._native) {
			// Andrey FIXME: Hack to place new volume entity in front of camera
			// Btw for some reason this camera position doesn't changed when we fly in the editor mode!
			var camera = ViewCore.instance.hard_camera;
			var cam_pos = camera.position;
			var cam_front = camera.getFront();
			var dist = 1.0;

			var offset = vec3.scale(vec3.create(), cam_front, dist);
			vec3.add(this._node.position, cam_pos, offset);
			this._node.mustUpdate = true;

			var pos = this._node.position;
			var clMin = vec3.create();
			var clMax = vec3.create();

			clMin[0] = pos[0] - 8;
			clMax[0] = pos[0] + 8;

			clMin[2] = pos[2] - 8;
			clMax[2] = pos[2] + 8;

			clMin[1] = pos[1] + 0;
			clMax[1] = clMin[1] + 1.5;

			this._clb.boxMin = clMin;
			this._clb.boxMax = clMax;

			this._root.createNativeEntity(this._root.index);
		}


		this._clb.build(this._root._native);

		this._native = this._root._native;

		this._dirty = true;
	}
}


CloudsObject.prototype.onRemoved = function(parent)
{
	LEvent.unbind( parent,"clicked", this.onClicked, this );

	this._node = null;
	if (this._native) {
		//nativeEngine._room.destroyEntityObj(this._native);
		this._native = null;
	}
	if (this._clb)
		this._clb.delete();
	this._clb = null;
}

CloudsObject.prototype.serialize = function(o)
{
	o.active = this._clb.active;
	o.boxMin = this._clb.boxMin;
	o.boxMax = this._clb.boxMax;
	o.useBlueNoise = this._clb.useBlueNoise;
	o.useWindAnimation = this._clb.useWindAnimation;
	o.useGlobalWind = this._clb.useGlobalWind;
	o.useHighNoise = this._clb.useHighNoise;
	o.receiveShadows = this._clb.receiveShadows;
	o.weatherType = this._clb.weatherType;
	o.resolution = this._clb.resolution;
	o.tileDetail = this._clb.tileDetail;
	o.tileWind = this._clb.tileWind;
	o.tileWeather = this._clb.tileWeather;
	o.windDirection = this._clb.windDirection;
	o.windSpeed = this._clb.windSpeed;
	o.globalCoverage = this._clb.globalCoverage;
	o.globalDensity = this._clb.globalDensity;
	o.globalLightAbsorption = this._clb.globalLightAbsorption;
	o.cloudInScatter = this._clb.cloudInScatter;
	o.cloudOutScatter = this._clb.cloudOutScatter;
	o.cloudOutScatterAmbient = this._clb.cloudOutScatterAmbient;
	o.cloudScatterRatio = this._clb.cloudScatterRatio;
	o.cloudSilverIntensity = this._clb.cloudSilverIntensity;
	o.cloudSilverExponent = this._clb.cloudSilverExponent;
}

CloudsObject.prototype.configure = function(o)
{
  this._clb.active = o.active;
  this._clb.boxMin = o.boxMin;
  this._clb.boxMax = o.boxMax;
  this._clb.useBlueNoise = o.useBlueNoise;
  this._clb.useWindAnimation = o.useWindAnimation;
  this._clb.useGlobalWind = o.useGlobalWind;
  this._clb.useHighNoise = o.useHighNoise;
  this._clb.receiveShadows = o.receiveShadows;
  this._clb.weatherType = o.weatherType;
  this._clb.resolution = o.resolution;
  this._clb.tileDetail = o.tileDetail;
  this._clb.tileWind = o.tileWind;
  this._clb.tileWeather = o.tileWeather;
  this._clb.windDirection = o.windDirection;
  this._clb.windSpeed = o.windSpeed;
  this._clb.globalCoverage = o.globalCoverage;
  this._clb.globalDensity = o.globalDensity;
  this._clb.globalLightAbsorption = o.globalLightAbsorption;
  this._clb.cloudInScatter = o.cloudInScatter;
  this._clb.cloudOutScatter = o.cloudOutScatter;
  this._clb.cloudOutScatterAmbient = o.cloudOutScatterAmbient;
  this._clb.cloudScatterRatio = o.cloudScatterRatio;
  this._clb.cloudSilverIntensity = o.cloudSilverIntensity;
  this._clb.cloudSilverExponent = o.cloudSilverExponent;


	var c = vec3.create();
	if (o.color) {
		c[0] = o.color[0];
		c[1] = o.color[1];
		c[2] = o.color[2];
	}
	else {
		c[0] = 1;
		c[1] = 1;
		c[2] = 1;
	}
	this._clb.color = c;
	this._color = c;


	this._dirty = true;

	this._index = CloudsObject.last_index++;
}

CloudsObject.prototype.onClicked = function(e)
{
	//console.debug("clicked",this.entity.name);
	if (!this._on_focus)
		this.enterFocus();
	else //leaving
		this.exitFocus();
}


CloudsObject.prototype.preRender = function(view)
{
	if (!this._native) {
		if (xyz.native_loaded)
			this.createNative();
	}
	if (!this._native) {
		return;
	}

	if (this._dirty || !vec3.equals(this._color, this._clb.color)) {

		this._dirty = false;

		this._clb.color = this._color;

		this._clb.build(this._native);
	}
}


CloudsObject.prototype.enterFocus = function()
{
	if (this._on_focus)
		return;

	LEvent.bind( this.space, "keydown", this.onKey, this );
	LEvent.bind( this.space, "mouse", this.onGlobalMouse, this );

	//compute radius
	var original_pos = this.entity.node.getGlobalPosition();
	var bb = node.updateBoundingBox();
	var center = BBox.getCenter(bb);
	var halfsize = BBox.getHalfsize(bb);
	vec3.mul( halfsize, halfsize, node.scaling );
	var radius = vec3.length( halfsize );// / this.entity.node.scaling[0];
	console.debug(radius);
	radius *= 1.5;
}

CloudsObject.prototype.exitFocus = function()
{
	if (!this._on_focus)
		return;

	LEvent.unbind(this.space, "keydown", this.onKey, this);
	LEvent.unbind(this.space, "mouse", this.onGlobalMouse, this);

	this._on_focus = false;
}

CloudsObject.prototype.onKey = function(type,e)
{
	if (e.key == "Escape")
	{
		this.exitFocus();
	}
	return true;
}

CloudsObject.prototype.onGlobalMouse = function(type,e)
{
	//console.debug(e);
	if (!this._on_focus)
		return;

	var speed = 0.02;

	if ( e.dragging )
	{
		//var node = this.entity.node;
		//var node = this._inspect_node;
		//var local_up = node.globalVectorToLocal( [0,1,0] );
		//var local_right = node.globalVectorToLocal( [1,0,0] );
		//node.rotate( e.deltax * speed, local_up );
		//node.rotate( e.deltay * speed, local_right );
	}

	if ( e.dragging && e.type == "mousemove")
		return true;
}

export default CloudsObject;

