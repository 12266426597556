import RoomMediaStream from "@src/engine/helpers/mediaStream";
import ROOM from "@src/engine/room";
import { getFullPath } from "@src/engine/Room/file-utils";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { Process } from "@src/libs/adaptiveJobSystem";
import { GL } from "@src/libs/litegl";
import { mat3, vec4 } from "gl-matrix";

import ChromaFs from "./FeedManager/Chroma.fs.glsl";
import CropFs from "./FeedManager/Crop.fs.glsl";

/**
 * Responsible for triggering updates of stream texture (upload to GPU)
 * @see {@link /libs/adaptiveJobSystem.js} for details
 */
class FeedUpdateProcess extends Process {
	/**
     *
     * @param {{stream:RoomMediaStream|null, process:Process}} feed_info
     */
	constructor(feed_info) {
		super();

		this.__feed_info = feed_info;
	}

	step(t) {
		const feed = this.__feed_info;

		if (feed === undefined || feed === null) {
			return 1; // Yield
		}

		const stream = feed.stream;
		if (stream === undefined || stream === null) {
			return 1; // Yield
		}

		if (!stream.needs_texture_update) {
			return 1; // Yield
		}

		// Trigger upload
		stream.toTexture();

		// force clear the flag
		stream.needs_texture_update = false;

		return 1; // Yield
	}
}

//In charge of handling how and when the video streams of users should be uploaded to the GPU
//it will apply the chroma keying and other shaders generating the final image
//Created from XYZLauncher
function FeedManager( xyz )
{
	this.xyz = xyz;
	this.enabled = true;
	this.mode = FeedManager.DIRECT;
	this.use_chroma = true; //force chroma shader

	this.feeds = [];

	this.global_feed = null; //used for MCU
	this.surface_stream = null; //to display in surfaces
	this.default_surface_stream = null;

	//atlas
	this.atlas_resolution = [ 2048,1024 ];
	this.item_height = 256;
	this.dynamic_size = true;

	this.canvas = null;

	this._waiting_last_frame = false;
	this._pending_frames = 0;
	this._last_upload_time = 0;

	if (this.mode === FeedManager.ATLAS || this.mode === FeedManager.DEFERRED_ATLAS )
		this.createAtlas();
}

//three modes of working
FeedManager.DIRECT = "direct"; //every feed uses individual texture
//deprecated?
FeedManager.ATLAS = "atlas_direct"; //every feed is uploaded to the same atlas
FeedManager.DEFERRED_ATLAS = "atlas_deferred"; //every feed is uploaded to the same atlas using the deferred method

//to trigger when to call function
FeedManager.prototype.bindEvents = function()
{
	LEvent.bind( this.xyz, "postRender", function( e, view ) {
		//we update the feeds at the end of the frame, to be sure no sync problems
		if (this.enabled)
			this.updateFeeds( this.xyz.view );
	}, this);
}

FeedManager.prototype._prepareSurfaceStream = function( feed, feed_options )
{
	feed_options = feed_options || {};
	feed_options.surface_stream = true;

	if (!feed_options.framerate)
		feed_options.framerate = ROOM_SETTINGS.feeds.upload_feed_rate;

	var stream = null;
	//create a media stream
	if ( feed.constructor !== RoomMediaStream)
	{
		stream = new RoomMediaStream( this, feed_options );
		stream.shared = true;
		stream.assignFeed( feed );
	}
	else
		stream = feed;

	return stream;
}

FeedManager.prototype.setDefaultSurfaceFeed = function( feed, feed_options )
{
	const isDefaultStreamAssigned = this.default_surface_stream === this.surface_stream;

	this.default_surface_stream = feed ? this._prepareSurfaceStream(getFullPath(feed), feed_options) : null;

	if (isDefaultStreamAssigned) {
		this.surface_stream = this.default_surface_stream;
		this.xyz.space.surface_stream = this.default_surface_stream;
	}
}

FeedManager.prototype.assignSurfaceFeed = function( feed, feed_options )
{
	const space = this.xyz.space;

	feed = getFullPath(feed);

	LEvent.trigger( space, "global_stream_changed", feed );

	//locked means it is in use by other element
	if (this.surface_stream && !this.surface_stream._locked && this.surface_stream.free )
		this.surface_stream.free();

	if (feed)
	{
		const stream = this._prepareSurfaceStream(feed, feed_options);

		this.surface_stream = stream;
		space.surface_stream = stream;
	}
	else {
		this.surface_stream = this.default_surface_stream;
		space.surface_stream = this.default_surface_stream;
	}


	/* DEPRECATED: previously it was assigned to every entity on the scene, now surfaces choose
	//assign this media stream to every surface
	var entities = this.space.getAllEntities();
	for(var i = 0; i < entities.length; ++i)
	{
		var entity = entities[i];
		if(!entity.surface || entity.surface.skip_screenshare )
			continue;
		entity.assignFeed( stream );
	}
	*/
}


//called everytime a user gets a stream attached to it
FeedManager.prototype.assignFeed = function( participant, feed, feed_options )
{
	feed_options = feed_options || {};

	//if the feed uses MCU
	if (this.mode !== FeedManager.DIRECT )
	{
		feed_options.mcu = true;
		feed_options.rectangle = vec4.create();
	}

	//if null, remove feed from user
	if ( !feed )
	{
		this.removeFeed( participant );
		return null;
	}

	//weird case where the same feed is passed twice to the same user
	if ( participant.feed_info )
		this.removeFeed( participant );

	//create object to store all info
	var feed_info = {
		participant: participant,
		video: feed,
		options: feed_options,
		texture: null, //where the feed is uploaded
		final_texture: null, //where the final image with the chroma is stored
		blur_level: 0,
	};

	//create MediaStream to upload as texture (only if not mcu or first mcu)
	if (!feed_options.mcu || (feed_options.mcu && !this.global_feed))
	{
		var stream = new RoomMediaStream(this);
		stream.wait_click = false;
		stream._locked = true;
		if (this.xyz.native_mode && ROOM_SETTINGS.feeds.force_mipmaps_when_available)
			stream.force_mipmaps = true;
		stream.assignFeed( feed, feed_options );
		feed_info.stream = stream;
	}

	//inform user
	participant.avatar.mode = RoomAvatar.FEED_2D;

	//prepare the video element
	if (feed && feed.constructor === HTMLVideoElement)
	{
		var video = feed;
		video.loop = true;
		video.autoplay = true;
		video.crossOrigin = ROOM.crossOrigin;
		if (video.play)
			video.play();
	}

	// schedule texture update process
	feed_info.process = new FeedUpdateProcess(feed_info);
	if (!feed_info.stream)
		console.error("FeedInfo without stream?");
	feed_info.stream.upload_done_externally = true;
	this.xyz.process_scheduler.add(feed_info.process);

	this.feeds.push(feed_info);

	if ( feed_options.mcu && !this.global_feed )
		this.global_feed = feed_info;

	return feed_info;
}


//called from call (at the very end of frame) to update the feed of all participants for the next frame
FeedManager.prototype.updateFeeds = function( view )
{
	var native = xyz.native_mode;

	//upload the global once (in case of MCU)
	if ( this.global_feed )
		this.global_feed.stream.preRender( view );

	//upload shared screen to GPU (what if not visible?)
	if ( this.surface_stream )
		this.surface_stream.preRender( view );

	//check all feeds
	for (var i = 0; i < this.feeds.length; ++i)
	{
		var feed_info = this.feeds[i];
		var participant = feed_info.participant;
		var avatar = participant.avatar;
		var stream = feed_info.stream;

		//if on hold, reblur previous texture
		if ( participant.is_on_hold )
		{
			var texture = feed_info.final_texture || feed_info.stream.getTexture();
			if ( texture && feed_info.blur_level < 10 )
			{
				feed_info.blur_level++;
				texture.applyBlur(1,1,1);
			}
			continue;
		}
		else
			feed_info.blur_level = 0;


		//if MCU, then just copy the area to an individual texture
		if ( feed_info.options.mcu && feed_info.options.rectangle )
		{
			this.cropRegion( this.global_feed.stream.getTexture(), feed_info );
		}
		else //upload video to GPU
		{
			stream.preRender( view );
			feed_info.texture = stream.getTexture();
		}

		//apply chroma shader
		if ( feed_info.texture && ( feed_info.options.chroma || this.use_chroma ) && !feed_info.options.has_alpha )
			feed_info.texture = feed_info.final_texture = this.applyChroma( feed_info.texture, feed_info.final_texture, feed_info.participant );

		//apply special FX
		//...

		//assign to avatar
		avatar.setFeedTexture( feed_info.texture, feed_info.options );
	}
}

//creates a texture that only contains a portion of the texture atlas
//this is required as actions like blurring a user texture require it to be in an independent texture
FeedManager.prototype.cropRegion = function( origin_texture, feed_info )
{
	if (!origin_texture)
		return null;
	if (!feed_info.options.rectangle)
		throw ("mcu rectangle missing");
	//create texture with area as size
	var w = feed_info.options.rectangle[2];
	var h = feed_info.options.rectangle[3];
	if ( feed_info.options.normalized )
	{
		w = Math.floor(w * origin_texture.width);
		h = Math.floor(h * origin_texture.height);
	}
	if (!feed_info.texture || feed_info.texture.width !== w || feed_info.texture.height !== h )
	{
		if (feed_info.texture)
			feed_info.texture.delete();
		feed_info.texture = new GL.Texture(w,h,{ format: GL.RGBA, filter: gl.LINEAR });
		feed_info.texture.name = ":cropped_feed_" + feed_info.participant.index;
		gl.textures[feed_info.texture.name] = feed_info.texture;
	}

	//clone mcu area to individual texture
	var shader_name = "crop";
	var shader = gl.shaders[ shader_name ];
	if (!shader)
		shader = gl.shaders[ shader_name ] = new GL.Shader( GL.Shader.SCREEN_VERTEX_SHADER, FeedManager.CROP_FSCODE );

	feed_info.texture_matrix = this.getRectangleTransformMatrix( origin_texture, feed_info.options.rectangle, feed_info.options.normalized, feed_info.texture_matrix );
	shader.setUniform( "u_texture_matrix", feed_info.texture_matrix );

	gl.disable( GL.BLEND );
	gl.disable( GL.DEPTH_TEST );
	feed_info.texture.drawTo(function() {
		origin_texture.toViewport( shader );
	});

	return feed_info.texture;
}

//given a texture, removes the green chroma and stores the result in output_texture
//ATTENTION: IT IS IMPORTANT TO PASS THE SAME OUTPUT TEXTURE EVERYTIME, OTHERWISE IT WILL CREATE GARBAGE AND CRASH!!!
FeedManager.prototype.applyChroma = function( input_texture, output_texture, participant )
{
	if (!output_texture || output_texture.width !== input_texture.width || output_texture.height !== input_texture.height )
	{
		output_texture = new GL.Texture(input_texture.width, input_texture.height, { format: GL.RGBA, filter: gl.LINEAR  });
		var texname = output_texture.name = ":chroma_feed_" + participant.index;
		gl.textures[texname] = output_texture;
	}

	var shader_name = "chroma";
	var shader = gl.shaders[ shader_name ];
	if (!shader)
		shader = gl.shaders[ shader_name ] = new GL.Shader( GL.Shader.SCREEN_VERTEX_SHADER, FeedManager.CHROMA_FSCODE );

	gl.disable( GL.BLEND );
	gl.disable( GL.DEPTH_TEST );
	output_texture.drawTo(function() {
		shader.setUniform("u_viewport",gl.viewport_data);
		input_texture.toViewport( shader, FeedManager.CHROMA_UNIFORMS );
	});

	return output_texture;
}

//construct a matrix that if you multiply the UV adapts to the region
FeedManager.prototype.getRectangleTransformMatrix = function(texture, rect, normalized, matrix)
{
	matrix = matrix || mat3.create();

	var nx = rect[0];
	var ny = rect[1];
	var nw = rect[2];
	var nh = rect[3];

	if (!normalized)
	{
		nx = rect[0] / texture.width;
		ny = rect[1] / texture.height;
		nw = rect[2] / texture.width;
		nh = rect[3] / texture.height;
	}

	matrix[0] = nw;
	matrix[4] = nh;
	matrix[6] = nx;
	matrix[7] = 1 - (nh + ny);

	return matrix;
}

//remove from the feed list, it will stop being updated
FeedManager.prototype.removeFeed = function(participant)
{
	var feed_info = this.findParticipantFeed(participant);
	if (!feed_info)
		return;

	//remove from container
	var index = this.feeds.indexOf(feed_info);
	if ( index != -1 ) {
		this.feeds.splice(index,1);

		// removed scheduled update process
		this.xyz.process_scheduler.remove(feed_info.process);
		feed_info.process = null;
	}
	else
		console.warn("Feed info was not found in feeds? possible bug");

	//switch image back to profile picture or silhouette
	participant.avatar.setFeedTexture( null );

	//we deleted last so native engine has it during removal.
	if (feed_info.final_texture)
		feed_info.final_texture.delete();
}

//find feed based on participant
FeedManager.prototype.findParticipantFeed = function(participant)
{
	for (var i = 0; i < this.feeds.length; ++i)
	{
		var feed_info = this.feeds[i];
		if (feed_info.participant === participant)
			return feed_info;
	}
	return null;
}

//creates a canvas where to store all
FeedManager.prototype.createAtlas = function()
{
	console.debug("* Creating feed atlas ",this.atlas_resolution[0],this.atlas_resolution[1]);
	this.canvas = document.createElement("canvas");
	this.canvas.width = this.atlas_resolution[0];
	this.canvas.height = this.atlas_resolution[1];
	//document.body.appendChild( this.canvas ); //debug
}

//computes the atlas width based on the number of people
FeedManager.prototype.computeFeedWidth = function(view)
{
	var area_size = this.item_height;
	if ( !this._enough_room )
		area_size *= 0.75;
	else if ( this.dynamic_size )
	{
		if ( this.feeds.length < 4 )
			area_size *= 1.5;
	}
	return area_size;
}

FeedManager.prototype.preRender = function(view)
{
}

FeedManager.prototype.postRender = function(view)
{
}

//called from controllers manually
//fills the atlas with the latest user frame
FeedManager.prototype.generateAtlas = function()
{
	this._frame++;

	if (ROOM_SETTINGS.feeds.upload_feed_rate !== 0 )
	{
		var ms = 1000 / ROOM_SETTINGS.feeds.upload_feed_rate;
		var t = getTime();
		var elapsed = t - this._last_upload_time;
		if ( elapsed < ms )
			return;
		this._last_upload_time = t;
	}

	if (this.mode === FeedManager.ATLAS )
		this.generateAtlasDirect();
	else if (this.mode === FeedManager.DEFERRED_ATLAS )
		this.generateAtlasDeferred()
}

FeedManager.prototype.generateAtlasDirect = function(view)
{
	//draw feeds to canvas
	var canvas = this.canvas;
	var ctx = this.canvas.getContext("2d");
	ctx.clearRect(0,0,canvas.width,canvas.height);
	var area_size = this.computeFeedWidth();

	var x = 0;
	var y = 0;

	this._enough_room = true;
	var feeds = this.feeds;
	for (var i = 0; i < feeds.length; ++i)
	{
		var feed = feeds[i];

		//check participant visible
		if ( !feed.participant.was_visible || !feed.video || !feed.video.videoWidth || feed.video.readyState <= 1 )
			continue;

		//offset
		if ((x + h_size) > canvas.width )
		{
			x = 0;
			y += area_size;
		}
		if ( (y + h_size) > canvas.height )
		{
			this._enough_room = false;
			break;
		}

		//draw feed in atlas
		var h_size = Math.floor(area_size * (feed.video.videoWidth / feed.video.videoHeight));
		var rect = [ x,y,h_size, area_size ];
		feed.options.rectangle.set(rect);

		ctx.drawImage( feed.video, rect[0], rect[1], rect[2], rect[3] );

		x += h_size;
	}
}

FeedManager.prototype.generateAtlasDeferred = function()
{
	if ( this._waiting_last_frame )
		return;

	var that = this;

	//draw feeds to canvas
	var canvas = this.canvas;
	var ctx = this.canvas.getContext("2d");
	var deferred_settings = null; //{imageOrientation:"flipY"};
	var area_size = this.computeFeedWidth();

	var x = 0;
	var y = 0;

	var promises = [];
	if (!this._feeds_pending)
		this._feeds_pending = [];
	var feeds_pending = this._feeds_pending;
	feeds_pending.length = 0;

	this._enough_room = true;
	var feeds = this.feeds;
	this._pending_frames = 0;

	for (var i = 0; i < feeds.length; ++i)
	{
		var feed = feeds[i];

		//check participant visible
		if ( !feed.participant.was_visible || !feed.video || !feed.video.videoWidth || feed.video.readyState <= 1 )
			continue;

		//offset
		if ((x + h_size) > canvas.width )
		{
			x = 0;
			y += area_size;
		}
		if ( (y + h_size) > canvas.height )
		{
			this._enough_room = false;
			break;
		}

		//draw feed in atlas
		var h_size = Math.floor(area_size * (feed.video.videoWidth / feed.video.videoHeight));
		var rect = [ x,y,h_size, area_size ];
		feed._future_rectangle = rect;

		//promises.push( createImageBitmap( feed.video, {resizeWidth:rect[2],resizeHeight:rect[3],resizeQuality:"low"}) ); //imageOrientation:"flipY"
		//, {resizeWidth:rect[2],resizeHeight:rect[3],resizeQuality:"pixelated"}
		createImageBitmap( feed.video ).then((function(image) {
			var feed = this.feed;
			var rect = feed._future_rectangle;
			ctx.clearRect( rect[0], rect[1], rect[2], rect[3] );
			ctx.drawImage( image, rect[0], rect[1], rect[2], rect[3] );
			feed.options.rectangle.set(rect);
			that._pending_frames--;
			if (that._pending_frames === 0 )
				that._waiting_last_frame = false;
		}).bind({ feed:feed }));

		this._pending_frames++;
		//feeds_pending.push( feed );
		x += h_size;
	}

	if ( this._pending_frames )
		this._waiting_last_frame = true;

	/*
	Promise.all(promises).then( draw_all );
	function draw_all( images )
	{
		that._waiting_last_frame = false;

		//ctx.clearRect(0,0,canvas.width,canvas.height);
		if(!that._feeds_pending)
			return;
		var feeds_pending = that._feeds_pending;

		for(var i = 0; i < feeds_pending.length; ++i)
		{
			var feed = feeds_pending[i];
			var image = images[i];
			if(!image)
				continue;
			var rect = feed._future_rectangle;
			if(!rect) //???
				continue;
			ctx.drawImage( image, rect[0], rect[1], rect[2], rect[3] );
			feed.options.rectangle.set(rect);
		}
	}
	*/
}

// CHROMA SHADER **************************************

FeedManager.SHADER_NAME = "alpha_boost_fx";
FeedManager.SHADER_UNIFORMS = {	u_brightness: 1 };

FeedManager.CHROMA_UNIFORMS = {
	u_brightness: 1,
	u_similarity: 0.4,
	u_smoothness: 0.1,
	u_spill     : 0.05,
	u_key       : [ 0, 1, 0 ]
};

FeedManager.CHROMA_FSCODE = ChromaFs;

FeedManager.CROP_FSCODE = CropFs;

export default FeedManager;

