import Entity from "@src/engine/entity";
import EntityReference from "@src/engine/Entity/EntityReference";
import { RoomComponents } from "@src/engine/RoomComponents";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";

var NewEntityPanel = {

	area: [ -300,-300, 400,600 ],
	selected: null,
	target: null,

	new_entity_settings: null,

	render: function(editor)
	{
		var ctx = gl;
		var space = editor.space;

		if (!this.new_entity_settings)
		{
			var index = 1;
			while ( space.getEntity( "entity_" + index ) )
				index++;
			this.new_entity_settings = {
				name: "entity_" + index,
				type: 1,
				components: {}
			};
		}

		var settings = this.new_entity_settings;

		var sidewidth = editor.sidewidth;
		var w = 500;
		var h = 700;
		var x = (gl.canvas.width - w) * 0.5;
		var y = (gl.canvas.height - h) * 0.5;

		if ( GUI.Panel( x, y, w, h, "New Entity", true ) == false )
			return false;

		Label.call(GUI, x + 20, y + 60,w * 0.5,30, "Name" );
		settings.name = GUI.TextField( x + w * 0.3, y + 60, w * 0.7 - 20, 30, settings.name, null, true );

		Label.call(GUI, x + 20, y + 94,w * 0.5,24, "Attach to selected" );
		settings.attach_to_selected = GUI.Toggle( x + w - 50, y + 94, 30, 30, null, settings.attach_to_selected );

		ctx.globalAlpha = 0.5;
		var sy = y + 130;
		var comps = Object.keys( RoomComponents ).sort();
		for (var i = 0; i < comps.length; ++i)
		{
			var even = (i % 2) == 0;
			var comp_name = comps[i];
			var sx = even ? x + 20 : x + w * 0.5;
			if ( Button.call(GUI, sx,sy,24,24, "+", settings.components[comp_name] ) )
				settings.components[comp_name] = !settings.components[comp_name];
			Label.call(GUI,sx+30,sy,w*0.5-40,24,comp_name);
			if ( !even )
				sy += 28;
		}
		ctx.globalAlpha = 1;

		if ( Button.call(GUI, 20 + x, y + h - 60,120,40, "Cancel" ) )
		{
			this.new_entity_settings = null;
			return false;
		}

		var new_entity = null;

		//reference
		if ( Button.call(GUI, x + w - 20 - 220, y + h - 60,90,40, "Ref" ) )
			new_entity = new EntityReference();

		if ( Button.call(GUI, x + w - 20 - 120, y + h - 60,120,40, "Create", false, [ 0.3,0.5,0.3,1 ] ) )
			new_entity = new Entity();

		if ( new_entity )
		{
			new_entity.name = settings.name;

			//to where to attach
			if ( settings.attach_to_selected && editor.selected_item )
				editor.selected_item.addEntity( new_entity );
			else
				space.addEntity( new_entity );

			if ( new_entity.constructor === Entity )
				for (var i in settings.components)
				{
					var ctor = RoomComponents[i];
					var comp = new ctor();
					new_entity.addComponent(comp);
				}

			if (new_entity.constructor.type)
				new_entity.type = new_entity.constructor.type;

			editor.setSelection( new_entity );
			this.new_entity_settings = null;
			editor.setSelection( new_entity );
			editor.mode = editor.constructor.PROPERTIES_MODE;
			return false;
		}

		return true;
	}
};

export default NewEntityPanel;
