import { createDecoderModule } from "draco3d";

function HoloCamClient(_url)
{
	this.username = "admin@holoh.com";
	this.password = "adminadmin";

	this.onMeshData = null;
	this.onClose = null;
	this.onError = null;

	this.decoderModule = null;
	this.socket = null;
}

HoloCamClient.prototype.connect = function(url)
{
	var host = url;
	var email = this.username;
	var password = this.password;
	var that = this;

	this.close(); //close previous one

	// Draco decoder module
	if (this.decoderModule === null) {
		createDecoderModule({}).then(function(module) {
			// This is reached when everything is ready, and you can call methods on
			// Module.
			that.decoderModule = module;
			console.debug("Decoder Module Initialized");
			launchSocket();
		});
	}

	function launchSocket() { // Draco decoder ready, start communication
		var frameSocketUrl = "wss://"+host+"/recvframe";
		var authUrl = "https://"+host+":/api/authuser";
		var devicesUrl = "https://"+host+"/api/holohcamlist";

		// Post a JSON to url using no header, returns received json by callback (cb)
		function postJsonNoHeader(url, data, cb) {
			const xhr = new XMLHttpRequest();
			xhr.open("POST", url, true);
			xhr.setRequestHeader("Content-Type", "application/json");

			// Create a state change callback
			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4 && xhr.status === 200) {
					cb(JSON.parse(xhr.responseText))
				}
			};

			// Send request
			xhr.send(data);
		}

		// GET request to server, Authorization Header set to token
		function getRequestAuthHeader(url, token, cb) {
			const xhr = new XMLHttpRequest();
			xhr.open("GET", url, true);
			xhr.setRequestHeader("Authorization", token);

			// Create a state change callback
			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4 && xhr.status === 200) {
					cb(JSON.parse(xhr.responseText))
				}
			};

			// Send request
			xhr.send();
		}

		var data = JSON.stringify({ "user_email": email, "password": password });
		postJsonNoHeader(authUrl, data, (token) => {
			const token_expiration = token.expiration_seconds
			token = token.auth_user_token

			getRequestAuthHeader(devicesUrl, token, (holohcams) => {
				if (holohcams.holohcams_total > 0) {
					const name = holohcams.holohcams[0].name
        			// Start websocket connection

        			var framews = that.socket = new WebSocket(frameSocketUrl);
        	        framews.binaryType = "arraybuffer";

        	        var frameIsInLoop = false; // Know whether the server is answering the initiator or the looped requests

        			var framesInSecond = 0; // Used to calculate FPS
        			var currentSecond = 0;
					var lastSecondFPS = 0; // Hold the fps we got the last second, used to inform the server about how the client is doing

        			///////////////////
        			//// FRAMES WS ////
        			///////////////////

        			framews.onopen = function() {
						framews.send(JSON.stringify({ "auth_user_token":token, "holohcam_name":name })); // Send initiator
					};

					framews.onmessage = function(evt) {
						if (evt.data instanceof ArrayBuffer) {
							// Received a data file

							// Calculate FPS
							var recvSecond = Math.trunc(Date.now() / 1000);
							if (currentSecond === recvSecond) {
								++framesInSecond;
							} else {
								that._fps = framesInSecond;
								lastSecondFPS = framesInSecond;
								currentSecond = recvSecond;
								framesInSecond = 0;
							}

							// Display mesh
							var mesh_data = that.decodeMeshData(evt.data);
							if (that.onMeshData)
								that.onMeshData(mesh_data);
							else
								console.debug(mesh_data);

						} else {
							const parsedAnswer = JSON.parse(evt.data);

							if (parsedAnswer.type === "error") {
								console.debug("FRAME: Error in data transmission loop: " + parsedAnswer.description);
							}
							else if (parsedAnswer.type === "metadata") {
								// The server sends the metadata of the file and immediately after the file itself.
								// The rotation is a 3d vector that represents gravity.

								console.debug("FRAME: The file to be received has the timestamp " + parsedAnswer.metadata.time_stamp);
								console.debug("FRAME: Rotation: [" + parsedAnswer.metadata.rot_x + " X, " + parsedAnswer.metadata.rot_y + " Y, " + parsedAnswer.metadata.rot_z + " Z]")
							}
							else if (parsedAnswer.type === "connection") {
								// The server wants the client to send its token. In the meantime send health data like the fps
								framews.send(JSON.stringify({ "auth_user_token":token, "fps": lastSecondFPS }));
							}
						}
					};

        			framews.onerror = function(err) {
        				if (that.onError)
        					that.onError(err)
        			};

        			framews.onclose = function(err)
        			{
        				if (that.onClose)
        					that.onClose(err);
        			};
        		}
			});
    	});
	}
}

HoloCamClient.prototype.close = function ()
{
	if (this.socket)
	{
		this.socket.close();
		this.socket = null;
	}
}


HoloCamClient.prototype.decodeDracoData = function (rawBuffer, _decoder)
{
	var decoderModule = this.decoderModule;
	const buffer = new decoderModule.DecoderBuffer();
	buffer.Init(new Int8Array(rawBuffer), rawBuffer.byteLength);

	const dracoGeometry = new decoderModule.Mesh();
	const status = decoder.DecodeBufferToMesh(buffer, dracoGeometry);

	decoderModule.destroy(buffer);

	return dracoGeometry;
}

HoloCamClient.prototype.decodeMeshData = function(data)
{
	// Link on how to get the vertices and other data from the mesh object
	//https://github.com/google/draco/blob/8f9ebcdb9ffa216c18af731ccb16602db308d009/javascript/npm/draco3d/draco_nodejs_example.js#L88
	var decoderModule = this.decoderModule;
	if (!decoderModule)
		return null;
	const decoder = new decoderModule.Decoder();
	const mesh = this.decodeDracoData(data, decoder);

	const numFaces = mesh.num_faces();
	const numIndices = numFaces * 3;
	const numPoints = mesh.num_points();

	var mesh_data = {};

	//indices
	const indices = new Uint32Array(numIndices);
	const ia = new decoderModule.DracoInt32Array();
	for (let i = 0; i < numFaces; ++i) {
		decoder.GetFaceFromMesh(mesh, i, ia);
		const index = i * 3;
		indices[index] = ia.GetValue(0);
		indices[index + 1] = ia.GetValue(1);
		indices[index + 2] = ia.GetValue(2);
	}
	decoderModule.destroy(ia);
	mesh_data.INDICES = indices;

	const attrs = { POSITION: 3, NORMAL: 3, COLOR: 3, TEX_COORD: 2 };

	Object.keys(attrs).forEach((attr) => {
		const stride = attrs[attr];
		const numValues = numPoints * stride;
		const decoderAttr = decoderModule[attr];
		const attrId = decoder.GetAttributeId(mesh, decoderAttr);

		if (attrId < 0) {
		  return;
		}

		const attribute = decoder.GetAttribute(mesh, attrId);
		const attributeData = new decoderModule.DracoFloat32Array();
		decoder.GetAttributeFloatForAllPoints(mesh, attribute, attributeData);

		console.warn(numValues === attributeData.size(), "Wrong attribute size.");

		const attributeDataArray = new Float32Array(numValues);
		for (let i = 0; i < numValues; ++i) {
		  attributeDataArray[i] = attributeData.GetValue(i);
		}

		decoderModule.destroy(attributeData);
		mesh_data[attr] = attributeDataArray;
	});

	decoderModule.destroy(decoder);

	return mesh_data;
}; //do not remove this

export default HoloCamClient;
