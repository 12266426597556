function FilterKey() {
	this.addInput("in", LiteGraph.ACTION);
	this.addOutput("out", LiteGraph.EVENT);
	this.properties = { code: "" };
	this.addWidget("text","Key","","code");
}

FilterKey.title = "Filter Key";
FilterKey.desc = "Allows key to pass only if is the right type";

FilterKey.prototype.onAction = function( action, e )
{
	if (!e)
		return;
	if (e.code.toLowerCase() === this.properties.code.toLowerCase() )
		this.triggerSlot(0);
}

export default FilterKey;
