import { CameraState } from "@src/engine/components/Camera/intelligent/CameraState";
const validationCameraState = new CameraState();
/**
 * Heavily inspired by Cinemachine from Unity
 */
export class CameraBrain {
    constructor() {
        this.constraints = [];
        /**
         * Enabled additional validation
         * There is a certain amount of performance overhead associated with this, so it should be disabled in production
         */
        this.validationEnabled = true;
    }
    /**
     *
     * @param {CameraConstraint} c
     */
    addConstraint(c) {
        this.constraints.push(c);
        c.link(this);
    }
    /**
     *
     * @param {CameraConstraint} c
     * @return {boolean}
     */
    hasConstraint(c) {
        return this.constraints.includes(c);
    }
    /**
     *
     * @param {CameraConstraint} c
     * @return {boolean}
     */
    removeConstraint(c) {
        const i = this.constraints.indexOf(c);
        if (i === -1) {
            // not found
            return false;
        }
        this.constraints.splice(i, 1);
        c.unlink();
        return true;
    }
    /**
     * Iteratively applies constraints over and over in an attempt to converge on a more precise solution
     * @param {CameraState} output
     * @param {CameraState} input
     * @param {number} [maxSteps]
     */
    solve(output, input, maxSteps = 8) {
        let state0 = new CameraState();
        let state1 = new CameraState();
        state0.copy(input);
        for (let i = 0; i < maxSteps; i++) {
            this.apply(state1, state0);
            // swap states
            const t = state0;
            state0 = state1;
            state1 = t;
        }
        // copy final result to output
        output.copy(state0);
    }
    /**
     * Given an input camera state, produce a constrained state and write it to output
     * @param {CameraState} output constrained result is written here
     * @param {CameraState} input initial camera state to be constrained
     */
    apply(output, input) {
        if (!input.validate()) {
            throw new Error("Input state invalid");
        }
        // prepare constraints
        const constraints = this.constraints;
        const constraintCount = constraints.length;
        let state0 = new CameraState();
        let state1 = new CameraState();
        state0.copy(input);
        for (let i = 0; i < constraintCount; i++) {
            // for the sake of convenience when implementing constraints, we copy input state to output fully before invoking solve, to allow for partial state updates
            state1.copy(state0);
            if (this.validationEnabled) {
                // remember initial state
                validationCameraState.copy(state0);
            }
            // solve the constraint
            constraints[i].solve(state1, state0);
            if (this.validationEnabled
                && !validationCameraState.equals(state0)) {
                throw new Error(`Constraint[${i}] modified input state. Input state should only be read and never written to`);
            }
            if (!state1.validate()) {
                throw new Error(`Constraint[${i}] produced invalid state`);
            }
            // swap states
            const t = state0;
            state0 = state1;
            state1 = t;
        }
        // copy final result to output
        output.copy(state0);
    }
    /**
     *
     * @param {number} timeDelta in seconds
     */
    tick(timeDelta) {
        const constraints = this.constraints;
        const n = constraints.length;
        for (let i = 0; i < n; i++) {
            const c = constraints[i];
            c.tick(timeDelta);
        }
    }
}
