/**
 * ray-casting algorithm based on
 * @link http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
 */
function pointInShapeArray(pos, shape) {
	const is_3d = pos.length === 3;
	const x = pos[0];
	const y = pos[is_3d ? 2 : 1];
	let inside = false;
	const l = shape.length;

	let i = 0, j = l - 1;
	for (; i < l; j = i, i += 1) {
		const pi = shape[i];
		const pj = shape[j];
		const xi = pi[0], yi = pi[is_3d ? 2 : 1];
		const xj = pj[0], yj = pj[is_3d ? 2 : 1];

		//works by testing an horizontal semi-infinite line starting at P and going to the right
		const intersect = ((yi > y) !== (yj > y)) //first test if it lays in the y range of the segment
                      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi); //then test in which side of the plane it lies
		if (intersect) {
			inside = !inside;
		}
	}

	return inside;
}

export default pointInShapeArray;
