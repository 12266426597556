import { vec3 } from "gl-matrix";
/**
 * searches for the camera track
 * @param {RoomSpace} space
 * @param {vec3} pos
 * @returns {TravelingTrack|null}
 */
export function findNearestCameraTrack(space, pos) {
    const comps = space._camera_tracks;
    if (!Array.isArray(comps)) {
        // tracks property is likely missing
        return null;
    }
    let best_distance = Infinity;
    let best_track = null;
    for (let i = 0; i < comps.length; ++i) {
        const comp = comps[i];
        const center = comp.entity.position;
        const _distance_to_position = vec3.distance(pos, center);
        if (_distance_to_position >= best_distance) {
            // too far
            continue;
        }
        best_distance = _distance_to_position;
        best_track = comp;
    }
    return best_track;
}
