import { CameraState } from "@src/engine/components/Camera/intelligent/CameraState";
import { CameraConstraint } from "@src/engine/components/Camera/intelligent/constraints/CameraConstraint";
import { pingpong } from "@woosh/meep-engine/src/core/math/pingpong";
import { smootherStep } from "@woosh/meep-engine/src/core/math/smootherStep";
const scratchState = new CameraState();
export class LocalTransformOscillatorConstraint extends CameraConstraint {
    constructor(transform0, transform1, period = 1, symmetrical = false) {
        super();
        this.localTransform0 = transform0;
        this.localTransform1 = transform1;
        this.period = period;
        this.symmetrical = symmetrical;
    }
    solve(outputState, inputState) {
        // Start the oscillation from its midpoint.
        let adjustedTime = this.time * 0.5 + this.period;
        if (this.symmetrical)
            adjustedTime = this.time * 0.5 + this.period * 0.25;
        // single swing of the pendulum is 1/period
        const halfPeriod = this.period * 0.5;
        const fractionUnfiltered = pingpong(adjustedTime, halfPeriod);
        // smooth out tangents at either extreme
        const fractionFiltered = smootherStep(0, halfPeriod, fractionUnfiltered);
        // get local transform for current time
        scratchState.lerp(this.localTransform0, this.localTransform1, fractionFiltered);
        outputState.copy(inputState);
        // add local transform
        outputState.compound(scratchState);
    }
}
