/**
 * bounding overlap, format: [ startx, starty, width, height ]
 * 
 * @param a
 * @param b
 * @return {boolean}
 */
function overlapBounding(a, b) {
    const A_end_x = a[0] + a[2];
    const A_end_y = a[1] + a[3];
    const B_end_x = b[0] + b[2];
    const B_end_y = b[1] + b[3];

    return !(a[0] > B_end_x ||
             a[1] > B_end_y ||
             A_end_x < b[0] ||
             A_end_y < b[1]);
}

export default overlapBounding;
