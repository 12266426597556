import { GL } from "@src/libs/litegl";
import Canvas from "@src/libs/LiteGL/Canvas";
import hexColorToRGBA from "@src/libs/LiteGL/Color/hexColorToRGBA";
import clamp from "@src/math/clamp";
import { mat3, mat4, vec2, vec4 } from "gl-matrix";
import ClipTextureShader from "./Canvas2DtoWebGL/ClipTextureShader.glsl";
import FlatFragmentShader from "./Canvas2DtoWebGL/FlatFragmentShader.glsl";
import GradientPrimitiveShader from "./Canvas2DtoWebGL/GradientPrimitiveShader.glsl";
import PointTextVertexShader from "./Canvas2DtoWebGL/PointTextVertexShader.glsl";
import PrimitiveShader from "./Canvas2DtoWebGL/PrimitiveShader.glsl";
import TexturedTransformShader from "./Canvas2DtoWebGL/TexturedTransformShader.glsl";
import TextureShader from "./Canvas2DtoWebGL/TextureShader.glsl";

import VertexShader from "./Canvas2DtoWebGL/VertexShader.glsl";

/**
 * replaces the Canvas2D functions by WebGL functions, the behaviour is not 100% the same but it kind of works in many cases
 * not all functions have been implemented
 * @param {HTMLCanvasElement} canvas
 */
function enableWebGLCanvas(canvas, options )
{
	let gl;
	options = options || {};

	// Detect if canvas is WebGL enabled and get context if possible
	if (!canvas.is_webgl)
	{
		options.canvas = canvas;
		if ( options.alpha === undefined )
			options.alpha = true;
		if ( options.stencil === undefined )
			options.stencil = true;
		try {
			gl = GL.create(options);
		} catch (e) {
			console.debug("This canvas cannot be used as WebGL, maybe WebGL is not supported or this canvas has already a 2D context associated");
			gl = canvas.getContext("2d", options);
			return gl;
		}
	}
	else
		gl = canvas.gl;


	// Return if canvas is already canvas2DtoWebGL enabled
	if (canvas.canvas2DtoWebGL_enabled)
		return gl;

	//settings
	const curveSubdivisions = 50;
	const max_points = 10000; //max amount of vertex allowed to have in a single primitive
	const max_characters = 1000; //max amount of characters allowed to have in a single fillText

	//flag it for future uses
	canvas.canvas2DtoWebGL_enabled = true;

	let prev_gl = null;

	const ctx = canvas.ctx = gl;
	ctx.WebGLCanvas = {};

	//reusing same buffer
	let global_index = 0;
	const global_vertices = new Float32Array(max_points * 3);
	const global_mesh = new GL.Mesh();
	const global_buffer = global_mesh.createVertexBuffer("vertices", null, null, global_vertices, gl.STREAM_DRAW);
	const quad_mesh = GL.Mesh.getScreenQuad();
	const circle_mesh = GL.Mesh.circle({ size: 1 });
	const extra_projection = mat4.create();
	const anisotropic = options.anisotropic !== undefined ? options.anisotropic : 2;

	const uniforms = {
		u_texture: 0
	};

	const extra_macros = {};
	if (options.allow3D)
		extra_macros.EXTRA_PROJECTION = "";

	//used to store font atlas textures (images are not stored here)
	let textures = gl.WebGLCanvas.textures_atlas = {};
	gl.WebGLCanvas.clearAtlas = function()
	{
		textures = gl.WebGLCanvas.textures_atlas = {};
	}

	let vertex_shader = null;
	let vertex_shader2 = null;
	let flat_shader = null;
	let texture_shader = null;
	let clip_texture_shader = null;
	let flat_primitive_shader = null;
	let textured_transform_shader = null;
	let textured_primitive_shader = null;
	let gradient_primitive_shader = null;
	let point_text_shader = null;

	gl.WebGLCanvas.set3DMatrix = function(matrix)
	{
		if (!matrix)
			mat4.identity( extra_projection );
		else
			extra_projection.set( matrix );
		if (extra_macros.EXTRA_PROJECTION === null)
		{
			extra_macros.EXTRA_PROJECTION = "";
			compileShaders();
			uniforms.u_projection = extra_projection;
		}
		uniforms.u_projection_enabled = !!matrix;
	}

	compileShaders();

	function compileShaders()
	{
		vertex_shader = VertexShader;

		vertex_shader2 = "\n\
			precision highp float;\n\
			attribute vec3 a_vertex;\n\
			attribute vec2 a_coord;\n\
			varying vec2 v_coord;\n\
			uniform vec2 u_position;\n\
			uniform vec2 u_size;\n\
			uniform vec2 u_viewport;\n\
			uniform mat3 u_transform;\n\
			#ifdef EXTRA_PROJECTION\n\
				uniform bool u_projection_enabled;\n\
				uniform mat4 u_projection;\n\
			#endif\n\
			void main() { \n\
				vec3 pos = vec3(u_position + vec2(a_coord.x,"+(options.no_flip?"":"1.0 - ")+" a_coord.y)  * u_size, 1.0);\n\
				v_coord = a_coord; \n\
				pos = u_transform * pos;\n\
				pos.z = 0.0;\n\
				#ifdef EXTRA_PROJECTION\n\
					if(u_projection_enabled)\n\
					{\n\
						gl_Position = u_projection * vec4(pos.xy,0.0,1.0);\n\
						return;\n\
					}\n\
				#endif\n\
				//normalize\n\
				pos.x = (2.0 * pos.x / u_viewport.x) - 1.0;\n\
				pos.y = -((2.0 * pos.y / u_viewport.y) - 1.0);\n\
				gl_Position = vec4(pos, 1.0); \n\
			}\n\
		";

		flat_shader = new GL.Shader(vertex_shader2, FlatFragmentShader, extra_macros );

		texture_shader = new GL.Shader(vertex_shader2, TextureShader, extra_macros );

		clip_texture_shader = new GL.Shader(vertex_shader2, ClipTextureShader, extra_macros );

		flat_primitive_shader = new GL.Shader(vertex_shader, PrimitiveShader, extra_macros );

		textured_transform_shader = new GL.Shader(GL.Shader.QUAD_VERTEX_SHADER, TexturedTransformShader, extra_macros );

		textured_primitive_shader = new GL.Shader(vertex_shader,"\n\
				precision highp float;\n\
				varying float v_visible;\n\
				uniform vec4 u_color;\n\
				uniform sampler2D u_texture;\n\
				uniform vec4 u_texture_transform;\n\
				uniform vec2 u_viewport;\n\
				uniform mat3 u_itransform;\n\
				void main() {\n\
					vec2 pos = (u_itransform * vec3( gl_FragCoord.s, u_viewport.y - gl_FragCoord.t,1.0)).xy;\n\
					pos *= vec2( (u_viewport.x * u_texture_transform.z), (u_viewport.y * u_texture_transform.w) );\n\
					vec2 uv = fract(pos / u_viewport) + u_texture_transform.xy;\n\
					" + (options.no_flip ? "":"uv.y = 1.0 - uv.y;")+"\n\
					gl_FragColor = u_color * texture2D( u_texture, uv);\n\
				}\n\
			", extra_macros );

		gradient_primitive_shader = new GL.Shader(vertex_shader, GradientPrimitiveShader, extra_macros );

		//used for text
		const POINT_TEXT_VERTEX_SHADER = PointTextVertexShader;

		const POINT_TEXT_FRAGMENT_SHADER = "\n\
			precision highp float;\n\
			uniform sampler2D u_texture;\n\
			uniform float u_iCharSize;\n\
			uniform vec4 u_color;\n\
			uniform float u_pointSize;\n\
			uniform vec2 u_viewport;\n\
			uniform vec2 u_angle_sincos;\n\
			varying vec2 v_coord;\n\
			void main() {\n\
				vec2 uv = vec2(1.0 - gl_PointCoord.s, gl_PointCoord.t);\n\
				uv = vec2( ((uv.y - 0.5) * u_angle_sincos.y - (uv.x - 0.5) * u_angle_sincos.x) + 0.5, ((uv.x - 0.5) * u_angle_sincos.y + (uv.y - 0.5) * u_angle_sincos.x) + 0.5);\n\
				uv = v_coord - uv * u_iCharSize + vec2(u_iCharSize*0.5);\n\
				" + (options.no_flip ? "" : "uv.y = 1.0 - uv.y;") + "\n\
				gl_FragColor = vec4(u_color.xyz, u_color.a * texture2D(u_texture, uv, -1.0  ).a);\n\
			}\n\
			";

		point_text_shader = new GL.Shader( POINT_TEXT_VERTEX_SHADER, POINT_TEXT_FRAGMENT_SHADER, extra_macros );
	}

	ctx.createImageShader = function(code)
	{
		return new GL.Shader( GL.Shader.QUAD_VERTEX_SHADER,"\n\
			precision highp float;\n\
			uniform sampler2D u_texture;\n\
			uniform vec4 u_color;\n\
			uniform vec4 u_texture_transform;\n\
			uniform vec2 u_viewport;\n\
			varying vec2 v_coord;\n\
			void main() {\n\
				vec2 uv = v_coord * u_texture_transform.zw + vec2(u_texture_transform.x,0.0);\n\
				uv.y = uv.y - u_texture_transform.y + (1.0 - u_texture_transform.w);\n\
				uv = clamp(uv,vec2(0.0),vec2(1.0));\n\
				vec4 color = u_color * texture2D(u_texture, uv);\n\
				"+code+";\n\
				gl_FragColor = color;\n\
			}\n\
		", extra_macros );
	}


	//some people may reuse it
	ctx.WebGLCanvas.vertex_shader = vertex_shader;

	//STACK and TRANSFORM
	ctx._matrix = mat3.create();
	const tmp_mat3 = mat3.create();
	const tmp_vec2 = vec2.create();
	const tmp_vec4 = vec4.create();
	const tmp_vec4b = vec4.create();
	const tmp_vec2b = vec2.create();
	ctx._stack = [];
	ctx._stack_size = 0;
	let global_angle = 0;
	const viewport = ctx.viewport_data.subarray(2, 4);

	ctx.translate = function(x,y)
	{
		tmp_vec2[0] = x;
		tmp_vec2[1] = y;
		mat3.translate( this._matrix, this._matrix, tmp_vec2 );
	}

	ctx.rotate = function(angle)
	{
		mat3.rotate( this._matrix, this._matrix, angle );
		global_angle += angle;
	}

	ctx.scale = function(x,y)
	{
		tmp_vec2[0] = x;
		tmp_vec2[1] = y;
		mat3.scale( this._matrix, this._matrix, tmp_vec2 );
	}

	//own method to reset internal stuff
	ctx.resetTransform = function()
	{
		//reset transform
		gl._stack_size = 0;
		this._matrix.set([ 1,0,0, 0,1,0, 0,0,1 ]);
		global_angle = 0;
	}

	ctx.save = function() {
		if (this._stack_size >= 32)
			return;
		let current_level = null;
		if (this._stack_size === this._stack.length)
		{
			current_level = this._stack[ this._stack_size ] = {
				matrix: mat3.create(),
				fillColor: vec4.create(),
				strokeColor: vec4.create(),
				shadowColor: vec4.create(),
				globalAlpha: 1,
				font: "",
				fontFamily: "",
				fontSize: 14,
				fontMode: "",
				textAlign: "",
				clip_level: 0
			};
		}
		else
			current_level = this._stack[ this._stack_size ];
		this._stack_size++;

		current_level.matrix.set( this._matrix );
		current_level.fillColor.set( this._fillcolor );
		current_level.strokeColor.set( this._strokecolor );
		current_level.shadowColor.set( this._shadowcolor );
		current_level.globalAlpha = this._globalAlpha;
		current_level.font = this._font;
		current_level.fontFamily = this._font_family;
		current_level.fontSize = this._font_size;
		current_level.fontMode = this._font_mode;
		current_level.textAlign = this.textAlign;
		current_level.clip_level = this.clip_level;
	}

	ctx.restore = function() {
		if (this._stack_size === 0)
		{
			mat3.identity( this._matrix );
			global_angle = 0;
			return;
		}

		this._stack_size--;
		const current_level = this._stack[this._stack_size];

		this._matrix.set( current_level.matrix );
		this._fillcolor.set( current_level.fillColor );
		this._strokecolor.set( current_level.strokeColor );
		this._shadowcolor.set( current_level.shadowColor );
		this._globalAlpha = current_level.globalAlpha;
		this._font = current_level.font;
		this._font_family = current_level.fontFamily;
		this._font_size = current_level.fontSize;
		this._font_mode = current_level.fontMode;
		this.textAlign = current_level.textAlign;
		const prev_clip_level = this.clip_level;
		this.clip_level = current_level.clip_level;

		global_angle = Math.atan2( this._matrix[3], this._matrix[4] ); //use up vector

		if (prev_clip_level === this.clip_level )
		{
			//nothing
		}
		else if (this.clip_level === 0 ) //exiting stencil mode
		{
			//clear and disable
			gl.enable( gl.STENCIL_TEST );
			gl.clearStencil( 0x0 );
			gl.clear( WebGL2RenderingContext.STENCIL_BUFFER_BIT );
			gl.disable( gl.STENCIL_TEST );
		}
		else //reduce clip level
		{
			gl.stencilFunc( gl.LEQUAL, this.clip_level, 0xFF ); //why LEQUAL?? should be GEQUAL but doesnt work
			gl.stencilOp( gl.KEEP, gl.KEEP, gl.KEEP );
		}
	}

	ctx.clip = function()
	{
		this.clip_level++;

		gl.colorMask(false, false, false, false);
		gl.depthMask(false);

		//fill stencil buffer
		gl.enable( gl.STENCIL_TEST );
		//gl.stencilFunc( gl.ALWAYS, 1, 0xFF );
		//gl.stencilOp( gl.KEEP, gl.KEEP, gl.REPLACE ); //TODO using INCR we could allow 8 stencils
		gl.stencilFunc( gl.EQUAL, this.clip_level - 1, 0xFF );
		gl.stencilOp( gl.KEEP, gl.KEEP, gl.INCR );

		this.fill();

		gl.colorMask(true, true, true, true);
		gl.depthMask(true);
		//gl.stencilFunc( gl.EQUAL, 1, 0xFF );
		gl.stencilFunc( gl.EQUAL, this.clip_level, 0xFF );
		gl.stencilOp( gl.KEEP, gl.KEEP, gl.KEEP );
	}

	ctx.clipImage = function(image,x,y,w,h)
	{
		this.clip_level++;

		gl.colorMask(false, false, false, false);
		gl.depthMask(false);
		gl.enable( gl.STENCIL_TEST );
		gl.stencilFunc( gl.EQUAL, this.clip_level - 1, 0xFF );
		gl.stencilOp( gl.KEEP, gl.KEEP, gl.INCR );

		//draw image with discard
		this.drawImage(image, x,y,w,h,clip_texture_shader);

		gl.colorMask(true, true, true, true);
		gl.depthMask(true);
		gl.stencilFunc( gl.EQUAL, this.clip_level, 0xFF );
		gl.stencilOp( gl.KEEP, gl.KEEP, gl.KEEP );
	}

	ctx.transform = function(a,b,c,d,e,f) {
		const m = tmp_mat3;

		m[0] = a; m[1] = b; m[2] = 0; m[3] = c; m[4] = d; m[5] = 0; m[6] = e; m[7] = f; m[8] = 1; //fix

		mat3.multiply( this._matrix, this._matrix, m );
		global_angle = Math.atan2( this._matrix[0], this._matrix[1] );
	}

	ctx.setTransform = function(a,b,c,d,e,f) {
		const m = this._matrix;

		m[0] = a; m[1] = b; m[2] = 0; m[3] = c; m[4] = d; m[5] = 0; m[6] = e; m[7] = f; m[8] = 1; //fix

		global_angle = Math.atan2( this._matrix[0], this._matrix[1] );
	}

	//Images

	//textures are stored inside images, so as long as the image exist in memory, the texture will exist
	function getTexture( img )
	{
		let tex = null;

		if (img.native)
			return img;

		if (img.constructor === GL.Texture)
		{
			if (img._context_id === gl.context_id)
				return img;
			return null;
		}
		else if (img.constructor === WebGLTexture)
		{
			img.width = img.height = 1024;
			img.bind = function(unit) {
				gl.bindTexture( WebGLRenderingContext.TEXTURE_2D, this );
				gl.activeTexture(gl.TEXTURE0 + (unit || 0) );
			}
			return img;
		}
		else
		{
			//same image could be used in several contexts
			if (!img.gl)
				img.gl = {};

			//Regular image
			if (img.src)
			{
				const wrap = gl.REPEAT;

				tex = img.gl[ gl.context_id ];
				if (tex)
				{
					if ( img.mustUpdate )
					{
						tex.uploadData( img );
						img.mustUpdate = false;
					}
					return tex;
				}
				return img.gl[ gl.context_id ] = GL.Texture.fromImage(img, { magFilter: options.magFilter || GL.LINEAR, minFilter: options.minFilter || gl.LINEAR_MIPMAP_LINEAR, wrap: wrap, ignore_pot:true, premultipliedAlpha: true, anisotropic: anisotropic } );
			}
			else //probably a canvas
			{
				tex = img.gl[ gl.context_id ];
				if (tex)
				{
					if ( img.mustUpdate )
					{
						tex.uploadData( img );
						img.mustUpdate = false;
					}
					return tex;
				}
				return img.gl[ gl.context_id ] = GL.Texture.fromImage(img, { minFilter: gl.LINEAR, magFilter: gl.LINEAR, anisotropic: anisotropic });
			}
		}

	}

	//it supports all versions of drawImage (3 params, 5 params or 9 params)
	//it allows to pass a shader, otherwise it uses texture_shader (code is GL.Shader.SCREEN_COLORED_FRAGMENT_SHADER)
	ctx.drawImage = function( img, x, y, w, h, shader )
	{
		if (!img)
			return;

		const img_width = img.videoWidth || img.width;
		const img_height = img.videoHeight || img.height;

		if (img_width === 0 || img_height === 0)
			return;

		const tex = getTexture(img);
		if (!tex)
			return;

		if (arguments.length === 9) //img, sx,sy,sw,sh, x,y,w,h
		{
			tmp_vec4b.set([ x/img_width,y/img_height,w/img_width,h/img_height ]);
			x = arguments[5];
			y = arguments[6];
			w = arguments[7];
			h = arguments[8];
			shader = textured_transform_shader;
		}
		else
			tmp_vec4b.set([ 0,0,1,1 ]); //reset texture transform

		tmp_vec2[0] = x; tmp_vec2[1] = y;
		tmp_vec2b[0] = w === undefined ? tex.width : w;
		tmp_vec2b[1] = h === undefined ? tex.height : h;

		tex.bind(0);

		if (tex !== img) //only apply the imageSmoothingEnabled if we are dealing with images, not textures
			gl.texParameteri(WebGLRenderingContext.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, this.imageSmoothingEnabled ? gl.LINEAR : gl.NEAREST );

		if (!this.tintImages)
		{
			tmp_vec4[0] = tmp_vec4[1] = tmp_vec4[2] = 1.0;	tmp_vec4[3] = this._globalAlpha;
		}

		uniforms.u_color = this.tintImages ? this._fillcolor : tmp_vec4;
		uniforms.u_position = tmp_vec2;
		uniforms.u_size = tmp_vec2b;
		uniforms.u_transform = this._matrix;
		uniforms.u_texture_transform = tmp_vec4b;
		uniforms.u_viewport = viewport;

		shader = shader || texture_shader;

		shader.uniforms( uniforms ).draw(quad_mesh);
		extra_projection[14] -= 0.001;
	}

	ctx.createPattern = function( img )
	{
		return getTexture( img );
	}

	//to create gradients
	function WebGLCanvasGradient(x,y,x2,y2)
	{
		this.id = (ctx._last_gradient_id++) % ctx._max_gradients;
		this.points = new Float32Array([ x,y,x2,y2 ]);
		this.stops = [];
		this._must_update = true;
	}

	//to avoid creating textures all the time
	ctx._last_gradient_id = 0;
	ctx._max_gradients = 16;
	ctx._gradients_pool = [];

	WebGLCanvasGradient.prototype.addColorStop = function( pos, color )
	{
		const final_color = hexColorToRGBA(color);
		const v = new Uint8Array(4);
		v[0] = clamp( final_color[0], 0,1 ) * 255;
		v[1] = clamp( final_color[1], 0,1 ) * 255;
		v[2] = clamp( final_color[2], 0,1 ) * 255;
		v[3] = clamp( final_color[3], 0,1 ) * 255;
		this.stops.push( [ pos, v ]);
		this.stops.sort( function(a,b) {return (a[0] > b[0]) ? 1 : ((b[0] > a[0]) ? -1 : 0);} );
		this._must_update = true;
	}

	WebGLCanvasGradient.prototype.toTexture = function()
	{
		//create a texture from the pool
		if (!this._texture)
		{
			if (this.id !== -1)
				this._texture = ctx._gradients_pool[ this.id ];
			if (!this._texture)
			{
				this._texture = new GL.Texture( 128,1, { format: gl.RGBA, magFilter: gl.LINEAR, wrap: gl.CLAMP_TO_EDGE, minFilter: gl.NEAREST });
				if (this.id !== -1)
					ctx._gradients_pool[ this.id ] = this._texture;
			}
		}
		if (!this._must_update)
			return this._texture;
		this._must_update = false;
		if (this.stops.length < 1)
			return this._texture; //no stops
		if (this.stops.length < 2)
		{
			this._texture.fill( this.stops[0][1] );
			return this._texture; //one color
		}

		//fill buffer
		let index = 0;
		let current = this.stops[index];
		let next = this.stops[index + 1];

		const buffer = new Uint8Array(128 * 4);
		for (var i = 0; i < 128; i+=1)
		{
			const color = buffer.subarray(i * 4, i * 4 + 4);
			const t = i / 128;
			if ( current[0] > t )
			{
				if (index === 0)
					color.set( current[1] );
				else
				{
					index+=1;
					current = this.stops[index];
					next = this.stops[index+1];
					if (!next)
						break;
					i-=1;
				}
			}
			else if ( current[0] <= t && t < next[0] )
			{
				const f = (t - current[0]) / (next[0] - current[0]);
				vec4.lerp( color, current[1], next[1], f );
			}
			else if ( next[0] <= t )
			{
				index+=1;
				current = this.stops[index];
				next = this.stops[index+1];
				if (!next)
					break;
				i-=1;
			}
		}
		//fill the remaining
		if (i<128)
			for (let j = i; j < 128; j+=1)
				buffer.set( current[1], j*4 );
		this._texture.uploadData( buffer );
		return this._texture;
	}

	ctx.createLinearGradient = function( x,y, x2, y2 )
	{
		return new WebGLCanvasGradient(x,y,x2,y2);
	}


	//Primitives

	ctx.beginPath = function()
	{
		global_index = 0;
	}

	ctx.closePath = function()
	{
		if (global_index < 3)
			return;
		global_vertices[ global_index ] = global_vertices[0];
		global_vertices[ global_index + 1] = global_vertices[1];
		global_vertices[ global_index + 2] = 1;
		global_index += 3;
	}

	ctx.moveTo = function(x,y)
	{
		//not the first line
		if (global_index === 0)
		{
			global_vertices[ global_index ] = x;
			global_vertices[ global_index + 1] = y;
			global_vertices[ global_index + 2] = 1;
			global_index += 3;
		}
		else
		{
			global_vertices[ global_index ] = global_vertices[ global_index - 3];
			global_vertices[ global_index + 1] = global_vertices[ global_index - 2];
			global_vertices[ global_index + 2] = 0;
			global_index += 3;
			global_vertices[ global_index ] = x;
			global_vertices[ global_index + 1] = y;
			global_vertices[ global_index + 2] = 0;
			global_index += 3;
		}

	}

	ctx.lineTo = function(x,y)
	{
		global_vertices[ global_index ] = x;
		global_vertices[ global_index + 1] = y;
		global_vertices[ global_index + 2] = 1;
		global_index += 3;
	}

	ctx.bezierCurveTo = function(m1x,m1y, m2x,m2y, ex,ey)
	{
		if (global_index < 3)
			return;

		const last = [ global_vertices[global_index - 3], global_vertices[global_index - 2] ];
		const cp = [ last, [ m1x, m1y ], [ m2x, m2y ], [ ex, ey ] ];
		for (let i = 0; i <= curveSubdivisions; i++)
		{
			const t = i / curveSubdivisions;
			let ax, bx, cx;
			let ay, by, cy;
			let tSquared, tCubed;

			/* cálculo de los coeficientes polinomiales */
			cx = 3.0 * (cp[1][0] - cp[0][0]);
			bx = 3.0 * (cp[2][0] - cp[1][0]) - cx;
			ax = cp[3][0] - cp[0][0] - cx - bx;

			cy = 3.0 * (cp[1][1] - cp[0][1]);
			by = 3.0 * (cp[2][1] - cp[1][1]) - cy;
			ay = cp[3][1] - cp[0][1] - cy - by;

			/* calculate the curve point at parameter value t */
			tSquared = t * t;
			tCubed = tSquared * t;

			const x = (ax * tCubed) + (bx * tSquared) + (cx * t) + cp[0][0];
			const y = (ay * tCubed) + (by * tSquared) + (cy * t) + cp[0][1];
			global_vertices[ global_index ] = x;
			global_vertices[ global_index + 1] = y;
			global_vertices[ global_index + 2] = 1;
			global_index += 3;
		}
	}

	ctx.quadraticCurveTo = function(mx,my,ex,ey)
	{
		if (global_index < 3)
			return;

		const sx = global_vertices[global_index - 3];
		const sy = global_vertices[global_index - 2];

		for (let i = 0; i <= curveSubdivisions; i++)
		{
			const f = i / curveSubdivisions;
			const nf = 1 - f;

			const m1x = sx * nf + mx * f;
			const m1y = sy * nf + my * f;

			const m2x = mx * nf + ex * f;
			const m2y = my * nf + ey * f;

			global_vertices[ global_index ] = m1x * nf + m2x * f;
			global_vertices[ global_index + 1] = m1y * nf + m2y * f;
			global_vertices[ global_index + 2] = 1;
			global_index += 3;
		}
	}


	ctx.fill = function()
	{
		if (global_index < 9)
			return;

		//update buffer
		global_buffer.uploadRange(0, global_index * 4); //4 bytes per float
		//global_buffer.upload(); //4 bytes per float
		uniforms.u_viewport = viewport;
		let shader = flat_primitive_shader;

		//first the shadow
		if ( this._shadowcolor[3] > 0.0 )
		{
			uniforms.u_color = this._shadowcolor;
			this.save();
			this.translate( this.shadowOffsetX, this.shadowOffsetY );
			shader.uniforms(uniforms).drawRange(global_mesh, gl.TRIANGLE_FAN, 0, global_index / 3);
			this.restore();
		}

		uniforms.u_color = this._fillcolor;
		uniforms.u_transform = this._matrix;

		const fill_style = this._fillStyle;

		if ( fill_style.constructor === WebGLCanvasGradient ) //gradient
		{
			const grad = fill_style;
			var tex = grad.toTexture();
			uniforms.u_color = [ 1,1,1, this.globalAlpha ];
			uniforms.u_gradient = grad.points;
			uniforms.u_texture = 0;
			uniforms.u_itransform = mat3.invert( tmp_mat3, this._matrix );
			tex.bind(0);
			shader = gradient_primitive_shader;
		}
		else if ( fill_style.constructor === GL.Texture ) //pattern
		{
			var tex = fill_style;
			uniforms.u_color = [ 1,1,1, this._globalAlpha ];
			uniforms.u_texture = 0;
			tmp_vec4.set([ 0,0,1/tex.width, 1/tex.height ]);
			uniforms.u_texture_transform = tmp_vec4;
			uniforms.u_itransform = mat3.invert( tmp_mat3, this._matrix );
			tex.bind(0);
			shader = textured_primitive_shader;
		}

		//render
		shader.uniforms(uniforms).drawRange(global_mesh, gl.TRIANGLE_FAN, 0, global_index / 3);
		extra_projection[14] -= 0.001;
	}

	//basic stroke using gl.LINES
	ctx.strokeThin = function()
	{
		if (global_index < 6)
			return;

		//update buffer
		global_buffer.uploadRange(0, global_index * 4); //4 bytes per float
		//global_buffer.upload( gl.STREAM_DRAW );

		gl.setLineWidth( this.lineWidth );
		uniforms.u_color = this._strokecolor;
		uniforms.u_transform = this._matrix;
		uniforms.u_viewport = viewport;
		flat_primitive_shader.uniforms(uniforms).drawRange(global_mesh, gl.LINE_STRIP, 0, global_index / 3);
	}

	//advanced stroke (it takes width into account)
	const lines_vertices = new Float32Array(max_points * 3);
	const lines_mesh = new GL.Mesh();
	const lines_buffer = lines_mesh.createVertexBuffer("vertices", null, null, lines_vertices, gl.STREAM_DRAW);

	ctx.stroke = function()
	{
		if (global_index < 6)
			return;

		tmp_vec2[0] = this._matrix[0];
		tmp_vec2[1] = this._matrix[1];

		if ( (this.lineWidth * vec2.length(tmp_vec2)) <= 1.0 )
			return this.strokeThin();

		const vertices = lines_vertices;
		const l = global_index;
		const line_width = this.lineWidth * 0.5;

		const points = global_vertices;

		let delta_x = 0;
		let delta_y = 0;
		let prev_delta_x = 0;
		let prev_delta_y = 0;
		let average_x = 0;
		let average_y = 0;
		let first_delta_x = 0;
		let first_delta_y = 0;

		if (points[0] === points[global_index - 3 ] && points[1] === points[global_index - 2 ])
		{
			delta_x = points[ global_index - 3 ] - points[ global_index - 6 ];
			delta_y = points[ global_index - 2 ] - points[ global_index - 5 ];
			var dist = Math.sqrt( delta_x*delta_x + delta_y*delta_y );
			if (dist !== 0)
			{
				delta_x = (delta_x / dist);
				delta_y = (delta_y / dist);
			}
		}

		let i, pos = 0;
		for (i = 0; i < l-3; i+=3)
		{
			prev_delta_x = delta_x;
			prev_delta_y = delta_y;

			delta_x = points[i+3] - points[i];
			delta_y = points[i+4] - points[i+1];
			var dist = Math.sqrt( delta_x*delta_x + delta_y*delta_y );
			if (dist !== 0)
			{
				delta_x = (delta_x / dist);
				delta_y = (delta_y / dist);
			}
			if (i === 0)
			{
				first_delta_x = delta_x;
				first_delta_y = delta_y;
			}

			average_x = delta_x + prev_delta_x;
			average_y = delta_y + prev_delta_y;

			var dist = Math.sqrt( average_x*average_x + average_y*average_y );
			if (dist !== 0)
			{
				average_x = (average_x / dist);
				average_y = (average_y / dist);
			}

			vertices[ pos+0 ] = points[i] - average_y * line_width;
			vertices[ pos+1 ] = points[i+1] + average_x * line_width;
			vertices[ pos+2 ] = 1;
			vertices[ pos+3 ] = points[i] + average_y * line_width;
			vertices[ pos+4 ] = points[i+1] - average_x * line_width;
			vertices[ pos+5 ] = 1;

			pos += 6;
		}

		//final points are tricky
		if (points[0] === points[global_index - 3 ] && points[1] === points[global_index - 2 ])
		{
			average_x = delta_x + first_delta_x;
			average_y = delta_y + first_delta_y;
			var dist = Math.sqrt( average_x*average_x + average_y*average_y );
			if (dist !== 0)
			{
				average_x = (average_x / dist);
				average_y = (average_y / dist);
			}
			vertices[ pos+0 ] = points[i] - average_y * line_width;
			vertices[ pos+1 ] = points[i+1] + average_x * line_width;
			vertices[ pos+2 ] = 1;
			vertices[ pos+3 ] = points[i] + average_y * line_width;
			vertices[ pos+4 ] = points[i+1] - average_x * line_width;
			vertices[ pos+5 ] = 1;
		}
		else
		{
			var dist = Math.sqrt( delta_x*delta_x + delta_y*delta_y );
			if (dist !== 0)
			{
				average_x = (delta_x / dist);
				average_y = (delta_y / dist);
			}

			vertices[ pos+0 ] = points[i] - (average_y - average_x) * line_width;
			vertices[ pos+1 ] = points[i+1] + (average_x + average_y) * line_width;
			vertices[ pos+2 ] = 1;
			vertices[ pos+3 ] = points[i] + (average_y + average_x) * line_width;
			vertices[ pos+4 ] = points[i+1] - (average_x - average_y) * line_width;
			vertices[ pos+5 ] = 1;
		}

		pos += 6;

		//lines_buffer.upload(gl.STREAM_DRAW);
		lines_buffer.uploadRange(0, pos * 4); //4 bytes per float

		uniforms.u_transform = this._matrix;
		uniforms.u_viewport = viewport;

		//first the shadow
		if ( this._shadowcolor[3] > 0.0 )
		{
			uniforms.u_color = this._shadowcolor;
			this.save();
			this.translate( this.shadowOffsetX, this.shadowOffsetY );
			flat_primitive_shader.uniforms(uniforms).drawRange(global_mesh, gl.TRIANGLE_STRIP, 0, pos / 3);
			this.restore();
		}

		//gl.setLineWidth( this.lineWidth );
		uniforms.u_color = this._strokecolor;
		flat_primitive_shader.uniforms( uniforms ).drawRange(lines_mesh, gl.TRIANGLE_STRIP, 0, pos / 3 );
		extra_projection[14] -= 0.001;
	}


	ctx.rect = function(x,y,w,h)
	{
		global_vertices[ global_index ] = x;
		global_vertices[ global_index + 1] = y;
		global_vertices[ global_index + 2] = 1;

		global_vertices[ global_index + 3] = x+w;
		global_vertices[ global_index + 4] = y;
		global_vertices[ global_index + 5] = 1;

		global_vertices[ global_index + 6] = x+w;
		global_vertices[ global_index + 7] = y+h;
		global_vertices[ global_index + 8] = 1;

		global_vertices[ global_index + 9] = x;
		global_vertices[ global_index + 10] = y+h;
		global_vertices[ global_index + 11] = 1;

		global_vertices[ global_index + 12] = x;
		global_vertices[ global_index + 13] = y;
		global_vertices[ global_index + 14] = 1;

		global_index += 15;
	}

	ctx.roundRect = function (x, y, w, h, radius, radius_low)
	{
		let top_left_radius = 0;
		let top_right_radius = 0;
		let bottom_left_radius = 0;
		let bottom_right_radius = 0;

		if ( radius === 0 )
		{
			this.rect(x,y,w,h);
			return;
		}

		if (radius_low === undefined)
			radius_low = radius;

		//make it compatible with official one
		if (radius !== null && radius.constructor === Array)
		{
			if (radius.length === 1)
				top_left_radius = top_right_radius = bottom_left_radius = bottom_right_radius = radius[0];
			else if (radius.length === 2)
			{
				top_left_radius = bottom_right_radius = radius[0];
				top_right_radius = bottom_left_radius = radius[1];
			}
			else if (radius.length === 4)
			{
				top_left_radius = radius[0];
				top_right_radius = radius[1];
				bottom_left_radius = radius[2];
				bottom_right_radius = radius[3];
			}
			else
				return;
		}
		else //old using numbers
		{
			top_left_radius = radius || 0;
			top_right_radius = radius || 0;
			bottom_left_radius = radius_low || 0;
			bottom_right_radius = radius_low || 0;
		}

		const gv = global_vertices;
		let gi = global_index;

		//topleft
		if (top_left_radius > 0)
			for (var i = 0; i < 10; ++i)
			{
				var ang = (i/10)*Math.PI*0.5;
				gv[ gi ] = x+top_left_radius*(1.0 - Math.cos(ang));
				gv[ gi + 1] = y+top_left_radius*(1.0 - Math.sin(ang));
				gv[ gi + 2] = 1;
				gi += 3;
			}

		gv[ gi + 0] = x+top_left_radius; gv[ gi + 1] = y; gv[ gi + 2] = 1;
		gv[ gi + 3] = x+w-top_right_radius; gv[ gi + 4] = y; gv[ gi + 5] = 1;
		gi += 6;

		//top right
		if (top_right_radius > 0)
			for (var i = 0; i < 10; ++i)
			{
				var ang = (i/10)*Math.PI*0.5;
				gv[ gi + 0] = x+w-top_right_radius*(1.0 - Math.sin(ang));
				gv[ gi + 1] = y+top_right_radius*(1.0 - Math.cos(ang));
				gv[ gi + 2] = 1;
				gi += 3;
			}

		gv[ gi + 0] = x+w; gv[ gi + 1] = y+top_right_radius; gv[ gi + 2] = 1;
		gv[ gi + 3] = x+w; gv[ gi + 4] = y+h-bottom_right_radius; gv[ gi + 5] = 1;
		gi += 6;

		//bottom right
		if (bottom_right_radius > 0)
			for (var i = 0; i < 10; ++i)
			{
				var ang = (i/10)*Math.PI*0.5;
				gv[ gi + 0] = x+w-bottom_right_radius*(1.0 - Math.cos(ang));
				gv[ gi + 1] = y+h-bottom_right_radius*(1.0 - Math.sin(ang));
				gv[ gi + 2] = 1;
				gi += 3;
			}

		gv[ gi + 0] = x+w-bottom_right_radius; gv[ gi + 1] = y+h; gv[ gi + 2] = 1;
		gv[ gi + 3] = x+bottom_left_radius; gv[ gi + 4] = y+h; gv[ gi + 5] = 1;
		gi += 6;

		//bottom right
		if (bottom_left_radius > 0)
			for (var i = 0; i < 10; ++i)
			{
				var ang = (i/10)*Math.PI*0.5;
				gv[ gi + 0] = x+bottom_left_radius*(1.0 - Math.sin(ang));
				gv[ gi + 1] = y+h-bottom_left_radius*(1.0 - Math.cos(ang));
				gv[ gi + 2] = 1;
				gi += 3;
			}

		gv[ gi + 0] = x; gv[ gi + 1] = y+top_left_radius; gv[ gi + 2] = 1;
		global_index = gi + 3;
	}


	ctx.arc = function(x,y,radius, start_ang, end_ang)
	{
		const scale = Math.max(Math.abs(this._matrix[0]), Math.abs(this._matrix[1]), Math.abs(this._matrix[3]), Math.abs(this._matrix[4]));//the axis defining ones
		let num = Math.ceil(radius * 2 * scale + 1);
		if (num<1)
			return;
		num = Math.min(num, 1024); //clamp it or bad things can happend

		start_ang = start_ang === undefined ? 0 : start_ang;
		end_ang = end_ang === undefined ? Math.PI * 2 : end_ang;

		const delta = (end_ang - start_ang) / num;

		for (let i = 0; i <= num; i++)
		{
			const f = start_ang + i * delta;
			this.lineTo(x + Math.cos(f) * radius, y + Math.sin(f) * radius);
		}
	}

	ctx.strokeRect = function(x,y,w,h)
	{
		this.beginPath();
		this.rect(x,y,w,h);//[x,y,1, x+w,y,1, x+w,y+h,1, x,y+h,1, x,y,1 ];
		this.stroke();
	}

	ctx.fillRect = function(x,y,w,h)
	{
		global_index = 0;

		//fill using a gradient or pattern
		if (this._fillStyle.constructor === GL.Texture || this._fillStyle.constructor === WebGLCanvasGradient )
		{
			this.beginPath();
			this.rect(x,y,w,h);
			this.fill();
			return;
		}

		uniforms.u_color = this._fillcolor;
		tmp_vec2[0] = x; tmp_vec2[1] = y;
		tmp_vec2b[0] = w; tmp_vec2b[1] = h;
		uniforms.u_position = tmp_vec2;
		uniforms.u_size = tmp_vec2b;
		uniforms.u_transform = this._matrix;
		uniforms.u_viewport = viewport;
		flat_shader.uniforms(uniforms).draw(quad_mesh);
		extra_projection[14] -= 0.001;
	}

	//other functions
	ctx.clearRect = function(x,y,w,h)
	{
		if (x !== 0 || y !== 0 || w !== canvas.width || h !== canvas.height )
		{
			gl.enable( gl.SCISSOR_TEST );
			gl.scissor(x,y,w,h);
		}

		gl.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
		const v = gl.viewport_data;
		gl.scissor(v[0],v[1],v[2],v[3]);
		gl.disable( gl.SCISSOR_TEST );
	}

	ctx.fillCircle = function(x,y,r)
	{
		global_index = 0;

		//fill using a gradient or pattern
		if (this._fillStyle.constructor === GL.Texture || this._fillStyle.constructor === WebGLCanvasGradient )
		{
			this.beginPath();
			this.arc(x,y,r,0,Math.PI*2);
			this.fill();
			return;
		}

		uniforms.u_color = this._fillcolor;
		tmp_vec2[0] = x; tmp_vec2[1] = y;
		tmp_vec2b[0] = r; tmp_vec2b[1] = r;
		uniforms.u_position = tmp_vec2;
		uniforms.u_size = tmp_vec2b;
		uniforms.u_transform = this._matrix;
		uniforms.u_viewport = viewport
		flat_shader.uniforms(uniforms).draw(circle_mesh);
		extra_projection[14] -= 0.001;
	}

	//control funcs: used to set flags at the beginning and the end of the render
	ctx.start2D = function()
	{
		prev_gl = window.gl;
		window.gl = this;
		const gl = this;

		//viewport[0] = gl.viewport_data[2];
		//viewport[1] = gl.viewport_data[3];
		gl.disable( gl.CULL_FACE );
		gl.disable( gl.DEPTH_TEST );
		gl.disable( gl.STENCIL_TEST );
		gl.enable( gl.BLEND );
		gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );
		//gl.blendFuncSeparate( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ZERO, gl.DST_ALPHA );
		gl.blendEquation( gl.FUNC_ADD );
		gl.lineWidth = 1;
		global_index = 0;
		mat4.identity( extra_projection );
		this.clip_level = 0; //sure?
	}

	ctx.finish2D = function()
	{
		global_index = 0;
		gl.lineWidth = 1;
		window.gl = prev_gl;
		gl.disable( gl.STENCIL_TEST );
	}

	//text rendering
	const point_text_vertices = new Float32Array(max_characters * 3);
	const point_text_coords = new Float32Array(max_characters * 2);
	const point_text_mesh = new GL.Mesh();
	const point_text_vertices_buffer = point_text_mesh.createVertexBuffer("vertices", null, null, point_text_vertices, gl.STREAM_DRAW);
	const point_text_coords_buffer = point_text_mesh.createVertexBuffer("coords", null, null, point_text_coords, gl.STREAM_DRAW);

	ctx.fillText = ctx.strokeText = function(text,startx,starty)
	{

		if (text === null || text === undefined)
			return;
		if (text.constructor !== String)
			text = String(text);

		const atlas = createFontAtlas.call(this, this._font_family, this._font_mode, this.expireFontAtlas);
		if (this.expireFontAtlas)
			this.expireFontAtlas = false;
		const info = atlas.info;

		const points = point_text_vertices;
		const coords = point_text_coords;
		let point_size = this._font_size * 1.1;

		if (point_size < 1)
			point_size = 1;

		let x = 0;
		let y = 0;
		const l = text.length;
		const kernings = info.kernings;
		let is_first_char = true;

		let vertices_index = 0, coords_index = 0;

		for (var i = 0; i < l; i++)
		{
			const char_code = text.charCodeAt(i);
			const c = info[char_code]; //info
			if (!c)
			{
				if (text.charCodeAt(i) === 10) //break line
				{
					x = 0;
					y += point_size;
					is_first_char = true;
				}
				else
					x += point_size * 0.5;
				continue;
			}

			const kern = kernings[text[i]];
			if (is_first_char)
			{
				x -= point_size * kern["nwidth"] * 0.25;
				is_first_char = false;
			}

			points[vertices_index+0] = startx + x + point_size * 0.5;
			points[vertices_index+1] = starty + y - point_size * 0.25;
			points[vertices_index+2] = 1;
			vertices_index += 3;

			coords[coords_index+0] = c[1];
			coords[coords_index+1] = c[2];
			coords_index += 2;

			const pair_kern = kern[text[i + 1]];
			if (!pair_kern)
				x += point_size * info.space;
			else
				x += point_size * pair_kern;
		}

		let offset = 0;
		if (this.textAlign === "right")
			offset = x + point_size * 0.5;
		else if (this.textAlign === "center")
			offset = (x + point_size * 0.5 ) * 0.5;
		if (offset)
			for (var i = 0; i < points.length; i += 3)
				points[i] -= offset;

		//render
		atlas.bind(0);

		//var mesh = GL.Mesh.load({ vertices: points, coords: coords });
		point_text_vertices_buffer.uploadRange(0,vertices_index*4);
		point_text_coords_buffer.uploadRange(0,coords_index*4);

		uniforms.u_color = this._fillcolor;
		uniforms.u_pointSize = point_size * vec2.length( this._matrix );
		uniforms.u_iCharSize = info.char_size / atlas.width;
		uniforms.u_transform = this._matrix;
		uniforms.u_viewport = viewport;
		if (!uniforms.u_angle_sincos)
			uniforms.u_angle_sincos = vec2.create();

		uniforms.u_angle_sincos[1] = Math.sin(-global_angle);
		uniforms.u_angle_sincos[0] = -Math.cos(-global_angle);

		point_text_shader.uniforms(uniforms).drawRange(point_text_mesh, gl.POINTS, 0, vertices_index / 3 );

		return { x:x, y:y };
	}

	ctx.measureText = function(text)
	{
		const atlas = createFontAtlas.call(this, this._font_family, this._font_mode);
		const info = atlas.info;
		const point_size = Math.ceil(this._font_size * 1.1);
		let textsize = 0;
		const spacing = point_size * info.spacing / info.char_size - 1;
		for (let i = 0; i < text.length; ++i)
		{
			const charinfo = info.kernings[text[i]];
			if (charinfo)
				textsize += charinfo.nwidth;
			else
				textsize += spacing / info.char_size;
		}
		//textsize = text.length * spacing;
		textsize *= point_size;
		return { width: textsize, height: point_size };
	}

	function createFontAtlas( fontname, fontmode, force )
	{
		fontname = fontname || "monospace";
		fontmode = fontmode || "normal";

		const imageSmoothingEnabled = this.imageSmoothingEnabled;
		const useInternationalFont = enableWebGLCanvas.useInternationalFont;

		const canvas_size = 1024;

		const texture_name = ":font_" + fontname + ":" + fontmode + ":" + useInternationalFont;

		let texture = textures[texture_name];
		if (texture && !force)
			return texture;


		let max_ascii_code = 200;
		let chars_per_row = 10;

		if (useInternationalFont) //more characters
		{
			max_ascii_code = 400;
			chars_per_row = 20;
		}

		const char_size = (canvas_size / chars_per_row) | 0;
		const font_size = (char_size * 0.95) | 0;

		const canvas = Canvas.create(canvas_size, canvas_size);
		const ctx = canvas.getContext("2d");
		ctx.fillStyle = "white";
		ctx.imageSmoothingEnabled = this.imageSmoothingEnabled;
		ctx.clearRect(0,0,canvas.width,canvas.height);
		ctx.font = fontmode + " " + font_size + "px " + fontname;
		ctx.textAlign = "center";
		let x = 0;
		let y = 0;
		const xoffset = 0.5;
		let yoffset = font_size * -0.3;
		const info = {
			font_size: font_size,
			char_size: char_size, //in pixels
			spacing: char_size * 0.6, //in pixels
			space: (ctx.measureText(" ").width / font_size)
		};

		yoffset += enableWebGLCanvas.fontOffsetY * char_size;

		//compute individual char width (WARNING: measureText is very slow)
		const kernings = info.kernings = {};
		for (var i = 33; i < max_ascii_code; i++)
		{
			var character = String.fromCharCode(i);
			var char_width = ctx.measureText(character).width;
			const char_info = { width: char_width, nwidth: char_width / font_size };
			kernings[character] = char_info;
		}

		const clip = true; //clip every character: debug

		//paint characters in atlas
		for (var i = 33; i < max_ascii_code; ++i)//valid characters from 33 to max_ascii_code
		{
			var character = String.fromCharCode(i);
			var kerning = kernings[ character ];
			if ( kerning && kerning.width ) //has some visual info
			{
				info[i] = [ character, (x + char_size*0.5)/canvas.width, (y + char_size*0.5) / canvas.height ];
				if (clip)
				{
					ctx.save();
					ctx.beginPath();
					ctx.rect( Math.floor(x)+0.5, Math.floor(y)+0.5, char_size-2, char_size-2 );
					ctx.clip();
					ctx.fillText( character, Math.floor(x+char_size*xoffset), Math.floor(y+char_size+yoffset), char_size );
					ctx.restore();
				}
				else
					ctx.fillText( character, Math.floor(x+char_size*xoffset), Math.floor(y+char_size+yoffset), char_size );
				x += char_size; //cannot pack chars closer because rendering points, no quads
				if ((x + char_size) > canvas.width)
				{
					x = 0;
					y += char_size;
				}
			}

			if ( y + char_size > canvas.height )
				break; //too many characters
		}

		const last_valid_ascii = i; //the last character in the atlas

		//compute kernings of every char with the rest of chars
		for (var i = 33; i < last_valid_ascii; ++i)
		{
			var character = String.fromCharCode(i);
			var kerning = kernings[ character ];
			var char_width = kerning.width;
			for (let j = 33; j < last_valid_ascii; ++j)
			{
				const other = String.fromCharCode(j);
				const other_width = kernings[other].width;
				if (!other_width)
					continue;
				const total_width = ctx.measureText(character + other).width; //this is painfully slow...
				kerning[other] = (total_width * 1.45 - char_width - other_width) / font_size;
			}
		}

		texture = GL.Texture.fromImage( canvas, { format: gl.ALPHA, magFilter: imageSmoothingEnabled ? gl.LINEAR : gl.NEAREST, minFilter: imageSmoothingEnabled ? gl.NEAREST_MIPMAP_LINEAR : gl.NEAREST, premultiply_alpha: false, anisotropic: 8 } );
		texture.info = info; //font generation info

		return textures[texture_name] = texture;
	}

	//NOT TESTED
	ctx.getImageData = function(x,y,w,h)
	{
		const buffer = new Uint8Array(w * h * 4);
		gl.readPixels(x,y,w,h,gl.RGBA,gl.UNSIGNED_BYTE,buffer);
		return { data: buffer, width: w, height: h, resolution: 1 };
	}

	ctx.putImageData = function( imagedata, x, y )
	{
		const tex = new GL.Texture(imagedata.width, imagedata.height, {
			filter: gl.NEAREST,
			pixel_data: imagedata.data
		});
		tex.renderQuad(x,y,tex.width, tex.height);
	}

	Object.defineProperty(gl, "fillStyle", {
		get: function() { return this._fillStyle; },
		set: function(v) {
			if (!v)
				return;
			this._fillStyle = v;
			hexColorToRGBA( v, this._fillcolor, this._globalAlpha );
		}
	});

	Object.defineProperty(gl, "strokeStyle", {
		get: function() { return this._strokeStyle; },
		set: function(v) {
			if (!v)
				return;
			this._strokeStyle = v;
			hexColorToRGBA( v, this._strokecolor, this._globalAlpha );
		}
	});

	//shortcuts
	Object.defineProperty(gl, "fillColor", {
		get: function() { return this._fillcolor; },
		set: function(v) {
			if (!v)
				return;
			if ( v.length < 5 )
				this._fillcolor.set(v);
			else
				console.error("fillColor value has more than 4 components");
		}
	});

	Object.defineProperty(gl, "strokeColor", {
		get: function() { return this._strokecolor; },
		set: function(v) {
			if (!v)
				return;
			if ( v.length < 5 )
				this._strokecolor.set(v);
			else
				console.error("strokeColor value has more than 4 components");
		}
	});

	Object.defineProperty(gl, "shadowColor", {
		get: function() { return this._shadowcolor; },
		set: function(v) {
			if (!v)
				return;
			hexColorToRGBA( v, this._shadowcolor, this._globalAlpha );
		}
	});

	Object.defineProperty(gl, "globalAlpha", {
		get: function() { return this._globalAlpha; },
		set: function(v) {
			this._globalAlpha = v;
			this._strokecolor[3] = this._fillcolor[3] = v;
		}
	});

	Object.defineProperty(gl, "globalCompositeOperation", {
		get: function() { return this._globalCompositeOperation; },
		set: function(v) {
			this._globalCompositeOperation = v;
			gl.blendEquation( gl.FUNC_ADD );
			switch (v)
			{
			case "source-over":
				gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );
				gl.blendEquation( gl.FUNC_ADD );
				break;
			case "difference":
				gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );
				gl.blendEquation( gl.FUNC_REVERSE_SUBTRACT );
				break;
			}
		}
	});

	Object.defineProperty(gl, "font", {
		get: function() { return this._font; },
		set: function(v) {
			this._font = v;
			const t = v.split(" ");
			if (t.length === 3)
			{
				this._font_mode = t[0];
				this._font_size = parseFloat(t[1]);
				if ( Number.isNaN( this._font_size ) )
					this._font_size = 14;
				if (this._font_size < 10)
					this._font_size = 10;
				this._font_family = t[2];
			}
			else if (t.length === 2)
			{
				this._font_mode = "normal";
				this._font_size = parseFloat(t[0]);
				if ( Number.isNaN( this._font_size ) )
					this._font_size = 14;
				if (this._font_size < 10)
					this._font_size = 10;
				this._font_family = t[1];
			}
			else
			{
				this._font_mode = "normal";
				this._font_family = t[0];
			}
		}
	});

	//define globals
	ctx._fillcolor = vec4.fromValues(0,0,0,1);
	ctx._strokecolor = vec4.fromValues(0,0,0,1);
	ctx._shadowcolor = vec4.fromValues(0,0,0,0);
	ctx._globalAlpha = 1;
	ctx._font = "14px monospace";
	ctx._font_family = "monospace";
	ctx._font_size = "14px";
	ctx._font_mode = "normal";


	//STATE
	ctx.clip_level = 0;
	ctx.strokeStyle = "rgba(0,0,0,1)";
	ctx.fillStyle = "rgba(0,0,0,1)";
	ctx.shadowColor = "transparent";
	ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
	ctx.globalAlpha = 1;
	ctx.globalCompositeOperation = "source-over";
	ctx.setLineWidth = ctx.lineWidth; //save the webgl function
	ctx.lineWidth = 4; //set lineWidth as a number
	ctx.imageSmoothingEnabled = true;
	ctx.tintImages = false; //my own parameter


	//empty functions: this is used to create null functions in those Canvas2D funcs not implemented here
	const names = [ "arcTo", "isPointInPath", "createImageData" ]; //all functions have been implemented
	const null_func = function () {
	};
	for (var i in names)
		ctx[ names[i] ] = null_func;

	return ctx;
};

enableWebGLCanvas.useInternationalFont = false; //render as much characters as possible in the texture atlas
enableWebGLCanvas.fontOffsetY = 0; //hack, some fonts need extra offsets, dont know why

export default enableWebGLCanvas;
