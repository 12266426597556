import { vec3, vec4 } from "gl-matrix";

import { WALK }             from "./constants";
import nearestToLine2DwithY from "./nearestToLine2DwithY";
import pointInShapeArray    from "./pointInShapeArray";

const Area = function()
{
	this.id = -1;
	this.enabled = true;
	this.height = 0;
	this.points = [ [ -1,0,-1 ],[ -1,0,1 ],[ 1,0,1 ],[ 1,0,-1 ] ];
	this.type = WALK;

	this._bounding = vec4.create(); //minx, minz, maxx, maxz
	this._component = null;
	this._participants = [];
	this.updateBounding();
}

Area.prototype.serialize = function(o)
{
	o.id = this.id;
	o.enabled = this.enabled;
	o.height = this.height;
	o.points = this.points;
	o.type = this.type;

	return o;
}

Area.prototype.configure = function(o)
{
	this.id = o.id;
	this.enabled = o.enabled;
	this.height = o.height;
	this.points = o.points;
	this.type = o.type;
	this.updateBounding();
}

Area.prototype.onPropertyChanged = function()
{
	if (this._component)
		this._component._must_update = true;
}

Area.prototype.updateBounding = function()
{
	const min = [ 0, 0, 0 ];
	const max = [ 0, 0, 0 ];

	if (!this.points.length)
		return;

	//init with first
	vec3.copy( min, this.points[0] );
	vec3.copy( max, min );

	for (let i = 1; i < this.points.length; ++i )
	{
		const point = this.points[i];
		vec3.min( min, min, point );
		vec3.max( max, max, point );
	}

	this._bounding.set([ min[0],min[2],max[0],max[2] ]);
}

Area.prototype.isInside = function(pos)
{
	const bb = this._bounding;
	//check with bounding
	if ( pos[0] < bb[0] || pos[2] < bb[1] ||
       pos[0] > bb[2] || pos[2] > bb[3] )
		return false; //out of bounding box

	if (!this.points.length)
		return false;

	//it is out of the shape ignore it
	if ( !pointInShapeArray( pos, this.points ) )
		return false;

	return true;
}

Area.prototype.findNearestEdgePoint = function(pos, min_dist )
{
	if ( min_dist === undefined )
		min_dist = 100000;
	const points = this.points;
	let nearest = null;

	let i = 0;
	const l = points.length;
	for (; i < l; ++i)
	{
		const p1 = points[i];
		const p2 = (i + 1) === l ? points[0] : points[i + 1];
		const nearest_to_segment = nearestToLine2DwithY(pos, p1, p2);
		const dist = vec3.distance(pos, nearest_to_segment);
		if (dist > min_dist)
			continue;

		//assign as the nearest edge point
		min_dist = dist;
		nearest = nearest_to_segment;
	}

	return nearest;
}

Area.prototype.getCenter = function(out)
{
	out = out || vec3.create();
	out[0] = (this._bounding[0] + this._bounding[2]) * 0.5;
	out[1] = this.height;
	out[2] = (this._bounding[1] + this._bounding[3]) * 0.5;
	return out;
}

export default Area;
