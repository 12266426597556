import { vec4 } from "gl-matrix";

import ROOM from "@src/engine/room";
import Button from "@src/libs/GLUI/Elements/Button";

import { corner_options } from "./constants";
import positionToArea from "./positionToArea";

function LGraphGUIButton()
{
	this.addOutput("",LiteGraph.EVENT);
	this.addOutput("was_pressed");
	this.properties = { enabled: true, text:"clickme", position: [ 20,20 ], size: [ 140,40 ], corner: LiteGraph.CORNER_TOP_LEFT };
	this.widgets_start_y = 2;
	this.addWidget("text","text","clickme","text");
	this.addWidget("button","click","click", function(w,canvas,node) {
		node.buttonClicked();
	});
	this._area = vec4.create();
	this._was_pressed = false;
}

LGraphGUIButton.title = "GUIButton";
LGraphGUIButton.desc = "Renders a toggle widget on the main canvas";
LGraphGUIButton["@corner"] = corner_options;

LGraphGUIButton.prototype.onRenderGUI = function( ctx, GUI )
{
	if (!this.getInputOrProperty("enabled"))
		return;
	positionToArea( this.properties.position, this.properties.corner, this._area );
	var parent_pos = this.getInputOrProperty("parent_pos");
	if (parent_pos)
	{
		this._area[0] += parent_pos[0];
		this._area[1] += parent_pos[1];
	}
	this._area[2] = this.properties.size[0];
	this._area[3] = this.properties.size[1];
	this._was_pressed = Button.call(GUI, this._area[0],this._area[1],this._area[2],this._area[3], this.properties.text );
}

LGraphGUIButton.prototype.onExecute = function()
{
	var enabled = this.getInputDataByName("enabled");
	if (enabled === false || enabled === true)
		this.properties.enabled = enabled;
	if (this._was_pressed)
		this.buttonClicked();
	this.setOutputData(1, this._was_pressed );
	this._was_pressed = false;
}

LGraphGUIButton.prototype.buttonClicked = function()
{
	this.triggerSlot(0);

	var graph = this.graph;
	var space = graph && graph.space || ROOM.space;
	if ( space.network )
		space.network.syncEvent( graph.component, "graph_trigger", [ this.id, 0 ], true );
}

LGraphGUIButton.prototype.onGetInputs = function() {
	return [ [ "enabled","boolean" ],[ "parent_pos","vec2" ] ];
}

export default LGraphGUIButton;
