import { generateUID } from "@src/engine/generateUID";
import { vec3 } from "gl-matrix";

function ProbeObject()
{
	this._on_focus = false;

	this._active = true;
	if(xyz.native_mode)
		this._pb = TmrwModule.NativeRoomAPI.ProbeBuilder();
	else
		this._pb = null;

	this._color = vec3.create();
	vec3.set(this._color, 1.0, 1.0, 1.0);

	this._native = null;
	this._dirty = false;
	this._generate = 0;

	this._url = "";
	this._dirtyUrl = false;

	this._canUpload = false;

	this._index = ProbeObject.last_index++;
}

ProbeObject.componentName = "ProbeObject";
ProbeObject.last_index = 1;

ProbeObject.icon = [ 6,3 ];

ProbeObject.widgets = {
	"Active": { type: "toggle" },
	"Helper": { type: "toggle" },
	"Use SH": { type: "toggle" },
	"Gen. Color": { type: "color" },
	"Gen. Intens": { type: "slider", min: 0.0, max: 10.0, mark: 1.0 },
	"Intensity": { type: "slider", min: 0.0, max: 10.0, mark: 1.0 },
	"Generate": { type: "button", title: "Generate" },
	"GenerateSH": { type: "button", title: "Generate with SH" },
	"Upload": { type: "button", title: "Upload to the server" },
	"Download": { type: "button", title: "Download from the server" },
	url: { type: "asset", extensions: "ktx" }

//	"Size": { type: "slider", min: 0.0, max: 100.0, mark: 1.0 },
//	"Falloff": { type: "slider", min: 0.0, max: 2.0, mark: 0.0 },
//	"Priority": { type: "slider", min: -1, max: 10, mark: 0 }
}

Object.defineProperty(ProbeObject.prototype, "Active", {
	get: function () {
		return this._pb ? this._pb.active : false;
	},
	set: function (v) {
		if(!this._pb) return;
		if (this._pb.active == v)
			return;
		this._dirty = true;
		this._pb.active = v;
	}
});

Object.defineProperty(ProbeObject.prototype, "Use SH", {
	get: function () {
		return this._pb ? this._pb.useSH : false;
	},
	set: function (v) {
		if (this._pb.useSH == v)
			return;
		this._dirty = true;
		this._pb.useSH = v;
	}
});

Object.defineProperty(ProbeObject.prototype, "url", {
	get: function () {
		return this._url;
	},
	set: function (v) {
		if (v != null)
			v = v.trim();
		if (v == this._url)
			return;
		this._url = v;
		//this._dirtyUrl = true;
	}
});

Object.defineProperty(ProbeObject.prototype, "Helper", {
	get: function () {
		return this._pb ? this._pb.helper : null;
	},
	set: function (v) {
		if(!this._pb)
			return;
		if (this._pb.helper == v)
			return;
		this._dirty = true;
		this._pb.helper = v;
	}
});

Object.defineProperty(ProbeObject.prototype, "Intensity", {
	get: function () {
		return this._pb ? this._pb.intensity : -1;
	},
	set: function (v) {
		if(!this._pb)
			return;
		if (this._pb.intensity == v)
			return;
		this._dirty = true;
		this._pb.intensity = v;
	}
});

Object.defineProperty(ProbeObject.prototype, "Gen. Intens", {
	get: function () {
		return this._pb ? this._pb.genIntensity : -1;
	},
	set: function (v) {
		if(!this._pb)
			return false;
		if (this._pb.genIntensity == v)
			return;
		this._dirty = true;
		this._pb.genIntensity = v;
	}
});

Object.defineProperty(ProbeObject.prototype, "Size", {
	get: function () {
		return this._pb ? this._pb.size : -1;
	},
	set: function (v) {
		if(!this._pb)
			return;
		if (this._pb.size == v)
			return;
		this._dirty = true;
		this._pb.size = v;
	}
});

Object.defineProperty(ProbeObject.prototype, "Falloff", {
	get: function () {
		return this._pb ? this._pb.fallOff : -1;
	},
	set: function (v) {
		if (this._pb.fallOff == v)
			return;
		this._dirty = true;
		this._pb.fallOff = v;
	}
});

Object.defineProperty(ProbeObject.prototype, "Priority", {
	get: function () {
		return this._pb ? this._pb.priority : -1;
	},
	set: function (v) {
		if(!this._pb)
			return;
		if (this._pb.priority == v)
			return;
		this._dirty = true;
		this._pb.priority = v;
	}
});

Object.defineProperty(ProbeObject.prototype, "Gen. Color", {
	get: function () {
		return this._color;
	},
	set: function (v) {
		vec3.copy(this._color, v);
	}
});


ProbeObject.prototype.Generate = function () {
	this._generate |= 4;
}
ProbeObject.prototype.GenerateSH = function () {
	this._generate |= 2;
}

ProbeObject.prototype.Download = function () {
	if (!this._url.includes(".ktx")) {
		editor.showNotification("Wrong Probe URL", [ 7, 0 ]);
	}
	else
		this._dirtyUrl = true;
}

ProbeObject.prototype.Upload = function () {
	if(!xyz.native_mode)
	{
		alert("only in native mode");
		return;
	}

	if (this._canUpload) {
		var editor = xyz.editor_controller;
		var space = xyz.space;

		if (!xyz.backend || !xyz.backend.username) {
			editor.showNotification("You are not logged in", [ 7, 0 ]);
			return;
		}

		if (!this._url)
		{
			this._url = space.folder + "/" + generateUID("PROBE-").substring(1) + ".ktx";
		}

		if (!this._url || !this._url.includes(".ktx")) {
			editor.showNotification("Invalid probe URL", [ 7, 0 ]);
			return;
		}
		if (!this._url.includes(space.folder)) {
			this._url = space.folder + "/" + this._url;
		}

		var data = this._pb.getData(this._native);
		if (data && data.length) {
			//console.log("Saving file, size: " + (data.length / 1024).toFixed(2) + "KBs" );
			editor.saveFileInBackend(this._url, data)
			var server = 0;
		}
	}
}

ProbeObject.prototype.onAdded = function (parent) {
	LEvent.bind(parent, "clicked", this.onClicked, this);

	this._node = parent.node;
}

ProbeObject.prototype.createNative = function () {
	if (this._root && window.nativeEngine && !this._native) {
		if (!this._root._native) {
			// Andrey FIXME: Hack to place new volume entity in front of camera
			// Btw for some reason this camera position doesn't changed when we fly in the editor mode!
			var camera = ViewCore.instance.hard_camera;
			var cam_pos = camera.position;
			var cam_front = camera.getFront();
			var dist = 1.0;

			var offset = vec3.scale(vec3.create(), cam_front, dist);
			vec3.add(this._node.position, cam_pos, offset);
			this._node.mustUpdate = true;

			this._root.createNativeEntity(this._root.index);
		}

		this._pb.position = this._node.position;
		this._pb.scale = this._node.scaling;

		//this._pb.build(this._root._native);

		this._native = this._root._native;

		this._dirty = true;
		this._dirtyUrl = true;
	}
}


ProbeObject.prototype.onRemoved = function(parent)
{
	LEvent.unbind( parent,"clicked", this.onClicked, this );

	this._node = null;
	if (this._native) {
		//nativeEngine._room.destroyEntityObj(this._native);
		this._native = null;
	}
	if (this._pb)
		this._pb.delete();
	this._pb = null;
}

ProbeObject.prototype.serialize = function(o)
{
	if (!this._pb)
		return;

	o.active = this._pb.active;
	o.useSH = this._pb.useSH;

	o.color = this._pb.genColor;
	o.intensity = this._pb.intensity;
	o.genIntensity = this._pb.genIntensity;
	o.size = this._pb.size;
	o.fallOff = this._pb.fallOff;
	o.priority = this._pb.priority;
	o.parallax = this._pb.parallax;
	o.url = this._url;
}

ProbeObject.prototype.configure = function(o)
{
	if(!this._pb)
		return;

	this._pb.active = o.active;
	this._pb.useSH = o.useSH;

	this._pb.intensity = o.intensity;
	this._pb.genIntensity = o.genIntensity;
	this._pb.size = o.size;
	this._pb.fallOff = o.fallOff;
	this._pb.priority = o.priority;
	this._pb.parallax = o.parallax;

	this._url = o.url;
	this._pb.url = o.url;

	var c = vec3.create();
	c[0] = o.color[0];
	c[1] = o.color[1];
	c[2] = o.color[2];
	this._pb.genColor = c;
	this._color = c;


	this._dirty = true;
}

ProbeObject.prototype.onClicked = function(e)
{
	//console.debug("clicked",this.entity.name);
	if (!this._on_focus)
		this.enterFocus();
	else //leaving
		this.exitFocus();
}

/*ProbeObject.prototype.onRenderInspector = function (ctx, x, y, w, h, editor) {
	if (GUI.Button(x, y, w, 24, "Generate"))
		this._generate |= 2;
	if (GUI.Button(x, y+30, w, 24, "Generate with SH"))
		this._generate |= 4;
}*/


ProbeObject.prototype.preRender = function(view)
{
	if (!this._native) {
		if (xyz.native_loaded)
			this.createNative();
	}
	if (!this._native) {
		return;
	}

	if (!this._pb)
		return;

	if (this._dirtyUrl) {
		if (this._url.includes(".ktx")) {
			this._dirty = true;
		}
	}

	if (this._dirty || !vec3.equals(this._color, this._pb.genColor) || !vec3.equals(this._pb.position, this._node.position)) {
		this._dirty = false;

		this._pb.genColor = this._color;

		this._pb.position = this._node.position;
		this._pb.scale = this._node.scaling;

		if (this._dirtyUrl) {
			var url = this._url ? this._url : "";
			if (this._url) {
				url = this._url.substring(this._url.lastIndexOf("/")+1);
			}
			this._pb.url = url;
		}
		if (this._pb.useSH)
			this._canUpload = true;

		this._pb.build(this._native);
	}

	if (this._generate) {
		this._pb.gen(this._native, this._generate);
		if (this._generate & 2)
			this._canUpload = true;
		this._generate = 0;
	}

	this._dirtyUrl = false;
}


ProbeObject.prototype.enterFocus = function()
{
	if (this._on_focus)
		return;

	LEvent.bind( this.space, "keydown", this.onKey, this );
	LEvent.bind( this.space, "mouse", this.onGlobalMouse, this );

	//compute radius
	var original_pos = this.entity.node.getGlobalPosition();
	var bb = node.updateBoundingBox();
	var center = BBox.getCenter(bb);
	var halfsize = BBox.getHalfsize(bb);
	vec3.mul( halfsize, halfsize, node.scaling );
	var radius = vec3.length( halfsize );// / this.entity.node.scaling[0];
	console.debug(radius);
	radius *= 1.5;
}

ProbeObject.prototype.exitFocus = function()
{
	if (!this._on_focus)
		return;

	LEvent.unbind(this.space, "keydown", this.onKey, this);
	LEvent.unbind(this.space, "mouse", this.onGlobalMouse, this);

	this._on_focus = false;
}

ProbeObject.prototype.onKey = function(type,e)
{
	if (e.key == "Escape")
	{
		this.exitFocus();
	}
	return true;
}

ProbeObject.prototype.onGlobalMouse = function(type,e)
{
	//console.debug(e);
	if (!this._on_focus)
		return;

	var speed = 0.02;

	if ( e.dragging )
	{
		//var node = this.entity.node;
		//var node = this._inspect_node;
		//var local_up = node.globalVectorToLocal( [0,1,0] );
		//var local_right = node.globalVectorToLocal( [1,0,0] );
		//node.rotate( e.deltax * speed, local_up );
		//node.rotate( e.deltay * speed, local_right );
	}

	if ( e.dragging && e.type == "mousemove")
		return true;
}

export default ProbeObject;

