import { openLink } from "@src/engine/Room/openLink";

function InteractiveObjectActionLink()
{
	this._id = -1;
	this.url = "";
	this.new_tab = false;
	this.description = "";
}

InteractiveObjectActionLink.label = "Open Link";

InteractiveObjectActionLink.prototype.onClick = function(_e, _entity, _comp) {
	if (this.url)
		openLink( this.url );
}

InteractiveObjectActionLink.prototype.serialize = function() {
	return {
		url: this.url,
		new_tab: this.new_tab,
		description: this.description
	}
}

InteractiveObjectActionLink.prototype.configure = function(o)
{
	this.url = o.url;
	this.new_tab = o.new_tab;
	this.description = o.description;
}

export default InteractiveObjectActionLink;
