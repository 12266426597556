import { vec4 }           from "gl-matrix";

import { corner_options } from "./constants";
import positionToArea     from "./positionToArea";

function LGraphGUISlider()
{
	this.addOutput("v");
	this.properties = { enabled: true, text: "", min: 0, max: 1, value: 0, position: [ 20,20 ], size: [ 200,40 ], corner: LiteGraph.CORNER_TOP_LEFT };
	this.addWidget("slider","value",0.5,"value");
	this.widgets_up = true;
	this._area = vec4.create();
	this.size = [200,40];
}

LGraphGUISlider.title = "GUISlider";
LGraphGUISlider.desc = "Renders a slider on the main canvas";
LGraphGUISlider["@corner"] = corner_options;

LGraphGUISlider.prototype.onRenderGUI = function()
{
	if (!this.getInputOrProperty("enabled"))
		return;
	positionToArea( this.properties.position, this.properties.corner, this._area );
	this._area[2] = this.properties.size[0];
	this._area[3] = this.properties.size[1];

	var parent_pos = this.getInputOrProperty("parent_pos");
	if (parent_pos)
	{
		this._area[0] += parent_pos[0];
		this._area[1] += parent_pos[1];
	}

	this.properties.value = GUI.Slider( this._area[0],this._area[1],this._area[2],this._area[3], Number(this.properties.value), Number(this.properties.min), Number(this.properties.max) );
	if (this.properties.text)
	{
		gl.textAlign = "right";
		gl.fillStyle = "#AAA";
		gl.fillText( this.properties.text, this._area[0] - 20, this._area[1] + this._area[3] * 0.75);
		gl.textAlign = "left";
	}
}

LGraphGUISlider.prototype.onExecute = function()
{
	if (this.inputs && this.inputs.length)
		this.properties.enabled = this.getInputOrProperty("enabled");
	this.setOutputData(0, this.properties.value );
}

LGraphGUISlider.prototype.onGetInputs = function() {
	return [ [ "enabled","boolean" ],[ "parent_pos","vec2" ] ];
}

export default LGraphGUISlider;
