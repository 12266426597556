import { vec3 } from "gl-matrix";
export const Direction3Constants = {
    ZERO: vec3.fromValues(0, 0, 0),
    ONE: vec3.fromValues(1, 1, 1),
    RIGHT: vec3.fromValues(1, 0, 0),
    LEFT: vec3.fromValues(-1, 0, 0),
    UP: vec3.fromValues(0, 1, 0),
    DOWN: vec3.fromValues(0, -1, 0),
    FRONT: vec3.fromValues(0, 0, -1),
    BACK: vec3.fromValues(0, 0, 1),
};
