import { mat3, vec3, vec2 } from "gl-matrix";
import Entity from "@src/engine/entity";
import EntityReference from "@src/engine/Entity/EntityReference";
import { RoomComponents } from "@src/engine/RoomComponents";
import Button from "@src/libs/GLUI/Elements/Button";
import Label  from "@src/libs/GLUI/Elements/Label";

//EDITOR STUFF ******************************************************
var GraphEditorDialog = {
	area: [ 0,0,400,400 ],
	selected: null,
	editor: null,

	onOpen: function( editor )
	{
		this.editor = editor;

		if(!this.style)
		{
			this.style = document.createElement("link");
			this.style.rel="stylesheet";
			this.style.type="text/css";
			this.style.href="https://tamats.com/projects/litegraph/css/litegraph.css";
			document.head.appendChild(this.style);
		}

		if (0)
		{
			if (!this.graphcanvas)
			{
				this.root = document.createElement("div");
				this.root.style.position = "fixed";
				this.root.style.overflow = "hidden";
				this.root.style.zIndex = 10;
				this.root.className = "litegraph lgraphcanvas";
				gl.canvas.parentNode.appendChild( this.root );
				this.canvas = document.createElement("canvas");
				this.graphcanvas = new LiteGraph.LGraphCanvas( this.canvas );
				this.graphcanvas.onDropItem = this.onDropItem.bind(this);
				this.root.appendChild( this.canvas );
			}
			else
				this.root.style.display = "";
		}
		else
		{
			this.graphcanvas = new LiteGraph.LGraphCanvas( gl.canvas, null, { skip_events:true, skip_render:true } );
			this.graphcanvas.clear_background = false;
			this.graphcanvas.render_canvas_border = false;
			this.graphcanvas.onShowNodePanel = this.onShowNodePanel.bind(this);
		}


		//assign some graph
		if(!this.selected)
		{
			var graphs = editor.space.findComponents( RoomComponents.GraphComponent ).map(g=>({ title: g.entity.name, entity: g.entity, graph: g}));
			if(graphs.length)
			{
				this.selected = graphs[0].graph;
			}
		}

		this._must_offset = true;
		this.editor.lowpanel_visible = true;
	},

	onClose: function()
	{
		if (this.root)
			this.root.style.display = "none";
		this.editor.lowpanel_visible = false;
	},

    showGraph: function( graph_component )
    {
        this.selected = graph_component;
    },

	render: function()
	{
        var editor = this.editor;
		var w = this.area[2] = gl.canvas.width - editor.sidewidth;
		var h = this.area[3] = this.editor.lowheight;
		var x = this.area[0] = editor.sidewidth;
		var y = this.area[1] = gl.canvas.height - h;
		var ctx = gl;
		var top_margin = 40;
		var space = xyz.space;
		var that = this;

		var on_edge = Math.abs( GUI.mouse.position[1] - y ) < 4;
		if (on_edge)
			GUI.cursor = "ns-resize";

		if (this._must_offset)
		{
			vec2.copy( this.graphcanvas.ds.offset, [ this.area[0],this.area[1] ] );
			this._must_offset = false;
		}

		if (GUI.Panel( x, y, w,h, null, true, 0 ) === false )
		{
			if (this.root)
				this.root.style.display = "none";
			return false;
		}

        var graph = null;
		if ( this.selected && this.selected.entity && this.selected.entity._space )
			graph = this.selected;

		GUI.DropArea(x,y,w,h,this.onDropItem.bind(this));

		if (this.root) //canvas2d
		{
			this.root.style.left = x + "px";
			this.root.style.top = (y + top_margin) + "px";
			this.root.style.width = w;
			this.root.style.height = h - top_margin;
			if (this.canvas.width !== w || this.canvas.height !== (h - top_margin) )
			{
				this.canvas.width = w;
				this.canvas.height = h - top_margin;
				this.graphcanvas.setDirty(true,true);
			}

			this.graphcanvas.setGraph( graph ? graph._graph : null );
			this.graphcanvas.draw();
		}
		else //webgl
		{
			if( Button.call(GUI, x+5,y+6,30,top_margin-12, GLUI.icons.plus ) )
			{
				this.addGraphToSelection();
			}
			Label.call(GUI, x+45,y+6,100,top_margin-12, "Graph" );
			if( Button.call(GUI, x+150,y+6,200,top_margin-12, graph ? graph.entity.name : "" ) )
			{
				var graphs = space.findComponents( RoomComponents.GraphComponent ).map(g=>({ title: g.entity.name, entity: g.entity, graph: g}));

				GUI.ShowContextMenu(graphs,
					{
						callback: function (v, item) {
							that.editor.setSelection(item.entity);
							that.selected = item.graph;
						},
						id: "graphs_context_menu"
					});
			}

			if( Button.call(GUI, x+380,y+6,100,top_margin-12, "Detach" ) )
			{
				this.openGraphInWindow( graph );
			}

			this.graphcanvas.setGraph( graph ? graph._graph : null );
			this.graphcanvas.viewport = [ x,y+top_margin,w,h-top_margin ];
			ctx.save();
			ctx.beginPath();
			ctx.rect(x,y+top_margin,w,h-top_margin);
			ctx.clip();
			this.graphcanvas.setDirty(true,true);
			this.graphcanvas.draw();
			ctx.restore();
			return;
		}

	},

    showGraphNodeInspector: function(x,y,w,h)
    {
        if (!this.graphcanvas)
    		return y;

        var ctx = window.gl;
        var starty = y;

    	var selected_nodes = Object.values( this.graphcanvas.selected_nodes );
    	if (selected_nodes.length !== 1)
	    	return;

        ctx.fillColor = [ 0.2,0.2,0.25,1 ];
        ctx.beginPath();
        ctx.roundRect(x,y,w,h-(y-starty)-20,[ 4 ]);
        ctx.fill();

        var node = selected_nodes[0];
        this.renderNodeInspector(x+10,y+10,w-20,h-(y-starty)-30,node);
    },

    renderNodeInspector: function( x,y,w,h, node )
    {
        var starty = y;
        //visualize the selected node
        Label.call(GUI,x,y,w,20, "Title");
        GUI.TextField(x + 80,y,w-80,20, node.title, 4, false );
        y+= 24;
        Label.call(GUI,x,y,w,16, "Type");
        Label.call(GUI,x + 80,y,w-80,16, node.type, [ 1,1,1,0.5 ] );
        y+= 40;
    
        for (var i in node.properties)
        {
            var propname = i;
            var value = node.properties[i];
    
            Label.call(GUI,x,y,w,20, propname);
    
            if ( value != null )
            {
                var type = value.constructor;
                var new_value = null;
    
                var widget_info = node.constructor["@" + i ];
                if ( widget_info && (widget_info.type || widget_info.widget) )
                    type = widget_info.type;
    
                switch (type)
                {
                case "string":
                case String: new_value = GUI.TextField(x + 80,y,w-80,20, value, 4, true ); break;
                case "boolean":
                case Boolean: new_value = GUI.Toggle(x + 80,y,w-80,20, null, value ); break;
                case "number":
                case Number: new_value = GUI.Number(x + 80,y,w-80,20, value ); break;
                case "vec2":
                case "vec3":
                case "vec4":
                    GUI.Vector(x + 80,y,w-80,20, value );
                    break;
                case "enum":
                    if (widget_info && widget_info.values)
                    {
                        var values = widget_info.values;
                        if ( values.constructor === Function )
                            values = values(component);
                        var index =	values.constructor === Array ? values.indexOf( value ) : values[i];
                        var gui_values = values;
                        if(values.constructor === Object)
                        {
                            gui_values = Object.keys(values);
                            index = findIndexForCombo(values,gui_values,value);
                        }
                        var r = GUI.ComboLine( x + 80, y, w - 80, 20, index, gui_values, i, widget_info.labels );
                        if(values.constructor === Object)
                            r = gui_values[r];
    
                        if (r != null && r !== -1 )
                            new_value = values[r];
                    }
                    break;
                case Array: GUI.Vector(x + 80,y,w-80,20, value ); break;
                }
    
                if(value != new_value && new_value != null)
                    node.setProperty(propname,new_value);
            }
    
            y+= 24;
        }
    
        if ( node.onExtraInspector )
            y = node.onExtraInspector( x, y, w );
    
        if ( Button.call(GUI, x,starty + h - 30,w,20, "Delete", false, [ 0.8,0.3,0.3,1 ],[ 1,1,1,1 ] ) )
        {
            node.graph.remove( node );
            GraphEditorDialog.graphcanvas.deselectAllNodes();
        }
    
        function findIndexForCombo(obj, keys, value){
            for(var i=0;i < keys.length;++i)
                if(obj[keys[i]] == value)
                    return i;
            return -1;
          }	
    },

	onKeyDown: function(e)
	{
		return !this.graphcanvas.processKey(e); //negate because it returns false when block
	},

	//called from
	onMouse: function(e)
	{
		var y = (gl.canvas.height - this.editor.lowheight);
		var on_edge = Math.abs( e.mousey - y ) < 4;

		if (e.type === "mousedown" )
		{
			if ( on_edge )
			{
				this.dragging_edge = true;
				this.capture_mouse = true;
			}
			else
				return this.graphcanvas.processMouseDown(e);
		}
		else if (e.type === "mousemove" )
		{
			if ( this.dragging_edge )
			{
				//console.debug("draggin edge");
				this.editor.lowheight -= e.deltay;
			}
			else
				return this.graphcanvas.processMouseMove(e);
		}
		else if (e.type === "mouseup" )
		{
			this.capture_mouse = false;
			this.dragging_edge = false;
			return this.graphcanvas.processMouseUp(e);
		}
		else if (e.type === "wheel" )
			return this.graphcanvas.processMouseWheel(e);
	},

	onShowNodePanel: function(node)
	{
		console.log("show node panel");
		this.editor.setSelection( this.selected );
		this.editor.mode = this.editor.constructor.PROPERTIES_MODE;
	},

	onDropItem: function(e)
	{
		var graphcanvas = null;
		var graph = null;

		var doc = e.target.ownerDocument;
		var win = doc.defaultView || doc.parentWindow;
		if (win.litegraph_graphcanvas)
		{
			graphcanvas = win.litegraph_graphcanvas;
			graph = graphcanvas.graph;
		}
		else if ( this.selected && this.selected._graph)
		{
			graphcanvas = this.graphcanvas;
			graph = this.selected._graph;
		}
		if (!graph)
		{
			console.warn("no graph");
			return;
		}

		//adjust
		if ( e.mousex == null )
		{
			e.mousex = e.offsetX;
			e.mousey = e.offsetY;
		}

		var x = e.mousex;
		var y = e.mousey;

		var item_type = e.dataTransfer.getData("type");
		if (!item_type)
		{
			console.warn("no type found in dropped element");
			return;
		}

		var callback = RoomComponents.GraphComponent.item_drop_types[ item_type ];
		if ( !callback )
		{
			console.warn("no callback for type: ", item_type );
			return false;
		}

		//in case is a node
		var graphnode = callback( e, graph );
		if (!graph || !graphnode)
			return false;

		//position node
		var s = Math.floor(LiteGraph.NODE_TITLE_HEIGHT * 0.5);

		//convert to world space
		var pos = [ x - s, y + s ];
		var wpos = graphcanvas.ds.convertCanvasToOffset(pos);

		graphnode.pos[0] = wpos[0];
		graphnode.pos[1] = wpos[1];

		//get active graph
		graph.add( graphnode );
		graphnode.onExecute();
		if (graphnode.getTitle) //refresh title
			graphnode.getTitle();
		return true;
	},

    openGraphInWindow: function( graph )
    {
        if (this._dialog_window)
        {
            this._dialog_window.focus();
            return;
        }
    
        var that = this;
        var title = "Graph";
    
        var dialog_window = window.open("_blank","","width=800, height=600, location=no, status=no, menubar=no, titlebar=no, fullscreen=yes");
        dialog_window.document.write( "<head><title>"+title+"</title>" );
        this._dialog_window = dialog_window;
    
        var styles = document.querySelectorAll("link[rel='stylesheet'],style");
        for (var i = 0; i < styles.length; i++)
            dialog_window.document.write( styles[i].outerHTML );
        dialog_window.document.write( "</head><body></body>" );
        dialog_window.document.close();
    
        if (!ROOM.windows)
            ROOM.windows = [];
        ROOM.windows.push(dialog_window);
    
        var canvas = document.createElement("canvas");
        canvas.width = 800;
        canvas.height = 800;
        var graphcanvas = new LiteGraph.LGraphCanvas( canvas, graph._graph, { autoresize:true } );
        graphcanvas.onDropItem = GraphEditorDialog.onDropItem.bind(GraphEditorDialog);
        dialog_window.litegraph_graphcanvas = graphcanvas;
    
        dialog_window.document.body.appendChild( graphcanvas.canvas );
        dialog_window.document.body.style.backgroundColor = "#222";
    
        //closing event
        dialog_window.onbeforeunload = function() {
            var index = ROOM.windows.indexOf( dialog_window );
            if (index !== -1)
                ROOM.windows.splice( index, 1 );
            //if(on_close)
            //	on_close();
            that._dialog_window = null;
        }
    
        return dialog_window;
    },
	
	addGraphToSelection: function()
	{
		var item = this.editor.selected_item;
		if(!item)
			return;
		var graphcomp = new ROOM.Components.GraphComponent();
		item.addComponent(graphcomp);
		this.editor.setSelection(graphcomp);
		this.showGraph(graphcomp,true);
	}
};

export default GraphEditorDialog;