import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { quat } from "gl-matrix";

function FaceTo()
{
	this.enabled = true;
	this.mode = "spherical";
	this.smooth = true;

	this._temp = quat.create();
}

FaceTo.componentName = "FaceTo";

FaceTo.icon = [ 3,6 ];
FaceTo.type = ROOM_TYPES.MEDIA;

FaceTo["@mode"] = { type:"enum", values: [ "spherical","cylindrical" ] };

FaceTo.prototype.preRender = function( view )
{
	if (!this.enabled)
		return;

	var node = this.entity.node;
	var camera = view._last_camera;

	var prev = quat.copy( this._temp, node.rotation );
	node.orientTo( camera.position, true, [ 0,1,0 ], false, this.mode === "cylindrical" );
	if ( this.smooth )
	{
		quat.slerp( node._rotation, node._rotation, prev, 0.9 );
		node._must_update_matrix = true;
	}
}

export default FaceTo;
