import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { GL } from "@src/libs/litegl";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { RD } from "@src/libs/rendeer";
import Track from "@src/libs/rendeer/animationTrack"; //used to define stuff
import { vec3 } from "gl-matrix";

import PathEditorTool from "./Path/PathEditorTool";

/** Path
* Used to store splines
* @class Path
*/
function Path()
{
	this.points = []; //array of objects! { pos: [x,y,z], ... }
	this.closed = false;
	this.type = Path.BEZIER;

	this._must_update = false;
	this._marker = 0;
}

Path.componentName = "Path";
Path.LINEAR = 0;
Path.HERMITE = 1;
Path.BEZIER = 2;
Path.componentName = "Path";

Path.prototype.clear = function()
{
	this.points.length = 0;
	this._must_update = true;
}

//points stored are cloned
Path.prototype.addPoint = function(p)
{
	var pos = vec3.create();
	pos[0] = p[0];
	pos[1] = p[1];
	if (p.length > 2)
		pos[2] = p[2];
	this.points.push( { pos: pos } );
	this._must_update = true;
}

Path.prototype.getSegments = function()
{
	var l = this.points.length;

	switch (this.type)
	{
	case Path.LINEAR:
		if (l < 2)
			return 0;
		return l - 1 + (this.closed ? 1 : 0);
		break;
	case Path.HERMITE:
		if (l < 2)
			return 0;
		return l - 1 + (this.closed ? 1 : 0);
	case Path.BEZIER:
		if (l < 3)
			return 0;
		return (((l-1)/3)|0) + (this.closed ? 1 : 0);
		break;
	}
	return 0;
}

Path.prototype.movePoint = function( index, pos, preserve_tangents )
{
	if (index < 0 && index >= this.points.length)
		return;

	var point_info = this.points[ index ];
	var p = point_info.pos;
	var total_diff = vec3.sub( vec3.create(), pos, p );
	vec3.copy(p, pos);

	if (!preserve_tangents || this.type !== Path.BEZIER )
		return;

	if (index % 3 === 2 && this.points.length > index + 2 )
	{
		var middle_pos = this.points[index + 1].pos;
		var next_pos = this.points[index + 2].pos;
		var diff = vec3.sub( vec3.create(), middle_pos, p );
		vec3.add( next_pos, middle_pos, diff );
	}
	else if (index % 3 === 1 && index > 3 )
	{
		var middle_pos = this.points[index - 1].pos;
		var prev_pos = this.points[index - 2].pos;
		var diff = vec3.sub( vec3.create(), middle_pos, p );
		vec3.add( prev_pos, middle_pos, diff );
	}
	else if (index % 3 === 0 )
	{
		if ( index > 1 )
		{
			var prev_pos = this.points[index - 1].pos;
			vec3.add( prev_pos, prev_pos, total_diff );
		}
		if ( index < this.points.length - 1 )
		{
			var next_pos = this.points[index + 1].pos;
			vec3.add( next_pos, next_pos, total_diff );
		}
	}

	this._must_update = true;
}

//f goes from 0 to 1
Path.prototype.computePoint = function(f, out, to_num_segments )
{
	if ( to_num_segments)
	{
		var num = this.getSegments();
		if ( num > 0)
			f /= num;
		else
		{
			out = out || vec3.create();
			return out;
		}
	}

	switch (this.type)
	{
	case Path.HERMITE: return this.getHermitePoint(f,out); break;
	case Path.BEZIER: return this.getBezierPoint(f,out); break;
	case Path.LINEAR:
	default:
		return this.getLinearPoint(f,out);
		break;
	}
	//throw("Impossible path type");
}

Path.prototype.computePointGlobal = function(f, out, to_num_segments )
{
	var p = this.computePoint(f,out,to_num_segments );
	return this.entity.node.localToGlobal(p);
}


Path.prototype.removePoint = function( index, remove_segment )
{
	if (this.type === Path.BEZIER )
	{
		//if keypoint
		if ((index % 3) === 0 ) //is pass point
			this.points.splice( index - 1, 3 );
		else if ((index - 1) % 3 === 0 ) //pre
			this.points.splice( index, 3 );
		else //post
			this.points.splice( index - 2, 3 );
	}
	else
		this.points.splice( index, 1 );
	this._must_update = true;
}


Path.prototype.getLinearPoint = function(f, out)
{
	out = out || vec3.create();
	var num = this.points.length;
	var l = num;
	if (l < 2)
		return out;

	if (f <= 0)
		return vec3.copy(out, this.points[0].pos);
	if (f >= 1)
	{
		if (this.closed)
			return vec3.copy(out, this.points[0].pos);
		return vec3.copy(out, this.points[l-1].pos);
	}

	if ( this.closed )
		l += 1;

	var v = ((l-1) * f);
	var i = v|0;
	var fract = v-i;
	var p = this.points[ i % num ].pos;
	var p2 = this.points[ (i+1) % num ].pos;
	return vec3.lerp(out, p, p2, fract);
}

Path.temp_vec3a = vec3.create();
Path.temp_vec3b = vec3.create();
Path.temp_vec3c = vec3.create();

Path.prototype.getBezierPoint = function(f, out)
{
	out = out || vec3.create();
	var l = this.points.length;
	if (l < 4)
		return out;
	l = (((l-1)/3)|0) * 3 + 1; //take only useful points

	if (f <= 0)
		return vec3.copy(out, this.points[0].pos);
	if (f >= 1)
		return vec3.copy(out, this.points[ this.closed ? 0 : l-1 ].pos );

	var num = (l-1)/3 + (this.closed ? 1 : 0); //num segment
	var v = num*f; //id.weight
	var i = (v|0); //id
	var t = v-i;//weight

	var i1 = (i*3);
	var i2 = (i*3+1);
	var i3 = (i*3+2);
	var i4 = (i*3+3);

	var p,p1,p2,p3;

	if (this.closed && i === num - 1 )
	{
		p = this.points[l-1].pos;
		p3 = this.points[0].pos;
		var diff = vec3.sub( Path.temp_vec3c, p, this.points[l-2].pos );
		p1 = vec3.add( Path.temp_vec3a, p, diff );
		diff = vec3.sub( Path.temp_vec3c, p3, this.points[1].pos );
		p2 = vec3.add( Path.temp_vec3b, p3, diff );
	}
	else
	{
		p = this.points[ i1 ].pos;
		p1 = this.points[ i2 ].pos;
		p2 = this.points[ i3 ].pos;
		p3 = this.points[ i4 ].pos;
	}

	var b1 = (1-t)*(1-t)*(1-t);
	var b2 = 3*t*(1-t)*(1-t);
	var b3 = 3*t*t*(1-t);
	var b4 = t*t*t;

	out[0] = p[0] * b1 + p1[0] * b2 + p2[0] * b3 + p3[0] * b4;
	out[1] = p[1] * b1 + p1[1] * b2 + p2[1] * b3 + p3[1] * b4;
	out[2] = p[2] * b1 + p1[2] * b2 + p2[2] * b3 + p3[2] * b4;
	return out;
}

Path.prototype.getHermitePoint = function(f, out)
{
	out = out || vec3.create();
	var l = this.points.length;
	if (l < 2)
		return out;
	if (f <= 0)
		return vec3.copy(out, this.points[0].pos);
	if (f >= 1)
		return vec3.copy(out, this.points[ this.closed ? 0 : l-1].pos);

	var num = (l-1) + (this.closed ? 1 : 0); //num segments
	var v = num*f; //id.weight
	var i = (v|0); //id
	var t = v-i;//weight

	var pre_p0 = this.points[i - 1].pos;
	var p0 = this.points[ i ].pos;
	var p1 = this.points[ i+1 ].pos;
	var post_p1 = this.points[ i+2 ].pos;

	if (!pre_p0)
		pre_p0 = this.closed ? this.points[l - 1].pos : p0;
	if (!p1)
		p1 = this.points[ (i+1) % l ].pos;
	if (!post_p1)
		post_p1 = this.closed ? this.points[ (i+2) % l ].pos : p1;

	RD.Animation.EvaluateHermiteSplineVector( p0, p1, pre_p0, post_p1, t, out );
	return out;
}


Path.prototype.samplePoints = function( n, out )
{
	if (n <= 0)
	{
		var segments = this.getSegments();
		if (this.type === Path.LINEAR)
			n = segments + 1;
		else
			n = segments * 20;
	}

	out = out || Array(n);
	out.length = n;

	for (var i = 0; i < n; i++)
		out[i] = this.computePoint(i/(n-1));
	return out;
}

Path.prototype.samplePointsTyped = function( n, out )
{
	if (out && out.length < (n * 3))
		n = Math.floor(out.length / 3);

	if (n <= 0)
	{
		var segments = this.getSegments();
		if (this.type === Path.LINEAR)
			n = segments + 1;
		else
			n = segments * 20;
	}

	out = out || new Float32Array( n * 3 );
	for (var i = 0; i < n; i++)
		this.computePoint(i/(n-1),out.subarray(i*3,i*3+3));
	return out;
}


Path.prototype.serialize = function(o)
{
	var points = Array( this.points.length );
	for (var i = 0; i < this.points.length; i++)
	{
		var p = this.points[i];
		var data = {};
		for (var j in p)
		{
			if ( p[j] != null && p[j].length )
				data[j] = typedArrayToArray( p[j] ); //clone arrays
			else
				data[j] = p[j];
		}
		points[i] = data;
	}

	o.points = points;
	o.type = this.type;
	o.closed = this.closed;
	return o;
}

Path.prototype.configure = function(o)
{
	this.type = o.type;
	this.closed = o.closed;

	if (o.points)
	{
		this.points.length = o.points.length;
		for (var i = 0; i < this.points.length; i++)
		{
			var p = o.points[i];
			var data = {};
			for (var j in p)
				if ( p[j] != null && p[j].length )
					data[j] = new Float32Array(p[j]);
				else
					data[j] = p[j];
			this.points[i] = data;
		}
	}
	else
		this.points.length = 0;

	this._must_update = true;
}


//used in case we want to render it
Path.prototype.updateBuffers = function()
{
	var samples_per_segment = 50;
	var segments = this.getSegments();
	var samples = segments * samples_per_segment;
	if (!this._line || this._line.length !== samples * 3 )
		this._line = new Float32Array( samples * 3 );
	this.samplePointsTyped( samples, this._line );

	var num_points = this.points.length;
	if (!this._points_data || this._points_data.length !== num_points * 3 )
		this._points_data = new Float32Array( num_points * 3 );
	for (var i = 0; i < this.points.length; ++i)
		this._points_data.set( this.points[i].pos, i*3 );
	this._must_update = false;
}

//render Gizmos for editor
Path.prototype.renderGizmo = function( view, editor, selected )
{
	if (this._must_update)
		this.updateBuffers();

	var camera = view.getCurrentCamera();

	//render all areas line
	gl.disable( gl.DEPTH_TEST );
	gl.enable( gl.BLEND );
	gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );

	//render line
	if ( this._line && this._line.length > 6 )
	{
		view.renderer.color = selected  ? [ 2,2,4,1 ] : [ 1,1,2,0.5 ];
		view.renderer.renderPoints( this._line, null, camera, this._line.length / 3, null, 1, GL.LINE_STRIP );
	}

	//in edit mode
	if (PathEditorTool.instance && PathEditorTool.instance === editor.tool )
	{
		//render control points
		if (this.points && this.points.length)
		{
			view.renderer.color = [ 2,2,0, selected ? 1 : 0.2 ];
			view.renderer.renderPoints( this._points_data, null, camera, this._points_data.length / 3, null, -5 );
		}
	}

	//auto deselect
	if (!selected && PathEditorTool.instance === editor.tool && PathEditorTool.instance.component === this )
		editor.selectTool("select"); //deselect

	if (PathEditorTool.instance)
	{
		//PathEditorTool.instance.component = this;
		PathEditorTool.instance.onRenderGizmo( view, editor, selected );
	}

	if ( selected && this.points.length )
	{
		var num_segments = this.getSegments();
		var pos = this.computePoint( this._marker, null, true );
		view.renderer.color = [ 4,2,0, selected ? 1 : 0.2 ];
		view.renderer.renderPoints( pos, null, camera, 1, null, -8 );
	}

	gl.enable( gl.DEPTH_TEST );
	gl.enable( gl.BLEND );
}

Path.prototype.onRenderInspector = function( ctx, x,y,w,h, editor )
{
	var that = this;
	var starty = y;

	Label.call(GUI,x,y,w,20, "Num. Points " + this.points.length );
	y += 24;

	Label.call(GUI,x,y,w,20, "Clear");
	if ( Button.call(GUI, x + 100,y,w - 100,20, "Clear" ) )
		this.clear();
	y += 24;

	Label.call(GUI,x,y,w,20, "Tool");
	if ( Button.call(GUI, x + 100,y,w - 100,20, PathEditorTool.instance === editor.tool ? "Stop Editing" : "Edit" ) )
	{
		if (PathEditorTool.instance === editor.tool )
			editor.selectTool("select"); //deselect
		else
			this.enableTool();
	}
	y+= 30;

	Label.call(GUI,x,y,w,20, "Mark");
	this._marker = GUI.Number( x + 100,y,w - 100,20, this._marker );
	y += 24;


	if (PathEditorTool.instance && PathEditorTool.instance === editor.tool )
		PathEditorTool.instance.onRenderInspector( ctx, x,y,w,h-(y-starty), editor );
}

Path.prototype.enableTool = function()
{
	var editor = RoomEditor.instance;
	var tool = PathEditorTool.instance;
	if (!tool)
		PathEditorTool.instance = tool = new PathEditorTool();
	tool.component = this;
	editor.selectTool( tool );
}

export default Path;

