import ROOM from "@src/engine/room";

function LGraphParticipant()
{
	this.properties = {};
	this.addOutput("participant","participant");
	this.addOutput("position","vec3");
	this.addOutput("escape",LiteGraph.EVENT);
}

LGraphParticipant.title = "Participant";
LGraphParticipant.desc = "A participant";
LGraphParticipant.highlight_color = "#CCC";

LGraphParticipant.prototype.onAdded = function(graph)
{
	var comp = graph.component;
	var ent = comp.entity;
	var space = ent._space;
	LEvent.bind( space, "escape", this.onEscape, this );
}

LGraphParticipant.prototype.onEscape = function(type, e)
{
	this.triggerSlot(2,e);
}

LGraphParticipant.prototype.onRemoved = function(graph)
{
	var comp = graph.component;
	var ent = comp.entity;
	var space = ent._space;
	LEvent.unbind( space, "escape", this.onEscape, this );
}

LGraphParticipant.prototype.onExecute = function()
{
	var space = this.graph && this.graph.space || ROOM.space;
	var participant = space.local_participant;
	if (!participant)
		return;

	this.setOutputData(0, participant);
	this.setOutputData(1, participant.position);
}

export default LGraphParticipant;

