import ROOM from "@src/engine/room";
import { getExtension } from "@src/engine/Room/getExtension";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import RoomCropModes from "@src/engine/Room/RoomCropModes";
import { GL } from "@src/libs/litegl";
import { Material } from "@src/libs/rendeer/Material";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import nextPowerOfTwo from "@src/math/nextPowerOfTwo";
import { mat3, vec3 } from "gl-matrix";

import isNumber from "lodash.isnumber";

/**
 * Stream of video/audio (webcam, video, or WebRTC)
 *
 * Streams can be used by components
 * The same stream can be used by several elements of the scene
 * It does the conversion to WebGL Texture of a Image/Video/Url
 *
 * @param [parent]
 * @param {Object} [options]
 * @constructor
 */
function RoomMediaStream(parent, options = {})
{
	this.parent = parent; //could be entity or participant or xyz
	this.id = RoomMediaStream.last_id++;

	this.upload_done_externally = false; //set to true if using job system

	this.autoplay = typeof options.autoplay === "boolean" ? options.autoplay : true;
	this.volume = isNumber(options.volume) ? options.volume : RoomMediaStream.default_volume;
	this.loop = typeof options.loop === "boolean" ? options.loop : false;
	this.current_time = isNumber(options.current_time) ? options.current_time : 0;
	this.wait_click = true; //wait to user clicking the screen to start playback of this media stream
	this.opacity = 1;
	this.force_mipmaps = false;

	this.shader_name = null; //shader to apply
	this.shader_uniforms = null; //unifors for shader

	this.texture = null;
	this.premultiply_alpha = options.premultiply_alpha || false;
	this.use_POT_texture = RoomMediaStream.default_use_POT_texture;
	this._feed = null;
	this._crop_mode = RoomCropModes.NO_CROP;
	this._has_error = false;
	this._locked = false; //if true it cannot be freed from outside
	this._original_texture_size = null;
	this._linked_stream = null; //it can point to a different mediastream in case both share same texture
	this._rectangle = null; //in case we want to display just a small area
	this._currSize = [ 1,1 ]; //LEGACY: used to know aspect ratio?

	this._framerate = options.framerate || 0;
	this._last_upload_time = 0;
	//this._max_resolution = options.max_resolution || 0; //not supported

	//used for rendering
	this.texture = null;
	this.texture_name = ":stream_texture_" + this.id;
	this.material = new Material({
		"alphaMode": "BLEND",
		"alphaCutoff":0.5,
		"color": [ 0,0,0,1 ],
		"emissive": [ 1,1,1 ],
		"textures": {
			"emissive": { texture: this.texture_name }
		},
		"flags": {
			"two_sided": true
		}
	});
	this.material.name = ":stream_" + this.id;
	StaticMaterialsTable[ this.material.name ] = this.material;

	this.mustUpdate = true;

	/**
	 *
	 * @type {boolean}
	 */
	this.needs_texture_update = false;
}

RoomMediaStream.streams = {}; //storage of all streams
RoomMediaStream.video_formats = [ "mp4","mpeg","webm" ];
RoomMediaStream.image_formats = [ "jpg","jpeg","png","webp" ];
RoomMediaStream.last_id = 0;
RoomMediaStream.default_volume = 0.3;
RoomMediaStream.block_upload_to_gpu = false;
RoomMediaStream.images_uploaded_to_gpu = 0;
RoomMediaStream.force_mipmaps = false;

RoomMediaStream.allow_deferred_upload = true;
RoomMediaStream.default_use_POT_texture = false;

Object.defineProperty( RoomMediaStream.prototype, "crop_mode", {
	set: function(v) {
		this._crop_mode = v;
		var tex_name = null;
		switch ( this._crop_mode )
		{
		case RoomCropModes.CIRCLE: tex_name = "stream_mask_circle.png"; break;
		case RoomCropModes.ROUNDED: tex_name = "stream_mask_rounded.png"; break;
		case RoomCropModes.FULLCIRCLE: tex_name = "stream_mask_circle2.png"; break;
		case RoomCropModes.GRADIENT: tex_name = "stream_mask_gradient.png"; break;
		default:
			tex_name = "stream_mask_gradient.png"; break; //HACK to force GRADIENT
		}
		this.material.textures.opacity = tex_name;
	},
	get: function()
	{
		return this._crop_mode;
	}
});

Object.defineProperty( RoomMediaStream.prototype, "paused", {
	set: function(_v) {
	},
	get: function()
	{
		return this._feed && this._feed.paused;
	}
});

Object.defineProperty( RoomMediaStream.prototype, "ready", {
	set: function(_v) {
		throw ("stream ready cannot be assigned");
	},
	get: function()
	{
		if ( this._linked_stream )
			return this._linked_stream.ready;
		return this._feed && (this._feed.width || this._feed.videoWidth);
	}
});

//retrieves a stream from the manager
RoomMediaStream.Get = function(feed)
{
	var feed_url = feed;
	if (feed.constructor === String)
		feed_url = feed;
	else if (feed.src || feed.id)
		feed_url = feed.src || feed.id;
	else
		throw ("media stream doesnt have string identifier");

	var stream = RoomMediaStream.streams[ feed_url ];
	if (stream)
		return stream;

	stream = new RoomMediaStream();
	stream.assignFeed(feed);
	stream.str_id = feed_url;
	RoomMediaStream.streams[ feed_url ] = stream;
	return stream;
}

//called from surface.update mostly
RoomMediaStream.prototype.update = function(dt, t)
{
	//to avoid updating twice a shared stream
	if (t === this._time && (!this._feed || !this._feed.paused) )
		return;

	this._time = t;

	if ( this._linked_stream )
		this._linked_stream.update(dt, t);

	//this.preRender();
}

//called from Participant or Surface
//mostly upload to GPU and update material
RoomMediaStream.prototype.preRender = function(view)
{
	if (this._feed &&
		(this._feed.constructor === HTMLVideoElement ||
		this._feed.constructor === HTMLCanvasElement) )
		this.mustUpdate = true;

	//for videos with autoplay
	if (this._feed && (!this.wait_click || ViewCore.user_clicked) && this._feed.paused && !this._feed.first_time && this.autoplay)
	{
		try
		{
			this._feed.play();
		}
		catch (err)
		{
			console.error("video play without user clicked");
		}
		this._feed.first_time = true;
		this.mustUpdate = true;
	}

	if ( this._linked_stream )
	{
		this._linked_stream.shader_name = this.shader_name;
		this._linked_stream.shader_uniforms = this.shader_uniforms;
		this._linked_stream.preRender( view );
	}
	else
	{
		if (this.mustUpdate && (this._last_frame !== view.frame || !this._last_frame) )
		{
			this._last_frame = view.frame;

			// request texture update, the actual update will happen later on else-where
			if (!this.upload_done_externally)
				this.toTexture();
			else
				this.needs_texture_update = true;
		}
	}

	var texture_name = this.getTextureName();
	this.material.textures.emissive.texture = texture_name;
	this.material.opacity = this.opacity;
}

/**
 * uploads to the GPU the video
 * called from FeedUpdateProcess, controlled by Adaptive Job System (see {@link XYZLauncher#process_scheduler})
 */
RoomMediaStream.prototype.toTexture = function()
{
	if (!this._feed || this._has_error )
		return null;

	//uploads to GPU
	try
	{
		if (this._feed.constructor === HTMLVideoElement && this._feed.complete === false) //still loading
			return null;

		if (this._feed.videoWidth || this._feed.width) //ensure there is something to show
		{
			var now = getTime();
			var framerate = this._framerate || ROOM_SETTINGS.feeds.upload_feed_rate || 0;

			var ms_between_frames = framerate ? (1000 / framerate) : 0;

			if ( ViewCore.user_clicked && //no browser restriction to play videos
				(!framerate || now > ( this._last_upload_time + ms_between_frames )) && //framerate
				(this._feed.constructor !== HTMLVideoElement ||
				 (this._feed.readyState === 4 || this._feed.readyState === 2) )) //HAVE_CURRENT_DATA, HAVE_ENOUGH_DATA
			{
				if (!RoomMediaStream.block_upload_to_gpu)
					this.uploadImageToGPU( this._feed );
			}
		}
	}
	catch (err)
	{
		console.error(err);
		console.error("Stream comes from cross domain, cannot be uploaded", this._feed );
		this._has_error = true;
	}
	this.mustUpdate = false;

	//if(this._feed.constructor === HTMLVideoElement)
	//	texture.drawTo( this.renderVideoUI.bind(this) );
}

//creates a texture with the appropiate size to store the image
RoomMediaStream.prototype.prepareTexture = function( image, texture )
{
	var width = 0;
	var height = 0;
	var isScreenShareOn = false;

	//VIDEO FEED (could be a video or a webcam)
	if (image.constructor === HTMLVideoElement)
	{
		var video = image;
		isScreenShareOn = true;
		if (!video.videoWidth && !video.width && (video.paused || video.seeking) )
		{
			width = ROOM_SETTINGS.feeds.video_width;
			height = ROOM_SETTINGS.feeds.video_height;
		}
		else
		{
			width = video.videoWidth || video.width;
			height = video.videoHeight || video.height;
		}
		if (this.texture && (this._currSize[0] !== width || this._currSize[1] !== height))
		{
			this.texture.fill([ 0,0,0,0 ]); //clear the texture at the start of frame
			this._currSize = [ width, height ];
		}

	}
	else if (image.constructor === GL.Texture)
	{
		//nothing to do
		return null;
	}
	else //if(this._feed.constructor === HTMLCanvasElement)
	{
		width = this._feed.width;
		height = this._feed.height;
	}

	if (!width || !height)
		return null;

	var width_pot = nextPowerOfTwo(width);
	var height_pot = nextPowerOfTwo(height);

	//enlarge texture to fit POT size
	if ( this.use_POT_texture && (width_pot !== width || height_pot !== height ))
	{
		if (!this._original_texture_size)
			this._original_texture_size = [ 0,0 ];
		this._original_texture_size[0] = width;
		this._original_texture_size[1] = height;
		width = width_pot;
		height = height_pot;
	}
	else
		this._original_texture_size = null;

	texture = texture || this.texture;

	if (!texture || texture.width !== width || texture.height !== height) {

		//free
		if (texture)
			texture.delete();

		texture = this.texture = new GL.Texture( width, height, {
			format: gl.RGBA,
			filter: gl.LINEAR,
			anisotropic: 8,
			ignore_pot: true, //avoids checking if POT texture (useful in webgl2)
			minFilter: this._original_texture_size || RoomMediaStream.force_mipmaps || this.force_mipmaps ? GL.LINEAR_MIPMAP_LINEAR : GL.LINEAR
		});
		texture.fill([ 0,0,0,0 ]); //empty
		texture.name = this.texture_name;
		gl.textures[ this.texture_name ] = texture;
		console.debug(" %c GL - creating MediaStream Texture: %c " + width + "x" + height + " ", "background-color: #EEE; color: #F51;", "background-color: #321; color: #AAA;" );
	}

	return texture;
}


RoomMediaStream.deferred_settings =  { imageOrientation:"flipY" };
//uploads to GPU but also applys shader if mediastream has one defined
//it can upload directly or deferred
RoomMediaStream.prototype.uploadImageToGPU = function( image, texture )
{
	var that = this;
	if (!image || (!image.width && !image.videoWidth) ) //not ready
		return null;

	//video is still loading
	if (image.constructor === HTMLVideoElement && image.readyState !== null && image.readyState !== 4)
		return null;

	//allocate space for the texture based on the image size
	texture = this.prepareTexture( image, texture );

	//if deferred, fetches the frame then uploads, otherwise just upload
	var use_deferred = image.constructor === HTMLVideoElement && RoomMediaStream.allow_deferred_upload;
	if ( !use_deferred )
	{
		upload_to_gpu_and_apply_shader( image );
	}
	else
	{
		if (!this._waiting_deferred_frame)
		{
			this._waiting_deferred_frame = true;
			// https://developer.mozilla.org/es/docs/Web/API/createImageBitmap
			if (image.requestVideoFrameCallback)
				image.requestVideoFrameCallback( on_frame_ready );
			else
				upload_to_gpu_and_apply_shader( image );
		}
	}

	return texture;

	function on_frame_ready( t, frame_info )
	{
		upload_to_gpu_and_apply_shader( image );
	}

	//this functions uploads once the data arrives, if shader is requried then it applies it
	function upload_to_gpu_and_apply_shader( image )
	{
		RoomMediaStream.images_uploaded_to_gpu++;
		that._last_upload_time = getTime();
		var no_flip = false;
		that._waiting_deferred_frame = false;
		var subimage = image.width !== texture.width || image.height !== texture.height;

		//in case of shader
		var shader = null;
		if ( that.premultiply_alpha )
			shader = gl.shaders[ "premultiply_alpha" ];
		else if ( that.shader_name )
		{
			shader = gl.shaders[ that.shader_name ];
			if ( shader && that.shader_uniforms )
				shader.uniforms( that.shader_uniforms );
		}

		if (!shader)
		{
			texture.uploadImage( image, { no_flip: no_flip, subimage: subimage } );
			return texture;
		}

		that._waiting_deferred_frame = false;
		var temp = GL.Texture.getTemporary( image.width, image.height, { format: GL.RGBA } );
		temp.uploadImage( image, { no_flip: no_flip, subimage: subimage } );
		shader.uniforms({ u_viewport: gl.viewport_data, u_time: getTime(), u_rand: vec3.fromValues(Math.random(),Math.random(),Math.random()) });
		temp.copyTo( texture, shader );
		GL.Texture.releaseTemporary( temp );
	}
}

//feed must be an image or video or url
RoomMediaStream.prototype.assignFeed = function( feed, feed_options )
{
	if (feed === this._feed)
		return;

	this._linked_stream = null;
	this._rectangle = null;

	if (feed)
	{
		this._feed_options = feed_options || {};
		this._has_error = false;
		this.mustUpdate = true;

		if (feed.constructor === RoomMediaStream) //you can pass another RoomMediaStream so same stream is used twice
		{
			this._linked_stream = feed;
		}
		else if (feed.constructor === MediaStream) //probably user webcam
		{
			//check for video stream
			const videotracks = feed.getVideoTracks();
			if (!videotracks.length) //only audio?
			{
				feed = null;
				//console.debug("Feed doesnt contain a video track");
			}
			else
			{
				var video_track = videotracks[0];
				if ( !video_track.enabled )
				{
					feed = null;
					//console.debug("VideoTrack is disabled");
				}
				else
				{
					var video = document.createElement("video");
					video.srcObject  = feed;
					video.volume = this.volume;
					video.onload = function() {
						console.debug(" - video stream assigned: " + url );
					}
					video.onerror = function(e) {
						that.has_error = true;
						console.error(" - error loading stream video: " + url, e );
					}
					feed = video;
				}
			}
		}
		else if (feed.constructor === HTMLVideoElement) //is a video
		{
			feed.autoplay = this.autoplay;
			//feed.muted = true; // to allow autoplay without issues
			this._currSize =
			[
				feed.videoWidth || feed.width,
				feed.videoHeight || feed.height
			]; // For tracking if texture clear is needed on resize of video feed
		}
		else if (feed.constructor === GL.Texture) //is a texture
		{
			//nothing to do as the surface will grab the texture name
		}
		else if (feed.constructor === String) //is a string
		{
			var ext = getExtension(feed);
			if (feed[0] === "#" || feed[0] === "_" || feed[0] === ":") //texture name
			{
				feed = gl.textures[feed];
			}
			else if (RoomMediaStream.video_formats.indexOf(ext) !== -1) //video url
			{
				var that = this;
				var url = feed;
				var video = document.createElement("video");
				video.src = url;
				video.volume = this.volume;
				video.loop = this.loop;
				video.preload = "auto";
				video.autoplay = this.autoplay;
				video.crossOrigin = ROOM.crossOrigin;
				video.currentTime = this.current_time;
				video.onload = function() {
					console.debug(" - video stream loaded: " + url );
				}
				video.onerror = function(e) {
					that.has_error = true;
					console.error(" - error loading stream video: " + url, e );
				}

				if (this.parent.room)
					this.parent.room.main_media = video;
				feed = video;
			}
			else if (RoomMediaStream.image_formats.indexOf(ext) !== -1) //image
			{
				//is image
				var image = new Image();
				image.src = feed;
				image.crossOrigin = ROOM.crossOrigin;
				var that = this;
				image.onload = function() {
					that.uploadImageToGPU(this);
				}
				feed = image;
			}
			else
				console.error("feed assigned is of an unknown type: ", feed );
		}
	}

	if ( feed == null && this._feed )
	{
		//pause video when destroying to avoid audio in the bg
		if (this._feed.constructor === HTMLVideoElement )
		{
			this._feed.pause();
			this._feed.volume = 0;
		}
	}

	this._feed = feed;
}

//deallocate space
RoomMediaStream.prototype.free = function()
{
	this.assignFeed(null);

	if (this.texture)
	{
		delete gl.textures[ this.texture.name ];
		this.texture.delete();
		this.texture = null;
	}

	delete StaticMaterialsTable[ this.material.name ];

	//in case it is a shared one
	if (this.str_id)
	{
		delete RoomMediaStream.streams[ this.str_id ];
		delete this["str_id"];
	}

	if (this.onFree)
		this.onFree();
}

RoomMediaStream.prototype.getAspect = function()
{
	if (this._feed_options.mode === "holoh" && this.texture )
		return this.texture.width / this.texture.height * 2;
	if ( this._rectangle )
		return this._rectangle[2] / this._rectangle[3];
	if (this.texture)
		return this.texture.width / this.texture.height;
	return 1;
}

RoomMediaStream.prototype.getTextureName = function()
{
	if (this._linked_stream)
		return this._linked_stream.getTextureName();

	if (!this._feed)
		return null;

	if (this._feed.constructor === GL.Texture)
		return this._feed.name || this._feed.filename || this._feed.url;

	return this.texture_name;
}

RoomMediaStream.prototype.getTexture = function()
{
	if (this._linked_stream)
		return this._linked_stream.getTexture();
	if (!this._feed)
		return null;
	if (this._feed.constructor === GL.Texture)
		return this._feed;
	return this.texture;
}

RoomMediaStream.prototype.assignRectangle = function(rect, normalized) {
	this._rectangle = rect;
	var mat = this.material;
	if (!mat.textures.emissive)
		return;

	if (!rect)
	{
		mat.textures.emissive.uv_channel = 0;
		return;
	}

	mat.textures.emissive.uv_channel = 2;
	if (!mat.uv_transform)
		mat.uv_transform = mat3.create();

	var tex = this.getTexture();
	if (!tex)
		return;

	var nx = rect[0];
	var ny = rect[1];
	var nw = rect[2];
	var nh = rect[3];

	if (!normalized)
	{
		nx = rect[0] / tex.width;
		ny = rect[1] / tex.height;
		nw = rect[2] / tex.width;
		nh = rect[3] / tex.height;
	}

	mat.uv_transform[0] = nw;
	mat.uv_transform[4] = nh;
	mat.uv_transform[6] = nx;
	mat.uv_transform[7] = 1 - (nh + ny);
}

//allows to use a webcam as stream
RoomMediaStream.prototype.openWebcam = function() {
	if (!navigator.getUserMedia) {
		//console.debug('getUserMedia() is not supported in your browser, use chrome and enable WebRTC from about://flags');
		return;
	}

	this._waiting_confirmation = true;

	// Not showing vendor prefixes.
	var constraints = {
		audio: false,
		video: {} // facingMode: this.facingMode }
	};
	navigator.mediaDevices
		.getUserMedia(constraints)
		.then(this.streamReady.bind(this))
		.catch(onFailSoHard);

	var that = this;
	function onFailSoHard(e) {
		console.debug("Webcam rejected", e);
		that._webcam_stream = false;
		RoomMediaStream.is_webcam_open = false;
	}
}

RoomMediaStream.prototype.closeStream = function() {
	if (!this._webcam_stream)
		return;

	this.texture.fill([ 1,1,1,1 ]);

	var tracks = this._webcam_stream.getTracks();
	if (tracks.length) {
		for (var i = 0; i < tracks.length; ++i) {
			tracks[i].stop();
		}
	}
	RoomMediaStream.is_webcam_open = false;
	this._webcam_stream = null;
	this._feed = null;
};

RoomMediaStream.prototype.streamReady = function(localMediaStream) {
	this._webcam_stream = localMediaStream;

	var video = this._feed;
	if (!video) {
		video = document.createElement("video");
		video.autoplay = this.autoplay;
		video.srcObject = localMediaStream;
		this._feed = video;
		//document.body.appendChild( video ); //debug
		//when video info is loaded (size and so)
		video.onloadedmetadata = function(e) {
			// Ready to go. Do some stuff.
			console.debug(e);
			RoomMediaStream.is_webcam_open = true;
		};
	}
};

RoomMediaStream.prototype.play = function()
{
	if (this._feed && this._feed.play)
		this._feed.play();
}

RoomMediaStream.prototype.pause = function()
{
	if (this._feed && this._feed.pause)
		this._feed.pause();
}

export default RoomMediaStream;
