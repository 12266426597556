import EnumOrientation from "./EnumOrintation";
// this object stores configurable variables for the system
// this object is CLONED in room.js when set
const INITIAL_ROOM_SETTINGS = {
    participants: {
        profile_offset_max: 0.1,
        profile_position: [0, 0.96, 0],
        profile_scaling: 0.666,
        aspect_modifier: 1,
        global_opacity: 1,
        draw_nametags: true,
        view_height: 1.04,
        walk_height: 1.5,
        walking_fov: 40,
        nametag_scale: 0.65,
        nametag_position: [0, -0.27, 0.05],
        brightness: 1,
        thirth_person_view_offset: [0, 1, 1.15],
        two_sided: true,
        mirror_cutout_of_myself: true,
        mirror_cutout_of_others: false,
        auto_lean_back: true,
        draw_icons: false
    },
    space: {
        no_branding: false,
    },
    feeds: {
        upload_feed_rate: 30,
        video_width: 320,
        video_height: 240,
        force_mipmaps_when_available: true
    },
    call: {
        allow_walking: true,
        allow_focus: true,
        allow_seat_change: true,
        allow_teleport: false,
        watermark: null,
        update_seats_orientation: true,
        table_facing_mode: EnumOrientation.SEAT_FACING_ORIENTATION,
        first_person_facing_mode: EnumOrientation.USER_FACING_ORIENTATION,
        table_mode: {
            intro_slide_time: 5,
            intro_angle: 90,
            momentum_factor: 0.965,
            brake_factor: 0.9
        },
        DOF: {
            allow: false,
            on_focus: false,
            on_selfview: false,
            on_table: true,
        },
        bubbles: {
            size: 170,
            margin: 30,
            mirror_myself: true,
            mirror_others: false,
            draw_icons: false
        },
    },
    audio: {
        environment_volume: 1,
    },
    surfaces: {},
    /*
    // Andrey FIXME: Nor sure what is this legacy stuff is doing here. We don't need those settings since December 2022.
    render: {
        fx: {
            DitheringEnabled: false,
            DitheringIntensity: 0.017,
            CGTemperature: 0,
            CGContrast: 1,
            CGVibrance: 1,
            CGSaturation: 1,
            Cam_Aperture: 100,

            DOFMaxCocBackground: 32,
            DOFMaxCocForeground: 32,
            DOFNativeResolution: false,
            DOFCocThreshold: 2,
            DOFBlurScale: 10
        }

    }
    */
};
export default INITIAL_ROOM_SETTINGS;
