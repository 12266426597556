export const DEFAULT = 0;
export const EXR = 1;
export const RADIANCE = 2;

export const CUBE_MAP_POSITIVE_X = 0;
export const CUBE_MAP_POSITIVE_Y = 1;
export const CUBE_MAP_POSITIVE_Z = 2;
export const CUBE_MAP_NEGATIVE_X = 3;
export const CUBE_MAP_NEGATIVE_Y = 4;
export const CUBE_MAP_NEGATIVE_Z = 5;
