import Entity from "@src/engine/entity";
import { RoomComponents } from "@src/engine/RoomComponents";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";

import EntityListPanel from "./entityList"

//Dialog displayed when users access their backlog from the editor
var SelectEntityPanel = {

	area: [ -300,-300, 500,600 ],
	selected: null,

    callback: null,

	render: function(editor)
	{
		var w = this.area[2];
		var h = this.area[3];
		var x = this.area[0] = gl.canvas.width * 0.5 - w*0.5;
		var y = this.area[1] = gl.canvas.height * 0.5 - h*0.5;
		var ctx = gl;

		if (GUI.Panel( x, y, w,h, "Select Entity", true ) === false )
			return false;

		if(!this.list)
		{
			this.list = new EntityListPanel(editor);
			this.list.onSelectedEntity = this.callback;
		}
		this.list.render(x+10,y+30,w-20,h-120);

		if ( Button.call(GUI,  20 + x, y + h - 60,120,40, "Select" ) )
		{
			return false;
		}
	},

	onWheel: function(e)
	{
		var direction = (e.wheel > 0) ? 1 : -1;
		this.list.side_scroll_target += direction * 20;
	}
};

export default SelectEntityPanel;
