import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";

function LGraphSpaceNode()
{
	this.properties = { entity_id:"", node_id: "" };
	this.addWidget("button","Capture Node",null, this.capture.bind(this) );
}

LGraphSpaceNode.title = "Space Node";
LGraphSpaceNode.desc = "Represents one node from the 3D space";

LGraphSpaceNode.prototype.capture = function()
{
	var editor = xyz.editor_controller;
	if (!editor)
		return;
	var node = editor.selected_node;
	if (!node)
		return;
	this.properties.node_id = node.name;
	if(node.getOwner)
	{
		var ent = editor.translateNativeObject(node);
		if(ent)
			this.properties.entity_id = ent.uid;
	}
}

LGraphSpaceNode.prototype.onAction = function(action)
{
}

LGraphSpaceNode.prototype.getNode = function()
{
	var ent = xyz.space.getEntityById( this.properties.entity_id );
	if(!ent)
		return null;
	var nat = ent._native;
	if(!nat)
		return null;
	var root_node = nat.getRootNode();
	if(root_node.name == this.properties.node_id)
		return root_node;
	return root_node.findNodeByName( this.properties.node_id );
}

LGraphSpaceNode.prototype.onExecute = function()
{
	var node = this.getNode();
	if (!node)
		return;

	//read inputs
	if (this.inputs) //there must be inputs always but just in case
	{
		for (var i = 0; i < this.inputs.length; ++i)
		{
			var input = this.inputs[i];
			if ( input.type === LiteGraph.ACTION || input.link == null )
				continue;
			var v = this.getInputData(i);
			if (v === undefined)
				continue;
			switch ( input.name )
			{
				case "material": node.material = v; break;
				case "position": node.position = v; break;
				case "rotation": node.rotation = v; break;
				case "scale": node.scaling = v; break;
			}
		}
	}

	//write outputs
	if (this.outputs)
	for (var i = 0; i < this.outputs.length; ++i)
	{
		var output = this.outputs[i];
		if (!output.links || !output.links.length || output.type === LiteGraph.EVENT )
			continue;
		var v = null;
		switch ( output.name )
		{
			case "name": v = node.name; break;
			case "material": v = node.material; break;
			case "position": v = node.position; break;
			case "rotation": v = node.rotation; break;
			case "scale": v = node.scaling; break;
		}
		this.setOutputData( i, v );
	}
}

LGraphSpaceNode.prototype.onDrawBackground = function(ctx)
{
	var node = this.getNode();
	if(node)
	{
		this.title = "Node: " + node.name;
		this.boxcolor = null;
	}
	else
		this.boxcolor = "orange";
}

LGraphSpaceNode.prototype.onGetInputs = function() {
	return [ ["name","string"], [ "position","vec3" ], ["rotation","quat" ],["scale","vec3" ], [ "material","material" ] ];
}

LGraphSpaceNode.prototype.onGetOutputs = function() {
	return [ ["name","string"], [ "position","vec3" ], ["rotation","quat" ],["scale","vec3" ], [ "material","material" ] ];
}

export default LGraphSpaceNode;
