import { quat, vec3 } from "gl-matrix";

import { SceneNode } from "@src/libs/rendeer/SceneNode";

function InspectObject()
{
	this.enabled = true;
	this.mode = "OBJECT_TO_USER";
	this._on_focus = false;
	this._old_pos = vec3.create();
	this._old_rot = quat.create();

	this._inspect_node = new SceneNode();
}

InspectObject.componentName = "InspectObject";
InspectObject.icon = [ 6,3 ];

InspectObject.widgets = {
	"mode":{ type:"enum", values:[ "OBJECT_TO_USER","USER_TO_OBJECT" ] }
}


InspectObject.prototype.onAdded = function(parent)
{
	LEvent.bind( parent,"clicked", this.onClicked, this );
}

InspectObject.prototype.onRemoved = function(parent)
{
	LEvent.unbind( parent,"clicked", this.onClicked, this );
}

InspectObject.prototype.serialize = function(o)
{
	o.mode = this.mode;
	o.enabled = this.enabled;
}

InspectObject.prototype.configure = function(o)
{
	this.mode = o.mode;
	this.enabled = o.enabled;
}

InspectObject.prototype.onClicked = function(e)
{
	//console.debug("clicked",this.entity.name);
	if (!this._on_focus)
		this.enterFocus();
	else //leaving
		this.exitFocus();
}

/*
InspectObject.prototype.postRender = function(view)
{
	var node = this.entity.node;
	var box = node.updateBoundingBox(false);
	view.renderer.renderBounding( box );
}
*/

InspectObject.prototype.enterFocus = function()
{
	if (this._on_focus)
		return;

	var node = this._inspect_node;
	var info = this.entity.node.serialize();
	node.configure( info );
	if (this.entity.node.children[0])
		this.entity.node.children[0].visible = false;
	node.name = "inspector";

	//this._old_pos.set( node.position );
	//this._old_rot.set( node.rotation );

	LEvent.bind( this.space, "keydown", this.onKey, this );
	LEvent.bind( this.space, "mouse", this.onGlobalMouse, this );

	//compute radius
	var original_pos = this.entity.node.getGlobalPosition();
	var bb = node.updateBoundingBox();
	var center = BBox.getCenter(bb);
	var halfsize = BBox.getHalfsize(bb);
	vec3.mul( halfsize, halfsize, node.scaling );
	var radius = vec3.length( halfsize );// / this.entity.node.scaling[0];
	console.debug(radius);
	radius *= 1.5;
	//var radius = 0.4;

	var view = ViewCore.instance;
	var camera = view.getCurrentCamera();
	var offset = vec3.fromValues( 0,0,-radius );
	var result = camera.localToGlobal( offset );

	//
	Tween.easeProperty( node, "position", result, 0.5);
	//node.position = result;

	this._on_focus = true;
	this._was_walking_allowed = this.space.settings.allow_walking;
	this.space.settings.allow_walking = false;
	this.space.scene.root.addChild( node );
}

InspectObject.prototype.exitFocus = function()
{
	if (!this._on_focus)
		return;

	var node = this._inspect_node;
	var old_node = this.entity.node;


	LEvent.unbind( this.space, "keydown", this.onKey, this );
	LEvent.unbind( this.space, "mouse", this.onGlobalMouse, this );

	Tween.easeProperty( node, "position", old_node.position, 0.3 );
	Tween.easeProperty( node, "rotation", old_node.rotation, 0.4, null, function() {
		node.parentNode.removeChild( node );
		if (old_node.children[0])
			old_node.children[0].visible = true;
	});

	this._on_focus = false;
	this.space.settings.allow_walking = this._was_walking_allowed;
}

InspectObject.prototype.onKey = function(type,e)
{
	if ( e.key == "Escape" )
	{
		this.exitFocus();
	}
	return true;
}

InspectObject.prototype.onGlobalMouse = function(type,e)
{
	//console.debug(e);
	if ( !this._on_focus )
		return;

	var speed = 0.02;

	if ( e.dragging )
	{
		//var node = this.entity.node;
		var node = this._inspect_node;
		var local_up = node.globalVectorToLocal( [ 0,1,0 ] );
		var local_right = node.globalVectorToLocal( [ 1,0,0 ] );
		node.rotate( e.deltax * speed, local_up );
		node.rotate( e.deltay * speed, local_right );
	}

	if ( e.dragging && e.type == "mousemove")
		return true;
}

//ROOM.registerComponent( InspectObject );

export default InspectObject;

