import TableTop from "@src/engine/components/tabletop";
import { getFullPath } from "@src/engine/Room/file-utils";
import { RoomComponents } from "@src/engine/RoomComponents";
import { quatFromAxisAngle } from "@src/gl-matrix/quat";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { Material } from "@src/libs/rendeer/Material";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import clamp from "@src/math/clamp";
import { vec2, vec3, vec4 } from "gl-matrix";

function Figurine()
{
	this.id = -1;
	this._manager = null;

	this.name = "";		//name to display
	this.url = null;	//glb
	this.type = Figurine.CHARACTER;
	this.position = vec3.create();	//position according to the world
	this.pos2D = vec2.create();	//in image space
	this.scale = 1;		//to adjust size
	this.base_scale = 1;
	this.color = 0; //index from Figurine.palette
	this.owner_id = null;
	this._max_height = 1; //how tall is the figure, to place icons on top

	this.flags = {
		visible: true, //can be seen
		fixed: false, //doesnt move with the table
		locked: false //cannot be interacted with
	};

	this.selected = false;	//is selected by user

	this._screenpos = vec4.create(); //fourth for size

	//internals
	this._node = new SceneNode(); //root
	this._prefab = new SceneNode(); //this holds the loaded item
	this._custom_material = null; //the colorized material is stored here

	this._tooltip = new SceneNode();
	this._tooltip.mesh = "planeXZ";
	this._tooltip.position = [ 0,0.02,0 ];
	this._tooltip.flags.visible = false;

	this._node.addChild( this._prefab );
	this._node.addChild( this._tooltip );
}

//FigurineManager.Figurine = Figurine;
//FigurineManager.icon = [ 12,6 ];

Figurine.CHARACTER = 1;
Figurine.PROP = 2;

Figurine.palette = TableTop.palette;


Figurine["@position"] = { type: "vec3" };
Figurine["@rotation"] = { type: "quat" };

//tells you where is the figurine really placed
Object.defineProperty( Figurine.prototype, "visual_position", {
	get: function() {
		return this._node.position;
	},
	set: function(v) {
		throw ("visual position cannot be assigned");
	},
	enumerable: false
});

Object.defineProperty( Figurine.prototype, "rotation", {
	get: function() {
		return this._node.rotation;
	},
	set: function(v) {
		this._node.rotation = v;
	},
	enumerable: true
});

Object.defineProperty( Figurine.prototype, "mustUpdate", {
	get: function() {
		return this._node._must_update_matrix;
	},
	set: function(v) {
		this._node._must_update_matrix = v;
	},
	enumerable: true
});

Figurine.prototype.preRender = function( view )
{
	var tabletop = this._manager.entity.tabletop;
	var pos = this.position;
	if (tabletop)
	{
		if ( !this.flags.fixed && !this._moving)
		{
			//convert pos2D to worldpos
			//...

			pos[0] = clamp( pos[0], tabletop.width * -0.5, tabletop.width * 0.5 );
			pos[2] = clamp( pos[2], tabletop.height * -0.5, tabletop.height * 0.5 );
		}
	}

	this._node.position = pos;
	//this._prefab.position = this._hover ? [0,0.02,0] : [0,0,0]; //raise
	this._node.scaling = this._manager.scale; //base size
	var scale = this.scale * this.base_scale;
	this._prefab.scaling = [ scale,scale,scale ];
	if (this._custom_material) //colorize
		vec3.scale( this._custom_material.albedo, Figurine.palette[ this.color ], 2 );
	//this._shadow.scaling = [scale*2.5,scale*2.5,scale*2.5];
}

Figurine.prototype.getBoundingBox = function()
{
	return this._prefab.updateBoundingBox();
}

Figurine.prototype.localToGlobal = function(pos,result)
{
	return this._node.localToGlobal(pos,result);
}

Figurine.prototype.updateImagePosition = function()
{
	//convert local to board and image
	var tabletop = this._manager.entity.tabletop;
	var pos = this.localToGlobal();
	tabletop.worldToImage(pos,this.pos2D);
}

Figurine.prototype.setURL = function( url )
{
	if (url === this.url)
		return;

	var that = this;
	this.url = url;

	if (url.indexOf(".glb") !== -1 || url.indexOf(".gltf") !== -1 )
	{
		this._prefab.loadGLTF( getFullPath(url), inner_load );
		return;
	}

	//WIP...
	if (url.indexOf("://") !== -1 ) //remote file
	{
		if (RoomComponents.ImportObject)
		{
			//search for the appropiate loader for this url
			var importer = RoomComponents.ImportObject.getImporter(url);
			if (!importer)
				return;
			importer.requestModelFromURL( getFullPath(url) ).then((node)=>{
				that._prefab.addChild(node);
			});
		}
	}

	function inner_load( node )
	{
		var first_node = node.children[0];
		if (!first_node)
			return;
		var mat = StaticMaterialsTable[ first_node.primitives[0].material ];
		if (!mat)
			return;
		var matname = mat.name + "_" + that.id;
		var custommat = new Material();
		custommat.configure( mat.serialize() );
		custommat.name = matname;
		custommat.register();
		first_node.primitives[0].material = matname;
		that._custom_material = custommat;
	}
}

Figurine.prototype.serialize = function(o)
{
	o.id = this.id;
	o.url = this.url;
	o.position = typedArrayToArray( this.position );
	o.scale = this.scale;
	o.base_scale = this.base_scale;
	o.type = this.type;
	o.color = this.color;
	o.owner_id = this.owner_id;
	o.pos2D = typedArrayToArray( this.pos2D );
	o.flags = Object.assign({}, this.flags);

	return o;
}

Figurine.prototype.configure = function(o)
{
	if (o.id != null)
		this.id = o.id;
	vec3.copy(this.position, o.position );
	this.scale = o.scale || 1;
	this.base_scale = o.base_scale || 1;
	this.type = o.type;
	this.color = o.color;
	this.owner_id = o.owner_id;
	if (o.pos2D)
		vec2.copy( this.pos2D, o.pos2D );
	if (o.flags)
		this.flags = Object.assign({}, o.flags);
	this.setURL( o.url );
}

Figurine.prototype.onSelect = function()
{
	if (this._manager)
		this._manager.onFigurineSelected(this);
}

Figurine.prototype.onDeselect = function()
{
	if (this._manager)
		this._manager.onFigurineSelected(null);
}

Figurine.prototype.orientTo = function( position, tween_time, skip_event )
{
	//compute new rotation
	var front = [ 0,0,1 ];
	var delta = vec3.sub( vec3.create, position, this.position );
	vec3.normalize( delta, delta );
	var axis = vec3.cross( vec3.create(), delta, front );
	vec3.normalize( axis, axis );
	var angle = -Math.acos( vec3.dot( delta, front ) );
	var rotation = quatFromAxisAngle(axis, angle);
	if ( tween_time )
		Tween.easeProperty( this, "rotation", rotation, tween_time );
	else
		this.rotation = rotation;

	if (!skip_event && this._manager)
		this._manager.syncData({
			action: "figurine",
			property: "rotation",
			figurine_id: this.id,
			rotation: typedArrayToArray(rotation)
		});
}

Figurine.prototype.move = function( position, tween_time, skip_event )
{
	if (tween_time)
		Tween.easeProperty( this, "position", position, tween_time );
	else
		this.position = position;

	var that = this;
	var tabletop = this._manager.entity.tabletop;

	this.flags.fixed = !tabletop.isInsideTable(position);

	if (!skip_event && this._manager)
		this._manager.syncData({
			action: "figurine",
			property: "position",
			figurine_id: this.id,
			value: typedArrayToArray(position)
		});
}

export default Figurine;
