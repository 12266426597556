const BGRtoRGB = function(byteArray) {
    for (
        let j = 0, l = byteArray.length, tmp = 0;
        j < l;
        j += 4 //BGR fix
    ) {
        tmp = byteArray[j];
        byteArray[j] = byteArray[j + 2];
        byteArray[j + 2] = tmp;
    }
}

export default BGRtoRGB;
