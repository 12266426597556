import Entity from "@src/engine/entity";
import { RoomComponents } from "@src/engine/RoomComponents";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";

//Dialog displayed when users access their backlog from the editor
var AddComponentPanel = {

	area: [ -300,-300, 500,600 ],
	selected: null,
	target: null,
	components: null,

	render: function(editor)
	{
		var w = this.area[2];
		var h = this.area[3];
		var x = this.area[0] = gl.canvas.width * 0.5 - w*0.5;
		var y = this.area[1] = gl.canvas.height * 0.5 - h*0.5;
		var ctx = gl;

		if (GUI.Panel( x, y, w,h, "Add Component", true ) === false )
			return false;

		var target = this.target; //editor.selected_item;
		if ( !target || target.constructor !== Entity )
			return false;

		if (!this.components)
			this.components = {};

		ctx.globalAlpha = 0.5;
		var sy = y + 50;
		var comps = Object.keys( RoomComponents ).sort();
		for (var i = 0; i < comps.length; ++i)
		{
			var even = (i % 2) === 0;
			var comp_name = comps[i];
			var sx = even ? x + 20 : x + w * 0.5;
			if ( Button.call(GUI,  sx,sy,24,24, "+", this.components[comp_name] ) )
				this.components[comp_name] = !this.components[comp_name];
			Label.call(GUI,sx+30,sy,w*0.5-40,24,comp_name);
			if ( !even )
				sy += 28;
		}
		ctx.globalAlpha = 1;

		if ( Button.call(GUI,  20 + x, y + h - 60,120,40, "Add" ) )
		{
			for (var i in this.components)
			{
				var ctor = RoomComponents[i];
				var comp = new ctor();
				target.addComponent(comp);
			}

			//focus
			editor.visible_comp_index = target._components.length - 1;

			//select
			if(target.is_root)
				target = target._space;
			editor.setSelection( target );
			this.components = null;
			return false;
		}
	}
};

export default AddComponentPanel;
