import ROOM from "@src/engine/room";

function LGraphTransform()
{
	this.properties = {
		entity_id:"",
		node_id:""
	};
	this.addOutput("position","vec3");
}

LGraphTransform.title = "Transform";
LGraphTransform.desc = "to modify position,rotation,scale";
LGraphTransform.highlight_color = "#CCC";

LGraphTransform.prototype.getEntity = function()
{
	if (!this.properties.entity_id)
		return null;
	var ent = ROOM.space.getEntityById( this.properties.entity_id );
	if (!ent)
		return null;
	return ent;
}

LGraphTransform.prototype.onDrawBackground = function(ctx)
{
	var ent = this.getEntity();
	if (!ent)
	{
		this.boxcolor = "red";
		return;
	}

	this.boxcolor = "white";

	var highlight = ent && ent._is_selected;

	if (highlight)
	{
		this.boxcolor = LGraphTransform.highlight_color;
		if (!this.flags.collapsed) //render line
		{
			ctx.fillStyle = LGraphTransform.highlight_color;
			ctx.fillRect(0,0,this.size[0],2);
		}
	}
	else
		this.boxcolor = null;

	if (ent)
	{
		var name = ent.name;
		if (name)
		{
			ctx.font = "20px Arial";
			ctx.textAlign = "left";
			ctx.fillColor = [ 1,1,1,0.75 ];
			ctx.fillText( name, 0,-40);
		}
	}
}

LGraphTransform.prototype.onExecute = function()
{
	var ent = this.getEntity();
	var target = ent;
	if( ent && this.properties.node_id )
		target = ent.findNodeByName(this.properties.node_id);

	if(this.inputs)
	for(var i = 0; i < this.inputs.length; i++)
	{
		var input = this.inputs[i];
		if(input.link == null)
			continue;

		var v = this.getInputData(i);

		if(input.name == "entity") //only as
		{
			ent = target = v;
			this.properties.entity_id = ent ? ent.uid : "";
			break;
		}

		if(!target)
			continue;

		//get prop
		var prop = null;
		switch(input.name)
		{
			case "x":
			case "y":
			case "z": prop = target.position; break;
			case "sx": 
			case "sy": 
			case "sz": prop = target.scaling; break;
		}

		if(prop)
		switch(input.name)
		{
			case "x": prop[0] = v; target.position = prop; break;
			case "y": prop[1] = v; target.position = prop; break;
			case "z": prop[2] = v; target.position = prop; break;
			case "sx": prop[0] = v; target.scaling = prop; break;
			case "sy": prop[1] = v; target.scaling = prop; break;
			case "sz": prop[2] = v; target.scaling = prop; break;
		}
		else
		switch(input.name)
		{
			case "position": target.position = v; break;
			case "scale": target.scaling = v; break;
			case "rotation": target.rotation = v; break;
		}

		//node
		if(target.$$ && target.$$.ptrType.name == "NativeNode")
		{
			//nothing to do
		}
		else //entity
			target.mustUpdate = true;
		//ent.syncNative();
	}

	if(target && this.outputs)
	for(var i = 0; i < this.outputs.length; i++)
	{
		var v = null;
		var output = this.outputs[i];
		if(!output.links || !output.links.length)
			continue;
		switch(output.name)
		{
			case "x": v = target.position[0]; break;
			case "y": v = target.position[1]; break;
			case "z": v = target.position[2]; break;
			case "sx": v = target.scaling[0]; break;
			case "sy": v = target.scaling[1]; break;
			case "sz": v = target.scaling[2]; break;
			case "position": v = target.position; break;
			case "rotation": v = target.rotation; break;
			case "scale": v = target.scaling; break;
		}

		this.setOutputData(i,v);
	}

}

LGraphTransform.prototype.onGetInputs = function()
{
	return [
		["entity","Entity" ],		
		["x","number" ],
		["y","number" ],
		["z","number" ],
		["position","vec3" ],
		["rotation","quat" ],
		["sx","number" ],
		["sy","number" ],
		["sz","number" ],
		["scale","vec3" ]
	];
}

LGraphTransform.prototype.onGetOutputs = function()
{
	return [
		["x","number" ],
		["y","number" ],
		["z","number" ],
		["position","vec3" ],
		["rotation","quat" ],
		["scale","vec3" ]
	];
}


export default LGraphTransform;

