import clamp from "clamp";
import deg2rad from "deg2rad";
import * as glMatrix from "gl-matrix";
import lerp from "lerp";
import rad2deg from "rad2deg";
import { BubblesManager } from "@src/controllers/components/bubblesManager";
import { ComponentContainer } from "@src/engine/componentContainer";
//import TCameraComponent from "@src/engine/components/camera";
import Seat from "@src/engine/components/seat";
import Entity from "@src/engine/entity";
import { Inventory } from "@src/engine/helpers/inventory";
import RoomMediaStream from "@src/engine/helpers/mediaStream";
import ROOM from "@src/engine/room";
import { ROOM_LAYERS } from "@src/engine/Room/ROOM_LAYERS";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import EnumOrientation from "@src/EnumOrintation";
import { quatFromAxisAngle, quatFromEuler, quatToEuler } from "@src/gl-matrix/quat";
import { vec3CartesianToPolar } from "@src/gl-matrix/vec3";
import { LEvent } from "@src/libs/LEvent";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { Camera } from "@src/libs/rendeer/Camera";
import { ClipType } from "@src/libs/rendeer/ClipType";
import { Direction3Constants } from "@src/libs/rendeer/Direction3Constants";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { getTime } from "@utils/time/getTime";
import { EnumParticipantFlags } from "./EnumParticiapntFlags";
import { EnumParticipantStatus } from "./EnumParticiapntStatus";
/**
 * @description: This class represents every person in the ROOM
 * All the visual representation code is in ROOM.Avatar
 */
export class RoomParticipant extends ComponentContainer {
    constructor(id, user_info, xyz) {
        super();
        this.id = id;
        this.xyz = xyz;
        this._user = null; //it must be assigned through the setter
        this._track_entity = null;
        this.room = null;
        // user data
        this.profile_picture_url = null;
        this.is_founder = false;
        this.is_local_participant = false; //if is the participant of the user in this computer
        // state
        this.status = EnumParticipantStatus.ONLINE;
        this._visible = true; //if it should display its bubble
        this.was_visible = false; //previous frame
        this.is_inside_view = false;
        this.walking = false;
        this.bad_connection = false; //tells if user has bad connection
        this.jumping = false; //rename to is_jumping
        this.is_audio_muted = false;
        this.is_video_muted = false;
        this.is_on_hold = false;
        this.loudness = 0; //changes when sound comes from this person
        this.smooth_loudness = 0;
        this.fade_factor = 1; //used to fade in/out when entering
        //Where is looking forward too
        //yaw 0 is front=-Z (yaw is applied to root node, while pitch only to camera view)
        this.orientation = [0, 0, 60]; //yaw, pitch, fov in degrees
        this.head_offset = 0; //for thirth person view
        //used to teleport...
        this.velocity_y = 0;
        this.ground_y = 0;
        this.ready_to_teleport = false;
        //user controlled: used in avatar to pan video feed
        this.face_offset = [0, 0];
        this.face_scale = 1;
        this.face_brightness = 1;
        this.profile_scaling = 0; // additional scaling
        //internally used by cutout
        this._internal_offset = [0, 0];
        this._internal_scale = 1;
        /**
         * Used for head tracking
         * In degrees
         */
        this._internal_yaw = 0;
        /**
         * In degrees
         */
        this._internal_pitch = 0;
        //where is the user bubble located in the screen
        this._bubble = [0, -200, 100, 0]; //where to show the bubble with its stream
        this.cursor_position = [0, 0, 0]; //used on flat surfaces
        //in which entity is seating
        this.seat = null; //contains the Seat component, not the entity
        this.last_seat = null; //last seat before walking
        this.force_self_render = false;
        this.force_self_render_one_frame = false; //one frame only
        this.mouse_hover = false;
        this.feed_info = null;
        //create representation
        //this.node contains the yaw
        this._profile_node = null; //it has scale and offsets, contains the texture of the stream
        this._selectable_node = null;
        this.focus_item = null; //tells you to which object is focusing the camera
        this.init_yaw = 0;
        this.target_yaw = 0;
        this.target_pitch = 0;
        this._distance_to_camera = 0;
        this._projected_radius = 0; //tells you how much screen space it takes
        this._distance = 0;
        //generated from BubbleManager
        this.getBubbleTexture = function (force_update) {
            if (force_update)
                this._force_bubble_update = true;
            return this._bubble_texture;
        };
        this.index = RoomParticipant.last_index++;
        this.name = `User${this.index}`;
        this.layers = ROOM_LAYERS.PARTICIPANTS;
        if (user_info) {
            this.user = user_info;
        }
        //data useful, should be in avatar?
        this.profile_position = glMatrix.vec3.create(); //center of head in world space
        this.screen_position = glMatrix.vec4.create(); //center of head in screen space
        //everything in the 3D scene related to this user should be below this one
        this.user_node = new SceneNode();
        this.user_node.flags.no_transform = true;
        this.user_node.name = `participant_usernode_${this.index}`;
        //root node used to attach objects
        //this is the node that rotates and moves when walking around
        this.node = new SceneNode();
        this.node.participant = this;
        this.node.room_entity = this;
        this.node.name = `participant_${this.index}`;
        this.user_node.addChild(this.node);
        //create visual representation of user
        this.avatar = xyz.createAvatar(this);
        this.inventory = new Inventory(this); //WIP
        //used to interpolate over time
        this._target_position = glMatrix.vec3.create();
        this.leaning_offset = glMatrix.vec3.create();
        this.highlight_time = getTime();
        this._volume = RoomMediaStream.default_volume;
    }
    getFont() {
        return this.xyz.options.fontFamily;
    }
    /**
     * @deprecated
     * @param _v
     * Please use XYZLauncher.setChromaToAll
     * @see XYZLauncher.setChromaToAll
     */
    static setChromaToAll(_v) {
        console.warn("Participant.setChromaToAll DEPRECATED, moved to xyz.setChromaToAll");
    }
    get user() {
        return this._user;
    }
    set user(user_info) {
        if (this._user == user_info)
            return;
        this._user = user_info;
        if (!this._user)
            return;
        this.is_founder = user_info.isFounder;
        let avatar_url = user_info.profile_picture || user_info.avURL || null;
        if (avatar_url) {
            //append special host address
            avatar_url = this.getProfilePictureAbsoluteURL(avatar_url);
        }
        this.profile_picture_url = avatar_url;
        this.name = user_info.name || user_info.fullName;
        if (user_info.is_demo) {
            this.is_demo = true;
        }
    }
    set yaw(v) {
        this.orientation[0] = v;
    }
    get yaw() {
        return this.orientation[0];
    }
    set pitch(v) {
        this.orientation[1] = v;
    }
    get pitch() {
        return this.orientation[1];
    }
    //NOT USED AFAIK
    set fov(v) {
        this.orientation[2] = v;
    }
    get fov() {
        return this.orientation[2];
    }
    set position(v) {
        this.node.position = v;
    }
    get position() {
        return this.node.position;
    }
    set target_position(v) {
        glMatrix.vec3.set(this._target_position, v[0], v[1], v[2]);
    }
    get target_position() {
        return this._target_position;
    }
    set visible(isVisible) {
        if (this._visible == isVisible)
            return;
        this._visible = isVisible;
        this.preRender(this.xyz.view); //update all visible elements
    }
    get visible() {
        return this._visible;
    }
    /**
     * @Deprecated
     * @param item
     */
    viewSurface(item) {
        return this.focusOn(item);
    }
    //returns the node to which the camera should focus when clicking
    getProfileFocusNode() {
        if (this.seat)
            return this.seat.focus_node;
        return this.avatar.feed_node;
    }
    getProfilePictureAbsoluteURL(url) {
        return location.origin + "/api/" + url;
    }
    preRender(view) {
        this.node.layers = this.layers;
        const camera = view.camera;
        this.avatar.mirrored =
            (ROOM_SETTINGS.participants.mirror_cutout_of_myself &&
                this.is_local_participant) ||
                (ROOM_SETTINGS.participants.mirror_cutout_of_others &&
                    !this.is_local_participant);
        //updates user representation based on settings
        this.avatar.preRender(view);
        //orient towards camera
        this.updateAvatarOrientation(camera);
        //tells where the bubble should be, computes distance to camera
        this.updateScreenPosition(camera);
        //in case of components
        LEvent.trigger(this, "preRender", view);
        //check if it was visible from the camera in the last frame
        //we need this to know if we must upload the texture to the GPU to save resources
        const profile_node = this.avatar.feed_node;
        const profile_pos = profile_node.getGlobalPosition();
        this.is_inside_view = camera.testSphere(profile_pos, 0.25) != ClipType.Outside;
        let facing_camera = glMatrix.vec3.dot(camera.getFront(), profile_node.getGlobalVector(Direction3Constants.FRONT)) > 0;
        if (this.avatar.material.flags.two_sided) {
            facing_camera = true;
        }
        this.was_visible = profile_node.visible && this.is_inside_view && facing_camera;
        //update seat rotation
        this.updateSeatOrientation();
        this.updateNative();
        RoomParticipant.marker_offset = 0;
    }
    //render markers and stuff
    postRender(_view) {
        this.mouse_hover = false; //it is assigned per frame
    }
    /**
     * Synchronized texture handles with native both for feeds and labels
     */
    updateNative() {
        if (!this.xyz.nativeEngine)
            return;
        if (!this.avatar.feed_node) {
            return;
        }
        const profile_node = this.avatar.feed_node;
        if (profile_node._native) {
            const np = profile_node._native;
            np.visible = profile_node.visible;
            if (profile_node.visible) {
                np.material = profile_node.material;
                np.setGlobalTransform(profile_node.getGlobalMatrix());
                const material = StaticMaterialsTable[profile_node.material];
                if (material) {
                    const texture_mat = material.textures["emissive"];
                    if (texture_mat) {
                        const name = texture_mat.texture || texture_mat;
                        const textureEmissive = window.gl.textures[name];
                        if (textureEmissive) {
                            this.xyz.nativeEngine.setNodeEmissiveTexture(np, textureEmissive);
                            if (material._color && material.alphaMode)
                                np.setBlend(material.alphaMode, material._color[3], true);
                            np.setEmissiveFactor(material.emissive[0], material.emissive[1], material.emissive[2]);
                            // Andrey FIXME: I hope missing texture means white ghost?
                            if (this.avatar && !this.avatar._texture)
                                np.setDepthAlphaThreshold(0.1);
                            const depth_test = material.flags.depth_test !== undefined
                                ? Boolean(material.flags.depth_test)
                                : true;
                            np.setDepthTest(depth_test);
                            const two_sided = material.flags.two_sided !== undefined
                                ? Boolean(material.flags.two_sided)
                                : false;
                            np.setTwoSided(two_sided);
                            np.setChromaKey(false); //disabled as the texture has already alpha
                            if (material.uv_transform && texture_mat.uv_channel === 2) {
                                np.setEmissiveTransform(material.uv_transform, false);
                            }
                        }
                    }
                }
            }
        }
        const info_node = this.avatar.nametag_node;
        if (info_node && info_node._native) {
            const ni = info_node._native;
            ni.visible = info_node.visible;
            if (info_node.visible) {
                ni.material = info_node.material;
                ni.setGlobalTransform(info_node.getGlobalMatrix());
                const material = StaticMaterialsTable[info_node.material];
                if (material) {
                    const texture_mat = material.textures["emissive"];
                    if (texture_mat) {
                        const name = texture_mat.texture || texture_mat;
                        const textureEmissive = window.gl.textures[name];
                        if (textureEmissive) {
                            this.xyz.nativeEngine.setNodeEmissiveTexture(ni, textureEmissive);
                            if (material._color && material.alphaMode)
                                ni.setBlend(material.alphaMode, material._color[3], true);
                            ni.setEmissiveFactor(material.emissive[0], material.emissive[1], material.emissive[2]);
                            const depth_test = material.flags.depth_test !== undefined
                                ? Boolean(material.flags.depth_test)
                                : true;
                            ni.setDepthTest(depth_test);
                            const two_sided = material.flags.two_sided !== undefined
                                ? Boolean(material.flags.two_sided)
                                : false;
                            ni.setTwoSided(two_sided);
                        }
                    }
                }
            }
        }
        const walk_node = this.avatar._base_mesh._native;
        if (walk_node) {
            if (this.walking) {
                walk_node.setGlobalTransform(this.avatar.node.getGlobalMatrix());
            }
            else {
                const location = this.space.root.node.getGlobalMatrix();
                walk_node.setGlobalTransform(location);
            }
        }
    }
    //returns the feed texture, or the profile picture, or null
    getProfileTexture() {
        let texture = this.avatar.getFeedTexture();
        if (!texture && this.profile_picture_url) {
            texture = ROOM.view.loadTexture(this.profile_picture_url);
        }
        return texture;
    }
    updateScreenPosition(camera) {
        //compute useful information
        const profile_node = this.avatar.feed_node;
        profile_node.getGlobalPosition(this.profile_position);
        this._distance_to_camera = glMatrix.vec3.distance(camera.position, this.profile_position);
        camera.project(this.profile_position, null, this.screen_position);
        this.screen_position[1] = window.gl.canvas.height - this.screen_position[1]; //flip Y
        this.screen_position[3] = camera.computeProjectedRadius(this.profile_position, 0.25);
        //based on aspect ratio
        if (window.gl.canvas.width > window.gl.canvas.height)
            this._projected_radius = this.screen_position[3] / window.gl.canvas.height;
        else
            this._projected_radius = this.screen_position[2] / window.gl.canvas.width;
        //fix bad projection cases
        const left_edge = -300;
        const right_edge = window.gl.canvas.width + 300;
        this.screen_position[0] = clamp(this.screen_position[0], left_edge, right_edge);
        if (this.screen_position[2] > 1) {
            //behind the camera
            this._projected_radius = 0;
            //split them in both sides
            const plane = window.geo.createPlane(camera.position, camera._right);
            const dist = window.geo.distancePointToPlane(this.profile_position, plane);
            this.screen_position[0] = dist < 0 ? left_edge : right_edge;
            if (this._bubble &&
                !this.xyz.space.local_participant.focus_item &&
                this.xyz.call_controller.bubbles_manager.mode != BubblesManager.TOP_MODE &&
                !this.xyz.call_controller.enable_flatcall) {
                //remove anim to avoid passing from side to side
                if ((this.screen_position[0] == left_edge &&
                    this._bubble[0] > window.gl.canvas.width * 0.5) ||
                    (this.screen_position[0] == right_edge &&
                        this._bubble[0] < window.gl.canvas.width * 0.5))
                    this._bubble[0] = this.screen_position[0]; //jump directly
            }
        }
    }
    //called from this.preRender
    updateSeatOrientation(reset) {
        const must_orient_seat = this.seat &&
            ROOM_SETTINGS.call.update_seats_orientation &&
            this.seat.rotating_seat;
        if (!must_orient_seat || !this.seat || reset) {
            if (this.last_seat && this.last_seat._original_orientation) {
                this.last_seat._target_node.rotation = this.last_seat._original_orientation;
                this.last_seat._original_orientation = null;
            }
            return;
        }
        const proxy_name = this.seat.proxy_node;
        if (!proxy_name || !this.seat._target_node)
            return;
        const seat_node = this.seat._target_node;
        const profile_node = this.avatar.pivot_node;
        //check in case feature not available
        if (!seat_node.rotation)
            return;
        //save
        if (!this.seat._original_orientation)
            this.seat._original_orientation = glMatrix.quat.clone(seat_node.rotation);
        //avoid problems
        const r = quatToEuler(glMatrix.vec3.create(), seat_node.rotation);
        const r2 = quatToEuler(glMatrix.vec3.create(), profile_node.getGlobalRotation());
        r[0] = r2[0] - Math.PI * 0.5;
        const result = quatFromEuler(glMatrix.quat.create(), r);
        seat_node.rotation = result;
        //seat_node._must_update_matrix = true;
    }
    //draw nametag on the top bar
    updateTopNameTag(camera) {
        const username = this.getUsername();
        if (!username)
            return;
        const profile_node = this.avatar.feed_node;
        const ctx = window.gl;
        ctx.start2D();
        const margin_top = 5;
        const pos = camera.project(profile_node.getGlobalPosition());
        const s = 1; // - this._distance_to_camera * 0.1;
        const w = 120 * s;
        const alpha = 1 - this._distance_to_camera * 0.03;
        if (pos[2] > 1 || pos[0] + w * 0.5 < 0 || pos[0] - w * 0.5 > window.gl.canvas.width)
            return;
        if (this.avatar.nametag_texture_dark && alpha > 0) {
            const h = w * (this.avatar.nametag_texture_dark.height / this.avatar.nametag_texture_dark.width);
            ctx.globalAlpha = alpha;
            ctx.drawImage(this.avatar.nametag_texture_dark, pos[0] - w * 0.5, margin_top + (1.0 - alpha) * 10, w, h);
            ctx.globalAlpha = 1;
        }
    }
    //rotates the cutout in the direction of the camera
    updateAvatarOrientation(camera) {
        //var feed_node = this.avatar ? this.avatar.feed_node : this._profile_node;
        const must_orient = this.avatar
            ? this.avatar.feed_node.visible
            : this._profile_node.visible;
        //disabled, not remember why is was done...
        if (must_orient && this.is_local_participant && 0) {
            this.resetOrientation();
            return;
        }
        let mode = ROOM_SETTINGS.call.first_person_facing_mode; //RoomParticipant.orientation_mode;
        //intercept
        if (RoomParticipant.face_forward_of_seat && this.seat)
            mode = ROOM_SETTINGS.call.table_facing_mode; //RoomParticipant.USER_FACING_ORIENTATION;
        if (must_orient)
            this.orientParticipant(mode, camera);
        else {
            if (!this.seat || !this.seat.spectator)
                this.resetOrientation();
        }
    }
    //based on mode, rotates the avatar node
    orientParticipant(orient_mode, camera) {
        //face auto orientation towards camera
        const feed_node = this.avatar ? this.avatar.pivot_node : this._profile_node;
        //save old in case we want to interpolate
        const original = glMatrix.quat.clone(feed_node.rotation);
        //reset rotation first
        feed_node.rotation = glMatrix.quat.create();
        feed_node._must_update_matrix = true; //for native?
        switch (orient_mode) {
            case EnumOrientation.NO_ORIENTATION: //facing 0 degrees to world (never used)
                break;
            case EnumOrientation.USER_ORIENTATION: //facing forward the user yaw
                this.resetOrientation();
                break;
            case EnumOrientation.USER_FACING_ORIENTATION: //facing forward to user but slightly to yourself
                this.orientNodeToCamera(feed_node, camera, original, 0.925);
                break;
            case EnumOrientation.BILLBOARD_ORIENTATION: //always facing to camera even vertically
                this.node.rotation = glMatrix.quat.create(); //reset yaw
                if (camera.type == Camera.PERSPECTIVE)
                    feed_node.orientTo(camera.position, false, [0, 1, 0]);
                else
                    feed_node.orientTo(camera._front, true, [0, 1, 0], true);
                break;
            case EnumOrientation.SEAT_ORIENTATION: //forward of its seat
                this.resetYaw(); //sets yaw to seat yaw
                this.node.rotation = quatFromAxisAngle([0, 1, 0], deg2rad(this.yaw));
                break;
            case EnumOrientation.SEAT_FACING_ORIENTATION: //forward of its seat by facing slightly to you
                this.resetYaw(); //sets yaw to seat yaw
                this.node.rotation = quatFromAxisAngle([0, 1, 0], deg2rad(this.yaw + 180)); //why 180? no idea
                this.orientNodeToCamera(feed_node, camera, original, 0.7);
                break;
            case EnumOrientation.FACING_ORIENTATION: //always facing to camera
            default:
                this.orientNodeToCamera(feed_node, camera);
                break;
        }
    }
    //rotates a node of the participant to look in the camera direction
    orientNodeToCamera(node, camera, prev_rotation, angle_threshold) {
        //reset rotation
        glMatrix.quat.identity(node.rotation);
        //get front of participant
        const front = node.getGlobalVector([0, 0, -1]);
        front[1] = 0; //flaten
        //get vector to camera
        const to_camera = glMatrix.vec3.sub(glMatrix.vec3.create(), camera.position, node.getGlobalPosition());
        to_camera[1] = 0;
        //in case the camera is too close to the participant,avoid doing anything
        if (glMatrix.vec3.length(front) > 0.0001 && glMatrix.vec3.length(to_camera) > 0.0001) {
            glMatrix.vec3.normalize(front, front);
            glMatrix.vec3.normalize(to_camera, to_camera);
            //dot product to get angle between them
            let Fdot = glMatrix.vec3.dot(to_camera, front);
            Fdot = clamp(Fdot, -1, 1);
            const angle = Math.acos(Fdot); // * 0.9;
            //has angle
            if (Math.abs(angle) > 0.0001) {
                const axis = glMatrix.vec3.cross(glMatrix.vec3.create(), front, to_camera);
                if (glMatrix.vec3.length(axis) > 0.0001) {
                    glMatrix.vec3.normalize(axis, axis);
                    //not behind
                    if (angle_threshold) {
                        if (angle > -Math.PI * angle_threshold &&
                            angle < Math.PI * angle_threshold) {
                            //cross to get axis
                            if (!isNaN(angle))
                                node.rotate(angle * 0.5, axis);
                        }
                    }
                    else
                        node.rotate(angle, axis);
                }
            }
        }
        //hack as profile plane points in the wrong direction...
        node.rotate(Math.PI, [0, 1, 0]); //rotate 180...
        //smooth transition
        if (prev_rotation) {
            if (RoomParticipant.force_orientation) {
                node.rotation = prev_rotation;
                //RoomParticipant.force_orientation = false;
            }
            else
                glMatrix.quat.slerp(node._rotation, node._rotation, prev_rotation, 0.9);
        }
        node._must_update_matrix = true;
    }
    //face in its yaw direction
    resetOrientation() {
        const feed_node = this.avatar ? this.avatar.pivot_node : this._profile_node;
        //var profile_node = this.avatar ? this.avatar.feed_node : this._profile_node;
        glMatrix.quat.identity(feed_node.rotation);
        feed_node._must_update_matrix = true;
        //extra rotation to face in the other direction
        feed_node.rotate(Math.PI, [0, 1, 0]);
    }
    //yaw to seat yaw
    resetYaw() {
        const seat = this.seat || this.last_seat;
        if (!seat)
            return;
        const R = seat.entity.node.getGlobalRotation();
        const angles = quatToEuler(glMatrix.vec3.create(), R);
        const seat_yaw = rad2deg(angles[0]);
        this.init_yaw = this.target_yaw = this.yaw = seat_yaw;
        this.target_pitch = this.pitch = rad2deg(angles[1]);
    }
    //pans the yaw and pitch to focus on a point
    lookAt(target) {
        if (target instanceof SceneNode)
            target = target.getGlobalPosition();
        else if (target instanceof RoomParticipant)
            target = target.getProfilePosition();
        else if (target.position)
            target = target.position;
        if (target.length !== 3)
            return;
        const pos = this.avatar.pivot_node.getGlobalPosition();
        const toTarget = glMatrix.vec3.sub(glMatrix.vec3.create(), pos, target);
        glMatrix.vec3.normalize(toTarget, toTarget);
        const angles = vec3CartesianToPolar(glMatrix.vec3.create(), toTarget);
        this.pitch = rad2deg(-angles[1]);
        this.yaw = (rad2deg(angles[2]) + 180) % 360;
    }
    getProfilePosition(out) {
        return this.avatar.pivot_node.getGlobalPosition(out);
    }
    onHoverMouse(e) {
        this.highlight_time = getTime();
    }
    mute() {
        this._volume = 0;
        this.is_audio_muted = true;
        this.clientEvent("MUTE_AUDIO", { mute: true });
    }
    unmute() {
        this._volume = RoomMediaStream.default_volume;
        this.is_audio_muted = false;
        this.clientEvent("MUTE_AUDIO", { mute: false });
    }
    getPivotNode() {
        return this.avatar.pivot_node;
    }
    //called from RoomSpace.prototype.testRayWithInteractiveNodes
    getInteractiveNodes(container, camera) {
        const profile_node = this.avatar ? this.avatar.feed_node : this._profile_node;
        const selectable_node = this.avatar
            ? this.avatar.selectable_node
            : this._selectable_node;
        const profile_material = StaticMaterialsTable[profile_node.material];
        //get front vector
        const front = profile_node.getGlobalVector([0, 0, -1]);
        //compare with camera front to see if seeing from behind
        const cam_front = camera.getFront();
        const FdotE = glMatrix.vec3.dot(front, cam_front);
        if (FdotE < 0) {
            // participant is facing away from the camera
            return;
        }
        if ((!this.is_local_participant || this.was_visible) &&
            profile_node.visible &&
            profile_material &&
            profile_material.opacity > 0.3) {
            selectable_node.visible = true;
            selectable_node.updateMatrices();
            container.push(selectable_node);
        }
    }
    /**
     * @TODO rework type using Bridge event types
     * @description sends action through bridge only if you are the one generating it
     */
    clientEvent(type, data) {
        if (this.is_local_participant && this.space.bridge)
            this.space.bridge.notify(type, data);
    }
    getFocusNode() {
        if (!this.focus_item)
            return null;
        if (this.focus_item instanceof SceneNode)
            return this.focus_item;
        return this.focus_item.node;
    }
    //positions a camera where he should be seeing (above the root node)
    //called from RoomCall.prototype.onUpdate
    updateCameraView(camera) {
        let eyes_height = ROOM_SETTINGS.participants.view_height; //config.seats.camera_height_offset || 0;
        if (!this.seat) {
            //standing
            eyes_height *= ROOM_SETTINGS.participants.walk_height;
        }
        //user is focused, position camera in front of focused element
        if (this.focus_item) {
            const focus_node = this.getFocusNode();
            const focus_entity = focus_node.getParentEntity();
            let dist = 0.5;
            let offsety = 0;
            if (focus_entity && focus_entity.seat) {
                //is participant: match selfview
                dist = 2;
                offsety = -0.15;
                //camera.fov = 30; //this will block the fov, but shiould be configurable with mousewheel
            }
            const eye = focus_node.localToGlobal([0, offsety, dist]);
            const target = focus_node.localToGlobal([0, 0, 0]);
            const up = focus_node.getGlobalVector([0, 1, 0]);
            //camera.lookAt( eye2, target, [0,1,0] );
            camera.lookAt(eye, target, up);
            if (this.focus_offset)
                //offset by dragging
                camera.moveLocal(this.focus_offset, 1.0);
            return;
        }
        this.pitch = clamp(this.pitch, -89, 89);
        //regular view
        const node = this.node;
        const head_offset = this.head_offset < 1 ? 0 : this.head_offset - 1;
        const camoffset = glMatrix.vec3.fromValues(0, eyes_height +
            head_offset * ROOM_SETTINGS.participants.thirth_person_view_offset[1] +
            head_offset * clamp(this.pitch, 0, 90) * -0.025, //helps lower when looking up
        head_offset * ROOM_SETTINGS.participants.thirth_person_view_offset[2]);
        glMatrix.vec3.add(camoffset, camoffset, this.leaning_offset);
        const eye = node.localToGlobal(camoffset, camoffset);
        const front = node.getGlobalVector([0, head_offset * -0.5, -1]);
        const right = node.getGlobalVector([1, 0, 0]);
        const final_pitch = this.pitch + this._internal_pitch;
        const Q = quatFromAxisAngle(right, deg2rad(final_pitch));
        glMatrix.vec3.transformQuat(front, front, Q);
        glMatrix.vec3.scaleAndAdd(eye, eye, front, 0.1);
        const target = glMatrix.vec3.add(glMatrix.vec3.create(), front, eye);
        let up = glMatrix.vec3.fromValues(0, 1, 0);
        if (this.leaning_offset[0]) {
            const leaning = node.getGlobalVector(this.leaning_offset);
            up = glMatrix.vec3.fromValues(leaning[0] * 0.3, 1, leaning[2] * 0.3);
        }
        //move camera
        camera.lookAt(eye, target, up);
    }
    //name can be in several places... (ugly)
    getUsername() {
        let username = "";
        if (this.user)
            username =
                this.user.nameInMeeting || this.user.fullName || this.user.name || "";
        else
            username = this.name || "";
        return username;
    }
    //called from call.processItemClicked
    processClick(e) {
        //pass to components
        this.processActionInComponents("processClick", e);
    }
    //called from call update
    update(dt, t) {
        this.smooth_loudness = this.smooth_loudness * 0.8 + 0.2 * this.loudness;
        this.fade_factor = clamp(this.fade_factor + dt, 0, 1);
        if (this.interpolate_state) {
            const f = 0.1;
            glMatrix.vec3.lerp(this.position, this.position, this.target_position, f);
            this.yaw = lerp(this.yaw, this.target_yaw, f);
            this.pitch = lerp(this.pitch, this.target_pitch, f);
            this.node._must_update_matrix = true;
        }
        //orient based on the yaw (not pitch)
        const final_yaw = this.yaw + this._internal_yaw + 180; //180...
        const q = quatFromAxisAngle([0, 1, 0], deg2rad(final_yaw));
        this.node.rotation = q;
        //INFO: this.pitch is not used to orient the plane so it only affects your camera
        //thats why it is processed from participant.updateCameraView();
        //from the director mode?
        if (RoomParticipant.block_orientation && !this.is_local_participant) {
            if (this.seat) {
                this.node.rotation = this.seat.entity.rotation;
                this.node.rotate(Math.PI, [0, 1, 0]);
            }
        }
        if (this.stream && "update" in this.stream) {
            this.stream.update(dt, t);
        }
        //change volume based on distance to users
        if (this.space.settings &&
            this.space.settings.spatial_audio_for_participants &&
            this != this.space.local_participant)
            this.bindVolumeWithPosition();
    }
    bindVolumeWithPosition() {
        if (!this.player || !this.player.remoteVideo)
            return;
        const video = this.player.remoteVideo.videoElement;
        if (!video)
            return;
        const settings = this.space.settings.spatial_audio_for_participants;
        if (!settings)
            return;
        const near = settings.near || 2;
        const far = settings.far || 8;
        const dist = glMatrix.vec3.distance(this.position, this.space.local_participant.position);
        const volume = smoothStep(far, near, dist);
        video.volume = volume;
        function smoothStep(min, max, value) {
            const x = Math.max(0, Math.min(1, (value - min) / (max - min)));
            return x * x * (3 - 2 * x);
        }
    }
    /**
     * @TODO rework type using Bridge event types
     */
    on(event_type, callback, instance) {
        LEvent.bind(this, event_type, callback, instance);
    }
    /**
     * @TODO rework type using Bridge event types
     */
    off(event_type, callback, instance) {
        LEvent.unbind(this, event_type, callback, instance);
    }
    //could be a entity or the name of the entity
    enterSeat(seat) {
        if (!this.space)
            throw "participant is not in any space";
        var seat_comp = null;
        if (!seat) {
            console.error("seat is null");
            return;
        }
        if (typeof seat === "string") {
            seat = this.space.getEntity(seat);
        }
        if (seat instanceof Entity) {
            seat_comp = seat.getComponent(Seat);
        }
        if (!seat_comp) {
            console.error("seat not found");
            return;
        }
        if (!(seat_comp instanceof Seat)) {
            console.error("not a seat");
            return;
        }
        if (seat_comp == this.seat) {
            return;
        }
        if (seat_comp.participant) {
            console.error("seat is already in use");
            return;
        }
        //already seating
        const prev_seat = this.seat;
        const fov = this.xyz.view.hard_camera.fov; //we save old fov to keep it when sitting
        if (this.seat)
            this.exitSeat();
        this.seat = this.last_seat = seat_comp;
        if (this.seat) {
            this.seat.onParticipantEnter(this);
            this.fade_factor = 0;
            //assign track node
            const track = this.space.findNearestCameraTrack(this.seat.entity.node.position);
            this._track_entity = track;
        }
        else
            this._track_entity = null;
        this.walking = Boolean(!this.seat);
        this.head_offset = 0;
        this.moveToSeat();
        LEvent.trigger(this, "changeSeat", {
            author: this,
            component: seat,
            prev_seat: prev_seat,
            prev_fov: fov,
        });
        LEvent.trigger(this.space, "changeSeat", { author: this, component: seat });
        if (seat_comp && seat_comp.walking_start)
            this.exitSeat();
        return seat;
    }
    //in case he walked away or was transitioning to another room
    moveToSeat(only_position) {
        const seat = this.seat || this.last_seat;
        const R = seat.entity.node.getGlobalRotation();
        const angles = quatToEuler(glMatrix.vec3.create(), R);
        this.node.position = seat.entity.node.position;
        this.target_position = this.node.position;
        if (!only_position) {
            this.init_yaw = this.target_yaw = this.yaw = rad2deg(angles[0]);
            this.target_pitch = this.pitch = rad2deg(angles[1]);
        }
    }
    exitSeat() {
        if (!this.seat) {
            this.avatar.resetNativeNodes();
            console.error("user not seated");
            return;
        }
        const prev_seat = this.seat;
        this.pitch = 0; //look forward
        this.head_offset = 0;
        this.last_seat = prev_seat;
        this.seat.onParticipantLeaves(this);
        this.seat = null;
        this.walking = true;
        this.focusOn(null);
        this._track_entity = null;
        this.avatar.resetNativeNodes();
        this.updateSeatOrientation(true);
        if (prev_seat) {
            //was seated
            this.node.moveLocal([0, 0, 1]);
        }
        LEvent.trigger(this, "changeSeat", {
            author: this,
            component: null,
            prev_seat: prev_seat,
        });
        LEvent.trigger(this.space, "changeSeat", {
            author: this,
            component: null,
            prev_seat: prev_seat,
        });
    }
    //call from backend if somebody was in the room in walking mode
    enterWalking() {
        this.walking = true;
        //start fade?
        this.fade_factor = 0;
    }
    //tell to which element of the scene this participant should be focused on, usually only applies to local_participant
    //item could be an entity or a node
    focusOn(item) {
        if (this.focus_item == item)
            return;
        if (this.focus_item)
            this.focus_item.is_on_focus = false;
        const camera = this.xyz.view.hard_camera;
        const prev_focus_item = this.focus_item;
        this.focus_item = item;
        if (this.focus_item)
            this.focus_item.is_on_focus = true;
        this.focus_offset = null;
        const view = this.xyz.view;
        //enter focus
        if (item) {
            this._prev_focus_camera = view.hard_camera.serialize();
            camera.fov = 65;
            view.setDOF(true, 1);
            view.setDynamicResolution(false);
            view.setDOF(true, 1);
            //clicked on a surface
            const surface = item.room_entity && item.room_entity.surface;
            if (surface)
                surface.onEnterFocus();
        } //exit focus
        else {
            const surface = prev_focus_item.room_entity && prev_focus_item.room_entity.surface;
            if (surface)
                surface.onExitFocus();
            if (this._prev_focus_camera)
                view.hard_camera.configure(this._prev_focus_camera);
            view.setDOF(false, 1);
            view.setDynamicResolution(true);
        }
        LEvent.trigger(this.space, "focusOn", { author: this, target: item });
        if (item) {
            ROOM.lockMouse(false);
        }
        else {
            if (this.walking && 0)
                ROOM.lockMouse(true);
        }
        //toggle the interface (deprecated)
        this.clientEvent("ON_SHOW_LIST_VIDEO_FEED", {
            isShowListVideoFeed: Boolean(item),
        });
    }
    exitFocus() {
        this.space.local_participant.focusOn(null);
    }
    testRayWithFocusPlane(ray) {
        if (!this.focus_item)
            return null;
        const node = this.focus_item instanceof SceneNode
            ? this.focus_item
            : this.focus_item.node;
        if (!node)
            return null;
        return node.testRayWithLocalPlane(ray);
    }
    //called from Scene
    onEnterSpace(space) {
        space.scene.root.addChild(this.user_node);
    }
    onLeftSpace() {
        if (this.seat)
            this.exitSeat();
        this.walking = false;
        if (this.avatar)
            this.avatar.onLeftSpace();
        this.space.scene.root.removeChild(this.user_node);
        //remove stream from global stream
        if (this.space._global_feed && this.space._global_feed == this.stream)
            this.space.playStream(null);
        //free memory
        if (this.stream) {
            this.stream._locked = false;
            this.stream.free();
            this.stream = null;
        }
        this.space = null;
    }
    assignFeed(feed, feed_options) {
        this.feed_info = this.xyz.assignFeed(this, feed, feed_options);
    }
    onMouse(_e) {
    }
    //sync stuff
    stateToJSON() {
        var _a, _b;
        const o = {
            id: this.id,
            yaw: this.yaw,
            pitch: this.pitch,
            is_on_hold: this.is_on_hold,
            position: typedArrayToArray(this.position),
            cursor_position: this.cursor_position,
            seat_name: ((_b = (_a = this.seat) === null || _a === void 0 ? void 0 : _a.entity) === null || _b === void 0 ? void 0 : _b.name) || null,
            face_offset: typedArrayToArray(this.face_offset),
            face_scale: this.face_scale,
            profile_scaling: this.profile_scaling,
            face_brightness: this.face_brightness,
            internal_offset: typedArrayToArray(this._internal_offset),
            internal_scale: this._internal_scale,
            loudness: this.loudness,
            walking: this.walking,
        };
        LEvent.trigger(this, "state_to_object", o);
        return o;
    }
    //called from
    JSONToState(state) {
        try {
            if (state.yaw != null)
                this.target_yaw = state.yaw;
            if (state.pitch != null)
                this.target_pitch = state.pitch;
            if (state.position != null) {
                this.target_position = state.position;
            }
            this.walking = state.walking || false;
            this.is_on_hold = state.is_on_hold;
            if (state.cursor_position)
                glMatrix.vec3.copy(this.cursor_position, state.cursor_position);
            if (state.face_offset) {
                glMatrix.vec2.copy(this.face_offset, state.face_offset);
                this.face_scale = state.face_scale;
                this.face_brightness = state.face_brightness;
            }
            if (state.internal_offset) {
                glMatrix.vec2.copy(this._internal_offset, state.internal_offset);
                this._internal_scale = state.internal_scale;
            }
            this.loudness = state.loudness || 0;
            //syncs seats
            if (!this.is_local_participant && RoomParticipant.sync_seats) {
                if (state.seat_index != null)
                    this.setSeatIndex(state.seat_index);
                else if (state.seat_name != null) {
                    //could be null!
                    this.setSeatName(state.seat_name);
                }
            }
            if (state.profile_scaling)
                this.profile_scaling = state.profile_scaling;
            LEvent.trigger(this, "object_to_state", state);
            if (!this.interpolate_state) {
                if (state.position != null)
                    this.position = this.target_position;
                if (state.yaw != null)
                    this.yaw = this.target_yaw;
                if (state.pitch != null)
                    this.pitch = this.target_pitch;
                this.node._must_update_matrix = true;
            }
            this.interpolate_state = true;
        }
        catch (err) {
            console.error("Some error in the JSON for the participant state:", err);
        }
    }
    //binary version
    stateToStream(stream) {
        stream.writeArray(this.node._position); //3*4 = 12
        stream.writeFloat32(this.yaw); //16
        stream.writeFloat32(this.pitch); //20
        stream.writeFloat32(this.loudness); //24
        let flags = 0;
        if (this._visible)
            flags |= EnumParticipantFlags.VISIBLE;
        if (this.walking)
            flags |= EnumParticipantFlags.WALKING;
        stream.writeUint32(flags);
        stream.writeUint32(this.seat ? this.seat.entity.index : 0xffffffff);
        stream.writeFloat32(this.profile_scaling);
        LEvent.trigger(this, "read_stream", stream);
    }
    setSeatName(seat_name) {
        if (seat_name === null) {
            //no seat
            if (this.seat) {
                console.debug("EJECTED: user seat should be null");
                this.exitSeat();
            }
        }
        else {
            if (this.seat && this.seat.entity.name !== seat_name) {
                console.debug("EJECTED: user seat name is ", this.seat.entity.name, "and it should be", seat_name);
                this.exitSeat();
            }
            const seat_entity = this.space.getEntity(seat_name);
            if (seat_entity && seat_entity.seat && !seat_entity.seat.participant)
                this.enterSeat(seat_entity);
            else
                return false;
        }
        return true;
    }
    setSeatIndex(seat_index) {
        if (seat_index === null)
            return;
        if (seat_index === -1) {
            //no seat
            if (this.seat) {
                console.debug("EJECTED: user seat index is -1");
                this.exitSeat();
            }
        }
        else {
            if (this.seat && this.seat.entity.index !== seat_index) {
                console.debug("EJECTED: user seat index is ", this.seat.entity.index, "and it should be", seat_index);
                this.exitSeat();
            }
            const seat_entity = this.space.getEntityByIndex(seat_index);
            if (seat_entity && seat_entity.seat && !seat_entity.seat.participant)
                this.enterSeat(seat_entity);
            else
                return false;
        }
        return true;
    }
    streamToState(stream) {
        stream.readArray(this.target_position); //3*4 = 12
        this.target_yaw = stream.readFloat32(); //16
        this.target_pitch = stream.readFloat32(); //20
        this.loudness = stream.readFloat32(); //24
        const flags = stream.readUint32();
        this._visible = Boolean(flags & EnumParticipantFlags.VISIBLE);
        this.walking = Boolean(flags & EnumParticipantFlags.WALKING);
        //syncs seats
        let seat_index = stream.readUint32();
        if (seat_index === 0xffffffff)
            seat_index = -1;
        if (!this.is_local_participant && RoomParticipant.sync_seats)
            this.setSeatIndex(seat_index);
        this.profile_scaling = stream.readFloat32();
        LEvent.trigger(this, "store_stream", stream);
        if (!this.interpolate_state) {
            this.position = this.target_position;
            this.yaw = this.target_yaw;
            this.pitch = this.target_pitch;
            this.node._must_update_matrix = true;
        }
        this.interpolate_state = true;
    }
}
RoomParticipant.orientation_mode = EnumOrientation.USER_FACING_ORIENTATION;
RoomParticipant.force_self_render = false;
RoomParticipant.update_seats_orientation = true;
RoomParticipant.two_sided = true;
RoomParticipant.block_orientation = false; //used in director mode ??
RoomParticipant.face_forward_of_seat = false; //used when in track mode
RoomParticipant.force_orientation = false; //no interpolation of rotations during one frame
RoomParticipant.global_opacity = 1;
RoomParticipant.draw_top_nodetag = true;
RoomParticipant.global_streams = new Map();
RoomParticipant.last_index = 0;
RoomParticipant.sync_seats = true;
RoomParticipant.LAYERS_MASK = 1 << 8;
RoomParticipant.marker_offset = 0;
RoomParticipant.MIN_DISTANCE_BETWEEN_PARTICIPANTS = 0.5;
