import { mat4, vec3 } from "gl-matrix";

/**
 * @param {mat4} mat
 * @returns {Number[]}
 */
export const mat4ToArray = function(mat) {
	return [ mat[0], mat[1], mat[2], mat[3], mat[4], mat[5], mat[6], mat[7], mat[8], mat[9], mat[10], mat[11], mat[12], mat[13], mat[14], mat[15] ];
};

export const mat4SetUpAndOrthonormalize = function(out, m, up) {
	if (m !== out) {
		mat4.copy(out, m);
	}

	const right = out.subarray(0, 3);
	vec3.normalize(out.subarray(4, 7), up);
	const front = out.subarray(8, 11);
	vec3.cross(right, up, front);
	vec3.normalize(right, right);
	vec3.cross(front, right, up);
	vec3.normalize(front, front);
};

/**
 * projects vector from 3D to 2D and returns the value in normalized screen space
 *
 * @link https://github.com/hughsk/from-3d-to-2d/blob/master/index.js
 *
 * @param {mat4} out
 * @param {mat4} m should be a projection matrix (or a VP or MVP)
 * @param {vec3} a
 * @returns {mat4}
 */
export const mat4ProjectVec3 = function(out, m, a) {
	const ix = a[0];
	const iy = a[1];
	const iz = a[2];

	const ox = m[0] * ix + m[4] * iy + m[8] * iz + m[12];
	const oy = m[1] * ix + m[5] * iy + m[9] * iz + m[13];
	const oz = m[2] * ix + m[6] * iy + m[10] * iz + m[14];
	const ow = m[3] * ix + m[7] * iy + m[11] * iz + m[15];

	out[0] = (ox / ow + 1) / 2;
	out[1] = (oy / ow + 1) / 2;
	out[2] = (oz / ow + 1) / 2;
	return out;
};

export const mat4MultiplyVec3 = function(out, m, a) {
	const x = a[0], y = a[1], z = a[2];
	out[0] = m[0] * x + m[4] * y + m[8] * z + m[12];
	out[1] = m[1] * x + m[5] * y + m[9] * z + m[13];
	out[2] = m[2] * x + m[6] * y + m[10] * z + m[14];
	return out;
};

export const mat4RotateVec3 = function(out, m, a) {
	const x = a[0], y = a[1], z = a[2];
	out[0] = m[0] * x + m[4] * y + m[8] * z;
	out[1] = m[1] * x + m[5] * y + m[9] * z;
	out[2] = m[2] * x + m[6] * y + m[10] * z;
	return out;
};

export const mat4FromTranslationFrontTop = function(out, pos, front, top) {
	vec3.cross(out.subarray(0, 3), front, top);
	out.set(top, 4);
	out.set(front, 8);
	out.set(pos, 12);
	return out;
};

/**
 * @param v
 * @returns {mat4}
 */
export const mat4TranslationMatrix = function(v) {
	const out = mat4.create();
	out[12] = v[0];
	out[13] = v[1];
	out[14] = v[2];
	return out;
};

/**
 * @param out
 * @param v
 * @returns {*}
 */
export const mat4SetTranslation = function(out, v) {
	out[12] = v[0];
	out[13] = v[1];
	out[14] = v[2];
	return out;
};

/**
 * @param out
 * @param matrix
 * @returns {*}
 */
export const mat4GetTranslation = function(out, matrix) {
	out[0] = matrix[12];
	out[1] = matrix[13];
	out[2] = matrix[14];
	return out;
};

/**
 * returns the matrix without rotation
 *
 * @param out
 * @param mat
 * @returns {*}
 */
export const mat4ToRotationMat4 = function(out, mat) {
	mat4.copy(out, mat);
	out[12] = out[13] = out[14] = 0.0;
	return out;
};

/**
 * @TODO `matrix` seems to be undefined, check this
 *
 * @param out
 * @param mat
 * @param row
 * @param row2
 * @returns {*}
 */
export const mat4SwapRows = function(out, mat, row, row2)
{
	if (out != mat)
	{
		mat4.copy(out, mat);
		out[4*row] = mat[4*row2];
		out[4*row+1] = mat[4*row2+1];
		out[4*row+2] = mat[4*row2+2];
		out[4*row+3] = mat[4*row2+3];
		out[4*row2] = mat[4*row];
		out[4*row2+1] = mat[4*row+1];
		out[4*row2+2] = mat[4*row+2];
		out[4*row2+3] = mat[4*row+3];
		return out;
	}

	const temp = new Float32Array(matrix.subarray(row * 4, row * 5));
	matrix.set( matrix.subarray(row2*4,row2*5), row*4 );
	matrix.set( temp, row2*4 );
	return out;
}

/**
 * used in skinning
 */
export const mat4ScaleAndAdd = function(out, mat, mat2, v)
{
	out[0] = mat[0] + mat2[0] * v; 	out[1] = mat[1] + mat2[1] * v; 	out[2] = mat[2] + mat2[2] * v; 	out[3] = mat[3] + mat2[3] * v;
	out[4] = mat[4] + mat2[4] * v; 	out[5] = mat[5] + mat2[5] * v; 	out[6] = mat[6] + mat2[6] * v; 	out[7] = mat[7] + mat2[7] * v;
	out[8] = mat[8] + mat2[8] * v; 	out[9] = mat[9] + mat2[9] * v; 	out[10] = mat[10] + mat2[10] * v; 	out[11] = mat[11] + mat2[11] * v;
	out[12] = mat[12] + mat2[12] * v;  out[13] = mat[13] + mat2[13] * v; 	out[14] = mat[14] + mat2[14] * v; 	out[15] = mat[15] + mat2[15] * v;
	return out;
}
