import {
	CORNER_BOTTOM_CENTER,
	CORNER_BOTTOM_LEFT,
	CORNER_BOTTOM_RIGHT,
	CORNER_TOP_CENTER,
	CORNER_TOP_LEFT,
	CORNER_TOP_RIGHT
} from "./constants";

function positionToArea( position, corner, area )
{
	var x = position[0];
	var y = position[1];

	switch ( corner )
	{
	case CORNER_TOP_RIGHT: x = gl.canvas.width - x; break;
	case CORNER_BOTTOM_LEFT: y = gl.canvas.height - y; break;
	case CORNER_BOTTOM_RIGHT: x = gl.canvas.width - x; y = gl.canvas.height - y; break;
	case CORNER_TOP_CENTER: x = gl.canvas.width * 0.5; break;
	case CORNER_BOTTOM_CENTER: x = gl.canvas.width * 0.5; y = gl.canvas.height - y; break;
	case CORNER_TOP_LEFT:
	default:
	}

	area[0] = x;
	area[1] = y;
}

export default positionToArea;
