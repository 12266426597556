import BaseComponent from "@src/engine/components/baseComponent";
import Label from "@src/libs/GLUI/Elements/Label";
class BroadcastSceneComponent extends BaseComponent {
    constructor() {
        super();
        this.moveFreely = false;
        this.isHandyCamActive = false;
        this.handyCamIntensity = 0;
        this.previewImageURL = "";
        this.name = "";
    }
    serialize(o = {}) {
        o.moveFreely = this.moveFreely;
        o.isHandyCamActive = this.isHandyCamActive;
        o.handyCamIntensity = this.handyCamIntensity;
        o.previewImageURL = this.previewImageURL;
        o.name = this.name;
        return o;
    }
    configure(o) {
        if (o.moveFreely !== undefined)
            this.moveFreely = o.moveFreely;
        o.isHandyCamActive !== undefined ? this.isHandyCamActive = o.isHandyCamActive : this.isHandyCamActive = false;
        o.handyCamIntensity !== undefined ? this.handyCamIntensity = o.handyCamIntensity : this.handyCamIntensity = 1;
        o.previewImageURL !== undefined ? this.previewImageURL = o.previewImageURL : this.previewImageURL = "";
        o.name !== undefined ? this.name = o.name : this.name = "";
    }
    onRenderInspector(ctx, x, y, w, h, editor) {
        this.moveFreely = GUI.Toggle(x, y, w, 20, "Move Freely", this.moveFreely);
        y += 30;
        this.isHandyCamActive = GUI.Toggle(x, y, w, 20, "Handy Cam", this.isHandyCamActive);
        y += 30;
        if (this.isHandyCamActive) {
            Label.call(GUI, x, y, 100, 20, "Handy Cam Intensity");
            this.handyCamIntensity = GUI.Slider(x + 200, y, w - 200, 20, this.handyCamIntensity, 0, 2);
            y += 30;
        }
        Label.call(GUI, x, y, 100, 20, "Preview Image URL");
        this.previewImageURL = GUI.TextField(x + 200, y, w - 200, 20, this.previewImageURL, true, true);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "Name");
        this.name = GUI.TextField(x + 200, y, w - 200, 20, this.name, true, true);
        y += 30;
    }
}
BroadcastSceneComponent.componentName = "BroadcastScene";
export default BroadcastSceneComponent;
