import { GL } from "@src/libs/litegl";
import { ARRAY_TYPES } from "./ArrayTypes";

/**
 * This class stores all the HDRE data
 * @class HDREImage
 */
function HDREImage(header, data, o) {

	if (this.constructor !== HDREImage) {
		throw ("You must use new to create a HDREImage");
	}

	this._ctor(header, data);

	if (o) {
		this.configure(o);
	}
}

HDREImage.prototype._ctor = function(h, data) {

	//could I build hdre without header?
	h = h || {};

	// file info
	this.version = h["version"];

	// dimensions
	this.width = h["width"];
	this.height = h["height"];

	// channel info
	this.n_channels = h["nChannels"];
	this.bits_channel = h["bpChannel"];

	// image info
	this.data = data;
	this.type = ARRAY_TYPES[h["type"]];
	this.max_irradiance = h["maxIrradiance"];
	this.shs = h["shs"];
	this.size = h["size"];

	// store h just in case
	this.header = h;
};

HDREImage.prototype.configure = function(o) {

	o = o || {};

	this.is_rgbe = o.rgbe !== undefined ? o.rgbe : false;

	for (var k in o)
		this[k] = o[k];

	if (this.bits_channel === 32) {
		this.type = Float32Array;
	} else {
		this.type = Uint8Array;
	}

};

HDREImage.prototype.flipY = function() {

};

HDREImage.prototype.toTexture = function() {

	if (!window.GL) {
		throw ("this function requires to use litegl.js");
	}

	var _envs = this.data;
	if (!_envs) {
		return false;
	}

	// Get base enviroment texture
	var tex_type = gl.FLOAT;
	var data = _envs[0].data;
	var w = this.width;

	if (this.type === Uint16Array) // HALF FLOAT
	{
		tex_type = gl.HALF_FLOAT_OES;
	} else if (this.type === Uint8Array) {
		tex_type = gl.UNSIGNED_BYTE;
	}


	var flip_Y_sides = false;

	// "true" for using old environments
	// (not standard flipY)
	if (this.version < 3.0) {
		flip_Y_sides = true;
	}

	if (flip_Y_sides) {
		var tmp = data[2];
		data[2] = data[3];
		data[3] = tmp;
	}

	var options = {
		format: this.n_channels === 4 ? gl.RGBA : gl.RGB,
		type: tex_type,
		minFilter: gl.LINEAR_MIPMAP_LINEAR,
		texture_type: gl.TEXTURE_CUBE_MAP,
	};

	GL.Texture.disable_deprecated = true;

	var tex = new GL.Texture(w, w, options);
	tex.mipmap_data = {};

	// Generate mipmaps
	tex.bind(0);

	var num_mipmaps = Math.log(w) / Math.log(2);

	// Upload prefilter mipmaps
	for (var i = 0; i <= num_mipmaps; i++) {
		var level_info = _envs[i];
		var levelsize = Math.pow(2, num_mipmaps - i);

		if (level_info) {
			var pixels = level_info.data;

			if (flip_Y_sides && i > 0) {
				var tmp = pixels[2];
				pixels[2] = pixels[3];
				pixels[3] = tmp;
			}

			for (var f = 0; f < 6; ++f) {
				if (!flip_Y_sides && i == 0) {
					GL.Texture.flipYData(pixels[f], w, w, this.n_channels);
				}

				tex.uploadData(pixels[f], { cubemap_face: f, mipmap_level: i }, true);
			}
			tex.mipmap_data[i] = pixels;
		} else {
			var zero = new Float32Array(levelsize * levelsize * this.n_channels);
			for (var f = 0; f < 6; ++f)
				tex.uploadData(zero, { cubemap_face: f, mipmap_level: i }, true);
		}
	}

	GL.Texture.disable_deprecated = false;

	// Store the texture
	tex.has_mipmaps = true;
	tex.data = null;
	tex.is_rgbe = this.is_rgbe;

	return tex;
}

export default HDREImage;
