import Seat from "@src/engine/components/seat";
import { RoomComponents } from "@src/engine/RoomComponents";

//THIS ACTION IS PERFORMED FROM TransitionToSeat.js
function FollowPathToSeatAction() {
	this.path = "";
	this.seat = "";
	this.reverse = false;
}

FollowPathToSeatAction.label = "Follow Path to Seat";
FollowPathToSeatAction["@path"] = { type: "entity" };
FollowPathToSeatAction["@seat"] = { type: "entity" };

FollowPathToSeatAction.prototype.onClick = function(e, entity, _comp) {
	const space = entity.space;
	let path = space.getEntity(this.path);
	let seat = space.getEntity(this.seat);
	if (!path) {
		return;
	}
	path = path.getComponent(RoomComponents.Path);
	if (seat) {
		seat = seat.getComponent(Seat);
	}
	if (!path) {
		return;
	}

	const call = RoomCall.instance;
	call.followPath(path, seat, this.reverse);
};

FollowPathToSeatAction.prototype.serialize = function() {
	return {
		path: this.path,
		seat: this.seat,
		reverse: this.reverse
	};
};

FollowPathToSeatAction.prototype.configure = function(o) {
	this.path = o.path || "";
	this.seat = o.seat || "";
	this.reverse = o.reverse || false;
};

export default FollowPathToSeatAction;
