import { DEG2RAD }   from "@src/constants";
import ROOM          from "@src/engine/room";
import { getPathInfo } from "@src/engine/Room/file-utils";
import HSLToRGB      from "@src/libs/LiteGL/Color/HSLToRGB";
import clamp         from "@src/math/clamp";
import XYZLauncher from "@src/XYZLauncher";

function IntroLoader(xyz)
{
	IntroLoader.instance = this;

	this.enabled = true; //disable
	this.render_spinner = false;
	this.space = xyz.space;

	this.show_preview_texture = true; //render the preview
	this.default_fill_color = [ 1,1,1,1 ];

	this.ready = true;
	this.active = false;
	this.fade = true;
	this.xyz = xyz;
	xyz.onRenderLoader = this.render.bind(this);
	this.spinner_angle = 0;
	this.has_loaded = false;
	this.preview_texture = null;
	this.preview_time = 0;
	LEvent.bind( xyz.space, "start_loading", this.onStartLoading, this );
}

IntroLoader.prototype.onStartLoading = function(e,url)
{
	this.active = true;
	this.has_loaded = false;
	this.url = url;
}

function smoothstep01(x)
{
	return x * x * (3 - 2 * x);
}

IntroLoader.prototype.loadPreviewURL = function()
{
	var that = this;
	if (!this.url)
		return;

	var info = getPathInfo( this.url );

	//fetch preview
	var preview_url = null;
	if ( this.space.preview_url )
		preview_url = info.folder + "/" + this.space.preview_url;

	this.xyz.view.loadTexture( preview_url, null, function(tex) {
		that._loading_preview = false;
		that.space.preview_texture = tex;
		that.preview_texture = tex;
		that.preview_time = getTime() * 0.001;
	});

	this._loading_preview = true;
}

//called from XYZLauncher.prototype.onRender through xyz.onRenderLoader
IntroLoader.prototype.render = function(dt)
{
	if (!this.enabled || !this.active)
		return;

	var xyz = this.xyz;
	var space = xyz.space;
	var ctx = gl;
	var canvas = gl.canvas;

	if (!this.textures)
	{
		this.textures = [];
		this.textures[0] = xyz.view.loadTexture( "textures/intro_logo1.png", { minFilter: gl.LINEAR_MIPMAP_LINEAR });
		this.textures[1] = xyz.view.loadTexture( "textures/intro_logo2.png", { minFilter: gl.LINEAR_MIPMAP_LINEAR });
	}

	ctx.start2D();

	var time = getTime() * 0.001;
	var w = gl.canvas.width;
	var h = gl.canvas.height;

	var loading = space.loading || space.loading_info.length || !space.ready_assets;

	if (ROOM.user_clicked_once)
		this.fade = false;

	//load preview
	if ( !this.preview_texture && this.space.preview_url && !this._loading_preview)
		this.loadPreviewURL();

	if ( loading )
	{
		this.ready = false;
		xyz.ready_time = time;
		var ratio = space.getLoadRatio();
		//if(bridge)
		//	bridge.notify( bridge.EVENT_LOADING, { type: "LOAD_PROGRESS", value: ratio });
		ctx.fillColor = this.default_fill_color;
		ctx.fillRect(0,0, canvas.width, canvas.height );

		if ( this.preview_texture && this.show_preview_texture ) //fade in
		{
			var aspect = this.preview_texture.width / this.preview_texture.height;
			ctx.globalAlpha = clamp(time - this.preview_time,0,1);
			ctx.drawImage( this.preview_texture, w*0.5 - (h*aspect*0.5), 0, h*aspect,h );
			ctx.globalAlpha = 1;
		}

		var t = getTime() * 0.001 / 1.2;
		var f = t - Math.floor(t);
		this.spinner_angle = smoothstep01(f) * Math.PI * 2;

		if (!space.loading_json && this.render_spinner)
			this.renderSpinner( ctx, canvas, this.spinner_angle, space.settings.no_branding );

		this.renderProgressBar(ctx, canvas, ratio);

		this.loading_fade = 1;
		this.has_loaded = true;
	}
	else if ( this.loading_fade > 0 )//not loading, fade in
	{
		var alpha = this.loading_fade + 0.5;
		var v = this.default_fill_color;ctx.fillColor = [ v[0],v[1],v[2],v[3]*alpha ];

		if ( !this.preview_texture )
			ctx.fillRect(0,0, ctx.canvas.width, ctx.canvas.height );

		this.spinner_angle = this.spinner_angle * 0.9 + (Math.PI * 2) * 0.1;

		var alpha = clamp( this.loading_fade,0,1);
		ctx.globalAlpha = alpha;

		if ( this.preview_texture && this.show_preview_texture )
		{
			var aspect = this.preview_texture.width / this.preview_texture.height;
			ctx.drawImage( this.preview_texture, w*0.5 - (h*aspect*0.5), 0, h*aspect,h );
		}

		if (this.render_spinner)
			this.renderSpinner( ctx, canvas, this.spinner_angle, space.settings.no_branding );
		this.renderProgressBar(ctx, canvas, 1);
		ctx.globalAlpha = 1;
		this.loading_fade -= dt*0.3;
	}
	else if (!this.ready || !space.ready) //done
	{
		this.ready = true;
		if (this.has_loaded)
			this.active = false;
		xyz.onRoomAssetsLoaded();
	}
}

IntroLoader.prototype.renderSpinner = function(ctx, canvas, angle, non_branded )
{
	ctx.save();
	ctx.translate( canvas.width * 0.5, canvas.height * 0.5 );
	var s = 0.34;
	ctx.scale( s, s );

	if ( !non_branded )
	{
		var tex = this.textures[0];
		if (!tex)
			return;
		ctx.fillColor = [ 1,1,1 ];
		ctx.beginPath();
		ctx.arc(0,0,300,0,Math.PI*2);
		ctx.fill();
		ctx.drawImage( tex, tex.width * -0.5, tex.height * -0.5 );
		tex = this.textures[1];
		ctx.rotate( angle );
		ctx.drawImage( tex, tex.width * -0.5, tex.height * -0.5 );
	}
	else
	{
		var delta = (360/8) * DEG2RAD;
		for (var i = 0; i < 8; ++i)
		{
			var x = Math.cos(delta*i + angle) * 150;
			var y = Math.sin(delta*i + angle) * 150;
			ctx.fillColor = HSLToRGB(i/8,0.5,0.7);
			ctx.beginPath();
			ctx.arc(x,y,30,0,Math.PI*2);
			ctx.fill();
		}
	}

	ctx.restore();
}


IntroLoader.prototype.renderProgressBar = function(ctx, canvas, progress) {
	ctx.fillColor = [ 0, 137/255, 1 ];
	ctx.fillRect( 0, 0, canvas.width * progress, 7 );
}

XYZLauncher.registerModule( IntroLoader, "IntroLoader" );
window.IntroLoader = IntroLoader;

export {IntroLoader};
