export const CORNER_TOP_LEFT = 0;
export const CORNER_TOP_RIGHT = 1;
export const CORNER_BOTTOM_LEFT = 2;
export const CORNER_BOTTOM_RIGHT = 3;
export const CORNER_TOP_CENTER = 4;
export const CORNER_BOTTOM_CENTER = 5;

export const corner_options = {
	type: "enum",
	values: {
		"top-left": CORNER_TOP_LEFT,
		"top-right": CORNER_TOP_RIGHT,
		"bottom-left": CORNER_BOTTOM_LEFT,
		"bottom-right": CORNER_BOTTOM_RIGHT,
		"top-center": CORNER_TOP_CENTER,
		"bottom-center": CORNER_BOTTOM_CENTER
	}
};
