// requires GLUIContext (glui.js)

import Button from "@src/libs/GLUI/Elements/Button";
import { GL } from "@src/libs/litegl";
import clamp from "@src/math/clamp";

GLUI.Context.prototype.TranslucentPanel = function(x, y, w, h, border, color)
{
	var view = xyz.view;
	var tex = view.getTranslucentTexture();
	if(!tex)
		return;
	color = color || [1,1,1,1];
	this.blocked_areas.push([x,y,w,h]);
	y = gl.canvas.height - y - h;
	gl.enable(gl.BLEND);
	gl.blendFunc(gl.SRC_ALPHA,gl.ONE_MINUS_SRC_ALPHA);

	var shader = gl.shaders["translucent_round_rect"];
	if(!shader)
		shader = gl.shaders["translucent_round_rect"] = new GL.Shader( GLUI.Context.prototype.TranslucentPanel.VERTEX_SHADER, GLUI.Context.prototype.TranslucentPanel.FRAGMENT_SHADER );
	var mesh = GL.Mesh.getScreenQuad();
	shader.uniforms({
		u_texture: tex.bind(0),
		u_color: color,
		u_rect: [x,y,x+w,y+h],
		u_border_radius: border,
		u_pix_length: 1,
		u_viewport: gl.viewport_data
	}).draw(mesh);
}

GLUI.Context.prototype.ShadowPanel = function(x,y,w,h,border,blur,color)
{
	var view = xyz.view;
	color = color || [1,1,1,1];
	y = gl.canvas.height - y - h;
	gl.enable(gl.BLEND);
	gl.blendFunc(gl.SRC_ALPHA,gl.ONE_MINUS_SRC_ALPHA);

	var shader = gl.shaders["shadow_round_rect"];
	if(!shader)
		shader = gl.shaders["shadow_round_rect"] = new GL.Shader( GLUI.Context.prototype.TranslucentPanel.VERTEX_SHADER, GLUI.Context.prototype.TranslucentPanel.FRAGMENT_SHADER, { SHADOW:"" } );
	var mesh = GL.Mesh.getScreenQuad();
	shader.uniforms({
		u_color: color,
		u_rect: [x-border*0.5,y-border*0.5,x+w+border,y+h+border],
		u_border_radius: border,
		u_blur_radius: blur,
		u_pix_length: 1,
		u_viewport: gl.viewport_data
	}).draw(mesh);
}

GLUI.Context.prototype.TranslucentPanel.VERTEX_SHADER = "\n\
		precision highp float;\n\
		attribute vec3 a_vertex;\n\
		attribute vec2 a_coord;\n\
		uniform vec4 u_rect;\n\
		uniform vec4 u_viewport;\n\
		varying vec2 v_coord;\n\
		void main() { \n\
			v_coord = a_coord; \n\
			vec2 vertex = a_coord;\n\
			vertex = vertex * ((u_rect.zw - u_rect.xy) / u_viewport.zw) + (u_rect.xy / u_viewport.zw);\n\
			v_coord = vertex; \n\
			gl_Position = vec4(vertex * 2.0 - 1.0, 0.0, 1.0);\n\
		}\n\
	";

GLUI.Context.prototype.TranslucentPanel.FRAGMENT_SHADER = "\n\
		precision highp float;\n\
		varying vec2 v_coord;\n\
		#ifdef SHADOW\n\
		uniform float u_blur_radius;\n\
		#else\n\
		uniform sampler2D u_texture;\n\
		#endif\n\
		uniform vec4 u_rect;\n\
		uniform vec4 u_viewport;\n\
		uniform vec4 u_color;\n\
		uniform float u_pix_length;\n\
		uniform float u_border_radius;\n\
		float distToRect( float minx, float miny, float maxx, float maxy, vec2 p) {\n\
			float dx = max(0.0, max( minx - p.x, p.x - maxx));\n\
			float dy = max(0.0, max( miny - p.y, p.y - maxy));\n\
			return (dx*dx + dy*dy);\n\
		}\n\
		void main() { \n\
			#ifdef SHADOW\n\
				float radius = u_border_radius + u_blur_radius;\n\
				float d = distToRect( u_rect.x + radius, u_rect.y + radius, u_rect.z - radius, u_rect.w - radius, gl_FragCoord.xy);\n\
				if( d > (radius*radius) )\n\
					discard;\n\
				d = sqrt(d);\n\
				gl_FragColor = vec4(u_color.xyz, u_color.a * clamp(1.0 - d / u_border_radius,0.0,1.0) );\n\
			#else\n\
				float d = distToRect( u_rect.x + u_border_radius, u_rect.y + u_border_radius, u_rect.z - u_border_radius, u_rect.w - u_border_radius, gl_FragCoord.xy);\n\
				if( d > (u_border_radius*u_border_radius) )\n\
					discard;\n\
				d = sqrt(d);\n\
				vec4 color = u_color;\n\
				if(d > (u_border_radius - u_pix_length) )\n\
					color.a *= (u_border_radius - d);\n\
				gl_FragColor = color * texture2D(u_texture,v_coord);\n\
			#endif\n\
		}\n\
	";


GLUI.Context.prototype.RadialMenu = function( options, radius, anim )
{
	if(anim === undefined)
		anim = 1;
	anim = clamp(anim,0,1);
	var w = gl.canvas.width;
	var h = gl.canvas.height;
	var num = options.length;
	var angle = (Math.PI * 2) / num;
	radius = radius || 100;
	var clicked = null;

	for(var i = 0; i < num; ++i)
	{
		var item = options[i];
		var x = w*0.5 + Math.cos(angle * i) * radius;
		var y = h*0.5 + Math.sin(angle * i) * radius;
		if( GUI.CircleIconButton(x,y, item.icon ) )
			clicked = item;
	}

	return clicked;
}


GLUI.Context.prototype.CircleIconButton = function( x,y, icon, color, reverse_icon, size )
{
	var alpha = 1;
	if(color != null && color.constructor === Number)
	{
		alpha = color;
		color = null;
	}

	size = size || 56;
	var view = ViewCore.instance; //to load texture
	var r = GUI.HoverArea( x,y, size, size );
	var ctx = gl;
	var hover = r == GLUI.HOVER;
	var offset = hover ? 4 : 0;
	ctx.globalAlpha = alpha;
	if(color)
	{
		ctx.tintImages = true;
		ctx.fillColor = color;
	}
	ctx.drawImage( view.loadTexture( "textures/circle-shadow.png" ), x - offset,y - offset,size + offset*2,size + offset*2);
	ctx.tintImages = false;

	if( icon.constructor === Array)
	{
		GUI.DrawIcon( x + size*0.5, y + size*0.5, size * 0.007 + (hover ? 0.1 : 0), icon,false,reverse_icon ? color:[0,0,0,alpha] );
		//ctx.tintImages = false;
	}
	else if( icon.constructor === String)
	{
		ctx.fillColor = reverse_icon ? [1,1,1,1] : [0,0,0,1];
		ctx.textAlign = "center";
		ctx.font = Math.floor(size * 0.5) + "px Arial";
		ctx.fillText( icon, x + size*0.5, y + size*0.7 );
	}
	ctx.globalAlpha = 1;
	return r == GLUI.CLICKED;
}

GLUI.Context.prototype.RoundTextButton = function( x,y,w, text, text_color, bg_color, alpha )
{
	if( alpha === undefined )
		alpha = 1;
	text_color = text_color || [0,0,0,1];
	bg_color = bg_color || [1,1,1,1];

	var r = GUI.HoverArea( x-w*0.5,y-20, w, 40 );
	var ctx = gl;
	ctx.globalAlpha = alpha;
	ctx.fillColor = (r == GLUI.HOVER) ? text_color : bg_color;
	ctx.beginPath();
	ctx.roundRect(x-w*0.5,y-20,w,40,20);
	ctx.fill();

	ctx.fillColor = (r == GLUI.HOVER) ? bg_color : text_color;
	ctx.font = "14px " + xyz.options.fontFamily;
	ctx.textAlign = "center";
	ctx.fillText( text, x,y+5 );
	ctx.globalAlpha = 1;

	if(r == GLUI.CLICKED)
		this.consumeClick();

	return r == GLUI.CLICKED;
}

GLUI.Context.prototype.ColorPalette = function( x,y,r, value, palette )
{

	this.value_changed = false;
	var ctx = gl;

	//color selector
	ctx.fillColor = [1,1,1,1];
	ctx.beginPath();
	ctx.arc( x, y, r+4, 0, 2*Math.PI );
	ctx.fill();

	ctx.fillColor = palette[ value ];
	ctx.beginPath();
	ctx.arc( x, y, r, 0, 2*Math.PI );
	ctx.fill();

	if( GUI.HoverArea( x - r, y-r, r*2,r*2) == GLUI.CLICKED )
	{
		this.value_changed = true;
		return (value + 1) % palette.length;
	}
	return value;
}

GLUIContext.prototype.InlineMediaPlayer = function(x,y,w,h, media, label, playCallback, pauseCallback, stopCallback, seekCallback )
{
	// Play / pause btn
	if ( Button.call(this, x, y, 32, 32, ( !media || media.paused ) ? GLUI.icons.play : GLUI.icons.pause ) )
	{
		if ( !media || media.paused ) {
			if ( typeof playCallback == "function" )
				playCallback();
		}
		else {
			if ( typeof pauseCallback == "function" )
				pauseCallback();
		}
	}

	// Stop btn
	if ( Button.call(this, x + 34, y, 32, 32, GLUI.icons.stop ) )
	{
		if ( media ) {
			if ( typeof stopCallback == "function" )
				stopCallback();
		}
	}

	// Label
	this.TextField( x + 70, y, w - 100, 28, label );

	// Slider
	if ( media )
	{
		var v = this.Slider( x, y + h - 24, w - 20, 24, media.currentTime / media.duration, 0, 1 );
		if ( this.value_changed ) {
			if ( typeof seekCallback == "function" )
				seekCallback(v);
		}
	}
	else
	{
		this.Slider( x, y + h - 24, w - 20, 24, 0, 0, 1 );
	}
}; //do not remove the semicolon nor the breakline

