import { vec2 } from "gl-matrix";

/**
 * @deprecated
 * @use vec2.rotate
 *
 * @param {vec2} out
 * @param {vec2} vec ?
 * @param {number} angle_in_rad
 * @returns {vec2}
 */
export const vec2Rotate = function(out,vec,angle_in_rad)
{
	const x = vec[0], y = vec[1];
	const cos = Math.cos(angle_in_rad);
	const sin = Math.sin(angle_in_rad);
	out[0] = x * cos - y * sin;
	out[1] = x * sin + y * cos;
	return out;
}

/**
 * for signed angles
 *
 * @param {vec2} a
 * @param {vec2} b
 * @returns {number}
 */
export const vec2Perpdot = function(a, b)
{
	return a[1] * b[0] + -a[0] * b[1];
}

export const vec2ComputeSignedAngle = function( a, b )
{
	return Math.atan2( vec2Perpdot(a,b), vec2.dot(a,b) );
}

/**
 * @deprecated
 * @use vec2.random
 * @link https://glmatrix.net/docs/vec2.js.html#line375
 *
 * @param {vec2} vec
 * @param {number} scale
 * @returns {vec2}
 */
export const vec2Random = function( vec, scale )
{
	scale = scale || 1.0;
	vec[0] = Math.random() * scale;
	vec[1] = Math.random() * scale;
	return vec;
}
