import { RoomController } from "@src/controllers/RoomController";
import { GL } from "@src/libs/litegl";
import { Direction3Constants } from "@src/libs/rendeer/Direction3Constants";
import { Material } from "@src/libs/rendeer/Material";
import { Ray } from "@src/libs/rendeer/Ray";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { vec3 } from "gl-matrix";

// TABLEVIEW CONTROLLER ***************************************
// Used to change interaction to more table focused
export class RoomTableViewController extends RoomController {
	constructor(launcher, space, view, _settings) {
		super();

		RoomTableViewController.instance = this;

		this.xyz = this.launcher = launcher;
		this.name = "tableview";

		this.space = space;
		this.room = space; //legacy
		this.view = view;

		this.cam_speed = 1;
		this.locked_by = null; //participant who locked the board

		this.selected_component = null;
		this.selected_item = null;

		this._last_point = vec3.create();
		this.last_ray = new Ray();

		this.node = new SceneNode();

		this.camera_views = {};

		this.current_tool = null;
		this.dragging_camera = false;
	}

	onEnter() {
		var participant = this.space.local_participant;
		this.view.render_gizmos = false; //render ghosts
		//this.view.smooth_camera = false;

		var comp = this.selected_component;
		if (!comp)
			return;

		var camera = this.view.hard_camera;

		//position camera above
		var center = comp._root.position;
		camera.position[1] = participant.position[1] + 2.5;
		var front = vec3.sub(vec3.create(), camera.position, center);
		vec3.normalize(front, front);
		var eye = vec3.scaleAndAdd(vec3.create(), center, front, 1);
		camera.lookAt(eye, center, [ 0, 1, 0 ]);

		this.space.scene.root.addChild(this.node);

		this.current_tool = null;

		this.setHTMLInterface(false);

		if (comp.onEnterTable)
			comp.onEnterTable(this);
	}

	onLeave() {
		var comp = this.selected_component;
		this.space.scene.root.removeChild(this.node);
		this.setHTMLInterface(true);

		if (comp.onLeaveTable)
			comp.onLeaveTable(this);
	}

	preRender() {
		//prepare participants so they have their own node in the scene that shows the cursor
		for (var i = 0; i < this.space.participants.length; ++i) {
			var participant = this.space.participants[i];
			if (participant.is_local_participant)
				continue;

			if (!participant._table_node) {
				participant._table_node = new SceneNode();
				this.node.addChild(participant._table_node);
				participant._table_node.mesh = "sphere";
				participant._table_node.scaling = [ 0.01, 0.002, 0.01 ];

				var mat = participant._cursor_material = new Material();
				mat.albedo = [ 0, 0, 0, 1 ];
				mat.alphaMode = "BLEND";
				mat.blendMode = "ADD";
				mat.emissive = [ 1.1, 1.5, 1.6 ];
				participant._table_node.material = mat.name = "cursor_" + participant.index;
				StaticMaterialsTable[mat.name] = mat;
			}

			if (!participant.smooth_cursor_position) {
				participant.smooth_cursor_position = vec3.create();
				vec3.copy(participant.smooth_cursor_position, participant.cursor_position);
			}

			vec3.lerp(participant.smooth_cursor_position, participant.smooth_cursor_position, participant.cursor_position, 0.2);
			participant._table_node.position = participant.smooth_cursor_position;
		}
	}

	onRender() {
		var comp = this.selected_component;
		if (!comp)
			return;

		this.preRender();

		LEvent.trigger(comp.entity, "preRenderTable", this.view);

		//render 3D scene
		this.view.render();

		LEvent.trigger(comp.entity, "postRenderTable", this.view);

		var ctx = gl;
		ctx.start2D();

		xyz.call_controller.bubbles_manager.render(1.0);

		this.renderUI();
	}

	renderForeground() {
		//for(var i = 0; i < this.die.length; ++i)
		//	this.die[i].render( this.view );
	}

	renderMarkers() {

	}

	renderUI() {
		var comp = this.selected_component;
		if (!comp)
			return;

		var ctx = gl;
		ctx.start2D();

		//if (this.hover_item)
		//	GUI.cursor = "pointer";

		if (this.selected_component && this.selected_component.renderUI)
			this.selected_component.renderUI(this);
		LEvent.trigger(comp.entity, "renderUITable", this);

		//exit
		if (GUI.CircleIconButton(gl.canvas.width - 70, 20, GLUI.icons.x, 1))
			this.exit();
	}

	exit() {
		this.launcher.setController(this.launcher.call_controller);
	}

	onMouse(e) {
		var r = GUI.onMouse(e);
		if (r || GUI.isPositionBlocked(e.mousex, e.mousey))
			return true;

		var participant = this.space.local_participant;
		//var hard_camera = this.view.hard_camera;
		//var camera = this.view.camera;
		var camera = this.view.hard_camera;
		camera.updateMatrices();
		var right = camera.getLocalVector(Direction3Constants.RIGHT);
		var cursorx = e.canvasx;
		var cursory = e.canvasy;
		var ray = e.ray = camera.getRay(cursorx, cursory, null, this.last_ray);
		var comp = this.selected_component;
		if (!comp)
			return;

		var center = comp.entity.node.getGlobalPosition();
		var up = comp.entity.node.getGlobalVector(Direction3Constants.UP);
		var collides_plane = e.collides_plane = ray.testPlane(center, up);

		if (LEvent.trigger(comp.entity, "mouseEvent", e))
			return;

		if (this.current_tool && this.current_tool.onMouse) {
			if (this.current_tool.onMouse(e))
				return;
		}

		var hover_item = collides_plane ? this.testRayWithItems(ray) : null;
		if (this.hover_item)
			this.hover_item._hover = false;
		this.hover_item = hover_item;
		if (this.hover_item)
			hover_item._hover = true;

		var coll_pos = comp.worldToTable(ray.collision_point);

		if (e.type == "wheel") {
			var direction = (e.wheel > 0) ? 1 : -1;
			if (this.hover_item && this.hover_item.onWheel)
				this.hover_item.onWheel(direction, e);
			else {
				this.view.processMouseWheel(e, "Z");
				this.clampCamera(camera);
			}
		} else if (e.type == "mousedown") {
			//check collision when right click
			if ((e.button == GL.LEFT_MOUSE_BUTTON || e.button == GL.MIDDLE_MOUSE_BUTTON) && ray.testPlane(center, up)) {
				this.dragging_camera = true;
				this._last_point.set(ray.collision_point);
			}

			var item = hover_item;

			if (e.shiftKey && this.selected_item) {
				this.orienting_item = this.selected_item;
				this.orienting_item.orientTo(coll_pos, 0.1);
			} else {
				this.dragging_item = item;
				if (item && item.onMouse)
					item.onMouse(e);
			}
		} else if (e.type == "mousemove") {
			var collides_plane = ray.testPlane(center, up);
			if (collides_plane)
				vec3.copy(participant.cursor_position, ray.collision_point);

			if (e.buttons & GL.RIGHT_MOUSE_BUTTON_MASK) //right
			{
				camera.orbit(e.deltax * -0.002, [ 0, 1, 0 ]);
				camera.orbit(e.deltay * -0.002, right);
				if (camera.position[1] < comp.entity.position[1])
					camera.position[1] = comp.entity.position[1];
			} else if (this.orienting_item && this.orienting_item.orientTo && (e.buttons & GL.LEFT_MOUSE_BUTTON_MASK)) {
				this.orienting_item.orientTo(coll_pos, 0);
			} else if (this.dragging_item && (e.buttons & GL.LEFT_MOUSE_BUTTON_MASK)) {
				if (this.dragging_item.move) {
					this.dragging_item.move(coll_pos, 0);
				}
			} else if ((e.buttons & GL.LEFT_MOUSE_BUTTON_MASK) || (e.buttons & GL.MIDDLE_MOUSE_BUTTON_MASK)) //left & middle
			{
				if (collides_plane && this.dragging_camera) {
					var delta = vec3.create();
					vec3.sub(delta, this._last_point, ray.collision_point);
					//this._last_point.set( ray.collision_point ); //????
					//pan
					var final_pos = vec3.add(vec3.create(), camera.position, delta);

					if (this.isInsideValidArea(final_pos))
						camera.move(delta);
				}
			}
		} else if (e.type == "mouseup") {
			this.dragging_camera = false;
			this.dragging_item = null;
			this.orienting_item = null;

			if (e.click_time < 250) //fast click
			{
				//select item
				var item = hover_item;
				this.onItemClick(item); //could be null
				/*
				else //test board
				{
					var on_board = ray.testPlane( center, up );
					if( this.selected_item && on_board )
					{
						var coll = ray.collision_point;
						var pos = comp.worldToTable( coll );
						if(this.selected_item.onAction)
							this.selected_item.onAction( pos );
						else if(this.selected_item.move)
							this.selected_item.move( pos );
						this.selectItem(null);
					}
					else if( on_board )
					{
						this.showMarker( coll );
					}
				}
				*/
			}
		}
	}

	clampCamera(camera) {
		//max dist
		var front = vec3.sub(vec3.create(), camera.target, camera.position);
		var d = vec3.length(front);
		if (d < 0.1) {
			vec3.normalize(front, front);
			camera.position = vec3.scaleAndAdd(vec3.create(), camera.target, front, -0.1);
		} else if (d > 5) {
			vec3.normalize(front, front);
			camera.position = vec3.scaleAndAdd(vec3.create(), camera.target, front, -5);
		}
	}

	isInsideValidArea(pos) {
		var comp = this.selected_component;
		if (!comp)
			return;
		var camera = this.view.hard_camera;
		var center = comp.entity.position;
		var margin = 3;
		return (pos[0] > (center[0] - margin) && pos[0] < (center[0] + margin) &&
			pos[1] > (center[1] - margin) && pos[1] < (center[1] + margin) &&
			pos[2] > (center[2] - margin) && pos[2] < (center[2] + margin));
	}

	testRayWithItems(ray) {
		var comp = this.selected_component;

		var items = [];

		comp.entity.processActionInComponents("getTableItems", items);

		var selected = null;
		var coll = vec3.create();

		for (var i = 0; i < items.length; ++i) {
			var item = items[i];
			if (!item.getBoundingBox)
				continue;

			var bb = item.getBoundingBox();
			if (!bb)
				continue;
			if (geo.testRayBBox(ray.origin, ray.direction, bb, null, coll, 100)) {
				selected = item;
				break;
			}
		}

		//console.debug(selected);

		return selected;
	}

	onItemClick(selected) {
		//clicked on one
		this.selectItem(selected);
	}

	selectItem(item) {
		if (this.selected_item == item)
			return;

		if (this.selected_item) {
			this.selected_item.selected = false;
			if (this.selected_item.onDeselect)
				this.selected_item.onDeselect();
		}

		this.selected_item = item;

		if (item) {
			this.selected_item.selected = true;
			if (this.selected_item.onSelect)
				this.selected_item.onSelect();
		}
	}

	saveCameraView(cam_id) {
		console.debug("camera saved", cam_id);
		this.camera_views[cam_id] = this.view.hard_camera.serialize();
	}

	restoreCameraView(cam_id, skip_transition) {
		var cam_info = this.camera_views[cam_id];
		if (!cam_info)
			return;
		console.debug("restore camera", cam_id);
		this.view.hard_camera.configure(cam_info);
		if (skip_transition)
			this.view.camera.configure(cam_info);
	}

	setHTMLInterface(v) {
		if (this.xyz.bridge)
			this.xyz.bridge.notify(this.xyz.bridge.ON_BUTTONS_IN_MEETING_ALLOWED, {
				isButtonAllowed: v,          // Hide All Buttons
				muteButtonStatus: v,          // Hide Mute Button
				videoButtonStatus: v,         // Hide Video Button
				screenshareButtonStatus: v,   // Hide Screen Share Button
				chatButtonStatus: v,          // Hide Chat Button
				attendeeListStatus: v,        // Hide Attendee List
				moreOptionsStatus: v,         // Hide More Options Button
				exitButtonStatus: v,          // Hide Exit Button
				fullscreenAllowedStatus: v    // Hide Full screen Button
			});
	}

	onKeyDown(e) {
		var camera = this.view.hard_camera;

		if (this.selected_component && this.selected_component.onKeyDown) {
			if (this.selected_component.onKeyDown(e))
				return true;
		}

		switch (e.code) {
		case "Digit1":
		case "Digit2":
		case "Digit3":
		case "Digit4":
		case "Digit5":
		case "Digit6":
		case "Digit7":
		case "Digit8":
		case "Digit9":
		case "Digit0":
			var num = Number(e.code.substr(5));
			if (e.ctrlKey || e.metaKey)
				return;
			if (e.shiftKey)
				this.saveCameraView(num);
			else
				this.restoreCameraView(num, false);
			e.preventDefault();
			break;
		case "Tab":
		case "F2":
			if (this.xyz.editor_controller)
				this.xyz.setController(this.xyz.editor_controller);
			e.preventDefault();
			break;
		case "Delete":
			//this.deleteSelection();
			break;
		case "KeyD":
			break;
		case "KeyA":
			break;
			//case "KeyQ":
		case "Escape":
			if (this.selected_item)
				this.selectItem(null);
			else
				this.xyz.setController(this.xyz.call_controller);
			break;
		}
	}

	testRayWithEntities(ray, get_collided_node) {
	}

	onUpdate(dt) {
		//this._dice.rotate( dt, [0,1,0] );
		var camera = this.view.hard_camera;
		var delta = vec3.create();

		if (gl.keys.UP || gl.keys["W"])
			delta[2] = -1;
		else if (gl.keys.DOWN || gl.keys["S"])
			delta[2] = 1;
		if (gl.keys.LEFT || gl.keys["A"])
			delta[0] = -1;
		else if (gl.keys.RIGHT || gl.keys["D"])
			delta[0] = 1;

		if (gl.keys["Q"])
			camera.orbit(-0.9 * dt, Direction3Constants.UP);
		else if (gl.keys["E"])
			camera.orbit(0.9 * dt, Direction3Constants.UP);

		var dist = vec3.sqrLen(delta);
		if (dist) {
			camera.getLocalVector(delta, delta);
			delta[1] = 0;
			vec3.normalize(delta, delta);
			camera.move(delta, dt);
		}

		RoomCall.instance.onUpdate(dt);
	}

	//executed on a seat change
	onPartipantChangeSeat(type, event) {
		RoomCall.instance.onPartipantChangeSeat(type, event);
	}

	onParticipantLeave() {
		RoomCall.instance.onParticipantLeave();
	}

	onTick() {
		RoomCall.instance.onTick();
	}

	static launch(comp) {
		if (!RoomTableViewController.instance)
			RoomTableViewController.instance = new RoomTableViewController(xyz, xyz.space, xyz.view);
		RoomTableViewController.instance.selected_component = comp;
		xyz.setController(RoomTableViewController.instance);
		return RoomTableViewController.instance;
	}
}

RoomTableViewController.instance = null;