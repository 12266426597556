import { vec4 } from "gl-matrix";

import ROOM from "@src/engine/room";
//import TextField from "@src/libs/GLUI/Elements/TextField"; //doesnt exists
import GLUI from "@src/libs/glui";

import { corner_options } from "./constants";
import positionToArea from "./positionToArea";

function LGraphGUITextField()
{
	this.addInput("clear",LiteGraph.ACTION);
	this.addOutput("changed",LiteGraph.EVENT);
	this.addOutput("value","string");
	this.properties = { enabled: true, text:"", position: [ 20,20 ], size: [ 140,40 ], corner: LiteGraph.CORNER_TOP_LEFT };
	this.widgets_start_y = 2;
	this._area = vec4.create();
}

LGraphGUITextField.title = "GUITextField";
LGraphGUITextField.desc = "Renders a text input widget";
LGraphGUITextField["@corner"] = corner_options;

LGraphGUITextField.prototype.onRenderGUI = function( ctx, GUI )
{
	if (!this.getInputOrProperty("enabled"))
		return;
	var that = this;
	positionToArea( this.properties.position, this.properties.corner, this._area );
	var parent_pos = this.getInputOrProperty("parent_pos");
	if (parent_pos)
	{
		this._area[0] += parent_pos[0];
		this._area[1] += parent_pos[1];
	}
	this._area[2] = this.properties.size[0];
	this._area[3] = this.properties.size[1];
	this.properties.text = GUI.TextField( this._area[0],this._area[1],this._area[2],this._area[3], this.properties.text, 0, true, false, inner );

	function inner(v)
	{
		this.properties.text = v;
		this.setOutputData(1, this.properties.text );
		that.triggerSlot(0);
	}
}

LGraphGUITextField.prototype.onExecute = function()
{
	var enabled = this.getInputDataByName("enabled");
	if (enabled === false || enabled === true)
		this.properties.enabled = enabled;
	this.setOutputData(1, this.properties.text );
	this._was_pressed = false;
}

LGraphGUITextField.prototype.onGetInputs = function() {
	return [ [ "text","string" ],[ "parent_pos","vec2" ] ];
}

export default LGraphGUITextField;
