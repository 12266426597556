
import Track from './animationTrack';

//Animation contains tracks that contain keyframes
function Animation()
{
	this.name = "";
	this.tracks = [];
	this.duration = 10;
}

Animation.prototype.addTrack = function(track, group)
{
	//search similar and add right after
	if(group)
	for(var i = 0; i < this.tracks.length; ++i)
	{
		var t = this.tracks[i];
		if( t.target_node == track.target_node )
		{
			this.tracks.splice(i+1,0,track);
			return;
		}
	}
	this.tracks.push(track);
}

Animation.prototype.removeTrack = function(track)
{
	//search similar
	var index = this.tracks.indexOf( track );
	if(index != -1)
		this.tracks.splice(index,1);
}

Animation.prototype.applyAnimation = function( root_node, time, interpolation )
{
	for(var i = 0; i < this.tracks.length; ++i)
	{
		var track = this.tracks[i];
		if(track.enabled === false)
			continue;
		track.applyTrack( root_node, time, interpolation );
	}
}

Animation.prototype.serialize = function()
{
	var o = {
		name: this.name,
		duration: this.duration,
		tracks: []
	};

	for(var i = 0; i < this.tracks.length; ++i)
	{
		var track = this.tracks[i];
		o.tracks.push( track.serialize() );
	}	

	return o;
}

Animation.prototype.configure = function(o)
{
	this.name = o.name;
	this.duration = o.duration;
	this.tracks.length = 0;

	for(var i = 0; i < o.tracks.length; ++i)
	{
		var track = new Track();
		track.configure( o.tracks[i] );
		this.tracks.push( track );
	}	

	return o;
}

Animation.prototype.findNearestLeft = function( time )
{
	var nearest_time = 0;
	for(var i = 0; i < this.tracks.length; ++i)
	{
		var track = this.tracks[i];
		var index = track.findTimeIndex( time );
		if(index == -1)
			continue;
		var keyframe_time = track.data[index];
		if( keyframe_time > nearest_time )
			nearest_time = keyframe_time;
	}
	return nearest_time;
}

Animation.prototype.findNearestRight = function( time )
{
	var nearest_time = this.duration;
	for(var i = 0; i < this.tracks.length; ++i)
	{
		var track = this.tracks[i];
		var index = track.findTimeIndex( time );
		if(index == -1)
			continue;
		var keyframe_time = track.data[index + 1];
		if( keyframe_time != null && keyframe_time < nearest_time )
			nearest_time = keyframe_time;
	}
	return nearest_time;
}

export default Animation;
