import BaseComponent from "@src/engine/components/baseComponent";
import Label from "@src/libs/GLUI/Elements/Label";
class AnimationComponent extends BaseComponent {
    constructor() {
        super();
        this.duration = 10;
        this.dollyDistanceAnimation = 0;
        this.panDegreesAnimation = 0;
        this.truckDistanceAnimation = 0;
        this.pedestalDistanceAnimation = 0;
        this.shiftXDistanceAnimation = 0;
        this.shiftYDistanceAnimation = 0;
        this.tiltDegreesAnimation = 0;
        this.fovFactorAnimation = 0;
        this.sliderAnimation = 0;
    }
    serialize(o = {}) {
        o.duration = this.duration;
        o.dollyDistanceAnimation = this.dollyDistanceAnimation;
        o.panDegreesAnimation = this.panDegreesAnimation;
        o.truckDistanceAnimation = this.truckDistanceAnimation;
        o.pedestalDistanceAnimation = this.pedestalDistanceAnimation;
        o.shiftXDistanceAnimation = this.shiftXDistanceAnimation;
        o.shiftYDistanceAnimation = this.shiftYDistanceAnimation;
        o.tiltDegreesAnimation = this.tiltDegreesAnimation;
        o.fovFactorAnimation = this.fovFactorAnimation;
        o.sliderAnimation = this.sliderAnimation;
        return o;
    }
    configure(o) {
        o.duration !== undefined ? this.duration = o.duration : this.duration = 1;
        o.dollyDistanceAnimation !== undefined ? this.dollyDistanceAnimation = o.dollyDistanceAnimation : this.dollyDistanceAnimation = 1;
        o.panDegreesAnimation !== undefined ? this.panDegreesAnimation = o.panDegreesAnimation : this.panDegreesAnimation = 1;
        o.truckDistanceAnimation !== undefined ? this.truckDistanceAnimation = o.truckDistanceAnimation : this.truckDistanceAnimation = 1;
        o.pedestalDistanceAnimation !== undefined ? this.pedestalDistanceAnimation = o.pedestalDistanceAnimation : this.pedestalDistanceAnimation = 1;
        o.shiftXDistanceAnimation !== undefined ? this.shiftXDistanceAnimation = o.shiftXDistanceAnimation : this.shiftXDistanceAnimation = 1;
        o.shiftYDistanceAnimation !== undefined ? this.shiftYDistanceAnimation = o.shiftYDistanceAnimation : this.shiftYDistanceAnimation = 1;
        o.tiltDegreesAnimation !== undefined ? this.tiltDegreesAnimation = o.tiltDegreesAnimation : this.tiltDegreesAnimation = 1;
        o.fovFactorAnimation !== undefined ? this.fovFactorAnimation = o.fovFactorAnimation : this.fovFactorAnimation = 1;
        o.sliderAnimation !== undefined ? this.sliderAnimation = o.sliderAnimation : this.sliderAnimation = 0;
    }
    onRenderInspector(ctx, x, y, w, h, editor) {
        Label.call(GUI, x, y, 100, 20, "Duration");
        this.duration = GUI.Number(x + 200, y, w - 200, 20, this.duration, 0.1, 0.8);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "Dolly");
        this.dollyDistanceAnimation = GUI.Number(x + 200, y, w - 200, 20, this.dollyDistanceAnimation);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "Pan");
        this.panDegreesAnimation = GUI.Number(x + 200, y, w - 200, 20, this.panDegreesAnimation, 0.1);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "Truck");
        this.truckDistanceAnimation = GUI.Number(x + 200, y, w - 200, 20, this.truckDistanceAnimation);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "Pedestal");
        this.pedestalDistanceAnimation = GUI.Number(x + 200, y, w - 200, 20, this.pedestalDistanceAnimation);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "ShiftX");
        this.shiftXDistanceAnimation = GUI.Number(x + 200, y, w - 200, 20, this.shiftXDistanceAnimation);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "ShiftY");
        this.shiftYDistanceAnimation = GUI.Number(x + 200, y, w - 200, 20, this.shiftYDistanceAnimation);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "Tilt");
        this.tiltDegreesAnimation = GUI.Number(x + 200, y, w - 200, 20, this.tiltDegreesAnimation, 0.1);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "Fov");
        this.fovFactorAnimation = GUI.Number(x + 200, y, w - 200, 20, this.fovFactorAnimation, 0.1);
        y += 30;
        Label.call(GUI, x, y, 100, 20, "Slider");
        this.sliderAnimation = GUI.Number(x + 200, y, w - 200, 20, this.sliderAnimation);
    }
}
AnimationComponent.componentName = "Animations";
AnimationComponent.icon = [11, 7];
export default AnimationComponent;
