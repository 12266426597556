const Label = function(x, y, w, h, text, color, fontsize, mousable, align) {
    var ctx = this.context;
    ctx.font = (fontsize || Math.floor(h * 0.8)) + 'px ' + this.fontFamily;
    align = align || 'left';
    ctx.textAlign = align;
    ctx.fillColor = color || [0.9, 0.9, 0.9, ctx.globalAlpha];
    if (align === 'left') {
        ctx.fillText(String(text), x, y + h * 0.7);
    } else if (align === 'right') {
        ctx.fillText(String(text), x + w, y + h * 0.7);
    } else //center
    {
        ctx.fillText(String(text), x + w * 0.5, y + h * 0.7);
    }

    if (mousable) {
        return this.HoverArea(x, y, w, h);
    }
    ctx.textAlign = "left";
}

export default Label;
