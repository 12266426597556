//copy methods from origin to target
const extendClass = function(target, origin) {
    for (var i in origin) {
        //copy class properties
        if (target.hasOwnProperty(i)) continue;
        target[i] = origin[i];
    }

    if (origin.prototype) {
        //copy prototype properties
        var prop_names = Object.getOwnPropertyNames(origin.prototype);
        for (
            var i = 0;
            i < prop_names.length;
            ++i //only enumerables
        ) {
            var name = prop_names[i];
            //if(!origin.prototype.hasOwnProperty(name))
            //	continue;

            if (target.prototype.hasOwnProperty(name))
                //avoid overwritting existing ones
                continue;

            //copy getters
            if (origin.prototype.__lookupGetter__(name))
                target.prototype.__defineGetter__(
                    name,
                    origin.prototype.__lookupGetter__(name)
                );
            else target.prototype[name] = origin.prototype[name];

            //and setters
            if (origin.prototype.__lookupSetter__(name))
                target.prototype.__defineSetter__(
                    name,
                    origin.prototype.__lookupSetter__(name)
                );
        }
    }

    if (!target.hasOwnProperty("superclass"))
        Object.defineProperty(target, "superclass", {
            get: function () {
                return origin;
            },
            enumerable: false,
        });
};

export default extendClass;
