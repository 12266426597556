//This APP allows to test all features
function ControlsApp(surface )
{
	this.xyz = XYZLauncher.instance;
	this.surface = surface;
}

//if "canvas" it will be rendered inside a regular canvas2D and uploaded as a texture every frame, otherwise inside a WebGL texture
//ControlsApp.output_mode = "canvas";  // or null for default (webgl)

ControlsApp.prototype.onRenderInspector = function( GUI, x, y, w, h, surface )
{

}

//only called if active
ControlsApp.prototype.onMouse = function(e, active)
{
}

ControlsApp.prototype.onEnter = function()
{
	console.debug("entering!");
}

ControlsApp.prototype.onLeave = function()
{
	console.debug("leaving!");
}


ControlsApp.prototype.update = function(dt, t)
{

}

ControlsApp.prototype.render = function(ctx, tex, GUI, mouse, viewport )
{
	var xyz = XYZLauncher.instance;
	this.width = tex.width;
	this.height = tex.height;

	var x = viewport[0];
	var y = viewport[1];
	var w = viewport[2];
	var h = viewport[3];

	if ( this.surface.alphaMode === "OPAQUE")
	{
		ctx.fillColor = [ 0.2,0.2,0.2,1 ];
		ctx.fillRect(x,y,w,h);
	}
	else
		ctx.clearRect(x,y,w,h);

	//ctx.fillColor = [1,1,1,1];
	//GUI.Label( x + 10,y + 10,100,30,"Control App");

	var space = this.surface.space;
	space.processAction("onRenderGraph",[ ctx,GUI,tex ],true);
}

//example of Global UI (outside the panel)
ControlsApp.prototype.renderGlobalUI = function(ctx, GUI)
{
	/*
	if( Button.call(GUI, ctx.canvas.width * 0.5 - 110,30, 200, 30,"Clear All") )
	{
	}
	*/
}


ControlsApp.app_name = "Controls";

export default ControlsApp;
