import Entity from "@src/engine/entity";
import { generateUID } from "@src/engine/generateUID";
import { UID_PREFIX } from "@src/engine/rendeer/UID_PREFIX";
import ROOM from "@src/engine/room";
import { clone } from "@src/engine/Room/clone";
import { cloneObject } from "@src/engine/Room/cloneObject";
import { getFolder, getFullPath } from "@src/engine/Room/file-utils";
import { getClassName } from "@src/engine/Room/getClassName";
import { getExtension } from "@src/engine/Room/getExtension";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { RD } from "@src/libs/rendeer";
import { Camera } from "@src/libs/rendeer/Camera";
import { Scene } from "@src/libs/rendeer/Scene";
import Animation from "@src/libs/rendeer/animation";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { testRayWithNodes } from "@src/libs/rendeer/testRayWithNodes";
import { vec3 } from "gl-matrix";


//Holds all the info about a single room
//Should allow multiple spaces existing simulatenously

//contains a description of the room
export class RoomSpace extends ComponentContainer {
	uid = generateUID("SCN");
	last_index = 0;
	name = "";
	variation = "";
	description = "";
	status = "public";
	ready = false; //will be set to true when room data is ready
	ready_assets = false; //will be set to true when all is loaded, toggled from space.onChangeResourcesLoading
	tags = {
		mobileReady: false,
		comingSoon: false,
		isFeatured: false
	};

	categories = {};

	root_path = "";

	//skybox info
	environment = {};

	//default view
	camera_info = {};

	//to send data to other users
	bridge = null;

	//careful, also overwritten in this.clear()
	fx = {};

	//this contains MARKERS, PREFABS, PROBES, etc
	root = new Entity();
	entities_by_uid = {};
	entities_by_name = {};
	entities_by_index = {};
	markers = [];

	components_by_uid = {};

	/**
	 *
	 * @type {RoomParticipant[]}
	 */
	participants = [];

	play_mode = ROOM.PLAY_MODES.STOP;
	global_time = 0;
	anim_time = 0;
	animation = null;
	ping = -1;

	//first participant created is assigned as local
	local_participant = null;

	settings = {
		render_skybox: true,
		allow_walking: true,
		hide_nametags: false,
		tablets_texture: null,
		/**
		 * @deprecated implemented in webroom-next instead, see RMVP-15061
		 * @type {string}
		 */
		watermark: null,
		/**
		 * @deprecated implemented in webroom-next instead, see RMVP-15061
		 * @type boolean
		 */
		no_branding: false,
		allow_apps_on_tablets: true,
		native_menu: false
	};

	//extra info
	info = null;

	//to track files being loaded, updated from components loading, like PrefabRenderer
	loading_info = [];

	//holds the whole scene
	scene = new Scene();

	//holds extra stuff
	scripts = [];

	surface_stream = null; //where the shared screen to display on tvs is stored

	/**
	 * @type {AbortController | null}
	 */
	roomFetchAbortController = null;

	/**
	 *
	 * @param {ViewCore} view
	 */
	constructor(view) {
		super();

		this.view = view;


		this.resetFX();

		this.root.is_root = true;
		this.root._space = this;

		this.entities_by_uid[this.uid] = this;

		//reset to default
		this.clear();

		this.scene.root.addChild(this.root.node);

		//hack so components can be added here too
		this.space = this._space = this;

		this.mode = "call";

		//for easy access
		if (!ROOM.space) {
			ROOM.space = this;
			ROOM.scene = this; //LEGACY
		}

	}

	/**
	 * object's lifecycle: shutdown
	 * this method unloads the loaded room and stops the RoomSpace service
	 * @returns {void}
	 */
	shutdown() {
		for (const nameEntityPair of Object.entries(this.entities_by_name)) {
			nameEntityPair[1].removeAllComponents();
		}

		this.clear();
		this.scene = new Scene();
		this.participants = [];	//fixme introduce and invoke dispose method on RoomParticipant
		this.local_participant = null;
	}

	on(event, callback, instance) {
		LEvent.bind(this, event, callback, instance);
	}

	off(event, callback, instance) {
		LEvent.unbind(this, event, callback, instance);
	}

	/**
	 * @param {number} dt delta time in seconds since the last update
	 */
	update(dt) {
		this.global_time += dt;

		if (this.play_mode === ROOM.PLAY_MODES.PLAY && this.animation) {
			this.setTime(this.anim_time + dt);
			if (this.anim_time > this.animation.duration)
				this.play_mode = ROOM.PLAY_MODES.STOP;
		}

		//scenes can contain components too
		this.processAction("update", [ dt, this.global_time ], true);

		//not very important but just in case
		//this.scene.update(dt); //disabled because it takes lots of resources

		//update tweens
		Tween.update(dt); //this shouldnt be here...
	}

	/**
	 *
	 * @param {number} dt
	 * @param {Camera} camera
	 */
	fixedUpdate(dt, camera) {
		this.processAction("fixedUpdate", [ dt, camera, this.global_time ], true);
	}

	//execute something in every entity, component and participant
	processAction(func_name, parameters, expand_parameters) {
		this.processActionInComponents(func_name, parameters, expand_parameters);
		this.root.processActionInComponents(func_name, parameters, expand_parameters);

		for (let i = 0; i < this.participants.length; ++i) {
			const participant = this.participants[i];
			participant.processActionInComponents(func_name, parameters, expand_parameters);
		}
	}

	findComponents(comp_name, out) {
		out = out || [];
		out = super.findComponents(comp_name, out);
		this.root.findComponents(comp_name, out);
		return out;
	}

	//searches by name
	getEntity(name, filter) {
		if (this.entities_by_name[name] && !filter)
			return this.entities_by_name[name];

		return this.root.getEntity(name, filter);
	}

	getEntityByIndex(index) {
		var ent = this.entities_by_index[index];
		if(ent)
			return ent;

		return inner( this.root, index );

		//search by index
		function inner( ent, index)
		{
			if( ent.index === index )
				return ent;
			for (let i = 0; i < ent.children.length; ++i) {
				const child = ent.children[i];
				var r = inner( child, index );
				if(r)
					return r;
			}
			return null;
		}
	}

	//returns first
	getEntityByComponent(comp) {
		return this.entities.find(a => a.hasComponent(comp));
	}

	/**
	 *
	 * @param {Entity[]} [r]
	 * @returns {Entity[]}
	 */
	getAllEntities(r) {
		return this.root.getAllEntities(r);
	}

	//returns all
	getEntitiesByComponent(comp) {
		return this.root.getEntitiesByComponent(comp);
	}

	//given a locator, it returns info about the object being affected
	setPropertyFromPath(path, index, v) {
		index = index || 0;
		let ent = null;
		if (path.length < index)
			return;

		if (path[index][0] === UID_PREFIX)
			ent = this.getEntityById(path[index]); //also works for root scene
		else {
			ent = path[index] === "#space" ? this : this.getEntity(path[index]);
		}

		if (path.length === index + 1) {
			//cannot assign an entity
			console.error("cannot assign an entity from a path");
			return;
		}

		if (!ent)
			return null;

		if (ent !== this) {
			ent.setPropertyFromPath(path, index + 1, v);
			return;
		}

		let comp = null;
		if (path[index + 1][0] === UID_PREFIX)
			comp = ent.getComponentByUId(path[index + 1]);
		else
			comp = ent.getComponent(path[index + 1]);
		if (!comp) //not found
			return;
		if (path.length === index + 2) //assigning the component??
		{
			console.error("cannot assign a component from a path");
			return;
		}
		comp.setPropertyFromPath(path, index + 2, v);
		return;
	}

	//given a locator, it returns info about the object being affected
	getPropertyFromPath(path, index) {
		index = index || 0;
		let ent = null;
		if (path.length < index)
			return;

		if (path[index][0] === UID_PREFIX)
			ent = this.getEntityById(path[index]); //also works for root scene
		else {
			ent = path[index] === "#space" ? this : this.getEntity(path[index]);
		}

		if (path.length === index + 1)
			return ent;

		if (!ent)
			return null;

		if (ent !== this)
			return ent.getPropertyFromPath(path, index + 1);

		let comp = null;
		if (path[index + 1][0] === UID_PREFIX)
			comp = ent.getComponentByUId(path[index + 1]);
		else
			comp = ent.getComponent(path[index + 1]);
		if (!comp) //not found
			return;
		if (path.length === index + 2) //assigning the component??
			return comp;

		return comp.getPropertyFromPath(path, index + 2);
	}

	//must return INFO
	resolveLocator(locator) {
		return this.resolveLocatorFromPath(locator.split("/"));
	}

	//given a locator in path form (array), it returns info about the object being affected
	resolveLocatorFromPath(path, index) {
		index = index || 0;
		let target = null;
		if (!path.length)
			return;

		if (path[index][0] === ROOM.locator_chars.material)
			target = StaticMaterialsTable[path[index].substr(1)]; //also works for root scene
		else if (path[index][0] === UID_PREFIX)
			target = this.getEntityById(path[index]); //also works for root scene
		else if (path[index] === "#space")
			target = this;
		else
			target = this.getEntity(path[index]);

		if (!target)
			return null;

		if (path.length === index + 1) {
			return {
				node: target,
				target: target,
				name: target.name,
				value: target,
				type: target.constructor.class_type
			};
		}

		//special case
		if (target === this) {
			let comp = null;
			if (path[index + 1][0] === UID_PREFIX)
				comp = this.getComponentByUId(path[index + 1]);
			else
				comp = this.getComponent(path[index + 1]);
			if (!comp)
				return;
			if (path.length === 2) //requesting the component
			{
				return {
					node: target,
					target: comp,
					name: getClassName(comp.constructor),
					value: comp,
					type: "component"
				};
			}

			if (comp.resolveLocatorFromPath)
				return comp.resolveLocatorFromPath(path, index + 2);
			//???????
			if (path.length === index + 3 && comp[path[index + 2]] !== undefined)
				return comp.getPropertyInfoFromPath(path, index + 2);

			//tessst!!!!
			return {
				node: target,
				target: comp,
				name: path[index + 2],
				value: comp[path[index + 2]],
				type: typeof (comp[path[index + 2]])
			};
		}

		if (target.resolveLocatorFromPath)
			return target.resolveLocatorFromPath(path, index + 1);

		return {
			node: null,
			target: target,
			name: path[index + 1],
			value: target[path[index + 1]],
			type: typeof (target[path[index + 1]])
		};
	}

	getLocator() {
		return "#space";
	}

	//searches by uid
	getEntityById(uid, filter) {
		if (this.entities_by_uid[uid] && !filter)
			return this.entities_by_uid[uid];

		//search by name
		return this.root.getEntityById(uid, filter);
	}

	//Retrieves an array of all entities of a given type
	getEntitiesOfType(type) {
		return this.root.getEntitiesOfType(type);
	}

	addEntity(entity) {
		return this.root.addEntity(entity);
	}

	removeEntity(entity) {
		if (entity._space !== this._space)
			throw ("cannot remove entity from other space");
		entity._parent.removeEntity(entity);
	}

	//***************************
	addParticipant(participant) {
		const index = this.participants.indexOf(participant);
		if (index !== -1) {
			console.error("cannot add participant to room, already in the room");
			return;
		}

		//first participant is the local participant
		if (!this.local_participant)
			this.local_participant = participant;
		participant.space = this;
		this.participants.push(participant);
		participant.onEnterSpace(this);

		if (this.onParticipantEnters)
			this.onParticipantEnters(participant);
		LEvent.trigger(this, "participant_enter", participant);
		terminal.log(" + User joined: " + participant.getUsername());
	}

	getParticipant(id) {
		for (let i = 0; i < this.participants.length; ++i)
			if (this.participants[i].id === id)
				return this.participants[i];
		return null;
	}

	/**
	 *
	 * @param {RoomParticipant} participant
	 */
	removeParticipant(participant) {
		const space = this.space;
		const index = this.participants.indexOf(participant);
		if (index === -1) {
			console.error("cannot remove participant, not in a space");
			return;
		}

		participant.onLeftSpace();
		participant.space = null;
		this.participants.splice(index, 1);

		//for controller
		if (this.onParticipantLeave)
			this.onParticipantLeave(participant, space);

		LEvent.trigger(this, "participant_leave", participant);

		// Remove participant from native module
		if (xyz.native_mode) {
			TmrwModule.removeParticipant(participant.id, participant.name ? participant.name : participant.id);
		}

		terminal.log(" + User left: " + participant.getUsername());
	}

	sortEntities() {
		this.root.sortEntities();
	}

	resetFX() {
		this.fx = {
			exposure: 1,
			illumination: 1,
			illumination_gamma: 1,
			brightness: 1,
			contrast: 1,
			participants_basecolor: [ 1, 1, 1 ],
			participants_brightness: 1,
			glow: {
				intensity: 0.3,
				persistence: 0.6,
				threshold: 1
			},
		};

		//Javi: not sure about this new dirty flag...
		this.native_dirty = true;

		this.fx_loaded = false;
	}

	clear() {
		this.uid = generateUID("SCN");
		const d = new Date();
		this.timestamp = d.getDate() + "/" + (1 + d.getMonth()) + "/" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
		this.last_index = 0;
		this.variation = "";
		this.description = "";
		this.tags = {
			mobileReady: false,
			comingSoon: false,
			isFeatured: false
		};
		this.categories = {};

		this.environment = {
			url: "",
			rotation: 0,
			exposure: 1,
			color: [ 0.5, 0.5, 0.5 ]
		};

		this.settings = {
			render_skybox: true,
			allow_walking: true,
			hide_nametags: false,
			tablets_texture: null,
			allow_apps_on_tablets: true
		};

		//this.fx = ...
		this.resetFX();

		this.camera_info = {
			position: [ 2, 2, -2 ],
			target: [ 0, 0.5, 0 ],
			up: [ 0, 1, 0 ],
			fov: 60,
			min_height: 0.01,
			max_height: 3,
			max_distance: 5
		};

		this.removeAllComponents()
		this.root.clear();
		this.root.name = "root";

		this.entities_by_uid = {};
		this.entities_by_name = {};
		this.entities_by_index = {};
		this.markers = [];

		this.entities_by_uid[this.uid] = this; //not sure if needed

		this.configurations = [];

		this.info = null;

		LEvent.trigger(this, "clear");
	}

	//loads the JSON file with the Room info
	//url must be local, not global
	load(url, on_complete, _on_error) {

		if(this.roomFetchAbortController){
			this.roomFetchAbortController.abort();
		}

		this.roomFetchAbortController = new AbortController();

		const that = this;
		const folder = getFolder(url);
		let full_url = getFullPath(url);
		const index = url.lastIndexOf("/");
		let filename = url.substr(index + 1);
		const ext = getExtension(filename);
		LEvent.trigger(this, "start_loading", url);
		if (!ext) {
			full_url += "/room.json";
			filename = "room.json";
		}

		//nocache
		full_url += "?nocache=" + Math.random() + (getTime().toFixed(6));

		if (ROOM.credentials) {
			if (ROOM.credentials.headers)
				ROOM.credentials.headers["Content-Type"] = "application/json";
			else
				ROOM.credentials.headers = { "Content-Type": "application/json" };
		}

		//remove all
		this.clear();
		this.loading = true;
		this.loading_json = true;

		const fetchOptions = {...ROOM.credentials, signal: this.roomFetchAbortController.signal}

		fetch(full_url, fetchOptions)
			.then(response => {
				if (!response.ok)
					throw new Error("HTTP " + response.status + ":" + response.statusText);
				return response.json();
			})
			.then(function (data) {
				that.url = url;
				that.filename = filename;
				that.folder = folder;
				that.loading = false;
				that.loading_json = false;
				that._original_data = JSON.stringify(data);
				that.fromJSON(data, inner);

				//after all scripts are loaded and json set
				function inner() {
					if (on_complete)
						on_complete(that, data);
				}
			})
			.catch(error => {
				if(error.name === "AbortError")
					console.debug("Room fetch canceled");
				else
					throw error;
			})
			.finally(() => {
				this.roomFetchAbortController = null;
			});
	}

	/**
	 * loads included scripts and triggers events, besides configure
	 * @param {*} data
	 * @param {function():void} callback
	 */
	fromJSON(data, callback) {
		const on_ready = () => {
			this.configure(data);
			LEvent.trigger(this, "ready");
			if (callback)
				callback();
		}

		if (data.scripts) {
			console.debug("loading external scripts...");
			ROOM.loadScripts(data.scripts, on_ready, true);
		} else {
			on_ready();
		}
	}

	configure(json) {
		//clear
		this.clear();

		this.uid = json.uid || generateUID("SCN");
		if (this.uid[0] !== UID_PREFIX)
			this.uid = generateUID("SCN"); //LEGACY
		this.last_index = json.last_index || 0;
		this.name = json.name || "";
		this.status = json.status || "public";
		if (json.tags) {
			this.tags = json.tags;
		} else { //legacy
			this.tags = {
				mobileReady: json.mobileReady || false,
				comingSoon: json.comingSoon || false,
				isFeatured: false
			};
		}

		this.categories = json.categories || {};

		this.variation = json.variation || "";
		this.description = json.description || "";
		this.entities_by_uid = {}; //erase old
		this.entities_by_uid[this.uid] = this;

		this.preview_url = json.preview_url || null;
		this.preview_featured_url = json.preview_featured_url || null;

		if (json.fx)
			for (var i in json.fx) {
				this.fx[i] = json.fx[i];
			}

		// For compatibility with old rooms, which don't have native_fx section!
		this.fx_loaded = true;

		//LEGACY
		if (!this.fx.glow)
			this.fx.glow = {
				intensity: 0.3,
				persistence: 0.6,
				threshold: 1.3
			};
		if (this.fx.glow && this.fx.glow.threshold === null)
			this.fx.glow.threshold = 1.3;
		if (!this.fx.illumination_gamma)
			this.fx.illumination_gamma = 1;

		//Javi: no need to apply now, wait to View to do it
		//if (xyz.native_mode && o.fx.native_fx )
		//	nativeEngine.setAllFX( o.fx.native_fx );
		this.native_dirty = true;

		if (json.config)
			this.config = json.config;

		this.timestamp = json.timestamp || "//";

		if (json.settings)
			this.settings = cloneObject(json.settings);
		if (this.settings.allow_walking === undefined)
			this.settings.allow_walking = true; //LEGACY
		if (this.settings.hide_nametags === undefined)
			this.settings.hide_nametags = false; //LEGACY
		if (this.settings.tablets_texture === undefined)
			this.settings.tablets_texture = null; //LEGACY
		if (this.settings.allow_apps_on_tablets === undefined)
			this.settings.allow_apps_on_tablets = true; //LEGACY

		if (json.scene) {
			if (json.scene.environment)
				this.environment = json.scene.environment;
			if (this.environment.url)
				this.environment.url = ROOM.replaceExtension(this.environment.url);

			if (!this.environment.color) //LEGACY
				this.environment.color = [ 0.5, 0.5, 0.5 ];

			if (json.scene.camera) {
				const cam_info = json.scene.camera;
				for (var i in cam_info)
					this.camera_info[i] = cam_info[i];
			}
			if (json.scene.entities) {
				this.root.configure(json.scene);
			}
		}

		if (json.info)
			this.info = json.info;

		if (json.scripts)
			this.scripts = json.scripts;

		if (json.animation) {
			// Andrey: This code crashes
			this.animation = new Animation();
			this.animation.configure(json.animation);
		}

		if (this.view)
			this.view.updateFromSpace(this); //here we update everything for rendering and on native

		this.configureComponents(json);

		if (json.global_settings)
			xyz.updateSettings(json.global_settings);

		LEvent.trigger(this, "configure", json);
	}

	//Dumps an object with all the scene info (used for undo)
	serialize() {
		const json = {
			uid: this.uid,
			last_index: this.last_index,
			name: this.name,
			status: this.status,
			tags: this.tags,
			categories: this.categories,
			variation: this.variation || "",
			description: this.description || "",
			preview_url: this.preview_url || null,
			preview_featured_url: this.preview_featured_url || null
		};

		if (this.info) {
			json.info = this.info;
			json.version = ROOM.version;
		}

		json.scene = {
			environment: this.environment,
			camera: this.camera_info,
			entities: []
		};

		const d = new Date();
		json.timestamp = d.getDate() + "/" + (1 + d.getMonth()) + "/" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
		json.settings = cloneObject(this.settings);

		if (this.animation)
			json.animation = this.animation.serialize();

		const entities_group = this.root.serialize();
		json.scene.entities = entities_group.entities;
		json.fx = clone(this.fx);
		if (json.fx && json.fx.native)
			json.fx.native = {};

		if (xyz.native_mode)
			json.fx.native_fx = nativeEngine.getAllFX();

		if (this.scripts.length)
			json.scripts = this.scripts.concat();

		this.serializeComponents(json);
		this.serializeMaterialsCache(json);

		LEvent.trigger(this, "serialize", json);

		return json;
	}

	serializeMaterialsCache(o) {
		if (xyz.native_mode) {
			o.materialsCache = nativeEngine.getMaterialsCache();
		}
	}

	//called from PrefabRenderer or other components when preloading stuff or finished loading it
	//also called from xyz.emRoomLoaded to be notified from native
	onChangeResourcesLoading(_url) {
		this.ready_assets = this.loading_info.length === 0;
	}

	setTime(time) {
		if (!this.animation)
			return;

		if (this.anim_time === time)
			return;

		this.anim_time = time;

		const animation = this.animation;

		for (let i = 0; i < animation.tracks.length; ++i) {
			const track = animation.tracks[i];
			const target_name = track.target_node;
			if (!target_name)
				continue;
			const target_info = this.resolveLocator(target_name);
			/*
			if( target_name[0] == "@" )
				target = this.getEntityById( target_name );
			else
				target = this.getEntity( target_name );
			*/
			if (!target_info)
				continue;
			const target = target_info.target;
			track._target = target;
			const sample = track.getSample(time, RD.LINEAR);
			if (target.setPropertyFromPath)
				target.setPropertyFromPath(track.target_property.split("/"), 0, sample);
			else
				target[track.target_property] = sample;
		}
	}

	//when loading, tells you how many res have been loaded
	getLoadRatio() {
		if (globalThis.TmrwModule !== undefined)
			return xyz.getLoadingProgress();
		let loaded = 0;
		let total = 0;
		for (let i = 0; i < this.loading_info.length; ++i) {
			const info = this.loading_info[i];
			loaded += info.loaded;
			total += info.total;
		}

		return loaded / total;
	}

	//Crawls the scene tree upwards to find the entity associated with this node
	getSceneNodeEntity(node) {
		if (node.room_entity)
			return node.room_entity;
		if (node.parentNode)
			return this.getSceneNodeEntity(node.parentNode);
		return null;
	}

	//returns a SceneNode by its given name
	getSceneNode(node_name) {
		return this.scene.root.findNodeByName(node_name);
	}

	//test ray against all nodes
	testRay(ray, collision, max_dist, layers) {
		return this.scene.testRay(ray, collision, max_dist, layers, true);
	}

	/**
	 * checks which node is crossing the ray, returns the entity
	 * called from {@link RoomCall#onMouse}
	 * @param {Ray} ray
	 * @param {Camera} camera
	 * @param {boolean} [get_collided_node]
	 * @returns {ISceneNode|null}
	 */
	testRayWithInteractiveNodes(ray, camera, get_collided_node = false) {

		/**
		 *
		 * @type {ISceneNode[]}
		 */
		const interactive_nodes = [];

		// hack to fix legacy code just in case...
		if (camera && camera.constructor !== Camera) {
			console.error("testRayWithInteractiveNodes called with wrong parameters");
			camera = null
			get_collided_node = true;
		}

		if (!testRayWithNodes.frame_collision) {
			testRayWithNodes.frame_collision = 1;
		}

		testRayWithNodes.frame_collision++;

		const nativeEngine = globalThis.nativeEngine;

		if (nativeEngine) {
			// Andrey: New native physics API doesn't support ray-tracing on per-entity/node basis
			// Layer-Mask 1 means static mesh nodes only;
			// Layer-Mask 2 means interactive nodes only;
			const ent = nativeEngine._room.testRay(ray.origin, ray.direction, 100.0, 2);
			if (ent) {
				let node = ent.lastCollidedNode();
				if (!get_collided_node)
					node = ent.getRootNode();
				return node;
			}
		}

		// If native node - not found let's continue with JS nodes

		// gather interactive nodes
		const entities = this.getAllEntities();
		for (let i = 0; i < entities.length; ++i) {
			const entity = entities[i];
			entity.getInteractiveNodes(interactive_nodes);
		}

		const participants = this.participants;
		for (let i = 0; i < participants.length; ++i) {
			const participant = participants[i];
			participant.getInteractiveNodes(interactive_nodes, camera);
		}

		// test ray collision with nodes
		const coll = vec3.create();

		return testRayWithNodes(
			ray,
			interactive_nodes,
			coll,
			Infinity,
			0xFFFF,
			false,
			get_collided_node
		);
	}

	playSound(src, volume = 0.1) {
		if (!this._audio)
			this._audio = document.createElement("audio");

		this._audio.src = src;
		this._audio.autoplay = true;
		this._audio.loop = true;
		this._audio.volume = volume;

		return this._audio;
	}

	addMarker(pos3D, type, duration, options) {
		if (!pos3D)
			return;

		duration = duration || 5;
		const data = {
			position: typedArrayToArray(pos3D),
			duration: duration,
			type: type || null,
			time: getTime() * 0.001 + duration,
			options: options || {}
		};

		this.markers.push(data);
		return data;
	}

	//Broadcast message to all participants
	sendMessage(msg) {
		if (!this.bridge) {
			console.warn("no messages bridge stablished");
			return false;
		}
		if (msg.constructor !== String)
			msg = JSON.stringify(msg);
		this.bridge.notify("EVENT_BCAST_OUT", msg);
		return true;
	}

	sendP2P(buffer) {
		if (!this.bridge) {
			console.warn("no messages bridge stablished");
			return false;
		}
		if (buffer.constructor !== ArrayBuffer) {
			console.error("sendP2P supports only ArrayBuffers");
			return false;
		}
		this.bridge.notify("EVENT_P2P_OUT", buffer);
		return true;
	}

	get node() {
		return this.scene.root;
	}

	set node(_v) {
		throw ("node cannot be set of space");
	}

	get quality() {
		return ROOM.QUALITY_STR[this.view.render_quality].toLowerCase();
	}

	set quality(v) {
		switch (String(v).toLowerCase()) {
		case "low":
			v = ROOM.QUALITY.LOW;
			break;
		case "medium":
			v = ROOM.QUALITY.MEDIUM;
			break;
		case "high":
			v = ROOM.QUALITY.HIGH;
			break;
		default:
			console.warn("unknown quality setting");
			return;
		}
		this.view.render_quality = v;
		if (nativeEngine && nativeEngine._engine) {
			nativeEngine._engine.setShadersQuality(v);
			nativeEngine._engine.setRenderQuality(v);
		}
	}
}


window.RoomSpace = RoomSpace;

