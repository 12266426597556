import { ROOM_LAYERS } from "@src/engine/Room/ROOM_LAYERS";
import { GL } from "@src/libs/litegl";
import { testRayWithNodes } from "@src/libs/rendeer/testRayWithNodes";
import { vec3 } from "gl-matrix";

//To configure how others see yourself
export function RoomViewObjects(launcher, space, view, _settings )
{
	this.xyz = this.launcher = launcher;
	this.name = "view object";

	this.space = space;
	this.room = space; //legacy
	this.view = view;

	this.selected_object = null;

	RoomViewObjects.instance = this;
}

RoomViewObjects.instance = null;

RoomViewObjects.prototype.onEnter = function()
{
	var participant = this.space.local_participant;
}

RoomViewObjects.prototype.onLeave = function()
{
	var participant = this.space.local_participant;
}

RoomViewObjects.prototype.onRender = function()
{
	var participant = this.space.local_participant;
	var camera = this.view.hard_camera;

	//render scene
	this.view.render();

	var ctx = gl;
	ctx.start2D();
	ctx.fillColor = [ 0,0,0,0.5 ];
	ctx.fillRect(0,0,gl.canvas.width,gl.canvas.height);
}


RoomViewObjects.prototype.renderUI = function()
{
	var ctx = gl;
	ctx.start2D();

	if ( GUI.CircleIconButton( gl.canvas.width - 70,20, GLUI.icons.x, 1 ) )
		this.exit();
}

RoomViewObjects.prototype.exit = function()
{
	this.launcher.setController( this.launcher.call_controller );
}

RoomViewObjects.prototype.onMouse = function(e)
{
	var r = GUI.onMouse(e);
	if ( r || GUI.isPositionBlocked( e.canvasx, e.canvasy ) )
		return true;

	var participant = this.space.local_participant;
	var camera = this.view.hard_camera;
	var right = camera.getLocalVector([ 1,0,0 ]);
	var cursorx = e.canvasx;
	var cursory = e.canvasy;
	var ray = e.ray = this.view.camera.getRay( cursorx, cursory, null, this.view.last_ray );

	if ( this.gizmo.onMouse(e) )
		return;

	if (e.type == "wheel" )
	{
		var direction = (e.wheel > 0) ? 1 : -1;

	}
	else if (e.type == "mousemove" && e.dragging )
	{
		//camera.rotate( e.deltax * -0.002, [0,1,0] );
		//camera.rotate( e.deltay * -0.002, right );
	}
	else if (e.type == "mouseup" && e.click_time < 250)
	{
	}
}

RoomViewObjects.prototype.onKeyDown = function(e)
{
	var camera = this.view.hard_camera;

	switch (e.code)
	{
	case "KeyD":
		break;
	case "KeyA":
		break;
	case "KeyQ":
	case "Escape":
		this.launcher.setController( this.launcher.call_controller );
		break;
	}
}

RoomViewObjects.prototype.testRayWithEntities = function( ray, get_collided_node )
{
	var prefab_nodes = [];
	var space = this.space;

	var layers = ROOM_LAYERS.ITEMS;

	//gather interactive nodes
	for (var i = 0; i < space.entities.length; ++i)
	{
		var entity = space.entities[i];
		if (!entity.prefab || !(entity.layers & layers) )
			continue;

		prefab_nodes.push(entity.node);
	}

	//test ray collision with nodes
	var test_against_mesh = true;
	var coll = vec3.create();
	var now = performance.now();
	var collided_node = testRayWithNodes( ray, prefab_nodes, coll, Infinity, 0xFFFF, test_against_mesh, get_collided_node );
	var time = performance.now() - now;
	//if( time > 10 )
	//	console.debug("ray picking test time exceeded threshold:", time );
	return collided_node;
}


//executed on a seat change
RoomViewObjects.prototype.onPartipantChangeSeat = function( type, event )
{
	RoomCall.instance.onPartipantChangeSeat(type, event );
}

RoomViewObjects.prototype.onParticipantLeave = function()
{
	RoomCall.instance.onParticipantLeave();
}

RoomViewObjects.prototype.onUpdate = function(dt)
{
	var camera = this.view.hard_camera;
	camera.applyController( dt, null, gl.keys.SHIFT ? this.cam_speed * 2 : this.cam_speed, gl.mouse.isButtonPressed(GL.LEFT_MOUSE_BUTTON) || gl.mouse.isButtonPressed(GL.RIGHT_MOUSE_BUTTON) );

	RoomCall.instance.onUpdate(dt);
}

RoomViewObjects.prototype.onTick = function()
{
	RoomCall.instance.onTick();
}
