import ROOM from "@src/engine/room";
import { RoomComponents } from "@src/engine/RoomComponents";

function LGraphSpaceArea()
{
	this.addInput("enabled", "boolean" );
	this.addOutput("toggle", LiteGraph.EVENT );
	this.addOutput("user_enter", LiteGraph.EVENT );
	this.addOutput("user_leave", LiteGraph.EVENT );
	this.addOutput("user_inside", "boolean" );

	this.properties = { locator: "", area_id: -1, cache_object: false };

	this._component = null;
}

LGraphSpaceArea.title = "Area";
LGraphSpaceArea.desc = "Helps control properties of walkable areas";

LGraphSpaceArea.prototype.getComponent = function()
{
	var component = ROOM.get(this.properties.locator);
	if (component !== this._component )
		this.bindEvents( component );
	this._component = component;
	if (!this._component)
		this.boxcolor = "red";
	else
		this.boxcolor = null;
	return this._component;
}

LGraphSpaceArea.prototype.onRemoved = function()
{
	this.bindEvents(null); //remove binding
}

LGraphSpaceArea.prototype.bindEvents = function(target)
{
	if (this._component === target )
		return;

	if ( this._component )
		LEvent.unbindAll( this._component, this );
	this._component = target;
	if (!target)
		return;

	LEvent.bind( this._component, "user_enter", this.onAreaEvent, this );
	LEvent.bind( this._component, "user_leave", this.onAreaEvent, this );
}

LGraphSpaceArea.prototype.onEvent = function(e)
{
	var area = comp.getAreaById( this.properties.area_id );
	if(!area)
		return;

	if(e == "toggle")
		area.enabled = !area.enabled;
	
}

LGraphSpaceArea.prototype.onAreaEvent = function(e, data)
{
	if (data.area_id === this.properties.area_id )
	{
		if (e === "user_enter" )
			this.triggerSlot(0); //user enter
		else if (e === "user_leave" )
			this.triggerSlot(1); //user leave
	}
}

LGraphSpaceArea.prototype.onExecute = function()
{
	var comp = this.getComponent();
	if (!comp || comp.constructor !== RoomComponents.WalkableArea)
		return;
	var area = comp.getAreaById( this.properties.area_id );
	if (!area)
	{
		this.boxcolor = "orange";
		return;
	}

	var v = this.getInputData(0);
	if ( v !== undefined )
	{
		v = Boolean(v);
		if (area.enabled !== v )
		{
			area.enabled = v;
			comp._must_update = true;
		}
	}
	this.setOutputData(2,area._participants.length > 0);
}

export default LGraphSpaceArea;

