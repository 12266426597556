import clamp from "clamp";
import { quat, vec3, vec4 } from "gl-matrix";
import lerp from "lerp";

import { DEG2RAD, RAD2DEG } from "@src/constants";
import { RoomController } from "@src/controllers/RoomController";
import Seat from "@src/engine/components/seat";
import Surface from "@src/engine/components/surface";
//import GraphComponent from "@src/engine/components/graph";

import Entity from "@src/engine/entity";
import EntityReference from "@src/engine/Entity/EntityReference";
import { generateUID } from "@src/engine/generateUID";
import ROOM from "@src/engine/room";
import { clone } from "@src/engine/Room/clone";
import { getFolder, getFullPath } from "@src/engine/Room/file-utils";
import { getObjectClassName } from "@src/engine/Room/getObjectClassName";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { RoomComponents } from "@src/engine/RoomComponents";
import { quatFromEuler, quatToEuler } from "@src/gl-matrix/quat";
import { vec3RotateY } from "@src/gl-matrix/vec3";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { GL } from "@src/libs/litegl";

import Animation from "@src/libs/rendeer/animation";
import Track from "@src/libs/rendeer/animationTrack";

import Backend from "@src/controllers/components/backend";

import "@src/libs/litegraph_mini";
import { RD } from "@src/libs/rendeer";
import { Camera } from "@src/libs/rendeer/Camera";
import { Material } from "@src/libs/rendeer/Material";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { Gizmo } from "@src/libs/rendeer-gizmo";

import cubemap_fragment_shader_code from "./editor/cubemap_fragment_shader_code.glsl";
import HTMLUI from "./helpers/HTMLUI";
import AddComponentPanel from "./panels/addComponent";
import FilesDialog from "./panels/files";
import LayersDialog from "./panels/layers";
import MaterialEditorPanel from "./panels/materialEditor";
import NewEntityPanel from "./panels/newEntity";
import NodeInfoPanel from "./panels/nodeInfo";
import LoginDialog from "./panels/profile";
import SelectEntityPanel from "./panels/selectEntity";
import GraphEditorDialog from "./panels/graphEditor";
import TimelineDialog from "./panels/timelineEditor";

import TempRecentSpacesDialog from "./panels/tempRecentSpacesDialog";
import ShowAllTexturesPanel from "./panels/ShowAllTexturesPanel";
import SpaceSettingsDialog from "./panels/spaceSettings";
import WatchTexturePanel from "./panels/watchTexture";
import WorkPanel from "./panels/workPanel";

import "@src/libs/litegraph_mini";

//in charge of changing room properties
class RoomEditor extends RoomController {
	/**
	 *
	 * @param {XYZLauncher} launcher
	 * @param {RoomSpace} space
	 * @param {ViewCore} view
	 */
	constructor(launcher, space, view ) {
		super();
		var that = this;
		RoomEditor.instance = this;
		this.name = "editor";

		this.launcher = launcher;
		this.space = space;
		this.view = view;
		this.sidewidth = 350;
		this.lowheight = 400;
		this.viewport = [0, 0, 100, 100];
		this.mode = RoomEditor.ENTITIES_MODE;
		this.side_scroll = 0;
		this.side_scroll_target = 0;
		this.auto_sync = true;
		this.hide_ui = false;
		this.autosave_enabled = true;
		this.autosave_time = 5; //in mins

		this.wireframe_mode = 0;

		this.lowpanel_visible = false;

		this.tool = null;
		this.tools = {};

		this.anim_time = 0;

		this.collapsed_labels = {
			environment: true,
			default_camera: true,
			fx: true,
			nativebase: true,
			nativefx: true,
			nativeglowfx: true,
			nativeglowffx: true,
			nativessaofx: true,
			nativevigfx: true,
			nativefogfx: true,
			nativedoffx: true,
			nativedofbfx: true,
			nativeoutlfx: true,
			nativetod: true,
			nativecgfx: true,
			nativecgdlfx: true,
			nativecgcmfx: true,
			nativecgadfx: true,
			nativecgtrfx: true,
			nativecgcvfx: true,
			nativecgwbfx: true,
			nativedrfx: true,
			nativessrfx: true,
			nativeditfx: true,
			nativemsaafx: true,
			nativetaafx: true,
			nativelitfx: true,
			nativelitshfx: true,
			nativelitsshfx: true,
			nativelitvshfx: true,
			nativelitcshfx: true,
			nativeaniso: true,
			nativeshadem: true,
			nativereflrefr: true,
			nativewbase: true,
			nativewcolor: true,
			nativewrefr: true,
			nativewrefl: true,
			nativewdispl: true,
			nativewfoam: true,
			nativewfeatures: true,
		};
		this.collapsed_collections = {};

		this.gizmo = new Gizmo();
		this.gizmo.mode = Gizmo.ALL;
		this.gizmo.coordinates = Gizmo.WORLD_SPACE;
		this.gizmo.onDuplicateTargets = this.onDuplicateTargets.bind(this);
		this.gizmo.onTransform = this.onGizmoTransform.bind(this);
		this.gizmo.render_move_plane = false;
		this.gizmo_to_individual_nodes = false;
		this.gizmo.onActionFinished = function()
		{
			that.afterTransformSelection();
		}

		this.selection = null; //all entities selected
		this.selected_item = null;
		this.selected_node = null;

		this.orbital_camera = false;
		this.use_call_camera = false;
		this.render_gizmos = true;
		this.render_bounding = false;
		this.draw_timeline = false;
		this.visible_comp_index = 0;
		this.filter = "";
		this.show_tools_panel = false;
		this.render_entity_names = false;
		this.render_scene_tree_debug = false;
		this.render_selfview = false;
		this.render_call_mode = false;
		this.show_frame_texture = false;
		this.show_all_textures = false;
		this.show_sidebar = true;
		this.show_colletions_list = true;
		this.use_depth_in_gizmos = true;

		this.preview_encoding = "image/webp";//"image/jpeg";//"image/png"

		//editor camera
		const gl = GL.ctx;
		this.camera = new Camera();
		this.camera.perspective( 50, gl.canvas.width / gl.canvas.height, 0.01, 1000 );
		this.camera.lookAt( [ 2,2,-2 ],[ 0,0.5,0 ],[ 0,1,0 ] );
		this.current_camera = this.camera;
		this.cam_speed = 5;

		this.draggable_color = [.7,.8,1,1];

		this.viewcone_mesh = GL.Mesh.load({ vertices:[ 0,0,0, -1,1,-1, 1,1,-1, 1,-1,-1, -1,-1,-1 ], lines: [ 0,1, 0,2, 0,3, 0,4, 1,2, 2,3, 3,4, 4,1 ] });
		StaticMaterialsTable["flat"] = this.flat_material = new Material({ color:[ 1,1,1,1 ] });

		this.active_dialogs = [];

		this.lower_dialog = null;
		this.tags_collapsed = true;
		this.categories_collapsed = true;

		this._modifyed_settings = {};
		ROOM.edited_materials = {};

		//UNDO
		this.undo = [];
		this.redo = [];

		this.notifications = [];
		this.notifications_y = view.canvas.height;

		this.compileShaders();

		this.addTool( "select", new SelectionTool() );
		this.addTool( "manipulate", new ManipulateTool() );
		this.addTool( "pick_color", new PickColorTool( this ) );
		this.selectTool( "select" );

		this.low_panel = new WorkPanel(this, this.space);

		this.auto_backup = false;
		this.last_backup = null;

		this.space.on("clear", this.onSceneClear, this );
	}

	onEnter() {
		if(!xyz.backend)
			xyz.backend = new Backend(xyz,xyz.options);


		this.view.loadTexture( "textures/surface-guide.png");
		this.view.loadMesh( "meshes/arrow.obj" );

		this.view.limit_camera = false;
		this.view.allow_freecam = true;
		this.space.mode = "editor";
		this.dragging_camera = false;
		this.view.render_gizmos = true;
		Seat.gizmos_visible = true;

		if (StaticMaterialsTable["ghost"])
			StaticMaterialsTable["ghost"].opacity = 1;
		if (StaticMaterialsTable["ghost_spectator"])
			StaticMaterialsTable["ghost_spectator"].opacity = 1;

		//clone cam
		this.camera.configure( this.view.camera.serialize() );

		//restore state
		if (this.last_backup)
			this.space.configure( this.last_backup );

		var nativeDepth = false;
		if (xyz.nativeEngine) {
			xyz.nativeEngine._viewRP.enableDeviceDepthExport(true);
			nativeDepth = xyz.nativeEngine._viewRP.isDeviceDepthEnabled();
		}

		var that = this;
		if (!this._autosave_interval) {
			this._autosave_interval = setInterval(function () {
				if (that.autosave_enabled)
					that.autosave();
			}, this.autosave_time * 60 * 1000);
		}
}

	onLeave() {
		//save current state
		this.last_backup = this.auto_backup ? this.space.serialize() : null;
		GUI.resetGUI(true);

		var space = this.space;
		this.view.lookAt( space.camera_info.position, space.camera_info.target );
		this.view.hard_camera.type = Camera.PERSPECTIVE;

		//this.detachAllDialogs();

		if (this._autosave_interval) {
			clearInterval(this._autosave_interval);
			this._autosave_interval = null;
		}

		if (xyz.nativeEngine)
			xyz.nativeEngine._viewRP.enableDeviceDepthExport(false);
}

	exit () {
		this.launcher.setControllerToPrevious();
	}

	onSceneClear() {
		this.view.reset();
		ROOM.edited_materials = {};
	}

	updateViewportArea() {
		var topheight = 20;
		var w = gl.canvas.width;
		var h = gl.canvas.height;
		this.viewport[0] = this.sidewidth;
		this.viewport[1] = topheight;
		this.viewport[2] = w - this.sidewidth;
		this.viewport[3] = h - (this.lowpanel_visible ? this.lowheight : 0) - topheight;
	}


	onRender() {
		var that = this;

		this.updateViewportArea();

		//this mode is to fake call mode when editing
		if ( this.render_call_mode )
		{
			xyz.call_controller.onRender();
			if (!this.hide_ui)
				this.renderUI();
			return;
		}

		//allows to render to a part of the screen
		//this.view.resize_mode = "custom";
		//gl.viewport( 200,200,500,500 );
		//nativeEngine._engine.setViewport([500,500]);
		this.current_camera = this.use_call_camera ? this.view.camera : this.camera;
		this.view.render( this.current_camera );
		if ( this.show_frame_texture && this.view.pbrpipeline )
			this.view.pbrpipeline.frame_texture.toViewport();

		if (that.render_gizmos)
			that.renderGizmos();

		//gl.viewport( 0,0,gl.canvas.width,gl.canvas.height );

		if ( this.render_scene_tree_debug )
			this.view.renderer.renderDebugSceneTree( this.space.scene, this.current_camera );

		if (!this.hide_ui)
			this.renderUI();

		ROOM.cursor_style = GUI.cursor || "";
	}

	//WIP
	convertVPToReverseZ(camera) {
		var w,h;
		var fovRad = camera.fov * DEG2RAD;
		var s = Math.tan(fovRad / 2.0) * camera.near;
		var mAspect = camera.aspect;
		var mFovVert = 0;
		var near = camera.near;
		var far = camera.far;
		if (1) //direction == Fov::VERTICAL)
		{
			w = s * camera.aspect;
			h = s;
			mFovVert = fovRad;
		}
		else
		{
			w = s;
			h = s / camera.aspect;
			mFovVert = fovRad / camera.aspect;
		}
		var left = -w;
		var right = w;
		var top = h;
		var bottom = -h;

		var p = camera._projection_matrix;
		mat4.identity(p);
		
		p[0] = (2 * near) / (right - left); //[0,0]
		p[5] = (2 * near) / (top - bottom);//[1,1]
		p[8] = (right + left) / (right - left);//[2][0]
		p[9] = (top + bottom) / (top - bottom);//[2][1]
		p[10] = -(far + near) / (far - near);//[2][2]
		p[11] = -1;//[2][3]
		p[14] = -(2 * far * near) / (far - near);//[3][2]
		p[15] = 0;//[3][3]

		p[10] = -1;           // lim(far->inf) = -1
		p[14] = -2 * near;    // lim(far->inf) = -2*near

		// Convert to inversed DX matrix
		var scalingX = 1.0;
		var scalingY = 1.0;
		var shiftX = 0.0;
		var shiftY = 0.0;

		var mS = mat4.create();
		mat4.identity(mS);
		mS[0] = scalingX;
		mS[3] = shiftX;
		mS[5] = scalingY;
		mS[7] = shiftY;
		mS[10] = -0.5;
		mS[11] = 0.5;
		mat4.transpose(mS, mS);
		mat4.multiply(p, mS, p);

		mat4.multiply(camera._viewprojection_matrix, p, camera._view_matrix);
	}

	convertVPToReverseZ(camera) {
		var w, h;
		var fovRad = camera.fov * DEG2RAD;
		var s = Math.tan(fovRad / 2.0) * camera.near;
		var mAspect = camera.aspect;
		var mFovVert = 0;
		var near = camera.near;
		var far = camera.far;
		if (1) //direction == Fov::VERTICAL)
		{
			w = s * camera.aspect;
			h = s;
			mFovVert = fovRad;
		}
		else {
			w = s;
			h = s / camera.aspect;
			mFovVert = fovRad / camera.aspect;
		}
		var left = -w;
		var right = w;
		var top = h;
		var bottom = -h;

		var p = camera._projection_matrix;
		mat4.identity(p);

		p[0] = (2 * near) / (right - left); //[0,0]
		p[5] = (2 * near) / (top - bottom);//[1,1]
		p[8] = (right + left) / (right - left);//[2][0]
		p[9] = (top + bottom) / (top - bottom);//[2][1]
		p[10] = -(far + near) / (far - near);//[2][2]
		p[11] = -1;//[2][3]
		p[14] = -(2 * far * near) / (far - near);//[3][2]
		p[15] = 0;//[3][3]

		p[10] = -1;           // lim(far->inf) = -1
		p[14] = -2 * near;    // lim(far->inf) = -2*near

		// Convert to inversed DX matrix
		var scalingX = 1.0;
		var scalingY = 1.0;
		var shiftX = 0.0;
		var shiftY = 0.0;

		var mS = mat4.create();
		mat4.identity(mS);
		mS[0] = scalingX;
		mS[3] = shiftX;
		mS[5] = scalingY;
		mS[7] = shiftY;
		mS[10] = -0.5;
		mS[11] = 0.5;
		mat4.transpose(mS, mS);
		mat4.multiply(p, mS, p);

		mat4.multiply(camera._viewprojection_matrix, p, camera._view_matrix);
	}

	renderEntityNames() {
		var camera = this.view._last_camera;
		const gl = GL.ctx;
		gl.globalAlpha = 0.75;
		var entities = this.space.root.getAllEntities();
		var use_reverse_z = Boolean(xyz.nativeEngine) && camera.type == 1;

		for (var i = 0; i < entities.length; ++i)
		{
			var ent = entities[i];
			if (!ent.enabled)
				continue;
			var pos = camera.project( ent.position );

			if( use_reverse_z && pos[2] < 0.5)
				continue;
			if ( !use_reverse_z && pos[2] > 1 )
				continue;
				
			pos[1] = gl.canvas.height - pos[1]; //reverse
			var r = GUI.DrawIcon(pos[0],pos[1],0.3,[ 0,1 ],false,null,null,true);
			Label.call(GUI,pos[0] + 10,pos[1] - 10,100,20, ent.name );
			if (r === GLUI.CLICKED )
			{
				this.setSelection( ent );
				this.mode = RoomEditor.PROPERTIES_MODE;
			}
		}
		gl.globalAlpha = 1;
	}

	//called from pipeline, renders editor stuff
	renderGizmos() {
		this.space.processActionInComponents("renderGizmo",[ this.view, this, this.selected_item === this.space ], true );
		var camera = this.view._last_camera;

		var entities = this.space.root.getAllEntities();

		const gl = GL.ctx;
		var use_reverse_z = Boolean(xyz.nativeEngine) && camera.type == 1;
		if (use_reverse_z) {
			gl.depthFunc(gl.GEQUAL);
			this.convertVPToReverseZ(camera);
		}

		for (var i = 0; i < entities.length; ++i)
		{
			var ent = entities[i];
			if (ent.enabled)
				ent.processActionInComponents("renderGizmo",[ this.view, this, this.selected_item === ent ], true );
		}

		if (this.selected_item)
		{
			if (this.render_bounding && this.selected_node )
				this.view.renderer.renderBounding( this.selected_node.updateBoundingBox(true) );
		}

		if(use_reverse_z)
			gl.depthFunc( gl.LEQUAL );

		//if(this.tool && this.tool.onRender )
		//	this.tool.onRender( this );
	}

	renderIcon3D( pos, icon, size, color ) {
		if (!GUI._icons_texture)
			return;
		const gl = GL.ctx;
		var shader = gl.shaders["icons_shader"];
		if (!shader)
			shader = gl.shaders["icons_shader"] = new GL.Shader( RD.points_vs_shader, RD.points_fs_shader, { "FS_UNIFORMS":"uniform sampler2D u_texture; uniform vec4 u_rect;", "FS_CODE":"color *= texture2D(u_texture,remap(gl_PointCoord.xy,vec2(0.0),vec2(1.0),u_rect.xy, u_rect.zw ) );" });

		var num = GUI._icons_texture.width / GUI.icon_size;

		var startx = icon[0] / num;
		var starty = 1.0 - icon[1] / num;
		var endx = (icon[0] + 1) / num;
		var endy = 1.0 - (icon[1] + 1) / num;

		var rect = [ startx, starty, endx, endy ];
		//rect = [0,1,1,0];
		shader.uniforms({
			u_texture: GUI._icons_texture.bind(0),
			u_rect: rect
		});
		//render point
		gl.depthMask( false );
		gl.enable( gl.BLEND );
		gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );
		this.view.renderer.color = color || [ 2,2,2,1 ];
		this.view.renderer.renderPoints( pos, null, this.view.renderer._camera, 1, shader, size || 0.2, GL.POINTS );
		gl.depthMask( true );
	}

	renderUI() {
		var that = this;
		var view = this.view;
		var camera = this.view.renderer._camera;
		var xyz = this.launcher;
		var native = xyz.native_mode;
		var nativeEngine = window.nativeEngine || null;

		if (this.selection && this.selected_item && (this.selected_item.constructor === Entity || ( native && this.selected_item.constructor === TmrwModule.NativeEntity) || this.selected_item.constructor === EntityReference) && this.render_gizmos )
		{
			var selected_nodes = this.selection.items ? Array.from( this.selection.items ).map( function(v) { return v.node; }) : [];

			if ( this.gizmo_to_individual_nodes ) //&& this.selected_node
				selected_nodes = [ this.selected_node ];

			this.gizmo.setTargets( selected_nodes );
			if ( selected_nodes.length )
				this.view.renderOutline( this.view.renderer, this.view.scene, camera, selected_nodes );
			if (this.selected_item.proxy_node)
			{
				var highlight_node = this.space.scene.root.findNodeByName( this.selected_item.proxy_node );
				if (highlight_node)
					this.view.renderOutline( this.view.renderer, this.view.scene, camera, [ highlight_node ] );
			}
		}

		if (this.tool && this.tool.onRender )
			this.tool.onRender( this, this.view );

		var ctx = gl;
		ctx.start2D();

		if (this.render_entity_names)
			this.renderEntityNames();

		if ( this.render_selfview && this.space.local_participant )
			ctx.drawImage( this.space.local_participant.getBubbleTexture(), this.sidewidth, 100, 256,256 );

		//disable mouse actions in areas of dialogs that will be rendered at the end
		if ( this.active_dialogs.length )
		{
			for (var i = 0; i < this.active_dialogs.length; ++i)
			{
				var dialog = this.active_dialogs[i];
				if ( dialog.area )
					GUI.disableArea( dialog.area );
				else
				{
					if (dialog(this,true) === true )
					{
						dialog.active = false;
						this.active_dialogs.splice(i,1);
						i--;
					}

				}
			}
		}

		//in case the tool wants to render anything in 2D
		if (this.tool && this.tool.onRenderUI)
			this.tool.onRenderUI(this);

		//debug info
		ctx.fillStyle = "white";
		ctx.font = "14px " + this.launcher.options.fontFamily;
		ctx.textAlign = "left";
		//ctx.fillText("Alpha 0.1",10,20);

		var w = gl.canvas.width;
		var h = gl.canvas.height;
		var sidewidth = this.sidewidth;

		//low stats panel
		this.renderStats(ctx);

		//top menu
		//top menu
		var top_menu_y = 0;
		if ( this.show_sidebar )
		{
			this.renderTopMenu(0,0,w,30);
			top_menu_y = 30;
		}

		//drop canvas area
		GUI.DropArea(sidewidth,0,w-sidewidth,h, this.onDropOnCanvas.bind(this) );

		//sidebar
		if ( this.show_sidebar )
			this.renderSidebar(0,top_menu_y,sidewidth,h-top_menu_y);

		//bottom bar
		if ( this.show_tools_panel )
			this.renderToolsPanel(sidewidth,h - 100,w-sidewidth,100);

		if (xyz.backend)
		{
			GUI.next_tooltip = xyz.backend.username ? ("Logged as " + xyz.backend.username): "Login";
			if ( Button.call(GUI, w - 160,top_menu_y + 20,64,64,[ 7,0 ],false,[ 0.2,0.2,0.2,0.9 ],[ 1,1,1 ],30) )
				this.attachDialog( LoginDialog );
			if (xyz.backend.username) //logged
			{
				GUI.next_tooltip = "Open Files";
				GUI.DrawIcon( w - 114,top_menu_y + 66, 0.45, [ 0,1 ], false,[ 0.3,0.9,0.4,1 ] );
				if ( Button.call(GUI, w - 230,top_menu_y + 20,64,64,[ 10,6 ],false,[ 0.2,0.2,0.2,0.9 ],[ 1,1,1 ],20) )
				{
					if (!FilesDialog._not_first_time && xyz.space.folder && xyz.backend.current_folder !== xyz.space.folder )
					{
						xyz.backend.changeFolder( xyz.space.folder );
						FilesDialog._not_first_time = true;
					}
					this.attachDialog( FilesDialog );
				}
			}
		}

		GUI.next_tooltip = "Exit Editor";
		if ( Button.call(GUI, w - 74,top_menu_y + 20,64,64,[ 6,2 ],false,[ 0.2,0.2,0.2,0.9 ],[ 1,1,1 ],20) )
		{
			this.exit();
		}

		var tools_x = this.ui ? 10 : sidewidth + 10;

		if( !xyz.native_mode )
		{
			GUI.next_tooltip = "Undo";
			if ( Button.call(GUI, tools_x,top_menu_y + 10,32,32,[ 6,5 ],false,[ 0.1,0.1,0.1,0.9 ],[ 1,1,1 ]) )
				this.doUndo();
			GUI.next_tooltip = "Redo";
			if ( Button.call(GUI, tools_x + 42,top_menu_y + 10,32,32,[ 7,5 ],false,[ 0.1,0.1,0.1,0.9 ], this.redo.length ? [ 1,1,1 ] : [ 0,0,0,0.1 ] ) )
				this.doRedo();
		}

		//options
		GUI.next_tooltip = "Focus on selection";
		if ( Button.call(GUI, tools_x + 90,top_menu_y + 10,32,32,[ 8,6 ] ))
			this.focusOnEntity(this.selected_item, GUI.wasShift ? this.selected_node : null );
		GUI.next_tooltip = "Show debug info";
		if ( Button.call(GUI, tools_x + 90 + 40,top_menu_y + 10,32,32,[ 9,6 ], this.render_gizmos ))
			this.render_gizmos = !this.render_gizmos;

		if ( Button.call(GUI, tools_x + 90 + 40 * 2,top_menu_y + 10,32,32,[ 11,4 ], this.draw_timeline ))
			this.showTimeline( !TimelineDialog.active );

		GUI.next_tooltip = "Layers";
		if ( Button.call(GUI, tools_x + 90 + 40 * 3,top_menu_y + 10,32,32,[ 7,6 ] ))
		{
			LayersDialog.target = this.view;
			if ( LayersDialog.active )
				this.detachDialog( LayersDialog );
			else
				this.attachDialog( LayersDialog );
		}

		GUI.next_tooltip = "Camera Options";
		if ( Button.call(GUI, tools_x + 90 + 40 * 4,top_menu_y + 10,32,32,[ 0,4 ] ))
			GUI.ShowContextMenu( RoomEditor.camera_options, this.onCameraOptions.bind(this) );

		GUI.next_tooltip = "Tools Panel";
		if ( Button.call(GUI, tools_x + 90 + 40 * 5,top_menu_y + 10,32,32,[ 5,0 ],this.show_tools_panel ))
			this.show_tools_panel = !this.show_tools_panel;

		GUI.next_tooltip = "View Mode";
		if (view.pbrpipeline) {
			if (Button.call(GUI, tools_x + 90 + 40 * 6, top_menu_y + 10, 32, 32, [8, 7], this.view.pbrpipeline.overwrite_shader_name)) {
				var view = this.view;
				GUI.ShowContextMenu(["Default", null, "UVs", "Normal", "Albedo", "Lightmap", "HDR Image"],
					{
						callback: function (v, item) {
							if (!view.pbrpipeline)
								return;
							view.pbrpipeline.overwrite_shader_name = null;
							view.pbrpipeline.overwrite_shader_mode = null;
							view.pbrpipeline.allow_instancing = false;
							if (item === "UVs")
								view.pbrpipeline.overwrite_shader_name = "uvs";
							else if (item === "Normal")
								view.pbrpipeline.overwrite_shader_mode = "normals.fs";
							else if (item === "Albedo")
								view.pbrpipeline.overwrite_shader_mode = "albedo.fs";
							else if (item === "Lightmap")
								view.pbrpipeline.overwrite_shader_mode = "occlusion.fs";
							else if (item === "HDR Image")
								that.show_frame_texture = true;
							else {
								view.pbrpipeline.allow_instancing = true;
								that.show_frame_texture = false;
							}
						},
						id: "view_mode_context_menu"
					});
			}
		}

		GUI.next_tooltip = "View Mode";
		if (xyz.native_mode) {
			var dMode = nativeEngine._engine.getDiagnosticsMode();
			if (Button.call(GUI, tools_x + 90 + 40 * 6, top_menu_y + 10, 32, 32, [8, 7], dMode != 0)) {
				var view = this.view;
				GUI.ShowContextMenu(["Default", "Overdraw", "Albedo", "Emissive", "Pixel-Normals", "Pixel-Reflect", "Roughness", "Metalness", "Pixel-Alpha", "IBL-Specular", "IBL-Diffuse", "Dyn-Lighting", "Dyn-Shadows", "Occlusion-Map", "SS-Reflections", "Vert-Normals", "Vert-Tangents", "Vert-Binormals", "Amb-Occlusion", "Forward-Pass", "Depth-Pass"],
					{
						callback: function (v, item) {
							var diagnMode = v;
							if (diagnMode >= 0)
								nativeEngine._engine.setDiagnosticsMode(diagnMode);
						},
						id: "view_mode_context_menu"
					});
			}
		}


		GUI.next_tooltip = "View Mode";
		if (xyz.native_mode) {
			var dMode = nativeEngine._engine.getDiagnosticsMode();
			if (Button.call(GUI, tools_x + 90 + 40 * 6, top_menu_y + 10, 32, 32, [8, 7], dMode != 0)) {
				var view = this.view;
				GUI.ShowContextMenu(["Default", "Overdraw", "Albedo", "Emissive", "Pixel-Normals", "Pixel-Reflect", "Roughness", "Metalness", "Pixel-Alpha", "IBL-Specular", "IBL-Diffuse", "Dyn-Lighting", "Dyn-Shadows", "Occlusion-Map", "SS-Reflections", "Vert-Normals", "Vert-Tangents", "Vert-Binormals", "Amb-Occlusion", "Forward-Pass", "Depth-Pass"],
					{
						callback: function (v, item) {
							var diagnMode = v;
							if (diagnMode >= 0)
								nativeEngine._engine.setDiagnosticsMode(diagnMode);
						},
						id: "view_mode_context_menu"
					});
			}
		}

		// Andrey: icons show demo
		//for (var i = 0; i < 8; i++) {
		//	for (var j = 0; j < 8; j++) {
		//		if (Button.call(GUI, tools_x + 90 + 40 * i, top_menu_y + 100 + j * 40, 32, 32, [i, j], "TestMode", 0)) {
		//		}
		//	}
		//}

		GUI.next_tooltip = "Wireframe Mode";
		if (xyz.native_mode) {
			if (Button.call(GUI, tools_x + 90 + 40 * 7, top_menu_y + 10, 32, 32, [3, 4], "Wireframe Mode", this.wireframe_mode != 0)) {
				var view = this.view;
				GUI.ShowContextMenu(["Solid", "Wireframe", "Wireframe + Solid"],
					{
						callback: function (v, item) {
							var wireMode = v;
							this.wireframe_mode = wireMode;
							if (wireMode >= 0)
								nativeEngine._engine.setWireFrameMode(wireMode);
						},
						id: "wire_mode_context_menu"
					});
			}

		}

		GUI.next_tooltip = "low quality render";
		if (Button.call(GUI, tools_x + 90 + 40 * 8, top_menu_y + 10, 32, 32, [ 9, 4 ], this.view.render_quality === ROOM.QUALITY.LOW)) {
			this.view.render_quality = this.view.render_quality == ROOM.QUALITY.LOW ? ROOM.QUALITY.HIGH : ROOM.QUALITY.LOW;
			if (xyz.native_mode) {
				if (this.view.render_quality === ROOM.QUALITY.LOW) {
					nativeEngine._engine.setRenderQuality(0);
					nativeEngine._engine.setShadersQuality(0);
				}
				else {
					nativeEngine._engine.setRenderQuality(3);
					nativeEngine._engine.setShadersQuality(1);
				}
			}
		}

		GUI.next_tooltip = "show entity names";
		if ( Button.call(GUI, tools_x + 90 + 40 * 9,top_menu_y + 10,32,32,[ 14,2 ], this.render_entity_names ))
			this.render_entity_names = !this.render_entity_names;

		GUI.next_tooltip = "disable depth on gizmos";
		if ( Button.call(GUI, tools_x + 90 + 40 * 10, top_menu_y + 10,32,32,[ 10,4 ], this.use_depth_in_gizmos ))
			this.use_depth_in_gizmos = !this.use_depth_in_gizmos;

		GUI.next_tooltip = "show graph editor";
		if ( Button.call(GUI, tools_x + 90 + 40 * 11, top_menu_y + 10,32,32,[ 14,7 ] ))
			this.showGraph( !GraphEditorDialog.active );

		/*
		GUI.next_tooltip = "show textures";
		if ( Button.call(GUI, tools_x + 90 + 40 * 10,top_menu_y + 10,32,32,[ 13,2 ], this.show_all_textures ))
			this.attachDialog( ShowAllTexturesPanel );
		*/

		GUI.next_tooltip = "show selfview";
		if ( Button.call(GUI, tools_x + 90 + 40 * 14,top_menu_y + 10,32,32,[ 7,0 ], this.render_selfview ))
			this.render_selfview = !this.render_selfview;

		GUI.next_tooltip = "show selfview";
		if ( Button.call(GUI, tools_x + 90 + 40 * 15,top_menu_y + 10,32,32,[ 0,7 ], this.render_call_mode ))
			this.render_call_mode = !this.render_call_mode;


		y = NodeInfoPanel.render(this, top_menu_y + 100);
		MaterialEditorPanel.render(this, y);

		if ( this.selected_texture )
			WatchTexturePanel.render(this,100);

		//tools
		var y = top_menu_y + 100;
		for (var i in this.tools)
		{
			var tool = this.tools[i];
			if (tool.constructor.icon)
			{
				if ( Button.call(GUI, tools_x,y,40,40,tool.constructor.icon, tool === this.tool ) )
					this.selectTool( tool );
			}

			y+= 50;
		}

		//tools ui
		if( this.tool && this.tool.onRenderUI )
			this.tool.onRenderUI(this, view);

		//timeline, graph, etc
		this.low_panel.render(ctx);

		//display
		if (this.notifications.length)
		{
			var ny = this.notifications_y = lerp( h - this.notifications.length * 60 - 10, this.notifications_y, 0.9 );
			ctx.globalAlpha = 0.8;
			for (var i = 0; i < this.notifications.length; ++i)
			{
				var item = this.notifications[i];
				var remove = GUI.Panel( w - 300, ny, 290,50, item.content, true, 5, item.icon ) === false;
				if (remove || item.end_time < getTime())
					this.notifications.splice(i--,1);
				ny += 60;
			}
			ctx.globalAlpha = 1;
		}

		if (this.lower_dialog)
			this.lower_dialog( sidewidth + 10, gl.canvas.height - 350, gl.canvas.width - sidewidth - 20, 340 );

		//allows to have a dialog
		if ( this.active_dialogs.length )
		{
			for (var i = 0; i < this.active_dialogs.length; ++i)
			{
				var dialog = this.active_dialogs[i];
				if ( dialog.area )
					GUI.enableArea( dialog.area );
				var r = null;
				if ( dialog.render )
				{
					r = dialog.render(this);
					if(this.focus_dialog == dialog)
					{
						ctx.strokeStyle = "rgba(255,255,255,0.4)";
						var area = dialog.area;
						ctx.strokeRect(area[0],area[1],area[2],area[3]-1);
					}					
				}
				else
					r = dialog(this);
				if ( r === false)
				{
					dialog.active = false;
					this.active_dialogs.splice(i,1); //remove
					i--;
				}
			}
		}

		if (this.show_confirm)
		{
			var r = GUI.Confirm( this.show_confirm.text );
			if (r === false)
				this.show_confirm = null;
			else if (r === true)
			{
				this.show_confirm.callback();
				this.show_confirm = null;
			}
		}

		if (this.space.loading_info.length)
		{
			ctx.save();
			ctx.translate( w - 60, h - 60 );
			ctx.rotate( getTime() * 0.006 );
			GUI.DrawIcon( 0, 0, 1, [ 11,1 ] );
			ctx.restore();
		}

		if (	GUI.tooltip )
		{
			ctx.fillColor = [ 0,0,0,0.5 ];
			ctx.beginPath();
			ctx.roundRect( gl.canvas.width * 0.25, gl.canvas.height - 40, gl.canvas.width * 0.5, 60,10 );
			ctx.fill();

			ctx.font = "24px " + this.launcher.options.fontFamily;
			ctx.textAlign = "center";
			ctx.fillStyle = "white";
			ctx.fillText( GUI.tooltip, gl.canvas.width * 0.5, gl.canvas.height - 10 );
			GUI.tooltip = null;
			GUI.next_tooltip = null;
		}

		ctx.finish2D();
	}

	onCameraOptions(index,item) {
		var space = this.space;
		var camera = this.camera;
		console.debug(item);
		if (item === "Orthographic")
		{
			camera.type = Camera.ORTHOGRAPHIC;
			camera.frustum_size = 4;
			camera.near = -1000;
			camera.far = 1000;
			camera.lookAt( [ 10,10,10 ], [ 0,0,0 ], [ 0,1,0 ] );
		}
		else if (item === "Perspective")
		{
			camera.type = Camera.PERSPECTIVE;
			camera.lookAt( camera.position, space.camera_info.target, [ 0,1,0 ] );
		}
		else if (item === "Cenital")
		{
			camera.type = Camera.ORTHOGRAPHIC;
			camera.near = -1000;
			camera.far = 1000;
			camera.frustum_size = 3;
			var eye = vec3.add( vec3.create(), camera.position, [ 0,100,0 ] );
			var target = vec3.copy( vec3.create(), camera.position );
			camera.lookAt( eye, camera.position, [ 0,0,-1 ] );
		}
		else if (item === "Orbital")
		{
			this.orbital_camera = !this.orbital_camera;
		}
		else if (item === "Reset")
		{
			camera.lookAt( space.camera_info.position, space.camera_info.target, [ 0,1,0 ] );
		}
		//else if(item == "Call View")
		//	this.use_call_camera = !this.use_call_camera;
	}

	renderTopMenu(x,y,w,h) {
		var ctx = gl;
		var that = this;

		var bgcolor = [ 0.15,0.15,0.15,1 ];

		ctx.fillColor = bgcolor;
		ctx.fillRect(x,y,w,h);
		GUI.blockArea(x,y,w,h);

		var menu = RoomEditor.topmenu;
		var item_height = h-8;
		x += 2;

		for (var i = 0; i < menu.length; ++i)
		{
			var item = menu[i];
			if ( Button.call(GUI,  x, y+4, 100, item_height, item.title, false, null, null, 0) )
				inner(item);
			x += 110;
		}

		function inner(item)
		{
			if (item.submenu)
			{
				that._top_menu_info = GUI.ShowContextMenu( item.submenu, {
					callback:
						function(v,item) {
							inner_entry(item);
						},
					id: "topmenu_" + item.title,
					margin: 2,
					icons: true,
					position: [ x+10,y+item_height+4 ]
				});
			}
		}

		function inner_entry( item )
		{
			if (item.callback)
			{
				if (item.callback() === true )
					return;
			}
			if (item.event)
				LEvent.trigger( that, item.event );
			that.onTopMenuOption(item);
		}
	}

	onTopMenuOption(item) {
		const xyz = this.launcher;
		console.debug("menu event",item.event);

		if (item.event === "new_space")
		{
			this.show_confirm = { text: "Are you sure?", callback: (function() {
				this.space.clear();
				this.setSelection(null);
				if (this.view)
					this.view.updateFromSpace( this.space );
			}).bind(this) };
		}
		else if (item.event === "load_space")
		{
			this.showLoadSpaceDialog();
		}		
		else if (item.event === "show_history")
		{
			this.showHistorySavedSpaces();
		}		
		else if (item.event === "save_space")
		{
			this.saveSessionRemote();
		}
		else if (item.event === "save_as_space")
		{
			var filename = xyz.space.url;
			if (filename && filename.indexOf(".json") === -1 )
				filename += ( filename[ filename.length - 1 ] == "/" ? "" : "/") + "room.json";
			filename = prompt("Full filename",filename);
			if (!filename)
				return;
			if (filename.indexOf(".json") === -1 )
				alert("json extension missing");
			else
				this.saveSessionRemote( filename );
		}
		else if (item.event === "update_thumbnail")
		{
			this.saveSnapshot();
		}
	}

	renderSidebar(x,y,w,h) {
		var ctx = gl;
		var that = this;

		var bgcolor = [ 0.2,0.2,0.2,0.95 ];

		ctx.fillColor = bgcolor;
		ctx.fillRect(x,y,w,h);
		GUI.blockArea(x,y,w,h);

		//big menu
		for (var i = 0; i < 5; ++i)
		{
			var icon = RoomEditor.section_icon[i];
			if ( Button.call(GUI, x + i * 70,y,70,70,icon, i === this.mode,null,null,0 ) )
				this.mode = i;
		}
		GUI.DrawIcon(x + 35 + 70 * this.mode,y + 70,1,[ 2,3 ],false,bgcolor);

		var panel_height = h - 120;

		if (this.mode === 0)
		{
			if (1)
				this.renderEntitiesList(ctx, x + 10, y + 90, w - 20, h - 120);
			else //split mode
			{
				var mid = h * 0.5;
				this.renderEntitiesList(ctx, x + 10, y + 90, w - 20, mid - 90 );
				this.renderInspector(ctx, x + 10, y + mid, w - 20, h - mid);
			}
		}
		else if (this.mode === 1)
			this.renderInspector(ctx, x + 10, y + 90, w - 20, panel_height);
		else if (this.mode === 2)
			this.renderSession(ctx, x + 10, y + 90, w - 20, panel_height);
		else if (this.mode === 3)
			this.renderSettings(ctx, x + 10, y + 90, w - 20, panel_height);
		else if (this.mode === 4)
			this.renderGlobalSettings(ctx, x + 10, y + 90, w - 20, panel_height);
	}

	renderEntitiesList(ctx,x,y,w,h) {
		var space = this.space;
		var that = this;

		this.filter = GUI.SearchBox(x, y, w - 100, 30, this.filter || "");

		//toggle collections
		GUI.next_tooltip = "Show Collections";
		if ( Button.call(GUI, x + w - 70,y,30,30,[ 8,3 ], this.show_colletions_list, [ 0.1,0.1,0.1,0.9 ],null,10 ) )
			this.show_colletions_list = !this.show_colletions_list;

		//new entity
		if ( Button.call(GUI, x + w - 30,y,30,30,[ 0,6 ], false, [ 0.1,0.1,0.1,0.9 ],null,10 ) )
			this.attachDialog( NewEntityPanel );

		if ( this.side_scroll_target > 0 )
			this.side_scroll_target = 0;
		this.side_scroll = lerp( this.side_scroll, this.side_scroll_target, 0.1 );
		y += 40;
		var size = 20;
		var margin = 4;
		var line_height = size + margin;
		var list_height = h - 80;

		//scrollable area
		var starty = y;
		ctx.fillColor = [ 0.1,0.1,0.1,0.9 ];
		ctx.beginPath();
		ctx.roundRect( x,starty,w,list_height, [ 5 ] );
		ctx.fill();
		y += 10;

		//main element: space
		var mouse_state = GUI.HoverArea( x + 10, y+2, w - size, 24 )//, { entity: this.space, callback: this.onDragEntity.bind(this) });
		GUI.DropArea( x + 10, y+2, w - size, 20, onDropOnEntity.bind(this), space.root );
		GUI.DrawIcon( x + 10 + size*0.5, y + size*0.5, 0.3, [ 0,1 ], false, [ 1,1,1,0.5 ] );
		var color = mouse_state !== GLUI.NONE ? [ 1, 1, 1, 1 ] : [ 0.8, 0.8, 0.8, 0.8 ];
		var is_selected = this.space === this.selected_item;
		if (is_selected)
			color = [ 1,1,0.8,1 ];
		Label.call(GUI, x + 36, y+2, w - 40, size, "space", color );
		if (mouse_state === GLUI.CLICKED) //clicked (down and  up)
		{
			//double click
			if (this.selected_item === this.space && GUI.last_noclick_time < 250 )
			{
				this.mode = RoomEditor.PROPERTIES_MODE;
				GUI.consumeClick();
			}
			else
				this.setSelection( this.space );
		}

		//show component icons on the right
		show_components(this.space, x + 30, y, w - 10 );

		y += line_height;
		var top_y = y;
		var item_y = y + this.side_scroll;

		//clip area for scroll
		ctx.save();
		ctx.beginPath();
		ctx.rect(x,y,w,list_height - 60);
		ctx.clip();

		//autoscroll
		if ( gl.mouse.buttons )
		{
			var mousepos = [ gl.mouse.mousex,gl.mouse.mousey ];
			if ( GUI.isInsideRect( mousepos, x,y,w,50,30) )
				this.side_scroll_target += 5;
			else if ( GUI.isInsideRect( mousepos, x,y+list_height-80,w,50,30) )
				this.side_scroll_target -= 5;
		}

		//display entities list
		var collections = space.root.getCollections();
		var collections_array = Object.keys(collections);
		var current_collection = null;
		if (collections_array.length === 0 || !this.show_colletions_list ) //render as list of entities
		{
			item_y = render_entity_array.call( this, this.space.root.children, item_y );
		}
		else //in case of collections: render grouped by collection
		{
			item_y = render_collections.call( this, this.space.root.children, item_y );
		}

		//remove clip
		ctx.restore();

		y += list_height - 20;

		//actions
		GUI.next_tooltip = "Sort Nodes";
		if ( Button.call(GUI, x + 10,y,30,30,[ 4,10 ], false, [ 0.1,0.1,0.1,0.9 ],null,10 ) )
			this.sortEntities();

		//renders a group of entities
		function render_entity_array( entities, item_y, level )
		{
			level = level || 0;

			//show entities list
			for ( var i = 0; i < entities.length; i++ )
			{
				//out of the area
				if (item_y > (top_y + list_height - 40) )
					break;

				//get entities list (from current group!)
				var entity = entities[i];

				//filter based on collections
				if (current_collection === "" )
				{
					if ( entity.collection != null )
						continue;
				}
				else if (current_collection != null && entity.collection !== current_collection )
					continue;

				//filter based on search
				if ( this.filter )
				{
					if (this.filter[0] === ".")
					{
						var comp_name = this.filter.toLowerCase().substr(1);
						var j = 0;
						if (entity._components)
						{
							for (; j < entity._components.length; ++j)
							{
								var comp = entity._components[j];
								if (comp.constructor.name.toLowerCase().indexOf( comp_name ) !== -1 )
									break;
							}
							if (j === entity._components.length)
								continue;
						}
					}
					else if (entity.name.indexOf( this.filter ) === -1 )
						continue;
				}

				if ( item_y < (top_y - line_height) )//&& 0) //out up
				{
					item_y += line_height;
					continue;
				}

				//render current entity
				var is_selected = this.isSelected( entity );
				item_y = render_entity.call( this, entity, item_y, is_selected, level );

				//recursive
				if ( entity.children && entity.children.length && !entity.collapsed)
					item_y = render_entity_array.call( this, entity.children, item_y, level + 1 );
			}

			return item_y;
		}

		function render_collections( entities, item_y, level )
		{
			if (collections_array.indexOf("") === -1)
				collections_array.push("");

			for ( var j = 0; j < collections_array.length; ++j )
			{
				//render collection name
				current_collection = collections_array[j];
				var collection_collapsed = this.collapsed_collections[ current_collection ];
				if (GUI.DrawIcon( x + 10, item_y + 8, 0.3,[ collection_collapsed ? 4 : 5, 11 ], false, null, null, true ) === GLUI.CLICKED )
				{
					collection_collapsed = !collection_collapsed;
					this.collapsed_collections[ current_collection ] = collection_collapsed;
				}
				Label.call(GUI, x + 20, item_y, w, size, current_collection !== "" ? current_collection : "Default" );
				GUI.DropArea( x, item_y, w, size, onDropOnCollection.bind(this), current_collection );
				if ( Button.call(GUI,  x + w - size - 10, item_y, size, size, [ 15,1 ]) )
				{
					this._options_collection = current_collection;
					GUI.ShowContextMenu([ "Rename",null,"Clear" ],
						{
							callback: function(v,item)
							{
								if (item === "Rename")
								{
									var r = prompt("Rename Collection to",that._options_collection);
									if (r)
										that.renameCollection( that._options_collection, r );
								}
								else if (item === "Clear")
									that.renameCollection( that._options_collection, null );
							},
							id: "icon_context_menu"
						});
				}
				item_y += line_height;

				//render entities in that collection
				if ( !collection_collapsed )
					item_y = render_entity_array.call( this, this.space.root.children, item_y );
			}			
		}

		//render the name of the entity and its icons ****************************
		function render_entity( entity, item_y, is_selected, level )
		{
			level = level || 0;

			var item_x = x + level * 20;
			var item_w = w - level * 20;
			var is_group = entity.children.length;

			var mouse_state = GUI.HoverArea(item_x + 10+ (is_group?30:0),item_y,item_w - 56 - (is_group?30:0),size, { entity: entity, callback: this.onDragEntity.bind(this) } );
			var color = mouse_state !== GLUI.NONE ? [ 0.8, 0.8, 0.8, 1 ] : [ 0.8, 0.8, 0.8, 0.8 ];
			if (is_selected)
			{
				color = [ 1,1,0.8,1 ];
				ctx.fillColor = [ 0.3,0.3,0.4,0.5 ];
				ctx.fillRect(item_x + 5, item_y, item_w - 10, size);
			}

			//in case something is drop on to the entity
			if( !GUI.dragged_item || GUI.dragged_item.entity != entity)
				GUI.DropArea( item_x + 10, item_y, item_w - 56, size, onDropOnEntity.bind(this), entity );

			if (mouse_state === GLUI.CLICKED) //clicked
			{
				//double click
				if (entity === this.selected_item && GUI.last_noclick_time < 250 && !gl.keys["SHIFT"] )
				{
					this.mode = RoomEditor.PROPERTIES_MODE;
					GUI.consumeClick();
				}
				else
					this.setSelection( entity, gl.keys["SHIFT"] );
			}
			else if (mouse_state === GLUI.PRESSED) //holding name
			{
				this._last_pressed_entity = entity;
				//if( gl.keys["SHIFT"] )
				//	this.setSelection( entity, true );
				//if(!entity._is_selected)
				//	this.setSelection( entity, gl.keys["SHIFT"] );
			}

			//Label.call(GUI, x + w - 60, item_y, 40, size, String(entity.index));


			if ( Button.call(GUI, item_x + item_w - 30, item_y, size, size, entity.enabled ? [ 8,7 ] : [ 9,7 ]) )
			{
				entity.enabled = !entity.enabled;
				this.autoSyncSelection();
			}

			if ( entity._components )
				show_components( entity, item_x, item_y, item_w );

			var icon = null;
			if ( is_group )
				icon = entity.collapsed ? [ 2,0 ] : [ 2,1 ];
			else
				icon = RoomEditor.icons.entities[ entity.type_name ] || [ 1,1 ];

			if (icon)
			{
				if (GUI.DrawIcon( item_x + 20 + size*0.5, item_y + size*0.5, 0.3, icon, false, is_group ? [ 0.7,0.9,1,0.7 ] : [ 1,1,1,0.5 ], null, is_group ) === GLUI.CLICKED )
					entity.collapsed = !entity.collapsed;
			}
			if (entity.name)
			{
				if ( !entity.enabled )
					color[3] *= 0.5;
				Label.call(GUI, item_x + 50, item_y + 2, item_w - 80, size, entity.name, color );
			}

			item_y += line_height;
			return item_y;
		}


		function show_components( entity, item_x, item_y, item_w )
		{
			//show components
			var compx = item_x + item_w - 30 - size - 2;
			for (var j = entity._components.length - 1; j >= 0; --j)
			{
				var comp = entity._components[j];
				var icon = comp.constructor.icon;
				if (!icon)
					continue;
				ctx.globalAlpha = 0.4;
				GUI.DrawIcon(compx + size*0.5, item_y + size*0.5, 0.3, icon );
				if (GUI.HoverArea(compx,item_y,size,size) === GLUI.CLICKED )
				{
					that.mode = RoomEditor.PROPERTIES_MODE;
					GUI.consumeClick();
					that.visible_comp_index = j;
					that.setSelection( entity, gl.keys["SHIFT"] );
				}
				ctx.globalAlpha = 1;

				if ( comp._warning )
					GUI.DrawIcon(compx + size*0.5, item_y + size*0.5, 0.3, [ 13,1 ],false,[ 1,1,0.2,1 ]);
				compx -= size - 4;
			}
		}

		function onDropOnEntity(e,entity)
		{
			var data = e.dataTransfer.getData("type");
			if (data === "Entity")
			{
				var uid = e.dataTransfer.getData("uid");
				var dragged_entity = space.getEntityById(uid);
				if ( dragged_entity && dragged_entity != entity )
				{
					if (dragged_entity._parent)
						dragged_entity._parent.removeEntity(dragged_entity);
					entity.addChild( dragged_entity );
				}
			}
		}

		function onDropOnCollection(e,collection)
		{
			var data = e.dataTransfer.getData("type");
			if (data === "Entity")
			{
				var uid = e.dataTransfer.getData("uid");
				var dragged_entity = space.getEntityById(uid);
				if ( dragged_entity )
				{
					//dragged_entity.collection = collection ? collection : null;
					that.assignCollectionToSelection( collection ? collection : null )
				}
			}
		}
	}

	onDragEntity(e,item) {
		var entity = item.entity;
		e.dataTransfer.setData("type","Entity");
		e.dataTransfer.setData("uid",item.entity.uid);
		if (!entity._is_selected)
			RoomEditor.instance.setSelection(entity, gl.keys["SHIFT"]);
	}

	onDragTransform(e,item) {
		var entity = item.entity;
		if(!entity || !entity.uid)
			return;
		e.dataTransfer.setData("type","Transform");
		e.dataTransfer.setData("uid",item.entity.uid);
		if(item.node_name)
			e.dataTransfer.setData("node_name",item.node_name);
	}

	onDragNode(e, item)
	{
		var node = item.node;
		var entity = RoomEditor.instance.translateNativeObject( node );
		//var entity = RoomEditor.instance.view.getSceneNodeEntity(node);

		if(!entity)
			return;
		e.dataTransfer.setData("type","Node");
		e.dataTransfer.setData("entity_id",entity.uid);
		e.dataTransfer.setData("node_id",node.name);
	}	

	renderInspector(ctx,x,y,w,h) {
		if (this.selected_item)
		{
			if ( this.selected_item.constructor === Entity || this.selected_item.constructor === EntityReference )
				this.renderEntityInspector(ctx, this.selected_item,x,y,w,h);
			else if ( this.selected_item.constructor === ROOM.Scene )
				this.renderSpaceInspector(ctx, this.space,x,y,w,h);
		}
	}

	renderSpaceInspector(ctx, space, x,y,w,h) {
		var starty = y;

		GUI.DrawIcon( 20, y + 12, 0.4, [ 10,6 ] );
		GUI.next_tooltip = "Room name";
		GUI.TextField( x + 32,y,w-80,24, space.filename || "", null, true );

		GUI.next_tooltip = "Show Settings";
		if ( Button.call(GUI,  x + w - 30, y, 24, 24, [ 5,0 ] ) )
		{
			this.attachDialog( SpaceSettingsDialog );
		}
		y += 40;

		y = this.renderScriptsList( ctx, x, y, w, 240 );
		y = this.renderComponentsPanel( ctx, space.root, x,y,w,h-(y - starty));
	}

	renderScriptsList(ctx, x,y,w,h ) {
		var scripts = this.space.scripts;
		var total_height = scripts.length * 24;

		Label.call(GUI, x + 5,y,w-20,24, "Scripts");
		if (!this.scripts_scroll )
			this.scripts_scroll = GUI.createScrollArea(w,h,total_height);

		//add script
		if ( Button.call(GUI,  x + w - 34, y, 24,24, [ 0,6 ] ) )
		{
			this.selectFile(function(file) {
				var path = file ? file.localpath : null;
				if (scripts.indexOf(path) === -1)
					scripts.push(path);
				ROOM.loadScripts([ getFullPath(path) ]);
			},[ "js" ]);
		}

		this.scripts_scroll.total = total_height;
		GUI.ScrollableArea( this.scripts_scroll, x + 10, y + 30, w - 20, h - 40, inner );

		function inner(x,y,w,h)
		{
			var iy = y;
			for (var i = 0; i < scripts.length; ++i)
			{
				var script = scripts[i];
				Label.call(GUI, x + 5,iy,w-20,20, script);
				if ( Button.call(GUI,  x + w - 30,iy,20,20, GLUI.icons.trash ) )
					scripts.splice(i--,1);
				iy += 24;
			}
		}

		return y + h;
	}

	renderEntityInspector(ctx, entity,x,y,w,h) {
		var editor = this;
		var space = this.view.space;
		if (!entity || (entity.constructor !== Entity && entity.constructor !== EntityReference ) )
			return;

		var starty = y;

		//ctx.fillStyle = "red";	ctx.fillRect(x,y,w,h); //debug
		var icon = RoomEditor.icons.entities[ entity.type_name ] || [ 1,1 ];
		if (GUI.HoverArea( x + 10, y + 2,20,20 ) === GLUI.CLICKED )
		{
			/*
			GUI.ShowContextMenu(["Seat","Surface","Prefab","Audio","Interaction"],
				{
				callback: function(v,item)
					{
						console.debug(v,item);
					},
				id: "icon_context_menu"
			});
			*/
		}

		GUI.HoverArea( x + 10, y+2, 20, 20, { entity: entity, callback: this.onDragEntity.bind(this) });
		GUI.DrawIcon( x + 20, y + 12, 0.4, icon, false, this.draggable_color );
		GUI.next_tooltip = "Previous";
		if ( Button.call(GUI, x + 32, y+2, 20,20, GLUI.icons.left ) )
		{
			//select previous
			if(this.previous_selection)
				this.setSelection(this.previous_selection);
		}
		GUI.next_tooltip = "Entity name";
		entity.name = GUI.TextField( x + 56,y,w-104,24, entity.name, null, true );
		GUI.next_tooltip = "Options";
		if ( Button.call(GUI,  x + w - 60, y, 24, 24, [ 8,0 ] ) )
		{
			GUI.ShowContextMenu( RoomEditor.entity_options, {
				callback: function(v,item)
				{
					console.debug(v,item);
					if (item && item.callback)
						item.callback(entity);
					else
					{

					}
				}
			});
		}

		GUI.next_tooltip = "Focus on entity";
		if ( Button.call(GUI,  x + w - 30, y, 24, 24, [ 8,6 ] ) )
			this.focusOnEntity(entity);

		y += 10;

		this.AnimBullet( x + 10,y+24,20, entity, "enabled");
		GUI.next_tooltip = "Enabled";
		if ( Button.call(GUI,  x + 30, y+24, 32,32, entity.enabled ? [ 8,7 ] : [ 9,7 ] ) )
		{
			entity.enabled = !entity.enabled;
			this.autoSyncSelection();
		}

		GUI.next_tooltip = "Layers";
		if ( Button.call(GUI,  x + 90, y+24, 32,32, [ 7,6 ] ) )
			this.attachDialog( LayersDialog );

		GUI.next_tooltip = "Collection";
		entity.collection = GUI.TextField( x + 150, y+24, 140,32, entity.collection || "") || null;
		if ( Button.call(GUI,  x + 292, y+24, 32,32, GLUI.icons.down ) )
		{
			var collections = space.root.getCollections();
			var values = Object.keys( collections );
			values.push("+");
			if ( entity.collection )
				values.push(null,"Remove");
			GUI.ShowContextMenu( values, { title:"Collections",
				callback: function(v,item)
				{
					console.debug(v,item);
					if (item === "+")
					{
						var name = prompt("Collection name");
						entity.collection = name || null;
					}
					else if (item === "Remove" )
						entity.collection = null;
					else
						entity.collection = item;
				}
			});
		}

		if ( entity.constructor === Entity ) //no group
		{
			/*
			GUI.next_tooltip = "Interactive";
			if ( Button.call(GUI,  x + 110, y+24, 32,32, GLUI.icons.cursor, entity.flags.interactive ) )
				entity.flags.interactive = !entity.flags.interactive;
			*/

			y += 70;

			y = this.renderParenting( ctx, entity, x, y, w );
			y = this.renderTransform( ctx, entity, x, y, w );
			y = this.renderComponentsPanel( ctx, entity, x,y,w,h-(y-starty) - 50);

			GUI.next_tooltip = "Sync Entity";
			if ( Button.call(GUI,  x + 50, starty + h - 40,40,40, GLUI.icons.refresh ) && this.selected_item.sync )
				this.selected_item.sync(true);
		}
		else if ( entity.constructor === EntityReference )
		{
			y += 60;
			y = this.renderTransform( ctx, entity, x, y, w );
			y += 30;
			GUI.next_tooltip = "Reference";
			Label.call(GUI, x,y,80,24, "URL");
			entity.url = GUI.TextField( x+80,y,w-110,24, entity.url || "", null, true );
			if ( Button.call(GUI,  x + w - 24,y,24,24, [ 2,0 ] ) )
			{
				editor.selectFile((function(file) {
					if ( file && file.localpath )
					{
						entity.url = file.localpath;
						entity.load( file.localpath );
					}
				}).bind(),[ "json" ],null,editor.url);
			}
		}

		GUI.next_tooltip = "Delete Selection";
		if ( !this.show_confirm && Button.call(GUI,  x, starty + h - 40,40,40, GLUI.icons.trash,false,[ 0.8,0.3,0.3,1 ],[ 1,1,1,1 ] ) )
			this.show_confirm = { text: "Deleting selection. Are you sure?", callback: (function() {
				this.deleteItem( this.selected_item );
			}).bind(this) };
	}

	renderComponentsPanel(ctx,container,x,y,w,h) {
		var space = this.view.space;
		var starty = y;

		//tab
		var tabcolor = [ 0.3,0.3,0.3,1 ];
		ctx.fillColor = tabcolor;
		ctx.beginPath();
		ctx.roundRect( x, y + 40, w, h - 40, [ 0, 0, 10, 10 ] );
		ctx.fill();

		//comps
		var selected_component = null;
		this.visible_comp_index = clamp( this.visible_comp_index, 0, container._components.length - 1 );

		for ( var i = 0; i < container._components.length; ++i)
		{
			var comp = container._components[i];
			if (this.visible_comp_index === i)
			{
				selected_component = comp;
				ctx.fillColor = tabcolor;
			}
			else
				ctx.fillColor = [ 0.1,0.1,0.1,0.5 ]

			//tab
			ctx.beginPath();
			ctx.roundRect( x + i * 42, y, 40, 40, [ 8,8,0,0 ] );
			ctx.fill();

			//component icon
			var color = this.draggable_color;
			GUI.DrawIcon( x + i * 42 + 20, y + 20, 0.5, comp.constructor.icon || [ 1,1 ], false, [ color[0], color[1], color[2], this.visible_comp_index === i ? 0.9 : 0.3 ] );

			//click to change
			if (GUI.HoverArea( x + i * 42, y, 40, 40, { target: comp, callback: this.onDragComponent } ) === GLUI.CLICKED )
			{
				this.visible_comp_index = i;
				selected_component = comp;
			}
		}

		if ( Button.call(GUI,  x + w - 38, y + 2, 36, 36, [ 0,6 ], false, [ 0.3,0.3,0.3,0 ], [ 0.4,0.4,0.4,0.3 ], 18) )
		{
			AddComponentPanel.target = this.selected_item;
			this.attachDialog( AddComponentPanel );
		}

		y += 40;
		if (selected_component)
		{
			//component panel
			y += this.renderComponentInspector( ctx, selected_component, x + 10, y, w - 20, h - (y - starty) - 20 );
		}

		return y;
	}

	renderParenting( ctx, entity, x, y, w )
	{
		if(entity.parent == entity.space.root)
			return y;

		var space = entity.space;

		Label.call(GUI,x + 10, y, 150, 26, "Parent");
		var value = GUI.TextField(x + 100, y, w - 130, 26, entity._parent.name, null, false );
		if ( Button.call(GUI, x + w - 40, y, 26,26, GLUI.icons.x, false ) )
		{
			entity._parent.removeChild( entity );
			space.root.addChild( entity )
		}

		y += 34;
		return y;
	}

	renderTransform( ctx, entity, x, y, w, node ) {
		if ( Button.call(GUI, x, y, 30,30, entity.__collapse_transform ? GLUI.icons.right_thin : GLUI.icons.down_thin, false, 0 ) )
			entity.__collapse_transform = !entity.__collapse_transform;

		//add icon here
		var target_entity = (node && node.getOwner) ? this.translateNativeObject(node) : entity;
		GUI.HoverArea( x + 30, y + 5, 20, 20, { entity: target_entity, node_name: node ? node.name : null, callback: this.onDragTransform.bind(this) });
		GUI.DrawIcon( x + 40, y + 15, 0.35, [ 1,4 ], false, this.draggable_color );

		Label.call(GUI,x + 55, y + 5, w, 26, "Transform");

		GUI.next_tooltip = "Copy";
		if ( Button.call(GUI,  x + w-100,y,26,26, [ 8,3 ], false,[ 0,0,0,0.5 ],null,-2 ) )
			RoomEditor.toClipboard({ type: "transform", position: Array.from(entity.position), rotation: Array.from(entity.rotation), scale: Array.from(entity.scaling) });

		GUI.next_tooltip = "Paste";
		if ( Button.call(GUI,  x + w-70,y,26,26, [ 9,3 ], false,[ 0,0,0,0.5 ],null,-2 ) )
		{
			var data = RoomEditor.fromClipboard();
			if(data && data.type == "transform")
			{
				entity.position = data.position;
				entity.rotation = data.rotation;
				entity.scaling = data.scale;
			}
		}

		GUI.next_tooltip = "Reset";
		if ( Button.call(GUI,  x + w-26,y,26,26, [ 4,2 ], false,[ 0,0,0,0.5 ],null,-2 ) )
		{
			entity.position = vec3.create();
			entity.rotation = quat.create();
			entity.scaling = [ 1,1,1 ];
		}

		x += 5;
		w -= 10;
		y += 40;

		if ( entity.__collapse_transform )
			return y;

		var is_entity = entity.constructor === Entity;
		var color = [ 1,1,1,0.7 ];

		if (is_entity)
			this.AnimBullet(x - 5,y,18, entity, "position");
		Label.call(GUI, x + 20, y,140,20, "Position", color );
		GUI.Vector( x + 90,y,w-130,20, entity.position, 0.01 );
		//if( GUI.value_changed )
		//	this.autoSyncSelection();
		GUI.next_tooltip = "Reset position";
		if ( is_entity && Button.call(GUI,  x + w-26,y-2,24,24, [ 1,5 ], false,[ 0,0,0,0.5 ],null,-2 ) )
		{
			entity.position = [ 0,0,0 ];
			this.focusOnEntity(entity);
		}

		y += 30;

		var rot = [ 0,0,0 ];
		quatToEuler(rot, entity.rotation );
		if (is_entity)
			this.AnimBullet(x - 5, y,18, entity, "rotation");
		Label.call(GUI, x + 20, y,140,20, "Rotation", color );
		vec3.scale( rot, rot, RAD2DEG );
		GUI.Vector( x + 90,y,w-130,20, rot, 0.1 );
		if ( GUI.value_changed )
		{
			vec3.scale( rot, rot, DEG2RAD );
			quatFromEuler(entity.rotation, rot);
		}

		GUI.next_tooltip = "Rotate 45 on Y";
		if ( Button.call(GUI,  x + w-26,y-2,24,24, [ 3,6 ], false,[ 0,0,0,0.5 ],null,-2 ) )
		{
			if (entity.node)
				entity.node.rotate(45*DEG2RAD,[ 0,1,0 ]);
			else if ( entity.rotate )
				entity.rotate(45*DEG2RAD,[ 0,1,0 ]);
			this.autoSyncSelection();
		}

		if ( 0 && Button.call(GUI,  x + w-40,y-10,24,24, [ 1,5 ] ) )
			entity.rotation = quat.create();

		y += 30;

		if (is_entity)
			this.AnimBullet(x - 5, y,18, entity, "scaling");
		Label.call(GUI, x + 20, y,w-120,20, "Scale", color );
		if (entity.__const_scale)
		{
			var s = GUI.Number( x + 90,y,w-130,20, entity.scaling[0], 0.01 );
			entity.scaling[0] = entity.scaling[1] = entity.scaling[2] = s;
			//if( GUI.value_changed )
			//	this.autoSyncSelection();
		}
		else
		{
			GUI.Vector( x + 90,y,w-130,20, entity.scaling, 0.01 );
			if ( GUI.value_changed )
				this.autoSyncSelection();
		}
		GUI.next_tooltip = "Uniform scale";
		if ( Button.call(GUI,  x + w-26,y-2,24,24, [ this.const_scale ? 10 : 11,5 ], false,[ 0,0,0,0.5 ],null,-2 ) )
			entity.__const_scale = !entity.__const_scale;
		//if( 0 && Button.call(GUI,  x + w-40,y-10,24,24, [1,5] ) )
		//	entity.node.scaling = [1,1,1];

		if (is_entity)
		{
			entity.syncNative();
			entity.node.mustSync(true);
		}
		else {
			if (!entity.native && entity.room_entity && entity.room_entity._native) {
				var nativeEntity = entity.room_entity._native;
				var nativeNode = nativeEntity.getRootNode();
				nativeNode.position = entity.position;
				nativeNode.rotation = entity.rotation;
				nativeNode.scaling = entity.scaling;
			}
			entity._must_update_matrix = true;
		}

		y += 40;
		return y;
	}

	renderComponentInspector(ctx, component, x, y, w, h ) {
		var that = this;

		y += 10;

		//title bar
		ctx.fillColor = [ 0,0,0,0.3 ];
		ctx.fillRect(x-5,y-5,w+10,30);

		var startx = x;
		if (component.enabled != null)
		{
			this.AnimBullet(startx + 5,y,20, component, "enabled" );
			component.enabled = GUI.Toggle(startx+25, y, 20,20, null, component.enabled );
			if ( GUI.value_changed )
				this.autoSyncSelection();
			startx += 55;
		}

		Label.call(GUI, startx, y,140,24, getObjectClassName(component) );
		if ( Button.call(GUI, x + w - 26, y - 2, 24,24, [ 8,0 ],false,null,null,0) )
		{
			GUI.ShowContextMenu([ "Help","Show JSON",null,"Delete" ],
				{
					callback: function(v,item)
					{
						console.debug(v,item);
						if (item === "Show JSON")
						{
							var json = {};
							component.serialize(json);
							RoomEditor.showJSON(json, "Component");
						}
						if (item === "Delete")
						{
							that.show_confirm = { text: "Are you sure?", callback: (function() {
								this.deleteComponent(component);
							}).bind(that) };
						}
					},
					id: "component_context_menu"
				});
		}

		if (component.onRenderInspector)
			y = component.onRenderInspector( ctx, x, y + 35, w, h - 25, this ) || y;
		else
			y = this.renderDefaultInspector( ctx, x, y + 35, w, h - 25, component );

		//warning
		if ( component._warning )
		{
			GUI.DrawIcon(x + 20, y + 10, 0.4, [ 13,1 ], false, [ 1,1,0.5,1 ] );
			Label.call(GUI, x + 40, y, w - 50, 20, component._warning );
			if ( Button.call(GUI,  x + w - 40, y, 24,24, GLUI.icons.x ) )
				delete component._warning;

			y += 30;
		}

		return y;
	}

	extractWidgetsFromObject(instance) {
		var classObject = instance.constructor;
		var properties_info = {};

		for (var i in instance)
		{
			var v = instance[i];
			if (i[0] === "_")
				continue;

			if (classObject["@" + i]) //guess from class object info
			{
				var shared_options = classObject["@" + i];
				if ( shared_options && shared_options.widget === null)
					continue; //skip
				properties_info[i] = inner_clone( shared_options );
			}
			else if (instance["@" + i]) //guess from instance info
				properties_info[i] = instance["@" + i];
			else if (v === null || v === undefined) //are you sure?
				continue;
			else
			{
				switch ( v.constructor )
				{
				case Number: properties_info[i] = { type: "number", step: 0.1 }; break;
				case String: properties_info[i] = { type: "string" }; break;
				case Boolean: properties_info[i] = { type: "boolean" }; break;
				default:
					if ( v && (v.constructor === Array || v.constructor.BYTES_PER_ELEMENT) ) //Array or typed_array
					{
						var is_number = v[0] != null && v[0].constructor === Number;
						switch (v.length)
						{
						case 2: properties_info[i] = { type: is_number ? "vec2" : "Array", step: 0.1 }; break;
						case 3: properties_info[i] = { type: is_number ? "vec3" : "Array", step: 0.1 }; break;
						case 4: properties_info[i] = { type: is_number ? "vec4" : "Array", step: 0.1 }; break;
						default:
							properties_info[i] = { type: "Array" }; break;
							continue;
						}
					}
				}
			}
		}

		return properties_info;

		//basic cloner
		function inner_clone(original, target)
		{
			target = target || {};
			for (var j in original)
				target[j] = original[j];
			return target;
		}
	}

	renderDefaultInspector(ctx, x,y,w,h, component ) {
		var start_y = y;
		var line_height = 24;
		var margin = 4;
		var editor = this;

		var widgets = component.widgets || component.constructor.widgets;
		if (!widgets)
		{
			widgets = this.extractWidgetsFromObject(component);
			//return y;
		}

		for (var i in widgets)
		{
			var widget = widgets[i];
			var value = component[i];
			if (i === "enabled" ) //skip enabled as it is show in the title
				continue;

			var type = null;
			if ( widget.constructor === String )
				type = widget;
			else if ( widget.widget )
				type = widget.widget;
			else if ( widget.type )
				type = widget.type;
			else
				type = typeof(value);

			if (type !== "button")
			{
				this.AnimBullet(x + 4,y,20, component, i );
				Label.call(GUI, x + 20, y, 120, line_height, i );
			}

			var editable_start = x + 120;
			var editable_width = x + w - editable_start;
			GUI.next_tooltip = i;

			switch (type)
			{
			case "null": break;
			case "number": value = GUI.Number(editable_start, y, editable_width, line_height, value ); break;
			case "slider": value = GUI.Slider(editable_start, y, editable_width, line_height, value, widget.min || 0, widget.max || 1, widget.step || 0 ); break;
			case "vec2":
			case "vec3":
			case "vector2":
			case "vector3": value = GUI.Vector(editable_start, y, editable_width, line_height, value ); break;
			case "color": GUI.Color3(editable_start, y, editable_width, line_height, value ); break;
			case "boolean":
			case "toggle": value = GUI.Toggle(editable_start, y, editable_width, line_height, null, value ); break;
			case "string":
			case "textfield":
				value = GUI.TextField(editable_start, y, editable_width - 24, line_height, value );
				if ( Button.call(GUI,  x + w - 24,y,24,24, [ 2,0 ] ) )
					value = prompt("Set value", value || "") || "";
				break;
			case "asset":
				value = GUI.TextField( editable_start,y,editable_width-24,24, value || "", null, true );
				if ( Button.call(GUI,  x + w - 24,y,24,24, [ 2,0 ] ) )
				{
					editor.selectFile((function(file) {
						component[this.prop] = file ? file.localpath : null;
						if (component.mustUpdate )
							component.mustUpdate();
					}).bind({ prop:i }),widget.extensions,null,value);
				}
				break;
			case "entity":
				value = GUI.TextField(editable_start, y, editable_width - 24, line_height, value );
				if ( Button.call(GUI,  x + w - 24,y,24,24, [ 2,0 ] ) )
					value = prompt("Set value", value || "") || "";
				break;
			case "enum":
			case "combo":
				if (widget.values)
				{
					var values = widget.values;
					if ( values.constructor === Function )
						values = values(component);
					var index =	values.constructor === Array ? values.indexOf( value ) : values[i];
					var r = GUI.ComboLine( editable_start, y, editable_width, line_height, index, values, i, widget.labels );
					if (r != null && r !== -1 )
						value = values[r];
				}
				break;
			case "button":
				if ( Button.call(GUI, x, y, w, line_height, i ) )
				{
					if ( component[i] && component[i].constructor === Function )
						component[i]();
				}
				break;
			default:
				break;
			}

			y += line_height + margin;
			if (value !== undefined)
				component[i] = value;

			if (GUI.value_changed && component.mustUpdate )
				component.mustUpdate();
		}

		if ( component.onRenderExtraInspector )
			component.onRenderExtraInspector( ctx,x,y,w,h - (y - start_y), this );

		return y;
	}

	renderSession(ctx,x,y,w,h) {
		var space = this.view.space;
		var sidewidth = w;
		var that = this;
		var xyz = this.launcher;

		Label.call(GUI, 10, y,140,30, "ROOM" );
		this.space.url = GUI.TextField(100,y - 5,sidewidth - 100,30, this.space.url, null, true );

		y += 50;
		GUI.next_tooltip = "New Session";
		if ( Button.call(GUI,  10,y-20,50,50, [ 5,2 ] ) )
		{
			this.show_confirm = { text: "Are you sure?", callback: (function() {
				this.newSession();
			}).bind(this) };
		}
		GUI.next_tooltip = "Download session";
		if ( Button.call(GUI,  70,y-20,50,50, [ 6,0 ] ) )
		{
			var data = space.serialize();
			this.addSerializeMetaInfo(data);
			//download
			ROOM.downloadFile( space.filename || "room.json", JSON.stringify( data, null, " " ) );
		}

		if ( xyz.backend && xyz.backend.username )
		{
			GUI.next_tooltip = "Save To Server";
			if ( Button.call(GUI,  130,y-20,50,50, [ 0,8 ] ) )
			{
				this.selectFile(function(file,folder) {
					if (file)
						that.saveSessionRemote( folder + "/" + file );
				},"json", space.filename );
			}

			GUI.next_tooltip = "Load From Server";
			if ( Button.call(GUI,  190,y-20,50,50, [ 1,8 ] ) )
			{
				this.selectFile(function(file) {
					if (!file)
						return;
					space.load( file.relpath.substr( xyz.space.root_path.length + 2 ) ); //remove "/data/"
				},"json", null, xyz.backend.current_folder );
			}
		}
		else
		{
			GUI.next_tooltip = "Save Locally";
			if ( Button.call(GUI,  130,y-20,50,50, [ 0,8 ] ) )
			{
				//upload
				var data = space.serialize();
				console.debug(data);
				localStorage.setItem("TMRW_session", JSON.stringify( data ) );
			}
			GUI.next_tooltip = "Load Locally";
			if ( Button.call(GUI,  190,y-20,50,50, [ 1,8 ] ) )
			{
				//upload
				var data = localStorage.getItem("TMRW_session");
				if (data)
				{
					data = JSON.parse(data);
					console.debug(data);
					space.clear();
					space.configure( data );
				}
			}
		}

		y += 70;

		Label.call(GUI, 10, y,140,30, "Name" );
		this.space.name = GUI.TextField(110,y - 5,sidewidth - 110,30, this.space.name, null, true );
		y += 40;

		Label.call(GUI, 10, y,140,30, "Variation" );
		y += 40;

		Label.call(GUI, 10, y,140,30, "Description" );
		if ( Button.call(GUI,  sidewidth - 140,y,140,30, "Edit") )
			this.space.description = prompt( "Description", this.space.description ) || "";
		y += 40;

		Label.call(GUI, 10, y,140,30, "Date" );
		GUI.TextField(130,y - 5,sidewidth - 130,30, xyz.space.timestamp	|| "");
		y += 40;

		ctx.fillColor = [ 0.15,0.15,0.15,1 ];
		ctx.beginPath();
		ctx.roundRect(10,y,w-10,50,[ 5 ]);
		ctx.fill();
		Label.call(GUI, 15, y+5,w-20,20, this.space.description );
		y += 70;

		Label.call(GUI, 10, y,140,30, "Status" );
		if ( Button.call(GUI, sidewidth - 100,y,100,30, this.space.status) )
			this.space.status = (this.space.status === "public" ? "private" : "public" );
		y += 40;

		if ( GUI.DrawIcon( 20, y+10, 0.5, this.tags_collapsed ? [ 5,3 ] : [ 13,3 ],null,null,null,true ) === GLUI.CLICKED )
		{
			this.tags_collapsed = !this.tags_collapsed;
			GUI.consumeClick();
		}

		Label.call(GUI, 30, y,140,30, "Tags" );
		y += 40;

		if ( !this.tags_collapsed )
		{
			for (var i in this.space.tags )
			{
				this.space.tags[i] = GUI.Toggle(20,y,w-40,20, i, this.space.tags[i] );
				y += 24;
			}

			if ( Button.call(GUI, 10,y,w-20,20, "Add Tag") )
			{
				var tag = prompt("new tag");
				if (tag)
					this.space.tags[ tag ] = false;
			}
			y += 30;
		}


		if ( GUI.DrawIcon( 20, y+10, 0.5, this.categories_collapsed ? [ 5,3 ] : [ 13,3 ],null,null,null,true ) === GLUI.CLICKED )
		{
			this.categories_collapsed = !this.categories_collapsed;
			GUI.consumeClick();
		}

		Label.call(GUI, 30, y,140,30, "Categories" );
		y += 40;

		if ( !this.categories_collapsed )
		{
			for (var i in this.space.categories )
			{
				Label.call(GUI,20,y,w-40,20, i );
				if ( Button.call(GUI, w-20,y,20,20,GLUI.icons.x) )
					delete this.space.categories[i];
				y += 24;
			}

			if ( Button.call(GUI, 10,y,w-20,20, "Add Category") )
			{
				var category = prompt("new category");
				if (category)
					this.space.categories[ category ] = true;
			}

			y += 30;
		}

		y += 20;
		Label.call(GUI, 10, y,140,30, "Plugins" );
		if ( Button.call(GUI, sidewidth - 50,y,40,40,[ 0,6 ], false, [ 0.1,0.1,0.1,0.9 ],null,10 ) )
		{
			this.selectFile( function(url) {
				if (url)
				{
					that.showNotification("Script added");
					xyz.addPlugin( url.fullpath );
				}
			}, [ "js" ] );
		}

		y += 8;

		for ( var i = 0; i < xyz.plugins.length; i++)
		{
			var url = xyz.plugins[i];
			var plugin_url = url;
			var index = plugin_url.lastIndexOf("/");
			if (index !== -1)
				plugin_url = plugin_url.substr( index );
			Label.call(GUI, 30, y + 40 + i * 30, 140,24, plugin_url );
			if ( Button.call(GUI,  sidewidth - 90, y + 30 + i * 30, 32,32,[ 10,0 ] ) ) //trash
				xyz.plugins.splice(i,1);
			if ( Button.call(GUI,  sidewidth - 50, y + 30 + i * 30, 32,32,[ 1,5 ] ) ) //refresh
			{
				xyz.plugins.splice(i,1);
				xyz.addPlugin( url );
			}
		}
	}

	renderSettings(ctx,x,y,w,h) {
		var that = this;

		//add scrollbar
		if (!this.settings_scroll )
			this.settings_scroll = GUI.createScrollArea(w,h,h);

		this.settings_scroll.total = h;
		GUI.ScrollableArea( this.settings_scroll, x + 10, y + 30, w - 20, h - 40, inner );

		function inner(x,y,w,h)
		{
			that.renderSettingsWidgets(x,y,w,h);
		}
	}

	renderSettingsWidgets(x,y,w,h) {
		var that = this;
		var space = this.view.space;
		var sidewidth = w;
		var pipeline = this.view.pbrpipeline;
		var view = this.view;
		var nativeEngine = window.nativeEngine;
		
		if ( this.drawCollapsableLabel( "environment","Environment", x,y,w ) )
		{
			GUI.Color3(sidewidth - 70,y,60,30, this.space.environment.color, [ sidewidth + 100, 100,256,256 ] );
			GUI.next_tooltip = "Render Skybox";
			if ( Button.call(GUI,  sidewidth - 110, y, 30,30, space.settings.render_skybox ? [ 3,0 ] : [ 9,7 ] ) )
				space.settings.render_skybox = !space.settings.render_skybox;
			y += 40;
			Label.call(GUI,10,y,100,24, "Rotation");
			if (pipeline)
			{
				space.environment.rotation = pipeline.environment_rotation = GUI.Slider(110,y,sidewidth-120,24, pipeline.environment_rotation, 0,360,5 );
				space.environment.rotation = pipeline.environment_rotation = Math.round( pipeline.environment_rotation / 5 ) * 5; //quantize
				Label.call(GUI,10,y+30,100,24, "Exposure");
				pipeline.environment_factor = GUI.Slider(110,y+30,sidewidth-120,24, pipeline.environment_factor, 0,5,0,1 );
			}
			else
				space.environment.exposure = GUI.Slider(110,y,sidewidth-120,24, space.environment.exposure, 0,5,0,1 );

			var ctx = gl;
			ctx.fillColor = [ 0,0,0,0.9 ];
			ctx.fillRect( 10, y + 60, sidewidth - 20, 120 );
			if (space.environment.url && pipeline )
			{
				var texture = pipeline.environment_texture;
				if (texture)
					this.drawCubemap( texture, 14, y + 64, sidewidth - 28, 112, pipeline.environment_factor, pipeline.environment_rotation );
			}
			y += 190;

			space.environment.url = GUI.TextField( 10, y, sidewidth-70, 24, space.environment.url, null, true );
			if ( Button.call(GUI,  10 + sidewidth - 70, y, 24,24, [ 2,0 ] ) )
			{
				this.selectFile(function(file) {
					var path = file ? file.localpath : null;
					if (path !== space.environment.url)
					{
						space.environment.url = ROOM.removeExtension(path);
						view.setEnvironment( getFullPath(path) );
					}
				},[ "hdre" ],null,space.environment.url + ".hdre");
			}
			if ( Button.call(GUI,  10 + sidewidth - 40, y, 24,24, GLUI.icons.x ) )
			{
				space.environment.url = null;
				view.setEnvironment( null );
			}
			y += 10;

			if (xyz.native_mode) {
				var lighting = nativeEngine.getLighting(true);

				lighting.iblRotation = space.environment.rotation;
				if(pipeline)
					lighting.iblIntensity = pipeline.environment_factor * 30000.0;
				else
					lighting.iblIntensity = space.environment.exposure * 30000.0;

				nativeEngine.setLighting();
			}
		}
		y += 30;

		if ( this.drawCollapsableLabel( "default_camera","Default Camera", x,y,w ) )
		{
			GUI.next_tooltip = "View from camera";
			if ( Button.call(GUI, sidewidth - 30,y,24,24, [ 8,7 ] ) )
				this.camera.lookAt( space.camera_info.position, space.camera_info.target,[ 0,1,0 ] );
			y += 30;
			Label.call(GUI, 30,y,100,24, "Position");
			GUI.Vector( 120,y,w-140,24, space.camera_info.position );
			if ( Button.call(GUI, w-30,y,24,24, [ 5,6 ] ) )
				space.camera_info.position = vec3.clone( this.camera.position );
			y += 30;
			Label.call(GUI, 30,y,100,24, "Target");
			GUI.Vector( 120,y,w-140,24, space.camera_info.target );
			if ( Button.call(GUI, w-30,y,24,24, [ 5,6 ] ) )
				space.camera_info.target = vec3.clone( this.camera.position );
			y += 10;
		}

		y += 30;

		if ( this.drawCollapsableLabel( "fx","Post FX", x,y,w ) )
		{
			GUI.next_tooltip = "Reset values";
			if ( Button.call(GUI,  sidewidth-120, y, 100,24, [ 12,0 ] ) )
			{
				space.fx.exposure = space.fx.illumination = space.fx.illumination_gamma = space.fx.emissive_factor = space.fx.contrast = space.fx.brightness = space.fx.participants_brightness = 1;
				vec3.copy( space.fx.participants_basecolor, [ 1,1,1 ] );
			}

			y+=40;
			Label.call(GUI,10,y,100,24, "Exposure");
			space.fx.exposure = GUI.Slider( 120, y, sidewidth-130,24, space.fx.exposure, 0, 5, 0, 1 );
			y+=30;
			Label.call(GUI,10,y,100,24, "Illumination");
			space.fx.illumination = GUI.Slider( 120, y, sidewidth-130,24, space.fx.illumination, 0, 5, 0, 1 );
			y+=30;
			Label.call(GUI,10,y,100,24, "Ill.Gamma");
			space.fx.illumination_gamma = GUI.Slider( 120, y, sidewidth-130,24, space.fx.illumination_gamma, 0, 2, 0, 1 );
			y+=30;
			Label.call(GUI,10,y,100,24, "Emissive");
			space.fx.emissive_factor = GUI.Slider( 120, y, sidewidth-130,24, space.fx.emissive_factor, 0, 4, 0, 1 );
			y+=30;
			Label.call(GUI,10,y,100,24, "Contrast");
			space.fx.contrast = GUI.Slider( 120, y, sidewidth-130,24, space.fx.contrast, 0, 2, 0, 1 );
			y+=30;
			Label.call(GUI,10,y,100,24, "Brightness");
			space.fx.brightness = GUI.Slider( 120, y, sidewidth-130,24, space.fx.brightness, 0, 2, 0, 1 );
			y+=30;
			Label.call(GUI,10,y,100,24, "Participants");
			space.fx.participants_brightness = GUI.Slider( 120, y, 120,24, space.fx.participants_brightness, 0.5, 3, 0, 1 );
			//y+=30;
			GUI.Color3( 240, y, 40, 24, space.fx.participants_basecolor );
			//GUI.Color3( 240, y, sidewidth-260,24, space.fx.participants_basecolor );
			if ( Button.call(GUI,  sidewidth-40, y, 30, 24, [ 6,4 ] ) )
			{
				var tool = this.selectTool("pick_color");
				tool.callback = function(c) {
					if (c)
						vec3.copy( space.fx.participants_basecolor, c );
					that.selectTool();
				}
			}

			if (nativeEngine) {
				var fx = nativeEngine.getSceneFX(true, 0);

				fx.exposure = space.fx.exposure;
				fx.illumination = space.fx.illumination;
				fx.occlusion_gamma = space.fx.illumination_gamma;
				fx.emissive_factor = space.fx.emissive_factor;
				fx.contrast = space.fx.contrast;
				fx.brightness = space.fx.brightness;

				nativeEngine.setSceneFX(null, 0);
			}

			y += 30;

			if (nativeEngine) {
				if (this.drawCollapsableLabel("fx", "Low Post FX", x, y, w)) {
					var fxl = nativeEngine.getSceneFX(true, 1);

					y += 40;
					Label.call(GUI, 10, y, 100, 24, "Exposure");
					fxl.exposure = GUI.Slider(120, y, sidewidth - 130, 24, fxl.exposure, 0, 5, 0, 1);
					y += 30;

					Label.call(GUI, 10, y, 100, 24, "Illumination");
					fxl.illumination = GUI.Slider(120, y, sidewidth - 130, 24, fxl.illumination, 0, 5, 0, 1);
					y += 30;

					Label.call(GUI, 10, y, 100, 24, "Ill.Gamma");
					fxl.occlusion_gamma = GUI.Slider(120, y, sidewidth - 130, 24, fxl.occlusion_gamma, 0, 2, 0, 1);
					y += 30;

					Label.call(GUI, 10, y, 100, 24, "Emissive");
					fxl.emissive_factor = GUI.Slider(120, y, sidewidth - 130, 24, fxl.emissive_factor, 0, 4, 0, 1);
					y += 30;

					Label.call(GUI, 10, y, 100, 24, "Contrast");
					fxl.contrast = GUI.Slider(120, y, sidewidth - 130, 24, fxl.contrast, 0, 2, 0, 1);
					y += 30;

					Label.call(GUI, 10, y, 100, 24, "Brightness");
					fxl.brightness = GUI.Slider(120, y, sidewidth - 130, 24, fxl.brightness, 0, 2, 0, 1);
					y += 30;

					nativeEngine.setSceneFX(null, 1);
				}

				y += 30;
			}

			/*
			if (space.fx.glow)
			{
				y += 20;
				Label.call(GUI,10, y, 100, 24, "Glow Int.");
				space.fx.glow.intensity = GUI.Slider(140, y, sidewidth - 150, 24, space.fx.glow.intensity, 0, 2);
				Label.call(GUI,10, y + 30, 100, 24, "Glow Pers.");
				space.fx.glow.persistence = GUI.Slider(140, y + 30, sidewidth - 150, 24, space.fx.glow.persistence, 0, 2);
				Label.call(GUI,10, y + 60, 100, 24, "Glow Thres.");
				space.fx.glow.threshold = GUI.Slider(140, y + 60, sidewidth - 150, 24, space.fx.glow.threshold, 0, 4);

				if (window.nativeEngine) {
					var glow = nativeEngine.getGlow(true);

					glow.gain = space.fx.glow.intensity;
					glow.persistence = space.fx.glow.persistence;
					glow.threshold = space.fx.glow.threshold;

					nativeEngine.setGlow();
				}

				if (window.nativeEngine) {
					var glow = nativeEngine.getGlow(true);

					glow.gain = space.fx.glow.intensity;
					glow.persistence = space.fx.glow.persistence;
					glow.threshold = space.fx.glow.threshold;

					nativeEngine.setGlow();
				}
			} else
*/
			if (window.nativeEngine)
			{
				var glow = nativeEngine.getGlow(true);
				glow.enable = false;
				nativeEngine.setGlow();
			}

			y += 80;
		}

		y += 30;

		if (xyz.native_mode) {
			if (Button.call(GUI, x + w - 100, y, 100, 24, "Reset"))
				space.fx.native = clone(ROOM_SETTINGS.render.fx);
			y += 40;
			var q_types = [
				"LOW",
				"MEDIUM",
				"HIGH",
				"ULTRA"
			];
			var q_type_values = [
				TmrwModule.NativeViewRP$QualityLevel.LOW,
				TmrwModule.NativeViewRP$QualityLevel.MEDIUM,
				TmrwModule.NativeViewRP$QualityLevel.HIGH,
				TmrwModule.NativeViewRP$QualityLevel.ULTRA
			];

			var native = this.space.settings.native_menu;
			if (Button.call(GUI, 10, y, w - 20, 24, "Native menu"))
				this.space.settings.native_menu = !this.space.settings.native_menu;
			if (native !== this.space.settings.native_menu) {
				nativeEngine._engine.setNativeMenu(this.space.settings.native_menu);
			}
			y += 40;

			if (this.drawCollapsableLabel("nativetod", "Time-Of-Day", x + 10, y, w)) {
				y += 40;
				var todTime = nativeEngine._room.getTimeOfDay();
				if (todTime < 0) {
					Label.call(GUI, x + 0, y, w, 26, "Current Room doesn't support TOD...");
					y += 30;
				}
				else {
					var todTimeNew = GUI.SliderVal(140, y, sidewidth - 150, 24, todTime, 0.0, 23.9);
					y += 30;

					if (todTime != todTimeNew) {
						nativeEngine._room.setTimeOfDay(todTimeNew);
					}
				}
				y += 10;
			}
			else
				y += 40;

			if (this.drawCollapsableLabel("nativelitfx", "Lighting / Shadows", x + 10, y, w)) {
				y += 40;

				var lighting = nativeEngine.getLighting(true);
				var shadows = nativeEngine.getShadows(true);

				if (Button.call(GUI, x + 15, y, 140, 24, "Reset Lighting")) {
					nativeEngine.setLighting(nativeEngine.getLightingDef());
					lighting = nativeEngine.getLighting(false);
				}
				y += 30;

				if (Button.call(GUI, x + 15, y, 140, 24, "Reset Shadows")) {
					nativeEngine.setShadows(nativeEngine.getShadowsDef());
					shadows = nativeEngine.getShadows(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enable Sun");
				lighting.enableSunLight = GUI.Toggle(140, y, sidewidth - 150, 24, null, lighting.enableSunLight);
				y += 30;

				if (lighting.enableSunLight) {
					Label.call(GUI, x + 15, y, 100, 24, "Sun on LM-Materials");
					lighting.sunLightOnLMMaterials = GUI.Toggle(140, y, sidewidth - 150, 24, null, lighting.sunLightOnLMMaterials);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Sun Color");
					GUI.Color3(140, y, sidewidth - 150, 24, lighting.sunLightColor);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Sun Intensity");
					lighting.sunLightIntensity = GUI.SliderVal(140, y, sidewidth - 150, 24, lighting.sunLightIntensity, 100.0, 500000.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Sun Direction");
					GUI.Vector(140, y, sidewidth - 150, 24, lighting.sunLightDirection, 0.01);
					y += 30;
				}
				y += 10;

				Label.call(GUI, x + 15, y, 100, 24, "IBL Intensity");
				lighting.iblIntensity = GUI.SliderVal(140, y, sidewidth - 150, 24, lighting.iblIntensity, 0.0, 30000.0);
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "IBL Rotation");
				lighting.iblRotation = GUI.SliderVal(140, y, sidewidth - 150, 24, lighting.iblRotation, 0.0, 359.0);
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Demo Lights");
				lighting.enableDemoLights = GUI.Toggle(140, y, sidewidth - 150, 24, null, lighting.enableDemoLights);
				y += 30;

				Label.call(GUI, x + 25, y, 100, 24, "Lights Count");
				lighting.demoLights = GUI.SliderVal(150, y, sidewidth - 150, 24, lighting.demoLights, 0, 48, 1);
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Sun Shadows");
				lighting.enableSunShadows = GUI.Toggle(140, y, sidewidth - 150, 24, null, lighting.enableSunShadows);
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Omni Shadows");
				lighting.enableOmniShadows = GUI.Toggle(140, y, sidewidth - 150, 24, null, lighting.enableOmniShadows);
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Spot Shadows");
				lighting.enableSpotShadows = GUI.Toggle(140, y, sidewidth - 150, 24, null, lighting.enableSpotShadows);
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Contact Shadows");
				shadows.screenSpaceContactShadows = GUI.Toggle(140, y, sidewidth - 150, 24, null, shadows.screenSpaceContactShadows);
				y += 30;

				if (shadows.screenSpaceContactShadows) {
					if (this.drawCollapsableLabel("nativelitcshfx", "Contact Shadows", x + 10, y, w)) {
						y += 40;

						var csh = shadows;

						Label.call(GUI, x + 25, y, 100, 24, "Steps Count");
						csh.stepCount = GUI.SliderVal(150, y, sidewidth - 150, 24, csh.stepCount, 1, 16, 1);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Distance");
						csh.maxContactShadowDistance = GUI.SliderVal(150, y, sidewidth - 150, 24, csh.maxContactShadowDistance, 0.01, 5.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Bias");
						csh.contactShadowBias = GUI.SliderVal(150, y, sidewidth - 150, 24, csh.contactShadowBias, 0.1, 10.0);
						y += 30;
					}
					else
						y += 40;
				}

				if (shadows.screenSpaceContactShadows || lighting.enableSunShadows || lighting.enableOmniShadows || lighting.enableSpotShadows) {
					Label.call(GUI, x + 25, y, 100, 24, "Ambient");
					shadows.ambientInfluence = GUI.SliderVal(150, y, sidewidth - 150, 24, shadows.ambientInfluence, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 25, y, 100, 24, "Shadow Far");
					shadows.shadowFar = GUI.SliderVal(150, y, sidewidth - 150, 24, shadows.shadowFar, 0.0, 400.0);
					y += 30;

					Label.call(GUI, x + 25, y, 100, 24, "Shadow LightSize");
					shadows.shadowBulbRadius = GUI.SliderVal(150, y, sidewidth - 150, 24, shadows.shadowBulbRadius, 0.0, 20.0);
					y += 30;

					Label.call(GUI, x + 25, y, 100, 24, "Shadow Far");
					shadows.shadowFar = GUI.SliderVal(150, y, sidewidth - 150, 24, shadows.shadowFar, 0.0, 400.0);
					y += 30;

					Label.call(GUI, x + 25, y, 100, 24, "Shadow LightSize");
					shadows.shadowBulbRadius = GUI.SliderVal(150, y, sidewidth - 150, 24, shadows.shadowBulbRadius, 0.0, 20.0);
					y += 30;
				}

				if (lighting.enableSunShadows || lighting.enableOmniShadows || lighting.enableSpotShadows) {
					if (this.drawCollapsableLabel("nativelitshfx", "Shadow Options", x + 10, y, w)) {
						y += 40;

						Label.call(GUI, x + 25, y, 100, 24, "Shadow Type");
						var s_types = [
							"PCF",
							"VSM",
							"DPCF",
							"PCSS"
						];
						var s_type_values = [
							TmrwModule.NativeViewRP$ShadowType.PCF,
							TmrwModule.NativeViewRP$ShadowType.VSM,
							TmrwModule.NativeViewRP$ShadowType.DPCF,
							TmrwModule.NativeViewRP$ShadowType.PCSS
						];
						var sindex = lighting.shadowType.value;
						lighting.shadowType = s_type_values[GUI.ComboLine(150, y, sidewidth - 150, 24, sindex, s_types)];
						y += 30;

						if (lighting.enableSunShadows) {
							Label.call(GUI, x + 25, y, 100, 24, "Cascades");
							shadows.shadowCascades = GUI.Slider(150, y, sidewidth - 150, 24, shadows.shadowCascades, 1, 4, 1);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Casc-Splits");
							GUI.Vector(150, y, sidewidth - 150, 24, shadows.cascadeSplitPositions, 0.01);
							y += 30;
						}

						Label.call(GUI, x + 25, y, 100, 24, "Shadow-Map Size");
						var sh_types = [
							"256",
							"512",
							"1024",
							"2048"
						];
						var sh_type_values = [
							256,
							512,
							1024,
							2048
						];
						var shindex = 0;
						if (shadows.mapSize <= 256)
							shindex = 0;
						else
						if (shadows.mapSize <= 512)
							shindex = 1;
						else
						if (shadows.mapSize <= 1024)
							shindex = 2;
						else
						if (shadows.mapSize <= 2048)
							shindex = 3;
						shadows.mapSize = sh_type_values[GUI.ComboLine(150, y, sidewidth - 150, 24, shindex, sh_types)];
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Stable");
						shadows.stable = GUI.Toggle(150, y, sidewidth - 150, 24, null, shadows.stable);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "LISPSM");
						shadows.lispsm = GUI.Toggle(150, y, sidewidth - 150, 24, null, shadows.lispsm);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Constant Bias");
						shadows.constantBias = GUI.SliderVal(150, y, sidewidth - 150, 24, shadows.constantBias, 0.0, 1.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Normal Bias");
						shadows.normalBias = GUI.SliderVal(150, y, sidewidth - 150, 24, shadows.normalBias, 0.0, 10.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Offset Constant");
						shadows.polygonOffsetConstant = GUI.SliderVal(150, y, sidewidth - 150, 24, shadows.polygonOffsetConstant, 0.0, 2.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Offset Slope");
						shadows.polygonOffsetSlope = GUI.SliderVal(150, y, sidewidth - 150, 24, shadows.polygonOffsetSlope, 0.0, 4.0);
						y += 30;

						if (lighting.shadowType == TmrwModule.NativeViewRP$ShadowType.DPCF || lighting.shadowType == TmrwModule.NativeViewRP$ShadowType.PCSS) {
							if (this.drawCollapsableLabel("nativelitsshfx", "Penumbra", x + 10, y, w)) {
								y += 40;

								var ssh = nativeEngine.getSoftShadows(true);
								if (Button.call(GUI, x + 25, y, 140, 24, "Reset Soft")) {
									nativeEngine.setSoftShadows(nativeEngine.getSoftShadowsDef());
									ssh = nativeEngine.getSoftShadows(false);
								}
								y += 30;

								Label.call(GUI, x + 25, y, 100, 24, "Scale");
								ssh.penumbraScale = GUI.SliderVal(150, y, sidewidth - 150, 24, ssh.penumbraScale, 0.0, 100.0);
								y += 30;

								Label.call(GUI, x + 25, y, 100, 24, "Ratio Scale");
								ssh.penumbraRatioScale = GUI.SliderVal(150, y, sidewidth - 150, 24, ssh.penumbraRatioScale, 1.0, 100.0);
								y += 30;
							}
							else
								y += 40;
						}

						if (lighting.shadowType == TmrwModule.NativeViewRP$ShadowType.VSM) {
							if (this.drawCollapsableLabel("nativelitvshfx", "VSM Shadows", x + 10, y, w)) {
								y += 40;

								var vsh = nativeEngine.getVsmShadows(true);
								if (Button.call(GUI, x + 25, y, 140, 24, "Reset VSM")) {
									nativeEngine.setVsmShadows(nativeEngine.getVsmShadowsDef());
									vsh = nativeEngine.getVsmShadows(false);
								}
								y += 30;

								var sh = shadows;

								Label.call(GUI, x + 25, y, 100, 24, "Mipmapping");
								vsh.mipmapping = GUI.Toggle(150, y, sidewidth - 150, 24, null, vsh.mipmapping);
								y += 30;

								Label.call(GUI, x + 25, y, 100, 24, "High Precision");
								vsh.highPrecision = GUI.Toggle(150, y, sidewidth - 150, 24, null, vsh.highPrecision);
								y += 30;

								Label.call(GUI, x + 25, y, 100, 24, "ELVSM");
								sh.vsm.elvsm = GUI.Toggle(150, y, sidewidth - 150, 24, null, sh.vsm.elvsm);
								y += 30;

								Label.call(GUI, x + 25, y, 100, 24, "Blur Width");
								sh.vsm.blurWidth = GUI.SliderVal(150, y, sidewidth - 150, 24, sh.vsm.blurWidth, 0.0, 64.0);
								y += 30;

								Label.call(GUI, x + 25, y, 100, 24, "Anisotropy");
								vsh.anisotropy = GUI.SliderVal(150, y, sidewidth - 150, 24, vsh.anisotropy, 0, 3, 1);
								y += 30;

								Label.call(GUI, x + 25, y, 100, 24, "MSAA Samples");
								vsh.msaaSamples = GUI.SliderVal(150, y, sidewidth - 150, 24, vsh.msaaSamples, 1, 8, 1);
								y += 30;

								Label.call(GUI, x + 25, y, 100, 24, "Min Variance");
								vsh.minVarianceScale = GUI.SliderVal(150, y, sidewidth - 150, 24, vsh.minVarianceScale, 0.0, 10.0);
								y += 30;

								Label.call(GUI, x + 25, y, 100, 24, "Bleed Reduction");
								vsh.lightBleedReduction = GUI.SliderVal(150, y, sidewidth - 150, 24, vsh.lightBleedReduction, 0., 8, 1);
								y += 30;
							}
							else
								y += 40;
						}
					}
					else
						y += 40;
				}
				nativeEngine.setLighting();
				nativeEngine.setShadows();
				nativeEngine.setVsmShadows();
				nativeEngine.setSoftShadows();
			}
			else
				y += 40;

			if (this.drawCollapsableLabel("nativecgfx", "Color Grading", x + 10, y, w)) {
				y += 40;

				var cg = nativeEngine.getColorGrading(true);

				//if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
				//	nativeEngine.setColorGrading(nativeEngine.getColorGradingDef());
				//	cg = nativeEngine.getColorGrading(false);
				//}
				//y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				cg.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, cg.enabled);
				y += 30;

				if (cg.enabled) {
					if (Button.call(GUI, 20, y, sidewidth - 20, 30, "Reset"))
						cg = nativeEngine.resetColorGrading();
					y += 40;

					Label.call(GUI, x + 15, y, 100, 24, "Lum. scaling");
					cg.luminanceScaling = GUI.Toggle(140, y, sidewidth - 150, 24, null, cg.luminanceScaling);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Gamut Mapping");
					cg.gamutMapping = GUI.Toggle(140, y, sidewidth - 150, 24, null, cg.gamutMapping);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Quality");
					var qindex = cg.quality.value;
					cg.quality = q_type_values[GUI.ComboLine(140, y, sidewidth - 150, 24, qindex, q_types)];
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Tonemapping");
					var t_types = [
						"LINEAR",
						"UNCHARTED",
						"ACES_LEGACY",
						"ACES",
						"FILMIC",
						"UCHIMURA",
						"REINHARD",
						"GENERIC",
						"AGX",
						"DISP-RANGE"
					];
					var t_type_values = [
						TmrwModule.NativeColorGradingRP$ToneMapping.LINEAR,
						TmrwModule.NativeColorGradingRP$ToneMapping.UNCHARTED,
						TmrwModule.NativeColorGradingRP$ToneMapping.ACES_LEGACY,
						TmrwModule.NativeColorGradingRP$ToneMapping.ACES,
						TmrwModule.NativeColorGradingRP$ToneMapping.FILMIC,
						TmrwModule.NativeColorGradingRP$ToneMapping.UCHIMURA,
						TmrwModule.NativeColorGradingRP$ToneMapping.REINHARD,
						TmrwModule.NativeColorGradingRP$ToneMapping.GENERIC,
						TmrwModule.NativeColorGradingRP$ToneMapping.AGX,
						TmrwModule.NativeColorGradingRP$ToneMapping.DISPLAY_RANGE
					];
					var tindex = cg.toneMapping.value;
					cg.toneMapping = t_type_values[GUI.ComboLine(140, y, sidewidth - 150, 24, tindex, t_types)];
					y += 30;

					if (cg.toneMapping == TmrwModule.NativeColorGradingRP$ToneMapping.AGX) {
						var cgagx = nativeEngine.getAgxToneMapper(true);

						Label.call(GUI, x + 15, y, 100, 24, "AgX Type");
						var agx_types = [
							"NONE",
							"PUNCHY",
							"GOLDEN"
						];
						var agx_type_values = [
							TmrwModule.NativeAgxToneMapper$AgxLook.NONE,
							TmrwModule.NativeAgxToneMapper$AgxLook.PUNCHY,
							TmrwModule.NativeAgxToneMapper$AgxLook.GOLDEN,
						];
						var agxindex = cgagx.lookType.value;
						cgagx.lookType = agx_type_values[GUI.ComboLine(140, y, sidewidth - 150, 24, agxindex, agx_types)];
						y += 30;
					}

					if (cg.toneMapping == TmrwModule.NativeColorGradingRP$ToneMapping.GENERIC) {
						if (this.drawCollapsableLabel("nativecggfx", "Generic", x + 10, y, w)) {
							y += 40;

							var cgg = nativeEngine.getToneMapper(true);
							if (Button.call(GUI, x + 25, y, 140, 24, "Reset Tone-Mapper")) {
								nativeEngine.setToneMapper(nativeEngine.getToneMapperDef());
								cgg = nativeEngine.getToneMapper(false);
							}
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Contrast");
							cgg.contrast = GUI.SliderVal(150, y, sidewidth - 150, 24, cgg.contrast, 0.001, 3.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Shoulder");
							cgg.shoulder = GUI.SliderVal(150, y, sidewidth - 150, 24, cgg.shoulder, 0.0, 1.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Mid GrayIn");
							cgg.midGrayIn = GUI.SliderVal(150, y, sidewidth - 150, 24, cgg.midGrayIn, 0.0, 1.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Mid GrayOut");
							cgg.midGrayOut = GUI.SliderVal(150, y, sidewidth - 150, 24, cgg.midGrayOut, 0.0, 1.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "HDR Max");
							cgg.hdrMax = GUI.SliderVal(150, y, sidewidth - 150, 24, cgg.hdrMax, 1.0, 64.0);
							y += 30;
						}
						else
							y += 40;
					}


					Label.call(GUI, x + 15, y, 100, 24, "Exposure");
					cg.exposure = GUI.SliderVal(140, y, sidewidth - 150, 24, cg.exposure, -10.0, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Night Adapt.");
					cg.nightAdaptation = GUI.SliderVal(140, y, sidewidth - 150, 24, cg.nightAdaptation, 0.0, 1.0);
					y += 30;

					if (this.drawCollapsableLabel("nativecgwbfx", "White Balance", x + 20, y, w)) {
						y += 40;

						var temperature = cg.temperature * 100.0;
						var tint = cg.tint * 100.0;

						Label.call(GUI, x + 25, y, 100, 24, "Temperature");
						temperature = GUI.SliderVal(150, y, sidewidth - 150, 24, temperature, -100, 100, 1);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Tint");
						tint = GUI.SliderVal(150, y, sidewidth - 150, 24, tint, -100, 100, 1);
						y += 30;

						cg.temperature = temperature / 100.0;
						cg.tint = tint / 100.0;
					}
					else
						y += 40;

					if (this.drawCollapsableLabel("nativecgcmfx", "Channel Mixer", x + 20, y, w)) {
						y += 40;

						Label.call(GUI, x + 15, y, 100, 24, "Red Chan.");
						GUI.Color3(140, y, sidewidth - 150, 24, cg.outRed);
						y += 30;

						Label.call(GUI, x + 15, y, 100, 24, "Green Chan.");
						GUI.Color3(140, y, sidewidth - 150, 24, cg.outGreen);
						y += 30;

						Label.call(GUI, x + 15, y, 100, 24, "Blue Chan.");
						GUI.Color3(140, y, sidewidth - 150, 24, cg.outBlue);
						y += 30;
					}
					else
						y += 40;

					if (this.drawCollapsableLabel("nativecgtrfx", "Tonal Ranges", x + 20, y, w)) {
						y += 40;

						var w = cg.shadows[3];
						cg.shadows[3] = 1;
						Label.call(GUI, x + 15, y, 100, 24, "Shadows");
						GUI.Color3(140, y, sidewidth - 150, 24, cg.shadows);
						y += 30;

						Label.call(GUI, x + 15, y, 100, 24, "Shadow wght");
						cg.shadows[3] = GUI.Slider(140, y, sidewidth - 150, 24, w, -2.0, 2.0);
						y += 40;

						w = cg.midtones[3];
						cg.midtones[3] = 1;
						Label.call(GUI, x + 15, y, 100, 24, "Mid-Tones");
						GUI.Color3(140, y, sidewidth - 150, 24, cg.midtones);
						y += 30;

						Label.call(GUI, x + 15, y, 100, 24, "Mid-Tone wght");
						cg.midtones[3] = GUI.SliderVal(140, y, sidewidth - 150, 24, w, -2.0, 2.0);
						y += 40;

						w = cg.highlights[3];
						cg.highlights[3] = 1;
						Label.call(GUI, x + 15, y, 100, 24, "Highlights");
						GUI.Color3(140, y, sidewidth - 150, 24, cg.highlights);
						y += 30;

						Label.call(GUI, x + 15, y, 100, 24, "Highlight wght");
						cg.highlights[3] = GUI.SliderVal(140, y, sidewidth - 150, 24, w, -2.0, 2.0);
						y += 30;
					}
					else
						y += 40;

					if (this.drawCollapsableLabel("nativecgdlfx", "Decision List", x + 20, y, w)) {
						y += 40;

						Label.call(GUI, x + 25, y, 100, 24, "Slope-X");
						cg.slope[0] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.slope[0], 0.0, 2.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Slope-Y");
						cg.slope[1] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.slope[1], 0.0, 2.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Slope-Z");
						cg.slope[2] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.slope[2], 0.0, 2.0);
						y += 40;


						Label.call(GUI, x + 25, y, 100, 24, "Offset-X");
						cg.offset[0] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.offset[0], -0.5, 0.5);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Offset-Y");
						cg.offset[1] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.offset[1], -0.5, 0.5);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Offset-Z");
						cg.offset[2] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.offset[2], -0.5, 0.5);
						y += 40;


						Label.call(GUI, x + 25, y, 100, 24, "Power-X");
						cg.power[0] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.power[0], 0.0, 2.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Power-Y");
						cg.power[1] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.power[1], 0.0, 2.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Power-Z");
						cg.power[2] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.power[2], 0.0, 2.0);
						y += 30;
					}
					else
						y += 40;

					if (this.drawCollapsableLabel("nativecgadfx", "Adjustments", x + 20, y, w)) {
						y += 40;

						Label.call(GUI, x + 25, y, 100, 24, "Brightness");
						cg.brightness = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.brightness, 0.0, 4.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Contrast");
						cg.contrast = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.contrast, 0.0, 2.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Vibrance");
						cg.vibrance = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.vibrance, 0.0, 2.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Saturation");
						cg.saturation = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.saturation, 0.0, 2.0);
						y += 30;
					}
					else
						y += 40;

					if (this.drawCollapsableLabel("nativecgcvfx", "Curves", x + 20, y, w)) {
						y += 40;

						Label.call(GUI, x + 25, y, 100, 24, "Linked");
						cg.linkedCurves = GUI.Toggle(150, y, sidewidth - 150, 24, null, cg.linkedCurves);
						y += 30;

						if (!cg.linkedCurves) {
							Label.call(GUI, x + 25, y, 100, 24, "Gamma-R");
							cg.gamma[0] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.gamma[0], 0.0, 4.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Mid-R");
							cg.midPoint[0] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.midPoint[0], 0.0, 2.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Scale-R");
							cg.scale[0] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.scale[0], 0.0, 4.0);
							y += 40;


							Label.call(GUI, x + 25, y, 100, 24, "Gamma-G");
							cg.gamma[1] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.gamma[1], 0.0, 4.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Mid-G");
							cg.midPoint[1] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.midPoint[1], 0.0, 2.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Scale-G");
							cg.scale[1] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.scale[1], 0.0, 4.0);
							y += 40;


							Label.call(GUI, x + 25, y, 100, 24, "Gamma-B");
							cg.gamma[2] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.gamma[2], 0.0, 4.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Mid-B");
							cg.midPoint[2] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.midPoint[2], 0.0, 2.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Scale-B");
							cg.scale[2] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.scale[2], 0.0, 4.0);
							y += 40;
						}
						else {
							Label.call(GUI, x + 25, y, 100, 24, "Gamma-Curve");
							cg.gamma[0] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.gamma[0], 0.0, 4.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Mid-Curve");
							cg.midPoint[0] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.midPoint[0], 0.0, 2.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Scale-Curve");
							cg.scale[0] = GUI.SliderVal(150, y, sidewidth - 150, 24, cg.scale[0], 0.0, 4.0);
							y += 30;

							cg.gamma[1] = cg.gamma[0];
							cg.gamma[2] = cg.gamma[0];

							cg.midPoint[1] = cg.midPoint[0];
							cg.midPoint[2] = cg.midPoint[0];

							cg.scale[1] = cg.scale[0];
							cg.scale[2] = cg.scale[0];
						}
					}
					else
						y += 40;
				}

				nativeEngine.setColorGrading();
				nativeEngine.setToneMapper();
				nativeEngine.setAgxToneMapper();
			}
			else
				y += 40;

			if (this.drawCollapsableLabel("nativeglowfx", "Glow FX", x + 10, y, w)) {
				y += 40;

				var glow = nativeEngine.getGlow(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setGlow(nativeEngine.getGlowDef());
					glow = nativeEngine.getGlow(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				glow.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, glow.enabled);
				y += 30;

				if (glow.enabled) {
					Label.call(GUI, x + 15, y, 100, 24, "Additive");
					var bAdd = glow.blendMode == TmrwModule.NativeViewRP$BloomOptions$BlendMode.ADD;
					bAdd = GUI.Toggle(140, y, sidewidth - 150, 24, null, bAdd);
					glow.blendMode = bAdd ? TmrwModule.NativeViewRP$BloomOptions$BlendMode.ADD : TmrwModule.NativeViewRP$BloomOptions$BlendMode.INTERPOLATE;
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Dirt Type");
					var d_types = [
						"None",
						"Type 1",
						"Type 2",
						"Type 3",
						"Type 4",
						"Type 5",
						"Type 6",
						"Type 7",
						"Type 8",
						"Type 9",
						"Type 10",
						"Type 11",
						"Type 12",
						"Type 13",
						"Type 14",
						"Type 15",
						"Type 16"
					];
					var dindex = glow.dirtNum;
					glow.dirtNum = GUI.ComboLine(150, y, sidewidth - 150, 24, dindex, d_types);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Threshold");
					glow.threshold = GUI.SliderVal(140, y, sidewidth - 150, 24, glow.threshold, 0.0, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Persistence");
					glow.persistence = GUI.SliderVal(140, y, sidewidth - 150, 24, glow.persistence, 0.0, 4.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Strength");
					glow.strength = GUI.SliderVal(140, y, sidewidth - 150, 24, glow.strength, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Dirt-Strength");
					glow.dirtStrength = GUI.SliderVal(140, y, sidewidth - 150, 24, glow.dirtStrength, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Gain");
					glow.gain = GUI.SliderVal(140, y, sidewidth - 150, 24, glow.gain, 0.0, 20.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Anamorphism");
					glow.anamorphism = GUI.SliderVal(140, y, sidewidth - 150, 24, glow.anamorphism, 0.0, 4.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Levels");
					glow.levels = GUI.SliderVal(140, y, sidewidth - 150, 24, glow.levels, 1, 8, 1);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Ghost-Flares");
					glow.lensFlare = GUI.Toggle(140, y, sidewidth - 150, 24, null, glow.lensFlare);
					y += 30;

					if (glow.lensFlare) {
						if (this.drawCollapsableLabel("nativeglowffx", "Glow FX", x + 20, y, w)) {
							y += 40;

							Label.call(GUI, x + 25, y, 100, 24, "Star-Burst");
							glow.starBurst = GUI.Toggle(150, y, sidewidth - 150, 24, null, glow.starBurst);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Chr-Aberration");
							glow.chromaticAberration = GUI.SliderVal(150, y, sidewidth - 150, 24, glow.chromaticAberration, 0.0, 1.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Ghosts Count");
							glow.ghostCount = GUI.SliderVal(150, y, sidewidth - 150, 24, glow.ghostCount, 0, 16, 1);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Ghost-Spacing");
							glow.ghostSpacing = GUI.SliderVal(150, y, sidewidth - 150, 24, glow.ghostSpacing, 0.0, 1.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Ghost-Thr");
							glow.ghostThreshold = GUI.SliderVal(150, y, sidewidth - 150, 24, glow.ghostThreshold, 0.0, 32.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Halo Thickness");
							glow.haloThickness = GUI.SliderVal(150, y, sidewidth - 150, 24, glow.haloThickness, 0.0, 8.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Halo Radius");
							glow.haloRadius = GUI.SliderVal(150, y, sidewidth - 150, 24, glow.haloRadius, 0.0, 0.5);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Halo Threshold");
							glow.haloThreshold = GUI.SliderVal(150, y, sidewidth - 150, 24, glow.haloThreshold, 0.0, 32.0);
							y += 30;
						}
						else
							y += 40;
					}
				}

				nativeEngine.setGlow();
			}
			else
				y += 40;

			if (this.drawCollapsableLabel("nativedofbfx", "Bokeh DoF FX", x + 10, y, w)) {
				y += 40;

				var dofb = nativeEngine.getBokehDOF(true);

				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setBokehDOF(nativeEngine.getBokehDOFDef());
					dofb = nativeEngine.getBokehDOF(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				dofb.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, dofb.enabled);
				y += 30;

				if (dofb.enabled) {
					//Label.call(GUI, x + 15, y, 100, 24, "Native-Res");
					//dof.nativeResolution = GUI.Toggle(140, y, sidewidth - 150, 24, null, dof.nativeResolution);
					//y += 30;
					Label.call(GUI, x + 15, y, 100, 24, "Anti-Aliasing");
					dofb.aa = GUI.Toggle(140, y, sidewidth - 150, 24, null, dofb.aa);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "High Quality");
					dofb.quality = GUI.Toggle(140, y, sidewidth - 150, 24, null, dofb.quality);
					y += 30;

					if (dofb.aa) {
						Label.call(GUI, x + 15, y, 100, 24, "AA Quality");
						dofb.aaQuality = GUI.Toggle(140, y, sidewidth - 150, 24, null, dofb.aaQuality);
						y += 30;

						Label.call(GUI, x + 15, y, 100, 24, "AA Weight");
						dofb.aaWeight = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.aaWeight, 0.0, 1.0);
						y += 30;
					}

					Label.call(GUI, x + 15, y, 100, 24, "Focus-Dist");
					dofb.focusDistance = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.focusDistance, 0.0, 30.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Focal-Region");
					dofb.focusRegion = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.focusRegion, 0.0, 30.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Blur Scale");
					dofb.cocScale = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.cocScale, 0.1, 100.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Near-Region");
					dofb.nearTransitionRegion = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.nearTransitionRegion, 0.0, 100.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Far-Region");
					dofb.farTransitionRegion = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.farTransitionRegion, 0.0, 100.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Occlusion");
					dofb.occlusion = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.occlusion, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Max Bokeh Size");
					dofb.maxBokehSize = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.maxBokehSize, 0.0, 40.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Brightness");
					dofb.brightness = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.brightness, 0.0, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Color Thr,");
					dofb.colorThreshold = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.colorThreshold, 0.0, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Size Thr.");
					dofb.sizeThreshold = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.sizeThreshold, 0.0, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Bokeh Type");
					var bb_types = [
						"Pentagon",
						"Circle",
						"Spherical",
						"Square",
						"Star",
						"Dirty",
						"Pent-Dirty"
					];
					var bbindex = dofb.bokehType;
					dofb.bokehType = GUI.ComboLine(150, y, sidewidth - 150, 24, bbindex, bb_types);
					y += 30;
				}

				nativeEngine.setBokehDOF();
			}
			else
				y += 40;


			if (this.drawCollapsableLabel("nativedoffx", "DoF FX", x + 10, y, w)) {
				y += 40;

				var dof = nativeEngine.getDOF(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setDOF(nativeEngine.getDOFDef());
					dof = nativeEngine.getDOF(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				dof.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, dof.enabled);
				y += 30;

				if (dof.enabled) {
					Label.call(GUI, x + 15, y, 100, 24, "Native-Res");
					dof.nativeResolution = GUI.Toggle(140, y, sidewidth - 150, 24, null, dof.nativeResolution);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Median Filter");
					var dofMedian = dof.filter == TmrwModule.NativeViewRP$DepthOfFieldOptions$Filter.MEDIAN;
					dofMedian = GUI.Toggle(140, y, sidewidth - 150, 24, null, dofMedian);
					dof.filter = dofMedian ? TmrwModule.NativeViewRP$DepthOfFieldOptions$Filter.MEDIAN : TmrwModule.NativeViewRP$DepthOfFieldOptions$Filter.NONE;
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Focus-Dist");
					dof.focusDistance = GUI.SliderVal(140, y, sidewidth - 150, 24, dof.focusDistance, 0.0, 30.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Blur Scale");
					dof.cocScale = GUI.SliderVal(140, y, sidewidth - 150, 24, dof.cocScale, 0.1, 100.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "COC Threshold");
					dof.cocThreshold = GUI.SliderVal(140, y, sidewidth - 150, 24, dof.cocThreshold, 0.1, 2.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "COC Range");
					dof.cocRange = GUI.SliderVal(140, y, sidewidth - 150, 24, dof.cocRange, 0.1, 4.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Rings Count");
					dof.fastGatherRingCount = GUI.SliderVal(140, y, sidewidth - 150, 24, dof.fastGatherRingCount, 1, 17, 1);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Foregr-COC");
					dof.maxForegroundCOC = GUI.SliderVal(140, y, sidewidth - 150, 24, dof.maxForegroundCOC, 1, 32, 1);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Backgr-COC");
					dof.maxBackgroundCOC = GUI.SliderVal(140, y, sidewidth - 150, 24, dof.maxBackgroundCOC, 1, 32, 1);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Max Mips");
					dof.maxMipLevels = GUI.SliderVal(140, y, sidewidth - 150, 24, dof.maxMipLevels, 3, 8, 1);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Max Aperture");
					dof.maxApertureDiameter = GUI.SliderVal(140, y, sidewidth - 150, 24, dof.maxApertureDiameter, 0.0, 4.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Bokeh Type");
					var b_types = [
						"Circle",
						"Blades"
					];
					var bindex = dof.bokehType;
					dof.bokehType = GUI.ComboLine(150, y, sidewidth - 150, 24, bindex, b_types);
					y += 30;

					if (dof.bokehType) {
						Label.call(GUI, x + 25, y, 100, 24, "Blades");
						dof.bladesCount = GUI.SliderVal(150, y, sidewidth - 150, 24, dof.bladesCount, 3.0, 10.0);
						y += 30;
					}
				}

				nativeEngine.setDOF();
			}
			else
				y += 40;

			if (this.drawCollapsableLabel("nativedofbfx", "Bokeh DoF FX", x + 10, y, w)) {
				y += 40;

				var dofb = nativeEngine.getBokehDOF(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setBokehDOF(nativeEngine.getBokehDOFDef());
					dofb = nativeEngine.getBokehDOF(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				dofb.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, dofb.enabled);
				y += 30;

				if (dofb.enabled) {
					//Label.call(GUI, x + 15, y, 100, 24, "Native-Res");
					//dof.nativeResolution = GUI.Toggle(140, y, sidewidth - 150, 24, null, dof.nativeResolution);
					//y += 30;
					Label.call(GUI, x + 15, y, 100, 24, "Anti-Aliasing");
					dofb.aa = GUI.Toggle(140, y, sidewidth - 150, 24, null, dofb.aa);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "High Quality");
					dofb.quality = GUI.Toggle(140, y, sidewidth - 150, 24, null, dofb.quality);
					y += 30;

					if (dofb.aa) {
						Label.call(GUI, x + 15, y, 100, 24, "AA Quality");
						dofb.aaQuality = GUI.Toggle(140, y, sidewidth - 150, 24, null, dofb.aaQuality);
						y += 30;

						Label.call(GUI, x + 15, y, 100, 24, "AA Weight");
						dofb.aaWeight = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.aaWeight, 0.0, 1.0);
						y += 30;
					}

					Label.call(GUI, x + 15, y, 100, 24, "Focus-Dist");
					dofb.focusDistance = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.focusDistance, 0.0, 30.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Focal-Region");
					dofb.focusRegion = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.focusRegion, 0.0, 30.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Blur Scale");
					dofb.cocScale = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.cocScale, 0.1, 100.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Near-Region");
					dofb.nearTransitionRegion = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.nearTransitionRegion, 0.0, 100.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Far-Region");
					dofb.farTransitionRegion = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.farTransitionRegion, 0.0, 100.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Occlusion");
					dofb.occlusion = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.occlusion, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Max Bokeh Size");
					dofb.maxBokehSize = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.maxBokehSize, 0.0, 40.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Brightness");
					dofb.brightness = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.brightness, 0.0, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Color Thr,");
					dofb.colorThreshold = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.colorThreshold, 0.0, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Size Thr.");
					dofb.sizeThreshold = GUI.SliderVal(140, y, sidewidth - 150, 24, dofb.sizeThreshold, 0.0, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Bokeh Type");
					var bb_types = [
						"Pentagon",
						"Circle",
						"Spherical",
						"Square",
						"Star",
						"Dirty",
						"Pent-Dirty"
					];
					var bbindex = dofb.bokehType;
					dofb.bokehType = GUI.ComboLine(150, y, sidewidth - 150, 24, bbindex, bb_types);
					y += 30;
				}

				nativeEngine.setBokehDOF();
			}
			else
				y += 40;

			if (this.drawCollapsableLabel("nativeoutlfx", "Outline Options", x + 10, y, w)) {
				y += 40;

				var fx = nativeEngine.getSceneFX(true, 0);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setSceneFX(nativeEngine.getSceneFXDef());
					fx = nativeEngine.getSceneFX(false, 0);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				fx.outlineEnable = GUI.Toggle(140, y, sidewidth - 150, 24, null, fx.outlineEnable);
				y += 30;
				if (fx.outlineEnable) {
					Label.call(GUI, x + 15, y, 100, 24, "Rate");
					fx.outlineRate = GUI.SliderVal(140, y, sidewidth - 150, 24, fx.outlineRate, 0.1, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Width");
					fx.outlineWidth = GUI.SliderVal(140, y, sidewidth - 150, 24, fx.outlineWidth, 0.1, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Intensity");
					fx.outlineIntensity = GUI.SliderVal(140, y, sidewidth - 150, 24, fx.outlineIntensity, 0.1, 2.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Color");
					GUI.Color3(140, y, sidewidth - 150, 24, fx.outlineColor);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Color Occluded");
					GUI.Color3(140, y, sidewidth - 150, 24, fx.outlineColorOccluded);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Bloom");
					fx.outlineBloom = GUI.Toggle(140, y, sidewidth - 150, 24, null, fx.outlineBloom);
					y += 30;

					if (fx.outlineBloom) {
						Label.call(GUI, x + 15, y, 100, 24, "Additive");
						fx.outlineBloomAdditive = GUI.Toggle(140, y, sidewidth - 150, 24, null, fx.outlineBloomAdditive);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Threshold");
						fx.outlineBloomThreshold = GUI.SliderVal(150, y, sidewidth - 160, 24, fx.outlineBloomThreshold, 0.01, 5.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Gain");
						fx.outlineBloomGain = GUI.SliderVal(150, y, sidewidth - 160, 24, fx.outlineBloomGain, 0.0, 20.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Persistence");
						fx.outlineBloomPersistence = GUI.SliderVal(150, y, sidewidth - 160, 24, fx.outlineBloomPersistence, 0.0, 4.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Strength");
						fx.outlineBloomStrength = GUI.SliderVal(150, y, sidewidth - 160, 24, fx.outlineBloomStrength, 0.0, 4.0);
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Levels");
						fx.outlineBloomLevels = GUI.SliderVal(150, y, sidewidth - 160, 24, fx.outlineBloomLevels, 1, 8);
						y += 30;
					}
				}

				nativeEngine.setSceneFX(null, 0);
			}
			else
				y += 40;

			if (this.drawCollapsableLabel("nativedrfx", "Dyn-Resolution", x + 10, y, w)) {
				y += 40;

				var dr = nativeEngine.getDynRes(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setDynRes(nativeEngine.getDynResDef());
					dr = nativeEngine.getDynRes(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				dr.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, dr.enabled);
				y += 30;

				if (dr.enabled) {
					Label.call(GUI, x + 15, y, 100, 24, "Homogeneous");
					dr.homogeneousScaling = GUI.Toggle(140, y, sidewidth - 150, 24, null, dr.homogeneousScaling);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Adaptive");
					dr.adaptiveScale = GUI.Toggle(140, y, sidewidth - 150, 24, null, dr.adaptiveScale);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Quality");
					var qindex = dr.quality.value;
					dr.quality = q_type_values[GUI.ComboLine(140, y, sidewidth - 150, 24, qindex, q_types)];
					y += 30;

					Label.call(GUI, x + 25, y, 100, 24, "Min Scale");
					GUI.Vector(150, y, sidewidth - 150, 24, dr.minScale, 0.01);
					y += 30;

					Label.call(GUI, x + 25, y, 100, 24, "Max Scale");
					GUI.Vector(150, y, sidewidth - 150, 24, dr.maxScale, 0.01);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Sharpness");
					dr.sharpness = GUI.SliderVal(140, y, sidewidth - 150, 24, dr.sharpness, 0.0, 2.0);
					y += 30;
				}

				nativeEngine.setDynRes();
			}
			else
				y += 40;

			if (this.drawCollapsableLabel("nativessrfx", "SSR FX", x + 10, y, w)) {
				y += 40;

				var ssr = nativeEngine.getSSR(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setSSR(nativeEngine.getSSRDef());
					ssr = nativeEngine.getSSR(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				ssr.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, ssr.enabled);
				y += 30;

				if (ssr.enabled) {
					Label.call(GUI, x + 15, y, 100, 24, "Half-Res");
					ssr.halfRes = GUI.Toggle(140, y, sidewidth - 150, 24, null, ssr.halfRes);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Thickness");
					ssr.thickness = GUI.SliderVal(140, y, sidewidth - 150, 24, ssr.thickness, 0.0, 5.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Bias");
					ssr.bias = GUI.SliderVal(140, y, sidewidth - 150, 24, ssr.bias, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Max Distance");
					ssr.maxDistance = GUI.SliderVal(140, y, sidewidth - 150, 24, ssr.maxDistance, 0.001, 20.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Stride");
					ssr.stride = GUI.SliderVal(140, y, sidewidth - 150, 24, ssr.stride, 0.001, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Reflectance");
					ssr.reflectance = GUI.SliderVal(140, y, sidewidth - 150, 24, ssr.reflectance, 0.0, 2.0);
					y += 30;
				}

				nativeEngine.setSSR();
			}
			else
				y += 40;
			if (this.drawCollapsableLabel("nativessaofx", "SSDO FX", x + 10, y, w)) {
				y += 40;

				var ssao = nativeEngine.getSSAO(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setSSAO(nativeEngine.getSSAODef());
					ssao = nativeEngine.getSSAO(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				ssao.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, ssao.enabled);
				y += 30;

				if (ssao.enabled) {
					Label.call(GUI, x + 15, y, 100, 24, "Half-Res");
					var halfRes = ssao.resolution < 1.0;
					halfRes = GUI.Toggle(140, y, sidewidth - 150, 24, null, halfRes);
					ssao.resolution = halfRes ? 0.5 : 1.0;
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Bent Normals");
					ssao.bentNormals = GUI.Toggle(140, y, sidewidth - 150, 24, null, ssao.bentNormals);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Radius");
					ssao.radius = GUI.SliderVal(140, y, sidewidth - 150, 24, ssao.radius, 0.0, 4.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Radius");
					ssao.radius = GUI.SliderVal(140, y, sidewidth - 150, 24, ssao.radius, 0.0, 4.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Bias");
					ssao.bias = GUI.SliderVal(140, y, sidewidth - 150, 24, ssao.bias, 0.0, 0.2);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Power");
					ssao.power = GUI.SliderVal(140, y, sidewidth - 150, 24, ssao.power, 0.0, 4.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Intensity");
					ssao.intensity = GUI.SliderVal(140, y, sidewidth - 150, 24, ssao.intensity, 0.0, 4.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Min Hor. Angle");
					ssao.minHorizonAngleRad = GUI.SliderVal(140, y, sidewidth - 150, 24, ssao.minHorizonAngleRad, 0.0, 1.57);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Bilateral Thr.");
					ssao.bilateralThreshold = GUI.SliderVal(140, y, sidewidth - 150, 24, ssao.bilateralThreshold, 0.0, 0.2);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Quality");
					var qindex = ssao.quality.value;
					ssao.quality = q_type_values[GUI.ComboLine(140, y, sidewidth - 150, 24, qindex, q_types)];
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Upsampling");
					var uindex = ssao.upsampling.value;
					ssao.upsampling = q_type_values[GUI.ComboLine(140, y, sidewidth - 150, 24, uindex, q_types)];
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Low Pass");
					var findex = ssao.lowPassFilter.value;
					ssao.lowPassFilter = q_type_values[GUI.ComboLine(140, y, sidewidth - 150, 24, findex, q_types)];
					y += 30;

					if (this.drawCollapsableLabel("nativessaossctfx", "SSAO SSCT FX", x + 20, y, w)) {
						y += 40;

						var ssct = nativeEngine.getSSAO_SSCT(true);
						if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
							nativeEngine.setSSAO_SSCT(nativeEngine.getSSAO_SSCT_Def());
							ssct = nativeEngine.getSSAO_SSCT(false);
						}
						y += 30;

						Label.call(GUI, x + 25, y, 100, 24, "Enabled");
						ssct.enabled = GUI.Toggle(150, y, sidewidth - 150, 24, null, ssct.enabled);
						y += 30;

						if (ssct.enabled) {
							Label.call(GUI, x + 25, y, 100, 24, "Cone Angle");
							ssct.lightConeRad = GUI.Slider(150, y, sidewidth - 150, 24, ssct.lightConeRad, 0.0, 1.57);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Shadow Distance");
							ssct.shadowDistance = GUI.Slider(150, y, sidewidth - 150, 24, ssct.shadowDistance, 0.0, 10.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Contact Distance");
							ssct.contactDistanceMax = GUI.Slider(150, y, sidewidth - 150, 24, ssct.contactDistanceMax, 0.0, 100.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Intensity");
							ssct.intensity = GUI.Slider(150, y, sidewidth - 150, 24, ssct.intensity, 0.0, 10.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Depth Bias");
							ssct.depthBias = GUI.Slider(150, y, sidewidth - 150, 24, ssct.depthBias, 0.0, 1.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Depth Slope Bias");
							ssct.depthSlopeBias = GUI.Slider(150, y, sidewidth - 150, 24, ssct.depthSlopeBias, 0.0, 1.0);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Samples Count");
							ssct.sampleCount = GUI.Slider(150, y, sidewidth - 150, 24, ssct.sampleCount, 1, 32, 1);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Rays Count");
							ssct.rayCount = GUI.Slider(150, y, sidewidth - 150, 24, ssct.rayCount, 1, 5, 1);
							y += 30;

							Label.call(GUI, x + 25, y, 100, 24, "Direction");
							GUI.Vector(150, y, sidewidth - 150, 24, ssct.lightDirection, 0.01);
							y += 30;
						}
					}
					else
						y += 40;
				}

				nativeEngine.setSSAO();
				nativeEngine.setSSAO_SSCT();
			}
			else
				y += 40;
			if (this.drawCollapsableLabel("nativefogfx", "Fog FX", x + 10, y, w)) {
				y += 40;

				var fog = nativeEngine.getFog(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setFog(nativeEngine.getFogDef());
					fog = nativeEngine.getFog(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				fog.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, fog.enabled);
				y += 30;

				if (fog.enabled) {
					Label.call(GUI, x + 15, y, 100, 24, "IBL Color");
					fog.fogColorFromIbl = GUI.Toggle(140, y, sidewidth - 150, 24, null, fog.fogColorFromIbl);
					y += 30;

					if (fog.fogColorFromIbl) {
						Label.call(GUI, x + 15, y, 100, 24, "IBL Intensity");
						fog.fogColorFromIblIntensity = GUI.SliderVal(140, y, sidewidth - 150, 24, fog.fogColorFromIblIntensity, 0.01, 10.0);
						y += 30;
					}

					Label.call(GUI, x + 15, y, 100, 24, "Fog Color");
					GUI.Color3(140, y, sidewidth - 150, 24, fog.color);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Distance");
					fog.distance = GUI.SliderVal(140, y, sidewidth - 150, 24, fog.distance, 0.0, 400.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Density");
					fog.density = GUI.SliderVal(140, y, sidewidth - 150, 24, fog.density, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Height");
					fog.height = GUI.SliderVal(140, y, sidewidth - 150, 24, fog.height, 0.0, 200.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Height Falloff");
					fog.heightFalloff = GUI.SliderVal(140, y, sidewidth - 150, 24, fog.heightFalloff, 0.0, 10.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Scatt. Start");
					fog.inScatteringStart = GUI.SliderVal(140, y, sidewidth - 150, 24, fog.inScatteringStart, 0.0, 100.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Scatt. Size");
					fog.inScatteringSize = GUI.SliderVal(140, y, sidewidth - 150, 24, fog.inScatteringSize, 0.1, 100.0);
					y += 30;
				}
				nativeEngine.setFog();
			}
			else
				y += 40;
			if (this.drawCollapsableLabel("nativevigfx", "Vignette FX", x + 10, y, w)) {
				y += 40;

				var vig = nativeEngine.getVignette(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setVignette(nativeEngine.getVignetteDef());
					vig = nativeEngine.getVignette(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				vig.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, vig.enabled);
				y += 30;

				if (vig.enabled) {
					Label.call(GUI, x + 15, y, 100, 24, "Mid point");
					vig.midPoint = GUI.SliderVal(140, y, sidewidth - 150, 24, vig.midPoint, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Roundness");
					vig.roundness = GUI.SliderVal(140, y, sidewidth - 150, 24, vig.roundness, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Feather");
					vig.feather = GUI.SliderVal(140, y, sidewidth - 150, 24, vig.feather, 0.0, 1.0);
					y += 30;

					GUI.Color3(140, y, sidewidth - 150, 24, vig.color);
					y += 30;
				}

				nativeEngine.setVignette();
			}
			else
				y += 40;
			if (this.drawCollapsableLabel("nativeditfx", "Dithering FX", x + 10, y, w)) {
				y += 40;

				var dith = nativeEngine.getDithering(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setDithering(nativeEngine.getDitheringDef());
					dith = nativeEngine.getDithering(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Dithering");
				var dith_types = [
					"NONE",
					"TEMPORAL"
				];
				var dith_type_values = [
					TmrwModule.NativeViewRP$Dithering.NONE,
					TmrwModule.NativeViewRP$Dithering.TEMPORAL
				];
				var dtindex = dith.mode.value;
				dith.mode = dith_type_values[GUI.ComboLine(140, y, sidewidth - 150, 24, dtindex, dith_types)];
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Intensity");
				dith.intensity = GUI.SliderVal(140, y, sidewidth - 150, 24, dith.intensity, 0.0, 1.0);
				y += 30;

				nativeEngine.setDithering();
			}
			else
				y += 40;
			if (this.drawCollapsableLabel("nativemsaafx", "MSAA FX", x + 10, y, w)) {
				y += 40;

				var msaa = nativeEngine.getMSAA(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setMSAA(nativeEngine.getMSAADef());
					msaa = nativeEngine.getMSAA(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				msaa.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, msaa.enabled);
				y += 30;

				if (msaa.enabled) {
					Label.call(GUI, x + 15, y, 100, 24, "Samples");
					msaa.sampleCount = GUI.Number(140, y, sidewidth - 150, 24, msaa.sampleCount);
					y += 30;
				}

				nativeEngine.setMSAA();
			}
			else
				y += 40;
			if (this.drawCollapsableLabel("nativetaafx", "TAA FX", x + 10, y, w)) {
				y += 40;

				var taa = nativeEngine.getTAA(true);
				if (Button.call(GUI, x + 15, y, 140, 24, "Reset")) {
					nativeEngine.setTAA(nativeEngine.getTAADef());
					taa = nativeEngine.getTAA(false);
				}
				y += 30;

				Label.call(GUI, x + 15, y, 100, 24, "Enabled");
				taa.enabled = GUI.Toggle(140, y, sidewidth - 150, 24, null, taa.enabled);
				y += 30;

				if (taa.enabled) {
					Label.call(GUI, x + 15, y, 100, 24, "Filter History");
					taa.filterHistory = GUI.Toggle(140, y, sidewidth - 150, 24, null, taa.filterHistory);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Filter Input");
					taa.filterInput = GUI.Toggle(140, y, sidewidth - 150, 24, null, taa.filterInput);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "History Reprojection");
					taa.historyReprojection = GUI.Toggle(140, y, sidewidth - 150, 24, null, taa.historyReprojection);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Prevent Flickering");
					taa.preventFlickering = GUI.Toggle(140, y, sidewidth - 150, 24, null, taa.preventFlickering);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Use YCoCg");
					taa.useYCoCg = GUI.Toggle(140, y, sidewidth - 150, 24, null, taa.useYCoCg);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Filter Width");
					taa.filterWidth = GUI.SliderVal(140, y, sidewidth - 150, 24, taa.filterWidth, 0.0, 2.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Feedback");
					taa.feedback = GUI.SliderVal(140, y, sidewidth - 150, 24, taa.feedback, 0.0, 1.0);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Var. Gamma");
					taa.varianceGamma = GUI.SliderVal(140, y, sidewidth - 150, 24, taa.varianceGamma, 0.5, 1.4);
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Box Type");
					var box_types = [
						"AABB",
						"VARIANCE",
						"AABB_VARIANCE"
					];
					var box_type_values = [
						TmrwModule.NativeViewRP$TAABoxType.BOX_TYPE_AABB,
						TmrwModule.NativeViewRP$TAABoxType.BOX_TYPE_VARIANCE,
						TmrwModule.NativeViewRP$TAABoxType.BOX_TYPE_AABB_VARIANCE,
					];
					var btindex = taa.boxType.value;
					taa.boxType = box_type_values[GUI.ComboLine(140, y, sidewidth - 150, 24, btindex, box_types)];
					y += 30;

					Label.call(GUI, x + 15, y, 100, 24, "Box Clipping");
					var box_clipping = [
						"ACCURATE",
						"CLAMP",
						"NONE"
					];
					var box_clipping_values = [
						TmrwModule.NativeViewRP$TAABoxClipping.BOX_CLIPPING_ACCURATE,
						TmrwModule.NativeViewRP$TAABoxClipping.BOX_CLIPPING_CLAMP,
						TmrwModule.NativeViewRP$TAABoxClipping.BOX_CLIPPING_NONE,
					];
					var bcindex = taa.boxClipping.value;
					taa.boxClipping = box_clipping_values[GUI.ComboLine(140, y, sidewidth - 150, 24, bcindex, box_clipping)];
					y += 30;
				}
				y += 40;

				nativeEngine.setTAA();
			}
			else
				y += 40;
		}

		y += 30;

		//this will do the update of the native checking for changed values
		this.view.updateNativeSettings();
	}

	renderGlobalSettings(ctx,x,y,w,h) {
		var that = this;
		var space = this.view.space;
		var sidewidth = this.sidewidth;
		var pipeline = this.view.pbrpipeline;
		var view = this.view;
		var depth_dist = 10;
		if (!this._modifyed_settings)
			this._modifyed_settings = {};

		if ( Button.call(GUI, 10,y,sidewidth - 20,30, "Download JSON") )
		{
			ROOM.downloadFile("settings.json", JSON.stringify( this.getSettingsJSON( ROOM_SETTINGS,{},"")) );
		}
		y += 40;

		this.settings_filter = GUI.SearchBox(10, y, sidewidth - 20, 30, this.settings_filter || "");
		y += 40;

		showSection(null,ROOM_SETTINGS,-1,"")

		function showSection(name,section,depth,fullname)
		{
			if ( that.collapsed_labels[ fullname ] == null )
				that.collapsed_labels[ fullname ] = true;

			if (name && depth === 0 )
			{
				if (!that.drawCollapsableLabel( fullname, name, x,y,w ) && !that.settings_filter.length )
				{
					y += 30;
					return;
				}
				y += 30;
			}
			else if (name)
			{
				Label.call(GUI, 10 + depth*depth_dist, y, 100 - depth*depth_dist, 24, name );
				y += 30;
			}

			for (var i in section)
			{
				var value = section[i];
				if ( value && value.constructor === Object )
					showSection(i,value,depth+1,depth >= 0 ? fullname + "." + i : i);
				else {
					var var_fullname = depth >= 0 ? fullname + "." + i : i;
					if (!that.settings_filter || var_fullname.indexOf( that.settings_filter ) !== -1)
						showVar(section,i,value,depth+1,var_fullname);
				}
			}
			y += 10;
		}

		function showVar(object,name,value,depth,fullname)
		{
			var sw = 150;
			var sh = 20;
			var sx = sidewidth - sw - 15;
			that._modifyed_settings[fullname] = GUI.Toggle( 5,y+2,sh-4,sh-4, null, that._modifyed_settings[fullname] || false );
			Label.call(GUI, 25+depth*depth_dist, y, 100-depth*5,sh, name );
			var type = typeof(value);
			if (value != null && value.length != null)
				type = "array";
			switch (type)
			{
			case "boolean": object[name] = GUI.Toggle( sx,y,sw,sh, null, value ); break;
			case "number": object[name] = GUI.Number( sx,y,sw,sh, value ); break;
			case "array": GUI.Vector( sx,y,sw,sh, value ); break;
			}
			y += sh+4;
		}
	}

	getSettingsJSON() {
		var that = this;
		return inner( ROOM_SETTINGS,{},"");

		function inner(root,out_root,namespace)
		{
			for (var i in root)
			{
				var value = root[i];
				var fullname = namespace ? namespace + "." + i : i;
				if ( value && value.constructor === Object )
				{
					var o = {};
					inner(value,o,fullname);
					if ( Object.keys(o).length ) //avoid empty ones
						out_root[i] = o;
				}
				else
				{
					if ( that._modifyed_settings[fullname] )
						out_root[i] = value;
				}
			}

			return out_root;
		}
	}

	showGraph( v, graph )
	{
		if ( v && !GraphEditorDialog.active)
		{
			this.attachDialog( GraphEditorDialog );
			GraphEditorDialog.showGraph(graph);
		}
			
		if( !v && GraphEditorDialog.active)
			this.detachDialog( GraphEditorDialog );
	}

	showGraphNodeInspector(x,y,w,h){
		GraphEditorDialog.showGraphNodeInspector(x,y,w,h);
	}

	showTimeline( v, animation_comp )
	{
		if ( v && !TimelineDialog.active)
		{
			this.attachDialog( TimelineDialog );
			TimelineDialog.showAnimation( animation_comp.animation, animation_comp );
		}
			
		if( !v && TimelineDialog.active)
			this.detachDialog( TimelineDialog );
	}

	renderStats(ctx) {
		if (!this.view.pbrpipeline)
			return;
		var x = gl.canvas.width - 120;
		var y = gl.canvas.height - 40;
		ctx.fillColor = [ 0,0,0,0.5 ];
		ctx.fillRect(x,y,120,40);

		var num = this.view.pbrpipeline.rendered_render_calls;
		this.view.pbrpipeline.rendered_render_calls = 0;
		ctx.fillColor = [ 0.5,1,0.5,1 ];
		if (num > 100)
			ctx.fillColor = [ 1,1,0.5,1 ];
		if (num > 500)
			ctx.fillColor = [ 1,0.5,0.5,1 ];
		ctx.font = "20px Arial";
		ctx.fillText( "RCs: " + num, x + 20, y + 26 );
	}

	renderToolsPanel(x,y,w,h) {
		var ctx = gl;
		ctx.fillColor = [ 0.2,0.2,0.2,1 ];
		ctx.fillRect(x,y,w,h);

		y += 20;
		x += 20;

		/*
		if ( Button.call(GUI, x, y, 140, 24, "Erase Surfaces" ) )
			this.removeNodesByComponent( ROOM.Components.Surface );

		if ( Button.call(GUI, x + 200, y, 180, 24, "Autoplace Seats" ) )
			this.autoplaceSeats( this._autoplace_num_seats,0,true  );
		this._autoplace_num_seats = GUI.Number( x + 380, y, 140, 24, this._autoplace_num_seats || 6, 1 );
		*/

		if ( Button.call(GUI, x + 540, y, 180, 24, "Autoplace Surfaces" ) )
			this.autoplaceTablets();

		y += 30;

		/*
		if ( Button.call(GUI, x, y, 140, 24, "Create Surface" ) )
			this.placeSurfaceOnSelectedNode();
		*/

	}

	drawCollapsableLabel( name, text, x, y, w ) {
		var collapsed = this.collapsed_labels[ name ];
		var st = GUI.HoverArea( x,y, collapsed ? w : 30,30 );
		GUI.DrawIcon( x + 10, y + 10, 0.6, collapsed ? [ 5,3 ] : [ 13,3 ] );
		Label.call(GUI, x + 30, y, w - 80, 30, text );
		if ( st === GLUI.CLICKED )
			this.collapsed_labels[ name ] = !collapsed;
		return !this.collapsed_labels[ name ];
	}

	drawPropertyLabel( target, name, type, y, w ) {
		this.AnimBullet(10,y,30, target, name, type );
		Label.call(GUI,40,y,100,30, name );

		if (type === "number" )
			target[name] = GUI.Number(10 + 140,y,w - 160,30, target[name] );
		//else if( type == "color" )
		//	entity[name]
	}

	drawCubemap(texture,x,y,w,h, exposure, offset ) {
		var shader = gl.shaders["show_cubemap"];
		if (!shader)
			shader = gl.shaders["show_cubemap"] = new GL.Shader( GL.Shader.SCREEN_VERTEX_SHADER, cubemap_fragment_shader_code );
		var mesh = GL.Mesh.getScreenQuad();
		var v = new Float32Array( gl.viewport_data );
		gl.viewport(x,v[3] - y - h,w,h);
		shader.uniforms({ u_texture: texture.bind(0), u_color:[ 1,1,1,1 ], u_rotation: offset * DEG2RAD, u_gamma: 1/2.2, u_exposition:exposure }).draw( mesh );
		gl.viewport( v[0], v[1], v[2], v[3] );
	}

	drawAxisGizmo(x,y,size) {
		//TODO
	}

	onUpdate(dt) {
		if ( this.render_call_mode )
		{
			xyz.call_controller.onUpdate(dt);
			return;
		}


		this.space.update(dt);
		this.anim_time = this.space.anim_time;

		//move camera with keyboard
		var camera = this.camera; //this.view.camera
		camera.applyController( dt, null, gl.keys.SHIFT ? this.cam_speed * 2 : this.cam_speed, gl.mouse.isButtonPressed(GL.LEFT_MOUSE_BUTTON) || gl.mouse.isButtonPressed(GL.RIGHT_MOUSE_BUTTON) );
	}

	processCameraController(e) {
		var camera = this.camera; //this.view.camera
		var right = camera.getLocalVector([ 1,0,0 ]);

		if ( this.orbital_camera )
		{
			if (gl.mouse.buttons & 1) //left
			{
				camera.orbit( e.deltax * -0.002 * (this.reverse_camera_control ? -1 : 1), [ 0,1,0 ] );
				camera.orbit( e.deltay * -0.002, right );
			}
			else
			{
				camera.moveLocal( [ e.deltax * 0.01,e.deltay * -0.01, 0 ] );
			}
		}
		else
		{
			//cenital
			if (camera.type === Camera.ORTHOGRAPHIC )
			{
				if ( vec3.equals(camera.up,[ 0,0,-1 ]) )
					camera.move( [ e.deltax * -0.002 * camera.frustum_size, 0, e.deltay * -0.002 * camera.frustum_size ] );
				else
				{
					if (gl.mouse.buttons & 1)
						camera.moveLocal( [ e.deltax * -0.002 * camera.frustum_size, e.deltay * 0.002 * camera.frustum_size, 0 ] );
					else
					{
						camera.orbit( e.deltax * -0.002 * (this.reverse_camera_control ? -1 : 1), [ 0,1,0 ] );
						camera.orbit( e.deltay * -0.002, right );
					}
				}

			}
			else
			{
				camera.rotate( e.deltax * -0.002 * (this.reverse_camera_control ? -1 : 1), [ 0,1,0 ] );
				camera.rotate( e.deltay * -0.002, right );
			}
		}
	}

	onMouse(e) {
		if ( this.low_panel && this.low_panel.processMouse( e ) )
			return true;

		//send to UI
		var r = GUI.onMouse(e);
		if (r)
			return true;

		var nativeEngine = window.nativeEngine || null;
		var now = getTime();

		//send to dialogs
		var pos = [ e.mousex, e.mousey ];
		var clicked_dialog = null;
		for (var i = this.active_dialogs.length - 1; i >= 0; i--)
		{
			var dialog = this.active_dialogs[i];
			var b = dialog.area;
			var is_inside_dialog = b && (pos[0] > b[0] && pos[0] < (b[0] + b[2]) && pos[1] > b[1] && pos[1] < (b[1] + b[3]));
			if (is_inside_dialog && e.type === "mousedown" )
				clicked_dialog = dialog;
			if ( dialog.onMouse && ( is_inside_dialog || dialog.capture_mouse ) )
			{
				if (dialog.onMouse(e) === true )
				{
					if (e.type === "mousedown" )
						this.focus_dialog = clicked_dialog || null;
					return true;
				}
			}
			if ( is_inside_dialog )
				break;
		}

		if (e.type === "mousedown" )
			this.focus_dialog = clicked_dialog || null;

		var ray = e.ray = this.view.renderer._camera.getRay( e.canvasx, e.canvasy, null, this.view.last_ray );

		if (this.tool && this.tool.onMouse)
		{
			if ( this.tool.onMouse(e, ray, this) )
				return true;
		}

		if ( this.render_call_mode )
		{
			xyz.call_controller.onMouse(e);
			return;
		}

		if (e.type === "wheel" )
		{
			var direction = (e.wheel > 0) ? 1 : -1;

			//test attached
			if( this.active_dialogs.length )
			{
				var mousepos = [ gl.mouse.mousex,gl.mouse.mousey ];
				for(var i=this.active_dialogs.length-1; i>=0;--i)
				{
					var dialog = this.active_dialogs[i];
					if ( dialog.onWheel && GUI.isInsideRect( mousepos, dialog.area[0],dialog.area[1],dialog.area[2],dialog.area[3],10) )
						dialog.onWheel(e);
				}
			}

			if (GUI.isPositionBlocked(e.mousex, e.mousey))
			{
				if ( e.mousex < this.sidewidth )
					this.side_scroll_target += direction * 20;
				return true;
			}
			else
			{
				if (this.camera.type === Camera.ORTHOGRAPHIC )
					this.camera.frustum_size *= 1 - direction * 0.04;
				else if ( this.orbital_camera )
					this.camera.moveLocal([ 0,0,direction * -0.1 ]);
				else
					this.cam_speed *= 1 + direction * 0.05;
			}
		}

		if (e.type === "mousedown" && !GUI.isPositionBlocked(e.mousex, e.mousey) )
		{
			this.saveUndo();
			this.dragging_camera = true;
		}
		else if (e.type === "mouseup")
		{
			this.dragging_camera = false;
		}

		//fast click in scene
		if (e.click_time < 150 && !GUI.isPositionBlocked(e.mousex, e.mousey) )
		{
			var ray = e.ray = this.view.renderer._camera.getRay( e.canvasx, e.canvasy, null, this.view.last_ray );
			var r = this.view.testRay( ray );
			if (this.tool)
			{
				var time_between_clicks = now - this.last_click_time;
				if( time_between_clicks < 150 && this.tool.onNodeDoubleClicked)
					this.tool.onNodeDoubleClicked(r,this,e);
				else 
					if(this.tool.onNodeClicked)
						this.tool.onNodeClicked(r,this,e);
			}
		}

		if (e.type === "mouseup")
			this.last_click_time = now;

		// Andrey native objects inspector (used in materials gallery)
		var navEntity = null;
		if (xyz.native_mode) {
			navEntity = nativeEngine._room.getInspectedEntity();
			var nodeOnRay = this.space.testRayWithInteractiveNodes(ray, this.view.renderer._camera);
			if (nodeOnRay || navEntity) {
				var entityOnRay = navEntity ? navEntity : nodeOnRay.getParentEntity();
				if (entityOnRay && entityOnRay.native) {
					var isInteractive = entityOnRay.getMouseInteractive();
					if (isInteractive) {
						var x = e.x;
						var y = e.y;
						var action = 0;
						var button = 0;
						var modifiers = 0;

						if (e.eventType === "mousedown")
							action = 1;
						else
						if (e.eventType === "mouseup")
							action = 2;
						else
						if (e.eventType === "mousemove")
							action = 0;
						else
						if (e.eventType === "mousescroll")
							action = 3;

						//console.log("action" + e.eventType);
						//console.log("button" + e.button);

						if (this.dragging_camera || action == 2)
							button = 1;

						if (e.shiftKey)
							modifiers |= 1;
						if (e.ctrlKey)
							modifiers |= 2;
						if (e.altKey)
							modifiers |= 4;

						entityOnRay.mouseInteraction(x, y, action, button, modifiers);

						navEntity = nativeEngine._room.getInspectedEntity();
          }
        }
      }
    }


		if ( this.dragging_camera && !gl.mouse.buttons)
			this.dragging_camera = false;

		if (e.type === "mousemove" && this.dragging_camera && !navEntity)
			this.processCameraController(e);

		//if (e.type == "mouseup" && e.buttons && this.inspector)
		//	this.inspector.refresh();
	}

	saveSessionRemote( full_filename, update_preview ) {
		var xyz = this.launcher;

		//if(xyz.native_mode)
		//	update_preview = false;

		if ( !xyz.backend || !xyz.backend.username )
		{
			this.showNotification( "You are not logged", [ 7,0 ] );
			return;
		}

		if (!full_filename)
		{
			full_filename = this.space.url;
			if (!full_filename)
			{
				alert("no filename");
				return;
			}

			//in case it was a folder
			if (full_filename.indexOf(".json") === -1 )
				full_filename += "/room.json";
		}
		else
		{
			this.space.url = full_filename;
			var t = this.space.url.split("/");
			this.space.filename = t.pop();
			this.space.folder = t.join("/");
		}

		var tokens = full_filename.split("/");
		var roomname = ROOM.removeExtension( tokens.pop() );

		if (update_preview ) //update urls
			this.saveSnapshot(); //this saves the previews and updates the url in space

		//extract all now, after screenshots
		var data = this.getSceneJSON();

		//Save json file
		//TODO: read file and compare with space._original_data to ensure no overwrite
		this.saveFileInBackend( full_filename, JSON.stringify( data, null, " " ) );

		//save locally
		this.saveTempLocalCopy( full_filename, JSON.stringify( data, null, " " ) );
	}

	autosave()
	{
		if ( !xyz.backend || !xyz.backend.username )
			return;

		var full_filename = this.space.url;
		if (!full_filename)
			return;

		console.debug("auto-saving...");
		var data = this.getSceneJSON();

		//in case it was a folder
		if (full_filename.indexOf(".json") === -1 )
			full_filename += "/room_autosave.json";
		else if(full_filename.indexOf("_autosave") == -1)
		{
			full_filename = ROOM.removeExtension( full_filename );
			full_filename = full_filename + "_autosave.json";
		}

		this.showNotification("Autosave",[ 0,0 ]);
		this.saveFileInBackend( full_filename, JSON.stringify( data, null, " " ) );
	}

	autosave() {
		if (!xyz.backend || !xyz.backend.username)
			return;

		var full_filename = this.space.url;
		if (!full_filename)
			return;

		console.debug("auto-saving...");
		var data = this.getSceneJSON();

		//in case it was a folder
		if (full_filename.indexOf(".json") === -1)
			full_filename += "/room_autosave.json";
		else if (full_filename.indexOf("_autosave") == -1) {
			full_filename = ROOM.removeExtension(full_filename);
			full_filename = full_filename + "_autosave.json";
		}

		this.showNotification("Autosave", [0, 0]);
		this.saveFileInBackend(full_filename, JSON.stringify(data, null, " "));
	}

	getSceneJSON() {
		var data = this.space.serialize();
		this.addSerializeMetaInfo(data);
		data.global_settings = this.getSettingsJSON();
		return data;
	}

	showLoadSpaceDialog() {
		var that = this;
		this.selectFile(function (file) {
			var path = file ? file.localpath : null;
			if (path)
				that.loadScene(path);
		}, ["json"], null, xyz.space.folder);
	}

	//not tested
	loadScene(url) {
		this.space.load(url, inner);

		function inner() {
			xyz.call_controller.setComponentController(null);
			//xyz.space.addParticipant( xyz.space.local_participant );
		}
	}

	addSerializeMetaInfo(data) {
		if (!this.view.pbrpipeline)
			return;

		data = data || {};

		//compute biggest texture
		var max_texture_size = 0;
		var num_textures = 0;
		for (var i in StaticMaterialsTable)
		{
			var mat = StaticMaterialsTable[i];
			for (var j in mat.textures)
			{
				var tex_info = mat.textures[j];
				if (!tex_info)
					continue;
				var tex_name = tex_info.constructor === String ? tex_info : tex_info.texture;
				if (!tex_name || tex_name[0] === ":" || tex_name.substr(0,10) !== "data_rooms")
					continue;
				var tex = gl.textures[tex_name];
				if (!tex)
					continue;
				if ( tex.width > max_texture_size)
					max_texture_size = tex.width;
				if ( tex.height > max_texture_size)
					max_texture_size = tex.height;
				num_textures++;
			}
		}

		data.metrics = {
			objects: this.view.pbrpipeline ? this.view.pbrpipeline.render_calls.length : -1,
			max_texture_size: max_texture_size,
			num_textures: num_textures
		};

		return data;
	}

	saveSnapshot() {
		var that = this;

		var space = this.space;
		var full_filename = space.url;
		var settings = RoomEditor.screenshot_settings;

		var tokens = full_filename.split("/");
		var roomname = ROOM.removeExtension( tokens.pop() );
		var encoding = this.preview_encoding; //"image/jpeg";//"image/png"
		var extension = encoding.split("/").pop();//extract extension
		var preview_filename = "preview_" + roomname + "." + extension;
		var featured_preview_filename = "preview_featured_" + roomname + "." + extension;
		space.preview_url = preview_filename;
		space.preview_featured_url = featured_preview_filename;

		//set camera to default position
		this.camera.lookAt( space.camera_info.position, space.camera_info.target, [ 0,1,0 ] );

		//store main screenshot
		this.takeSnapshot( settings.normal[0], settings.normal[1], inner, encoding );
		function inner( data )
		{
			that.saveFileInBackend( space.folder + "/" + preview_filename, data );
		}

		//store secondary screenshot
		this.takeSnapshot( settings.banner[0], settings.banner[1], inner2, encoding );
		function inner2( data )
		{
			that.saveFileInBackend( space.folder + "/" + featured_preview_filename, data );
		}
	}

	takeSnapshot( width, height, callback, encoding ) {
		this.render_gizmos = false;
		this.render_bounding = false;
		Seat.gizmos_visible = false;

		encoding = encoding || this.preview_encoding;
		var extension = encoding.split("/").pop();

		if ( !callback )
			callback = function(data) {
				ROOM.downloadFile("screenshot." + extension, data);
			}

		this.view.takeSnapshot( width, height, callback, this.camera, encoding );

		Seat.gizmos_visible = true;
		this.render_gizmos = true;
	}

	takeCubemapSnapshot( size, callback ) {
		//position on first seat
		//look forward of seat
	}

	saveFileInBackend( filename, data, callback ) {
		var xyz = this.launcher;
		var that = this;
		xyz.backend.uploadFile( filename, data, function(v) {
			var basename = filename.split("/").pop();
			if (!v)
				that.showNotification("Error saving file",[ 0,0 ]);
			else
				that.showNotification( basename + " saved");
		});
	}

	onKeyDown(e) {
		if (this.tool && this.tool.onKeyDown)
		{
			if ( this.tool.onKeyDown(e, this) )
				return true;
		}

		if ( GUI.onKey(e) )
			return;

		//send to dialogs
		var pos = [ e.mousex, e.mousey ];
		if ( this.focus_dialog && this.focus_dialog.onKeyDown )
		{
			if (this.focus_dialog.onKeyDown(e) === true )
				return true;
		}

		switch (e.code)
		{
		case "ArrowUp":
			//console.debug("up!");
			break;
		case "Space":
			//this.space.play_mode = this.space.play_mode === ROOM.PLAY_MODES.PLAY ? ROOM.PLAY_MODES.PAUSED : ROOM.PLAY_MODES.PLAY;
			break;
		case "KeyZ":
			if (e.ctrlKey && !xyz.native_mode )
				this.doUndo();
			break;
		case "KeyY":
			if (e.ctrlKey && !xyz.native_mode )
				this.doRedo();
			break;
		case "KeyD":
			if (e.ctrlKey)
			{
				this.setSelection(null);
				e.preventDefault();
			}
			break;
		case "KeyS":
			if (e.ctrlKey)
			{
				//this.saveSessionLocal("current");
				this.saveSessionRemote();
				e.preventDefault();
			}
			break;
		case "KeyL":
			if (e.ctrlKey)
			{
				this.loadSession("current");
				e.preventDefault();
			}
			break;
		case "KeyN":
			this.gizmo_to_individual_nodes = !this.gizmo_to_individual_nodes;
			break;
		case "KeyR":
			this.view.renderer.loadShaders("pbr-shaders.glsl");
			break;
		case "KeyF":
			if ( this.selected_item )
				this.focusOnEntity( this.selected_item, e.shiftKey ? this.selected_node : null );
			break;
		case "F2":
			//this.showInterface( (!this.ui || !this.ui.visible) ? true : false );
			e.preventDefault();
			break;
		case "F10":
			this.hide_ui = !this.hide_ui;
			e.preventDefault();
			break;
		case "F4":
			//this.view.pbrpipeline.captureEnvironment( this.view.scene, [0,1,0] );
			this.launcher.reloadPlugins();
			e.preventDefault();
			break;
		case "F3": //use environment
			this.view.setEnvironment( this.launcher.root_path + "/environments/panorama.hdre");
			e.preventDefault();
			break;
		case "PageUp":
			this.camera.fov *= 1.1;
			break;
		case "PageDown":
			this.camera.fov *= 0.9;
			break;
		case "Delete":
			this.deleteSelection();
			break;
		case "Escape":
			this.exit();
			break;
		default:
			return false;
		}

		return true;
	}

	getCustomView( camera, tex_handle ) {
		if(!window.nativeEngine)
			return null;

		if(!this.custom_view)
		{
			var custom_view = this.custom_view = nativeEngine._engine.addCustomViewRP("editor_view");
			var custom_cam = nativeEngine._engine.createCamera("custom_camera");
			//var scene = nativeEngine._engine.createSceneRP("myScene");
			var scene = nativeEngine._engine.getMainSceneRP();
			custom_view.setCamera(custom_cam);
			custom_view.setScene(scene);
			custom_view.setTexture(tex_handle);
			custom_view.setColorGrading(true);
			custom_view.setViewport(0, 0, 512, 512, 1);
			//custom_view.setSkyBox("url to sky box");
		}

		//copy camera
		custom_cam.position = camera.position;
		custom_cam.target = camera.target;
		custom_cam.up = camera.up;
		custom_cam.fov = camera.fov;

		return this.custom_view;
	}

	newSession() {
		this.space.clear();
		this.selected_item = null;
		this.selected_node = null;
		this.selection = null;
		this.show_confirm = null;
		this.mode = 0;
	}

	deleteItem(item) {
		if (!item)
			return;

		if (item.constructor === Entity || item.constructor === EntityReference)
		{
			this.saveUndo();
			if (item._parent)
				item._parent.removeEntity( item );
			this.selected_item = null;
			this.show_confirm = null;
			this.mode = 0;
		}
	}

	renameCollection(old_name, new_name) {
		this.saveUndo();
		var entities = this.space.getAllEntities();
		for (var i = 0; i < entities.length; ++i)
		{
			var entity = entities[i];
			if (entity.collection === old_name)
				entity.collection = new_name;
		}
	}

	assignCollectionToSelection(collection) {
		if (!this.selection)
			return;

		collection = collection || null;

		var that = this;
		this.saveUndo();
		this.selection.items.forEach(function(item) {
			if (item.constructor === Entity)
				item.collection = collection;
		});
	}

	deleteSelection() {
		if (!this.selection)
			return;

		var that = this;
		this.saveUndo();
		this.selection.items.forEach(function(item) {
			if (item.constructor === Entity)
			{
				that.space.removeEntity( item );
				that.selected_item = null;
				that.show_confirm = null;
				that.mode = 0;
			}
		});
		this.selection = null;
		this.selected_item = this.selected_node = null;
	}

	deleteComponent(comp) {
		if (!comp)
			return;
		this.saveUndo();
		comp.entity.removeComponent(comp);
	}

	showEntityInInspector( entity )
	{
		this.mode = RoomEditor.PROPERTIES_MODE;
		this.setSelection( entity );
	}

	//target could be entity or space (or component)
	setSelection( target, add_to_current ) {

		this.previous_selection = this.selected_item;

		if(target.is_root)
			target = target._space;

		//for components
		if(target && target.constructor.is_component)
		{
			var comp = target;
			target = target.entity;
			this.visible_comp_index = target._components.indexOf(comp);
		}

		if (this.selected_item && this.selected_item._is_selected)
			this.selected_item._is_selected = false;

		if (!target) {
			if (!add_to_current || !this.selection)
				this.selection = null;
			//if (this.inspector)
			//	this.inspectObject( null );
			return;
		}

		var xyz = this.launcher;
		var native = xyz.native_mode;
		var space = xyz.space;

		var entity = null;
		//console.log(target, this.translateNativeObject(target));
		//target = this.translateNativeObject(target);

		var type = null;
		if (target.constructor === Entity || target.constructor === EntityReference) {
			type = "entity";
			entity = target;
			this.selected_item = entity;
			this.selected_item._is_selected = true;
			this.selected_node = target.node;
			target.updateNativeEntity();
			if (target.constructor === EntityReference)
				this.selected_node = null;
		}
		else if (target.constructor === SceneNode )
		{
			type = "node";
			entity = this.selected_item = target.getParentEntity();
			if(this.selected_item)
				this.selected_item._is_selected = true;
			this.selected_node = target;
		}
		else if ( checkNativeType(target,"NativeNode") )
		{
			type = "node";
			entity = this.selected_item = this.translateNativeObject(target);
			if(this.selected_item)
				this.selected_item._is_selected = true;
			this.selected_node = target;
		}
		else if (target.constructor === ROOM.Scene) {
			type = "scene";
			this.selected_item = target;
			this.selected_node = null;
			this.selected_item._is_selected = true;
		}
		if (!this.selection)
			this.selection = {
				items: new Set()
			};

		if (!add_to_current)
			this.selection.items = new Set();

		this.selection.type = type;
		this.selection.target = target;
		if (add_to_current && this.selection.items.has(this.selected_item)) {
			this.selection.items.delete(this.selected_item);
			if (this.selection.size === 0)
				this.setSelection(null);
		}
		else
			this.selection.items.add(this.selected_item);

		//if (this.inspector)
		//	this.inspectObject( this.selected_item );

		function checkNativeType(obj,type)
		{
			if(!obj || !obj.$$ || !obj.$$.ptrType)
				return false;
			return obj.$$.ptrType.name == type + "*";
		}
	}

	isSelected(item) {
		if (!this.selection)
			return false;
		return this.selection.items.has(item);
	}

	afterTransformSelection() {
		//update others
		this.autoSyncSelection();
	}

	autoSyncSelection() {
		if (	this.auto_sync && this.selected_item && this.selected_item.sync )
			this.selected_item.sync(true);
	}

	focusOnEntity(entity, node) {
		var camera = this.view.renderer._camera;

		if (node)
		{
			var bb = node.updateBoundingBox();
			if (!bb)
				camera.target = node.getGlobalPosition();
			else
				camera.target = BBox.getCenter(bb);
		}
		else if ( entity )
			camera.target = entity.node.getGlobalPosition();
	}

	showNotification(text, icon) {
		this.notifications.push({ content: text, icon: icon, end_time: getTime() + 2000 });
	}

	onGizmoTransform()
	{
		if(this.selected_item && this.selected_item.syncNative)
			this.selected_item.syncNative();
	}

	onDuplicateTargets(nodes) {
		var entity = this.view.getSceneNodeEntity(nodes[0]);
		if (!entity)
			return true;

		var new_entity = new Entity();
		this.space.addEntity(new_entity);
		var data = entity.serialize();
		data.uid = generateUID("ENT"); //overwrite uid

		//generate unique name
		var name = data.name;
		var subname = name;

		if (0) //add underscore
		{
			var p = name.lastIndexOf("_");
			var subname = "";
			if (p !== -1 )
				subname = name.substr(0,p) + "_";
			else
				subname = name + "_";
		}
		else //remove trailing number
		{
			var str = subname.replace(/[0-9]/g, "");
			//var num = subname.replace(/[A-Za-z ]/g, '') || 0;
			subname = str;
		}

		var index = 1;
		while ( this.space.getEntity( subname + index ) )
			index++;
		data.name = subname + index;

		new_entity.configure( data );
		this.setSelection(new_entity);
		this.gizmo.setTargets([ new_entity.node ]);
		return true;
	}

	getUndoInfo() {
		var o = {
			type: "scene",
			camera: this.view.hard_camera.serialize(),
			scene: this.space.serialize()
		};

		if (this.selected_item && this.selected_item.constructor === Entity)
		{
			o.selection = {
				entity: this.space.root.children.indexOf( this.selected_item ),
				items: Array.from(this.selection.items).map(a=>a.uid)
			};
			if ( this.selected_node )
				o.selection.node = this.selected_node.name;
		}

		return o;
	}

	applyUndoInfo(step) {
		this.selection = null;

		if (step.type === "scene")
		{
			this.space.clear();
			this.space.configure( step.scene );
			this.view.hard_camera.configure( step.camera );
		}
		else if (step.type === "material")
		{
			var material = new Material();
			material.configure( step.material );
			StaticMaterialsTable[ material.name ] = material;
		}

		if (step.selection)
		{
			this.selected_item = this.space.root.children[ step.selection.entity ];
			if (step.selection.items)
			{
				this.selection = {
				};
				var items = new Set();
				for (var i = 0; i < step.selection.items.length; ++i)
				{
					var ent = this.space.getEntityById( step.selection.items[i] );
					if (ent)
					{
						if (!this.selection.entity)
							this.selection.entity = ent;
						items.add(ent);
					}
				}
				this.selection.items = items;
			}
			if (step.selection.node)
			{
				this.selected_node = this.space.getEntity(step.selection.node);
				this.gizmo.setTargets( this.selected_node ? [ this.selected_node ] : null );
			}
			else
			{
				var selected_nodes = Array.from( this.selection.items ).map( function(v) { 
					if(v.node)
						return v.node;
				}).filter((a)=>a); //remove nulls
				this.gizmo.setTargets( selected_nodes );
			}
		}
	}

	saveUndo(step) {
		this.redo.length = 0;
		step = step || this.getUndoInfo();
		var str = JSON.stringify(step);
		this.undo.push( str );
		if (this.undo.length > 100)
			this.undo.shift();
	}

	doUndo() {
		//save redo
		var step = this.getUndoInfo();
		var str = JSON.stringify(step);
		this.redo.push( str );

		//get undo step
		var step = this.undo.pop();
		if (!step)
			return;
		var data = JSON.parse(step);
		this.applyUndoInfo(data);
	}

	doRedo() {
		if (!this.redo.length)
			return;

		//save undo
		var step = this.getUndoInfo();
		var str = JSON.stringify(step);
		this.undo.push( str );

		//get undo step
		var step = this.redo.pop();
		if (!step)
			return;
		var data = JSON.parse(step);
		this.applyUndoInfo(data);
	}

	onBeforeUnload() {
		this.saveSessionLocal("autosave");
	}

	loadAutosave() {
		this.loadSession("autosave");
	}

	saveTempLocalCopy(name, data) {
		TempRecentSpacesDialog.save(name,data);
		//this.showNotification( "Saved locally" );
	}

	saveSessionLocal( name ) {
		name = name || "";
		var data = this.space.serialize();
		var data_str = JSON.stringify( data, null, " " );
		console.debug("saving...");
		localStorage.setItem("tmrw_editor_session_" + name,data_str);
	}

	loadSession( name ) {
		name = name || "";
		var data_str = localStorage.getItem("tmrw_editor_session_" + name);
		if (!data_str)
			return;
		var data = JSON.parse( data_str );
		console.debug("recovering save");
		this.space.configure( data );
	}

	sortEntities() {
		this.space.root.children.sort( (a,b)=>a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
	}

	showHistorySavedSpaces() {
		this.attachDialog( TempRecentSpacesDialog );
	}

	autoAssignDistance(factor) {
		factor = factor || 1;
		for (var i = 0; i < this.space.children.length; ++i)
		{
			var entity = this.space.children[i];
			var comp = entity.getComponent( Surface );
			if (!comp)
				continue;
			entity.node.scaling[2] = entity.node.scaling[1] * factor;
			entity.node._must_update_matrix = true;
		}
	}

	placeSurfaceOnSelectedNode() {
		var node = this.selected_node;
		if (!node)
			return;
		var ent = new Entity();
		ent.name = node.name;
		ent.type = ROOM_TYPES.SURFACE;
		xyz.space.addEntity( ent );
		var comp = new Surface(this.launcher.call_controller);
		ent.addComponent(comp);
		comp.proxy_node = node.name;
		comp.alignWithProxy();
		ent.node.scaling[2] = ent.node.scaling[1] * 1;

		this.setSelection( ent );
	}

	removeNodesByComponent(comp) {
		var list = xyz.space.getAllEntities();
		for(var i in list)
		{
			var e = list[i];
			if(e.hasComponent(comp))
				e.parent.removeChild(e);
		}
	}

	autoplaceSeats( number, max, seek_tablet ) {
		var reference_seat = this.space.getEntity( "seat1" );
		var reference_surface = this.space.getEntity( "tablet1" );
		if (!reference_seat || !reference_surface)
		{
			console.error("no seat or surface found");
			return;
		}

		var seat_offset = reference_seat.position;
		var seat_rotation = reference_seat.rotation;
		var seat_angle = reference_seat.angle;

		var entities = this.root.getAllEntities();

		//clear old ones
		for (var i = 0; i < entities.length; ++i)
		{
			var ent = entities[i];
			if (ent.type !== ROOM_TYPES.SEAT && ent.type !== ROOM_TYPES.SURFACE )
				continue;
			this.space.removeEntity(ent);
			--i;
		}

		var delta_angle = 360 / number;

		for (var i = 0; i < number; ++i)
		{
			if ( max && i >= max)
				break;

			var angle = (delta_angle * Math.floor(i/2));
			if (i % 2 === 1)
				angle += 180;

			var entity = new Entity();
			entity.type = ROOM_TYPES.SEAT;

			var pos = vec3.create();
			vec3RotateY( pos, seat_offset, angle * DEG2RAD );
			entity.position = pos;
			//entity.angle = angle;
			entity.angle = seat_angle - angle + 90;
			this.space.addEntity( entity );
			entity.name = "seat" + (i+1);
			var seat_entity = entity;

			var comp = new Seat();
			entity.addComponent( comp );

			if (reference_surface)
			{
				var entity = new Entity();
				entity.type = ROOM_TYPES.SURFACE;

				var pos = vec3.create();
				vec3RotateY( pos, reference_surface.position, angle * DEG2RAD );
				entity.position = pos;
				entity.node.rotation = reference_surface.node.rotation;
				entity.node.scaling = reference_surface.node.scaling;
				entity.node.rotate( -angle * DEG2RAD, [ 0,1,0 ], true );
				this.space.addEntity( entity );
				entity.name = "tablet" + (i+1);

				var comp = new Surface(this.launcher.call_controller);
				entity.addComponent( comp );
				comp.proxy_node = "tablet_" + (i<10?"0":"") + (i+1);
				comp.seat_name = seat_entity.name;

				if ( seek_tablet )
				{
					var node = this.getNodeBehindEntity(entity);
					if (node && node.name)
						comp.proxy_node = node.name;
				}
			}
		}
	}

	fillTabletNodes(rootNode, nodes) {
		var name = rootNode.name;

		if (name.indexOf("tablet_") === 0 || name.indexOf("tv_") === 0) {
			if (rootNode.mesh)
				nodes.push(rootNode);
		}

		var children = rootNode.getChildren();
		const l = children.length;
		for (var i=0; i<l; i++) {
			const node = children[i];
			this.fillTabletNodes(node, nodes);
		}
	}

	extractMaterialsChanged(){
		//for every entity
			extractMaterialsNative
				//get material changed
	}

	autoplaceTablets() {
		var space = xyz.space;

		//remove tablets
		space.getEntitiesByComponent( Surface )
			.filter( a=>a.name && (a.name.indexOf("tablet_") === 0 || a.name.indexOf("tv_")) )
			.forEach( a=>a.parent.removeChild(a) );

		//search nodes

		//NATIVE
		if (window.nativeEngine)
		{
			var nodes = [];

			var rootNode = nativeEngine._room.getRootNode();
			this.fillTabletNodes(rootNode, nodes); //gather nodes with TV or tablet_ in the name

			for (var i = 0; i < nodes.length; ++i) {
				var node = nodes[i];
				if (!node.mesh)
					continue; //not a true tablet node, more like a grouping one
				var tokens = node.name.split("_");
				var index = Number(tokens[1]);

				//create entity and add to scene
				//assign name as node
				//add surface component to entity (assign node name as proxy)
				//setup transform of entity as node
				//set entity scale as [ 0.02,0.02 * (node._scale[2] / node._scale[1])),0.02 * 2 ]			
				
				var ent = new Entity();
				// Andrey: native entity requires index, which is not assigned in Entity constructor for some reason
				ent.index = space.last_index++;

				ent.type = ROOM_TYPES.SURFACE;
				ent.name = tokens[0] + index;
				ent.createNativeEntity(ent.index);

				var scale = node._scale;
				var zaspect = scale[2] / scale[1];
				//ent.node.transform = node.transform;
				ent.node._position = node.position;
				ent.node._scale = node.scaling;
				ent.node._rotation = node.rotation;

				ent.node.scale([0.02, 0.02 * zaspect, 0.02 * 2]);
				ent.node.rotate(Math.PI * -0.5, [1, 0, 0]);
				space.addEntity(ent);

				var comp = new ROOM.Components.Surface();
				comp.proxy_node = node.name;
				if (tokens[0] === "tablet")
					comp.seat = "seat" + index;
				comp.app_name = "LockScreen";

				ent.addComponent(comp);

				// Update native surface entity
				ent._native.type = TmrwModule.NativeEntity$Type.SURFACE;
				nativeEngine._room.addEntity(ent._native, ent.name);
				var native_comp = ent._native.createComponent("SurfaceComponent");
				var surf_comp = native_comp.castSurfaceComponent();
				surf_comp.proxy_node = node.name;
				if (tokens[0] === "tablet")
					surf_comp.seat = "seat" + index;

				// Native doesn't use surface nodes! Only the proxy one
				var native_node = ent._native.node;
				native_node.position = ent.node._position;
				native_node.rotation = ent.node._rotation;
				native_node.scaling = ent.node._scale;
			}
		}
		else
		{
			// WEB ENGINE
			var nodes = xyz.space.scene.root.findNodesByFilter(a=>a.name && (a.name.indexOf("tablet_") === 0 || a.name.indexOf("tv_") === 0) );
			for (var i = 0; i < nodes.length; ++i)
			{
				var node = nodes[i];
				if (!node.mesh && !node.primitives.length)
					continue; //not a true tablet node, more like a grouping one
				var tokens = node.name.split("_");
				var index = Number( tokens[1] );

				//add surface
				var ent = new Entity();
				ent.type = ROOM_TYPES.SURFACE;
				ent.name = tokens[0] + index;
				//ent.node.fromMatrix( node.getGlobalMatrix() );
				var zaspect = node.scaling[2] / node.scaling[1];
				ent.node.transform = node.transform;
				ent.node.scale([ 0.02,0.02 * zaspect,0.02 * 2 ]);
				ent.node.rotate(Math.PI*-0.5,[ 1,0,0 ]);
				space.addEntity( ent );
				var comp = new Surface();
				comp.proxy_node = node.name;
				if (tokens[0] === "tablet" )
					comp.seat = "seat" + index;
				ent.addComponent( comp );

				//add seat
				//TODO
			}
		}
	}

	getNodeBehindEntity( entity ) {
		var origin = entity.node.localToGlobal([ 0,0,1 ]);
		var direction = entity.node.localToGlobal([ 0,0,0 ]);
		vec3.sub( direction, direction, origin );
		var ray = new GL.Ray(origin, direction);
		return this.view.testRay( ray );
	}

	addTool( name, tool ) {
		this.tools[ name ] = tool;
	}

	selectTool( tool, param ) {
		if (tool && tool.constructor === String )
		{
			tool = this.tools[ tool ];
			if (!tool)
			{
				console.warn("no tool found", arguments[0] );
				return this.tool;
			}
		}

		if (this.tool === tool)
			return tool;

		if (this.tool && this.tool.onDisable)
			this.tool.onDisable(this);

		this.tool = tool;

		if (!tool)
			return null;

		if (this.tool.onEnable)
			this.tool.onEnable(this, param);

		return this.tool;
	}

	attachDialog( dialog ) {
		//avoid repeating a dialog
		var index = this.active_dialogs.indexOf( dialog );
		if (index !== -1)
			return;

		dialog.active = true;
		if (dialog.onOpen)
			dialog.onOpen( this );
		this.active_dialogs.push( dialog );
	}

	detachDialog( dialog ) {
		var index = this.active_dialogs.indexOf( dialog );
		if (index === -1)
			return;

		dialog.active = false;
		if (dialog.onClose)
			dialog.onClose();
		this.active_dialogs.splice( index, 1 );
	}

	detachAllDialogs() {
		while ( this.active_dialogs.length )
			this.detachDialog( this.active_dialogs[0] );
	}

	hideAllDialogs() {
	}

	enableSelectNodeTool(callback) {
		var tool = this.selectTool("Select");
		tool.callback = callback;
	}

	selectEntity( callback )
	{
		SelectEntityPanel.callback = callback;
		this.attachDialog( SelectEntityPanel );
	}

	selectFile( callback, valid_extensions, save_filename, current_value ) {
		if ( !xyz.backend || !xyz.backend.username )
		{
			this.showNotification( "You are not logged", [ 7,0 ] );
			return;
		}

		if (valid_extensions && valid_extensions.constructor === String)
			valid_extensions = valid_extensions.split(",");

		if (current_value)
		{
			var folder = getFolder(current_value);
			xyz.backend.changeFolder(folder);
		}

		var dialog = RoomEditor.select_file_dialog;
		dialog.show( this, callback, valid_extensions, dialog.save_filename );
	}

	selectFolder( callback ) {
		if ( !xyz.backend || !xyz.backend.username )
		{
			this.showNotification( "You are not logged", [ 7,0 ] );
			return;
		}

		var dialog = inner.bind(xyz.backend);
		this.attachDialog( dialog );

		var that = this;
		var area = null;
		var folder = "";

		function inner( editor, pre_render )
		{
			var ctx = gl;

			var sidewidth = that.sidewidth;
			var w = 600;
			var h = gl.canvas.height - 40;
			var x = gl.canvas.width * 0.5 - w*0.5;
			var y = gl.canvas.height * 0.5 - h*0.5;
			if ( pre_render )
			{
				area = GUI.disableArea(x,y,w,h);
				return;
			}
			GUI.enableArea( area );

			if (GUI.Panel( x, y, w,h, "Folders", true ) === false )
			{
				editor.detachDialog( this );
				return;
			}

			xyz.backend.showFiles( x + 20, y + 60, w - 40, h - 140, inner_setFolder, null, true );

			folder = GUI.TextField( x + 20, y + h - 60, w - 170, 40, folder, null, true );
			if ( Button.call(GUI,  x + w - 140, y + h - 60, 120, 40, "Select" ) && callback)
				inner_callback(folder);
		}

		function inner_setFolder(folder_path)
		{
			folder = folder_path;
		}

		function inner_callback(folder_path)
		{
			if (callback)
				callback(folder_path);
			that.detachDialog(dialog);
		}
	}

	// ****************************************
	compileShaders() {
		//used to previsualize the environment
		gl.shaders["panoramic"] = new GL.Shader("\
			precision mediump float;\n\
			attribute vec3 a_vertex;\n\
			attribute vec2 a_coord;\n\
			varying vec2 v_coord;\n\
			uniform mat4 u_mvp;\n\
			void main() {\n\
				v_coord = a_coord;\n\
				gl_Position = u_mvp * vec4(a_vertex,1.0);\n\
			}\
			","\
			precision mediump float;\n\
			varying vec2 v_coord;\n\
			uniform vec4 u_color;\n\
			uniform float u_rotation;\n\
			uniform samplerCube u_texture;\n\
			void main() {\n\
			  vec2 coord = v_coord * 2.0 - vec2(1.0);\n\
			  float dist = length(coord);\n\
			  if(dist > 1.1)\n\
			  {\n\
				gl_FragColor = vec4(0.0,0.0,0.0,1.0);\n\
				return;\n\
			  }\n\
			  if(dist > 0.99)\n\
				discard;\n\
			  vec3 dir = normalize(vec3( coord, 0.5 ));\n\
			  float c = cos(u_rotation);\n\
			  float s = sin(u_rotation);\n\
			  dir = vec3(dir.x * c - dir.z * s, dir.y, dir.x * s + dir.z * c);\n\
			  vec4 tex = textureCube(u_texture, dir);\n\
			  if(tex.a < 0.1)\n\
				discard;\n\
			  gl_FragColor = u_color * tex;\n\
			}\
		");
	}

	AnimBullet( x,y,s, target, property ) {
		var has_track = TimelineDialog.findTrack( target, property );
		GUI.next_tooltip = property;
		if ( GUI.Bullet(x - 5, y, s, has_track, null, null, true, { target: target, property: property, callback: this.onDragProperty } ) )
		{
			if (GUI.wasShift)
			{
				if (target.getLocator)
					console.debug( target.getLocator(property, true) );
				else
					console.warn("no locator");
			}
			else
				this.onAnimationTrackBullet( target, property );
		}

	}

	onDragProperty(e,info) {
		var locator = null;

		if (info.target.getLocator)
			locator = info.target.getLocator(info.property, false);
		else
		{
			console.warn("no locator");
			return;
		}

		if (!locator)
			return;

		e.dataTransfer.setData("type","property");
		e.dataTransfer.setData("uid",locator);
	}

	onDragComponent(e,info) {
		var locator = null;
		if (info.target.getLocator)
			locator = info.target.getLocator();
		else
		{
			console.warn("no locator");
			return;
		}

		if (!locator)
			return;

		e.dataTransfer.setData("type","component");
		e.dataTransfer.setData("locator",locator);
		e.dataTransfer.setData("entity_uid",info.target.entity.uid);
		e.dataTransfer.setData("component_uid",info.target.uid);
	}

	onAnimationTrackBullet( target, property_name, type ) {
		TimelineDialog.onAnimationTrackBullet( target, property_name, type );
	}

	getWorldPositionFromMouseEvent(e) {
		var pos = [ 0,0,0 ];
		var ray = this.camera.getRay(e.canvasx, e.canvasy);
		var r = this.space.scene.testRay( ray, null, 1000, 0xFF, true );
		//var r = ray.testPlane([0,0,0],[0,1,0]);
		if (r)
			return ray.collision_point;
		return null;
	}

	onDrag(e) {
		var type = e.dataTransfer.getData("type");
		//console.debug(type, e);
		var that = this;
	}

	onDropOnCanvas(e) {
		var type = e.dataTransfer.getData("type");
		//console.debug(type, e);
		var that = this;
		var space = this.space;

		//is a file?
		if (e.dataTransfer.files.length)
			return this.onDropFile(e);

		//is a resource?
		if (type === "Resource")
		{
			var filename = e.dataTransfer.getData("uid");
			var ext = e.dataTransfer.getData("extension");
			var pos = this.getWorldPositionFromMouseEvent(e);
			if (ext === "glb")
			{
				var ent = createEntity({ type: "PREFAB", name:"prefab" },pos);
				ent.assignPrefab( filename );
			}
			if (ext === "mp3")
			{
				var ent = createEntity({ type: "AUDIO", name:"audio" },pos);
				var comp = new RoomComponents.AudioPlayer();
				ent.addComponent(comp);
				comp.url = filename;
			}
		}

		function createEntity(o, pos)
		{
			var ent = new Entity();
			space.addEntity(ent);
			ent.configure(o);
			if (pos)
				ent.position = pos;
			that.setSelection(ent);
			that.detachAllDialogs();
			return ent;
		}
	}

	//called from XYZLauncher
	onDropFile( evt ) {
		var that = this;
		var files = evt.dataTransfer.files;
		var count = files.length;
		var ext = files[0].name.split(".").pop().toLowerCase();

		if (ext === "hdre")
		{
			var url = URL.createObjectURL(files[0]);
			this.view.setEnvironment(url);
		}
		else if (ext === "json")
		{
			var reader = new FileReader();
			reader.onload = function(e) {
				var data = JSON.parse( e.target.result );
				that.space.fromJSON(data);
			}
			reader.readAsText( files[0] );
		}
		else if (ext === "drc")
		{
			var reader = new FileReader();
			reader.onload = function(e) {
				var r = HoloCamClient.instance.decodeMeshData( e.target.result );
				console.debug(r);
			}
			reader.readAsArrayBuffer( files[0] );
		}
		else if (ext === "png" || ext === "jpg")
		{
		}
		else if (ext === "glb")
		{
			var pos = this.getWorldPositionFromMouseEvent(evt);
			insert_prefab( files[0], pos );
		}
		else //multiple files
		{
			var pos = this.getWorldPositionFromMouseEvent(evt);

			if (xyz.native_mode)
			{
			//native mode supporting GLTF + BIN import directly?
				console.error("cannot add several files at the same time");
			}
			else
				RD.GLTF.loadFromFiles( files, function(node) {
					var ent = new Entity();
					that.space.addEntity(ent);
					ent.configure({ type: "PREFAB", name:"prefab" });
					ent.assignPrefab( node );
					if (pos)
						ent.position = pos;
				});
		}

		//should this work in both?
		function insert_prefab( file, pos )
		{
			var ent = new Entity();
			ent.name = "entity";
			that.space.addEntity(ent);
			var comp = new RoomComponents.PrefabRenderer();
			ent.addComponent( comp );
			var url = URL.createObjectURL( files[0] );
			comp.url = url;
		}
	}

	static showSelectedEntityJSON(entity) {
		//var entity = this.selected_item;
		if (!entity)
			return;
		var json = {};
		json = entity.serialize(json);
		RoomEditor.showJSON(json, "Entity");
	}

	static toClipboard( object, force_local ) {
		if (object && object.constructor !== String )
			object = JSON.stringify( object );

		var input = null;
		var in_clipboard = false;
		if ( !force_local )
			try
			{
				var copySupported = document.queryCommandSupported("copy");
				input = document.createElement("input");
				input.type = "text";
				input.style.opacity = 0;
				input.value = object;
				document.body.appendChild( input );
				input.select();
				in_clipboard = document.execCommand("copy");
				console.debug( in_clipboard ? "saved to clipboard" : "problem saving to clipboard");
				document.body.removeChild( input );
			} catch (err) {
				if (input)
					document.body.removeChild( input );
				console.warn("Oops, unable to copy using the true clipboard");
			}

		//old system
		try
		{
			this._safe_cliboard = null;
			localStorage.setItem("room_clipboard", object );
		}
		catch (err)
		{
			this._safe_cliboard = object;
			console.warn("cliboard quota excedeed");
		}
	}

	static fromClipboard()
	{
		var obj = localStorage.getItem("room_clipboard" );
		if(!obj) return null;
		if(obj[0] == '{')
			return JSON.parse(obj);
		return obj;
	}

	static showJSON(object, tabtitle) {
		if (!object)
			return;
		if (object.constructor === String)
			object = JSON.parse(object); //transform to object so we can use the propper stringify function
		var code = JSON.stringify( object.serialize ? object.serialize() : object, null, "\t");

		tabtitle = tabtitle || "Code";
		console.debug(code); //helps navigating

		code = htmlEncode( code ); //otherwise < is probleamtic
		var w = window.open("","_blank");
		w.document.write("<style>* { margin: 0; padding: 0; } html,body { font-size: 1.2em; margin: 20px; background-color: #222; color: #ddd; } </style>");
		var str = beautifyJSON( code );
		w.document.write("<pre>"+str+"</pre>");
		w.document.close();
		w.document.title = tabtitle;

		function htmlEncode( html_code )
		{
			var e = document.createElement("div");
			e.innerHTML = html_code;
			return e.innerText;
		}

		return w;

	}

	collapseMaterialPanel(shouldCollapse = true) {
		MaterialEditorPanel.collapsed = shouldCollapse;
	}

	translateNativeObject(item) {
		if (item.getOwner) {
			var owner = item.getOwner();
			var index = owner.getID();
			return this.space.getEntityByIndex(index);
		}

		if(item.room_entity)
			return item.room_entity;
		return null;
	}
}


RoomEditor.ENTITIES_MODE = 0;
RoomEditor.PROPERTIES_MODE = 1;
RoomEditor.ROOM_MODE = 3;

RoomEditor.screenshot_settings = {
	normal: [ 2048,1024 ],//[1024,512],
	banner: [ 1142,285 ]
}

RoomEditor.icons = {
	entities: {
		"NULL": [ 1,4 ],
		"PREFAB": [ 2,4 ],
		"SEAT": [ 7,0 ],
		"SURFACE": [ 1,7 ],
		"CAMERA": [ 0,4 ],
		"SOUND": [ 4,7 ],
		"GROUP": [ 2,0 ],
		"REFERENCE": [ 14,3 ]
	}
}

RoomEditor.camera_options = [
	"Perspective",
	"Orthographic",
	null,
	"Cenital",
	"Orbital",
	"Reset"
];

RoomEditor.section_icon = [
	[ 11,0 ], //box
	[ 1,0 ], //magni
	[ 2,0 ], //folder
	[ 3,0 ], //env
	[ 5,0 ] //gears
];

RoomEditor.topmenu = [ {
	title:"Projects",
	submenu: [
		{ title:"New", event:"new_space", icon: [ 12,2 ] },
		{ title:"Load", event:"load_space", icon: [ 11,2 ] },
		{ title:"History", event:"show_history", icon: [ 11,2 ] },
		{ title:"Save", event:"save_space", icon: [ 10,2 ] },
		{ title:"Save As", event:"save_as_space", icon: [ 10,2 ] },
		{ title:"Update thumbnail", event:"update_thumbnail", icon: [ 3,0 ] }
	]
} ];


RoomEditor.select_file_dialog = {
	area: [ 0,0,600,600 ],
	save_filename: "",
	filename: "",
	valid_extensions: "",
	editor: null,
	callback: null,

	show: function( editor, callback, valid_extensions, save_filename )
	{
		this.editor = editor;
		this.callback = callback;
		this.valid_extensions = valid_extensions;
		this.save_filename = save_filename;
		this.editor.attachDialog( this );
	},

	close: function()
	{
		this.editor.detachDialog( this );
	},

	render: function ( editor, pre_render )
	{
		var ctx = gl;
		var that = this;

		var sidewidth = editor.sidewidth;
		var w = gl.canvas.width - sidewidth - 300;
		var h = gl.canvas.height - 60;
		var x = sidewidth + 20;
		var y = 40;
		vec4.copy( this.area, [ x,y,w,h ] );

		if (GUI.Panel( x, y, w,h, "Files", true ) === false )
		{
			this.close();
			return;
		}

		xyz.backend.showFiles( x + 20, y + 60, w - 40, h - 170, inner_assignFileName, this.valid_extensions );

		if ( this.save_filename )
		{
			filename = GUI.TextField( x + 20, y + h - 60, w - 170, 40, filename, null, true );
			if ( GUI.Button( x + w - 140, y + h - 60, 120, 40, "Save" ) && this.onReady)
				this.onReady( this.filename, xyz.backend.current_folder );
		}
		else
		{
			if ( GUI.Button( x + 20, y + h - 60, 120, 40, "No file" ) && this.onReady)
				this.onReady(null);
		}

		function inner_assignFileName( file, folder )
		{
			if (that.save_filename)
				that.filename = file ? file.name : null;
			else
				that.onReady( file, folder, file ? folder + "/" + file.name : null );
		}
	},

	onReady: function(file, folder, fullpath)
	{
		if (this.callback)
			this.callback(file, folder, fullpath);
		this.editor.detachDialog( this );
	}
}

//***************************

function SelectionTool()
{

}

SelectionTool.icon = [ 7,3 ];
SelectionTool.tooltip = "Select";

SelectionTool.prototype.render = function()
{

}

SelectionTool.prototype.onMouse = function(e, ray, editor)
{

}

//called from RoomEditor.prototype.onMouse
SelectionTool.prototype.onNodeClicked = function(node, editor, event)
{
	//search parent
	if (!node)
		return;

	if (this.callback)
	{
		this.callback(node);
		this.callback = null;
		return;
	}

	//select entity
	var entity = editor.view.getSceneNodeEntity(node);
	if (!entity)
		return;

	//var node_js = editor.translateNativeObject( node );

	editor.setSelection( node, event.shiftKey );
}

SelectionTool.prototype.onNodeDoubleClicked = function(node, editor, event)
{
	//editor.selected_item = editor.translateNativeObject( node );
	//editor.setSelection( node );
	editor.mode = RoomEditor.PROPERTIES_MODE;
}


function ManipulateTool()
{

}

ManipulateTool.icon = [ 7,2 ];
ManipulateTool.tooltip = "Manipulate";

ManipulateTool.prototype.onRender = function( editor )
{
	editor.gizmo.render( editor.view.renderer, editor.view.renderer._camera );
}

ManipulateTool.prototype.onMouse = function( e, ray, editor )
{
	return editor.gizmo.onMouse(e);
}

ManipulateTool.prototype.onNodeClicked = SelectionTool.prototype.onNodeClicked;
ManipulateTool.prototype.onNodeDoubleClicked = SelectionTool.prototype.onNodeDoubleClicked;

function PickColorTool( editor )
{
	this.editor = editor;
	PickColorTool.instance = this;
	this.callback = null;
}

PickColorTool.tooltip = "PickColor";

PickColorTool.prototype.getColor = function( x,y )
{
	if (!this.editor.view.pbrpipeline)
		return [ 0,0,0,255 ];
	//read from final image
	var texture = this.editor.view.pbrpipeline.final_texture;
	if (!texture)
		return;
	var channels = 4;
	var pixels = texture.getPixels();
	var pos = y * texture.width * channels + x * channels;
	return pixels.subarray( pos, pos+channels );
}

PickColorTool.prototype.onMouse = function( e, ray, editor )
{
	if (e.type === "mousedown" )
	{
		if (this.callback)
		{
			var color = this.getColor( e.canvasx, e.canvasy );
			if (color)
			{
				var color2 = vec3.scale( vec3.create(), color, 1/255 );
				this.callback( color2 );
			}
			this.callback = null;
		}
	}
}



function beautifyJSON( code, skip_css )
{
	if (typeof(code) === "object")
		code = JSON.stringify(code);
	var reserved = [ "false", "true", "null" ];
	code = code.replace(/(\"(\\.|[^\"])*\")/g, function(v) {//strings
		return "<span class='str'>" + v + "</span>";
	});
	code = code.replace(/(\w+)/g, function(v) { //reserved words
		if (reserved.indexOf(v) !== -1)
			return "<span class='rsv'>" + v + "</span>";
		return v;
	});
	code = code.replace(/([0-9]+)/g, function(v) {//numbers
		return "<span class='num'>" + v + "</span>";
	});
	code = code.replace(/(\w+\.\w+)/g, function(v) {//obj.method
		var t = v.split(".");
		return "<span class='obj'>" + t[0] + "</span>.<span class='prop'>" + t[1] + "</span>";
	});
	code = code.replace(/(\/\/[a-zA-Z0-9\?\!\(\)_ ]*)/g, function(v) { //comments
		return "<span class='cmnt'>" + v + "</span>";
	});
	if (!skip_css)
		code = "<style>.obj { color: #79B; } .prop { color: #B97; }	.str { color: #A79; } .num { color: #B97; } .str .num { color: #A79; } .cmnt { color: #798; } .rsv { color: #9AB; } </style>" + code;
	return code;
}


//context menu options
RoomEditor.entity_options = [
	{ title:"Show JSON", callback: RoomEditor.showSelectedEntityJSON },
	{ title:"Delete" }
];

// ****************************************************

/* Editor 2.0

RoomEditor.prototype.showInterface = function( v )
{
	this.show_sidebar = !v;

	if (!this.ui && !v)
		return;

	if (!this.ui)
		this.buildHTMLUI();

	this.ui.visible = v;

	if (v)
	{
		this.ui.add( gl.canvas, ".maincanvas" );
		this.inspectObject( this.selected_item );
		this.treeview.update( xyz.space.root );
	}
	else
	{
		this.ui.root.parentNode.appendChild( gl.canvas );
	}
}

RoomEditor.prototype.buildHTMLUI = function()
{
	var that = this;

	this.ui = new HTMLUI( gl.canvas.parentNode );
	this.ui.setupLayout( RoomEditor.HTML, RoomEditor.CSS );
	this.inspector = new HTMLUI.Inspector();
	this.treeview = new HTMLUI.TreeView({ default_icon:"icons/icon-tree-item.png" });
	this.treeview.onEntryClicked = this.onTreeViewItemClicked.bind(this);
	this.ui.add( this.inspector, ".sidebar .inspector .content");
	this.ui.add( this.treeview, ".entitiesview .content" );
	var tabbuttons = this.ui.tabbuttons = [];

	var session_name = this.ui.root.querySelector(".ui-header .session-name");
	session_name.innerText = this.space.info.name;

	var tab_names = "Base,Sky,Elements,Area,Materials,Effects,Speed,Thumbnail".split(",");
	for (var i = 0; i < tab_names.length; ++i)
	{
		var tabbutton = new HTMLUI.Button(tab_names[i],{
			icon_off: "icons/icon-" + tab_names[i].toLowerCase() + ".png",
			icon_on: "icons/icon-" + tab_names[i].toLowerCase() + "-white.png"
		});
		tabbutton.root.classList.remove("btn");
		tabbutton.root.classList.add("tabbutton");
		this.ui.add( tabbutton, ".tabsbar" );
		this.ui.tabbuttons.push(tabbutton);
		tabbutton.callback = function(e,b)
		{
			for (var j = 0; j < tabbuttons.length; ++j)
				tabbuttons[j].active = false;
			this.active = true;
			//change layout...
		}
	}

	var addbutton = new HTMLUI.Button("+ Add Component",{ type:"secondary" });
	this.ui.add( addbutton, ".inspector .footer" );

	var newEntityButton = new HTMLUI.Button("", { mini:true, icon:"icons/icon-add.png" });
	this.ui.add( newEntityButton, ".sidebar .title .buttons" );
	newEntityButton.callback = this.showNewEntityDialog.bind(this);

	var copyEntityButton = new HTMLUI.Button("", { mini:true,type:"secondary", icon:"icons/icon-copy.png" });
	this.ui.add( copyEntityButton, ".sidebar .title .buttons" );

	var deleteEntityButton = new HTMLUI.Button("", { mini:true,type:"secondary", icon:"icons/icon-trash.png" });
	this.ui.add( deleteEntityButton, ".sidebar .title .buttons" );
	deleteEntityButton.callback = function() {
		that.deleteItem( that.selected_item );
		that.refreshTreeView();
	}

	var savebutton = new HTMLUI.Button("Save",{ type: "secondary" });
	this.ui.add( savebutton, ".ui-header .buttons" );

	var playbutton = new HTMLUI.Button("Play",{ type: "secondary" });//, button_icon:"icons/icon-play.png"});
	playbutton.callback = this.exit.bind(this);
	this.ui.add( playbutton, ".ui-header .buttons" );

	var leavebutton = new HTMLUI.Button("Leave Editor",{});
	leavebutton.callback = this.exit.bind(this);
	this.ui.add( leavebutton, ".ui-header .buttons" );
}


RoomEditor.prototype.onTreeViewItemClicked = function(entry,event)
{
	this.setSelection( entry.item, event.shiftKey );
}

RoomEditor.prototype.inspectObject = function(object)
{
	this.treeview.setSelection( object );
	this.inspector.clear();
	if (!object)
		return;

	var title = this.ui.get(".inspector > .header .name");
	title.innerText = object.name;

	if ( object.node )
		this.inspectTransform( object.node );

	if ( object && object._components )
		for (var i = 0; i < object._components.length; ++i)
		{
			var comp = object._components[i];
			this.inspector.addContainer(comp.constructor.name);
			this.inspector.inspect( comp, true );
		}
}

RoomEditor.prototype.refreshTreeView = function()
{
	this.treeview.update( xyz.space.root );
}

RoomEditor.prototype.inspectTransform = function(node)
{
	this.inspector.addContainer("Transform", { collapsable: true });
	this.inspector.addObjectProperty(node, "position");
	var rot = quatToEuler(vec3.create(),node.rotation);
	var w = this.inspector.add("vec3","rotation", rot, { step:5 });
	w.target = node;
	w.property = "eulerAnglesDegrees";
	w.onChange = this.inspector.processPropertyChange.bind(this.inspector);
	this.inspector.addObjectProperty(node, "scaling");
}

RoomEditor.prototype.showNewEntityDialog = function()
{
	var entity_types = [
		{
			title: "Seat",
			comp: "Seat",
			icon: "icon-tree-person.png"
		},
		{
			title:"AudioPlayer",
			comp:"AudioPlayer",
			icon:"icon-tree-speaker.png"
		},
		{
			title:"Asset",
			comp:"PrefabRenderer",
			icon:"icon-tree-item.png"
		},
		{
			title:"Surface",
			comp:"Surface",
			icon:"icon-tree-screen.png"
		},
		{
			title:"Table",
			comp:"TravelingTrack",
			icon: "icon-tree-table.png"
		},
		{
			title:"Marker",
			icon: "icon-tree-hand.png"
		}
	];

	var attach_to_selected = false;
	var that = this;
	var dialog = new HTMLUI.Dialog({ width: 740, height: 480 });
	dialog.show(true);

	dialog.setContent("<div class='title'><h2>Add a new Entity</h2><h3>Select a type of entity.</h3></div><div class='entities-type-list'></div>");
	var list_elem = dialog.root.querySelector(".entities-type-list");
	for (var i = 0; i < entity_types.length; ++i)
	{
		var item = entity_types[i];
		var elem = HTMLUI.newTag("div", { className:"entity-item" });
		elem.style.backgroundImage = "url('icons/"+item.icon+"')";
		elem.innerHTML = "<span>"+item.title+"</span>";
		elem.dataset["comp"] = item.comp || "";
		elem.onclick = clicked;
		list_elem.appendChild(elem);
	}

	dialog.center();

	function clicked(e) {
		var new_entity = new Entity();
		new_entity.name = "New Entity";
		var space = xyz.space;

		//to where to attach
		if ( attach_to_selected && that.selected_item )
			that.selected_item.addEntity( new_entity );
		else
			space.addEntity( new_entity );

		if ( new_entity.constructor === Entity && this.dataset["comp"])
		{
			var comp_name = this.dataset["comp"];
			var ctor = RoomComponents[comp_name];
			var comp = new ctor();
			new_entity.addComponent(comp);
		}

		if (new_entity.constructor.type)
			new_entity.type = new_entity.constructor.type;

		that.setSelection( new_entity );
		that.refreshTreeView();
		dialog.close();
	}
}

RoomEditor.CSS = `
	.color-profile { color: #3089CA; }
	.color-speaker { color: #EC8001; }
	.color-hand { color: #2EC3A0; }
	.color-item { color: #FF577F; }
	.color-screen { color: #F6D365; }
	.color-table { color: #8340C6; }

	.ui-area { width: 100%; height: 100%; }
	.ui-root button { font-family: var(--room-font), Arial, sans-serif; }

	.ui-root { overflow: hidden; }

	.ui-header {
		height: 58px !important;
		background-color: white;
		padding-left: 10px !important;
		padding-top: 10px !important;
		border-top: 1px solid #DEDEDE;
		border-bottom: 1px solid #DEDEDE;
	}

	.ui-header .title {
		display: inline-block;
		height: 100%;
		top: 20px;
		position: absolute;
		color: #8A8A8A;
		margin-left: 6px;
	}

	.ui-header .session-name {
		font-weight: bold;
		position: absolute;
		left: 40%;
		width: 200px;
		top: 20px;
		color: #464646;
	}

	.ui-header .buttons {
		position: absolute;
		right: 20px;
		text-align: right;
	}

	.ui-header .buttons .btn {
		width: 120px;
		height: 80%;
		display: inline-block;
	}

	.ui-body {
		height: calc(100% - 60px) !important;
		background-color: white;
	}

	.tabsbar {
		width: 69px !important;
		border-right: 1px solid #DEDEDE;
		display: inline-block;
		background-color: #F4F4F4;
		position: absolute;
		left: 0;
	}

	.tabsbar button {
		border: 0;
		width: 100%;
		height: 69px;
		border-radius: 0;
		cursor: pointer;
		position: relative;
		padding: 0;
		background-repeat: no-repeat;
		background-position: 50% 30%;
	}	

	.tabsbar button .text {
		bottom: 6px;
		display: block;
		position: absolute;
		text-align: center;
		width: 100%;
		font-family: var(--room-font), Arial, sans-serif;
		font-size: 10px;
	}

	.tabsbar button:hover {
		background-color: #CCC;
	}

	.tabsbar button.active {
		color: white;
		background-color: #0088FF;
	}

	.workspace {
		width: calc(100% - 70px) !important;
		display: inline-block;
		position: absolute;
		right: 0;
	}

	.sidebar {
		width: calc(300px) !important;
		display: inline-block;
		position: absolute;
		background-color: #F4F4F4 !important;
		left: 0;
	}

	.sidebar > .title {
		height: 39px;
		border-bottom: 1px solid #DEDEDE;
		color: #8A8A8A;
		font-weight: bold;
	}

	.sidebar > .title .name {
		display: inline-block;
	    padding: 10px;
	}

	.sidebar > .title .buttons {
		position: absolute;
		top: 4px;
		right: 4px;
		width: 120px;
	}

	.sidebar > .entitiesview {
		height: calc( 50% - 60px );
	}

	.sidebar > .inspector {
		height: calc( 50% - 40px );
	}

	.entititesview {
		background-color: #eee;
		width: 100%;
	}

	.entitiesview .content {
		width: 100%;
		height: 100%;
	}

	.inspector {
		background-color: #FCFCFC;
		border-radius: 8px 8px 0 0;
		padding: 10px;
	}

	.inspector .ui-inspector {
		height: calc( 100% - 10px);
	}

	.inspector > .header {
		height: 40px;
		padding-top: 10px;
	}

	.ui-treeview ::-webkit-scrollbar-thumb {
		background: red;
		border-radius: 2px;
	}

	.inspector > .header .name {
		color: #EC8001;
		padding-left: 10px;
		font-size: 16px;
		font-weight: bold;
	}

	.inspector > .content {
		height: calc( 100% - 80px );
		overflow: auto;
	}

	.inspector .ui-inspector {
		width: calc( 100% - 10px );
		margin-left: 10px;
	}

	.inspector > .footer {
		height: 80px;
		background-color: white;
	}

	.maincanvas {
		width: calc(100% - 300px) !important;
		display: inline-block;
		overflow: hidden;
		position: absolute;
		right: 0;
	}

	.entities-type-list {
		text-align: center;
	}

	.entities-type-list .entity-item {
		position: relative;
		display: inline-block;
		width: 130px;
		height: 130px;
		border-radius: 16px;
		margin: 10px;
		text-align: center;
		background-repeat: no-repeat;
		background-position: center;
		border: 2px solid transparent;
		padding: 0;
	}

	.entities-type-list .entity-item:hover {
		border: 2px solid #EC8001;
	}

	.entities-type-list .entity-item span {
		display: block;
		position: absolute;
		text-align: center;
		width: 100%;
		bottom: 20px;
	}

`;

RoomEditor.HTML = `
<div class="ui-header ui-area"><span class="logo"><img src="https://tamats.com/tmrw/icons/logo.png"/><span class='title'>Creator</span></span><span class="session-name"></span><span class="buttons"></span></div>
<div class="ui-body ui-area">
	<div class="tabsbar ui-area"></div>
	<div class="workspace ui-area">
		<div class="sidebar ui-area">
			<div class="title"><span class='name'>Elements</span><span class="buttons"></div>
			<div class="entitiesview">
				<div class="content"></div>
			</div>
			<div class="inspector">
				<div class="header"><span class='name'></span><span class="buttons"></div>
				<div class="content"></div>
				<div class="footer"></div>
			</div>
		</div>
		<div class="maincanvas ui-area"></div>
	</div>
</div>
`;
*/

export default RoomEditor;

