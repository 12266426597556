import { DEG2RAD } from "@src/constants/index";
import { perspectiveProjectionMatrix } from "@src/engine/helpers/perspectiveProjectionMatrix";
import { mat4FromTranslationFrontTop, mat4MultiplyVec3, mat4ProjectVec3, mat4RotateVec3 } from "@src/gl-matrix/mat4";
import { vec3UnProject } from "@src/gl-matrix/vec3";
import { ClipType } from "@src/libs/rendeer/ClipType";
import { Direction3Constants } from "@src/libs/rendeer/Direction3Constants";
import { Ray } from "@src/libs/rendeer/Ray";
import { mat4, quat, vec2, vec3, vec4 } from "gl-matrix";

/* Temporary containers ************/
const temp_vec3 = vec3.create();
const temp_vec3b = vec3.create();
const temp_quat = quat.create();

/* used functions */

/**
 *
 * @param {vec4} plane
 * @param {vec3} center
 * @param {vec3} halfsize
 * @returns {ClipType|number}
 */
function planeOverlap(plane, center, halfsize) {
	const n = plane;//plane.subarray(0,3);
	const d = plane[3];

	const tempx = Math.abs(halfsize[0] * n[0]);
	const tempy = Math.abs(halfsize[1] * n[1]);
	const tempz = Math.abs(halfsize[2] * n[2]);

	const radius = tempx + tempy + tempz;

	const distance = vec3.dot(n, center) + d;

	if (distance <= -radius) {
		return ClipType.Outside;
	} else if (distance <= radius) {
		return ClipType.Overlap;
	} else {
		return ClipType.Inside
	}
}

/**
 *
 * @param {vec4} plane
 * @param {vec3} point
 * @returns {number}
 */
function distanceToPlane(plane, point) {
	return vec3.dot(plane, point) + plane[3];
}

export class Camera {
	/**
	 * the camera type, Camera.PERSPECTIVE || Camera.ORTHOGRAPHIC
	 * @property type {number}
	 * @default Camera.PERSPECTIVE
	 */
	type = Camera.PERSPECTIVE;

	_position = vec3.fromValues(0, 100, 100);
	_target = vec3.fromValues(0, 0, 0);
	_up = vec3.fromValues(0, 1, 0);

	/**
	 * near distance
	 * @property near {number}
	 * @default 0.1
	 */
	_near = 0.1;
	/**
	 * far distance
	 * @property far {number}
	 * @default 10000
	 */
	_far = 10000;
	/**
	 * aspect (width / height)
	 * @property aspect {number}
	 * @default 1
	 */
	_aspect = 1.0;
	/**
	 * fov angle in degrees
	 * @property fov {number}
	 * @default 45
	 */
	_fov = 45; //persp

	/**
	 * Normalized offset in range [-1, 1]
	 * @type {vec2}
	 * @private
	 */
	_shift = vec2.create();

	get shift(){
		return this._shift;
	}

	set shift(v){
		vec2.copy(this._shift, v);
	}

	/**
	 * size of frustrum when working in orthographic (could be also an array with [left,right,top,bottom]
	 * @property frustum_size {number}
	 * @default 50
	 */
	_frustum_size = 50; //ortho (could be also an array with [left,right,top,bottom]
	flip_y = false;

	//if set to [w,h] of the screen (or framebuffer) it will align the viewmatrix to the texel if it is in orthographic mode
	//useful for shadowmaps in directional lights
	view_texel_grid = null;

	_view_matrix = mat4.create();
	_projection_matrix = mat4.create();
	_viewprojection_matrix = mat4.create();
	_model_matrix = mat4.create(); //inverse of view

	_autoupdate_matrices = true;
	_must_update_matrix = false;

	_top = vec3.create();
	_right = vec3.create();
	_front = vec3.create();

	uniforms = {
		u_view_matrix: this._view_matrix,
		u_projection_matrix: this._projection_matrix,
		u_viewprojection_matrix: this._viewprojection_matrix,
		u_camera_front: this._front,
		u_camera_position: this._position,
		u_camera_planes: vec2.fromValues(0.1, 1000),
	};

	/**
	 * Camera wraps all the info about the camera (properties and view and projection matrices)
	 * @class Camera
	 * @constructor
	 */
	constructor(options) {


		if (options)
			this.configure(options);

		this.updateMatrices();
		this.getLocalPoint = Camera.prototype.localToGlobal;
		this.testMesh = (function () {
			if (!global.BBox) //no litegl installed
				return;

			const aabb = BBox.create();
			const center = aabb.subarray(0, 3);
			const halfsize = aabb.subarray(3, 6);

			return function (mesh, matrix) {
				//convert oobb to aabb
				const bounding = mesh.bounding;
				if (!bounding)
					return ClipType.Inside;
				BBox.transformMat4(aabb, bounding, matrix);
				return this.testBox(center, halfsize);
			}
		})();
	}

	configure(o) {
		if (o.type != null) this.type = o.type;
		if (o.position) this._position.set(o.position);
		if (o.target) this._target.set(o.target);
		if (o.up) this._up.set(o.up);
		if (o.near) this.near = o.near;
		if (o.far) this.far = o.far;
		if (o.fov) this.fov = o.fov;
		if (o.shift) this.shift = o.shift;
		if (o.aspect) this.aspect = o.aspect;
	}

	serialize() {
		const o = {
			type: this.type,
			position: [ this.position[0], this.position[1], this.position[2] ],
			target: [ this.target[0], this.target[1], this.target[2] ],
			up: this.up,
			fov: this.fov,
			near: this.near,
			far: this.far,
			aspect: this.aspect,
			shift: this.shift,
		};
		return o;
	}

	/**
	 * changes the camera to perspective mode
	 * @method perspective
	 * @param {number} fov
	 * @param {number} aspect
	 * @param {number} near
	 * @param {number} far
	 */
	perspective(fov, aspect, near, far) {
		this.type = Camera.PERSPECTIVE;
		this._fov = fov;
		this._aspect = aspect;
		this._near = near;
		this._far = far;

		this._must_update_matrix = true;
	}

	/**
	 * changes the camera to orthographic mode (frustumsize is top-down)
	 * @method orthographic
	 * @param {number} frustum_size
	 * @param {number} near
	 * @param {number} far
	 * @param {number} aspect
	 */
	orthographic(frustum_size, near, far, aspect) {
		this.type = Camera.ORTHOGRAPHIC;
		this._frustum_size = frustum_size;
		if (arguments.lenth > 1) {
			this._near = near;
			this._far = far;
			this._aspect = aspect || 1;
		}

		this._must_update_matrix = true;
	}

	/**
	 * configure view of the camera
	 * @method lookAt
	 * @param {vec3} position
	 * @param {vec3} target
	 * @param {vec3} up
	 */
	lookAt(position, target, up) {
		if (this._position === target) //special case
			target = vec3.clone(target);
		vec3.copy(this._position, position);
		vec3.copy(this._target, target);
		vec3.copy(this._up, up);

		this._must_update_matrix = true;
	}

	/**
	 * update view projection matrices
	 * @method updateMatrices
	 */
	updateMatrices(force) {
		if (this._autoupdate_matrices || force) {
			//proj
			if (this.type === Camera.ORTHOGRAPHIC) {
				if (this.frustum_size.constructor === Number) {
					mat4.ortho(this._projection_matrix, -this.frustum_size * this._aspect, this.frustum_size * this._aspect, -this._frustum_size, this._frustum_size, this._near, this._far);
				} else if (this.frustum_size.length) {
					mat4.ortho(this._projection_matrix, this.frustum_size[0], this.frustum_size[1], this.frustum_size[2], this.frustum_size[3], this.frustum_size.length > 3 ? this.frustum_size[4] : this._near, this.frustum_size.length > 4 ? this.frustum_size[5] : this._far);
				}
			} else {
				perspectiveProjectionMatrix(
					this._projection_matrix,
					this._aspect,
					this._fov * DEG2RAD,
					this._shift[0], this._shift[1],
					this._near, this._far
				);
			}

			if (this.flip_y) {
				mat4.scale(this._projection_matrix, this._projection_matrix, [ 1, -1, 1 ]);
			}

			//view
			mat4.lookAt(this._view_matrix, this._position, this._target, this._up);

			//align
			if (this.view_texel_grid && this.type === Camera.ORTHOGRAPHIC) {
				const view_width = this.frustum_size.constructor === Number ? this.frustum_size * this._aspect : this.frustum_size[0];
				const view_height = this.frustum_size.constructor === Number ? this.frustum_size : this.frustum_size[1];
				const stepx = 2 * view_width / this.view_texel_grid[0];
				const stepy = 2 * view_height / this.view_texel_grid[1];
				this._view_matrix[12] = Math.floor(this._view_matrix[12] / stepx) * stepx;
				this._view_matrix[13] = Math.floor(this._view_matrix[13] / stepy) * stepy;
			}
		}

		if (this.is_reflection) {
			mat4.scale(this._view_matrix, this._view_matrix, [ 1, -1, 1 ]);
		}

		mat4.multiply(this._viewprojection_matrix, this._projection_matrix, this._view_matrix);
		mat4.invert(this._model_matrix, this._view_matrix);

		this._must_update_matrix = false;

		mat4RotateVec3(this._right, this._model_matrix, Direction3Constants.RIGHT);
		mat4RotateVec3(this._top, this._model_matrix, Direction3Constants.UP);
		mat4RotateVec3(this._front, this._model_matrix, Direction3Constants.FRONT);

		this.distance = vec3.distance(this._position, this._target);

		this.uniforms.u_camera_planes[0] = this._near;
		this.uniforms.u_camera_planes[1] = this._far;
	}

	getModel(m) {
		m = m || mat4.create();
		if (this._must_update_matrix)
			this.updateMatrices();
		mat4.copy(m, this._model_matrix);
		return m;
	}

	/**
	 * update camera using a model_matrix as reference
	 * @method updateVectors
	 * @param {mat4} model_matrix
	 */
	updateVectors(model_matrix) {
		const front = vec3.subtract(temp_vec3, this._target, this._position);
		const dist = vec3.length(front);
		mat4MultiplyVec3(this._position, model_matrix, Direction3Constants.ZERO);
		mat4MultiplyVec3(this._target, model_matrix, [ 0, 0, -dist ]);
		mat4RotateVec3(this._up, model_matrix, Direction3Constants.UP);
	}

	/**
	 * transform vector (only rotates) from local to global
	 * @method getLocalVector
	 * @param {vec3} v
	 * @param {vec3} result [Optional]
	 * @return {vec3} local point transformed
	 */
	getLocalVector(v, result) {
		if (this._must_update_matrix)
			this.updateMatrices();

		return mat4RotateVec3(result || vec3.create(), this._model_matrix, v);
	}

	/**
	 * transform point from local to global coordinates
	 * @method localToGlobal
	 * @param {vec3} v
	 * @param {vec3} result [Optional]
	 * @return {vec3} local point transformed
	 */
	localToGlobal(v, result) {
		if (this._must_update_matrix)
			this.updateMatrices();

		return vec3.transformMat4(result || vec3.create(), v, this._model_matrix);
	}

	/**
	 * transform point from global coordinates (world space) to local coordinates (view space)
	 * @method globalToLocal
	 * @param {vec3} v
	 * @param {vec3} result [Optional]
	 * @return {vec3} local point
	 */
	globalToLocal(v, result) {
		if (this._must_update_matrix)
			this.updateMatrices();
		return vec3.transformMat4(result || vec3.create(), v, this._view_matrix);
	}

	/**
	 * transform vector from global coordinates (world space) to local coordinates (view space) taking into account only rotation and scaling
	 * @method globalVectorToLocal
	 * @param {vec3} v
	 * @param {vec3} result [Optional]
	 * @return {vec3} local vector
	 */
	globalVectorToLocal(v, result) {
		if (this._must_update_matrix)
			this.updateMatrices();
		return mat4RotateVec3(result || vec3.create(), this._view_matrix, v);
	}

	/**
	 * gets the front vector normalized
	 * @method getFront
	 * @param {vec3} [dest]
	 * @return {vec3} front vector
	 */
	getFront(dest = vec3.create()) {
		vec3.subtract(dest, this._target, this._position);
		vec3.normalize(dest, dest);
		return dest;
	}

	/**
	 * move the position and the target that amount
	 * @method move
	 * @param {vec3} v
	 * @param {Number} scalar [optional] it will be multiplied by the vector
	 */
	move(v, scalar) {
		if (scalar !== undefined) {
			vec3.scale(temp_vec3, v, scalar);
			v = temp_vec3;
		}

		vec3.add(this._target, this._target, v);
		vec3.add(this._position, this._position, v);
		this._must_update_matrix = true;
	}

	/**
	 * move the position and the target using the local coordinates system of the camera
	 * @method moveLocal
	 * @param {vec3} v
	 * @param {Number} [scalar] it will be multiplied by the vector
	 */
	moveLocal(v, scalar) {
		if (this._must_update_matrix)
			this.updateMatrices();
		const delta = mat4RotateVec3(temp_vec3, this._model_matrix, v);
		if (scalar !== undefined)
			vec3.scale(delta, delta, scalar);
		vec3.add(this._target, this._target, delta);
		vec3.add(this._position, this._position, delta);
		this._must_update_matrix = true;
	}

	/**
	 * rotate over its position
	 * @method rotate
	 * @param {number} angle in radians
	 * @param {vec3} axis
	 */
	rotate(angle, axis) {
		const R = quat.setAxisAngle(temp_quat, axis, angle);
		const front = vec3.subtract(temp_vec3, this._target, this._position);
		vec3.transformQuat(front, front, R);
		vec3.add(this._target, this._position, front);
		this._must_update_matrix = true;
	}

	/**
	 * rotate over its position
	 * @method rotateLocal
	 * @param {number} angle in radians
	 * @param {vec3} axis in local coordinates
	 */
	rotateLocal(angle, axis) {
		if (this._must_update_matrix)
			this.updateMatrices();
		const local_axis = mat4RotateVec3(temp_vec3b, this._model_matrix, axis);
		const R = quat.setAxisAngle(temp_quat, local_axis, angle);
		const front = vec3.subtract(temp_vec3, this._target, this._position);
		vec3.transformQuat(front, front, R);
		vec3.add(this._target, this._position, front);
		this._must_update_matrix = true;
	}

	/**
	 * rotate around its target position
	 * @method rotate
	 * @param {number} angle in radians
	 * @param {vec3} axis
	 * @param {vec3} [center=null] if another center is provided it rotates around it
	 */
	orbit(angle, axis, center, axis_in_local) {
		if (!axis)
			throw("RD: orbit axis missing");

		center = center || this._target;
		if (axis_in_local) {
			if (this._must_update_matrix)
				this.updateMatrices();
			axis = mat4RotateVec3(temp_vec3b, this._model_matrix, axis);
		}
		const R = quat.setAxisAngle(temp_quat, axis, angle);
		const front = vec3.subtract(temp_vec3, this._position, this._target);
		vec3.transformQuat(front, front, R);
		vec3.add(this._position, center, front);
		this._must_update_matrix = true;
	}

//multiplies front by f and updates position
	orbitDistanceFactor(f, center) {
		center = center || this._target;
		const front = vec3.subtract(temp_vec3, this._position, center);
		vec3.scale(front, front, f);
		vec3.add(this._position, center, front);
		this._must_update_matrix = true;
	}

	/**
	 * projects a point from 3D to 2D
	 * @method project
	 * @param {vec3} vec coordinate to project
	 * @param {Array} [viewport=gl.viewport]
	 * @param {vec3|vec4} [result]
	 * @return {vec3} the projected point
	 */
	project(vec, viewport, result) {
		result = result || vec3.create();
		viewport = viewport || gl.viewport_data;
		if (this._must_update_matrix)
			this.updateMatrices();
		mat4ProjectVec3(result, this._viewprojection_matrix, vec);

		//adjust to viewport
		result[0] = result[0] * viewport[2] + viewport[0];
		result[1] = result[1] * viewport[3] + viewport[1];

		return result;
	}

	/**
	 * returns the size in screenspace of a sphere set in a position
	 * @method computeProjectedRadius
	 * @param {vec3} vec center of sphere
	 * @param {Number} radius radius of sphere
	 * @param {vec4} [viewport]
	 * @param {Boolean} [billboarded]  in case you want the billboarded projection
	 * @return {Number} radius
	 */
	computeProjectedRadius(center, radius, viewport, billboarded = false) {
		viewport = viewport || gl.viewport_data;

		//billboarded circle
		if (billboarded) {
			const v = vec4.create();
			v.set(center);
			v[3] = 1;
			const proj = vec4.transformMat4(v, v, this._viewprojection_matrix);
			return Math.max(1.0, viewport[3] * this._projection_matrix[5] * radius / proj[3]);
		}

		//from https://stackoverflow.com/questions/21648630/radius-of-projected-sphere-in-screen-space
		if (this.type === Camera.ORTHOGRAPHIC)
			return radius / (this._frustum_size.constructor === Number ? this._frustum_size : 1) * viewport[3] / 2;
		const d = vec3.distance(center, this.position); //true distance
		if (d == 0)
			return 0;
		const fov = this.fov / 2 * Math.PI / 180.0;
		const dem = Math.abs(d * d - radius * radius);
		const pr = 1.0 / Math.tan(fov) * (radius / Math.sqrt(dem)); //good
		//var pr = 1.0 / Math.tan(fov) * radius / d; // distorted
		return pr * (viewport[3] / 2);
	}

	/**
	 * projects a point from 2D to 3D
	 * @method unproject
	 * @param {vec3} vec coordinate to unproject
	 * @param {Array} [viewport=gl.viewport]
	 * @param {vec3} [result=vec3]
	 * @return {vec3} the projected point
	 */
	unproject(vec, viewport, result) {
		viewport = viewport || gl.viewport_data;
		if (this._must_update_matrix)
			this.updateMatrices();
		return vec3UnProject(result || vec3.create(), vec, this._viewprojection_matrix, viewport);
	}

	/**
	 * gets the ray passing through one pixel
	 * @method getRay
	 * @param {number} x
	 * @param {number} y
	 * @param {Array} [viewport=gl.viewport]
	 * @param {Ray} [out] { origin: vec3, direction: vec3 }
	 * @return {Ray} ray object { origin: vec3, direction:vec3 }
	 */
	getRay(x, y, viewport, out) {
		if (x === undefined || y === undefined)
			throw("Camera.getRay requires x and y parameters");

		viewport = viewport || gl.viewport_data;

		if (!out)
			out = new Ray();

		if (this._must_update_matrix)
			this.updateMatrices();

		const origin = out.origin;
		vec3.set(origin, x, y, 0);
		if (this.type === Camera.ORTHOGRAPHIC)
			vec3UnProject(origin, origin, this._viewprojection_matrix, viewport);
		else
			vec3.copy(origin, this.position);

		const direction = out.direction;
		vec3.set(direction, x, y, 1);
		vec3UnProject(direction, direction, this._viewprojection_matrix, viewport);
		vec3.sub(direction, direction, origin);
		vec3.normalize(direction, direction);
		return out;
	}

	/**
	 * given a screen coordinate it cast a ray and returns the collision point with a given plane
	 * @method getRayPlaneCollision
	 * @param {number} x
	 * @param {number} y
	 * @param {vec3} position Plane point
	 * @param {vec3} normal Plane normal
	 * @param {vec3} [result=vec3]
	 * @param {vec4} [viewport=vec4]
	 * @return {vec3} the collision point, or null
	 */
	getRayPlaneCollision(x, y, position, normal, result, viewport) {
		result = result || vec3.create();
		//*
		const ray = this.getRay(x, y, viewport);
		if (geo.testRayPlane(ray.origin, ray.direction, position, normal, result))
			return result;
		return null;
		/*/
		if(this._must_update_matrix)
			this.updateMatrices();
		var RT = new GL.Raytracer( this._viewprojection_matrix, viewport );
		var start = this._position;
		var dir = RT.getRayForPixel( x,y );
		if( geo.testRayPlane( start, dir, position, normal, result ) )
			return result;
		return null;
		//*/
	}

	getModelForScreenPixel(x, y, distance, face_to_eye, result) {
		result = result || mat4.create();

		//convert coord from screen to world
		const pos = this.unproject([ x, y, -1 ]);
		const delta = vec3.sub(vec3.create(), pos, this._position);
		vec3.normalize(delta, delta);
		vec3.scaleAndAdd(pos, pos, delta, distance);

		vec3.normalize(delta, delta);

		//build matrix
		mat4FromTranslationFrontTop(result, pos, delta, this._up);

		return result;
	}

	/**
	 * Used to move the camera (helps during debug)
	 * @method applyController
	 * @param {number} dt delta time from update
	 * @param {Event} e mouse event or keyboard event
	 */
	applyController(dt, event, speed, enable_wsad) {
		speed = speed || 10;
		if (dt) {
			const delta = vec3.create();
			if (gl.keys[Camera.controller_keys.forward] || (enable_wsad && gl.keys["W"]))
				delta[2] = -1;
			else if (gl.keys[Camera.controller_keys.back] || (enable_wsad && gl.keys["S"]))
				delta[2] = 1;
			if (gl.keys[Camera.controller_keys.left] || (enable_wsad && gl.keys["A"]))
				delta[0] = -1;
			else if (gl.keys[Camera.controller_keys.right] || (enable_wsad && gl.keys["D"]))
				delta[0] = 1;
			if (vec3.sqrLen(delta))
				this.moveLocal(delta, dt * speed);
		}

		if (event) {
			if (event.deltax)
				this.rotate(event.deltax * -0.005, Direction3Constants.UP);
			if (event.deltay)
				this.rotateLocal(event.deltay * -0.005, Direction3Constants.RIGHT);
		}
	}

	lerp(camera, f) {
		vec3.lerp(this._position, this._position, camera._position, f);
		vec3.lerp(this._target, this._target, camera._target, f);
		vec3.lerp(this._up, this._up, camera._up, f);
		this._fov = this._fov * (1.0 - f) + camera._fov * f;
		this._near = this._near * (1.0 - f) + camera._near * f;
		this._far = this._far * (1.0 - f) + camera._far * f;

		if (this._frustum_size.constructor === Number)
			this._frustum_size = this._frustum_size * (1.0 - f) + camera._frustum_sizer * f;
		this._must_update_matrix = true;
	}

//it rotates the matrix so it faces the camera
	orientMatrixToCamera(matrix) {
		matrix.set(this._right, 0);
		matrix.set(this._top, 4);
		matrix.set(this._front, 8);
	}

	extractPlanes() {
		const vp = this._viewprojection_matrix;
		const planes = this._planes_data || new Float32Array(4 * 6);

		//right
		planes.set([ vp[3] - vp[0], vp[7] - vp[4], vp[11] - vp[8], vp[15] - vp[12] ], 0);
		normalize(0);

		//left
		planes.set([ vp[3] + vp[0], vp[7] + vp[4], vp[11] + vp[8], vp[15] + vp[12] ], 4);
		normalize(4);

		//bottom
		planes.set([ vp[3] + vp[1], vp[7] + vp[5], vp[11] + vp[9], vp[15] + vp[13] ], 8);
		normalize(8);

		//top
		planes.set([ vp[3] - vp[1], vp[7] - vp[5], vp[11] - vp[9], vp[15] - vp[13] ], 12);
		normalize(12);

		//back
		planes.set([ vp[3] - vp[2], vp[7] - vp[6], vp[11] - vp[10], vp[15] - vp[14] ], 16);
		normalize(16);

		//front
		planes.set([ vp[3] + vp[2], vp[7] + vp[6], vp[11] + vp[10], vp[15] + vp[14] ], 20);
		normalize(20);

		this._planes_data = planes;
		if (!this._frustrum_planes)
			this._frustrum_planes = [ planes.subarray(0, 4), planes.subarray(4, 8), planes.subarray(8, 12), planes.subarray(12, 16), planes.subarray(16, 20), planes.subarray(20, 24) ];

		function normalize(pos) {
			const N = planes.subarray(pos, pos + 3);
			let l = vec3.length(N);
			if (!l === 0.0)
				return;
			l = 1.0 / l;
			planes[pos] *= l;
			planes[pos + 1] *= l;
			planes[pos + 2] *= l;
			planes[pos + 3] *= l;
		}
	}

	/**
	 * test if box is inside frustrum (you must call camera.extractPlanes() previously to update frustrum planes)
	 * @method testBox
	 * @param {vec3} center center of the box
	 * @param {vec3} halfsize halfsize of the box (vector from center to corner)
	 * @return {number} CLIP_OUTSIDE or CLIP_INSIDE or CLIP_OVERLAP
	 */
	testBox(center, halfsize) {
		if (!this._frustrum_planes)
			this.extractPlanes();
		const planes = this._frustrum_planes;
		let flag = 0, o = 0;

		flag = planeOverlap(planes[0], center, halfsize);
		if (flag === ClipType.Outside) return ClipType.Outside;
		o += flag;
		flag = planeOverlap(planes[1], center, halfsize);
		if (flag === ClipType.Outside) return ClipType.Outside;
		o += flag;
		flag = planeOverlap(planes[2], center, halfsize);
		if (flag === ClipType.Outside) return ClipType.Outside;
		o += flag;
		flag = planeOverlap(planes[3], center, halfsize);
		if (flag === ClipType.Outside) return ClipType.Outside;
		o += flag;
		flag = planeOverlap(planes[4], center, halfsize);
		if (flag === ClipType.Outside) return ClipType.Outside;
		o += flag;
		flag = planeOverlap(planes[5], center, halfsize);
		if (flag === ClipType.Outside) return ClipType.Outside;
		o += flag;

		if (o == 0) return ClipType.Inside;
		else return ClipType.Overlap;
	}

	/**
	 * test if sphere is inside frustrum (you must call camera.extractPlanes() previously to update frustrum planes)
	 * @method testSphere
	 * @param {vec3} center
	 * @param {number} radius
	 * @return {number} CLIP_OUTSIDE or CLIP_INSIDE or CLIP_OVERLAP
	 */
	testSphere(center, radius) {
		if (!this._frustrum_planes)
			this.extractPlanes();
		const planes = this._frustrum_planes;

		let dist;
		let overlap = false;

		dist = distanceToPlane(planes[0], center);
		if (dist < -radius)
			return ClipType.Outside;
		else if (dist >= -radius && dist <= radius)
			overlap = true;
		dist = distanceToPlane(planes[1], center);
		if (dist < -radius)
			return ClipType.Outside;
		else if (dist >= -radius && dist <= radius)
			overlap = true;
		dist = distanceToPlane(planes[2], center);
		if (dist < -radius)
			return ClipType.Outside;
		else if (dist >= -radius && dist <= radius)
			overlap = true;
		dist = distanceToPlane(planes[3], center);
		if (dist < -radius)
			return ClipType.Outside;
		else if (dist >= -radius && dist <= radius)
			overlap = true;
		dist = distanceToPlane(planes[4], center);
		if (dist < -radius)
			return ClipType.Outside;
		else if (dist >= -radius && dist <= radius)
			overlap = true;
		dist = distanceToPlane(planes[5], center);
		if (dist < -radius)
			return ClipType.Outside;
		else if (dist >= -radius && dist <= radius)
			overlap = true;

		if (overlap)
			return ClipType.Overlap;
		return ClipType.Inside;
	}

	get position() {
		return this._position;
	}

	set position(value) {
		this._position.set(value);
	}

	get target() {
		return this._target;
	}

	set target(value) {
		this._target.set(value);
	}

	get up() {
		return this._up;
	}

	set up(value) {
		this._up.set(value);
	}

	get fov() {
		return this._fov;
	}

	set fov(value) {
		this._fov = value;
	}

	get aspect() {
		return this._aspect;
	}

	set aspect(value) {
		this._aspect = value;
	}

	get frustum_size() {
		return this._frustum_size;
	}

	set frustum_size(value) {
		this._frustum_size = value;
	}

	get near() {
		return this._near;
	}

	set near(value) {
		this._near = value;
	}

	get far() {
		return this._far;
	}

	set far(value) {
		this._far = value;
	}

	get view_matrix() {
		return this._view_matrix;
	}

	set view_matrix(value) {
		this._view_matrix = value;
	}

	get projection_matrix() {
		return this._projection_matrix;
	}

	set projection_matrix(value) {
		this._projection_matrix = value;
	}

	get viewprojection_matrix() {
		return this._viewprojection_matrix;
	}

	set viewprojection_matrix(value) {
		this._viewprojection_matrix = value;
	}
}


Camera.PERSPECTIVE = 1;
Camera.ORTHOGRAPHIC = 2;


//(could be also an array with [left,right,top,bottom]


Camera.controller_keys = { forward: "UP", back: "DOWN", left: "LEFT", right: "RIGHT" };


