import Entity from "@src/engine/entity";
import { RoomComponents } from "@src/engine/RoomComponents";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import NewEntityPanel from "./newEntity";
//import RoomEditor from "./../editor"

function lerp(a,b,f) { return a * (1-f) + b*f; }


//Dialog displayed when users access their backlog from the editor
class EntityListPanel {
    area = [0,0,0,0];
    filter = "";
    selected = null;

    display_add_entity = false;
    display_actions = false;
    show_colletions_list = true;
    editor = null;
    space = null;
    side_scroll = 0;
    side_scroll_target = 0;

    onSelectedEntity = null;

    constructor(editor)
    {
        this.editor = editor;
    }

    render( x, y, w, h)
    {
        this.area = [x,y,w,h];
        this.renderEntitiesList(window.gl,x,y + 20,w - 20,h);
    }

    renderEntitiesList(ctx,x,y,w,h) {
        var space = this.space = this.editor.space;
        var that = this;
        var editor = this.editor;
    
        this.filter = GUI.SearchBox(x, y, w - 100, 30, this.filter || "");
    
        //toggle collections
        GUI.next_tooltip = "Show Collections";
        if ( Button.call(GUI, x + w - 70,y,30,30,[ 8,3 ], this.show_colletions_list, [ 0.1,0.1,0.1,0.9 ],null,10 ) )
            this.show_colletions_list = !this.show_colletions_list;
    
        //new entity
        if(this.display_add_entity)
        {
            if ( Button.call(GUI, x + w - 30,y,30,30,[ 0,6 ], false, [ 0.1,0.1,0.1,0.9 ],null,10 ) )
                editor.attachDialog( NewEntityPanel );
        }
    
        if ( this.side_scroll_target > 0 )
            this.side_scroll_target = 0;
    
        this.side_scroll = lerp( this.side_scroll, this.side_scroll_target, 0.1 );
        y += 40;
        var size = 20;
        var margin = 4;
        var line_height = size + margin;
        var list_height = h - 80;
    
        //scrollable area
        var starty = y;
        ctx.fillColor = [ 0.1,0.1,0.1,0.9 ];
        ctx.beginPath();
        ctx.roundRect( x,starty,w,list_height, [ 5 ] );
        ctx.fill();
        y += 10;
    
        //main element: space
        var mouse_state = GUI.HoverArea( x + 10, y+2, w - size, 24, { entity: this.space, callback: editor.onDragEntity.bind(this) });
        GUI.DrawIcon( x + 10 + size*0.5, y + size*0.5, 0.3, [ 0,1 ], false, [ 1,1,1,0.5 ] );
        var color = mouse_state !== GLUI.NONE ? [ 1, 1, 1, 1 ] : [ 0.8, 0.8, 0.8, 0.8 ];
        var is_selected = this.space === this.selected_item;
        if (is_selected)
            color = [ 1,1,0.8,1 ];
        Label.call(GUI, x + 36, y+2, w - 40, size, "space", color );
        if (mouse_state === GLUI.CLICKED) //clicked (down and  up)
        {
            //double click
            if (editor.selected_item === this.space && GUI.last_noclick_time < 250 )
            {
                this.onEntityDoubleClicked(this.space);
                GUI.consumeClick();
            }
            else
                editor.onEntityClicked( this.space );
        }
    
        //show component icons on the right
        show_components(this.space, x + 30, y, w - 10 );
    
        y += line_height;
        var top_y = y;
        var item_y = y + this.side_scroll;
    
        //clip area for scroll
        ctx.save();
        ctx.beginPath();
        ctx.rect(x,y,w,list_height - 60);
        ctx.clip();
    
        //autoscroll
        if ( gl.mouse.buttons )
        {
            var mousepos = [ gl.mouse.mousex,gl.mouse.mousey ];
            if ( GUI.isInsideRect( mousepos, x,y,w,50,30) )
                this.side_scroll_target += 5;
            else if ( GUI.isInsideRect( mousepos, x,y+list_height-80,w,50,30) )
                this.side_scroll_target -= 5;
        }
    
        //display entities list
        var collections = space.root.getCollections();
        var collections_array = Object.keys(collections);
        var current_collection = null;
        if (collections_array.length === 0 || !this.show_colletions_list ) //render as list of entities
        {
            item_y = render_entity_array.call( this, this.space.root.children, item_y );
        }
        else //in case of collections: render grouped by collection
        {
            item_y = render_collections.call( this, this.space.root.children, item_y );
        }
    
        //remove clip
        ctx.restore();
    
        y += list_height - 20;
    
        //actions
        if(this.display_actions)
        {
            GUI.next_tooltip = "Sort Nodes";
            if ( Button.call(GUI, x + 10,y,30,30,[ 4,10 ], false, [ 0.1,0.1,0.1,0.9 ],null,10 ) )
                this.sortEntities();
        }
    
        //renders a group of entities
        function render_entity_array( entities, item_y, level )
        {
            level = level || 0;
    
            //show entities list
            for ( var i = 0; i < entities.length; i++ )
            {
                //out of the area
                if (item_y > (top_y + list_height - 40) )
                    break;
    
                //get entities list (from current group!)
                var entity = entities[i];
    
                //filter based on collections
                if (current_collection === "" )
                {
                    if ( entity.collection != null )
                        continue;
                }
                else if (current_collection != null && entity.collection !== current_collection )
                    continue;
    
                //filter based on search
                if ( this.filter )
                {
                    if (this.filter[0] === ".")
                    {
                        var comp_name = this.filter.substr(1);
                        var j = 0;
                        if (entity._components)
                        {
                            for (; j < entity._components.length; ++j)
                            {
                                var comp = entity._components[j];
                                if (comp.constructor.name.indexOf( comp_name ) !== -1 )
                                    break;
                            }
                            if (j === entity._components.length)
                                continue;
                        }
                    }
                    else if (entity.name.indexOf( this.filter ) === -1 )
                        continue;
                }
    
                if ( item_y < (top_y - line_height) )//&& 0) //out up
                {
                    item_y += line_height;
                    continue;
                }
    
                //render current entity
                var is_selected = this.isSelected( entity );
                item_y = render_entity.call( this, entity, item_y, is_selected, level );
    
                //recursive
                if ( entity.children && entity.children.length && !entity.collapsed)
                    item_y = render_entity_array.call( this, entity.children, item_y, level + 1 );
            }
    
            return item_y;
        }
    
        function render_collections( entities, item_y, level )
        {
            if (collections_array.indexOf("") === -1)
                collections_array.push("");
    
            for ( var j = 0; j < collections_array.length; ++j )
            {
                //render collection name
                current_collection = collections_array[j];
                var collection_collapsed = this.collapsed_collections[ current_collection ];
                if (GUI.DrawIcon( x + 10, item_y + 8, 0.3,[ collection_collapsed ? 4 : 5, 11 ], false, null, null, true ) === GLUI.CLICKED )
                {
                    collection_collapsed = !collection_collapsed;
                    this.collapsed_collections[ current_collection ] = collection_collapsed;
                }
                Label.call(GUI, x + 20, item_y, w, size, current_collection !== "" ? current_collection : "Default" );
                GUI.DropArea( x, item_y, w, size, onDropOnCollection.bind(this), current_collection );
                if ( Button.call(GUI,  x + w - size - 10, item_y, size, size, [ 15,1 ]) )
                {
                    this._options_collection = current_collection;
                    GUI.ShowContextMenu([ "Rename",null,"Clear" ],
                        {
                            callback: function(v,item)
                            {
                                if (item === "Rename")
                                {
                                    var r = prompt("Rename Collection to",that._options_collection);
                                    if (r)
                                        that.renameCollection( that._options_collection, r );
                                }
                                else if (item === "Clear")
                                    that.renameCollection( that._options_collection, null );
                            },
                            id: "icon_context_menu"
                        });
                }
                item_y += line_height;
    
                //render entities in that collection
                if ( !collection_collapsed )
                    item_y = render_entity_array.call( this, this.space.root.children, item_y );
            }			
        }
    
        //render the name of the entity and its icons ****************************
        function render_entity( entity, item_y, is_selected, level )
        {
            level = level || 0;
    
            var item_x = x + level * 20;
            var item_w = w - level * 20;
            var is_group = entity.children.length;
    
            var mouse_state = GUI.HoverArea(item_x + 10+ (is_group?30:0),item_y,item_w - 56 - (is_group?30:0),size, { entity: entity, callback: editor.onDragEntity.bind(this) } );
            var color = mouse_state !== GLUI.NONE ? [ 0.8, 0.8, 0.8, 1 ] : [ 0.8, 0.8, 0.8, 0.8 ];
            if (is_selected)
            {
                color = [ 1,1,0.8,1 ];
                ctx.fillColor = [ 0.3,0.3,0.4,0.5 ];
                ctx.fillRect(item_x + 5, item_y, item_w - 10, size);
            }
    
            //in case something is drop on to the entity
            GUI.DropArea( item_x + 10, item_y, item_w - 56, size, onDropOnEntity.bind(this), entity );
    
            if (mouse_state === GLUI.CLICKED) //clicked
            {
                //double click
                if (entity === this.selected_item && GUI.last_noclick_time < 250 && !gl.keys["SHIFT"] )
                {
                    this.onEntityDoubleClicked(entity);
                    GUI.consumeClick();
                }
                else
                    this.onEntityClicked(entity);
            }
            else if (mouse_state === GLUI.PRESSED) //holding name
            {
                this._last_pressed_entity = entity;
                //if( gl.keys["SHIFT"] )
                //	this.setSelection( entity, true );
                //if(!entity._is_selected)
                //	this.setSelection( entity, gl.keys["SHIFT"] );
            }
    
            //Label.call(GUI, x + w - 60, item_y, 40, size, String(entity.index));
    
            if ( Button.call(GUI, item_x + item_w - 30, item_y, size, size, entity.enabled ? [ 8,7 ] : [ 9,7 ] ) )
            {
                entity.enabled = !entity.enabled;
                this.autoSyncSelection();
            }
    
            if ( entity._components )
                show_components( entity, item_x, item_y, item_w );
    
            var icon = null;
            if ( is_group )
                icon = entity.collapsed ? [ 2,0 ] : [ 2,1 ];
    
            if (icon)
            {
                if (GUI.DrawIcon( item_x + 20 + size*0.5, item_y + size*0.5, 0.3, icon, false, is_group ? [ 0.7,0.9,1,0.7 ] : [ 1,1,1,0.5 ], null, is_group ) === GLUI.CLICKED )
                    entity.collapsed = !entity.collapsed;
            }
            if (entity.name)
            {
                if ( !entity.enabled )
                    color[3] *= 0.5;
                Label.call(GUI, item_x + 50, item_y + 2, item_w - 80, size, entity.name, color );
            }
    
            item_y += line_height;
            return item_y;
        }
        
        function show_components( entity, item_x, item_y, item_w )
        {
            //show components
            var compx = item_x + item_w - 30 - size - 2;
            for (var j = entity._components.length - 1; j >= 0; --j)
            {
                var comp = entity._components[j];
                var icon = comp.constructor.icon;
                if (!icon)
                    continue;
                ctx.globalAlpha = 0.4;
                GUI.DrawIcon(compx + size*0.5, item_y + size*0.5, 0.3, icon );
                if (GUI.HoverArea(compx,item_y,size,size) === GLUI.CLICKED )
                {
                    //that.mode = RoomEditor.PROPERTIES_MODE;
                    GUI.consumeClick();
                    that.visible_comp_index = j;
                    that.setSelection( entity, gl.keys["SHIFT"] );
                }
                ctx.globalAlpha = 1;
    
                if ( comp._warning )
                    GUI.DrawIcon(compx + size*0.5, item_y + size*0.5, 0.3, [ 13,1 ],false,[ 1,1,0.2,1 ]);
                compx -= size - 4;
            }
        }
    
        function onDropOnEntity(e,entity)
        {
            var data = e.dataTransfer.getData("type");
            if (data === "Entity")
            {
                var uid = e.dataTransfer.getData("uid");
                var dragged_entity = space.getEntityById(uid);
                if ( dragged_entity && dragged_entity != entity )
                {
                    if (dragged_entity._parent)
                        dragged_entity._parent.removeEntity(dragged_entity);
                    entity.addChild( dragged_entity );
                }
            }
        }
    
        function onDropOnCollection(e,collection)
        {
            var data = e.dataTransfer.getData("type");
            if (data === "Entity")
            {
                var uid = e.dataTransfer.getData("uid");
                var dragged_entity = space.getEntityById(uid);
                if ( dragged_entity )
                {
                    //dragged_entity.collection = collection ? collection : null;
                    that.assignCollectionToSelection( collection ? collection : null )
                }
            }
        }
    }

    onEntityClicked(entity){
        this.selected = entity;
        if(this.onSelectedEntity)
            this.onSelectedEntity(entity);
    }

    onEntityDoubleClicked(entity){
        
    }
    
    sortEntities() {
        this.space.root.children.sort( (a,b)=>a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    }

    isSelected( entity ) {
        return this.selected == entity;
    }
}

export default EntityListPanel;
