import { RoomController } from "@src/controllers/RoomController";
import Button from "@src/libs/GLUI/Elements/Button";

//To roam in predefined camera positions in a room, being called from showcase.html
export class RoomShowcaseRoom extends RoomController {
	constructor(launcher, space, view, settings ) {
		super();

		RoomShowcaseRoom.instance = this;

		this.xyz = this.launcher = launcher;
		this.name = "showcase";

		this.space = space;
		this.view = view;
		this.settings = settings;

		this._camera = this.view.camera;
		this._hard_camera = this.view.hard_camera;

		this._prefabs_loaded = false;

		this._entities = [];
		this._current_entity = null;
		this._current_entity_index = null;
		this._has_just_started_animating = false;

		this._showcase_texts_dom = null;
	}

	onEnter( _prev_controller ) {

		if ( this._prefabs_loaded === true )
		{
			this.setInitialProperties();
		}
		else
		{
			LEvent.bind( this.space, "prefabsLoaded", this.setInitialProperties, this );
		}
	}

	setInitialProperties() {
		//set Entities within the order in showcase-slides.html
		var that = this;
		var temp_entities = Object.values( this.space.entities_by_name ).filter( function ( entity )
		{
			return ( entity.hasComponent( "ShowcaseStep" ) &&
				entity.getComponent( "ShowcaseStep" ).enabled &&
				entity.getComponent( "ShowcaseStep" ).camera_state )
		} )

		this._showcase_texts_dom = document.getElementById( "showcase-texts" );

		Object.values( this._showcase_texts_dom.children ).forEach( function ( wrapper )
		{
			var entity_name = wrapper.getAttribute( "data-entity-name" );
			var entity = temp_entities.find( el => el.name === entity_name );
			if ( entity )
				that._entities.push( entity )
		} )

		this._current_entity_index = 0;
		this._current_entity = this._entities[ this._current_entity_index ];

		this.view.camera_smooth_factor = 0.05;

		if ( this._showcase_texts_dom )
			this._showcase_texts_dom.style = "";

		if ( this._prefabs_loaded === false )
		{
			this._prefabs_loaded = true;
			LEvent.unbind( this.space, "prefabsLoaded", this.setInitialProperties, this );
		}

		this.focusToEntity( "current" );
	}

	onUpdate( dt ) {
		this.space.update( dt );
	}

	onRender() {
		this.view.render();

		if ( this.settings.debug )
		{
			var w = gl.canvas.width;
			var h = gl.canvas.height;
			if ( Button.call(GUI,  w - 50, h - 50, 50, 50, [ 0, 5 ], false, [ 0.2, 0.2, 0.2, 0.9 ], [ 1, 1, 1 ], 6 ) )
			{
				this.launcher.setController( this.launcher.editor_controller );
				if ( this._showcase_texts_dom )
					this._showcase_texts_dom.style = "display:none;"
			}
		}
	}

	onMouse( e ) {

		var r = GUI.onMouse( e );
		if ( r || GUI.isPositionBlocked( e.canvasx, e.canvasy ) )
			return true;

		if ( !this._prefabs_loaded ) return;

		if ( !this._has_just_started_animating && e.eventType == "mousewheel" )
		{
			var direction = e.delta < 0 ? "next" : "previous"
			this.changeShowcaseEnitity( direction );
		}
	}

	onKeyDown( e ) {
		if ( !this._prefabs_loaded ) return;
		switch ( e.code )
		{
		case "ArrowRight":
		case "ArrowDown":
		case "KeyP":
			e.preventDefault(); //default arrow keys breaking scroll
			this.changeShowcaseEnitity( "next" );
			break;
		case "ArrowLeft":
		case "ArrowUp":
		case "KeyO":
			e.preventDefault();
			this.changeShowcaseEnitity( "previous" );
			break;
		}
	}

	changeShowcaseEnitity( direction ) {
		this.focusToEntity( direction )
		this._has_just_started_animating = true;
		setTimeout( () =>
		{
			this._has_just_started_animating = false;
		}, 250 );
	}

	//direction "next","previous" or "current"
	focusToEntity( direction ) {
		this.changeCurrentEntity( direction );
		this.updateCameraState();
		this.scrollIntoCurrentSlide();
	}

	//direction "next","previous" or "current"
	changeCurrentEntity( direction ) {
		if ( direction === "current" ) return;
		if ( direction === "next" )
		{
			this._current_entity_index += 1;
			if ( this._current_entity_index === this._entities.length ) this._current_entity_index = 0;
		}
		else if ( direction === "previous" )
		{
			this._current_entity_index -= 1;
			if ( this._current_entity_index < 0 ) this._current_entity_index = this._entities.length - 1;
		}
		this._current_entity = this._entities[ this._current_entity_index ]
	}

	updateCameraState() {
		var component = this._current_entity.getComponent( "ShowcaseStep" );
		this._hard_camera.configure( component.camera_state );
	}

	scrollIntoCurrentSlide() {
		var text_wrapper = document.querySelector( "[data-entity-name=\"" + this._current_entity.name + "\"] .text-wrapper" )
		if ( text_wrapper )
			text_wrapper.scrollIntoView( { behavior: "smooth", block: "center" } );
	}
}

RoomShowcaseRoom.instance = null;


