import { ROOM_VERSION } from "@src/engine/Room/ROOM_VERSION";

/**
 * check if one version is valid (not older) than the current
 * @param {string|number|string[]|number[]} a
 * @param {number[]} [b]
 */
export function checkVersion(a, b = ROOM_VERSION) {
	if (!a)
		return false;

	if (typeof a === "string") {
		a = a.split(".");
	}

	if (a[0] > b[0])
		return true;
	if (a[0] < b[0])
		return false;

	if (a[1] > b[1])
		return true;
	if (a[1] < b[1])
		return false;

	if (a[2] >= b[2])
		return true;
	return false;

}