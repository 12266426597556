import { GL } from "@src/libs/litegl";
import isPowerOfTwo from "@src/math/isPowerOfTwo";
import { mat3 } from "gl-matrix";

import HDREImage from "./HDREImage";
import { parse } from "./parse";
import CopyCubemapShaderFragment from "./Shaders/CopyCubemapShader.frag";
import FilterShaderFragment from "./Shaders/FilterShader.frag";
import FilterShaderVertex from "./Shaders/FilterShader.vert";
import LatLongShaderFragment from "./Shaders/LatLongShader.frag";
import SpheremapShaderFragment from "./Shaders/SpheremapShader.frag";

/**
 * This class creates HDRE from different sources
 * @class HDREBuilder
 */

function HDREBuilder(o) {

	if (this.constructor !== HDREBuilder) {
		throw ("You must use new to create a HDREBuilder");
	}

	this._ctor();

	if (o) {
		this.configure(o);
	}
}

HDREBuilder.prototype._ctor = function() {

	this.flip_Y_sides = true;
	this.pool = {};
	this.last_id = 0;
};

HDREBuilder.prototype.configure = function(_o) {

};

HDREBuilder.prototype.createImage = function(data, size) {

	if (!data) {
		throw ("[error] cannot create HDRE image");
	}

	var texture = null;
	var image = new HDREImage();

	//create gpu texture from file
	if (data.constructor !== GL.Texture) {
		texture = this.createTexture(data, size);
	} else {
		texture = data;
	}

	image.configure({
		version: 3.0,
		width: texture.width,
		height: texture.height,
		n_channels: texture.format === WebGLRenderingContext.RGB ? 3 : 4,
		bits_channel: texture.type === WebGLRenderingContext.FLOAT ? 32 : 8,
		texture: texture,
		id: this.last_id
	});

	this.pool[this.last_id++] = image;
	console.debug(this.pool);

	return image;
};

HDREBuilder.prototype.fromFile = function(buffer, options) {

	var image = parse(buffer, options);
	this.pool[this.last_id++] = image;
	console.debug(this.pool);

	if (options.callback) {
		options.callback(image);
	}

	return image;
};

HDREBuilder.prototype.fromHDR = function(filename, buffer, size) {

	var data, ext = filename.split(".").pop();

	switch (ext) {
	case "hdr":
		data = this._parseRadiance(buffer);
		break;

	case "exr":
		data = this._parseEXR(buffer);
		break;

	default:
		throw ("cannot parse hdr file");
	}

	//add HDRE image to the pool
	return this.createImage(data, size);
};

HDREBuilder.prototype.fromTexture = function(texture) {

	this.filter(texture, {
		oncomplete: (function(result) {

			this.createImage(result);

		}).bind(this)
	});
};

/**
 * Create a texture based in data received as input
 * @method CreateTexture
 * @param {Object} data
 * @param {Number} cubemap_size
 */
HDREBuilder.prototype.createTexture = function(data, cubemap_size, options) {
	if (!window.GL) {
		throw ("this function requires to use litegl.js");
	}

	if (!data) {
		throw ("No data to get texture");
	}

	options = options || {};

	var width = data.width,
		height = data.height;

	var is_cubemap = !!(width / 4 === height / 3 && isPowerOfTwo(width));

	var channels = data.numChannels;
	var pixelData = data.rgba;
	var pixelFormat = channels === 4 ? gl.RGBA : gl.RGB; // EXR and HDR files are written in 4

	if (!width || !height) {
		throw ("No width or height to generate Texture");
	}

	if (!pixelData) {
		throw ("No data to generate Texture");
	}

	var texture = null;

	var params = {
		format: pixelFormat,
		type: gl.FLOAT,
		pixel_data: pixelData
	};

	GL.Texture.disable_deprecated = true;

	// 1 image cross cubemap
	if (is_cubemap) {
		var square_length = pixelData.length / 12;
		var faces = parseFaces(square_length, width, height, pixelData);

		width /= 4;
		height /= 3;

		params.texture_type = gl.TEXTURE_CUBE_MAP;
		params.pixel_data = faces;

		texture = new GL.Texture(width, height, params);

		var temp = texture.clone();
		var shader = new GL.Shader(Shader.SCREEN_VERTEX_SHADER, CopyCubemapShaderFragment);

		//save state
		var current_fbo = gl.getParameter(gl.FRAMEBUFFER_BINDING);
		var viewport = gl.getViewport();
		var fb = gl.createFramebuffer();
		gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
		gl.viewport(0, 0, width, height);

		var mesh = Mesh.getScreenQuad();

		// Bind original texture
		texture.bind(0);
		mesh.bindBuffers(shader);
		shader.bind();

		var rot_matrix = GL.temp_mat3;
		var cams = GL.Texture.cubemap_camera_parameters;

		for (var i = 0; i < 6; i++) {
			gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, temp.handler, 0);
			var face_info = cams[i];

			mat3.identity(rot_matrix);
			rot_matrix.set(face_info.right, 0);
			rot_matrix.set(face_info.up, 3);
			rot_matrix.set(face_info.dir, 6);
			shader._setUniform("u_rotation", rot_matrix);
			shader._setUniform("u_flip", true);
			gl.drawArrays(gl.TRIANGLES, 0, 6);
		}

		mesh.unbindBuffers(shader);
		//restore previous state
		gl.setViewport(viewport); //restore viewport
		gl.bindFramebuffer(gl.FRAMEBUFFER, current_fbo); //restore fbo
		gl.bindTexture(temp.texture_type, null); //disable

		temp.is_cubemap = is_cubemap;
	}

	// basic texture or sphere map
	else {
		texture = new GL.Texture(width, height, params);
	}

	// texture properties
	texture.wrapS = gl.CLAMP_TO_EDGE;
	texture.wrapT = gl.CLAMP_TO_EDGE;
	texture.magFilter = gl.LINEAR;
	texture.minFilter = gl.LINEAR_MIPMAP_LINEAR;

	if (is_cubemap) {
		return temp;
	}

	if (!options.discard_spheremap) {
		gl.textures["tmp_spheremap"] = texture;
	}


	var result = this.toCubemap(texture, cubemap_size);
	GL.Texture.disable_deprecated = false;

	return result;
};

/**
 * Converts spheremap or panoramic map to a cubemap texture
 * @method ToCubemap
 * @param {Texture} tex
 * @param {Number} cubemap_size
 */
HDREBuilder.prototype.toCubemap = function(tex, cubemap_size) {
	var size = cubemap_size || this.CUBE_MAP_SIZE;
	if (!size) {
		throw ("CUBEMAP size not defined");
	}

	//save state
	var current_fbo = gl.getParameter(gl.FRAMEBUFFER_BINDING);
	var viewport = gl.getViewport();
	var fb = gl.createFramebuffer();
	gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
	gl.viewport(0, 0, size, size);

	var shader_type = (tex.width === tex.height * 2) ? LatLongShaderFragment : SpheremapShaderFragment;
	var shader = new GL.Shader(Shader.SCREEN_VERTEX_SHADER, shader_type);

	if (!shader) {
		throw ("No shader");
	}

	// Bind original texture
	tex.bind(0);
	var mesh = Mesh.getScreenQuad();
	mesh.bindBuffers(shader);
	shader.bind();

	var cubemap_texture = new GL.Texture(size, size, {
		format: tex.format,
		texture_type: gl.TEXTURE_CUBE_MAP,
		type: gl.FLOAT,
		minFilter: gl.LINEAR_MIPMAP_LINEAR
	});
	var rot_matrix = GL.temp_mat3;
	var cams = GL.Texture.cubemap_camera_parameters;

	for (var i = 0; i < 6; i++) {
		gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, cubemap_texture.handler, 0);
		var face_info = cams[i];

		mat3.identity(rot_matrix);
		rot_matrix.set(face_info.right, 0);
		rot_matrix.set(face_info.up, 3);
		rot_matrix.set(face_info.dir, 6);
		shader._setUniform("u_rotation", rot_matrix);
		gl.drawArrays(gl.TRIANGLES, 0, 6);
	}

	mesh.unbindBuffers(shader);

	//restore previous state
	gl.setViewport(viewport); //restore viewport
	gl.bindFramebuffer(gl.FRAMEBUFFER, current_fbo); //restore fbo
	gl.bindTexture(cubemap_texture.texture_type, null); //disable

	var pixels = cubemap_texture.getCubemapPixels();

	if (this.flip_Y_sides) {
		var tmp = pixels[2];
		pixels[2] = pixels[3];
		pixels[3] = tmp;
	}

	for (var f = 0; f < 6; ++f) {
		if (this.flip_Y_sides) {
			GL.Texture.flipYData(pixels[f], size, size, tex.format === GL.RGBA ? 4 : 3);
		}

		cubemap_texture.uploadData(pixels[f], { cubemap_face: f });
	}


	return cubemap_texture;
};

/**
 * Blurs each of the level of a given environment texture
 * @method Filter
 * @param {Texture} texture
 * @param {Object} options
 */
HDREBuilder.prototype.filter = function(texture, options) {

	if (!window.GL) {
		throw ("this function requires to use litegl.js");
	}

	var options = options || {};

	if (!texture) {
		throw ("no texture to filter");
	}

	var shader = new Shader(FilterShaderVertex, FilterShaderFragment);
	var mipCount = 5;

	//Reset Builder steps
	this.LOAD_STEPS = 0;
	this.CURRENT_STEP = 0;

	//Clean previous mipmap data
	texture.mipmap_data = {
		0: texture.getCubemapPixels()
	};

	// compute necessary steps
	for (var i = 1; i <= mipCount; ++i) {
		var faces = 6;
		var blocks = Math.min(texture.width / Math.pow(2, i), 8);
		this.LOAD_STEPS += faces * blocks;
	}

	GL.Texture.disable_deprecated = true;

	for (let mip = 1; mip <= mipCount; mip++) {
		this._blur(texture, mip, mipCount, shader, (function(result) {

			var pixels = result.getCubemapPixels();

			//data always comes in rgba when reading pixels from textures
			if (texture.format === GL.RGB) {
				for (var f = 0; f < 6; ++f)
					pixels[f] = _removeAlphaChannel(pixels[f]);
			}

			texture.mipmap_data[mip] = pixels;

			for (var f = 0; f < 6; ++f)
				texture.uploadData(pixels[f], { cubemap_face: f, mipmap_level: mip }, true);

			if (this.CURRENT_STEP === this.LOAD_STEPS) {
				texture.data = null;

				// format is stored different when reading hdre files!!
				if (options.image_id) {
					this.pool[options.image_id].data = texture.mipmap_data;
				}

				if (options.oncomplete) {
					options.oncomplete(texture);
				}

				GL.Texture.disable_deprecated = false;
			}

		}).bind(this));
	}
};

/**
 * Blurs a texture calling different draws from data
 * @method blur
 * @param {Texture} input
 * @param {Number} level
 * @param {Shader||String} shader
 */
HDREBuilder.prototype._blur = function(input, level, mipCount, shader, oncomplete) {
	var data = this._getDrawData(input, level, mipCount);

	if (!data) {
		throw ("no data to blur");
	}

	// var channels =

	var options = {
		format: input.format, //gl.RGBA,
		type: gl.FLOAT,
		minFilter: gl.LINEAR_MIPMAP_LINEAR,
		texture_type: gl.TEXTURE_CUBE_MAP
	};

	var result = new GL.Texture(data.size, data.size, options);
	var current_draw = 0;

	//save state
	var current_fbo = gl.getParameter(gl.FRAMEBUFFER_BINDING);
	var viewport = gl.getViewport();

	var fb = gl.createFramebuffer();
	var mesh = GL.Mesh.getScreenQuad();

	var inner_blur = function() {

		const drawInfo = data.draws[current_draw];
		drawInfo.uniforms["u_mipCount"] = mipCount;
		drawInfo.uniforms["u_emsize"] = input.width;

		if (!shader) {
			throw ("No shader");
		}

		// bind blur fb each time
		gl.bindFramebuffer(gl.FRAMEBUFFER, fb);

		input.bind(0);
		shader.bind();
		mesh.bindBuffers(shader);

		gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_CUBE_MAP_POSITIVE_X + drawInfo.face, result.handler, 0);
		gl.viewport(drawInfo.viewport[0], drawInfo.viewport[1], drawInfo.viewport[2], drawInfo.viewport[3]);

		shader.uniforms(drawInfo.uniforms);
		gl.drawArrays(gl.TRIANGLES, 0, 6);

		mesh.unbindBuffers(shader);
		input.unbind();

		//restore previous state each draw
		gl.setViewport(viewport); //restore viewport
		gl.bindFramebuffer(gl.FRAMEBUFFER, current_fbo); //restore fbo
		gl.bindTexture(result.texture_type, null);
	};

	var that = this;

	var interval = setInterval(function() {

		inner_blur();
		current_draw++;

		that.CURRENT_STEP++;

		if (current_draw === data.draws.length) {
			clearInterval(interval);

			if (oncomplete) {
				oncomplete(result);
			}
		}
	}, 100);
};

/**
 * Gets info to blur in later pass
 * @method getDrawData
 * @param {Texture} input
 * @param {Number} level
 * @param {Shader} shader
 */
HDREBuilder.prototype._getDrawData = function(input, level, mipCount) {
	var blocks = 8;

	var size = input.height; // by default
	size /= Math.pow(2, level);

	// Recompute number of blocks
	blocks = Math.min(blocks, size);

	var totalLevels = mipCount;
	var roughness = (level + 1) / (totalLevels + 1);

	var deferredInfo = {};

	var cams = GL.Texture.cubemap_camera_parameters;
	var cubemap_cameras = [];
	var draws = [];

	for (const c in cams) {

		const face_info = cams[c];
		const rot_matrix = mat3.create();
		mat3.identity(rot_matrix);
		rot_matrix.set(face_info.right, 0);
		rot_matrix.set(face_info.up, 3);
		rot_matrix.set(face_info.dir, 6);
		cubemap_cameras.push(rot_matrix);
	}

	cubemap_cameras = GL.linearizeArray(cubemap_cameras);

	for (var i = 0; i < 6; i++) {
		var face_info = cams[i];

		const rot_matrix = mat3.create();
		mat3.identity(rot_matrix);
		rot_matrix.set(face_info.right, 0);
		rot_matrix.set(face_info.up, 3);
		rot_matrix.set(face_info.dir, 6);

		for (var j = 0; j < blocks; j++) {
			const uniforms = {
				"u_rotation": rot_matrix,
				"u_blocks": blocks,
				"u_mipCount": mipCount,
				"u_roughness": roughness,
				"u_ioffset": j * (1 / blocks),
				"u_cameras": cubemap_cameras,
				"u_color_texture": 0
			};

			const blockSize = size / blocks;

			draws.push({
				uniforms: uniforms,
				viewport: [ j * blockSize, 0, blockSize, size ],
				face: i
			});
		}
	}

	deferredInfo["blocks"] = blocks;
	deferredInfo["draws"] = draws;
	deferredInfo["size"] = size;
	deferredInfo["roughness"] = roughness;
	deferredInfo["level"] = level;

	return deferredInfo;
};

/**
 * Parse the input data and get all the EXR info
 * @method _parseEXR
 * @param {ArrayBuffer} buffer
 */
HDREBuilder.prototype._parseEXR = function(buffer) {
	if (!Module.EXRLoader) {
		console.error("[cannot parse exr file] this function needs tinyexr.js to work");
	}

	function parseNullTerminatedString(buffer, offset) {

		var uintBuffer = new Uint8Array(buffer);
		var endOffset = 0;

		while (uintBuffer[offset.value + endOffset] !== 0)
			endOffset += 1;

		var stringValue = new TextDecoder().decode(
			new Uint8Array(buffer).slice(offset.value, offset.value + endOffset)
		);

		offset.value += (endOffset + 1);

		return stringValue;

	}

	function parseFixedLengthString(buffer, offset, size) {

		var stringValue = new TextDecoder().decode(
			new Uint8Array(buffer).slice(offset.value, offset.value + size)
		);

		offset.value += size;

		return stringValue;

	}


	function parseUint32(buffer, offset) {

		var Uint32 = new DataView(buffer.slice(offset.value, offset.value + 4)).getUint32(0, true);
		offset.value += 4;
		return Uint32;
	}

	function parseUint8(buffer, offset) {

		var Uint8 = new DataView(buffer.slice(offset.value, offset.value + 1)).getUint8(0, true);
		offset.value += 1;
		return Uint8;
	}

	function parseFloat32(buffer, offset) {

		var float = new DataView(buffer.slice(offset.value, offset.value + 4)).getFloat32(0, true);
		offset.value += 4;
		return float;
	}

	function parseUint16(buffer, offset) {

		var Uint16 = new DataView(buffer.slice(offset.value, offset.value + 2)).getUint16(0, true);
		offset.value += 2;
		return Uint16;
	}

	function parseChlist(buffer, offset, size) {

		var startOffset = offset.value;
		var channels = [];

		while (offset.value < (startOffset + size - 1)) {

			var name = parseNullTerminatedString(buffer, offset);
			var pixelType = parseUint32(buffer, offset); // TODO: Cast this to UINT, HALF or FLOAT
			var pLinear = parseUint8(buffer, offset);
			offset.value += 3; // reserved, three chars
			var xSampling = parseUint32(buffer, offset);
			var ySampling = parseUint32(buffer, offset);

			channels.push({
				name: name,
				pixelType: pixelType,
				pLinear: pLinear,
				xSampling: xSampling,
				ySampling: ySampling
			});
		}

		offset.value += 1;

		return channels;
	}

	function parseChromaticities(buffer, offset) {

		var redX = parseFloat32(buffer, offset);
		var redY = parseFloat32(buffer, offset);
		var greenX = parseFloat32(buffer, offset);
		var greenY = parseFloat32(buffer, offset);
		var blueX = parseFloat32(buffer, offset);
		var blueY = parseFloat32(buffer, offset);
		var whiteX = parseFloat32(buffer, offset);
		var whiteY = parseFloat32(buffer, offset);

		return { redX: redX, redY: redY, greenX, greenY, blueX, blueY, whiteX, whiteY };
	}

	function parseCompression(buffer, offset) {

		var compressionCodes = [
			"NO_COMPRESSION",
			"RLE_COMPRESSION",
			"ZIPS_COMPRESSION",
			"ZIP_COMPRESSION",
			"PIZ_COMPRESSION"
		];

		var compression = parseUint8(buffer, offset);

		return compressionCodes[compression];

	}

	function parseBox2i(buffer, offset) {

		var xMin = parseUint32(buffer, offset);
		var yMin = parseUint32(buffer, offset);
		var xMax = parseUint32(buffer, offset);
		var yMax = parseUint32(buffer, offset);

		return { xMin: xMin, yMin: yMin, xMax: xMax, yMax: yMax };
	}

	function parseLineOrder(buffer, offset) {

		var lineOrders = [
			"INCREASING_Y"
		];

		var lineOrder = parseUint8(buffer, offset);

		return lineOrders[lineOrder];
	}

	function parseV2f(buffer, offset) {

		var x = parseFloat32(buffer, offset);
		var y = parseFloat32(buffer, offset);

		return [ x, y ];
	}

	function parseValue(buffer, offset, type, size) {

		if (type === "string" || type === "iccProfile") {
			return parseFixedLengthString(buffer, offset, size);
		} else if (type === "chlist") {
			return parseChlist(buffer, offset, size);
		} else if (type === "chromaticities") {
			return parseChromaticities(buffer, offset);
		} else if (type === "compression") {
			return parseCompression(buffer, offset);
		} else if (type === "box2i") {
			return parseBox2i(buffer, offset);
		} else if (type === "lineOrder") {
			return parseLineOrder(buffer, offset);
		} else if (type === "float") {
			return parseFloat32(buffer, offset);
		} else if (type === "v2f") {
			return parseV2f(buffer, offset);
		}
	}

	var EXRHeader = {};

	// Start parsing header
	var offset = { value: 8 };
	var keepReading = true;

	// clone buffer
	buffer = buffer.slice(0);

	while (keepReading) {
		var attributeName = parseNullTerminatedString(buffer, offset);

		if (attributeName === 0) {
			keepReading = false;
		} else {
			var attributeType = parseNullTerminatedString(buffer, offset);
			var attributeSize = parseUint32(buffer, offset);
			var attributeValue = parseValue(buffer, offset, attributeType, attributeSize);
			EXRHeader[attributeName] = attributeValue;
		}
	}

	if (EXRHeader.compression === undefined) {
		throw "EXR compression is undefined";
	}

	var width = EXRHeader.dataWindow.xMax - EXRHeader.dataWindow.xMin + 1;
	var height = EXRHeader.dataWindow.yMax - EXRHeader.dataWindow.yMin + 1;
	var numChannels = EXRHeader.channels.length;

	var byteArray;

	// get all content from the exr
	try {
		var data = new Uint8Array(buffer);
		var exr = new Module.EXRLoader(data);

		if (exr.ok()) {
			byteArray = exr.getBytes();
		} else {
			throw ("Error getting bytes from EXR file");
		}

	} catch (error) {
		console.error(error);
	}

	return {
		header: EXRHeader,
		width: width,
		height: height,
		rgba: byteArray,
		numChannels: numChannels
	};
};

/**
 * Parse the input data and get all the HDR (radiance file) info
 * @method parseRadiance
 * @param {ArrayBuffer} buffer
 */
HDREBuilder.prototype._parseRadiance = function(buffer) {
	if (!parseHdr) {
		console.error("[cannot parse hdr file] this function needs hdr-parser.js to work");
	}

	var img = parseHdr(buffer);

	return {
		header: null,
		width: img.shape[0],
		height: img.shape[1],
		rgba: img.data,
		numChannels: img.data.length / (img.shape[0] * img.shape[1])
	};
};

export default HDREBuilder;
