import { quat, vec3 } from "gl-matrix";

import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";


function ParticlesObject()
{
	this._enabled = true;
	this._on_focus = false;

	this._preset = "Smoke";
	this._url = null;
	this._curUrl = null;

	this._native = null;
	this._dirty = false;
	this._dirtyUrl = false;
	this._pause = false;

	this._color = vec3.fromValues(0, 0, 0);
	this._vector = vec3.fromValues(0, 0, 0);

	if (xyz.native_mode)
		this._pb = TmrwModule.NativeRoomAPI.ParticlesBuilder();
	else
		this._pb = null;

	this._index = ParticlesObject.last_index++;
}

ParticlesObject.componentName = "ParticlesObject";
ParticlesObject.last_index = 1;

ParticlesObject.icon = [ 6,3 ];

ParticlesObject.widgets = {
	"preset": { type: "enum", values: [ "Smoke", "Fire", "Campfire", "Campfire-Heat", "Curly-Smoke", "Falling-Smoke", "Plum-Smoke", "Puffy-Smoke", "Fire-Smoke", "Smoke-1", "Smoke-2", "Smoke-3", "Spiral", "Spiral-Blue", "Ribbons-Rotation", "Ribbons", "Lights", "Dust", "Rain", "Snow", "Leaves", "Lightning", "Fireworks", "NewYear" ] },
	url: { type: "asset", extensions: "pfx" },
	"Restart": { type: "button", title: "Restart" },
	"Reload": { type: "button", title: "Reload" },
}

ParticlesObject.prototype.Restart = function () {
	if (!this._pb)
		return;
	this._pb.restart(this._native);
}

ParticlesObject.prototype.Reload = function () {
	if (!this._pb)
		return;
	this._pb.reload(this._native);
}


Object.defineProperty(ParticlesObject.prototype, "enabled", {
	get: function () {
		return this._enabled;
	},
	set: function (v) {
		if (this._enabled == v)
			return;
		this._dirty = true;
		this._enabled = v;
	}
});

Object.defineProperty(ParticlesObject.prototype, "preset", {
	get: function () {
		return this._preset;
	},
	set: function (v) {
		if (this._preset == v)
			return;
		this._dirty = true;
		this._preset = v;
		this._url = null;
	}
});

Object.defineProperty(ParticlesObject.prototype, "url", {
	get: function () {
		return this._url;
	},
	set: function (v) {
		if (v != null)
			v = v.trim();
		if (v == this._url)
			return;
		this._url = v;
		this._dirtyUrl = true;
	}
});

ParticlesObject.prototype.getUrl = function () {
	if (this._url && this._url != "")
		return this._url;

	var particleName = null;
	switch (this._preset) {
	case "Smoke":
		particleName = "particles/smoke.pfx";
		break;
	case "Fire":
		particleName = "particles/fire.pfx";
		break;
	case "Campfire":
		particleName = "particles/campfire.pfx";
		break;
	case "Campfire-Heat":
		particleName = "particles/campfire_heat.pfx";
		break;
	case "Curly-Smoke":
		particleName = "particles/test_curly_smoke.pfx";
		break;
	case "Falling-Smoke":
		particleName = "particles/test_smoke_falling.pfx";
		break;
	case "Plum-Smoke":
		particleName = "particles/test_smoke_plum.pfx";
		break;
	case "Puffy-Smoke":
		particleName = "particles/test_smoke_puffy.pfx";
		break;
	case "Fire-Smoke":
		particleName = "particles/test_smoke_fire.pfx";
		break;
	case "Smoke-1":
		particleName = "particles/test_smoke1.pfx";
		break;
	case "Smoke-2":
		particleName = "particles/test_smoke4.pfx";
		break;
	case "Smoke-3":
		particleName = "particles/test_smoke7.pfx";
		break;
	case "Spiral":
		particleName = "particles/spiral.pfx";
		break;
	case "Spiral-Blue":
		particleName = "particles/spiral_bl.pfx";
		break;
	case "Ribbons-Rotation":
		particleName = "particles/3d_rotation.pfx";
		break;
	case "Ribbons":
		particleName = "particles/ribbons.pfx";
		break;
	case "Lights":
		particleName = "particles/lights.pfx";
		break;
	case "Dust":
		particleName = "particles/dust.pfx";
		break;
	case "Rain":
		particleName = "particles/rain.pfx";
		break;
	case "Snow":
		particleName = "particles/snow.pfx";
		break;
	case "Leaves":
		particleName = "particles/leaves.pfx";
		break;
	case "Lightning":
		particleName = "particles/forked_lightning.pfx";
		break;
	case "Fireworks":
		particleName = "particles/fireworks.pfx";
		break;
	case "NewYear":
		particleName = "particles/new_year.pfx";
		break;
	}

	return particleName;
}

ParticlesObject.prototype.onAdded = function (parent) {
	LEvent.bind(parent, "clicked", this.onClicked, this);

	this._node = parent.node;
}

//called from PreRender
ParticlesObject.prototype.createNative = function () {
	if (this._root && nativeEngine && !this._native) {
		if (!this._root._native) {
			// Andrey FIXME: Hack to place new particles entity in front of camera
			// Btw for some reason this camera position doesn't changed when we fly in the editor mode!
			var camera = ViewCore.instance.hard_camera;
			var cam_pos = camera.position;
			var cam_front = camera.getFront();
			var dist = 1.0;

			var offset = vec3.scale(vec3.create(), cam_front, dist);
			vec3.add(this._node.position, cam_pos, offset);
			this._node.mustUpdate = true;

			this._root.createNativeEntity(this._root.index);
		}

		var url = this.getUrl();

		if (url && this._pb && !this._native) {
			// Creates native entity particles component
			this._pb.nameFX(url);
			this._pb.position(this._node.position);
			this._pb.rotation(this._node.rotation);
			this._pb.build(this._root._native);

			this._native = this._root._native;
			this._dirty = false;
		}
	}
}

ParticlesObject.prototype.onRenderExtraInspector = function (ctx, x, y, w, h, editor) {
	if (!this._pb)
		return;
	var sidewidth = 310;
	if (Button.call(GUI, x, y, sidewidth, 24, this._pause ? "Run" : "Pause")) {
		this._pause = this._pause ? false : true;
		this._pb.pause(this._native, this._pause);
	}
	y += 40;

	var numAtts = this._pb.getNumAttributes(this._native);
	if (numAtts < 1)
		return;

	var line_height = 28;

	Label.call(GUI, x, y, 140, line_height, "Attributes:");
	y += 30;

	for (var a = 0; a < numAtts; a++) {
		var attr = this._pb.getAttribute(this._native, a);
		Label.call(GUI, x + 15, y, 100, 24, attr.name);
		switch (attr.type) {
			case 0:
				attr.asBoolean = GUI.Toggle(120, y, sidewidth - 120, 24, null, attr.asBoolean);
				y += line_height;
				break;
			case 1:
				attr.asInt = GUI.SliderVal(120, y, sidewidth - 120, 24, attr.asInt, attr.minInt, attr.maxInt, 1);
				y += line_height;
				break;
			case 2:
				attr.asFloat = GUI.SliderVal(120, y, sidewidth - 120, 24, attr.asFloat, attr.minFloat, attr.maxFloat);
				y += line_height;
				break;
			case 3:
				GUI.Color3(x + 120, current_y, 40, 24, this._color);
				GUI.Vector(x + 160, current_y, 180, 24, this._color);
				attr.asColor = this._color;
				y += line_height;
				break;
			case 4:
				GUI.Vector(x + 120, current_y, 180, 24, this._vector);
				attr.asVector3 = this._vector;
				y += line_height;
				break;
			case 5:
				// How to edit the string?
				y += line_height;
				break;
		}
		this._pb.setAttribute(this._native, a, attr);
  }
}

ParticlesObject.prototype.onRemoved = function(parent)
{
	LEvent.unbind( parent,"clicked", this.onClicked, this );

	this._node = null;
	if (this._native) {
		//nativeEngine._room.destroyEntityObj(this._native);
		this._native = null;
	}
	if (this._pb)
		this._pb.delete();
	this._pb = null;
}

ParticlesObject.prototype.serialize = function(o)
{
	o.enabled = this._enabled;
	o.url = this._url;
	o.preset = this._preset;

	if (!this._pb)
		return;

	var numAtts = this._pb.getNumAttributes(this._native);
	if (numAtts < 1)
		return;

	o.attributes = [];
	for (var i = 0; i < numAtts; ++i) {
		var attr = this._pb.getAttribute(this._native, i);

		var a = {};
		a.name = attr.name;
		a.type = attr.type;
		a.scope = attr.scope;

		switch (attr.type) {
			case 0:
				a.asBoolean = attr.asBoolean;
				break;
			case 1:
				a.asInt = attr.asInt;
				break;
			case 2:
				a.asFloat = attr.asFloat;
				break;
			case 3:
				a.asColor = attr.asColor;
				break;
			case 4:
				a.asVector3 = attr.asVector3;
				break;
			case 5:
				a.asString = attr.asString;
				break;
		}

		o.attributes.push(a);
	}
}

ParticlesObject.prototype.configure = function(o)
{
	this._enabled = o.enabled;
	this._url = o.url;
	this._preset = o.preset;

	this._atts = o.attributes;

	this._index = ParticlesObject.last_index++;
}

ParticlesObject.prototype.onClicked = function(e)
{
	//console.debug("clicked",this.entity.name);
	if (!this._on_focus)
		this.enterFocus();
	else //leaving
		this.exitFocus();
}


ParticlesObject.prototype.preRender = function(view)
{
	if (!this._native) {
		if (xyz.native_json_loaded)
			this.createNative();
	}
	if (!this._native) {
		return;
	}

	var n = this._native.getRootNode();
	if (this._dirtyUrl) {
		if (this._url.includes(".pfx")) {
			this._dirty = true;
			this._dirtyUrl = false;
		}
	}

	if (this._atts && this._pb) {
		var numAtts = this._pb.getNumAttributes(this._native);
		if (numAtts > 0) {
			// Update serialized attributes

			for (var i = 0; i < this._atts.length; i++) {
				var a = this._atts[i];
				var attr = this._pb.getAttributeByName(this._native, a.name);
				if (attr && attr.name != "") {
					switch (attr.type) {
						case 0:
							attr.asBoolean = a.asBoolean;
							break;
						case 1:
							attr.asInt = a.asInt;
							break;
						case 2:
							attr.asFloat = a.asFloat;
							break;
						case 3:
							attr.asColor = a.asColor;
							break;
						case 4:
							attr.asVector3 = a.asVector3;
							break;
						case 5:
							attr.asString = a.asString;
							break;
					}
					this._pb.setAttributeByName(this._native, a.name, attr);
				}
			}
			this._atts = null;
		}
	}

	if (!vec3.equals(n.position, this._node.position) ||
		!vec3.equals(n.scaling, this._node.scaling) ||
		!quat.equals(n.rotation, this._node.rotation) ||
		this._dirty) {

		this._dirty = false;

		var url = this.getUrl();

		if (url) {
			this._native.setUrl(url);
			this._native.setScale(this._node.scaling);
			this._native.setPosition(this._node.position);
			this._native.setRotation(this._node.rotation);
		}
	}
}


ParticlesObject.prototype.enterFocus = function()
{
	if (this._on_focus)
		return;

	LEvent.bind( this.space, "keydown", this.onKey, this );
	LEvent.bind( this.space, "mouse", this.onGlobalMouse, this );

	//compute radius
	var original_pos = this.entity.node.getGlobalPosition();
	var bb = node.updateBoundingBox();
	var center = BBox.getCenter(bb);
	var halfsize = BBox.getHalfsize(bb);
	vec3.mul( halfsize, halfsize, node.scaling );
	var radius = vec3.length( halfsize );// / this.entity.node.scaling[0];
	console.debug(radius);
	radius *= 1.5;
}

ParticlesObject.prototype.exitFocus = function()
{
	if (!this._on_focus)
		return;

	LEvent.unbind(this.space, "keydown", this.onKey, this);
	LEvent.unbind(this.space, "mouse", this.onGlobalMouse, this);

	this._on_focus = false;
}

ParticlesObject.prototype.onKey = function(type,e)
{
	if (e.key == "Escape")
	{
		this.exitFocus();
	}
	return true;
}

ParticlesObject.prototype.onGlobalMouse = function(type,e)
{
	//console.debug(e);
	if (!this._on_focus)
		return;

	var speed = 0.02;

	if ( e.dragging )
	{
		//var node = this.entity.node;
		//var node = this._inspect_node;
		//var local_up = node.globalVectorToLocal( [0,1,0] );
		//var local_right = node.globalVectorToLocal( [1,0,0] );
		//node.rotate( e.deltax * speed, local_up );
		//node.rotate( e.deltay * speed, local_right );
	}

	if ( e.dragging && e.type == "mousemove")
		return true;
}

export default ParticlesObject;

