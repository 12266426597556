import { vec3, vec4 } from 'gl-matrix';
export const defaultColorConfig = {
    rail: "rgba(0, 0, 0, 102)",
    progress: "white",
    marker: vec4.fromValues(0.5, 0.5, 0.5, 0.5),
    ball: {
        default: vec3.fromValues(0.4, 0.4, 0.4),
        hover: vec3.fromValues(0.5, 0.5, 0.5),
    },
    label: {
        background: vec3.fromValues(0.3, 0.3, 0.3),
        text: vec3.fromValues(0.8, 0.8, 0.8),
        font: "Arial",
    }
};
