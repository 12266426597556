/**
 * Tells if one number is power of two (used for textures)
 *
 * @method isPowerOfTwo
 * @param {number} v
 * @return {boolean}
 */
const isPowerOfTwo = function(v) {
	return (Math.log(v) / Math.log(2)) % 1 === 0;
};

export default isPowerOfTwo;
