import ROOM from "@src/engine/room";
import { ActionListener } from "@src/controllers/components/actionListener";
import { LEvent } from "@src/libs/LEvent";

function LGraphSignal()
{
	this.properties = {
		signal_type:"",
        payload:"",
        local: false //if true the signal also gets dispatched locally
	};
    this.addInput("signal",LiteGraph.EVENT);
    this.addInput("payload","string");
	this.addOutput("signal",LiteGraph.EVENT);
    this.addOutput("payload","string");
    this.addWidget("text","type", "", "signal_type" );
}

LGraphSignal.title = "Signal";
LGraphSignal.desc = "send signal to other users";
LGraphSignal.highlight_color = "#CCC";

LGraphSignal.prototype.onAdded = function()
{
    LEvent.bind(xyz.space, "signal", this.onSignal, this);
}

LGraphSignal.prototype.onRemoved = function()
{
    LEvent.unbind(xyz.space, "signal", this.onSignal, this);
}

LGraphSignal.prototype.onSignal = function(type, event)
{
    console.log(event);
    if(event.signal == this.properties.signal_type)
    {
        this.setOutputData(1,this.properties.payload);
        this.triggerSlot(0);
    }
}

LGraphSignal.prototype.onAction = function(evt)
{
    if (this.isInputConnected(1))
        this.properties.payload = this.getInputData(1) || "";

    var msg = {
        type:"action",
        action:"signal",
        signal: this.properties.signal_type,
        payload: this.properties.payload
    };
    
    //send
    if( xyz.space )
        xyz.space.sendMessage(msg);

    if(this.properties.local)
        this.onSignal("signal",msg);
}

LGraphSignal.prototype.onDrawBackground = function(ctx)
{
}

LGraphSignal.prototype.onExecute = function()
{
    if (this.isInputConnected(1))
        this.properties.payload = this.getInputData(1) || "";
    this.setOutputData(1,this.properties.payload);
}

ActionListener.registerAction("signal",function( evt ){
    LEvent.trigger( xyz.space, "signal", evt ); 
});

export default LGraphSignal;

