import Entity from "@src/engine/entity";
import { getObjectClassName } from "@src/engine/Room/getObjectClassName";
import Button from "@src/libs/GLUI/Elements/Button";
import { RD } from "@src/libs/rendeer";
import Timeline from "@src/libs/timeline";

function TimelineWidget( panel, editor, space )
{
	this.name = "Timeline";

	this.panel = panel;
	this.editor = editor;
	this.space = space;

	const that = this;
	this.timeline = new Timeline();
	this.timeline.onSetTime = function(v) {
		that.space.setTime( v );
		that.anim_time = v;
	}

	this.timeline.onDrawContent = this.onDrawTimelineContent.bind(this);
}

TimelineWidget.prototype.draw = function(ctx,x,y,w,h)
{
	if (!this.timeline)
	{
		if ( Button.call(GUI,x,y,100,24,"Create Animation") )
			this.space.animation = new RD.Animation();
		return;
	}
	const space = this.space;
	this.timeline.draw( gl, space.animation, space.anim_time, [ x,y+10,w,h-10 ] );
}

TimelineWidget.prototype.processMouse = function(e)
{
	return this.timeline.processMouse(e);
}

TimelineWidget.prototype.onDrawTimelineContent = function( ctx, time_start, time_end, timeline )
{
	const animation = this.space.animation;
	if (!animation)
		return null;

	const track_height = 24;
	let y = 0;
	let index = 0;
	for (let i = 0; i < animation.tracks.length; ++i)
	{
		const track = animation.tracks[i];
		if ( track.hidden )
			continue;
		const target_name = track.target_node;
		let target = null;
		const target_info = this.space.resolveLocator(target_name);

		let title = null;
		if (target_info)
		{
			target = target_info.target;
			if ( target.constructor === Entity )
				title = target.name;
			else if ( target.constructor.is_component)
				title = target.entity.name + "/" + getObjectClassName( target );
			else
				title = target_info.name || "[Object]";
		}
		else
			title = "[not found]";
		const subtitle = track.target_property;

		timeline.drawTrackWithKeyframes( ctx, y, track_height, title, subtitle, track, index, target, on_click );

		y += track_height;
		index++;
		if ( y > ctx.canvas.height )
			break;
	}

	function on_click( track, event, timeline, pos )
	{
		if ( pos[0] < 30 )
			track.enabled = !track.enabled;
	}
}

export default TimelineWidget;
