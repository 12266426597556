import { mat3, vec3 } from "gl-matrix";

import PrefabRenderer from "@src/engine/components/prefab";
import { ViewCore } from "@src/engine/render/view";
import ROOM from "@src/engine/room";
import { getFullPath } from "@src/engine/Room/file-utils";
import { getExtension } from "@src/engine/Room/getExtension";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { GL } from "@src/libs/litegl";
import { Material } from "@src/libs/rendeer/Material";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import clamp from "@src/math/clamp";
import remap from "@src/math/remap";

import StreamFxFs from "./Avatar/StreamFx.fs.glsl";
import StreamHolohFs from "./Avatar/StreamHoloh.fs.glsl";
import StreamHolohVs from "./Avatar/StreamHoloh.vs.glsl";

/**
 *
 * @param {RoomParticipant} participant
 * @constructor
 *
 * @description
 * in charge of representing a user inside the 3D space
 * rendering the cutout in space, ball
 * The orientation is handled by Participant
 */
function RoomAvatar(participant) {
	this.participant = participant;

	this.visible = true; //change this to hide avatar
	this.mirrored = false;

	//how must be displayed
	this.mode = RoomAvatar.AUTO; //WIP

	this.material = null; //material to apply

	this.node = null; //node at floor level
	this.pivot_node = null; //eyes level, no scale
	this.feed_node = null; //node showing the face (has scale)
	this.nametag_node = null;
	this.selectable_node = null; //the one for mouse raypicking

	this._texture = null; //contains the final texture displayed
	this._feed_options = null;

	this._base_mesh = null; //used to display the sphere when walking
	this.showGhostImage = false; // used to prevent shape image on tutorial "fake" participant

	this.init(); //create some nodes and material
}

window.RoomAvatar = RoomAvatar;

RoomAvatar.AUTO = 0;
RoomAvatar.PICTURE = 1;
RoomAvatar.FEED_2D = 2;
RoomAvatar.FEED_3D = 3;
RoomAvatar.GHOST = 4;
RoomAvatar.CHARACTER = 10;

RoomAvatar.foot_mesh = null; //"meshes/roomba_sphere_noanim_shadow.glb";
RoomAvatar.profile_mesh = "plane";

RoomAvatar.show_basemesh = true;

RoomAvatar.preload_textures = [
	"stream_mask.png",
	"stream_mask_circle.png",
	"stream_mask_circle2.png",
	"stream_mask_rounded.png",
	"stream_mask_gradient.png",
];

RoomAvatar.prototype.wasVisible = function () {
	return this.feed_node.visible;
};

RoomAvatar.prototype.init = function () {
	//root node at floor level
	this.node = new SceneNode();
	this.node.name = "avatar_root";
	this.participant.node.addChild(this.node);

	//node at eyes height
	this.pivot_node = new SceneNode();
	this.pivot_node.name = "avatar_pivot";
	this.node.addChild(this.pivot_node);

	//node to display the feed
	this.feed_node = new SceneNode();
	this.feed_node.name = "feed";
	this.pivot_node.addChild(this.feed_node);
	this.feed_node.mustSync(true);

	//avatar material
	this.material = new Material(RoomAvatar.feed_material_info);
	this.material.name = "profile_" + this.participant.index;
	StaticMaterialsTable[this.material.name] = this.material;

	if (GL.ctx.meshes["circle"] === undefined) {
		// required for selection
		GL.ctx.meshes["circle"] = GL.Mesh.circle();
	}

	//node that can be clicked to select a user
	const node = (this.selectable_node = new SceneNode({
		//hide the selectable circle. Change to true to make visible the halo
		visible: false,
		position: [ 0, 0, -0.002 ],
		scale: [ 0.25, 0.5, 1 ],
		name: "selectable_avatar",
		mesh: "circle",
		material: "profile_selectable",
	}));
	node.flags.two_sided = false; //not sure if true or false...
	node.updateMatrices();
	this.feed_node.addChild(node);

	//base mesh
	this._base_mesh = new SceneNode({ scale: 1 });

	
	if (!PrefabRenderer.block_prefab_loading && !globalThis.nativeEngine && RoomAvatar.foot_mesh) {
		this._base_mesh.loadGLTF(getFullPath(RoomAvatar.foot_mesh));
	}

	this.node.addChild(this._base_mesh);

	//nametag
	const nametag_node = (this.nametag_node = new SceneNode({
		name: "nametag",
		mesh: "plane",
		position: ROOM_SETTINGS.participants.nametag_position,
		scaling: [ 1, 0.25, 1 ],
	}));
	nametag_node.scale(0.5);
	nametag_node.visible = true;
	this.pivot_node.addChild(nametag_node);

	//legacy I think...
	if (getExtension(RoomAvatar.profile_mesh)) {
		//has extension
		ViewCore.instance.loadMesh(RoomAvatar.profile_mesh);
	}
};

RoomAvatar.prototype.initNative = function () {
	const nativeEngine = globalThis.nativeEngine;

	if (!nativeEngine || this._nativeEntity) return;

	const e = nativeEngine._room.createEntity(
		"FEED",
		"plane",
		this.material.name,
		this.material.name,
		-1
	);
	if (e > 0) this._nativeEntity = nativeEngine._room.getEntity(e);
	if (this._nativeEntity) {
		this.feed_node._native = this._nativeEntity.getStreamNode();
		if (this.nametag_node)
			this.nametag_node._native = this._nativeEntity.getInfoNode();
	}
};

RoomAvatar.prototype.onLeftSpace = function () {
	if (nativeEngine) {
		if (this._nativeEntity) {
			this.resetNativeNodes(false);
			if (this.nametag_node && this.nametag_node._native)
				this.nametag_node._native.visible = false;

			if (this._base_mesh._native) {
				this._base_mesh._native.visible = false;
			}

			// Andrey FIXME: Disable until i find the reason for crash
			//var id = this.avatar._nativeEntity.getID();
			//nativeEngine._room.destroyEntity(id);
			this._nativeEntity = null;
		}
	}
};

RoomAvatar.prototype.resetNativeNodes = function () {
	const walking = this.participant.walking;

	const node = this.feed_node;

	if (node._native) {
		node._native.visible = walking;
	}

	if (this._nativeEntity && this._base_mesh) {
		this._nativeEntity.setWalkingMode(walking);
	}
};


/**
 * in charge of updating the visual representation of a user
 * mostly adapting the material of the visible shape and its position
 * called from participant.preRender
 * @param {ViewCore} view
 */
RoomAvatar.prototype.preRender = function (view) {
	this.initNative();

	//update visual representation
	const camera = view.camera;
	const space = view.space;
	const participant = this.participant;
	const seat = participant.seat;
	const material = this.material;
	const participant_settings = ROOM_SETTINGS.participants;
	const profile_node = this.feed_node; //pending from profile_pivot_node and root

	const layer_mask = 0xff;
	/** @see RoomParticipant.LAYERS_MASK; **/
	this.node.layers = layer_mask;

	//assign visibility
	let must_render_user = this.visible;

	if (!participant.walking && (!seat || seat.spectator)) {
		must_render_user = false;
	}

	//in case of walking, be sure to render the rumba
	let face_height = 0;
	if (participant.walking) {
		face_height = 0.1;
		if (this._nativeEntity && !this._base_mesh._native) {
			this._base_mesh._native = this._nativeEntity.getWalkNode();
		}
	}

	//not very necessary but it works well for debugging purposes
	this.pivot_node.position = participant_settings.profile_position;

	if (participant.walking) {
		this.pivot_node.position[2] = 0;
	}

	//modify visibility in case face is hidden
	profile_node.visible = must_render_user;
	profile_node.layers = layer_mask;

	//adapt aspect
	let feed_aspect = this._texture
		? this._texture.width / this._texture.height
		: 1;

	if (this._feed_options && this._feed_options.mode === "holoh") {
		feed_aspect *= 2;
	}

	//place the face
	const scale = {
    	profile: participant_settings.profile_scaling + participant.profile_scaling,
    	aspect : participant_settings.aspect_modifier * feed_aspect,
    	face   : participant.face_scale
	};

	//no stream, reset internal offset!!
	if (!this.participant.feed) {
		participant._internal_offset[0] = 0;
		participant._internal_offset[1] = 0;
		participant._internal_scale = 1;
	}

	const profile_pos = [
		participant._internal_offset[0] * scale.face,
		participant._internal_offset[1] * scale.face,
		0,
	];

	profile_pos[0] += participant.face_offset[0] * participant_settings.profile_offset_max;
	profile_pos[1] -= participant.face_offset[1] * participant_settings.profile_offset_max - face_height;

	profile_pos[0] *= scale.profile * scale.aspect;
	profile_pos[1] *= scale.profile;

	profile_node.position = profile_pos;

	//tweak parameters of the material
	let brightness =
		participant.face_brightness *
		participant_settings.brightness *
		space.fx.participants_brightness;

	if (participant.mouse_hover) {
		brightness *= 1.5;
	}

	const emissive = [ brightness, brightness, brightness ];

	vec3.mul(emissive, emissive, space.fx.participants_basecolor);

	material.emissive = emissive;

	//done here as stream may change its opacity
	const size_fade = clamp(
		remap(participant._projected_radius, 0.49, 0.5, 1, 0), // changing this value to prevent transparecy when zoomed in
		0,
		1
	);

	material.opacity =
		size_fade *
		ROOM_SETTINGS.participants.global_opacity *
		participant.fade_factor *
		1;//(participant.bad_connection ? 0.75 : 1); //disabled

	const cam_front = camera._front;
	const front = profile_node.getGlobalVector([ 0, 0, -1 ]);

	if (
		material.opacity === 0 ||
		(material.flags.two_sided === false && vec3.dot(front, cam_front) < 0)
	) {
		//facing backwards
		participant.is_inside_view = false;
		participant.is_visible = false;
	}

	if (!participant.seat) {
		material.flags.two_sided = true;
	} else {
		material.flags.two_sided = participant.constructor.two_sided;
	}

	let mode = RoomAvatar.FEED_2D;

	if (this._feed_options && this._feed_options.mode === "holoh") {
		mode = RoomAvatar.FEED_3D;
	}

	//2D Video Stream
	if (mode === RoomAvatar.FEED_2D) {
		this.setupFeed2D(view);
	} else if (mode === RoomAvatar.FEED_3D) {
		this.setupFeed3D(view);
	}

	// for the tutorial "fake" participant
	if (this.showGhostImage == true) {
		if (this._texture == null) {
			this.material.opacity = 0;
			profile_node.visible = false;
			this._base_mesh.visible = false;
		} else {
			//this.material.opacity = material.opacity;
			profile_node.visible = true;
			this._base_mesh.visible = true;
		}
	}

	//adapt area
	const scale_node = participant._internal_scale * scale.face * scale.profile;
	const flipX = 1;

	const pos_y = scale_node/2.0 - 0.30;
	profile_node.scaling = [ scale_node * scale.aspect * flipX, scale_node, 1 ]; // Z fixed, otherwise it will move in Z when scaling
	profile_node.position = [ 0, pos_y ];

	///tweak base mesh glowing aura
	this._base_mesh.visible = participant.walking && must_render_user;

	const glow_mat = StaticMaterialsTable["Material #2147462153"];

	if (glow_mat) {
		glow_mat.blendMode = "ADD";
		glow_mat.flags.two_sided = true;

		if (glow_mat.emissive) {
			vec3.set(glow_mat.emissive, 0.58, 0.8, 4);
		}

	}

	//update nametag
	this.updateNametag();
};

RoomAvatar.prototype.setFeedTexture = function (texture, feed_options = {}) {
	if (texture && !texture.name)
		throw "texture must have a name and be registered in gl.textures[] ";

	this._texture = texture;
	this._feed_options = feed_options;
};

//returns the texture to represent this person
RoomAvatar.prototype.getFeedTexture = function () {
	if (this.showGhostImage)
		return this._texture ? this._texture : null;

	return this._texture || ROOM.view.loadTexture("textures/profile_shape.png");
};

//returns the feed options
RoomAvatar.prototype.getFeedOptions = function () {
	return this._feed_options;
};

RoomAvatar.prototype.setupFeed2D = function () {
	const profile_node = this.feed_node; //pending from profile_pivot_node and root
	const material = this.material;

	profile_node.mesh = RoomAvatar.profile_mesh; //flat mesh
	profile_node.material = material.name;
	material.primitive = GL.TRIANGLES;
	material.alphaMode = "BLEND";
	material.shader_name = null;
	material.textures.opacity = "textures/stream_mask_gradient.png";

	//get the texture to display
	const texture = this.getFeedTexture();
	if (!texture) {
		material.textures.emissive = null;
		return;
	}

	//assign texture to material
	if (!material.textures.emissive || material.textures.emissive.constructor === String) {
		material.textures.emissive = {};
	}

	material.textures.emissive.texture = texture.name;

	if (!this.mirrored) {
		material.textures.emissive.uv_channel = 0;

		if (material.uv_transform) {
			material.uv_transform = null;
		}

	} else {
		material.textures.emissive.uv_channel = 2;

		if (!material.uv_transform) {
			material.uv_transform = mat3.create();
		}

		material.uv_transform[0] = -1;
		material.uv_transform[6] = 1;
	}
};

//HOLOH
RoomAvatar.prototype.setupFeed3D = function (view) {
	const profile_node = this.feed_node; //pending from profile_pivot_node and root
	const material = this.material;
	material.shader_name = "holoh_pointcloud";
	material.alphaMode = "OPAQUE";

	if (!this.holoh_stream_data) {
		this.holoh_stream_data = {
			res_x: 640,
			res_y: 480,
			max_depth: 1.0,
			min_depth: 0.3,
			depth_encoding: [
				{ length: 0.2, detail: 0.05 },
				{ length: 0.15, detail: 0.15 },
				{ length: 0.3, detail: 0.6 },
				{ length: 0.15, detail: 0.15 },
				{ length: 0.2, detail: 0.05 },
			],
			camera_intrinsics: { fx: 387.27, fy: 387.27, ppx: 322.079, ppy: 234.248 },
		};
		const curve = this.holoh_stream_data.depth_encoding; //[ {"length": 0.20, "detail": 0.05}, {"length": 0.15, "detail": 0.15}, {"length": 0.30, "detail": 0.60}, {"length": 0.15, "detail": 0.15}, {"length": 0.20, "detail": 0.05} ];
		this.holoh_stream_data._depth_encoding =
			RoomAvatar.create_slopes(curve).flat();
		const camera_intrinsics = this.holoh_stream_data.camera_intrinsics;
		this.holoh_stream_data._camera_intrinsics = new Float32Array([
			camera_intrinsics.fx,
			camera_intrinsics.fy,
			camera_intrinsics.ppx,
			camera_intrinsics.ppy,
		]);
		this.holoh_stream_data._depth_range = new Float32Array([
			this.holoh_stream_data.min_depth,
			this.holoh_stream_data.max_depth,
		]);
	}

	if (!gl.shaders[material.shader_name])
		gl.shaders[material.shader_name] = new GL.Shader(
			RoomAvatar.STREAM_HOLOH_VSCODE,
			RoomAvatar.STREAM_HOLOH_FSCODE
		);

	material.primitive = GL.POINTS;
	const texture = this.getFeedTexture();
	if (!texture) return;

	material.textures.opacity = null;
	material.textures.emissive = texture.name;

	const res = [ texture.width, Math.floor(texture.height / 2) ];
	material.uniforms.u_ires = [ 1 / texture.width, 1 / texture.height ];
	material.uniforms.u_depth_encoding = this.holoh_stream_data._depth_encoding;
	material.uniforms.u_depth_range = this.holoh_stream_data._depth_range;
	material.uniforms.u_camera_intrinsics =
		this.holoh_stream_data._camera_intrinsics;
	let mesh = this._mesh_3d;
	if (
		texture.width > 1 &&
		(!mesh || mesh.res[0] !== res[0] || mesh.res[1] !== res[1])
	) {
		//w-1 as 1 means one face, so 2 vertices, and we want as many vertices as pixels, so w-1
		mesh = this._mesh_3d = GL.Mesh.plane({
			detailX: res[0] - 1,
			detailY: res[1] - 1,
		});
		mesh.res = res; //store
		mesh.name = "holoh_" + res[0] + "x" + res[1];
		profile_node.mesh = mesh.name;
		gl.meshes[mesh.name] = mesh;
	} else profile_node.mesh = mesh.name;

	if (!window.TmrwEngine) {
		texture.bind(0);
		gl.activeTexture(gl.TEXTURE0);
		gl.texParameteri(WebGLRenderingContext.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
	}
};

//for devs
RoomAvatar.replaceHolohShader = function (vs_code, fs_code) {
	const newshader = new GL.Shader(
		vs_code || RoomAvatar.STREAM_HOLOH_VSCODE,
		fs_code || RoomAvatar.STREAM_HOLOH_FSCODE
	);
	gl.shaders["holoh_pointcloud"] = newshader;
};

RoomAvatar.create_slopes = function (segments) {
	const computed_slopes = [];
	let accumulated_length = 0;
	let accumulated_detail = 0;

	for (let i = 0; i < segments.length; ++i) {
		const segment = segments[i];
		const end = accumulated_detail + segment.detail;
		const slope = 1 / (segment.detail / segment.length);
		const displacement = accumulated_length - accumulated_detail * slope;
		accumulated_length += segment.length;
		accumulated_detail += segment.detail;
		computed_slopes.push([ displacement, slope, end ]);
	}
	return computed_slopes;
};

RoomAvatar.STREAM_HOLOH_VSCODE = StreamHolohVs;

RoomAvatar.STREAM_HOLOH_FSCODE = StreamHolohFs;

RoomAvatar.feed_material_info = {
	alphaMode: "BLEND",
	alphaCutoff: 0.5,
	color: [ 0, 0, 0, 1 ],
	emissive: [ 1, 1, 1 ],
	flags: {
		two_sided: true,
	},
};

RoomAvatar.nofeed_material_info = {
	name: "nofeed_body",
	alphaMode: "BLEND",
	opacity: 1,
	albedo: [ 0, 0, 0 ],
	emissive: [ 0.6, 0.6, 0.6 ],
	textures: {
		opacity: {
			texture: "gradient.png",
		},
	},
	flags: {
		two_sided: false,
		depth_write: false,
		preAlpha: true,
	},
	additive: false,
};

//basic shader applied, this is renamed as "alpha_boost_fx"
RoomAvatar.STREAM_FX_FSCODE = StreamFxFs;

//update the content of the texture/canvas of the nametag (username)
RoomAvatar.prototype.updateNametag = function () {
	if (!this.nametag_material) {

		this.nametag_material = new Material({
			color: [ 0, 0, 0 ],
			emissive: [ 0.8, 0.8, 0.8 ],
			alphaMode: "BLEND",
		});

		this.nametag_material.alphaCutoff = 0.5;
		this.nametag_material.render_priority = -100; //force render last
		this.nametag_material.name = ":nametag_mat_" + this.participant.index;

		StaticMaterialsTable[this.nametag_material.name] = this.nametag_material;
	}

	const username = this.participant.getUsername();
	const nametag_node = this.nametag_node;

	const infowidth = 256;
	const infoheight = 64;

	if (!RoomAvatar.nametag_canvas) {
		RoomAvatar.nametag_canvas = document.createElement("canvas");
		RoomAvatar.nametag_canvas.width = infowidth;
		RoomAvatar.nametag_canvas.height = infoheight;
	}

	if (!this.nametag_texture) {
		this.nametag_texture = new GL.Texture(infowidth, infoheight, {
			filter: gl.LINEAR,
			wrap: gl.CLAMP_TO_EDGE,
		});
		let texture_name = this.nametag_texture.name = ":nametag_tex_" + this.participant.index;
		gl.textures[texture_name] = this.nametag_texture;

		this.nametag_texture_dark = new GL.Texture(infowidth, infoheight, {
			filter: gl.LINEAR,
			wrap: gl.CLAMP_TO_EDGE,
		});

		texture_name = this.nametag_texture_dark.name = ":nametag_tex_dark_" + this.participant.index;
		gl.textures[texture_name] = this.nametag_texture_dark;
	}

	const video_muted =
		this.participant.is_video_muted ||
		!this.participant.feed_info ||
		!this.participant.feed_info.video;

	let must_be_updated = false;
	if (
		this.nametag_texture.username !== username ||
		this.nametag_texture.is_audio_muted !== this.participant.is_audio_muted ||
		this.nametag_texture.is_video_muted !== video_muted
	) {
		must_be_updated = true;
	}

	if (must_be_updated) {
		//to detect changes
		this.nametag_texture.username = username;
		this.nametag_texture.is_audio_muted = this.participant.is_audio_muted;
		this.nametag_texture.is_video_muted = video_muted;

		let right_margin = 0;
		if (ROOM_SETTINGS.participants.draw_icons) {
			if (this.participant.is_audio_muted) right_margin += 32;
			if (this.participant.is_video_muted) right_margin += 32;
		}

		const canvas = RoomAvatar.nametag_canvas; //shared canvas
		const ctx = canvas.getContext("2d");
		const w = canvas.width;
		const h = canvas.height;

		const font_size = 24;
		const font_name = this.participant.getFont();

		ctx.font = font_size + "px " + font_name + ",Arial";
		const text_size = ctx.measureText(username);

		let safename = username || "";

		const safemargin = 60 + right_margin / 2;

		if (text_size.width > w - safemargin) {
			const tokens = safename.split(" ");
			safename = "";
			let total_size = 0;
			for (let i = 0; i < tokens.length; ++i) {
				const token = tokens[i];
				const tokensize = ctx.measureText(" " + token);
				if (total_size + tokensize.width > w - safemargin) {
					if (safename === "") safename = username.substr(0, 8) + "...";
					else safename += "...";
					break;
				}
				safename += (safename !== "" ? " " : "") + token;
				total_size += tokensize.width;
			}
		}

		//white badge *******************************
		const tagwidth = w;
		const bh = Math.floor(h * 1);

		ctx.clearRect(0, 0, w, h);
		//hack to avoid alpha 0, otherwise color will be black
		ctx.fillStyle = "rgba(255,255,255,0.003)";
		ctx.fillRect(0, 0, w + 2, bh + 2);

		//circular shape
		ctx.fillStyle = "rgba(255,255,255,1)";
		ctx.beginPath();
		ctx.arc(
			w * 0.5 - tagwidth * 0.5 + bh * 0.5,
			bh * 0.5,
			bh * 0.5 - 1,
			Math.PI * 0.5,
			Math.PI * 1.5
		);
		ctx.arc(
			w * 0.5 + tagwidth * 0.5 - bh * 0.5,
			bh * 0.5,
			bh * 0.5 - 1,
			Math.PI * 1.5,
			Math.PI * 2.5
		);
		ctx.fill();

		//clip area to avoid text overflowing
		ctx.save();
		ctx.beginPath();
		ctx.rect(
			Math.floor(w * 0.5 - tagwidth * 0.5 + bh * 0.25),
			1,
			Math.ceil(tagwidth - bh) + 1,
			bh - 2
		);
		ctx.clip();

		if (safename) {
			ctx.fillStyle = "black";
			ctx.textAlign = "center";
			ctx.font = font_size + "px " + font_name + ",Arial";
			ctx.fillText(
				safename,
				w * 0.5 - right_margin / 2,
				h / 2 + font_size * 0.4
			);
		}

		ctx.restore();

		if (ROOM_SETTINGS.participants.draw_icons) {
			let icon_edge = w * 0.5 + tagwidth * 0.5 - bh / 1.5;

			if (this.participant.is_audio_muted) {
				this.drawIcon(ctx, [ 3, 7 ], icon_edge, bh * 0.25, bh * 0.5, true);
				icon_edge -= bh * 0.5;
			}

			if (video_muted) {
				this.drawIcon(ctx, [ 7, 7 ], icon_edge, bh * 0.25, bh * 0.5, true);
			}
		}

		this.nametag_texture.uploadData(canvas);

		//dark badge *******************************
		ctx.clearRect(0, 0, w, h);

		ctx.fillStyle = "rgba(0,0,0,0.9)";
		ctx.beginPath();
		ctx.arc(
			w * 0.5 - tagwidth * 0.5 + bh * 0.5,
			bh * 0.5,
			bh * 0.25 - 1,
			Math.PI * 0.5,
			Math.PI * 1.5
		);
		ctx.arc(
			w * 0.5 + tagwidth * 0.5 - bh * 0.5,
			bh * 0.5,
			bh * 0.25 - 1,
			Math.PI * 1.5,
			Math.PI * 2.5
		);
		ctx.fill();

		if (safename) {
			ctx.fillStyle = "white";
			ctx.textAlign = "center";
			ctx.font = Math.floor(font_size * 0.75) + "px " + font_name + ",Arial";
			ctx.fillText(
				safename,
				w * 0.5 - right_margin / 2,
				h / 2 + font_size * 0.2
			);
		}

		if (ROOM_SETTINGS.participants.draw_icons) {
			var icon_edge = w * 0.5 + tagwidth * 0.5 - bh / 1.25;
			if (this.participant.is_audio_muted) {
				this.drawIcon(ctx, [ 3, 7 ], icon_edge, bh * 0.3, bh * 0.4);
				icon_edge -= bh * 0.4;
			}
			if (video_muted) this.drawIcon(ctx, [ 7, 7 ], icon_edge, bh * 0.3, bh * 0.4);
		}

		this.nametag_texture_dark.uploadData(canvas);
	}

	this.nametag_material.opacity = 1;

	const aspect = infoheight / infowidth;
	const scale = ROOM_SETTINGS.participants.nametag_scale;
	nametag_node.scaling = [ 1 * scale, aspect * scale, scale ];
	nametag_node.position = ROOM_SETTINGS.participants.nametag_position;
	this.nametag_material.textures.emissive = this.nametag_texture_dark.name;

	if (this.participant.mouse_hover) {
		this.nametag_material.emissive = [ 0.2, 0.4, 1.8 ]; //bluish
	} else {
		this.nametag_material.emissive = [ 0.8, 0.8, 0.8 ];
	}

	if (ROOM_SETTINGS.participants.draw_nametags) {
		const name_fade_factor = clamp(
			5 - 0.5 * (getTime() - this.participant.highlight_time) * 0.005,
			0,
			1
		);
		this.nametag_material.opacity = name_fade_factor;
	} else this.nametag_material.opacity = 0;

	this.nametag_material.opacity *= ROOM_SETTINGS.participants.global_opacity;
	this.nametag_material.flags.depth_test = false;

	//show the top infobar
	if (this.nametag_node && this.nametag_node.visible) {
		//this.nametag_node.rotation = profile_node.rotation;
		this.nametag_node.material = this.nametag_material.name;
	}

	return this.nametag_material;
};

RoomAvatar.prototype.drawIcon = function (ctx, icon, x, y, size, reverse) {
	if (reverse) {
		ctx.globalCompositeOperation = "difference";
	}

	ctx.drawImage(
		ROOM.icons_img,
		icon[0] * 64,
		icon[1] * 64,
		64,
		64,
		x,
		y,
		size,
		size
	);

	if (reverse) {
		ctx.globalCompositeOperation = "source-over";
	}
};

export { RoomAvatar };
