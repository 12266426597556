//this simulates the bridge provided by WebRTC layer but using WebSockets and SillyServer
//meant to emulate the network layer in the dev server
//doesnt know anything about NetworkSync
//instantiated from index.html
import { getFullPath } from "@src/engine/Room/file-utils";
import RoomCropModes from "@src/engine/Room/RoomCropModes";
import { LEvent } from "@src/libs/LEvent";
import SillyClient from "@src/libs/sillyclient";

function CoreBridgeFake(xyz )
{
	this.xyz = xyz;

	console.debug(" + CoreBridge Emulator");
	this.server = new SillyClient();
	this.server.on_ready = this.onReady.bind(this);
	this.server.on_room_info = this.onRoomInfo.bind(this);
	this.server.on_message = this.onMessage.bind(this);
	this.server.on_close = this.onClose.bind(this);
	this.server.on_user_connected = this.onUserConnected.bind(this);
	this.server.on_user_disconnected = this.onUserDisconnected.bind(this);

	//LEvent.bind( xyz.room, "ready", this.connect, this );
	if (!xyz.space)
		throw ("FakeCoreBridge requires space to exists before loading");

	LEvent.bind( xyz.space,"changeSeat", this.onChangeSeat, this );
	this.subscribe( "CHANGE_SEAT_REQ", this.onRequestChangeSeat.bind(this) );
	this.subscribe( "EVENT_BCAST_OUT", this.onSendMessage.bind(this) );
	this.subscribe( "EVENT_P2P_OUT", this.onSendMessage.bind(this) );

	// XXX_OUT: messages flowing out of room core
	// XXX_IN: messages flowing into room core
	this.EVENT_BCAST_OUT = "EVENT_BCAST_OUT"; // tcp channel, json text payload
	this.EVENT_BCAST_IN = "EVENT_BCAST_IN";
	this.EVENT_STATE_OUT = "EVENT_STATE_OUT"; // tcp channel, json text payload
	this.EVENT_STATE_IN = "EVENT_STATE_IN";
	this.EVENT_P2P_OUT = "EVENT_P2P_OUT"; // udp channel, json/binary
	this.EVENT_P2P_IN = "EVENT_P2P_IN";

	this.EVENT_ALERT_OUT = "EVENT_ALERT_OUT"; // local UI alert payload {msg: text_to_display}
	this.EVENT_SET_FPS_IN = "EVENT_SET_FPS_IN"; // payload: {fps: value}

	CoreBridgeFake.instance = this;
}

Object.defineProperty( CoreBridgeFake.prototype, "is_connected", {
	set: function() { throw ("cannot set connected"); },
	get: function() { return this.server.is_connected; }
});

CoreBridgeFake.prototype.connect = function(roomname)
{
	// specifically accessed via global to split chunks
	if (!this.xyz.bridge) {
		this.xyz.setBridge(this);
	}
	this.server.connect("tamats.com:55000/TMRW_" + roomname);
}

CoreBridgeFake.prototype.disconnect = function()
{
	this.server.close();
}

//send
CoreBridgeFake.prototype.notify = function(e,data)
{
	if (e === "EVENT_BCAST_OUT" || e === "EVENT_P2P_OUT" )
	{
		if (this.server.is_connected)
			this.server.sendMessage(data);
	}
	else
		LEvent.trigger(this,e,data);
}

//receive
CoreBridgeFake.prototype.subscribe = function(e,func)
{
	LEvent.bind( this, e, function(e,data) { func(data); });
}

CoreBridgeFake.prototype.onReady = function( user_id )
{
	var participant = this.xyz.space.local_participant;
	participant.id = user_id;
}

CoreBridgeFake.prototype.onSendMessage = function( payload )
{
	if (this.server.is_connected)
		this.server.sendMessage(payload);
}

CoreBridgeFake.prototype.onRoomInfo = function( info )
{
	for (var i in info.clients)
	{
		var client_id = info.clients[i];
		this.onUserConnected( client_id );
	}

	//console.debug(info);
	var participant = this.xyz.space.local_participant;
	this.server.sendMessage({
		type: "change_seat",
		seat_entity: participant.seat_entity ? participant.seat_entity.name : null
	});

	// hack
	// using a timeout for the moment because onRoomInfo
	// is triggered before space.participants is populated
	setTimeout(() => {
		LEvent.trigger( this.xyz.space, "on_room_info" );
	}, 500);
}

CoreBridgeFake.prototype.createParticipant = function( user_id, info )
{
	var space = this.xyz.space;
	var participant = space.getParticipant( user_id );
	if (participant)
		return participant;

	var participant = this.xyz.createParticipant( user_id, info );
	participant.name = "User_" + user_id;
	space.addParticipant( participant );

	var video = document.createElement("video");
	video.src = getFullPath(user_id % 2 === 0 ? "videos/cutouts/fakeperson5.webm" : "videos/cutouts/fakeperson9.webm" );
	video.loop = true;
	participant.crop_mode = RoomCropModes.GRADIENT;
	participant.assignFeed( video );
	return participant;
}

//called from sillyclient, only when somebody NEW connect, not if they were already in the room
CoreBridgeFake.prototype.onUserConnected = function( user_id )
{
	user_id = Number(user_id);
	var space = this.xyz.space;
	var local_participant = space.local_participant;

	//create remote user locally
	//this.createParticipant( user_id );

	//tell new user about me
	var info = {
		type:"user_info",
		user_id: this.server.user_id,
		user_info: local_participant.user
	};
	if ( local_participant.seat_entity )
		info.seat_entity = local_participant.seat_entity.name;
	this.server.sendMessage( info );
}

CoreBridgeFake.prototype.onUserDisconnected = function( user_id )
{
	var space = this.xyz.space;
	var participant = space.getParticipant( user_id );
	if (participant)
		space.removeParticipant(participant);
}

//ONLY FOR FAKE BRIDGE
CoreBridgeFake.prototype.onMessage = function( user_id, data )
{
	var space = this.xyz.space;

	//if binary then assume P2P, otherwise Broadcast
	if (data.constructor === ArrayBuffer)
	{
		LEvent.trigger(this, "EVENT_P2P_IN", data );
	}
	else
	{
		var msg = JSON.parse( data );

		if (msg.type === "user_info" )
		{
			this.createParticipant( user_id, msg.user_info );
			if ( msg.seat_entity )
				participant.enterSeat( msg.seat_entity );
		}
		else if (msg.type === "change_seat" )
		{
			var participant = space.getParticipant( user_id );
			if (participant)
			{
				if ( msg.seat_entity )
					participant.enterSeat( msg.seat_entity );
				else
					participant.exitSeat();
			}
		}

		//this will trigger the bridge.subscribe( "EVENT_BCAST_IN", ... ) from XYZLauncehr.setBridge
		LEvent.trigger( this, "EVENT_BCAST_IN", msg ); //to emulate real layer
	}
}

CoreBridgeFake.prototype.onClose = function( err)
{
	console.debug("server closed");
}

CoreBridgeFake.prototype.onRequestChangeSeat = function(payload)
{
	//console.debug("REQ");
	var space = this.xyz.space;
	var seat = payload.newSeat;

	//delay action to make it more similar to real situations
	setTimeout( inner, 100 );

	function inner()
	{
		if (seat)
			space.local_participant.enterSeat( "seat" + seat );
		else
			space.local_participant.exitSeat();
	}

	//broadcast? or it will be changed automatically
}

CoreBridgeFake.prototype.onChangeSeat = function(e,data)
{
	if (!this.server.is_connected)
		return;

	if (data.author !== this.xyz.space.local_participant)
		return;

	this.server.sendMessage({
		type: "change_seat",
		seat_entity: data.entity ? data.entity.name : null
	});
}

window.CoreBridgeFake = CoreBridgeFake;

export default CoreBridgeFake;
