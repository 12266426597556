import { generateUID } from "@src/engine/generateUID";
import { getObjectClassName } from "@src/engine/Room/getObjectClassName";
import { RoomComponents } from "@src/engine/RoomComponents";
import { LEvent } from "@src/libs/LEvent";
import MissingComponent from "./components/MissingComponent";

/**
* ComponentContainer class allows to add component based properties to any other class
* @class ComponentContainer
* @constructor
*/
function ComponentContainer()
{
	this._components = [];
}

/**
* Adds a component to this node.
* @method configureComponents
* @param {Object} info object containing all the info from a previous serialization
*/
ComponentContainer.prototype.configureComponents = function( info )
{
	if (!info.components)
		return;

	var to_configure = [];

	//attach first, configure later
	for (var i = 0, l = info.components.length; i < l; ++i)
	{
		var comp_info = info.components[i];
		var comp_class = comp_info[0];
		var comp = null;

		//special case: this is the only component that comes by default
		if (comp_class == "Transform" && i == 0 && this.transform)
		{
			comp = this.transform;
		}
		else
		{
			//search for the class
			var classObject = RoomComponents[ comp_class ];
			if (!classObject) {
				console.error("Unknown component found: " + comp_class);
				classObject = MissingComponent;
			}
			//create component
			comp = new classObject();
			//attach to node
			this.addComponent( comp );

			if ( comp.constructor === MissingComponent )
				comp._comp_class = comp_class;
		}

		//what about configure the comp after adding it?
		//comp.configure( comp_info[1] );
		to_configure.push( comp, comp_info[1] );

		//editor stuff
		if ( comp_info[1].editor )
			comp._editor = comp_info[1].editor;

		//ensure the component uid is stored, some components may forgot about it
		if ( comp_info[1].uid && comp_info[1].uid !== comp.uid )
			comp.uid = comp_info[1].uid;
	}

	//configure components now that all of them are created
	//this is to avoid problems with components that check if the node has other components and if not they create it
	for (var i = 0, l = to_configure.length; i < l; i+=2)
	{
		var comp = to_configure[i];
		var data = to_configure[i+1];

		comp.configure( data );
	}
}



/**
* Adds a component to this node.
* @method serializeComponents
* @param {Object} o container where the components will be stored
*/
ComponentContainer.prototype.serializeComponents = function( o, simplified )
{
	if (!this._components)
		return;

	o = o || {};

	o.components = [];
	for (var i = 0, l = this._components.length; i < l; ++i)
	{
		var comp = this._components[i];
		if ( !comp.serialize || comp.skip_serialize )
			continue;
		var obj = {};

		//enforce uid storage
		if (comp.uid)
			obj.uid = comp.uid;

		comp.serialize( obj, simplified );

		if (comp._editor && !simplified )
			obj.editor = comp._editor;

		var object_class = null;

		if ( comp.constructor === MissingComponent )
			object_class = comp._comp_class;
		else
			object_class = getObjectClassName( comp );

		if (!obj.object_class)
			obj.object_class = object_class; //enforce

		o.components.push([ object_class, obj ]);
	}

	return o;
}

/**
* returns an array with all the components
* @method getComponents
* @return {Array} all the components
*/
ComponentContainer.prototype.getComponents = function( class_type )
{
	if (class_type)
	{
		var result = [];
		if (class_type.constructor === String)
			class_type = RoomComponents[class_type];
		for (var i = 0, l = this._components.length; i < l; ++i)
		{
			var compo = this._components[i];
			if ( compo.constructor === class_type )
				result.push( compo );
		}
		return result;
	}

	return this._components;
}

/**
* Adds a component to this node. (maybe attach would been a better name)
* @method addComponent
* @param {Object} component
* @return {Object} component added
*/
ComponentContainer.prototype.addComponent = function( component, index )
{
	if (!component)
		throw ("addComponent cannot receive null");

	//you may pass a component class instead of an instance
	if (component.constructor === String)
	{
		component = RoomComponents[ component ];
		if (!component)
			throw ("component class not found: " + arguments[0] );
	}
	if (component.is_component)
		component = new component();

	//link component with container
	component._root = this;

	//must have uid
	if ( !component.uid )
		component.uid = generateUID("COMP-");

	if ( component.onAdded)
		component.onAdded(this);

	//link node with component
	if (!this._components)
		Object.defineProperty( this, "_components", { value: [], enumerable: false });
	if (this._components.indexOf(component) !== -1)
		throw ("inserting the same component twice");

	if (index !== undefined && index <= this._components.length )
		this._components.splice(index,0,component);
	else
		this._components.push( component );

	LEvent.trigger( this, "componentAdded", component );

	return component;
}

/**
* Removes a component from this node.
* @method removeComponent
* @param {Object} component
*/
ComponentContainer.prototype.removeComponent = function(component)
{
	if (!component)
		throw ("removeComponent cannot receive null");

	if (component._root != this)
		console.error("this component doesnt belong to this node");

	//unlink component with container
	component._root = null;

	//not very clean, ComponetContainer shouldnt know about ONE.SceneNode, but this is more simple
	if ( component.onRemoved )
		component.onRemoved(this);

	//remove all events
	LEvent.unbindAll(this,component);

	//remove from components list
	var pos = this._components.indexOf(component);
	if (pos !== -1)
		this._components.splice(pos,1);
	else
		console.warn("removeComponent: Component not found in node");

	LEvent.trigger( this, "componentRemoved", component );
}

/**
* Removes all components from this node.
* @method removeAllComponents
* @param {Object} component
*/
ComponentContainer.prototype.removeAllComponents = function()
{
	while (this._components.length)
		this.removeComponent( this._components[0] );
}


/**
* Returns if the container has a component of this class
* @method hasComponent
* @param {String|Class} component_class the component to search for, could be a string or the class itself
*/
ComponentContainer.prototype.hasComponent = function( component_class )
{
	if (!this._components)
		return false;

	//string
	if ( component_class.constructor === String )
	{
		component_class = RoomComponents[ component_class ];
		if (!component_class)
			return false;
	}

	//search in components
	for (var i = 0, l = this._components.length; i < l; ++i)
		if ( this._components[i].constructor === component_class )
			return true;

	return false;
}


/**
* Returns the first component of this container that is of the same class
* @method getComponent
* @param {Object|String} component_class the class to search a component from (could be the class or the name)
* @param {Number} [index] if you want the Nth component of this class
*/
ComponentContainer.prototype.getComponent = function( component_class, index )
{
	if (!this._components || !component_class)
		return null;

	//convert string to class
	if ( component_class.constructor === String )
	{
		//special case, locator by name (the locator starts with an underscore if it is meant to be a name)
		if ( component_class[0] === "_" )
		{
			component_class = component_class.substr(1); //remove underscore
			for (var i = 0, l = this._components.length; i < l; ++i)
			{
				if ( this._components[i].name == component_class )
				{
					if (index !== undefined && index > 0)
					{
						index--;
						continue;
					}
					return this._components[i];
				}
			}
			return false;
		}

		//otherwise the string represents the class name
		component_class = RoomComponents[ component_class ];
		if (!component_class)
			return;
	}

	//search components
	for (var i = 0, l = this._components.length; i < l; ++i)
	{
		if ( this._components[i].constructor === component_class )
		{
			if (index !== undefined && index > 0)
			{
				index--;
				continue;
			}
			return this._components[i];
		}
	}

	return null;
}

/**
* Returns the component with the given uid
* @method getComponentByUId
* @param {string} uid the uid to search
*/
ComponentContainer.prototype.getComponentByUId = function(uid)
{
	if (!this._components)
		return null;
	for (var i = 0, l = this._components.length; i < l; ++i)
		if ( this._components[i].uid == uid )
			return this._components[i];
	return null;
}

/**
* Returns the position in the components array of this component
* @method getIndexOfComponent
* @param {Number} position in the array, -1 if not found
*/
ComponentContainer.prototype.getIndexOfComponent = function(component)
{
	if (!this._components)
		return -1;
	return this._components.indexOf( component );
}

/**
* Returns the component at index position
* @method getComponentByIndex
* @param {Object} component
*/
ComponentContainer.prototype.getComponentByIndex = function(index)
{
	if (!this._components)
		return null;
	return this._components[index];
}

/**
* Returns a list of components matching the search, it search in the node and child nodes
* @method findComponent
* @param {Class|String} component the component class or the class name
* @return {Array} an array with all the components of the same class
*/
ComponentContainer.prototype.findComponents = function( comp_name, out )
{
	out = out || [];
	if (!comp_name)
		return out;
	if ( comp_name.constructor === String )
		comp_name = RoomComponents[ comp_name ];
	if (!comp_name)
		return out;

	if ( this._components )
		for (var i = 0; i < this._components.length; ++i )
		{
			var comp = this._components[i];
			if ( comp && comp.constructor === comp_name )
				out.push( comp );
		}

	if (this._children)
		for (var i = 0; i < this._children.length; ++i )
			this._children[i].findComponents( comp_name, out );
	return out;
}

/**
* Changes the order of a component
* @method setComponentIndex
* @param {Object} component
*/
ComponentContainer.prototype.setComponentIndex = function( component, index )
{
	if (!this._components)
		return null;
	if (index < 0)
		index = 0;
	var old_index = this._components.indexOf( component );
	if (old_index == -1)
		return;

	this._components.splice( old_index, 1 );

	if (index >= this._components.length)
		this._components.push( component );
	else
		this._components.splice( index, 0, component );

}


/**
* Ensures this node has a component of the specified class, if not it creates one and attaches it
* @method requireComponent
* @param {Object|String} component_class the class to search a component from (could be the class or the name)
* @param {Object} data [optional] the object to configure the component from
* @return {Component} the component found or created
*/
ComponentContainer.prototype.requireComponent = function( component_class, data )
{
	if (!component_class)
		throw ("no component class specified");

	//convert string to class
	if ( component_class.constructor === String )
	{
		component_class = RoomComponents[ component_class ];
		if (!component_class)
		{
			console.error("component class not found:", arguments[0] );
			return null;
		}
	}

	//search component
	var l = this._components.length;
	for (var i = 0; i < l; ++i)
	{
		if ( this._components[i].constructor === component_class )
			return this._components[i];
	}

	var compo = new component_class();
	this.addComponent(compo, l ); //insert before the latest scripts, to avoid situations where when partially parsed the components the component is attached but not parsed yet
	if (data)
		compo.configure(data);
	return compo;
}


/**
* executes the method with a given name in all the components
* @method processActionInComponents
* @param {String} method_name the name of the function to execute in all components (in string format)
* @param {*|Array} params one parameter or an array with every parameter that the function may need
* @param {Boolean} [expand_parameters] [optional] in case the parameters must be expanded as individual
* @param {Boolean} [skip_stop] [optional]
*/
ComponentContainer.prototype.processActionInComponents = function( method_name, params, expand_parameters, skip_stop )
{
	if ( !this._components || !this._components.length )
		return false;
	var r = false;

	for (var i = 0, l = this._components.length; i < l; ++i)
	{
		var comp = this._components[i];
		if ( !comp[method_name] || comp[method_name].constructor !== Function )
			continue;

		if ( comp.callMethod )
			r |= comp.callMethod( method_name, params, true );
		else if ( expand_parameters )
			r |= comp[method_name].apply(comp, params);
		else
			r |= comp[method_name].call(comp, params);

		if ( r && !skip_stop )
			return r;
	}

	return r;
}

/**
* executes the method with a given name in all the components and its children
* @method broadcastMessage
* @param {String} method_name the name of the function to execute in all components (in string format)
* @param {Array} params array with every parameter that the function may need
*/
ComponentContainer.prototype.broadcastMessage = function( method_name, params )
{
	this.processActionInComponents( method_name, params );

	if (this._children && this._children.length )
		for (var i = 0, l = this._children.length; i < l; ++i)
			this._children[i].broadcastMessage( method_name, params );
}

window.ComponentContainer = ComponentContainer;

// @TODO: please, use ComponentContainer by importing
window.ComponentContainer = ComponentContainer;

export { ComponentContainer };
