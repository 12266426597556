/**
 * This parses a text file that contains several text files (they are separated by "\filename"),
 * and returns an object with every file separatly
 */
const processFileAtlas = function (data, skip_trim = false) {
    var lines = data.split("\n");
    var files = {};
    var current_file_lines = [];
    var current_file_name = "";
    for (var i = 0, l = lines.length; i < l; i++) {
        var line = skip_trim ? lines[i] : lines[i].trim();
        if (!line.length)
            continue;
        if (line[0] != "\\") {
            current_file_lines.push(line);
            continue;
        }
        if (current_file_lines.length)
            files[current_file_name] = current_file_lines.join("\n");
        current_file_lines.length = 0;
        current_file_name = line.substr(1);
    }
    if (current_file_lines.length)
        files[current_file_name] = current_file_lines.join("\n");
    return files;
};
export default processFileAtlas;
