import { vec3 } from "gl-matrix";

function nearestToLine2DwithY(p, a, b, out = vec3.create()) {
	const atob = [ b[0] - a[0], 0, b[2] - a[2] ];
	const atop = [ p[0] - a[0], 0, p[2] - a[2] ];
	const len = atob[0] * atob[0] + atob[2] * atob[2];
	const dot = atop[0] * atob[0] + atop[2] * atob[2];
	const t = Math.min(1, Math.max(0, dot / len));
	vec3.scaleAndAdd(out, a, atob, t);
	return out;
}

export default nearestToLine2DwithY;
