import { DEG2RAD, RAD2DEG } from "@src/constants";
import { mat3SetColumn } from "@src/gl-matrix/mat3";

import { mat4GetTranslation, mat4MultiplyVec3, mat4RotateVec3 } from "@src/gl-matrix/mat4";
import { quatFromEuler, quatFromMat3AndQuat, quatToEuler } from "@src/gl-matrix/quat";
import { LEvent } from "@src/libs/LEvent";
import { GL } from "@src/libs/litegl";
import cloneObject from "@src/libs/LiteGL/cloneObject";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { BlendType } from "@src/libs/rendeer/BlendType";
import { Direction3Constants } from "@src/libs/rendeer/Direction3Constants";
import { parseTextConfig } from "@src/libs/rendeer/parseTextConfig";
import { PriorityType } from "@src/libs/rendeer/PriorityType";
import { RayGlobals } from "@src/libs/rendeer/RayGlobals";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { testRayMesh } from "@src/libs/rendeer/testRayMesh";
import { mat3, mat4, quat, vec3, vec4 } from "gl-matrix";
import isNumber from "lodash.isnumber";

/* Temporary containers ************/
const temp_mat3 = mat3.create();
const temp_mat4 = mat4.create();
const temp_vec3 = vec3.create();
const temp_quat = quat.create();


const invmodel = mat4.create();
const world_pos = vec3.create();
const coll_point = vec3.create();
const collision_point = vec3.create();

const origin = vec3.create();
const direction = vec3.create();
const end = vec3.create();
const inv = mat4.create();

let last_object_id = 0;

export class SceneNode {
	/**
	 * SceneNode class to hold an scene item
	 * @class SceneNode
	 * @constructor
	 * @param {object} [json] optional parameter to set various fields, similar to deserializing from JSON
	 */
	constructor(json) {
		if (this.constructor !== SceneNode) {
			throw("You must use new to create SceneNode");
		}

		this._ctor();

		if (json) {
			this.configure(json);
		}
	}

	_ctor() {
		this._uid = last_object_id++;

		/**
		 * A unique identifier, useful to retrieve nodes by name
		 * @type {string}
		 */
		this._id = null;

		/**
		 * An array containing [x,y,z, rotx,roty,rotz,rotw,  sx, sy, sz]
		 * @type {Float32Array}
		 * @private
		 */
		this._transform = new Float32Array(10);

		/**
		 *
		 * @type {Float32Array|vec3}
		 * @private
		 */
		this._position = this._transform.subarray(0, 3);
		/**
		 *
		 * @type {Float32Array|vec4}
		 * @private
		 */
		this._rotation = this._transform.subarray(3, 7);

		/**
		 *
		 * @type {Float32Array|vec3}
		 * @private
		 */
		this._scale = this._transform.subarray(7, 10);

		// initialize transform
		quat.identity(this._rotation);
		this._scale.set(Direction3Constants.ONE);

		this._local_matrix = mat4.create();
		this._global_matrix = mat4.create(); //in global space

		this._must_update_matrix = false;

		//watchers
		//TO DO: use Proxy

		//bounding box in world space
		this.bounding_box = null; //use updateBoundingBox to update it

		//rendering priority (order) bigger means earlier
		this.render_priority = PriorityType.Opaque;

		this.layers = 0x3 | 0; //first two layers

		this.draw_range = null;
		this._instances = null; //array of mat4 with the model for every instance

		this.primitive = GL.TRIANGLES;

		this.primitives = []; //in case this object has multimaterial this will contain { index: submesh index, material: material name, mode: render primitive };

		//assets
		this.mesh = null;

		this.textures = {};
		this.shader = null;

		//could be used for many things
		this.blend_mode = BlendType.None;


		/**
		 * The color in RGBA format
		 * @type {vec4}
		 * @default [1,1,1,1]
		 */
		this._color = vec4.fromValues(1, 1, 1, 1);

		//in case it uses materials
		this.material = null;

		//overwrite callbacks
		if (!this.onRender)
			this.onRender = null;
		if (!this.onShaderUniforms)
			this.onShaderUniforms = null;

		this.flags = {
			visible: true,
			collides: true //for testRay
		};

		//object inside this object
		this.children = [];

		this._uniforms = { u_color: this._color, u_color_texture: 0 };
	}

	/**
	 * Configure this SceneNode to a state from an object (used with serialize)
	 * @method configure
	 * @param {Object} o object with the state of a SceneNode
	 */
	configure(o) {
		let parent = null;

		//copy to attributes
		for (let i in o) {
			switch (i) {
			case "children": //special case
				continue;
			case "uniforms": //special case
				for (var j in o.uniforms)
					this.uniforms[j] = o.uniforms[j];
				continue;
			case "texture":
				this[i] = o[i];
				continue;
			case "flags":
				for (var j in o.flags)
					this.flags[j] = o.flags[j];
				continue;
			case "scale":
			case "scaling":
				vec3.copy(this._scale, [ 1, 1, 1 ]); //reset first
				this.scale(o[i]);
				continue;
			case "tiling":
				if (isNumber(o[i]))
					this.setTextureTiling(o[i], o[i]);
				else
					this.setTextureTiling(o[i][0], o[i][1], o[i][2], o[i][3]);
				continue;
			case "skeleton":
				throw "Rendeer skeletons are not supported, use native features instead";
			case "animations":
				throw "Rendeer animation are not supported, use native features instead";
			case "primitives":
				this.primitives = o.primitives.map(function (a) {
					return Object.assign({}, a);
				}); //clone first level
				continue;
			case "name":
			case "mesh":
			case "material":
			case "ref":
			case "draw_range":
			case "submesh":
			case "skin":
			case "extra":
			case "animation":
				this[i] = o[i];
				continue;
			case "parent":
				parent = o[i];
				break;
			}

			//default
			const v = this[i];
			if (v === undefined)
				continue;

			if (v && v.constructor === Float32Array)
				v.set(o[i]);
			else
				this[i] = o[i];
		}

		this._must_update_matrix = true;

		//update matrix
		this.updateGlobalMatrix();

		if (o.children) {
			this.removeAllChildren();
			for (let i = 0; i < o.children.length; ++i) {
				const child = new SceneNode();
				child.configure(o.children[i]);
				this.addChild(child);
			}
		}

		if (parent) {
			if (this.parentNode)
				console.error("This node already has a parent");
			else
				parent.addChild(this);
		}
	}

	/**
	 *
	 * @param {Ray} ray
	 * @param {vec3} [normal]
	 * @param {vec3} [result]
	 * @param {boolean} in_local
	 * @returns {vec3|null}
	 */
	testRayWithLocalPlane(ray, normal, result, in_local) {
		const model = this.getGlobalMatrix();

		const N = normal || vec3.fromValues(0, 0, 1);
		mat4RotateVec3(N, model, N);
		vec3.normalize(N, N);
		mat4GetTranslation(world_pos, model);

		//wrong direction (from the back)
		if (geo.testRayPlane(ray.origin, ray.direction, world_pos, N, coll_point) === false) {
			return null;
		}

		result = result || vec3.create();

		if (in_local) {
			mat4.invert(invmodel, model);
			vec3.transformMat4(result, coll_point, invmodel);
		} else {
			vec3.copy(result, coll_point);
		}

		return result;
	}

	/**
	 * Tests if the ray collides with this node mesh or the children
	 * @param {GL.Ray} ray the object containing origin and direction of the ray
	 * @param {vec3} result where to store the collision point
	 * @param {Number} max_dist the max distance of the ray
	 * @param {Number} layers the layers bitmask where you want to test
	 * @param {boolean} test_against_mesh if true it will test collision with mesh, otherwise only boundings
	 * @param {boolean} test_primitives
	 * @return {SceneNode|null} the node where it collided
	 */
	testRay(
		ray,
		result=vec3.create(),
		max_dist=Infinity,
		layers=0xFFFF,
		test_against_mesh=false,
		test_primitives=false
	) {

		RayGlobals.tested_objects++;

		let node = null;

		//how to optimize: (now it checks randomly based on order in scene graph)
		//	sort nodes by BB center distance to camera
		//	raytest starting from closer

		//test with this node mesh
		let collided = false;

		if (this.flags.visible === false)
			return null;

		if ((this.layers & layers) && !this.flags.ignore_collisions && this.mesh) {
			collided = this.testRayWithMesh(ray, collision_point, max_dist, layers, test_against_mesh, test_primitives);
		}

		//update closest point if there was a collision
		if (collided) {

			const distance = vec3.distance(ray.origin, collision_point);

			if (max_dist == null || distance < max_dist) {
				max_dist = distance;
				result.set(collision_point);
				node = this;
			}
		}

		// if no children, then return current collision
		if (!this.children || !this.children.length)
			return node;

		// cannot externalize
		const local_result = vec3.create();

		// test against children
		const child_count = this.children.length;
		for (let i = 0; i < child_count; ++i) {
			const child = this.children[i];
			if(!child.testRay) //is native?
				continue;
			const child_collided = child.testRay(ray, local_result, max_dist, layers, test_against_mesh);
			if (!child_collided)
				continue;

			const distance = vec3.distance(ray.origin, local_result);
			if (distance > max_dist)
				continue;

			max_dist = distance;
			result.set(local_result);
			node = child_collided;
		}

		return node;
	}


	/**
	 * Tests if the ray collides with the mesh in this node
	 * @param {GL.Ray} ray the object containing origin and direction of the ray
	 * @param {vec3} coll_point where to store the collision point
	 * @param {number} max_dist the max distance of the ray
	 * @param {number} layers the layers where you want to test
	 * @param {boolean} test_against_mesh if true it will test collision with mesh, otherwise only bounding
	 * @return {boolean} true if it collided
	 */
	testRayWithMesh(ray, coll_point, max_dist, layers, test_against_mesh) {
		if (!this.mesh)
			return false;

		const gl = GL.ctx;

		const mesh = gl.meshes[this.mesh];
		if (!mesh || mesh.ready === false) //mesh not loaded
			return false;

		const group_index = this.submesh == null ? -1 : this.submesh;

		//ray to local
		const model = this._global_matrix;
		mat4.invert(inv, model);
		vec3.transformMat4(origin, ray.origin, inv);
		vec3.add(end, ray.origin, ray.direction);
		vec3.transformMat4(end, end, inv);
		vec3.sub(direction, end, origin);
		vec3.normalize(direction, direction);

		let two_sided = this.flags.two_sided;

		if (this.primitives && this.primitives.length) {
			const material = StaticMaterialsTable[this.primitives[0].material];
			if (material)
				two_sided = material.flags.two_sided;
		}

		return testRayMesh(ray, origin, direction, model, mesh, group_index, coll_point, max_dist, layers, test_against_mesh, two_sided);
	}

	toggleLayerBit(mask) {
		this.layers ^= mask;
	}

	/**
	 *
	 * @param {Material[]} [out]
	 * @returns {Material[]}
	 */
	getAllMaterials(out = []) {

		if (this.material) {
			const mat = StaticMaterialsTable[this.material];
			if (mat !== undefined) {
				out.push(mat);
			}
		}

		if (this.primitives && this.primitives.length) {
			for (let i = 0; i < this.primitives.length; ++i) {
				const prim = this.primitives[i];
				if (prim.material) {
					const mat = StaticMaterialsTable[prim.material];
					if (mat !== undefined)
						out.push(mat);
				}
			}
		}

		const children = this.children;

		if (children) {
			for (let i = 0; i < children.length; ++i) {
				const node = children[i];
				node.getAllMaterials(out);
			}
		}

		return out;
	}

	/**
	 * Crawls the scene tree upwards to find the entity associated with this node
	 * @param {SceneNode} root
	 * @param {boolean} [skip_connections]
	 */
	getParentEntity(root, skip_connections = false) {
		if (!skip_connections) {
			if (!root && this._target_entity)
				return this._target_entity;
			if (this.room_entity)
				return this.room_entity;
		}

		if (this.parentNode)
			return this.parentNode.getParentEntity(this.parentNode);
		return null;
	}

	mustSync(sync) {
		// OVERRITED FROM NATIVE TO SYNC ALL INFO LIKE MATERIALS AND TRANSFORM
	}

	clone(depth) {
		const o = new this.constructor();
		for (let i in this) {
			if (i[0] === "_") //private
				continue;
			if (i === "children") //never copy this
			{
				if (depth)
					for (let j = 0; j < this.children.length; ++j)
						o.addChild(this.children[j].clone(depth));
				continue;
			}
			const v = this[i];
			if (v === undefined)
				continue;
			else if (v === null)
				o[i] = null;
			else if (v.constructor === Object)
				o[i] = cloneObject(v);
			else if (v.constructor === Array)
				o[i] = v.concat();
			else if (o[i] !== v)
				o[i] = v;
		}
		return o;
	}

	/**
	 * Attach node to its children list
	 * @method addChild
	 * @param {SceneNode} node
	 * @param {boolean} [keep_transform] if true the node position/rotation/scale will be modified to match the current global matrix (so it will stay at the same place)
	 */
	addChild(node, keep_transform = false) {
		if (node._parent)
			throw("addChild: Cannot add a child with a parent, remove from parent first");

		node._parent = this;
		if (keep_transform)
			node.fromMatrix(node._global_matrix);

		if (!this.children)
			this.children = [];

		this.children.push(node);

		if (this._scene !== node._scene)
			change_scene(node, this._scene);

		//recursive change all children scene pointer
		function change_scene(node, scene) {
			if (node._scene && node._scene != scene) {
				const index = node._scene._nodes.indexOf(node);
				if (index != -1)
					node._scene._nodes.splice(index, 1);
				if (node.id && node._scene._nodes_by_id[node.id] == node)
					delete node._scene._nodes_by_id[node.id];
			}
			node._scene = scene;
			if (scene) {
				scene._nodes.push(node);
				if (node.id && scene)
					scene._nodes_by_id[node.id] = node;
			}
			if (node.children) {
				let i = 0;
				const l = node.children.length;
				for (; i < l; i++) {
					const child = node.children[i];
					if (child._scene != scene)
						change_scene(child, scene);
				}
			}
		}

		return this; //to chain
	}

	/**
	 * Remove a node from its children list
	 * @method removeChild
	 * @param {SceneNode} node
	 * @param {boolean} [keep_transform]
	 */
	removeChild(node, keep_transform = false) {
		if (node._parent !== this)
			throw("removeChild: Not its children");

		if (!this.children)
			return this;

		const pos = this.children.indexOf(node);
		if (pos === -1)
			throw("removeChild: impossible, should be children");

		this.children.splice(pos, 1);
		node._parent = null;
		if (keep_transform)
			node.fromMatrix(node._global_matrix);
		else
			node._global_matrix.set(node._local_matrix);

		change_scene(node);

		//recursive change all children
		function change_scene(node) {
			if (node._scene) {
				if (node.id && node._scene._nodes_by_id[node.id] == node)
					delete node._scene._nodes_by_id[node.id];
				const index = node._scene._nodes.indexOf(node);
				if (index !== -1)
					node._scene._nodes.splice(index, 1);
			}
			node._scene = null;
			let i = 0;
			const l = node.children.length;
			for (; i < l; i++)
				change_scene(node.children[i]);
		}

		return this;
	}

	removeAllChildren() {
		if (!this.children)
			return;

		while (this.children.length)
			this.removeChild(this.children[0]);
	}

	/**
	 * Remove all childs
	 * @method clear
	 */
	clear() {
		if (!this.children)
			return;

		while (this.children.length)
			this.removeChild(this.children[this.children.length - 1]);
	}

	/**
	 * Remove this node from its parent
	 * @method remove
	 */
	remove() {
		if (!this._parent)
			return;
		this._parent.removeChild(this);
	}

	/**
	 * Change the order inside the children, useful when rendering without Depth Test
	 * @method setChildIndex
	 * @param {SceneNode} child
	 * @param {Number} index
	 */
	setChildIndex(child, index) {
		if (!this.children)
			return;

		const old_index = this.children.indexOf(child);
		if (old_index == -1)
			return;
		this.children.splice(old_index, 1);
		this.children.splice(index, 0, child);
	}

	/**
	 * Recursively retrieves all children nodes (this doesnt include itself)
	 * @method getAllChildren
	 * @param {Array} out [Optional] you can specify an array where all the children will be pushed
	 * @return {Array} all the children nodes
	 */
	getAllChildren(out = []) {

		const children = this.children;

		if (!children) {
			return out;
		}

		const l = children.length;

		for (let i = 0; i < l; i++) {
			const node = children[i];
			out.push(node);
			node.getAllChildren(out);
		}

		return out;
	}

	/**
	 * Recursively retrieves all children nodes taking into account visibility (flags.visible)
	 * @method getVisibleChildren
	 * @param {Array} [result=Array] you can specify an array where all the children will be pushed
	 * @param {number} [layers]
	 * @param {boolean} [layers_affect_children]
	 * @return {Array} all the children nodes
	 */
	getVisibleChildren(result = [], layers = 0xFFFF, layers_affect_children = false) {

		if (!this.children)
			return result;

		if (this.flags.visible === false)
			return result;

		let i = 0;
		const l = this.children.length;
		for (; i < l; i++) {
			const node = this.children[i];
			if (node.flags.visible === false)
				continue;
			const in_layer = (node.layers & layers);
			if (layers_affect_children && !in_layer)
				continue;
			if (in_layer)
				result.push(node);
			node.getVisibleChildren(result, layers);
		}

		return result;
	}

	/**
	 * Returns an object that represents the current state of this object an its children
	 * @method serialize
	 * @return {Object} object
	 */
	serialize() {
		const r = {
			position: [ this._position[0], this._position[1], this._position[2] ],
			rotation: [ this._rotation[0], this._rotation[1], this._rotation[2], this._rotation[3] ],
			scale: [ this._scale[0], this._scale[1], this._scale[2] ],
			children: [],
		};

		if (this.name)
			r.name = this.name;
		if (this.primitives && this.primitives.length)
			r.primitives = this.primitives.map(function (a) {
				return Object.assign({}, a);
			}); //clone first level
		if (this.mesh)
			r.mesh = this.mesh;
		if (this.material)
			r.material = this.material;
		if (this.submesh != null)
			r.submesh = this.submesh;
		if (this.flags)
			r.flags = JSON.parse(JSON.stringify(this.flags));
		if (this.extra)
			r.extra = this.extra;
		if (this.animation)
			r.animation = this.animation;
		if (this.animations) //clone anims
		{
			r.animations = [];
			for (var i = 0; i < this.animations.length; ++i)
				r.animations.push(this.animations[i].serialize());
		}
		if (this.skin) {
			r.skin = {};
			r.skin.joints = this.skin.joints.concat();
			r.skin.skeleton_root = this.skin.skeleton_root;
			r.skin.bindMatrices = [];
			for (var i = 0; i < this.skin.bindMatrices.length; ++i)
				r.skin.bindMatrices.push(typedArrayToArray(this.skin.bindMatrices[i]));
		}
		if (this.skeleton)
			r.skeleton = this.skeleton.serialize();

		if (this.onSerialize)
			this.onSerialize(r);

		if (this.children)
			for (var i = 0, l = this.children.length; i < l; i++) {
				const node = this.children[i];
				r.children.push(node.serialize());
			}

		return r;
	}


	/**
	 * sets the name of the mesh to be used to render the object
	 * @method setMesh
	 * @param {String|Mesh} mesh_name also it accepts a mesh itself
	 */
	setMesh(mesh_name) {
		if (!mesh_name)
			this.mesh = null;
		else if (typeof (mesh_name) == "string")
			this.mesh = mesh_name;
		else
			this._mesh = mesh_name;
	}

	/**
	 * Sets the name of the mesh to be used to render the object
	 * @method setTexture
	 * @param {String} channel which channel to use (the texture will be uploaded to the shader with the name "u_" + channel + "_texture"
	 * @param {String} texture texture name (textures are retrieved from the renderer.textures
	 */
	setTexture(channel, texture) {
		if (!texture)
			this.textures[channel] = null;
		else if (typeof (texture) == "string")
			this.textures[channel] = texture;
	}

	/**
	 * clears position, rotation and scale
	 * @method resetTransform
	 */
	resetTransform() {
		this._position.set(Direction3Constants.ZERO);
		quat.identity(this._rotation);
		this._scale.set(Direction3Constants.ONE);
		this._must_update_matrix = true;
	}

	/**
	 * Translate object in local space
	 * @method translate
	 * @param {vec3} delta
	 * @param {Boolean} local [optional] if true it will rotate the vector according to its rotation
	 */
	translate(delta, local) {
		if (local)
			this.getGlobalVector(delta, temp_vec3);
		else
			temp_vec3.set(delta);
		vec3.add(this._position, this._position, temp_vec3);
		this._must_update_matrix = true;
	}

	moveLocal(delta) {
		this.translate(delta, true);
	}

	/**
	 * Assigns rotation based on euler angles
	 * @method rotate
	 * @param {number|vec3} yaw or euler (rotation in Y)  in radians
	 * @param {number} pitch (rotation in X)  in radians
	 * @param {number} roll (rotation in Z)  in radians
	 */
	setEulerRotation(yaw, pitch, roll) {
		if (yaw && yaw.length >= 3)
			quatFromEuler(this._rotation, yaw);
		else
			quatFromEuler(this._rotation, [ yaw, pitch, roll ]);
		this._must_update_matrix = true;
	}

	/**
	 * returns a vec3 decomposition of .rotation in euler format [yaw,pitch,roll]
	 * @method rotate
	 * @param {vec3} out [optional]  in radians
	 */
	getEulerRotation(out) {
		out = out || vec3.create();
		quatToEuler(out, this._rotation);
		return out;
	}

	/**
	 * Rotate object (supports local or global but doesnt takes into account parent)
	 * @method rotate
	 * @param {number} angle_in_rad
	 * @param {vec3} axis
	 * @param {boolean} in_local specify if the axis is in local space or global space
	 */
	rotate(angle_in_rad, axis, in_local) {
		quat.setAxisAngle(temp_quat, axis, angle_in_rad);

		if (!in_local)
			quat.multiply(this._rotation, this._rotation, temp_quat);
		else
			quat.multiply(this._rotation, temp_quat, this._rotation);
		this._must_update_matrix = true;
	}

	/**
	 * Rotate object passing a quaternion containing a rotation
	 * @method rotateQuat
	 * @param {quat} q
	 */
	rotateQuat(q, in_local) {
		if (!in_local)
			quat.multiply(this._rotation, this._rotation, q);
		else
			quat.multiply(this._rotation, q, this._rotation);
		this._must_update_matrix = true;
	}

	/**
	 * Scale object
	 * @method scale
	 * @param {vec3} v
	 */
	scale(v) {
		if (v.constructor === Number) {
			temp_vec3[0] = temp_vec3[1] = temp_vec3[2] = v;
			vec3.mul(this._scale, this._scale, temp_vec3);
		} else
			vec3.mul(this._scale, this._scale, v);
		this._must_update_matrix = true;
	}

	/**
	 * Places node in position, looking in the target direction,
	 * @method lookAt
	 * @param {vec3} position where to place the node (in local coords)
	 * @param {vec3} target where to look at
	 * @param {vec3} up [optional] the up vector
	 * @param {boolean} reverse [optional] if true if will look the opposite way
	 */
	lookAt(position, target, up, reverse) {
		this.position = position;
		this.orientTo(target, reverse, up);
	}

	/**
	 * Rotate object to face in one direction
	 * @method orientTo
	 * @param {vec3} v
	 */
	orientTo(v, reverse, up, in_local_space, cylindrical) {
		const pos = this.getGlobalPosition();
		//build unitary vectors
		const front = vec3.create();
		if (in_local_space)
			front.set(v);
		else
			vec3.sub(front, pos, v);

		if (cylindrical) //flatten
			front[1] = 0;

		up = up || Direction3Constants.UP;
		vec3.normalize(front, front);
		if (reverse)
			vec3.scale(front, front, -1);
		const temp = mat3.create();
		const right = vec3.cross(vec3.create(), up, front);
		vec3.normalize(right, right);
		const top = vec3.cross(vec3.create(), front, right);
		vec3.normalize(top, top);
		//build mat3
		mat3SetColumn(temp, right, 0);
		mat3SetColumn(temp, top, 1);
		mat3SetColumn(temp, front, 2);
		quat.fromMat3(this._rotation, temp);
		quat.normalize(this._rotation, this._rotation);
		this._must_update_matrix = true;
	}

	/**
	 * Set the pivot point, 0,0,0 by default (WARNING: use flags.pivot = true  to enable the use of the pivot)
	 * @method setPivot
	 * @param {vec3} pivot local coordinate of the pivot point
	 */
	setPivot(pivot) {
		this.pivot = pivot;
	}

	setTextureTiling(tiling_x, tiling_y, offset_x, offset_y) {
		if (!this.texture_matrix) {
			this.texture_matrix = mat3.create();
			this._uniforms["u_texture_matrix"] = this.texture_matrix;
		}

		offset_x = offset_x || 0;
		offset_y = offset_y || 0;

		if (!this.shader)
			this.shader = "texture_transform";

		mat3.identity(this.texture_matrix);
		mat3.translate(this.texture_matrix, this.texture_matrix, [ offset_x, offset_y ]);
		mat3.scale(this.texture_matrix, this.texture_matrix, [ tiling_x, tiling_y ]);
	}

	/**
	 * Get transform local matrix
	 * @method getLocalMatrix
	 * @param {mat4} out [optional] where to copy the result, otherwise it is returned the property matrix
	 * @return {mat4} matrix44
	 */
	getLocalMatrix(out) {
		if (this._must_update_matrix)
			this.updateLocalMatrix();
		if (out) {
			out.set(this._global_matrix);
			return out;
		}
		return this._local_matrix;
	}

	/**
	 * Get transform global matrix (concatenating parents) (its a reference)
	 * @method getGlobalMatrix
	 * @param {mat4} [out] [optional] where to copy the result, otherwise it is returned the property matrix
	 * @param {Boolean} [fast] [optional] it will skip computing the whole ierarchy and reuse the latest stored global matrix (it could be outdated)
	 * @return {mat4} matrix44
	 */
	getGlobalMatrix(out, fast) {
		this.updateGlobalMatrix(fast);
		if (out) {
			out.set(this._global_matrix);
			return out;
		}
		return this._global_matrix;
	}

	/**
	 * Get global rotation (concatenating parent rotations)
	 * @method getGlobalRotation
	 * @param {quat} [result=quat] quaternion to store the result
	 * @return {quat} resulting rotation in quaternion format
	 */
	getGlobalRotation(result) {
		result = result || vec4.create();
		quat.identity(result);
		let current = this;
		const top = this._scene ? this._scene._root : null;
		//while we havent reach the tree root
		while (current != top) {
			quat.multiply(result, current._rotation, result);
			current = current._parent;
		}

		return result;
	}

	/**
	 * recomputes _local_matrix according to position, rotation and scaling
	 * @method updateLocalMatrix
	 */
	updateLocalMatrix() {
		const m = this._local_matrix;
		this._must_update_matrix = false;

		//clear
		mat4.identity(m);

		if (this.flags.no_transform)
			return;

		//pivoted
		if (this.flags.pivot && this._pivot) {
			//m[12] = -this._pivot[0]; m[13] = -this._pivot[1]; m[14] = -this._pivot[2];
			m[12] = this._pivot[0];
			m[13] = this._pivot[1];
			m[14] = this._pivot[2];
		}

		//translate
		mat4.translate(m, m, this._position);

		//rotate
		mat4.fromQuat(temp_mat4, this._rotation);
		mat4.multiply(m, m, temp_mat4);

		//scale
		mat4.scale(m, m, this._scale);

		//pivoted
		if (this.flags.pivot && this._pivot) {
			mat4.translate(m, m, [ -this._pivot[0], -this._pivot[1], -this._pivot[2] ]);
		}

		if(this.onMatrixUpdated)
			this.onMatrixUpdated();
	}

	/**
	 * recomputes _global_matrix according to position, rotation and scaling
	 * @method updateGlobalMatrix
	 * @param {boolean} [fast=false] skips recomputation of parent, use it only if you are sure its already updated
	 * @param {boolean} [update_childs=false] update global matrix in childs too
	 */
	updateGlobalMatrix(fast, update_childs) {
		let global = null;
		if (this._must_update_matrix && !this.flags.no_transform)
			this.updateLocalMatrix();

		if (this._parent && this._parent._transform && this._parent.flags.no_transform !== true) {
			global = fast ? this._parent._global_matrix : this._parent.getGlobalMatrix();
			if (this.flags.no_transform)
				this._global_matrix.set(global);
			else
				mat4.multiply(this._global_matrix, global, this._local_matrix);
		} else //no parent
		{
			this._global_matrix.set(this._local_matrix);
		}

		//propagate to childs
		if (update_childs) {
			for (let i = 0; i < this.children.length; i++)
				this.children[i].updateGlobalMatrix(true, update_childs);
		}
	}

	/**
	 * recompute local and global matrix
	 * @method updateMatrices
	 * @param {boolean} [fast=false] uses the global matrix as it is in the parent node instead of crawling all the ierarchy
	 */
	updateMatrices(fast) {
		this.updateLocalMatrix();
		this.updateGlobalMatrix(fast);
	}

	/**
	 * updates position, rotation and scale from the matrix
	 * @method fromMatrix
	 * @param {mat4} m the matrix
	 * @param {boolean} [is_global=false] if the matrix is in global or local space
	 */
	fromMatrix(m, is_global) {
		if (is_global && this._parent && this._parent._transform) //&& this._parent != this.
		{
			mat4.copy(this._global_matrix, m); //assign to global
			const M_parent = this._parent.getGlobalMatrix(); //get parent transform
			mat4.invert(M_parent, M_parent); //invert
			m = mat4.multiply(this._local_matrix, M_parent, m); //transform from global to local
		}

		//pos
		const M = mat4.clone(m);
		mat4MultiplyVec3(this._position, M, [ 0, 0, 0 ]);

		//scale
		const tmp = vec3.create();
		this._scale[0] = vec3.length(mat4RotateVec3(tmp, M, Direction3Constants.RIGHT));
		this._scale[1] = vec3.length(mat4RotateVec3(tmp, M, Direction3Constants.UP));
		this._scale[2] = vec3.length(mat4RotateVec3(tmp, M, Direction3Constants.BACK));

		const M3 = mat3.fromMat4(temp_mat3, M);
		quatFromMat3AndQuat(this._rotation, M3);

		if (m != this._local_matrix)
			mat4.copy(this._local_matrix, m);

		this._must_update_matrix = false;
	}

	/**
	 * Returns a point multiplied by the local matrix
	 * @method getLocalPoint
	 * @param {vec3} v the point
	 * @param {vec3} [result=vec3] where to store the output
	 * @return {vec3} result
	 */
	getLocalPoint(v, result) {
		result = result || vec3.create();
		if (this._must_update_matrix)
			this.updateLocalMatrix();
		return vec3.transformMat4(result, v, this._local_matrix);
	}

	/**
	 * Returns a point rotated by the local rotation (relative to its parent)
	 * @method getParentVector
	 * @param {vec3} v the point
	 * @param {vec3} [result=vec3] where to store the output
	 * @return {vec3} result
	 */
	getParentVector(v, result) {
		result = result || vec3.create();
		return vec3.transformQuat(result, v, this._rotation);
	}

//LEGACY
	getLocalVector(v) {
		console.error("DEPRECATED: SceneNode.prototype.getLocalVector, use getGlobalVector or getParentVector");
	}

	/**
	 * Returns the node position in global coordinates
	 * @method getGlobalPosition
	 * @param {vec3} [result=optional] where to store the output
	 * @param {Boolean} [fast=optional] uses the current global amtrix without recomputing it, is faster but if the current matrix hasnt been updated the result will be wrong
	 * @return {vec3} result
	 */
	getGlobalPosition(result, fast) {
		result = result || vec3.create();

		if (fast)
			return vec3.transformMat4(result, Direction3Constants.ZERO, this._global_matrix);

		if (!this._parent || !this._parent._transform) {
			result.set(this._position);
			return result;
		}

		const m = this.getGlobalMatrix();
		return vec3.transformMat4(result, Direction3Constants.ZERO, m);
	}

	/**
	 * Returns a point from local coordinates to global (multiplied by the global matrix)
	 * @method localToGlobal
	 * @param {vec3} v the point
	 * @param {vec3} [result=vec3] where to store the output
	 * @param {vec3} fast [Boolean] if true uses the last global matrix computed instead of recomputing it (but it could be outdated)
	 * @return {vec3} result
	 */
	localToGlobal(v, result, fast) {
		result = result || vec3.create();
		const m = this.getGlobalMatrix(null, fast);
		return vec3.transformMat4(result, v, m);
	}

	/**
	 * Transform a point from global coordinates to local coordinates
	 * @method globalToLocal
	 * @param {vec3} v the point
	 * @param {vec3} [result=vec3] where to store the output
	 * @return {vec3} result
	 */
	globalToLocal(v, result) {
		result = result || vec3.create();
		const m = this.getGlobalMatrix();
		mat4.invert(temp_mat4, m);
		return vec3.transformMat4(result, v, temp_mat4);
	}

	/**
	 * Transform a vector from global coordinates to local coordinates
	 * @method globalVectorToLocal
	 * @param {vec3} v the point
	 * @param {vec3} [result=vec3] where to store the output
	 * @return {vec3} result
	 */
	globalVectorToLocal(v, result) {
		result = result || vec3.create();
		const q = this.getGlobalRotation();
		quat.invert(q, q);
		return vec3.transformQuat(result, v, q);
	}

	/**
	 * Returns a point rotated by the global matrix
	 * @method getGlobalVector
	 * @param {vec3} v the point
	 * @param {vec3} [result=vec3] where to store the output
	 * @return {vec3} result
	 */
	getGlobalVector(v, result) {
		result = result || vec3.create();
		const q = this.getGlobalRotation(temp_quat);
		return vec3.transformQuat(result, v, q);
	}

	/**
	 * Returns the distance between the center of the node and the position in global coordinates
	 * @method getDistanceTo
	 * @param {vec3} position the point
	 * @return {number} result
	 */
	getDistanceTo(position) {
		const m = this.getGlobalMatrix();
		return vec3.distance(position, m.subarray(12, 15));
	}

	/**
	 * Searchs the node and returns the first child node with the matching id, it is a recursive search so it is slow
	 * @method findNode
	 * @param {string} id the id of the node
	 * @return {SceneNode} result node otherwise null
	 */
	findNode(id) {
		let i = 0;
		const l = this.children.length;
		for (; i < l; i++) {
			const node = this.children[i];
			if (node.id == id)
				return node;
			const r = node.findNode(id);
			if (r)
				return r;
		}
		return null;
	}

	/**
	 * Searchs the node and returns the first child node with the matching name, it is a recursive search so it is slow
	 * @method findNodeByName
	 * @param {string} name the name of the node
	 * @return {SceneNode} result node otherwise null
	 */
	findNodeByName(name) {
		if (name == null)
			return null;

		let i = 0;
		const l = this.children.length;
		for (; i < l; i++) {
			const node = this.children[i];
			if (node.name == name)
				return node;
			const r = node.findNodeByName(name);
			if (r)
				return r;
		}
		return null;
	}

	/**
	 * Searchs which nodes pass the filter function
	 * @method findNodesByFilter
	 * @param {Function} filter_func a function that receives the node and must return true if it passes
	 * @param {Number} layers [optional] bitmask to filter by layers too
	 * @param {Array} result [optional] where to store the output
	 * @return {Array} array with all the nodes that passed the function
	 */
	findNodesByFilter(filter_func, layers = 0xFFFF, result = []) {
		let i = 0;
		const l = this.children.length;
		for (; i < l; i++) {
			const node = this.children[i];
			if (!(node.layers & layers))
				continue;

			if (!filter_func || filter_func(node))
				result.push(node);

			node.findNodesByFilter(filter_func, layers, result);
		}
		return result;
	}

	/**
	 * calls a function in child nodes
	 * @method propagate
	 * @param {String} method name
	 * @param {Array} params array containing the params
	 */
	propagate(method, params) {
		let i = 0;
		const l = this.children.length;
		for (; i < l; i++) {
			const node = this.children[i];
			if (!node) //�?
				continue;
			//has method
			if (node[method])
				node[method].apply(node, params);
			//recursive
			if (node.children && node.children.length)
				node.propagate(method, params);
		}
	}

	//not used yet
	loadTextConfig(url, callback) {
		const that = this;
		GL.request(url, null, function (data) {
			const info = parseTextConfig(data);
			if (callback)
				callback(info);
		}, alert);
	}

	/**
	 * calls to be removed from the scene
	 * @method destroy
	 * @param { Boolean } force [optional] force to destroy the resource now instead of deferring it till the update ends
	 */
	destroy(force) {
		//in case this node doesnt belong to a scene, we just remove it from its parent
		if (!this.scene || force) {
			if (this._parent)
				this._parent.removeChild(this);
			return;
		}

		//deferred: otherwise we put it pending to destroy
		this.scene._to_destroy.push(this);
	}

	/**
	 * Updates the bounding box in this node, taking into account the mesh bounding box and its children
	 * @method updateBoundingBox
	 * @param { Boolean } force [optional] force to destroy the resource now instead of deferring it till the update ends
	 */
	updateBoundingBox(ignore_children) {
		const model = this._global_matrix;

		const gl = GL.ctx;

		const mesh = gl.meshes[this.mesh];

		let bb = null;
		if (mesh) {
			const mesh_bb = mesh.getBoundingBox();
			if (!this.bounding_box)
				this.bounding_box = BBox.create();
			bb = BBox.transformMat4(this.bounding_box, mesh_bb, model);
		}

		if (ignore_children || !this.children || this.children.length == 0)
			return bb;

		for (let i = 0; i < this.children.length; ++i) {
			const child = this.children[i];
			const child_bb = child.updateBoundingBox();
			if (!child_bb)
				continue;
			if (!bb) {
				bb = this.bounding_box = BBox.create();
				bb.set(child_bb);
			} else
				BBox.merge(bb, bb, child_bb);
		}

		return bb;
	}

	/**
	 * returns the N material
	 * @method getMaterial
	 * @param { Number } index
	 * @return { Material } the material or null if not found
	 */
	getMaterial(index) {
		index = index || 0;

		if (this.material)
			return StaticMaterialsTable[this.material];
		if (this.primitives && this.primitives.length > index)
			return StaticMaterialsTable[this.primitives[index].material];
		return null;
	}

	/**
	 * adjust the rendering range so it renders one specific submesh of the mesh
	 * @method setRangeFromSubmesh
	 * @param {String} submesh_id could be the index or the string with the name
	 */
	setRangeFromSubmesh(submesh_id) {
		if (submesh_id === undefined || !this.mesh) {
			this.draw_range = null;
			return;
		}

		const gl = GL.ctx;

		const mesh = gl.meshes[this.mesh];
		if (!mesh || !mesh.info || !mesh.info.groups) {
			console.warn("you cannot set the submesh_id while the mesh is not yet loaded");
			return;
		}

		//allows to search by string or index
		if (submesh_id.constructor === String) {
			for (var i = 0; i < mesh.info.groups.length; ++i) {
				const info = mesh.info.groups[i];
				if (info.name == submesh_id) {
					submesh_id = i;
					break;
				}
			}

			if (i === mesh.info.groups.length)
				return false; //not found
		}

		const submesh = mesh.info.groups[submesh_id];
		if (!submesh)
			return;

		this.draw_range[0] = submesh.start;
		this.draw_range[1] = submesh.length;
	}

	/**
	 * returns an array of nodes which center is inside the sphere
	 * @method findNodesInSphere
	 * @param {number} layers [optional] bitmask to filter by layer, otherwise 0xFFFF is used
	 */
	findNodesInSphere(center, radius, layers = 0xFFFF, out = []) {

		for (let i = 0; i < this.children.length; ++i) {
			const node = this.children[i];

			if (node.layers & layers) {
				node.getGlobalPosition(temp_vec3, true);
				const dist = vec3.distance(temp_vec3, center);
				if (dist <= radius) {
					out.push(node);
				}
			}

			if (node.children.length) {
				node.findNodesInSphere(center, radius, layers, out);
			}

		}

		return out;
	}

	/**
	 *
	 * @param {number} mask
	 * @param {boolean} v
	 */
	setLayerBit(mask, v) {
		if (v)
			this.layers |= mask;
		else
			this.layers &= ~mask;
	}

	on(event_type, callback, instance) {
		LEvent.bind(this, event_type, callback, instance);
	}

	off(event_type, callback, instance) {
		LEvent.unbind(this, event_type, callback, instance);
	}

	dispatch(event_type, param) {
		LEvent.trigger(this, event_type, param);
	}

	get id() {
		return this._id;
	}

	set id(value) {
		if (this._scene) {
			console.error("Cannot change id of a node already in a scene.");
		} else {
			this._id = value;
		}
	}

	get uniforms() {
		return this._uniforms;
	}

	set uniforms(value) {
		cloneObject(value, this._uniforms)
		this._uniforms["u_color"] = this._color;
	}

	get position() {
		return this._position;
	}

	set position(value) {
		this._position.set(value);
		this._must_update_matrix = true;
	}

	get x() {
		return this._position[0];
	}

	set x(value) {
		this._position[0] = value;
		this._must_update_matrix = true;
	}

	get y() {
		return this._position[1];
	}

	set y(value) {
		this._position[1] = value;
		this._must_update_matrix = true;
	}

	get z() {
		return this._position[2];
	}

	set z(value) {
		this._position[2] = value;
		this._must_update_matrix = true;
	}

	get rotation() {
		return this._rotation;
	}

	set rotation(value) {
		this._rotation.set(value);
		this._must_update_matrix = true;
	}

	get scaling() {
		return this._scale;
	}

	set scaling(value) {
		if (value.constructor === Number)
			this._scale[0] = this._scale[1] = this._scale[2] = value;
		else
			this._scale.set(value);
		this._must_update_matrix = true;
	}

	get eulerAngles() {
		return this.getEulerRotation();
	}

	set eulerAngles(value) {
		this.setEulerRotation(value);
	}

	get eulerAnglesDegrees() {
		const v = this.getEulerRotation();

		v[0] *= RAD2DEG;
		v[1] *= RAD2DEG;
		v[2] *= RAD2DEG;

		return v;
	}

	set eulerAnglesDegrees(value) {
		this.setEulerRotation([ value[0] * DEG2RAD, value[1] * DEG2RAD, value[2] * DEG2RAD ]);
	}

	get transform() {
		return this._transform;
	}

	set transform(value) {
		this._transform.set(value);
		quat.normalize(this._rotation, this._rotation); //ensure it is not deformed
		this._must_update_matrix = true;
	}

	get matrix() {
		return this._local_matrix;
	}

	set matrix(value) {
		this.fromMatrix(value);
	}

	get pivot() {
		return this._pivot;
	}

	set pivot(value) {
		this._must_update_matrix = true;
		if (!value) {
			this._pivot = null;
			this.flags.pivot = false;
			return;
		}
		if (!this._pivot)
			this._pivot = vec3.create();
		this._pivot.set(value);
		this.flags.pivot = true;
	}


	/**
	 * to work with tween
	 */
	get mustUpdate() {
		return this._must_update_matrix;
	}

	/**
	 * to work with tween
	 */
	set mustUpdate(value) {
		if (value) {
			this._must_update_matrix = true;
		}
	}

	get color() {
		return this._color;
	}

	set color(value) {
		this._color.set(value);
	}

	get opacity() {
		return this._color[3];
	}

	set opacity(value) {
		this._color[3] = value;
	}

	get visible() {
		return this.flags.visible;
	}

	set visible(value) {
		this.flags.visible = value;
	}

	get texture() {
		return this.textures["color"];
	}

	set texture(value) {
		this.textures["color"] = value;
	}

	get scene() {
		return this._scene;
	}

	set scene(ignored) {
		throw("cannot set scene, you must use addChild in its parent node");
	}

	get parentNode() {
		return this._parent;
	}

	set parentNode(ignored) {
		throw("Cannot set parentNode of SceneNode");
	}
}

SceneNode.ctor = SceneNode.prototype._ctor; //helper

SceneNode.prototype.setRotationFromEuler = SceneNode.prototype.setEulerRotation;
SceneNode.prototype.getGlobalPoint = SceneNode.prototype.localToGlobal;
SceneNode.prototype.move = SceneNode.prototype.translate;

SceneNode["@position"] = { type: "vec3" };
SceneNode["@rotation"] = { type: "quat" };
















