import lerp from "lerp";

import LoadingManager from "@src/engine/helpers/loadingManager";
import ROOM from "@src/engine/room";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { GL } from "@src/libs/litegl";

import SurfaceAppsContainer from "./SurfaceAppsContainer";
import ImageElement from "./ui/imageElement";

function OSApp( surface )
{
	this.xyz = XYZLauncher.instance;
	this.surface = surface;

	this.info = OSApp.general_info;
	this.resolution_factor = 2;
	this.tabs = {};
	this.apps = {}; //open apps
	this.subapp = null; // sub app instance
	this.mouse_state = null;
	this.mouse_action = null;
	this.click_timeout = null;
	this.images_loaded = false;
	this.header_height = 60;
	this.elements = [];
	this.initialized = false;
	this.participant_cursors = {};

	this.prevent_app_update = false;
	this.os_bar = true;

	this.loadImages();

	var os_settings = {};
	if ( this.surface &&
		this.surface.apps_settings &&
		this.surface.apps_settings.apps &&
		this.surface.apps_settings.apps["OS"] )
		os_settings = this.surface.apps_settings.apps["OS"];
	if ( os_settings.os_bar )
		this.os_bar = os_settings.os_bar;
	var subapp_name = os_settings.subapp || "Home";
	this.instanciateApp( subapp_name );

	LEvent.bind(this.xyz.space, "global_stream_changed", (event, feed) => {
		if ( !feed )
		{
			this.surface._material.uv_transform = null;
		}
	}, this);

	window.os = this;
}

OSApp.app_name = "OS";

//called from first time rendered
OSApp.prototype.init = function( tex )
{
	const w = tex.width;

	this.elements["home-btn"] = new ImageElement( ROOM.images[this.info.images["home_ss"]], {
		crop: { width: "50%" },
		width: 35,
		height: 35,
		x: 60,
		y: this.header_height / 2 - 35 / 2,
		hitbox: { width: 65, height: 65 },
		frames: [ { x: 0 }, { x: "50%" } ]
	});

	this.initialized = true;
}

OSApp.prototype.stateToJSON = function()
{
	const state = {};

	if ( this.subapp )
	{
		state.current_app_name = this.subapp.app_name;

		if (this.subapp.app_name !== "Home" && this.subapp.app_name !== "LockScreen"
				&& this.subapp.stateToJSON )
		{
			if ( !this.apps[ this.subapp.app_name ] )
			{
				this.apps[ this.subapp.app_name ] = {};
			}
			this.apps[ this.subapp.app_name ].state = JSON.parse(this.subapp.stateToJSON());
		}
	}

	state.apps = this.apps;

	return JSON.stringify( state );
}

OSApp.prototype.JSONToState = function( json )
{
	const data = JSON.parse( json );
	this.apps = data.apps;

	if ( data.current_app_name )
	{
		this.instanciateApp( data.current_app_name );
	}
}

OSApp.prototype.loadImages = function()
{
	const manager = new LoadingManager( null, () => {
		this.images_loaded = true;
	});

	for (var i in this.info.images )
	{
		if ( !this.info.images.hasOwnProperty(i) )
			continue;

		ROOM.getImage( this.info.images[i], manager );
	}
}

OSApp.prototype.render = function (ctx, tex, GUI, mouse, viewport)
{
	if (!this.initialized)
		this.init(tex);

	this.viewport = viewport;

	this.renderSubApp( tex, viewport );
}

OSApp.prototype.renderSubApp = function(tex, viewport)
{
	if ( !this.subapp )
		return;

	this.subapp.width = tex.width;
	this.subapp.height = tex.height;

	if (!this.surface.was_active && this.surface.active && this.subapp.onEnter)
		this.subapp.onEnter();
	if (this.surface.was_active && !this.surface.active && this.subapp.onLeave)
		this.subapp.onLeave();

	if ( this.subapp.render )
	{
		tex.drawTo( this.renderInsideUI.bind(this) );
	}

	this.surface._must_render_app_ui = Boolean(this.subapp.renderGlobalUI);
}

OSApp.prototype.renderInsideUI = function( output )
{
	var ctx = null;
	var app_viewport = [ 0,this.header_height,this.viewport[2],this.viewport[3] - this.header_height ];

	if (output.constructor === GL.Texture )
		ctx = gl;
	else if (output.constructor === HTMLCanvasElement )
		ctx = output.getContext("2d");

	// renders the app
	if ( this.subapp.render )
		this.subapp.render( ctx, output, this.surface.GUI, this.surface.GUI.mouse, app_viewport );

	if ( this.os_bar !== false )
		this.renderOSOverlay( output, this.viewport );
}

OSApp.prototype.renderOSOverlay = function(tex, viewport)
{
	var GUI = this.surface.GUI;
	if (this.subapp && this.subapp.app_name === "LockScreen")
		return;

	if ( this.surface.active )
	{
		GUI.setMouse({
			position: this.surface.mouse,
			mousex: this.surface.mouse[0],
			mousey: this.surface.mouse[1],
			buttons: gl.mouse.buttons,
			dragging: gl.mouse.dragging
		});
	}

	this.renderOSBar(tex);
}

OSApp.prototype.renderOSBar = function (tex)
{
	if (!this.initialized || !this.subapp || this.subapp.app_name === "LockScreen" )
		return;

	const w = tex.width;
	const h = tex.height;

	var ctx = gl;
	var mouse = gl.mouse;

	ctx.fillStyle = "#464646";
	ctx.fillRect(0, 0, w, this.header_height);

	var sx = 160;
	var font = "300 25px " + this.xyz.options.fontFamily;

	//ctx.textBaseline = "middle"; // doesnt work with webgl
	for (var i in this.apps)
	{
		if ( !this.apps.hasOwnProperty(i) )
			continue;

		var app_name = i;
		var tab = this.tabs[app_name];
		var tab_padding = 50;
		if ( !tab )
		{
			ctx.font = font;
			var text_width = ctx.measureText(app_name).width;
			var close_size = 20;
			var space = 15;
			this.tabs[app_name] = {
				width: text_width + close_size + space + tab_padding,
				height: this.header_height - 16,
				x: sx,
				y: 8,
				color: "#0089ff",
				close: new ImageElement( ROOM.images[this.info.images["close"]], {
					width: close_size,
					height: close_size,
					x: sx + tab_padding / 2 + text_width + space,
					y: 20,
					hitbox: { width: close_size * 2, height: close_size * 2 }
				})
			};

			tab = this.tabs[app_name];
		}

		// blue OS tab
		ctx.fillStyle = tab.color;
		ctx.beginPath();
		ctx.roundRect(tab.x, tab.y, tab.width, tab.height, tab.height / 2);
		ctx.fill();

		ctx.fillStyle = "white";
		ctx.textAlign = "left";
		ctx.font = font;
		//ctx.fillText(app_name, tab.x + tab_padding / 2, tab.y + tab.height / 1.9); // canvas2d alignment
		ctx.fillText(app_name, tab.x + tab_padding / 2, tab.y + tab.height / 1.32); // webgl alignment

		tab.close.render( ctx );
		sx += tab.width + 15;
	}
	ctx.textBaseline = "alphabetic";

	ctx.font = font;

	this.elements["home-btn"].render( ctx );

	this.renderCursors(ctx, tex, GUI, mouse);

	this.postRender();
}

OSApp.prototype.renderCursors = function(ctx, tex, GUI, mouse)
{
	for ( var i in this.participant_cursors )
	{
		var c = this.participant_cursors[i];

		if ( !c )
		{
			continue;
		}

		var lerped_x = lerp( c.prev_position[0], c.position[0], .5 );
		var lerped_y = lerp( c.prev_position[1], c.position[1], .5 );

		c.prev_position[0] = lerped_x;
		c.prev_position[1] = lerped_y;

		c.img.render( ctx, lerped_x, lerped_y );

		ctx.save();
		ctx.lineWidth = 1;
		ctx.translate(25, 20);
		var padding = 15;
		var label_height = 30;
		ctx.textAlign = "left";
		ctx.textBaseline = "middle";
		ctx.font = "20px " + this.xyz.options.fontFamily;
		if (!c.width)
		{
			c.width = ctx.measureText( c.name ).width;
		}
		ctx.fillStyle = "black";
		ctx.beginPath();
		ctx.roundRect( lerped_x, lerped_y, c.width + padding * 2, label_height, 15 );
		ctx.fill();
		ctx.strokeStyle = "white";
		ctx.stroke();
		ctx.fillStyle = "white";
		//ctx.fillText( c.name, lerped_x + padding, lerped_y + (label_height / 1.8) ); // canvas2d alignment
		ctx.fillText( c.name, lerped_x + padding, lerped_y + (label_height / 1.3) ); // webgl alignment
		ctx.restore();
	}
}

OSApp.prototype.postRender = function()
{
	if ( this.mouse_action === "click" )
	{
		this.mouse_action = null;
	}

	//ROOM.cursor_style = this.cursor_style;
}

OSApp.prototype.update = function(dt, t, mouse)
{
	var GUI = this.surface.GUI;

	//this.cursor_style = "";

	if ( !this.images_loaded || !this.subapp || !this.initialized )
		return;

	if (this.subapp.app_name === "LockScreen" )
	{
		this.subapp.update( dt, t, mouse );
		return;
	}

	// The OS bar visibility can be turned off
	if ( this.os_bar === true )
	{
		this.elements["home-btn"].goToFrame(0);

		for ( var i in this.tabs )
		{
			if ( !this.tabs.hasOwnProperty(i) ) continue;

			var tab = this.tabs[i];

			tab.color = this.subapp.app_name === i ? "#0089ff" : "#343434";

			if ( tab.close.isHovered( GUI ) )
			{
				//this.cursor_style = "pointer";
				tab.color = "#ff0034";
				if (this.mouse_action === "click" )
				{
					if (i === this.subapp.app_name )
					{
						var app_action = {
							action: "load_subapp",
							data: { app_name: "Home" }
						};
						this.sendAction( app_action );
						this.processAction( app_action );
					}

					var app_action = {
						action: "kill_subapp",
						data: { app_name: i }
					};
					this.sendAction( app_action );
					this.processAction( app_action );

					break;
				}
			}
			else if ( GUI.isInsideRect( mouse.position, tab.x, tab.y, tab.width, tab.height ) )
			{
				if (this.mouse_state === "mousedown" )
				{
					if (i !== this.subapp.app_name )
					{
						this.prevent_app_update = true;
					}
				}
				if (i !== this.subapp.app_name )
				{
					//this.cursor_style = "pointer";
					tab.color = "#666666";
					if (this.mouse_action === "click" )
					{
						this.saveSubappState();

						var app_state = this.apps[i] ? this.apps[i].state : null;

						var app_action = {
							action: "load_subapp",
							data: { app_name: i, state: app_state }
						};
						this.sendAction( app_action );
						this.processAction( app_action );
					}
				}
			}
		}

		if ( this.elements["home-btn"].isHovered( GUI ) )
		{
			//this.cursor_style = "pointer";
			this.elements["home-btn"].goToFrame(1);
			if (this.mouse_action === "click" && this.subapp.app_name !== "Home")
			{
				this.prevent_app_update = true;
				this.saveSubappState();
				this.sendAction({ action: "load_subapp", data: { app_name: "Home" } });
				this.processAction({ action: "load_subapp", data: { app_name: "Home" } });
			}
		}
	}

	if ( this.subapp && this.subapp.update && !this.prevent_app_update)
	{
		this.subapp.update(dt, t, mouse);
	}

	//clear
	this.mouse_action = null;
}

OSApp.prototype.onMouseEnter = function(ev)
{
	if ( !this.surface.GUI )
		return;

	if ( this.subapp && this.subapp.onMouseEnter )
	{
		if ( ev.surfaceY > this.header_height ) //over the app area
			this.subapp.onMouseEnter(ev);
		//else //over the bar
	}
}

OSApp.prototype.onMouseLeave = function(ev)
{
	if ( this.subapp && this.subapp.onMouseLeave )
		this.subapp.onMouseLeave(ev);
}

OSApp.prototype.onMouse = function(ev, active)
{
	var GUI = this.surface.GUI;
	if (!this.initialized)
		return;

	if ( ev.eventType === "mouseup" && active )
	{
		this.mouse_state = "mouseup";
		if ( this.click_timeout )
		{
			this.mouse_action = "click";
		}
	}
	else if ( ev.eventType === "mousedown" && active )
	{
		this.mouse_state = "mousedown";
		if ( !this.click_timeout )
		{
			this.click_timeout = setTimeout(() => {
				this.click_timeout = null;
			}, 200);
		}
	}

	if ( this.subapp && this.subapp.onMouse && ev.surfaceY > this.header_height )
	{
		this.subapp.onMouse(ev, active);
	}

	if ( active )
		this.shareCursor( ev );
}

OSApp.prototype.onKeyDown = function(ev, active)
{
	if ( this.subapp && this.subapp.onKeyDown && this.initialized )
		return this.subapp.onKeyDown(ev,active);
}

OSApp.prototype.allowDragMouseAction = function()
{
	if ( this.subapp && this.subapp.allowDragMouseAction)
		return this.subapp.allowDragMouseAction();
	return true;
}

OSApp.prototype.shareCursor = function( ev )
{
	var app_action = {
		action: "show_participant_cursor",
		data: {
			id: this.xyz.space.local_participant.id.toString(),
			x: ev.surfaceX,
			y: ev.surfaceY,
			color: this.participant_color
		}
	};
	this.sendAction( app_action );
}

OSApp.prototype.processAction = function( app_action )
{
	const action = app_action.action;
	const data = app_action.data;

	// subapp action
	if (this.subapp && app_action.subapp === this.subapp.app_name && this.subapp.processAction )
	{
		this.subapp.processAction( app_action );
	}

	// os action
	else if (action === "load_subapp" )
	{
		if ( data.app_state )
		{
			if ( !this.apps )
				this.apps = {};
			this.apps[ data.app_name ].state = JSON.parse(data.app_state);
		}
		this.instanciateApp( data.app_name, data.initiator );
	}
	else if (action === "kill_subapp" )
	{
		this.killApp( data.app_name );
	}
	else if (action === "show_participant_cursor" )
	{
		if ( !this.participant_cursors[ data.id ] )
		{
			var participant = this.xyz.space.getParticipant( data.id );
			if ( participant )
			{
				this.participant_cursors[ data.id ] = {
					name: participant.getUsername(),
					prev_position: [ data.x, data.y ],
					position: [ data.x, data.y ],
					timeout: setTimeout(() => {
						this.participant_cursors[ data.id ] = null;
					}, 3000),
					img: new ImageElement( ROOM.images[this.info.images["cursors"]], {
						crop: { width: 50, height: "100%" },
						width: 25,
						height: 25
					})
				};
			}
		}
		else
		{
			this.participant_cursors[ data.id ].position[0] = data.x;
			this.participant_cursors[ data.id ].position[1] = data.y;
			clearTimeout( this.participant_cursors[ data.id ].timeout );
			this.participant_cursors[ data.id ].timeout = setTimeout(() => {
				this.participant_cursors[ data.id ] = null;
			}, 3000);
		}
	}
	else if (action === "remove_participant_cursor" )
	{
		this.participant_cursors[ data.id ] = null;
	}
	else if (action === "save_app_state" )
	{
		if ( !this.apps[ data.app_name ])
		{
			this.apps[ data.app_name ] = {};
		}
		this.apps[ data.app_name ].state = JSON.parse(data.state);
	}
}

OSApp.prototype.sendAction = function( app_action )
{
	this.xyz.bridge.notify( "EVENT_BCAST_OUT", JSON.stringify({
		type: "director",
		action: "surface_update",
		surface_entity: this.surface.root.uid,
		subaction: "app_action",
		app_action: app_action }) );
}

OSApp.prototype.killApp = function( app_name )
{
	delete this.apps[ app_name ];

	var tab_x = this.tabs[ app_name ].x;
	var tab_width = this.tabs[ app_name ].width;
	delete this.tabs[ app_name ];

	for (var i in this.tabs)
	{
		if ( !this.tabs.hasOwnProperty(i)) continue;

		if (this.tabs[i].x > tab_x)
		{
			this.tabs[i].x -= (tab_width + 15);
			this.tabs[i].close.x -= (tab_width + 15);
		}
	}
}

OSApp.prototype.saveSubappState = function()
{
	if ( this.subapp.stateToJSON )
	{
		var json_state = this.subapp.stateToJSON();
		if ( !this.apps[ this.subapp.app_name ] )
			this.apps[ this.subapp.app_name ] = {};
		this.apps[ this.subapp.app_name ].state = JSON.parse(json_state);
		this.sendAction({ action: "save_app_state", data: { app_name: this.subapp.app_name, state: json_state } });
	}
}

OSApp.prototype.instanciateApp = function( app_name, initiator )
{
	var app_ctor = SurfaceAppsContainer.getAppByName(app_name);

	var state = null;
	var already_exists = false;
	for ( var i in this.apps )
	{
		if ( !this.apps.hasOwnProperty(i) ) continue;

		if (i === app_name )
		{
			already_exists = true;
			state = JSON.stringify(this.apps[i].state);
			break;
		}
	}

	if ( !already_exists )
	{
		this.addTab( app_name );
	}

	if ( app_ctor && !app_ctor.has_errors )
	{
		try
		{
			this.subapp = new app_ctor(this.surface, state);
			this.subapp.app_name = app_name;
			if ( state && this.subapp.JSONToState )
			{
				this.subapp.JSONToState( state );
			}
			if ( initiator )
			{
				this.apps[app_name].initiator = initiator;
			}
		}
		catch (err)
		{
			app_ctor.has_errors = true;
			console.error("error creating surface app: ", app_name, err );
		}
	}

	this.prevent_app_update = false;

	if ( this.surface.active && this.subapp.onEnter )
		this.subapp.onEnter();
}

OSApp.prototype.addTab = function( app_name )
{
	if (app_name === "LockScreen" || app_name === "Home" )
		return;

	this.apps[ app_name ] = {};
}

OSApp.onRenderInspector = function( GUI, x, y, w, h, surface )
{
	var app_settings = {};
	if ( surface && surface.apps_settings && surface.apps_settings.apps && surface.apps_settings.apps[this.app_name] )
		app_settings = surface.apps_settings.apps[this.app_name];

	var subapp = app_settings.subapp || null;
	Label.call(GUI, x + 20, y, 160, 20, "Subapp" );
	var r = GUI.TextField( x + 180, y, w - 235, 20, subapp, false, true, false );
	if ( GUI.value_changed )
	{
		surface.updateAppsSettings("OS", "subapp", r);
	}
	if ( Button.call(GUI, x + w - 50, y, 20, 20, [ 2,0 ] ) )
	{
		var apps = SurfaceAppsContainer.getAppNames();
		apps.unshift("");
		GUI.ShowContextMenu(apps, (i,v) => {
			surface.updateAppsSettings("OS", "subapp", v);
		},"surface_apps");
	}

	y += 22;

	var os_bar = app_settings.os_bar || null;
	Label.call(GUI, x + 20, y, 160, 20, "OS bar visibility" );
	var r = GUI.TextField( x + 180, y, w - 235, 20, os_bar, false, true, false );
	if ( GUI.value_changed )
	{
		surface.updateAppsSettings("OS", "os_bar", r);
	}
	if ( Button.call(GUI, x + w - 50, y, 20, 20, [ 2,0 ] ) )
	{
		var options = [ "true", "false" ];
		options.unshift("");
		GUI.ShowContextMenu(options, (i,v) => {
			surface.updateAppsSettings("OS", "os_bar", v);
		});
	}
	y += 20;
	Label.call(GUI, x + 20, y, 160, 18, "(requires a restart of the OS)" );

	return y;
}

OSApp.general_info = {
	"images": {
		"cursors": "/textures/apps/os/cursors_spritesheet.png",
		"home_ss": "/textures/apps/os/icons/home_ss.png",
		"power_ss": "/textures/apps/os/icons/power_ss.png",
		"close": "/textures/apps/os/icons/close.png"
	}
};

export default OSApp;
