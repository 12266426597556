//used for hashing keys
if (!String.prototype.hasOwnProperty("hashCode")) {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(String.prototype, "hashCode", {
		value: function () {
			let hash = 0,
				i,
				c,
				l;
			if (this.length === 0) return hash;
			for (i = 0, l = this.length; i < l; ++i) {
				c = this.charCodeAt(i);
				hash = (hash << 5) - hash + c;
				hash |= 0; // Convert to 32bit integer
			}
			return hash;
		},
		enumerable: false,
	});
}

