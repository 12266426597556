import HTMLRendererStyles from "./HTMLRenderer/HTMLRenderer.css";

//this class is in charge of creating some html basic objects like an input
class HTMLRenderer {

	constructor(container, canvas) {
		this.head = document.head;
		this.container = container;
		this.canvas = canvas;
		this.prefix = "html-rd-";
		this.styles = null;
		this.element = null;
	}

	append(html, styles, options = {
		clear_before_append: true
	}) {
		if ( options.clear_before_append )
		{
			this.clear();
		}

		if ( styles )
		{
			if ( typeof styles === "string" )
			{
				var style_tag = document.createElement("style");
				style_tag.type = "text/css";
				style_tag.innerHTML = styles;
				this.head.append( style_tag );
			}
			else
			{
				this.head.append( styles );
			}
		}
		this.container.append( html );

		this.styles = styles;
		this.element = html;
	}

	createInput(options = {
		value: "",
		placeholder: "Write here...",
		clear_before_append: true
	}) {

		if ( options.clear_before_append )
		{
			this.clear();
		}

		const styles = HTMLRendererStyles
			.toString()
			.replaceAll("__HTML_PREFIX__", this.prefix);

		this.styles = document.createElement("style");
		this.styles.type = "text/css";
		this.styles.id = this.prefix + "popup__styles";
		this.styles.innerHTML = styles;
		this.head.append(this.styles);

		const popup = document.createElement("div");
		popup.id = popup.className = this.prefix + "popup";

		const popup__background = document.createElement("div");
		popup__background.className = this.prefix + "popup__background";
		popup__background.addEventListener("click", this.animateOut.bind(this));
		popup.append(popup__background);

		const popup__box = document.createElement("div");
		popup__box.className = this.prefix + "popup__box";
		popup.append(popup__box);

		const popup__input = document.createElement("input");
		popup__input.value = options.value;
		popup__input.className = this.prefix + "popup__input";
		popup__input.setAttribute("type", "text");
		popup__input.setAttribute("placeholder", options.placeholder);
		popup__box.append(popup__input);
		popup__input.addEventListener("keydown", (ev) => {
			if (ev.key === "Enter") {
				this.animateOut();
			}
		});

		const popup__submit = document.createElement("div");
		popup__submit.className = this.prefix + "popup__submit";
		popup__submit.innerHTML = "<span>OK</span>";
		popup__submit.addEventListener("click", this.animateOut.bind(this));
		popup__box.append(popup__submit);

		this.container.append(popup);

		popup__input.focus();

		this.element = popup;

		return {
			element: popup,
			background: popup__background,
			box: popup__box,
			input: popup__input,
			submit: popup__submit
		};
	}

	animateOut() {
		this.element.classList.add(this.prefix + "popup--out");
		setTimeout(this.clear.bind(this), 150);
	}

	clear() {
		if (this.styles) {
			this.styles.parentElement.removeChild(this.styles);
			this.styles = null;
		}
		if (this.element) {
			this.element.parentElement.removeChild(this.element);
			this.element = null;
		}
	}
}

window.HTMLRenderer = HTMLRenderer;
