import { RoomComponents } from "@src/engine/RoomComponents";

function InteractiveObjectActionToggleVideo() {
	this._id = -1;
	this.restart = false;
}

InteractiveObjectActionToggleVideo.label = "Toggle Video";

InteractiveObjectActionToggleVideo.prototype.onClick = function(e, entity, _comp) {
	const comp = entity.getComponent(RoomComponents.MediaPlayer);
	if (!comp) {
		return;
	}
	comp.toggle();
	if (this.restart && comp._media) {
		comp._media.currentTime = 0;
	}
};

InteractiveObjectActionToggleVideo.prototype.serialize = function() {
	return {
		restart: this.restart
	};
};

InteractiveObjectActionToggleVideo.prototype.configure = function(o) {
	this.restart = o.restart;
};

export default InteractiveObjectActionToggleVideo;
