const nextPowerOfTwo = (num) => {
    if (num > 0 && (num & (num - 1)) === 0) {
        return num;
    }
    let result = 1;
    while (num > 0) {
        result = result << 1;
        num = num >> 1;
    }
    return result;
};
export default nextPowerOfTwo;
