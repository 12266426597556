// here we store the available surface apps
class SurfaceAppsContainer {
	static _apps = {};

	/**
   * @param app
   */
	static registerApp(app) {
		SurfaceAppsContainer._apps[app.app_name] = app;
	}

	/**
   * @returns {string[]}
   */
	static getAppNames() {
		return Object.keys(SurfaceAppsContainer._apps);
	}

	/**
   * @param name
   * @returns {*}
   */
	static getAppByName(name) {
		return SurfaceAppsContainer._apps[name];
	}
}

export default SurfaceAppsContainer;
