import Entity from "@src/engine/entity";
import { RoomComponents } from "@src/engine/RoomComponents";
import { vec3 } from "gl-matrix";

//Moves one entity/camera to follow a path defined by a spline
function FollowPath()
{
	this.enabled = true;
	this.path = null;
	this.target = null;
	this.factor = 0;
	this.to_camera = false;
}

FollowPath.componentName = "FollowPath";
FollowPath["@path"] = { type: "entity" };
FollowPath["@target"] = { type: "entity" };

FollowPath.componentName = "FollowPath";

FollowPath.prototype.update = function(dt)
{
	if ( !this.path )
		return;

	var path = this.space.getEntity( this.path );
	if ( !path )
		return;

	if ( path.constructor === Entity )
		path = path.getComponent( RoomComponents.Path );
	if ( !path || path.constructor !== RoomComponents.Path )
		return;

	var pos = path.computePoint( this.factor );
	var target_pos = path.computePoint( this.factor + 0.01, null, true );

	var target = null;
	if ( this.target )
		target = this.space.getEntity( this.target );
	if ( target && target.constructor === Entity )
	{
		var target_path = target.getComponent( RoomComponents.Path );
		if ( target_path )
			target_pos = target_path.computePoint( this.factor, null, true );
		else
			target_pos = target.node.position;
	}

	var dist = vec3.distance( pos, target_pos );

	if ( !dist )
		return;

	//lookAt
	if ( this.to_camera )
		ViewCore.instance.hard_camera.lookAt( pos, target_pos, [ 0,1,0 ] );
	else
		this.entity.node.lookAt( pos, target_pos, [ 0,1,0 ] );
}

export default FollowPath;
