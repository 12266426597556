import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";

import MaterialEditorPanel from "./materialEditor";

var NodeInfoPanel = {

	area: [ 300,300, 800,600 ],
	collapsed: true,
	child_index: 0,
	material_index: 0,

	render: function( editor, y )
	{
		var node = editor.selected_node;
		if (!node)
			return y;

		var ctx = gl;

		var w = 400;
		var h = 270;
		if ( this.collapsed )
		{
			w = 200;
			h = 44;
		}

		var x = this.area[0] = gl.canvas.width - (w - 10);
		this.area[1] = y;

		GUI.Panel( x,y,w + 40,h );
		var end_y = y + h + 10;

		//collapser
		GUI.next_tooltip = "Show Node Info";
		var st = GUI.HoverArea( x,y, this.collapsed ? w : 30,44);
		GUI.DrawIcon( x + 20, y + 20, 0.4, this.collapsed ? [ 5,3 ] : [ 13,3 ] );
		if ( st === GLUI.CLICKED )
		{
			this.collapsed = !this.collapsed;
			return end_y;
		}

		//icon
		var color = this.collapsed ? [ 1,1,1,0.5 ] : [ 1,1,1,1 ];
		GUI.HoverArea( x + 30,y + 5, 30,30, { node: node, callback: editor.onDragNode.bind(this) }); 
		GUI.DrawIcon( x + 45, y + 20, 0.3, [ 11,0 ],false, editor.draggable_color );
		Label.call(GUI, x + 60,y+10,w-100,24, node.name || "unnamed", color );
		if (this.collapsed) {
			if (!node.native && node.room_entity && node.room_entity._native) {
				var nativeNode = node.room_entity._native.getRootNode();
				nativeNode.position = node.position;
				nativeNode.rotation = node.rotation;
				nativeNode.scaling = node.scaling;
			}
			return end_y;
		}
		if (node.parentNode)
			if ( Button.call(GUI, x + w - 112,y + 10,24,24, [ 2,2 ] ) )
				editor.selected_node = node.parentNode;

		if ( Button.call(GUI, x + w - 88,y + 10,24,24, [ 8,6 ] ) )
			editor.focusOnEntity( node.getParentEntity(), node );

		if ( Button.call(GUI, x + w - 62,y + 10,24,24, [ 6,3 ] ) )
			editor.selected_item = node.getParentEntity();

		if ( Button.call(GUI, x + w - 36,y + 10,24,24, node.visible ? [ 8,7 ] : [ 9,7 ] ) )
			node.visible = !node.visible;

		y += 40;

		Label.call(GUI, x + 20, y, 100, 24, "Mesh" );
		GUI.TextField( x + 100, y, w-120, 24, node.mesh || "" );
		y += 30;

		Label.call(GUI, x + 20, y, 100, 24, "Material" );
		if (node.material)
		{
			if (Button.call(GUI,x + 100, y, w - 120, 20, node.material) || 1) {
				inner_setmat(node.material);

				if (!editor.selected_material && node.native)
					editor.selected_material = node.getMaterial();
			}

		}
		else if (node.primitives.length)
		{
			if ( node.primitives.length > 1 && Button.call(GUI, x + 100,y,20,20, [ 0,3 ] ) )
				this.material_index = this.material_index > 0 ? (this.material_index - 1) : this.material_index;
			if ( node.primitives.length > 1 && Button.call(GUI, x + w-35,y,20,20, [ 1,3 ] ) )
				this.material_index++;
			this.material_index = Math.min( this.material_index, node.primitives.length - 1 );
			//if( Button.call(GUI, x + 120,y,w-160,20, node.primitives[this.material_index].material ) || 1 )
			inner_setmat( node.primitives[this.material_index].material );
		}

		y += 30;

		y = editor.renderTransform( ctx, node, x+5,y,w-10, node );

		if ( node.children.length)
		{
			Label.call(GUI, x + 20, y, 100, 24, "Children" );
			if ( node.children.length > 1 )
			{
				if ( Button.call(GUI, x + w-25,y,20,20, [ 1,3 ] ) )
					this.child_index = (this.child_index + 1) % node.children.length;
				if ( Button.call(GUI, x + 100,y,20,20, [ 0,3 ] ) )
					this.child_index = this.child_index === 0 ? (node.children.length - 1) : (this.child_index - 1);
			}
			this.child_index = Math.min( this.child_index, node.children.length - 1 );
			if ( Button.call(GUI, x + 120,y,w-140,20, node.children[this.child_index].name || "unnamed" ) )
				editor.selected_node = node.children[this.child_index];
			y += 20;
		}

		function inner_setmat( mat_name )
		{
			editor.selected_material = StaticMaterialsTable[ mat_name ];
			MaterialEditorPanel.collapsed = false;
		}

		return end_y;
	}
};

export default NodeInfoPanel;
