import ROOM from "@src/engine/room";
import { getFullPath } from "@src/engine/Room/file-utils";
import { getExtension } from "@src/engine/Room/getExtension";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { GL } from "@src/libs/litegl";
import { Material } from "@src/libs/rendeer/Material";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import clamp from "@src/math/clamp";

//used to play videos on the room
function MediaPlayer()
{
	this._index = MediaPlayer.last_index++;
	this._enabled = true;
	this._url = "";
	this._mode = "play";
	this.autoplay = true;
	this._target_object = null;
	this.target_material = "";
	this.texture_channel = "albedo";
	//this.color_factor = 1;

	this._volume = 0.25;
	this.restart_on_play = true;
	this._loop = true;
	//this.interactive = false;

	this._current_time = 0;

	//create media object, could be a image, texture, video
	this._media = null;
	//this._media = new Audio();
	this._node = null;

	this._plane = new SceneNode();
	this._plane.name = ":mediaPlayer_" + this._index;
	this._plane.mesh = "plane";
	this._plane.material = "mediaPlayer_" + this._index;

	this._material = new Material();
	this._material.name = this._plane.material;
	this._material.color = [ 1,1,1,1 ];
	StaticMaterialsTable[ this._material.name ] = this._material;
}

MediaPlayer.componentName = "MediaPlayer";
MediaPlayer.last_index = 1;
MediaPlayer.icon = [ 11,7 ];
MediaPlayer.type = ROOM_TYPES.MEDIA;
MediaPlayer.widgets = {
	url: { type:"asset", extensions:"jpg,png,webp,mp3,wav,ogg,mpg,mp4,mpeg,ogv,avi,webm" },
	//interactive: "boolean",
	autoplay: "boolean",
	restart_on_play: "boolean",
	loop: "boolean",
	volume: "number",
	texture_channel: { widget: "combo", values: [ "albedo","emissive","opacity" ] },
};

Object.defineProperty( MediaPlayer.prototype, "enabled", {
	get: function() {
		return this._enabled;
	},
	set: function(v) {
		this._enabled = v;
		if (v)
		{
			if (this._media && this._media.playing && this._media.stop)
				this._media.stop();
		}
		else if ( this._media && this._media.playing === false && this._url )
			this.play();
	}
});

Object.defineProperty( MediaPlayer.prototype, "loop", {
	get: function() {
		return this._loop;
	},
	set: function(v) {
		if (this._loop == v)
			return;
		this._loop = v;
		if (this._media)
			this._media.loop = v;
	}
});

Object.defineProperty( MediaPlayer.prototype, "url", {
	get: function() {
		return this._url;
	},
	set: function(v) {
		if (v != null)
			v = v.trim();
		if (v == this._url)
			return;
		this._url = v;
		this.updateMediaContainer();
	}
});

Object.defineProperty( MediaPlayer.prototype, "volume", {
	get: function() {
		return this._volume;
	},
	set: function(v) {
		this._volume = clamp(v,0,1);
		if (this._media && this._media.volume != null)
		{
			this._media.volume = clamp(this._volume * ROOM_SETTINGS.audio.environment_volume,0,1);
		}
	}
});

MediaPlayer.prototype.onAdded = function(parent)
{
	this._node = parent.node;
	this._node.addChild(this._plane);
}

MediaPlayer.prototype.onRemoved = function(parent)
{
	this._node.removeChild( this._plane );
	this._node = null;
	if (this._media && this._media.pause !== undefined)
		this._media.pause();
	if (this._texture)
	{
		gl.textures[ this._texture.name ] = null;
		this._texture.delete();
		this._texture = null;
	}
}

MediaPlayer.prototype.preRender = function(view)
{
	if (!this._media)
		return;

	var material = this._material;
	this._plane.flags.visible = true;
	if ( this.target_material )
	{
		var mat = StaticMaterialsTable[ this.target_material ];
		if (mat)
		{
			material = mat;
			this._plane.flags.visible = false;
		}
	}

	var media = this._media;

	if (this.enabled)
	{
		if (media.constructor === GL.Texture)
		{
			material.textures[ this.texture_channel ] = media.name;
		}
		else if (media.constructor === HTMLVideoElement)
		{
			media.loop = this._loop;
			if (!this._texture || this._texture.width != media.videoWidth || this._texture.height != media.videoHeight )
			{
				if (this._texture)
					this._texture.delete();
				this._texture = new GL.Texture( media.videoWidth, media.videoHeight, { minFilter: GL.LINEAR, format: GL.RGBA });
				this._texture.name = this._plane.name;
				gl.textures[ this._texture.name ] = this._texture;
			}

			material.textures[ this.texture_channel ] = this._texture.name;
			if ( !material.emissive && this.texture_channel == "emissive" )
				material.emissive = [ 1,1,1 ];

			if (ViewCore.user_clicked && !media.paused && !media.seeking && media.currentTime < (media.duration - 0.1))
			{
				this._texture.uploadImage(media);
				var e = this.entity;
				if (e._native)
				{
					var n = e._native.createNode(this._plane.mesh);
					n.material = this._plane.material;
					n.visible = true;
					nativeEngine.setNodeEmissiveTexture(n, this._texture);
					if (material.alphaMode)
						n.setBlend(material.alphaMode, material._color[3],true);
					if (material.emissive)
						n.setEmissiveFactor(material.emissive[0], material.emissive[1], material.emissive[2]);
					n.setGlobalTransform(this._plane.getGlobalMatrix());
				}
			}
		}
	}
	if (this._plane)
		this._plane.mustSync(true);
}

MediaPlayer.prototype.serialize = function(json)
{
	json.volume = this.volume;
	json.url = this.url;
	json.loop = this._loop;
	//json.interactive = this.interactive;
	json.restart_on_play = this.restart_on_play;
	json.texture_channel = this.texture_channel;
	json.target_material = this.target_material;

	if (this._material.textures)
		for (var i in this._material.textures)
			if ( i[0] == ":" )
				delete this._material.textures[i];
	json.material = this.target_material ? null : this._material.serialize();

	json.autoplay = this.autoplay;
	return json;
}

MediaPlayer.prototype.configure = function(json)
{
	this.volume = json.volume;
	this.loop = json.loop;
	this.autoplay = json.autoplay === undefined ? true : json.autoplay; //legacy
	this.target_material = json.target_material;
	this.restart_on_play = json.restart_on_play || false;
	this.texture_channel = json.texture_channel || "emissive";
	if (json.material)
		this._material.configure( json.material );

	if (!this.autoplay)
		this._mode = "paused";

	this.url = json.url; //last one to use all other settings
}

MediaPlayer.prototype.update = function(dt)
{
	if ( this._enabled && this._media && this._media.play && this._media.paused &&
		this._mode == "play" && ViewCore.user_clicked )
		this.play();

	if (this._media && this._media.volume !== undefined)
		this._media.volume = clamp(this._volume * ROOM_SETTINGS.audio.environment_volume,0,1);
}

//plays the sound
MediaPlayer.prototype.play = function()
{
	if (!this._url)
		return;

	//prevents case where play is called from update when already playing and restarts
	if (this._mode == "play" && !this.restart_on_play && (!this._media.paused || this._media.currentTime == this._media.duration ))
		return;

	this._mode = "play";

	if ( this.restart_on_play && this._media && this._media.currentTime !== undefined )
		this._media.currentTime = 0;
	if ( this._media && this._media.play && ViewCore.user_clicked )
	{
		this._media.play();
		LEvent.trigger(this,"video_start");
	}
}

MediaPlayer.prototype.pause = function()
{
	if (this._media && this._media.pause !== undefined)
	{
		this._media.pause();
		this._mode = "paused";
	}
}

MediaPlayer.prototype.stop = function()
{
	if (this._media && this._media.pause !== undefined)
	{
		this._media.pause();
		this._mode = "paused";
		this._media.currentTime = 0;
	}
}

MediaPlayer.prototype.toggle = function()
{
	if (this._mode == "play")
		this.pause();
	else
		this.play();
}

MediaPlayer.prototype.updateMediaContainer = function()
{
	var ext = getExtension(this._url);
	if (!ext)
		return;
	ext = ext.toLowerCase();
	switch (ext)
	{
	case "png":
	case "jpg":
	case "webp":
		this._media = ROOM.view.loadTexture(this._url);
		break;
	case "webm":
	case "mpg":
	case "avi":
	case "ogm":
	case "mpeg":
	case "mp4":
		this._media = document.createElement("video");
		this._media.src = getFullPath(this._url);
		this._media.crossOrigin = ROOM.crossOrigin;
		this._media.autoplay = this.autoplay;
		this._media.loop = this._loop;
		this._media.crossOrigin = ROOM.crossOrigin;
		this._media.volume = clamp(this._volume * ROOM_SETTINGS.audio.environment_volume,0,1);
		break;
	case "mp3":
	case "wav":
	case "ogg":
		this._media = new Audio();
		this._media.src = getFullPath(this._url);
		this._media.crossOrigin = ROOM.crossOrigin;
		this._media.volume = clamp(this._volume * ROOM_SETTINGS.audio.environment_volume,0,1);
		break;
	default:
		console.warn("unknown media filetype",ext);
		break;
	}

	if (this._media && this._media.addEventListener)
	{
		this._media.addEventListener("canplay", this.onVideoCanPlay.bind(this) );
		this._media.addEventListener("ended",this.onVideoEnd.bind(this) );
	}
}

MediaPlayer.prototype.onVideoCanPlay = function(o)
{
}

MediaPlayer.prototype.onVideoEnd = function(o)
{
	this._mode = "paused";
	LEvent.trigger(this,"video_end");
}

MediaPlayer.prototype.getActions = function(o)
{
	return [ "Play","Pause","Stop","Restart","Toggle" ];
}


MediaPlayer.prototype.getEvents = function(o)
{
	return [ "video_start","video_end" ];
}

MediaPlayer.prototype.onAction = function(v)
{
	if (v == "Play")
		this.play();
	else if (v == "Pause")
		this.pause();
	else if (v == "Stop")
		this.stop();
	else if (v == "Toggle")
		this.toggle();
	else if (v == "Restart")
	{
		if ( this._media && this._media.currentTime !== undefined )
			this._media.currentTime = 0;
	}
}

/**
 * @param ctx
 * @param x
 * @param y
 * @param w
 * @param h
 * @param {RoomEditor} editor
 */
MediaPlayer.prototype.onRenderExtraInspector = function(ctx, x,y,w,h, editor )
{
	var line_height = 24;

	GUI.next_tooltip = "Use selected mat";
	if ( Button.call(GUI, x + w - line_height * 2,y,line_height,line_height, [ 6,4 ] ) )
	{
		if (editor.selected_material)
			this.target_material = editor.selected_material.name;
	}
	if ( Button.call(GUI, x + w - line_height,y,line_height,line_height, GLUI.icons.x ) )
		this.target_material = "";

	Label.call(GUI, x, y,140,line_height, "Target Mat" );
	GUI.next_tooltip = "Target material";
	this.target_material = GUI.TextField( x + 100,y,w-160,line_height, this.target_material || "", null, true );

	y += 40;

	if ( Button.call(GUI,x+10,y,w-20,30,"View Material") )
	{
		editor.selected_material = StaticMaterialsTable[ this._material.name ];
		editor.collapseMaterialPanel(false);
	}
	y += 50;

	if ( Button.call(GUI,x+10,y,30,30,GLUI.icons.play) )
		this.play();
	if ( Button.call(GUI,x+50,y,30,30,GLUI.icons.stop) )
		this.stop();
	y += 50;

}

export default MediaPlayer;
