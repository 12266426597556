import { vec4 }           from "gl-matrix";

import { corner_options } from "./constants";
import positionToArea     from "./positionToArea";

function LGraphGUIToggle()
{
	this.addOutput("v");
	this.properties = { enabled: true, value: true, text:"toggle", position: [ 20,20 ], size: [ 140,40 ], corner: LiteGraph.CORNER_TOP_LEFT };
	this._area = vec4.create();
}

LGraphGUIToggle.title = "GUIToggle";
LGraphGUIToggle.desc = "Renders a toggle widget on the main canvas";
LGraphGUIToggle["@corner"] = corner_options;

LGraphGUIToggle.prototype.onRenderGUI = function(ctx,GUI,tex)
{
	if (!this.getInputOrProperty("enabled"))
		return;

	positionToArea( this.properties.position, this.properties.corner, this._area );
	var parent_pos = this.getInputOrProperty("parent_pos");
	if (parent_pos)
	{
		this._area[0] += parent_pos[0];
		this._area[1] += parent_pos[1];
	}
	this._area[2] = this.properties.size[0];
	this._area[3] = this.properties.size[1];
	this.properties.value = GUI.Toggle( this._area[0],this._area[1],this._area[2],this._area[3], this.properties.value, this.properties.text );
}

LGraphGUIToggle.prototype.onExecute = function()
{
	this.setOutputData(0, this.properties.value );
}

LGraphGUIToggle.prototype.onGetInputs = function() {
	return [ [ "enabled","boolean" ],[ "parent_pos","vec2" ] ];
}

export default LGraphGUIToggle;
