import { SurfaceApp } from "@src/engine/apps/SurfaceApp";
import XYZLauncher from "@src/XYZLauncher";
class ScreenSharingApp extends SurfaceApp {
    constructor(surface) {
        super();
        this.on_focus = false;
        //size will be assigned by Surface
        this.width = 1;
        this.height = 1;
        this.xyz = XYZLauncher.instance;
        this.surface = surface;
    }
    //if "canvas" it will be rendered inside a regular canvas2D and uploaded as a texture every frame, otherwise inside a WebGL texture
    //ScreenSharingApp.output_mode = "canvas";  // or null for default (webgl)
    onEnter() {
        this.on_focus = true;
    }
    onLeave() {
        this.on_focus = false;
    }
    render(ctx, tex, gui, mouse, viewport) {
        const w = this.width = tex.width;
        const h = this.height = tex.height;
        //example of using regular Canvas calls
        ctx.fillColor = this.bg_color;
        ctx.fillRect(0, 0, w, h);
    }
    onMouse(e, active) {
        if (e.type === "mousedown")
            this.on_focus = true;
    }
    onKeyDown(e, active) {
        if (!this.on_focus)
            return;
        if (e.code === "Escape")
            this.on_focus = false;
        else
            return false;
        return true;
    }
}
ScreenSharingApp.app_name = "Screen Sharing";
export default ScreenSharingApp;
