function ImageElement(image, params = {})
{
	if ( !params.rendered ) params.rendered = {};
	this.image = image;
	this.ready = false;
	this.frames = [];
	this.frame = 0;
	this.hovered = false;
	this._hitbox = {};
	this.debug = params.debug || false;
	if ( this.image.naturalWidth == 0 )
	{
		this.image.addEventListener("load", () => {
			this._processParams( params );
		});
		this.x = typeof params.x === "number" ? params.x : 0;
		this.y = typeof params.y === "number" ? params.y : 0;
		this.width = typeof params.width === "number" ? params.width : 0;
		this.height = typeof params.height === "number" ? params.height : 0;
	}
	else
	{
		this._processParams( params );
	}
}

Object.defineProperty( ImageElement.prototype, "hitbox", {
	set: function(v) {
		if ( v.x )
			this._hitbox.x = v.x;
		if ( v.y )
			this._hitbox.y = v.y;
		if ( v.width )
			this._hitbox.width = v.width;
		if ( v.height )
			this._hitbox.height = v.height;
	},
	get: function()
	{
		var v = {
			width: this._hitbox.width || this.width,
			height: this._hitbox.height || this.height
		};
		v.x = this._hitbox.x || this.x - (v.width - this.width)/2;
		v.y = this._hitbox.y || this.y - (v.height - this.height)/2;
		return v;
	}
});

ImageElement.prototype._processParams = function(params)
{
	this.naturalWidth = this.image.naturalWidth;
	this.naturalHeight = this.image.naturalHeight;

	if ( params.crop )
	{
		this.crop = {
			x: typeof params.crop.x === "string" ? this._processStringParam(params.crop.x, "x") : (params.crop.x || 0),
			y: typeof params.crop.y === "string" ? this._processStringParam(params.crop.y, "y") : (params.crop.y || 0),
			width: typeof params.crop.width === "string" ? this._processStringParam(params.crop.width, "x") : params.crop.width || this.naturalWidth,
			height:typeof params.crop.height === "string" ? this._processStringParam(params.crop.height, "y") : params.crop.height || this.naturalHeight
		};
	}

	this.x = typeof params.x === "string" ? this._processStringParam(params.x, "x") : params.x || 0;
	this.y = typeof params.y === "string" ? this._processStringParam(params.y, "y") : params.y || 0;
	this.width = typeof params.width === "string" ? this._processStringParam(params.width, "x") : params.width || this.naturalWidth;
	this.height = typeof params.height === "string" ? this._processStringParam(params.height, "y") : params.height || this.naturalHeight;

	if ( params.hitbox )
	{
		this._hitbox = {
			width: typeof params.hitbox.width === "string" ? this._processStringParam(params.hitbox.width, "x") : params.hitbox.width || this.width,
			height:typeof params.hitbox.height === "string" ? this._processStringParam(params.hitbox.height, "y") : params.hitbox.height || this.height
		};
		this._hitbox.x = typeof params.hitbox.x === "string" ? this._processStringParam(params.hitbox.x, "x") : params.hitbox.x || null;
		this._hitbox.y = typeof params.hitbox.y === "string" ? this._processStringParam(params.hitbox.y, "y") : params.hitbox.y || null;
	}

	this.frames = params.frames || [];
	for (var i = 0; i < this.frames.length; i++)
	{
		var frame = this.frames[i];
		if ( frame.x )
			frame.x = typeof frame.x === "string" ? this._processStringParam(frame.x, "x") : frame.x || 0;

		if ( frame.y )
			frame.y = typeof frame.y === "string" ? this._processStringParam(frame.y, "y") : frame.y || 0;

		if ( frame.width )
			frame.width = typeof frame.width === "string" ? this._processStringParam(frame.width, "x") : frame.width || 0;

		if ( frame.height )
			frame.height = typeof frame.height === "string" ? this._processStringParam(frame.height, "y") : frame.height || 0;
	}

	this.frame = 0;

	this.ready = true;
}

ImageElement.prototype._processStringParam = function(string, axis)
{
	if (string.includes("%"))
	{
		var percent = parseFloat(string) / 100;
		if (axis == "x")
		{
			return percent * this.naturalWidth;
		}
		return percent * this.naturalHeight;
	}
	else if (string.includes("/"))
	{
		var numbers = string.split("/");
		if (axis == "x")
		{
			return this.naturalWidth * numbers[0] / numbers[1];
		}
		return this.naturalHeight * numbers[0] / numbers[1];
	}
}

ImageElement.prototype.goToFrame = function(i)
{
	if ( !this.ready || !this.frames[i] )
	{
		return;
	}

	this.frame = i;

	if ( this.frames[i].x != undefined )
	{
		this.crop.x = this.frames[i].x;
	}

	if ( this.frames[i].y != undefined )
	{
		this.crop.y = this.frames[i].y;
	}

	if ( this.frames[i].width != undefined )
	{
		this.crop.width = this.frames[i].width;
	}

	if ( this.frames[i].height != undefined )
	{
		this.crop.height = this.frames[i].height;
	}
}

ImageElement.prototype.isHovered = function( gui )
{
	if ( !this.ready )
	{
		return false;
	}

	if ( gui )
	{
		this.hovered = gui.isInsideRect( gui.mouse.position, this.hitbox.x, this.hitbox.y, this.hitbox.width, this.hitbox.height );
		return this.hovered;
	}
}

ImageElement.prototype.render = function(ctx, x, y )
{
	if ( !this.ready )
	{
		return;
	}

	if ( !ctx )
	{
		throw "[ImageElement] \"ctx\" parameter not provided in render function."
	}

	if ( x != null )
		this.x = x;
	if ( x != null )
		this.y = y;

	if ( this.crop )
	{
		ctx.drawImage( this.image,
			this.crop.x, this.crop.y,
			this.crop.width, this.crop.height,
			this.x, this.y,
			this.width, this.height );
	}
	else
	{
		ctx.drawImage( this.image,
			this.x, this.y,
			this.width, this.height );
	}
	if ( this.debug )
	{
		ctx.save();
		ctx.globalAlpha = .5;
		ctx.fillStyle = "red";
		ctx.fillRect(this.hitbox.x, this.hitbox.y, this.hitbox.width, this.hitbox.height);
		ctx.restore();
	}
}

export default ImageElement;
