import { GL } from "@src/libs/litegl";
import { vec3 } from "gl-matrix";

const temp_vec3 = vec3.create();

let last_ray_distance = -1;

/**
 *
 * @param {Ray} ray
 * @param {vec3} local_origin
 * @param {vec3} local_direction
 * @param model
 * @param mesh
 * @param {number} group_index
 * @param result
 * @param max_dist
 * @param {number} layers
 * @param {boolean} test_against_mesh
 * @param {boolean} two_sided
 * @returns {boolean}
 */
export function testRayMesh(
	ray,
	local_origin,
	local_direction,
	model,
	mesh,
	group_index,
	result,
	max_dist,
	layers,
	test_against_mesh,
	two_sided
) {

	max_dist = max_dist == null ? Number.MAX_VALUE : max_dist;

	let bb = null;
	let subgroup = null;

	if (group_index === -1) {
		bb = mesh.getBoundingBox();
		subgroup = mesh;
	} else {
		subgroup = mesh.info.groups[group_index];
		bb = subgroup.bounding;
		if (!bb) {
			mesh.computeGroupsBoundingBoxes();
			bb = subgroup.bounding;
		}
	}

	if (!bb) //mesh has no vertices
		return false;

	if (!max_dist)
		max_dist = 10000000;

	//test against object oriented bounding box
	const r = geo.testRayBBox(local_origin, local_direction, bb, null, temp_vec3);
	if (!r) //collided with OOBB
		return false;

	vec3.transformMat4(result, temp_vec3, model);
	let distance = last_ray_distance = vec3.distance(ray.origin, result);

	//there was a collision but too far
	if (distance > max_dist)
		return false;

	//test agains mesh
	if (!test_against_mesh)
		return true;

	//create mesh octree
	if (!subgroup._octree) {
		if (subgroup === mesh)
			subgroup._octree = new GL.Octree(mesh);
		else
			subgroup._octree = new GL.Octree(mesh, subgroup.start, subgroup.length);
	}

	//ray test agains octree
	const hit_test = subgroup._octree.testRay(local_origin, local_direction, 0, max_dist, two_sided);

	//collided the OOBB but not the mesh, so its a not
	if (!hit_test) {
		// no collision
		return false;
	}

	testRayMesh.last_hit_test = hit_test;

	//compute global hit point
	result.set(hit_test.hit);
	vec3.transformMat4(result, result, model);
	distance = last_ray_distance = vec3.distance(ray.origin, result);

	//there was a collision but too far
	return distance <= max_dist;

}


//internal function to reuse computations
testRayMesh.last_hit_test = null;