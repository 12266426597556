import { vec3 } from "gl-matrix";
import { CameraConstraint } from "./CameraConstraint";
import { line3_compute_nearest_point_to_point } from "@woosh/meep-engine/src/core/geom/3d/line/line3_compute_nearest_point_to_point";
import { v3_distance_sqr } from "@woosh/meep-engine/src/core/geom/vec3/v3_distance_sqr";
const scratchV3 = vec3.create();
/**
 * Given a normal vector, build 2 vectors that are at 90° to that vector and each other, forming a tangent and a bi-tangent
 * @param {number[]} normal
 * @param {number[]} u
 * @param {number[]} v
 */
function buildOrthonormalVectors(normal, u, v) {
    if (Math.abs(vec3.dot(normal, [0, 1, 0])) > 0.01) {
        vec3.cross(u, normal, [0, 0, 1]);
        vec3.cross(v, normal, [1, 0, 0]);
    }
    else if (Math.abs(vec3.dot(normal, [1, 0, 0])) > 0.01) {
        vec3.cross(u, normal, [0, 1, 0]);
        vec3.cross(v, normal, [0, 0, 1]);
    }
    else {
        vec3.cross(u, normal, [1, 0, 0]);
        vec3.cross(v, normal, [0, 1, 0]);
    }
}
export class PathPositionConstraint extends CameraConstraint {
    constructor() {
        super(...arguments);
        this.path = [];
    }
    /**
     *
     * @param radius
     * @param resolution How close should points be placed together, the lower the value - the more points will be created and smoother the path
     * @param normal
     * @param offset
     * @returns {PathPositionConstraint}
     */
    static makeRing(radius, resolution = 0.1, normal = [0, 1, 0], offset = [0, 0, 0]) {
        const r = new PathPositionConstraint();
        // build 2 orthogonal vectors on the plane
        const u = vec3.create();
        const v = vec3.create();
        buildOrthonormalVectors(normal, u, v);
        // scale vectors by radius
        vec3.scale(u, u, radius);
        vec3.scale(v, v, radius);
        // figure out number of segments
        const circumference = radius * Math.PI * 2;
        // need at least 3 points to form a bounding shape in 2d space
        const pointCount = Math.max(3, Math.ceil(circumference / resolution));
        for (let i = 0; i < pointCount + 1; i++) {
            const fraction = i / pointCount;
            const angle = fraction * Math.PI * 2;
            const cos = Math.cos(angle);
            const sin = Math.sin(angle);
            const x = cos * u[0] + sin * v[0] + offset[0];
            const y = cos * u[1] + sin * v[1] + offset[1];
            const z = cos * u[2] + sin * v[2] + offset[2];
            r.path[i * 3] = x;
            r.path[i * 3 + 1] = y;
            r.path[i * 3 + 2] = z;
        }
        return r;
    }
    solve(outputState, inputState) {
        const path = this.path;
        const n = path.length;
        if (n < 3) {
            // not enough points to form a path
            return;
        }
        let ax, ay, az, bx, by, bz;
        ax = path[0];
        ay = path[1];
        az = path[2];
        const refX = inputState.position[0];
        const refY = inputState.position[1];
        const refZ = inputState.position[2];
        let bestX = ax;
        let bestY = ay;
        let bestZ = az;
        let best_distance = Infinity;
        for (let i = 3; i < n; i += 3) {
            bx = path[i];
            by = path[i + 1];
            bz = path[i + 2];
            line3_compute_nearest_point_to_point(scratchV3, 0, ax, ay, az, bx, by, bz, refX, refY, refZ);
            const foundX = scratchV3[0];
            const foundY = scratchV3[1];
            const foundZ = scratchV3[2];
            // check distance
            const distSqr = v3_distance_sqr(foundX, foundY, foundZ, refX, refY, refZ);
            if (distSqr < best_distance) {
                best_distance = distSqr;
                bestX = foundX;
                bestY = foundY;
                bestZ = foundZ;
            }
            // swap
            ax = bx;
            ay = by;
            az = bz;
        }
        // write out best found point
        outputState.position[0] = bestX;
        outputState.position[1] = bestY;
        outputState.position[2] = bestZ;
    }
}
