import { RAD2DEG } from "@src/constants";
import ROOM from "@src/engine/room";
import { getFullPath } from "@src/engine/Room/file-utils";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { vec2ComputeSignedAngle } from "@src/gl-matrix/vec2";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { GL } from "@src/libs/litegl";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { Material } from "@src/libs/rendeer/Material";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import remap from "@src/math/remap";
import { mat4, vec2, vec3 } from "gl-matrix";

import BackgroundFragmentShader from "./TableTop/Background.fs.glsl";

//this component allows to define an area to play
//handles also figurines
function TableTop()
{
	this._index = TableTop.last_index++;
	this.enabled = true;
	this.width = 1;
	this.height = 1;
	this.ppu = TableTop.default_ppu; //pixels per unit
	this.background_color = [ 0.1,0.1,0.1 ];
	this.background_texture = "";
	this.flat_illumination = true;
	this.allow_scroll = true;

	this._presets = [];

	//2D scene
	this._scene2D = new ROOM.Scene2D();
	this._scene2D._component = this;

	this._texture = null;
	this._drawing_texture = null;

	//root
	this._node = new SceneNode();

	//board
	this._board = new SceneNode({ //board scale changes to fit the table
		mesh: "planeXZ",
		name: "tabletop_" + this._index,
		material: "tabletop_" + this._index
	});
	this._node.addChild( this._board );

	this._material = new Material({
		name: this._board.material,
		model: "pbrMetallicRoughness",
		albedo: [ 0,0,0 ],
		roughnessFactor: 0.5,
		metallicFactor: 0.1,
		emissive: vec3.create()
	}).register();

	this._table_panel_collapsed = true;
	this._drawing_panel_collapsed = true;
	this._tex_size = [ 0,0 ];

	//drawing
	if (!TableTop.PencilTool.initialized)
		TableTop.PencilTool.init();
	this._brush_color = 0;
	this._brush_size = 20;
	this._brush_type = "line";

	this._pending_strokes = [];

	this._controller = null;
}

TableTop.componentName = "TableTop";
TableTop.icon = [ 5,4 ];
TableTop.type = ROOM_TYPES.GAMES;
TableTop.last_index = 0;

TableTop.palette = [ [ 1,1,1 ],[ 0,0,0 ],[ 0.5,0.5,0.5 ],[ 0, 0.537, 1 ],[ 0, 0.294, 0.7 ],[ 0.57, 0, 0.70 ],[ 0, 0.705, 0.564 ],[ 0.168, 0.705, 0 ],[ 0.33, 0.93, 0.035 ],[ 1, 0.8, 0 ],[ 1, 0.678, 0 ],[ 1, 0, 0.20 ] ];
TableTop["@background_color"] = { type:"color" };
TableTop["@background_texture"] = { type:"string", widget: "asset", extensions:"png,bmp,jpg,jpeg,webp" };

TableTop.default_ppu = 512;

TableTop.prototype.mustUpdate = function()
{
	this._must_update_texture = true;
}

TableTop.prototype.onAdded = function(parent)
{
	parent.tabletop = this;
	parent.node.addChild( this._node );
}

TableTop.prototype.onRemoved = function(parent)
{
	parent.tabletop = null;
	parent.node.removeChild( this._node );
}

TableTop.prototype.onEnterTable = function( controller )
{
	this._controller = controller;
	if ( this.flat_illumination )
	{
		controller.view.setEnvironment(null);
		controller.view.pbrpipeline.bgcolor.set( [ 1,1,1 ] );
	}
}

TableTop.prototype.onLeaveTable = function()
{
	if ( this.space.environment.url )
	{
		var environment_url = getFullPath( ROOM.replaceExtension( this.space.environment.url, "hdre") );
		this._controller.view.setEnvironment( environment_url, this.space.environment.rotation, this.space.environment.exposure );
	}
}

TableTop.prototype.getInteractiveNodes = function( container )
{
	if (!this.enabled)
		return;
	container.push(this._board);
}

TableTop.temp_vec3 = vec3.create();

//spaces:
// world: 3d coordinate of the 3d scene of a point over the table
// table: 3d coordinate in local space (x:-w/2 .. w/2, y==0, z:-h/2 .. h/2) not affected by size scale
// board: 3d coordinate in local space (x,[-0.5..+0.5],y==0) of the board node
// image: 2D coordinate from 0..w,0..h

TableTop.prototype.imageToWorld = function( pos, result )
{
	result = result || vec3.create();
	var m = this._board.getGlobalMatrix();
	var pos3D = TableTop.temp_vec3;
	//image to board
	pos3D[0] = ((pos[0] / this._scene2D.width) - 0.5);
	pos3D[1] = 0;
	pos3D[2] = ((pos[1] / this._scene2D.height) - 0.5);
	//board to world
	vec3.transformMat4(result,pos3D,m);
	return result;
}

TableTop.prototype.worldToImage = function( pos, result )
{
	result = result || vec2.create();
	var m = this._board.getGlobalMatrix();

	//world to board
	var pos3D = TableTop.temp_vec3;
	mat4.invert(m,m);
	vec3.transformMat4( pos3D, pos, m );

	//board to image
	result[0] = (pos3D[0] + 0.5 ) * this._scene2D.width;
	result[1] = (pos3D[2] + 0.5 ) * this._scene2D.height;

	return result;
}

TableTop.prototype.boardToWorld = function( pos, result )
{
	result = result || vec3.create();
	var m = this._board.getGlobalMatrix();
	vec3.transformMat4(result,pos,m);
	return result;
}

TableTop.prototype.worldToTable = function( pos , result )
{
	result = result || vec3.create();
	var m = this._node.getGlobalMatrix();
	mat4.invert(m,m);
	vec3.transformMat4(result,pos,m);
	return result;
}

TableTop.prototype.worldToBoard = function( pos , result )
{
	result = result || vec3.create();
	var m = this._board.getGlobalMatrix();
	mat4.invert(m,m);
	vec3.transformMat4(result,pos,m);
	return result;
}

TableTop.prototype.processClick = function(e)
{
	if (!this.enabled)
		return;

	//orbiting?
	if (!this.space.local_participant.seat && !this.space.local_participant.walking)
		return;

	var controller = this._controller = ROOM.TableViewController.launch(this);
}

TableTop.prototype.isInsideTable = function(pos)
{
	var hw = this.width * 0.5;
	var hh = this.height * 0.5;
	return (pos[0] >= -hw && pos[0] <= hw && pos[2] >= -hh && pos[2] <= hh);
}

TableTop.prototype.setScroll = function(v, sync)
{
	this._scene2D.offset[0] = v[0];
	this._scene2D.offset[1] = v[1];
	this._must_update_texture = true;
	LEvent.trigger( this.entity, "tableSetScroll", v );
}

TableTop.prototype.scrollTable = function(delta, sync)
{
	if (delta[0] || delta[1])
	{
		this._scene2D.offset[0] += delta[0];
		this._scene2D.offset[1] += delta[1];
		this._must_update_texture = true;

		LEvent.trigger( this.entity, "tableMoveScroll", delta );
		LEvent.trigger( this.entity, "tableSetScroll", this._scene2D.offset );
	}

	if (sync)
		this.syncData({
			action: "scroll",
			offset: Array.from(this._scene2D.offset),
			scale:  this._scene2D.scale
		});
}

TableTop.prototype.serialize = function(o)
{
	o.enabled = this.enabled;
	o.width = this.width;
	o.height = this.height;
	o.ppu = this.ppu;
	o.background_color = typedArrayToArray(this.background_color);
	o.background_texture = this.background_texture;
	o.flat_illumination = this.flat_illumination;
	o.allow_scroll = this.allow_scroll;
	o.scene2D = this._scene2D.serialize();
	o.presets = this._presets.concat();
}

TableTop.prototype.configure = function(o)
{
	this.enabled = o.enabled;
	this.width = o.width || 1;
	this.height = o.height || 1;
	this.ppu = o.ppu || TableTop.default_ppu;
	if (o.background_color)
		vec3.copy( this.background_color, o.background_color );
	this.background_texture = o.background_texture || "";
	this.flat_illumination = o.flat_illumination || false;
	this.allow_scroll = o.allow_scroll;

	if (o.scene2D)
		this._scene2D.configure( o.scene2D );

	this._must_update_texture = true;
	this._presets = o.presets ? o.presets.concat() : [];

	this.preloadTextures();
}

TableTop.prototype.preloadTextures = function()
{
	var layers = this._scene2D.preloadResources();
}

TableTop.prototype.updateBoardTexture = function( view )
{
	var that = this;
	var w = Math.ceil( this.width * this.ppu );
	var h = Math.ceil( this.height * this.ppu );

	this._tex_size[0] = w;
	this._tex_size[1] = h;

	if (!this._texture || this._texture.width !== w || this._texture.height !== h )
	{
		if (this._texture)
			this._texture.delete();
		this._texture = new GL.Texture( w, h, { format: gl.RGB });
		this._texture.name = "tabletop_board_texture_" + this._index;
		gl.textures[ this._texture.name ] = this._texture;
		this._material.textures.emissive = this._texture.name;
		vec3.copy( this._material.emissive, [ 1,1,1 ] );

		if (!this._fbo)
			this._fbo = new GL.FBO();
		this._fbo.setTextures([ this._texture ]);
		this._must_update_texture = true;
		console.debug(" - table tex size:",w,h);
	}

	if (!this._must_update_texture && !this._scene2D._must_update )
		return;

	this._fbo.bind();

	//fill with bg
	var tex = null;
	if ( this.background_texture )
		tex = view.loadTexture( this.background_texture, null, function() { that._must_update_texture = true; });
	if (tex) //textured
	{
		var shader = gl.shaders["tabletop_background"];
		if (!shader)
			shader = gl.shaders["tabletop_background"] = new GL.Shader( GL.Shader.SCREEN_VERTEX_SHADER, TableTop.BACKGROUND_FRAGMENT_SHADER );
		shader.toViewport({ u_tiling: 1.0, u_offset: this._scene2D.offset, u_size: tex.width, u_isize: 1 / tex.width, u_color: this.background_color, u_texture: tex.bind(0) });
	}
	else //flat color
	{
		var bg = this.background_color;
		gl.clearColor(bg[0],bg[1],bg[2],1);
		gl.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
	}

	gl.start2D();

	this._scene2D.width = w;
	this._scene2D.height = h;

	this._scene2D.render(gl);

	this._fbo.unbind();
	this._must_update_texture = this._scene2D._must_update = false;
}

TableTop.prototype.preRender = function( view )
{
	//update texture
	this.updateBoardTexture( view );

	//update board
	this._board.scaling = [ this.width,1,this.height ];

	//update texture
	if (this._pending_strokes.length)
	{
		this.prepareDrawingTexture();
		if (this._drawing_texture)
			this._drawing_texture.drawTo( this.updateDrawingTexture.bind(this) );
	}
}

//position is in 3D
TableTop.prototype.drawMarker = function( mode, position, color_index, size, prev_position, sync )
{
	//convert pos to 2D
	var pos2D = this._board.globalToLocal( position );
	var prev_pos2D = prev_position ? this._board.globalToLocal( prev_position ) : null;

	//check texture
	var board_texture = this._texture;
	if (!board_texture)
		return;

	var w = board_texture.width;
	var h = board_texture.height;

	//remap
	pos2D[0] = remap(pos2D[0],-0.5,0.5,0,w);
	pos2D[1] = remap(pos2D[2],-0.5,0.5,0,h);

	if (prev_pos2D)
	{
		prev_pos2D[0] = remap(prev_pos2D[0],-0.5,0.5,0,w);
		prev_pos2D[1] = remap(prev_pos2D[2],-0.5,0.5,0,h);
	}

	this.prepareDrawingTexture();

	var stroke_info = {
		pos: pos2D,
		prev_pos: prev_pos2D,
		size: size,
		color: color_index,
		mode: mode
	};

	this._pending_strokes.push(stroke_info);

	//sync
	if (sync)
		this.syncData({
			action: "draw",
			stroke: stroke_info
		});
}

TableTop.prototype.prepareDrawingTexture = function()
{
	if (this._tex_size[0] === 0)
		return;

	var scale = 1;
	var w = this._tex_size[0] * scale;
	var h = this._tex_size[1] * scale;

	if (!this._drawing_texture || this._drawing_texture.width !== w || this._drawing_texture.height !== h )
	{
		if (this._drawing_texture)
			this._drawing_texture.delete();
		this._drawing_texture = new GL.Texture( w, h, { magFilter: gl.LINEAR });
		this._drawing_texture.name = "drawing_tabletop_" + this._index;
		gl.textures[ this._drawing_texture.name ] = this._drawing_texture;
	}

	if (!this._drawing_plane)
	{
		this._drawing_plane = new SceneNode();
		this._drawing_plane.mesh = "planeXZ";
		this._drawing_plane.name = this._drawing_plane.material = "drawing_tabletop_" + this._index;
		this._drawing_plane.position = [ 0,0.001,0 ];
		this._board.addChild( this._drawing_plane );

		this._drawing_material = new Material();
		this._drawing_material.name = this._drawing_plane.material;
		this._drawing_material.alphaMode = "BLEND";
		this._drawing_material.textures.albedo = this._drawing_texture.name;

		this._drawing_material.register();
	}

}

//draws strokes
TableTop.prototype.updateDrawingTexture = function()
{
	if (!this._pending_strokes.length)
		return;

	var view = xyz.view;
	gl.start2D();
	//gl.scale(
	var brush_texture = view.loadTexture("textures/disc_alpha.png");
	gl.tintImages = true;
	gl.blendFuncSeparate( GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA, GL.ONE, GL.ONE );
	var v2 = vec2.create();
	var current_pos = vec2.create();

	this._drawing_plane.flags.visible = true;

	for (var i = 0; i < this._pending_strokes.length; ++i)
	{
		var stroke = this._pending_strokes[i];
		var size = stroke.size;
		if (stroke.mode === "draw" )
		{
			gl.fillColor = TableTop.palette[ stroke.color ];
			gl.blendFuncSeparate( GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA, GL.ONE, GL.ONE );
			if (stroke.prev_pos)
			{
				var dist = vec2.length(v2);
				if (dist < 0.0001 )
					dist = 0.0001;
				var n = Math.ceil(dist / (size*0.25)); //mindist
				if (n === 0)
					n = 1;
				for (var j = 0; j < n; ++j)
				{
					current_pos[0] = stroke.pos[0] + (v2[0] / n) * j - size * 0.5;
					current_pos[1] = stroke.pos[1] + (v2[1] / n) * j - size * 0.5;
					gl.drawImage( brush_texture, current_pos[0], current_pos[1], size, size );
				}
			}
			else
				gl.drawImage( brush_texture, stroke.pos[0] - size * 0.5, stroke.pos[1] - size * 0.5, size, size );
		}
		else if (stroke.mode === "erase" )
		{
			gl.fillColor = [ 1,1,1,0 ];
			gl.colorMask(false,false,false,true);
			gl.blendFunc( GL.ONE_MINUS_SRC_ALPHA, GL.ZERO );
			gl.drawImage( brush_texture, stroke.pos[0] - size * 0.5, stroke.pos[1] - size * 0.5, size, size );
			gl.colorMask(true,true,true,true);
		}
	}

	gl.blendFuncSeparate( GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA, GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA );//restore
	gl.tintImages = false;
	this._pending_strokes.length = 0;
}

TableTop.vertices = new Float32Array([ -1,0,1, 1,0,1, 1,0,-1, -1,0,-1 ]);

TableTop.prototype.renderGizmo = function( view, editor, selected )
{
	if (!selected)
		return;
}


TableTop.prototype.clearDrawing = function(sync)
{
	if ( !this._drawing_texture )
		return;

	this._drawing_plane.flags.visible = false;
	this._drawing_texture.fill([ 0.5,0.5,0.5,0 ]);
	if (sync)
		this.syncData({
			action: "clear"
		});
}

//called from tableview
TableTop.prototype.renderUI = function( controller )
{
	controller._last_panel_y = 10;

	this.entity.processActionInComponents( "renderTableContent", controller );

	if ( this._controller.current_tool && this._controller.current_tool.render )
		this._controller.current_tool.render( controller.view );

	this.renderBoardUI( controller );
	this.renderDrawingUI( controller );

	GUI.pushStyle();
	GUI.style.controllerColor = [ 1,1,1 ];
	GUI.style.controllerColorHover = [ 0.8,0.8,0.8 ];
	this.entity.processActionInComponents( "renderTableUI", controller );
	GUI.popStyle();
}

TableTop.prototype.renderBoardUI = function( controller )
{
	var that = this;
	var x = 0;
	var y = controller._last_panel_y;
	var w = 300;
	var h = 250;

	if (this._table_panel_collapsed) //hidden
	{
		x = x - w + 50;
		h = 40;
	}

	var ctx = gl;
	GUI.TranslucentPanel(x-40,y,w+40,h,20,[ 0.6,0.6,0.6,1 ]);

	ctx.globalAlpha = 0.5;
	var was_collapsed = this._table_panel_collapsed;
	if (GUI.DrawIcon( x + w - 24, y + 20, 0.5, [ 7,6 ],false,null,null,true ) === GLUI.CLICKED )
		this._table_panel_collapsed = !this._table_panel_collapsed;
	if (this._table_panel_collapsed)
		TableTop.selected_layer_id = null;

	ctx.globalAlpha = 1;

	var itemx = x + w + 10;

	if ( this.allow_scroll )
	{
		if (controller.current_tool === TableTop.ScrollLayerTool )
		{
			if ( GUI.CircleIconButton( itemx, y, GLUI.icons.x,1 ) )
				controller.current_tool = null;
		}
		else if ( GUI.CircleIconButton( itemx, y, [ 3,5 ],1 ) )
		{
			controller.current_tool = TableTop.ScrollLayerTool;
			TableTop.ScrollLayerTool.comp = this;
		}

		itemx += 50;
	}

	//if not centered, show center button
	if ( this._scene2D.offset[0] || this._scene2D.offset[1])
	{
		if ( GUI.CircleIconButton( itemx, y, [ 8,6 ],1 ) )
			this.setScroll([ 0,0 ],true);
		itemx += 50;
	}

	if ( GUI.CircleIconButton( itemx, y, [ 10,8 ] ) )
		this.shareView();

	for (var i = 0; i < 4; ++i)
	{
		if ( !this._presets[i] )
			continue;
		if ( GUI.CircleIconButton( itemx, y, String.fromCharCode(65+i) ) )
			this.restorePreset(i);
		itemx += 50;
	}

	controller._last_panel_y += 70;
	if (this._table_panel_collapsed || was_collapsed)
		return;

	var selected_layer = TableTop.selected_layer_id != null ? this._scene2D.root.getLayer( TableTop.selected_layer_id ) : null;

	if (selected_layer && selected_layer !== this._scene2D.root )
	{
		if ( selected_layer.url !== undefined && Button.call(GUI, x + 10, y + 10,30,30, GLUI.icons.image,false,0 ) )
			this.showSelectImageDialog();

		if ( Button.call(GUI, x + 40, y + 10,30,30, GLUI.icons.text,false,0 ) )
			this.showSelectNameDialog();

		if (controller.current_tool === TableTop.ManipulateLayerTool )
		{
			if ( Button.call(GUI, x + 70, y + 10,30,30, GLUI.icons.x,false,0 ) )
				controller.current_tool = null;
		}
		else if ( Button.call(GUI, x + 70, y + 10,30,30, [ 0,5 ],false,0 ) )
		{
			controller.current_tool = TableTop.ManipulateLayerTool;
			TableTop.ManipulateLayerTool.comp = this;
		}

		if ( Button.call(GUI, x + 100, y + 10,30,30, [ 2,3 ],false,0 ) )
			this.moveLayer(-1);

		if ( Button.call(GUI, x + 130, y + 10,30,30, [ 3,3 ],false,0 ) )
			this.moveLayer(1);

		if ( Button.call(GUI, x + 160, y + 10,30,30, [ 10,8 ],false,0 ) )
			this.adjustLayer();
	}

	if ( Button.call(GUI, x + w - 80, y + 10,30,30, [ this.allow_scroll ? 11 : 10, 5 ],null,0,null,5 ) )
		this.allow_scroll = !this.allow_scroll;

	if (!this.allow_scroll && controller.current_tool === TableTop.ScrollLayerTool )
		controller.current_tool = null;

	var item_y = y + 45;
	this._scene2D.root.flags.open = true;

	var total_height = this._scene2D.numLayers() * 24;
	if (!this._layers_scroll)
		this._layers_scroll = GUI.createScrollArea(w,160,total_height);
	this._layers_scroll.total = total_height;
	GUI.ScrollableArea( this._layers_scroll, x, item_y, w-10, 160, inner,null, [ 0.2,0.2,0.2,0.5 ], [ 0.6,0.6,0.6,0.5 ] );

	function inner(x,y,w,h)
	{
		that.renderLayerListUI( ctx, that._scene2D.root, x, y, w-10, h );
	}

	ctx.globalAlpha = 1;

	//Bottom part ...

	for (var i = 0; i < 4; ++i)
	{
		if ( Button.call(GUI, x + 10 + 28 * i, y + h - 34,24,24, String.fromCharCode(65+i), !this._presets[i], 0,null,5 ) )
			this.savePreset(i);
	}
	if ( this._presets.length && Button.call(GUI, x + 10 + 28 * 4, y + h - 34,24,24, GLUI.icons.x,false,0 ) )
		this._presets.length = 0;

	if ( selected_layer && Button.call(GUI, x + w - 120, y + h - 40,30,30, GLUI.icons.trash,false,0 ) )
	{
		selected_layer._parent.removeLayer( selected_layer );
		this.syncBoard();
	}

	if ( Button.call(GUI, x + w - 80, y + h - 40,30,30, [ 2,0 ],false,0 ) )
	{
		var layer = new ROOM.Scene2D.Group();
		layer.name = "group_" + this._scene2D.last_layer_id;
		this.addLayerToSelected( layer );//, TableTop.selected_layer_id );
	}

	if ( Button.call(GUI, x + w - 40, y + h - 40,30,30, [ 7,6 ],false,0 ) )
	{
		var layer = new ROOM.Scene2D.Layer();
		layer.name = "layer_" + this._scene2D.last_layer_id;
		this.addLayerToSelected( layer );//, TableTop.selected_layer_id );
		this.adjustLayer(layer);
		this.showSelectNameDialog();
	}

	controller._last_panel_y += 210;
}

TableTop.prototype.renderLayerListUI = function(ctx, layer, x, y, w )
{
	var selected = TableTop.selected_layer_id === layer.id;
	ctx.globalAlpha = layer.enabled ? 1 : 0.5;

	var icon = null;
	if (layer.constructor === ROOM.Scene2D.Layer )
		icon = [ 4,1 ];
	else if (layer.constructor === ROOM.Scene2D.Group )
		icon = layer.flags.open ? [ 2,1 ] : [ 2,0 ];

	if ( icon )
	{
		if (GUI.DrawIcon( x + 20, y + 10, 0.25, icon, null, layer.flags.open ? [ 0.6,0.7,0.8,1 ] : [ 0.5,0.6,0.7,1 ],null,!layer._is_root ) === GLUI.CLICKED)
		{
			if (layer.flags.open != null)
				layer.flags.open = !layer.flags.open;
		}
	}

	var r =  Label.call(GUI,32 + x, y, 100, 20, layer._is_root ? "Layers" : layer.name, [ 1,1,1, selected ? 1 : 0.6 ],null,true );
	if (r === GLUI.CLICKED)
		TableTop.selected_layer_id = layer.id;
	else if (r)
		GUI.cursor = "pointer";

	if ( !layer._is_root && Button.call(GUI,w - 30,y,20,20, layer.enabled ? [ 8,7 ] : [ 9,7 ],false,0) )
	{
		layer.enabled = !layer.enabled;
		this.syncLayer(layer);
		this._must_update_texture = true;
	}

	y += 20;

	if (layer._layers && layer.flags.open)
	{
		var layers = layer._layers;
		for (var i = 0; i < layers.length; ++i)
			y = this.renderLayerListUI( ctx, layers[i], x + 20, y, w );
	}

	return y;
}

TableTop.prototype.addLayerToSelected = function( layer )
{
	var container = this._scene2D.root;
	var selected_layer = null;
	var index = -1;
	if (TableTop.selected_layer_id != null)
	{
		selected_layer = this._scene2D.root.getLayer( TableTop.selected_layer_id );
		if (selected_layer)
		{
			if (selected_layer._layers)
			{
				container = selected_layer;
				index = 0;
			}
			else
				index = selected_layer.getIndex();
		}
	}

	container.addLayer( layer, index );
	TableTop.selected_layer_id = layer.id;
}

TableTop.prototype.moveLayer = function(offset)
{
	if (TableTop.selected_layer_id == null)
		return;
	var layer = this._scene2D.root.getLayer( TableTop.selected_layer_id );
	if (!layer)
		return;

	var layers = layer._parent._layers;
	var index = layers.indexOf( layer );
	if (index === 0 && offset === -1)
		return;
	if (index === layers.length - 1 && offset === 1)
		return;

	layers.splice(index,1);
	if (offset === -1)
		layers.splice(index-1,0,layer);
	else
		layers.splice(index+1,0,layer);
	this._must_update_texture = true;
	this.syncBoard();//sync all, easier
}

TableTop.prototype.adjustLayer = function( layer )
{
	if (!layer)
	{
		if (TableTop.selected_layer_id == null)
			return;
		layer = this._scene2D.root.getLayer( TableTop.selected_layer_id );
	}
	if (!layer)
		return;
	//layer.position = [0,0];
	layer.position = [ this.width * this.ppu * 0.5,this.height * this.ppu * 0.5 ];
	layer.angle = 0;
	this._must_update_texture = true;
	this.syncBoard();//sync all, easier
}

TableTop.prototype.renderDrawingUI = function(controller)
{
	var x = 0;
	var y = controller._last_panel_y;
	var w = 180;
	var h = 40;

	if (this._drawing_panel_collapsed) //hidden
		x = x - w + h + 10;

	var ctx = gl;
	GUI.TranslucentPanel(x-40,y,w+40,h,20,[ 0.6,0.6,0.6,1 ]);

	ctx.globalAlpha = 0.5;
	if (GUI.DrawIcon( x + w - 24, y + h * 0.5, 0.5, [ 5,6 ],false,null,null,true ) === GLUI.CLICKED )
		this._drawing_panel_collapsed = !this._drawing_panel_collapsed;
	ctx.globalAlpha = 1;

	if (this._drawing_panel_collapsed && controller.current_tool === TableTop.PencilTool)
		controller.current_tool = null;

	if (this._drawing_panel_collapsed)
		return controller._last_panel_y += 70;

	if (controller.current_tool === TableTop.PencilTool && TableTop.PencilTool.mode === "draw")
	{
		if ( GUI.CircleIconButton( x + 20, y, GLUI.icons.x, 1 ) )
			controller.current_tool = null;
	}
	else if ( GUI.CircleIconButton( x + 20, y, [ 5,6 ], 1 ) )
	{
		controller.current_tool = TableTop.PencilTool;
		TableTop.PencilTool.enable( controller, "draw", this );
	}

	if (controller.current_tool === TableTop.PencilTool && TableTop.PencilTool.mode === "erase")
	{
		if ( GUI.CircleIconButton( x + 80, y, GLUI.icons.x, 1 ) )
			controller.current_tool = null;
	}
	else if ( GUI.CircleIconButton( x + 80, y, [ 6,6 ], 1 ) )
	{
		controller.current_tool = TableTop.PencilTool;
		TableTop.PencilTool.enable( controller, "erase", this );
	}

	controller._last_panel_y += 70;
}

TableTop.prototype.showSelectImageDialog = function()
{
	var layer = this._scene2D.getLayer( TableTop.selected_layer_id );
	if (!layer)
		return;

	var that = this;

	var url = prompt("select board url", layer.url || "");
	if (url == null)
		return;
	layer.url = url || "";

	var view = xyz.view;
	var texture = view.loadTextureProxy( layer.url, null, inner );
	if (texture && texture.width > 1)
		inner(texture);

	this.syncLayer(layer);

	function inner(tex)
	{
		if (!tex)
			return;
		layer.size[0] = tex.width;
		layer.size[1] = tex.height;
		that.syncLayer(layer);
		that._must_update_texture = true;
	}
}

TableTop.prototype.showSelectNameDialog = function()
{
	var layer = this._scene2D.getLayer( TableTop.selected_layer_id );
	if (!layer)
		return;
	var r = prompt("Select name", layer.name );
	if ( r == null )
		return;
	layer.name = r;
	this.syncLayer( layer );
}

TableTop.prototype.shareView = function()
{
	var view = this._controller.view;
	var camera = view._last_camera;

	this.syncData({
		action: "view",
		data: camera.serialize()
	});
}

TableTop.prototype.savePreset = function(index)
{
	if (index == null)
		index = this._presets.length;
	var layers = this._scene2D.root.getAllLayers();
	var r = {
		offset: Array.from( this._scene2D.offset ),
		scale: this._scene2D.scale,
		layers: {}
	};
	for (var i = 0; i < layers.length; ++i)
	{
		var layer = layers[i];
		var info = {
			enabled: layer.enabled
		};
		if ( layer.constructor === ROOM.Scene2D.Layer )
			layer.serialize(info);
		r.layers[ layer.id ] = info;
	}

	//so other table stuff can add info
	this.entity.processActionInComponents( "savePresetData", r );

	this._presets[index] = r;
}

TableTop.prototype.restorePreset = function(index)
{
	if (index == null || !this._presets[index] )
		return;
	var r = this._presets[index];
	var layers_info = r.layers;
	var layers = this._scene2D.root.getAllLayers();
	for (var i = 0; i < layers.length; ++i)
	{
		var layer = layers[i];
		var info = layers_info[ layer.id ];
		if (!info)
			continue;
		if (layer.constructor === ROOM.Scene2D.Group)
			layer.enabled = info.enabled || false;
		else if (layer.constructor === ROOM.Scene2D.Layer)
			layer.configure(info);
	}

	//so other table stuff can restore info
	this.entity.processActionInComponents( "restorePresetData", r );

	this._must_update_texture = true;
	//this._scene2D.configure( this._presets[index] );
	this.syncBoard();
}


//called from RoomTableViewController.onKeyDown
TableTop.prototype.onKeyDown = function(e)
{
	if ( this.entity.processActionInComponents( "onTableKeyDown", e ) )
		return true;

	if (e.code === "Escape" && this._controller.current_tool )
	{
		this._controller.current_tool = null;
		return true;
	}

	switch (e.code)
	{
	default: return false;
	}

	return true;
}


TableTop.prototype.onSyncData = function(data)
{
	var controller = RoomTableViewController.instance;

	if (data.action === "scene" )
		this._scene2D.configure( data.scene );
	else if (data.action === "scroll" )
	{
		vec2.copy( this._scene2D.offset, data.offset );
		this._scene2D.scale = data.scale;
		this.mustUpdate();
	}
	else if (data.action === "view" )
	{
		controller.view.hard_camera.configure( data.data );
	}
	else if (data.action === "layer" )
	{
		var layer = this._scene2D.getLayer( data.layer_id );
		if (layer)
		{
			layer.configure( data.layer_data );
			this.mustUpdate();
		}
	}
	else if (data.action === "draw" )
		this._pending_strokes.push( data.stroke );
	else if (data.action === "clear" )
		this.clearDrawing();

}

TableTop.prototype.syncBoard = function()
{
	this.syncData({
		action: "scene",
		scene: this._scene2D.serialize()
	});
}

TableTop.prototype.syncLayer = function(layer)
{
	if (!layer || layer._scene !== this._scene2D )
		return;

	this.syncData({
		action: "layer",
		layer_id: layer.id,
		parent_id: layer._parent ? layer._parent.id : null,
		layer_data: layer.serialize()
	});
}



TableTop.PencilTool = {
	initialized: false,
	comp: null,
	drawing: false,
	mode: "draw",
	prev_pos: vec3.create(),
	controller: null,

	init: function()
	{
		if (this.initialized)
			return;
		var view = xyz.view;
		var brush_texture = view.loadTexture("textures/disc_alpha.png",null, inner);
		this.initialized = true;

		function inner()
		{
			gl.colorMask(true,true,true,false);
			brush_texture.fill([ 1,1,1,1 ]);
			gl.colorMask(true,true,true,true);
		}
	},

	enable: function(controller, mode, comp)
	{
		this.controller = controller;
		this.mode = mode;
		this.comp = comp;
		this.controller.selectItem(null);
	},

	render: function()
	{
		var ctx = gl;
		var comp = this.comp;

		//toolbar
		var centerx = gl.canvas.width * 0.5;
		var y = gl.canvas.height - 200;
		var w = this.mode === "draw" ? 590 : 200;
		var h = 74;
		var x = centerx - w*0.5;
		GUI.TranslucentPanel(x,y,w,h,40,[ 1.4,1.4,1.4,1 ]);

		//logo
		ctx.globalAlpha = 0.5;
		GUI.DrawIcon( x + 36, y + 36, 0.5, this.mode === "draw" ? [ 5, 6 ] : [ 6, 6 ] );
		ctx.globalAlpha = 1;

		if (this.mode === "draw" )
		{
			//color palette
			var palette = TableTop.palette;
			for (var i = 0; i < palette.length; ++i)
			{
				if (comp._brush_color === i)
					GUI.DrawIcon( x + 80 + i * 30, y + 20, 0.65, [ 1,1 ], false, palette[3] );
				if (GUI.DrawIcon( x + 80 + i * 30, y + 20, 0.45, GLUI.icons.circle, false, palette[i], null, true ) === GLUI.CLICKED )
					comp._brush_color = i;
			}

			var brush_sizes = [ 20,40,80 ];

			for (var i = 0; i < 3; ++i)
			{
				if (GUI.DrawIcon( x + 80 + 40 * i, y + 50, 0.65, [ i,9 ], false, palette[comp._brush_color], null,true ) === GLUI.CLICKED )
					comp._brush_size = brush_sizes[i];
				if (comp._brush_size === brush_sizes[i] )
					GUI.DrawIcon( x + 80 + 40 * i, y + 60, 0.3, [ 0,1 ], false, palette[3] );
			}

			if (comp._brush_type === "line" )
				GUI.DrawIcon( x + 220, y + 60, 0.3, [ 0,1 ], false, palette[3] );
			if (GUI.DrawIcon( x + 220, y + 50, 0.65, [ 1,6 ], false, palette[comp._brush_color], null,true ) === GLUI.CLICKED )
				comp._brush_type = "line";

			if (comp._brush_type === "dots" )
				GUI.DrawIcon( x + 250, y + 60, 0.3, [ 0,1 ], false, palette[3] );
			if (GUI.DrawIcon( x + 250, y + 50, 0.65, [ 3,9 ], false, palette[comp._brush_color], null,true ) === GLUI.CLICKED )
				comp._brush_type = "dots";
		}

		//trash
		if ( GUI.CircleIconButton( x + w - 130, y + 10, GLUI.icons.trash, [ 0.8,0,0.2,1 ], true ) )
			comp.clearDrawing(true);

		//close
		if ( GUI.CircleIconButton( x + w - 70, y + 10, GLUI.icons.x, 1 ) )
			this.controller.current_tool = null;
	},

	onMouse: function(e)
	{
		if (!this.comp)
			return;
		var participant = xyz.space.local_participant;
		if (!participant)
			return;

		var pos = vec3.create();
		var delta = vec3.create();
		var size = (this.mode === "erase") ? 50 : this.comp._brush_size;

		if (e.type === "mousedown" && e.buttons === 1 && !gl.keys["SPACE"] )
		{
			this.drawing = true;
			this.comp.drawMarker( this.mode, e.ray.collision_point, this.comp._brush_color, size, null, true );
			this.prev_pos.set( e.ray.collision_point );
		}
		else if (this.drawing && e.type === "mousemove")
		{
			if ( !e.dragging )
				this.drawing = false; //avoid weird events lost
			else
			{
				if (this.comp._brush_type === "dots")
				{
					if ( ((getTime() * 0.005) % 1) > 0.5 )
						this.comp.drawMarker( this.mode, e.ray.collision_point, this.comp._brush_color, size, this.prev_pos, true );
					this.prev_pos.set( e.ray.collision_point );
				}
				else
				{
					this.comp.drawMarker( this.mode, e.ray.collision_point, this.comp._brush_color, size, this.prev_pos, true );
					this.prev_pos.set( e.ray.collision_point );
				}
			}
		}
		else if (e.type === "mouseup" && this.drawing )
		{
			this.drawing = false;
		}
		else
			return false;
		return true;
	}
};

TableTop.ScrollLayerTool = {
	last_img_pos: vec2.create(),

	onMouse: function(e)
	{
		if (!this.comp)
			return;

		if (e.type === "mousedown" && e.buttons === 1)
		{
			this.comp.worldToImage( e.ray.collision_point, this.last_img_pos );
		}
		else if (e.dragging && e.type === "mousemove")
		{
			var pos = this.comp.worldToImage( e.ray.collision_point );
			var delta = vec2.sub( vec2.create(), pos, this.last_img_pos );
			this.comp.scrollTable(delta,true);
			this.last_img_pos.set(pos);
		}
		else if (e.type === "mouseup" )
		{
			this.comp.scrollTable([ 0,0 ],true);
		}
		else
			return false;
		return true;
	}

}

TableTop.ManipulateLayerTool = {
	initialized: false,
	comp: null,
	mode: "move",
	dragging: false,
	prev_pos: vec3.create(),
	prev_pos3D: vec3.create(),

	move_pos: vec3.create(),
	rotate_pos: vec3.create(),
	scale_pos: vec3.create(),

	center_pos: vec2.create(),
	rotate_pos_board: vec2.create(),
	scale_pos_board: vec2.create(),
	last_img_pos: vec2.create(),

	init: function()
	{
		if (this.initialized)
			return;
		this.initialized = true;
	},

	render: function(view)
	{
		if (!this.comp)
			return;
		var participant = xyz.space.local_participant;
		if (!participant)
			return;

		var scene2D = this.comp._scene2D;

		var layer = scene2D.getLayer( TableTop.selected_layer_id );
		if (!layer)
			return;

		if (!layer.position)
			return;

		var camera = view._last_camera;
		var selected_color = [ 0.75,0.75,0.75,1 ];

		var pos2D = layer.localToGlobal([ 0,0 ]);
		this.center_pos.set( pos2D );
		var pos3D = this.comp.imageToWorld( pos2D );
		camera.project( pos3D, null, this.move_pos );
		GUI.DrawIcon( this.move_pos[0], gl.canvas.height - this.move_pos[1], 0.8, [ 0,1 ], false, this.mode === "move" ? [ 1, 1, 1, 1 ] : selected_color  );
		GUI.DrawIcon( this.move_pos[0], gl.canvas.height - this.move_pos[1], 0.4, [ 0,5 ], true );

		if ( layer.size[0] && layer.size[1] )
		{
			layer.localToGlobal([ 0, layer.size[1]*-0.5 ], pos2D );
			this.rotate_pos_board.set(pos2D);
			this.comp.imageToWorld( pos2D, pos3D );
			camera.project( pos3D, null, this.rotate_pos );
			GUI.DrawIcon( this.rotate_pos[0], gl.canvas.height - this.rotate_pos[1], 0.8, [ 0,1 ], false, this.mode === "rotate" ? [ 1, 1, 1, 1 ] : selected_color );
			GUI.DrawIcon( this.rotate_pos[0], gl.canvas.height - this.rotate_pos[1], 0.4, [ 1,5 ], true );

			layer.localToGlobal([ layer.size[0]*0.5, layer.size[1]*0.5 ], pos2D );
			this.scale_pos_board.set(pos2D);
			this.comp.imageToWorld( pos2D, pos3D );
			camera.project( pos3D, null, this.scale_pos );
			GUI.DrawIcon( this.scale_pos[0], gl.canvas.height - this.scale_pos[1], 0.8, [ 0,1 ], false, this.mode === "scale" ? [ 1, 1, 1, 1 ] : selected_color );
			GUI.DrawIcon( this.scale_pos[0], gl.canvas.height - this.scale_pos[1], 0.4, [ 2,5 ], true );
		}
	},

	onMouse: function(e)
	{
		if (!this.comp)
			return;
		var participant = xyz.space.local_participant;
		if (!participant)
			return;

		var scene2D = this.comp._scene2D;

		var layer = scene2D.getLayer( TableTop.selected_layer_id );
		if (!layer)
			return;

		var pos2D = this.comp.worldToBoard( e.ray.collision_point );

		if (e.type === "mousedown" && e.buttons === 1)
		{
			this.dragging = true;
			this.prev_pos.set( pos2D );

			var mouse_pos = [ e.canvasx, e.canvasy ];
			var dist_move = vec2.distance( mouse_pos, this.move_pos );
			var dist_rotate = vec2.distance( mouse_pos, this.rotate_pos );
			var dist_scale = vec2.distance( mouse_pos, this.scale_pos );

			if ( dist_move < dist_rotate && dist_move < dist_scale && dist_move < 50 )
				this.mode = "move";
			else if ( dist_rotate < dist_scale && dist_rotate < 50 )
				this.mode = "rotate";
			else if ( dist_scale < 50 )
				this.mode = "scale";
			else
				this.mode = "move";
			this.comp.worldToImage( e.ray.collision_point, this.last_img_pos );
		}
		else if (this.dragging && e.type === "mousemove")
		{
			if ( !e.dragging )
				this.dragging = false; //avoid weird events lost
			else
			{
				var deltax = (pos2D[0] - this.prev_pos[0]) * this.comp.ppu * 2;
				var deltay = (pos2D[2] - this.prev_pos[2]) * this.comp.ppu * 2;
				if (this.mode === "move" )
				{
					if (layer.move)
						layer.move(deltax, deltay);
				}
				else if (this.mode === "scale" )
				{
					var img_pos = this.comp.worldToImage( e.ray.collision_point );
					var dist_a = vec2.distance( this.center_pos, img_pos );
					var dist_b = vec2.distance( this.center_pos, this.last_img_pos );
					layer.scale *= dist_a / dist_b;
					this.last_img_pos.set( img_pos );
				}
				else if (this.mode === "rotate" )
				{
					var img_pos = this.comp.worldToImage( e.ray.collision_point );
					var a = vec2.sub( vec2.create(), img_pos, this.center_pos );
					var b = vec2.sub( vec2.create(), this.last_img_pos, this.center_pos );
					vec2.normalize(a,a);
					vec2.normalize(b,b);
					var delta = vec2ComputeSignedAngle( a, b );
					this.last_img_pos.set( img_pos );
					layer.angle += delta * RAD2DEG;
				}
				this.comp._must_update_texture = true;
				this.comp.syncLayer(layer);
				this.prev_pos.set( pos2D );
			}
		}
		else if (e.type === "mouseup" && this.dragging )
		{
			this.dragging = false;
			this.comp.syncLayer(layer);
		}
		else
			return false;
		return true;
	}
};


TableTop.BACKGROUND_FRAGMENT_SHADER = BackgroundFragmentShader;

export default TableTop;
