import { quat, vec2, vec3, vec4 } from "gl-matrix";

function DecalObject()
{
	this._enabled = true;
	this._on_focus = false;

	this._texture = "";
	this._textureNormal = "";

	this._size = 1.0;
	this._height = 1.0;
	this._square = true;
	this._intensity = 1.0;
	this._transparency = 1.0;
	this._color = vec3.create();
	vec3.set(this._color, 1.0, 1.0, 1.0);


	this._preset = "Dirty";

	this._rotation = 0.0;
	this._metalness = 0.1;
	this._roughness = 0.5;
	this._depthThreshold = 0.0001;
	this._normalScale = 0.5;

	this._flipU = true;
	this._flipV = false;

	this._pom = false;
	this._pomDisplacement = 0.1;
	this._pomSelfShadow = 0.1;
	this._pomHeightBias = 1.0;
	this._pomAlphaFading = 0.0;

	this._decal_position = vec3.create();
	this._decal_rot = vec4.create();
	this._decal_scale = vec3.create();

	this._decal_norm = vec3.create();
	vec3.set(this._decal_norm, 0.0, 1.0, 0.0);

	vec3.set(this._decal_position, -10000.0, -10000.0, -10000.0);
	vec3.set(this._decal_scale, -10000.0, -10000.0, -10000.0);
	vec4.set(this._decal_rot, -10000.0, -10000.0, -10000.0, -10000.0);

	this._projected = false;

	this._native = null;
	this._dirty = false;

	this._index = DecalObject.last_index++;
}

DecalObject.componentName = "DecalObject";

DecalObject.last_index = 1;

DecalObject.icon = [ 6,3 ];

DecalObject.widgets = {
	"Preset": { type: "enum", values: ["Dirty", "Puddle", "Asphalt-Crack", "Asphalt-Crater", "Asphalt", "Asphalt-Pothole", "Wall-Cracks-01", "Wall-Cracks-02", "Moss", "Rocks", "Forest-Roots", "Concrete-Broken", "Dirt-On-Road1", "Dirt-On-Road2", "Ground-Crack", "Ground-Decal", "Graffiti-1", "Graffiti-2", "Graffiti-3"] },
	"Size": { type: "slider", min: 0, max: 20, mark: 1 },
	"Square": { type: "toggle" },
	"Height": { type: "slider", min: 0, max: 20, mark: 1 },
	"Rotation": { type: "slider", min: 0, max: 360, mark: 1 },
	"Flip-U": { type: "toggle" },
	"Flip-V": { type: "toggle" },
	"NormScale": { type: "slider", min: 0, max: 1, mark: 1 },
	"Metalness": { type: "slider", min: 0, max: 1, mark: 1 },
	"Roughness": { type: "slider", min: 0, max: 1, mark: 1 },
	"Depth": { type: "slider", min: 0, max: 10, mark: 0.01 },
	"Use POM": { type: "toggle" },
	"PomDispl": { type: "slider", min: 0, max: 1, mark: 0.1 },
	"PomSS": { type: "slider", min: 0, max: 5, mark: 0.1 },
	"PomBias": { type: "slider", min: -1, max: 1, mark: 1 },
	"PomAlpha": { type: "slider", min: 0, max: 1, mark: 1 },
	"Intensity": { type: "slider", min: 0, max: 10, mark: 1 },
	"Transparency": { type: "slider", min: 0, max: 10, mark: 1 },
	"Color": { type: "color" },
	Texture: { type: "asset", extensions: "jpg,png" },
	TextureNormal: { type: "asset", extensions: "jpg,png" }
}

Object.defineProperty(DecalObject.prototype, "Preset", {
	get: function () {
		return this._preset;
	},
	set: function (v) {
		if (this._preset == v)
			return;
		this._dirty = true;
		this._preset = v;
	}
});

Object.defineProperty(DecalObject.prototype, "enabled", {
	get: function () {
		return this._enabled;
	},
	set: function (v) {
		if (this._enabled == v)
			return;
		this._dirty = true;
		this._enabled = v;
	}
});

Object.defineProperty(DecalObject.prototype, "Color", {
	get: function () {
		return this._color;
	},
	set: function (v) {
		if (vec3.equals(this._color, v))
			return;
		this._dirty = true;
		vec3.copy(this._color, v);
	}
});

Object.defineProperty(DecalObject.prototype, "Transparency", {
	get: function () {
		return this._transparency;
	},
	set: function (v) {
		if (this._transparency == v)
			return;
		this._dirty = true;
		this._transparency = v;
	}
});

Object.defineProperty(DecalObject.prototype, "Size", {
	get: function () {
		return this._size;
	},
	set: function (v) {
		if (this._size == v)
			return;
		this._dirty = true;
		this._size = v;
	}
});
Object.defineProperty(DecalObject.prototype, "Height", {
	get: function () {
		return this._height;
	},
	set: function (v) {
		if (this._height == v)
			return;
		this._dirty = true;
		this._height = v;
	}
});
Object.defineProperty(DecalObject.prototype, "Square", {
	get: function () {
		return this._square;
	},
	set: function (v) {
		if (this._square == v)
			return;
		this._dirty = true;
		this._square = v;
	}
});
Object.defineProperty(DecalObject.prototype, "Flip-U", {
	get: function () {
		return this._flipU;
	},
	set: function (v) {
		if (this._flipU == v)
			return;
		this._dirty = true;
		this._flipU = v;
	}
});
Object.defineProperty(DecalObject.prototype, "Flip-V", {
	get: function () {
		return this._flipV;
	},
	set: function (v) {
		if (this._flipV == v)
			return;
		this._dirty = true;
		this._flipV = v;
	}
});
Object.defineProperty(DecalObject.prototype, "Rotation", {
	get: function () {
		return this._rotation;
	},
	set: function (v) {
		if (this._rotation == v)
			return;
		this._dirty = true;
		this._rotation = v;
	}
});

Object.defineProperty(DecalObject.prototype, "NormScale", {
	get: function () {
		return this._normalScale;
	},
	set: function (v) {
		if (this._normalScale == v)
			return;
		this._dirty = true;
		this._normalScale = v;
	}
});

Object.defineProperty(DecalObject.prototype, "Metalness", {
	get: function () {
		return this._metalness;
	},
	set: function (v) {
		if (this._metalness == v)
			return;
		this._dirty = true;
		this._metalness = v;
	}
});

Object.defineProperty(DecalObject.prototype, "Roughness", {
	get: function () {
		return this._roughness;
	},
	set: function (v) {
		if (this._roughness == v)
			return;
		this._dirty = true;
		this._roughness = v;
	}
});

Object.defineProperty(DecalObject.prototype, "Depth", {
	get: function () {
		return this._depthThreshold;
	},
	set: function (v) {
		if (this._depthThreshold == v)
			return;
		this._dirty = true;
		this._depthThreshold = v;
	}
});

Object.defineProperty(DecalObject.prototype, "Use POM", {
	get: function () {
		return this._pom;
	},
	set: function (v) {
		if (this._pom == v)
			return;
		this._dirty = true;
		this._pom = v;
	}
});
Object.defineProperty(DecalObject.prototype, "PomDispl", {
	get: function () {
		return this._pomDisplacement;
	},
	set: function (v) {
		if (this._pomDisplacement == v)
			return;
		this._dirty = true;
		this._pomDisplacement = v;
	}
});
Object.defineProperty(DecalObject.prototype, "PomSS", {
	get: function () {
		return this._pomSelfShadow;
	},
	set: function (v) {
		if (this._pomSelfShadow == v)
			return;
		this._dirty = true;
		this._pomSelfShadow = v;
	}
});
Object.defineProperty(DecalObject.prototype, "PomBias", {
	get: function () {
		return this._pomHeightBias;
	},
	set: function (v) {
		if (this._pomHeightBias == v)
			return;
		this._dirty = true;
		this._pomHeightBias = v;
	}
});
Object.defineProperty(DecalObject.prototype, "PomAlpha", {
	get: function () {
		return this._pomAlphaFading;
	},
	set: function (v) {
		if (this._pomAlphaFading == v)
			return;
		this._dirty = true;
		this._pomAlphaFading = v;
	}
});

Object.defineProperty(DecalObject.prototype, "Intensity", {
	get: function () {
		return this._intensity;
	},
	set: function (v) {
		if (this._intensity == v)
			return;
		this._dirty = true;
		this._intensity = v;
	}
});


Object.defineProperty(DecalObject.prototype, "Texture", {
	get: function () {
		return this._texture;
	},
	set: function (v) {
		if (v != null)
			v = v.trim();
		if (v == this._texture)
			return;
		this._texture = v;
		this._dirty = true;
	}
});

Object.defineProperty(DecalObject.prototype, "TextureNormal", {
	get: function () {
		return this._textureNormal;
	},
	set: function (v) {
		if (v != null)
			v = v.trim();
		if (v == this._textureNormal)
			return;
		this._textureNormal = v;
		this._dirty = true;
	}
});

DecalObject.prototype.serialize = function(o)
{
	o.enabled = this._enabled;
	o.preset = this._preset;

	o.size = this._size;
	o.height = this._height;
	o.square = this._square;
	o.rotation = this._rotation;
	o.intensity = this._intensity;
	o.transparency = this._transparency;
	o.color = this._color;

	o.flipU = this._flipU;
	o.flipV = this._flipV;

	o.metalness = this._metalness;
	o.roughness = this._roughness;
	o.normalScale = this._normalScale;
	o.depthThreshold = this._depthThreshold * 0.01;

	o.pom = this._pom;
	o.pomDisplacement = this._pomDisplacement;
	o.pomSelfShadow = this._pomSelfShadow;
	o.pomHeightBias = this._pomHeightBias;
	o.pomAlphaFading = this._pomAlphaFading;

	o.texture = this._texture;
	o.textureNormal = this._textureNormal;

	o.decal_pos = this._decal_pos;
	o.decal_norm = this._decal_norm;
}

DecalObject.prototype.configure = function(o)
{
	this._enabled = o.enabled;
	this._preset = o.preset;

	this._size = o.size;
	this._height = o.height;
	this._square = o.square;
	this._rotation = o.rotation;
	this._intensity = o.intensity;
	this._transparency = o.transparency;
	this._color = o.color;

	if (o.flipU)
		this._flipU = o.flipU;
	if (o.flipV)
		this._flipV = o.flipV;

	this._metalness = o.metalness;
	this._roughness = o.roughness;
	this._normalScale = o.normalScale;
	this._depthThreshold = o.depthThreshold * 100;

	this._pom = o.pom;
	this._pomDisplacement = o.pomDisplacement;
	this._pomSelfShadow = o.pomSelfShadow;
	this._pomHeightBias = o.pomHeightBias;
	this._pomAlphaFading = o.pomAlphaFading;

	this._texture = o.texture;
	this._textureNormal = o.textureNormal;

	this._decal_pos = o.decal_pos;
	vec3.copy(this._decal_position, this._decal_pos);

	if (o.decal_norm)
		vec3.copy(this._decal_norm, o.decal_norm);

	this._projected = true;
	this._dirty = true;
}


DecalObject.prototype.createNative = function () {
	if (this._root && nativeEngine && !this._native) {
		var bNew = false;
		if (!this._root._native) {
			// Andrey FIXME: Hack to place new decal entity in front of camera
			// Btw for some reason this camera position doesn't changed when we fly in the editor mode!
			var camera = ViewCore.instance.hard_camera;
			var cam_pos = camera.position;
			var cam_front = camera.getFront();
			var dist = 1.0;

			var offset = vec3.scale(vec3.create(), cam_front, dist);

			var nodePos = this._node.position;
			vec3.add(nodePos, cam_pos, offset);
			this._decal_pos = nodePos;
			vec3.copy(this._decal_position, nodePos);
			this._node.mustUpdate = true;

			this._root.createNativeEntity(this._root.index);

			this._dirty = true;

			bNew = true;
		}
		this._native = this._root._native;

		// Creates decal component
		var roomAPI = nativeEngine._room;
		roomAPI.decalPlace(this._native, this._decal_pos, this._decal_norm);

		if (bNew) {
			var center = vec2.create();
			vec2.set(center, gl.canvas.width / 2.0, gl.canvas.height / 2.0);

			this._projected = false;
			this._root._native.unprojectPos(center);
		}
	}
}

DecalObject.prototype.preRender = function(view)
{
	if (!this._native) {
		if (xyz.native_json_loaded)
			this.createNative();
	}

	if (!this._native)
		return;
	var n = this._native.getRootNode();

	if (this._native.decalUnprojected()) {
		vec3.copy(this._decal_pos, n.position);
		vec3.copy(this._decal_norm, this._native.decalGetNormal());

		this._projected = true;
	}
	if (!this._projected)
		return;

	if (!vec3.equals(this._decal_position, this._node.position) ||
		!vec3.equals(this._decal_scale, this._node.scaling) ||
		!quat.equals(this._decal_rot, this._node.rotation) ||
		this._dirty) {

		// Andrey: workaround for now!
		var proj = true;
		if (!vec3.equals(this._decal_position, this._node.position)) {
			proj = true;
    }

		vec3.copy(this._decal_position, this._node.position);
		vec3.copy(this._decal_scale, this._node.scaling);
		vec4.copy(this._decal_rot, this._node.rotation);


		this._dirty = false;

		var decal_color = vec4.create();
		vec4.set(decal_color, this._color[0], this._color[1], this._color[2], this._transparency);

		var vsize = vec3.create();
		vec3.set(vsize, this._size, this._height, 1.0);
		var s = vec3.mul(vec3.create(), this._node.scaling, vsize);

		this._native.setPosition(this._decal_pos);
		//this._native.setScale(s);
		this._native.setRotation(this._node.rotation);
		this._native.setColor(decal_color);

		this._native.decalSetSize(s);
		this._native.decalSetIntensity(this._intensity);
		this._native.decalSetSquare(this._square);
		this._native.decalSetRotation(this._rotation);
		this._native.decalSetMetalness(this._metalness);
		this._native.decalSetRoughness(this._roughness);
		this._native.decalSetNormalScale(this._normalScale);
		this._native.decalSetDepthThreshold(this._depthThreshold * 0.01);

		if (this._texture != "")
			this._native.decalSetTextures(this._texture, this._textureNormal);
		else
			this._native.decalSetPreset(this._preset);

		this._native.decalSetPOM(this._pom);
		this._native.decalSetPOMDisplacement(this._pomDisplacement);
		this._native.decalSetPOMSelfShadow(this._pomSelfShadow);
		this._native.decalSetPOMHeightBias(this._pomHeightBias);
		this._native.decalSetPOMAlphaFading(this._pomAlphaFading);

		this._native.decalSetFlipU(this._flipU);
		this._native.decalSetFlipV(this._flipV);

		if (proj) {
			this._native.decalProject();
			vec3.copy(this._decal_norm, this._native.decalGetNormal());
		}
	}
}


DecalObject.prototype.enterFocus = function()
{
	if (this._on_focus)
		return;

	LEvent.bind( this.space, "keydown", this.onKey, this );
	LEvent.bind( this.space, "mouse", this.onGlobalMouse, this );

	//compute radius
	var original_pos = this.entity.node.getGlobalPosition();
	var bb = node.updateBoundingBox();
	var center = BBox.getCenter(bb);
	var halfsize = BBox.getHalfsize(bb);
	vec3.mul( halfsize, halfsize, node.scaling );
	var radius = vec3.length( halfsize );// / this.entity.node.scaling[0];
	console.debug(radius);
	radius *= 1.5;
}

DecalObject.prototype.exitFocus = function()
{
	if (!this._on_focus)
		return;

	LEvent.unbind(this.space, "keydown", this.onKey, this);
	LEvent.unbind(this.space, "mouse", this.onGlobalMouse, this);

	this._on_focus = false;
}

DecalObject.prototype.onCallMouse = function (event_type, e) {
	if (!this._enabled || !this._native)
		return;

}

DecalObject.prototype.onKeyDown = function (event_type, e) {
	if (!this._enabled)
		return;

	if (e.code === "KeyQ" || e.code === "Escape") {
		return true;
	}
}

DecalObject.prototype.onKey = function(type,e)
{
	if (e.key == "Escape")
	{
		this.exitFocus();
	}
	return true;
}

DecalObject.prototype.onGlobalMouse = function(type,e)
{
	//console.debug(e);
	if (!this._on_focus)
		return;
}

export default DecalObject;
