import { vec4 } from "gl-matrix";

import Label from "@src/libs/GLUI/Elements/Label";

import { corner_options } from "./constants";
import positionToArea     from "./positionToArea";

function LGraphGUIStatic()
{
	this.properties = { enabled: true, text:"Hello", image:"", position: [ 20,20 ], size: [ 140,40 ], corner: LiteGraph.CORNER_TOP_LEFT };
	this.widgets_start_y = 2;
	this.addWidget("text","text","Hello","text");
	this._area = vec4.create();
}

LGraphGUIStatic.title = "GUIStatic";
LGraphGUIStatic.desc = "Renders text or images";
LGraphGUIStatic["@corner"] = corner_options;

LGraphGUIStatic.prototype.onRenderGUI = function( ctx, GUI )
{
	if (!this.getInputOrProperty("enabled"))
		return;
	positionToArea( this.properties.position, this.properties.corner, this._area );
	var parent_pos = this.getInputOrProperty("parent_pos");
	if (parent_pos)
	{
		this._area[0] += parent_pos[0];
		this._area[1] += parent_pos[1];
	}
	this._area[2] = this.properties.size[0];
	this._area[3] = this.properties.size[1];
	var tex = this.getInputOrProperty("image");
	if (tex && ctx.webgl)
	{
		var texture = gl.textures[ tex ];
		if (texture)
			ctx.drawImage( texture, this._area[0],this._area[1], this._area[2], this._area[3] );
	}
	Label.call(GUI, this._area[0],this._area[1], this._area[2], this._area[3], this.properties.text );
}

LGraphGUIStatic.prototype.onExecute = function()
{
	var enabled = this.getInputDataByName("enabled");
	if (enabled === false || enabled === true)
		this.properties.enabled = enabled;
	var text = this.getInputDataByName("text");
	if (text !== undefined)
		this.properties.text = text || "";
}

LGraphGUIStatic.prototype.onGetInputs = function() {
	return [ [ "enabled","boolean" ],[ "image","string" ],[ "parent_pos","vec2" ],[ "text","string" ] ];
}

export default LGraphGUIStatic;
