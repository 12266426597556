import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { GL } from "@src/libs/litegl";
import { mat4, vec2, vec3, vec4 } from "gl-matrix";

import BaseComponent from "./baseComponent";
import Area from "./WalkableArea/Area";
import { BLOCK, WALK, TRIGGER } from "./WalkableArea/constants";
import distanceXZ from "./WalkableArea/distanceXZ";
import nearestToLine2DwithY from "./WalkableArea/nearestToLine2DwithY";
import WalkableAreaTool from "./WalkableArea/WalkableAreaTool";

//define areas where users can move around
//define areas where users cannot move
//tool to edit it
function WalkableArea()
{
	this.enabled = true;
	this.areas = [];
	this.occluders = [];
	this.apply_to_camera = true;
	this.height_range = [ 0,5 ]; //min and max height
	this.last_area_id = 1;

	//default
	var default_area = new Area();
	default_area.id = 1;
	this.areas.push(default_area);

	this._must_update = true;
	this._total_points = null;
	this._points = null;
	this._middle_points = null;

	this._local_participant_current_area_id = -1;
}

WalkableArea.componentName = "WalkableArea";

WalkableArea.WALK = WALK;
WalkableArea.BLOCK = BLOCK;
WalkableArea.TRIGGER = TRIGGER;

//******** /AREA ***********************

WalkableArea.icon = [ 12,6 ];

WalkableArea.types_str = [ "Walk","Block" ];
WalkableArea.types_colors = [ [ .8,.8,.8,1 ],[ 1,0.5,0.5,1 ],[ 0.5,0.5,1,1 ] ];

WalkableArea.selected_index = 0; //for editor

WalkableArea.prototype.onAdded = function(_parent)
{
}

WalkableArea.prototype.onRemoved = function(_parent)
{
}

WalkableArea.prototype.serialize = function(o)
{
	o.enabled = this.enabled;
	o.areas = [];
	for (var i = 0; i < this.areas.length; ++i)
		o.areas.push( this.areas[i].serialize({}) );
	o.apply_to_camera = this.apply_to_camera;
	o.height_range = this.height_range.concat();
	o.last_area_id = this.last_area_id;
}

WalkableArea.prototype.configure = function(o)
{
	this.enabled = o.enabled;
	var used_ids = {};
	var reassign = false;
	if (o.areas)
	{
		this.areas = [];

		for (var i = 0; i < o.areas.length; ++i)
		{
			var area_info = o.areas[i];
			if (!area_info.points || area_info.points.length === 0)
			{
				console.warn("area without points");
				continue;
			}
			var area = new Area();
			area.configure( area_info );
			if ( area.id == null )
				area.id = 1000 + i;
			if ( area.height == null )
				area.height = 0;
			if ( area.enabled === undefined )
				area.enabled = true;
			if ( area.type === undefined )
				area.type = WalkableArea.WALK;
			this.addArea( area );

			if( used_ids[area.id] )
				reassign = true;
			else
				used_ids[area.id] = true;
		}

		if(reassign)
		{
			console.warn("WalkAreas using existing ID, reassigning");
			for (var i = 0; i < this.areas.length; ++i)
				this.areas[i].id = i+1;
		}
	}
	else if (o.area) //legacy
	{
		var area = new Area();
		area._component = this;
		area.configure( {
			id: 0,
			enabled: true,
			height: 0,
			points: o.area.concat()
		} );
		this.areas = [ area ];
	}
	this.apply_to_camera = o.apply_to_camera;
	if ( o.height_range )
		this.height_range = o.height_range.concat();
	if ( o.last_area_id != null )
		this.last_area_id = o.last_area_id;
	else
		this.last_area_id = this.areas.length + 1;
}

//enable drawing shapes tool
WalkableArea.prototype.enableTool = function()
{
	var editor = xyz.editor_controller;
	var tool = WalkableAreaTool.instance;
	if (!tool)
		WalkableAreaTool.instance = tool = new WalkableAreaTool();
	tool.component = this;
	editor.selectTool( tool );
}

WalkableArea.prototype.update = function(dt)
{
	if (!this.enabled)
		return;

	var view = ViewCore.instance;

	var space = this.space;
	var participant = space.local_participant;
	if (!participant)
		return;

	//make sure this participant is inside the walk areas
	if ( !participant.seat && participant.walking)
	{
		var old_pos = vec3.clone( participant.position );
		var newpos = this.adjustPosition( participant.position, 3 );
		if ( newpos && vec3.distance(old_pos,newpos) > 0 )
		{
			participant.node.position = newpos;
		}
	}

	//for current camera
	if (this.apply_to_camera && this.entity.space.mode !== "editor")
	{
		var cam = view.getCurrentCamera();
		var front = cam.getFront();
		var newpos = this.adjustPosition( cam.position );
		if (newpos)
		{
			cam.position = newpos;
			cam.target = vec3.add( front, front, newpos );
		}
	}
}

WalkableArea.prototype.addArea = function( area )
{
	if ( !area ) //create a default one
	{
		var selected_area = this.areas[ WalkableArea.selected_index ];

		var info = {
			id: this.last_area_id++,
			enabled: true,
			height: selected_area ? selected_area.height : 0,
			points:[ [ -1,0,-1 ],[ -1,0,1 ],[ 1,0,1 ],[ 1,0,-1 ] ]
		};

		var area = new Area();
		area.configure( info );
	}

	this.areas.push(area);
	area._component = this;
	this._must_update = true;

	return this.areas.length - 1;
}

WalkableArea.prototype.removeArea = function( index )
{
	if (this.areas.length === 1)
		return; //cannot remove all

	if (this.areas.length > index)
	{
		var area = this.areas[index];
		if (area)
			area._component = null;
		this.areas.splice(index,1);
		this._must_update = true;
		if ( WalkableArea.selected_index >= this.areas.length )
			WalkableArea.selected_index = this.areas.length - 1;
	}
}

//not in use right now
WalkableArea.prototype.checkParticipantAreas = function( participants )
{
	//check who is in every area
	for (var i = 0; i < participants.length; ++i)
	{
		var participant = participants[i];
		var pos = participant.position;
		var prev_area = participant._current_area;
		var inside_area = null;
		var inside_area_index = -1;

		for (var j = 0; j < this.areas.length; ++j)
		{
			var area = this.areas[j];
			if (!area.enabled || area.type === WalkableArea.BLOCK )
				continue;

			//too high or too low, ignore it
			var diff = Math.abs( area.height - pos[1] );
			if ( max_jump_height && diff > max_jump_height )
				continue;

			//it is not inside
			if ( !area.isInside( pos ) )
				continue;

			//assign
			inside_area = area;
			inside_area_index = j;
		}

		if (inside_area !== prev_area )
		{
			//LEvent.trigger(this, "user_leave" )
		}

		participant._current_area = inside_area;
	}

}


//given a point it makes sure it is inside the valid area
WalkableArea.prototype.adjustPosition = function( pos, height )
{
	height = height || 0;
	var result = vec3.create();

	var max_jump_height = height *= 0.5;

	var valid_areas = [];

	//check if inside areas
	for (var j = 0; j < this.areas.length; ++j)
	{
		var area = this.areas[j];
		if ( !area.enabled )
			continue;

		//too high or too low, ignore it
		var diff = Math.abs( area.height - pos[1] );
		if ( max_jump_height && diff > max_jump_height )
			continue;

		//it is out of the shape ignore it
		if ( !area.isInside( pos ) )
			continue;

		//he is inside a blocking area
		if (area.type === WalkableArea.BLOCK )
		{
			//move to border of block area
			var nearest = area.findNearestEdgePoint( pos );
			if (nearest)
				nearest[1] = area.height; //adjust to the one with similar height
			return nearest;
		}

		valid_areas.push(area);
	}

	//the point is inside a valid area, assign propper height
	if ( valid_areas.length )
	{
		var point_y = pos[1];
		result.set( pos ); //leave same pos as it was valid
		valid_areas = valid_areas.sort( function(a,b) { return b.height - a.height; } );
		result[1] = valid_areas[0].height; //adjust to the one with similar height
		return result;
	}

	//if not inside
	//search for the closest point
	var min_dist = 100000;
	var nearest = null;

	for (var j = 0; j < this.areas.length; ++j)
	{
		var area = this.areas[j];
		if (!area.enabled || area.type !== WALK )
			continue;
		var diff = Math.abs( area.height - pos[1] );
		if ( max_jump_height && diff > max_jump_height )
			continue;

		for (var i = 0, l = area.points.length; i < l; ++i)
		{
			var p1 = area.points[i];
			var p2 = (i+1) === l ? area.points[0] : area.points[i + 1];
			var nearest_to_segment = nearestToLine2DwithY( pos, p1, p2 );
			var dist = distanceXZ( pos, nearest_to_segment );
			if (dist > min_dist)
				continue;

			//move to closer wall
			min_dist = dist;
			nearest = nearest_to_segment;
		}
	}

	return nearest;
}



WalkableArea.prototype.updateBuffers = function()
{
	this._must_update = false;

	if (this.areas.length === 0)
		return;

	var num = 0;
	for (var j = 0, l = this.areas.length; j < l; ++j)
		num += this.areas[j].points.length;

	var selected_area = this.areas[ WalkableArea.selected_index ];
	if (!selected_area)
		selected_area = this.areas[0];

	this._total_points = new Float32Array( num * 3 );
	this._lines = new Float32Array( num * 3 * 2 );
	this._lines_color = new Float32Array( num * 4 * 2 );

	this._points = new Float32Array( selected_area.points.length * 3 );
	this._middle_points = new Float32Array( this._points.length );
	var colors = WalkableArea.types_colors;

	var index = 0;
	var index2 = 0;
	var index_selected = 0;
	var p3d = vec3.create();
	for (var j = 0, l2 = this.areas.length; j < l2; ++j)
	{
		var area = this.areas[j];
		var type = area.type || 0;
		for (var i = 0, l = area.points.length; i < l; ++i)
		{
			var p1 = area.points[i];
			var p2 = (i+1) === l ? area.points[0] : area.points[i + 1];
			p1[1] = p2[1] = area.height;
			this._total_points.set(p1,index*3);
			this._lines.set(p1,index2*3);
			this._lines.set(p2,index2*3+3);

			var color = colors[type];
			if ( !area.enabled )
				color = [ 0.0,0.0,0.0,1.0 ];
			this._lines_color.set(color,index2*4);
			this._lines_color.set(color,index2*4+4);

			if (area === selected_area )
			{
				this._points.set(p1,index_selected*3);
				vec3.lerp( p3d, p1, p2, 0.5 );
				this._middle_points.set(p3d,index_selected*3);
				index_selected++;
			}

			index++;
			index2+=2;
		}
	}

	//triangulate with earcut
	//TODO

}

//returns the index in the area array of the point closer in screen space
//max_dist in pixels
WalkableArea.prototype.getNearestPoint2D = function( pos2D, max_dist, camera, test_middle, filter_area )
{
	max_dist = max_dist || 50;
	var p3d = vec3.create();
	var p3d_middle = vec3.create();
	var min_dist = 1000000;
	var area_index = -1;
	var point_index = -1;
	var middle = vec3.create();
	var is_edge = false;

	//test corners
	for (var j = 0, l2 = this.areas.length; j < l2; ++j)
	{
		var area = this.areas[j];
		if (filter_area && filter_area !== area )
			continue;
		if ( !area.enabled )
			continue;

		for (var i = 0, l = area.points.length; i < l; ++i)
		{
			var p1 = area.points[i];
			camera.project(p1,null,p3d);
			var dist = vec2.distance( pos2D, p3d );
			if ( dist < max_dist && dist < min_dist )
			{
				min_dist = dist;
				point_index = i;
				area_index = j;
				is_edge = false;
			}
		}

		//test edges
		if (test_middle && point_index === -1)
		{
			for (var i = 0, l = area.points.length; i < l; ++i)
			{
				var p1 = area.points[i];
				var p2 = (i+1) === l ? area.points[0] : area.points[i + 1];
				var middle = vec3.lerp(middle,p1,p2,0.5);
				camera.project(middle,null,p3d_middle);
				var dist = vec2.distance( pos2D, p3d_middle );
				if ( dist < max_dist && dist < min_dist )
				{
					min_dist = dist;
					point_index = i;
					area_index = j;
					is_edge = true;
				}
			}
		}
	}

	if (point_index === -1)
		return null;

	return { point_index: point_index, area_index: area_index, is_edge: is_edge };
}

WalkableArea.prototype.getAreaById = function(id)
{
	id = Number(id); //enforce number
	for (var j = 0, l2 = this.areas.length; j < l2; ++j)
	{
		var area = this.areas[j];
		if (area.id === id)
			return area;
	}
	return null;
}

WalkableArea.prototype.getAreaIndexFromRay = function(ray, filter_index )
{
	var N = [ 0,1,0 ];

	var closer = -1;

	for (var j = 0, l2 = this.areas.length; j < l2; ++j)
	{
		var area = this.areas[j];

		//test collision with plane
		if ( !ray.testPlane( [ 0,area.height,0 ], N ) )
			continue;

		//check if coll point is inside area
		if ( !area.isInside( ray.collision_point ) )
			continue;

		if (filter_index === undefined || filter_index === -1)
			return j;

		closer = j;

		if (filter_index !== j )
			return j;
	}

	return closer;
}


WalkableArea.prototype.renderGizmo = function( view, editor, selected )
{
	if (this._must_update)
		this.updateBuffers();

	var camera = view.getCurrentCamera();

	var tool_active = WalkableAreaTool.instance === editor.tool;
	var size = tool_active ? -12 : -4;
	var color = selected && tool_active ? [ 5,5,0,1 ] : [ 1,1,1,1 ];

	var selected_area = this.areas[ WalkableArea.selected_index ];

	//render plane
	if (selected && selected_area && tool_active && 0) //disabled
	{
		gl.enable( gl.DEPTH_TEST );
		gl.depthMask( false );
		var model = mat4.create();
		mat4.translate(model,model,[ 0,selected_area.height,0 ]);
		mat4.scale(model, model, [ 100,100,100 ]);
		var mesh = gl.meshes["planeXZ"];
		gl.enable( gl.BLEND );
		gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );
		var texture = view.loadTexture("/textures/grid_gauss.png");
		var color = [ 0.6,0.7,1,1 ];
		view.renderer.renderMesh( model, mesh, texture, color );
		gl.depthMask( true );
		gl.disable( gl.BLEND );
	}

	//render all areas line
	if(editor.use_depth_in_gizmos)
		gl.enable(gl.DEPTH_TEST);
	else
		gl.disable(gl.DEPTH_TEST);

	gl.enable( gl.BLEND );
	gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );
	view.renderer.color = [ 1,1,1, selected ? 1 : 0.2 ];
	if ( this._lines ) //?
		view.renderer.renderLines( this._lines, this._lines_color, camera, this._lines.length / 3, false );

	//var total_points = this._total_points;
	var points = this._points;
	var middle_points = this._middle_points;
	if (selected && selected_area)
	{
		//render full line
		var color = selected_area.type === WalkableArea.BLOCK ? [ 5, 0, 0, 1 ] : [ 5, 5, 0, 1 ];
		if ( !selected_area.enabled )
			color[3] = 0.5;
		view.renderer.color = color;
		//view.renderer.renderPoints( points, null, camera, points.length / 3, null,1, GL.LINE_LOOP );
		view.renderer.renderLines( points, this._lines_color, camera, points.length / 3, true );

		//render edge points
		if (tool_active)
		{
			view.renderer.color = [ 5,0,5,1 ];
			view.renderer.renderPoints( middle_points, null, camera, middle_points.length / 3, null, size || 0.2 );

			//render corner points
			view.renderer.color = [ 5,5,0,1 ];
			view.renderer.renderPoints( points, null, camera, points.length / 3, null, size || 0.2 );
		}
	}

	gl.enable( gl.DEPTH_TEST );
	gl.enable( gl.BLEND );
}

WalkableArea.prototype.onRenderInspector = function( ctx, x,y,w,h, editor )
{
	var that = this;
	var tcolor = vec4.create();

	Label.call(GUI,x,y,w,20, "Height Range");
	GUI.Vector( x + 120,y,w - 120,20, this.height_range );
	y += 24;

	Label.call(GUI,x,y,w,20, "Apply to camera");
	this.apply_to_camera = GUI.Toggle( x + 120,y,w - 120,20, null, this.apply_to_camera );
	y += 24;

	Label.call(GUI,x,y,w,20, "Tool");
	if ( Button.call(GUI, x + 100,y,w - 100,20, WalkableAreaTool.instance === editor.tool ? "Stop Editing" : "Edit" ) )
	{
		if (WalkableAreaTool.instance === editor.tool )
			editor.selectTool("select");
		else
			this.enableTool();
	}
	y += 30;

	var list_height = h - 200;
	WalkableArea.scroll = GUI.List( x,y,w, list_height, this.areas, inner, WalkableArea.scroll || 0 );
	y += list_height + 20;

	if ( Button.call(GUI,x,y,w,20,"+") )
		WalkableArea.selected_index = this.addArea();
	y += 30;

	//show selectead area
	var selected_area = this.areas[ WalkableArea.selected_index ];

	if (selected_area)
	{
		Label.call(GUI,x,y,100,20, "Height" );
		selected_area.height = GUI.Number(x+100,y,w-100,20, selected_area.height );
		if ( GUI.value_changed )
			this._must_update = true;
		y+= 30;

		Label.call(GUI,x,y,100,20, "Type" );
		selected_area.type = GUI.ComboLine(x+100,y,w-100,20, selected_area.type || 0, WalkableArea.types_str );
		if ( GUI.value_changed )
			this._must_update = true;
		y+= 30;
	}

	function inner( x,y,w,h, item, i )
	{
		//to show locator bullet
		editor.AnimBullet( x + 10,y,20, that, "areas/" + item.id +"/enabled");

		//enabled
		item.enabled = GUI.Toggle(x + 30,y,20,20,"", item.enabled );
		if ( GUI.value_changed )
			that._must_update = true;

		//name
		var color = i === WalkableArea.selected_index ? [ 1, 1, 1, 1 ] : [ 1, 1, 1, 0.5 ];
		vec4.mul(tcolor, color, WalkableArea.types_colors[item.type]);

		if (Label.call(GUI, x + 60, y + 2, w - 90, 20, WalkableArea.types_str[item.type] + " " + item.id, tcolor, null, true ) === GLUI.CLICKED )
		{
			WalkableArea.selected_index = i;
			that._must_update = true;
		}

		//area drag
		GUI.DrawIcon(x + w - 40,y+10,0.36,[ 5,4 ]);
		GUI.HoverArea(x + 40,y,w - 60,20, { component: that, area: item, area_id: item.id, callback: onAreaDragged });

		//delete
		if ( Button.call(GUI,x + w - 20,y,20,20,GLUI.icons.trash) )
		{
			that.removeArea(i);
			WalkableArea.selected_index = Math.min( that.areas.length - 1, WalkableArea.selected_index );
		}
	}

	function onAreaDragged(e)
	{
		console.debug("area start dragging");
		var locator = this.component.getLocator();
		e.dataTransfer.setData("type","area");
		e.dataTransfer.setData("area_id",this.area.id);
		e.dataTransfer.setData("uid",locator);
	}

}

WalkableArea.prototype.insertPoint = function( index )
{
	if (!this.areas.length)
		return;

	index = Math.floor(index);
	var area = this.areas[ WalkableArea.selected_index ];
	if (!area)
	{
		area = this.areas[0];
		WalkableArea.selected_index = 0;
	}

	var points = area.points;

	var p = points[index].concat();
	vec3.lerp(p, points[index], points[index === points.length - 1 ? 0: index + 1], 0.5);
	points.splice( index+1,0, p);
	this._must_update = true;
	return points.length > index + 1 ? index + 1 : 0;
}

WalkableArea.prototype.deletePoint = function( index )
{
	if (!this.areas.length)
		return;

	index = Math.floor(index);
	var area = this.areas[ WalkableArea.selected_index ];
	if (!area)
	{
		area = this.areas[0];
		WalkableArea.selected_index = 0;
	}

	var points = area.points;
	points.splice( index, 1 );
	this._must_update = true;
	return points.length > index ? index : 0;
}

WalkableArea.prototype.getPropertyFromPath = function( path, index )
{
	if (path[index] === "areas" )
	{
		var id = path[index+1];
		var area = this.getAreaById( id );
		if (!area)
			return;

		if ( path.length > (index+2) )
			return area[ path[index+2] ];
		return area;
	}
	else
		return BaseComponent.prototype.getPropertyFromPath.call( this, path, index );
}

WalkableArea.prototype.setPropertyFromPath = function( path, index, value )
{
	if (path[index] === "areas" )
	{
		var id = path[index+1];
		var area = this.getAreaById( id );
		if (!area)
			return true;//avoids reassigning
		if ( path.length > 2 && area[ path[index+2] ] !== undefined )
			area[ path[index+2] ] = value;
		this._must_update = true;
		return true;
	}
	else
		return BaseComponent.prototype.setPropertyFromPath.call( this, path, index, value );
	//return false;
}

WalkableArea.prototype.resolveLocatorFromPath = function( path, index )
{
	if (path[index] === "areas" && path.length > index + 2)
	{
		var id = path[index+1];
		var area = this.getAreaById( id );
		if (!area)
			return null;

		var propname = path[index+2];
		var type = typeof( area[ propname ] );

		return {
			target: area,
			name: "area_enabled",
			property: propname,
			entity: this,
			type: type
		};
	}
	else
		return BaseComponent.prototype.resolveLocatorFromPath.call( this, path, index );
}


export default WalkableArea;
