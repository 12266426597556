import { quat, vec3 } from "gl-matrix";

function LightObject()
{
	this._enabled = true;
	this._on_focus = false;

	this._texture = null;

	this._type = "OMNI";
	this._fallOff = 6.0;
	this._intensity = 6000.0;
	this._spotConeInner = 22.0;
	this._spotConeOuter = 30.0;
	this._color = vec3.create();
	vec3.set(this._color, 1.0, 1.0, 1.0);
	this._castShadows = false;

	this._native = null;
	this._dirty = false;

	this._index = LightObject.last_index++;
}

LightObject.componentName = "LightObject";
LightObject.last_index = 1;
LightObject.icon = [ 14,4 ];

LightObject.widgets = {
	"type":{ type:"enum", values:[ "OMNI","SPOT","FOCUSED_SPOT","DIRECTIONAL" ] },
	"fallOff": { type: "slider", min: 0.1, max: 100, mark: 6 },
	"Intensity": { type: "slider", min: 10, max: 200000, mark: 6000 },
	"coneInner": { type: "slider", min: 5, max: 90, mark: 22 },
	"coneOuter": { type: "slider", min: 5, max: 90, mark: 30 },
	"castShadows": { type: "toggle" },
	"Color": { type: "color" },
	Texture: { type: "asset", extensions: "jpg,png" }
}

Object.defineProperty(LightObject.prototype, "enabled", {
	get: function () {
		return this._enabled;
	},
	set: function (v) {
		if (this._enabled == v)
			return;
		this._dirty = true;
		this._enabled = v;
	}
});

Object.defineProperty(LightObject.prototype, "type", {
	get: function () {
		return this._type;
	},
	set: function (v) {
		if (this._type == v)
			return;
		this._dirty = true;
		this._type = v;
	}
});

Object.defineProperty(LightObject.prototype, "Color", {
	get: function () {
		return this._color;
	},
	set: function (v) {
		if (vec3.equals(this._color, v))
			return;
		this._dirty = true;
		vec3.copy(this._color, v);
	}
});

Object.defineProperty(LightObject.prototype, "castShadows", {
	get: function () {
		return this._castShadows;
	},
	set: function (v) {
		if (this._castShadows == v)
			return;
		this._dirty = true;
		this._castShadows = v;
	}
});

Object.defineProperty(LightObject.prototype, "fallOff", {
	get: function () {
		return this._fallOff;
	},
	set: function (v) {
		if (this._fallOff == v)
			return;
		this._dirty = true;
		this._fallOff = v;
	}
});

Object.defineProperty(LightObject.prototype, "coneInner", {
	get: function () {
		return this._spotConeInner;
	},
	set: function (v) {
		if (this._spotConeInner == v)
			return;
		this._dirty = true;
		this._spotConeInner = v;
	}
});

Object.defineProperty(LightObject.prototype, "coneOuter", {
	get: function () {
		return this._spotConeOuter;
	},
	set: function (v) {
		if (this._spotConeOuter == v)
			return;
		this._dirty = true;
		this._spotConeOuter = v;
	}
});

Object.defineProperty(LightObject.prototype, "Intensity", {
	get: function () {
		return this._intensity;
	},
	set: function (v) {
		if (this._intensity == v)
			return;
		this._dirty = true;
		this._intensity = v;
	}
});

Object.defineProperty(LightObject.prototype, "Texture", {
	get: function () {
		return this._texture;
	},
	set: function (v) {
		if (v != null)
			v = v.trim();
		if (v == this._texture)
			return;
		this._texture = v;
		this._dirty = true;
	}
});

LightObject.prototype.lightType = function () {
	if (this._type == "OMNI")
		return TmrwModule.NativeLight$Type.POINT;
	if (this._type == "SPOT")
		return TmrwModule.NativeLight$Type.SPOT;
	if (this._type == "FOCUSED_SPOT")
		return TmrwModule.NativeLight$Type.FOCUSED_SPOT;
	if (this._type == "DIRECTIONAL")
		return TmrwModule.NativeLight$Type.DIRECTIONAL;
	return TmrwModule.NativeLight$Type.POINT;
}


LightObject.prototype.createNative = function () {
	if (this._root && nativeEngine && !this._native) {
		if (!this._root._native) {
			// Andrey FIXME: Hack to place new light entity in front of camera
			var camera = ViewCore.instance.hard_camera;
			var cam_pos = camera.position;
			var cam_front = camera.getFront();
			var dist = 1.0;

			var offset = vec3.scale(vec3.create(), cam_front, dist);
			vec3.add(this._node.position, cam_pos, offset);
			this._node.mustUpdate = true;

			this._root.createNativeEntity(this._root.index);
		}

		var lType = this.lightType();

		// Creates native light entity component
		TmrwModule.NativeRoomAPI.LightBuilder()
			.type(lType)
			.position(this._node.position)
			.rotation(this._node.rotation)
			.color(this._color)
			.intensity(this._intensity) // In LUX
			.spotConeInner(this._spotConeInner) // In Degrees
			.spotConeOuter(this._spotConeOuter) // In Degrees
			.fallOff(this._fallOff) // In meters
			.castShadows(this._castShadows)
			.build(this._root._native);

		this._native = this._root._native;
		this._dirty = false;
	}
}

LightObject.prototype.onAdded = function(parent)
{
	LEvent.bind( parent,"clicked", this.onClicked, this );

	this._node = parent.node;
}

LightObject.prototype.onRemoved = function(parent)
{
	LEvent.unbind( parent,"clicked", this.onClicked, this );

	this._node = null;
	if (this._native) {
		//nativeEngine._room.destroyEntityObj(this._native);
		this._native = null;
	}
}

LightObject.prototype.serialize = function(o)
{
	o.enabled = this._enabled;
	o.type = this._type;
	o.fallOff = this._fallOff;
	o.intensity = this._intensity;
	o.spotConeInner = this._spotConeInner;
	o.spotConeOuter = this._spotConeOuter;
	o.color = this._color;
	o.castShadows = this._castShadows;
}

LightObject.prototype.configure = function(o)
{
	if(o.enabled === undefined)
		return; //problems with legacy
		
	this._enabled = o.enabled;
	this._type = o.type;
	this._fallOff = o.fallOff;
	this._intensity = o.intensity;
	this._spotConeInner = o.spotConeInner;
	this._spotConeOuter = o.spotConeOuter;
	this._color = o.color;
	this._castShadows = o.castShadows;
}

LightObject.prototype.onClicked = function(e)
{
	//console.debug("clicked",this.entity.name);
	if (!this._on_focus)
		this.enterFocus();
	else //leaving
		this.exitFocus();
}


LightObject.prototype.preRender = function(view)
{
	if (!this._native) {
		if (xyz.native_json_loaded)
			this.createNative();
	}
	if (!this._native) {
		return;
	}

	var n = this._native.getRootNode();
	if (!vec3.equals(n.position, this._node.position) ||
		!vec3.equals(n.scaling, this._node.scaling) ||
		!quat.equals(n.rotation, this._node.rotation) ||
		this._dirty) {

		this._dirty = false;

		var lType = this.lightType();

		this._native.lightSetType(lType);
		this._native.setScale(this._node.scaling);
		this._native.setPosition(this._node.position);
		this._native.setRotation(this._node.rotation);
		this._native.lightSetFalloff(this._fallOff);
		this._native.lightSetIntensity(this._intensity);
		this._native.lightSetColor(this._color);
		this._native.lightSetSpotCone(this._spotConeInner, this._spotConeOuter);
		this._native.lightSetCastShadows(this._castShadows);
	}
}


LightObject.prototype.enterFocus = function()
{
	if (this._on_focus)
		return;

	LEvent.bind( this.space, "keydown", this.onKey, this );
	LEvent.bind( this.space, "mouse", this.onGlobalMouse, this );

	//compute radius
	var original_pos = this.entity.node.getGlobalPosition();
	var bb = node.updateBoundingBox();
	var center = BBox.getCenter(bb);
	var halfsize = BBox.getHalfsize(bb);
	vec3.mul( halfsize, halfsize, node.scaling );
	var radius = vec3.length( halfsize );// / this.entity.node.scaling[0];
	console.debug(radius);
	radius *= 1.5;
}

LightObject.prototype.exitFocus = function()
{
	if (!this._on_focus)
		return;

	LEvent.unbind(this.space, "keydown", this.onKey, this);
	LEvent.unbind(this.space, "mouse", this.onGlobalMouse, this);

	this._on_focus = false;
}

LightObject.prototype.onKey = function(type,e)
{
	if (e.key == "Escape")
	{
		this.exitFocus();
	}
	return true;
}

LightObject.prototype.onGlobalMouse = function(type,e)
{
	//console.debug(e);
	if (!this._on_focus)
		return;

	var speed = 0.02;

	if ( e.dragging )
	{
		//var node = this.entity.node;
		//var node = this._inspect_node;
		//var local_up = node.globalVectorToLocal( [0,1,0] );
		//var local_right = node.globalVectorToLocal( [1,0,0] );
		//node.rotate( e.deltax * speed, local_up );
		//node.rotate( e.deltay * speed, local_right );
	}

	if ( e.dragging && e.type == "mousemove")
		return true;
}

export default LightObject;
