import { vec3 } from "gl-matrix";
export const makeSliderTheme = (fontFamily = "Arial") => {
    return {
        rail: "rgba(0, 0, 0, 0.4)",
        progress: "white",
        ball: {
            default: vec3.fromValues(0, 136 / 255, 255 / 255),
            hover: vec3.fromValues(7 / 255, 99 / 255, 180 / 255),
        },
        label: {
            background: vec3.fromValues(0, 136 / 255, 255 / 255),
            text: vec3.fromValues(1, 1, 1),
            font: fontFamily,
        }
    };
};
