import { ARRAY_TYPES } from "./ArrayTypes";
import HDREImage from "./HDREImage";
import {
	CUBE_MAP_NEGATIVE_X,
	CUBE_MAP_NEGATIVE_Y,
	CUBE_MAP_NEGATIVE_Z,
	CUBE_MAP_POSITIVE_X,
	CUBE_MAP_POSITIVE_Y,
	CUBE_MAP_POSITIVE_Z
} from "./constants";

let last_parsed_file = undefined;

/*
  Private library methods
*/
function parseSignature(buffer, offset) {
	var endOffset = 4;

	return window.TextDecoder !== undefined ? new TextDecoder().decode(new Uint8Array(buffer).slice(offset, offset + endOffset)) : "";
}

function parseString(buffer, offset) {

	var uintBuffer = new Uint8Array(buffer);
	var endOffset = 0;

	while (uintBuffer[offset + endOffset] != 0)
		endOffset += 1;

	return window.TextDecoder !== undefined ? new TextDecoder().decode(new Uint8Array(buffer).slice(offset, offset + endOffset)) : "";
}

function parseFloat32(buffer, offset, LE) {
	return new DataView(buffer.slice(offset, offset + 4)).getFloat32(0, LE); // Float32
}

function parseUint16(buffer, offset, LE) {
	return new DataView(buffer.slice(offset, offset + 2)).getUint16(0, LE); // Uint16
}

function parseFaces(size, width, height, pixelData) {
	var faces = [],
		it = 0,
		F = CUBE_MAP_NEGATIVE_Y;

	for (var i = 0; i < 6; i++)
		faces[i] = new Float32Array(size);

	// get 3 vertical faces
	for (var i = 0; i < height; i++) {
		var x1_n = (width * 0.25) + (i * width),
			x2_n = (width * 0.5) + (i * width);

		if (i === (height / 3)) {
			F = CUBE_MAP_POSITIVE_Z;
			it = 0;
		}
		if (i === (height / 3) * 2) {
			F = CUBE_MAP_POSITIVE_Y;
			it = 0;
		}

		var line = pixelData.subarray(x1_n * 3, x2_n * 3);
		faces[F].set(line, it);
		it += line.length;
	}

	// from now get the rest from left to right

	it = 0;
	F = CUBE_MAP_NEGATIVE_X; // next face
	for (var i = (height / 3); i < (height / 3) * 2; i++) {
		var x1_n = (width * 0.0) + (i * width),
			x2_n = (width * 0.25) + (i * width);

		var line = pixelData.subarray(x1_n * 3, x2_n * 3);
		faces[F].set(line, it);
		it += line.length;
	}

	it = 0;
	F = CUBE_MAP_POSITIVE_X; // next face
	for (var i = (height / 3); i < (height / 3) * 2; i++) {
		var x1_n = (width * 0.5) + (i * width),
			x2_n = (width * 0.75) + (i * width);

		var line = pixelData.subarray(x1_n * 3, x2_n * 3);
		faces[F].set(line, it);
		it += line.length;
	}

	it = 0;
	F = CUBE_MAP_NEGATIVE_Z; // next face
	for (var i = (height / 3); i < (height / 3) * 2; i++) {
		var x1_n = (width * 0.75) + (i * width),
			x2_n = (width * 1.0) + (i * width);

		var line = pixelData.subarray(x1_n * 3, x2_n * 3);
		faces[F].set(line, it);
		it += line.length;
	}

	// order faces
	var ret = [];

	ret.push(faces[CUBE_MAP_POSITIVE_X],
		faces[CUBE_MAP_POSITIVE_Y],
		faces[CUBE_MAP_POSITIVE_Z],
		faces[CUBE_MAP_NEGATIVE_X],
		faces[CUBE_MAP_NEGATIVE_Y],
		faces[CUBE_MAP_NEGATIVE_Z]);

	return ret;
}

/**
 * Parse the input data and create texture
 * @method parse
 * @param {ArrayBuffer} buffer
 * @param {Object} options (oncomplete, onprogress, filename, ...)
 */
export const parse = function(buffer, options = {}) {
	if (!buffer) {
		throw ("No data buffer");
	}

	var fileSizeInBytes = buffer.byteLength;
	var LE = true;

	/*
  *   Read header
  */

	// Read signature
	var sg = parseSignature(buffer, 0);

	// Read version
	var v = parseFloat32(buffer, 4, LE);

	// Get 2 bytes of width, height
	var w = parseUint16(buffer, 8, LE);
	var h = parseUint16(buffer, 10, LE);
	// Get max file size in bytes
	var m = parseFloat(parseFloat32(buffer, 12, LE));

	// Set rest of the bytes
	var c = parseUint16(buffer, 16, LE);
	var b = parseUint16(buffer, 18, LE);
	var s = parseUint16(buffer, 20, LE);
	var isLE = parseUint16(buffer, 22, LE);

	var i = parseFloat(parseFloat32(buffer, 24, LE));
	var a = parseUint16(buffer, 28, LE);

	var shs = null;
	var hasSH = parseUint16(buffer, 30, LE);

	if (hasSH) {
		var Ncoeffs = parseFloat32(buffer, 32, LE) * 3;
		shs = [];
		var pos = 36;

		for (var i = 0; i < Ncoeffs; i++) {
			shs.push(parseFloat32(buffer, pos, LE));
			pos += 4;
		}
	}

	var header = {
		version: v,
		signature: sg,
		type: a,
		width: w,
		height: h,
		nChannels: c,
		bpChannel: b,
		maxIrradiance: i,
		shs: shs,
		encoding: isLE,
		size: fileSizeInBytes
	};

	// console.table(header);
	window.parsedFile = last_parsed_file = { buffer: buffer, header: header };

	if (v < 2 || v > 1e3) { // bad encoding
		console.error("old version, please update the HDRE");
		return false;
	}
	if (fileSizeInBytes > m) {
		console.error("file too big");
		return false;
	}


	/*
  *   BEGIN READING DATA
  */

	var dataBuffer = buffer.slice(s);
	var array_type = ARRAY_TYPES[header.type];

	var dataSize = dataBuffer.byteLength / 4;
	var data = new array_type(dataSize);
	var view = new DataView(dataBuffer);

	var pos = 0;

	for (var i = 0; i < dataSize; i++) {
		data[i] = view.getFloat32(pos, LE);
		pos += 4;
	}

	var numChannels = c;

	var ems = [],
		precomputed = [];

	var offset = 0;
	var originalWidth = w;

	for (var i = 0; i < 6; i++) {
		var mip_level = i + 1;
		var offsetEnd = w * w * numChannels * 6;
		ems.push(data.slice(offset, offset + offsetEnd));
		offset += offsetEnd;

		if (v > 2.0) {
			w = originalWidth / Math.pow(2, mip_level);
		} else {
			w = Math.max(8, originalWidth / Math.pow(2, mip_level));
		}
	}

	/*
    Get bytes
  */

	// care about new sizes (mip map chain)
	w = header.width;

	for (var i = 0; i < 6; i++) {
		var bytes = ems[i];

		// Reorder faces
		var faces = [];
		var bPerFace = bytes.length / 6;

		var offset = 0;

		for (var j = 0; j < 6; j++) {
			faces[j] = new array_type(bPerFace);

			var subdata = bytes.slice(offset, offset + (numChannels * w * w));
			faces[j].set(subdata);

			offset += (numChannels * w * w);
		}

		precomputed.push({
			data: faces,
			width: w
		});

		// resize next textures
		var mip_level = i + 1;

		if (v > 2.0) {
			w = originalWidth / Math.pow(2, mip_level);
		} else {
			w = Math.max(8, originalWidth / Math.pow(2, mip_level));
		}

		if (options.onprogress) {
			options.onprogress(i);
		}
	}

	return new HDREImage(header, precomputed, options);
};
