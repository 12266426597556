import { GL } from "@src/libs/litegl";
import clamp from "@src/math/clamp";
import { vec2 } from "gl-matrix";
import CutPixelShader from "./Shaders/Glow/CutPixelShader.glsl";
import FinalPixelShader from "./Shaders/Glow/FinalPixelShader.glsl";
import ScalePixelShader from "./Shaders/Glow/ScalePixelShader.glsl";

/**
 * Independent glow FX
 * @link https://catlikecoding.com/unity/tutorials/advanced-rendering/bloom/
 */
class FXGlow {
	fx_name = "Glow";

	cut_pixel_shader = CutPixelShader;
	scale_pixel_shader = ScalePixelShader;
	final_pixel_shader = FinalPixelShader;
	_cut_shader = undefined;

	enabled = true;
	intensity = 0.5;
	persistence = 0.6;
	iterations = 8;
	threshold = 0.9;
	scale = 1;

	dirt_texture = null;
	dirt_factor = 0.5;

	_textures = [];
	_uniforms = {
		u_intensity: 1,
		u_texture: 0,
		u_glow_texture: 1,
		u_threshold: 0,
		u_texel_size: vec2.create(),
	};

	applyFX(color_texture, depth_texture, output_texture, glow_texture, average_texture) {
		if (!this.enabled || this.intensity <= 0.0) {
			if (color_texture !== output_texture) {
				color_texture.copyTo(output_texture);
			}
			return;
		}

		let width = color_texture.width;
		let height = color_texture.height;

		const texture_info = {
			format: color_texture.format,
			type: color_texture.type,
			minFilter: GL.LINEAR,
			magFilter: GL.LINEAR,
			wrap: gl.CLAMP_TO_EDGE
		};

		const uniforms = this._uniforms;
		const textures = this._textures;

		const original_output_texture = output_texture;
		if (output_texture === color_texture) {
			output_texture = GL.Texture.getTemporary(
				width,
				height,
				texture_info
			);
		}

		//cut
		let cutShader = this._cut_shader;
		if (!cutShader) {
			cutShader = this._cut_shader = new GL.Shader(
				GL.Shader.SCREEN_VERTEX_SHADER,
				this.cut_pixel_shader
			);
		}

		gl.disable(gl.DEPTH_TEST);
		gl.disable(gl.BLEND);

		uniforms.u_threshold = this.threshold;
		let currentDestination = (textures[0] = GL.Texture.getTemporary(
			width,
			height,
			texture_info
		));
		color_texture.blit(currentDestination, cutShader.uniforms(uniforms));
		let currentSource = currentDestination;

		let iterations = this.iterations;
		iterations = clamp(iterations, 1, 16) | 0;
		const texel_size = uniforms.u_texel_size;
		const intensity = this.intensity;

		uniforms.u_intensity = 1;
		uniforms.u_delta = this.scale; //1

		//downscale/upscale shader
		var shader = this._shader;
		if (!shader) {
			shader = this._shader = new GL.Shader(
				GL.Shader.SCREEN_VERTEX_SHADER,
				this.scale_pixel_shader
			);
		}

		let i = 1;
		//downscale
		for (; i < iterations; i++) {
			width = width >> 1;
			if ((height | 0) > 1) {
				height = height >> 1;
			}
			if (width < 2) {
				break;
			}
			currentDestination = textures[i] = GL.Texture.getTemporary(
				width,
				height,
				texture_info
			);
			texel_size[0] = 1 / currentSource.width;
			texel_size[1] = 1 / currentSource.height;
			currentSource.blit(
				currentDestination,
				shader.uniforms(uniforms)
			);
			currentSource = currentDestination;
		}

		//average
		if (average_texture) {
			texel_size[0] = 1 / currentSource.width;
			texel_size[1] = 1 / currentSource.height;
			uniforms.u_intensity = intensity;
			uniforms.u_delta = 1;
			currentSource.blit(average_texture, shader.uniforms(uniforms));
		}

		//upscale and blend
		gl.enable(gl.BLEND);
		gl.blendFunc(gl.ONE, gl.ONE);
		uniforms.u_intensity = this.persistence;
		uniforms.u_delta = 0.5;

		// i-=2 => -1 to point to last element in array, -1 to go to texture above
		for (i -= 2; i >= 0; i--) {
			currentDestination = textures[i];
			textures[i] = null;
			texel_size[0] = 1 / currentSource.width;
			texel_size[1] = 1 / currentSource.height;
			currentSource.blit(
				currentDestination,
				shader.uniforms(uniforms)
			);
			GL.Texture.releaseTemporary(currentSource);
			currentSource = currentDestination;
		}
		gl.disable(gl.BLEND);

		//glow
		if (glow_texture) {
			currentSource.blit(glow_texture);
		}

		//final composition
		if (output_texture) {
			const final_texture = output_texture;
			const dirt_texture = this.dirt_texture;
			const dirt_factor = this.dirt_factor;
			uniforms.u_intensity = intensity;

			shader = dirt_texture
				? this._dirt_final_shader
				: this._final_shader;
			if (!shader) {
				if (dirt_texture) {
					shader = this._dirt_final_shader = new GL.Shader(
						GL.Shader.SCREEN_VERTEX_SHADER,
						this.final_pixel_shader,
						{ USE_DIRT: "" }
					);
				} else {
					shader = this._final_shader = new GL.Shader(
						GL.Shader.SCREEN_VERTEX_SHADER,
						this.final_pixel_shader
					);
				}
			}

			final_texture.drawTo(function() {
				color_texture.bind(0);
				currentSource.bind(1);
				if (dirt_texture) {
					shader.setUniform("u_dirt_factor", dirt_factor);
					shader.setUniform(
						"u_dirt_texture",
						dirt_texture.bind(2)
					);
				}
				shader.toViewport(uniforms);
			});
		}

		GL.Texture.releaseTemporary(currentSource);

		if (color_texture === original_output_texture) {
			output_texture.copyTo(original_output_texture);
			GL.Texture.releaseTemporary(output_texture);
		}
	}
}

export default FXGlow;
