import lerp from "lerp";
import { CameraConstraint } from "@src/engine/components/Camera/intelligent/constraints/CameraConstraint";
export class FovConstraint extends CameraConstraint {
    constructor(minFov, maxFov, initialFactor, lerpFactor) {
        super();
        this.minFov = minFov;
        this.maxFov = maxFov;
        this.currentFovFactor = initialFactor;
        this.targetFovFactor = initialFactor; // Start with the initial factor
        this.lerpFactor = lerpFactor;
    }
    updateFactor(newFactor) {
        // Update the target factor
        this.targetFovFactor = newFactor;
    }
    setFov(newFov) {
        this.fovTarget = newFov;
    }
    tick(timeDelta) {
        // Progressively update the current factor towards the target factor over time
        this.currentFovFactor = lerp(this.currentFovFactor, this.targetFovFactor, this.lerpFactor);
    }
    solve(outputState, inputState) {
        outputState.copy(inputState);
        // Apply the current FOV factor to interpolate the FOV if there is no fovTarget
        outputState.fov = this.fovTarget || lerp(this.minFov, this.maxFov, 1 - this.currentFovFactor);
    }
}
