import { vec3 } from "gl-matrix";
import lerp from "lerp";

import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import Button from "@src/libs/GLUI/Elements/Button";
import { GL } from "@src/libs/litegl";
import clamp from "@src/math/clamp";
import remap from "@src/math/remap";

//Renders the Participants Bubbles floating in the screen borders
function BubblesManager(xyz, call) {
	this.xyz = xyz;
	this.call = call;

	this.render_outofview = true;
	this.show_muted_as_ghost = false;
	this.skip_selfview = false;
	this.smooth_transitions = true;
	this.align_face = false; //sue internal offset and internal scale on video feed
	this.blur_all = false;

	this.camview_size = 150;
	this.camview_margin = 30;
	this.mode = BubblesManager.CALL_MODE;
	this._current_mode = null; //in case we use an AUTO mode, this has the real final mode
	this.left_scroll = 0;
	this.right_scroll = 0;
	this.showBubble = false;

	this.texture_size = 256;

	this.freeze_next_frame = false;

	//to store temporary
	this.top_bin = [];
	this.left_bin = [];
	this.right_bin = [];
	this.center_bin = []; //on the 3D space

	this.maskDiv = document.createElement("div");
	this.maskText = document.createElement("div");
	this.screenShareMaskInitialized = false;
	this.showScreenShareBubble = false;
	this.initializeMaskElements();
}

//WIP
BubblesManager.AUTO_MODE = 0; //checks best option
BubblesManager.CALL_MODE = 1; //bubles on side
BubblesManager.FLAT_MODE = 2; //bubles spread around all screen
BubblesManager.TOP_MODE = 3; //bubles on top bar

BubblesManager.MAX_DISTANCE = 8;

BubblesManager.filter_distance_func = function (a) {
	return a._distance_to_camera < BubblesManager.MAX_DISTANCE;
};

BubblesManager.prototype.renderBubbles = function (participants) {
	for (var i = 0; i < participants.length; ++i) {
		var participant = participants[i];
		var bubble = participant._bubble;
		this.renderCamView(participant, bubble[0], bubble[1], bubble[2], bubble[3]);
	}
};

BubblesManager.prototype.render = function (alpha, force_mode) {
	var space = this.xyz.space;
	var local_participant = space.local_participant;
	if (!local_participant) return;

	//filter by distance so faraway people doesnt have a bubble
	var participants = space.participants;

	participants = participants.filter(BubblesManager.filter_distance_func);

	var mode = this.mode;
	if (force_mode) mode = force_mode;
	this._current_mode = mode;

	if (this.screenShareMaskInitialized && this.showScreenShareBubble) {
		//this.renderScreenShareBubble();
	}

	if (mode === BubblesManager.AUTO_MODE) {
		if (local_participant.focus_item) mode = BubblesManager.TOP_MODE;
		else if (this.call.enable_flatcall) mode = BubblesManager.FLAT_MODE;
		else if (this.call.controller_from_component)
			mode = BubblesManager.TOP_MODE;
		else mode = BubblesManager.CALL_MODE;
	}

	if (mode === BubblesManager.CALL_MODE)
		this.renderCallMode(participants, alpha);
	else if (mode === BubblesManager.FLAT_MODE) {
		participants = space.participants.concat(); //the other one contains filtered people based on distance
		this.renderFlatMode(participants, alpha);
	} else if (mode === BubblesManager.TOP_MODE)
		this.renderTopMode(participants, alpha);
};

//region Mask element properties
BubblesManager.prototype.initializeMaskElements = function () {
	this.maskDiv.style.width = "208px";
	this.maskDiv.style.height = "124px";
	this.maskDiv.style.backgroundColor = "red";
	this.maskDiv.style.position = "absolute";
	this.maskDiv.style.top = "14px";
	this.maskDiv.style.left = "12px";
	this.maskDiv.style.zIndex = "1";
	this.maskDiv.style.backgroundColor = "rgba(64, 64, 64, 0.85)";
	this.maskDiv.style.border = "solid 1px rgba(128, 128, 128, 0.85)";
	this.maskDiv.style.borderRadius = "10px";
	this.maskDiv.style.backgroundOrigin = "border-box";
	this.maskDiv.style.backgroundClip = "padding-box, border-box";
	this.maskDiv.style.clipPath = "polygon(0% 0%, 0% 100%, 6% 100%, 6% 8%, 95% 8%, 95% 78%, 6% 78%, 0 100%, 100% 100%, 100% 0%)"

	this.maskText.style.position = "absolute";
	this.maskText.style.bottom = "4px";
	this.maskText.style.left = "12px";
	this.maskText.style.right = "12px";
	this.maskText.style.zIndex = "1000";
	this.maskText.style.fontSize = "12px";
	this.maskText.style.textAlign = "center";
	this.maskText.style.color = "white";

	this.maskDiv.appendChild(this.maskText);

	this.screenShareMaskInitialized = true;
}

BubblesManager.prototype.renderScreenShareBubble = function() {
	if (!XYZLauncher.instance.mobile && XYZLauncher?.instance?.globalFeedTexture && this.showBubble) {
		gl.enable(gl.BLEND);
		gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

		let owner = XYZLauncher.instance.globalFeedOwner;
		owner = owner || "Guest"  //prevent undefined owner
		owner = owner.length > 8 ? owner.substring(0, 9) + "..." : owner;
		this.maskText.innerText = `${owner} is screen sharing`;

		if (!document.body.contains(this.maskDiv)) {
			document.body.appendChild(this.maskDiv);
		}

		gl.drawTexture(XYZLauncher.instance.globalFeedTexture, 24, 24, 186, 87);
	} else {
		if (document.body.contains(this.maskDiv)) {
			document.body.removeChild(this.maskDiv);
		}
	}
}

BubblesManager.prototype.renderTopMode = function (participants, alpha) {
	var w = gl.canvas.width;
	var h = gl.canvas.height;
	var space = this.xyz.space;
	var local_participant = space.local_participant;

	var mouse_y = gl.mouse.mousey;
	var minscale = 0.5;
	var maxscale = 0.9;
	var centerx = gl.canvas.width * 0.5;
	var size = this.camview_size;
	size = Math.min(size, gl.canvas.height * 0.15);
	var separated = false;

	var lerp_factor = 0; //0.9; //this.smooth_transitions ? 0.9 : 0;

	var focused_participant = null;
	var in_focus = local_participant.focus_item != null;

	if (local_participant.focus_item) {
		var ent = local_participant.focus_item.getParentEntity();
		if (ent.seat && ent.seat.participant)
			focused_participant = ent.seat.participant;
	}

	var y = size * 0.6;
	var index = 0;

	if (this.freeze_next_frame) {
		//fade out when freeze
		for (var i = 0; i < participants.length; ++i) {
			var participant = participants[i];
			var bubble = participant._bubble;
			bubble[3] = lerp(0, bubble[3], 0.8);
		}
	} else {
		for (var i = 0; i < participants.length; ++i) {
			var participant = participants[i];
			var bubble = participant._bubble;

			if (!separated && participant.was_visible) {
				if (bubble) bubble[3] = 0; //alpha 0, do not render
				continue;
			}

			var item_size = size; // * (participant.is_local_participant ? 1.2 : 1);
			var item_alpha = alpha;
			var offsetx = size * Math.floor((index + 1) / 2) * 2 * 0.5;
			//if(index!=0)
			//	offsetx += this.camview_margin * 0.5;
			var x = w * 0.5 + offsetx * (index % 2 ? 1 : -1);
			var item_y = y;

			//interpolate
			bubble[0] = lerp(x, bubble[0], lerp_factor);
			bubble[1] = lerp(item_y, bubble[1], 0.9);
			bubble[2] = lerp(item_size, bubble[2], lerp_factor);
			bubble[3] = lerp(item_alpha, bubble[3], lerp_factor);

			index++;
		}
	} //freeze

	this.renderBubbles(participants);

	this.freeze_next_frame = false;
};

BubblesManager.prototype.renderFlatMode = function (participants, alpha) {
	var w = gl.canvas.width;
	var h = gl.canvas.height;
	var space = this.xyz.space;
	var local_participant = space.local_participant;

	var focused_participant = null;
	var in_focus = local_participant.focus_item != null;

	if (local_participant.focus_item) {
		var ent = local_participant.focus_item.getParentEntity();
		if (ent.seat && ent.seat.participant)
			focused_participant = ent.seat.participant;
	}

	var lerp_factor = this.smooth_transitions ? 0.9 : 0;
	var minscale = 0.5;
	var maxscale = 0.9;
	var num_participants = participants.length;
	if (focused_participant) num_participants -= 1;
	var size = this.camview_size;
	var marginleft = this.camview_margin;
	var usefularea = Math.floor(w - size - 100); //area that can be used for secondary participants
	var maxsecondary = Math.floor(usefularea / (size * minscale));
	var numsecondary = num_participants - 1;
	var secondary_factor = clamp(
		(usefularea * 0.5) / Math.ceil(0.5 * numsecondary) / size,
		minscale,
		maxscale
	);
	var secondarysize = secondary_factor * size;

	var numrows = 2;
	if (num_participants > 8) numrows = 3;
	var num_per_row = Math.ceil(num_participants / numrows);
	num_per_row = Math.max(4, num_per_row);
	secondarysize = Math.min(
		(gl.canvas.width - marginleft * 2) / num_per_row,
		(gl.canvas.height - 80) / numrows
	);
	secondarysize = Math.floor(secondarysize);

	participants = participants.concat(); //clone
	participants.sort(function (a, b) {
		return a.screen_position[0] - b.screen_position[0];
	}); //left to right

	var gridx = 0;
	var gridy = 0;
	var row = 0;

	for (var i = 0; i < participants.length; ++i) {
		var participant = participants[i];

		//compute pos
		var x = 0;
		var y = 0;

		x = gridx + secondarysize * 0.5 + marginleft;
		y = gridy + secondarysize * 0.5 + 40;
		gridx += secondarysize;
		//outside? do next row
		if (gridx > gl.canvas.width - secondarysize - marginleft * 2) {
			gridx = 0;
			gridy += secondarysize;
			row++;
		}

		//interpolate
		var bubble = participant._bubble;

		bubble[0] = lerp(x, bubble[0], lerp_factor);
		bubble[1] = lerp(y, bubble[1], 0.9);
		bubble[2] = lerp(secondarysize, bubble[2], lerp_factor);
		bubble[3] = lerp(alpha, bubble[3], lerp_factor);
	}

	this.renderBubbles(participants);
};

BubblesManager.prototype.renderCallMode = function (participants, alpha) {
	var w = gl.canvas.width;
	var h = gl.canvas.height;
	var space = this.xyz.space;
	var local_participant = space.local_participant;

	var mouse_y = gl.mouse.mousey;
	var minscale = 0.5;
	var maxscale = 0.9;
	var centerx = gl.canvas.width * 0.5;
	var size = this.camview_size;

	var lerp_factor = this.smooth_transitions ? 0.9 : 0;

	var focused_participant = null;
	var in_focus = local_participant.focus_item != null;

	if (local_participant.focus_item) {
		var ent = local_participant.focus_item.getParentEntity();
		if (ent.seat && ent.seat.participant)
			focused_participant = ent.seat.participant;
	}

	if (participants.length === 2) {
		//special case
		maxscale = 1;
		centerx -= size * 0.5;
	}

	//all this code to arrange them...
	var num_participants = participants.length;
	if (focused_participant) num_participants -= 1;
	var usefularea = Math.floor(w - size - 100); //area that can be used for secondary participants
	var maxsecondary = Math.floor(usefularea / (size * minscale));
	var numsecondary = num_participants - 1;
	var secondary_factor = clamp(
		(usefularea * 0.5) / Math.ceil(0.5 * numsecondary) / size,
		minscale,
		maxscale
	);
	var secondarysize = secondary_factor * size;
	//var secondarysize = size * 0.9;

	if (!this._prev_secondary_bubble_size) this._prev_secondary_bubble_size = 0.1;
	this._prev_secondary_bubble_size = secondarysize = lerp(
		secondarysize,
		this._prev_secondary_bubble_size,
		0.9
	);

	var marginleft = 40;

	if (space.local_participant) {
		var selfview_alpha = alpha;
		if (space.local_participant.head_offset > 1)
		//far enough
			selfview_alpha = 0;

		var local_p = space.local_participant;
		if (!local_p._bubble) local_p._bubble = [ centerx, size * 0.5, size, 1 ];
		var bubble = local_p._bubble;

		var me_size = size;
		me_size *= 0.6;

		bubble[0] = centerx;
		bubble[1] = me_size * 0.5;
		bubble[2] = me_size;
		bubble[3] = lerp(selfview_alpha, bubble[3], lerp_factor);

		//self view
		if (!this.skip_selfview)
			this.renderCamView(local_p, bubble[0], bubble[1], bubble[2], bubble[3]);
	}

	var gridx = 0;
	var gridy = 0;
	var row = 0;

	if (!this._outside_bubble_y_left)
		this._outside_bubble_y_left = this._outside_bubble_y_right =
      gl.canvas.height * 0.5;

	var outside_y_left = 0;
	var outside_y_right = 0;

	//other attendees
	for (var i = 1; i < participants.length; ++i) {
		var participant = participants[i];

		//compute pos
		var x = 0;
		var y = 0;

		if (focused_participant === participant)
		//skip focused participant
			continue;

		x = Math.floor((i - 1) / 2) * secondarysize + (size + secondarysize) * 0.5;
		if (i % 2 === 1) x += centerx;
		else x = centerx - x;
		if (x + secondarysize * 0.5 > w)
		//avoid outside
			break;
		y = !in_focus ? -secondarysize : secondarysize * 0.5;
		if (!in_focus && participant.screen_position) {
			var screenpos = participant.screen_position;
			var is_in_front = true; //screenpos[2] < 1;
			if (
				this.render_outofview &&
        (screenpos[0] < 0 || screenpos[0] > gl.canvas.width)
			) {
				//&& screenpos[2] > 0) //outside
				if (screenpos[0] < 0) {
					x = secondarysize * 0.5;
					y = this._outside_bubble_y_left + outside_y_left + this.left_scroll;
					if (is_in_front) outside_y_left += secondarysize;
				} else {
					x = gl.canvas.width - secondarysize * 0.5;
					y =
            this._outside_bubble_y_right + outside_y_right + this.right_scroll;
					if (is_in_front) outside_y_right += secondarysize;
				}

				if (is_in_front) alpha = 1;
				else {
					x = screenpos[0];
					y = screenpos[1];
					alpha = 0;
				}
			} else {
				x = screenpos[0];
				y = screenpos[1];
				alpha = 0;
			}
		}

		//fade distance
		if (0) {
			var f = clamp(
				participant._distance_to_camera / BubblesManager.MAX_DISTANCE,
				0,
				1
			);
			alpha *= clamp(remap(f, 0.9, 1, 1, 0), 0, 1);
		}

		//interpolate
		var bubble = participant._bubble;

		bubble[0] = lerp(x, bubble[0], lerp_factor);
		bubble[1] = lerp(y, bubble[1], 0.9);
		bubble[2] = lerp(secondarysize, bubble[2], lerp_factor);
		bubble[3] = lerp(alpha, bubble[3], lerp_factor);

		//draw
		this.renderCamView(
			participant,
			bubble[0],
			bubble[1],
			bubble[2],
			bubble[3],
			true
		);
	}

	this._outside_bubble_y_left = gl.canvas.height * 0.5 - outside_y_left * 0.5;
	this._outside_bubble_y_right = gl.canvas.height * 0.5 - outside_y_right * 0.5;
};

//renders the bubble of one person
BubblesManager.prototype.renderCamView = function (
	participant,
	centerx,
	centery,
	size,
	alpha,
	skip_username
) {
	if (!participant) return;

	if (alpha === undefined) alpha = 1;

	if (alpha <= 0 && !participant._force_bubble_update) return;

	if (
		(centerx < -size * 0.5 ||
      centerx > gl.canvas.width + size * 0.5 ||
      centery < -size * 0.5 ||
      centery > gl.canvas.height + size * 0.5) &&
    !participant._force_bubble_update
	)
		return; //out of screen

	participant._force_bubble_update = false;

	//compute texture size
	size = Math.ceil(size);
	var fbo_texture_size = this.texture_size;
	if (this.mode === BubblesManager.FLAT_MODE) fbo_texture_size *= 2;

	//update texture and FBO
	if (
		!participant._bubble_fbo ||
    participant._bubble_texture.width !== fbo_texture_size
	) {
		participant._bubble_texture = new GL.Texture(
			fbo_texture_size,
			fbo_texture_size,
			{ format: gl.RGBA }
		);
		participant._bubble_fbo = new GL.FBO([ participant._bubble_texture ]);
	}

	var x = centerx - size * 0.5;
	var y = centery - size * 0.5;

	var view = this.xyz.view;
	var ctx = gl;

	//detect mouse hovering or clicking
	var is_hover = false;
	if (alpha >= 0.5) {
		var mouse = GUI.HoverArea(x, y, size, size); //this blocks the GUI, careful
		// Disable Participant bubble Click on tutorial flow
		if (mouse === GLUI.CLICKED && !this.call.launcher.is_in_tutorial_flow)
		{
			this.call.onParticipantClicked(participant, true, GUI.last_mouse_event);
		}
		is_hover = mouse === GLUI.HOVER;
	}

	//fill with the texture
	var mirror =
    (participant.is_local_participant &&
		ROOM_SETTINGS.call.bubbles.mirror_myself) ||
    (!participant.is_local_participant &&
		ROOM_SETTINGS.call.bubbles.mirror_others);
	this.composeCamViewBubble(participant, fbo_texture_size, view, mirror);

	var camviewx = x;
	var camviewy = y;
	participant.mouse_hover |= is_hover;

	//draw bubble on the screen
	var texsize = size;
	ctx.globalAlpha = alpha;

	var bg_path = participant.is_founder
		? "textures/camview-circle-founder.png"
		: "textures/camview-circle.png";
	//gl.colorMask(true,true,true,false);
	ctx.drawImage(
		view.loadTexture(bg_path),
		camviewx,
		camviewy,
		texsize,
		texsize
	); //done before
	ctx.drawImage(
		participant._bubble_texture,
		camviewx,
		camviewy,
		texsize,
		texsize
	);

	//draw user name
	var nametag_texture = participant.avatar.nametag_texture;
	const has_nametag =
    nametag_texture && nametag_texture.username && !skip_username;
	let nametag_visible = false;
	if (has_nametag) {
		var aspect = nametag_texture.width / nametag_texture.height;
		if (this._current_mode === BubblesManager.FLAT_MODE) {
			var namesize_width = size * 0.5;
			var namesize_height = namesize_width / aspect;
			ctx.drawImage(
				nametag_texture,
				x + size * 0.5 - namesize_width * 0.5,
				y + size - namesize_height,
				namesize_width,
				namesize_height
			);
			nametag_visible = true;
		} else if (participant.mouse_hover) {
			//&& !participant.is_local_participant)
			var infobar_height = size / aspect;
			var infobar_y = y + size;
			ctx.drawImage(nametag_texture, x, infobar_y, size, infobar_height);
			nametag_visible = true;
		}
	}

	if ( ROOM_SETTINGS.call.bubbles.draw_icons )
	{
		//draw mute icons
		var iconsize = size / 2.5;
		var offset = 0;
		if ( (participant.is_video_muted || !participant.feed_info) )
			offset += 1;
		if ( participant.is_audio_muted )
			offset += 1;
		if (offset == 2)
			offset = -iconsize/2;

		if (!nametag_visible)
		{
			if (participant.is_video_muted || !participant.feed_info)
			{
				Button.call(GUI,
					centerx - iconsize / 2 + offset,
					centery + size / 2 - iconsize / 2,
					iconsize,
					iconsize,
					[ 7, 7 ],
					false,
					[ 0.4, 0.4, 0.4, alpha * 0.8 ],
					null,
					iconsize / 2
				);
				offset += iconsize;
			}
			if (participant.is_audio_muted )
				Button.call(GUI,
					centerx - iconsize / 2 + offset,
					centery + size / 2 - iconsize / 2,
					iconsize,
					iconsize,
					[ 3, 7 ],
					false,
					[ 0.4, 0.4, 0.4, alpha * 0.8 ],
					null,
					iconsize / 2
				);
		}
	}

	//gl.colorMask(true,true,true,true);
	ctx.globalAlpha = 1;
};

BubblesManager.prototype.composeCamViewBubble = function (
	participant,
	size,
	view,
	mirrored
) {
	var ctx = gl;
	var space = this.xyz.space;

	var texture = null;
	if (participant.is_video_muted && this.show_muted_as_ghost)
		texture = view.loadTexture("textures/profile_shape.png");
	else texture = participant.getProfileTexture(true); //returns feed, or profile pic or null
	var feed_options = participant.avatar._feed_options;

	if (participant.is_on_hold || this.blur_all) {
		if (!participant._bubble_texture.is_blurred) {
			participant._bubble_texture.applyBlur(4, 4, 1);
			participant._bubble_texture.is_blurred = true;
			gl.enable(GL.BLEND);
		}
		return;
	}

	participant._bubble_texture.is_blurred = false;

	//switch to render inside texture *************************
	participant._bubble_fbo.bind();

	var participant_settings = ROOM_SETTINGS.participants;

	//textures path
	var bg_path = participant.is_founder
		? "textures/camview-circle-founder.png"
		: "textures/camview-circle.png";
	var clip_path = participant.is_founder
		? "textures/camview-clip-founder.png"
		: "textures/camview-clip.png";
	var ring_path = "textures/camview-ring.png";

	gl.clearColor(1, 1, 1, 0);
	gl.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);

	//draw bg
	ctx.fillColor = [ 1, 1, 1, 0.5 ];
	ctx.globalAlpha = 1;
	ctx.drawImage(view.loadTexture(bg_path), 0, 0, size, size);
	ctx.globalAlpha = 1;

	//render ring
	var circle_alpha = /*alpha * */ participant.smooth_loudness;
	var circle_color = [ 0.2, 0.4, 1, 1 ];
	if (participant.is_audio_muted && 0) {
		circle_color = [ 0, 0, 0, 1 ];
		circle_alpha = 1;
	}

	//render icons here
	//...

	//external ring
	if (circle_alpha > 0) {
		ctx.fillColor = circle_color;
		ctx.globalAlpha = circle_alpha;
		ctx.tintImages = true;
		ctx.drawImage(view.loadTexture(ring_path), 0, 0, size, size);
		//ctx.globalAlpha = alpha;
		ctx.tintImages = false;
	}

	//confine inside area, this was done when it was direct rendered to screen?
	gl.enable(gl.SCISSOR_TEST);
	gl.scissor(0, participant._bubble_texture.height - size, size, size);

	if (texture) {
		//compute rectangle
		var rect = null;
		if (feed_options) {
			if (feed_options.rectangle) rect = feed_options.rectangle;
			else if (feed_options.mode === "holoh")
				rect = [ 0, 0, texture.width, texture.height * 0.5 ];
		}

		var aspect = 1;
		if (rect) aspect = rect[2] / rect[3];
		else aspect = texture.width / texture.height;

		var head_scale = 1.25 * participant.face_scale;
		var camw = size * aspect * head_scale;
		var camh = size * head_scale;
		var camviewx =
      size * 0.5 -
      camw * 0.5 +
      0.25 *
        participant.face_offset[0] *
        camw *
        participant_settings.profile_scaling;
		var camviewy =
      (size - size * head_scale) * 0.25 +
      0.25 *
        participant.face_offset[1] *
        camh *
        participant_settings.profile_scaling;
		ctx.tintImages = true;

		//apply color grading from space and user brightness
		var f = Math.pow(participant.face_brightness, 1 / 3.2);
		var color = [ f, f, f, 1 ];
		vec3.mul(color, color, space.fx.participants_basecolor);
		ctx.fillColor = color;

		if (rect) {
			//only one area of the image contains the face (used for MCU)
			if (mirrored)
				ctx.drawImage(
					texture,
					rect[0],
					rect[1],
					rect[2],
					rect[3],
					camviewx + camw,
					camviewy,
					-camw,
					camh
				);
			else
				ctx.drawImage(
					texture,
					rect[0],
					rect[1],
					rect[2],
					rect[3],
					camviewx,
					camviewy,
					camw,
					camh
				);
		} else {
			if (mirrored)
			//if yourself, reverse
				ctx.drawImage(texture, camviewx + camw, camviewy, -camw, camh);
			//reverse
			else ctx.drawImage(texture, camviewx, camviewy, camw, camh);
		}

		ctx.tintImages = false;
	}
	gl.disable(gl.SCISSOR_TEST);

	//multiply alpha by clip path (careful that the shader do not discard alpha==0)
	gl.enable(gl.BLEND);
	gl.blendFunc(gl.DST_ALPHA, gl.ZERO);
	gl.colorMask(false, false, false, true);
	ctx.drawImage(view.loadTexture(clip_path), 0, 0, size, size);
	gl.colorMask(true, true, true, true);
	//gl.enable( gl.BLEND );
	gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

	//end rendering inside texture ************
	participant._bubble_fbo.unbind();
};

BubblesManager.prototype.onMouseWheel = function (e) {
	if (e.canvasx < this.camview_size) this.left_scroll += e.deltaY * 0.1;
	else if (e.canvasx > gl.canvas.width - this.camview_size)
		this.right_scroll += e.deltaY * 0.1;
	else return false;
	return true;
};

//separate the users in bins, to render them in batch
//WIP
/*
BubblesManager.prototype.sortParticipantsToBins = function(participants)
{
	this.top_bin.length = 0;
	this.left_bin.length = 0;
	this.right_bin.length = 0;
	this.center_bin.length = 0;

	var w = gl.canvas.width;
	var h = gl.canvas.height;
	var space = this.xyz.space;
	var local_participant = space.local_participant;
	if(!local_participant)
		return;

	var in_focus = local_participant.focus_item;
	var flat_call = this.mode == BubblesManager.FLAT_MODE;
	var focused_participant = null;

	if( local_participant.focus_item )
	{
		var ent = space.local_participant.focus_item.getParentEntity();
		if( ent.seat && ent.seat.participant )
			focused_participant = ent.seat.participant;
	}

	for(var i = 0; i < participants.length; ++i)
	{
		var participant = participants[i];
		if(!participant._bubble)
			participant._bubble = [ w*0.5, -200, 100, 0 ];

		if(!participant.getProfileTexture())
			continue;

		var screenpos = participant.screen_position;

		var is_left_side = screenpos[0] < 0;
		var is_right_side = screenpos[0] > gl.canvas.width;
		var is_outside = is_left_side || is_right_side;
		var is_behind = screenpos[2] > 1; //behind the camera

		if(is_outside || is_behind)
		{
			if( screenpos[0] < w*0.5 )
				this.left_bin.push( participant );
			else
				this.right_bin.push( participant );
		}
		else
			this.center_bin.push(participant);
	}
}

BubblesManager.prototype.renderBin = function( bin )
{
	//TODO
}
*/

window.BubblesManager = BubblesManager;

export { BubblesManager };
