import clamp from "@src/math/clamp";

if (typeof(window) !== "undefined" && window.CanvasRenderingContext2D && !window.CanvasRenderingContext2D.prototype.hasOwnProperty("fillColor") )
{
	Object.defineProperty( window.CanvasRenderingContext2D.prototype, "fillColor", {
		set: function(v)
		{
			if (!this._fillColor)
				this._fillColor = new Float32Array([ 1,1,1,1 ]);
			this._fillColor.set(v);
			if (v.length === 3)
				this.fillStyle = "#" +  + inner(v[0]) +
                         inner(v[1]) +
                         inner(v[2]);
			else if (v.length === 4)
			{
				this.globalAlpha = v[3];
				this.fillStyle = "rgba(" + inner2(v[0]) + "," +
                         inner2(v[1]) + "," +
                         inner2(v[2]) + "," +
                         clamp(v[3],0,1).toFixed(3) + ")";
			}

			function inner(v)
			{
				v = clamp( v * 255,0,255)|0;
				return v.toString(16);
			}

			function inner2(v)
			{
				v = clamp( v * 255,0,255)|0;
				return v.toFixed(3);
			}
		},
		get: function()
		{
			this._fillColor[3] = this.globalAlpha;
			return this._fillColor;
		}
	});
}
