var WatchTexturePanel = {

	show_all: false,
	area: [ 300,300, 800,600 ],

	/**
	 * @param {RoomEditor} editor
	 * @param y
	 * @return {*}
	 */
	render: function( editor, y )
	{
		var texture = editor.selected_texture;
		if (!texture)
			return;

		var ctx = gl;

		var h = this.area[3] = gl.canvas.height - y - 50;
		var w = this.area[2] = h - 20;
		var x = this.area[0] = gl.canvas.width - 410 - w;

		if ( GUI.Panel( x,y,w,h, "Texture View", true ) === false )
		{
			editor.selected_texture = null;
			return;
		}

		ctx.font = "14px " + editor.launcher.options.fontFamily;
		ctx.fillText(  texture.width + "x" + texture.height, x + 170, y + 18 );
		ctx.fillText(texture.format === GL.RGBA ? "RGBA" : "RGB", x + 170, y + 36 );
		ctx.fillText(  texture.name || "", x + 260, y + 26 );

		var texsize = w - 40;
		gl.drawImage( texture, x + 20, y + 40, texsize, texsize );

		return y;
	}
};

export default WatchTexturePanel;
