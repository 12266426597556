import { vec3 } from 'gl-matrix';
import HUEToRGB from '@src/libs/LiteGL/Color/HUEToRGB';
const HSLToRGB = function (h, s, l, out) {
    var r, g, b;
    out = out || vec3.create();
    if (s == 0) {
        r = g = b = l; // achromatic
    }
    else {
        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = HUEToRGB(p, q, h + 1 / 3);
        g = HUEToRGB(p, q, h);
        b = HUEToRGB(p, q, h - 1 / 3);
    }
    out[0] = r;
    out[1] = g;
    out[2] = b;
    return out;
};
export default HSLToRGB;
