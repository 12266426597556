/**
 * Tells you the closest POT size (it rounds, so 257 will give 256, while 511 will give 512)
 * @method nearestPowerOfTwo
 * @param {number} v
 * @return {number}
 */
const nearestPowerOfTwo = function(v) {
	return Math.pow(2, Math.round(Math.log(v) / Math.log(2)));
};

export default nearestPowerOfTwo;
