import Button from "@src/libs/GLUI/Elements/Button";
import Label  from "@src/libs/GLUI/Elements/Label";

// Dialog displayed when users access their backlog from the editor
var LoginDialog = {

	area: [ -300,-300, 400,300 ],
	login_settings: null,

	render: function(editor)
	{
		var backend = xyz.backend;
		var ctx = gl;
		if (!this.login_settings)
		{
			this.login_settings = {
				username: "",
				password: ""
			};
			var info = localStorage.getItem("xyz_backend_user");
			if (info)
			{
				try
				{
					info = JSON.parse(info);
					this.login_settings.username = info.username;
				}
				catch (err)
				{
				}
			}
		}

		var settings = this.login_settings;

		var sidewidth = editor.sidewidth;
		var w = this.area[2];
		var h = this.area[3];
		var x = this.area[0] = gl.canvas.width * 0.5 - w*0.5;
		var y = this.area[1] = gl.canvas.height * 0.5 - h*0.5;

		if (GUI.Panel( x, y, w,h, this.username ? this.username + " profile" : "Login", true ) === false )
		{
			this.login_settings = null;
			return false;
		}

		if ( Button.call(GUI, 20 + x, y + h - 60,120,40, "Cancel" ) )
		{
			this.login_settings = null;
			return false;
		}

		if ( backend.username )
		{
			//this.showFiles( x + 20, y + 60, w - 40, h - 200 );
			if ( Button.call(GUI, x + w - 20 - 120, y + h - 60,120,40, "Logout", false, [ 0.5,0.3,0.3,1 ] ) )
			{
				backend.logout();
				this.login_settings = null;
				return false;
			}

			return;
		}

		GUI.pressed_enter = false;
		Label.call(GUI, x + 20, y + 60, 100, 30, "Username" );
		this.login_settings.username = GUI.TextField( x + 150, y + 60, 230, 30, this.login_settings.username,4,true,false );
		Label.call(GUI, x + 20, y + 100, 100, 30, "Password" );
		this.login_settings.password = GUI.TextField( x + 150, y + 100, 230, 30, this.login_settings.password,4,true,true );

		Label.call(GUI, x + 20, y + 140, 100, 30, "Remember me" );
		this.remember_me = GUI.Toggle( x + w - 50, y + 140, 30, 30, null, this.remember_me );

		if ( Button.call(GUI, x + w - 20 - 120, y + h - 60,120,40, "Login", false, [ 0.3,0.5,0.3,1 ] ) || GUI.pressed_enter)
		{
			backend.login( this.login_settings.username, this.login_settings.password );
			this.login_settings = null;
			return false;
		}
	}

};

export default LoginDialog;
