import { getFullPath } from "@src/engine/Room/file-utils";
import { RD } from "@src/libs/rendeer";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { quat } from "gl-matrix";

function Dice( id, type )
{
	this.id = id != null ? id : -1;
	this.type = type || 10;
	this.value = 0;

	this._node = new SceneNode();
	this._node.loadGLTF( getFullPath("props/dice_"+type+"_animated.glb") );
}

Dice.prototype.serialize = function()
{
	return {
		id: this.id,
		type: this.type,
		value: this.value
	};
}

Dice.prototype.roll = function(_view)
{
	this.value = Math.floor( Math.random() * this.type );
	if (this.type !== 10 ) //d10 starts at 0 ends at 9, the rest starts at 1
		this.value += 1;
	return this.value;
}

Dice.prototype.animateToValue = function( value )
{
	if (!this._node || !this._node.children.length) //still loading
		return;

	var anim = this._node.children[0].animations[0];
	if (!anim)
		return;

	console.debug(value);
	//this.value = value;

	var t = 0;

	if (this.type === 10)
		t = (anim.duration / 9 ) * value;
	else
		t = (anim.duration / (this.type - 1) ) * (value - 1);

	var track = anim.tracks[0];
	var sample = track.getSample(t, RD.LINEAR);
	var node = this._node.findNodeByName(track.target_node);
	var num = 5; //num rolls

	roll();

	function roll()
	{
		if ( --num )
			Tween.easeProperty( node, "rotation", quat.random( quat.create() ), 0.2+ Math.random()*0.2, num === 4 ? Tween.EASE_IN_QUAD : Tween.LINEAR, roll );
		else
			Tween.easeProperty( node, "rotation", sample, 1.2 + Math.random()*0.4,  Tween.EASE_OUT_BOUNCE );
	}
}

export default Dice;
