"use strict";

class e {
	constructor(t) {
		this.size = t, this.head = 0, this.tail = 0, this.count = 0, this.data = new Array(t)
	}

	resize(t) {
		const e = new Array(t);
		this.data = e, this.size = t, this.clear()
	}

	getHead() {
		return this.data[this.head - 1]
	}

	getFromHead(t) {
		let e = this.head - (t + 1);
		for (; e < 0;) e += this.size;
		return this.data[e]
	}

	clear() {
		this.head = 0, this.tail = 0, this.count = 0
	}

	push(t) {
		const e = this.head;
		this.data[e] = t;
		const r = this.size, n = (e + 1) % r;
		this.head = n, this.count === r ? this.tail = (this.tail + 1) % r : this.count++
	}

	shift() {
		if (0 === this.count) return;
		const t = this.data[this.tail];
		return this.count--, this.tail = (this.tail + 1) % this.size, t
	}

	removeElementByIndex(t) {
		if (t >= this.count) return;
		const e = this.size, r = this.tail, n = this.count;
		for (let i = t; i < n; i++) {
			const t = r + i, n = t % e, o = (t + 1) % e;
			this.data[n] = this.data[o]
		}
		this.head--, this.count--
	}

	removeIf(t, e) {
		const r = this.count, n = this.size, i = this.tail, o = this.data;
		for (let s = 0; s < r; s++) {
			const r = (i + s) % n, a = o[r];
			if (t.call(e, a)) return this.removeElementByIndex(r), a
		}
	}

	forEach(t, e) {
		const r = this.count, n = this.size, i = this.tail, o = this.data;
		for (let s = 0; s < r; s++) {
			const r = o[(i + s) % n];
			t.call(e, r)
		}
	}

	contains(t) {
		return -1 !== this.data.indexOf(t)
	}
}

function r(t, e) {
	return t - e
}

class n {
	constructor(t = 100) {
		this.__data = new e(t)
	}

	resize(t) {
		this.__data.resize(t)
	}

	getLastRecord() {
		return this.__data.getHead()
	}

	record(t) {
		this.__data.push(t)
	}

	computeStats(t) {
		const e = this.__data, n = e.data, i = e.count;
		return 0 === i ? (t.mean = 0, t.meadian = 0, t.min = 0, t.max = 0, !1) : (t.mean = function (t, e = 0, r = t.length) {
			let n = 0;
			const i = r - e;
			for (let e = 0; e < r; e++) n += t[e];
			return n / i
		}(n, 0, i), t.meadian = function (t, e, n) {
			const i = t.slice();
			return i.sort(r), i[0 + (n - 0) / 2 | 0]
		}(n, 0, i), t.max = function (t, e = 0, r = t.length) {
			let n = Number.NEGATIVE_INFINITY;
			for (let i = e; i < r; i++) {
				const e = t[i];
				e > n && (n = e)
			}
			return n
		}(n, 0, i), t.min = function (t, e = 0, r = t.length) {
			let n = Number.POSITIVE_INFINITY;
			for (let i = e; i < r; i++) {
				const e = t[i];
				e < n && (n = e)
			}
			return n
		}(n, 0, i), !0)
	}

	clear() {
		this.__data.clear()
	}
}

const i = new class {
	constructor() {
		this.mean = 0, this.meadian = 0, this.max = 0, this.min = 0
	}
}, o = .001;

function s(t) {
	return 1e3, (e = 2 * t) < 10 ? 10 : e > 1e3 ? 1e3 : e;
	var e
}

function a(t, e) {
	return e.priority - t.priority
}

export const AJS = {
	VERSION: "1.0.0",
	"@description": "Adaptive Job System, written for TMRW"
}

export class IdleTimePredictor {
	budget_correction = o;
	__budget_correction_target_fps = 60;
	perf_frame_time = new n(s(this.__budget_correction_target_fps));
	__last_update_time = 0;

	set budget_correction_target_fps(t) {
		this.__budget_correction_target_fps = t, this.perf_frame_time.resize(s(t))
	}

	get budget_correction_target_fps() {
		return this.__budget_correction_target_fps
	}

	constructor(t) {
		this.budget_correction_target_fps = t
	}

	get historical_average_frame_time() {
		return this.perf_frame_time.computeStats(i), i.mean
	}

	estimate() {
		const t = this.historical_average_frame_time, e = 1e3 / this.__budget_correction_target_fps - t;
		return Math.max(0, e + this.budget_correction)
	}

	update(t) {
		const e = performance.now();
		void 0 === t && (t = e - this.__last_update_time);
		const r = this.perf_frame_time;
		r.record(t), r.computeStats(i);
		const n = i.mean, s = this.__budget_correction_target_fps, a = 1e3 / s, c = a - n;
		c > 0 ? this.budget_correction < o ? this.budget_correction = o : this.budget_correction = this.budget_correction + Math.max(.5, a / n) / s : c < 0 && (this.budget_correction = this.budget_correction - .5 / s), this.__last_update_time = e
	}
}

export class Process {
	last_update_time = 0;
	perf_step_execution_time = new n(16);

	get priority() {
		return 0
	}

	step(t) {
		return 2
	}
}

export class ProcessScheduler {
	processes = [];
	remaining_budget = 0;
	remembered_execution_times = new WeakMap;

	add(t) {
		return this.processes.push(t), !0
	}

	remove(t) {
		const e = this.processes.indexOf(t);
		return -1 !== e && (this.processes.splice(e, 1), !0)
	}

	prod() {
		this.processes.sort(a);
		const t = performance.now();
		let e = t;
		const r = this.processes.length;
		for (let n = 0; n < r; n++) {
			const r = this.processes[n], i = e - t;
			if (i >= this.remaining_budget) break;
			const o = this.remembered_execution_times.get(r);
			if (void 0 !== o && o + i > this.remaining_budget && n >= 2) {
				this.remembered_execution_times.set(r, .9 * o);
				continue
			}
			r.step();
			const s = performance.now();
			r.perf_step_execution_time.record(s - r.last_update_time);
			const a = s - e;
			this.remembered_execution_times.set(r, a), e = s, r.last_update_time = e
		}
		const n = Math.max(0, e - t);
		return this.remaining_budget -= n, n
	}
}