import RoomMediaStream from "@src/engine/helpers/mediaStream";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";

function PerformancePanel( controller )
{
	this.controller = controller;
	this.area = [ 0,0,300,500 ];

	this.show_cutout = false;
	this.show_source = false;
}

PerformancePanel.prototype.render = function()
{
	var w = this.area[2];
	var h = this.area[3];
	var x = this.area[0] = 10;
	var y = this.area[1] = gl.canvas.height - 80 - this.area[3];

	if ( GUI.Panel( x, y, w, h, "Performance Test", true ) === false )
		return false;

	var ROOM = window.ROOM || xyz.ROOM;
	var space = xyz.space;

	y += 50
	this.controller.enable_render_scene = GUI.Toggle( x + 10, y, w - 20, 24, "Render Scene", this.controller.enable_render_scene );
	y+= 30;
	RoomMediaStream.block_upload_to_gpu = GUI.Toggle( x + 10, y, w - 20, 24, "Block Streams", RoomMediaStream.block_upload_to_gpu );
	y += 30
	ROOM.Components.Surface.block_apps = GUI.Toggle( x + 10, y, w - 20, 24, "Block Surfaces", ROOM.Components.Surface.block_apps );
	y+= 30;
	Label.call(GUI, x + 10, y, w - 20, 24, "Feed Rate" );
	ROOM_SETTINGS.feeds.upload_feed_rate = Math.round( GUI.Slider( x + 100, y, w - 120, 24,ROOM_SETTINGS.feeds.upload_feed_rate, 0,60 ) );
	y += 30;
	ROOM_SETTINGS.call.DOF.allow = GUI.Toggle( x + 10, y, w - 20, 24, "Allow DOF", ROOM_SETTINGS.call.DOF.allow );
	y+= 30;

	if (window.cutout)
	{
		window.cutout.roiDetection = GUI.Toggle( x + 10, y, w - 20, 24, "ROI", window.cutout.roiDetection );
		y+= 30;
		window.cutout.segmentation = GUI.Toggle( x + 10, y, w - 20, 24, "Segmentation", window.cutout.segmentation );
		y+= 30;
	}

	this.show_source = GUI.Toggle( x + 10, y, w - 20, 24, "Show Cutouts Source", this.show_source );
	y+= 30;

	this.show_cutout = GUI.Toggle( x + 10, y, w - 20, 24, "Show Cutouts Tex", this.show_cutout );
	y+= 30;

	var offsetx = 200;
	for (var i = 0; i < space.participants.length; ++i)
	{
		var participant = space.participants[i];
		if (!participant.feed_info || !participant.feed_info.video)
			continue;

		var video = participant.feed_info.video;
		var video_width = video.videoWidth || video.width;
		var video_height = video.videoHeight || video.height;
		var offsetw = video_width;

		if ( this.show_source )
		{
			if ( !video.parentNode )
			{
				video.style.position = "fixed";
				video.style.top = "10px";
				video.style.pointerEvents = "none";
				document.body.appendChild( video );
			}
			video.style.display = "";
			video.style.left = offsetx + "px";
			video.style.width = video_width + "px";
			video.style.height = video_height + "px";
			video.style.zIndex = 1000;
		}
		else
			video.style.display = "none";

		if ( this.show_cutout )
		{
			var tex = participant.feed_info.texture;
			if (tex)
			{
				gl.drawImage( tex, offsetx, Math.floor(gl.canvas.height / 2), tex.width, tex.height );
				offsetw = Math.max( offsetw, tex.width );
			}
		}

		offsetx += offsetw + 5;
	}


	if (this.show_source)
	{
		if ( this.source_elem )
			this.source_elem.style.display = "";
		else if ( !this.source_elem && space.local_participant.feed_info && space.local_participant.feed_info.video )
		{
			this.source_elem = space.local_participant.feed_info.video;
			this.source_elem.style.position = "fixed";
			this.source_elem.style.right = "10px";
			this.source_elem.style.top = "10px";
			this.source_elem.style.pointerEvents = "none";
			document.body.appendChild( this.source_elem );
		}
	}
	else if ( this.source_elem )
	{
		this.source_elem.style.display = "none";
	}

	if (window.ma)
	{
		Label.call(GUI, x + 10, y, w - 20, 24, "Cutout TOP" );
		window.ma.top = GUI.Slider( x + 100, y, w - 120, 24, window.ma.top, 0,1 );
		y += 30;
		Label.call(GUI, x + 10, y, w - 20, 24, "Cutout LOW" );
		window.ma.low = GUI.Slider( x + 100, y, w - 120, 24, window.ma.low, 0,1 );
		y += 30;
		window.ma.filter.enabled = GUI.Toggle( x + 10, y, w - 20, 24, "Filter", window.ma.filter.enabled );
		y+= 30;

		if(window.ma.canvas)
		{
			if ( Button.call(GUI, x + 10, y, w - 20, 24, "Toggle Canvas") )
			{
				window.ma.canvas.style.position = "fixed";
				window.ma.canvas.style.top = 0;
				window.ma.canvas.style.right = 0;
				if( !window.ma.canvas.parentNode )
					document.body.appendChild( window.ma.canvas );
				else
					document.body.removeChild( window.ma.canvas );
			}
			y += 30;
		}
	}

	/*
	window.ma = {
		top: 0.7,
		low: 0.3,
		filter: {
			enabled: true
		},
		canvas: null
	}
	*/

	return true;
};

export default PerformancePanel;
