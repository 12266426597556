import loadScripts from "load-scripts";

import FeedManager from "@src/controllers/components/feedManager";
import { RoomBroadcast } from "@src/controllers/broadcast";
import Surface from "@src/engine/components/surface";
import { RoomAvatar } from "@src/engine/helpers/avatar";
import { RoomParticipant } from "@src/engine/Participant/RoomParticipant";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import RoomCropModes from "@src/engine/Room/RoomCropModes";
import RootPath from "@src/engine/Room/RootPath";
import { IdleTimePredictor, ProcessScheduler } from "@src/libs/adaptiveJobSystem";
import { XYZModules } from "@src/xyz/XYZModules";
import { XYZRegisterModule } from "@src/xyz/XYZRegisterModule";
import isMobile from "@src/XYZLauncher/isMobile";

import ROOM from "./engine/room.external";
import enableWebGLCanvas from "./libs/Canvas2DtoWebGL";
import { EnumRenderQuality } from "./XYZLauncher/EnumRenderQuality";

window.xyz = null;

const MINIMUM_TARGET_FPS = 60;

//This is the class instantiated from WebRoom. It is the launcher to all the render engine and the room layer.
//It also works as a manager to handle different space controllers.
class XYZLauncher {

	/**
	 * XYZLauncher soft singleton
	 * @type {XYZLauncher}
	 * @static
	 */
	static instance = null;

	static isMobile = isMobile;

	fixed_update_ms = 10; //100 times per second
	accumulated_fixed_update = 0;
	webgl = 2;

	/**
	 * A controller for broadcasting
	 * @type {RoomBroadcast | null}
	 */
	broadcast_controller = null;

	/**
	 * A controller created at room creating.
	 * @type {RoomController | null}
	 */
	call_controller = null

	/**
	 * A currently used controller
	 * @type {RoomController|null}
	 */
	active_controller = null;

	/**
	 * @type {RoomController|null}
	 */
	previous_controller = null;

	/**
	 * @type {RoomSpace}
	 */
	space = null;

	/**
	 * RoomSpace, legacy
	 * @type {RoomSpace | null}
	 * @deprecated Use {@link XYZLauncher.space} instead
	 */
	room = null;

	/**
	 * ProcessScheduler of the Adaptive Job System
	 * @type {ProcessScheduler | null}
	 */
	process_scheduler = null;

	/**
	 * IdleTimePredictor of the Adaptive Job System
	 * @type {IdleTimePredictor | null}
	 */
	#performance_predictor = null;

	loading_fade = 0;
	ready = false;
	allowClick = true;
	allowMove = true;
	allowKeyBoard = true;
	allowSeatClick = true;
	render_debug_ui = false;
	last_dt = 0;

	is_in_broadcast_mode = false;

	bridge = null; //to communicate with the upper layers (WebRTC, UX, etc)
	plugins = [];
	modules = {};

	/**
	 * @type {ViewCore}
	 */
	view = null;
	/**
	 *
	 * @type {FeedManager | null}
	 */
	feed_manager = null;

	/**
	 * Backward compatibility related flag
	 * @type {boolean}
	 */
	#legacyInitializationMode = false;

	/**
	 * if the engine initialization hsa been called
	 * @type {boolean}
	 */
	#initializationTriggered = false;

	#startupTriggered = false;

	_native_engine_fx = {}; //used to cache native engine values

	//Globalfeed variables used to define the user who is screensharing.
	globalFeedOwner = undefined;
	globalFeedOwnerId = "";
	globalFeedTexture;
	globalFeed = null;

	/**
	 * @param {HTMLDivElement} container
	 * @param {Partial<XYZLauncherOptions>} options
	 */
	constructor(container, options) {
		if (!container) {
			throw "no container provided for XYZLauncher";
		}

		if (XYZLauncher.instance) {
			throw new Error("Attempt to create another instance of XYZLauncher. Please use the previously created one")
		}

		this.container = container;

		// we either use the options OR providing fontFamily to display the debug message
		options = options || { fontFamily: "Arial" };
		this.options = options;

		xyz = this;
		XYZLauncher.instance = this;

		console.debug("XYZ using ROOM " + ROOM.version_str);
		this.ROOM = ROOM; //hack to have access to ROOM

		this.force_webgl = options.webgl;
		this.mobile = XYZLauncher.isMobile.any();
		if (this.force_webgl !== undefined) {
			console.debug("Forcing WebGL context: " + this.force_webgl);
		}

		if (options.native != null) {
			this.native_mode = options.native !== "false" && options.native !== false;
		} else {
			this.native_mode = true;
		}

		this.native_debug = options.native_debug === "true" || options.native_debug === true;
		this.native_st = options.native_st === "true" || options.native_st === true;
		this.native_profile = options.native_profile ? options.native_profile : 0.0;
		this.native_streaming = options.native_streaming != undefined ? options.native_streaming : 1;
		this.nativeFolderPath = options.nativeFolderPath ? options.nativeFolderPath : "";

		// Disable native calls since WASM module is not instantiated
		if (this.native_mode) {
			console.debug("XYZLauncher is using Native Engine...");
			console.debug("Native sources path: " + this.nativeFolderPath);
		} else {
			console.debug("XYZLauncher is using JS Engine...");
		}

		//first this
		RootPath.set(options.root_path || "data");

		// Instantiate Adaptive Job System
		this.process_scheduler = new ProcessScheduler();
		this.#performance_predictor = new IdleTimePredictor(MINIMUM_TARGET_FPS);

		const params = {};
		this.params = params;
		ROOM.params = params;

		const urlParams = new URLSearchParams(window.location.search);
		urlParams.forEach(function (value, key) {
			params[key] = value;
		});

		//global stuff
		if (params.feed_rate) {
			ROOM.options.feeds.upload_feed_rate = Number(params.feed_rate);
		}
		if (params.surfaces === "false") {
			Surface.block_apps = true;
		}
		if (params.log === "true") {
			ROOM.verbose = true;
			window.log_native = true;
		}

		//load images
		ROOM.icons_img = ROOM.getImage("textures/icons.png");
	}

	/**
	 * object's lifecycle: initialize
	 * call it to initialize the engine
	 * @returns {Promise<void>} resolved when when initialization process is finished
	 */
	async initialize() {
		if (this.#initializationTriggered) {
			return;
		}
		this.#initializationTriggered = true;

		if (!this.container)
			this.container = document.body;

		return this.init(this.container, this.options);
	}

	/**
	 * object's lifecycle: startup
	 * call it when everything else is ready to begin rendering a scene
	 */
	startup(){
		if (!this.ready) {
			throw new Error("The engine and the room aren't ready; you need to call XYZLauncher.initialize and XYZLauncher.loadRoom before calling startup")
		}

		if(this.#startupTriggered)
		{
			console.error("XYZLauncher already started");
			return;
		}

		this.#startupTriggered = true;

		this.view.startup();

		if (this.active_controller === null) {
			this.setController(this.call_controller);
		}
	}

	/**
	 * object's lifecycle: shutdown
	 * call it to halt the engine's job
	 * the engine remains in a "hot" state for potential reuse
	 */
	shutdown() {
		// stop rendering
		this.view.shutdown();

		// change the value only after successful view.shutdown call
		this.#startupTriggered = false;

		// draw it in black,
		const gl = GL.ctx;
		gl.clearColor(0.0, 0.0, 0.0, 1.0);
		gl.clear(gl.COLOR_BUFFER_BIT);
	}

	/**
	 * object's lifecycle: dispose
	 * call it completely unload the engine and free up resources.
	 * the reverse operation of initialization
	 * @todo Not implemented yet
	 */
	dispose() {
		console.warn("Engine dispose: not implemented yet");
	}

	async init(container, options) {
		// for HTML elements
		this.container.style.setProperty("--room-font", options.fontFamily);

		if (options.notifyUser) {
			this.notifyUser = options.notifyUser;
		}

		//init render engine
		if (options.shaders_file) {
			ViewCore.shaders_file = options.shaders_file;
		}

		if (this.native_mode) {
			await this.initNative(container, options);
		} else {
			this.webgl = this.force_webgl === 1 ? 1 : 2;
			this.initSpace(container, options);
		}

		//the feed manager will be in charge of all feeds
		ROOM.feed_manager = this.feed_manager = new FeedManager(this);
		this.feed_manager.bindEvents();

		//load last session info
		this.loadSessionInfo();

		//load modules
		if (!this.native_mode) this.initModules();
	}

	async initNative(container, options) {
		this.native_init_time = getTime();

		await loadScripts(`${this.nativeFolderPath}NativeEngine.js`);
		// order is important, nativeRenderer relies on NativeEngine
		await loadScripts(`${this.nativeFolderPath}nativeRender.js`);

		if (!TmrwModule) {
			throw new Error("NativeEngine not found");
		}
		TmrwModule._rootPath = this.nativeFolderPath;

		this.needCanvasInit = true;

		this._loadProgress = 0.0;
		this._loadProgressType = 0;

		// We use pre-created canvas for 3D scene
		//this.canvas = ModuleEngine.canvas;
		this.canvas = TmrwModule.canvas;

		this.canvas.width = window.innerWidth;
		this.canvas.height = window.innerHeight;
		this.canvas.style.opacity = 1;
		container.appendChild(this.canvas);

		this.nativeEngine = new NativeEngine(
			this.canvas,
			false,
			this.native_st,
			this.native_debug
		);
		window.nativeEngine = this.nativeEngine;

		// it's mandatory to receive the callback, otherwise the app will crash
		await new Promise(resolve => {
			this.nativeEngine.addOnLoadedCallback(() => {
				// proceed the initialization
				this.emInitCanvas();

				// in case if the room was loaded before the engine
				this.delayedLoadRoom();
				resolve();
			});
		})


		if (!window.nativeEngine.isInitialized()) {
			window.nativeEngine = null;
			this.webgl = 1;
			this.native_mode = false;
			console.warn(
				"Cannot launch native! NativeEngine was failed to initialize. Fallback to Web engine!"
			);
		}

		this.enableDrop(); //to drop files (DEBUG)

		//used to bypass cors in fetch operations
		if (options.credentials) ROOM.credentials = options.credentials;

		//debug
		if (options.debug === "nofeeds" || options.debug === "norender")
			ROOM_FLAGS.allow_feeds = false;

		this.GUI = GUI;
		window.addEventListener("beforeunload", this.onBeforeUnload.bind(this));
		window.addEventListener("paste", this.onPaste.bind(this));
		/* code to prevent emscripten compiled code from eating key input */
	}

	/**
	 * Initialize RoomSpace
	 * @param {HTMLElement} container
	 * @param options
	 */
	initSpace(container, options) {
		this.view = new ViewCore(container || document.body, this);
		ROOM.view = this.view;

		enableWebGLCanvas(this.view.canvas, { version: this.webgl, no_flip: false }); //enables Canvas2D functions
		this.enableDrop(this.view.canvas); //to drop files (DEBUG)
		this.view.root_path = RootPath.get();

		if (options.quality === EnumRenderQuality.LOW)
			this.view.render_quality = ROOM.QUALITY.LOW;
		else if (options.quality === EnumRenderQuality.MEDIUM)
			this.view.render_quality = ROOM.QUALITY.MEDIUM;
		else if (options.quality === EnumRenderQuality.HIGH)
			this.view.render_quality = ROOM.QUALITY.HIGH;

		if (options.resize) this.view.resize_mode = options.resize;

		this.view.onMouse = this.onMouse.bind(this);
		this.view.onRender = this.onRender.bind(this);
		this.view.onNodeClicked = this.onNodeClicked.bind(this);
		this.view.onUpdate = this.onUpdate.bind(this);
		this.view.onContextLost = this.onContextLost.bind(this);

		//create ROOM
		this.space = new RoomSpace(this.view);
		this.space.root_path = RootPath.get();
		this.space.debug = options.debug;
		this.room = this.space; //LEGACY

		//used to bypass cors in fetch operations
		if (options.credentials) ROOM.credentials = options.credentials;

		//debug
		if (options.debug === "nofeeds" || options.debug === "norender")
			ROOM_FLAGS.allow_feeds = false;

		//load global config (to avoid hardcoding it here)
		//this.view.loadConfig(ROOM.getFullPath("config.json")); //nocache included inside
		if (options.allow_external_scripts)
			this.space.allow_external_scripts = true;

		//assign
		this.view.setSpace(this.space);
		//GUI.icons = ROOM.getFullPath("textures/icons.png");
		this.GUI = GUI;
		//GUI.text_input_mode = "html";
		GUI.icons = "icons.png";
		this.view.loadTexture("/textures/icons.png", { name: "icons.png" });
		GUI.init(GL.ctx);

		//interaction controllers
		this.call_controller = new RoomCall(this, this.space, this.view, options);
		this.active_controller = null;
		if (this.#legacyInitializationMode) {
			this.setController(this.call_controller);
		}

		window.addEventListener("beforeunload", this.onBeforeUnload.bind(this));
		window.addEventListener("paste", this.onPaste.bind(this));

		ROOM.waitForWebfonts([ this.options.fontFamily ], function () {
			ROOM.webfonts_loaded = true;
		});

		// update the engine init time; just for logging
		this.native_init_time = getTime() - this.native_init_time;
	}

	/**
	 Need to initialize JS OpenGL context after Emscripten
	 Called right after the NativeEngine is initialized
	*/
	emInitCanvas() {
		if (!this.needCanvasInit) return;
		this.needCanvasInit = false;

		let pathName = window.location.pathname;

		// to prevent drawing the scene
		this.active_controller = null;

		if (this.options.data_domain) {
			pathName = "";
		}

		const isMobileAny = isMobile.any();
		const isMobileIos = isMobile.iOS();
		let browserQuality = 3;
		if (isMobileAny) {
			browserQuality |= 0x80;
		}
		if (isMobileIos) {
			browserQuality |= 0x40;
		}

		//ModuleEngine.globalConfig(this.options.data_domain ? this.options.data_domain : window.location.origin, pathName, this.options.root_path, 3);
		TmrwModule.globalConfig(
			this.options.data_domain
				? this.options.data_domain
				: window.location.origin,
			pathName,
			this.options.root_path,
			browserQuality
		);

		nativeEngine.setMobile(isMobileAny);
		nativeEngine.setIOS(isMobileIos);
		const ctx = enableWebGLCanvas(this.canvas, {
			version: this.webgl,
			alpha: false,
		}); //enables Canvas2D functions
		if (ctx != null) {
			console.debug("OpenGL initialized for canvas: " + this.canvas.id);
		} else {
			console.error("OpenGL failed initialization for canvas: " + this.canvas.id);
		}

		// creates a RoomSpace for us
		this.initSpace(this.container, this.options);

		//debuging purposes
		if (this.options.debug) {
			ViewCore.user_clicked = false;
			this.setBridge(new CoreBridgeFake(XYZLauncher.instance)); //this is only in dev environment!!!

			Promise.all([ this.enableEditor() ]).then(() => {
				this.call_controller.enable_debug_gui = true;
				this.call_controller.enable_version = true;
			})
		}

		this.broadcast_controller = new RoomBroadcast(this, this.space, this.view, this.options)

		this.initModules();
		this.ready_time = getTime() * 0.001;
	}

	initModules() {
		if(XYZLauncher.modules_initialized)
		{
			console.error("Modules already initialized");
			return;
		}
		var options = this.options;

		for (const i in XYZModules) {
			const module = new XYZModules[i](this, options);
			this.modules[i] = module;
		}

		if (options.bridge) {
			this.setBridge(options.bridge);
			this.bindEvents();
		}

		XYZLauncher.modules_initialized = true;
		this.ready_time = getTime() * 0.001;
		this.html_renderer = new HTMLRenderer(this.container, this.view.canvas);
	}

	setSpace(space) {
		this.space = space;
		this.active_controller.space = space;
		this.view.setSpace(space);
		ROOM.space = space;
	}

	/**
	 *
	 * @param {RoomController} controller
	 * @returns {boolean} returns false if controller is already set and true otherwise
	 */
	setController(controller) {
		if (controller === undefined) {
			throw new Error("Trying to set controller to undefined value");
		}

		if (!controller.isRoomController) {
			throw new Error("expected to get a RoomController instance, instead got something else")
		}

		if (this.active_controller === controller) {
			// no change
			return false;
		}

		if (this.active_controller !== null) {
			this.active_controller.onLeave(controller);
		}

		this.previous_controller = this.active_controller;

		this.active_controller = controller;
		this.active_controller.enter_time = getTime() * 0.001;

		this.active_controller.onEnter(this.previous_controller);

		this.is_in_broadcast_mode = this.active_controller === RoomBroadcast.instance;

		// TODO notify of controller change

		return true;
	}

	setControllerToPrevious() {
		this.setController(this.previous_controller);
	}

	setBroadcastMode(isEnabled) {
		if (isEnabled !== (this.active_controller === this.broadcast_controller))
			this.setController(isEnabled ? this.broadcast_controller : this.call_controller);
		this.is_in_broadcast_mode = isEnabled;
	}

	//updates the ROOM_SETTINGS using an object that doesn't need to contain all
	updateSettings(o) {
		//update only the ones changed
		inner_update(ROOM_SETTINGS, o);

		function inner_update(root, in_root) {
			for (const i in root) {
				const value = root[i];
				if (value && value.constructor === Object && in_root[i]) {
					inner_update(value, in_root[i]);
				} else {
					if (in_root[i] !== undefined) root[i] = in_root[i];
				}
			}
		}
	}

	throwError( msg )
	{
		console.error("ERROR IN XYZ: " + msg);
		if (this.on_error)
			this.on_error(msg);
	}

	bindEvents() {
		const that = this;
		LEvent.bind(this.view, "quality_reduced", this.onEvent.bind(this));
	}

	//events from the TCS
	onEvent(e) {
		const bridge = this.bridge;
		if (!bridge) return;

		if (e === "quality_reduced") {
			this.notify(bridge.EVENT_ALERT_OUT, {
				type: "PERFORMANCE_REDUCED",
				msg: "Framerate too low. Visual quality has been reduced to improve the experience.",
			});
		}
	}

	setBridge(bridge) {
		const that = this;
		if (!this.space) throw "cannot set bridge without space";
		this.space.bridge = bridge;
		this.bridge = bridge;
		for (const i in this.modules) {
			const m = this.modules[i];
			if (m.onBridgeAvailable) m.onBridgeAvailable(bridge);
		}

		if (this.active_controller && this.active_controller.onBridgeAvailable)
			this.active_controller.onBridgeAvailable(bridge);

		//retrigger messages
		bridge.subscribe("EVENT_BCAST_IN", function (data) {
			if (data.constructor === String && data[0] === "{") data = JSON.parse(data);
			LEvent.trigger(that.space, "BROADCAST", data);
		});
		bridge.subscribe("EVENT_P2P_IN", function (data) {
			LEvent.trigger(that.space, "P2P", data);
		});

		bridge.subscribe("EVENT_PING_UPDATE", function (data) {
			that.space.ping = data.pingDelay;
		});

		bridge.subscribe("NLP_EVENT", function (data) {
			terminal.log("NLP: " + String(data.event));
		});

		bridge.subscribe("USER_ACTION", function (data) {
			LEvent.trigger(that.space, "REMOTE_ACTION", data);
		});
	}

	/**
	 *
	 * @param {RoomParticipant} participant
	 * @return {RoomAvatar}
	 */
	createAvatar(participant) {
		return new RoomAvatar(participant);
	}

	// Participants ***************************************
	createParticipant(id, user_info) {
		return new RoomParticipant(id, user_info, this);
	}

	addParticipant(participant) {
		this.space.addParticipant(participant);
	}

	removeParticipant(participant) {
		this.space.removeParticipant(participant);
	}

	getLocalParticipant() {
		return this.space.local_participant;
	}

	getParticipant(id) {
		return this.space.getParticipant(id);
	}

	getEntity(id) {
		return this.space.getEntity(id);
	}

	setDefaultSurfaceFeed(feed, feed_options) {
		if (!ROOM.feed_manager) return;
		ROOM.feed_manager.setDefaultSurfaceFeed(feed, feed_options);
	}

	async assignGlobalFeed(feed, feed_options) {
		if (!ROOM.feed_manager) return;
		ROOM.feed_manager.assignSurfaceFeed(feed, feed_options);
		if (feed_options) {
				this.globalFeedOwner = feed_options.name;
				this.globalFeedOwnerId = feed_options.uid;
				this.globalFeed = feed;
			} else {
				this.globalFeedOwner = undefined;
				this.globalFeedOwnerId = "";
			}
	}

	assignFeed(participant, feed, feed_options) {
		if (!ROOM.feed_manager) return;
		return ROOM.feed_manager.assignFeed(participant, feed, feed_options);
	}

	setChromaToAll(v) {
		if (!ROOM.feed_manager) return;
		ROOM.feed_manager.use_chroma = v;
	}

	onExit() {
		//remove feed to avoid ugly stuff
		this.space.local_participant.assignFeed(null);

		//clear whole scene
		this.space.clear();

		//clear engine
		this.view.renderer.destroy();
	}

	/**
	 * Triggers the room loading process.
	 * @param {string} url url to the room resource
	 * @param {function} [callback] (deprecated - Use the returned promise instead) callback to be called after the room is loaded;
	 * @returns {Promise<space>}
	 */
	async loadRoom(url, callback) {
		if (callback) {
			console.error("You use a callback for XYZLauncher.loadRoom which is deprecated. Use the returned promise instead")
		}

		if (!this.#initializationTriggered) {
			console.debug("Switch to legacy initialization mode. Use Lifecycle to avoid this, more info: LIFECYCLE_REFERENCE.md")
			this.#legacyInitializationMode = true;	// temporary, for backward compatibility
			await this.initialize();
		}

		// make sure we don't have the previous room loaded
		this.unloadRoom();

		this.allowMove = false;
		this.allowKeyBoard = false
		this.ready = false;

		if (this.native_mode) {
			this.allowMove = true; //we do not block in native
			this.allowKeyBoard = true
			console.debug("Request to load room: " + url);

			// We have to postpone room loading because wasm module is not initialized yet
			// In order to postpone it we assign the url to this.room_url
			// and the delayedLoadRoom() will handle loading for us later (most probably be called from XYZLauncher.onUpdate()
			this.room_url = url;

			// temporary wrap around the deprecated callback
			const that = this;
			const resultPromise = new Promise((resolve => {
				that.room_callback = space => {
					if (callback) callback(space);
					resolve(space);
				}
			}));

			//attempt to load the scene, most probably it won't do anything tho
			this.delayedLoadRoom();

			return resultPromise;
		} else {
			console.debug("loading room...");
			const that = this;
			this.space.load(
				url,
				function () {
					that.allowMove = true;
					that.allowKeyBoard = true
					that.onRoomReady();
					if (callback) callback(that.space);
				},
				function (error) {
					console.error("Problem loading a room:", error);
				}
			);
		}
	}

	// unloads the current room
	unloadRoom() {
		if (!this.ready) {
			return;	// do nothing if there is no room loaded
		}
		this.ready = false;

		this.shutdown();

		this.nativeEngine.onRelease();

		this.room_callback = null;
		this.room_url = null;

		this.call_controller?.shutdown()

		this.space.shutdown();

		// remove the controllers were used
		this.active_controller = null;
		this.previous_controller = null;
	}

	// ROOM LOADING ************************************************
	loadRoomFromJSON(json, callback) {
		const that = this;
		this.space.fromJSON(json, function () {
			that.onRoomReady();
			that.space.ready = true;
			LEvent.trigger(that.space, "start");
			if (callback) callback();
		});
	}

	//called when the room.json is loaded, but still the prefabs are being loaded
	onRoomReady() {
		// inform the basic controller
		this.call_controller?.onRoomReady?.(this.space);

		// inform the active controller in case it exists
		if (this.active_controller && this.active_controller !== this.call_controller) {
			this.active_controller.onRoomReady?.(this.space);
		}

		if (this.bridge) {
			this.bridge.notify("ROOM_LOADING_JSON_ENDED");
		}

		// the room loading \ initialization is done
		this.ready = true;
	}

	//called from IntroLoader after loading assets (GLBs) (streaming textures will arrive later)
	onRoomAssetsLoaded() {
		this.ready = true;
		this.space.ready = true;

		// play video on surfaces on mobile instead of black screen while there is no screen sharing
		if (this.mobile) {
			const videoEl = document.createElement("video");
			videoEl.loop = true;
			videoEl.muted = true;
			videoEl.autoplay = true;
			videoEl.setAttribute("playsinline", "playsinline");
			videoEl.setAttribute("webkit-playsinline", "webkit-playsinline");

			videoEl.replaceChildren(...[
				{ name: "surface_screensaver_hevc.mp4", type: "video/mp4;codecs=\"hvc1\"" },
				{ name: "surface_screensaver_vp8.webm", type: "video/webm;codecs=\"vp8\"" },
			].map(({ name, type }) => {
				const source = document.createElement("source");
				source.src = `${RootPath.get()}/videos/${name}`;
				source.type = type;
				return source;
			}));

			videoEl.load();
			videoEl.addEventListener("canplaythrough", () => videoEl.play());

			this.setDefaultSurfaceFeed(videoEl);
		}

		LEvent.trigger(this.space, "start");
		if (this.bridge) this.bridge.notify("ROOM_LOADING_ENDED");
	}

	//RENDER ******************************
	onRender() {
		const gl = GL.ctx;

		if (gl.context_lost) return;

		GUI.resetGUI();
		GUI.setMouse(gl.mouse);

		if (this.options.debug === "norender") {
			//show warning
			gl.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
			gl.start2D();
			gl.font = "20px " + this.options.fontFamily;
			gl.fillStyle = "white";
			gl.fillText("DEBUG TEST: " + String(getTime()), 100, 100);
			return;
		}

		this.#performance_predictor.update();
		/*
	   we make sure to give at least some budget to the scheduler every frame
	   to ensure that at least some work is done even if we're below desired FPS threshold.
	   This is a compromise between updating video feeds and maintaining high FPS
	   */
		this.process_scheduler.remaining_budget = Math.max(
			3,
			this.#performance_predictor.estimate()
		);
		this.process_scheduler.prod(); // kick-off adaptive workload

		if (this.active_controller) {
			if (this.active_controller.onRender) this.active_controller.onRender();
			else if (
				this.active_controller.proxy_controller &&
				this.active_controller.proxy_controller.onRender
			)
				this.active_controller.proxy_controller.onRender();
		}

		//renders the loading screen
		if (this.onRenderLoader) this.onRenderLoader(this.last_dt);

		if (this.render_debug_ui) GUI.drawBlockAreas();
		GUI.finish();

		//this.view.canvas.style.cursor = GUI.cursor || ROOM.cursor_style;
		this.view.canvas.style.cursor = ROOM.cursor_style;
		//console.log(this.view.canvas.style.cursor)
		//console.debug("Cursor:", this.view.canvas.style.cursor);

		LEvent.trigger(this, "postRender", this.view);
	}

	//called from litegl main loop
	onUpdate(dt) {
		this.last_dt = dt;

		if (this.active_controller) {
			if (this.active_controller.onUpdate)
				this.active_controller.onUpdate(dt);
			else if (this.active_controller.proxy_controller?.onUpdate)
				this.active_controller.proxy_controller.onUpdate(dt);
		}

		// FIXME: "TmrwModule.updateRoom is not a function" sometimes
		if (this.native_mode && window.emscriptenIntitialized && typeof TmrwModule.updateRoom === "function") {
			//ModuleEngine.updateRoom(dt);
			TmrwModule.updateRoom(dt);
		}

		//fixed update loop
		this.accumulated_fixed_update += dt * 1000;
		while (
			this.fixed_update_ms &&
			this.accumulated_fixed_update > this.fixed_update_ms
		) {
			this.accumulated_fixed_update -= this.fixed_update_ms;
			this.onFixedUpdate(this.fixed_update_ms);
		}
	}

	onFixedUpdate(dt) {
		if (this.active_controller) {
			if (this.active_controller.onFixedUpdate)
				this.active_controller.onFixedUpdate(dt);
			else if (
				this.active_controller.proxy_controller &&
				this.active_controller.proxy_controller.onFixedUpdate
			)
				this.active_controller.proxy_controller.onFixedUpdate(dt);
		}
	}

	//called from View
	onMouse(e) {
		//HACK to know if I can play videos or open audio stream
		if (!ROOM.user_clicked_once && e.type === "mousedown") {
			ROOM.user_clicked_once = true;
			ViewCore.user_clicked = true; //legacy
			LEvent.trigger(this.space, "user_clicked_once");
		}

		if (e.type === "mousedown" && this.onVideosCanPlay) {
			this.onVideosCanPlay(ROOM);
			this.onVideosCanPlay = null;
		}
		let mouseBlockedByNative = false;
		if (this.native_mode) {
			//mouseBlockedByNative = ModuleEngine.isMouseHovered();
			mouseBlockedByNative = this.nativeEngine._room.isMouseBlockedByGUI();
		}

		//block mouse while loading
		if (mouseBlockedByNative) return;
		if (!this.allowMove && e.type === "mousemove") return;
		if (!this.allowClick && ( e.type  === "mouseup" || e.type === "mousedown")) return;

		if (this.active_controller) {
			if (this.active_controller.onMouse) {
				var r = this.active_controller.onMouse(e);
				if (r) return r;
			} else if (
				this.active_controller.proxy_controller.onMouse &&
				this.active_controller.proxy_controller.onMouse
			) {
				var r = this.active_controller.proxy_controller.onMouse(e);
				if (r) return r;
			}
		}
	}

	// INTERACTION ******************************
	onKeyDown(e) {
		//block keyboard while loading
		if (!this.allowKeyBoard) return;

		// Except F10 Key press all key presses are disabled
		if (this.is_in_tutorial_flow && e.code !== "F10") return;

		if (this.active_controller) {
			if (this.active_controller.onKeyDown) {
				var r = this.active_controller.onKeyDown(e);
				if (r) return r;
			} else if (
				this.active_controller.proxy_controller &&
				this.active_controller.proxy_controller.onKeyDown
			) {
				var r = this.active_controller.proxy_controller.onKeyDown(e);
				if (r) return r;
			}
		}

		if (e.code === "F6") {
			const data = this.space.serialize();
			data.url = this.space.url;
			localStorage.setItem("room_backup", JSON.stringify(data));
			location.reload();
		}
	}

	onKeyUp(e) {
		//nothing done here
	}

	onNodeClicked(node) {
		//block mouse while loading
		if (!this.allowClick && ( e.type  === "mouseup" || e.type === "mousedown")) return;

		if (this.active_controller) {
			if (this.active_controller.onNodeClicked)
				this.active_controller.onNodeClicked(node);
			else if (
				this.active_controller.proxy_controller &&
				this.active_controller.proxy_controller.onNodeClicked
			)
				this.active_controller.proxy_controller.onNodeClicked(node);
		}
	}

	notify(event, payload) {
		if (this.bridge) this.bridge.notify(event, payload);
	}

	onContextLost() {
		this.notify("GPU_CONTEXT_LOST", {});
	}

	async enableEditor() {
		const { default: ROOMEditor } = await import(/* webpackChunkName: "editor" */"./controllers/editor")

		this.editor_controller = new ROOMEditor(this, this.room, this.view);
		this.room.allow_external_scripts = true;

		const { default: GraphComponent } = await import(/* webpackChunkName: "[c]graph" */"@src/engine/components/graph")
		ROOM.registerComponent(GraphComponent);

		//const { default: ROOMEditor2 } = await import(/* webpackChunkName: "editor" */"./controllers/editor2")
		//this.editor_controller2 = new ROOMEditor2(this, this.room, this.view);
	}

	/*
	async enableDirector() {
		const { default: ROOMDirector } = await import("./controllers/director");
		this.director_controller = new ROOMDirector(this, this.room, this.view);
	}
	*/

	enableDrop(container) {
		const that = this;
		const dropbox = container || document.body;
		dropbox.addEventListener("dragenter", onDragEvent, false);

		function onDragEvent(evt) {
			onDragAny(evt);
			dropbox.addEventListener("dragexit", onDragAny, false);
			dropbox.addEventListener("dragover", onDragAny, false);
			dropbox.addEventListener("drop", onDrop, false);
		}

		function onDragAny(evt) {
			evt.stopPropagation();
			evt.preventDefault();
			that.onDrag(evt);
		}

		function onDrop(evt) {
			GUI.dragged_item = null;
			dropbox.removeEventListener("dragexit", onDragAny, false);
			dropbox.removeEventListener("dragover", onDragAny, false);
			dropbox.removeEventListener("drop", onDrop, false);
			that.onDrag(evt);
			that.onDropItem(evt);
		}
	}

	//element being dragged
	onDrag(evt) {
		GL.augmentEvent(evt);
		//console.debug(evt);
		if (this.active_controller) {
			if (this.active_controller.onDrag) this.active_controller.onDrag(evt);
			else if (
				this.active_controller.proxy_controller &&
				this.active_controller.proxy_controller.onDrag
			)
				this.active_controller.proxy_controller.onDrag(evt);
		}
	}

	onDropItem(evt) {
		evt.stopPropagation();
		evt.preventDefault();
		GL.augmentEvent(evt);

		if (GUI.onDropItem(evt)) return;

		if (this.active_controller) {
			if (this.active_controller.onDropItem)
				this.active_controller.onDropItem(evt);
			else if (
				this.active_controller.proxy_controller &&
				this.active_controller.proxy_controller.onDropItem
			)
				this.active_controller.proxy_controller.onDropItem(evt);
		}
	}

	onPaste(event) {
		let paste = (event.clipboardData || window.clipboardData).getData("text");
		console.debug("paste", paste, event.target);
		if (GUI.onPaste(event, paste)) {
			event.preventDefault();
			return true;
		}

		paste = paste.toUpperCase();
		if (this.active_controller) {
			if (this.active_controller.onPaste) {
				if (this.active_controller.onPaste(paste, event)) {
					event.preventDefault();
					return true;
				}
			} else if (
				this.active_controller.proxy_controller &&
				this.active_controller.proxy_controller.onPaste
			) {
				if (this.active_controller.proxy_controller.onPaste(paste, event)) {
					event.preventDefault();
					return true;
				}
			}
		}
	}

	onBeforeUnload(e) {
		ROOM.beforeUnload();
		this.saveSessionInfo();
		if (this.native_mode) {
			nativeEngine.onExit();
		}
		if (this.active_controller) {
			if (this.active_controller.onBeforeUnload)
				return this.active_controller.onBeforeUnload();
			else if (
				this.active_controller.proxy_controller &&
				this.active_controller.proxy_controller.onBeforeUnload
			)
				return this.active_controller.proxy_controller.onBeforeUnload();
		}
	}

	saveSessionInfo() {
		const session = {
			quality: this.quality,
			plugins: this.plugins,
		};

		localStorage.setItem("ROOM_Session_settings", JSON.stringify(session));
		return session;
	}

	loadSessionInfo() {
		const str = localStorage.getItem("ROOM_Session_settings");
		if (!str) return;
		console.debug("loaded session data: " + str);
		const session = JSON.parse(str);
		if (session.plugins && session.plugins.length) {
			this.plugins = session.plugins;
			ROOM.loadScripts(session.plugins);
		}
		return session;
	}

	addPlugin(url, callback) {
		this.plugins.push(url);
		ROOM.loadScripts([ url ], callback);
	}

	reloadPlugins() {
		ROOM.loadScripts(this.plugins);
	}

	//===============================================================================================================
	// Emscripten specific stuff
	//===============================================================================================================

	/**
	 * Load room when everything else is ready
	 */
	delayedLoadRoom() {
		if (this.room_url == null) {
			return;
		}

		if (!this.native_mode || !window.emscriptenIntitialized) {
			return;
		}

		/**
		 * @type {XYZLauncher}
		 */
		const that = this;

		if (this.native_mode) {
			if (this.native_profile !== 0.0) {
				nativeEngine._engine.setProfileStallsThreshold(this.native_profile);
			}
			if (this.native_streaming !== undefined) {
				nativeEngine._engine.setStreamingMode(this.native_streaming);
			}

			TmrwModule.destroyRoom();
			this.native_loaded = false;
			this.native_json_loaded = false;

			this.native_load_time = getTime();
		}

		this.space.load(
			this.room_url,
			function () {
				that.onRoomReady();
				if (that.room_callback) that.room_callback(that.space);
				// for backward compatibility
				if (that.#legacyInitializationMode) {
					that.setController(that.call_controller);
				}
			},
			function (error) {
				console.error("Failed room loading: ", error);
			}
		);

		this.room_url = null;
	}

	emRoomLoaded(status, pos, target) {
		if (!this.space) return;
		console.debug("STATUS LOADING:", status); // Status 1 means all room materials are precached

		// status values:
		// Unloaded = 0,
		// OK = 1 (Streaming Started),
		// OK-Materials-Compiling = 2,
		// Downloading = 3,
		// Importing = 4,
		// Cancelled = 5,
		// DownloadFailed = 6,
		// ImportFailed = 7,
		// CacheFailed = 8

		if (status == 0)
			this.native_json_loaded = true;

		// Andrey FIXME: I changed room loaded event status from 1 to 2 to speedup loading
		// Average materials precache time is 3-5 secs. This time is enough to activate the identity panel
		// So overall loading time should be decreased
		if (status !== 2) return;

		if (!this.#startupTriggered)
			this.view.shutdown();	// we disabled render right after room loaded

		this.native_load_time = getTime() - this.native_load_time;


		// eslint-disable-next-line
		console.log("Engine init time: ", this.native_init_time, " ms");
		// eslint-disable-next-line
		console.log("Room load time: ", this.native_load_time, " ms");

		this.native_loaded = true;
		this.native_json_loaded = true;
		this.space.loading_info.length = 0;
		this.space.onChangeResourcesLoading();

		if (xyz.native_mode) {
			LEvent.trigger(this.space, "first_time_seat");
		}
	}

	emRoomMaterialLoaded(material, flags) {
		if (!this.ROOM || !this.ROOM.edited_materials) return;

		// Custom material event from native to resave edited material in the editor
		this.ROOM.edited_materials[material] = flags;
	}

	emRoomEntityLoaded(entity, status) {
		if (!this.space) return;

		// Event from native: Entity loaded and initialized
	}

	emRoomNodeLoaded(node, status) {
		if (!this.space) return;

		// Event from native: Node loaded and initialized
	}

	emRoomProgress(progr, type) {
		this._loadProgress = progr;
		this._loadProgressType = type;
		if (progr > 0.0) {
			if (!this.native_loaded) this.space.loading_info.length = 1;
		}
	}

	getLoadingProgress() {
		return this._loadProgress;
	}

	get root_path() {
		return this._root_path;
	}

	set root_path(v) {
		RootPath.set(v);
		this._root_path = v;
		if(this.view)
			this.view.renderer.setDataFolder(v);
		if (this.space)
			this.space.root_path = this._root_path;
	}

	get webGLContext() {
		return this.view.context;
	}

	set webGLContext(_value) {
		throw new Error("Context can only be retreived, not set");
	}

	get quality() {
		return this.space.quality;
	}

	set quality(v) {
		this.space.quality = v;
	}

	static registerModule = XYZRegisterModule
}

window.XYZLauncher = XYZLauncher;
XYZLauncher.Modules = XYZModules;
XYZLauncher.native_default = false;

// temp fix - expose via launcher
XYZLauncher.CROP_MODES = RoomCropModes;


export default XYZLauncher;
