import RoomProxy from "@src/engine/Room/RoomProxy";
import RootPath from "@src/engine/Room/RootPath";

export const getFolder = function(fullpath)
{
	if (!fullpath)
		return "";
	var info = getPathInfo(fullpath);
	return info.folder;
}

export const getPathInfo = function(fullpath)
{
	if (!fullpath)
		return null;

	var pos = fullpath.indexOf("?");
	if (pos !== -1)
		fullpath = fullpath.substr(0,pos);

	var tokens = fullpath.split("/");
	var base = tokens[ tokens.length - 1 ];
	if (base.indexOf(".") === -1 )
	{
		return {
			folder: tokens.filter(a=>a).join("/"), //no filename, just folder
			basename: null
		};
	}

	tokens = tokens.filter(a=>a);//remove "" or nulls
	var basename = tokens.pop();
	var folder = tokens.join("/");
	return {
		basename: basename,
		folder: folder
	};
}

export const getFullPath = function(path, nocache)
{
	if (!path || path.constructor !== String)
		return path;
	if (path[0] === "/")
		path = path.substr(1);

	const proxy = RoomProxy.getCurrentProxy();

	if (path.indexOf("://") === -1) //relative
	{
		const root_path = RootPath.get();

		var hasSlashA = root_path.substr(-1) === "/";
		var hasSlashB = path[0] === "/";
		if (hasSlashA !== hasSlashB )
			path = root_path + path;
		else if ( hasSlashA && hasSlashB )
			path = root_path + path.substr(1);
		else
			path = root_path + "/" + path;
	}
	else if (proxy)
	{
		path = proxy + path;
	}

	if (nocache)
		path += (path.indexOf("?") === -1 ? "?" : "&" ) + "nocache=" + String(Math.random()) + performance.now();
	return path;
}
