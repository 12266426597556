import { DEG2RAD } from "@src/constants";
import { mat4SetTranslation } from "@src/gl-matrix/mat4";
import { vec2ComputeSignedAngle } from "@src/gl-matrix/vec2";
import { GL } from "@src/libs/litegl";
import extendClass from "@src/libs/LiteGL/extendClass";
import loadFileAtlas from "@src/libs/LiteGL/File/loadFileAtlas";
import processFileAtlas from "@src/libs/LiteGL/File/processFileAtlas";
import { Gizmo } from "@src/libs/rendeer-gizmo";
import { BlendType } from "@src/libs/rendeer/BlendType";
import { Camera } from "@src/libs/rendeer/Camera";
import { ClipType } from "@src/libs/rendeer/ClipType";
import { DataType } from "@src/libs/rendeer/DataType";
import { Direction3Constants } from "@src/libs/rendeer/Direction3Constants";
import { InterpolationType } from "@src/libs/rendeer/InterpolationType";
import { Material } from "@src/libs/rendeer/Material";
import { parseTextConfig } from "@src/libs/rendeer/parseTextConfig";
import { PriorityType } from "@src/libs/rendeer/PriorityType";
import { Ray } from "@src/libs/rendeer/Ray";
import { Scene } from "@src/libs/rendeer/Scene";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { testRayMesh } from "@src/libs/rendeer/testRayMesh";
import { testRayWithNodes } from "@src/libs/rendeer/testRayWithNodes";
import { TYPE_SIZES } from "@src/libs/rendeer/TYPE_SIZES";
import { mat3, mat4, quat, vec2, vec3, vec4 } from "gl-matrix";

//Rendeer.js lightweight scene container by Javi Agenjo (javi.agenjo@gmail.com) 2014

const global = globalThis;

//main namespace

/**
 * Main namespace
 * @namespace RD
 */

/**
 * the global namespace, access it using RD.
 * @class .
 */

/**
 * @property ZERO {vec3}
 * @default[0,0,0]
 */

/**
 * @property ONE {vec3}
 * @default[1,1,1]
 */

/**
 * @property BLACK {vec3}
 * @default[0,0,0]
 */

/**
 * @property WHITE {vec3}
 * @default[1,1,1]
 */

export const RD = {
	version: 0.5,

	get last_hit_test() {
		throw new Error("deprecated, moved to testRayMesh");
	}
};

if (global.RD !== undefined) {
	throw new Error("RD namespace already set, likely attempting to load a second instance of Rendeer script");
}

// assign to global scope
global.RD = RD;
/**
 * @deprecated use `Direction3Constants` instead
 */
RD.ZERO = Direction3Constants.ZERO;
/**
 * @deprecated use `Direction3Constants` instead
 */
RD.ONE = Direction3Constants.ONE;
/**
 * @deprecated use `Direction3Constants` instead
 */
RD.RIGHT = Direction3Constants.RIGHT;
/**
 * @deprecated use `Direction3Constants` instead
 */
RD.LEFT = Direction3Constants.LEFT;
/**
 * @deprecated use `Direction3Constants` instead
 */
RD.UP = Direction3Constants.UP;
/**
 * @deprecated use `Direction3Constants` instead
 */
RD.DOWN = Direction3Constants.DOWN;
/**
 * @deprecated use `Direction3Constants` instead
 */
RD.FRONT = Direction3Constants.FRONT;
/**
 * @deprecated use `Direction3Constants` instead
 */
RD.BACK = Direction3Constants.BACK;

RD.FRONT2D = vec2.fromValues(0, 1);
RD.WHITE = vec3.fromValues(1, 1, 1);
RD.BLACK = vec3.fromValues(0, 0, 0);
RD.IDENTITY = mat4.create();
RD.ONES4 = vec4.fromValues(1, 1, 1, 1);
RD.TRANS10_IDENTITY = new Float32Array([ 0, 0, 0, 0, 0, 0, 1, 1, 1, 1 ]);

RD.CENTER = 0;
RD.TOP_LEFT = 1;
RD.TOP_RIGHT = 2;
RD.BOTTOM_LEFT = 3;
RD.BOTTOM_RIGHT = 4;
RD.TOP_CENTER = 5;
RD.BOTTOM_CENTER = 6;

//higher means render before
/**
 * @deprecated use `PriorityType` directly instead
 * @type {PriorityType}
 */
RD.PRIORITY_BACKGROUND = PriorityType.Background;
/**
 * @deprecated use `PriorityType` directly instead
 * @type {PriorityType}
 */
RD.PRIORITY_OPAQUE = PriorityType.Opaque;
/**
 * @deprecated use `PriorityType` directly instead
 * @type {PriorityType}
 */
RD.PRIORITY_ALPHA = PriorityType.Alpha;
/**
 * @deprecated use `PriorityType` directly instead
 * @type {PriorityType}
 */
RD.PRIORITY_HUD = PriorityType.HUD;

/**
 * @deprecated use `BlendType` directly instead
 * @type {BlendType}
 */
RD.BLEND_NONE = BlendType.None;
/**
 * @deprecated use `BlendType` directly instead
 * @type {BlendType}
 */
RD.BLEND_ALPHA = BlendType.Alpha;
/**
 * @deprecated use `BlendType` directly instead
 * @type {BlendType}
 */
RD.BLEND_ADD = BlendType.Add;
/**
 * @deprecated use `BlendType` directly instead
 * @type {BlendType}
 */
RD.BLEND_MULTIPLY = BlendType.Multiply;

RD.NO_BILLBOARD = 0;
RD.BILLBOARD_SPHERIC = 1;
RD.BILLBOARD_PARALLEL_SPHERIC = 2;
RD.BILLBOARD_CYLINDRIC = 3;
RD.BILLBOARD_PARALLEL_CYLINDRIC = 4;

//data types (used in animation tracks)
RD.UNKNOWN = DataType.UNKNOWN;
RD.NUMBER = DataType.NUMBER;
RD.SCALAR = DataType.SCALAR;
RD.VEC2 = DataType.VEC2;
RD.VEC3 = DataType.VEC3;
RD.VEC4 = DataType.VEC4;
RD.QUAT = DataType.QUAT;
RD.MAT3 = DataType.MAT3;
RD.TRANS10 = DataType.TRANS10;
RD.MAT4 = DataType.MAT4;
RD.STRING = DataType.STRING;

RD.TYPES = DataType;

RD.TYPES_SIZE = TYPE_SIZES;

RD.NO_INTERPOLATION = InterpolationType.None;
RD.LINEAR = InterpolationType.Linear;
RD.CUBIC = InterpolationType.Cubic;

/**
 * @deprecated use `StaticMaterialsTable` directly instead
 * @type {{[p: string]: Material}}
 */
RD.Materials = StaticMaterialsTable;
RD.Images = {}; //used for GLTFs embeded images

RD.setup = function (o) {
	o = o || {};
	if (RD.configuration)
		throw("already called setup");
	RD.configuration = o;
}

/* Temporary containers ************/
const identity_mat4 = mat4.create();
const temp_mat4 = mat4.create();
const temp_vec2 = vec2.create();
const temp_vec3 = vec3.create();
const temp_vec3b = vec3.create();

RD.Camera = Camera;

RD.CLIP_INSIDE = ClipType.Inside;
RD.CLIP_OUTSIDE = ClipType.Outside;
RD.CLIP_OVERLAP = ClipType.Overlap;

/**
 * @deprecated use direct import instead
 * @readonly
 */
RD.testRayWithNodes = testRayWithNodes;
/**
 * @deprecated use direct import instead
 * @readonly
 */
RD.testRayMesh = testRayMesh;


/**
 * @deprecated use `Material` import directly instead
 * @readonly
 */
RD.Material = Material;

/**
 * Renderer in charge of rendering a Scene
 * Valid options: all LiteGL context creation options (canvas, WebGL Flags, etc), plus: assets_folder, autoload_assets, shaders_file
 * @class Renderer
 * @constructor
 */
function Renderer(context, options) {
	options = options || {};

	var gl = this.gl = this.context = context;
	if (!gl || !gl.enable)
		throw("litegl GL context not found.");

	if (context !== global.gl) {
		gl.makeCurrent();

		global.gl = this.gl;
		GL.ctx = this.gl;
	}

	this.point_size = 5;
	this.sort_by_priority = true;
	this.sort_by_distance = false;
	this.reverse_normals = false; //used for reflections
	this.disable_cull_face = false;
	this.layers_affect_children = false;

	this.assets_folder = "";

	this._view_matrix = mat4.create();
	this._projection_matrix = mat4.create();
	this._viewprojection_matrix = mat4.create();
	this._mvp_matrix = mat4.create();
	this._model_matrix = mat4.create();
	this._texture_matrix = mat3.create();
	this._color = vec4.fromValues(1, 1, 1, 1); //in case we need to set a color
	this._viewprojection2D_matrix = mat4.create(); //used to 2D rendering

	this._nodes = [];
	this._uniforms = {
		u_view: this._view_matrix,
		u_viewprojection: this._viewprojection_matrix,
		u_model: this._model_matrix,
		u_mvp: this._mvp_matrix,
		u_global_alpha_clip: 0.0,
		u_color: this._color,
		u_texture_matrix: this._texture_matrix
	};

	this.global_uniforms_containers = [ this._uniforms ];

	//set some default stuff

	this.canvas = gl.canvas;

	this.assets_folder = options.assets_folder || "";
	this.autoload_assets = options.autoload_assets !== undefined ? options.autoload_assets : true;
	this.default_texture_settings = { wrap: gl.REPEAT, minFilter: gl.LINEAR_MIPMAP_LINEAR, magFilter: gl.LINEAR };
	this.default_cubemap_settings = { minFilter: gl.LINEAR_MIPMAP_LINEAR, magFilter: gl.LINEAR, is_cross: 1 };


	//global containers and basic data
	this.meshes["plane"] = GL.Mesh.plane({ size: 1 });
	this.meshes["planeXZ"] = GL.Mesh.plane({ size: 1, xz: true });
	this.meshes["cube"] = GL.Mesh.cube({ size: 1, wireframe: true });
	this.meshes["sphere"] = GL.Mesh.sphere({ size: 1, subdivisions: 32, wireframe: true });
	this.meshes["grid"] = GL.Mesh.grid({ size: 10 });

	this.textures["notfound"] = this.default_texture = new GL.Texture(1, 1, {
		filter: gl.NEAREST,
		pixel_data: new Uint8Array([ 0, 0, 0, 255 ])
	});
	this.textures["white"] = this.default_texture = new GL.Texture(1, 1, {
		filter: gl.NEAREST,
		pixel_data: new Uint8Array([ 255, 255, 255, 255 ])
	});

	this.num_assets_loading = 0;
	this.assets_loading = {};
	this.assets_not_found = {};
	this.frame = 0;
	this.draw_calls = 0;

	if (!options.ignore_shaders)
		this.createShaders();
	else
		this.createShadersLite(); // Andrey: Editor requires some shaders to work

	if (options.shaders_file)
		this.loadShaders(options.shaders_file, null, options.shaders_macros);

}

RD.Renderer = Renderer;

Object.defineProperty(Renderer.prototype, "color", {
	set: function (v) {
		this._color.set(v);
	},
	get: function () {
		return this._color;
	},
	enumerable: true
});

/**
 * whats the data folder where all data should be fetch
 * @method setDataFolder
 * @param {string} path
 */
Renderer.prototype.setDataFolder = function (path) {
	if (!path) {
		this.assets_folder = "";
		return;
	}

	this.assets_folder = path;

	if (this.assets_folder.substr(-1) != "/")
		this.assets_folder += "/";
}

/**
 * clear color and depth buffer
 * @method clear
 * @param {vec4} color clear color
 */
Renderer.prototype.clear = function (color) {
	if (color)
		this.gl.clearColor(color[0], color[1], color[2], color.length >= 3 ? color[3] : 1.0);
	else
		this.gl.clearColor(0, 0, 0, 0);
	this.gl.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT | WebGLRenderingContext.DEPTH_BUFFER_BIT);
}

Renderer._sort_by_dist_func = function (a, b) {
	return b._distance - a._distance;
}

Renderer._sort_by_priority_func = function (a, b) {
	return b.render_priority - a.render_priority;
}

Renderer._sort_by_priority_and_dist_func = function (a, b) {
	var r = b.render_priority - a.render_priority;
	if (r != 0)
		return r;
	return b._distance - a._distance;
}

/**
 * clears all resources from GPU
 * @method destroy
 */
Renderer.prototype.destroy = function () {
	if (typeof gl !== "undefined" && gl !== null) {
		gl.destroy();
	}

	// delete all materials
	Object.keys(StaticMaterialsTable).forEach(key => {
		delete StaticMaterialsTable[key];
	});
}

/**
 * renders once scene from one camera
 * @method render
 * @param {RD.Scene} scene
 * @param {RD.Camera} camera
 * @param {Array} nodes [Optional] array with nodes to render, otherwise all nodes will be rendered
 * @param {Number} layers [Optional] bit mask with which layers should be rendered, if omited then 0xFFFF is used (8 first layers)
 * @param {CustomPipeline} pipeline [Optional] allows to pass a class that will handle the rendering of the scene, check PBRPipeline from the repo for an example
 * @param {Boolean} skip_fbo [Optional] in case you are rendering to a texture and you have already set your own FBOs (for custom pipelineS)
 */
Renderer.prototype.render = function (scene, camera, nodes, layers, pipeline, skip_fbo) {
	if (layers == null)
		layers = 0xFFFF;

	if (!scene)
		throw("Renderer.render: scene not provided");

	if (this._current_scene) {
		this._current_scene = null;
		throw("Cannot render an scene while rendering an scene");
	}

	camera = camera || scene.camera;
	if (!camera)
		throw("Renderer.render: camera not provided");

	global.gl = this.gl;
	GL.ctx = this.gl;

	//find which nodes should we render
	this._nodes.length = 0;
	if (!nodes)
		scene._root.getVisibleChildren(this._nodes, layers, this.layers_affect_children);
	nodes = nodes || this._nodes;

	if (!nodes.length && 0)//even if no nodes in the scene, somebody may want to render something using the callbacks
	{
		scene.frame++;
		this.frame++;
		return;
	}

	//get matrices in the camera
	this.enableCamera(camera);
	this.enable2DView();

	//stack to store state
	this._state = [];
	this._meshes_missing = 0;
	//this.draw_calls = 0;
	this._current_scene = scene;

	//set globals
	this._uniforms.u_time = scene.time;

	//precompute distances
	if (this.sort_by_distance)
		nodes.forEach(function (a) {
			a._distance = a.getDistanceTo(camera._position);
		});

	//filter by mustRender (you can do your frustum culling here)
	var that = this;
	nodes = nodes.filter(function (n) {
		return !n.mustRender || n.mustRender(that, camera) != false;
	}); //GC

	//sort
	if (this.sort_by_distance && this.sort_by_priority)
		nodes.sort(RD.Renderer._sort_by_priority_and_dist_func);
	else if (this.sort_by_priority)
		nodes.sort(RD.Renderer._sort_by_priority_func);
	else if (this.sort_by_distance)
		nodes.sort(RD.Renderer._sort_by_dist_func);

	//pre rendering
	if (this.onPreRender)
		this.onPreRender(camera);

	if (scene._root.preRender)
		scene._root.preRender(this, camera);

	pipeline = pipeline || this.pipeline;

	if (pipeline)
		pipeline.render(this, nodes, camera, scene, skip_fbo);
	else {
		for (var i = 0; i < nodes.length; ++i) {
			var node = nodes[i];

			//recompute matrices
			node.updateGlobalMatrix(true);

			if (this.onPreRenderNode)
				this.onPreRenderNode(node, camera);
			if (node.preRender)
				node.preRender(this, camera);
		}

		//rendering
		for (var i = 0; i < nodes.length; ++i) {
			var node = nodes[i];
			node.flags.was_rendered = false;
			if (node.flags.visible === false || !(node.layers & layers))
				continue;
			if (this.mustRenderNode && this.mustRenderNode(node, camera) === false)
				continue;
			node.flags.was_rendered = true;
			this.setModelMatrix(node._global_matrix);

			if (node.render)
				node.render(this, camera);
			else
				this.renderNode(node, camera);
		}

		//post rendering
		if (scene._root.postRender)
			scene._root.postRender(this, camera);
		for (var i = 0; i < nodes.length; ++i) {
			var node = nodes[i];
			if (node.postRender)
				node.postRender(this, camera);
			if (this.onPostRenderNode)
				this.onPostRenderNode(node, camera);
		}
	}

	if (this.onPostRender)
		this.onPostRender(camera);

	scene.frame++;
	this.frame++;
	this._current_scene = null;
}

Renderer.prototype.enableCamera = function (camera) {
	this._camera = camera;
	camera.updateMatrices(); //multiply
	camera.extractPlanes(); //for frustrum culling

	this._view_matrix.set(camera._view_matrix);
	this._projection_matrix.set(camera._projection_matrix);
	this._viewprojection_matrix.set(camera._viewprojection_matrix);
	this._uniforms.u_camera_position = camera.position;
}

//in case you are going to use functions to render in 2D in screen space
Renderer.prototype.enable2DView = function () {
	mat4.ortho(this._viewprojection2D_matrix, 0, gl.viewport_data[2], 0, gl.viewport_data[3], -1, 1);
}


//this functions allow to interrupt the render of one scene to render another one
Renderer.prototype.saveState = function () {
	var state = {
		camera: this._camera,
		nodes: this._nodes
	};

	this.state.push(state);
}

Renderer.prototype.restoreState = function () {
	var state = this.state.pop();
	var camera = this.camera = state.camera;
	this._view_matrix.set(camera._view_matrix);
	this._projection_matrix.set(camera._projection_matrix);
	this._viewprojection_matrix.set(camera._viewprojection_matrix);
	this._uniforms.u_camera_position = camera.position;
	this._nodes = state.nodes;
}

//assign and updated viewprojection matrix
Renderer.prototype.setModelMatrix = function (matrix) {
	this._model_matrix.set(matrix);
	mat4.multiply(this._mvp_matrix, this._viewprojection_matrix, matrix);
}

//allows to add some global uniforms without overwritting the existing ones
Renderer.prototype.setGlobalUniforms = function (uniforms) {
	for (var i in uniforms) {
		if (this._uniforms[i] && this._uniforms[i].set)
			this._uniforms[i].set(uniforms[i]);
		else
			this._uniforms[i] = uniforms[i];
	}
}

//avoid garbage
var instancing_uniforms = {
	u_model: null
};

//used to render one node (ignoring its children) based on the shader, texture, mesh, flags, layers and uniforms
Renderer.prototype.renderNode = function (node, camera) {
	//get mesh
	var mesh = null;
	if (node._mesh) //hardcoded mesh
		mesh = node._mesh;
	else if (node.mesh) //shared mesh
	{
		mesh = gl.meshes[node.mesh];
		if (!mesh) {
			this._meshes_missing++;
			if (this.autoload_assets && node.mesh.indexOf(".") != -1)
				this.loadMesh(node.mesh);
		}
	}

	//from GLTF
	if (node.primitives && node.primitives.length) {
		if (!mesh)
			return;
		for (var i = 0; i < node.primitives.length; ++i) {
			var prim = node.primitives[i];
			var material = this.overwrite_material || StaticMaterialsTable[prim.material];
			if (!material)
				continue;
			if (this.onFilterByMaterial) {
				if (this.onFilterByMaterial(material, StaticMaterialsTable[prim.material]) == false)
					continue;
			}
			this.renderMeshWithMaterial(node._global_matrix, mesh, material, "triangles", i, node.skeleton, node);
		}
		return;
	}

	if (mesh && (node.material || this.overwrite_material)) {
		var material = this.overwrite_material || StaticMaterialsTable[node.material];
		if (material) {
			if (material.render) {
				this.renderMeshWithMaterial(node._global_matrix, mesh, material, node.indices, node.submesh, node.skeleton, node);
				return;
			} else
				node.color = material.color;
		}
	}

	if (!mesh) {
		if (node.onRender)
			node.onRender(this, camera);
		return;
	}

	var instancing = false;
	if (node._instances && (gl.webgl_version > 1 || gl.extensions.ANGLE_instanced_arrays))
		instancing = true;

	//get shader
	var shader = null;
	var shader_name = node.shader;
	if (this.on_getShader)
		shader = this.on_getShader(node, camera);
	else {
		if (!shader && node.shader)
			shader = gl.shaders[shader_name];
		if (this.shader_overwrite)
			shader = gl.shaders[this.shader_overwrite];
	}
	if (!shader) {
		if (node.skeleton)
			shader = node.textures.color ? this._texture_skinning_shader : this._flat_skinning_shader;
		else
			shader = node.textures.color ? this._texture_shader : this._flat_shader;
	}

	//shader doesnt support instancing
	if (instancing && !shader.attributes.u_model)
		instancing = false;

	//get texture
	var slot = 0;
	var texture = null;
	for (var i in node.textures) {
		var texture_name = node.textures[i];
		if (!texture_name)
			continue;
		if (texture_name.constructor === Object) //in case it has properties for this channel
			texture_name = texture_name.texture;

		var texture_uniform_name = "u_" + i + "_texture";

		if (shader && !shader.samplers[texture_uniform_name]) //texture not used in shader
			continue; //do not bind it

		texture = gl.textures[texture_name];
		if (!texture) {
			if (this.autoload_assets && texture_name.indexOf(".") != -1)
				this.loadTexture(texture_name, this.default_texture_settings);
			texture = gl.textures["white"];
		}

		if (node.flags.pixelated) {
			texture.bind(0);
			gl.texParameteri(WebGLRenderingContext.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
			gl.texParameteri(WebGLRenderingContext.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
		} else if (node.flags.pixelated === false) {
			texture.bind(0);
			gl.texParameteri(WebGLRenderingContext.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
			gl.texParameteri(WebGLRenderingContext.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
		}

		node._uniforms[texture_uniform_name] = texture.bind(slot++);
	}

	//flags
	if (!this.ignore_flags)
		this.enableItemFlags(node);

	if (node.onRender)
		node.onRender(this, camera, shader);

	if (node.skeleton) {
		node.bones = node.skeleton.computeFinalBoneMatrices(node.bones, mesh);
		shader.setUniform("u_bones", node.bones);
	}

	//allows to have several global uniforms containers
	for (var i = 0; i < this.global_uniforms_containers.length; ++i)
		shader.uniforms(this.global_uniforms_containers[i]); //globals
	if (!this.skip_node_uniforms)
		shader.uniforms(node._uniforms); //node specifics
	if (node.onShaderUniforms) //in case the node wants to add extra shader uniforms that need to be computed at render time
		node.onShaderUniforms(this, shader);
	if (this.onNodeShaderUniforms) //in case the node wants to add extra shader uniforms that need to be computed at render time
		this.onNodeShaderUniforms(this, shader, node);

	var group = null;
	if (node.submesh != null && mesh.info && mesh.info.groups && mesh.info.groups[node.submesh])
		group = mesh.info.groups[node.submesh];

	if (instancing) {
		instancing_uniforms.u_model = node._instances;
		if (group)
			shader.drawInstanced(mesh, node.primitive === undefined ? gl.TRIANGLES : node.primitive, node.indices, instancing_uniforms, group.start, group.length);
		else if (node.draw_range)
			shader.drawInstanced(mesh, node.primitive === undefined ? gl.TRIANGLES : node.primitive, node.indices, instancing_uniforms, node.draw_range[0], node.draw_range[1]);
		else
			shader.drawInstanced(mesh, node.primitive === undefined ? gl.TRIANGLES : node.primitive, node.indices, instancing_uniforms);
	} else {
		if (group)
			shader.drawRange(mesh, node.primitive === undefined ? gl.TRIANGLES : node.primitive, group.start, group.length, node.indices);
		else if (node.draw_range)
			shader.drawRange(mesh, node.primitive === undefined ? gl.TRIANGLES : node.primitive, node.draw_range[0], node.draw_range[1], node.indices);
		else
			shader.draw(mesh, node.primitive === undefined ? gl.TRIANGLES : node.primitive, node.indices);
	}

	if (!this.ignore_flags)
		this.disableItemFlags(node);

	this.draw_calls += 1;
}

Renderer.prototype.renderMesh = function (model, mesh, texture, color, shader, mode, index_buffer_name, group_index) {
	if (!mesh)
		return;
	if (color)
		this._uniforms.u_color.set(color);
	if (!model)
		model = RD.IDENTITY;
	this._uniforms.u_model.set(model);
	if (!shader)
		shader = texture ? gl.shaders["texture"] : gl.shaders["flat"];
	if (texture)
		this._uniforms.u_texture = texture.bind(0);
	shader.uniforms(this._uniforms);
	shader.draw(mesh, mode === undefined ? gl.TRIANGLES : mode, index_buffer_name);
	this.draw_calls += 1;
}

Renderer.prototype.renderMeshWithMaterial = function (model, mesh, material, index_buffer_name, group_index, skeleton, node) {
	if (material.render)
		material.render(this, model, mesh, index_buffer_name, group_index, skeleton, node);
}

//allows to pass a mesh or a bounding box
//if matrix specified, the bbox will be TSR on rendering (rendered ad OOBB), not recomputed using the matrix
Renderer.prototype.renderBounding = function (mesh_or_bb, matrix, color) {
	if (!mesh_or_bb)
		return;
	matrix = matrix || RD.IDENTITY;

	var m = this._uniforms.u_model;
	var bb = null;
	if (mesh_or_bb.constructor === GL.Mesh)
		bb = mesh_or_bb._bounding;
	else
		bb = mesh_or_bb;

	color = color || [ 1, 1, 0, 1 ];

	var s = bb.subarray(3, 6); //halfsize
	mat4.translate(m, matrix, bb.subarray(0, 3));
	mat4.scale(m, m, [ s[0] * 2, s[1] * 2, s[2] * 2 ]);
	this.renderMesh(m, gl.meshes["cube"], null, color, null, gl.LINES, "wireframe");
}

Renderer.prototype.enableItemFlags = function (item) {
	var ff = item.flags.flip_normals;
	if (this.reverse_normals)
		ff = !ff;
	gl.frontFace(ff ? gl.CW : gl.CCW);
	gl[item.flags.depth_test === false ? "disable" : "enable"](gl.DEPTH_TEST);
	if (item.flags.depth_write === false)
		gl.depthMask(false);
	gl[item.flags.two_sided === true || this.disable_cull_face ? "disable" : "enable"](gl.CULL_FACE);

	//blend
	if (item.blend_mode !== RD.BLEND_NONE) {
		gl.enable(gl.BLEND);
		switch (item.blend_mode) {
		case RD.BLEND_ALPHA:
			gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
			break;
		case RD.BLEND_ADD:
			gl.blendFunc(gl.SRC_ALPHA, gl.ONE);
			break;
		case RD.BLEND_MULTIPLY:
			gl.blendFunc(gl.DST_COLOR, gl.ONE_MINUS_SRC_ALPHA);
			break;
		}
	} else
		gl.disable(gl.BLEND);

	//PBR Materials
	if (item.alphaMode == "BLEND") {
		gl.enable(gl.BLEND);
		gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
		gl.depthMask(false);
	}
}

Renderer.prototype.disableItemFlags = function (item) {
	if (item.flags.flip_normals) gl.frontFace(gl.CCW);
	if (item.flags.depth_test === false) gl.enable(gl.DEPTH_TEST);
	if (item.blend_mode !== RD.BLEND_NONE) gl.disable(gl.BLEND);
	if (item.flags.two_sided) gl.disable(gl.CULL_FACE);
	if (item.flags.depth_write === false)
		gl.depthMask(true);

	if (item.alphaMode == "BLEND") {
		gl.depthMask(true);
		gl.disable(gl.BLEND);
	}
}

Renderer.prototype.setPointSize = function (v) {
	this.point_size = v;
	gl.shaders["point"].uniforms({ u_pointSize: this.point_size });
}


/**
 * Helper method to render points very fast
 * positions and extra must be a Float32Array with all the positions, extra must have 4
 * @method renderPoints
 * @param {Float32Array} positions
 * @param {Float32Array} extra used to stored extra info per point
 * @param {RD.Camera} camera
 * @param {Number} num_points
 * @param {GL.Shader} shader
 * @param {Number} point_size
 */
RD.Renderer.prototype.renderPoints = function (positions, extra, camera, num_points, shader, point_size, primitive, texture, model) {
	if (!positions || positions.constructor !== Float32Array)
		throw("RD.renderPoints only accepts Float32Array");
	if (!shader) {
		if (primitive == GL.LINES || primitive == GL.LINE_LOOP) {
			shader = this.shaders["flat"];
		} else if (texture) {
			shader = this.shaders["_points_textured"];
			if (!shader) {
				shader = this.shaders["_points_textured"] = new GL.Shader(RD.points_vs_shader, RD.points_fs_shader, { "TEXTURED": "" });
				shader.uniforms({ u_texture: 0, u_atlas: 1, u_pointSize: 1 });
			}
		} else {
			shader = this.shaders[extra ? "_points_color" : "_points"];
			if (!shader) {
				if (extra)
					shader = this.shaders["_points_color"] = new GL.Shader(RD.points_vs_shader, RD.points_fs_shader, { "COLORED": "" });
				else
					shader = this.shaders["_points"] = new GL.Shader(RD.points_vs_shader, RD.points_fs_shader);
				shader.uniforms({ u_texture: 0, u_atlas: 1, u_pointSize: 1 });
			}
		}
	}

	point_size = point_size || 1;

	var max_points = 1024;
	num_points = num_points || positions.length / 3;
	var positions_data = null;
	var extra_data = null;
	var mesh = this._points_mesh;

	if (num_points > positions.length / 3)
		num_points = positions.length / 3;

	if (!mesh || positions.length > (max_points * 3)) {
		if (num_points > max_points)
			max_points = GL.Texture.nextPOT(num_points);
		positions_data = new Float32Array(max_points * 3);
		extra_data = new Float32Array(max_points * 4);
		mesh = this._points_mesh = GL.Mesh.load({ vertices: positions_data, extra4: extra_data });
	} else {
		positions_data = this._points_mesh.getBuffer("vertices").data;
		extra_data = this._points_mesh.getBuffer("extra4").data;
	}

	positions_data.set(positions_data.length > positions.length ? positions : positions.subarray(0, positions_data.length));
	if (extra)
		extra_data.set(extra_data.length > extra.length ? extra : extra.subarray(0, extra_data.length));
	else if (extra_data.fill) //fill with zeros
		extra_data.fill(0);
	mesh.upload(GL.DYNAMIC_STREAM);

	shader.setUniform("u_color", this._color);
	shader.setUniform("u_pointSize", point_size);
	shader.setUniform("u_camera_perspective", camera._projection_matrix[5]);
	shader.setUniform("u_model", model || RD.IDENTITY);
	shader.setUniform("u_viewport", gl.viewport_data);
	shader.setUniform("u_viewprojection", camera._viewprojection_matrix);
	if (texture)
		shader.setUniform("u_texture", texture.bind(0));
	shader.drawRange(mesh, primitive !== undefined ? primitive : GL.POINTS, 0, num_points);

	return mesh;
}

RD.points_vs_shader = "\n\
precision highp float;\n\
attribute vec3 a_vertex;\n\
attribute vec4 a_extra4;\n\
varying vec3 v_pos;\n\
varying vec4 v_extra4;\n\
uniform mat4 u_model;\n\
uniform mat4 u_viewprojection;\n\
uniform vec4 u_viewport;\n\
uniform float u_camera_perspective;\n\
uniform float u_pointSize;\n\
\n\
float computePointSize( float radius, float w )\n\
{\n\
	if(radius < 0.0)\n\
		return -radius;\n\
	return u_viewport.w * u_camera_perspective * radius / w;\n\
}\n\
\n\
void main() {\n\
	v_pos = (u_model * vec4(a_vertex,1.0)).xyz;\n\
	v_extra4 = a_extra4;\n\
	gl_Position = u_viewprojection * vec4(v_pos,1.0);\n\
	gl_PointSize = computePointSize( u_pointSize, gl_Position.w );\n\
}\n\
";

RD.points_fs_shader = "\n\
precision highp float;\n\
uniform vec4 u_color;\n\
varying vec4 v_extra4;\n\
vec2 remap(in vec2 value, in vec2 low1, in vec2 high1, in vec2 low2, in vec2 high2 ) { vec2 range1 = high1 - low1; vec2 range2 = high2 - low2; return low2 + range2 * (value - low1) / range1; }\n\
#ifdef TEXTURED\n\
	uniform sampler2D u_texture;\n\
#endif\n\
#ifdef FS_UNIFORMS\n\
	FS_UNIFORMS\n\
#endif\n\
\n\
void main() {\n\
	vec4 color = u_color;\n\
	#ifdef COLORED\n\
		color *= v_extra4;\n\
	#endif\n\
	#ifdef TEXTURED\n\
		color *= texture2D( u_texture, gl_FragCoord );\n\
	#endif\n\
	#ifdef FS_CODE\n\
		FS_CODE\n\
	#endif\n\
	gl_FragColor = color;\n\
}\n\
";

/**
 * Helper method to render points very fast
 * positions and extra must be a Float32Array with all the positions, extra must have 4
 * @method renderLines
 * @param {Float32Array} positions
 * @param {Float32Array} colors in rgba format
 * @param {RD.Camera} camera
 * @param {Number} num_points
 * @param {GL.Shader} shader
  */
 RD.Renderer.prototype.renderLines = function (positions, colors, camera, num_points, closed, shader, model) {
	if (!positions || positions.constructor !== Float32Array)
		throw("RD.renderPoints only accepts Float32Array");
	if (!shader) {
		if (colors)
			shader = this.shaders["color"];
		else
			shader = this.shaders["flat"];
	}

	var primitive = closed ? GL.LINE_LOOP : GL.LINES;
	var max_points = 1024;
	num_points = num_points || positions.length / 3;
	var positions_data = null;
	var colors_data = null;
	var mesh = this._lines_mesh;
	model = model || RD.IDENTITY;

	if (num_points > positions.length / 3)
		num_points = positions.length / 3;

	if (!mesh || positions.length > (max_points * 3)) {
		if (num_points > max_points)
			max_points = GL.Texture.nextPOT(num_points);
		positions_data = new Float32Array(max_points * 3);
		colors_data = new Float32Array(max_points * 4);
		mesh = this._lines_mesh = GL.Mesh.load({ vertices: positions_data, colors: colors_data });
	} else {
		positions_data = this._lines_mesh.getBuffer("vertices").data;
		colors_data = this._lines_mesh.getBuffer("colors").data;
	}

	positions_data.set(positions_data.length > positions.length ? positions : positions.subarray(0, positions_data.length));
	if (colors)
		colors_data.set(colors_data.length > colors.length ? colors : colors.subarray(0, colors_data.length));
	else if (colors_data.fill) //fill with zeros
		colors_data.fill(0);
	mesh.upload(GL.DYNAMIC_STREAM);

	mat4.multiply(this._mvp_matrix, camera._viewprojection_matrix, model);
	shader.setUniform("u_color", this._color);
	shader.setUniform("u_mvp", this._mvp_matrix);
	shader.drawRange(mesh, primitive, 0, num_points);
	return mesh;
}

//for rendering lines with width...
//stream vertices with pos in triangle strip form (aberrating jumps)
//stream extra2 with info about line corner (to inflate)

Renderer.prototype.renderWideLines = function (positions, lineWidth, strip, model) {
	if (!positions || positions.constructor !== Float32Array)
		throw("RD.renderPoints only accepts Float32Array");
	var shader = this.shaders["_lines"];
	if (!shader)
		shader = this.shaders["_lines"] = new GL.Shader(RD.lines_vs_shader, this._flat_fragment_shader);

	var camera = this._camera;
	var max_points = 1024;
	var num_points = positions.length / 3;
	var total_vertices = (strip ? num_points * 2 : num_points * 4);
	var positions_data = null;
	var normals_data = null;
	var extra_data = null;
	var mesh = this._lines_wide_mesh;

	if (!mesh || (total_vertices * 3) > mesh.getBuffer("vertices").data.length) {
		max_points = GL.Texture.nextPOT(total_vertices);
		positions_data = new Float32Array(max_points * 3);
		normals_data = new Float32Array(max_points * 3); //store tangent, not normal
		extra_data = new Float32Array(max_points * 2);
		indices_data = new Uint16Array(max_points * 3); //for every 2 points (line) there is 6 indices (two triangles)
		mesh = this._lines_wide_mesh = GL.Mesh.load({
			triangles: indices_data,
			vertices: positions_data,
			normals: normals_data,
			extra2: extra_data
		});
	} else {
		positions_data = this._lines_wide_mesh.getBuffer("vertices").data;
		normals_data = this._lines_wide_mesh.getBuffer("normals").data;
		extra_data = this._lines_wide_mesh.getBuffer("extra2").data;
		indices_data = this._lines_wide_mesh.getIndexBuffer("triangles").data;
	}

	var left_uv = vec2.fromValues(-1, -1);
	var right_uv = vec2.fromValues(1, -1);
	var left2_uv = vec2.fromValues(-1, 1);
	var right2_uv = vec2.fromValues(1, 1);
	var N = vec3.create();

	//fill
	if (!strip) {
		var num_lines = Math.floor(num_points / 2); //one line per 2 points

		var indices = [];
		//i is index of line
		for (var i = 0; i < num_lines; ++i) {
			var iv = i * 2; //index of vertex
			var v1 = positions.subarray(iv * 3, iv * 3 + 3);
			var v2 = positions.subarray(iv * 3 + 3, iv * 3 + 6);
			vec3.sub(N, v2, v1);

			positions_data.set(v1, i * 12);
			positions_data.set(v1, i * 12 + 3);
			positions_data.set(v2, i * 12 + 6);
			positions_data.set(v2, i * 12 + 9);

			normals_data.set(N, i * 12);
			normals_data.set(N, i * 12 + 3);
			normals_data.set(N, i * 12 + 6);
			normals_data.set(N, i * 12 + 9);

			extra_data.set(left_uv, i * 8);
			extra_data.set(right_uv, i * 8 + 2);
			extra_data.set(left2_uv, i * 8 + 4);
			extra_data.set(right2_uv, i * 8 + 6);

			indices_data.set([ i * 4 + 0, i * 4 + 2, i * 4 + 1, i * 4 + 1, i * 4 + 2, i * 4 + 3 ], i * 6);
		}
	} else {
		throw("strip lines not supported yet");
	}

	mesh.upload(GL.DYNAMIC_STREAM);

	gl.enable(gl.CULL_FACE);
	shader.setUniform("u_model", model || RD.IDENTITY);
	shader.setUniform("u_color", this._color);
	shader.setUniform("u_camera_front", camera._front);
	shader.setUniform("u_camera_position", camera.eye);
	shader.setUniform("u_lineWidth", lineWidth * 0.5);
	shader.setUniform("u_camera_perspective", camera._projection_matrix[5]);
	shader.setUniform("u_viewport", gl.viewport_data);
	shader.setUniform("u_viewprojection", camera._viewprojection_matrix);
	shader.drawRange(mesh, gl.TRIANGLES, 0, num_points * (strip ? 6 : 3));

	return mesh;
}

RD.lines_vs_shader = "\n\
precision highp float;\n\
attribute vec3 a_vertex;\n\
attribute vec3 a_normal;\n\
attribute vec2 a_extra2;\n\
uniform mat4 u_model;\n\
uniform mat4 u_viewprojection;\n\
uniform vec4 u_viewport;\n\
uniform vec3 u_camera_front;\n\
uniform vec3 u_camera_position;\n\
uniform float u_camera_perspective;\n\
uniform float u_lineWidth;\n\
\n\
float computePointSize( float radius, float w )\n\
{\n\
	if(radius < 0.0)\n\
		return -radius;\n\
	return u_viewport.w * u_camera_perspective * radius / w;\n\
}\n\
void main() {\n\
	vec3 T = normalize( (u_model * vec4(a_normal,0.0)).xyz );\n\
	vec3 pos = (u_model * vec4(a_vertex,1.0)).xyz;\n\
	vec3 pos2 = (u_model * vec4(a_vertex + T,1.0)).xyz;\n\
	vec3 front = u_camera_front;//normalize( a_vertex - u_camera_position );\n\
	T = normalize( pos2 - pos ) ;\n\
	//float proj_w = (u_viewprojection * vec4(a_vertex,1.0)).w;\n\
	//float fixed_size_factor = computePointSize( u_lineWidth, proj_w );\n\
	vec3 side = normalize( cross(T,front) * a_extra2.x ) * u_lineWidth;\n\
	pos += side;\n\
	gl_Position = u_viewprojection * vec4(pos,1.0);\n\
}\n\
";

/**
 * Returns the path appending the folder where assets are located
 * @method getAssetFullPath
 * @param {String} name name (and url) of the mesh
 * @return {String} full path
 */
Renderer.prototype.getAssetFullPath = function (url, skip_assets_folder) {
	if (url.indexOf("://") != -1 || skip_assets_folder)
		return url;

	if (!this.assets_folder)
		return url;
	else if (this.onGetAssetsFolder)
		return this.onGetAssetFolder(url);
	else {
		var hasSlashA = this.assets_folder.substr(-1) == "/";
		var hasSlashB = url[0] == "/";
		if (hasSlashA != hasSlashB)
			return this.assets_folder + url;
		else if (hasSlashA && hasSlashB)
			return this.assets_folder + url.substr(1);
		else
			return this.assets_folder + "/" + url;
	}
	console.warn("this path should never be executed...");
	return url;
}

/**
 * Loads one mesh and stores inside the meshes object to be reused in the future, if it is already loaded it skips the loading
 * @method loadMesh
 * @param {String} name name (and url) of the mesh
 * @param {Function} on_complete callback
 */
Renderer.prototype.loadMesh = function (url, on_complete, skip_assets_folder) {
	if (!url)
		return console.error("loadMesh: Cannot load null name");

	if (this.assets_loading[url] || this.assets_not_found[url])
		return;

	var name = url;

	//check if we have it
	var mesh = this.meshes[name];
	if (mesh) {
		if (on_complete)
			on_complete(mesh);
		return mesh;
	}

	var that = this;

	//load it
	var full_url = this.getAssetFullPath(url, skip_assets_folder);

	var new_mesh = GL.Mesh.fromURL(full_url, function (m) {
		if (!m) {
			that.assets_not_found[url] = true;
			delete that.meshes[url];
		} else
			that.meshes[name] = m;
		that.num_assets_loading--;
		delete that.assets_loading[url];
		if (on_complete)
			on_complete(m, url);
	});

	this.assets_loading[url] = new_mesh;
	this.num_assets_loading++;

	this.meshes[name] = new_mesh; //temporary mesh
	return new_mesh;
}

/**
 * Loads one texture and stores inside the textures object to be reused in the future, if it is already loaded it skips the loading
 * @method loadTexture
 * @param {String} name name (and url) of the texture
 * @param {Object} options texture options as in litegl (option.name is used to store it with a different name)
 * @param {Function} on_complete callback
 */
Renderer.prototype.loadTexture = function (url, options, on_complete, skip_assets_folder) {
	if (!url)
		return console.error("loadTexture: Cannot load null name");

	if (this.assets_loading[url] || this.assets_not_found[url])
		return;

	var name = url;
	if (options) {
		if (options.name)
			name = options.name;
		if (options.preview)
			name = options.preview;
	}

	//check if we have it
	var tex = this.textures[name];
	if (tex && !tex.is_preview) {
		if (on_complete)
			on_complete(tex);
		return tex;
	}

	var that = this;

	//load it
	var full_url = this.getAssetFullPath(url, skip_assets_folder);
	var new_tex = null;

	if (url.indexOf("CUBEMAP") != -1)
		new_tex = GL.Texture.cubemapFromURL(full_url, this.default_cubemap_settings, inner_callback);
	else if (this.credentials) //hack for CORS
	{
		if (this.credentials.headers)
			this.credentials.headers["Content-Type"] = "application/octet-stream";
		else
			this.credentials.headers = { "Content-Type": "application/octet-stream" };

		new_tex = new GL.Texture(1, 1, options);

		fetch(full_url, this.credentials).then(function (response) {
			if (!response.ok)
				throw new Error("HTTP " + response.status + ":" + response.statusText);
			return response.arrayBuffer();
		}).then(function (buffer) {
			var image_local_url = URL.createObjectURL(new Blob([ buffer ])); //,{ type : mimeType }
			options.texture = new_tex;
			new_tex = GL.Texture.fromURL(image_local_url, options, inner_callback);
			options.texture = null;
			setTimeout(function () {
				URL.revokeObjectURL(image_local_url);
			}, 60 * 1000);
		});
	} else
		new_tex = GL.Texture.fromURL(full_url, options, inner_callback);

	function inner_callback(t) {
		if (that.debug)
			console.log(" + texture loaded: " + url);
		if (!t)
			that.assets_not_found[url] = true;
		else
			that.textures[name] = t;
		if (on_complete)
			on_complete(t, name);
		that.num_assets_loading--;
		delete that.assets_loading[url];
		if (that.on_texture_load)
			that.on_texture_load(t, name);
	}

	if (options && options.preview)
		new_tex.is_preview = true;

	this.assets_loading[url] = new_tex;
	this.num_assets_loading++;

	this.textures[name] = new_tex;
	return new_tex;
}

Renderer.prototype.loadTextureAtlas = function (data, url, on_complete) {
	if (typeof (data) == "string")
		data = JSON.parse(data);
	var that = this;

	if (url.indexOf("://") == -1)
		url = this.assets_folder + url;

	var atlas = GL.Texture.fromURL(url, null, function (tex) {
		var files = data.files;
		that.textures[":atlas"] = tex;
		for (var i in files) {
			//do not overwrite textures
			if (that.textures[i] && !that.textures[i].is_preview)
				continue;
			var file = files[i];
			var mini_tex = new GL.Texture(data.size, data.size, { wrap: gl.REPEAT, filter: gl.LINEAR });
			mini_tex.drawTo(function () {
				tex.gl.drawTexture(tex, 0, 0, data.size, data.size, file.x, file.y, file.width || data.size, file.height || data.size);
			});
			mini_tex.is_preview = true;
			//save preview
			that.textures[i] = mini_tex;
		}

		if (on_complete)
			on_complete(files);
	});
}

/**
 * Loads a shaders file in the Atlas file format (check GL.loadFileAtlas in litegl)
 * @method loadShaders
 * @param {String} url url to text file containing all the shader files
 * @param {Function} on_complete callback
 * @param {Object} extra_macros object containing macros that must be included in all
 */
Renderer.prototype.loadShaders = function (url, on_complete, extra_macros, skip_assets_folder) {
	if (url.indexOf("://") == -1 && !skip_assets_folder)
		url = this.assets_folder + url;

	url += "?nocache=" + Math.random();
	this.loading_shaders = true;

	//load shaders code from a files atlas
	loadFileAtlas(url, (files) => {
		this.compileShadersFromAtlas(files, extra_macros);
		this.loading_shaders = false;
		if (on_complete) {
			on_complete(files);
		}
	});
}

//reloads last shaders
Renderer.prototype.reloadShaders = function (extra_macros) {
}

/**
 * Compiles shaders from Atlas file format (check GL.loadFileAtlas in litegl)
 * @method compileShadersFromAtlasCode
 * @param {String} shaders_code big text file containing the shaders in shader atlas format
 * @param {Object} extra_macros object containing macros that must be included in all
 */
Renderer.prototype.compileShadersFromAtlasCode = function (shaders_code, extra_macros) {
	var subfiles = processFileAtlas(shaders_code);
	this.compileShadersFromAtlas(subfiles, extra_macros);
}

//takes several subfiles (strings) and process them to compile shaders
Renderer.prototype.compileShadersFromAtlas = function (files, extra_macros) {
	var info = files["shaders"];
	if (!info) {
		console.warn("No 'shaders' found in shaders file atlas, check documentation");
		return;
	}

	this.shader_files = files;

	//expand #imports "..."
	for (var i in files)
		files[i] = GL.Shader.expandImports(files[i], files);

	//compile shaders
	var lines = info.split("\n");
	for (var i = 0; i < lines.length; ++i) {
		var line = lines[i];
		var t = line.trim().split(" ");
		var name = t[0].trim();
		if (name.substr(0, 2) == "//")
			continue;
		var vs = files[t[1]];
		var fs = files[t[2]];
		var macros = null;
		var flags = {};

		//parse extras
		if (t.length > 3) {
			for (var j = 3; j < t.length; ++j) {
				if (t[j][0] == "#")
					flags[t[j].substr(1)] = true;
				else {
					macros = t.slice(j).join(" ");
					break;
				}
			}
		}

		if (flags.WEBGL1 && gl.webgl_version != 1)
			continue;
		if (flags.WEBGL2 && gl.webgl_version != 2)
			continue;

		if (t[1] && t[1][0] == "@") {
			var pseudoname = t[1].substr(1) + "_VERTEX_SHADER";
			if (GL.Shader[pseudoname])
				vs = GL.Shader[pseudoname];
		}
		if (t[2] && t[2][0] == "@") {
			var pseudoname = t[2].substr(1) + "_FRAGMENT_SHADER";
			if (GL.Shader[pseudoname])
				fs = GL.Shader[pseudoname];
		}

		if (macros) {
			try {
				macros = JSON.parse(macros);
			} catch (err) {
				console.error("Error in shader macros: ", name, macros, err);
			}
		}

		if (macros && extra_macros) {
			var final_macros = {};
			for (var k in macros)
				final_macros[k] = macros[k];
			for (var k in extra_macros)
				final_macros[k] = extra_macros[k];
			macros = final_macros;
		} else if (extra_macros)
			macros = extra_macros;

		//console.log("compiling: ",name,macros);

		try {
			if (!vs || !fs) {
				console.warn("Shader subfile not found: ", t[1], t[2]);
				continue;
			}

			if (this.shaders[name])
				this.shaders[name].updateShader(vs, fs, macros);
			else
				this.shaders[name] = new GL.Shader(vs, fs, macros);
		} catch (err) {
			GL.Shader.dumpErrorToConsole(err, vs, fs);
		}
	}
}

Renderer.prototype.setShadersFromFile = function (file_data) {
	var files = processFileAtlas(file_data);
	this.compileShadersFromAtlas(files);
}

Renderer.cubemap_info = [
	{ front: [ 1.0, 0.0, 0.0 ], up: [ 0.0, -1.0, 0.0 ] }, //POSX
	{ front: [ -1.0, 0.0, 0.0 ], up: [ 0.0, -1.0, 0.0 ] }, //NEGX
	{ front: [ 0.0, 1.0, 0.0 ], up: [ 0.0, 0.0, 1.0 ] }, //POSY
	{ front: [ 0.0, -1.0, 0.0 ], up: [ 0.0, 0.0, -1.0 ] }, //NEGY
	{ front: [ 0.0, 0.0, 1.0 ], up: [ 0.0, -1.0, 0.0 ] }, //POSZ
	{ front: [ 0.0, 0.0, -1.0 ], up: [ 0.0, -1.0, 0.0 ] } //NEGZ
];

Renderer.prototype.renderToCubemap = function (cubemap, scene, position, nodes, layers, near, far) {
	var camera = Renderer.cubemap_camera;
	if (!camera)
		Renderer.cubemap_camera = camera = new RD.Camera();

	near = near || 0.1;
	far = far || 1000;

	camera.perspective(90, 1, near, far);
	var front = vec3.create();
	var that = this;

	cubemap.drawTo(function (tex, i) {
		var side = Renderer.cubemap_info[i];
		vec3.add(front, position, side.front);
		camera.lookAt(position, front, side.up);
		that.clear();
		that.render(scene, camera, nodes, layers);
	});

	return cubemap;
}

Renderer.prototype.drawSphere3D = function (pos, radius, color) {
	if (!gl.meshes["sphere"])
		gl.meshes["sphere"] = GL.Mesh.sphere({ slices: 32 });
	var shader = gl.shaders["flat"];
	shader.setUniform("u_color", color);
	shader.setUniform("u_viewprojection", this._viewprojection_matrix);
	var m = temp_mat4;
	mat4.identity(m);
	mat4.translate(m, m, pos);
	mat4.scale(m, m, [ radius, radius, radius ]);
	shader.setUniform("u_model", m);
	shader.draw(gl.meshes["sphere"]);
	this.draw_calls += 1;
}


Renderer.prototype.drawCircle2D = function (x, y, radius, color, fill) {
	if (!gl.meshes["circle"])
		gl.meshes["circle"] = GL.Mesh.circle({ radius: 1, slices: 32 });
	if (!gl.meshes["ring"])
		gl.meshes["ring"] = GL.Mesh.ring({ radius: 1, thickness: 0.02, slices: 64 });
	var shader = gl.shaders["flat"];
	shader.setUniform("u_color", color);
	shader.setUniform("u_viewprojection", this._viewprojection2D_matrix);
	var m = temp_mat4;
	mat4.identity(m);
	mat4.translate(m, m, [ x, y, 0 ]);
	mat4.scale(m, m, [ radius * 2, radius * 2, radius * 2 ]);
	shader.setUniform("u_model", m);
	shader.draw(gl.meshes[fill ? "circle" : "ring"]);
	this.draw_calls += 1;
}

Renderer.prototype.drawLine2D = function (x, y, x2, y2, width, color, shader) {
	var mesh = gl.meshes["plane"];
	shader = shader || gl.shaders["flat"];
	shader.setUniform("u_color", color);
	shader.setUniform("u_viewprojection", this._viewprojection2D_matrix);
	var m = temp_mat4;
	var dx = x2 - x;
	var dy = y2 - y;
	var angle = Math.atan2(dx, dy);
	var dist = Math.sqrt(dx * dx + dy * dy);
	width /= 2;
	mat4.identity(m);
	mat4.translate(m, m, [ (x + x2) * 0.5, (y + y2) * 0.5, 0 ]);
	mat4.rotate(m, m, angle, Direction3Constants.FRONT);
	var f = width;
	mat4.scale(m, m, [ f, dist, f ]);
	shader.setUniform("u_model", m);
	shader.draw(mesh);
	this.draw_calls += 1;
}

Renderer.prototype.renderDebugSceneTree = function (scene, camera) {
	var points = [];
	for (var i = 0; i < scene._nodes.length; ++i) {
		var node = scene._nodes[i];
		if (!node._parent || node._parent == scene.root)
			continue;
		var parent_pos = node._parent.getGlobalPosition();
		var pos = node.getGlobalPosition();
		points.push(parent_pos[0], parent_pos[1], parent_pos[2], pos[0], pos[1], pos[2]);
	}
	points = new Float32Array(points);
	this.renderPoints(points, null, camera, null, null, null, GL.LINES);
	this.renderPoints(points, null, camera, null, null, -10, GL.POINTS);
}


RD.sortByDistance = function (nodes, position) {
	nodes.forEach(function (a) {
		a._distance = a.getDistanceTo(position);
	});
	nodes.sort(function (a, b) {
		return b._distance - a._distance;
	});
}

RD.noBlending = function (n) {
	return n.blend_mode === RD.BLEND_NONE;
}


RD.generateTextureAtlas = function (textures, width, height, item_size, avoid_repetitions) {
	width = width || 1024;
	height = height || 1024;
	item_size = item_size || 64;
	var count = 0;
	for (var i in textures)
		count++;

	gl.disable(gl.DEPTH_TEST);
	gl.disable(gl.CULL_FACE);
	gl.disable(gl.BLEND);

	var atlas = new GL.Texture(width, height);
	var atlas_info = { width: width, height: height, size: item_size, files: {} };
	var posx = 0, posy = 0;
	var hashes = {};

	atlas.drawTo(function () {
		for (var i in textures) {
			if (i[0] == ":" || i == "white" || i == "black" || i == "notfound")
				continue;
			var tex = textures[i];
			if (tex.is_preview)
				continue;
			if (tex.texture_type != WebGLRenderingContext.TEXTURE_2D)
				continue;

			if (avoid_repetitions) {
				var hash = tex.toBase64().hashCode();
				if (hashes[hash]) {
					atlas_info.files[i] = atlas_info.files[hashes[hash]];
					continue;
				}
				hashes[hash] = i;
			}

			atlas_info.files[i] = { x: posx, y: posy };
			tex.renderQuad(posx, posy, item_size, item_size);
			posx += item_size;
			if (posx == width) {
				posx = 0;
				posy += item_size;
				if (posy == height) {
					console.warn("Atlas too small, some textures wont be stored.");
					return;
				}
			}
		}
	});

	atlas.info = atlas_info;
	console.log(atlas_info);
	return atlas;
}

//returns num of resources fully loaded from a list
Renderer.prototype.computeResourcesLoaded = function (list) {
	var num = 0;
	for (var i in list) {
		var name = list[i];
		var tex = this.textures[name];
		if (tex && tex.ready === false)
			continue;

		var mesh = this.meshes[name];
		if (mesh && mesh.ready === false)
			continue;

		if (tex || mesh)
			num++;
	}
	return num;
}

/**
 * container with all the registered meshes (same as gl.meshes)
 * @property meshes {Object}
 */
Object.defineProperty(Renderer.prototype, "meshes", {
	get: function () {
		return this.gl.meshes;
	},
	set: function (v) {
	},
	enumerable: true
});

/**
 * container with all the registered textures (same as gl.textures)
 * @property textures {Object}
 */
Object.defineProperty(Renderer.prototype, "textures", {
	get: function () {
		return this.gl.textures;
	},
	set: function (v) {
	},
	enumerable: true
});

/**
 * container with all the registered shaders (same as gl.shaders)
 * @property shaders {Object}
 */
Object.defineProperty(Renderer.prototype, "shaders", {
	get: function () {
		return this.gl.shaders;
	},
	set: function (v) {
	},
	enumerable: true
});


Renderer.prototype.addMesh = function (name, mesh) {
	if (mesh.gl != this.gl)
		mesh = mesh.cloneShared(this.gl);
	this.gl.meshes[name] = mesh;
}


RD.Renderer = Renderer;


RD.Factory = function Factory(name, parent, extra_options) {
	var tpl = RD.Factory.templates[name];
	var node = new SceneNode();
	if (tpl)
		node.configure(tpl);
	if (parent)
		(parent.constructor === RD.Scene ? parent.root : parent).addChild(node);
	if (extra_options)
		node.configure(extra_options);
	return node;
}

RD.Factory.templates = {
	grid: { mesh: "grid", primitive: 1, color: [ 0.5, 0.5, 0.5, 0.5 ], blend_mode: RD.BLEND_ALPHA },
	mesh: { shader: "phong" },
	sphere: { mesh: "sphere", shader: "phong" },
	floor: { mesh: "planeXZ", scaling: 10, shader: "phong" }
};

//**other useful classes

//This node allows to render a mesh where vertices are changing constantly
function DynamicMeshNode(o) {
	this._ctor();
	if (o)
		this.configure(o);
}

DynamicMeshNode.prototype._ctor = function () {
	SceneNode.prototype._ctor.call(this);

	this.vertices = [];
	this.normals = [];
	this.coords = [];
	this.indices = [];

	var size = 1024;
	this._vertices_data = new Float32Array(size * 3);
	this._normals_data = null;
	this._coords_data = null;
	this._indices_data = null;
	this._total = 0;
	this._total_indices = 0;
	this._mesh = GL.Mesh.load({ vertices: this._vertices_data });
}

DynamicMeshNode.prototype.updateVertices = function (vertices) {
	if (vertices)
		this.vertices = vertices;
	this._total = this.vertices.length;
	if (this._vertices_data.length < this.vertices.length) {
		this._vertices_data = new Float32Array(this.vertices.length * 2);
		this._mesh.getBuffer("vertices").data = this._vertices_data;
	}
	this._vertices_data.set(this.vertices);
	this._mesh.getBuffer("vertices").upload(GL.STREAM_DRAW);
}

DynamicMeshNode.prototype.updateNormals = function (normals) {
	if (normals)
		this.normals = normals;
	if (!this._normals_data || this._normals_data.length < this.normals.length) {
		this._normals_data = new Float32Array(this.normals.length * 2);
		var buffer = this._mesh.getBuffer("normals");
		if (!buffer)
			this._mesh.createVertexBuffer("normals", null, 3, this._normals_data, GL.STREAM_DRAW);
	}
	this._normals_data.set(this.normals);
	this._mesh.getBuffer("normals").upload(GL.STREAM_DRAW);
}

DynamicMeshNode.prototype.updateCoords = function (coords) {
	if (coords)
		this.coords = coords;
	if (!this._coords_data || this._coords_data.length < this.normals.length) {
		this._coords_data = new Float32Array(this.coords.length * 2);
		var buffer = this._mesh.getBuffer("coords");
		if (!buffer)
			this._mesh.createVertexBuffer("coords", null, 2, this._coords_data, GL.STREAM_DRAW);
	}
	this._coords_data.set(this.coords);
	this._mesh.getBuffer("coords").upload(GL.STREAM_DRAW);
}

DynamicMeshNode.prototype.updateIndices = function (indices) {
	if (indices)
		this.indices = indices;
	if (!this._indices_data || this._indices_data.length < this.indices.length) {
		this._indices_data = new Float32Array(this.indices.length * 2);
		var buffer = this._mesh.getIndexBuffer("triangles");
		if (!buffer)
			this._mesh.createIndicesBuffer("triangles", this._indices_data, GL.STREAM_DRAW);
	}
	this._indices_data.set(this.indices);
	this._mesh.getIndexBuffer("triangles").upload(GL.STREAM_DRAW);
	this._total_indices = indices.length;
}

DynamicMeshNode.prototype.render = function (renderer, camera) {
	if (!this._total)
		return;
	var shader = renderer.shaders[this.shader || "flat"];
	if (!shader)
		return;
	renderer.setModelMatrix(this._global_matrix);
	var mesh = this._mesh;
	var range = this._total_indices ? this._total_indices : this._total / 3;
	renderer.enableItemFlags(this);
	shader.uniforms(renderer._uniforms).uniforms(this._uniforms).drawRange(mesh, this.primitive === undefined ? GL.TRIANGLES : this.primitive, 0, range, this._total_indices ? "triangles" : null);
	renderer.disableItemFlags(this);
}

extendClass(DynamicMeshNode, SceneNode);
RD.DynamicMeshNode = DynamicMeshNode;


/**
 * Sprite class , inherits from SceneNode but helps to render 2D planes (in 3D Space)
 * @class Sprite
 * @constructor
 */
function Sprite(o) {
	this._ctor();
	if (o)
		this.configure(o);
}

Sprite.prototype._ctor = function () {
	SceneNode.prototype._ctor.call(this);

	this.mesh = "plane";
	this.size = vec2.fromValues(0, 0); //size of the
	this.sprite_pivot = RD.TOP_LEFT;
	this.blend_mode = RD.BLEND_ALPHA;
	this.flags.two_sided = true;
	this.flags.flipX = false;
	this.flags.flipY = false;
	this.flags.pixelated = false;
	//this.flags.depth_test = false;
	this.shader = "texture_transform";
	this._angle = 0;

	this.frame = null;
	this.frames = {};
	this.texture_matrix = mat3.create();

	this._uniforms["u_texture_matrix"] = this.texture_matrix;
}

Object.defineProperty(Sprite.prototype, "angle", {
	get: function () {
		return this._angle;
	},
	set: function (v) {
		this._angle = v;
		quat.setAxisAngle(this._rotation, Direction3Constants.FRONT, this._angle * DEG2RAD);
		this._must_update_matrix = true;
	},
	enumerable: true //avoid problems
});

Sprite.prototype.setSize = function (w, h) {
	this.size[0] = w;
	this.size[1] = h;
}

//static version
//num is the number of elements per row and column, if array then [columns,rows]
Sprite.createFrames = function (num, names, frames) {
	frames = frames || {};
	var num_rows;
	var num_colums;
	if (num.constructor != Number) {
		num_columns = num[0];
		num_rows = num[1];
	} else
		num_rows = num_columns = num;

	var x = 0;
	var y = 0;
	var offsetx = 1 / num_columns;
	var offsety = 1 / num_rows;
	var total = num_columns * num_rows;

	if (!names) {
		names = [];
		for (var i = 0; i < total; ++i)
			names.push(String(i));
	}

	for (var i = 0; i < names.length; ++i) {
		frames[names[i]] = { pos: [ x, y ], size: [ offsetx, offsety ], normalized: true };
		x += offsetx;
		if (x >= 1) {
			x = 0;
			y += offsety;
		}
		if (y >= 1)
			return frames;
	}
	return frames;
}

Sprite.prototype.createFrames = function (num, names) {
	Sprite.createFrames(num, names, this.frames);
}

Sprite.prototype.addFrame = function (name, x, y, w, h, normalized) {
	this.frames[name] = { pos: vec2.fromValues(x, y), size: vec2.fromValues(w, h), normalized: !!normalized };
}

Sprite.prototype.updateTextureMatrix = function (renderer) {
	mat3.identity(this.texture_matrix);
	//no texture
	if (!this.texture)
		return false;

	var texture = renderer.textures[this.texture];
	if (!texture && renderer.autoload_assets) {
		var that = this;
		if (this.texture.indexOf(".") != -1)
			renderer.loadTexture(this.texture, renderer.default_texture_settings, function (tex) {
				if (tex && that.size[0] == 0 && that.size[0] == 0)
					that.setSize(tex.width, tex.height);
			});
		texture = gl.textures["white"];
	}
	if (!texture) //texture not found
		return false;

	//adapt texture matrix
	var matrix = this.texture_matrix;

	var frame = this.current_frame = this.frames[this.frame];

	//frame not found
	if (this.frame !== null && !frame)
		return false;

	if (!frame) {
		if (this.flags.flipX) {
			temp_vec2[0] = this.flags.flipX ? 1 : 0;
			temp_vec2[1] = 0;
			mat3.translate(matrix, matrix, temp_vec2);
			temp_vec2[0] = (this.flags.flipX ? -1 : 1);
			temp_vec2[1] = 1;
			mat3.scale(matrix, matrix, temp_vec2);
		}
		return true;
	}

	if (frame.normalized) {
		temp_vec2[0] = this.flags.flipX ? frame.pos[0] + frame.size[0] : frame.pos[0];
		temp_vec2[1] = 1 - frame.pos[1] - frame.size[1];
		mat3.translate(matrix, matrix, temp_vec2);
		temp_vec2[0] = frame.size[0] * (this.flags.flipX ? -1 : 1);
		temp_vec2[1] = frame.size[1];
		mat3.scale(matrix, matrix, temp_vec2);
	} else {
		var tw = texture.width;
		var th = texture.height;
		temp_vec2[0] = (this.flags.flipX ? frame.pos[0] + frame.size[0] : frame.pos[0]) / tw;
		temp_vec2[1] = (th - frame.pos[1] - frame.size[1]) / th;
		mat3.translate(matrix, matrix, temp_vec2);
		temp_vec2[0] = (frame.size[0] * (this.flags.flipX ? -1 : 1)) / texture.width;
		temp_vec2[1] = frame.size[1] / texture.height;
		mat3.scale(matrix, matrix, temp_vec2);
	}

	return true;
}

Sprite.prototype.render = function (renderer, camera) {
	if (!this.texture)
		return;

	//this autoloads
	if (!this.updateTextureMatrix(renderer)) //texture or frame not found
		return;

	var tex = renderer.textures[this.texture];
	if (!tex)
		return;

	if (this.flags.pixelated) {
		tex.bind(0);
		gl.texParameteri(WebGLRenderingContext.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, this.flags.pixelated ? gl.NEAREST : gl.LINEAR);
		gl.texParameteri(WebGLRenderingContext.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, this.flags.pixelated ? gl.NEAREST_MIPMAP_NEAREST : gl.LINEAR_MIPMAP_LINEAR);
	}

	if (this.billboard_mode)
		RD.orientNodeToCamera(this.billboard_mode, this, camera, renderer);
	if (this.size[0] == 0 && tex.ready !== false)
		this.size[0] = tex.width;
	if (this.size[1] == 0 && tex.ready !== false)
		this.size[1] = tex.height;
	var size = this.size;
	var offsetx = 0;
	var offsety = 0;
	temp_mat4.set(this._global_matrix);

	var normalized_size = false;
	if (this.current_frame && this.current_frame.size) {
		size = this.current_frame.size;
		normalized_size = this.current_frame.normalized;
	}

	if (this.sprite_pivot) {
		switch (this.sprite_pivot) {
		case RD.TOP_LEFT:
			offsetx = 0.5;
			offsety = -0.5;
			break;
		case RD.TOP_CENTER:
			offsety = -0.5;
			break;
		case RD.TOP_RIGHT:
			offsetx = -0.5;
			break;
		case RD.BOTTOM_LEFT:
			offsetx = 0.5;
			offsety = 0.5;
			break;
		case RD.BOTTOM_CENTER:
			offsety = 0.5;
			break;
		case RD.BOTTOM_RIGHT:
			offsetx = -0.5;
			offsety = 0.5;
			break;
		}
		mat4.translate(temp_mat4, temp_mat4, [ offsetx * tex.width * size[0], offsety * tex.height * size[1], 0 ]);
	}

	if (normalized_size)
		mat4.scale(temp_mat4, temp_mat4, [ tex.width * size[0], tex.height * size[1], 1 ]);
	else
		mat4.scale(temp_mat4, temp_mat4, [ this.size[0], this.size[1], 1 ]);
	renderer.setModelMatrix(temp_mat4);

	renderer.renderNode(this, renderer, camera);
}

extendClass(Sprite, SceneNode);
RD.Sprite = Sprite;


function Skybox(o) {
	SceneNode.prototype._ctor.call(this, o);
	this._ctor();
	if (o)
		this.configure(o);
}

Skybox.prototype._ctor = function () {
	this.mesh = "cube";
	this.shader = "skybox";
	this.scaling = [ 10, 10, 10 ];
	this.flags.depth_test = false;
	this.flags.two_sided = true;
}

Skybox.prototype.render = function (renderer, camera) {
	this.position = camera.position;
	this.updateGlobalMatrix(true);
	renderer.setModelMatrix(this._global_matrix);
	renderer.renderNode(this, camera);
}

extendClass(Skybox, SceneNode);
RD.Skybox = Skybox;

RD.parseTextConfig = parseTextConfig;

//NOT USED IN ROOM, use createShadersLite instead...
Renderer.prototype.createShaders = function () {
	if (gl._shaders_created) {
		this._flat_shader = gl.shaders["flat"];
		this._flat_instancing_shader = gl.shaders["flat_instancing"];
		this._flat_skinning_shader = gl.shaders["flat_skinning"];
		this._point_shader = gl.shaders["point"];
		this._color_shader = gl.shaders["color"];
		this._texture_shader = gl.shaders["texture"];
		this._texture_albedo_shader = gl.shaders["texture_albedo"];
		this._texture_instancing_shader = gl.shaders["texture_instancing"];
		this._texture_albedo_instancing_shader = gl.shaders["texture_albedo_instancing"];

		if (RD.Skeleton)
			this._texture_skinning_shader = gl.shaders["texture_skinning"];

		this._texture_transform_shader = gl.shaders["texture_transform"];

		//basic phong shader
		this._phong_uniforms = {
			u_ambient: vec3.create(),
			u_light_vector: vec3.fromValues(0.5442, 0.6385, 0.544),
			u_light_color: RD.WHITE
		};
		this._phong_shader = gl.shaders["phong"];
		this._phong_shader._uniforms = this._phong_uniforms;
		this._phong_shader.uniforms(this._phong_uniforms);

		this._phong_instancing_shader = gl.shaders["phong_instancing"];
		this._phong_instancing_shader._uniforms = this._phong_uniforms;
		this._phong_instancing_shader.uniforms(this._phong_uniforms);

		this._textured_phong_shader = gl.shaders["textured_phong"];
		this._textured_phong_shader.uniforms(this._phong_uniforms);

		this._textured_phong_instancing_shader = gl.shaders["textured_phong_instancing"];
		this._textured_phong_instancing_shader.uniforms(this._phong_uniforms);
		this._normal_shader = gl.shaders["normal"];
		this._uvs_shader = gl.shaders["uvs"];
	}

	//adds code for skinning
	var skinning = "";
	var skinning_vs = "";
	if (RD.Skeleton) {
		skinning = "\n\
		#ifdef SKINNING\n\
			" + RD.Skeleton.shader_code + "\n\
		#endif\n\
		";
		skinning_vs = "\n\
		#ifdef SKINNING\n\
			computeSkinning(v_pos,v_normal);\n\
		#endif\n\
		";
	}

	var vertex_shader = this._vertex_shader = "\
				precision highp float;\n\
				attribute vec3 a_vertex;\n\
				attribute vec3 a_normal;\n\
				attribute vec2 a_coord;\n\
				varying vec3 v_pos;\n\
				varying vec3 v_normal;\n\
				varying vec2 v_coord;\n\
				" + skinning + "\n\
				#ifdef INSTANCING\n\
					attribute mat4 u_model;\n\
				#else\n\
					uniform mat4 u_model;\n\
				#endif\n\
				uniform mat4 u_viewprojection;\n\
				void main() {\n\
					v_pos = a_vertex;\n\
					v_normal = a_normal;\n\
					" + skinning_vs + "\n\
					v_pos = (u_model * vec4(v_pos,1.0)).xyz;\n\
					v_normal = (u_model * vec4(v_normal,0.0)).xyz;\n\
					v_coord = a_coord;\n\
					gl_Position = u_viewprojection * vec4( v_pos, 1.0 );\n\
					gl_PointSize = 2.0;\n\
				}\
				";

	var fragment_shader = this._flat_fragment_shader = "\
				precision highp float;\
				uniform vec4 u_color;\
				void main() {\
				  gl_FragColor = u_color;\
				}\
	";

	gl.shaders["flat"] = this._flat_shader = new GL.Shader(vertex_shader, fragment_shader);
	gl.shaders["flat_instancing"] = this._flat_instancing_shader = new GL.Shader(vertex_shader, fragment_shader, { INSTANCING: "" });
	gl.shaders["flat_skinning"] = this._flat_skinning_shader = new GL.Shader(vertex_shader, fragment_shader, { SKINNING: "" });

	this._point_shader = new GL.Shader("\
				precision highp float;\
				attribute vec3 a_vertex;\
				uniform mat4 u_mvp;\
				uniform float u_pointSize;\
				void main() {\
					gl_PointSize = u_pointSize;\
					gl_Position = u_mvp * vec4(a_vertex,1.0);\
				}\
				", "\
				precision highp float;\
				uniform vec4 u_color;\
				void main() {\
				  if( distance( gl_PointCoord, vec2(0.5)) > 0.5)\
				     discard;\
				  gl_FragColor = u_color;\
				}\
			");
	gl.shaders["point"] = this._point_shader;

	this._color_shader = new GL.Shader("\
		precision highp float;\
		attribute vec3 a_vertex;\
		attribute vec4 a_color;\
		varying vec4 v_color;\
		uniform vec4 u_color;\
		uniform mat4 u_mvp;\
		void main() {\
			v_color = a_color * u_color;\
			gl_Position = u_mvp * vec4(a_vertex,1.0);\
			gl_PointSize = 5.0;\
		}\
		", "\
		precision highp float;\
		varying vec4 v_color;\
		void main() {\
		  gl_FragColor = v_color;\
		}\
	");
	gl.shaders["color"] = this._color_shader;

	var fragment_shader = "\
		precision highp float;\
		varying vec2 v_coord;\
		uniform vec4 u_color;\n\
		#ifdef ALBEDO\n\
			uniform sampler2D u_albedo_texture;\n\
		#else\n\
			uniform sampler2D u_color_texture;\n\
		#endif\n\
		uniform float u_global_alpha_clip;\n\
		void main() {\n\
			#ifdef ALBEDO\n\
				vec4 color = u_color * texture2D(u_albedo_texture, v_coord);\n\
			#else\n\
				vec4 color = u_color * texture2D(u_color_texture, v_coord);\n\
			#endif\n\
			if(color.w <= u_global_alpha_clip)\n\
				discard;\n\
			gl_FragColor = color;\
		}\
	";

	gl.shaders["texture"] = this._texture_shader = new GL.Shader(vertex_shader, fragment_shader);
	gl.shaders["texture_albedo"] = this._texture_albedo_shader = new GL.Shader(vertex_shader, fragment_shader, { ALBEDO: "" });
	gl.shaders["texture_albedo_skinning"] = this._texture_albedo_skinning_shader = new GL.Shader(vertex_shader, fragment_shader, {
		SKINNING: "",
		ALBEDO: ""
	});
	gl.shaders["texture_instancing"] = this._texture_instancing_shader = new GL.Shader(vertex_shader, fragment_shader, { INSTANCING: "" });
	gl.shaders["texture_albedo_instancing"] = this._texture_albedo_instancing_shader = new GL.Shader(vertex_shader, fragment_shader, {
		ALBEDO: "",
		INSTANCING: ""
	});

	if (RD.Skeleton)
		gl.shaders["texture_skinning"] = this._texture_skinning_shader = new GL.Shader(vertex_shader, fragment_shader, { SKINNING: "" });

	this._texture_transform_shader = new GL.Shader("\
		precision highp float;\n\
		attribute vec3 a_vertex;\n\
		attribute vec2 a_coord;\n\
		varying vec2 v_coord;\n\
		uniform mat4 u_mvp;\n\
		uniform mat3 u_texture_matrix;\n\
		void main() {\n\
			v_coord = (u_texture_matrix * vec3(a_coord,1.0)).xy;\n\
			gl_Position = u_mvp * vec4(a_vertex,1.0);\n\
			gl_PointSize = 5.0;\n\
		}\n\
		", "\n\
		precision highp float;\n\
		varying vec2 v_coord;\n\
		uniform vec4 u_color;\n\
		uniform float u_global_alpha_clip;\n\
		uniform sampler2D u_color_texture;\n\
		void main() {\n\
			vec4 color = u_color * texture2D(u_color_texture, v_coord);\n\
			if(color.w <= u_global_alpha_clip)\n\
				discard;\n\
			gl_FragColor = color;\n\
		}\
	");
	gl.shaders["texture_transform"] = this._texture_transform_shader;

	//basic phong shader
	this._phong_uniforms = {
		u_ambient: vec3.create(),
		u_light_vector: vec3.fromValues(0.5442, 0.6385, 0.544),
		u_light_color: RD.WHITE
	};

	var fragment_shader = this._fragment_shader = "\
			precision highp float;\n\
			varying vec3 v_normal;\n\
			varying vec2 v_coord;\n\
			uniform vec3 u_ambient;\n\
			uniform vec3 u_light_color;\n\
			uniform vec3 u_light_vector;\n\
			uniform vec4 u_color;\n\
			#ifdef TEXTURED\n\
				uniform sampler2D u_color_texture;\n\
			#endif\n\
			#ifdef UNIFORMS\n\
				UNIFORMS\n\
			#endif\n\
			void main() {\n\
				vec4 color = u_color;\n\
				#ifdef TEXTURED\n\
					color *= texture2D( u_color_texture, v_coord );\n\
				#endif\n\
				vec3 N = normalize(v_normal);\n\
				float NdotL = max(0.0, dot(u_light_vector,N));\n\
				#ifdef EXTRA\n\
					EXTRA\n\
				#endif\n\
				gl_FragColor = color * (vec4(u_ambient,1.0) + NdotL * vec4(u_light_color,1.0));\n\
			}\
	";

	gl.shaders["phong"] = this._phong_shader = new GL.Shader(vertex_shader, fragment_shader);
	this._phong_shader._uniforms = this._phong_uniforms;
	this._phong_shader.uniforms(this._phong_uniforms);

	gl.shaders["phong_instancing"] = this._phong_instancing_shader = new GL.Shader(vertex_shader, fragment_shader, { INSTANCING: "" });
	this._phong_instancing_shader._uniforms = this._phong_uniforms;
	this._phong_instancing_shader.uniforms(this._phong_uniforms);

	gl.shaders["textured_phong"] = this._textured_phong_shader = new GL.Shader(vertex_shader, fragment_shader, { TEXTURED: "" });
	this._textured_phong_shader.uniforms(this._phong_uniforms);

	gl.shaders["textured_phong_instancing"] = this._textured_phong_instancing_shader = new GL.Shader(vertex_shader, fragment_shader, {
		INSTANCING: "",
		TEXTURED: ""
	});
	this._textured_phong_instancing_shader.uniforms(this._phong_uniforms);

	var fragment_shader = "\
			precision highp float;\n\
			varying vec3 v_normal;\n\
			void main() {\n\
				gl_FragColor = vec4( normalize(v_normal),1.0);\n\
			}\
	";
	gl.shaders["normal"] = this._normal_shader = new GL.Shader(vertex_shader, fragment_shader);

	var fragment_shader = "\
			precision highp float;\n\
			varying vec2 v_coord;\n\
			void main() {\n\
				gl_FragColor = vec4(v_coord,0.0,1.0);\n\
			}\
	";
	gl.shaders["uvs"] = this._uvs_shader = new GL.Shader(vertex_shader, fragment_shader);

	gl._shaders_created = true;
}

Renderer.prototype.createShadersLite = function () {
	if (gl._shaders_created) {
		this._flat_shader = gl.shaders["flat"];
		this._texture_shader = gl.shaders["texture"];
	}

	var skinning = "";
	var skinning_vs = "";

	var vertex_shader = this._vertex_shader = "\
				precision highp float;\n\
				attribute vec3 a_vertex;\n\
				attribute vec3 a_normal;\n\
				attribute vec2 a_coord;\n\
				varying vec3 v_pos;\n\
				varying vec3 v_normal;\n\
				varying vec2 v_coord;\n\
				#ifdef INSTANCING\n\
					attribute mat4 u_model;\n\
				#else\n\
					uniform mat4 u_model;\n\
				#endif\n\
				uniform mat4 u_viewprojection;\n\
				uniform vec2 u_clipControl;\n\
				void main() {\n\
					v_pos = a_vertex;\n\
					v_normal = a_normal;\n\
					v_pos = (u_model * vec4(v_pos,1.0)).xyz;\n\
					v_normal = (u_model * vec4(v_normal,0.0)).xyz;\n\
					v_coord = a_coord;\n\
					gl_Position = u_viewprojection * vec4( v_pos, 1.0 );\n\
			    gl_Position.z = dot(gl_Position.zw, u_clipControl.xy);\n\
					gl_PointSize = 2.0;\n\
				}\
				";

	var fragment_shader_0 = this._flat_fragment_shader = "\
				precision highp float;\
				uniform vec4 u_color;\
				void main() {\
				  gl_FragColor = u_color;\
				}\
	";

	gl.shaders["flat"] = this._flat_shader = new GL.Shader(vertex_shader, fragment_shader_0);

	gl.shaders["color"] = this._color_shader = new GL.Shader("\
		precision highp float;\
		attribute vec3 a_vertex;\
		attribute vec4 a_color;\
		varying vec4 v_color;\
		uniform vec4 u_color;\
		uniform mat4 u_mvp;\
		void main() {\
			v_color = a_color * u_color;\
			gl_Position = u_mvp * vec4(a_vertex,1.0);\
			gl_PointSize = 5.0;\
		}\
		", "\
		precision highp float;\
		varying vec4 v_color;\
		void main() {\
		  gl_FragColor = v_color;\
		}\
	");

	var fragment_shader_1 = "\
		precision highp float;\
		varying vec2 v_coord;\
		uniform vec4 u_color;\n\
		#ifdef ALBEDO\n\
			uniform sampler2D u_albedo_texture;\n\
		#else\n\
			uniform sampler2D u_color_texture;\n\
		#endif\n\
		uniform float u_global_alpha_clip;\n\
		void main() {\n\
			#ifdef ALBEDO\n\
				vec4 color = u_color * texture2D(u_albedo_texture, v_coord);\n\
			#else\n\
				vec4 color = u_color * texture2D(u_color_texture, v_coord);\n\
			#endif\n\
			if(color.w <= u_global_alpha_clip)\n\
				discard;\n\
			gl_FragColor = color;\
		}\
	";

	gl.shaders["texture"] = this._texture_shader = new GL.Shader(vertex_shader, fragment_shader_1);

	var clip = vec2.fromValues(2, -1);
	gl.shaders["flat"].setUniform("u_clipControl", clip);
	gl.shaders["texture"].setUniform("u_clipControl", clip);

	gl._shaders_created = true;
}

//****************************

RD.orientNodeToCamera = function (mode, node, camera, renderer) {
	if (!mode)
		return;

	if (mode == RD.BILLBOARD_CYLINDRIC || mode == RD.BILLBOARD_PARALLEL_CYLINDRIC) {
		var global_pos = null;
		if (mode == RD.BILLBOARD_CYLINDRIC) {
			global_pos = node.getGlobalPosition(temp_vec3b);
			vec3.sub(temp_vec3, camera._position, global_pos);
			temp_vec2[0] = temp_vec3[0];
			temp_vec2[1] = temp_vec3[2];
		} else //BILLBOARD_PARALLEL_CYLINDRIC
		{
			temp_vec2[0] = camera._front[0];
			temp_vec2[1] = camera._front[2];
		}

		var angle = vec2ComputeSignedAngle(temp_vec2, RD.FRONT2D);
		if (!isNaN(angle)) {
			mat4.rotateY(temp_mat4, identity_mat4, -angle);
			node._global_matrix.set(temp_mat4);
			mat4SetTranslation(node._global_matrix, node._position);
			mat4.scale(node._global_matrix, node._global_matrix, node._scale);
		}
	} else {
		if (mode == RD.BILLBOARD_PARALLEL_SPHERIC) {
			node._global_matrix.set(camera._model_matrix);
			mat4SetTranslation(node._global_matrix, node._position);
			mat4.scale(node._global_matrix, node._global_matrix, node._scale);
		} else //BILLBOARD_SPHERIC
		{
			mat4.lookAt(node._global_matrix, node._position, camera.position, Direction3Constants.UP);
			mat4.invert(node._global_matrix, node._global_matrix);
			mat4.scale(node._global_matrix, node._global_matrix, node._scale);
		}
	}

	renderer.setModelMatrix(node._global_matrix);
}


RD.readPixels = function (url, on_complete) {
	var image = new Image();
	image.src = url;
	image.onload = function () {
		var canvas = document.createElement("canvas");
		canvas.width = this.width;
		canvas.height = this.height;
		var ctx = canvas.getContext("2d");
		ctx.drawImage(this, 0, 0);
		var data = ctx.getImageData(0, 0, canvas.width, canvas.height);
		on_complete(data, this);
	}
};

RD.alignDivToNode = function (domElement, cameraElement, div, node, camera, allow_cursor) {
	var width = parseInt(domElement.clientWidth);
	var height = parseInt(domElement.clientHeight);
	var _widthHalf = width * 0.5;
	var _heightHalf = height * 0.5;
	var fov = camera._projection_matrix[5] * _heightHalf;

	//top container
	domElement.style.width = gl.canvas.width + "px";
	domElement.style.height = gl.canvas.height + "px";
	domElement.style.perspective = fov + "px";
	domElement.style.pointerEvents = "none";

	if (cameraElement) {
		var cameraCSSMatrix = "translateZ(" + fov + "px) " + getCameraCSSMatrix(camera._view_matrix);
		var style = cameraCSSMatrix + " translate(" + _widthHalf + "px," + _heightHalf + "px)";
		cameraElement.style.transformStyle = "preserve-3d";
		cameraElement.style.transform = style;
		cameraElement.style.width = width + "px";
		cameraElement.style.height = height + "px";
		cameraElement.style.pointerEvents = "none";
	}

	var model = node.getGlobalMatrix();
	var scaleX = 1 / div.clientWidth;
	var scaleY = 1 / div.clientHeight;
	if (div.parentNode != cameraElement)
		cameraElement.appendChild(div);

	div.style.pointerEvents = allow_cursor ? "auto" : "none";
	div.style.transform = getObjectCSSMatrix(model) + " scale3D(" + scaleX + "," + scaleY + ",1)";

	//renderObject( scene, scene, camera, cameraCSSMatrix );
	function epsilon(a) {
		return Math.abs(a) < 0.00001 ? 0 : a;
	}

	function getCameraCSSMatrix(matrix) {
		return "matrix3d(" + epsilon(matrix[0]) + ","
			+ epsilon(-matrix[1]) + ","
			+ epsilon(matrix[2]) + ","
			+ epsilon(matrix[3]) + ","
			+ epsilon(matrix[4]) + ","
			+ epsilon(-matrix[5]) + ","
			+ epsilon(matrix[6]) + ","
			+ epsilon(matrix[7]) + ","
			+ epsilon(matrix[8]) + ","
			+ epsilon(-matrix[9]) + ","
			+ epsilon(matrix[10]) + ","
			+ epsilon(matrix[11]) + ","
			+ epsilon(matrix[12]) + ","
			+ epsilon(-matrix[13]) + ","
			+ epsilon(matrix[14]) + ","
			+ epsilon(matrix[15]) + ")";
	}

	function getObjectCSSMatrix(matrix) {

		var matrix3d = "matrix3d(" + epsilon(matrix[0]) + ","
			+ epsilon(matrix[1]) + ","
			+ epsilon(matrix[2]) + ","
			+ epsilon(matrix[3]) + ","
			+ epsilon(-matrix[4]) + ","
			+ epsilon(-matrix[5]) + ","
			+ epsilon(-matrix[6]) + ","
			+ epsilon(-matrix[7]) + ","
			+ epsilon(matrix[8]) + ","
			+ epsilon(matrix[9]) + ","
			+ epsilon(matrix[10]) + ","
			+ epsilon(matrix[11]) + ","
			+ epsilon(matrix[12]) + ","
			+ epsilon(matrix[13]) + ","
			+ epsilon(matrix[14]) + ","
			+ epsilon(matrix[15]) + ")";
		return "translate(-50%,-50%) " + matrix3d;
	}
}

/**
 * @deprecated use direct import instead
 * @type {Scene}
 */
RD.Scene = Scene;
/**
 * @deprecated use direct import instead
 * @type {SceneNode}
 */
RD.SceneNode = SceneNode;
/**
 * @deprecated use direct import instead
 * @type {Ray}
 */
RD.Ray = Ray;
/**
 * @deprecated use direct import instead
 * @type {Gizmo}
 */
RD.Gizmo = Gizmo;
