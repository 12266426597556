import { directionVectorToRotation } from "@src/engine/helpers/directionVectorToRotation";
import { vec2, vec3 } from "gl-matrix";
function cameraToState(output, input) {
    // Copy position and fov
    vec3.copy(output.position, input._position);
    output.fov = input._fov;
    // Compute rotation from target and position
    directionVectorToRotation(output.rotation, input._position, input._target);
    //output.lookAt(input._target);
    vec2.copy(output.shift, input.shift);
}
export default cameraToState;
