import { vec3 } from "gl-matrix";

/**
 * for ray collision
 * @class Ray
 * @constructor
 * @param {vec3} [origin]
 * @param {vec3} [direction]
 */
export function Ray(origin, direction) {
	this.origin = vec3.create();
	this.direction = vec3.create();
	this.collision_point = vec3.create();

	if (origin)
		this.origin.set(origin);
	if (direction)
		this.direction.set(direction);
}

Ray.prototype.testPlane = function( P, N )
{
	return geo.testRayPlane( this.origin, this.direction, P, N, this.collision_point );
}

Ray.prototype.testSphere = function( center, radius, max_dist )
{
	return geo.testRaySphere( this.origin, this.direction, center, radius, this.collision_point, max_dist );
}

Ray.prototype.closestPointOnRay = function( origin, direction, closest )
{
	closest = closest || vec3.create();
	var end = vec3.create();
	vec3.add(end, this.origin, this.direction );
	var end2 = vec3.create();
	vec3.add(end2, origin, direction );
	geo.closestPointBetweenLines( this.origin, end, origin, end2, null, closest );
	return closest;
}
