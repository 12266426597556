import Backend from "@src/controllers/components/backend";
import Button from "@src/libs/GLUI/Elements/Button";

//Shows and helps navigate through the files in the backend
var FilesDialog = {

	area: [ -300,-300, 0,600 ],

	render: function( editor )
	{
		var backend = Backend.instance;
		if (!backend)
		{
			console.error("no backend found");
			return false;
		}

		if ( this.area[2] === 0 )
		{
			this.area[2] = gl.canvas.width - editor.sidewidth - 300;
			this.area[0] = editor.sidewidth + 20;
			this.area[1] = 40;
		}

		var w = this.area[2];
		var h = this.area[3] = gl.canvas.height - 60;
		var x = this.area[0];
		var y = this.area[1];

		if (GUI.Panel( x, y, w,h, "Files", true ) === false )
			return false;

		//The UI for the files is handled by backend.js
		backend.showFiles( x + 20, y + 60, w - 40, h - 200 );

		if ( Button.call(GUI,  x + w - 20 - 120, y + h - 60,120,40, "Close" ) )
			return false;
	}
};

export default FilesDialog;
