import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { Gizmo } from "@src/libs/rendeer-gizmo";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { mat4, vec2, vec3 } from "gl-matrix";

//Tool to edit the points from the editor *******************************
function PathEditorTool()
{
	this.component = null;
	this.selected = null;
	this.dragging = null;

	this._last_coll_point = vec3.create();
	this.selected_index = -1;

	this.height = 1;

	this._node = new SceneNode();
}

PathEditorTool.instance = null;

PathEditorTool.prototype.onEnable = function( editor )
{
	//move gizmo to selection
}

PathEditorTool.prototype.onMouse = function(e,ray,editor)
{
	//console.debug(e,ray);
	//test ray with floor plane

	var path = this.component;
	var mouse = [ e.canvasx, e.canvasy ];
	var view = editor.view;
	var camera = view.getCurrentCamera();
	var front = camera.getFront();

	if (this.selected_index !== -1 && this.selected_index >= path.points.length )
		this.selected_index = -1;

	if (this.selected_index !== -1 )
	{
		if ( editor.gizmo.onMouse(e) )
		{
			this.assignPosition( editor.gizmo.position );
			return true;
		}
	}

	var height = this.height;
	var plane_center = [ 0,height,0 ];
	var plane_normal = [ 0,1,0 ];

	if (this.selected_index !== -1 )
	{
		var p = path.points[ this.selected_index ].pos;
		ray.testPlane(p,[ 0,1,0 ]);
	}

	if ( !ray.testPlane(plane_center,plane_normal) )
		return;


	if (e.type === "mousedown")
	{

		var index = this.findNearest2D(mouse,camera);
		if (index !== -1 )
		{
			this.dragging = true;
			this.selected_index = index;
		}
	}
	else if (e.type === "mousemove")
	{
		/*
    if(this.dragging)
    {
      var index = this.selected_index;
      var p = path.points[ index ].pos;
      var final_point = ray.collision_point;
      if( e.shiftKey )
      {
        ray.testPlane( p, front );
        final_point = ray.collision_point;
      }

      this.assignPosition( final_point );
      return true;
    }
    */
	}
	else if (e.type === "mouseup")
	{
		if ( e.click_time < 250 && !this.dragging )
		{
			editor.saveUndo();
			path.addPoint( ray.collision_point );
			this.selected_index = path.points.length - 1;

			//add complementary
			if (path.points.length > 3 && ((path.points.length - 1) % 3) === 0 )
			{
				var p = vec3.create();
				var last = path.points[ path.points.length - 1 ].pos;
				vec3.sub( p, last, path.points[ path.points.length - 2 ].pos );
				vec3.add( p, last, p );
				path.addPoint( p );
			}
		}

		this.dragging = false;
	}
}

PathEditorTool.prototype.assignPosition = function( final_point )
{
	var path = this.component;
	var index = this.selected_index;
	var p = path.points[ index ].pos;

	var prev = index ? path.points[ index - 1 ].pos : null
	var next = index < path.points.length - 1 ? path.points[ index + 1 ].pos : null;

	var delta = vec3.create();
	vec3.sub( delta, final_point, p ); //compute delta
	vec3.copy( p, final_point ); //assign

	if ((index % 3) === 0 ) //is pass point
	{
		if (prev)
			vec3.add(prev,prev,delta);
		if (next)
			vec3.add(next,next,delta);
	}
	else //control
	{
		//first?
		if ((index - 1) % 3 === 0 )
		{
			var complementary = (index-2) >= 0 ? path.points[ index - 2 ].pos : null;
			if (complementary)
			{
				vec3.sub( delta, path.points[ index - 1 ].pos, p );
				vec3.add( complementary, path.points[ index - 1 ].pos, delta );
			}
		}
		else
		{
			var complementary = (index+2) < path.points.length ? path.points[ index + 2 ].pos : null;
			if (complementary)
			{
				vec3.sub( delta, path.points[ index + 1 ].pos, p );
				vec3.add( complementary, path.points[ index + 1 ].pos, delta );
			}
		}
	}

	path._must_update = true;
}

PathEditorTool.prototype.findNearest2D = function(pos, camera)
{
	var p2d = vec3.create();
	var path = this.component;
	var min_dist = 1000000;
	var index = -1;

	for (var i = 0; i < path.points.length; ++i)
	{
		var p = path.points[i].pos;
		camera.project( p, null, p2d );
		var dist = vec2.dist( pos, p2d );
		if ( dist > min_dist || dist > 10 )
			continue;
		index = i;
		min_dist = dist;
	}

	return index;
}

//called from editor.renderUI
PathEditorTool.prototype.onRender = function( editor, view )
{
	if (!this.component )
		return;

	var path = this.component;
	var camera = view.getCurrentCamera();

	if (this.selected_index !== -1 && path.points.length > this.selected_index)
	{
		this._node.position = path.points[this.selected_index].pos;
		editor.gizmo.mode = Gizmo.MOVEALL;
		editor.gizmo.setTargets([ this._node ]);
		editor.gizmo.render( editor.view.renderer, camera );
	}

	gl.disable( gl.DEPTH_TEST );

	//render selected point
	if (path.points && path.points.length && this.selected_index !== -1 && this.selected_index < path.points.length )
	{
		view.renderer.color = [ 5,5,2,1 ];
		view.renderer.renderPoints( path.points[this.selected_index].pos, null, camera, 1, null, -10 );
	}
}

PathEditorTool.prototype.onRenderInspector = function(ctx,x,y,w,h,editor)
{
	var path = this.component;

	Label.call(GUI, x,y,w,20, "Height" );
	this.height = GUI.Number( x + 100,y,w-100,20, this.height );
	y+=24;

	//Delete Point
	if ( Button.call(GUI,x,y,200,20,"Delete Point") )
	{
		this.deleteSelection();
	}
}

PathEditorTool.prototype.onRenderGizmo = function( view, editor, selected )
{
	var path = this.component;
	//var camera = view.getCurrentCamera();

	if (editor.tool === this )
		this.renderHeightPlane( view );
}

PathEditorTool.prototype.renderHeightPlane = function( view )
{
	var camera = view.getCurrentCamera();
	var size = 10;
	gl.enable( gl.DEPTH_TEST );
	gl.depthMask( false );
	var model = mat4.create();
	var x = 0;//camera.position[0];
	var z = 0;//camera.position[2];
	mat4.translate(model,model,[ x,this.height,z ]);
	mat4.scale(model, model, [ size,size,size ]);
	var mesh = gl.meshes["planeXZ"];
	gl.enable( gl.BLEND );
	gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );
	var texture = view.loadTexture("/textures/grid_gauss.png");
	var color = [ 0.6,0.7,1,1 ];
	view.renderer.renderMesh( model, mesh, texture, color );
	gl.depthMask( true );
	gl.disable( gl.BLEND );
}

PathEditorTool.prototype.deleteSelection = function()
{
	xyz.editor_controller.saveUndo();
	var path = this.component;
	if (this.selected_index !== -1 )
		path.removePoint( this.selected_index );
	if ( this.selected_index >= path.points.length )
		this.selected_index = path.points.length - 1;
}

PathEditorTool.prototype.onKeyDown = function(e)
{
	if (e.code === "Delete")
	{
		this.deleteSelection();
		return true;
	}
	return false;
}

export default PathEditorTool;
