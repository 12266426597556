import ROOM from "@src/engine/room";

//global space info
function LGraphSpace()
{
	this.properties = {};
	this.addOutput("time","number");
	this.addOutput("num.participants","number");
	this._space = null;
}

LGraphSpace.title = "Space";
LGraphSpace.desc = "Space information";
LGraphSpace.highlight_color = "#CCC";

LGraphSpace.prototype.onRemoved = function()
{
	if (!this._space)
		return;
	LEvent.unbindAll( this._space, this );
}

LGraphSpace.prototype.onExecute = function()
{
	var space = this.graph && this.graph.space || ROOM.space;
	if (!space)
		return;
	this._space = space;

	this.setOutputData( 0, space.global_time );
	this.setOutputData( 1, space.participants.length );
}

LGraphSpace.prototype.onOutputAdded = function(output)
{
	var space = this.graph && this.graph.space || ROOM.space;
	if (!space)
		return;
	this._space = space;

	if (output.type === LiteGraph.EVENT)
		LEvent.bind( space, output.name, this.onSceneEvent, this );
}

LGraphSpace.prototype.onSceneEvent = function(e,data)
{
	this.trigger(e,data);
}

LGraphSpace.prototype.onGetOutputs = function()
{
	return [ [ "first_time_seat",LiteGraph.EVENT ] ];
}

export default LGraphSpace;
