import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";

function LGraphMaterial() {
	this.properties = { uid: "", wait_event: true };
	this.addInput("set", LiteGraph.ACTION); //not used
	this.addOutput("mat", "material");
	this.addWidget("toggle", "Wait event", true, "wait_event");
	this.widgets_up = true;
	this._material = null;
	this._assign = false;
}

LGraphMaterial.title = "Material";
LGraphMaterial.desc = "Material info";

LGraphMaterial.prototype.onExecute = function() {
	var material = this._material = StaticMaterialsTable[this.properties.uid];
	if (!material) {
		return;
	}

	if (this.inputs && (this._assign || !this.properties.wait_event)) {
		this._assign = false;
		for (var i = 1; i < this.inputs.length; ++i) {
			var input = this.inputs[i];
			if (!input.link || input.type === LiteGraph.ACTION) {
				continue;
			}

			var v = this.getInputData(i);
			if (v === undefined) {
				continue;
			}

			if (input.name.substr(0, 4) === "tex_") {
				var channel = input.name.substr(4);
				material.textures[channel] = v;
				continue;
			}

			//special cases
			if(v)
			{
				if(input.name == "uv_tiling" && v.length == 2)
				{
					var old = material.uv_transform;
					old[0] = v[0];
					old[4] = v[1];
					material.uv_transform = old;
					/*
					var old = material.uvTiling0;
					old[0] = v[0];
					old[1] = v[1];
					material.uvTiling0 = old; //force change
					//material.setUVTiling(p, srcTiling);
					*/
					continue;
				}
				else if(input.name == "uv_offset" && v.length == 2)
				{
					var old = material.uv_transform;
					old[6] = v[0];
					old[7] = v[1];
					material.uv_transform = old;
					/*
					var old = material.uvTiling0;
					old[2] = v[0];
					old[3] = v[1];
					material.uvTiling0 = old; //force change
					//material.setUVTiling(p, srcTiling);
					*/
					continue;
				}
			}

			if (material[input.name] === undefined) {
				continue;
			}

			if (material[input.name] != null && material[input.name].set) {
				material[input.name].set(v);
			} else {
				material[input.name] = v;
			}
		}
	}

	if (this.outputs) {
		for (var i = 0; i < this.outputs.length; ++i) {
			var output = this.outputs[i];
			if (!output.links || !output.links.length || output.type === LiteGraph.EVENT) {
				continue;
			}
			if (i === 0) {
				this.setOutputData(i, material);
				continue;
			}

			if (output.name.substr(0, 4) === "tex_") {
				var channel = output.name.substr(4);
				this.setOutputData(i, material.textures[channel]);
				continue;
			}

			this.setOutputData(i, material[output.name]);
		}
	}
};

LGraphMaterial.prototype.onAction = function(action, param) {
	if (action === "set") {
		this._assign = true;
	}
};

LGraphMaterial.prototype.onDrawBackground = function(ctx) {
	if (!this._material) {
		this.boxcolor = "orange";
		return;
	}
	this.boxcolor = null;

	if (this._material.name) {
		ctx.font = "20px Arial";
		ctx.textAlign = "left";
		ctx.fillColor = [ 1, 0.7, 0.5, 0.75 ];
		ctx.fillText(this._material.name, 0, -40);
	}
};

LGraphMaterial.prototype.onGetInputs = function() {
	return [
		[ "color", "vec3" ],
		[ "opacity", "number" ],
		[ "emissive", "vec3" ],
		[ "uv_tiling", "vec2" ],
		[ "uv_offset", "vec2" ]
	];
};

LGraphMaterial.prototype.onGetOutputs = function() {
	return [
		[ "color", "vec3" ],
		[ "opacity", "number" ],
		[ "emissive", "vec3" ],
		[ "uv_tiling", "vec2" ],
		[ "uv_offset", "vec2" ]
	];
}

export default LGraphMaterial;
