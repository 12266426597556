import { mat3, vec3, vec2 } from "gl-matrix";
import Entity from "@src/engine/entity";
import EntityReference from "@src/engine/Entity/EntityReference";
import { RoomComponents } from "@src/engine/RoomComponents";
import Button from "@src/libs/GLUI/Elements/Button";
import Label  from "@src/libs/GLUI/Elements/Label";
import { getObjectClassName } from "@src/engine/Room/getObjectClassName";
import clamp from "@src/math/clamp";

import Timeline  from "@src/libs/timeline";
import Animation  from "@src/libs/rendeer/animation";
import Track  from "@src/libs/rendeer/animationTrack";


var TimelineDialog = {
	area: [ 0,0,400,400 ],
	selected: null,
	editor: null,
	timeline: null,
	current_time: 0,

	onOpen: function( editor )
	{
		var that = this;
		this.editor = editor;
		this.editor.lowpanel_visible = true;
		if(!this.timeline)
		{
			this.timeline = new Timeline();
			this.timeline.onSetTime = function(v) {
				//xyz.space.setTime( v );
				v = clamp(v, 0, this.duration );
				that.assignTime(v);
			}
		
			this.timeline.onDrawContent = this.onDrawTimelineContent.bind(this);			
		}
	},

	onClose: function()
	{
		this.editor.lowpanel_visible = false;
	},

	assignTime: function(v)
	{
		this.current_time = v;
		if(this.selected)		
			this.selected.applyAnimation( xyz.space.root, v, RD.CUBIC );
	},

    showAnimation: function( animation )
    {
        this.selected = animation;
    },

	render: function()
	{
        var editor = this.editor;
		var w = this.area[2] = gl.canvas.width - editor.sidewidth;
		var h = this.area[3] = this.editor.lowheight;
		var x = this.area[0] = editor.sidewidth;
		var y = this.area[1] = gl.canvas.height - h;
		var ctx = gl;
		var top_margin = 40;
		var space = xyz.space;
		var that = this;

		var on_edge = Math.abs( GUI.mouse.position[1] - y ) < 4;
		if (on_edge)
			GUI.cursor = "ns-resize";

		if (GUI.Panel( x, y, w,h, null, true, 0 ) === false )
		{
			return false;
		}

        var animation = this.selected;

		GUI.DropArea(x,y,w,h,this.onDropItem.bind(this));

		if (!animation)
		{
			if ( Button.call(GUI,x,y,100,24,"Create Animation") )
			{
				animation = this.selected = space.animation = new Animation();
			}
			return;
		}

		if(animation)
			this.timeline.draw( ctx, animation, this.current_time, [ x,y+40,w,h-40 ] );
	},

	onDrawTimelineContent: function( ctx, time_start, time_end, timeline )
	{
		const animation = this.selected;
		if (!animation)
			return null;
		var space = this.editor.space;
		var that = this;
	
		const track_height = 24;
		let y = 0;
		let index = 0;
		for (let i = 0; i < animation.tracks.length; ++i)
		{
			const track = animation.tracks[i];
			if ( track.hidden )
				continue;
			const target_name = track.target_node;
			let target = null;
			const target_info = space.resolveLocator(target_name);
	
			let title = null;
			if (target_info)
			{
				target = target_info.target;
				if ( target.constructor === Entity )
					title = target.name;
				else if ( target.constructor.is_component)
					title = target.entity.name + "/" + getObjectClassName( target );
				else
					title = target_info.name || "[Object]";
			}
			else
				title = "[not found]";
			const subtitle = track.target_property;
	
			var is_track_selected = track == this.selected_track;
			track._selected = is_track_selected;
			track._index = i;
			timeline.drawTrackWithKeyframes( ctx, y, track_height, title, subtitle, track, index, target, on_click );

			if(is_track_selected && this.selected_track_keyframe_index != -1)
			{
				var keyframe = track.data[this.selected_track_keyframe_index];
				if(keyframe)
				{
					var x = timeline.timeToX(keyframe[0]);
					ctx.fillStyle = "white";
					ctx.fillRect(x-5,y,10,track_height);
				}
			}
	
			y += track_height;
			index++;
			if ( y > ctx.canvas.height )
				break;
		}
	
		//track clicked
		function on_click( track, event, timeline, pos )
		{
			that.selected_track = track;
			that.selected_track_index = track._index;
			that.selected_track_keyframe_index = -1;

			if ( pos[0] < 30 ) //enabled
			{
				track.enabled = !track.enabled;
				return;
			}
			else if (pos[0] < timeline.sidebar_width ) //track name
			{
				//select track?
				console.log("track name clicked");
				return;
			}
			else
			{
				that.selected_track_keyframe_index = that.getKeyframeIndexFromPosition( track, pos[0] );
			}


		}
	},

	//x must be local
	getKeyframeIndexFromPosition( track, x )
	{
		var keyframes = track.data;
		for(var i = 0; i < keyframes.length; ++i)
		{
			var k = keyframes[i];
			var kx = this.timeline.timeToX(k[0]);
			if( Math.abs(x - kx) < 5 )
				return i;
		}
		return -1;
	},

	onAnimationTrackBullet( target, property_name, type ) {
		console.debug("Anim for ",target,name);
		if (!this.selected)
			return;

		var space = this.editor.space;
		this.editor.saveUndo();

		var animation = this.selected;
		var track = this.findTrack( target, property_name );
		var sample = null;

		if (!track)
		{
			var track = new Track();
			track.target_node = target.getLocator();
			track.target_property = property_name;
			if (target.getValueFromLocator)
				sample = target.getValueFromLocator( property_name );
			else if ( target.getPropertyFromPath )
				sample = target.getPropertyFromPath( property_name.split("/"), 0 );
			else
				sample = target[ property_name ];
			if (sample === undefined)
			{
				console.error("Target property is undefined", property_name );
				return;
			}

			if (sample && sample.length && sample.constructor !== String)
				track.type = sample.length; //it usually works...
			else
				track.type = 0; //for booleans, strings, etc
			animation.addTrack( track, true );
		}
		else
		{
			if (target.getValueFromLocator)
				sample = target.getValueFromLocator( property_name );
			else
				sample = target[ property_name ];
		}

		track._target = target;

		//find prev in case
		var index = track.findTimeIndex(this.current_time);
		var keyframe = null;
		//not necessary  
		var current_time = clamp( this.current_time, 0, animation.duration );

		if( index != -1 )
		{
			keyframe = track.getKeyframe(index);
			if( Math.abs(keyframe[0] - current_time) > 0.01 )
				keyframe = null;
		}
		if(keyframe)
		{
			if(keyframe[1] && keyframe[1].set)
				keyframe[1].set(sample); //TODO, copy in place?
			else if( track.value_size > 1)
				keyframe[1] = Array.from(sample); //clone
			else
				keyframe[1] = sample;
		}
		else //create new one
			track.addKeyframe( current_time, sample );
	},

	findTrack: function( target, property ) {
		var animation = this.selected;
		if (!animation)
			return null;

		//get target name
		var locator = target.getLocator();

		//search for an existing track
		for (var i = 0; i < animation.tracks.length; ++i)
		{
			var track = animation.tracks[i];

			if (track.target_property !== property )
				continue;

			if (track.target_node === locator ||
					(track._target && track._target === target ) )
				return track;

			if ((track.target_node[0] === "@" && track.target_node !== target.uid) ||
					(track.target_node[0] !== "@" && track.target_node !== target.name) )
				continue;

			return track;
		}
		return null;
	},

	onKeyDown: function(e)
	{
		var animation = this.selected;
		if(e.code == "Delete")
		{
			if( this.selected_track )
			{
				if( this.selected_track_keyframe_index != -1 )
					this.selected_track.deleteKeyframe( this.selected_track_keyframe_index );
				else 
				{
					animation.removeTrack( this.selected_track );
				}
				this.selected_track_keyframe_index = -1;
			}
			return true;
		}
	},

	//called from editor
	onMouse: function(e)
	{
		var y = (gl.canvas.height - this.editor.lowheight);
		var on_edge = Math.abs( e.mousey - y ) < 4;
		var localpos = this.timeline.toLocalPosition( e.offsetX, e.offsetY );

		if (e.type === "mousedown" )
		{
			this.dragging_keyframe = null;
			if ( on_edge )
			{
				this.dragging_edge = true;
				this.capture_mouse = true;
			}
			else
			{
				var track = this.timeline.getTrackInPosition(localpos[1]);
				if(track)
				{
					this.selected_track = track;
					this.selected_track_index = track._index;
					var index = this.getKeyframeIndexFromPosition( track, localpos[0] );
					this.selected_track_keyframe_index = index;
					if(index != -1)
						this.dragging_keyframe = { track: this.selected_track, index: index };
				}
			}
			
		}
		else if (e.type === "mousemove" )
		{
			if ( this.dragging_edge )
			{
				//console.debug("draggin edge");
				this.editor.lowheight -= e.deltay;
			}
			else
			{
				if(this.dragging_keyframe)
				{
					var track = this.dragging_keyframe.track;
					var time = this.timeline.xToTime(localpos[0]);
					var keyframe = track.getKeyframe( this.dragging_keyframe.index );
					if(keyframe)
					{
						keyframe[0] = time;
						track.sortKeyframes();
						this.dragging_keyframe.index = track.data.indexOf(keyframe);
						this.selected_track_keyframe_index = this.dragging_keyframe.index;
						return;
					}
				}
			}
		}
		else if (e.type === "mouseup" )
		{
			this.capture_mouse = false;
			this.dragging_edge = false;
			this.dragging_keyframe = null;
			//return this.graphcanvas.processMouseUp(e);
		}

		return this.timeline.processMouse(e);
		//else if (e.type === "wheel" )
		//	return this.graphcanvas.processMouseWheel(e);
	},

	onDropItem: function(e)
	{
	},

    openInWindow: function( graph )
    {
        if (this._dialog_window)
        {
            this._dialog_window.focus();
            return;
        }
    
        var that = this;
        var title = "Timeline";
    
        var dialog_window = window.open("_blank","","width=800, height=600, location=no, status=no, menubar=no, titlebar=no, fullscreen=yes");
        dialog_window.document.write( "<head><title>"+title+"</title>" );
        this._dialog_window = dialog_window;
    
        var styles = document.querySelectorAll("link[rel='stylesheet'],style");
        for (var i = 0; i < styles.length; i++)
            dialog_window.document.write( styles[i].outerHTML );
        dialog_window.document.write( "</head><body></body>" );
        dialog_window.document.close();
    
        if (!ROOM.windows)
            ROOM.windows = [];
        ROOM.windows.push(dialog_window);
    
        var canvas = document.createElement("canvas");
        canvas.width = 800;
        canvas.height = 800;
        //var graphcanvas = new LiteGraph.LGraphCanvas( canvas, graph._graph, { autoresize:true } );
        //graphcanvas.onDropItem = GraphEditorDialog.onDropItem.bind(GraphEditorDialog);
        //dialog_window.litegraph_graphcanvas = graphcanvas;
        //dialog_window.document.body.appendChild( graphcanvas.canvas );
        dialog_window.document.body.style.backgroundColor = "#222";
    
        //closing event
        dialog_window.onbeforeunload = function() {
            var index = ROOM.windows.indexOf( dialog_window );
            if (index !== -1)
                ROOM.windows.splice( index, 1 );
            //if(on_close)
            //	on_close();
            that._dialog_window = null;
        }
    
        return dialog_window;
    },


	/*
	renderAnimation(ctx,x,y,w,h) {
		var sidewidth = this.sidewidth;
		var animation = this.space.animation;

		if (!animation)
		{
			if ( Button.call(GUI, 10,y,sidewidth - 20,30, "Create Animation") )
			{
				animation = this.space.animation = new RD.Animation();
				this.draw_timeline = true;
			}
		}

		if (!animation)
			return;

		Label.call(GUI,10,y,100,30, "Duration");
		animation.duration = GUI.Number(10 + 140,y,sidewidth - 160,30, animation.duration );

		y += 100;
		Label.call(GUI,10,y,100,30, "Tracks");
		y += 40;
		var prev_entity = null;
		for (var i = 0; i < animation.tracks.length; ++i)
		{
			var track = animation.tracks[i];
			if (prev_entity === track.target_node )
				continue;
			prev_entity = track.target_node;
			if ( Button.call(GUI, 10, y, 24,24, !track.hidden ? [ 8,7 ] : [ 9,7 ] ) )
			{
				for (var j = i; j < animation.tracks.length; ++j)
				{
					if (animation.tracks[j].target_node === track.target_node)
						animation.tracks[j].hidden = !animation.tracks[j].hidden;
				}
			}

			var title = track.target_node;
			if ( track._target && track._target.name )
				title = track._target.name;

			ctx.font = "20x Arial";
			ctx.textAlign = "left";
			ctx.fillColor = [ 1,1,1,0.8 ];
			ctx.fillText(title, 40, y+20 );
			ctx.fillColor = [ 0.5,0.8,1,1 ];
			var textwidth = ctx.measureText(title).width;
			ctx.fillText(track.target_property, 40 + textwidth, y+20);

			if ( Button.call(GUI, sidewidth - 40, y, 24,24, [ 10,0 ] ) )
			{
				this.saveUndo();
				animation.tracks.splice(i,1);
			}

			y += 30;
		}
	}
	*/	
};

export default TimelineDialog;