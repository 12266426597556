import { rotationToDirectionVector } from "@src/engine/helpers/rotationToDirectionVector";
import { vec3 } from "gl-matrix";
function stateToCamera(output, input) {
    // Copy position and fov
    vec3.copy(output._position, input.position);
    output.fov = input.fov;
    // Compute target from rotation and position
    output.target = rotationToDirectionVector(input.position, input.rotation);
    output.shift = input.shift;
}
export default stateToCamera;
