import { CameraConstraint } from "./CameraConstraint";
export class LookAtConstraint extends CameraConstraint {
    static makeLookAt(target) {
        const constraint = new LookAtConstraint();
        constraint.target = target;
        return constraint;
    }
    solve(outputState, inputState) {
        outputState.copy(inputState);
        outputState.lookAt(this.target);
    }
}
