import Button from "@src/libs/GLUI/Elements/Button";
import Label  from "@src/libs/GLUI/Elements/Label";

var TempRecentSpacesDialog = {

	area: [ -300,-300, 650,600 ],
	selected: null,
    list: null,

    onOpen: function(editor)
    {
        this.editor = editor;
        this.refresh();
    },

    refresh: function()
    { 
        var str = localStorage.getItem("tmrw_editor_temp_sessions");
        if(str)
            this.list = JSON.parse(str);
        else 
            this.list = [];
    },

    saveList: function()
    {
        localStorage.setItem("tmrw_editor_temp_sessions", JSON.stringify(this.list));
    },

    load: function(item)
    {
        xyz.space.configure( item.data );
    },

    save: function( name, data )
    {
		if (!name)
		{
			name = this.editor.space.url;
			if (!name)
			{
				alert("no filename");
				return;
			}

			//in case it was a folder
			if (name.indexOf(".json") === -1 )
				name += "/room.json";
        }

        if(!data)
            data = this.editor.getSceneJSON();

		var list=localStorage.getItem("tmrw_editor_temp_sessions");
		if(list) list=JSON.parse(list);
		else list = [];
		var entry={
			name: name,
			data: data,
			date: (new Date()).toLocaleTimeString() + " " + (new Date()).toLocaleDateString()
		};
		list.unshift(entry);
		if(list.length > 50) //max
			list.length = 50;
		localStorage.setItem("tmrw_editor_temp_sessions",JSON.stringify(list));
    },

	render: function(editor)
	{
        var ctx=gl;
		var w = this.area[2];
		var h = this.area[3];
		var x = this.area[0] = gl.canvas.width * 0.5 - w*0.5;
		var y = this.area[1] = gl.canvas.height * 0.5 - h*0.5;

		if ( GUI.Panel( x, y, w,h, "Recent saves", true ) === false )
			return false;

		var space = xyz.space;
        var sy = y;

        GUI.next_tooltip = "Refresh";
        if ( Button.call(GUI, x+w-50, y + 44, 32,32, [ 1,5 ], true, [ 0.3,0.3,0.3,1 ] ) )
            this.refresh();        

        if(!this.list)
            this.refresh();
        var list = this.list;

        y += 50;

        var total_height = 200;
		if (!this.scroll )
			this.scroll = GUI.createScrollArea(w,h-50,total_height);
		this.scroll.total = total_height;
		GUI.ScrollableArea( this.scroll, x + 20, y + 30, w - 40, h - 140, inner.bind(this) );

		function inner(x,y,w,h)
		{
			var iy = y;
			for (var i = 0; i < list.length; ++i)
			{
				var item = list[i];

                var mouse_info = GUI.HoverArea( x, iy, w-40, 20 );
                var hover = mouse_info == GLUI.HOVER;

                var color = [ 0.7,0.7,0.7,1 ];
                if(hover)
                    color = [1,1,1,1];
                else if(this.selected && this.selected.date == item.date)//use date better than name
                    color = [1,1,.7,1];

				Label.call(GUI, x + 5,iy,w-20,20, item.name + "   " + item.date, color );

				if ( Button.call(GUI,  x + w - 30,iy,20,20, GLUI.icons.trash ) )
                {
					list.splice(i--,1);
                    this.saveList();
                }

                if ( mouse_info === GLUI.CLICKED )
                    this.selected = item;

				iy += 24;
			}
		}

        if(this.selected)
        {
            GUI.next_tooltip = "Load";
            if ( Button.call(GUI, x+40, sy + h - 40, 100,32, "Load", true, [ 0.3,0.3,0.3,1 ] ) )
            {
                this.load( this.selected );
                return false;
            }
        }

        GUI.next_tooltip = "Save";
        if ( Button.call(GUI, x+160, sy + h - 40, 100,32, "Save", true, [ 0.3,0.3,0.3,1 ] ) )
        {
            var data = this.editor.getSceneJSON();
            this.save();
            this.refresh();
        }
    }
};

export default TempRecentSpacesDialog;
