import { mat3, quat, vec3 } from "gl-matrix";

import { vec3GetMat3Column } from "./vec3";

export const quatFromAxisAngle = function(axis, rad) {
	const out = quat.create();
	rad = rad * 0.5;
	const s = Math.sin(rad);
	out[0] = s * axis[0];
	out[1] = s * axis[1];
	out[2] = s * axis[2];
	out[3] = Math.cos(rad);
	return out;
};

/**
 * @param {vec3} out
 * @param {quat} q
 * @returns {vec3}
 */
export const quatToEuler = function(out, q) {
	const heading = Math.atan2(2 * q[1] * q[3] - 2 * q[0] * q[2], 1 - 2 * q[1] * q[1] - 2 * q[2] * q[2]);
	const attitude = Math.asin(2 * q[0] * q[1] + 2 * q[2] * q[3]);
	const bank = Math.atan2(2 * q[0] * q[3] - 2 * q[1] * q[2], 1 - 2 * q[0] * q[0] - 2 * q[2] * q[2]);
	if (!out) {
		out = vec3.create();
	}
	vec3.set(out, heading, attitude, bank);
	return out;
};

/**
 * @deprecated
 * @use quat.fromEuler
 * @link https://glmatrix.net/docs/quat.js.html#line459
 *
 * @param out
 * @param vec
 * @returns {*}
 */
export const quatFromEuler = function(out, vec) {
	const heading = vec[0];
	const attitude = vec[1];
	const bank = vec[2];

	const C1 = Math.cos(heading); //yaw
	const C2 = Math.cos(attitude); //pitch
	const C3 = Math.cos(bank); //roll
	const S1 = Math.sin(heading);
	const S2 = Math.sin(attitude);
	const S3 = Math.sin(bank);

	let w = Math.sqrt(1.0 + C1 * C2 + C1 * C3 - S1 * S2 * S3 + C2 * C3) * 0.5;
	if (w === 0.0) {
		w = 0.000001;
	}

	const x = (C2 * S3 + C1 * S3 + S1 * S2 * C3) / (4.0 * w);
	const y = (S1 * C2 + S1 * C3 + C1 * S2 * S3) / (4.0 * w);
	const z = (-S1 * S3 + C1 * S2 * C3 + S2) / (4.0 * w);
	quat.set(out, x, y, z, w);
	quat.normalize(out, out);
	return out;
};

/**
 * reusing the previous quaternion as an indicator to keep perpendicularity
 *
 * @link http://matthias-mueller-fischer.ch/publications/stablePolarDecomp.pdf
 * @type {function(*, *, *): *}
 */
export const quatFromMat3AndQuat = (function() {
	const temp_mat3 = mat3.create();
	const temp_quat = quat.create();
	const Rcol0 = vec3.create();
	const Rcol1 = vec3.create();
	const Rcol2 = vec3.create();
	const Acol0 = vec3.create();
	const Acol1 = vec3.create();
	const Acol2 = vec3.create();
	const RAcross0 = vec3.create();
	const RAcross1 = vec3.create();
	const RAcross2 = vec3.create();
	const omega = vec3.create();

	return function(q, A, max_iter) {
		max_iter = max_iter || 25;
		for (let iter = 0; iter < max_iter; ++iter) {
			const R = mat3.fromQuat(temp_mat3, q);
			vec3GetMat3Column(Rcol0, R, 0);
			vec3GetMat3Column(Rcol1, R, 1);
			vec3GetMat3Column(Rcol2, R, 2);
			vec3GetMat3Column(Acol0, A, 0);
			vec3GetMat3Column(Acol1, A, 1);
			vec3GetMat3Column(Acol2, A, 2);
			vec3.cross(RAcross0, Rcol0, Acol0);
			vec3.cross(RAcross1, Rcol1, Acol1);
			vec3.cross(RAcross2, Rcol2, Acol2);
			vec3.add(omega, RAcross0, RAcross1);
			vec3.add(omega, omega, RAcross2);
			const d = 1.0 / Math.abs(vec3.dot(Rcol0, Acol0) + vec3.dot(Rcol1, Acol1) + vec3.dot(Rcol2, Acol2)) + 1.0e-9;
			vec3.scale(omega, omega, d);
			const w = vec3.length(omega);
			if (w < 1.0e-9) {
				break;
			}
			vec3.scale(omega, omega, 1 / w); //normalize
			quat.setAxisAngle(temp_quat, omega, w);
			quat.mul(q, temp_quat, q);
			quat.normalize(q, q);
		}
		return q;
	};
})();
