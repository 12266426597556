import { vec2, vec4 } from "gl-matrix";

import { DEG2RAD } from "@src/constants";
import ROOM from "@src/engine/room";
import { vec2Rotate } from "@src/gl-matrix/vec2";

function Scene2D()
{
	this.width = 100; //in pixels
	this.height = 100;

	this.offset = [ 0,0 ];
	this.scale = 1;

	this.last_layer_id = 0;

	this.root = new Scene2D.Group();
	this.root._is_root = true;
	this.root.name = "root";
	this.root._scene = this;

	this._must_update = true;
}

Scene2D.layer_types = {};


Scene2D.prototype.serialize = function()
{
	var o = {
		width: this.width,
		height: this.height,
		offset: Array.from( this.offset ),
		scale: this.scale,
		last_layer_id: this.last_layer_id,
		root: this.root.serialize()
	};

	return o;
}

Scene2D.prototype.configure = function(o)
{
	this.width = o.width;
	this.height = o.height;

	vec2.copy( this.offset, o.offset );
	this.scale = o.scale;
	this.last_layer_id = o.last_layer_id || 0;

	this.root.clear();
	if (o.root)
		this.root.configure(o.root);
	this._must_update = true;
	return o;
}

//recursive search
Scene2D.prototype.getLayer = function(id)
{
	if (id == this.root.id)
		return this.root;
	return this.root.getLayer(id);
}

/*
Scene2D.prototype.getLayerByPosition = function(pos)
{
	for(var i = 0; i < this._layers.length; ++i)
	{
		var layer = this._layers[i];
		if(layer.isInside(pos))
			return layer;
	}
	return null;
}
*/

Scene2D.prototype.numLayers = function()
{
	return this.root.numLayers();
}

Scene2D.prototype.render = function(ctx)
{
	ctx.save();
	ctx.translate( this.offset[0], this.offset[1] );
	ctx.scale( this.scale, this.scale );
	this.root.render( ctx );
	ctx.restore();
}

Scene2D.prototype.preloadResources = function()
{
	this.root.preloadResources();
}

Scene2D.getImage = function( url )
{
	return ROOM.view.loadTextureProxy( url );
}

//**********************************

function Scene2DLayer()
{
	this.id = -1;
	this.name = "";
	this.url = null;
	this.size = vec2.fromValues(1,1);

	this.position = vec2.create();
	this.angle = 0;
	this.scale = 1;

	this.opacity = 1;

	this.flags = {
		enabled: true
	};

	this._scene = null;
	this._area = vec4.create(); //bounding
}

Scene2DLayer.TYPE = 0;
Scene2D.layer_types[0] = Scene2DLayer;

Object.defineProperty( Scene2DLayer.prototype, "enabled", {
	get: function() {
		return this.flags.enabled;
	},
	set: function(v) {
		this.flags.enabled = v;
	},
	enumerable: false
});

Scene2DLayer._temp = vec2.create();

Scene2DLayer.prototype.preloadResources = function()
{
	var that = this;
	if ( this.url )
		xyz.view.loadTextureProxy( this.url, null, inner );

	function inner()
	{
		that._scene._must_update = true;
	}
}

Scene2DLayer.prototype.render = function(ctx)
{
	if (!this.url)
		return;

	var image = Scene2D.getImage( this.url );
	if (!image || image.width <= 1 || image.height <= 1 )
		return;

	ctx.save();
	ctx.translate( this.position[0], this.position[1] );
	ctx.rotate( this.angle * DEG2RAD );
	ctx.scale( this.scale, this.scale );
	ctx.globalAlpha = this.opacity;
	//centered
	ctx.drawImage( image, this.size[0] * -0.5, this.size[1] * -0.5, this.size[0], this.size[1] );
	ctx.globalAlpha = 1;
	ctx.restore();
}

Scene2DLayer.prototype.isInside = function(pos)
{
	var temp = Scene2DLayer._temp;
	this.globalToLocal(pos,temp);
	return !(temp[0] < this.size[0] * 0.5 ||
			 temp[0] > this.size[0] * 0.5 ||
			 temp[1] < this.size[1] * 0.5 ||
			 temp[1] > this.size[1] * 0.5);
}

Scene2DLayer.prototype.move = function(deltax, deltay)
{
	this.position[0] += deltax;
	this.position[1] += deltay;
	if (this._scene)
		this._scene._must_update = true;
}

Scene2DLayer.temp_tl = vec2.create();
Scene2DLayer.temp_tr = vec2.create();
Scene2DLayer.temp_bl = vec2.create();
Scene2DLayer.temp_br = vec2.create();

//computes the world bounding box of the layer
Scene2DLayer.prototype.updateArea = function()
{
	if (!this.size[0] && !this.size[1])
	{
		this._area[0] = this.position[0];
		this._area[1] = this.position[1];
		this._area[2] = 0;
		this._area[3] = 0;
		return;
	}

	var tl = this.localToGlobal([ this.size[0]*-0.5,this.size[1]*0.5 ],Scene2DLayer.temp_tl,true);
	var tr = this.localToGlobal([ this.size[0]*0.5,this.size[1]*0.5 ],Scene2DLayer.temp_tr,true);
	var bl = this.localToGlobal([ this.size[0]*-0.5,this.size[1]*-0.5 ],Scene2DLayer.temp_bl,true);
	var br = this.localToGlobal([ this.size[0]*0.5,this.size[1]*-0.5 ],Scene2DLayer.temp_br,true);

	var minx = Math.min(tl[0],tr[0],bl[0],br[0]);
	var maxx = Math.max(tl[0],tr[0],bl[0],br[0]);
	var miny = Math.min(tl[1],tr[1],bl[1],br[1]);
	var maxy = Math.max(tl[1],tr[1],bl[1],br[1]);
	this._area[0] = minx;
	this._area[1] = miny;
	this._area[2] = maxx - minx;
	this._area[3] = maxy - miny;
}

Scene2DLayer.prototype.localToGlobal = function(pos,result,skip_offset)
{
	result = result || vec2.create();
	vec2.copy( result, pos );
	vec2.scale(result, result, this.scale );
	if (this.angle)
		vec2Rotate( result, result, this.angle * DEG2RAD );
	vec2.add( result, result, this.position );
	if ( this._scene && !skip_offset)
	{
		result[0] += this._scene.offset[0];
		result[1] += this._scene.offset[1];
	}
	return result;
}

Scene2DLayer.prototype.globalToLocal = function(pos,result,skip_offset)
{
	result = result || vec2.create();
	vec2.sub( result, pos, this.position );
	if ( this._scene && !skip_offset)
	{
		result[0] -= this._scene.offset[0];
		result[1] -= this._scene.offset[1];
	}

	if (this.angle)
		vec2Rotate( result, result, -this.angle * DEG2RAD );
	vec2.scale(result, result, 1/this.scale );
	return result;
}

Scene2DLayer.prototype.serialize = function(o)
{
	o = o || {};
	o.id = this.id;
	o.name = this.name;
	o.flags = Object.assign({}, this.flags);
	o.position = Array.from( this.position );
	o.angle = this.angle;
	o.scale = this.scale;
	o.size = Array.from( this.size );
	o.url = this.url;
	o.opacity = this.opacity;
	return o;
}

Scene2DLayer.prototype.configure = function(o)
{
	this.id = o.id;
	this.flags = Object.assign({}, o.flags);
	this.name = o.name;
	vec2.copy( this.position, o.position );
	this.scale = o.scale;
	this.angle = o.angle;
	vec2.copy( this.size, o.size );
	this.url = o.url;
	this.opacity = o.opacity;
	if (this._scene)
		this._scene._must_update = true;
}

Scene2DLayer.prototype.getIndex = function()
{
	if (!this._parent)
		return -1;
	return this._parent._layers.indexOf( this );
}

Scene2D.Layer = Scene2DLayer;

//*****************************************

function Scene2DGroup()
{
	this.id = -1;
	this.name = "";
	this.flags = {
		enabled: true,
		open: true //for editor
	};

	this._scene = null;
	this._layers = [];
}

Scene2DGroup.TYPE = 1;
Scene2D.layer_types[ Scene2DGroup.TYPE ] = Scene2DGroup;

Object.defineProperty( Scene2DGroup.prototype, "enabled", {
	get: function() {
		return this.flags.enabled;
	},
	set: function(v) {
		this.flags.enabled = v;
	},
	enumerable: false
});

Scene2DGroup.prototype.getIndex = Scene2DLayer.prototype.getIndex;

Scene2DGroup.prototype.clear = function()
{
	while (this._layers.length)
		this.removeLayer( this._layers[this._layers.length-1] );
}

Scene2DGroup.prototype.render = function(ctx)
{
	//for each layer in reverse order
	var layers = this._layers;
	for (var i = layers.length - 1; i >= 0; --i)
	{
		var layer = layers[i];
		if (!layer.enabled)
			continue;
		if (layer.render)
			layer.render(ctx);
	}
}

Scene2DGroup.prototype.getLayer = function(id)
{
	var layers = this._layers;
	for (var i = 0; i < layers.length; ++i)
	{
		var layer = layers[i];
		if ( layer.id == id )
			return layer;
		if ( layer.getLayer )
		{
			var r = layer.getLayer(id);
			if (r)
				return r;
		}
	}
	return null;
}

Scene2DGroup.prototype.getAllLayers = function(result)
{
	result = result || [];
	var layers = this._layers;
	for (var i = 0; i < layers.length; ++i)
	{
		var layer = layers[i];
		result.push( layer );
		if (layer.getAllLayers)
			layer.getAllLayers(result);
	}
	return result;
}

Scene2DGroup.prototype.numLayers = function()
{
	var num = 0;
	var layers = this._layers;
	for (var i = 0; i < layers.length; ++i)
	{
		var layer = layers[i];
		if ( layer.numLayers)
			num += layer.numLayers();
	}
	return num + layers.length;
}


Scene2DGroup.prototype.addLayer = function( layer, index )
{
	if (layer._parent == this)
		return;
	if (!this._scene)
		throw ("no scene");
	if (index == null)
		index = -1;
	layer.id = this._scene.last_layer_id++;
	layer._scene = this._scene;
	layer._parent = this;
	if ( index == -1 )
		this._layers.push( layer );
	else
		this._layers.splice( index, 0, layer );
	if (this._scene)
		this._scene._must_update = true;

}

Scene2DGroup.prototype.removeLayer = function(layer)
{
	var index = this._layers.indexOf( layer );
	if (index == -1)
		return;
	this._layers.splice( index,1 );
	layer._parent = null;
	layer._scene = null;
	if (this._scene)
		this._scene._must_update = true;
}

Scene2DGroup.prototype.updateArea = function()
{
	var layers = this._layers;
	if (!layers.length)
		return false;
	for (var i = 0; i < layers.length; ++i)
	{
		var layer = layers[i];
		found |= layer.updateArea();
	}
	return found;
}

Scene2DGroup.prototype.preloadResources = function()
{
	var layers = this._layers;
	for (var i = layers.length - 1; i >= 0; --i)
	{
		var layer = layers[i];
		if (layer.preloadResources)
			layer.preloadResources();
	}
}

Scene2DGroup.prototype.move = function(deltax, deltay)
{
	for (var i = 0; i < this._layers.length; ++i)
		this._layers[i].move(deltax,deltay);
}

Scene2DGroup.prototype.serialize = function()
{
	var o = {
		id: this.id,
		name: this.name,
		class_type: Scene2DGroup.TYPE,
		flags: Object.assign({}, this.flags)
	};

	var layers = this._layers;
	o.layers = [];
	for (var i = 0; i < layers.length; ++i)
	{
		var layer = layers[i];
		var info = layer.serialize();
		info.class_type = layer.constructor.TYPE;
		o.layers.push(info);
	}

	return o;
}

Scene2DGroup.prototype.configure = function(o)
{
	this.id = o.id;
	this.name = o.name;
	this.flags = Object.assign({}, o.flags);

	if (!this._scene)
		throw ("scene missing");

	var layers = this._layers;
	layers.length = 0; //remove all layers

	for (var i = 0; i < o.layers.length; ++i)
	{
		var info = o.layers[i];
		var layer = null;
		if (!info.class_type)
			info.class_type = Scene2D.Layer.TYPE; //legacy
		var ctor = Scene2D.layer_types[info.class_type];
		if (!ctor)
			continue;
		layer = new ctor();
		layer._parent = this;
		layer._scene = this._scene;
		layers.push(layer);
		layer.configure( info );
	}

	if (this._scene)
		this._scene._must_update = true;
}

Scene2D.Group = Scene2DGroup;

ROOM.Scene2D = Scene2D;
