import { shouldUseLargeControls } from "@src/controllers/SelfView/shouldUseLargeControls";
import { ThemeBlue } from "@src/libs/GLUI/Button/ThemeBlue";
import { ThemeDark } from "@src/libs/GLUI/Button/ThemeDark";
import { ThemeLight } from "@src/libs/GLUI/Button/ThemeLight";
export const frameSize = {
    x: 110,
    y: 133,
    thickness: 4,
};
export const CommonButtonParams = {
    icon_size: shouldUseLargeControls() ? 0.6 : 0.4,
    icon_color: ThemeLight.icon, // #fff
};
export const getBackgroundColor = (isTutorial = false) => {
    return (isTutorial ? ThemeBlue : ThemeLight).background;
};
export const getHoverColor = (isTutorial = false) => {
    return (isTutorial ? ThemeBlue : ThemeLight).hover;
};
export const getLinesGradientStartColor = (isTutorial = false) => {
    return (isTutorial ? ThemeBlue : ThemeDark).background;
};
export const getResizeButtonParams = (gradientPoints, isTutorial = false) => {
    return Object.assign(Object.assign({}, CommonButtonParams), { icon_color: (isTutorial ? ThemeBlue : ThemeLight).icon, cursor: "nesw-resize", gradient: isTutorial ? undefined : Object.assign(Object.assign({}, CommonButtonParams.gradient), { points: gradientPoints }) });
};
