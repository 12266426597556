import { mat4, vec3, vec4 } from "gl-matrix";

export const VEC3_ZERO = vec3.fromValues(0,0,0);
export const VEC3_FRONT = vec3.fromValues(0,0,-1);
export const VEC3_UP = vec3.fromValues(0,1,0);
export const VEC3_RIGHT = vec3.fromValues(1,0,0);

/**
 * @note this leaves the Z as is, not modified.
 *
 * @param {vec3} a
 * @returns {vec3}
 */
export const vec3Zero = function(a)
{
	a[0] = a[1] = 0.0;
	return a;
}

/**
 * @param {vec3} a
 * @returns {Number}
 */
export const vec3MinValue = function(a) {
	if (a[0] < a[1] && a[0] < a[2]) {
		return a[0];
	}
	if (a[1] < a[2]) {
		return a[1];
	}
	return a[2];
};

/**
 * @param {vec3} a
 * @returns {Number}
 */
export const vec3MaxValue = function(a) {
	if (a[0] > a[1] && a[0] > a[2]) {
		return a[0];
	}
	if (a[1] > a[2]) {
		return a[1];
	}
	return a[2];
};

/**
 * @param {vec3} vec
 * @returns {[Number,Number,Number]}
 */
export const vec3ToArray = function(vec) {
	return [ vec[0], vec[1], vec[2] ];
};

/**
 * @param {vec3} out
 * @param {vec3} a
 * @param {Number} v
 */
export const vec3SubValue = function(out, a, v) {
	out[0] = a[0] - v;
	out[1] = a[1] - v;
	out[2] = a[2] - v;
};

/**
 * @param {vec3} out
 * @param {vec3} a
 * @param {Number} v
 */
export const vec3AddValue = function(out, a, v) {
	out[0] = a[0] + v;
	out[1] = a[1] + v;
	out[2] = a[2] + v;
};

/**
 * @deprecated
 * @use vec3.rotateX
 * @link https://glmatrix.net/docs/vec3.js.html#line564
 *
 * @param {vec3} out
 * @param {vec3} vec
 * @param {Number} angle_in_rad
 * @returns {vec3}
 */
export const vec3RotateX = function(out, vec, angle_in_rad) {
	const y = vec[1], z = vec[2];
	const cos = Math.cos(angle_in_rad);
	const sin = Math.sin(angle_in_rad);

	out[0] = vec[0];
	out[1] = y * cos - z * sin;
	out[2] = y * sin + z * cos;
	return out;
};

/**
 * @deprecated
 * @use vec3.rotateY
 * @link https://glmatrix.net/docs/vec3.js.html#line593
 *
 * @param {vec3} out
 * @param {vec3} vec
 * @param {number} angle_in_rad
 * @returns {vec3}
 */
export const vec3RotateY = function(out, vec, angle_in_rad) {
	const x = vec[0], z = vec[2];
	const cos = Math.cos(angle_in_rad);
	const sin = Math.sin(angle_in_rad);

	out[0] = x * cos - z * sin;
	out[1] = vec[1];
	out[2] = x * sin + z * cos;
	return out;
};

/**
 * @deprecated
 * @use vec3.rotateZ
 * @link https://glmatrix.net/docs/vec3.js.html#line622
 *
 * @param {vec3} out
 * @param {vec3} vec
 * @param {number} angle_in_rad
 * @returns {vec3}
 */
export const vec3RotateZ = function(out,vec,angle_in_rad)
{
	const x = vec[0], y = vec[1];
	const cos = Math.cos(angle_in_rad);
	const sin = Math.sin(angle_in_rad);

	out[0] = x * cos - y * sin;
	out[1] = x * sin + y * cos;
	out[2] = vec[2];
	return out;
}

/**
 * @deprecated
 * @use vec3.angle
 * @link https://glmatrix.net/docs/vec3.js.html#line649
 *
 * @param {vec3} a
 * @param {vec3} b
 * @returns {number}
 */
export const vec3Angle = function( a, b )
{
	return Math.acos( vec3.dot(a,b) );
}

export const vec3SignedAngle = function(from, to, axis)
{
	const unsignedAngle = vec3Angle(from, to);
	const cross_x = from[1] * to[2] - from[2] * to[1];
	const cross_y = from[2] * to[0] - from[0] * to[2];
	const cross_z = from[0] * to[1] - from[1] * to[0];
	const sign = Math.sign(axis[0] * cross_x + axis[1] * cross_y + axis[2] * cross_z);
	return unsignedAngle * sign;
}

/**
 * @deprecated
 * @use vec3.random
 * @link https://glmatrix.net/docs/vec3.js.html#line461
 *
 * @param {vec3} vec
 * @param {Number} scale
 * @returns {vec3}
 */
export const vec3Random = function(vec, scale = 1.0)
{
	vec[0] = Math.random() * scale;
	vec[1] = Math.random() * scale;
	vec[2] = Math.random() * scale;
	return vec;
}

/**
 * converts from cartesian to polar
 *
 * @param {vec3} out
 * @param {vec3} v
 * @return {vec3} returns [radius,inclination,azimuth]
 */
export const vec3CartesianToPolar = function( out, v )
{
	out = out || vec3.create();
	const x = v[0];
	const y = v[1];
	const z = v[2];
	out[0] = Math.sqrt(x*x+y*y+z*z); //radius
	out[1] = Math.asin(y/out[0]); //inclination
	out[2] = Math.atan2(x,z); //azimuth
	return out;
}

/**
 * converts from polar to cartesian
 *
 * @param {vec3} out
 * @param {vec3} v [r,lat,long] or [radius,inclination,azimuth]
 * @return {vec3} returns out
 */
export const vec3PolarToCartesian = function(out, v)
{
	const r = v[0];
	const lat = v[1];
	const lon = v[2];
	out[0] = r * Math.cos(lat) * Math.sin(lon);
	out[1] = r * Math.sin(lat);
	out[2] = r * Math.cos(lat) * Math.cos(lon);
	return out;
}

/**
 * reflects a vector over a normal
 * @param {vec3} out
 * @param {vec3} v
 * @param {vec3} n
 * @return {vec3} reflected vector
 */
export const vec3Reflect = function(out, v, n)
{
	const x = v[0];
	const y = v[1];
	const z = v[2];
	vec3.scale( out, n, -2 * vec3.dot(v,n) );
	out[0] += x;
	out[1] += y;
	out[2] += z;
	return out;
}

/**
 * @link https://github.com/hughsk/from-3d-to-2d/blob/master/index.js
 *
 * @param out
 * @param vec
 * @param mvp
 * @param viewport
 * @returns {*}
 */
export const vec3Project = function(out, vec,  mvp, viewport) {
	viewport = viewport || gl.viewport_data;

	const m = mvp;

	const ix = vec[0];
	const iy = vec[1];
	const iz = vec[2];

	const ox = m[0] * ix + m[4] * iy + m[8] * iz + m[12];
	const oy = m[1] * ix + m[5] * iy + m[9] * iz + m[13];
	const oz = m[2] * ix + m[6] * iy + m[10] * iz + m[14];
	const ow = m[3] * ix + m[7] * iy + m[11] * iz + m[15];

	const projx = (ox / ow + 1) / 2;
	const projy = 1 - (oy / ow + 1) / 2;
	const projz = (oz / ow + 1) / 2;

	out[0] = projx * viewport[2] + viewport[0];
	out[1] = projy * viewport[3] + viewport[1];
	out[2] = projz; //ow
	return out;
};

const unprojectMat = mat4.create();
const unprojectVec = vec4.create();

export const vec3UnProject = function (out, vec, viewprojection, viewport) {

	const m = unprojectMat;
	const v = unprojectVec;

	v[0] = (vec[0] - viewport[0]) * 2.0 / viewport[2] - 1.0;
	v[1] = (vec[1] - viewport[1]) * 2.0 / viewport[3] - 1.0;
	v[2] = 2.0 * vec[2] - 1.0;
	v[3] = 1.0;

	if (!mat4.invert(m,viewprojection))
		return null;

	vec4.transformMat4(v, v, m);
	if (v[3] === 0.0)
		return null;

	out[0] = v[0] / v[3];
	out[1] = v[1] / v[3];
	out[2] = v[2] / v[3];

	return out;
};

//col according to common matrix notation, here are stored as rows
export const vec3GetMat3Column = function(out, m, index )
{
	out[0] = m[index*3];
	out[1] = m[index*3 + 1];
	out[2] = m[index*3 + 2];
	return out;
}
