import SurfaceAppsContainer from "@src/engine/apps/SurfaceAppsContainer";
import Button               from "@src/libs/GLUI/Elements/Button";
import Label                from "@src/libs/GLUI/Elements/Label";

var AppSettingsDialog = {

	area: [ 0, 0, 600, 500 ],
	selected: null,
	surface: null,

	onOpen: function(editor) {
		this.surface = null;
		if ( editor.selected_item && editor.selected_item.surface )
			this.surface = editor.selected_item.surface;
		this.scroll_y = 0;
	},

	render: function(_editor)
	{
		var w = this.area[2];
		var h = this.area[3];
		var x = this.area[0] = gl.canvas.width * 0.5 - w*0.5;
		var y = this.area[1] = gl.canvas.height * 0.5 - h*0.5;

		if (GUI.Panel( x, y, w,h, "Surface apps settings", true ) === false )
			return false;

		y += 60;

		if ( !this.surface ) {
			Label.call(GUI, x + 20, y, 160, 24, "Surface component not found." );
			return;
		}

		if (!this.scroll_area )
			this.scroll_area = GUI.createScrollArea(0, 0, 0); // ?

		GUI.ScrollableArea( this.scroll_area, x + 10, y, w - 20, 400, inner.bind(this) );

		function inner(x,y,w,h)
		{
			this.scroll_y -= GUI.wheel_delta * 10;
			GUI.wheel_delta = 0;
			this.scroll_y = Math.max(0,this.scroll_y);

			y -= this.scroll_y;

			Label.call(GUI, x + 20, y, 160, 32, "Surface settings");
			y += 36;

			Label.call(GUI, x + 20, y, 160, 24, "Startup app");
			var temp = GUI.style.backgroundColor;
			GUI.style.backgroundColor = [ .2,.2,.2 ];
			var r = GUI.TextField( x + 180, y, w - 235, 24, this.surface.startup_app, 6, true, false );
			if ( GUI.value_changed ) {
				this.surface.apps_settings.startup_app = r;
				this.surface.startup_app = r;
			}

			const appNames = SurfaceAppsContainer.getAppNames();

			if ( Button.call(GUI,  x + w - 50, y, 24, 24, [ 2,0 ] ) )
			{
				appNames.unshift("");
				GUI.ShowContextMenu(appNames, (i,v) => {
					this.surface.apps_settings.startup_app = v;
					this.surface.startup_app = v;
				},"surface_apps");
			}
			y += 28;
			Label.call(GUI, x + 20, y, 160, 18, "Which app is loaded when the surface is turned on.");

			y += 50;
			Label.call(GUI, x + 20, y, 160, 32, "Apps");
			y += 36;

			for (var app_name in appNames)
			{
				var app = SurfaceAppsContainer.getAppByName(app_name);
				if ( app.onRenderInspector )
				{
					y += 10;
					Label.call(GUI, x + 20, y, 160, 24, app_name);
					y += 26;
					y = app.onRenderInspector( GUI, x, y, w, h, this.surface ) || y;
					y += 30;
				}
			}

			GUI.style.backgroundColor = temp;
		}
	}
};

export default AppSettingsDialog;
