import { quat, vec3 } from "gl-matrix";

function VolumeFogObject()
{
	this._enabled = true;
	this._on_focus = false;

	this._type = "BOX";
	this._vf = null;
	if(xyz.native_mode)
		this._vf = TmrwModule.NativeRoomAPI.VolFogBuilder();

	this._color = vec3.create();
	vec3.set(this._color, 0.5, 0.5, 0.5);

	this._densityNoiseFrequency = vec3.create();
	vec3.set(this._densityNoiseFrequency, 0.1, 0.15, 0.05);

	this._densityNoiseTile = vec3.create();
	vec3.set(this._densityNoiseTile, 0.05, 0.1, 0.05);

	this._native = null;
	this._dirty = false;

	this._index = VolumeFogObject.last_index++;
}

VolumeFogObject.componentName = "VolumeFogObject";
VolumeFogObject.last_index = 1;

VolumeFogObject.icon = [ 6,3 ];

VolumeFogObject.widgets = {
	"Type": { type: "enum", values: [ "BOX", "SPHEROID" ] },
	"Density": { type: "slider", min: 0.0, max: 2.0 },
	"Dens-Offset": { type: "slider", min: -1.0, max: 1.0, mark: 0 },
	"Soft-Edges": { type: "slider", min: 0.0, max: 1.0, mark: 0.2 },
	"HDR": { type: "slider", min: -20.0, max: 20.0, mark: 0.0 },
	"Falloff-Lati": { type: "slider", min: 0.0, max: 360.0, mark: 90 },
	"Falloff-Long": { type: "slider", min: 0.0, max: 360.0, mark: 0 },
	"Falloff-Scale": { type: "slider", min: -10.0, max: 10.0, mark: 1 },
	"Falloff-Shift": { type: "slider", min: -5.0, max: 5.0, mark: 0 },
	"Sun-Infl.": { type: "slider", min: 0.0, max: 1.0, mark: 0.5 },
	"Amb-Infl.": { type: "slider", min: 0.0, max: 1.0, mark: 0.4 },
	"Color": { type: "color" },
	"Use-Noise": { type: "toggle" },
	"Wind-Infl.": { type: "slider", min: 0.0, max: 1.0, mark: 0.5 },
	"Noise-Offs": { type: "slider", min: -2.0, max: 2.0, mark: -0.15 },
	"Noise-Scale": { type: "slider", min: 0.0, max: 10.0, mark: 2.0 },
	"Noise-Freq": { type: "vector3", min: 0.0, max: 1.0 },
	"Noise-Tile": { type: "vector3", min: 0.0, max: 1.0 },
}

Object.defineProperty(VolumeFogObject.prototype, "enabled", {
	get: function () {
		return this._enabled;
	},
	set: function (v) {
		if (this._enabled == v)
			return;
		this._dirty = true;
		this._enabled = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Type", {
	get: function () {
		return this._type;
	},
	set: function (v) {
		if (this._type == v)
			return;
		this._dirty = true;
		this._type = v;
		if(!this._vf)
			return;

		if (v == "BOX")
			this._vf.volumeType = 0;
		else
			this._vf.volumeType = 1;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Density", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.globalDensity;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.globalDensity == v)
			return;
		this._dirty = true;
		this._vf.globalDensity = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Dens-Offset", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.densityOffset;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.densityOffset == v)
			return;
		this._dirty = true;
		this._vf.densityOffset = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Falloff-Lati", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.heightFallOffDirLati;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.heightFallOffDirLati == v)
			return;
		this._dirty = true;
		this._vf.heightFallOffDirLati = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Falloff-Long", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.heightFallOffDirLong;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.heightFallOffDirLong == v)
			return;
		this._dirty = true;
		this._vf.heightFallOffDirLong = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Falloff-Scale", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.heightFallOffScale;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.heightFallOffScale == v)
			return;
		this._dirty = true;
		this._vf.heightFallOffScale = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Falloff-Shift", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.heightFallOffShift;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.heightFallOffShift == v)
			return;
		this._dirty = true;
		this._vf.heightFallOffShift = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Sun-Infl.", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.sunInfluence;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.sunInfluence == v)
			return;
		this._dirty = true;
		this._vf.sunInfluence = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Amb-Infl.", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.ambInfluence;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.ambInfluence == v)
			return;
		this._dirty = true;
		this._vf.ambInfluence = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "HDR", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.HDRDynamic;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.HDRDynamic == v)
			return;
		this._dirty = true;
		this._vf.HDRDynamic = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Soft-Edges", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.softEdges;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.softEdges == v)
			return;
		this._dirty = true;
		this._vf.softEdges = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Color", {
	get: function () {
		return this._color;
	},
	set: function (v) {
		vec3.copy(this._color, v);
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Use-Noise", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.useNoise;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.useNoise == v)
			return;
		this._dirty = true;
		this._vf.useNoise = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Wind-Infl.", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.windInfluence;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.windInfluence == v)
			return;
		this._dirty = true;
		this._vf.windInfluence = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Noise-Offs", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.densityNoiseOffset;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.densityNoiseOffset == v)
			return;
		this._dirty = true;
		this._vf.densityNoiseOffset = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Noise-Scale", {
	get: function () {
		if(!this._vf)
			return;
		return this._vf.densityNoiseScale;
	},
	set: function (v) {
		if(!this._vf)
			return;
		if (this._vf.densityNoiseScale == v)
			return;
		this._dirty = true;
		this._vf.densityNoiseScale = v;
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Noise-Freq", {
	get: function () {
		return this._densityNoiseFrequency;
	},
	set: function (v) {
		if (vec3.equals(this._densityNoiseFrequency, v))
			return;
		this._dirty = true;
		vec3.copy(this._densityNoiseFrequency, v);
	}
});

Object.defineProperty(VolumeFogObject.prototype, "Noise-Tile", {
	get: function () {
		return this._densityNoiseTile;
	},
	set: function (v) {
		if (vec3.equals(this._densityNoiseTile, v))
			return;
		this._dirty = true;
		vec3.copy(this._densityNoiseTile, v);
	}
});


VolumeFogObject.prototype.onAdded = function (parent) {
	LEvent.bind(parent, "clicked", this.onClicked, this);

	this._node = parent.node;
}

VolumeFogObject.prototype.createNative = function () {
	if (this._root && nativeEngine && !this._native) {
		if (!this._root._native) {
			// Andrey FIXME: Hack to place new volume entity in front of camera
			// Btw for some reason this camera position doesn't changed when we fly in the editor mode!
			var camera = ViewCore.instance.hard_camera;
			var cam_pos = camera.position;
			var cam_front = camera.getFront();
			var dist = 1.0;

			var offset = vec3.scale(vec3.create(), cam_front, dist);
			vec3.add(this._node.position, cam_pos, offset);
			this._node.mustUpdate = true;

			this._root.createNativeEntity(this._root.index);
		}

		this._vf.position = this._node.position;
		this._vf.scale = this._node.scaling;

		this._vf.build(this._root._native);

		this._native = this._root._native;

		this._dirty = true;
	}
}


VolumeFogObject.prototype.onRemoved = function(parent)
{
	LEvent.unbind( parent,"clicked", this.onClicked, this );

	this._node = null;
	if (this._native) {
		//nativeEngine._room.destroyEntityObj(this._native);
		this._native = null;
	}
	if (this._vf)
		this._vf.delete();
	this._vf = null;
}

VolumeFogObject.prototype.serialize = function(o)
{
	if(!this._vf)
		return;

	o.enabled = this._enabled;

	o.volumeType = this._vf.volumeType;
	o.globalDensity = this._vf.globalDensity;
	o.densityOffset = this._vf.densityOffset;
	o.HDRDynamic = this._vf.HDRDynamic;
	o.softEdges = this._vf.softEdges;
	o.heightFallOffDirLati = this._vf.heightFallOffDirLati;
	o.heightFallOffDirLong = this._vf.heightFallOffDirLong;
	o.heightFallOffScale = this._vf.heightFallOffScale;
	o.heightFallOffShift = this._vf.heightFallOffShift;
	o.sunInfluence = this._vf.sunInfluence;
	o.ambInfluence = this._vf.ambInfluence;
	o.color = this._vf.color;
	o.useNoise = this._vf.useNoise;
	o.windInfluence = this._vf.windInfluence;
	o.densityNoiseOffset = this._vf.densityNoiseOffset;
	o.densityNoiseScale = this._vf.densityNoiseScale;
	o.densityNoiseFrequency = this._densityNoiseFrequency;
	o.densityNoiseTile = this._densityNoiseTile;
}

VolumeFogObject.prototype.configure = function(o)
{
	if(!this._vf)
		return;

	if(o.enabled === undefined)
		return; //problems with legacy
		
	this._enabled = o.enabled;

	this._vf.volumeType = o.volumeType;
	this._vf.globalDensity = o.globalDensity;
	this._vf.densityOffset = o.densityOffset;
	this._vf.HDRDynamic = o.HDRDynamic;
	this._vf.softEdges = o.softEdges;
	this._vf.heightFallOffDirLati = o.heightFallOffDirLati;
	this._vf.heightFallOffDirLong = o.heightFallOffDirLong;
	this._vf.heightFallOffScale = o.heightFallOffScale;
	this._vf.heightFallOffShift = o.heightFallOffShift;
	this._vf.sunInfluence = o.sunInfluence;
	this._vf.ambInfluence = o.ambInfluence;
	this._vf.useNoise = o.useNoise;
	this._vf.windInfluence = o.windInfluence;
	this._vf.densityNoiseOffset = o.densityNoiseOffset;
	this._vf.densityNoiseScale = o.densityNoiseScale;
	this._densityNoiseFrequency = o.densityNoiseFrequency;
	this._densityNoiseTile = o.densityNoiseTile;

	var c = vec3.create();
	c[0] = o.color[0];
	c[1] = o.color[1];
	c[2] = o.color[2];
	this._vf.color = c;
	this._color = c;

	this._vf.densityNoiseFrequency = this._densityNoiseFrequency;
	this._vf.densityNoiseTile = this._densityNoiseTile;

	this._dirty = true;

	if (this._vf.volumeType == 0)
		this._type = "BOX";
	else
		this._type = "SPHEROID";

	this._index = VolumeFogObject.last_index++;
}

VolumeFogObject.prototype.onClicked = function(e)
{
	//console.debug("clicked",this.entity.name);
	if (!this._on_focus)
		this.enterFocus();
	else //leaving
		this.exitFocus();
}


VolumeFogObject.prototype.preRender = function(view)
{
	if (!this._native) {
		if (xyz.native_loaded)
			this.createNative();
	}
	if (!this._native) {
		return;
	}

	if(!this._vf)
		return;

	if (this._dirty || !vec3.equals(this._color, this._vf.color)) {

		this._dirty = false;

		this._vf.color = this._color;
		this._vf.densityNoiseFrequency = this._densityNoiseFrequency;
		this._vf.densityNoiseTile = this._densityNoiseTile;

		this._vf.position = this._node.position;
		this._vf.scale = this._node.scaling;

		this._vf.build(this._native);
	}
}


VolumeFogObject.prototype.enterFocus = function()
{
	if (this._on_focus)
		return;

	LEvent.bind( this.space, "keydown", this.onKey, this );
	LEvent.bind( this.space, "mouse", this.onGlobalMouse, this );

	//compute radius
	var original_pos = this.entity.node.getGlobalPosition();
	var bb = node.updateBoundingBox();
	var center = BBox.getCenter(bb);
	var halfsize = BBox.getHalfsize(bb);
	vec3.mul( halfsize, halfsize, node.scaling );
	var radius = vec3.length( halfsize );// / this.entity.node.scaling[0];
	console.debug(radius);
	radius *= 1.5;
}

VolumeFogObject.prototype.exitFocus = function()
{
	if (!this._on_focus)
		return;

	LEvent.unbind(this.space, "keydown", this.onKey, this);
	LEvent.unbind(this.space, "mouse", this.onGlobalMouse, this);

	this._on_focus = false;
}

VolumeFogObject.prototype.onKey = function(type,e)
{
	if (e.key == "Escape")
	{
		this.exitFocus();
	}
	return true;
}

VolumeFogObject.prototype.onGlobalMouse = function(type,e)
{
	//console.debug(e);
	if (!this._on_focus)
		return;

	var speed = 0.02;

	if ( e.dragging )
	{
		//var node = this.entity.node;
		//var node = this._inspect_node;
		//var local_up = node.globalVectorToLocal( [0,1,0] );
		//var local_right = node.globalVectorToLocal( [1,0,0] );
		//node.rotate( e.deltax * speed, local_up );
		//node.rotate( e.deltay * speed, local_right );
	}

	if ( e.dragging && e.type == "mousemove")
		return true;
}

export default VolumeFogObject;

