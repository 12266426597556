// returns if the button was pressed
const Button = function(x, y, w, h, content, enabled, color, color_hover, border)
{
    this.blocked_areas.push([ x,y,w,h ]);

    let hover = this.isInsideRect( this.mouse.position, x,y,w,h );

    //in case the context menu is over
    const blocked_by_context_menu = this.isHoverContextMenu(this.mouse.position);

    //do not display it as hover
    if ( hover && blocked_by_context_menu)
        hover = false;

    if (hover)
        this.tooltip = this.next_tooltip;
    this.next_tooltip = null;

    const ctx = this.context;
    if (hover)
        this.cursor = "pointer";

    if (color !== 0) //color 0 means no bg
    {
        ctx.fillColor = color || this.style.backgroundColor;
        if (hover)
            ctx.fillColor = color_hover || this.style.backgroundColor_hover;
        else if (enabled)
            ctx.fillColor = color_hover || [ 0.9,0.9,0.9,ctx.globalAlpha ];

        if ( ctx.fillColor[3] > 0)
        {
            if (border == -2 && content && content.constructor === Array ) //bg icon
            {
                if (this._icons_texture)
                {
                    ctx.tintImages = true;
                    ctx.drawImage( this._icons_texture, 0, 0, this.icon_size,this.icon_size, x, y, w, h );
                    ctx.tintImages = false;
                }
            }
            else
            {
                ctx.beginPath();
                if (border == -1)
                    ctx.arc(x+w*0.5,y+h*0.5,h*0.5,0,Math.PI*2);
                else if (border == 0)
                    ctx.rect(x,y,w,h);
                else
                    ctx.roundRect(x,y,w,h, [ border || this.style.borderRadius ]);
                ctx.fill();
            }
        }
    }

    if (content != null)
    {
        if (content.constructor === String)
        {
            ctx.textAlign = "center";
            ctx.font = ((h * 0.75)|0) + "px Arial";
            ctx.fillColor = enabled ? [ 1 - this.style.color[0],1 - this.style.color[1],1 - this.style.color[2] ] : this.style.color;
            ctx.fillText(content,x+w*0.5,y + h*0.75);
        }
        else
        {
            this.DrawIcon(x+w*0.5,y+h*0.5,h/80,content,hover || enabled );
        }
    }

    if ( !blocked_by_context_menu && !this.cancel_next_mouseup && this.last_mouseup_pos && this.isInsideRect( this.last_mouseup_pos, x,y,w,h ) )
    {
        this.consumeClick();
        return true;
    }
    return false;
}

export default Button;
