import RoomCropModes from "@src/engine/Room/RoomCropModes";
import XYZLauncher   from "@src/XYZLauncher";

//this module controls the render quality automatically
function Autoperformance(xyz )
{
	this.xyz = xyz;
	console.debug("autoperformance init");
	this.control_cutout = true;

	this.view = xyz.view;
	this.fps_threshold = 20;
	this.misses_limit = 10;
	this.misses = 0;

	LEvent.bind( xyz.view, "fps_updated", this.onFPSUpdated, this );
}

Autoperformance.prototype.onFPSUpdated = function()
{
	//already in lowest possible
	if (this.view.render_quality === 1)
		return;

	//fps are good
	if (this.view.fps > this.fps_threshold)
	{
		//decrease misses
		this.misses = Math.max(0,this.misses - 1);
		return;
	}

	//fps are not good
	//console.debug("missed");
	this.misses++;

	//check how many misses
	if ( this.misses < this.misses_limit)
		return;

	//too many times slow
	this.reduceQuality();
}

Autoperformance.prototype.reduceQuality = function()
{
	//reset counters
	this.misses_limit = 5;
	this.misses = 0;

	//reduce quality
	if (this.view.render_quality > 1)
	{
		this.view.render_quality = this.view.render_quality - 1;
		LEvent.trigger( this.view, "quality_reduced" );
	}

	if ( this.view.render_quality === 1 && this.control_cutout )
	{
		window.CUTOUT_ENABLE = false;
		this.xyz.space.local_participant.crop_mode = RoomCropModes.CIRCLE;
	}
	else
	{
		window.CUTOUT_ENABLE = true;
		this.xyz.space.local_participant.crop_mode = RoomCropModes.GRADIENT;
	}
}

XYZLauncher.registerModule( Autoperformance, "Autoperformance" );
window.Autoperformance = Autoperformance;
