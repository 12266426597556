//used to store info about what has the user

function Inventory(participant)
{
	this.participant = participant;

	this.items = [];
	this.items_by_id = {};
}

export { Inventory };
