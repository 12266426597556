import ROOM from "@src/engine/room";
import { getFullPath } from "@src/engine/Room/file-utils";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";
import { RD } from "@src/libs/rendeer";
import { Gizmo } from "@src/libs/rendeer-gizmo";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { vec3 } from "gl-matrix";

//Import objects from thirth party sites
function ImportObject()
{
	this._enabled = true;
	this._url = "";
	this._transform = new Float32Array(10);
	this._origin_url = null;
	this.scale = 1; //in case we want to make objects small

	this._mode = ""; //"manipulate","menu",""

	this._object_loaded = false;
	this._node = new SceneNode();
	this._prefab_node = new SceneNode({ id: "imported_prefab_root" });
	this._node.addChild( this._prefab_node );
}

ImportObject.componentName = "ImportObject";
ImportObject.icon = [ 2,4 ];
ImportObject.type = ROOM_TYPES.PREFAB;
ImportObject.default_glb = "interaction/model-import.glb";

ImportObject.getImporter = function( source )
{
	if (source.indexOf("sketchfab") !== -1 )
	{
		//if (!SketchfabImporter.instance)
		//	SketchfabImporter.instance = new SketchfabImporter();
		//return SketchfabImporter.instance;
		return null;
	}

	return null;
}

Object.defineProperty( ImportObject.prototype, "enabled", {
	get: function() {
		return this._enabled;
	},
	set: function(v) {
		this._enabled = v;
		this._prefab_node.flags.visible = v;
	}
});

Object.defineProperty( ImportObject.prototype, "url", {
	get: function() {
		return this._url;
	},
	set: function(v) {
		if (v !== null)
			v = v.trim();
		if (v === this._url)
			return;
		this._url = v;
	}
});

ImportObject.prototype.onAdded = function(parent)
{
	parent.node.addChild( this._node );

	if (!this._url)
		this.reset();

	LEvent.bind( parent.space, "renderCallGizmos", this.renderCallGizmos, this );
	LEvent.bind( parent.space, "mouse", this.onCallMouse, this );
	LEvent.bind( parent.space, "renderUI", this.onRenderUI, this );
	LEvent.bind( parent.space, "keydown", this.onKeyDown, this );

}

ImportObject.prototype.onRemoved = function(parent)
{
	parent.node.removeChild( this._node );

	LEvent.unbind( parent.space, "renderCallGizmos", this.renderCallGizmos, this );
	LEvent.unbind( parent.space, "mouse", this.onCallMouse, this );
	LEvent.unbind( parent.space, "renderUI", this.onRenderUI, this );
	LEvent.unbind( parent.space, "keydown", this.onKeyDown, this );
}

//shows the sketch fab icon again
ImportObject.prototype.reset = function(skip_icon)
{
	this._origin_url = null;
	this._node.resetTransform();
	this._prefab_node.clear();
	this._prefab_node.position = [ 0,0,0 ];
	this._prefab_node.scaling = [ 0.1,0.1,0.1 ];
	//if (!skip_icon)
	//	this._prefab_node.loadGLTF( getFullPath( ImportObject.default_glb ) );
	this._object_loaded = false;
}

ImportObject.prototype.serialize = function(json)
{
	json.enabled = this._enabled;
	json.url = this._url;
	json.transform = typedArrayToArray(this._transform);
	json.origin_url = this._origin_url;
	json.scale = this.scale;
	return json;
}

ImportObject.prototype.configure = function(json)
{
	if (json.enabled !== undefined)
		this.enabled = json.enabled;
	this.url = json.url;
	if (json.transform)
		this._transform.set( json.transform );

	this.scale = json.scale || 1;

	if (json.origin_url && this._origin_url !== json.origin_url)
		this.downloadFromSketchfab( json.origin_url );
}

ImportObject.prototype.showPopup = function()
{
	var importer = ImportObject.getImporter("sketchfab");
	if (!importer)
		return;
	importer.showSearchInterface( this );
	ROOM.lockMouse(false);
}

ImportObject.prototype.renderCallGizmos = function(e, view)
{
	if (!this._enabled || !this._origin_url || this._mode !== "manipulate" )
		return;

	if (!this._gizmo)
	{
		this._gizmo = new Gizmo();
		this._gizmo.mode = Gizmo.MOVEAXIS | Gizmo.ROTATEALL | Gizmo.SCALE;
	}

	this._gizmo.setTargets([ this._node ]);
	this._gizmo.render( view.renderer, view.renderer._camera );
}

ImportObject.prototype.onCallMouse = function( event_type, e )
{
	if (!this._enabled || !this._object_loaded )
		return;

	var r;

	if (this._mode !== "" && e.type === "mouseup" && e.click_time < 250 )
	{
		this._mode = "";
		return true;
	}

	if (this._mode === "manipulate" )
	{
		if ( this._gizmo )
			r = this._gizmo.onMouse(e);
	}
	else if (this._mode === "rotate" )
	{
		if ( e.dragging )
		{
			this._node.rotate(e.deltax * 0.01,[ 0,1,0 ]);
			r = true;
		}
	}
	else if (this._mode === "scale" )
	{
		if ( e.dragging )
		{
			this._node.scale( 1 + e.deltax * 0.01);
			r = true;
		}
	}


	if ( r ) //user did something with the gizmo
		this.syncData({
			action: "transform",
			transform: typedArrayToArray( this._node.transform )
		});

	return r;
}

ImportObject.prototype.onKeyDown = function( event_type, e )
{
	if (!this._enabled || this._mode === "" || !this._object_loaded )
		return;

	if (e.code === "KeyQ" || e.code === "Escape" )
	{
		this._mode = "";
		return true;
	}
}

ImportObject.prototype.onRenderUI = function( e, view )
{
	if (this._mode === "menu" )
	{
		var options = [ {
			type: "trash",
			icon: GLUI.icons.trash
		},{
			type: "rotate",
			icon: [ 1,5 ]
		},{
			type: "scale",
			icon: [ 2,5 ]
		},{
			type: "manipulate",
			icon: [ 7,2 ]
		} ];

		var v = GUI.RadialMenu(options);
		if (v === -1 )
			this._mode = "";
		else if ( v ) //something clicked
		{
			if (v.type === "trash" )
			{
				this.reset();
				this._mode = "";
				this.syncData({
					action: "change_url",
					url: ""
				});
			}
			else if (v.type === "manipulate" )
			{
				this._mode = "manipulate";
			}
			else if (v.type === "rotate" )
			{
				this._mode = "rotate";
			}
			else if (v.type === "scale" )
			{
				this._mode = "scale";
			}
		}
	}
	else if ( this._mode )
	{
		//exit
		if ( GUI.CircleIconButton( gl.canvas.width - 70,20, GLUI.icons.x, 1 ) )
			this._mode = "";
	}
}


//editor
ImportObject.prototype.renderGizmo = function( view, editor, selected )
{
	var node = this._prefab_node;
	var aabb = node.updateBoundingBox();

	gl.disable( gl.DEPTH_TEST );
	view.renderer.renderBounding( aabb, null, [ 4,4,0,1 ] );
	gl.enable( gl.DEPTH_TEST );
}

ImportObject.prototype.onRenderInspector = function( ctx, x,y,w,h, editor )
{
	Label.call(GUI, x,y,w,20,"Scale");
	this.scale = GUI.Number( x + 100,y,w - 100,20, this.scale );
	y+=24;

	if ( Button.call(GUI, x,y,w,20, "Search" ) )
		this.showPopup();
	y+=24;

	if ( Button.call(GUI, x,y,w,20, "Reset" ) )
		this.reset();
	y+=24;

	if ( Button.call(GUI, x,y,w,20, "Rescale" ) )
		this.rescale();
	y+=24;
}

ImportObject.prototype.processClick = function(e)
{
	if ( !this._object_loaded )
	{
		//show menu to select prefab
		this.showPopup();
		return;
	}

	//show menu
	if (this._mode === "")
	{
		ROOM.space.local_participant.lookAt( this.entity );
		this._mode = "menu";
	}
	else if (this._mode === "menu")
		this._mode = "";
	else if (this._mode === "manipulate")
		this._mode = "";
}

ImportObject.prototype.getInteractiveNodes = function(container)
{
	container.push(this._node);
}

ImportObject.prototype.update = function(dt,t)
{
	var prefab_root = this._prefab_node.children[0];

	if (!prefab_root || !prefab_root.animations || !prefab_root.animations.length)
		return;

	var anim = prefab_root.animations[0]; //first one as default
	var time = t % anim.duration;
	//changes transforms acording to animated nodes
	anim.applyAnimation( prefab_root, time, RD.LINEAR );
	//updates bones in case of skinned meshes
	prefab_root.updateSkinningBones();
}

//not used now
ImportObject.prototype.setPrefabUrl = function(url, skip_event)
{
	return;
	
	var root_node = this._prefab_node;
	root_node.clear();
	root_node.scaling = [ 1,1,1 ];
	root_node.loadGLTF( getFullPath( url ) );

	if (!skip_event)
		this.syncData({
			action: "change_url",
			source: source,
			url: url
		});
}

ImportObject.prototype.setPrefabNode = function( node, origin_url, source, skip_event )
{
	var root_node = this._prefab_node;
	root_node.clear();
	root_node.scaling = [ 0.001,0.001,0.001 ]; //millimeters to meters
	root_node.position = [ 0,0,0 ];
	root_node.addChild( node );

	this._object_loaded = true;
	this._origin_url = origin_url;
	this.rescale();

	if (!skip_event)
		this.syncData({
			action: "change_url",
			source: source,
			url: origin_url
		});
}

ImportObject.prototype.rescale = function()
{
	if (!this.entity)
		return;

	// normalize scale
	var node = this._prefab_node;
	node.scaling = [ 1,1,1 ];
	node.position = [ 0,0,0 ];
	node.updateGlobalMatrix(false,true);

	var aabb = node.updateBoundingBox();
	const target_radius = this.scale * this.entity.node.scaling[0];
	console.debug( "scale", aabb[12], "pos",aabb.subarray(0,3) );
	//return;

	const new_scale = target_radius / aabb[12];
	node.scaling = [ new_scale, new_scale, new_scale ];

	var center = this._node.getGlobalPosition();
	vec3.sub( center, center , aabb ); //aabb starts with the center
	vec3.mul( center,center,node.scaling );//compensate
	node.position = center; //BBox.getCenter( aabb );

	node.updateSkinningBones();
}

//get message from other user interacting with this object
ImportObject.prototype.onSyncData = function(data)
{
	if (data.action === "change_url" )
	{
		if (data.source === "sketchfab" )
		{
			this.downloadFromSketchfab( data.url, null, true );
		}
		else
			this.reset();
	}
	else if (data.action === "transform" )
	{
		this._node.transform = data.transform;
	}
}

//download and assigns, do not trigger event
ImportObject.prototype.downloadFromSketchfab = function(url, callback, skip_event )
{
	var that = this;

	//change icon
	this.reset(true);

	//download
	var importer = ImportObject.getImporter("sketchfab");
	importer.downloadModel( url ).then( function(node) {
		that.setPrefabNode( node, url, "sketchfab", skip_event );
		if (callback)
			callback(node);
	}).catch(function() {
		console.error("problem loading sketchfab model");
		that.reset();
	});
}

export default ImportObject;
