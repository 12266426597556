import { SurfaceOutputModeType } from "@src/engine/apps/SurfaceOutputModeType";
export class SurfaceApp {
    constructor() {
        this.app_name = "Unnamed";
        this.render_always = false;
        this.resolution_factor = 1;
        this.progressive = false;
        this.width = 1;
        this.height = 1;
        this.canvas = null;
        this.ctx = null;
        this.icons = null;
    }
    onEnter() {
        // override as necessary
    }
    onLeave() {
        // override as necessary
    }
    render(ctx, output, gui, mouse, viewport) {
        // override as necessary
    }
    renderGlobalUI(gl, gui) {
        // override as necessary
    }
    /**
     * @deprecated
     */
    onAssignFeed(feed) {
        throw new Error("Deprecated, do not use");
    }
    update(delta_time, time, mouse) {
        // override as necessary
    }
    onMouseEnter(e) {
        // override as necessary
    }
    onMouseLeave(e) {
        // override as necessary
    }
    onMouse(e, active) {
        // override as necessary
    }
    onKeyDown(e, active) {
        // override as necessary
    }
    JSONToState(state) {
        // override as necessary
    }
}
SurfaceApp.output_mode = SurfaceOutputModeType.Canvas;
