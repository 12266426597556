import BaseComponent from "@src/engine/components/baseComponent";
import Entity from "@src/engine/entity";
import ROOM from "@src/engine/room";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { LEvent } from "@src/libs/LEvent";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import Stream from "@src/libs/stream";
import { XYZRegisterModule } from "@src/xyz/XYZRegisterModule";
import { vec3 } from "gl-matrix";

//Module for XYZLauncher
//In charge of syncing all clients
//Created automatically on XYZLauncher startup
function NetworkSync(xyz)
{
	NetworkSync.instance = this;

	this.enabled = true;
	this.xyz = xyz;
	this.sync_rate = NetworkSync.FRAMES_PER_SYNC; //send sync every N frames, 0 means all
	this.bridge = null;
	this.use_p2p = false; //if true it will send sync through p2p, otherwise through broadcast
	this.send_ping = false;
	this.filter_repeated_messages = true;

	if ( xyz.bridge )
		this.onBridgeAvailable( xyz.bridge );

	xyz.space.network = this;
	this.frame = 0;

	this.onP2PIN = null;
	this.onBroadcastIN = null;
	this.interval_timer = setInterval( this.onTick.bind(this), NetworkSync.TICK_INTERVAL );

	LEvent.bind(xyz.space, "participant_enter", this.onNewParticipant, this);

	this._last_state_message = null;
	this.room_state_synced = false;
	this.local_participant;
}

//silly bom
NetworkSync.SIGNATURE = 0xFAFE1337;

//event payloads
NetworkSync.UNKNOWN = 0;
NetworkSync.ROOM_STATUS = 1;
NetworkSync.PARTICIPANT = 2;
NetworkSync.CUSTOM = 10;
NetworkSync.ENTITY = 3;

NetworkSync.FRAMES_PER_SYNC = 12;
NetworkSync.TICK_INTERVAL = 2000;

NetworkSync.registered_event_handlers = {};

NetworkSync.registerEventHandler = function(event_type, callback)
{
	NetworkSync.registered_event_handlers[ event_type ] = callback;
}

//ticks are every second, not every frame
NetworkSync.prototype.onTick = function()
{
	if ( this.send_ping )
		this.send({ type:"ping" });
	LEvent.trigger( this.xyz.space, "tick" );
}

NetworkSync.prototype.onNewParticipant = function(ev, participant)
{
	this.forceStateSend();

	if ( !this.local_participant )
	{
		this.local_participant = this.xyz.space.local_participant;
		LEvent.trigger( this.xyz.space, "local_participant_added" );
	}

	if ( !this.room_state_synced )
	{
		if ( participant != this.local_participant )
		{
			if ( participant.user && participant.user.enterTimestamp < this.local_participant.user.enterTimestamp )
			{
				this.getRoomStateFromOtherParticipant( participant );
			}
		}
	}
}

NetworkSync.prototype.getRoomStateFromOtherParticipant = function( participant )
{
	if ( this.room_state_synced )
		return;

	this.bridge.notify( "EVENT_BCAST_OUT", JSON.stringify({
		type: "director",
		action: "ping",
		subject: "surfaces_state",
		to_participant: participant.id,
		from_participant: this.local_participant.id }) );

	this.room_state_synced = true;
}

NetworkSync.prototype.forceStateSend = function()
{
	this._last_state_message = null;
}

NetworkSync.prototype.onBridgeAvailable = function(bridge)
{
	if (this.bridge == bridge)
		return;

	this.bridge = bridge;

	LEvent.bind( this.xyz.space, "update", this.update, this );

	bridge.subscribe( "EVENT_P2P_IN", this.processP2PIN.bind(this) );
	bridge.subscribe( "EVENT_BCAST_IN", this.processBroadcastIN.bind(this) );

	//send hello
	this.send({ "msg":"hello from NetworkSync" });
}

//sends a message
NetworkSync.prototype.send = function( msg )
{
	if (!this.bridge)
		return;

	var bridge = this.bridge;
	if (msg.constructor !== String)
		msg = JSON.stringify( msg );
	bridge.notify( "EVENT_BCAST_OUT", msg );
}

//called from update
NetworkSync.prototype.update = function(dt)
{
	if (!this.xyz.bridge || !this.enabled)
		return;

	this.frame++;

	//send info to all clients about current user status
	if ( !this.sync_rate || (this.frame % this.sync_rate) == 0 )
		this.syncUserStatus(dt);
}

NetworkSync.prototype.syncUserStatus = function(dt)
{
	var xyz = this.xyz;
	var bridge = xyz.bridge;
	if (!bridge)
		return;
	var space = xyz.space;
	var participant = space.local_participant;

	if (participant && participant.is_hidden)
		return; //hidden users are not sent

	if ( this.use_p2p )
	{
		var stream = this.getSyncStream();
		if ( stream.isEmpty() == false )
		{
			var payload = stream.finalize(); //end and cut
			xyz.bridge.notify( "EVENT_P2P_OUT", payload ); //send to all peers
		}
	}
	else
	{
		var payload = this.getSyncObject();
		var str = JSON.stringify( payload );
		if ( str != this._last_state_message || !this.filter_repeated_messages )
		{
			this._last_state_message = str;
			xyz.bridge.notify( "EVENT_BCAST_OUT", payload ); //send to all peers
		}
	}

}

NetworkSync.prototype.getSyncStream = function()
{
	var space = this.xyz.space;

	if (!this._stream)
		this._stream = new Stream(ROOM_SETTINGS.globals.video_width);
	var stream = this._stream;
	stream.reset();

	//BOM to check
	this._stream.writeUint32( NetworkSync.SIGNATURE );

	//gather data from user
	var participant = space.local_participant;
	if (!participant)
		return;
	if ( participant.id )
	{
		stream.writeUint16( NetworkSync.PARTICIPANT );
		stream.pushBlockSize(); //here we will store the block size
		stream.writeString( participant.id, 64 );
		participant.stateToStream( stream );
		stream.popBlockSize(); //here we are storing the offset
	}

	LEvent.trigger( space, "store_stream", stream );

	return stream;
}

//called from
NetworkSync.prototype.processP2PIN = function(payload)
{
	var xyz = this.xyz;
	var space = xyz.space;

	var stream = new Stream( payload );

	var signature = stream.readUint32();
	if (signature !== NetworkSync.SIGNATURE)
		return; //not for me

	while ( !stream.eof() )
	{
		var msg_type = stream.readUint16();
		var block_size = stream.readUint16();
		var index = stream.index;

		if ( msg_type === NetworkSync.PARTICIPANT )
		{
			var id = stream.readString();
			if (id)
			{
				var participant = space.getParticipant(id);
				if (participant)
					participant.streamToState( stream );
			}
		}
		else
		{
			LEvent.trigger( space, "read_stream_block", { type: msg_type, stream: stream } );
			if (this.onP2PIN)
				this.onP2PIN( msg_type, stream );
		}

		stream.index = index + block_size; //allows to skip blocks independent of how it was handled to avoid chain errors
	}

}

//processes messages received from other users
NetworkSync.prototype.processBroadcastIN = function( payload )
{
	var xyz = this.xyz;
	var space = xyz.space;

	if ( payload.constructor === String && payload[0] === "{" )
		payload = JSON.parse( payload );

	//trigger in room
	//LEvent.trigger( this.xyz.space, "BROADCAST", payload ); //done in xyzlauncher.setbridge
	//LEvent.trigger( this, "BROADCAST", payload ); //trigger here too
	if (this.onBroadcastIN) //old way
		this.onBroadcastIN( payload );

	var msg = payload;
	if (!msg.type)
		return;

	var handler = NetworkSync.registered_event_handlers[msg.type];
	if (!handler) //could be handled by other module
	{
		//console.error( " * unknown event in data channel", msg.type );
		return;
	}

	handler(msg, space);

	try
	{
	}
	catch (err)
	{
		console.error("error processing event",err);
	}
}


//SENDES and RECEIVERS

//sends
NetworkSync.prototype.syncItem = function( item, tween )
{
	if (!item.name)
		throw ("cannot sync item without name");

	if (!item.serialize)
		throw ("cannot sync item without serialize()");

	//processed in NetworkSync.prototype.processBroadcastIN
	var json = item.serialize();
	var msg = {
		type: "sync_item",
		item_type: item.constructor.name,
		item_name: item.name,
		item_data: json,
		tween: tween || false
	};
	this.send( msg );
}

//to sync changes in your scene with other users
NetworkSync.registerEventHandler("sync_item", function( msg, space )
{
	if ( msg.item_type === "Entity" && msg.item_data )
	{
		//find entity
		var ent = space.getEntity( msg.item_name );
		if (ent) //if exists, configure
		{
			if ( msg.tween && msg.item_data.position )
			{
				var pos = msg.item_data.position;
				var rot = msg.item_data.rotation;
				delete msg.item_data.position;
				delete msg.item_data.rotation;
				msg.item_data.position = vec3.clone( ent.position );
				msg.item_data.rotation = vec3.clone( ent.rotation );
				ent.configure( msg.item_data );
				Tween.easeProperty( ent, "position", pos, 0.5 );
				Tween.easeProperty( ent, "rotation", rot, 0.5 );
			}
			else
				ent.configure(msg.item_data);
		}
		else //create if it doesnt exists
		{
			ent = new Entity();
			ent.name = msg.item_name;
			ent.configure(msg.item_data);
			space.addEntity( ent );
		}
	}
	else if ( msg.item_type === "SceneNode" && msg.item_data ) //to sync specific scene node
	{
		var node = space.scene.root.findNodeByName(msg.item_name);
		if (node)
			node.configure(msg.item_data);
	}
	else if ( msg.item_type === "Participant" && msg.participant ) //to sync participant state
	{
		var participant = space.getParticipant( msg.participant.id );
		if (participant)
			participant.JSONToState( msg.participant );
	}
});

//for events triggered by users
//sends event to all users
NetworkSync.prototype.syncEvent = function( target, event_type, params, skip_feedback )
{
	if (!target.getLocator)
	{
		console.error("Target doesnt have a locator");
		return;
	}

	if (!skip_feedback)
		LEvent.trigger(target, event_type, params );

	if (!this.bridge)
		return;

	var author_name = this.xyz.space.local_participant.getUsername();

	var locator = target.getLocator();
	var msg = {
		type: "sync_event",
		target_locator: locator,
		event_type: event_type,
		params: params,
		author: author_name
	};

	//replicate
	this.send( msg );
}

NetworkSync.registerEventHandler("sync_event", function( msg, space ) {

	console.debug(" * event "+ msg.event_type +" by",msg.author);
	var target = ROOM.get( msg.target_locator );
	if (target)
		LEvent.trigger( target, msg.event_type, msg.params );
});

//for minor things (like scene markers)
NetworkSync.prototype.syncGenericObject = function(type, obj)
{
	var msg = {
		type: "sync_obj",
		obj_type: type,
		data: obj
	};
	this.send( msg );
}

NetworkSync.registerEventHandler("sync_obj", function( msg, space ) {
	if ( msg.obj_type === "marker" )
	{
		var marker = msg.data;
		marker.time = getTime() * 0.001 + marker.duration; //addapt time
		space.markers.push( marker );
	}
});


NetworkSync.prototype.getSyncObject = function()
{
	var space = this.xyz.space;
	var participant = space.local_participant;
	if (!participant)
		return;

	var o = {
		type: "sync_item",
		item_type: "Participant"
	};

	if ( participant.id )
		o.participant = participant.stateToJSON();

	LEvent.trigger( space, "store_json", o );

	return o;
}

//to send data between components
NetworkSync.prototype.syncData = function( target, data )
{
	if (!target.getLocator)
		throw ("cannot sync target without getLocator");

	var locator = target.getLocator();

	//processed in NetworkSync.prototype.processBroadcastIN
	var msg = {
		type: "sync_data",
		target: locator,
		data: data
	};
	this.send( msg );
}

//to send info between entities and components
NetworkSync.registerEventHandler("sync_data", function( msg, space ) {
	if (!msg.target)
		return;
	var target = ROOM.get( msg.target );
	if (!target || !target.onSyncData )
		return;

	target.onSyncData( msg.data, msg );
});

//to send data between components
BaseComponent.addNewMethod( "syncData", function(data)
{
	if (!this.space || !this.space.network)
		return;
	this.space.network.syncData(this,data);
});

//sync nodes
SceneNode.prototype.sync = function()
{
	var ent = this.getParentEntity();
	if (!ent)
		return;
	if (!ent.space || !ent.space.network)
		return;
	ent.space.network.syncItem(this);
}

XYZRegisterModule( NetworkSync, "NetworkSync" );

LEvent.triggerSync = function( target, event_type, params )
{
	if (!target.getLocator)
	{
		console.error("Target doesnt have a locator");
		return;
	}

	var network = NetworkSync.instance;
	if (!network || !network.bridge)
		return;

	if (!ROOM.space.local_participant)
		return;

	var author_name = ROOM.space.local_participant.getUsername();
	var locator = target.getLocator();

	var msg = {
		type: "sync_event",
		target_locator: locator,
		event_type: event_type,
		params: params,
		author: author_name
	};

	//send
	network.send( msg );
};

window.NetworkSync = NetworkSync;
