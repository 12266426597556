import ROOM from "@src/engine/room";
import { getFullPath } from "@src/engine/Room/file-utils";
import FXGlow from "@src/libs/Fx/FXGlow";
import { GL } from "@src/libs/litegl";
import { RD } from "@src/libs/rendeer";
import { Camera } from "@src/libs/rendeer/Camera";
import { Material } from "@src/libs/rendeer/Material";
import { Ray } from "@src/libs/rendeer/Ray";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { testRayWithNodes } from "@src/libs/rendeer/testRayWithNodes";
import clamp from "@src/math/clamp";
import { vec2, vec3, vec4 } from "gl-matrix";

/**
 * The View of a ROOM
 * In charge of rendering and grabbing events
 * @param {XYZLauncher} launcher
 * @param {HTMLElement} element
 */
function ViewCore(element, launcher) {
	ViewCore.instance = this;

	this.launcher = launcher;

	/**
	 *
	 * @type {RoomSpace|null}
	 */
	this.space = null;

	this.native = launcher.native_mode; // Native engine

	//settings
	this.enabled = true; //allows to disable the rendering
	this.layers = 0xffff; //visible layers
	this.camera_control = true; //if true the camera is controlled by this class
	this.reverse_camera_control = true;
	this.smooth_camera = true;
	this.skip_hard_camera = false; //if true this.camera is not overwritten by hard_camera
	this.participant_front_vector = vec3.fromValues(0, 0, -1);
	this.limit_camera = false;
	this.allow_freecam = false;
	this.camera_max_orient = 0.6; //in rad
	this.resize_mode = "auto";
	this._render_quality = ROOM.QUALITY.MEDIUM;
	this.camera_smooth_factor = 0.15;
	this.resolution_factor = 1; //all the canvas

	this.hide_participants = false;
	this.hide_prefabs = false;
	this.use_prerendered_cubemap = false;

	//internals
	this.context = null;
	this.canvas = null;
	this.scene = null;
	this.camera = null; //visible camera

	this.item_hover = null;
	//this.node_hover = null;
	this.fade_factor = 5;

	this.renderer = null;
	this.pbrpipeline = null;
	this.fx = null;

	this.num_resources_loading = 0;
	this.resources_loading = {};

	this.frame = 0;
	this.frames_this_second = 0;
	this.fps = 0;

	this.last_ray = new Ray(); //last mouse ray in world space
	this.grab_point = vec3.create();

	this.loading = null;
	this.assigned_camera = null;

	this._last_camera = null;
	this._blur_level_last_frame = 0;

	this.debug_strings = [];

	/**
	 * if the rendering process is active and running
	 * @type {boolean}
	 */
	this.animating = false;

	//initialize render
	const context = this.native ? launcher.canvas.ctx : undefined;
	this.initRender(element, context, launcher);
}

window.ViewCore = ViewCore;
ViewCore.NO_MOVEMENT = 0;
ViewCore.HOVER_MOVEMENT = 1;
ViewCore.LEFTMOUSE_MOVEMENT = 2;
ViewCore.RIGHTMOUSE_MOVEMENT = 3;

ViewCore.user_clicked = true; //dangerous to set it to true


Object.defineProperty(ViewCore.prototype, "render_quality", {
	get: function () {
		return this._render_quality;
	},
	set: function (v) {
		this._render_quality = v;
		if (this.pbrpipeline === null) return;
		switch (v) {
		case ROOM.QUALITY.LOW:
			this.pbrpipeline.quality = 0;
			//this.pbrpipeline.resolution_factor = 0.5;
			this.pbrpipeline.use_rendertexture = true;
			if (this.fx !== null) this.fx.enabled = false;
			break;
		case ROOM.QUALITY.MEDIUM:
			this.pbrpipeline.quality = 1;
			//this.pbrpipeline.resolution_factor = 1;
			this.pbrpipeline.use_rendertexture = true;
			if (this.fx != null) this.fx.enabled = false;
			break;
		case ROOM.QUALITY.HIGH:
			this.pbrpipeline.quality = 1;
			//this.pbrpipeline.resolution_factor = 1;
			this.pbrpipeline.use_rendertexture = true;
			if (this.fx != null) this.fx.enabled = true;
			break;
		}
	},
});

ViewCore.shaders_file = "pbr-shaders.glsl";

ViewCore.prototype.getCurrentCamera = function () {
	return this.renderer._camera || this.assigned_camera || this.camera;
};

/**
 * @private
 * @param {HTMLElement} element
 * @param {WebGL2RenderingContext|WebGLRenderingContext} context
 */
ViewCore.prototype.initRender = function (element, context, launcher) {

	if (context === undefined) {
		// context is not given, lets create one
		context = GL.create({
			container: element,
			width: window.innerWidth,
			height: window.innerHeight,
			stencil: true,
			alpha: false,
			version: 1,
		});
	}

	this.context = context;
	this.canvas = context.canvas;

	const options = {};
	if (this.native) {
		options.ignore_shaders = true;
		this.dofBlur = 0.1;
		this.dofBlurTarget = 0.1;
		this.dofEnabled = false;
		this.dofDistance = 0.0;
		this.dofDistanceReq = 0.0;
	}

	//create the rendering context
	const renderer = (this.renderer = new RD.Renderer(context, options));
	if (this.launcher.options.credentials)
		renderer.credentials = this.launcher.options.credentials;

	//PBRPipeline
	if (!this.native) {
		var pbrpipeline = (this.pbrpipeline = new RD.PBRPipeline(renderer));
		if (ViewCore.shaders_code)
			renderer.compileShadersFromAtlasCode(ViewCore.shaders_code);
		else if (ViewCore.shaders_file) renderer.loadShaders(ViewCore.shaders_file);
		pbrpipeline.onRenderSkybox = this.renderSkybox.bind(this);
	} else this.pbrpipeline = null; //hack

	if (!this.native) {
		//FX
		this.fx = new FXGlow();
		this.fx.intensity = 0.3;
		pbrpipeline.fx = this.fx;
	} else {
		this.renderer.autoload_assets = false; //native loads assets by itself
	}

	//create cameras (the visible one and the editable one that will be interpolated to the visible one)
	const camera = (this.camera = new Camera());
	camera.perspective(50, this.canvas.width / this.canvas.height, 0.01, 1000);
	camera.lookAt([ 2, 2, -2 ], [ 0, 0.5, 0 ], [ 0, 1, 0 ]);
	this.hard_camera = new Camera();
	this.hard_camera.configure(camera);
	this._last_camera = camera;

	//input
	renderer.context.captureMouse(true, true);
	renderer.context.onmouse = this.processMouse.bind(this);
	renderer.context.onmousewheel = this.onMouseWheel.bind(this);

	renderer.context.captureKeys(true);
	renderer.context.onkeydown = this.onKeyDown.bind(this);
	renderer.context.onkeyup = this.onKeyUp.bind(this);

	renderer.context.ondraw = this.processRender.bind(this);
	renderer.context.onupdate = this.processUpdate.bind(this);

	if(XYZLauncher.instance?.useLifeCycle) {
		renderer.context.animate();
	}

	renderer.context.onlosecontext = this.processContextLost.bind(this);

	//renderer.context.ondragstart = this.processDragStart.bind(this); //test

	renderer.layers_affect_children = true;

	if(launcher)
		renderer.setDataFolder( launcher.options.root_path );

	this.renderer.shaders["premultiply_alpha"] = GL.Shader.createFX(
		"color.xyz *= color.a;"
	);

	//initialize render api
	if (this.native)
		ROOM.RenderAPI = RenderAPINative;
	else
		ROOM.RenderAPI = RenderAPIWeb;
	ROOM.RenderAPI.init();


	LEvent.trigger(this, "init");
};

/**
 * object's lifecycle: startup
 * this method starts the ViewCore service; must be called after initialization and before ViewCore can be used
 */
ViewCore.prototype.startup = function () {
	if (this.animating){
		console.warn("ViewCore was started twice, ignoring it.");
		return;
	}
	this.renderer.context.animate();
	this.animating = true;
}

/**
 * object's lifecycle: shutdown
 * this method stops the ViewCore's service; call it to disable rendering; you can call {@link startup} to start the service again
 */
ViewCore.prototype.shutdown = function () {
	this.renderer.context.animate(false);
	this.animating = false;
}

ViewCore.textures = [];

ViewCore.prototype.reset = function () {
	this.assign_camera = null;
};

ViewCore.prototype.loadConfig = function (url, on_complete) {
	const that = this;
	url += "?nocache=" + Math.random();

	if (ROOM.credentials) {
		if (ROOM.credentials.headers)
			ROOM.credentials.headers["Content-Type"] = "application/json";
		else ROOM.credentials.headers = { "Content-Type": "application/json" };
	}

	fetch(url, ROOM.credentials)
		.then(function (response) {
			return response.json();
		})
		.then(function (data) {
			that.configure(data);
			if (on_complete) on_complete(that, data);
		})
		.catch(function (error) {
			console.debug("Error loading config file:", error.message);
		});
};

ViewCore.prototype.configure = function (json) {
	console.debug("configuring viewcore...");

	console.debug(" - ROOM Assets version " + json.version);

	if (json.config) this.space.config = json.config;

	if (json.materials) {
		for (let i in json.materials) {
			const mat_info = json.materials[i];
			this.addMaterial(mat_info.name, mat_info);
		}
	}
};

//assign the room to display, switching rooms on the fly not supported yet
ViewCore.prototype.setSpace = function (space) {
	if (space === this.space) return;

	this.space = space;
	this.scene = space.scene;

	this.space.on("viewSurface", this.onViewSurface, this);

	//apply changes to view
};

ViewCore.prototype.setDebugPoint = function (position, size, color) {
	let mat = null;

	if (!this.debug_point) {
		this.debug_point = new SceneNode({
			mesh: "sphere",
			material: "debug_flat",
		});
		mat = StaticMaterialsTable["debug_flat"] = new Material();
		this.scene.root.addChild(this.debug_point);
	} else mat = StaticMaterialsTable["debug_flat"];

	this.debug_point.position = position;
	this.debug_point.scaling = size || 0.1;
	mat.color = color || [ 1, 1, 0, 1 ];
};

//called from space.configure
ViewCore.prototype.updateFromSpace = function (space) {
	if (space.environment.url && this.pbrpipeline) {
		const environment_url = getFullPath(
			ROOM.replaceExtension(space.environment.url, "hdre")
		);
		this.setEnvironment(
			environment_url,
			space.environment.rotation,
			space.environment.exposure
		);
		this.pbrpipeline.bgcolor.set(space.environment.color);
	} else {
		this.setEnvironment(null);
		if (this.pbrpipeline) this.pbrpipeline.bgcolor.set([ 1, 1, 1 ]);
	}

	if (space.environment.skybox_url && !xyz.native_mode) {
		const skybox_url = getFullPath(
			ROOM.replaceExtension(space.environment.skybox_url, "hdre")
		);
		this.pbrpipeline.loadEnvironment(skybox_url, null, true);
	}
	this.hard_camera.configure(space.camera_info);

	if (xyz.native_mode) {
		//send data to native engine
		this.updateNativeSettings();
		// Andrey FIXME: Had to comment this line because hdre is not needed in some rooms
		//if (space.environment.url)
			TmrwModule.setRoomInfo(space.url, space.environment.url);
	}
};

ViewCore.prototype.processRender = function () {
	if (this.onRender) this.onRender();
};

//renders the whole scene
ViewCore.prototype.render = function (camera, layers, only_preRender) {
	if (!this.enabled) return;

	this.countFrames();

	LEvent.trigger(this, "render", layers);

	const space = this.space;

	//update FX
	if (this.pbrpipeline) {
		this.pbrpipeline.brightness = space.fx.brightness;
		this.pbrpipeline.contrast = space.fx.contrast;
		this.pbrpipeline.exposure = space.fx.exposure; // * (this._render_quality == 1 ? 0.5 : 1);
		this.pbrpipeline.occlusion_factor = space.fx.illumination; //this._render_quality == 1 ? 1 : this.space.fx.illumination;
		this.pbrpipeline.occlusion_gamma = space.fx.illumination_gamma || 1;

		if (this.pbrpipeline.fx && space.fx.glow) {
			this.pbrpipeline.fx.intensity = space.fx.glow.intensity;
			this.pbrpipeline.fx.persistence = space.fx.glow.persistence;
			this.pbrpipeline.fx.threshold = space.fx.glow.threshold;
		}
		this.pbrpipeline.render_skybox =
			this.use_prerendered_cubemap || space.settings.render_skybox;
		if (this.use_prerendered_cubemap) {
			this.hide_prefabs = true;
			this.pbrpipeline.environment_texture = this.use_prerendered_cubemap
				? this._cubemap
				: this._old_skybox;
			this.pbrpipeline.render_skybox = true;
		} else {
			if (this.pbrpipeline.environment_texture === this._cubemap)
				this.pbrpipeline.environment_texture = this._old_skybox || null;
			this.pbrpipeline.render_skybox = space.settings.render_skybox;
			this.hide_prefabs = false;
		}

		this.pbrpipeline.resolution_factor =
			this.resolution_factor * (this.blur_background ? 0.5 : 1);
	}

	//update rendering parameters from native
	this.updateNativeSettings();

	//pre-stuff
	camera = camera || this.camera;
	this._last_camera = camera;
	this.renderer.enableCamera(camera); //to force update camera.extractPlanes
	this.preRender(camera);

	if (only_preRender) return; // only update prerender stuff

	//in case the window size changed, resizes canvas
	this.resizeBuffer();

	//render our 3D scene **********************************************
	this.renderScene(camera, layers);

	//call postRender in all entities
	this.postRender();

	if (this._selection_buffer && this.visualize_outline)
		this._selection_buffer.toViewport();

	//render UI (to pass to elements that want to render stuff on the ui)
	this.renderUI();

	if (this.debug_texture_view) this.debug_texture_view.toViewport();

	this._blur_level_last_frame = 0;
};

ViewCore.prototype.renderLastFrame = function (apply_blur) {
	let last_frame = null;
	if (this.pbrpipeline && this.pbrpipeline.final_texture)
		last_frame = this.pbrpipeline.final_texture;
	//add here code to get last frame from native engine
	if (this.native) last_frame = this.renderer.final_texture;

	if (!last_frame) {
		//gl.textures[":white"].toViewport();
		if (this.space.preview_texture) {
			const w = GL.ctx.canvas.width;
			const h = GL.ctx.canvas.height;
			const ctx = gl;
			const aspect =
				this.space.preview_texture.width / this.space.preview_texture.height;
			ctx.drawImage(
				this.space.preview_texture,
				w * 0.5 - h * aspect * 0.5,
				0,
				h * aspect,
				h
			);
		}
		return;
	}

	if (apply_blur) {
		if (this._blur_level_last_frame < 30) {
			last_frame.applyBlur(3, 3, 1);
			this._blur_level_last_frame++;
		}
	} else this._blur_level_last_frame = 0;

	last_frame.toViewport();
};

ViewCore.prototype.countFrames = function () {
	this.frame++;
	this.space.frame = this.renderer.frame;
	const now = getTime();
	const sec = Math.floor(now * 0.001);
	if (sec !== this.last_fps_sec) {
		this.last_fps_sec = sec;
		this.fps = this.frames_this_second;
		this.frames_this_second = 0;
		LEvent.trigger(this, "fps_updated");
	} else this.frames_this_second++;
};

ViewCore.prototype.renderSkybox = function (pipeline, camera) {
	if (!this.scene_skybox) return false; //handled by the pipeline

	this.scene_skybox.position = camera.position;
	this.renderer._uniforms.u_exposure = this.space.environment.exposure;

	const nodes = this.scene_skybox.getVisibleChildren();
	for (let i = 0; i < nodes.length; ++i) {
		const node = nodes[i];

		this.renderer.setModelMatrix(node.getGlobalMatrix());
		this.renderer.renderNode(node, camera);
	}

	return true;
};

ViewCore.prototype.renderScene = function (camera, layers, scene) {
	scene = scene || this.scene;
	camera = camera || this.camera;
	if (layers == null) layers = this.layers;

	if (!scene) return;

	//update aspect
	camera.aspect = this.canvas.width / this.canvas.height;
	//camera.aspect = gl.viewport_data[2] / gl.viewport_data[3];

	//gpu time meassure
	if (this.pbrpipeline) {
		this.pbrpipeline.resolveQueries();
		this.pbrpipeline.startGPUQuery();
	}

	//render one frame
	//it will clear
	//ATTENTION: THIS FUNC IS OVERWRITTEN IF NATIVE ENGINE from nativeRender.js
	this.renderer.render(this.scene, camera, null, layers, this.pbrpipeline);

	//render other 3D stuff
	this.renderMarkers(this.space, camera);

	//end gpu time meassure
	if (this.pbrpipeline) this.pbrpipeline.endGPUQuery();
};

ViewCore.prototype.drawTexture2D = function (texture, screenpos, size, alpha) {
	if (alpha === undefined) alpha = 1;
	if (alpha <= 0) return;

	const tmp = GL.ctx.globalAlpha;
	GL.ctx.globalAlpha = alpha;
	GL.ctx.drawImage(
		texture,
		screenpos[0] - size[0] * 0.5,
		screenpos[1] - size[1] * 0.5,
		size[0],
		size[1]
	);
	GL.ctx.globalAlpha = tmp;
};

ViewCore.prototype.renderToTexture = function (
	texture,
	camera,
	layers,
	skip_fx
) {
	if (!this.pbrpipeline) return;

	const that = this;
	texture.drawTo(function () {
		camera.aspect = texture.width / texture.height;
		that.renderer.render(
			that.scene,
			camera,
			null,
			layers || that.layers,
			that.pbrpipeline,
			true
		);
	});

	if (!skip_fx) {
		const texture_info = {
			format: texture.format,
			type: texture.type,
			minFilter: GL.LINEAR,
			magFilter: GL.LINEAR,
			wrap: GL.ctx.CLAMP_TO_EDGE,
		};
		const temp_texture = GL.Texture.getTemporary(
			texture.width,
			texture.height,
			texture_info
		);

		//glow, etc
		this.fx.applyFX(texture, null, temp_texture);

		//tonemapper
		const uniforms = this.pbrpipeline.fx_uniforms;
		uniforms.u_viewportSize[0] = texture.width;
		uniforms.u_viewportSize[1] = texture.height;
		uniforms.u_iViewportSize[0] = 1 / texture.width;
		uniforms.u_iViewportSize[1] = 1 / texture.height;
		temp_texture.copyTo(texture, GL.ctx.shaders["tonemapper"], uniforms);

		GL.Texture.releaseTemporary(temp_texture);
	}
};

//render interface
ViewCore.prototype.renderUI = function () {
	if (this.debug_strings.length) {
		GL.ctx.start2D();
		GL.ctx.font = "20px Arial";
		GL.ctx.textAlign = "center";
	}
	for (let i = 0; i < this.debug_strings.length; ++i) {
		const s = this.debug_strings[i];
		const p = this._last_camera.project(s.position);
		GL.ctx.fillColor = [ 1, 1, 1, 1 ];
		GL.ctx.fillText(s.str, p[0], GL.ctx.canvas.height - p[1]);
	}
	this.debug_strings.length = 0;
	LEvent.trigger(this, "renderUI");
	if (this.onRenderUI) this.onRenderUI();
};

//adapt canvas to container size
ViewCore.prototype.resizeBuffer = function () {
	this.resize_mode = "full"; //hack for now
	if (this.resize_mode === "auto") {
		//force this?
		//resize
		const container = this.canvas.parentNode;
		container.style.overflow = "hidden";
		const rect = container.getBoundingClientRect();
		const w = Math.round(rect.width);
		const h = Math.round(rect.height);
		if (this.context.canvas.width !== w || this.context.canvas.height !== h) {
			console.debug("Resizing Canvas to fit area:", [ w, h ]);
			this.context.canvas.width = w;
			this.context.canvas.height = h;
			GL.ctx.viewport(0, 0, this.context.canvas.width, this.context.canvas.height);

			// Workaround for mobile chrome!
			if (this.native) {
				TmrwModule.ResizeCanvas(window.innerWidth, window.innerHeight);
			}
		}
	} else if (this.resize_mode === "full") {
		if (
			this.context.canvas.width !== window.innerWidth ||
			this.context.canvas.height !== window.innerHeight
		) {
			this.context.canvas.width = window.innerWidth;
			this.context.canvas.height = window.innerHeight;
			console.debug("Resizing Canvas:", window.innerWidth, window.innerHeight);
			GL.ctx.viewport(0, 0, this.context.canvas.width, this.context.canvas.height);

			// Workaround for mobile chrome!
			if (this.native) {
				TmrwModule.ResizeCanvas(window.innerWidth, window.innerHeight);
			}
		}
	}
};

//some entities may require a preprocess
ViewCore.prototype.preRender = function (camera) {
	this.setFXShader(null); //disable FX

	//prepare participants
	for (var i = 0; i < this.space.participants.length; ++i) {
		const participant = this.space.participants[i];
		participant.preRender(this);
	}

	//prepare entities
	const entities = this.space.getAllEntities();
	for (var i = 0; i < entities.length; ++i) {
		const entity = entities[i];
		entity.node.layers = entity.layers; //assign
		entity._distance_to_camera = vec3.distance(
			entity.node.position,
			camera.position
		);
		entity.processActionInComponents("preRender", this);
	}

	if (ROOM.feed_manager) ROOM.feed_manager.preRender(this);

	LEvent.trigger(this, "preRender");
};

//some entities may require a preprocess
ViewCore.prototype.postRender = function () {
	//prepare participants
	const participants = this.space.participants;
	for (var i = 0; i < participants.length; ++i) {
		const participant = participants[i];
		participant.postRender(this);
	}

	//prepare entities
	const entities = this.space.getAllEntities();
	for (var i = 0; i < entities.length; ++i) {
		const entity = entities[i];
		entity.processActionInComponents("postRender", this);
	}

	if (ROOM.feed_manager) ROOM.feed_manager.postRender(this);

	LEvent.trigger(this, "postRender", this);
};

//called from gl.main loop
ViewCore.prototype.processUpdate = function (dt) {
	LEvent.trigger(this, "update");

	/* if(this.fade_factor <= 0)
		this.canvas.style.cursor = "none";
	else
		this.canvas.style.cursor = ""; */

	//update content (done from controller)
	//this.space.update(dt);

	//custom update
	if (this.onUpdate) this.onUpdate(dt);

	//custom camera is managed from the controller, not the view
	if (!this.skip_hard_camera) {
		if (this.limit_camera)
			this.hard_camera.position[1] = clamp(
				this.hard_camera.position[1],
				this.space.camera_info.min_height || 0.1,
				this.space.camera_info.max_height || 4
			);

		//smoothes the camera
		let f = this.smooth_camera ? this.camera_smooth_factor : 1;
		if (this._skip_transition) {
			//allows to avoid the transition on the next frame
			f = 1;
			this._skip_transition = false;
		}

		let active_camera = this.hard_camera;
		if (this.assigned_camera) {
			active_camera = this.assigned_camera;
			f = 1; //no smoothing
		}

		vec3.lerp(
			this.camera.position,
			this.camera.position,
			active_camera.position,
			f
		);
		vec3.lerp(this.camera.target, this.camera.target, active_camera.target, f);
		vec3.lerp(this.camera.up, this.camera.up, active_camera.up, f);

		// no lerp on the Y axis when jumping
		if (
			this.space &&
			this.space.local_participant &&
			this.space.local_participant.jumping
		) {
			this.camera.position[1] = active_camera.position[1];
			this.camera.target[1] = active_camera.target[1];
		}

		this.camera.fov = this.camera.fov * (1 - f) + active_camera.fov * f;
		this.camera.near = this.camera.near * (1 - f) + active_camera.near * f;
		this.camera.frustum_size =
			this.camera.frustum_size * (1 - f) + active_camera.frustum_size * f;
		if (this.camera.type !== active_camera.type)
			this.camera.type = active_camera.type;
	}
};

/*
ViewCore.prototype.processDragStart = function(e)
{
	console.debug("DRAGSTART!",e);
}
*/

//rel path: callback only called if loaded
ViewCore.prototype.loadTexture = function (url, options, callback) {
	let name = url;
	if (options && options.name) name = options.name;

	const ctx = this.context;

	let tex = ctx.textures[name];
	if (tex && tex.name === name) return tex;

	if (!options)
		options = {
			minFilter: ctx.LINEAR_MIPMAP_LINEAR,
			magFilter: ctx.LINEAR,
			wrap: ctx.REPEAT,
		};
	const fullurl = getFullPath(url);
	tex = this.renderer.loadTexture(fullurl, options, callback, true);

	if (!tex) return null;

	ctx.textures[name] = tex;
	if (!tex.name) tex.name = name;
	return tex;
};

ViewCore.prototype.loadTextureProxy = function (url, options, callback) {
	if (ROOM.use_two_step_proxy) {
		//returns url
		ROOM.getTwoStepProxy(url, inner);
		return GL.ctx.textures[url];
	} else return inner(url);

	function inner(proxy_url) {
		options = options || {};
		if (!options.name) options.name = url;
		const texture = ROOM.view.loadTexture(proxy_url, options, callback);
		return texture;
	}
};

ViewCore.prototype.loadMesh = function (url, options, callback) {
	if (GL.ctx.meshes[url]) return GL.ctx.meshes[url];
	const fullurl = getFullPath(url);
	const mesh = this.renderer.loadMesh(fullurl, callback, true);
	GL.ctx.meshes[url] = mesh;
	return mesh;
};

ViewCore.prototype.addMaterial = function (name, material) {
	if (material.constructor === Object) {
		const new_mat = new Material();
		new_mat.configure(material);
		material = new_mat;
	}

	for (let j in material.textures) {
		let tex_info = material.textures[j];
		if (!tex_info) continue;
		if (tex_info.texture) tex_info = tex_info.texture;
		this.loadTexture("/textures/" + tex_info, { name: tex_info });
	}

	StaticMaterialsTable[name] = material;
};

ViewCore.prototype.lookAt = function (position, target, up) {
	const camera = this.hard_camera;
	camera.position = position;
	camera.target = target;
	camera.up = up || [ 0, 1, 0 ];
	vec3.sub(this.participant_front_vector, target, position);
	vec3.normalize(this.participant_front_vector, this.participant_front_vector);
};

//debug load an scene
ViewCore.prototype.loadScene = function (url) {
	const that = this;
	RD.GLTF.load(url, function (node) {
		if (!node) return;
		that.space.scene.root.addChild(node);
	});
};

ViewCore.prototype.onViewSurface = function (event, params) {
	console.debug("view surface", params);

	if (params.surface_entity) {
		this.prev_fx_value = this.fx.enabled;
		this.fx.enabled = false;
	} else this.fx.enabled = this.prev_fx_value;
};

//redirect mouse
//called from GL
ViewCore.prototype.processMouse = function (e) {
	//console.log(e.type);
	if (e.type === "mousemove") this.fade_factor = 5;

	if (this.onMouse) {
		//processed
		if (this.onMouse(e)) return true;
	}
};

//call from onMouse from Call Controller
ViewCore.prototype.processCameraController = function (
	e,
	camera_mode,
	force_dragging
) {
	const camera = this.hard_camera;
	const dragging = e.dragging || force_dragging;

	if (e.type === "mousemove") {
		if (dragging) {
			if (e.middleButton) {
				const dist = vec3.distance(camera.position, camera.target) * 0.01;
				camera.moveLocal([ -e.deltax * dist, e.deltay * dist, 0 ], 0.1);
			} else if (e.leftButton || (force_dragging && !e.rightButton)) {
				var right = this.camera.getLocalVector([ 1, 0, 0 ]);
				if (camera_mode === "orbit") {
					camera.orbit(
						e.deltax * 0.01 * (this.reverse_camera_control ? -1 : 1),
						[ 0, 1, 0 ]
					);
					camera.orbit(
						e.deltay * 0.01 * (this.reverse_camera_control ? -1 : 1),
						right
					);
				} //no camera_mode
				else {
					camera.rotate(
						e.deltax * 0.01 * 0.2 * (this.reverse_camera_control ? -1 : 1),
						[ 0, 1, 0 ]
					);
					camera.rotate(
						e.deltay * 0.01 * 0.2 * (this.reverse_camera_control ? -1 : 1),
						right
					);
				}
			} else if (e.rightButton) {
				if (this.allow_freecam || camera_mode === "free") {
					var right = this.camera.getLocalVector([ 1, 0, 0 ]);
					if (camera_mode === "orbit") {
						camera.rotate(
							e.deltax * 0.01 * (this.reverse_camera_control ? -1 : 1),
							[ 0, 1, 0 ]
						);
						camera.rotate(
							e.deltay * 0.01 * (this.reverse_camera_control ? -1 : 1),
							right
						);
					} else {
						camera.moveLocal([ e.deltax * 0.002, e.deltay * -0.002, 0 ]);
					}
				}
			}
		} //no dragging
		else {
		}
	} else if (e.type === "mousedown") {
	}
};

ViewCore.prototype.onMouseWheel = function (e) {
	//pass key to core
	const r = this.launcher.onMouse(e);
	if (r) return r;
	//this.processMouseWheel(e);
};

ViewCore.prototype.processMouseWheel = function (e, mode) {
	mode = mode || "fov";

	const camera = this.hard_camera;
	const direction = e.wheel > 0 ? 1 : -1;
	if (mode === "fov") {
		if (camera.type === Camera.PERSPECTIVE) {
			var offset = 1 + direction * -0.02;
			camera.fov = clamp(camera.fov * offset, 10, 120);
		} else {
			var offset = 1 + direction * -0.1;
			camera.frustum_size *= offset;
		}
	} else if (mode === "Z") {
		var offset = 1 + direction * -0.1;
		camera.orbitDistanceFactor(offset);
	}
};

//PLEASE: DO NOT PROCESS KEYS FROM HERE, DO IT FROM CALL.JS  !!!
ViewCore.prototype.onKeyDown = function (e) {
	switch (e.code) {
		case "Digit0":
			this.key0 = true;
			this.processTMRW();
			break;
		case "Digit1":
			this.key1 = true;
			this.processTMRW();
			break;
		case "Digit2":
			this.key2 = true;
			this.processTMRW();
			break;
		case "Digit7":
			this.key7 = true;
			this.processTMRW();
			break;
		case "Digit6":
			this.key6 = true;
			this.processTMRW();
			break;
	}


	//pass key to core
	const r = this.launcher.onKeyDown(e);
	if (r) 
		return r;
	this.processTMRW();
	return true;
};

ViewCore.prototype.onKeyUp = function (e) {
	//pass key to core
	const r = this.launcher.onKeyUp(e);
	if (r) 
		return r;
	this.processTMRW();
	return false;
};

ViewCore.prototype.processTMRW = function () {
	var keys = gl.keys;

	if (keys["T"] && keys["M"] && keys["R"]) // && m_bKeyW)
	{
		var bProcess = false;
		var processImage = 0;
		if (keys["W"]) {
			processImage = 1;
			bProcess = true;
		}
		else
			if (keys["E"]) {
				processImage = 2;
				bProcess = true;
			}
		if (bProcess) {
			keys["T"] = keys["M"] = keys["R"] = keys["W"] = keys["E"] = false;
			if (window.nativeEngine && window.nativeEngine._engine)
				nativeEngine._engine.setROSPopup(processImage);
		}
	}
}

ViewCore.prototype.takeSnapshot = function (
	width,
	height,
	callback,
	camera,
	encoding,
	scene_callback
) {
	encoding = encoding || "image/png";
	const gl = GL.ctx;

	const w = gl.canvas.width;
	const h = gl.canvas.height;

	gl.canvas.width = width || w;
	gl.canvas.height = height || h;
	gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

	camera = camera || this.camera;

	this.preRender(camera);

	const nativeEngine = globalThis.nativeEngine;

	let dr = false;
	if (nativeEngine && nativeEngine._engine) {
		var dynRes = nativeEngine.getDynRes(true);
		dr = dynRes.enabled;

		dynRes.enabled = false;
		nativeEngine.setDynRes();

		var vp = vec2.create();
		vp[0] = gl.canvas.width;
		vp[1] = gl.canvas.height;
		nativeEngine._engine.setViewport(vp);
	}

	this.renderScene(camera);
	if (scene_callback) scene_callback(this, camera);

	gl.canvas.toBlob(inner, encoding);
	gl.canvas.width = w;
	gl.canvas.height = h;
	gl.viewport(0, 0, w, h);

	if (nativeEngine && nativeEngine._engine) {
		var dynRes = nativeEngine.getDynRes(true);
		dynRes.enabled = dr;
		nativeEngine.setDynRes();

		var vp = vec2.create();
		vp[0] = w;
		vp[1] = h;
		nativeEngine._engine.setViewport(vp);
	}

	if (nativeEngine && nativeEngine._engine) {
		var dynRes = nativeEngine.getDynRes(true);
		dynRes.enabled = dr;
		nativeEngine.setDynRes();

		var vp = vec2.create();
		vp[0] = w; vp[1] = h;
		nativeEngine._engine.setViewport(vp);
	}

	if (nativeEngine && nativeEngine._engine) {
		var dynRes = nativeEngine.getDynRes(true);
		dynRes.enabled = dr;
		nativeEngine.setDynRes();

		var vp = vec2.create();
		vp[0] = w; vp[1] = h;
		nativeEngine._engine.setViewport(vp);
	}

	function inner(data) {
		callback(data, encoding);
	}
};

ViewCore.prototype.updateCubemap = function (size, position) {
	size = size || 1024;
	layers = 0xffff;
	position = position || this.camera.position;

	if (!this._cubemap)
		this._cubemap = new GL.Texture(size, size, {
			texture_type: WebGLRenderingContext.TEXTURE_CUBE_MAP,
		});

	const that = this;
	const camera = new Camera();
	camera.perspective(90, 1, 0.1, 1000);
	const center = vec3.create();

	//show hidden entities
	if (!this.pbrpipeline) {
		if (this.pbrpipeline.environment_texture === this._cubemap)
			this.pbrpipeline.environment_texture = this._old_skybox;
		else this._old_skybox = this.pbrpipeline.environment_texture;
	}

	const were_prefabs = this.hide_prefabs;
	const were_skybox = this.space.settings.render_skybox;

	this.hide_participants = true;
	this.hide_prefabs = false;
	this.preRender();

	this._cubemap.drawTo(function (tex, i) {
		//HACK
		if (i === 2) i = 3;
		else if (i === 3) i = 2;
		const info = RD.Renderer.cubemap_info[i];

		vec3.sub(center, position, info.front);
		camera.lookAt(position, center, info.up);
		that.renderer.render(
			that.scene,
			camera,
			null,
			layers,
			that.pbrpipeline,
			true
		);
	});

	this.hide_prefabs = were_prefabs;
	this.hide_participants = false;

	//replace scene with cubemap
	//this._old_skybox = this.pbrpipeline.environment_texture;
	//this.space.settings.render_skybox = were_skybox;
	//this.pbrpipeline.environment_texture = this._cubemap;
	//this.pbrpipeline.render_skybox = true;
};

ViewCore.prototype.takeCubemapSnapshot = function (
	size,
	callback,
	position,
	target,
	encoding,
	_scene_callback
) {
	encoding = encoding || "image/png";
	const that = this;

	const output = new GL.Texture(size, size * 6, { wrap: GL.CLAMP_TO_EDGE });

	output.drawTo(function () {
		that.preRender();
	});

	function inner(data) {
		callback(data, encoding);
	}
};

ViewCore.prototype.setEnvironment = function (url, rotation, exposure) {
	rotation = rotation || 0;
	exposure = exposure || 1;
	const that = this;

	if (!url) {
		if (this.pbrpipeline) {
			this.pbrpipeline.environment_texture = null;
			this.pbrpipeline.environment_sh_coeffs = null;
		}
		return;
	}

	this.onStartLoadingResource(url);

	if (this.pbrpipeline) {
		if (!xyz.native_mode)
			this.pbrpipeline.loadEnvironment(url, function (_tex) {
				that.onFinishLoadingResource(url);
			});
		this.pbrpipeline.environment_rotation = rotation;
		this.pbrpipeline.environment_factor = exposure;
	}
};

ViewCore.prototype.onStartLoadingResource = function (name) {
	this.num_resources_loading++;
	this.resources_loading[name] = true;
};

ViewCore.prototype.onFinishLoadingResource = function (name) {
	if (this.num_resources_loading > 0) this.num_resources_loading--;
	delete this.resources_loading[name];

	if (this.num_resources_loading === 0)
		LEvent.trigger(this.space, "prefabsLoaded");
};

//returns scene node that interesects this ray
//called from several places but mostly Call.onMouse
ViewCore.prototype.testRay = function (ray, layers) {
	if (layers == null) layers = 0xffff;

	const coll = vec3.create();
	const test = this.scene.testRay(ray, coll, 1000, layers, true);
	if (test) {
		//console.debug( "SELECTED_NODE: ", test.name, test );
		window.SELECTED_NODE = test;
	}
	return test;
};

ViewCore.prototype.getSceneNodeEntity = function (node) {
	if (node.native) return node.getParentEntity();
	if (node.room_entity) return node.room_entity;
	if (node.parentNode) return this.getSceneNodeEntity(node.parentNode);
	return null;
};

ViewCore.prototype.processContextLost = function () {
	this.enabled = false; //disable rendering
	console.error("WebGL Context lost");
	if (this.onContextLost) this.onContextLost();
	LEvent.trigger(this, "contextLost");
};

ViewCore.prototype.renderMarkers = function (space, camera) {
	const t = getTime() * 0.001;
	camera = camera || this.camera;
	const markers = space.markers;
	if (!camera || !markers.length) return;

	const pos2D = vec3.create();
	const color = vec4.fromValues(1, 1, 1, 1);
	GL.ctx.enable(GL.ctx.BLEND);
	GL.ctx.blendFunc(GL.ctx.SRC_ALPHA, GL.ctx.ONE_MINUS_SRC_ALPHA);
	GL.ctx.disable(GL.ctx.DEPTH_TEST);

	for (let i = 0; i < markers.length; ++i) {
		const marker = markers[i];
		camera.project(marker.position, null, pos2D);
		if (pos2D[2] > 1) continue;
		const f = 1.0 - clamp((marker.time - t) / marker.duration, 0, 1);
		let icon = null;
		if (marker.type === "like") icon = [ 2, 8 ];
		else if (marker.options.icon) icon = marker.options.icon;

		if (marker.options.color && marker.options.color.length === 4)
			vec4.copy(color, marker.options.color);
		else vec4.copy(color, [ 1, 1, 1, 1 ]);
		color[3] = 1 - Math.pow(f, 2.2);
		const x = pos2D[0];
		let y = GL.ctx.canvas.height - pos2D[1];
		if (marker.options.rise) y += f * -100;
		let size = marker.options.size || 1;
		if (marker.options.grow) size += f;

		if (icon) GUI.DrawIcon(x, y, size, icon, false, color);

		//remove
		if (marker.time < t) {
			if (marker._node)
				ROOM.RenderAPI.destroyNode(marker._node);
			markers.splice(i, 1);
			i--;
		}
	}
};

//render the infobar of a user using a canvas
ViewCore.prototype.updateParticipantInfobar = function (participant) {
	if (!participant.infobar_material) {
		participant.infobar_material = new Material({
			color: [ 0, 0, 0 ],
			emissive: [ 0.6, 0.6, 0.6 ],
			alphaMode: "MASK",
			textures: { opacity: ROOM.root_path + "/textures/infobar_mask.png" },
		});
		participant.infobar_material.name = ":infobar_mat_" + participant.index;
		StaticMaterialsTable[participant.infobar_material.name] =
			participant.infobar_material;
	}

	if (!participant.infobar_canvas) {
		participant.infobar_canvas = document.createElement("canvas");
		participant.infobar_canvas.width = 512;
		participant.infobar_canvas.height = 64;
		participant.infobar_texture = new GL.Texture(
			participant.infobar_canvas.width,
			participant.infobar_canvas.height,
			{ filter: GL.ctx.LINEAR, wrap: GL.ctx.CLAMP_TO_EDGE }
		);
	}

	const texture_name = ":infobar_tex_" + participant.index;
	GL.ctx.textures[texture_name] = participant.infobar_texture;
	participant.infobar_material.textures.emissive = texture_name;

	if (participant.infobar_texture.username !== participant.name) {
		const canvas = participant.infobar_canvas;
		const ctx = canvas.getContext("2d");

		const text_size = ctx.measureText(participant.name);
		if (text_size.width > 340)
			participant.infobar_material.textures.opacity =
				ROOM.root_path + "/textures/infobar_mask_big.png";
		else if (text_size.width > 190)
			participant.infobar_material.textures.opacity =
				ROOM.root_path + "/textures/infobar_mask_medium.png";
		else
			participant.infobar_material.textures.opacity =
				ROOM.root_path + "/textures/infobar_mask_small.png";

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = "white";
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.font = "40px Arial";
		ctx.fillStyle = "black";
		ctx.textAlign = "center";
		ctx.fillText(
			participant.name,
			canvas.width * 0.5 + canvas.height * 0.25,
			canvas.height * 0.7
		);
		ctx.fillStyle = "#AFA";
		/*
		ctx.beginPath();
		ctx.arc(canvas.width*0.5 - text_size.width*0.5 - canvas.height * 0.25, canvas.height * 0.5, canvas.height * 0.25, 0, Math.PI * 2 );
		ctx.fill();
		*/
		participant.infobar_texture.uploadData(canvas);
		participant.infobar_texture.username = participant.name;
	}

	return participant.infobar_material;
};

ViewCore.prototype.reloadShaders = function () {
	const that = this;
	this.renderer.loadShaders(
		ViewCore.shaders_file,
		function () {
			that.pbrpipeline.resetShadersCache();
		},
		null,
		true
	);
};

//available stuff
//uniform sampler2D u_texture;
//varying vec2 v_coord;

ViewCore.prototype.setFXShader = function (name) {
	if (!this.pbrpipeline) return;

	if (name === "gameboy") {
		if (!GL.ctx.shaders["gameboy"]) {
			GL.ctx.shaders["gameboy"] = GL.Shader.createFX(
				`
				float v = (color.x + color.y + color.z) / 3.0;
				v = floor((v + 0.25 * dither4x4(gl_FragCoord.xy)) * 4.0) / 4.0;
				color.xyz = vec3(0.54,0.75,0.43) * v;
			`,
				`
				float dither4x4(vec2 position) {
				  int x = int(mod(position.x, 4.0));
				  int y = int(mod(position.y, 4.0));
				  int index = x + y * 4;
				  if (x < 8) {
					if (index == 0) return 0.0625;
					if (index == 1) return 0.5625;
					if (index == 2) return 0.1875;
					if (index == 3) return 0.6875;
					if (index == 4) return 0.8125;
					if (index == 5) return 0.3125;
					if (index == 6) return 0.9375;
					if (index == 7) return 0.4375;
					if (index == 8) return 0.25;
					if (index == 9) return 0.75;
					if (index == 10) return 0.125;
					if (index == 11) return 0.625;
					if (index == 12) return 1.0;
					if (index == 13) return 0.5;
					if (index == 14) return 0.875;
					if (index == 15) return 0.375;
				  }
				  return 0.0;
				}			
			`
			);
		}
		this.pbrpipeline.postfx_shader_name = "gameboy";
	} else if (name === "bw") {
		if (!GL.ctx.shaders["bw"])
			GL.ctx.shaders["bw"] = GL.Shader.createFX(
				"color.xyz = vec3(color.x + color.y + color.z) / 3.0;"
			);
		this.pbrpipeline.postfx_shader_name = "bw";
	} else if (name === "crt") {
		if (!GL.ctx.shaders["crt"])
			GL.ctx.shaders["crt"] = GL.Shader.createFX(
				"color.xyz *= vec3(0.95,1.0,1.05) * (sin(v_coord.y*u_size.y*3.14) * 0.2 + 0.8);",
				"uniform vec2 u_size;"
			);
		this.pbrpipeline.postfx_shader_name = "crt";
	} else this.pbrpipeline.postfx_shader_name = "";
};

/**
 * updates which element is below the ray
 * @param {Ray} ray
 * @param {MouseEvent} event
 * @param {Camera} [camera]
 */
ViewCore.prototype.testNodeHover = function (ray, event, camera= this.camera) {

	this.node_hover = this.space.testRayWithInteractiveNodes(ray, camera);

	if (this.item_hover) {
		// clear hover state from previously hovered element
		this.item_hover.hover = false;

		if (this.item_hover._native){
			this.item_hover._native.hover = false;
		}

	}

	this.subnode_hover = testRayWithNodes.coll_node;
	this.item_hover = null;
	if (this.node_hover) {
		this.item_hover = this.node_hover.getParentEntity();
		if (this.item_hover) {
			// Andrey FIXME: We try to replace NativeEntity with JS entity here, otherwise all entity actions do not work
			if (this.item_hover.native) {
				const entity = this.space.getEntityByIndex(this.item_hover.getID());
				if (entity) this.item_hover = entity;
			}

			this.item_hover.hover = true;
			if (this.item_hover._native) {
				var mInter = this.item_hover._native.getMouseInteractive();

				if (!mInter)
					this.item_hover._native.hover = true;

				if (mInter) {
					var x = event.x;
					var y = event.y;
					var action = 0;
					var button = 0;
					var modifiers = 0;

					if (event.type === "mousedown")
						action = 1;
					if (event.type === "mouseup")
						action = 2;
					if (event.type === "mousemove")
						action = 0;
					if (event.type === "mousescroll")
						action = 3;

					if (event.button === 0)
						button = 1;
					if (event.button === 1)
						button = 2;

					if (event.shiftKey)
						modifiers |= 1;
					if (event.ctrlKey)
						modifiers |= 2;
					if (event.altKey)
						modifiers |= 4;

					this.item_hover._native.mouseInteraction(x, y, action, button, modifiers);
        }
			}

			if (this.item_hover.onHoverMouse) {
				this.item_hover.onHoverMouse(event);
			}
		}
	}
};

//render node outline (used in editor and to mouse hover)
ViewCore.prototype.renderOutline = function (
	renderer,
	scene,
	camera,
	objects,
	color,
	filter_alpha_materials
) {
	if (!objects || !objects.length) return;

	if (this.fade_factor < 0) return;

	if (this.native) return;

	const that = this;

	const layers = 0xffff;
	objects = objects.filter(function (a) {
		return a.layers & layers;
	});

	let nodes_with_mesh = [];
	objects.forEach(function (v) {
		nodes_with_mesh.push(v);
		v.getAllChildren(nodes_with_mesh);
	});

	nodes_with_mesh = nodes_with_mesh.filter(function (v) {
		return v.mesh || v.primitives;
	});
	if (!nodes_with_mesh.length) return;

	const w = GL.ctx.viewport_data[2];
	const h = GL.ctx.viewport_data[3];
	if (
		!this._selection_buffer ||
		this._selection_buffer.width !== w ||
		this._selection_buffer.height !== h
	)
		this._selection_buffer = new GL.Texture(w, h, {
			format: GL.RGBA,
			magFilter: GL.ctx.NEAREST,
		});
	const selection_buffer = this._selection_buffer;

	if (!ViewCore.outline_material)
		ViewCore.outline_material = new Material({ shader_name: "flat" });

	const shadername = this.shader;
	this._selection_buffer.drawTo(function () {
		GL.ctx.clearColor(0, 0, 0, 1);
		GL.ctx.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT | WebGLRenderingContext.DEPTH_BUFFER_BIT);
		renderer.shader_overwrite = shadername;
		const tmp = renderer.onNodeShaderUniforms;
		renderer.onNodeShaderUniforms = function (node, shader) {
			shader.setUniform("u_color", [ 1, 1, 1, 1 ]);
		};
		if (filter_alpha_materials)
			renderer.onFilterByMaterial = that.filterByAlphaMode;

		//pass some info
		const mat_name = nodes_with_mesh[0].primitives.length
			? nodes_with_mesh[0].primitives[0].material
			: nodes_with_mesh[0].material;
		const mat = StaticMaterialsTable[mat_name];
		renderer.disable_cull_face = true;
		renderer.overwrite_material = ViewCore.outline_material;
		if (mat) {
			ViewCore.outline_material.primitive = mat.primitive;
			ViewCore.outline_material.point_size = mat.point_size;
		}

		renderer.render(scene, camera, nodes_with_mesh);
		renderer.shader_overwrite = null;
		renderer.onFilterByMaterial = null;
		renderer.disable_cull_face = false;
		renderer.onNodeShaderUniforms = tmp;
		renderer.overwrite_material = null;
	});
	let outline_shader = GL.ctx.shaders["outline"];
	if (!outline_shader)
		outline_shader = GL.ctx.shaders["outline"] = GL.Shader.createFX(
			"\
			vec3 colorU = texture2D(u_texture, uv - vec2(0.0,u_res.y)).xyz;\n\
			vec3 colorD = texture2D(u_texture, uv - vec2(0.0,-u_res.y)).xyz;\n\
			vec3 colorUL = texture2D(u_texture, uv - u_res).xyz;\n\
			vec3 colorUR = texture2D(u_texture, uv + vec2(u_res.x,-u_res.y)).xyz;\n\
			vec3 colorL = texture2D(u_texture, uv - vec2(u_res.x,0.0)).xyz;\n\
			vec3 colorR = texture2D(u_texture, uv - vec2(-u_res.x,0.0)).xyz;\n\
			vec3 colorDL = texture2D(u_texture, uv - vec2(u_res.x,-u_res.y)).xyz;\n\
			vec3 outline = (abs(color.xyz - colorU) + abs(color.xyz - colorL) + abs(color.xyz - colorR) + abs(color.xyz - colorD)) * 0.3;\n\
			outline += (abs(color.xyz - colorUL) + abs(color.xyz - colorDL) + abs(color.xyz - colorUR)) * 0.1;\n\
			color = u_color * vec4( clamp(outline,vec3(0.0),vec3(1.0)), length(outline) * 10.0 );\n\
			//color = texture2D(u_texture, uv);\n\
		",
			"uniform vec2 u_res; uniform vec4 u_color;"
		);

	GL.ctx.blendFunc(GL.ctx.SRC_ALPHA, GL.ctx.ONE);
	//gl.blendFunc(gl.SRC_ALPHA,gl.ONE_MINUS_SRC_ALPHA);
	GL.ctx.enable(GL.ctx.BLEND);
	GL.ctx.disable(GL.ctx.DEPTH_TEST);
	selection_buffer.toViewport(outline_shader, {
		u_color: color || [ 1, 1, 1, 1 ],
		u_res: [ 1 / w, 1 / h ],
	});
	GL.ctx.disable(GL.ctx.BLEND);
	GL.ctx.enable(GL.ctx.DEPTH_TEST);
};

ViewCore.prototype.filterByAlphaMode = function (material, node_material) {
	return node_material.alphaMode !== "BLEND";
};

ViewCore.prototype.getTranslucentTexture = function () {
	if (!this.pbrpipeline) return;

	const last_frame = this.pbrpipeline.final_texture;
	if (!last_frame) return null;

	if (!this._translucent_frame_texture)
		this._translucent_frame_texture = new GL.Texture(512, 512, {
			magFilter: GL.ctx.LINEAR,
		});

	//reuse old if is in the same frame
	if (this._translucent_frame_texture.frame === this.frame)
		return this._translucent_frame_texture;
	this._translucent_frame_texture.frame = this.frame;

	//apply iterative blur
	let input = last_frame;
	const output = this._translucent_frame_texture;
	const a = last_frame.width / last_frame.height;
	for (let i = 0; i < 2; ++i) {
		this._translucent_frame_texture_temp = input.applyBlur(
			Math.pow(1, i),
			Math.pow(a, i),
			1,
			output,
			this._translucent_frame_texture_temp
		);
		input = output;
	}
	return output;
};

//DEPRECATED **********************************
ViewCore.matter_cube_sides_info = [
	[
		[ 0, 1, 0 ],
		[ -0.7071067690849304, 0, 0, 0.7071067690849304 ],
	], //front
	[
		[ 0, 0, 1 ],
		[ 0, 0, 0, 1 ],
	], //left
	[
		[ 1, 0, 0 ],
		[ 0, 0.7071067690849304, 0, 0.7071067690849304 ],
	], //top
	[
		[ 0, 0, -1 ],
		[ 0, 1, 0, 0 ],
	], //back
	[
		[ -1, 0, 0 ],
		[ 0, -0.7071067690849304, 0, 0.7071067690849304 ],
	], //right
	[
		[ 0, -1, 0 ],
		[ 0.7071067690849304, 0, 0, 0.7071067690849304 ],
	], //bottom
];

ViewCore.matter_cube_info = [
	[ "_0_0", [ -0.5, 0.5, 0 ] ],
	[ "_1_0", [ 0.5, 0.5, 0 ] ],
	[ "_0_1", [ -0.5, -0.5, 0 ] ],
	[ "_1_1", [ 0.5, -0.5, 0 ] ],
];

ViewCore.prototype.getMatterportView = function (folder) {
	//create structure
	if (!this.matterport_node) {
		this.matterport_node = new SceneNode();
		this.matterport_node.scaling = [ -1, 1, 1 ]; //flip

		for (let i = 0; i < ViewCore.matter_cube_sides_info.length; ++i) {
			const side_info = ViewCore.matter_cube_sides_info[i];
			const side = new SceneNode({ position: side_info[0] });
			side.rotation = side_info[1];
			this.matterport_node.addChild(side);

			for (let j = 0; j < ViewCore.matter_cube_info.length; ++j) {
				var info = ViewCore.matter_cube_info[j];
				var name = "1k_face" + i + info[0];
				const part = new SceneNode({
					name: name,
					mesh: "plane",
					position: info[1],
					scale: 1,
					material: name,
				});
				side.addChild(part);

				const material = new Material();
				material.flags.two_sided = true;
				material.shader_name = "degamma_material";
				material.flags.depth_test = false;
				material.textures.color = name + ".jpg";
				StaticMaterialsTable[name] = material;
			}
		}
	}

	//load textures
	if (this.matterport_node.folder !== folder) {
		this.matterport_node.folder = folder;
		for (let i = 0; i < ViewCore.matter_cube_sides_info.length; ++i) {
			for (let j = 0; j < ViewCore.matter_cube_info.length; ++j) {
				var info = ViewCore.matter_cube_info[j];
				var name = "1k_face" + i + info[0];
				GL.ctx.textures[name + ".jpg"] = GL.Texture.fromURL(
					folder + "/" + name + ".jpg"
				);
			}
		}
	}

	return this.matterport_node;
};

ViewCore.prototype.setDOF = function (enabled, focal_length) {
	const nativeEngine = globalThis.nativeEngine;

	//only works on native
	if (!nativeEngine || !nativeEngine._engine)
		return;

	this.dofEnabled = enabled;
	this.dofDistanceReq = focal_length;
	if (enabled)
		this.dofBlurTarget = 2.0;
	else
		this.dofBlurTarget = 0.1;
};

ViewCore.prototype.setDynamicResolution = function (v) {
	const nativeEngine = globalThis.nativeEngine;

	if (nativeEngine && nativeEngine._engine) {
		const dynRes = nativeEngine.getDynRes(true);
		if (dynRes.enabled != v) {
			dynRes.enabled = v;
			nativeEngine.setDynRes();
		}
	}
};

ViewCore.prototype.printText = function (str, position) {
	const o = {
		str: str,
		position: position,
	};
	this.debug_strings.push(o);
	return o;
};

//called from updateFromSpace and view.render (every frame)
ViewCore.prototype.updateNativeSettings = function () {

	const nativeEngine = globalThis.nativeEngine;

	if (nativeEngine === undefined || !nativeEngine._engine)
		return;

	if (this.dofBlur != this.dofBlurTarget || this.dofDistance != this.dofDistanceReq) {
		const speed = 0.2;
		this.dofDistance = this.dofDistanceReq;
		let enabled = this.dofEnabled;
		if (this.dofEnabled) {
			this.dofBlur += speed;
			if (this.dofBlur >= this.dofBlurTarget)
				this.dofBlur = this.dofBlurTarget;
		} else {
			this.dofBlur -= speed;
			if (this.dofBlur <= this.dofBlurTarget)
				this.dofBlur = this.dofBlurTarget;
			enabled = true;
		}
		const dof = nativeEngine.getDOF(true);
		if (dof.enabled != enabled || dof.focusDistance != this.dofDistance || this.dofBlur != dof.cocScale) {
			dof.enabled = enabled;
			if (enabled) {
				//enabled
				dof.cocThreshold = 2.0;
				dof.cocRange = 1.0;
				dof.cocScale = this.dofBlur;
				//dof.cameraAperture = 2;
				dof.focusDistance = this.dofDistance;
				dof.nativeResolution = false;      // FIXME Andrey: Disabled Native resolution because it is too slow currently
			}
			nativeEngine.setDOF();
		}
	}
	else {
		let enabled = this.dofEnabled;
		const dof = nativeEngine.getDOF(true);
		if (dof.enabled != enabled) {
			dof.enabled = enabled;
			nativeEngine.setDOF();
		}
	}

	//in space
	const fx = this.space.fx;

	if (this.space.native_dirty) {
		this.space.native_dirty = false;

		const low_spec = nativeEngine._engine.getShadersQuality() === 0;

		const nativeFX = nativeEngine.getSceneFX(true, 0);

		nativeFX.exposure = fx.exposure;
		if (low_spec)
			nativeFX.illumination = fx.illumination * 0.5;
		else
			nativeFX.illumination = fx.illumination;
		nativeFX.occlusion_gamma = fx.illumination_gamma;
		nativeFX.emissive_factor = fx.emissive_factor;
		nativeFX.contrast = fx.contrast;
		nativeFX.brightness = fx.brightness;

		nativeEngine.setSceneFX(null, 0);

		if (this.space.fx_loaded && xyz.native_json_loaded) {
			if (fx.native_fx)
				nativeEngine.setAllFX(fx.native_fx);
		} else
			this.space.native_dirty = true;
	}
};

//using native engine
var RenderAPINative = {
	init: function() {

	},

	createNode: function()
	{
		var ent = nativeEngine._room.createEntityEmpty();
		var node = ent.getRootNode();
		return node;
	},

	destroyNode: function(node)
	{
		//check if node has entity and destroy too
		//nativeEngine._room.destroyEntity(ent);
	},

	loadPrefabInNode: function(node, url_or_data, callback )
	{
		node.loadURL( url_or_data, callback );
	},

	createMaterial: function()
	{
		if (!this.material_builder)
			this.material_builder = TmrwModule.NativeRoomAPI.MaterialBuilder();
		return this.material_builder.createMaterial();
	},

	destroyMaterial: function()
	{
		//TODO
	},

	createTexture: function()
	{
		//create
		//RD.Renderer.prototype.RegisterNativeTextureId
	},

	destroyTexture: function()
	{
		//free?
	}
};

var RenderAPIWeb = {
	init: function() {
		RD.SceneNode.prototype.loadURL = RD.SceneNode.prototype.loadGLTF;
		RD.SceneNode.prototype.getChildren = function() {return this.children;}
	},

	createNode: function()
	{
		var node = new RD.SceneNode();
		xyz.space.scene.root.addChild(node);
		return node;
	},

	destroyNode: function(node)
	{
		//check if node has entity and destroy too
		//nativeEngine._room.destroyEntity(ent);
		node.remove();
	},

	loadPrefabInNode: function(node, url_or_data, callback )
	{
		node.loadGLTF( url_or_data, callback );
	}
};

//ROOM.RenderAPI = RenderAPINative;

export { ViewCore };
