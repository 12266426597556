import { DEG2RAD } from "@src/constants";
import { getFullPath } from "@src/engine/Room/file-utils";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import Button from "@src/libs/GLUI/Elements/Button";
import { RD } from "@src/libs/rendeer";
import { Camera } from "@src/libs/rendeer/Camera";
import { Direction3Constants } from "@src/libs/rendeer/Direction3Constants";
import { Scene } from "@src/libs/rendeer/Scene";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { vec4 } from "gl-matrix";

import Dice from "./Dice";

//in charge of adding and rolling dices
function DiceManager()
{
	this._last_dice_id = 0;
	this._die = [];

	this._scene = new Scene();

	this._node = new SceneNode();
	this._node.rotate( -80 * DEG2RAD, [ 1,0,0 ]);
	this._scene.root.addChild( this._node );

	this._camera = new Camera();
	this._camera.lookAt([ 0,0,8 ],[ 0,0,0 ],[ 0,1,0 ]);
	this._camera.perspective(20,1,0.01,100);

	this._area = vec4.fromValues(0,gl.canvas.height - (256 + 160),256,256+60);
	this._key = "";
	this._hover = false;

	this._panel_collapsed = true;

	//preload
	RD.GLTF.load( getFullPath("props/dice_6_animated.glb") );
	RD.GLTF.load( getFullPath("props/dice_8_animated.glb") );
	RD.GLTF.load( getFullPath("props/dice_10_animated.glb") );
}

DiceManager.componentName = "DiceManager";
DiceManager.icon = [ 2,4 ];
DiceManager.type = ROOM_TYPES.GAMES;
DiceManager.last_index = 0;

DiceManager.prototype.serialize = function(o)
{
	var die = [];
	for (var i = 0; i < this._die.length; ++i)
		die.push(this._die[i].serialize());

	o.last_dice_id = this._last_dice_id;
	o.die = die;
}

DiceManager.prototype.configure = function(o)
{
	this.reset();

	this._last_dice_id = o.last_dice_id;
	for (var i = 0; i < o.die.length; ++i)
	{
		var dice_info = o.die[i];
		var dice = new Dice( dice_info.id, dice_info.type );
		this.addDice(dice);
		dice.value = dice_info.value;
	}
}

DiceManager.prototype.findDice = function(id)
{
	for (var i = 0; i < this._die.length; ++i)
	{
		var dice = this._die[i];
		if (dice.id === id )
			return dice;
	}
	return null;
}

DiceManager.prototype.reset = function(sync)
{
	this._last_dice_id = 0;

	for (var i = 0; i < this._die.length; ++i)
	{
		var dice = this._die[i];
		dice._manager = null;
		this._node.removeChild( dice._node );
	}

	this._die.length = 0;
	if (sync)
		this.syncDice();
}

DiceManager.prototype.addDice = function( dice, sync )
{
	//spawn
	if (dice.id === -1)
		dice.id = this._last_dice_id++;
	dice._manager = this;
	this._node.addChild( dice._node );
	this._die.push( dice );

	this._key = this.getKey();

	if (sync)
		this.syncDice();
}

DiceManager.prototype.removeDice = function( dice, sync )
{
	if (dice._manager !== this)
		throw ("error, dice doesnt belong here");

	var index = this._die.indexOf( dice );
	if (index === -1)
		return;
	this._die.splice(index,1);
	dice._manager = null;
	this._node.removeChild( dice._node );

	if (sync)
		this.syncDice();
}


//sends current dice config to all
DiceManager.prototype.syncDice = function()
{
	var die = [];
	for (var i = 0; i < this._die.length; ++i)
		die.push(this._die[i].serialize());

	this.syncData({
		action: "update_dice",
		die: die,
		last_id: this._last_dice_id,
		key: this.getKey()
	});
}

DiceManager.prototype.onSyncData = function(data)
{
	if (data.action === "update_dice" )
	{
		if (!data.die)
			return;

		this._last_dice_id = data.last_id;

		var new_dice = [];
		var updated_dice = [];
		var old_dice = this._die.concat(); //clone

		for (var i = 0; i < data.die.length; ++i)
		{
			var dice_info = data.die[i];
			var dice = this.findDice( dice_info.id );
			if ( !dice ) //new dice
			{
				dice = new Dice( dice_info.id, dice_info.type );
				this.addDice(dice);
				new_dice.push(dice);
			}
			else //existing dice
			{
				var index = old_dice.indexOf(dice);
				old_dice.splice( index, 1 ); //remove
				updated_dice.push(dice); //add
			}

			dice.value = dice_info.value;
			dice.animateToValue( dice.value );
		}

		//remove olds
		for (var i = 0; i < old_dice.length; ++i)
			this.removeDice( old_dice[i] );
	}
}

DiceManager.prototype.getKey = function()
{
	var key = [];
	for (var i = 0; i < this._die.length; ++i)
	{
		var dice = this._die[i];
		key.push( dice.type );
	}
	return key.join(",");
}

DiceManager.prototype.roll = function()
{
	for (var i = 0; i < this._die.length; ++i)
	{
		var dice = this._die[i];
		dice.roll(); //generates a value
		dice.animateToValue( dice.value );
	}

	this.syncDice();
}

DiceManager.prototype.onAdded = function(parent)
{
}

DiceManager.prototype.onRemoved = function(parent)
{
}

DiceManager.prototype.renderTable = function(view)
{
}

DiceManager.prototype.spawnDice = function( type )
{
	if ( this._die.length >= 4 ) //max dice
		return;

	var dice = new Dice(-1,type);
	this.addDice( dice, true );
}

DiceManager.temp_viewport = vec4.create();

DiceManager.dice_positions = [
	null, //0
	[ [ 0,0,0 ] ],//1
	[ [ -1,0,0 ],[ 1,0,0 ] ],//2
	[ [ -1,0.75,0 ],[ 1,0.75,0 ],[ 0,-0.75,0 ] ],//3
	[ [ -1,1,0 ],[ 1,1,0 ],[ -1,-1,0 ],[ 1,-1,0 ] ]//4
];

DiceManager.camera_position = [ 0, -2, 12 ];

DiceManager.prototype.renderTableUI = function( controller )
{
	var view = controller.view;

	var x = 0;
	var y = controller._last_panel_y;
	var w = 300;
	var h = 340;

	if (this._panel_collapsed) //hidden
	{
		x = x - w + 50;
		h = 40;
	}

	var ctx = gl;
	GUI.TranslucentPanel(x-40,y,w+40,h,20,[ 0.6,0.6,0.6,1 ]);

	ctx.globalAlpha = 0.5;
	if (GUI.DrawIcon( x + w - 24, y + 20, 0.5, [ 12,8 ],false,null,null,true ) === GLUI.CLICKED )
		this._panel_collapsed = !this._panel_collapsed;
	ctx.globalAlpha = 1;

	if (this._panel_collapsed)
	{
		controller._last_panel_y += 70;
		return;
	}

	//render buttons
	var itemx = x+40;
	var dicey = this._area[1] + 10;
	if ( Button.call(GUI, itemx,y+10,30,30,"d6",false,[ 0.5,0.5,0.5,0.1 ] ) )
		this.spawnDice(6);
	if ( Button.call(GUI, itemx+40,y+10,30,30,"d8",false,0) )
		this.spawnDice(8);
	if ( Button.call(GUI, itemx+80,y+10,30,30,"d10",false,0) )
		this.spawnDice(10);
	if ( Button.call(GUI, itemx+120,y+10,30,30,GLUI.icons.x,false,0) )
		this.reset(true);

	if (this._die.length)
		this.renderDice( x,y+40,w,h-40, view);

	controller._last_panel_y += h + 40;
}

DiceManager.prototype.renderDice = function(x,y,w,h, view)
{
	//position dice
	var max_length = 4;
	var delta = max_length / this._die.length;
	var positions = DiceManager.dice_positions[ this._die.length ];
	for (var i = 0; i < this._die.length; ++i)
	{
		var dice = this._die[i];
		dice._node.position = positions[i];
	}

	var campos = this._node.localToGlobal( DiceManager.camera_position );
	var up = this._node.getGlobalVector(Direction3Constants.UP);
	this._camera.lookAt(campos,Direction3Constants.ZERO,up);

	//renders dice in independent viewport
	var temp = DiceManager.temp_viewport;
	temp.set( gl.viewport_data );

	this._area[0] = x;
	this._area[1] = y;
	this._area[2] = w;
	this._area[3] = h;

	y = gl.canvas.height - y - h; //flip

	this._camera.perspective(20,w/h,0.01,100);

	gl.viewport(x,y,w,h);
	gl.enable( gl.SCISSOR_TEST );
	gl.scissor(x,y,w,h);
	gl.clear( WebGLRenderingContext.DEPTH_BUFFER_BIT );
	view.pbrpipeline.skip_background = true;
	view.pbrpipeline.gamma = this._hover ? 0.9 : 1.2;

	view.renderer.render( this._scene, this._camera, null, 0xFFFF, view.pbrpipeline, true );
	view.pbrpipeline.skip_background = false;
	view.pbrpipeline.gamma = 2.2;

	gl.disable( gl.SCISSOR_TEST );
	gl.viewport(temp[0],temp[1],temp[2],temp[3]);

	var mouse = GUI.HoverArea( this._area[0], this._area[1], this._area[2], this._area[3] );

	if (mouse === GLUI.CLICKED )
		this.roll();
	this._hover = mouse === GLUI.HOVER;

	gl.start2D();
}

export default DiceManager;
