export function getClassName(obj) {
	if (!obj)
		return;

	// custom to enable mangling
	if (obj.componentName) {
		return obj.componentName;
	}

	//from function info, but not standard
	if (obj.name)
		return obj.name;

	//from sourcecode
	if (obj.toString) {
		var arr = obj.toString().match(
			/function\s*(\w+)/);
		if (arr && arr.length === 2) {
			return arr[1];
		}
	}
}