import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";

function LGraphSpaceMaterialTransition()
{
	this.addInput("assign",LiteGraph.ACTION);
	this.properties = { entity_id:"", node_id: "", material_id: "", duration: 1 };
	this.addWidget("button","Capture Material",null, this.captureMaterial.bind(this) );
	this.addWidget("button","Capture Node",null, this.captureNode.bind(this) );
}

LGraphSpaceMaterialTransition.title = "Material Transform";
LGraphSpaceMaterialTransition.desc = "Transitions a material between two states";

LGraphSpaceMaterialTransition.prototype.getMaterial = function()
{
	if (!this.properties.material_id)
		return null;
	var material = StaticMaterialsTable[ this.properties.material_id ];
	if (!material)
		return null;
	return material;
}

LGraphSpaceMaterialTransition.prototype.captureMaterial = function()
{
	var editor = xyz.editor_controller;
	if (!editor)
		return;
	var material = editor.selected_material;
	if (material)
		this.properties.material_id = material.name;
	else if(editor.selected_node)
		this.properties.material_id = editor.selected_node.material || "";
}

LGraphSpaceMaterialTransition.prototype.captureNode = function()
{
	var editor = xyz.editor_controller;
	if (!editor)
		return;
	var node = editor.selected_node;
	if (!node)
		return;
	this.properties.node_id = node.name;
	if(node.getOwner)
	{
		var ent = editor.translateNativeObject(node);
		if(ent)
			this.properties.entity_id = ent.uid;
	}
}

LGraphSpaceMaterialTransition.prototype.onAction = function(action)
{
	if (action === "assign")
		this.assignMaterial();
}

LGraphSpaceMaterialTransition.prototype.assignMaterial = function()
{
	//get node
	var ent = xyz.space.getEntityById(this.properties.entity_id);
	if(ent)
	{
		var nat = ent._native;
		var root = nat.getRootNode();
		var node = root.findNodeByName( this.properties.node_id );
		if(node)
		{
			node.material = this.properties.material_id;
		}
		//ent.native.
		//var node = xyz.space.scene.root.findNodeByName(this.properties.node_id);
		//if(node)
		//	node.material = this.properties.material_id;
	}
}

LGraphSpaceMaterialTransition.prototype.onDrawBackground = function(ctx)
{
	/*
	var material = this.getMaterial();
	if (!material)
	{
		this.boxcolor = "orange";
		return;
	}
	this.boxcolor = null;

	if (material.name)
	{
		ctx.font = "20px Arial";
		ctx.textAlign = "left";
		ctx.fillColor = [ 1,1,1,0.75 ];
		ctx.fillText( material.name, 0,-40);
	}
	*/
}

/*
LGraphSpaceMaterialTransition.prototype.tweenMaterial = function( target, origin )
{
	var duration = this.properties.duration;
	if (duration == null)
		duration = 1;

	for (var i in origin)
	{
		var target_value = target[i];
		if ( target_value === undefined )
			continue;
		var origin_value = origin[i];
		if (i === "textures")
			continue; //special case
		if ( target_value != null && origin_value != null && 
			target_value.constructor !== String && 
			target_value.cosntructor != Object)
			Tween.easeProperty( target, i, origin_value, duration );
	}
}

LGraphSpaceMaterialTransition.prototype.onGetInputs = function() {
	return [ [ "change_state",LiteGraph.ACTION ] ];
}
*/

export default LGraphSpaceMaterialTransition;
