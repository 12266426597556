import { vec3 } from "gl-matrix";

function SkyBoxObject()
{
	this._on_focus = false;

	this._active = true;
	this._skb = TmrwModule.NativeRoomAPI.SkyBoxBuilder();

	this._color = vec3.create();
	vec3.set(this._color, 1.0, 1.0, 1.0);

	this._native = null;
	this._dirty = false;

	this._url = null;
	this._curUrl = null;
	this._dirtyUrl = false;

	this._preset = "Env 1";

	this._index = SkyBoxObject.last_index++;
}

SkyBoxObject.componentName = "SkyBoxObject";
SkyBoxObject.last_index = 1;

SkyBoxObject.icon = [ 6,3 ];

SkyBoxObject.widgets = {
	"preset": { type: "enum", values: [ "Env 1", "Env 2", "Env 3", "Env 4", "Env 5", "Env 6" ] },
	"Active": { type: "toggle" },
	"Sky Intensity": { type: "slider", min: 0.0, max: 10.0, mark: 1.0 },
	"IBL Intensity": { type: "slider", min: 0.0, max: 10.0, mark: 1.0 },
	"Sky SH": { type: "toggle" },
	"Sky IBL": { type: "toggle" },
	"Disable ENV": { type: "toggle" },
	"Show Sun": { type: "toggle" },
	"Color": { type: "color" },
	url: { type: "asset", extensions: "zip" }

//	"Size": { type: "slider", min: 0.0, max: 100.0, mark: 1.0 },
//	"Falloff": { type: "slider", min: 0.0, max: 2.0, mark: 0.0 },
//	"Priority": { type: "slider", min: -1, max: 10, mark: 0 }
}

Object.defineProperty(SkyBoxObject.prototype, "preset", {
	get: function () {
		return this._preset;
	},
	set: function (v) {
		if (this._preset == v)
			return;
		this._dirty = true;
		this._preset = v;
		this._url = null;
	}
});

Object.defineProperty(SkyBoxObject.prototype, "url", {
	get: function () {
		return this._url;
	},
	set: function (v) {
		if (v != null)
			v = v.trim();
		if (v == this._url)
			return;
		this._url = v;
		this._dirtyUrl = true;
	}
});

Object.defineProperty(SkyBoxObject.prototype, "Active", {
	get: function () {
		return this._skb.active;
	},
	set: function (v) {
		if (this._skb.active == v)
			return;
		this._dirty = true;
		this._skb.active = v;
	}
});

Object.defineProperty(SkyBoxObject.prototype, "Sky SH", {
	get: function () {
		return this._skb.useSH;
	},
	set: function (v) {
		if (this._skb.useSH == v)
			return;
		this._dirty = true;
		this._skb.useSH = v;
	}
});

Object.defineProperty(SkyBoxObject.prototype, "Sky IBL", {
	get: function () {
		return this._skb.useIBL;
	},
	set: function (v) {
		if (this._skb.useIBL == v)
			return;
		this._dirty = true;
		this._skb.useIBL = v;
	}
});

Object.defineProperty(SkyBoxObject.prototype, "Disable ENV", {
	get: function () {
		return this._skb.disableEnvMesh;
	},
	set: function (v) {
		if (this._skb.disableEnvMesh == v)
			return;
		this._dirty = true;
		this._skb.disableEnvMesh = v;
	}
});

Object.defineProperty(SkyBoxObject.prototype, "Show Sun", {
	get: function () {
		return this._skb.showSun;
	},
	set: function (v) {
		if (this._skb.showSun == v)
			return;
		this._dirty = true;
		this._skb.showSun = v;
	}
});

Object.defineProperty(SkyBoxObject.prototype, "Sky Intensity", {
	get: function () {
		return this._skb.skyIntensity;
	},
	set: function (v) {
		if (this._skb.skyIntensity == v)
			return;
		this._dirty = true;
		this._skb.skyIntensity = v;
	}
});

Object.defineProperty(SkyBoxObject.prototype, "IBL Intensity", {
	get: function () {
		return this._skb.iblIntensity;
	},
	set: function (v) {
		if (this._skb.iblIntensity == v)
			return;
		this._dirty = true;
		this._skb.iblIntensity = v;
	}
});


Object.defineProperty(SkyBoxObject.prototype, "Color", {
	get: function () {
		return this._color;
	},
	set: function (v) {
		vec3.copy(this._color, v);
	}
});

SkyBoxObject.prototype.getUrl = function () {
	if (this._url && this._url != "")
		return this._url;

	var skyName = null;
	switch (this._preset) {
	case "Env 1":
		skyName = "ibl/env1/ibl.zip";
		break;
	case "Env 2":
		skyName = "ibl/env2/ibl.zip";
		break;
	case "Env 3":
		skyName = "ibl/env3/ibl.zip";
		break;
	case "Env 4":
		skyName = "ibl/env4/ibl.zip";
		break;
	case "Env 5":
		skyName = "ibl/env5/ibl.zip";
		break;
	case "Env 6":
		skyName = "ibl/env6/ibl.zip";
		break;
	}

	return skyName;
}

SkyBoxObject.prototype.onAdded = function (parent) {
	LEvent.bind(parent, "clicked", this.onClicked, this);

	this._node = parent.node;
}

SkyBoxObject.prototype.createNative = function () {
	if (this._root && nativeEngine && !this._native) {
		if (!this._root._native) {
			// Andrey FIXME: Hack to place new volume entity in front of camera
			// Btw for some reason this camera position doesn't changed when we fly in the editor mode!
			var camera = ViewCore.instance.hard_camera;
			var cam_pos = camera.position;
			var cam_front = camera.getFront();
			var dist = 1.0;

			var offset = vec3.scale(vec3.create(), cam_front, dist);
			vec3.add(this._node.position, cam_pos, offset);
			this._node.mustUpdate = true;

			this._root.createNativeEntity(this._root.index);
		}


		this._skb.build(this._root._native);

		this._native = this._root._native;

		this._dirty = true;
	}
}


SkyBoxObject.prototype.onRemoved = function(parent)
{
	LEvent.unbind( parent,"clicked", this.onClicked, this );

	this._node = null;
	if (this._native) {
		//nativeEngine._room.destroyEntityObj(this._native);
		this._native = null;
	}
	if (this._skb)
		this._skb.delete();
	this._skb = null;
}

SkyBoxObject.prototype.serialize = function(o)
{
	o.active = this._skb.active;
	o.useSH = this._skb.useSH;
	o.useIBL = this._skb.useIBL;

	o.url = this._skb.url;
	o.color = this._skb.genColor;
	o.skyIntensity = this._skb.skyIntensity;
	o.iblIntensity = this._skb.iblIntensity;
	o.size = this._skb.size;
	o.priority = this._skb.priority;
	o.rotation = this._skb.rotation;
	o.disableEnvMesh = this._skb.disableEnvMesh;
}

SkyBoxObject.prototype.configure = function(o)
{
	this._skb.active = o.active;
	this._skb.useSH = o.useSH;
	this._skb.useIBL = o.useIBL;

	this._skb.skyIntensity = o.skyIntensity;
	this._skb.iblIntensity = o.iblIntensity;
	this._skb.size = o.size;
	this._skb.rotation = o.rotation;
	this._skb.priority = o.priority;
	this._skb.disableEnvMesh = o.disableEnvMesh;

	this._skb.url = o.url;
	this._url = o.url;

	var c = vec3.create();
	if (o.color) {
		c[0] = o.color[0];
		c[1] = o.color[1];
		c[2] = o.color[2];
	}
	else {
		c[0] = 1;
		c[1] = 1;
		c[2] = 1;
	}
	this._skb.color = c;
	this._color = c;


	this._dirty = true;

	this._index = SkyBoxObject.last_index++;
}

SkyBoxObject.prototype.onClicked = function(e)
{
	//console.debug("clicked",this.entity.name);
	if (!this._on_focus)
		this.enterFocus();
	else //leaving
		this.exitFocus();
}


SkyBoxObject.prototype.preRender = function(view)
{
	if (!this._native) {
		if (xyz.native_loaded)
			this.createNative();
	}
	if (!this._native) {
		return;
	}

	if (this._dirtyUrl) {
		if (this._url.includes(".zip")) {
			this._dirty = true;
		}
	}

	if (this._dirty || !vec3.equals(this._color, this._skb.color)) {

		var url = this.getUrl();

		this._dirty = false;

		this._skb.color = this._color;
		this._skb.url = url;

		this._skb.build(this._native);
	}
}


SkyBoxObject.prototype.enterFocus = function()
{
	if (this._on_focus)
		return;

	LEvent.bind( this.space, "keydown", this.onKey, this );
	LEvent.bind( this.space, "mouse", this.onGlobalMouse, this );

	//compute radius
	var original_pos = this.entity.node.getGlobalPosition();
	var bb = node.updateBoundingBox();
	var center = BBox.getCenter(bb);
	var halfsize = BBox.getHalfsize(bb);
	vec3.mul( halfsize, halfsize, node.scaling );
	var radius = vec3.length( halfsize );// / this.entity.node.scaling[0];
	console.debug(radius);
	radius *= 1.5;
}

SkyBoxObject.prototype.exitFocus = function()
{
	if (!this._on_focus)
		return;

	LEvent.unbind(this.space, "keydown", this.onKey, this);
	LEvent.unbind(this.space, "mouse", this.onGlobalMouse, this);

	this._on_focus = false;
}

SkyBoxObject.prototype.onKey = function(type,e)
{
	if (e.key == "Escape")
	{
		this.exitFocus();
	}
	return true;
}

SkyBoxObject.prototype.onGlobalMouse = function(type,e)
{
	//console.debug(e);
	if (!this._on_focus)
		return;

	var speed = 0.02;

	if ( e.dragging )
	{
		//var node = this.entity.node;
		//var node = this._inspect_node;
		//var local_up = node.globalVectorToLocal( [0,1,0] );
		//var local_right = node.globalVectorToLocal( [1,0,0] );
		//node.rotate( e.deltax * speed, local_up );
		//node.rotate( e.deltay * speed, local_right );
	}

	if ( e.dragging && e.type == "mousemove")
		return true;
}

export default SkyBoxObject;

