/**
 * Indexer used to reuse vertices among a mesh
 * @class Indexer
 * @constructor
 */
class Indexer {
    constructor() {
        this._unique = [];
        this._indices = [];
        this._map = {};
    }
    add(obj) {
        const key = JSON.stringify(obj);
        if (!(key in this._map)) {
            this._map[key] = this._unique.length;
            this._unique.push(obj);
        }
        return this._map[key];
    }
    get unique() {
        return this._unique;
    }
}
export default Indexer;
