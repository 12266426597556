import { getCurvePoints } from "cardinal-spline-js";
import lerp           from "lerp";

import EditableText from "@src/engine/apps/ui/editableText";
import ImageElement from "@src/engine/apps/ui/imageElement";
import LoadingManager from "@src/engine/helpers/loadingManager";
import ROOM from "@src/engine/room";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";

import WhiteboardNote from "./whiteboardNote";
import WhiteboardRow  from "./whiteboardRow";

function WhiteboardApp( surface, state )
{
	this.xyz = XYZLauncher.instance;
	this.surface = surface;
	this.info = WhiteboardApp.info;
	this.initialized = false;
	this.scale_factor = this.resolution_factor = 2; //changes ALL scale (including toolbar)
	this.width = null;
	this.height = null;
	this.icons = "textures/apps/whiteboard/icons_whiteboard.png";
	this.page_count_max = 9;
	this.current_page_index = 0;

	this.dragging = null;
	this.last_mouse_state = null;
	this.mouse_state = "mouseup"; // mouseup mousedown
	this.mouse_action = null; // click
	this.mouse_prev_position = null;
	this.MODES = {
		NONE: 0,
		DRAW: 1,
		NOTES: 2,
		ERASE: 3
	};
	this.mode = this.MODES.NONE;
	this.line_width = 3 * this.scale_factor;
	this.other_participants_current_lines = {};
	this.current_line = null;
	this.line_color = "black";
	this.drawing_cursor = false;
	this.hovered_line_index = null;
	this.click_timeout = null;
	this.focused_note = null;
	this.selected_note = null;
	this.header_height = null;
	this.cursor_style = null;
	this.max_point_count = 4000;

	// columns
	this.column_line_is_hovered = false;
	this.table_header_is_hovered = false;
	this.column_font_size = 30 * this.scale_factor;
	this.column_count_max = 8; // max number of columns
	this.column_line_width = 2 * this.scale_factor; // column line width
	this.column_stroke_color = "rgba(0, 0, 0, 0.1)"; // column line color
	this.column_header_height = 200 * this.scale_factor;
	this.column_line_is_dragged = false;
	this.editable_title = (options = {}) => {
		return (
			new EditableText({
				placeholder: this.page.columns.length + 1,
				text: options.text || "",
				font_size: this.column_font_size,
				max_length: 12 * this.scale_factor,
				parent: this,
				//onUpdate: that.sendState.bind(that),
				interactive: false
			})
		)
	}

	// rows
	this.row_count_max = 7; // max number of columns
	this.row_line_is_dragged = false;

	this.background_grid = false;
	this.cancel_mousedown = false;

	this.background = "background-1";
	if ( this.surface && this.surface.apps_settings && this.surface.apps_settings.apps &&
		this.surface.apps_settings.apps["Whiteboard"] && this.surface.apps_settings.apps["Whiteboard"].background )
		this.background = this.surface.apps_settings.apps["Whiteboard"].background;

	this.images_loaded = false;
	this.loadImages();

	this.elements = [];
	this.drawModeElements = [];

	window.whiteboard = this; // debug

	LEvent.bind( this.xyz.space, "keydown", this.processKeyDown.bind(this) );
	LEvent.bind( this.xyz.space, "participant_leave", this.onParticipantLeave, this );

	this.line_smoothing = true;
	this.pointsArrayDivisor = 2;
	this.tension = .5;
	this.numOfSeg = 3;

	this.html_renderer = this.xyz.html_renderer;

	this.max_note_count = 30;

	// default settings for a page
	this.default_page = function () {
		return ({
			title: "Page Title",
			notes: [],
			lines: [],
			//background_image_name: this.info.images[this.background],
			columns: [],
			rows: []
		});
	};

	this.pages = [ this.default_page() ];

	this.page = this.pages[this.current_page_index]; // pointer to current page

	this.participant_cursors = {};

	this.last_drawing_x = null;
	this.last_drawing_y = null;

	// public attributes used to draw without using the mouse (for instance with gestures)
	this.public_drawing_x = null;
	this.public_drawing_y = null;
	this.public_drawing_was_active = false;

	if ( state )
		this.JSONToState( state );
}

WhiteboardApp.output_mode = "canvas";

WhiteboardApp.prototype.init = function( tex, viewport )
{
	if (!this.images_loaded)
		return;

	if ( this.width !== viewport[2] )
	{
		this.width = viewport[2];
	}

	const x = viewport[0];
	const y = viewport[1];
	const w = viewport[2];
	const h = viewport[3];

	this.height = this.surface.single_app ? tex.height : h;

	this.line_width = 3 * this.scale_factor;
	this.header_height = 55 * this.scale_factor;
	this.column_font_size = 30 * this.scale_factor;
	this.column_line_width = 2 * this.scale_factor; // column line width
	this.column_header_height = 200 * this.scale_factor;

	const tools_size = .3  * this.scale_factor;
	const mid_header_y = this.header_height / 2;
	this.elements["page-arrow-left"] = new ImageElement( ROOM.images[this.info.images["arrow-left"]], {
		width: 6 * this.scale_factor,
		height: 10 * this.scale_factor,
		x: x + 38 * this.scale_factor,
		y: y + mid_header_y - 10 * this.scale_factor / 2
	});
	this.elements["page-arrow-right"] = new ImageElement( ROOM.images[this.info.images["arrow-right"]], {
		width: 6 * this.scale_factor,
		height: 10 * this.scale_factor });
	this.elements["canvas-frame"] = new ImageElement( ROOM.images[this.info.images["frame"]], {
		width: 54 * this.scale_factor,
		height: 36 * this.scale_factor });
	this.elements["page-count"] = new EditableText({
		text: `${this.current_page_index+1}/${this.page_count_max}`,
		font_size: 16 * this.scale_factor,
		interactive: false
	});
	this.elements["erase-btn"] = new ImageElement( ROOM.images[this.info.images["erase"]]);
	this.elements["erase-btn"].width *= .22 * this.scale_factor;
	this.elements["erase-btn"].height *= .22 * this.scale_factor;

	this.elements["column-add-btn"] = new ImageElement( ROOM.images[this.info.images["column_add"]], {
		width: `${25 * this.scale_factor}%`,
		height: `${25 * this.scale_factor}%` });
	this.elements["column-remove-btn"] = new ImageElement( ROOM.images[this.info.images["column_remove"]], {
		width: `${25 * this.scale_factor}%`,
		height: `${25 * this.scale_factor}%` });

	// eraser
	this.elements["eraser"] = new ImageElement( ROOM.images[this.info.images["eraser-spritesheet"]], {
		crop: { width: "100%", height: "1/3" },
		width: 100 * tools_size + "%",
		height: (100 / 3 * tools_size) + "%",
		frames: [ { y: 0 }, { y: "1/3" }, { y: "2/3" } ]
	});

	// pen
	this.elements["pen"] = new ImageElement( ROOM.images[this.info.images["pen-spritesheet"]], {
		crop: { width: "100%", height: "1/3" },
		width: (100 * tools_size)+"%",
		height: (100 / 3 * tools_size) + "%",
		frames: [ { y: 0 }, { y: "1/3" }, { y: "2/3" } ]
	});

	// note stack
	this.elements["note_stack"] = new ImageElement( ROOM.images[this.info.images["notes-spritesheet"]], {
		crop: { width: "50%", height: "100%" },
		width: 50 * tools_size + "%",
		height: 100 * tools_size + "%",
		frames: [ { x: 0 }, { x: "50%" } ]
	});
	this.elements["select-arrow"] = new ImageElement( ROOM.images[this.info.images["select-arrow-spritesheet"]], {
		crop: { width: "50%", height: "100%" },
		width: 60 * this.scale_factor,
		height: 60 * this.scale_factor,
		frames: [ { x: 0 }, { x: "50%" } ]
	});
	this.elements["select-arrow"].x = x + 10;

	var sy = y + 160 * this.scale_factor;
	this.elements["select-arrow"].y = sy;
	sy += this.elements["select-arrow"].height - 5 * this.scale_factor;
	this.elements["pen"].y = sy;
	sy += this.elements["pen"].height + 10 * this.scale_factor;
	this.elements["note_stack"].y = sy;
	sy += this.elements["note_stack"].height + 10 * this.scale_factor;
	this.elements["eraser"].y = sy;


	// page controls
	this.elements["canvas-frame"].x = x + this.elements["page-arrow-left"].x + this.elements["page-arrow-left"].width + 10 * this.scale_factor;
	this.elements["canvas-frame"].y = y + mid_header_y - this.elements["canvas-frame"].height / 2;

	this.elements["page-count"].position[0] = x + this.elements["canvas-frame"].x + 16 * this.scale_factor;
	this.elements["page-count"].position[1] = y + mid_header_y + 6 * this.scale_factor;

	this.elements["page-arrow-right"].x = x + this.elements["canvas-frame"].x + this.elements["canvas-frame"].width + 10 * this.scale_factor;
	this.elements["page-arrow-right"].y = this.elements["page-arrow-left"].y;

	// erase btn
	this.elements["erase-btn"].x = x + this.elements["page-arrow-right"].x + this.elements["page-arrow-right"].width + 29 * this.scale_factor;
	this.elements["erase-btn"].y = y + mid_header_y - this.elements["erase-btn"].height / 2;

	this.elements["column-add-btn"].x = x + 250 * this.scale_factor;
	this.elements["column-add-btn"].y = y + mid_header_y - this.elements["column-add-btn"].height / 2;

	this.elements["column-remove-btn"].x = x + 300 * this.scale_factor;
	this.elements["column-remove-btn"].y = this.elements["column-add-btn"].y;

	// stroke size
	var strokeOptions = {
		crop: { width: "1/3", height: "50%" },
		width: "1/3",
		height: "1/2",
		frames: [
			{ x: 0, y: 0 },       { x: "1/3", y: 0 },       { x: "2/3", y: 0 },
			{ x: 0, y: "50%" },   { x: "1/3", y: "50%" },   { x: "2/3", y: "50%" }
		]
	};
	this.drawModeElements["stroke-ss-1"] = new ImageElement( ROOM.images[this.info.images["stroke-spritesheet"]], strokeOptions);
	this.drawModeElements["stroke-ss-1"].width *= .8;
	this.drawModeElements["stroke-ss-1"].height *= .8;

	this.drawModeElements["stroke-ss-2"] = new ImageElement( ROOM.images[this.info.images["stroke-spritesheet"]], strokeOptions);
	this.drawModeElements["stroke-ss-2"].width *= .8;
	this.drawModeElements["stroke-ss-2"].height *= .8;

	this.drawModeElements["stroke-ss-3"] = new ImageElement( ROOM.images[this.info.images["stroke-spritesheet"]], strokeOptions);
	this.drawModeElements["stroke-ss-3"].width *= .8;
	this.drawModeElements["stroke-ss-3"].height *= .8;

	this.elements["download-btn"] = new ImageElement(
		ROOM.images[this.info.images["download-icon"]],
		{ x: w - 100, y: y + mid_header_y - 20, width: 40, height: 40 }
	);

	this.initialized = true;
}

WhiteboardApp.prototype.JSONToState = function( state )
{
	if (!state)
		return;

	const pages = JSON.parse(state);

	for (let i = 0; i < pages.length; i++) {
		if (pages[i].is_current_page)
			this.current_page_index = i;
	}

	if (typeof this.pages[this.current_page_index] === "undefined")
		this.pages[this.current_page_index] = this.default_page();

	this.page = this.pages[this.current_page_index];
	this.updatePageCountDisplay();

	this.clear();

	for (let u = 0; u < pages.length; u++)
	{
		const page = pages[u];

		if ( !this.pages[u] )
		{
			this.pages[u] = this.default_page();
		}



		for (let i = 0; i < page.notes.length; i++)
		{
			const note = page.notes[i];
			this.addNote({
				position: note.position,
				text: note.text,
				rotation: note.rotation,
				color: note.color,
				page_index: u
			}, u);
		}

		this.pages[u].lines = page.lines;

		for (let i = 0; i < page.columns.length; i++)
		{
			const col = page.columns[i];
			this.addColumn({
				title: col.title,
				x: col.x,
				prevent_reset: true
			}, u);
		}


	}
}

WhiteboardApp.prototype.stateToJSON = function()
{
	const page_data = [];
	for (var i = 0; i < this.pages.length; i++) {
		const page = this.pages[i];

		const state = {
			//background_image_name: page.background_image_name,
			notes: [],
			lines: [],
			current_lines: {},
			columns: [],
			rows: [],
			is_current_page: i === this.current_page_index
		};

		// notes
		for (var j = 0; j < page.notes.length; j++)
		{
			const note = page.notes[j];
			state.notes.push({
				position: note.position,
				text: note.text.text,
				rotation: note.rotation,
				color: note.color,
				page_index: i
			});
		}

		// lines
		for (var k = 0; k < page.lines.length; k++)
		{
			state.lines[k] = {
				width: page.lines[k].width,
				points: page.lines[k].points,
				color: page.lines[k].color
			};
		}

		// columns
		for (var k = 0; k < page.columns.length; k++)
		{
			const col = page.columns[k];
			state.columns.push({
				title: col.title.text,
				x: col.x
			});
		}

		// rows
		for (var k = 0; k < page.rows.length; k++)
		{
			state.rows.push({
				y: page.rows[k].y
			});
		}

		page_data.push(state);

	}

	return JSON.stringify(page_data);
}

WhiteboardApp.prototype.sendState = function()
{
	const json_state = this.stateToJSON();
	this.xyz.call_controller.broadcastMessage({ action: "surface_update", surface_entity: this.surface.root.uid, subaction: "load_app", app_state: json_state });
}


WhiteboardApp.prototype.sendAction = function( app_action )
{
	// used by the os in case this app is a subapp
	app_action.subapp = this.app_name;
	var action = {
		action: "surface_update",
		surface_entity: this.surface.root.uid,
		subaction: "app_action",
		app_action: app_action
	};

	this.xyz.call_controller.broadcastMessage(action);


}

//called from...
WhiteboardApp.prototype.processAction = function( app_action )
{
	const action = app_action.action;
	const data = app_action.data;

	if ( action === "add_note" )
	{
		this.addNote( data );
	}
	else if ( action === "move_note" )
	{
		var note = this.page.notes[ data.id ];
		note.position[0] = data.x;
		note.position[1] = data.y;
	}
	else if ( action === "remove_note" )
	{
		this.removeNote(data.id, data.page);
	}
	else if ( action === "block_note" )
	{
		var note = this.page.notes[ data.id ];
		if ( note === this.selected_note )
		{
			note.exitSelect();
		}
		var participant = xyz.space.getParticipant( data.participant );
		note.blocked = { id: participant.id, name: participant.getUsername() };
	}
	else if ( action === "update_note" )
	{
		var page = this.pages[ data.page ];
		var note = page.notes[ data.id ];
		note.changeColor(data.color);
		note.text.text = data.text;
		note.blocked = false;
	}
	else if ( action === "add_line" )
	{
		this.page.lines.push( data );
		this.shiftOldestLine();
	}
	else if ( action === "add_current_line_point" )
	{
		if ( !this.other_participants_current_lines[ data.id ] )
		{
			this.other_participants_current_lines[ data.id ] = {
				width: data.width,
				points: [ data.x, data.y ],
				color: data.color
			}
		}
		else
		{
			this.other_participants_current_lines[ data.id ].points.push( data.x, data.y );
		}
	}
	else if ( action === "end_current_line" )
	{
		delete this.other_participants_current_lines[ data.id ];
	}
	else if ( action === "resize_column" )
	{
		this.page.columns[ data.index ].x = data.x;
	}
	else if (action === "page_left")
	{
		this.current_page_index = data.page_index;
		this.page = this.pages[this.current_page_index];
		this.updatePageCountDisplay();
	}
	else if ( action === "page_right" )
	{
		if (this.current_page_index === this.page_count_max - 1)
			return;

		// add new page if no pages to right
		if (this.current_page_index === this.pages.length - 1)
		{
			this.addPage();
		}
		this.current_page_index = data.page_index;
		this.page = this.pages[this.current_page_index];
		this.updatePageCountDisplay();
	}
	else if ( action === "erase" )
	{
		this.clear();
	}
	else if ( action === "add_column" )
	{
		this.addColumn();
	}
	else if ( action === "remove_column" )
	{
		this.removeColumn();
	}
	else if ( action === "erase_line" )
	{
		this.page.lines.splice(data.index, 1);
	}
	else if ( action === "drawing_mode")
	{
		this.mode = this.MODES.DRAW;
		this.elements["pen"].goToFrame(2);
		this.xyz.allowClick = true;
		this.xyz.allowMove = true;
	}
}

WhiteboardApp.prototype.processKeyDown = function(e, ev)
{
	if (ev.key === "Enter") {
		this.defocusAllColumnHeaders();
	}
}

WhiteboardApp.prototype.onMouseLeave = function(_ev)
{
	this.dragging = null;
	this.mouse_action = null;

	if ( this.mode === this.MODES.DRAW )
	{
		this.mouse_state = "mouseup";
	}
}

WhiteboardApp.prototype.onMouse = function(ev, _active)
{
	this.hovered = true;

	if ( ev.eventType === "mouseup" && ev.button === 0 )
	{
		this.dragging = null;
		this.mouse_state = ev.eventType;


		if ( this.click_timeout )
			this.mouse_action = "click";

		else if ( this.mouse_action === "drag" )
			this.mouse_action = null;
	}
	else if ( ev.eventType === "mousedown" && ev.button === 0 )
	{
		this.mouse_state = ev.eventType;
		if ( !this.click_timeout )
		{
			this.click_timeout = setTimeout(() => {
				this.click_timeout = null;
			}, 200);
		}
	}
	else if ( ev.eventType === "mousemove" )
	{
		if ( this.mouse_state === "mousedown" ) {
			this.mouse_action = "drag";
		}
	}
}

WhiteboardApp.prototype.allowDragMouseAction = function()
{
	if ( this.mode != this.MODES.NONE)
		return false;
	return true;
}

WhiteboardApp.prototype.loadImages = function()
{
	const manager = new LoadingManager( null, () => {
		this.images_loaded = true;
	});

	/* Images */
	for (var i in this.info.images )
	{
		if ( !this.info.images.hasOwnProperty(i) )
			continue;

		ROOM.getImage( this.info.images[i], manager );
	}

	/* Optional images */
	ROOM.getImage( this.info.optional_images[ this.background ], manager );
}

WhiteboardApp.prototype.update = function(dt, t, mouse)
{
	if ( !this.initialized )
		return;

	this.cursor_style = "";
	this._keep_note_selected = false;

	this.row_header_offset = this.page.columns.length > 0 ? this.column_header_height : 0;
	this.row_area_height = this.height - this.row_header_offset;
	const row_height = Math.floor(this.row_area_height / (this.page.rows.length + 1));
	for (var i = 0; i < this.page.rows.length; i++)
	{
		this.page.rows[i].update(i, this.row_header_offset, row_height);
	}

	if (!xyz.mobile)
		this.updateInterface(dt, t, mouse);

	if ( this.mode === this.MODES.NONE || this.mode === this.MODES.NOTES )
		this.updateNotes(mouse);

	if ( this.mode === this.MODES.DRAW )
	{
		if ( this.selected_note )
			this.selected_note.exitSelect();
		this.updateDraw(mouse);
	}

	if ( this.mode === this.MODES.ERASE )
		this.updateErase(mouse);

	if ( this.page.notes.length > 0 )
	{
		for (var i = 0; i < this.page.notes.length; i++ )
		{

			this.page.notes[i].updateContent();
		}
	}

	// Selected note is unselected when click somewhere else
	if ( this.mouse_action === "click" )
	{
		if ( this.selected_note && !this._keep_note_selected )
		{
			this.selected_note.exitSelect();
			this.selected_note = null;
		}
	}
}

WhiteboardApp.prototype.updateInterface = function(dt, t, mouse, _tex, _ctx)
{
	var GUI = this.surface.GUI;

	if ( this.mode !== this.MODES.DRAW )
	{
		this.elements["pen"].goToFrame(0);
	}
	else
	{
		this.elements["pen"].goToFrame(2);
	}

	this.elements["note_stack"].goToFrame(0);

	if ( this.mode !== this.MODES.ERASE )
	{
		this.elements["eraser"].goToFrame(0);
	}
	else
	{
		this.elements["eraser"].goToFrame(2);
	}

	if ( this.mode !== this.MODES.NONE )
	{
		this.elements["select-arrow"].goToFrame(0);
	}
	else
	{
		this.elements["select-arrow"].goToFrame(1);
	}

	// hovering page controls - left
	if ( this.elements["page-arrow-left"].isHovered( GUI ) )
	{
		this.cursor_style = "pointer";
		if ( this.mouse_action === "click" )
		{
			if (this.current_page_index > 0)
			{
				this.current_page_index--;
				this.page = this.pages[this.current_page_index];
				this.updatePageCountDisplay();
				this.sendAction({ action: "page_left", data: { page_index: this.current_page_index } });
			}
		}
	}

	else if ( this.elements["page-arrow-right"].isHovered( GUI ) )
	{
		this.cursor_style = "pointer";
		if ( this.mouse_action === "click" )
		{
			if (this.current_page_index === this.page_count_max - 1)
				return;

			// add new page if no pages to right
			if (this.current_page_index === this.pages.length - 1)
			{
				this.addPage();
			}

			this.current_page_index++; // increment current page index
			this.page = this.pages[this.current_page_index];
			this.updatePageCountDisplay();
			this.sendAction({ action: "page_right", data: { page_index: this.current_page_index } });
		}
	}

	else if ( this.elements["erase-btn"].isHovered( GUI ) )
	{
		this.cursor_style = "pointer";
		if ( this.mouse_action === "click" )
		{
			this.clear();
			this.sendAction({ action: "erase" });
		}
	}

	else if (this.elements["column-add-btn"].isHovered( GUI )) {
		this.cursor_style = "pointer";
		if ( this.mouse_action === "click")
		{
			this.stopDrawing();
			this.mode = this.MODES.NONE;
			this.addColumn();
			this.sendAction({ action: "add_column" });
		}
	}

	else if (this.elements["column-remove-btn"].isHovered( GUI )) {
		this.cursor_style = "pointer";
		if ( this.mouse_action === "click")
		{
			this.removeColumn();
			this.sendAction({ action: "remove_column" });
		}
	}

	// hovering select arrow
	else if ( this.elements["select-arrow"].isHovered( GUI ) )
	{
		this.cursor_style = "pointer";
		this.elements["select-arrow"].goToFrame(1);
		if ( this.mouse_action === "click" )
		{
			this.mode = this.MODES.NONE;
		}
	}

	// hovering pen
	else if ( this.elements["pen"].isHovered( GUI ) )
	{
		this.cursor_style = "pointer";
		if ( this.mouse_action === "click" )
		{
			if (this.mode === this.MODES.DRAW)
			{
				this.stopDrawing();
				//this.sendState();
				this.mode = this.MODES.NONE;
				this.elements["pen"].goToFrame(0);
			}
			else
			{
				this.mode = this.MODES.DRAW;
				this.elements["pen"].goToFrame(2);
			}
		}
		else if ( this.mouse_state === "mousedown" && this.mode !== this.MODES.DRAW )
		{
			this.elements["pen"].goToFrame(1);
		}
		else if ( this.mouse_state === "mouseup" )
		{
			if (this.mode !== this.MODES.DRAW)
			{
				this.elements["pen"].goToFrame(1);
			}
		}
	}

	// hovering note stack
	else if ( GUI.isInsideRect( mouse.position, this.elements["note_stack"].x, this.elements["note_stack"].y, this.elements["note_stack"].width, this.elements["note_stack"].height) )
	{
		this.elements["note_stack"].goToFrame(1);
		this.cursor_style = "pointer";
		if ( this.mouse_action === "click" )
		{
			this.mode = this.MODES.NOTES;
			this.addNote();
			const note = this.page.notes[this.page.notes.length - 1];
			const data = {
				position: note.position,
				text: note.text.text,
				rotation: note.rotation,
				color: note.color
			};
			this.sendAction({ action: "add_note", data: data });
			this.stopDrawing();
			this._keep_note_selected = true;
			this.page.notes[this.page.notes.length-1].enterSelect();
		}
	}

	// hovering eraser
	else if ( GUI.isInsideRect( mouse.position, this.elements["eraser"].x, this.elements["eraser"].y, this.elements["eraser"].width, this.elements["eraser"].height) )
	{
		this.cursor_style = "pointer";
		if ( this.mouse_action === "click" )
		{
			if (this.mode === this.MODES.ERASE)
			{
				this.mode = this.MODES.NONE;
				this.elements["eraser"].goToFrame(0);
			}
			else
			{
				this.mode = this.MODES.ERASE;
				this.elements["eraser"].goToFrame(1);
			}
		}
		else if ( this.mouse_state === "mousedown" && this.mode !== this.MODES.ERASE )
		{
			this.elements["eraser"].goToFrame(1);
		}
		else if ( this.mouse_state === "mouseup" )
		{
			if (this.mode !== this.MODES.ERASE)
			{
				this.elements["eraser"].goToFrame(1);
			}
		}
	}

	// hovering download button
	else if ( GUI.isInsideRect( mouse.position, this.elements["download-btn"].x, this.elements["download-btn"].y, this.elements["download-btn"].width, this.elements["download-btn"].height) )
	{
		this.cursor_style = "pointer";
		if ( this.mouse_action === "click" )
		{
			var texture = this.surface._texture;
			if ( texture )
			{
				// Y flip
				var buffer = texture.getPixels();
				texture.setPixels(buffer, false);
				// download
				var blob = texture.toBlob();
				ROOM.downloadFile("whiteboard.png",blob);
			}
		}
	}

	else if ( this.mode === this.MODES.DRAW )
	{
		const scale_factor = this.scale_factor;
		if ( GUI.isInsideRect( mouse.position, this.drawModeElements["stroke-ss-1"].x, this.drawModeElements["stroke-ss-1"].y, this.drawModeElements["stroke-ss-1"].width, this.drawModeElements["stroke-ss-1"].height ) )
		{
			this.cursor_style = "pointer";
			if ( this.mouse_action === "click" )
			{
				this.line_width = 3 * scale_factor;
				this.consumeClick();
			}
		}
		else if ( GUI.isInsideRect( mouse.position, this.drawModeElements["stroke-ss-2"].x, this.drawModeElements["stroke-ss-2"].y, this.drawModeElements["stroke-ss-2"].width, this.drawModeElements["stroke-ss-2"].height ) )
		{
			this.cursor_style = "pointer";
			if ( this.mouse_action === "click" )
			{
				this.line_width = 6 * scale_factor;
				this.consumeClick();
			}
		}
		else if ( GUI.isInsideRect( mouse.position, this.drawModeElements["stroke-ss-3"].x, this.drawModeElements["stroke-ss-3"].y, this.drawModeElements["stroke-ss-3"].width, this.drawModeElements["stroke-ss-3"].height ) )
		{
			this.cursor_style = "pointer";
			if ( this.mouse_action === "click" )
			{
				this.line_width = 15 * scale_factor;
				this.consumeClick();
			}
		}
	}
}

WhiteboardApp.prototype.updateNotes = function(mouse)
{
	var note;
	var hovered;

	if ( !this.focused_note )
	{
		for (var i = this.page.notes.length - 1; i >= 0; i--)
		{
			note = this.page.notes[i];
			if ( this.mouse_action === "click" )
			{
				hovered = GUI.isInsideRect(mouse.position, note.position[0] - note.width / 2, note.position[1] - note.height / 2, note.width, note.height);
				if ( hovered )
				{
					this._keep_note_selected = true;
					// note was already selected -> focus
					if ( note === this.selected_note )
					{
						note.enterFocus();
					}
					// else, select note
					else
					{
						note.enterSelect();

					}
					break;
				}
			}
			// dragging notes
			else if ( this.mouse_action === "drag" && !this.column_line_is_dragged && !this.row_line_is_dragged )
			{
				if ( !this.dragging )
				{
					hovered = GUI.isInsideRect( mouse.position, note.position[0] - note.width / 2, note.position[1] - note.height / 2, note.width, note.height);
					if ( hovered )
						this.dragging = note;
				}
				if ( this.dragging === note && !note.blocked )
				{
					this._keep_note_selected = true;
					if ( !this.mouse_prev_position )
					{
						this.mouse_prev_position = [ mouse.position[0], mouse.position[1] ];
					}
					else
					{
						note.position[0] += mouse.position[0] - this.mouse_prev_position[0];
						note.position[1] += mouse.position[1] - this.mouse_prev_position[1];
						note.position[0] = parseFloat(note.position[0].toFixed(2));
						note.position[1] = parseFloat(note.position[1].toFixed(2));

						var data = {
							id: note.id,
							x: note.position[0],
							y: note.position[1]
						};
						this.sendAction({ action: "move_note", data: data });
						this.mouse_prev_position = [ mouse.position[0], mouse.position[1] ];
					}
				}
			}
			else if (this.mouse_state === "mousedown")
			{
				hovered = GUI.isInsideRect( mouse.position, note.position[0] - note.width / 2, note.position[1] - note.height / 2, note.width, note.height);
				if ( hovered )
				{
					this._keep_note_selected = true;
				}
			}
			else if (this.mouse_state === "mouseup")
			{
				this.mouse_prev_position = null;
			}
		}

	}
	else if ( this.focused_note )
	{
		if ( this.mouse_action === "click" )
		{
			note = this.focused_note;
			hovered = GUI.isInsideRect( mouse.position, note.position[0] - note.width / 2 * note.scale, note.position[1] - note.height / 2 * note.scale, note.width * note.scale, note.height * note.scale);
			if ( !hovered )
			{
				note.exitFocus();
				this.focused_note = null;
			}
		}
	}
}

//called from update
WhiteboardApp.prototype.updateDraw = function(mouse)
{

	this.drawing_cursor = 1;

	if (!this.viewport)
		return;

	if ( this.mouse_state === "mousedown" && mouse.position[1] > (this.viewport[1] + this.header_height) )
	{
		this.drawing_cursor = 2;

		if ( !this.cancel_mousedown)
		{
			var first_point = false;
			if ( !this.current_line )
			{
				first_point = true;
				this.current_line = {
					width: this.line_width,
					points: [],
					color: this.line_color
				};
			}
			const drawing_x = Math.round(mouse.position[0]);
			const drawing_y = Math.round(mouse.position[1]);

			// only draws if the mouse is moving
			if (first_point || !first_point && (drawing_x !== this.last_drawing_x || drawing_y !== this.last_drawing_y) )
			{
				this.current_line.points.push(drawing_x, drawing_y);

				const data = { id: this.xyz.space.local_participant.id.toString(), x: drawing_x, y: drawing_y };
				//if ( first_point )
				{
					data.width = this.line_width;
					data.color = this.line_color;
				}

				this.last_drawing_x = drawing_x;
				this.last_drawing_y = drawing_y;
				this.sendAction({ action: "add_current_line_point", data: data });
				console.debug("send add line", this.line_color);
			}
		}
	}
	else if ( this.public_drawing_x && this.public_drawing_y )
	{
		this.public_drawing_was_active = true;
		var first_point = false;
		if ( !this.current_line )
		{
			first_point = true;
			this.current_line = {
				width: this.line_width,
				points: [],
				color: this.line_color
			};
		}
		this.current_line.points.push(this.public_drawing_x, this.public_drawing_y);
		const data = { id: this.xyz.space.local_participant.id.toString(), x: this.public_drawing_x, y: this.public_drawing_y };
		if ( first_point )
		{
			data.width = this.line_width;
			data.color = this.line_color;
		}
		this.sendAction({ action: "add_current_line_point", data: data });
	}




	if (this.mouse_state === "mouseup" && this.last_mouse_state !== this.mouse_state )
	{
		this.stopDrawing();
	}
	else if ( !this.public_drawing_x && !this.public_drawing_y && this.public_drawing_was_active )
	{
		this.public_drawing_was_active = false;
		this.stopDrawing();
	}
}

WhiteboardApp.prototype.updateErase = function(mouse)
{
	var hovered_line_index = null;
	var mousex = Math.round(mouse.position[0]);
	var mousey = Math.round(mouse.position[1]);

	for (var i = 0; i < this.page.lines.length; i++)
	{
		var line = this.page.lines[i];
		line.hovered = false;

		 if ( hovered_line_index !== null )
			 continue;

		var is_inside = false;
		var points = line.points;
		for ( var j = 0; j < points.length; j+=2)
		{
			var d = WhiteboardApp.distance(mousex, mousey, points[j], points[j+1]);
			if ( d > (20 + line.width) )
				continue;
			is_inside = true;
			break;
		}

		if (!is_inside)
			continue;

		this.cursor_style = "not-allowed";
		hovered_line_index = i;
		if ( this.mouse_state === "mousedown" )
		{
			this.page.lines.splice(i, 1);
			this.sendAction({ action: "erase_line", data: { index: i } });
		}
		else if ( this.mouse_state === "mouseup" )
		{
			line.hovered = true;
		}
	}
}

WhiteboardApp.distance = function(x,y,x2,y2)
{
	var dx = x2 - x;
	var dy = y2 - y;
	return Math.sqrt( dx*dx + dy*dy );
}

//called from
WhiteboardApp.prototype.stopDrawing = function()
{
	if ( this.cancel_mousedown )
	{
		this.cancel_mousedown = false;
	}

	if (this.current_line && this.current_line.points.length > 2)
	{
		this.page.lines.push(this.current_line);
		this.sendAction( { action: "end_current_line", data: { id: this.xyz.space.local_participant.id.toString() } });
		this.sendAction( { action: "add_line", data: this.current_line });
	}
	this.current_line = null;

	this.shiftOldestLine();
}

WhiteboardApp.prototype.shiftOldestLine = function()
{
	var current_page = this.pages[ this.current_page_index ];
	this.point_count = 0;
	for (var i = 0; i < current_page.lines.length; i++)
	{
		this.point_count += current_page.lines[i].points.length;
	}



	while ( this.point_count > this.max_point_count )
	{
		this.point_count -= current_page.lines[0].points.length;
		current_page.lines.splice(0, 1);
	}
}

WhiteboardApp.prototype.render = function (ctx, tex, GUI, mouse, viewport)
{
	if ( !this.initialized )
		this.init(tex,viewport);

	this.viewport = viewport;


	ctx.drawImage( ROOM.getImage(this.info.optional_images[ this.background ] ), viewport[0], viewport[1], viewport[2], viewport[3] );


	this.renderColumns(ctx, tex, GUI, mouse, viewport);
	this.renderRows(ctx, tex, GUI, mouse, viewport);
	this.renderDrawings(ctx);
	this.renderNotes(ctx, tex, GUI, viewport);
	this.renderDrawingCursor(ctx, tex, GUI, mouse, viewport);
	if (!xyz.mobile)
	{
		this.renderInterface(ctx, tex, GUI, mouse, viewport);
		this.renderDrawModeToolbar(ctx, tex, GUI, mouse, viewport);
	}
	this.renderCursors(ctx, tex, GUI, mouse, viewport);

	this.postRender();
}

WhiteboardApp.prototype.postRender = function()
{
	this.last_mouse_state = this.mouse_state;

	if ( this.mouse_action === "click" )
	{
		this.mouse_action = null;
	}

	//ROOM.cursor_style = this.cursor_style;
}

WhiteboardApp.prototype.renderBackgroundGrid = function(ctx, tex, _GUI, _mouse, _viewport)
{
	if ( !this.background_grid )
	{
		return;
	}

	ctx.lineWidth = 2;
	ctx.strokeStyle = "#dddddd";
	for (var x = 0; x < tex.width; x += 30)
	{
		ctx.beginPath();
		ctx.moveTo(x, this.header_height);
		ctx.lineTo(x, this.height);
		ctx.stroke();
	}
	for (var y = this.header_height + 1; y < this.height; y += 30)
	{
		ctx.beginPath();
		ctx.moveTo(0, y);
		ctx.lineTo(tex.width, y);
		ctx.stroke();
	}
	ctx.lineWidth = 1;
}

WhiteboardApp.prototype.renderInterface = function(ctx, tex, GUI, mouse, viewport)
{
	const x = viewport[0];
	const y = viewport[1];
	const w = viewport[2];

	ctx.fillStyle = "white";
	ctx.fillRect( x, y, w, this.header_height );

	for (const i in this.elements )
	{
		if (this.elements.hasOwnProperty(i))
		{
			this.elements[i].render( ctx );
		}
	}
}

WhiteboardApp.prototype.consumeClick = function(GUI)
{
	if ( GUI )
	{
		GUI.consumeClick();
	}
	this.cancel_mousedown = true;
}

WhiteboardApp.prototype.renderDrawModeToolbar = function(ctx, tex, GUI, mouse, viewport)
{
	if ( this.mode !== this.MODES.DRAW )
	{
		return;
	}

	const x = viewport[0];
	const y = viewport[1];

	const scale_factor = this.scale_factor;

	const color_palette = [ "black", "#8c8b90", "#0088fe", "#014bb4", "#9300b3", "#00b48f", "#2db400", "#55ee08", "#fecb00", "#ffac01", "#fe0034" ];
	var sx = x + 400 * scale_factor;
	const mid_header_y = this.header_height / 2;
	for (var i = 0; i < color_palette.length; i++)
	{
		if (Button.call(GUI, sx - 10 * scale_factor, y + mid_header_y - 20 * scale_factor / 2, 20 * scale_factor, 20 * scale_factor, [ 15,15 ], true, 0 ))
		{
			this.stopDrawing();
			this.line_color = color_palette[i];
		}

		if (this.line_color === color_palette[i])
		{
			ctx.lineWidth = 1.5 * scale_factor;
			ctx.strokeStyle = "#0089ff";
			ctx.beginPath();
			ctx.arc( sx, y + mid_header_y, 12 * scale_factor, 0, 2 * Math.PI);
			ctx.stroke();
			ctx.lineWidth = 1;
		}

		ctx.fillStyle = color_palette[i];
		ctx.beginPath();
		ctx.arc( sx, y + mid_header_y, 9 * scale_factor, 0, 2 * Math.PI);
		ctx.fill();
		sx += 30 * scale_factor;
	}

	sx += 50 * scale_factor;

	var strokeButtonY = y + mid_header_y - this.drawModeElements["stroke-ss-1"].height / 2;
	var selected = this.line_width === 3*scale_factor ? 3 : 0;
	this.drawModeElements["stroke-ss-1"].goToFrame(0 + selected);
	this.drawModeElements["stroke-ss-1"].x = sx;
	this.drawModeElements["stroke-ss-1"].y = strokeButtonY;
	this.drawModeElements["stroke-ss-1"].render( ctx );

	selected = this.line_width === 6*scale_factor ? 3 : 0;
	sx += 32 * scale_factor;
	this.drawModeElements["stroke-ss-2"].goToFrame(1 + selected);
	this.drawModeElements["stroke-ss-2"].x = sx;
	this.drawModeElements["stroke-ss-2"].y = strokeButtonY;
	this.drawModeElements["stroke-ss-2"].render( ctx );

	selected = this.line_width === 15*scale_factor ? 3 : 0;
	sx += 35 * scale_factor;
	this.drawModeElements["stroke-ss-3"].goToFrame(2 + selected);
	this.drawModeElements["stroke-ss-3"].x = sx;
	this.drawModeElements["stroke-ss-3"].y = strokeButtonY;
	this.drawModeElements["stroke-ss-3"].render( ctx );
}

WhiteboardApp.prototype.renderDrawings = function(ctx)
{
	var i;
	var spline_points;
	var points;
	for (var u = 0; u < this.page.lines.length; u++)
	{
		if ( this.page.lines[u].hovered )
		{
			ctx.strokeStyle = "rgba(0,0,0,.3)";
		}
		else
		{
			ctx.strokeStyle = this.page.lines[u].color;
		}

		const line = this.page.lines[u];
		if ( !this.line_smoothing )
		{
			this.renderLine(ctx, line.points, line.width);
		}
		else
		{
			points = this.reducedPointsArray(line.points, this.pointsArrayDivisor);
			spline_points = getCurvePoints(points, this.tension, this.numOfSeg);

			if ( 0 ) //USE PATHS: not supported in WebGL Canvas implementation
			{
				if ( !line.spline_points_path2d )
				{
					line.spline_points_path2d = new Path2D();
					line.spline_points_path2d.moveTo(spline_points[0], spline_points[1]);
					for (i = 2; i < spline_points.length; i+=2)
					{
						line.spline_points_path2d.lineTo(spline_points[i], spline_points[i+1]);
					}
				}
				ctx.lineWidth = line.width;
				ctx.lineCap = ctx.lineJoin = "round";
				ctx.stroke(line.spline_points_path2d);
				ctx.lineWidth = 1;
			}
			else
			{
				ctx.beginPath();
				ctx.moveTo(spline_points[0], spline_points[1]);
				for (i = 2; i < spline_points.length; i+=2)
					ctx.lineTo(spline_points[i], spline_points[i+1]);
				ctx.lineWidth = line.width;
				ctx.lineCap = ctx.lineJoin = "round";
				ctx.stroke();
				ctx.lineWidth = 1;
			}
		}
	}

	// other participants current lines
	for (var u in this.other_participants_current_lines )
	{
		if (this.other_participants_current_lines.hasOwnProperty(u))
		{
			const line = this.other_participants_current_lines[u];
			ctx.strokeStyle = line.color;
			if ( !this.line_smoothing )
			{
				this.renderLine(ctx, line.points, line.width);
			}
			else
			{
				points = this.reducedPointsArray(line.points, this.pointsArrayDivisor);
				spline_points = getCurvePoints( points, this.tension, this.numOfSeg );
				this.renderLine(ctx, spline_points, line.width);
			}
		}
	}

	if (this.current_line)
	{
		ctx.strokeStyle = this.current_line.color;

		if ( !this.line_smoothing )
		{
			this.renderLine(ctx, this.current_line.points, this.current_line.width);
		}
		else
		{
			const line = this.current_line;
			points = this.reducedPointsArray(line.points, this.pointsArrayDivisor);
			spline_points = getCurvePoints( points, this.tension, this.numOfSeg );
			this.renderLine(ctx, spline_points, line.width);
		}
	}
}

WhiteboardApp.prototype.renderDrawingCursor = function(ctx, tex, GUI, mouse)
{
	if ( !this.drawing_cursor || this.mode !== this.MODES.DRAW )
	{
		return;
	}
	ctx.beginPath();
	if ( this.drawing_cursor === 1)
	{
		ctx.arc( mouse.position[0], mouse.position[1], this.line_width + 1, 0, 2 * Math.PI );
		ctx.fillStyle = "rgba(0,0,0,.6)";
	}
	else if ( this.drawing_cursor === 2)
	{
		ctx.arc( mouse.position[0], mouse.position[1], this.line_width + 1, 0, 2 * Math.PI );
		ctx.fillStyle = "rgba(0,0,0,.3)";
	}
	ctx.fill();
}

WhiteboardApp.prototype.reducedPointsArray = function(line_points, frequency)
{
	const points = [];
	const length = line_points.length;
	const freq = Math.round(frequency);

	for (var i = 0; i < length - 2; i += 2 * freq )
	{
		points.push( line_points[i] );
		points.push( line_points[i + 1] );
	}
	points.push( line_points[length - 2] );
	points.push( line_points[length - 1] );

	return points;
}

WhiteboardApp.prototype.renderLine = function(ctx, points, line_width)
{
	ctx.lineWidth = (line_width || this.line_width);
	ctx.beginPath();
	ctx.lineCap = ctx.lineJoin = "round";
	ctx.moveTo( points[0], points[1] );
	for (var i = 2, l = points.length; i < l; i += 2)
	{
		ctx.lineTo(points[i], points[i+1]);
	}
	ctx.stroke();
	ctx.closePath();
	ctx.lineWidth = 1;
}

WhiteboardApp.prototype.clearDrawings = function()
{
	this.page.lines = [];
	this.current_line = null;
}

WhiteboardApp.prototype.renderNotes = function(ctx, tex, GUI)
{
	ctx.save(); // prevents the note from changing global ctx parameters such as textBaseLine, etc.
	for (var i = 0; i < this.page.notes.length; i++)
	{
		const note = this.page.notes[i];
		if ( note === this.focused_note ) {
			continue;
		}
		note.render(ctx, tex, GUI);
	}

	if ( this.focused_note )
	{
		ctx.fillStyle = "rgba(0, 0, 0, .3)";
		ctx.fillRect(0, 0, tex.width, this.height);
		this.focused_note.render(ctx, tex, GUI);
	}
	ctx.restore();
}

WhiteboardApp.prototype.clearNotes = function()
{
	this.page.notes = [];
	this.focused_note = null;
}

WhiteboardApp.prototype.renderColumns = function (ctx, tex, GUI, mouse, viewport)
{
	if (this.page.columns.length < 1) {
		return;
	}

	// reset hovered flag
	this.table_header_is_hovered = false;

	// reset column line hover flag
	this.column_line_is_hovered = false;

	// set line styles
	ctx.strokeStyle = this.column_stroke_color;
	ctx.lineWidth = this.column_line_width;

	// default column positions
	const editableAreaWidth = tex.width;
	const columnWidth = Math.floor(editableAreaWidth / this.page.columns.length);

	const minColumnWidth = Math.floor(editableAreaWidth / this.column_count_max);

	for (var i = 0; i < this.page.columns.length; i++)
	{
		const column = this.page.columns[i];
		// default position
		if (column.x === null) {
			column.x = i * columnWidth;
		}

		//  draw dividing lines
		if (i > 0) {
			ctx.beginPath();
			ctx.moveTo(column.x, viewport[1]);
			ctx.lineTo(column.x, this.height);
			ctx.stroke();

			// create hover area for lines
			const lineHoverArea = GUI.HoverArea(column.x - 10, viewport[1], this.column_line_width + 10, this.height);
			if (lineHoverArea === GLUI.HOVER) {
				this.column_line_is_hovered = true;
			}

			if (lineHoverArea === GLUI.CLICKED && this.mode === this.MODES.NONE) {
				column.resizing = true;
				this.column_line_is_dragged = true;
			}
		}

		if (column.resizing === true) {
			if (mouse.dragging === true) {
				var nextColumnX = editableAreaWidth;
				if (typeof this.page.columns[i + 1] !== "undefined") {
					nextColumnX = this.page.columns[i + 1].x;
				}

				var prevColumnX = 0;
				if (typeof this.page.columns[i - 1] !== "undefined") {
					prevColumnX = this.page.columns[i - 1].x;
				}

				// prevent columns being smaller than minColumnWidth
				if (
					(nextColumnX - mouse.position[0] >= minColumnWidth) &&
                    (mouse.position[0] - prevColumnX >= minColumnWidth)
				) {
					column.x = mouse.position[0];
					var data = { index: i, x: column.x };
					this.sendAction({ action: "resize_column", data: data });
				}
			} else {
				column.resizing = false;
				this.column_line_is_dragged = false;
			}
		}
	}



	if (this.column_line_is_hovered === true) {
		this.cursor_style = "col-resize";
	}

	ctx.lineWidth = 1;
}

WhiteboardApp.prototype.renderRows = function (ctx, tex, GUI, mouse) {
	if (this.page.rows.length < 1) {
		return;
	}

	// set line styles
	ctx.strokeStyle = this.column_stroke_color;
	ctx.lineWidth = this.column_line_width;

	for (var i = 0; i < this.page.rows.length; i++)
	{
		this.page.rows[i].render(ctx, tex, GUI, mouse);
	}

	ctx.lineWidth = 1;
}

WhiteboardApp.prototype.clearColumnsAndRows = function()
{
	this.page.rows = [];
	this.page.columns = [];
}

WhiteboardApp.prototype.defocusAllColumnHeaders = function() {
	this.page.columns.forEach(column => {
		column.title.focused = false
	})
}

WhiteboardApp.prototype.addNote = function(options = {}, page_index = this.current_page_index)
{
	const page = this.pages[page_index];
	const id = page.notes.length;
	if (!options.page_index)
	{
		options.page_index = page_index || this.current_page_index;
	}
	if (page.notes.length < this.max_note_count)
	{
		page.notes.push( new WhiteboardNote(this, id, options) );
	}
}

WhiteboardApp.prototype.removeNote = function(id, page_index = this.current_page_index)
{
	var index = null;
	const page = this.pages[page_index];
	for (var i = 0; i < page.notes.length; i++)
	{
		if (page.notes[i].id === id)
		{
			index = i;
			page.notes[i] = null;
			break;
		}
	}
	if (typeof index === "number")
		page.notes.splice(index, 1);

	this.focused_note = null;
}

WhiteboardApp.prototype.clear = function(id)
{
	this.clearDrawings();
	this.clearNotes();
	this.clearColumnsAndRows();
}

WhiteboardApp.prototype.addColumn = function (options = {}, page_index = this.current_page_index)
{
	const page = this.pages[page_index];

	if (page.columns.length < this.column_count_max) {
		// reset default column positions when adding a new column
		if (page.columns.length > 0 && !options.prevent_reset) {
			for (var i = 0; i < page.columns.length; i++)
			{
				page.columns[i].x = null
			}
		}

		page.columns.push({
			x: options.x || null,
			title: this.editable_title({ text: options.title || null }),
			resizing: false
		});

		if ( page.columns.length === 1 && !options.prevent_reset)
		{
			this.addColumn();
		}
	}
}

WhiteboardApp.prototype.removeColumn = function ()
{
	for (var i = 0; i < this.page.columns.length; i++)
	{
		this.page.columns[i].x = null
	}

	if (this.page.columns.length > 0) {
		this.stopDrawing();
		this.mode = this.MODES.NONE;
		this.page.columns.pop();
	}

	if ( this.page.columns.length === 1)
	{
		this.removeColumn();
	}
}

WhiteboardApp.prototype.addRow = function (options = {}, page_index = this.current_page_index)
{
	const page = this.pages[page_index];

	if (page.rows.length < this.row_count_max) {
		this.stopDrawing();
		this.mode = this.MODES.NONE;

		// reset default row positions when adding a new row
		if (!options.prevent_reset)
		{
			for (var i = 0; i < page.rows.length; i++)
			{
				page.rows[i].y = null
			}
		}
		page.rows.push( new WhiteboardRow( this, page.rows.length, {
			y: options.y || null,
			resizing: false }) );
	}
}

WhiteboardApp.prototype.removeRow = function () {
	if (this.page.rows.length >= 1) {
		this.stopDrawing();
		this.mode = this.MODES.NONE;
		this.page.rows.pop();
	}

	for (var i = 0; i < this.page.rows.length; i++)
	{
		this.page.rows[i].y = null
	}
}

WhiteboardApp.prototype.addPage = function ()
{
	if (this.pages.length < this.page_count_max)
		this.pages.push( this.default_page() );
}

WhiteboardApp.prototype.updatePageCountDisplay = function ()
{
	if ( this.elements["page-count"] )
	{
		this.elements["page-count"].text = `${this.current_page_index+1}/${this.page_count_max}`;
	}
}

WhiteboardApp.prototype.removePage = function ( page_index )
{
	this.pages.splice( page_index, 1 );
	this.current_page_index = 0;
}

WhiteboardApp.prototype.onLeave = function()
{
	this.mouse_state = null;
	this.cursor_style = null;
	this.drawing_cursor = false;

	if ( this.selected_note )
	{
		this.selected_note.exitSelect();
	}

	this.sendAction({ action: "remove_participant_cursor", data: { id: this.xyz.space.local_participant.id } })
}

WhiteboardApp.prototype.renderCursors = function(ctx, tex, GUI, mouse)
{
	for ( var i in this.participant_cursors )
	{
		var c = this.participant_cursors[i];

		if ( !c )
		{
			continue;
		}

		var width = 4;

		c.position[0] = lerp( c.prev_position[0], c.position[0], .2 );
		c.position[1] = lerp( c.prev_position[1], c.position[1], .2 );

		c.prev_position[0] = c.position[0];
		c.prev_position[1] = c.position[1];

		ctx.lineWidth = 1;
		ctx.beginPath();
		ctx.fillRect( c.position[0] - width / 2, c.position[1] - width / 2, width, width );
		ctx.font = `20px ${this.xyz.options.fontFamily}, Arial, sans-serif`;
		ctx.fillText( c.name, c.position[0] + 10, c.position[1] );
	}
}

WhiteboardApp.prototype.onParticipantLeave = function( e,  participant )
{
	for (var i = 0; i < this.pages.length; i++)
	{
		var page = this.pages[i];
		if ( page.notes )
		{
			for (var u = 0; u < page.notes.length; u++)
			{
				var note = page.notes[u];
				if ( note.blocked.id === participant.id )
				{
					note.blocked = false;
				}
			}
		}
	}
}

WhiteboardApp.onRenderInspector = function( GUI, x, y, w, h, surface )
{
	var background = null;
	if ( surface && surface.apps_settings && surface.apps_settings.apps &&
		surface.apps_settings.apps[this.app_name] && surface.apps_settings.apps[this.app_name].background )
		background = surface.apps_settings.apps[this.app_name] && surface.apps_settings.apps[this.app_name].background;

	Label.call(GUI, x + 20, y, 160, 20, "Background" );
	var r = GUI.TextField( x + 180, y, w - 235, 20, background, false, true, false );
	if ( Button.call(GUI, x + w - 50, y, 20, 20, [ 2,0 ] ) )
	{
		var options = [ "background-1", "background-2", "background-3" ];
		options.unshift("");
		GUI.ShowContextMenu(options, (i,v) => {
			surface.updateAppsSettings(this.app_name, "background", v);

			// if app is currently running, updates background value
			var running_app = null;
			if (surface._app && surface._app.app_name === this.app_name )
				running_app = surface._app;
			else if (surface._app && surface._app.subapp && surface._app.subapp.app_name === this.app_name )
				running_app = surface._app.subapp;
			if ( running_app )
				running_app.background = v;
		});
	}

	return y;
}

WhiteboardApp.app_name = "Whiteboard";

/*
    - all "images" are loaded on start
    - "optional_images" are loaded conditionally
*/
WhiteboardApp.info = {
	"images": {
		"note--yellow-1": "/textures/apps/whiteboard/notes/note--yellow-1.png",
		"note--blue-1": "/textures/apps/whiteboard/notes/note--blue-1.png",
		"note--green-1": "/textures/apps/whiteboard/notes/note--green-1.png",
		"note--pink-1": "/textures/apps/whiteboard/notes/note--pink-1.png",
		"note--orange-1": "/textures/apps/whiteboard/notes/note--orange-1.png",
		"arrow-left": "/textures/apps/whiteboard/interface/arrow-left.png",
		"arrow-right": "/textures/apps/whiteboard/interface/arrow-right.png",
		"frame": "/textures/apps/whiteboard/interface/frame.png",
		"erase": "/textures/apps/whiteboard/interface/remove.png",
		"column_add": "/textures/apps/whiteboard/interface/column_add.png",
		"column_remove": "/textures/apps/whiteboard/interface/column_remove.png",
		"select-arrow-spritesheet": "/textures/apps/whiteboard/interface/select-arrow-spritesheet.png",
		"pen-spritesheet": "/textures/apps/whiteboard/interface/pen-spritesheet.png",
		"notes-spritesheet": "/textures/apps/whiteboard/interface/notes-spritesheet.png",
		"eraser-spritesheet": "/textures/apps/whiteboard/interface/eraser-spritesheet.png",
		"download-icon": "/textures/apps/whiteboard/interface/download.png",
		"stroke-spritesheet": "/textures/apps/whiteboard/interface/stroke-spritesheet.png"
	},
	"optional_images": {
		"background-1": "/textures/apps/whiteboard/backgrounds/background-1.jpg",
		"background-2": "/textures/apps/whiteboard/backgrounds/background-2.jpg",
		"background-3": "/textures/apps/whiteboard/backgrounds/background-3.jpg"
	},
	"colors": {
		"yellow": "#ffe26c",
		"green": "#e8ea86",
		"blue": "#69dbff",
		"pink": "#ff96c9",
		"orange": "#fbc697"
	}
};

export default WhiteboardApp;
