import Button from "@src/libs/GLUI/Elements/Button";
import clamp from "@src/math/clamp";

function MediaPlayerApp( surface )
{
	this.surface = surface;

	this.media_url = "";

	this.fade_factor = 5;
}

MediaPlayerApp.app_name = "MediaPlayer";

//only called if active
MediaPlayerApp.prototype.onMouse = function(_e, _active)
{
	this.fade_factor = 5;
}

MediaPlayerApp.prototype.update = function(dt, _t)
{
	this.fade_factor -= dt;
}

MediaPlayerApp.prototype.render = function(ctx, tex, GUI, mouse)
{
	var xyz = XYZLauncher.instance;
	if (!this.surface.stream)
		return;
	var video = this.surface.stream._feed;
	if (!video || video.constructor !== HTMLVideoElement)
		return;
	var view = xyz.view;

	//if(view.fade_factor <= 0)
	//	return;

	var w = tex.width;
	var h = tex.height;

	var item_height = Math.min( h / 10, w / 10 );

	//seeking icon
	if (video.seeking)
	{
		ctx.fillColor = [ 0,0,0,0.3 ];
		ctx.fillRect(0,0,w,h);
		ctx.save();
		ctx.translate( w * 0.5, h * 0.5 );
		ctx.rotate( getTime() * 0.004 );
		GUI.DrawIcon( 0, 0, 1, [ 11,1 ] );
		ctx.restore();
	}
	else if (video.paused && video.currentTime === 0)
	{
		if ( Button.call(GUI, w * 0.5 - item_height*0.5, h * 0.5 - item_height*0.5, item_height, item_height, [ 5,1 ], false,null,null,20) )
		{
			video.play();
			RoomCall.instance.cooldown_time = getTime() + 100;
		}
	}

	if (this.fade_factor <= 0)
		return;

	//timebar
	if ( video.duration )
	{
		var alpha = clamp(this.fade_factor,0,1);
		var slider_x = item_height * 3;
		var slider_w = w - item_height * 6;
		var slider_h = item_height * 0.5;
		var slider_y = h - slider_h * 0.5 - item_height * 1.5;
		var hover_slider = GUI.isInsideRect( mouse.position, slider_x, slider_y - 10, slider_w, slider_h + 20);

		GUI.blockArea( slider_x, slider_y, slider_w, slider_h );

		ctx.fillColor = [ 0.4,0.4,0.4,alpha ];
		ctx.fillRect( slider_x, slider_y, slider_w, slider_h );

		var f = video.currentTime / video.duration;
		ctx.fillColor = [ 0.9,0.1,0.1,alpha ];
		ctx.fillRect( slider_x, slider_y, f * slider_w, slider_h );

		if (hover_slider)
		{
			var slider_center = slider_x + f * slider_w;
			ctx.beginPath();
			ctx.arc(slider_center, slider_y + slider_h * 0.5, slider_h, 0, Math.PI * 2 );
			ctx.fill();

			if ( mouse.buttons ) //is pressed
			{
				var f = (mouse.position[0] - slider_x) / slider_w;
				var time = video.duration * f;
				video.currentTime = time;
			}
		}
	}

	if (video.currentTime !== 0 && Button.call(GUI, item_height, h - item_height * 2, item_height, item_height, video.paused ? [ 5, 1 ] : [ 6, 1 ], false,[ 0.3, 0.3, 0.3, 0.9 ],[ 1, 1, 1 ],5) )
	{
		if (!video.paused)
			video.pause();
		else
		{
			video.play();
		}
	}

	if ( Button.call(GUI, w - item_height*2, h - item_height*2, item_height, item_height, !video.volume ? [ 5,7 ] : [ 4,7 ], false,[ 0.3,0.3,0.3,0.9 ],[ 1,1,1 ],5) )
		video.volume = video.volume ? 0 : 0.25;

	ctx.fillColor = [ 1,1,1,1 ];
}

export default MediaPlayerApp;
