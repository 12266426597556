import { getFullPath } from "@src/engine/Room/file-utils";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import clamp from "@src/math/clamp";

function AudioPlayer()
{
	this._enabled = true;
	this._volume = 0.25;
	this._url = "";
	this._autoplay = false;
	this._loop = false;
	this.restart_on_play = true;
	this.global_sound = true;

	//this.current_time = 0;

	//create audio
	this._audio = new Audio();
	this._audio.autoplay = this.autoplay;
}

AudioPlayer.icon = [ 4,7 ];
AudioPlayer.componentName = "AudioPlayer";
AudioPlayer.type = ROOM_TYPES.SOUND;
AudioPlayer.widgets = {
	url: { type:"asset", extensions:"mp3,wav,ogg" },
	volume: "number",
	global_sound: "boolean",
	loop: "boolean",
	autoplay: "boolean"
};

Object.defineProperty( AudioPlayer.prototype, "enabled", {
	get: function() {
		return this._enabled;
	},
	set: function(v) {
		this._enabled = v;
		if (v)
		{
			if ( this._audio.playing )
				this._audio.stop();
		}
		else if ( !this._audio.playing && this._url )
			this.play();
	}
});

Object.defineProperty( AudioPlayer.prototype, "url", {
	get: function() {
		return this._url;
	},
	set: function(v) {
		if (v != null)
			v = v.trim();
		if (v === this._url)
			return;
		this._url = v;
		this._audio.src = getFullPath(this._url);
	}
});

Object.defineProperty( AudioPlayer.prototype, "loop", {
	get: function() {
		return this._loop;
	},
	set: function(v) {
		this._loop = this._audio.loop = v;
	}
});

Object.defineProperty( AudioPlayer.prototype, "volume", {
	get: function() {
		return this._volume;
	},
	set: function(v) {
		this._volume = clamp( v, 0, 1 );
		this._audio.volume = clamp(this._volume * ROOM_SETTINGS.audio.environment_volume,0,1);
	}
});

Object.defineProperty( AudioPlayer.prototype, "autoplay", {
	get: function() {
		return this._autoplay;
	},
	set: function(v) {
		this._autoplay = this._audio.autoplay = v;
	}
});


AudioPlayer.prototype.onAdded = function(parent)
{

}

AudioPlayer.prototype.onRemoved = function(parent)
{
	this._audio.pause();
}

AudioPlayer.prototype.serialize = function(json)
{
	json.volume = this.volume;
	json.url = this.url;
	json.global_sound = this.global_sound;
	json.autoplay = this.autoplay;
	json.loop = this._loop;
	json.restart_on_play = this.restart_on_play;
	return json;
}

AudioPlayer.prototype.configure = function(json)
{
	this.volume = json.volume;
	this.url = json.url;
	this.global_sound = json.global_sound;
	this.loop = json.loop;
	this.autoplay = json.autoplay;
	this.restart_on_play = json.restart_on_play || false;
	this._audio.loop = this.loop;
}

//done every frame in case environment_volume changes...
AudioPlayer.prototype.update = function(dt)
{
	this._audio.volume = clamp(this._volume * ROOM_SETTINGS.audio.environment_volume,0,1);
}

//plays the sound
AudioPlayer.prototype.play = function()
{
	if (!this._url)
		return;
	if (this.restart_on_play)
		this._audio.currentTime = 0;
	this._audio.play();
}

AudioPlayer.prototype.pause = function()
{
	this._audio.pause();
}

AudioPlayer.prototype.getActions = function(o)
{
	return [ "Play","Stop","Restart" ];
}

AudioPlayer.prototype.onAction = function(v)
{
	if (v === "Play")
		this.play();
	else if (v === "Stop")
		this.pause();
	else if (v === "Restart")
		this._audio.currentTime = 0;
}

export default AudioPlayer;
