import { DEG2RAD } from "@src/constants";
import { directionVectorToRotation } from "@src/engine/helpers/directionVectorToRotation";
import { mat4, quat, vec2, vec3, vec4 } from "gl-matrix";
import lerp from "lerp";
const UP = vec3.fromValues(0, 1, 0);
const DEFAULT_FOV = 45 * DEG2RAD;
export class CameraState {
    constructor() {
        this.position = vec3.create();
        this.rotation = quat.create();
        this.fov = 0;
        /**
         * shifts perspective convergence point
         * can take values between -1 and 1 of each axis
         * @default [0,0]
         */
        this.shift = vec2.create();
    }
    /**
     * Returns normalized vector pointing forward in the direction the camera is facing
     */
    get forward() {
        const matrix = this.worldMatrix;
        const result = vec3.fromValues(0, 0, 1);
        vec3.transformMat4(result, result, matrix);
        vec3.normalize(result, result);
        return result;
    }
    get up() {
        return vec3.fromValues(0, 1, 0);
    }
    copy(other) {
        vec3.copy(this.position, other.position);
        quat.copy(this.rotation, other.rotation);
        vec2.copy(this.shift, other.shift);
        this.fov = other.fov;
    }
    setPosition(x, y, z) {
        vec3.set(this.position, x, y, z);
    }
    setRotation(x, y, z, w) {
        quat.set(this.rotation, x, y, z, w);
    }
    setRotationFromEuler(x, y, z) {
        quat.fromEuler(this.rotation, x, y, z);
    }
    dolly(distance) {
        const forwardLocal = vec3.fromValues(0, 0, -1);
        // Rotate the forward vector to get the global forward direction
        const forwardGlobal = vec3.create();
        vec3.transformQuat(forwardGlobal, forwardLocal, this.rotation);
        const movement = vec3.create();
        vec3.scale(movement, forwardGlobal, distance);
        vec3.add(this.position, this.position, movement);
    }
    truck(distance) {
        const rightLocal = vec3.fromValues(1, 0, 0);
        // Rotate the right vector to get the global right direction
        const rightGlobal = vec3.create();
        vec3.transformQuat(rightGlobal, rightLocal, this.rotation);
        const movement = vec3.create();
        vec3.scale(movement, rightGlobal, distance);
        vec3.add(this.position, this.position, movement);
    }
    clone() {
        const r = new CameraState();
        r.copy(this);
        return r;
    }
    equals(other) {
        return vec3.equals(this.position, other.position)
            && vec4.equals(this.rotation, other.rotation)
            && vec2.equals(this.shift, other.shift)
            && this.fov === other.fov;
    }
    lookAt(target) {
        directionVectorToRotation(this.rotation, this.position, target);
    }
    /**
     * Transformation from local coordinate space to world
     */
    get worldMatrix() {
        const world_transform = mat4.create();
        mat4.fromRotationTranslation(world_transform, this.rotation, this.position);
        return world_transform;
    }
    /**
     * move camera in local coordinate space
     * @example [-1,0,0] will move camera to the left relative to current orientation
     * @example [0,-1,0] will move camera up relative to current orientation
     * @example [0,0,1] will move camera forward relative to current orientation
     */
    translateLocal(translation) {
        // build current transform matrix
        const world_transform = this.worldMatrix;
        const local_translation = vec3.create();
        vec3.transformMat4(local_translation, translation, world_transform);
        this.translate(local_translation);
    }
    translate(translation) {
        vec3.add(this.position, this.position, translation);
    }
    lerp(from, to, fraction) {
        vec3.lerp(this.position, from.position, to.position, fraction);
        quat.slerp(this.rotation, from.rotation, to.rotation, fraction);
        vec2.lerp(this.shift, from.shift, to.shift, fraction);
        this.fov = lerp(from.fov, to.fov, fraction);
    }
    /**
     * Similar to matrix product, increments current camera state by the one supplied
     */
    compound(other) {
        vec3.add(this.position, this.position, other.position);
        quat.multiply(this.rotation, this.rotation, other.rotation);
        this.fov += other.fov;
        vec2.add(this.shift, this.shift, other.shift);
    }
    validate() {
        const isNaN = Number.isNaN;
        const p = this.position;
        const r = this.rotation;
        if (isNaN(p[0])
            || isNaN(p[1])
            || isNaN(p[2])
            || isNaN(r[0])
            || isNaN(r[1])
            || isNaN(r[2])
            || isNaN(r[3])
            || isNaN(this.fov)) {
            return false;
        }
        return true;
    }
    cinemaDolly(distance) {
        this.translateLocal([0, 0, distance]);
    }
    cinemaTruck(distance) {
        this.translateLocal([distance, 0, 0]);
    }
    cinemaPedestal(distance) {
        this.translateLocal([0, distance, 0]);
    }
    cinemaPan(angle_degrees) {
        const local_rotation = quat.create();
        quat.fromEuler(local_rotation, angle_degrees * DEG2RAD, 0, 0);
        quat.multiply(this.rotation, this.rotation, local_rotation);
    }
    cinemaTilt(angle_degrees) {
        const local_rotation = quat.create();
        quat.fromEuler(local_rotation, 0, angle_degrees * DEG2RAD, 0);
        quat.multiply(this.rotation, this.rotation, local_rotation);
    }
}
