const getFileExtension = (url) => {
    let question = url.indexOf("?");
    if (question != -1)
        url = url.substr(0, question);
    let point = url.lastIndexOf(".");
    if (point == -1)
        return "";
    return url.substr(point + 1).toLowerCase();
};
export default getFileExtension;
