import Button from "@src/libs/GLUI/Elements/Button";
import Label  from "@src/libs/GLUI/Elements/Label";

var SpaceSettingsDialog = {

	area: [ -300,-300, 650,400 ],
	selected: null,

	render: function(editor)
	{
		var w = this.area[2];
		var h = this.area[3];
		var x = this.area[0] = gl.canvas.width * 0.5 - w*0.5;
		var y = this.area[1] = gl.canvas.height * 0.5 - h*0.5;

		if ( GUI.Panel( x, y, w,h, "Space Settings", true ) === false )
			return false;

		var space = xyz.space;

		y += 50;

		var flags = [ "render_skybox","allow_walking","hide_nametags","auto_lean_back","no_branding","allow_apps_on_tablets","spatial_audio_for_participants" ];
		var resources = [ "tablets_texture","watermark","session_path" ];

		for (var i in flags)
		{
			var prop = flags[i];
			space.settings[prop] = GUI.Toggle( x + 20, y, w - 60, 24, prop, space.settings[prop] );
			y += 30;
		}

		for (var i in resources)
		{
			var prop = resources[i];
			Label.call(GUI, x + 20, y, 160, 24, prop );
			var r = GUI.TextField( x + 180, y, w - 260, 24, space.settings[prop] || "", false, true );
			if ( GUI.value_changed )
				space.settings[prop] = r;
			if ( Button.call(GUI, x + w - 60, y, 24, 24, [ 2,0 ] ) )
			{
				editor.selectFile((function(file) {
					space.settings[this.prop] = file ? file.localpath : null;
				}).bind({ prop:prop }));
			}
			y += 30;
		}
	}
};

export default SpaceSettingsDialog;
