import { RoomCall } from "@src/controllers/call";
import FeedManager from "@src/controllers/components/feedManager";
import RoomEditor from "@src/controllers/editor";
import { RoomSelectSeat } from "@src/controllers/selectSeat";
import { RoomSelfView } from "@src/controllers/selfview";
import { RoomShowcaseRoom } from "@src/controllers/showcase";
import { RoomTableViewController } from "@src/controllers/tableView";
import { RoomViewObjects } from "@src/controllers/viewObjects";
import Surface from "@src/engine/components/surface";
import { RoomAvatar } from "@src/engine/helpers/avatar";
import { Inventory } from "@src/engine/helpers/inventory";
import { ViewCore } from "@src/engine/render/view";
import { RoomSpace } from "@src/engine/RoomSpace";
import ROOM from "./room";

// re-exports
ROOM.Surface = Surface;
ROOM.Editor = RoomEditor;
ROOM.SelectSeat = RoomSelectSeat;
ROOM.SelfView = RoomSelfView;
ROOM.ShowcaseRoom = RoomShowcaseRoom;
ROOM.TableViewController = RoomTableViewController;
ROOM.ViewObjects = RoomViewObjects;
ROOM.FeedManager = FeedManager;
ROOM.Space = RoomSpace;
ROOM.ViewCore = ViewCore;

/**
 * @deprecated Legacy
 * @type {RoomSpace}
 */
ROOM.Scene = RoomSpace; //LEGACY
ROOM.Call = RoomCall;
ROOM.Avatar = RoomAvatar;
ROOM.Inventory = Inventory;

export default ROOM;

