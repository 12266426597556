import { getFullPath } from "@src/engine/Room/file-utils";
import { openLink } from "@src/engine/Room/openLink";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { vec3 } from "gl-matrix";

import FollowPathToSeatAction from "./InteractiveObject/FollowPathToSeatAction";
import InteractiveObjectActionLink from "./InteractiveObject/InteractiveObjectActionLink";
import InteractiveObjectActionToggleVideo from "./InteractiveObject/InteractiveObjectActionToggleVideo";
import PrefabRenderer from "./prefab";

//Makes scene objects interactive so they trigger events when clicked
function InteractiveObject() {
	this.enabled = true;
	this.clickable = true;
	this.mouse_interaction = false;
	this._state = false;
	this.audio_url = "";
	this.link_url = "";
	this.max_distance = 0;
	this.volume = 0.4;
	this.local_action = false;
	this._last_action_id = 0;
	this.no_outline = false;

	this._actions = [];

	this._node = null; //root node
	this._audio = null;

	//used for sync mostly, triggered by
	LEvent.bind(this, "remote_click", this.remoteClickAction, this);
}

InteractiveObject.icon = [ 6, 3 ];

// to enable the mangle options
InteractiveObject.componentName = "InteractiveObject";

Object.defineProperty(InteractiveObject.prototype, "state", {
	set: function(v) {
		if (v === this._state) {
			return;
		}
		this._state = v;
		if (this._state) {
			LEvent.trigger(this, "state_on");
		} else {
			LEvent.trigger(this, "state_off");
		}
		LEvent.trigger(this, "state_change");
	},
	get: function() {
		return this._state;
	},
	enumerable: true
});

InteractiveObject["@audio_url"] = { type: "asset", extensions: "mp3,wav,ogg" };
InteractiveObject["@volume"] = { type: "number", widget: "slider", min: 0, max: 1 };

InteractiveObject.prototype.onAdded = function(parent) {
	this._node = parent.node;
};

InteractiveObject.prototype.onRemoved = function(parent) {
	this._node = null;
};

InteractiveObject.prototype.getInteractiveNodes = function(container) {
	if (!this.enabled || !this.clickable || !this.space.local_participant) {
		return;
	}

	if (this.max_distance) {
		var pos = this.entity.position;
		var ppos = this.space.local_participant.getProfilePosition();
		if (vec3.distance(pos, ppos) > this.max_distance) {
			return;
		}
	}

	this._node._skip_outline = this.no_outline;
	container.push(this._node);
};

InteractiveObject.prototype.triggerState = function() {
	this.state = !this._state; //this triggers events

	if (this.audio_url) {
		this.playAudio();
	}
};

InteractiveObject.prototype.preloadAudio = function() {
	if (!this._audio) {
		this._audio = new Audio();
	}
	if (this._audio.audio_url !== this.audio_url) {
		this._audio.src = getFullPath(this.audio_url);
		this._audio.audio_url = this.audio_url;
	}
};

InteractiveObject.prototype.playAudio = function() {
	this.preloadAudio();

	this._audio.autoplay = true;
	this._audio.currentTime = 0;
	this._audio.play();
	this._audio.volume = this.volume;
};

InteractiveObject.prototype.onAction = function(action_name, params) {
	if (action_name === "trigger_state") {
		this.triggerState();
	}
};

InteractiveObject.prototype.getActions = function() {
	return [ "trigger_state" ];
};

InteractiveObject.prototype.getEvents = function() {
	return [ "clicked", "state_on", "state_off", "state_change" ];
};

//called from call when click
InteractiveObject.prototype.processClick = function(e) {
	if (!this.enabled) {
		return;
	}

	//do what the clicks should do
	this.executeClick(e);

	//sync with all users
	if (!this.local_action && this.space.network) {
		LEvent.triggerSync(this, "remote_click", { type: "clicked" });
	}
};

//triggers the actions related to this interactive object
InteractiveObject.prototype.executeClick = function(e) {
	LEvent.trigger(this, "clicked", e);
	LEvent.trigger(this._root, "clicked", e);
	LEvent.trigger(this._root.space, "participant_clicked_entity", this._root);

	if (this.link_url) //legacy
	{
		openLink(this.link_url);
	}
	for (var i = 0; i < this._actions.length; ++i)
		this._actions[i].onClick(e, this.entity, this);

	//also trigger state in case it is used
	this.triggerState();
};

//called from LEvent, used for sync
InteractiveObject.prototype.remoteClickAction = function(type, e) {
	if (!this.enabled) {
		return;
	}
	this.executeClick(e);
};

InteractiveObject.prototype.serialize = function(o) {
	o.enabled = this.enabled;
	o.state = this._state;
	o.audio_url = this.audio_url;
	o.volume = this.volume;
	o.clickable = this.clickable;
	o.no_outline = this.no_outline;
	o.mouse_interaction = this.mouse_interaction;
	o.local_action = this.local_action;
	o.link_url = this.link_url;
	o.last_action_id = this._last_action_id;
	o.max_distance = this.max_distance;

	o.actions = [];
	for (var i = 0; i < this._actions.length; ++i) {
		var action = this._actions[i];
		var info = action.serialize();
		info.id = action._id;
		info.action_type = action.constructor.action_name;
		o.actions.push(info);
	}
};

InteractiveObject.prototype.configure = function(o) {
	this.enabled = o.enabled;
	this.state = o.state;
	this.audio_url = o.audio_url;
	this.volume = o.volume == null ? 0.4 : o.volume;
	this.clickable = o.clickable === undefined ? true : o.clickable;
	this.mouse_interaction = o.mouse_interaction === undefined ? false : o.mouse_interaction;
	this.link_url = o.link_url || "";
	this.max_distance = o.max_distance || 0;
	this.local_action = o.local_action || false;
	this._last_action_id = o.last_action_id || 0;
	this.no_outline = o.no_outline || false;

	this._actions.length = 0;
	if (o.actions) {
		for (var i = 0; i < o.actions.length; ++i) {
			var info = o.actions[i];
			var ctor = InteractiveObject.actions[info.action_type];
			if (!ctor) {
				continue;
			}
			var action = new ctor();
			action.configure(info);
			action._id = info.id;
			action._component = this;
			this._actions.push(action);
		}
	}

	this.preloadAudio();
};

InteractiveObject.prototype.addAction = function(action) {
	this._actions.push(action);
	action._id = this._last_action_id++;
	action._component = this;
};

InteractiveObject.prototype.removeAction = function(action) {
	var index = this._actions.indexOf(action);
	if (index !== -1) {
		this._actions.splice(index, 1);
	}
};

InteractiveObject.prototype.update = function(dt) {
	if (!this.enabled) {
		return;
	}

	if (this._audio) {
		this._audio.volume = this.volume;
	}

	var pr = this.entity.getComponent("PrefabRenderer");
	if (!pr) {
		return;
	}
	pr.anim_mode = this._state ? PrefabRenderer.ONCE_ANIM : PrefabRenderer.ONCE_REVERSE_ANIM;
};

InteractiveObject.prototype.onRenderExtraInspector = function(ctx, x, y, w, h, editor) {
	var that = this;
	var start_y = y;

	InteractiveObject.scroll = GUI.List(x, y, w, 100, this._actions, inner, InteractiveObject.scroll || 0);
	y += 104;

	if (Button.call(GUI,x, y, w, 20, "+ Add Action")) {
		var actions = Object.keys(InteractiveObject.actions);
		GUI.ShowContextMenu(actions,
			{
				callback: function(v, item) {
					var ctor_action = InteractiveObject.actions[item];
					if (!ctor_action) {
						return;
					}
					var action = new ctor_action();
					that.addAction(action);
					InteractiveObject.selected_action = action;
				},
				id: "add_action_interactive_object"
			});
	}

	y += 28;

	if (InteractiveObject.selected_action && InteractiveObject.selected_action._component === this) {
		editor.renderDefaultInspector(gl, x, y, w, h - start_y - 30, InteractiveObject.selected_action);
	}

	function inner(x, y, w, h, item, i) {
		if (GUI.Toggle(x - 2, y - 2, 18, 18, null, InteractiveObject.selected_action === item)) {
			InteractiveObject.selected_action = item;
		}

		if (Label.call(GUI,x + 20, y, w - 40, 18, item.constructor.label || "Action", null, null, true) === GLUI.CLICKED) {
			InteractiveObject.selected_action = item;
		}

		if (Button.call(GUI,x + w - 20, y, 18, 18, GLUI.icons.trash)) {
			that.removeAction(item);
			if (InteractiveObject.selected_action === item) {
				InteractiveObject.selected_action = null;
			}
		}
		return 20;
	}
};

InteractiveObject.actions = {};
InteractiveObject.registerAction = function(name, action) {
	action.action_name = name;
	if (!action.prototype.getLocator) {
		action.prototype.getLocator = InteractiveObject.action_locator_func;
	}
	InteractiveObject.actions[name] = action;
};

InteractiveObject.action_locator_func = function(prop) {
	if (!this._component) {
		return null;
	}
	return this._component.getLocator() + "/actions/" + this._id;
};

InteractiveObject.registerAction("link", InteractiveObjectActionLink);
InteractiveObject.registerAction("video_toggle", InteractiveObjectActionToggleVideo);
InteractiveObject.registerAction("follow_path", FollowPathToSeatAction);


export default InteractiveObject;
