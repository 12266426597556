//Used when a component is missing
//************************************************************
function MissingComponent()
{
	this._last_serialization = null;
	this._comp_class = "";
}

MissingComponent.prototype.configure = function(o)
{
	this.uid = o.uid;
	this._last_serialization = o;
}

MissingComponent.prototype.serialize = function()
{
	return this._last_serialization;
}

export default MissingComponent;
