import ROOM from "@src/engine/room";
import { getFullPath } from "@src/engine/Room/file-utils";
import { getExtension } from "@src/engine/Room/getExtension";
import { ROOM_PREFABS } from "@src/engine/Room/ROOM_PREFABS";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { GL } from "@src/libs/litegl";
import { RD } from "@src/libs/rendeer";
import { Material } from "@src/libs/rendeer/Material";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import clamp from "@src/math/clamp";

//In charge of rendering a GLTF inside the scene
function PrefabRenderer()
{
	this._enabled = true;
	this._url = "";
	this._url_org = "";
	this._draco = false;
	this.animation = "";
	this.anim_mode = PrefabRenderer.LOOP_ANIM;
	this.current_time = 0;
	this.lods_num = 1;
	this.cast_shadows = true;
	this.receive_shadows = true;

	this._node = null;
	this._prefab_root = null; //root of prefab node
	this._edited_materials = {};
	this._current_animation = null;
	this._last_animation_time = -1;

	this._force_skinning = false;

}

PrefabRenderer.componentName = "PrefabRenderer";
PrefabRenderer.icon = [ 2,4 ];
PrefabRenderer.type = ROOM_TYPES.PREFAB;
PrefabRenderer.skip_opt_versions = false;
PrefabRenderer.block_prefab_loading = false; //will block loading GLBs

PrefabRenderer.PAUSE_ANIM = 0; //not animated
PrefabRenderer.LOOP_ANIM = 1;
PrefabRenderer.ONCE_ANIM = 2;
PrefabRenderer.LOOP_REVERSE_ANIM = 3;
PrefabRenderer.ONCE_REVERSE_ANIM = 4;


PrefabRenderer.anim_modes_str = [ "PAUSE", "LOOP", "ONCE", "LOOP_REVERSE", "ONCE_REVERSE" ];

PrefabRenderer["@url"] = { type: "asset", extensions: [ "glb","gltf" ] };
PrefabRenderer["@animation"] = { type: "enum", values: function( comp ) {
	comp = comp || this.instance;
	if ( comp && comp._prefab_root && comp._prefab_root.animations )
		return comp._prefab_root.animations.map(a=>a.name);
	else
		return [ "default" ];
} };
PrefabRenderer["@anim_mode"] = { type: "enum", values:[ 0,1,2,3,4,5 ], labels: PrefabRenderer.anim_modes_str };

Object.defineProperty( PrefabRenderer.prototype, "url", {
	get: function() {
		return this._url;
	},
	set: function(v) {
		if (v !== null)
			v = v.trim();
		if (v === this._url)
			return;
		this._url = v;
		if ( this._prefab_root )
		{
			this._prefab_root.parentNode.removeChild( this._prefab_root );
			this._prefab_root = null;
		}
		if (v && !PrefabRenderer.block_prefab_loading)
			this.loadPrefab( this._url );
	},
	enumerable: true
});

Object.defineProperty( PrefabRenderer.prototype, "enabled", {
	set: function(v) {
		if (v === this._enabled)
			return;
		this._enabled = v;
	},
	get: function() { return this._enabled; }
});

Object.defineProperty( PrefabRenderer.prototype, "isAtStart", {
	set: function (v) {
		if (v)
			this.current_time = this._current_animation && this.current_time === this._current_animation.duration;
		else
			this.current_time = 0;
	},
	get: function() { return this.current_time === 0; }
});

Object.defineProperty( PrefabRenderer.prototype, "isAtEnd", {
	set: function(v) {
		if (!v)
			this.current_time = this._current_animation && this.current_time === this._current_animation.duration;
		else
			this.current_time = 0;
	},
	get: function() { return this._current_animation && this.current_time === this._current_animation.duration; }
});

PrefabRenderer.prototype.preRender = function(view)
{
	this._node.visible = this._enabled && this.entity.enabled && !view.hide_prefabs;
}

PrefabRenderer.prototype.serialize = function(o)
{
	o.url = this._url;
	o.draco = this._draco;
	o.animation = this.animation;
	o.anim_mode = this.anim_mode;
	o.inspect_mode = this.inspect_mode;
	o.edited_materials = this.extractMaterials();
	o.lods_num = this.lods_num;
	o.cast_shadows = this.cast_shadows;
	o.receive_shadows = this.receive_shadows;
}

PrefabRenderer.prototype.extractMaterials = function()
{
	if (this.entity._native)
		return this.extractMaterialsNative();

	var edited_materials = {};
	var template = ROOM_PREFABS[ this._url ];
	if ( template && template.materials )
	{
		for (var i in template.materials)
		{
			var mat = template.materials[i];
			if (!mat.name || !ROOM.edited_materials[mat.name])
				continue;
			var matinfo = mat.serialize();
			//HACK: urls could differ from DEV to PRODUCTION so to avoid problems...
			if (matinfo.textures)
				for (var j in matinfo.textures)
				{
					var texinfo = matinfo.textures[j];
					//texinfo.texture = "@";
				}
			//delete matinfo.textures;
			edited_materials[i] = matinfo;
		}
	}

	return edited_materials;
}

PrefabRenderer.prototype.extractMaterialsNative = function()
{
	if (!this.entity._native)
		return null;

	var root = this.entity._native.getRootNode();
	var o = {};

	inner(root);

	function inner(node)
	{
		var mat = node.getMaterial();
		if (mat)
		{
			var nameMat = mat.name;
			var nameMatNC = nameMat;
			var offs = nameMat.indexOf("_Custom(");
			if (offs > 0) {
				nameMatNC = nameMat.substring(0, offs);
			}
			var matCur = ROOM.edited_materials[mat.name] ? ROOM.edited_materials[nameMat] : ROOM.edited_materials[nameMatNC];
			if (matCur) {
				var ms = mat.serialize(matCur, nameMatNC);
				if (ms)
					o[nameMatNC] = ms;
			}
		}
		var children = node.children;
		for (var i = 0; i < children.length;++i)
			inner( children[i] );
	}

	return o;
}

PrefabRenderer.prototype.configure = function(o)
{
	var that = this;

	if (o.edited_materials)
		this._edited_materials = o.edited_materials;
	else if (o.materials) //LEGACY
		this._edited_materials = o.materials;
	if (o.animation)
		this.animation = o.animation;
	if (o.anim_mode != null)
		this.anim_mode = o.anim_mode;
	if (o.inspect_mode != null)
		this.inspect_mode = o.inspect_mode;
	if (o.lods_num)
		this.lods_num = o.lods_num;
	if (o.draco)
		this._draco = o.draco;

	if (o.cast_shadows)
		this.cast_shadows = o.cast_shadows;
	if (o.receive_shadows)
		this.receive_shadows = o.receive_shadows;

	//	if (this.entity && ModuleEngine)
	if (this.entity && globalThis.TmrwModule !== undefined)
	{
	  // Note: native prefab loading is asynchronous
	  //if (this.entity.node.native_handle != 0)
		{
		  this.entity.node.mesh = o.url;
		}
		//  TmrwModule.entityLoadPrefab(this.entity.node.native_handle, o.url);
		this._url = o.url; //store as we need it to save it back in case the editor requires it
		//TODO: notify when loaded!
	}
	else
	{
		//this will trigger the loading
		this.url = o.url;
	}
	this._url_org = this._url;
}

//used in case the prefab info contains materials to overwrite, more like a debug feature
//only called in web engine
PrefabRenderer.prototype.updateMaterials = function( materials )
{
	if (this.entity._native)
		return this.updateMaterialsNative( materials );

	for (var i in materials)
	{
		var mat = StaticMaterialsTable[i];
		if (!mat)
			continue;
		var mat_info = materials[i];
		mat.changed = true;
		for (var j in mat_info)
		{
			if (j !== "textures")
				mat[j] = mat_info[j];
		}
		if ( mat_info.textures )
		{
			if (!mat.textures)
				mat.textures = {};
			//for every tex channel
			for (var j in mat_info.textures)
			{
				var texinfo = mat_info.textures[j];
				if (!mat.textures[j])
					mat.textures[j] = {};
				var oldtexinfo = mat.textures[j];
				for (var k in texinfo)
				{
					if (k !== "texture" )
						oldtexinfo[k] = texinfo[k];
				}
			}
		}

	}
}

PrefabRenderer.prototype.updateMaterialsNative = function( materials )
{
	var root = this.entity._native.getRootNode();
	inner(root);

	function inner(node)
	{
		var native_mat = node.getMaterial();
		if (native_mat)
		{
			if (native_mat.name && materials[native_mat.name])
			{
				var mat_info = materials[mat.name];
				native_mat.configure( mat_info );
			}

		}
		var children = node.children;
		for (var i = 0; i < children.length;++i)
			inner( children[i] );
	}
}

PrefabRenderer.prototype.onAdded = function(parent)
{
	parent.prefab = this;
	this._node = parent.node;
	if (this._prefab_root)
		this._node.addChild( this._prefab_root );
	//autoassign type
	if (parent.type === ROOM_TYPES.NULL )
		parent.type = ROOM_TYPES.PREFAB;
}

PrefabRenderer.prototype.onRemoved = function(parent)
{
	if (parent.prefab === this)
		parent.prefab = null;
	if (this._prefab_root)
		this._node.removeChild( this._prefab_root );
	this._node = null;
}

PrefabRenderer.prototype.update = function(dt,t)
{
	var duration = 0.0;
	if (!this._anim_duration)
	{
		if (!this._prefab_root || !this._prefab_root.animations || !this._prefab_root.animations.length)
		{
			if (this.entity && this.entity._native)
			{
				this._anim_duration = this.entity._native.getAnimDuration(this.animation || "");
				if (!this._anim_duration)
					return;
				duration = this._anim_duration;
			}
			else
				return;
		}

		if (this._prefab_root)
		{
			var anim = null;
			if (this.animation)
			{
				for (var i = 0; i < this._prefab_root.animations.length; ++i)
				{
					if (this._prefab_root.animations[i].name === this.animation)
					{
						anim = this._prefab_root.animations[i];
						break;
					}
				}
			}
			else
				anim = this._prefab_root.animations[0]; //first one as default

			this._current_animation = anim;

			if (!anim || !anim.duration)
				return;
			duration = anim.duration;
		}
	}
	else
		duration = this._anim_duration;

	if (duration <= 0.0)
		return;

	var old_time = this.current_time;

	var delta = dt;

	if (this.anim_mode === PrefabRenderer.LOOP_REVERSE_ANIM || this.anim_mode === PrefabRenderer.ONCE_REVERSE_ANIM )
		delta = -dt;

	if (this.anim_mode !== PrefabRenderer.PAUSE_ANIM)
		this.current_time += delta;

	if (this.anim_mode === PrefabRenderer.LOOP_ANIM || this.anim_mode === PrefabRenderer.LOOP_REVERSE_ANIM)
	{
		this.current_time = this.current_time % duration;
		if ( this.current_time < 0 )
			this.current_time += duration;
	}

	var before_clamp = this.current_time;
	this.current_time = clamp( this.current_time, 0, duration );

	if (before_clamp !== this.current_time && this.anim_mode !== PrefabRenderer.LOOP_ANIM && this.anim_mode !== PrefabRenderer.LOOP_REVERSE_ANIM )
	{
		LEvent.trigger( this, "anim_finished", this );
	}

	if (anim && anim.enabled === false)
		this.current_time = 0;

	var bones_changed = false;

	if (this._last_animation_time !== this.current_time)
	{
		this._last_animation_time = this.current_time;
		if (anim)
		{
			anim.applyAnimation( this._node, this.current_time, RD.LINEAR );
			bones_changed = true;
		}
		if (this.entity && this.entity._native)
		{
			this.entity._native.setAnimTime(this.current_time);
		}
	}

	//search bones and update bone matrices
	if ( bones_changed || this._force_skinning )
		this._node.updateSkinningBones();
}

//do not call directly, assign the url instead
PrefabRenderer.prototype.loadPrefab = function( url, callback )
{
	var that = this;
	var space = this.space;
	var view = xyz.view;
	if ( this._warning )
		this._warning = null;

	if (window.nativeEngine) {
		var entity = this.entity;
		// Workaround for glb reloading crash
		//if (entity._native)
	  // 	entity.releaseNativeEntity();

		var extension = getExtension(url);

		var reader = new XMLHttpRequest();

		var full_url = getFullPath(url);

		reader.filename = url;
		reader.extension = extension;
		reader.entity = this.entity;
		reader.responseType = extension === "gltf" ? "json" : "arraybuffer";

		reader.open("GET", full_url, true);
		reader.send();

		reader.onload = function () {
			if (this.status !== 200) {
				console.error("GLTF not found", url);
				if (callback)
					callback(null);
				return;
			}
			var contents = new Uint8Array(reader.response);
			if (contents) {
				const name = this.filename;
				var entity = this.entity;
				if (!entity._native)
					entity.createNativeEntity(entity.index);
				if (entity._native) {
					entity._native.getRootNode().loadPrefab(contents, name, function (status) {
						console.log("Prefab loaded with status: " + status);
					});
				}
			}
		};

		reader.onerror = function (err) {
			console.log("Failed prefab loading with status: " + err);
		};
		
		return;
	}

	//check if already loaded in cache
	if ( ROOM_PREFABS[ url ] )
	{
		var node = new SceneNode();
		node.configure( ROOM_PREFABS[ url ] );
		onNodeReady( node );
		return;
	}

	var extension = getExtension(url);

	//prefabs could be images!
	if (extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "webp")
	{
		var img_node = new SceneNode();
		img_node.mesh = "plane";

		//in that case we create a plane and apply the texture
		if (!StaticMaterialsTable[url])
		{
			var material = new Material();
			material.textures.albedo = url;
			material.flags.two_sided = true;
			StaticMaterialsTable[url] = material;
		}
		img_node.material = url;
		Viewcore.instance.loadTexture( url );
		onNodeReady( img_node );
		return;
	}

	var full_url = getFullPath( url );

	//no extension? assume GLB
	if (url.substr(0,5) != "blob:")
	{
		if (url[ url.length - 1 ] === "/" )
			full_url += url.split("/").filter(function(v) {return !!v;}).pop() + ".gltf";
		else if (url.indexOf(".") === -1)
			full_url += ".glb";
	}

	//used to inform loader
	if (view)
		view.onStartLoadingResource(url);
	var load_info = { url: url, loaded:0, total: 1 };
	space.loading_info.push( load_info );
	space.onChangeResourcesLoading(url);

	if (full_url.indexOf("_opt") !== -1 && PrefabRenderer.skip_opt_versions )
		full_url = full_url.split("_opt").join("");

	//fetch GLTF
	RD.GLTF.load( full_url, function(node) {
		if (!node) //not found
		{
			that._warning = "GLTF not found";
			//remove from loading list
			remove_from_list();
			return;
		}

		//if high, prepare for afterloading
		if ( node.meta && node.meta.asset && node.meta.asset.hd_textures )
		{
			//delay this to make sure lowres textures are already fetched
			setTimeout( function() { that.loadHDTextures( node.meta.asset.hd_textures ); }, 100 );
		}

		//remove rootpath from texture paths
		RD.GLTF.removeRootPathFromTextures( node.materials, ROOM.root_path );

		//save in cache
		var template = node.serialize();
		template.materials = node.materials;
		ROOM_PREFABS[ url ] = template;

		//callback defined later in this code
		onNodeReady( node );
	}, !extension ? "glb" : null, function(url,loaded, total) { //progress
		//show loading progress
		load_info.loaded = loaded;
		load_info.total = total;
	});

	function onNodeReady(node)
	{
		console.debug("Prefab loaded",url);

		//assign to node
		that.setPrefabNode( node );

		//remove from loading list
		remove_from_list();

		//update materials that are changed from the GLTF
		if ( that._edited_materials )
			that.updateMaterials( that._edited_materials );

		if (callback)
			callback();
	}

	function remove_from_list()
	{
		//remove from pending files
		if (space && space.loading_info)
		{
			var index = space.loading_info.indexOf( load_info );
			if (index !== -1)
				space.loading_info.splice( index, 1 );
		}
		space.onChangeResourcesLoading(url);
		if (view)
			view.onFinishLoadingResource(url);
	}
}

//loads high resolution version of the textures
PrefabRenderer.prototype.loadHDTextures = function( textures )
{
	var num = Object.keys(textures).length;
	console.debug("+ HD Textures to fetch:", num  );
	for (var i in textures)
	{
		var filename = i;
		var path = textures[i];
		var tex = gl.textures[i];
		if (tex)
			GL.Texture.fromURL( path, { texture: tex }, function(tex) {
				//console.debug("+ HDTEX ",tex.name,"Pending:", num--  );
			});
		else
			gl.textures[ filename ] = GL.Texture.fromURL( path );
	}
}

PrefabRenderer.prototype.setPrefabNode = function( node )
{
	if (this._prefab_root === node )
		return;

	if ( this._prefab_root )
	{
		this._prefab_root.parentNode.removeChild( this._prefab_root );
		this._prefab_root = null;
	}

	this._prefab_root = node;
	if (!this._node) //not in an entity?
		return;
	this._node.addChild( node );
}

PrefabRenderer.selected_material = 0;

PrefabRenderer.prototype.onRenderExtraInspector = function(ctx, x,y,w,h, editor )
{
	//get all materials names
	//iterate through every node and get material name
	if(!this.entity._native)
		return;

	y+=20;

	if ( Button.call(GUI, x + w - 30,y,24,24, [ 12,0 ] ) )
		this.updateMaterialsList();

	if(this._materials_list)
	{
		Label.call(GUI, x,y,60,24,"Mats.");
		PrefabRenderer.selected_material = GUI.ComboLine(x+80,y,w-110,24, PrefabRenderer.selected_material, this._materials_list, "materials_in_prefab" );
		if(GUI.value_changed)
		{
			var matname = this._materials_list[ PrefabRenderer.selected_material ];
			editor.selected_material = RD.Materials[ matname ];
		}
	}
	else
		this.updateMaterialsList();
}

PrefabRenderer.prototype.updateMaterialsList = function()
{
	var rootnode = this.entity._native.node;
	var list = {};
	this.findAllMaterials( rootnode, list );
	this._materials_list = Object.keys(list);
}

PrefabRenderer.prototype.findAllMaterials = function( node, result )
{
	result = result || {};
	if(node.material && node.material[0] != ":")
		result[ node.material ] = 1;
	for(var i = 0; i < node.children.length; ++i)
		this.findAllMaterials( node.children[i], result );
	return result;
}


PrefabRenderer.prototype.onRenderInspectorOld = function(ctx, x,y,w,h, editor )
{
	var component = this;
	Label.call(GUI, x,y,100,24,"url");
	component.url = GUI.TextField( x + 100,y,w-140,24, component.url || "", null, true );
	if ( Button.call(GUI, x + w - 30,y,24,24, [ 2,0 ] ) )
	{
		editor.selectFile(function(file) {
			component.url = file ? file.localpath : null;
		},[ "glb","gltf" ]);
	}
	y += 30;

	if (this._prefab_root && this._prefab_root.animations)
	{
		Label.call(GUI, x,y,100,24,"Animation");
		var values = this._prefab_root.animations.map(a=>a.name);
		values.unshift(""); //add empty option
		var selected = values.indexOf( this.animation );
		if (selected === -1)
			selected = 0;
		var r = GUI.ComboLine(x+120,y,w-120,24, selected, values, "prefab_animations" );
		if (r !== selected)
			this.animation = values[r];
		y += 30;
	}

	Label.call(GUI, x,y,100,24,"Anim Mode");
	this.anim_mode = GUI.ComboLine(x+120,y,w-120,24, this.anim_mode, PrefabRenderer.anim_modes_str, "prefab_anim_mode" );
	y += 30;

	Label.call(GUI, x,y,100,24,"Current Time");
	this.current_time = GUI.Number(x+120,y,w-120,24, this.current_time );
	y += 30;

	Label.call(GUI, x, y, 100, 24, "Lods number");
	this.lods_num = GUI.Number(x + 120, y, w - 120, 24, this.lods_num);
	y += 30;

	Label.call(GUI, x, y, 100, 24, "Casts Shadows");
	this.cast_shadows = GUI.Toggle(x+129, y, w - 120, 24, null, this.cast_shadows);
	y += 30;

	Label.call(GUI, x, y, 100, 24, "Receives Shadows");
	this.receive_shadows = GUI.Toggle(x + 129, y, w - 120, 24, null, this.receive_shadows);
	y += 30;

	return y;
}

PrefabRenderer.getExtraProperties = function( result )
{
	result.push([ "isAtStart","boolean" ],[ "isAtEnd","boolean" ]);
}

PrefabRenderer.prototype.getEvents = function()
{
	return [ "clicked","anim_finished" ] ;//,"state_on","state_off","state_change"];
}

export default PrefabRenderer;
