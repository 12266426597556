
//you must pass an object with characters to replace and replace with what {"a":"A","c":"C"}
if (!String.prototype.hasOwnProperty("replaceAll")) {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(String.prototype, "replaceAll", {
		value: function (words) {
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			let str = this;
			for (const i in words) str = str.split(i).join(words[i]);
			return str;
		},
		enumerable: false,
	});
}

