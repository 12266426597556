import { UID_PREFIX } from "@src/engine/rendeer/UID_PREFIX";
import { GL } from "@src/libs/litegl";

let _last_uid = 1;
export function generateUID(prefix="", suffix="") {
	let hash = "";

	if (typeof (window) !== "undefined")
		hash = (window.navigator.userAgent.hashCode() % 0x1000000).toString(16);
	else
		hash = process.pid;

	let str = UID_PREFIX + prefix + hash + "-"; //user agent
	str += (GL.getTime() | 0 % 0x1000000).toString(16) + "-"; //date
	str += Math.floor((1 + Math.random()) * 0x1000000).toString(16) + "-"; //rand
	str += (_last_uid++).toString(16); //sequence
	str += suffix;
	return str;
}