import { vec3 }       from "gl-matrix";

// Tool to edit the points from the editor
function WalkableAreaTool()
{
	this.component = null;
	this.selected = null;
	this.dragging = null;

	this._last_coll_point = vec3.create();
}

WalkableAreaTool.instance = null;

WalkableAreaTool.prototype.onEnable = function()
{

}

WalkableAreaTool.prototype.extrudeEdge = function( selection )
{
	var comp = this.component;

	if (!selection || selection.point_index === -1)
		return;

	//vertices
	var area = comp.areas[ selection.area_index ];
	var index = selection.point_index;
	if ( !this.computeWinding( area.points ) )
		index -= 1;
	if ( index < 0 )
		index = area.points.length - 1;

	var p1 = area.points[ index ];
	var p2 = area.points[ index < area.points.length - 1 ? index + 1 : 0 ];

	//create new area
	var area_index = comp.addArea();
	var new_area = comp.areas[ area_index ];
	new_area.points = [ p1.concat(), p2.concat(), p2.concat(), p1.concat() ];
	new_area.height = area.height;

	selection.area_index = area_index;
	selection.point_index = 0;
	selection.is_edge = true;

	comp._must_update = true;
	comp.constructor.selected_index = area_index;
}

WalkableAreaTool.prototype.computeWinding = function( points )
{
	var sum = 0;
	for (var i = 0, l = points.length; i < l; ++i)
	{
		var p1 = points[i];
		var p2 = points[i === l - 1 ? 0 : i + 1];
		sum += (p2[0] - p1[0]) * (p2[2] - p1[2]);
	}

	return sum >= 0;
}

WalkableAreaTool.prototype.onMouse = function(e,ray,editor)
{
	//console.debug(e,ray);
	//test ray with floor plane

	var comp = this.component;
	var mouse = [ e.canvasx, e.canvasy ];
	var view = editor.view;
	var current_camera = view.getCurrentCamera();
	var selected_area = comp.areas[ comp.constructor.selected_index ];
	if(!selected_area)
		selected_area = comp.areas[0];

	var points = selected_area.points;

	if ( !selected_area || !ray.testPlane([ 0,selected_area.height,0 ],[ 0,1,0 ]) )
		return;

	if (e.type === "mousedown" && e.canvasx > editor.sidewidth)
	{
		var result = this.component.getNearestPoint2D( mouse,20,current_camera, true, selected_area );
		this.selected = result;
		this.dragging = result;
		editor.saveUndo();

		this.was_shift_on_click = e.shiftKey;

		if (this.selected)
		{
			vec3.copy(this._last_coll_point, ray.collision_point);

			if ( e.shiftKey && this.selected.is_edge )
			{
				this.extrudeEdge( this.selected );
				this.was_shift_on_click = false;
			}

			return true;
		}
	}
	else if (e.type === "mousemove")
	{
		if ( e.ctrlKey && e.dragging)
		{
			selected_area.height += e.deltay * -0.02;
			comp._must_update = true;
			return true;
		}
		else if ( e.shiftKey && e.dragging && this.was_shift_on_click  )
		{
			var delta = vec3.sub(vec3.create(), ray.collision_point, this._last_coll_point);
			for(var i = 0; i < points.length; ++i)
				vec3.add( points[i], points[i], delta);
			comp._must_update = true;
			vec3.copy(this._last_coll_point, ray.collision_point);
			return true;
		}
		else if (this.dragging && this.selected && this.selected.point_index !== -1)
		{
			//quantize
			var pc = ray.collision_point;
			pc[0] = Math.floor(pc[0] * 20) / 20;
			pc[2] = Math.floor(pc[2] * 20) / 20;

			var index = this.selected.point_index;
			var p = points[ index ];
			if (p)
			{
				if ( this.selected.is_edge ) //middle
				{
					var delta = vec3.sub(vec3.create(), ray.collision_point, this._last_coll_point);
					vec3.add( p, p, delta );
					p = points[index === points.length - 1 ? 0 : index + 1 ];
					vec3.add( p, p, delta );
					vec3.copy(this._last_coll_point, ray.collision_point);
				}
				else
					vec3.copy( p, ray.collision_point );
				comp._must_update = true;
			}
			return true;
		}
	}
	else if (e.type === "mouseup")
	{
		this.dragging = null;

		if (!this.selected && e.click_time < 250)
		{
			var index = this.component.getAreaIndexFromRay( e.ray, comp.constructor.selected_index );
			if (index !== -1)
			{
				this.selected = { area_index: index, point_index: -1 };
				comp.constructor.selected_index = index;
				comp._must_update = true;
			}
		}
	}

	if ( selected_area )
		selected_area.updateBounding();
}

WalkableAreaTool.prototype.onRender = function( editor, view )
{
	if (!this.component || !this.selected )
		return;

	var comp = this.component;
	var current_camera = view.getCurrentCamera();
	var size = -20;
	view.renderer.color = [ 2,2,2,1 ];
	gl.disable( gl.DEPTH_TEST );

	var point_index = -1;
	var selected_area = null;
	var is_edge = false;

	if ( this.selected )
	{
		selected_area = comp.areas[ this.selected.area_index ];
		point_index = this.selected.point_index;
		is_edge = this.selected.is_edge;
	}

	//render selected point
	if (selected_area && point_index !== -1)
	{
		var point = vec3.clone( selected_area.points[ point_index ] );
		var p2 = selected_area.points[point_index === selected_area.points.length - 1 ? 0 : point_index + 1 ];
		if ( is_edge )
			vec3.lerp(point,point,p2,0.5);
		view.renderer.renderPoints( point, null, current_camera, 1, null, size || 0.2 );
		gl.enable( gl.DEPTH_TEST );
	}

	//render UI
}

WalkableAreaTool.prototype.onRenderUI = function( editor, view )
{
	var ctx = gl;
	//ctx.fillText()
}


WalkableAreaTool.prototype.onKeyDown = function(e)
{
	if (!this.component || !this.selected )
		return;

	var comp = this.component;

	if (e.key === "Insert")
	{
		if ( this.selected )
		{
			this.selected = comp.insertPoint( this.selected.point_index );
		}
	}
	else if (e.key === "Delete")
	{
		if (this.selected)
		{
			comp.deletePoint( this.selected.point_index );
			this.selected = null;
		}
	}
	else
		return false;
	return true;
}

export default WalkableAreaTool;
