/**
 * Note that constraints must be convergent, that is, given that constraint is applies iteratively to a state over and over again - it will converge on a stable solution
 */
export class CameraConstraint {
    constructor() {
        /**
         * Internal time, in seconds
         * @type {number}
         */
        this.time = 0;
        /**
         *
         * @type {CameraBrain|null}
         * @private
         */
        this.brain = null;
    }
    /**
     * Advance simulation of internal constraint parameters
     * @param {number} timeDelta in seconds
     * @returns {void}
     */
    tick(timeDelta) {
        // override in subclasses as necessary
        this.time += timeDelta;
    }
    /**
     *
     * @param {CameraBrain} brain
     */
    link(brain) {
        if (this.brain === brain) {
            // already linked to this one
            return;
        }
        else if (this.brain !== null) {
            // linked to another brain
            throw new Error("Constraint is linked to another brain");
        }
        this.brain = brain;
    }
    /**
     *
     * @param {CameraBrain} _brain
     */
    unlink() {
        const brain = this.brain;
        if (brain === null) {
            // not linked
            return;
        }
        this.brain = null;
    }
    copy(other) {
        throw new Error("Not Implemented");
    }
    clone() {
        const r = new this.constructor();
        r.copy(this);
        return r;
    }
}
