import {LEvent} from '@src/libs/LEvent';

// simple http request
const HttpRequest = function(
    url,
    params,
    callback,
    error,
    options
) {
    var async = true;
    options = options || {};
    if (options.async !== undefined) async = options.async;

    if (params) {
        var params_str = null;
        var params_arr = [];
        for (var i in params) params_arr.push(i + "=" + params[i]);
        params_str = params_arr.join("&");
        url = url + "?" + params_str;
    }

    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, async);
    xhr.responseType = options.responseType || "text";
    xhr.onload = function (e) {
        var response = this.response;
        var type = this.getResponseHeader("Content-Type");
        if (this.status != 200) {
            LEvent.trigger(xhr, "fail", this.status);
            if (error) error(this.status);
            return;
        }

        LEvent.trigger(xhr, "done", this.response);
        if (callback) callback(this.response);
        return;
    };

    xhr.onerror = function (err) {
        LEvent.trigger(xhr, "fail", err);
    };

    if (options) {
        for (var i in options) xhr[i] = options[i];
        if (options.binary) xhr.responseType = "arraybuffer";
    }

    xhr.send();

    return xhr;
};

export default HttpRequest;
