/**
 *
 * @param {string} url
 * @returns {string}
 */
export function getExtension(url) {
	var index = url.indexOf("?");
	if (index !== -1)
		url = url.substr(0, index);

	index = url.lastIndexOf(".");
	if (index === -1)
		return "";
	return url.substr(index + 1).toLowerCase();
}