import { getFolder } from "@src/engine/Room/file-utils";
import { getExtension } from "@src/engine/Room/getExtension";
import { openLink } from "@src/engine/Room/openLink";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { XYZRegisterModule } from "@src/xyz/XYZRegisterModule";

//access the backend to store data (used by the editor)
function Backend(xyz, settings)
{
	Backend.instance = this;

	this.local_path = "";
	this.root_path = (settings.root_path || xyz._root_path) + "/";

	this.username = "";
	this.remember_me = true;
	this.current_folder = ""; //contains username
	this.home_folder = "";
	this.files = [];
	this.token = "";	

	this.scroll_y = 0;
	this.selected_file = null;

	this.xyz = xyz;
	this.xyz.backend = this;
	this.server_url = settings.server_url;

	this.connected = false;

	if (this.server_url)
		this.checkConnection();

	var that = this;

	this.onStatusError = function(err)
	{
		this.showNotification(err);
		//alert(err);
	}

	this.onError = function(err) {
		if ( that.connected )

			if ( ![ "localhost", "127.0.0.1", "0.0.0.0" ].includes( location.hostname ) )
				console.error("Error from Backend:",err);
	};
}

Backend.prototype.showNotification = function(text)
{
	if (!text)
		return;
	if (window.RoomEditor && window.RoomEditor.instance)
		RoomEditor.instance.showNotification(text);
}

Backend.prototype.login = function(username, password, on_complete)
{
	if (!this.server_url)
		throw ("no server url");
	var that = this;

	var formData = new FormData();
	formData.append("action", "login");
	formData.append("username", username);
	formData.append("password", password);

	var params = {
		method: "POST",
		body: formData
	};
	fetch( this.server_url, params )
		.then(function(response) {
		 if (!response.ok)
				throw new Error("HTTP " + response.status + ":" + response.statusText );
			return response.json();
		}).then(function(data) {
			if (data.status == -1)
			{
				console.debug(data.msg);
				//if(that.onError)
				//	that.onError(data.msg);
				return;
			}
			that.username = username;
			that.token = data.token;			
			that.home_folder = "users/" + username;
			that.current_folder = that.home_folder;
			console.debug("connected as " + username);
			if (that.remember_me)
				localStorage.setItem("xyz_backend_user", JSON.stringify({ username: username, token: data.token }) );
			that.refresh();
			if (on_complete)
				on_complete( that, data );
		});
}

Backend.prototype.genericAction = function( params, on_complete )
{
	if (!this.server_url)
		throw ("no server url");
	params += "&token=" + this.token;		
	var that = this;
	fetch( this.server_url + "?" + params)
		.then(function(response) {
		 if (!response.ok)
			{
			 if (that.onError)
				 that.onError("Error " + response.status );
				throw new Error("HTTP " + response.status + ":" + response.statusText );
			}
			return response.json();
		}).then(function(data) {
			if(that.verbose)
				console.log(data);
			if(data.debug)
				console.debug(data.debug);
			if( data.status == -2 )
			{
				that.current_folder = "";
				that.username = null;
				that.token = null;
			}

			if(data.status == -1)
			{
				console.debug( data.msg );
				if (that.onStatusError)
					that.onStatusError( data.msg );
				if (that.onError)
					that.onError( data.msg );
				return;
			}
			that.showNotification( data.msg );
			if (on_complete)
				on_complete( data );
		}).catch(function(err) {
			 if (that.onError)
				 that.onError(err);
			throw new Error(err);
		});
}

Backend.prototype.checkConnection = function( on_complete )
{
	var that = this;
	var info = localStorage.getItem("xyz_backend_user");
	if(info)
	{
		var userdata = JSON.parse(info);
		if(userdata.token)
			this.token = userdata.token;
	}
	this.genericAction( "action=check", function(data){
		if (data.status == -1 || !data.username)
		{
			console.debug("not connected to backend");
			that.username = null;
			that.connected = false;
			that.token = null;			
			return;
		}
		that.username = data.username;
		//that.token = data.token; //no token when checking, you sent the token
		console.debug("connected as " + that.username);
		that.home_folder = "users/" + data.username;
		that.current_folder = that.home_folder;
		that.connected = true;
		that.refresh();
		if (on_complete)
			on_complete( that, data );
	});
}

Backend.prototype.logout = function(on_complete)
{
	var that = this;
	this.genericAction( "action=logout", function(data) {
		that.username = null;
		that.token = null;		
		console.debug("logged out");
		if (on_complete)
			on_complete( that, data );
	});
}


Backend.prototype.createFolder = function( name, on_complete )
{
	this.genericAction( "action=mkdir&folder=" + name, on_complete );
}

Backend.prototype.deleteFile = function( name, on_complete )
{
	this.genericAction( "action=rm&filename=" + name, on_complete );
}

Backend.prototype.processFile = function( name, on_complete )
{
	this.genericAction( "action=process&filename=" + name, on_complete );
}

Backend.prototype.moveFile = function( name, target, on_complete )
{
	this.genericAction( "action=move&filename=" + name + "&target_filename=" + target, on_complete );
}

Backend.prototype.uploadFile = function( filename, data, on_complete)
{
	if (!this.server_url)
		throw ("no server url");
	var that = this;

	if(data.constructor === Array)
	{
		console.warn("data saves is Array, should be String or typed array, converting to Uint8Array");
		data = new Uint8Array(data);
	}

	//no need AFAIK
	if(data.constructor === Uint8Array)
		data = new Blob([data]);

	var params = {
		method: "POST",
		body: data
	};

	this.saving_file = filename;

	fetch( this.server_url + "?action=save&filename="+filename+"&token="+this.token, params  )
		.then(function(response) {
		 if (!response.ok)
				throw new Error("HTTP " + response.status + ":" + response.statusText );
			return response.json();
		}).then(function(data) {
			that.saving_file = null;
			if(that.verbose)
				console.log(data);
			if(data.status != 1)
			{
				console.debug("error saving file");
				if( data.status == -2 )
				{
					that.current_folder = "";
					that.username = null;
					that.token = null;
				}
	
				if( data.status == -1 )
				{
					//that.current_folder = "";
				}
				if(that.onError)
					that.onError(data.msg);
				if(on_complete)
					on_complete(null);
				return;
			}
			that.refresh();
			if(on_complete)
				on_complete( that, data );				
		}).catch(function(err){
			console.log("there was error in fetch");
			console.log(err);
		});
}

Backend.prototype.refresh = function()
{
	var that = this;
	that.files = [];
	this.listFiles( this.current_folder, function(files) {
		console.debug("files refreshed");
		that.files = files;
	});
}

Backend.prototype.listFiles = function(folder,on_complete)
{
	folder = folder || "";
	var that = this;
	this.genericAction( "action=list&folder=" + folder, function(data) {
		if (data.status == -1)
		{
			console.debug(data.msg);
			if (that.onError)
				that.onError(data.msg);
			if (on_complete)
				on_complete(null);
			return;
		}
		var files = data.data;
		if (!files)
		{
			if (on_complete)
				on_complete( files );
			return;
		}

		for (var i = 0; i < files.length; ++i)
		{
			var file = files[i];
			file.localpath = that.local_path + ( file.fullpath[0] == "/" ? "" : "/" ) + file.fullpath;
			file.relpath = file.fullpath;
			if (file.relpath.indexOf("users/data/") != -1)
				file.relpath = file.relpath.substr(11);
			file.fullpath = that.root_path + file.fullpath;
			file.extension = getExtension( file.name );
		}

		//folders first
		files = files.sort( function(a,b) {
			if ( a.is_dir == b.is_dir ) return a.name.localeCompare(b.name);
			if ( a.is_dir ) return -1;
			return a.name.localeCompare(b.name) + 100;
		});

		if (on_complete)
			on_complete( files );
	});
}

Backend.prototype.downloadFile = function( relpath )
{
	window.open( this.root_path + relpath );
}

Backend.prototype.changeFolder = function( folder )
{
	this.selected_file = null;
	this.current_folder = folder;
	this.scroll_y = 0;
	this.refresh();
}

//requirest GLUI lib
Backend.prototype.showFiles = function( x,y,w,h, on_click, valid_extensions, folders_mode )
{
	var sy = y + 20;
	GUI.fontFamily = "Monospace";

	var ctx = gl;
	ctx.fillStyle = "black";
	ctx.fillRect(x,y,w,h);

	GUI.DrawIcon( x + 30, sy + 10, 0.5, [ 2,0 ], false, [ 0.8,0.8,1,1 ] );
	Label.call(GUI,x + 50, sy, w - 40, 30, this.current_folder + "/" );

	var icons_x = x + w - 40;

	GUI.next_tooltip = "Refresh";
	if ( Button.call(GUI,  icons_x, sy - 10, 32,32, [ 1,5 ], true, [ 0.3,0.3,0.3,1 ] ) )
		this.refresh();
	icons_x -= 40;

	GUI.next_tooltip = "Home";
	if ( Button.call(GUI,  icons_x, sy - 10, 32,32, [ 9,0 ], true, [ 0.3,0.3,0.3,1 ] ) )
		this.changeFolder( this.home_folder );
	icons_x -= 40;

	GUI.next_tooltip = "Prev. Folder";
	if ( Button.call(GUI,  icons_x, sy - 10, 32,32, [ 2,3 ], true, [ 0.3,0.3,0.3,1 ] ) )
	{
		var t = this.current_folder.split("/");
		if (t.length > 0 )
		{
			t.pop();
			this.changeFolder( t.join("/") );
			return;
		}
	}
	icons_x -= 40;

	GUI.next_tooltip = "Create Folder";
	if ( Button.call(GUI,  icons_x, sy - 10, 32,32, [ 2,0 ], true ) )
	{
		var name = prompt("folder name");
		if (name)
		{
			this.createFolder( this.current_folder + "/" + name, this.refresh.bind(this) );
			//enter?
		}
	}
	icons_x -= 40;

	//show special actions to file
	if (this.selected_file)
	{
		GUI.next_tooltip = "Delete file";
		if ( Button.call(GUI,  icons_x, sy - 10, 32,32, [ 10,0 ], true, null, [ 0.8,0.6,0.6,1 ] ) )
			if ( confirm("Are you sure you want to delete the file?" ) )
				this.deleteFile( this.selected_file.relpath, this.refresh.bind(this) );
		icons_x -= 40;

		GUI.next_tooltip = this.selected_file.is_dir ? "Open Folder" : "Download file";
		if ( Button.call(GUI,  icons_x, sy - 10, 32,32, this.selected_file.is_dir ? [ 2,1 ] : [ 6,0 ], true ) )
		{
			if (this.selected_file.is_dir)
				this.changeFolder( this.current_folder + "/" + this.selected_file.name );
			else
				this.downloadFile( this.selected_file.relpath );
		}
		icons_x -= 40;

		if ( this.selected_file && (this.selected_file.extension == "js" || this.selected_file.extension == "txt" || this.selected_file.extension == "json") )
		{
			GUI.next_tooltip = "Edit File";
			if ( Button.call(GUI,  icons_x, sy - 10, 32,32, [ 11,6 ], true, [ 0.3,0.5,0.3,1 ] ) )
			{
				this.openInCodeEditor( this.selected_file );
			}
			icons_x -= 40;
		}

		if ( this.selected_file && (this.selected_file.extension == "glb") )
		{
			GUI.next_tooltip = "Process File";
			if ( Button.call(GUI,  icons_x, sy - 10, 32,32, [ 15,8 ], true, [ 0.3,0.5,0.3,1 ] ) )
				this.processFile( this.selected_file.relpath, this.refresh.bind(this) );
			icons_x -= 40;
		}
	}

	sy += 40;

	ctx.save();
	ctx.beginPath();
	ctx.rect(x,y+50,w-40,h-50);
	ctx.clip();

	this.scroll_y -= GUI.wheel_delta * 10;
	GUI.wheel_delta = 0;
	this.scroll_y = Math.max(0,this.scroll_y);

	var min_y = sy;
	sy -= this.scroll_y;
	var max_y = y + h;
	var block_drag = false;

	//for each file
	if (this.files)
		for (var i = 0; i < this.files.length; ++i)
		{
			var file = this.files[i];
			if ( valid_extensions && !file.is_dir)
			{
				var ext = getExtension(file.name);
				if ( valid_extensions.indexOf( ext ) == -1 )
					continue;
			}

			if ( folders_mode && !file.is_dir)
				continue;

			if ( sy < (min_y - 30) )
			{
				sy += 30;
				continue;
			}

			if ( sy > max_y )
				break;

			var icon = null;
			if (file.is_dir)
				icon = [ 2,0 ];
			else
				icon = [ 5,2 ];
			var file_width = w - 160;
			var mouse_info = GUI.HoverArea( x+80, sy, file_width, 28, { file: file, callback: this.onDragFile } );
			var hover = mouse_info == GLUI.HOVER;
			//GUI.Toggle(x+10,sy,18,18,false);

			if ( this.selected_file == file )
			{
				ctx.fillColor = [ 0.2,0.3,0.4,0.5 ];
				ctx.fillRect( x+80, sy, file_width, 28 );
				if ( Button.call(GUI,  x+80 + file_width, sy, 28,28, [ 15,1 ] ) )
					GUI.ShowContextMenu( file.is_dir ? Backend.context_folder_options : Backend.context_file_options, this.onFileContextOption.bind(this), { title: file.name } );
			}
			if (icon)
				GUI.DrawIcon( x + 60.5, sy + 10.5, 0.5, icon, false, [ 0.8,0.8,1,1 ] );
			var color = [ 0.8,0.8,0.8,1 ];
			if ( file.is_dir )
				color = [ 0.8,0.8,1,1 ];
			if ( hover )
				color = [ 1,1,1,1 ];
			if ( this.saving_file == file.relpath )
				color = [ 0.8,1,0.8,1 ];

			Label.call(GUI,x+80, sy, file_width, 28, file.name + ((file.is_dir) ? "/" : ""), color);
			if ( !file.is_dir )
			{
				var size_str = file.size > (1024*1024) ? Math.floor(file.size / (1024*1024)) + "MBs" : Math.floor(file.size / 1024) + "KBs";
				Label.call(GUI,x+80+file_width-100, sy, 100, 28, size_str, [ 0.8,0.6,0.8, file.size > (1024*1024) ? 0.5 : 0.4 ], null, null, "right");
			}

			if ( mouse_info === GLUI.CLICKED )
			{
				var prev_file = this.selected_file;
				this.selected_file = file;

				if ( folders_mode )
				{
					if ( prev_file == file )
					{
						this.changeFolder( this.current_folder + "/" + file.name );
						on_click( this.current_folder );
					}
					else
					{
						this.selected_file = file;
						on_click( this.current_folder + "/" + file.name );
					}
				}
				else if ( file.is_dir ) //folder
				{
					if ( prev_file == file )
						this.changeFolder( this.current_folder + "/" + file.name );
				}
				else if (on_click)
				{
					on_click(file, this.current_folder);
				}
			}

			if ( this.saving_file == file.relpath )
				GUI.DrawIcon( x + w - 120, sy + 10.5, 0.5, [ 6,0 ], false, [ 0.8,1,0.8,Math.sin(getTime()*0.002) * 0.5 + 0.5 ] );

			sy += 30;
		}

	ctx.restore();

	var max_scroll_height = this.files.length * 30 + 10;

	this.scroll_y = GUI.Scrollbar(x + w - 40,y + 45,30,h-50,this.scroll_y,max_scroll_height);

	var dragged_item = GUI.DropArea(x,y,w,h, this.onDropFile.bind(this) );
	GUI.fontFamily = this.xyz.options.fontFamily;
}

Backend.context_folder_options = [
	"Copy Link",
	null,
	"Delete"
];

Backend.context_file_options = [
	"Process",
	"Rename",
	"Open in Tab",
	"Copy Link",
	null,
	"Delete"
];

Backend.prototype.onFileContextOption = function( e, item )
{
	if (!this.selected_file)
		return;

	console.debug(e,item);
	var fullurl = this.selected_file.fullpath;

	if (item == "Delete")
	{
		this.deleteFile( this.selected_file.relpath, this.refresh.bind(this) );
	}
	else if (item == "Copy Link")
	{
		var index = location.pathname.lastIndexOf("/");
		var folder = location.pathname.substr(0,index);
		navigator.clipboard.writeText( location.origin + "/" + folder + "/" + fullurl );
	}
	else if (item == "Open in Tab")
	{
		openLink( fullurl );
	}
	else if (item == "Process")
	{
		this.processFile( this.selected_file.relpath, this.refresh.bind(this) );
	}
	else if (item == "Rename")
	{
		var r = prompt("New filename", this.selected_file.name );
		if (!r)
			return;
		var folder = getFolder( this.selected_file.relpath );
		var target = folder + "/" + r;
		this.moveFile( this.selected_file.relpath, target, this.refresh.bind(this) );
	}
}

Backend.prototype.onDragFile = function(e,item)
{
	e.dataTransfer.setData("type","Resource");
	e.dataTransfer.setData("extension",item.file.extension);
	e.dataTransfer.setData("uid",item.file.relpath);
}

Backend.prototype.openInCodeEditor = function(file)
{
	this.code_editor = window.open("../ide/?open=" + file.relpath);
}

Backend.prototype.onDropFile = function(e)
{
	console.debug("files",e);

	if (!this.username)
		return;

	var files = e.dataTransfer.files;
	if (files.length)
	{
		console.debug("uploading file");
		this.uploadFile( this.current_folder + "/" + files[0].name, files[0], function() {
		});
		this.refresh();
	}
}

//XYZRegisterModule( Backend, "Backend" );
window.Backend = Backend;

export default Backend;


