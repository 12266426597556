import { RoomCall } from "@src/controllers/call";
import { SurfaceApp } from "@src/engine/apps/SurfaceApp";
import { SurfaceOutputModeType } from "@src/engine/apps/SurfaceOutputModeType";
import ROOM from "@src/engine/room";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import XYZLauncher from "@src/XYZLauncher";
import SurfaceAppsContainer from "./SurfaceAppsContainer";
class LockScreenApp extends SurfaceApp {
    /**
     *
     * @param {Surface} surface
     */
    constructor(surface) {
        var _a, _b, _c, _d;
        super();
        this.xyz = XYZLauncher.instance;
        this.images = [];
        this.cursor_style = "";
        this.alpha = 1;
        this.next_app = "OS";
        this.surface = surface;
        this.resolution_factor = 2;
        this.images["logo"] = ROOM.getImage("textures/apps/lockscreen/logo.png");
        this.images["power"] = ROOM.getImage("textures/apps/lockscreen/power.png");
        this.next_app = (_d = (_c = (_b = (_a = this.surface.apps_settings) === null || _a === void 0 ? void 0 : _a.apps) === null || _b === void 0 ? void 0 : _b.LockScreen) === null || _c === void 0 ? void 0 : _c.next_app) !== null && _d !== void 0 ? _d : this.next_app;
        window.lockscreen = this;
    }
    JSONToState(json) {
        const data = JSON.parse(json);
        this.next_app = data.next_app || "OS";
    }
    stateToJSON() {
        return JSON.stringify({
            next_app: this.next_app
        });
    }
    onMouse(e, active) {
        //this.cursor_style = "pointer";
        if (active && e.eventType == "mousedown") {
            const root = this.surface.root;
            RoomCall.instance.triggerAction({
                action: "surface_update",
                surface_entity: root.uid,
                subaction: "load_app",
                app_name: this.next_app
            });
        }
    }
    render(ctx, tex, GUI, mouse) {
        const w = tex.width;
        const h = tex.height;
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, w, h);
        //logo bg first
        let logo = this.images["logo"];
        const max_logo_size = h / 1.5;
        if (this.surface && this.surface.background_image) {
            logo = this.images[this.surface.background_image];
            if (!logo)
                this.images[this.surface.background_image] = ROOM.getImage(this.surface.background_image);
        }
        if (logo && logo.width) {
            const logo_w = max_logo_size;
            const logo_h = max_logo_size * (logo.height / logo.width);
            ctx.drawImage(logo, w / 2 - logo_w / 2, h / 2 - logo_h / 2, logo_w, logo_h);
        }
        //date
        const now = new Date();
        const time = now.toLocaleTimeString(navigator.language, { hour: "2-digit", minute: "2-digit" });
        const date = now.toLocaleDateString("en-US", { weekday: "long", month: "long", day: "numeric" });
        ctx.fillStyle = "#010101";
        ctx.textAlign = "center";
        ctx.font = `300 ${40 * this.resolution_factor}px ${this.xyz.options.fontFamily}`;
        ctx.fillText(time, w / 2, 60 * this.resolution_factor);
        ctx.font = `300 ${16 * this.resolution_factor}px ${this.xyz.options.fontFamily}`;
        ctx.fillText(date, w / 2, 90 * this.resolution_factor);
        //power
        const y = h - 80;
        const picto_w = 62;
        const picto_h = 68;
        ctx.font = `${16 * this.resolution_factor}px ${this.xyz.options.fontFamily}`;
        ctx.fillText("Tap on screen to unlock", w / 2 + 45, y);
        ctx.drawImage(this.images["power"], w / 2 - picto_w / 2 - 200, y - picto_h * .75, picto_w, picto_h);
        if (this.alpha < 1) {
            ctx.globalAlpha = 1 - this.alpha;
            ctx.fillStyle = "black";
            ctx.fillRect(0, 0, w, h);
            ctx.globalAlpha = 1;
        }
        this.postRender();
    }
    postRender() {
        //ROOM.cursor_style = this.cursor_style;
    }
    static onRenderInspector(GUI, x, y, w, h, surface) {
        var _a, _b, _c;
        const app = this;
        const next_app = (_c = (_b = (_a = surface.apps_settings) === null || _a === void 0 ? void 0 : _a.apps[app.app_name]) === null || _b === void 0 ? void 0 : _b.next_app) !== null && _c !== void 0 ? _c : null;
        Label.call(GUI, x + 20, y, 160, 20, "Next app");
        const r = GUI.TextField(x + 180, y, w - 235, 20, next_app, false, true, false);
        if (GUI.value_changed) {
            surface.updateAppsSettings("LockScreen", "next_app", r);
        }
        if (Button.call(GUI, x + w - 50, y, 20, 20, [2, 0])) {
            const apps = SurfaceAppsContainer.getAppNames();
            apps.unshift("");
            GUI.ShowContextMenu(apps, (i, v) => {
                surface.updateAppsSettings("LockScreen", "next_app", v);
            }, "surface_apps");
        }
        return y;
    }
}
LockScreenApp.app_name = "LockScreen";
LockScreenApp.output_mode = SurfaceOutputModeType.Canvas;
export default LockScreenApp;
