function EditableText(options={})
{
	this.text = options.text || "";
	this.placeholder = options.placeholder || "";
	this.focused = options.focused || false;
	this.interactive = options.interactive !== false;
	this.font_size = options.font_size || 18;
	this.min_font_size = 10;
	this.max_font_size = 18;
	this.parent = options.parent || null;
	this.caret_width = options.caret_width || 1;
	this.scale = 1;
	this.position = [ 0, 0 ];
	this.width = options.width || null;
	this.align = options.align || "left";
	this.vertical_align = options.vertical_align || "top";
	this.max_length = options.max_length || null;
	this.onUpdate = options.onUpdate || null;
	this.colors = [];
	this.colors["text"] = options.text_color || "#000000";
	this.colors["placeholder"] = options.placeholder_color || "rgba(0, 0, 0, .2)";

	if (options.vertical_align && !this.parent)
	{
		console.warn("[EditableText] vertical_align needs a parent");
		this.vertical_align = "top";
	}

	this.allow_line_breaks = options.allow_line_breaks != null ? options.allow_line_breaks : true;

	LEvent.bind(xyz.space, "keydown", this.processKeyDown.bind(this));
}

EditableText.prototype.processKeyDown = function (e, ev) {
	if (this.focused === false || !this.interactive) {
		return;
	}

	var previous_text = this.text;

	if (ev.key === "Backspace" && this.text.length > 0)
	{
		this.text = this.text.slice(0, -1);
	}
	else if (ev.key === "Enter" && this.allow_line_breaks)
	{
		this.text += "\n";
	}
	else if (ev.key.length === 1)
	{
		if (this.max_length === null)
		{
			this.text += ev.key;
		}
		else if (this.text.length < this.max_length)
		{
			this.text += ev.key;
		}
	}

	if ( this.onUpdate && this.text != previous_text )
	{
		this.onUpdate();
	}
}

EditableText.prototype.replaceAt = function(text, index, replacement) {
	return text.substr(0, index) + replacement + text.substr(index + replacement.length);
}

EditableText.prototype.update = function (ctx) {
	if (this.vertical_align === "middle")
	{
		this.position[1] = this.parent.position[1] + 0;
	}

	if (!this.width)
	{
		return;
	}

	// needed for measureText accuracy
	ctx.font = this.font_size * this.scale + "px Arial";

	const text_width = ctx.measureText(this.text).width;


	if (text_width >= this.width * this.scale)
	{
		// single line text
		if (!this.allow_line_breaks)
		{
			this.font_size = Math.max(this.min_font_size, this.font_size - 1);
		}
		else
		{ // multiple line text
			const last_space_index = this.text.lastIndexOf(" ");
			if (last_space_index === -1) {
				this.font_size = Math.max(this.min_font_size, this.font_size - 2);
			} else {
				this.text = this.replaceAt(this.text, last_space_index, "\n");
			}
		}
	}

	if (text_width <= (this.width * this.scale) + 40 )
	{
		this.font_size = Math.min(this.max_font_size, this.font_size + 1);
	}
}

EditableText.prototype.render = function(ctx)
{
	ctx.font = this.font_size * this.scale + "px Arial";
	ctx.textAlign = "left";

	let cursor = "";
	if ( this.interactive )
	{
		if (this.focused && (((getTime() * 0.002) | 0) % 2) === 0) {
			cursor = "|";
		}
	}

	const num_lines = this.text.split(/\r\n|\r|\n/).length;
	let text_height, text_y_pos;

	if ( this.text || this.placeholder )
	{
		let measure;
		if ( this.text )
		{
			measure = ctx.measureText(this.text);
		}
		else
		{
			measure = ctx.measureText(this.placeholder);
		}
		text_height = measure.fontBoundingBoxAscent + measure.fontBoundingBoxDescent;
		text_y_pos = this.position[1] - (((num_lines - 1) * text_height) / 2);
	}

	if ( this.text || !this.text && !this.placeholder )
	{
		ctx.fillStyle = this.colors["text"];
		ctx.fillText(this.text + cursor, this.position[0], text_y_pos);
	}
	else if ( this.placeholder )
	{
		ctx.fillStyle = this.colors["placeholder"];
		ctx.fillText(this.placeholder, this.position[0], text_y_pos);
		if ( this.focused )
		{
			ctx.fillStyle = this.colors["text"];
			ctx.fillText(cursor, this.position[0] - this.font_size / 2 * this.scale, text_y_pos);
		}
	}
}

EditableText.prototype.renderCaret = function(ctx)
{
	var alpha = Math.sin(performance.now() / 200) < 0 ? 1 : 0;
	ctx.font = this.font_size * this.scale + "px Arial";
	var x = this.text ? ctx.measureText(this.text).width + this.position[0] : this.position[0];
	ctx.fillStyle = `rgba(0, 0, 0, ${alpha}`;
	ctx.fillRect(x, this.position[1] - this.font_size * this.scale, this.caret_width * this.scale, this.font_size * this.scale);
}

export default EditableText;
