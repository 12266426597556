import { RayGlobals } from "@src/libs/rendeer/RayGlobals";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import typedArrayToArray from "@src/libs/LiteGL/TypedArray/typedArrayToArray";

export class Scene {
	/**
	 * Scene holds the full scene graph, use root to access the root child
	 * @class Scene
	 * @constructor
	 */
	constructor() {
		this._root = new SceneNode();
		this._root.flags.no_transform = true; //avoid extra matrix multiplication
		this._root._scene = this;
		this._nodes_by_id = {};
		this._nodes = [];
		this._to_destroy = [];

		this.time = 0;
		this.frame = 0;
	}

	/**
	 * clears all nodes inside
	 * @method clear
	 */
	clear() {
		this._root = new SceneNode();
		this._root._scene = this;
		this._nodes.length = 0;
		this._nodes_by_id = {};
		this.time = 0;
	}

	/**
	 * returns gets node by id
	 * @method getNodeById
	 */
	getNodeById(id) {
		return this._nodes_by_id[id];
		//return this._root.findNode(id);
	}

	/**
	 * Returns an array of nodes which bounding overlaps with a given bounding box
	 * You must call Scene.root.updateBoundingBox() to update the boundings
	 *
	 * @method findNodesInBBox
	 * @param {BBox} box  use BBox.fromCenterHalfsize(center,halfsize) to define it
	 * @param {number} layers [optional] bitmask to filter by layer, otherwise 0xFFFF is used
	 */
	findNodesInBBox(box, layers, out) {
		if (layers === undefined)
			layers = 0xFFFF;
		out = out || [];
		for (var i = 0; i < this.nodes.length; ++i) {
			var node = this.nodes[i];
			if (!node.bounding_box || !(node.layers & layers))
				continue;
			if (!geo.testBBoxBBox(node.bounding_box, box))
				continue;
			out.push(node);
		}
		return out;
	}

	/**
	 * propagate update method to all nodes
	 * @method update
	 * @param {number} dt
	 */
	update(dt) {
		this.time += dt;
		this._root.propagate("update", [ dt ]);
		this.destroyPendingNodes();
	}

//
	destroyPendingNodes(dt) {
		//destroy entities marked
		if (!this._to_destroy.length)
			return;

		var n = null;
		while (n = this._to_destroy.pop()) {
			if (n._parent)
				n._parent.removeChild(n);
		}
	}

	/**
	 * test collision of this ray with nodes in the scene
	 * @method testRay
	 * @param {Ray} ray
	 * @param {vec3} result the collision point in case there was
	 * @param {number} max_dist
	 * @param {number} layers bitmask to filter by layer, otherwise 0xFFFF is used
	 * @param {boolean} test_against_mesh test against every mesh
	 * @return {SceneNode} node collided or null
	 */
	testRay(ray, result, max_dist, layers, test_against_mesh) {
		layers = layers === undefined ? 0xFFFF : layers;
		RayGlobals.tested_objects = 0;
		if (!result)
			result = ray.collision_point;
		if (test_against_mesh === undefined)
			test_against_mesh = true;

		
		if (window.nativeEngine) {
			var layers = test_against_mesh ? -1 : 2;
			var ent = nativeEngine._room.testRay(ray.origin, ray.direction, max_dist, layers);
			if (ent) {
				var node = ent.lastCollidedNode();
				return node;
			}
			return null;
		}
		//TODO
		//broad phase
		//get all the AABBs of all objects
		//store them in an octree

		return this.root.testRay(ray, result, max_dist, layers, test_against_mesh);
	}

	fromJSON(json) {
		this.root.clear();
		this.root.configure(json);
	}

	toJSON(on_node_to_json) {
		if (on_node_to_json && on_node_to_json.constructor !== Function)
			on_node_to_json = null;

		var index = 0;
		var json = {};
		tojson(this.root, json);
		return json;

		function tojson(node, data) {
			if (on_node_to_json) {
				var r = on_node_to_json(node, data);
				if (!r)
					return false;
			} else {
				if (!node.flags.no_transform) {
					data.position = typedArrayToArray(node.position);
					if (node.rotation[0] != 0 || node.rotation[1] != 0 || node.rotation[2] != 0 || node.rotation[3] != 1)
						data.rotation = typedArrayToArray(node.rotation);
					if (node.scaling[0] != 1 || node.scaling[1] != 1 || node.scaling[2] != 1)
						data.scaling = typedArrayToArray(node.scaling);
				}
				if (node.id)
					data.id = node.id;
				node.ref = data.ref = index++;
				if (node.mesh)
					data.mesh = node.mesh;
				if (node.submesh != null)
					data.submesh = node.submesh;
				if (node.draw_range)
					data.draw_range = node.draw_range.concat();
				if (node.material)
					data.material = node.material;
				if (node.shader)
					data.shader = node.shader;
				if (node.color[0] != 1 || node.color[1] != 1 || node.color[2] != 1 || node.color[3] != 1)
					data.color = typedArrayToArray(node.color);
				if (Object.values(node.textures).filter(function (a) {
					return a;
				}) > 0)
					data.shader = node.shader;
				if (node.extra)
					data.extra = node.extra;

				data.layers = node.layers;
				data.flags = node.flags;
			}

			if (!node.children.length)
				return true;
			var children_data = [];
			for (var i = 0; i < node.children.length; ++i) {
				var child = node.children[i];
				var child_json = {};
				if (tojson(child, child_json))
					children_data.push(child_json);
			}
			if (children_data.length)
				data.children = children_data;
			return true;
		}
	}

	get root() {
		return this._root;
	}

	set root(value) {
		this._root = value;
	}
}
