import ROOM from "@src/engine/room";
import Button from "@src/libs/GLUI/Elements/Button";

function LGraphLocatorProperty()
{
	this.addInput("set",LiteGraph.ACTION);
	this.addInput("in");
	this.addOutput("",LiteGraph.EVENT);
	this.addOutput("out");
	this.properties = { locator: "", wait_event: true, cache_object: true };
	this.addWidget("toggle","Wait event",true,"wait_event");
	this.widgets_up = true;
	this.size = [ 200,50 ];
	this._locator_split = null;
	this._locator_info = null;
}

LGraphLocatorProperty.title = "Property";
LGraphLocatorProperty.desc = "A property of an entity or component of the scene specified by its locator string";
LGraphLocatorProperty.highlight_color = "#CCC";

LGraphLocatorProperty.prototype.getLocatorInfo = function( force )
{
	if (!this._locator_split)
		return null;
	if ( !force && this.properties.cache_object && this._locator_info )
		return this._locator_info;
	if ( !this.graph )
		return null;
	var xyz = XYZLauncher.instance;
	var space = this.graph.space || xyz.space; //subgraphs do not have an scene assigned
	this._locator_info = space.resolveLocatorFromPath( this._locator_split );
	if (this._locator_info && this.inputs && this.inputs.length)
		this.inputs[1].type = this._locator_info.type;
	return this._locator_info;
}

LGraphLocatorProperty.prototype.onDropItem = function( event )
{
	var locator = event.dataTransfer.getData("uid");
	if (!locator)
		return;
	this.properties.locator = locator;
	this.onExecute();
	return true;
}

LGraphLocatorProperty.prototype.onPropertyChanged = function(name,value)
{
	if (name === "locator")
	{
		if ( value )
			this._locator_split = value.split("/");
		else
			this._locator_split = null;
	}
}

LGraphLocatorProperty.prototype.getTitle = function()
{
	var info = this._locator_info;
	if ((!this.title || this.title === LGraphLocatorProperty.title) && info)
	{
		if (info.target.constructor.is_component && info.target !== info.value )
			return info.target.constructor.name + " / " + info.property;
		return info.property;
	}
	return this.title || LGraphLocatorProperty.title;
}

LGraphLocatorProperty.prototype.onDrawBackground = function(ctx)
{
	var info = this.getLocatorInfo();
	if (!info)
	{
		this.boxcolor = "red";
		return;
	}

	if ( this._last_value === true )
		this.boxcolor = "white";
	else if ( this._last_value === false )
		this.boxcolor = "black";

	var highlight = info.entity && info.entity._is_selected;

	if (highlight)
	{
		this.boxcolor = LGraphLocatorProperty.highlight_color;
		if (!this.flags.collapsed) //render line
		{
			ctx.fillStyle = LGraphLocatorProperty.highlight_color;
			ctx.fillRect(0,0,this.size[0],2);
		}
	}
	else
		this.boxcolor = null;


	if (info.target)
	{
		var name = "";
		if (info.target.entity && info.target.entity.name) //for components
			name = info.target.entity.name;
		else if (info.target.constructor === ROOM.Entity) //for entities
			name = info.target.name;
		if (name)
		{
			ctx.font = "20px Arial";
			ctx.textAlign = "left";
			ctx.fillColor = [ 1,1,1,0.75 ];
			ctx.fillText( name, 0,-40);
		}
	}

}

LGraphLocatorProperty.prototype.onAction = function(action, _param)
{
	if (action === "set")
	{
		var v = this.getInputData(1);
		if (v !== undefined)
		{
			var info = this.getLocatorInfo();
			this._last_value = info;
			ROOM.setObjectProperty( info.target, info.property, v );
		}
	}
	else if (action === "toggle")
		ROOM.toggle( this.properties.locator );
}

LGraphLocatorProperty.prototype.onExecute = function()
{
	var info = null;

	if ( !this.properties.wait_event && this.inputs.length && this.inputs[1].link !== null )
	{
		info = this.getLocatorInfo();
		this._last_info = info;
		if (info && info.target)
		{
			var v = this.getInputData(1);
			this._last_value = v;
			if (v !== undefined)
				ROOM.setObjectProperty( info.target, info.property, v );
		}
	}
	if ( this.outputs.length && this.outputs[1].links && this.outputs[1].links.length )
	{
		if (!info)
			info = this.getLocatorInfo();
		this._last_info = info;
		if ( info && info.property )
			this.setOutputData( 1, info.target[ info.property ] );
	}
}

LGraphLocatorProperty.prototype.onGetInputs = function()
{
	return [ [ "toggle", LiteGraph.ACTION ] ];
}

LGraphLocatorProperty.prototype.onGetOutputs = function()
{
	return [ [ "locator","string" ] ];
}

LGraphLocatorProperty.prototype.onExtraInspector = function(x, y, w)
{
	if ( Button.call(GUI, x, y, w, 20, "Focus on Entity" ) )
	{
		var info = this.getLocatorInfo();
		if ( info.node || info.entity )
			ROOM.Editor.instance.focusOnEntity( info.node || info.entity );
	}

	y += 24;
}

export default LGraphLocatorProperty;
