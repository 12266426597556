import { RoomCall } from "@src/controllers/call";
import BaseComponent from "@src/engine/components/baseComponent";
import RoomMediaStream from "@src/engine/helpers/mediaStream";
import { RoomParticipant } from "@src/engine/Participant/RoomParticipant";
import { ViewCore } from "@src/engine/render/view";
import ROOM from "@src/engine/room";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { LEvent } from "@src/libs/LEvent";
import { GL } from "@src/libs/litegl";
import { Camera } from "@src/libs/rendeer/Camera";
import { Direction3Constants } from "@src/libs/rendeer/Direction3Constants";
import { vec2, vec3 } from "gl-matrix";
import { rotationToDirectionVector } from "@src/engine/helpers/rotationToDirectionVector";
class CameraComponent extends BaseComponent {
    constructor() {
        super(...arguments);
        this.enabled = true;
        this.camera = new Camera({ type: Camera.PERSPECTIVE, fov: 60, near: 0.1, far: 25 });
        this.render_to_texture = false;
        this.size = [0, 0];
        this.controllable = false;
        this.fx = "";
        this._texture = null;
        this._view_from_here = false;
        this._delta_yaw = 0;
        this._delta_pitch = 0;
        this.view_from_here = false;
        this.livePreviewActive = false;
    }
    onEnter(controller) {
    }
    onLeave(controller) {
    }
    onKeyDown(e) {
        return false;
    }
    addConstraint(constraint) {
    }
    travelingTrackToConstraints() {
        return [];
    }
    resetConstraints() {
    }
    onAdded(parent) {
        parent.cameraComponent = this;
        LEvent.bind(parent.space, "global_stream_changed", this.onGlobalStreamChanged, this);
    }
    onRemoved(parent) {
        parent.cameraComponent = null;
        LEvent.unbind(parent.space, "global_stream_changed", this.onGlobalStreamChanged, this);
        if (this._stream) {
            this._stream.free();
            this._stream = null;
        }
    }
    onStart() {
        this._original_matrix = this.entity.node.getLocalMatrix();
    }
    onFinish() {
        if (this._original_matrix)
            this.entity.node.fromMatrix(this._original_matrix);
    }
    onAction(action_name, params) {
        if (action_name === "Assign") {
            this.assign();
        }
        else if (action_name === "Cancel") {
            this.cancel();
        }
    }
    getActions() {
        return ["Assign", "Cancel"];
    }
    toGlobalStream() {
        this.enabled = true;
        this.entity.enabled = true;
        this.render_to_texture = true;
        if (!this._cloned_texture) {
            this.updateTexture();
        }
        if (!this._stream) {
            this._stream = new RoomMediaStream();
            this._stream.shared = true;
        }
        this._stream.assignFeed(this._cloned_texture);
        this.space.playStream(this._stream);
        this._stream.onFree = () => {
            this.entity.enabled = false;
        };
    }
    onGlobalStreamChanged(evt, feed) {
        if (this.space._global_feed && this.space._global_feed._feed === this._texture)
            this.enabled = false;
    }
    serialize(o = {}) {
        o.enabled = this.enabled;
        o.camera = this.camera.serialize();
        o.render_to_texture = this.render_to_texture;
        o.size = Array.from(this.size);
        o.controllable = this.controllable;
        o.fx = this.fx;
        return o;
    }
    configure(o) {
        this.enabled = o.enabled;
        this.size = vec2.clone(o.size);
        this.render_to_texture = o.render_to_texture;
        this.camera.configure(o.camera);
        this.controllable = o.controllable || false;
        this.fx = o.fx || "";
    }
    onMouse(e) {
        if (!this.controllable)
            return;
        if (e.type === "mousemove" && e.dragging) {
            this._delta_yaw -= e.deltax * 0.001;
            this._delta_pitch -= e.deltay * 0.001;
        }
    }
    //called from view.preRender
    preRender(view) {
        const enabled = this.enabled && this.entity.enabled;
        if (!this.render_to_texture && this._texture) {
            this._texture.delete();
            this._cloned_texture.delete();
            this._texture = null;
            this._cloned_texture = null;
        }
        if (!enabled)
            return;
        const node = this.entity.node;
        this.camera.lookAt(node.getGlobalPosition(), node.localToGlobal([0, 0, -1]), node.getGlobalVector([0, 1, 0]));
        if (this._delta_yaw) {
            this.camera.rotate(this._delta_yaw, [0, 1, 0]);
        }
        if (this._delta_pitch) {
            this.camera.rotateLocal(this._delta_pitch, [1, 0, 0]);
        }
        if (this.fx && this.view_from_here) {
            view.setFXShader(this.fx);
        }
        if (this.render_to_texture) {
            this.updateTexture();
        }
        if (this._stream) {
            this._stream.toTexture();
        }
    }
    assign() {
        RoomCall.instance.setComponentController(this);
    }
    cancel() {
        RoomCall.instance.setComponentController(null);
    }
    updateCamera(camera, view) {
        camera.configure(this.camera);
    }
    updateTexture(force_prerender = false) {
        const view = ViewCore.instance;
        const tex_name = this._tex_name = "#CAM_" + this.entity.name;
        let w = this.size[0];
        let h = this.size[1];
        const gl = GL.ctx;
        if (w === 0 || CameraComponent.high_quality_render)
            w = gl.canvas.width;
        else if (w === -1)
            w = Math.floor(gl.canvas.width * 0.5);
        if (h === 0 || CameraComponent.high_quality_render)
            h = gl.canvas.height;
        else if (h === -1)
            h = Math.floor(gl.canvas.height * 0.5);
        let type = gl.UNSIGNED_BYTE;
        const quality = this.space.quality;
        if (quality === "high") {
            type = gl.HIGH_PRECISION_FORMAT;
        }
        const tex_options = { minFilter: gl.LINEAR, type: type };
        if (!this._texture
            || this._texture.width !== w
            || this._texture.height !== h
            || this._texture.type !== tex_options.type) {
            this._texture = new GL.Texture(w, h, tex_options);
            this._cloned_texture = new GL.Texture(w, h, tex_options);
        }
        gl.textures[this._tex_name] = this._cloned_texture;
        this._cloned_texture.name = this._tex_name;
        RoomParticipant.force_self_render = true;
        /*
        if(this.space.local_participant) //to force visibility
            this.space.local_participant.updateUserRepresentation(view);
        else if(force_prerender)
            this.view.preRender();
        */
        view.renderToTexture(this._texture, this.camera, 0xFFFF, true);
        RoomParticipant.force_self_render = false;
        if (this.space.local_participant)
            this.space.local_participant.preRender(view);
        let shader = null;
        //correct tonemmaper or it will be applied twice
        if (quality !== "low") {
            shader = gl.shaders["tonemapper"];
            if (shader) //hardcoded to fix gamma
             {
                shader.uniforms({ u_gamma: 4.4, u_brightness: 1, u_contrast: 1 });
                if (CameraComponent.postfx_uniforms) //HACK
                    shader.uniforms(CameraComponent.postfx_uniforms);
            }
        }
        this._texture.copyTo(this._cloned_texture, shader); //to avoid feedback loops
    }
    onRenderInspector(ctx, x, y, w, h, editor) {
        //use automatic interface generator
        y = editor.renderDefaultInspector(ctx, x, y, w, h, this);
        //add custom stuff
        if (this._texture) {
            const aspect = this._texture.width / this._texture.height;
            const th = w / aspect;
            ctx.drawImage(this._texture, x + 10, y, w - 20, th);
            y += th + 20;
            Label.call(GUI, x, y, 100, 24, "Tex.Name");
            this._tex_name = GUI.TextField(x + 110, y, w - 110 - 40, 24, this._tex_name, false);
            if (Button.call(GUI, x + w - 24, y, 24, 24, GLUI.icons.copy))
                ROOM.Editor.toClipboard(this._tex_name);
        }
        if (this.livePreviewActive)
            this.renderCameraLive();
        return y;
    }
    copyFromCurrent() {
        const cam = ViewCore.instance.renderer._camera; //last rendered camera
        this.entity.node.lookAt(cam.position, cam.localToGlobal(Direction3Constants.FRONT));
    }
    renderCameraLive() {
        ViewCore.instance.renderer._camera.position = this.entity.node.position;
        ViewCore.instance.renderer._camera.target = rotationToDirectionVector(this.entity.node.position, this.entity.node.rotation, vec3.fromValues(0, 0, -1));
        ViewCore.instance.renderer._camera.fov = this.camera.fov;
        ViewCore.instance.renderer._camera.shift = this.camera.shift;
    }
    renderGizmo(view, editor, selected = false) {
        editor.renderIcon3D(this.entity.node.position, [0, 0], -64, [0, 0, 0, selected ? 0.9 : 0.6]);
        editor.renderIcon3D(this.entity.node.position, CameraComponent.icon, -64, selected ? [2, 2, 2, 0.9] : [1, 1, 1, 1]);
    }
    set fov(v) {
        this.camera.fov = v;
    }
    get fov() {
        return this.camera.fov;
    }
    set shift(v) {
        this.camera.shift = v;
    }
    get shift() {
        return this.camera.shift;
    }
}
CameraComponent.componentName = "CameraComponent";
CameraComponent.icon = [0, 4];
CameraComponent.type = ROOM_TYPES.CAMERA;
CameraComponent.widgets = {
    "fov": { type: "slider", min: 5, max: 120, mark: 90 },
    "shift": { type: "vector2" },
    "render_to_texture": { type: "toggle" },
    "size": { type: "vector2" },
    "controllable": { type: "toggle" },
    "fx": { type: "string" },
    "copyFromCurrent": { type: "button", title: "Copy From Current" },
    "livePreviewActive": { type: "toggle" }
};
CameraComponent.high_quality_render = true;
CameraComponent.postfx_uniforms = null;
export default CameraComponent;
