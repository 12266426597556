import isFunction from "lodash.isfunction";

/**
 * Clones an object (no matter where the object came from)
 * - It skip attributes starting with "_" or "jQuery" or functions
 * - it tryes to see which is the best copy to perform
 * - to the rest it applies JSON.parse( JSON.stringify ( obj ) )
 * - use it carefully
 * @method cloneObject
 * @param {Object} object the object to clone
 * @param {Object} target=null optional, the destination object
 * @param {bool} recursive=false optional, if you want to encode objects recursively
 * @param {bool} only_existing=false optional, only assign to methods existing in the target object
 * @return {Object} returns the cloned object (target if it is specified)
 */
export function cloneObject(object, target, recursive, only_existing) {
	if (object === undefined)
		return undefined;
	if (object === null)
		return null;

	//base type
	switch (object.constructor) {
	case String:
	case Number:
	case Boolean:
		return object;
	}

	//typed array
	if (object.constructor.BYTES_PER_ELEMENT) {
		if (!target)
			return new object.constructor(object);
		if (target.set)
			target.set(object);
		else if (target.construtor === Array) {
			for (var i = 0; i < object.length; ++i)
				target[i] = object[i];
		} else
			throw ("cloneObject: target has no set method");
		return target;
	}

	var o = target;
	if (o === undefined || o === null) {
		if (object.constructor === Array)
			o = [];
		else
			o = {};
	}

	//copy every property of this object
	for (var i in object) {
		if (i[0] === "@" || i[0] === "_" || i.substr(0, 6) === "jQuery") //skip vars with _ (they are private) or '@' (they are definitions)
			continue;

		if (only_existing && !target.hasOwnProperty(i) && !target.__proto__.hasOwnProperty(i)) //target[i] === undefined)
			continue;

		//if(o.constructor === Array) //not necessary
		//	i = parseInt(i);

		var v = object[i];
		if (v == null)
			o[i] = null;
		else if (isFunction(v)) //&& Object.getOwnPropertyDescriptor(object, i) && Object.getOwnPropertyDescriptor(object, i).get )
			continue;//o[i] = v;
		else if (v.constructor === File)
			o[i] = null;
		else if (v.constructor === Number || v.constructor === String || v.constructor === Boolean) //elemental types
			o[i] = v;
		else if (v.buffer && v.byteLength && v.buffer.constructor === ArrayBuffer) //typed arrays are ugly when serialized
		{
			if (o[i] && v && only_existing) {
				if (o[i].length === v.length) //typed arrays force to fit in the same container
					o[i].set(v);
			} else
				o[i] = new v.constructor(v); //clone typed array
		} else if (v.constructor === Array) //clone regular array (container and content!)
		{
			//not safe to use concat or slice(0) because it doesnt clone content, only container
			if (o[i] && o[i].set && o[i].length >= v.length) //reuse old container
			{
				o[i].set(v);
				continue;
			}
			o[i] = cloneObject(v);
		} else //Objects:
		{
			if (v.constructor.is_resource) {
				console.error("Resources cannot be saved as a property of a component nor script, they must be saved individually as files in the file system. If assigning them to a component/script use private variables (name start with underscore) to avoid being serialized.");
				continue;
			}

			if (v.constructor !== Object && !target && !v.toJSON) {
				console.warn("Cannot clone internal classes:", v, " When serializing an object I found a var with a class that doesnt support serialization. If this var shouldnt be serialized start the name with underscore.'");
				continue;
			}

			if (v.toJSON)
				o[i] = v.toJSON();
			else if (recursive)
				o[i] = cloneObject(v, null, true);
			else {
				if (v.constructor !== Object)
					console.warn("Cannot clone internal classes:", v, " When serializing an object I found a var with a class that doesnt support serialization. If this var shouldnt be serialized start the name with underscore.'");

				o[i] = JSON.parse(JSON.stringify(v));
			}
		}
	}
	return o;
}