import ROOM from "@src/engine/room";

import AudioPlayer       from "./audioPlayer";
import CameraComponent   from "./CameraComponent";
import CameraViewpoint   from "./cameraViewpoint";
import CloudsObject      from "./cloudsObject";
import DecalObject       from "./decalObject";
import FaceTo            from "./faceTo";
import FollowPath        from "./followPath";
import HoloCam           from "./holocam";
import InteractiveObject from "./interactiveObject";
import LightObject       from "./lightObject";
import MediaPlayer       from "./mediaPlayer";
import ParticlesObject   from "./particlesObject";
import SplineMeshObject  from "./splineMeshObject";
import PrefabRenderer    from "./prefab";
import ProbeObject       from "./probeObject";
import Seat              from "./seat";
import SkyBoxObject      from "./skyBoxObject";
import Surface           from "./surface";
import TravelingTrack    from "./TravelingTrack";
import VolumeFogObject   from "./volumeFogObject";
import WalkableArea      from "./walkableArea";
import Path              from "./path";
import GraphComponent    from "./graph";
import PlayAnimation    from "./playAnimation";
import AnimationComponent from "@src/engine/components/AnimationComponent";
import BroadcastSceneComponent from "@src/engine/components/BroadcastSceneComponent";

ROOM.registerComponent(InteractiveObject);
ROOM.registerComponent(Surface);
ROOM.registerComponent(Seat);
ROOM.registerComponent(PrefabRenderer);
ROOM.registerComponent(HoloCam);
ROOM.registerComponent(MediaPlayer);
ROOM.registerComponent(AudioPlayer);
ROOM.registerComponent(WalkableArea);
ROOM.registerComponent(FaceTo);
ROOM.registerComponent(CameraComponent);
ROOM.registerComponent(CameraViewpoint);
ROOM.registerComponent(Path);
ROOM.registerComponent(FollowPath);
ROOM.registerComponent(TravelingTrack);
ROOM.registerComponent(DecalObject);
ROOM.registerComponent(LightObject);
ROOM.registerComponent(ParticlesObject);
ROOM.registerComponent(SplineMeshObject);
ROOM.registerComponent(VolumeFogObject);
ROOM.registerComponent(ProbeObject);
ROOM.registerComponent(SkyBoxObject);
ROOM.registerComponent(CloudsObject);
ROOM.registerComponent(GraphComponent);
ROOM.registerComponent(PlayAnimation);
ROOM.registerComponent(AnimationComponent);
ROOM.registerComponent(BroadcastSceneComponent);

//deprecated
import Portal            from "./portal";
import TableTop          from "./tabletop";
import DiceManager       from "./TableTop/DiceManager";
import FigurineManager   from "./TableTop/FigurineManager";
import InspectObject     from "./inspectObject";
import ImportObject      from "./importObject";

ROOM.registerComponent(TableTop);
ROOM.registerComponent(DiceManager);
ROOM.registerComponent(FigurineManager);
ROOM.registerComponent(ImportObject);
ROOM.registerComponent(InspectObject);
ROOM.registerComponent(Portal);
