import { RoomCall } from "@src/controllers/call";

import Entity from "@src/engine/entity";
import { RoomParticipant } from "@src/engine/Participant/RoomParticipant";
import ROOM from "@src/engine/room";
import EnumOrientation from "@src/EnumOrintation";
import Button from "@src/libs/GLUI/Elements/Button";
import { GL } from "@src/libs/litegl";
import { Camera } from "@src/libs/rendeer/Camera";
import { testRayWithNodes } from "@src/libs/rendeer/testRayWithNodes";
import { vec3 } from "gl-matrix";


//To configure how others see yourself
function RoomSelectSeat(launcher, space, view, _settings )
{
	this.xyz = this.launcher = launcher;
	this.name = "configure";

	this.space = space;
	this.room = space; //legacy
	this.view = view;
	RoomSelectSeat.instance = this;

	this.grab_point = vec3.create();
	this.last_ray = new GL.Ray();
}

RoomSelectSeat.instance = null;

RoomSelectSeat.prototype.onEnter = function()
{
	var camera = this.view.hard_camera;
	var pos = [ 10,10,10 ];
	var target = [ 0,1,0 ];
	camera.lookAt( pos, target, [ 0,1,0 ] );
	var env = this.space.scene.root.findNodeByName("environment");
	if (env)
		env.visible = false;
	this.view.camera.frustum_size = camera.frustum_size = 2;
	this.view.limit_camera = false;
	this.view._skip_transition = true;
	RoomParticipant.orientation_mode = EnumOrientation.BILLBOARD_ORIENTATION;
	ROOM.Seat.gizmos_visible = true;
}

RoomSelectSeat.prototype.onLeave = function()
{
	this.view.hard_camera.type = Camera.PERSPECTIVE;
	this.view.pbrpipeline.setClippingPlane(null);
	this.view.pbrpipeline.render_skybox = true;
	var env = this.space.scene.root.findNodeByName("environment");
	if (env)
		env.visible = true;
	this.view.limit_camera = true;
	RoomParticipant.orientation_mode = EnumOrientation.USER_FACING_ORIENTATION;
	this.view._skip_transition = true;
}

RoomSelectSeat.prototype.onRender = function()
{
	var camera = this.view.hard_camera;
	camera.type = Camera.ORTHOGRAPHIC;

	this.view.pbrpipeline.setClippingPlane([ 0,2,0 ],[ 0,-1,0 ]);
	this.view.pbrpipeline.render_skybox = false;

	//render scene
	this.view.render();

	//UI
	var ctx = gl;
	ctx.start2D();
	if ( Button.call(GUI, 50,10,40,40, [ 6,2 ],true,[ 0.9,0.9,0.9,1 ],[ 1,1,1 ],20) )
		this.exit();

	RoomCall.instance.renderUI();
}

RoomSelectSeat.prototype.onMouse = function(e)
{
	var r = GUI.onMouse(e);
	if ( r || GUI.isPositionBlocked( e.canvasx, e.canvasy ) )
		return true;

	var camera = this.view.hard_camera;
	var ray = e.ray = this.view.camera.getRay( e.canvasx, e.canvasy, null, this.view.last_ray );

	this.view.node_hover = this.space.testRayWithInteractiveNodes( ray );
	this.view.subnode_hover = testRayWithNodes.coll_node;
	this.view.item_hover = null;
	if ( this.view.node_hover )
	{
		this.view.item_hover = this.view.node_hover.getParentEntity();
		if ( this.view.item_hover && this.view.item_hover.onHoverMouse )
			this.view.item_hover.onHoverMouse( e );
	}

	if (e.type == "mousemove")
	{
		if ( e.buttons & 1 )
		{
			var ray = this.view.camera.getRay( e.canvasx, e.canvasy, null, this.last_ray );
			if ( ray.testPlane( [ 0,0,0 ],[ 0,1,0 ] ) )
			{
				var delta = vec3.sub( vec3.create(), this.grab_point, ray.collision_point );
				console.debug(ray.collision_point);
				camera.move(delta);
				this.grab_point.set( ray.collision_point );
			}
		}
		else if ( e.buttons & 2 ) //right mouse
		{
			camera.orbit( -e.deltax * 0.01, [ 0,1,0 ] );
		}
	}
	else if (e.type == "mousedown")
	{
		if ( e.buttons & 1 )
		{
			var ray = this.view.camera.getRay( e.canvasx, e.canvasy, null, this.last_ray );
			if ( ray.testPlane( [ 0,0,0 ],[ 0,1,0 ] ) )
			{
				this.grab_point.set( ray.collision_point );
			}
		}
	}
	else if (e.type == "mouseup")
	{
		if (e.click_time < 250 && !GUI.isPositionBlocked([ e.mousex,e.mousey ])) //click
			this.processItemClicked( this.view.item_hover, this.view.node_hover, e, this.view.subnode_hover );
	}
	else if (e.type == "wheel")
	{
		var direction = (e.wheel > 0) ? 1 : -1;
		var offset = 1 + direction * -0.05;
		camera.frustum_size *= offset;
	}

}

RoomSelectSeat.prototype.processItemClicked = function( item, node, e )
{
	if (!item)
		return;
	if ( item.constructor === Entity && item.seat )
	{
		this.space.local_participant.enterSeat( item.name );
		this.exit();
	}
}

RoomSelectSeat.prototype.exit = function()
{
	this.launcher.setController( this.launcher.call_controller );
}

RoomSelectSeat.prototype.onKeyDown = function(e)
{
	switch (e.code)
	{
	case "Escape":
		this.exit();
		break;
	}
}

//resend events to call *************************************

RoomSelectSeat.prototype.onPartipantChangeSeat = function( type, event )
{
	RoomCall.instance.onPartipantChangeSeat(type, event );
}

RoomSelectSeat.prototype.onParticipantLeave = function()
{
	RoomCall.instance.onParticipantLeave();
}

RoomSelectSeat.prototype.onUpdate = function(dt)
{
	RoomCall.instance.onUpdate(dt);
}

RoomSelectSeat.prototype.onTick = function()
{
	RoomCall.instance.onTick();
}

export {RoomSelectSeat}
