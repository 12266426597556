import { mat3, vec3, vec2 } from "gl-matrix";

import ROOM   from "@src/engine/room";
import Button from "@src/libs/GLUI/Elements/Button";
import Label  from "@src/libs/GLUI/Elements/Label";

var MaterialEditorPanel = {

	area: [ 300,300, 800,600 ],
	collapsed: true,
	child_index: 0,
	material_index: 0,
	material_property_index: 0, //albedo...

	current_tab: 0,
	total_height: 200,

	material_property_names: [ "albedo","emissive","opacity","occlusion","metallicFactor","roughnessFactor","normal","displacement","detail" ],

	material_properties: [
		{ name: "albedo", type: "color" },
		{ name: "emissive", type: "color" },
		{ name: "opacity", type: "number" },
		{ name: "occlusion", type: "color" },
		{ name: "metallicFactor", type: "number", texture: "metallicRoughness" },
		{ name: "roughnessFactor", type: "number", texture: "metallicRoughness" },
		{ name: "normal", type: "number", prop_name: "normalmapFactor" },
		{ name: "displacement", type: "number", prop_name: "displacementFactor", min: -2, max: 2, marker: 0 },
		{ name: "detail", type: "number" }
	],

	blend_modes: [
		"ALPHA",
		"ADD",
		"MULTIPLY"
	],

	matProperty: function (material, prop_name, prop_type) {
		if (material.native) {
			if (prop_type === "color") {
				var color = material.propertyColor(prop_name);
				if (color[0] == -1000.0)
					return null;
				else
					return color;
			}
			else {
				var num = material.propertyNumber(prop_name);
				if (num == -1000.0)
					return null;
				else
					return num;
			}
		}
		else
			return material[prop_name];
	},

	setMatProperty: function (material, prop, prop_name, prop_type) {
		if (material.native) {
			if (prop_type === "color") {
				if (prop[0] != -1000)
					material.setPropertyColor(prop_name, prop);
			}
			else {
				if (prop != -1000)
					material.setPropertyNumber(prop_name, prop);
			}
		}
		else
			material[prop_name] = prop;
	},

	matTextureName: function (material, slot) {
		if (material.native) {
			var texName = material.textureName(slot);
			if (texName === "")
				return null;
			return texName;
		}
		else
			return material.textures[slot];
	},

	matTextureObj: function (material, slot, tex_name) {
		if (material.native) {
			var tex = material.textureObj(slot);
			if (!tex.handler)
				tex.handler = GLEM.textures[tex.handle];
			return tex;
		}
		else
			return gl.textures[tex_name];
	},

	render: function( editor, y )
	{
		var material = editor.selected_material;
		var RoomEditor = editor.constructor;
		if (!material)
			return;
		this.editor = editor;

		if (!this.specular_color) {
			this.specular_color = vec3.fromValues(0, 0, 0);
			this.prop_color = vec3.fromValues(0, 0, 0);
			this.sheen_color = vec3.fromValues(0, 0, 0);
			this.diffuse_color = vec3.fromValues(0, 0, 0);
			this.subsurface_color = vec3.fromValues(0, 0, 0);
			this.back_color = vec3.fromValues(0, 0, 0);
			this.diffuse_color = vec3.fromValues(0, 0, 0);
			this.anisotropy_direction = vec3.fromValues(0, 0, 0);

			this.waves_amplitude = vec4.fromValues(0, 0, 0, 0);
			this.waves_intensity = vec4.fromValues(0, 0, 0, 0);
			this.waves_noise = vec4.fromValues(0, 0, 0, 0);
		}
		var ctx = gl;

		var w = 400;
		var h = gl.canvas.height - y - 60;
		var start_y = y;

		if ( this.collapsed )
		{
			w = 200;
			h = 44;
		}

		var x = this.area[0] = gl.canvas.width - (w - 10);
		this.area[1] = y;
		var end_y = y + h;

		GUI.Panel( x,y,w + 40,h );

		GUI.next_tooltip = "Show Material Info";
		var st = GUI.HoverArea( x,y, 30,30 );
		GUI.DrawIcon( x + 20, y + 20, 0.4, this.collapsed ? [ 5,3 ] : [ 13,3 ] );
		if ( st === GLUI.CLICKED )
		{
			this.collapsed = !this.collapsed;
			GUI.consumeClick();
			return end_y;
		}

		var color = this.collapsed ? [ 1,1,1,0.5 ] : [ 1,1,1,1 ];
		GUI.DrawIcon( x + 45, y + 20, 0.3, [ 5,5 ],false, editor.draggable_color );
		if (GUI.HoverArea( x + 40, y + 10, 30, 30, { target: material, callback: this.onDragMaterial } ) === GLUI.CLICKED )
		{

		}

		var mat_name = material.name || "unnamed";

		Label.call(GUI, x + 60,y+10,w-100,24, mat_name, color );
		if ( this.collapsed )
			return end_y;

		GUI.next_tooltip = "Save Material";
		if ( Button.call(GUI, x + w - 36, y + 10, 24, 24, ROOM.edited_materials[ mat_name ] ? [ 6,0 ] : [ 3,1 ] ) )
			ROOM.edited_materials[ mat_name ] = !ROOM.edited_materials[ mat_name ];

		var current_y = y + 40;

		GUI.value_changed_from_reset = false;

		var tabs = [ "Properties","Channels","Debug" ];
		var tab_w = (w-20)/tabs.length;
		for (var i = 0; i < tabs.length; ++i)
		{
			if ( Button.call(GUI,x+10+i*tab_w,current_y,tab_w,24, tabs[i], this.current_tab == i,null,null,0 ) )
				this.current_tab = i;
		}
		current_y += 30;

		//scroll stuff

		var starty = current_y;
		var list_height = h - (current_y - start_y) - 20;

		if (!this.scroll_area )
			this.scroll_area = GUI.createScrollArea( w, list_height, this.total_height);
		this.scroll_area.total = this.total_height;

		GUI.ScrollableArea( this.scroll_area, x + 10, current_y + 10, w - 20, list_height, inner.bind(this) );
		//inner.call(this,x, current_y, w, list_height);

		function inner(x,y,w,h) //inside scroll
		{
			if (material.native)
			{
				material.startEdit();

				if (this.current_tab == 0) {
					current_y = this.renderMaterialNative( material, x, y, w, h);
				}

				ROOM.edited_materials[ mat_name ] |= GUI.value_changed_from_reset;

				if (this.current_tab == 1) //channels
				{
					current_y = this.renderChannels( material, x, y, w, h);
				}
			}
			else //non native
			{
				if (this.current_tab == 0) {
					current_y = this.renderMaterialBase( material, x, y, w, h);
				}
				if (this.current_tab == 1) //channels
				{
					current_y = this.renderChannels( material, x, y, w, h);
				}

			}

			if (this.current_tab == 2)
			{
				GUI.next_tooltip = "Show JSON";
				if ( Button.call(GUI, x + 10, y, w - 20, 24, "Show JSON" ) )
					RoomEditor.showJSON( material.serialize() );
				y += 30;

				GUI.next_tooltip = "Copy Locator";
				if ( Button.call(GUI, x + 10, y, w - 20, 24, "Copy" ) )
					RoomEditor.toClipboard( xyz.ROOM.locator_chars.material + material.name );

				current_y = y;
			}


			this.total_height = current_y - starty;
		}


		ROOM.edited_materials[ mat_name ] |= GUI.value_changed_from_reset;

		return end_y;
	},

	renderMaterialBase: function(material, x,y,w)
	{
		var current_y = y;
		var editor = this.editor;
		var nativeEngine = window.nativeEngine;

		if (!window.nativeEngine) {
			// Andrey: This check box is not compatible with native material shading models
			// Use native material properties instead
			var v = GUI.Toggle(x + 10, current_y, w - 20, 24, "Use PBR", material.model === "pbrMetallicRoughness");
			material.model = v ? "pbrMetallicRoughness" : "NOPBR";
			current_y += 30;
		}

		Label.call(GUI,x + 10, current_y, 100, 24, "alphaMode");
		if (material.alphaMode != null) {
			if (Button.call(GUI,x + 120, current_y, 100, 24, material.alphaMode)) {
				if (material.alphaMode === "OPAQUE")
					material.alphaMode = "BLEND";
				else if (material.alphaMode === "BLEND")
					material.alphaMode = "MASK";
				else
					material.alphaMode = "OPAQUE";
			}

			if (material.alphaMode === "MASK")
				material.alphaCutoff = GUI.Slider(x + 220, current_y, 180, 30, material.alphaCutoff, 0, 1);
			else if (material.alphaMode === "BLEND")
				material.opacity = GUI.Slider(x + 220, current_y, 160, 30, material.opacity, 0, 1);
		}
		else
		if (Button.call(GUI,x + 120, current_y, 100, 24, "OPAQUE"))
			material.alphaMode = "BLEND";

		current_y += 30;

		var flags = material.flags;
		if (material.alphaMode === "BLEND") {
			Label.call(GUI,x + 10, current_y, 120, 24, "Mode");
			var old_index = this.blend_modes.indexOf(material.blendMode || "ALPHA");
			var index = GUI.ComboLine(x + 120, current_y, 240, 24, old_index, this.blend_modes);
			if (old_index !== index)
				material.blendMode = this.blend_modes[index];
			current_y += 30;
		}

		Label.call(GUI,x + 10, current_y, 180, 24, "Two sided");
		flags.two_sided = GUI.Toggle(x + 120, current_y, 24, 24, null, flags.two_sided);
		if (flags.two_sided) {
			Label.call(GUI,x + 180, current_y, 180, 24, "Backface");
			if (!material.backface_color)
			{
				material.backface_color = [ 0.1, 0.1, 0.1 ];
				//console.log("backface color created");
			}
			GUI.Color3(x + 280, current_y, 100, 24, material.backface_color);
		}
		current_y += 30;

		/*
		if( material.textures.normal )
		{
			Label.call(GUI, x + 20, current_y + 120, 180, 30, "Normal" );
			material.normalmapFactor = GUI.Slider( x + 100, current_y + 120, 110, 30, material.normalmapFactor, 0, 1 );
		}
		*/

		return current_y;
	},

	renderMaterialNative: function( material, x, y, w, h )
	{
		var current_y = y;
		var flags = material.flags;
		var editor = this.editor;


		var eSM_NoPbr = 0;
		var eSM_PbrMetallicRoughness = 1;
		var eSM_PbrGlossiness = 2;
		var eSM_Cloth = 3;
		var eSM_Subsurface = 4;
		var eSM_WaterVolume = 5;
		var eSM_WaterOcean = 6;
		var eSM_FogVolume = 8;
		var eSM_NeRFVolume = 9;

		var shade_modes = [
			"NOPBR",
			"pbrMetallicRoughness",
			"pbrGlossiness",
			"pbrCloth",
			"pbrSubsurface",
			"waterVolume",
			"waterOcean",
			"Particles",
			"fogVolume"
		];
		Label.call(GUI,x + 10, current_y, 180, 24, "Shading Type");
		var shade_old_index = shade_modes.indexOf(material.model);
		var shade_index = GUI.ComboLine(x + 125, current_y, w - 160, 24, shade_old_index, shade_modes, "shade models");
		if (shade_old_index !== shade_index) {
			material.shadingModel = shade_index;
			if (shade_index == eSM_Cloth) {
				material.sheenMode = false;
				vec3.copy(this.diffuse_color, material.color);
				this.diffuse_color[0] = Math.sqrt(this.diffuse_color[0]);
				this.diffuse_color[1] = Math.sqrt(this.diffuse_color[1]);
				this.diffuse_color[2] = Math.sqrt(this.diffuse_color[2]);
				material.sheenColor = this.diffuse_color;
			}
		}
		current_y += 30;

		if (shade_index == eSM_WaterOcean || shade_index == eSM_WaterVolume) {
			var water = material.water;
			if (water) {
				if (editor.drawCollapsableLabel("nativewfeatures", "Water Features", x + 10, current_y, w)) {
					current_y += 40;

					Label.call(GUI,x + 10, current_y, 180, 24, "FFT Normals");
					water.flagFFTNormals = GUI.Toggle(x + 180, current_y, 24, 24, null, water.flagFFTNormals);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 180, 24, "Blended");
					water.flagTransparent = GUI.Toggle(x + 180, current_y, 24, 24, null, water.flagTransparent);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 180, 24, "Blinn-Phong");
					water.flagBlinnPhong = GUI.Toggle(x + 180, current_y, 24, 24, null, water.flagBlinnPhong);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 180, 24, "Simpl-Fresnel");
					water.flagSimplifiedFresnel = GUI.Toggle(x + 180, current_y, 24, 24, null, water.flagSimplifiedFresnel);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 180, 24, "Sky-Radiance");
					water.flagMeanSkyRadiance = GUI.Toggle(x + 180, current_y, 24, 24, null, water.flagMeanSkyRadiance);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 180, 24, "Foam");
					water.flagFoam = GUI.Toggle(x + 180, current_y, 24, 24, null, water.flagFoam);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 180, 24, "Shore");
					water.flagShore = GUI.Toggle(x + 180, current_y, 24, 24, null, water.flagShore);
					current_y += 30;
				}
				else
					current_y += 40;

				if (editor.drawCollapsableLabel("nativewbase", "Water Base Params", x + 10, current_y, w)) {
					current_y += 40;

					var dens = water.densityParams;

					Label.call(GUI,x + 10, current_y, 120, 24, "Amb Intens");
					dens[0] = GUI.SliderVal(x + 220, current_y, 160, 30, dens[0], 0.0, 2.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Diff Intens");
					dens[1] = GUI.SliderVal(x + 220, current_y, 160, 30, dens[1], 0.0, 2.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Normal Intens");
					dens[3] = GUI.SliderVal(x + 220, current_y, 160, 30, dens[3], 0.0, 1.0);
					current_y += 30;

					water.densityParams = dens;

					Label.call(GUI,x + 10, current_y, 120, 24, "Tex Tiling");
					water.textureTiling = GUI.SliderVal(x + 220, current_y, 160, 30, water.textureTiling, 0.01, 10.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Wind Speed");
					water.windSpeed = GUI.SliderVal(x + 220, current_y, 160, 30, water.windSpeed, 0.0, 20.0);
					current_y += 30;

					vec3.copy(this.anisotropy_direction, water.windDirection);
					Label.call(GUI,x + 10, current_y, 100, 24, "Wind. Dir.");
					GUI.Vector(x + 120, current_y, 180, 24, this.anisotropy_direction);
					water.windDirection = this.anisotropy_direction;
					current_y += 30;
				}
				else
					current_y += 40;

				if (editor.drawCollapsableLabel("nativewcolor", "Water Color Params", x + 10, current_y, w)) {
					current_y += 40;

					vec3.copy(this.diffuse_color, water.surfaceColor);
					Label.call(GUI,x + 10, current_y, 100, 24, "Surface Color");
					GUI.Color3(x + 150, current_y, 40, 24, this.diffuse_color);
					GUI.Vector(x + 190, current_y, 180, 24, this.diffuse_color);
					vec3.copy(this.waves_intensity, this.diffuse_color);
					this.waves_intensity[3] = water.surfaceColor[3];
					water.surfaceColor = this.waves_intensity;
					current_y += 30;

					vec3.copy(this.specular_color, water.shoreColor);
					Label.call(GUI,x + 10, current_y, 100, 24, "Shore Color");
					GUI.Color3(x + 150, current_y, 40, 24, this.specular_color);
					GUI.Vector(x + 190, current_y, 180, 24, this.specular_color);
					vec3.copy(this.waves_intensity, this.specular_color);
					this.waves_intensity[3] = water.shoreColor[3];
					water.shoreColor = this.waves_intensity;
					current_y += 30;

					vec3.copy(this.prop_color, water.depthColor);
					Label.call(GUI,x + 10, current_y, 100, 24, "Depth Color");
					GUI.Color3(x + 150, current_y, 40, 24, this.prop_color);
					GUI.Vector(x + 190, current_y, 180, 24, this.prop_color);
					vec3.copy(this.waves_intensity, this.prop_color);
					this.waves_intensity[3] = water.depthColor[3];
					water.depthColor = this.waves_intensity;
					current_y += 30;
				}
				else
					current_y += 40;

				if (editor.drawCollapsableLabel("nativewdispl", "Water Displacement Params", x + 10, current_y, w)) {
					current_y += 40;

					var dens = water.densityParams;

					Label.call(GUI,x + 10, current_y, 120, 24, "Height Intens");
					dens[2] = GUI.SliderVal(x + 220, current_y, 160, 30, dens[2], 0.0, 2.0);
					current_y += 30;

					water.densityParams = dens;

					Label.call(GUI,x + 10, current_y, 120, 24, "Wave Tiling");
					water.waveTiling = GUI.SliderVal(x + 220, current_y, 160, 30, water.waveTiling, 0.01, 2.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Wave Factor");
					water.waveAmplitudeFactor = GUI.SliderVal(x + 220, current_y, 160, 30, water.waveAmplitudeFactor, 0.01, 2.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Wave Steepness");
					water.waveSteepness = GUI.SliderVal(x + 220, current_y, 160, 30, water.waveSteepness, 0.01, 2.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Wave Steepness");
					water.waveSteepness = GUI.SliderVal(x + 220, current_y, 160, 30, water.waveSteepness, 0.01, 2.0);
					current_y += 30;

					vec4.copy(this.waves_amplitude, water.waveAmplitude);
					Label.call(GUI,x + 10, current_y, 120, 24, "Waves Amp");
					GUI.Vector(x + 190, current_y, 180, 24, this.waves_amplitude);
					water.waveAmplitude = this.waves_amplitude;
					current_y += 30;

					vec4.copy(this.waves_intensity, water.wavesIntensity);
					Label.call(GUI,x + 10, current_y, 120, 24, "Waves Intens");
					GUI.Vector(x + 190, current_y, 180, 24, this.waves_intensity);
					water.wavesIntensity = this.waves_intensity;
					current_y += 30;

					vec4.copy(this.waves_noise, water.wavesNoise);
					Label.call(GUI,x + 10, current_y, 120, 24, "Waves Noise");
					GUI.Vector(x + 190, current_y, 180, 24, this.waves_noise);
					water.wavesNoise = this.waves_noise;
					current_y += 30;
				}
				else
					current_y += 40;

				if (editor.drawCollapsableLabel("nativewrefr", "Water Refraction Params", x + 10, current_y, w)) {
					current_y += 40;

					Label.call(GUI,x + 10, current_y, 120, 24, "Clarity");
					water.waterClarity = GUI.SliderVal(x + 220, current_y, 160, 30, water.waterClarity, 0.0, 5.0);
					current_y += 30;

					Label.call(GUI, x + 10, current_y, 120, 24, "Refr. Roughness");
					water.refrRoughness = GUI.SliderVal(x + 220, current_y, 160, 30, water.refrRoughness, 0.0, 2.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Transparency");
					water.waterTransparency = GUI.SliderVal(x + 220, current_y, 160, 30, water.waterTransparency, 0.0, 100.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Horiz Extinct");
					water.horizontalExtinction = GUI.SliderVal(x + 220, current_y, 160, 30, water.horizontalExtinction, 0.0, 30.0);
					current_y += 30;

					var refr = water.refractionValues;

					Label.call(GUI,x + 10, current_y, 120, 24, "Refr IOR");
					refr[0] = GUI.SliderVal(x + 220, current_y, 160, 30, refr[0], 0.0, 3.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Refr Intens");
					refr[1] = GUI.SliderVal(x + 220, current_y, 160, 30, refr[1], 0.0, 1.0);
					current_y += 30;

					water.refractionValues = refr;

					Label.call(GUI,x + 10, current_y, 120, 24, "Refr Scale");
					water.refractionScale = GUI.SliderVal(x + 220, current_y, 160, 30, water.refractionScale, 0.0, 0.5);
					current_y += 30;
				}
				else
					current_y += 40;

				if (editor.drawCollapsableLabel("nativewrefl", "Water Reflection Params", x + 10, current_y, w)) {
					current_y += 40;

					var radf = water.radianceFactor;

					Label.call(GUI,x + 10, current_y, 120, 24, "Intensity");
					radf[1] = GUI.SliderVal(x + 220, current_y, 160, 30, radf[1], 0.0, 2.0);
					current_y += 30;

					Label.call(GUI, x + 10, current_y, 120, 24, "Refl. Roughness");
					water.reflRoughness = GUI.SliderVal(x + 220, current_y, 160, 30, water.reflRoughness, 0.0, 2.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Radiance");
					radf[0] = GUI.SliderVal(x + 220, current_y, 160, 30, radf[0], 0.0, 1.0);
					current_y += 30;

					water.radianceFactor = radf;

					Label.call(GUI,x + 10, current_y, 120, 24, "Shininess");
					water.shininess = GUI.SliderVal(x + 220, current_y, 160, 30, water.shininess, 0.0, 5.0);
					current_y += 30;

					var spec = water.specularValues;

					Label.call(GUI,x + 10, current_y, 120, 24, "Spec Intensity 0");
					spec[0] = GUI.SliderVal(x + 220, current_y, 160, 30, spec[0], 0.0, 1000.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Spec Intensity 1");
					spec[1] = GUI.SliderVal(x + 220, current_y, 160, 30, spec[1], 0.0, 1000.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Shininess F");
					spec[2] = GUI.SliderVal(x + 220, current_y, 160, 30, spec[3], 0.0, 2.0);
					current_y += 30;

					water.specularValues = spec;
				}
				else
					current_y += 40;

				if (editor.drawCollapsableLabel("nativewfoam", "Water Foam Params", x + 10, current_y, w)) {
					current_y += 40;

					var foamt = water.foamTiling;

					Label.call(GUI,x + 10, current_y, 120, 24, "Foam Tile 0");
					foamt[0] = GUI.SliderVal(x + 220, current_y, 160, 30, foamt[0], 0.0, 4.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Foam Tile 1");
					foamt[1] = GUI.SliderVal(x + 220, current_y, 160, 30, foamt[1], 0.0, 4.0);
					current_y += 30;

					water.foamTiling = foamt;

					vec3.copy(this.prop_color, water.foamRanges);
					Label.call(GUI,x + 10, current_y, 120, 24, "Foam Ranges");
					GUI.Vector(x + 190, current_y, 180, 24, this.prop_color);
					water.foamRanges = this.prop_color;
					current_y += 30;

					vec4.copy(this.waves_noise, water.foamNoise);
					Label.call(GUI,x + 10, current_y, 120, 24, "Foam Noise");
					GUI.Vector(x + 190, current_y, 180, 24, this.waves_noise);
					water.foamNoise = this.waves_noise;
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Foam Speed");
					water.foamSpeed = GUI.SliderVal(x + 220, current_y, 160, 30, water.foamSpeed, 0.0, 20.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Foam Intensity");
					water.foamIntensity = GUI.SliderVal(x + 220, current_y, 160, 30, water.foamIntensity, 0.0, 2.0);
					current_y += 30;

					vec4.copy(this.waves_noise, water.shoreColor);
					Label.call(GUI,x + 10, current_y, 120, 24, "Shore Intensity");
					this.waves_noise[3] = GUI.SliderVal(x + 220, current_y, 160, 30, this.waves_noise[3], 0.0, 10.0);
					water.shoreColor = this.waves_noise;
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Shore Fade");
					water.shoreFade = GUI.SliderVal(x + 220, current_y, 160, 30, water.shoreFade, 0.01, 50.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Shore Dist");
					water.shoreDistance = GUI.SliderVal(x + 220, current_y, 160, 30, water.shoreDistance, 1.0, 1000.0);
					current_y += 30;
				}
				else
					current_y += 40;
			}
		}
		else {

			if (material && material.native) {
				var matPreview = nativeEngine._engine.isMaterialPreview();
				Label.call(GUI, x + 10, current_y, 100, 24, "Material preview");
				var newPreview = GUI.Toggle(x + 180, current_y, 24, 24, null, matPreview);
				current_y += 30;
				if (matPreview != newPreview || (newPreview && this.preview_mat != material)) {
					this.preview_mat = material;
					nativeEngine._engine.setMaterialPreview(material, newPreview);
					GUI.value_changed_from_reset = false;
				}
			}

			if (editor.drawCollapsableLabel("nativebase", "Base Params", x + 10, current_y, w)) {
				current_y += 40;

				Label.call(GUI,x + 10, current_y, 100, 24, "Alpha Mode");
				var alpha_modes = [
					"OPAQUE",
					"BLEND",
					"MASK"
				];
				var alpha_old_index = alpha_modes.indexOf(material.alphaMode || "OPAQUE");
				var alpha_index = GUI.ComboLine(x + 120, current_y, w - 150, 24, alpha_old_index, alpha_modes);
				if (alpha_old_index !== alpha_index)
					material.alphaMode = alpha_modes[alpha_index];
				current_y += 30;

				if (material.alphaMode === "BLEND") {
					Label.call(GUI,x + 10, current_y, 120, 24, "Blend Mode");
					var old_index = this.blend_modes.indexOf(material.blendMode || "ALPHA");
					var index = GUI.ComboLine(x + 120, current_y, 240, 24, old_index, this.blend_modes);
					if (old_index !== index)
						material.blendMode = this.blend_modes[index];
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 120, 24, "Opacity:");
					material.opacity = GUI.SliderVal(x + 220, current_y, 160, 30, material.opacity, 0.0, 1.0);
					current_y += 30;
				}

				if (material.alphaMode === "MASK") {
					Label.call(GUI, x + 10, current_y, 120, 24, "Opacity:");
					material.opacity = GUI.SliderVal(x + 220, current_y, 160, 30, material.opacity, 0.0, 1.0);
					current_y += 30;

					Label.call(GUI, x + 10, current_y, 120, 24, "Mask Threshold:");
					material.alphaCutoff = GUI.SliderVal(x + 220, current_y, 180, 30, material.alphaCutoff, 0.0, 1.0);
					current_y += 30;

					Label.call(GUI,x + 10, current_y, 180, 24, "ATOC");
					material.ATOC = GUI.Toggle(x + 120, current_y, 24, 24, null, material.ATOC);
					current_y += 30;
				}

				vec3.copy(this.diffuse_color, material.color);
				Label.call(GUI,x + 10, current_y, 100, 24, "Diffuse Color");
				GUI.Color3(x + 150, current_y, 40, 24, this.diffuse_color);
				GUI.Vector(x + 190, current_y, 180, 24, this.diffuse_color);
				material.color = this.diffuse_color;
				current_y += 30;

				Label.call(GUI,x + 10, current_y, 120, 24, "Diffuse Intensity:");
				material.diffuse_intensity = GUI.SliderVal(x + 180, current_y, 180, 30, material.diffuse_intensity, 0.0, 10.0);
				current_y += 30;

				vec3.copy(this.specular_color, material.specular_color);
				Label.call(GUI,x + 10, current_y, 100, 24, "Specular Color");
				GUI.Color3(x + 150, current_y, 40, 24, this.specular_color);
				GUI.Vector(x + 190, current_y, 180, 24, this.specular_color);
				material.specular_color = this.specular_color;
				current_y += 30;

				Label.call(GUI,x + 10, current_y, 120, 24, "Specular Intensity:");
				material.specular_intensity = GUI.SliderVal(x + 180, current_y, 180, 30, material.specular_intensity, 0.0, 10.0);
				current_y += 30;


				Label.call(GUI,x + 10, current_y, 180, 24, "Two sided");
				flags.two_sided = GUI.Toggle(x + 180, current_y, 24, 24, null, flags.two_sided);
				current_y += 30;
				if (flags.two_sided) {
					Label.call(GUI,x + 10, current_y, 180, 24, "Backface");

					vec3.copy(this.back_color, material.backface_color);
					GUI.Color3(x + 150, current_y, 40, 24, this.back_color);
					GUI.Vector(x + 190, current_y, 180, 24, this.back_color);
					material.backface_color = this.back_color;
					current_y += 30;
				}

				Label.call(GUI,x + 15, current_y, 100, 24, "Normal-Scale");
				material.normalScale = GUI.SliderVal(x + 180, current_y, 180, 24, material.normalScale, 0.0, 5.0);
				current_y += 30;

				Label.call(GUI, x + 10, current_y, 100, 24, "Details Surface");
				var det_modes = [
					"Disabled",
					"User",
					"Cloth",
					"Hair",
					"Metal",
					"Skin",
					"Stains",
					"Stone",
					"Sand",
					"Wood",
					"Leaves",
					"Bark",
					"Grass",
					"Asphalt",
					"Forest",
					"Leather",
					"Concrete",
					"Brick",
					"Gravel"
				];
				var det_surf = material.detailSurfaceType + 1;
				var det_index = GUI.ComboLine(x + 150, current_y, w - 150, 24, det_surf, det_modes);
				if (det_surf !== det_index)
					material.detailSurfaceType = det_index - 1;
				current_y += 30;

				if (material.detailSurfaceType >= 0) {
					var surfName = det_modes[material.detailSurfaceType + 1];
					var det_types_count = [32, 5, 1, 3, 3, 1, 16, 9, 6, 1, 8, 1, 3, 6, 2, 6, 6, 2];
					var numTypes = det_types_count[material.detailSurfaceType];
					if (numTypes > 0) {
						var sD = "Detail " + surfName + " Type";
						Label.call(GUI, x + 10, current_y, 100, 24, sD);

						var det_types = [];

						for (var i = 0; i < numTypes; i++) {
							var s = surfName + " Type " + i.toString();
							det_types.push(s);
						}
						var det_type = material.detailType;
						var det_t = GUI.ComboLine(x + 150, current_y, w - 150, 24, det_type, det_types);
						if (det_type !== det_t)
							material.detailType = det_t;
						current_y += 30;

						Label.call(GUI, x + 15, current_y, 100, 24, "Detail Dist.");
						material.detailDistance = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.detailDistance, 0.0, 20.0);
						current_y += 30;

						Label.call(GUI, x + 15, current_y, 100, 24, "Detail Tiling");
						material.detailTiling = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.detailTiling, 0.01, 20.0);
						current_y += 30;

						Label.call(GUI, x + 15, current_y, 100, 24, "Detail Strength");
						material.detailStrength = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.detailStrength, 0.01, 10.0);
						current_y += 30;
					}
				}

				Label.call(GUI,x + 10, current_y, 180, 24, "Depth test");
				flags.depth_test = GUI.Toggle(x + 180, current_y, 24, 24, null, flags.depth_test);
				current_y += 30;

				Label.call(GUI,x + 10, current_y, 180, 24, "Depth write");
				flags.depth_write = GUI.Toggle(x + 180, current_y, 24, 24, null, flags.depth_write);
				current_y += 30;

				Label.call(GUI,x + 10, current_y, 180, 24, "HDR Lightmap");
				material.hdrLightmap = GUI.Toggle(x + 180, current_y, 24, 24, null, material.hdrLightmap);
				current_y += 30;

				Label.call(GUI,x + 10, current_y, 180, 24, "Dis. SSAO");
				material.disableSSAO = GUI.Toggle(x + 180, current_y, 24, 24, null, material.disableSSAO);
				current_y += 30;

				Label.call(GUI,x + 10, current_y, 180, 24, "Sun Affect");
				material.sunAffected = GUI.Toggle(x + 180, current_y, 24, 24, null, material.sunAffected);
				current_y += 30;

				Label.call(GUI, x + 10, current_y, 180, 24, "Local-Lights Affect");
				material.dynLightsAffected = GUI.Toggle(x + 180, current_y, 24, 24, null, material.dynLightsAffected);
				current_y += 30;
			}
			else
				current_y += 40;

			if (editor.drawCollapsableLabel("nativereflrefr", "Reflections / Refractions", x + 10, current_y, w)) {
				current_y += 40;

				var refl_modes = [
					"No Reflections",
					"Medium Reflections",
					"High Reflections"
				];
				Label.call(GUI,x + 10, current_y, 180, 24, "Reflections:");
				var refl_old_index = flags.reflective;
				var refl_index = GUI.ComboLine(x + 120, current_y, w - 150, 24, refl_old_index, refl_modes, "refl_modes");
				if (refl_old_index !== refl_index)
					flags.reflective = refl_index;
				current_y += 30;

				Label.call(GUI,x + 15, current_y, 100, 24, "Refraction");
				var refr_types = [
					"None",
					"Thin",
					"Solid Sphere",
					"Solid Box"
				];
				var rfindex = material.refractionMode;
				material.refractionMode = GUI.ComboLine(x + 120, current_y, w - 150, 24, rfindex, refr_types, "refr_types");
				current_y += 40;

				if (material.refractionMode) {
					if (editor.drawCollapsableLabel("nativerefr", "Refraction Options", x + 10, current_y, w)) {
						current_y += 40;

						Label.call(GUI,x + 15, current_y, 100, 24, "IOR");
						material.refractionIOR = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.refractionIOR, 0.2, 3.0);
						current_y += 30;

						Label.call(GUI,x + 15, current_y, 100, 24, "Distance");
						material.refractionDistance = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.refractionDistance, 0.0, 5.0);
						current_y += 30;

						Label.call(GUI,x + 15, current_y, 100, 24, "Roughness");
						material.refractionRoughness = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.refractionRoughness, 0.0, 1.0);
						current_y += 30;

						Label.call(GUI,x + 15, current_y, 100, 24, "Transmission");
						material.refractionTransmission = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.refractionTransmission, 0.0, 1.0);
						current_y += 30;

						if (material.refractionMode > 1) {
							Label.call(GUI,x + 15, current_y, 100, 24, "Thickness");
							material.thickness = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.thickness, 0.0, 1.0);
							current_y += 30;
						}
						else {
							Label.call(GUI,x + 15, current_y, 100, 24, "Thickness");
							material.microThickness = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.microThickness, 0.0, 1.0);
							current_y += 30;
						}
						Label.call(GUI,x + 15, current_y, 100, 24, "Tint");
						material.refractionTint = GUI.SliderVal(x + 120, current_y, w - 140, 24, material.refractionTint, 0.0, 1.0);
						current_y += 30;

						var refractionColor = material.refractionColor;
						Label.call(GUI,x + 15, current_y, 100, 24, "Color");
						GUI.Color3(x + 120, current_y, 40, 24, refractionColor);
						GUI.Vector(x + 160, current_y, 220, 24, refractionColor);
						material.refractionColor = refractionColor;
						current_y += 40;
					}
					else
						current_y += 40;
				}
			}
			else
				current_y += 40;


			if (editor.drawCollapsableLabel("nativeshadem", "Shading Models", x + 10, current_y, w)) {
				current_y += 40;

				Label.call(GUI, x + 10, current_y, 180, 24, "Blender-AO");
				material.blenderOcclusionMap = GUI.Toggle(x + 180, current_y, 24, 24, null, material.blenderOcclusionMap);
				current_y += 30;

				Label.call(GUI, x + 10, current_y, 180, 24, "Spec-AA");
				material.specularAA = GUI.Toggle(x + 180, current_y, 24, 24, null, material.specularAA);
				current_y += 30;
				if (material.specularAA) {
					Label.call(GUI, x + 10, current_y, 120, 24, "AA-Variance");
					material.specularAAVariance = GUI.SliderVal(x + 180, current_y, 180, 30, material.specularAAVariance, 0.0, 1.0);
					current_y += 30;

					Label.call(GUI, x + 10, current_y, 120, 24, "AA-Threshold");
					material.specularAAThreshold = GUI.SliderVal(x + 180, current_y, 180, 30, material.specularAAThreshold, 0.0, 1.0);
					current_y += 30;
				}

				var hasRefraction = material.refractionMode > 0;

				if (shade_index > eSM_NoPbr) {
					if (shade_index != eSM_FogVolume && shade_index != eSM_NeRFVolume && shade_index != eSM_WaterOcean && shade_index != eSM_WaterVolume) {

						if (shade_index != eSM_PbrGlossiness) {
							Label.call(GUI,x + 10, current_y, 120, 24, "Roughness");
							material.roughnessFactor = GUI.SliderVal(x + 180, current_y, 180, 30, material.roughnessFactor, 0.0, 1.0);
							current_y += 30;
						}

						if (shade_index == eSM_PbrGlossiness) {
							Label.call(GUI,x + 10, current_y, 120, 24, "Glossiness");
							material.reflectance = GUI.SliderVal(x + 180, current_y, 180, 30, material.reflectance, 0.0, 1.0);
							current_y += 30;
						}


						if (shade_index != eSM_Cloth && shade_index != eSM_PbrGlossiness) {
							if (!hasRefraction) {
								Label.call(GUI,x + 10, current_y, 120, 24, "Metalness");
								material.metallicFactor = GUI.SliderVal(x + 180, current_y, 180, 30, material.metallicFactor, 0.0, 1.0);
								current_y += 30;

								var hasReflectance = material.reflectance != 1.0;
								var prevReflectance = hasReflectance;
								Label.call(GUI,x + 10, current_y, 180, 24, "Cust-reflectance");
								hasReflectance = GUI.Toggle(x + 180, current_y, 24, 24, null, hasReflectance);
								if (hasReflectance != prevReflectance) {
									if (hasReflectance)
										material.reflectance = 0.5;
									else
										material.reflectance = 1.0;
								}
								current_y += 30;
								if (hasReflectance) {
									Label.call(GUI,x + 10, current_y, 150, 24, "Reflectivity");
									material.reflectance = GUI.SliderVal(x + 180, current_y, 180, 30, material.reflectance, 0.0, 0.99);
									current_y += 30;
								}
							}
						}

						if (shade_index != eSM_Subsurface) {
							Label.call(GUI,x + 10, current_y, 180, 24, "Clear-Coat layer");
							material.clearCoatMode = GUI.Toggle(x + 180, current_y, 24, 24, null, material.clearCoatMode);
							current_y += 30;

							if (shade_index != eSM_Cloth) {
								Label.call(GUI,x + 10, current_y, 180, 24, "Sheen layer");
								material.sheenMode = GUI.Toggle(x + 180, current_y, 24, 24, null, material.sheenMode);
								current_y += 30;
							}

							if (shade_index == eSM_Cloth) {
								vec3.copy(this.sheen_color, material.sheenColor);
								Label.call(GUI,x + 10, current_y, 100, 24, "Sheen color");
								GUI.Color3(x + 150, current_y, 40, 24, this.sheen_color);
								GUI.Vector(x + 190, current_y, 180, 24, this.sheen_color);
								material.sheenColor = this.sheen_color;
								current_y += 30;

								vec3.copy(this.subsurface_color, material.subsurfaceColor);
								Label.call(GUI,x + 10, current_y, 100, 24, "Subsurf. color");
								GUI.Color3(x + 150, current_y, 40, 24, this.subsurface_color);
								GUI.Vector(x + 190, current_y, 180, 24, this.subsurface_color);
								material.subsurfaceColor = this.subsurface_color;
								current_y += 30;
							}

							if (material.sheenMode) {
								if (shade_index != eSM_Cloth) {
									vec3.copy(this.sheen_color, material.sheenColor);
									Label.call(GUI,x + 10, current_y, 100, 24, "Sheen color");
									GUI.Color3(x + 150, current_y, 40, 24, this.sheen_color);
									GUI.Vector(x + 190, current_y, 180, 24, this.sheen_color);
									material.sheenColor = this.sheen_color;
									current_y += 30;
								}
								Label.call(GUI,x + 10, current_y, 120, 24, "Sheen roughness");
								material.sheenRoughness = GUI.SliderVal(x + 180, current_y, 180, 30, material.sheenRoughness, 0.0, 1.0);
								current_y += 30;
							}
							if (material.clearCoatMode) {
								Label.call(GUI,x + 10, current_y, 120, 24, "Clear-Coat");
								material.clearCoat = GUI.SliderVal(x + 180, current_y, 180, 30, material.clearCoat, 0.0, 1.0);
								current_y += 30;

								Label.call(GUI,x + 10, current_y, 120, 24, "Clear-Coat rough.");
								material.clearCoatRoughness = GUI.SliderVal(x + 180, current_y, 180, 30, material.clearCoatRoughness, 0.0, 1.0);
								current_y += 30;
							}
						}
					}
				}
			}
			else
				current_y += 40;

			material.flags = flags;

			var shade_index = material.shadingModel;

			if (editor.drawCollapsableLabel("nativeaniso", "Anisotropy / POM", x + 10, current_y, w)) {
				current_y += 40;

				if (shade_index > eSM_NoPbr) {
					if (shade_index != eSM_FogVolume && shade_index != eSM_NeRFVolume && shade_index != eSM_WaterOcean && shade_index != eSM_WaterVolume) {

						var hasAnisotropy = material.anisotropy != 0;
						var prevAnisotropy = hasAnisotropy;
						Label.call(GUI,x + 10, current_y, 180, 24, "Anisotropic");
						hasAnisotropy = GUI.Toggle(x + 180, current_y, 24, 24, null, hasAnisotropy);
						current_y += 30;
						if (hasAnisotropy != prevAnisotropy) {
							if (hasAnisotropy)
								material.anisotropy = -0.1;
							else
								material.anisotropy = 0.0;
						}
						Label.call(GUI,x + 10, current_y, 120, 24, "Anisotropy");
						material.anisotropy = GUI.SliderVal(x + 180, current_y, 180, 30, material.anisotropy, -1.0, 1.0);
						current_y += 30;

						//vec3.copy(this.anisotropy_direction, material.anisotropyDirection);
						//Label.call(GUI,x + 10, current_y, 100, 24, "Anis. Dir.");
						//GUI.Vector(x + 120, current_y, 180, 24, this.anisotropy_direction);
						//material.anisotropyDirection = this.anisotropy_direction;
						//current_y += 30;

						if (shade_index == eSM_Subsurface) {
							Label.call(GUI,x + 10, current_y, 120, 24, "Thickness");
							material.thickness = GUI.SliderVal(x + 220, current_y, 180, 30, material.thickness, 0.0, 1.0);
							current_y += 30;

							Label.call(GUI,x + 10, current_y, 120, 24, "Subsurf. power");
							material.subsurfacePower = GUI.SliderVal(x + 220, current_y, 180, 30, material.subsurfacePower, 1.0, 24.0);
							current_y += 30;

							vec3.copy(this.subsurface_color, material.subsurfaceColor);
							Label.call(GUI,x + 10, current_y, 100, 24, "Subsurf. color");
							GUI.Color3(x + 150, current_y, 40, 24, this.subsurface_color);
							GUI.Vector(x + 190, current_y, 180, 24, this.subsurface_color);
							material.subsurfaceColor = this.subsurface_color;
							current_y += 30;
						}


						Label.call(GUI,x + 10, current_y, 180, 24, "POM");
						material.POM = GUI.Toggle(x + 180, current_y, 24, 24, null, material.POM);
						current_y += 30;

						if (material.POM) {
							var pom_modes = [
								"Custom",
								"Rocks",
								"Stone",
								"Boulders",
								"Clay-Water",
								"Dirt-1",
								"Dirt-2",
								"Dirt-Gravel",
								"Dirt-Rocks",
								"Grass-Rocks",
								"Gravel",
								"Road-Cut",
								"Rocks-1",
								"Rocks-2",
								"Rocks-3",
								"Rocks-4",
								"Dirt-3",
								"Boulders-3",
								"Boulders-4",
								"Bricks",
								"Stone-Wall",
								"Cobble-Stones",
								"Metal-Sheet"
							];
							Label.call(GUI,x + 10, current_y, 180, 24, "POM preset:");
							var pom_old_index = material.POMPreset;
							var pom_index = GUI.ComboLine(x + 120, current_y, w - 150, 24, pom_old_index, pom_modes, "pom_modes");
							if (pom_old_index !== pom_index)
								material.POMPreset = pom_index;
							current_y += 30;

							Label.call(GUI,x + 20, current_y, 100, 24, "POM displ.");
							material.POMDisplacement = GUI.SliderVal(x + 150, current_y, w - 180, 24, material.POMDisplacement, 0.0, 1.1);
							current_y += 30;

							Label.call(GUI,x + 20, current_y, 100, 24, "POM bias");
							material.POMBias = GUI.SliderVal(x + 150, current_y, w - 180, 24, material.POMBias, -2.0, 2.0);
							current_y += 30;

							Label.call(GUI,x + 20, current_y, 100, 24, "POM SelfShad.");
							material.POMSelfShadow = GUI.SliderVal(x + 150, current_y, w - 180, 24, material.POMSelfShadow, 0.0, 1.0);
							current_y += 40;
						}
					}
				}
			}
			else
				current_y += 40;
		}

		return current_y;
	},

	renderUVMatrix: function(material, x, y, w )
	{
		Label.call(GUI, x + 20, y, 140, 24, "UV Transform");
		var transform = material.uv_transform;
		var srcTransform = mat3.create();
		mat3.copy(srcTransform, transform);

		srcTransform[0] = GUI.Number(x + 160, y, 40, 24, srcTransform[0]);
		srcTransform[4] = GUI.Number(x + 210, y, 40, 24, srcTransform[4]);
		srcTransform[6] = GUI.Number(x + 260, y, 40, 24, srcTransform[6]);
		srcTransform[7] = GUI.Number(x + 310, y, 40, 24, srcTransform[7]);
		y += 30;

		material.uv_transform = srcTransform;
		return y;
	},

	renderChannels: function(material, x, y, w, h)
	{
		var current_y = y;
		var editor = this.editor;

		current_y = this.renderUVMatrix(material,x,current_y,w);

		Label.call(GUI, x + 10, current_y, 120, 24, "Channel" );
		var index = GUI.ComboLine( x + 120, current_y, 220, 20, this.material_property_index, this.material_property_names );
		this.material_property_index = index;

		GUI.value_changed_from_reset = false;
		current_y += 30;

		var selected_prop = this.material_properties[ this.material_property_index ];
		this.mat_property = this.material_property_names[ this.material_property_index ];
		if (selected_prop)
		{
			var prop_name = selected_prop.prop_name || selected_prop.name;
			var prop = this.matProperty(material, prop_name, selected_prop.type);
			if (prop != null)
			{
				if (selected_prop.type === "color" )
				{
					vec3.copy(this.prop_color, prop);

					Label.call(GUI, x + 10, current_y, 100, 24, "Color" );
					GUI.Color3(x + 120, current_y, 40, 24, this.prop_color || [ 1,1,1 ] );
					GUI.Vector(x + 160, current_y, 220, 24, this.prop_color || [ 1,1,1 ] );

					this.setMatProperty(material, this.prop_color, prop_name, selected_prop.type);
				}
				else if (selected_prop.type === "number" )
				{
					Label.call(GUI,x + 10, current_y, 100, 24, "Factor");
					prop = GUI.Slider(x + 120, current_y, w - 140, 24, prop, selected_prop.min || 0, selected_prop.max || 2, 0.02, selected_prop.marker != null ? selected_prop.marker : 1);

					this.setMatProperty(material, prop, prop_name, selected_prop.type);
				}
				//this.setMatProperty(material, prop, prop_name, selected_prop.type);
			}
			else
			{
				if ( Button.call(GUI, x + 10, current_y, 240,24, "Create" ) )
				{
					GUI.value_changed_from_reset = true;
					if (selected_prop.type === "color" )
						this.setMatProperty(material, [ 1, 1, 1, 1 ], prop_name, "color");
					else
						this.setMatProperty(material, 1, prop_name, "number");
				}
			}

			current_y += 40;

			var tex = this.matTextureName(material, selected_prop.texture || this.mat_property);
			var tex_name = tex ? tex.texture || tex : "";

			Label.call(GUI, x + 10, current_y, 100, 24, "Texture" );
			tex_name = GUI.TextField( x + 110, current_y, w - 150, 24, tex_name ) || "";
			if ( Button.call(GUI, x + w - 34, current_y, 24, 24, [ 2,0 ] ) )
			{
				editor.selectFile((function(file) {
					material.textures[this.propname] = file ? file.localpath : null;
					if (file)
						editor.view.loadTexture( file.localpath );
				}).bind({ propname:this.mat_property }),[ "png","jpg" ]);
			}
			current_y += 40;

			if (tex) //has texture
			{
				//uvs being used
				if (material.native)
				{
					Label.call(GUI, x + 20, current_y, 140, 24, "UV Channel");
					var uv_channel = material.getUVChannel( this.mat_property ) || 0;
					var new_channel = GUI.ComboLine( x + 120, current_y, 70, 24, uv_channel, ["0","1","2"] );
					if(material.setUVChannel) //feature missing
						material.setUVChannel(this.mat_property,new_channel);

					var p = this.mat_property;
					if (p == "albedo" || p == "normal" || p == "emissive" || p == "metallicRoughness")
					{
						var tiling = material.getUVTiling(p);
						var srcTiling = vec2.create();
						vec2.copy(srcTiling, tiling);

						Label.call(GUI, x + 190, current_y, 140, 24, "Tiling");
						GUI.Vector(x + 260, current_y, 100, 24, srcTiling);
						material.setUVTiling(p, srcTiling);
					}

					current_y += 30;
				}
				else
				{
					var new_channel = GUI.ComboLine( x + 120, tex_y, 80, 24, tex.uv_channel || 0, ["0","1"] );
					if (tex.constructor === String)
						tex = material.textures[ selected_prop.texture || this.mat_property ] = { texture: tex, uv_channel: new_channel };
					else
						tex.uv_channel = new_channel;
				}

				var texsize = 256;
				var tex_y = current_y + texsize + 10;
				var texture = this.matTextureObj(material, selected_prop.texture || this.mat_property, tex_name);
				if (tex_name && texture)
				{
					gl.drawImage(texture, x + 20, current_y, texsize, texsize);

					//tex size
					Label.call(GUI, x + 20, tex_y, texsize, 24, texture.width + "x" + texture.height );
				}

				GUI.next_tooltip = "Clear texture";
				if ( Button.call(GUI, x + 20 + texsize - 30, current_y + 10, 24, 24, [ 4,2 ] ) )
				{
					editor.saveUndo( { type:"material", material: material.serialize() } );
					material.textures[ selected_prop.texture || this.mat_property ] = null;
					GUI.value_changed_from_reset = true;
				}

				GUI.next_tooltip = "Copy to clipboard";
				if ( Button.call(GUI, x + 30 + texsize, current_y, 34, 34, [ 5,2 ] ) )
					this.clipboard = tex_name;
				GUI.next_tooltip = "Paste from clipboard";
				if ( Button.call(GUI, x + 30 + texsize, current_y + 40, 34, 34, [ 6,2 ] ) )
				{
					material.textures[ selected_prop.texture || this.mat_property ] = this.clipboard;
					GUI.value_changed_from_reset = true;
				}


				GUI.next_tooltip = "Download texture";
				if ( Button.call(GUI, x + 30 + texsize, current_y + 80, 34, 34, [ 6,0 ] ) )
				{
					var texture = gl.textures[ tex_name ];
					if (texture)
					{
						var blob = texture.toBlob();
						ROOM.downloadFile("image.png",blob);
					}
				}

				GUI.next_tooltip = "View Texture";
				if ( Button.call(GUI, x + 30 + texsize, current_y + 120, 34, 34, [ 8,7 ] ) )
				{
					if(texture)
						editor.selected_texture = texture;
					else if(tex_name)
						editor.selected_texture = gl.textures[ tex_name ];
				}

			}
			else //no texture
			{
				GUI.next_tooltip = "Paste from clipboard";
				if ( Button.call(GUI, x + 30, current_y + 10, 30, 30, [ 6,2 ] ) )
				{
					material.textures[ selected_prop.texture || this.mat_property ] = this.clipboard;
					GUI.value_changed_from_reset = true;
				}

			}
		}

		return current_y;
	},

	onDragMaterial: function( e , info )
	{
		var locator = null;
		if (info.target.getLocator)
			locator = info.target.getLocator();
		else if(info.target.name)
			locator = info.target.name; //native material
		else
		{
			console.warn("no locator");
			return;
		}

		if (!locator)
			return;

		e.dataTransfer.setData("type","material");
		e.dataTransfer.setData("locator",locator);
	}
};

export default MaterialEditorPanel;
