//not used very much
export const ROOM_TYPES = {
	NULL: 0,
	PREFAB: 1,
	SEAT: 2,
	SURFACE: 3,
	CAMERA: 4,
	SOUND: 5,
	MEDIA: 6,
	EXIT: 10,
	GAME: 11,
	REFERENCE: 12,
	COMPONENT: 100
};