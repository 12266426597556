import { DEG2RAD } from "@src/constants";
import { RoomCall } from "@src/controllers/call";
import { BubblesManager } from "@src/controllers/components/bubblesManager";
import BaseComponent from "@src/engine/components/baseComponent";
import { CameraBrain } from "@src/engine/components/Camera/intelligent/CameraBrain";
import { CameraState } from "@src/engine/components/Camera/intelligent/CameraState";
import { LookAtConstraint } from "@src/engine/components/Camera/intelligent/constraints/LookAtConstraint";
import { PathPositionConstraint } from "@src/engine/components/Camera/intelligent/constraints/PathPositionConstraint";
import { FovConstraint } from "@src/engine/components/Camera/intelligent/constraints/FovConstraint";
import { findNearestCameraTrack } from "@src/engine/components/findNearestCameraTrack";
import { Tween } from "@src/engine/helpers/tween";
import { RoomParticipant } from "@src/engine/Participant/RoomParticipant";
import ROOM from "@src/engine/room";
import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { RoomSpace } from "@src/engine/RoomSpace";
import { mat4GetTranslation } from "@src/gl-matrix/mat4";
import { GL } from "@src/libs/litegl";
import clamp from "@src/math/clamp";
import XYZLauncher from "@src/XYZLauncher";
import { getTime } from "@utils/time/getTime";
import { mat4, vec3 } from "gl-matrix";
import lerp from "lerp";
import cameraToState from "../helpers/cameraToState";
import stateToCamera from "../helpers/stateToCamera";
import broadcast from "@src/controllers/broadcast";
class TravelingTrack extends BaseComponent {
    constructor() {
        super(...arguments);
        this.startAnimation = false;
        this.stopMomentum = false;
        this.enabled = true;
        this.max_radius = 3;
        this.speed = 1;
        this.min_fov = 30;
        this.max_fov = 50;
        this.num_seats = 0;
        this.separation = 0;
        this.frontal_factor = 0;
        this.view_height_offset = 0;
        this.near_plane = 0.01;
        this.enable_DOF = false;
        this._active = false;
        //distance to center
        this._distance = 1;
        //0 zoomest, 1 farthest
        this._camera_factor = 0.5;
        this._vertical_offset = 0;
        this._circle_points = null;
        this._dist_to_center = 1;
        this._height = 0;
        //0 is zoom, 1 is wide, 2 is behind
        this._state = 1;
        /**
         * This is essentially X velocity value for movement along the track
         */
        this._momentum = 0;
        this.tracking_participant = null;
        this.cancelAnimation = false;
        this.finishedIntroAnimation = false;
        this.animationStartTime = null;
        this.animationTime = null;
        this.animationStartAngle = null;
        //range
        this._smooth_camera_factor = this._camera_factor;
        //Camera director track
        this.cameraBrain = null;
    }
    get seat_points() {
        return this._seat_points;
    }
    set seat_points(value) {
        this._seat_points = value;
    }
    serialize(o = {}) {
        o.enabled = this.enabled;
        o.speed = this.speed;
        o.max_radius = this.max_radius;
        o.min_fov = this.min_fov;
        o.max_fov = this.max_fov;
        o.num_seats = this.num_seats;
        o.separation = this.separation;
        o.frontal_factor = this.frontal_factor;
        o.view_height_offset = this.view_height_offset;
        o.near_plane = this.near_plane;
        o.enable_DOF = this.enable_DOF;
        return o;
    }
    configure(o) {
        this.cameraBrain = new CameraBrain();
        this.enabled = o.enabled;
        this.speed = o.speed;
        this.max_radius = o.max_radius || 3;
        if (o.min_fov !== undefined) {
            this.min_fov = o.min_fov;
            this.max_fov = o.max_fov;
        }
        else // if( o.default_fov )
         {
            this.min_fov = 10;
            this.max_fov = 40;
        }
        this.num_seats = o.num_seats || 0;
        this.separation = o.separation || 0;
        this.frontal_factor = o.frontal_factor || 0;
        this.view_height_offset = o.view_height_offset || 0;
        this.near_plane = o.near_plane || 0.01;
        this.enable_DOF = o.enable_DOF !== false;
        // reset values to default
        this.animationStartTime = null;
        this.animationTime = null;
        this.animationStartAngle = null;
        this.cancelAnimation = false;
        this.finishedIntroAnimation = false;
    }
    getCenter() {
        const m = this.entity.node.getGlobalMatrix();
        const center = mat4GetTranslation(vec3.create(), m);
        center[1] += TravelingTrack.view_height + this.view_height_offset;
        return center;
    }
    getParticipantPosition() {
        var _a;
        const participant = (_a = this.tracking_participant) !== null && _a !== void 0 ? _a : this.entity.space.local_participant;
        return [
            participant.position[0],
            participant.position[1] + TravelingTrack.view_height + this.view_height_offset + 1,
            participant.position[2]
        ];
    }
    addConstraint(constraint) {
        this.cameraBrain.addConstraint(constraint);
    }
    travelingTrackToConstraints() {
        var _a;
        const center = this.getCenter();
        let radius = this.max_radius;
        if (this.separation)
            radius += 1;
        const lerpFactor = ((_a = XYZLauncher.instance) === null || _a === void 0 ? void 0 : _a.mobile) ? 0.5 : 0.9;
        return [
            PathPositionConstraint.makeRing(radius, 0.1, [0, 1, 0], center),
            LookAtConstraint.makeLookAt(center),
            this.fovConstraint = new FovConstraint(this.min_fov, this.max_fov, this._smooth_camera_factor, lerpFactor)
        ];
    }
    resetConstraints() {
        for (let i = this.cameraBrain.constraints.length - 1; i >= 0; i--) {
            this.cameraBrain.removeConstraint(this.cameraBrain.constraints[i]);
        }
    }
    onAdded(parent) {
        const space = parent.space;
        if (!space._camera_tracks)
            space._camera_tracks = [];
        space._camera_tracks.push(this);
    }
    onRemoved(parent) {
        const space = parent.space;
        if (!space._camera_tracks)
            return;
        const index = space._camera_tracks.indexOf(this);
        if (index !== -1)
            space._camera_tracks.splice(index, 1);
    }
    onEnter(controller) {
        var _a;
        this._active = true;
        const participant = (_a = this.tracking_participant) !== null && _a !== void 0 ? _a : controller.space.local_participant;
        if (participant.seat && participant.seat.walking_start)
            return false;
        this.enterTime = getTime();
        this._momentum = 0;
        this.stopMomentum = false;
        //compute distance to participant
        const center = this.entity.node.getGlobalPosition();
        const pos = vec3.clone(participant.position);
        center[1] = pos[1] = 0;
        this._dist_to_center = vec3.distance(pos, center);
        //if no participant/tutorial
        if (participant.position[0] === 0 && participant.position[2] === 0) {
            this._distance = 1;
        }
        else {
            this._distance = vec3.distance(pos, center); //and store it
            this._distance = Math.min(this._distance, this.max_radius);
        }
        //set up stuff
        this._vertical_offset = 0;
        const call = controller;
        call.view.hard_camera.fov = lerp(this.min_fov, this.max_fov, 0.5);
        participant.force_self_render = true;
        call.camera_mode = "on_tracks";
        call.bubbles_manager.mode = BubblesManager.TOP_MODE;
        RoomParticipant.two_sided = false;
        RoomParticipant.face_forward_of_seat = true;
        //controller.view.smooth_camera = false;
        var xyz = XYZLauncher.instance;
        if (xyz.nativeEngine && xyz.nativeEngine._engine)
            xyz.nativeEngine._engine.setTrackMode(true);
        // Andrey FIXME: Not sure what the puppose of ROOM_SETTINGS.call.DOF.enabled here, it is undefined!
        //if (ROOM_SETTINGS.call.DOF.enabled && this.enable_DOF)
        call.view.setDOF(true, 1);
        if (!this.num_seats)
            console.warn("Error in TravelingTrack: num seats is zero.");
        return true;
    }
    onLeave(controller) {
        var _a;
        this._active = false;
        const participant = (_a = this.tracking_participant) !== null && _a !== void 0 ? _a : controller.space.local_participant;
        const call = controller;
        call.camera_mode = "seat";
        participant.force_self_render = false;
        call.bubbles_manager.smooth_transitions = true;
        call.bubbles_manager.mode = BubblesManager.AUTO_MODE;
        RoomParticipant.two_sided = true;
        RoomParticipant.face_forward_of_seat = false;
        //controller.view.smooth_camera = true;
        var xyz = XYZLauncher.instance;
        if (xyz.nativeEngine && xyz.nativeEngine._engine)
            xyz.nativeEngine._engine.setTrackMode(false);
        // Andrey FIXME: Not sure what the puppose of ROOM_SETTINGS.call.DOF.enabled here, it is undefined!
        /*if (ROOM_SETTINGS.call.DOF.enabled) */
        call.view.setDOF(false, 1);
    }
    onKeyDown(e) {
        const view = ROOM.view;
        const delta = this.num_seats ? 360 / this.num_seats : 0;
        switch (e.code) {
            case "KeyA":
            case "ArrowLeft":
                view.hard_camera.orbit(DEG2RAD * -delta, [0, 1, 0]);
                break;
            case "KeyD":
            case "ArrowRight":
                view.hard_camera.orbit(DEG2RAD * delta, [0, 1, 0]);
                break;
            case "KeyW":
            case "ArrowUp":
                this._camera_factor *= 1.25;
                this._camera_factor = clamp(this._camera_factor, 0.1, 1);
                break;
            case "KeyS":
            case "ArrowDown":
                this._camera_factor /= 1.25;
                this._camera_factor = clamp(this._camera_factor, 0.1, 1);
                break;
        }
        return false;
    }
    onMouse(e, view) {
        var _a;
        const participant = (_a = this.tracking_participant) !== null && _a !== void 0 ? _a : this.entity.space.local_participant;
        if (!participant)
            return;
        const event_as_any = e;
        const currently_dragging = event_as_any.dragging;
        this.stopMomentum = currently_dragging;
        if (e.type === "mousemove" && currently_dragging) {
            // actively dragging the pointer across the viewport
            this.cancelAnimation = true;
            const speed_scale = this._dist_to_center * 0.005;
            // add momentum proportional to input
            this._momentum += event_as_any.deltax * speed_scale * this.speed;
        }
        else if (e.type === "wheel") {
            if (event_as_any.is_touch || event_as_any.trackpadevent) {
                this._camera_factor = event_as_any.pinchScale;
            } // mouse wheel
            else {
                const direction = event_as_any.deltaY > 0 ? -1 : 1;
                const scalingAmount = -0.05;
                const offset = 1 + direction * scalingAmount;
                this._camera_factor /= offset;
                this._camera_factor = clamp(this._camera_factor, 0.1, 1);
            }
            this.fovConstraint.updateFactor(this._camera_factor);
        }
    }
    //called from call.render
    preRender(view) {
        if (!this._active)
            return;
        const camera = view.hard_camera;
        const cam_pos = camera.position;
        const cam_front = camera.getFront();
        const space = this.entity.space;
        let max_dist = 0;
        let min_dist = 100000;
        const gl = GL.ctx;
        for (let i = 0; i < space.participants.length; ++i) {
            const participant = space.participants[i];
            //only the ones at the same table as me
            if (participant._track_entity !== space.local_participant._track_entity)
                continue;
            const front = participant.getProfileFocusNode().getGlobalVector([0, 0, -1]);
            vec3.normalize(front, front); //in case it has scale
            if (vec3.dot(cam_front, front) < 0)
                continue;
            //participant.yaw = 0;
            participant.resetYaw();
            if (participant.screen_position[2] < 1 &&
                participant.screen_position[0] > 0 &&
                participant.screen_position[0] < gl.canvas.width &&
                participant.screen_position[1] > 0 &&
                participant.screen_position[1] < gl.canvas.height) {
                //in front
                const user_pos = participant.getProfilePosition();
                const d = vec3.distance(cam_pos, user_pos);
                if (min_dist > d)
                    min_dist = d;
                if (max_dist < d)
                    max_dist = d;
            }
        }
        const focal_dist = (max_dist - min_dist) * 0.5 + min_dist;
        if (max_dist === 0 || !this.enable_DOF || !ROOM_SETTINGS.call.DOF.allow)
            view.setDOF(false, focal_dist);
        else if (ROOM_SETTINGS.call.DOF.allow && this.enable_DOF)
            view.setDOF(true, focal_dist);
    }
    fixedUpdate(timeDeltaMilliseconds, camera) {
        var _a;
        const timeDeltaSeconds = timeDeltaMilliseconds * 1e-3;
        (_a = this.cameraBrain) === null || _a === void 0 ? void 0 : _a.tick(timeDeltaSeconds * 2);
        camera === null || camera === void 0 ? void 0 : camera.moveLocal([this._momentum * 0.1, 0, 0]);
        const settings = ROOM_SETTINGS.call.table_mode;
        // Apply deceleration
        this._momentum *= settings.momentum_factor;
        if (this.stopMomentum) {
            this._momentum *= settings.brake_factor;
        }
    }
    moveCameraToParticipant(participant, time) {
        if (participant)
            this.tracking_participant = participant;
        if (time)
            this.animationTime = time;
        this.startAnimation = true;
        this.cancelAnimation = false;
    }
    //called from call.updateCamera
    updateCamera(camera, view) {
        var _a;
        //slide animation of startup
        this.startUpAnimation(camera);
        const state_initial = new CameraState();
        const state_final = new CameraState();
        const max_steps = ((_a = XYZLauncher.instance) === null || _a === void 0 ? void 0 : _a.mobile) ? 2 : 8;
        cameraToState(state_initial, camera);
        this.cameraBrain.solve(state_final, state_initial, max_steps);
        stateToCamera(camera, state_final);
        //TODO move shift
        view.camera.shift = camera.shift;
        camera.up = [0, 1, 0];
        //adapt near plane
        camera.near = Math.max(0.001, this.near_plane);
    }
    startUpAnimation(camera) {
        var _a, _b, _c;
        if (XYZLauncher.instance.active_controller instanceof broadcast)
            return;
        const center = this.getCenter();
        const participant = (_a = this.tracking_participant) !== null && _a !== void 0 ? _a : this.entity.space.local_participant;
        if (participant) {
            participant.force_self_render_one_frame = true;
        }
        const timeReady = (_c = (_b = RoomCall.instance) === null || _b === void 0 ? void 0 : _b.timeFromReady) !== null && _c !== void 0 ? _c : 0;
        if (timeReady > 0 && !this.finishedIntroAnimation && this.animationStartTime === null) {
            // initialize intro animation parameters
            this.animationStartTime = 0;
            this.animationTime = ROOM_SETTINGS.call.table_mode.intro_slide_time;
            this.animationStartAngle = ROOM_SETTINGS.call.table_mode.intro_angle * DEG2RAD;
        }
        else if (this.startAnimation) {
            this.startAnimation = false;
            this.animationStartTime = timeReady;
            const profilePosition = participant.getProfilePosition();
            const targetDirection = vec3.sub(vec3.create(), center, profilePosition);
            vec3.normalize(targetDirection, targetDirection);
            const current_direction = vec3.sub(vec3.create(), camera.position, center);
            vec3.normalize(current_direction, current_direction);
            this.animationStartAngle = vec3.angle(targetDirection, current_direction);
            if (vec3.dot([0, 1, 0], vec3.cross(vec3.create(), targetDirection, current_direction)) < 0) {
                this.animationStartAngle = -this.animationStartAngle;
            }
        }
        else if (this.animationStartTime !== null) {
            const sinceStart = timeReady - this.animationStartTime;
            if (this.animationTime !== null && sinceStart > 0) {
                camera.moveLocal([this._momentum * 0.1, 0, 0]); //animation smoothness
                participant.resetOrientation();
                let factor = Math.min(sinceStart / this.animationTime, 1);
                factor = Tween.getEaseFactor(factor, Tween.EASE_IN_OUT_QUAD);
                //find vector participant to center
                const participant_position = participant.getProfilePosition();
                const user_to_center = vec3.sub(vec3.create(), center, participant_position);
                //rotate according to time
                camera.position = vec3.add(user_to_center, center, vec3.rotateY(user_to_center, user_to_center, [0, 0, 0], (1 - factor) * this.animationStartAngle));
                if (sinceStart >= this.animationTime || this.cancelAnimation) {
                    this.animationStartTime = null;
                    if (!this.finishedIntroAnimation) {
                        this.finishedIntroAnimation = true;
                        XYZLauncher.instance.notify("ROOM_CINEMATIC_LOAD_DONE", {});
                    }
                    else {
                        XYZLauncher.instance.notify("ROOM_ANIMATION_TO_PARTICIPANT_DONE", {});
                    }
                }
            }
        }
    }
    updatePoints() {
        const radius = 1;
        const num = 90;
        const v = vec3.create();
        if (!this._circle_points) {
            this._circle_points = new Float32Array(3 * num);
        }
        for (let i = 0; i < num; ++i) {
            const d = i / num;
            const offset = this.separation * (i < num / 2 ? 0.5 : -0.5);
            v[0] = Math.cos(d * Math.PI * 2) * radius;
            v[1] = 0;
            v[2] = Math.sin(d * Math.PI * 2) * radius + offset;
            this._circle_points.set(v, i * 3);
        }
        if (this.num_seats && 0) {
            if (!this._seat_points || this._seat_points_num !== this.num_seats) {
                this._seat_points = new Float32Array(3 * this.num_seats);
            }
            this._seat_points_num = this.num_seats;
            for (let i = 0; i < this.num_seats; ++i) {
                const d = i / this.num_seats;
                v[0] = Math.sin(d * Math.PI * 2) * radius;
                v[1] = 0;
                v[2] = Math.cos(d * Math.PI * 2) * radius;
                this._seat_points.set(v, i * 3);
            }
        }
        this._last_separation = this.separation;
    }
    renderGizmo(view, editor, selected = false) {
        //circular mesh
        if (!this._circle_points || this._last_separation !== this.separation)
            this.updatePoints();
        const model = this.entity.node.getGlobalMatrix();
        view.renderer.color = selected ? [3, 3, 2, 1] : [1, 1, 1, 0.7];
        const gl = GL.ctx;
        if (this.num_seats) {
            if (!this._seat_points || this.num_seats !== this._seat_points_num)
                this.updatePoints();
            if (this._seat_points)
                view.renderer.renderPoints(this._seat_points, null, view._last_camera, this._circle_points.length / 3, null, 0.01, gl.POINTS, null, model);
        }
        view.renderer.renderPoints(this._circle_points, null, view._last_camera, this._circle_points.length / 3, null, 1, gl.LINE_LOOP, null, model);
        if (selected) {
            //outer circle
            view.renderer.color = selected ? [3, 2, 2, 1] : [1, 1, 1, 0.7];
            mat4.scale(model, model, [
                this.max_radius,
                this.max_radius,
                this.max_radius,
            ]);
            view.renderer.renderPoints(this._circle_points, null, view._last_camera, this._circle_points.length / 3, null, 1, gl.LINE_LOOP, null, model);
        }
    }
    findTableSeats() {
    }
}
TravelingTrack.componentName = "TravelingTrack";
TravelingTrack.icon = [1, 1];
TravelingTrack["@frontal_factor"] = { widget: "slider", min: 0, max: 1 };
TravelingTrack.view_height = 0.25;
export default TravelingTrack;
//***********************
/**
 * @deprecated use findNearestCameraTrack import instead
 */
RoomSpace.prototype.findNearestCameraTrack = function (pos) {
    return findNearestCameraTrack(this, pos);
};
