/**
 *
 * @param out
 * @param aspect
 * @param fovY in Radians
 * @param shiftX
 * @param shiftY
 * @param near
 * @param far
 */
export function perspectiveProjectionMatrix(out, aspect, fovY, shiftX, shiftY, near, far) {
    const f = 1.0 / Math.tan(fovY / 2);
    out[0] = f / aspect;
    out[1] = 0;
    out[2] = 0;
    out[3] = 0;
    out[4] = 0;
    out[5] = f;
    out[6] = 0;
    out[7] = 0;
    out[8] = shiftX;
    out[9] = shiftY;
    out[11] = -1;
    out[12] = 0;
    out[13] = 0;
    out[15] = 0;
    if (far != null && far !== Infinity) {
        const nf = 1 / (near - far);
        out[10] = (far + near) * nf;
        out[14] = 2 * far * near * nf;
    }
    else {
        out[10] = -1;
        out[14] = -2 * near;
    }
}
