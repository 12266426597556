/**
 * @enum {number}
 */
export const ROOM_LAYERS = {
	STATIC: 1,
	WALLS: 2,
	ITEMS: 4,
	CONTROL: 8,
	BACKGROUND: 16,
	PARTICIPANTS: 32,
	LAYER6: 64,
	LAYER7: 128
};