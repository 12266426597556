//Instantiated as a module from XYZLauncher
//somes actions can be send between participants to handle control over the room
//like moderation
import Entity from "@src/engine/entity";
import RoomMediaStream from "@src/engine/helpers/mediaStream";
import { RoomParticipant } from "@src/engine/Participant/RoomParticipant";
import { getFullPath } from "@src/engine/Room/file-utils";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { XYZRegisterModule } from "@src/xyz/XYZRegisterModule";

function ActionListener(xyz )
{
	this.xyz = xyz;
	xyz.action_listener = this;

	if ( xyz.bridge )
		this.onBridgeAvailable( xyz.bridge );
}

ActionListener.instance = this;

ActionListener.registered_actions = {};

//allows to register actions that could be performed from an event
ActionListener.registerAction = function( action, info )
{
	if (info.constructor === Function)
	{
		info = {
			onAction: info
		};
	}

	this.registered_actions[ action ] = info;
}

ActionListener.prototype.onBridgeAvailable = function(bridge)
{
	if (this.bridge == bridge)
		return;
	this.bridge = bridge;
	LEvent.bind( this.xyz.space, "BROADCAST", this.processBroadcastIN, this );
}

//TODO: this must be moved to sync.js: NetworkSync.registerEventHandler
ActionListener.prototype.processBroadcastIN = function(type, evt)
{
	//if (evt.type == "director")
		this.processEvent( evt );
}

ActionListener.prototype.processEvent = function(evt)
{
	var xyz = this.xyz;
	var space = this.xyz.space;
	var local_participant = space.local_participant;
	var to_me = ( evt.to_participant == null || evt.to_participant == local_participant.id );
	if ( !to_me )
		return;

	if ( evt.action )
	{
		var action_info = ActionListener.registered_actions[ evt.action ];
		if ( action_info && action_info.onAction )
		{
			action_info.onAction(evt,xyz);
			return;
		}
		//else
		//	LEvent.trigger( ActionListener, evt.action, evt );
	}

	if ( evt.action == "set" )
	{
		switch (evt.target)
		{
		case "exposure": space.fx.exposure = evt.value; break;
		case "emissive_factor": space.fx.emissive_factor = evt.value; break;
		case "orientation_mode": RoomParticipant.orientation_mode = evt.value; break;
		case "node":
			var node = space.root.findNodeByName( evt.node_name );
			if (node)
				node.configure( evt.value );
			break;
		}
	}
	else if ( evt.action == "participant" )
	{
		if ( !evt.participant_id || evt.participant_id == local_participant.id )
		{
			if ( evt.participant_id && evt.subaction == "state" )
			{
				//overwrite his state
				local_participant.JSONToState( evt.data );
			}
			else if ( evt.subaction == "message" )
			{
				xyz.call_controller.showMessage( evt.message, parseFloat( evt.duration ) );
			}
			else if ( evt.subaction == "redirect" && evt.url )
			{
				location.href = evt.url;
			}
		}
	}
	else if ( evt.action == "mute" || evt.action == "unmute")
	{
		if ( evt.participant_id == local_participant.id )
		{
			//force mute in client
			if ( evt.action == "mute")
				local_participant.mute();
			else
				local_participant.unmute();
		}
		else if ( evt.seat_name && local_participant.seat && evt.seat_name == local_participant.seat.entity.name )
		{
			//force mute in client
			if ( evt.action == "mute")
				local_participant.mute();
			else
				local_participant.unmute();
		}
		else {
			// force immediate volume update
			var participant;
			if (evt.participant_id)
				participant = space.getParticipant(evt.participant_id);
			else if (evt.seat_name)
				participant = space.getEntity(evt.seat_name).seat.participant;

			if (participant) {
				if ( evt.action == "mute")
					participant._volume = 0;
				else
					participant._volume = RoomMediaStream.default_volume;
			}
		}
	}
	else if ( evt.action == "scene_event" )
	{
		if ( evt.subaction == "marker" ) {
			xyz.call_controller.addMarker(evt.position,evt.marker_type,evt.duration,evt.options);
		}
	}
	else if ( evt.action == "move" )
	{
		if ( evt.participant_id == local_participant.id ) {
			if (evt.x) local_participant.position[0] = evt.x;
			if (evt.y) local_participant.position[1] = evt.y;
			if (evt.z) local_participant.position[2] = evt.z;
		}
	}
	else if ( evt.action == "change_room" )
	{
		//var fullpath = ROOM.getFullPath( evt.room_url );
		xyz.call_controller.transitionToSpace( evt.room_url  );
	}
	else if ( evt.method )
	{
		if (evt.method == "custom")
		{
			console.error("custom code disabled, dangerous");
			//var func = new Function( evt.code );
			//func();
		}
		else if (evt.method === "addRemoteModel")
		{
			if (evt.service === "sketchfab") {
				/*
				this.xyz.call_controller.importer.sketchfab_importer.downloadModel( evt.url, evt.model_name).then((entity) => {
					this.xyz.call_controller.importer.addModelToRoom(entity);
					this.xyz.director_controller.addModel(entity);
				});
				*/
			}
		}

	}
}

XYZRegisterModule( ActionListener, "ActionListener" );

//Actions *****************************************************

//changes seats
ActionListener.registerAction("seat", function( evt, xyz ) {

	var space = xyz.space;

	if ( evt.subaction == "move" ) {
		var next_seat = space.getEntity( evt.to_seat );
		var participant;
		if ( evt.from_seat )
		{
			var from_seat = space.getEntity( evt.from_seat );
			participant = from_seat.seat.participant;
		}
		else if ( evt.participant_id )
			participant = space.getParticipant( evt.participant_id );

		else if ( evt.participant_id == "" )
			participant = space.local_participant;

		participant.exitSeat();
		participant.enterSeat( next_seat );
	}
	if ( evt.subaction == "exit" ) {
		var from_seat = space.getEntity( evt.from_seat );
		var participant = from_seat.seat.participant;
		if ( participant ) participant.exitSeat();
	}
});

//controls media playback
ActionListener.registerAction("media", function( evt, xyz )
{
	var space = xyz.space;

	if ( evt.media_type == "audio" )
	{
		if ( evt.subaction == "play") {
			var fullpath = getFullPath( evt.media_src );
			if (!space._audio || !space._audio.src.includes(fullpath) )
				space.playSound( fullpath, .2 );
			else {
				space._audio.volume = .2;
				space._audio.play();
			}
		}

		else if ( evt.subaction == "pause" && space._audio ) {
			if ( !this.audioVolumeTween
				|| ( this.audioVolumeTween && !this.audioVolumeTween.running ) )
				space._audio.pause();
		}

		else if ( evt.subaction == "stop" && space._audio ) {
			if (space._audio.paused)
				space._audio.currentTime = 0;
			else {
				if (!this.audioVolumeTween || !this.audioVolumeTween.running) {
					this.audioVolumeTween = Tween.easeProperty(
						space._audio, "volume", 0, 1,
						null,
						function () {
							space._audio.pause();
							space._audio.currentTime = 0;
						});
				}
			}
		}

		else if ( evt.subaction == "seek" && space._audio )
		{
			space._audio.currentTime = evt.value;
		}
	}
	else if ( evt.media_type == "image" )
	{
		space.playStream( getFullPath( evt.media_src ) );
	}
	else if ( evt.media_type == "user_stream" )
	{
		var participant = space.getParticipant( evt.participant_id );
		if ( evt.subaction == "play" )
		{
			if (participant)
				participant.showAsGlobalFeed();
			else
			{
				space.playStream(null);
				console.debug("participant not found");
			}
		}
	}
	else if ( evt.media_type == "camera" )
	{
		var ent = space.getEntity( evt.entity_name );
		if ( evt.subaction == "play" )
		{
			if (ent && ent.cameraComponent)
			{
				if (local_participant && !local_participant.stream)
					local_participant.enableLocalStream();
				ent.cameraComponent.toGlobalStream();
			}
		}
	}
	else if ( evt.media_type == "video" )
	{
		var video = space._global_feed ? space._global_feed._feed : null;
		if (video && video.constructor !== HTMLVideoElement)
			video = null;

		if ( evt.subaction == "play" )
		{
			var fullpath = getFullPath( evt.media_src );
			if ( !video || !video.src || !video.src.includes(fullpath)) {
				var options = {};
				if ( evt.volume )
					options.volume = evt.volume;
				if ( evt.loop )
					options.loop = evt.loop;
				if ( evt.current_time )
					options.current_time = evt.current_time;
				if ( evt.paused )
					options.autoplay = !evt.paused;

				space.playStream( fullpath, options);
			} else {
				if ( !evt.paused )
					video.play();
			}
		}
		else if ( evt.subaction == "seek" )
		{
			if (video)
			{
				video.currentTime = evt.value;
			}
		}
		else if ( evt.subaction == "pause" )
		{
			if ( video && video.pause ) {
				video.pause();
			}
		}
		else if ( evt.subaction == "stop" )
		{
			if ( video && video.pause )
			{
				video.pause();
				video.currentTime = 0;
			}
		}
	}
	else
		space.playStream( null );
});

//updates an entity
ActionListener.registerAction("entity", function( evt, xyz )
{
	var space = xyz.space;
	if ( evt.subaction == "add" )
	{
		var ent = new Entity();

		space.addEntity( ent );
		ent.name = evt.name || "entity_" + ent.index;

		if ( evt.component ) {
			var comp = ent.addComponent( evt.component );

			if ( evt.url )
				comp.url = evt.url;

			if ( evt.position )
			{
				ent.position.set( evt.position );
				ent.mustUpdate = true;
			}
		}

		if ( evt.onUpdate )
		{
			//
		}
	}
	if ( evt.subaction == "remove" )
	{
		space.removeEntity( space.getEntity(evt.name) );
	}
});

/*
	Action sent by a new participant to another participant to retrieve the state all surfaces.
	Sends back an object like this to the "surfaces" action:
	{
		action: "surfaces",
		subaction: "get_state_of_surfaces",
		surfaces: [ {
			root_uid: "@ENT-e3277e-246e-1a34292-f",
			app_name: "OS",
			app_state: "some json"
		} ],
		to_participant: "1201",
		type: "director"
	}
*/
ActionListener.registerAction("ping", function( evt, xyz )
{
	var space = xyz.space;
	var answer = {};

	if ( evt.from_participant )
	{
		answer.to_participant = evt.from_participant;
	}
	if ( evt.subject == "surfaces_state" )
	{
		answer.action = "surfaces";
		answer.subaction = "get_state_of_surfaces";

		var surface_entities = space.getEntitiesOfType( ROOM_TYPES.SURFACE );
		answer.surfaces = [];
		for (var i = 0; i < surface_entities.length; i++)
		{
			var surface = surface_entities[i].surface;
			if ( !surface )
				continue;

			var surface_data = {
				root_uid: surface_entities[i].uid,
				app_name: surface.app_name
			};
			if ( surface._app && surface._app.stateToJSON )
			{
				/* surface_data.apps = {};
				surface_data.apps[ surface.app_name ] = {
					state: surface._app.stateToJSON()
				}; */
				surface_data.app_state = surface._app.stateToJSON();
			}
			answer.surfaces.push( surface_data );
		}
	}

	xyz.call_controller.broadcastMessage( answer );
});

// all surfaces
ActionListener.registerAction("surfaces", function( evt, xyz )
{
	var space = xyz.space;
	if ( evt.subaction == "get_state_of_surfaces" )
	{
		if ( evt.surfaces )
		{
			for ( var i = 0; i < evt.surfaces.length; i++ )
			{
				var surface_data = evt.surfaces[i];
				var surface_entity = space.getEntityById( surface_data.root_uid );
				var surface = surface_entity && surface_entity.surface ? surface_entity.surface : null;

				if ( !surface )
					continue;

				surface.apps = surface_data.apps;
				surface.app_name = surface_data.app_name;

				if ( !surface_data.app_state )
					continue;

				// applies the state if the app is already running
				if ( surface._app && surface._app.app_name == surface_data.app_name
					&& surface._app.JSONToState )
				{
					surface._app.JSONToState( surface_data.app_state );
				}
				else
				{
					// if the app is not ready yet, we temporarily store
					// the state in the surface before applying to the app
					surface.app_state = surface_data.app_state;
					surface.app_instanciation_callback = function() {
						if ( this._app && this._app.JSONToState )
						{
							this._app.JSONToState( this.app_state );
						}
						this.app_instanciation_callback = null;
						delete this.app_instanciation_callback;
					}
				}
			}

		}
	}
});

// Updates a specific surface or an array of surfaces
ActionListener.registerAction("surface_update", function( evt, xyz )
{
	var space = xyz.space;
	var entities = [];

	if ( evt.surface_entities )
		entities = evt.surface_entities;
	else if ( evt.surface_entity )
		entities[0] = evt.surface_entity;

	//entities in event
	for (var i = 0; i < entities.length; i ++)
	{
		var entity = space.getEntityById( entities[i] );
		if ( !entity || !entity.surface )
			continue;
		var surface = entity.surface;
		surface.processSurfaceAction(evt);
	}
});

window.ActionListener = ActionListener;

export {ActionListener}