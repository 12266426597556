import { quat, vec3 } from "gl-matrix";
export function rotationToDirectionVector(position, rotation, forward = vec3.fromValues(0, 0, 1)) {
    quat.normalize(rotation, rotation);
    // Rotate the forward vector by the quaternion
    const rotatedForward = vec3.create();
    vec3.transformQuat(rotatedForward, forward, rotation);
    // Compute the target by adding the rotated forward vector to the position
    const target = vec3.create();
    vec3.add(target, position, rotatedForward);
    return target;
}
