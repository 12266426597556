import { ROOM_SETTINGS } from "@src/engine/Room/ROOM_SETTINGS";
import { ROOM_TYPES } from "@src/engine/Room/ROOM_TYPES";
import { vec3ToArray } from "@src/gl-matrix/vec3";
import Button from "@src/libs/GLUI/Elements/Button";
import Label from "@src/libs/GLUI/Elements/Label";
import { LEvent } from "@src/libs/LEvent";
import { SceneNode } from "@src/libs/rendeer/SceneNode";
import { StaticMaterialsTable } from "@src/libs/rendeer/StaticMaterialsTable";
import { vec3 } from "gl-matrix";

//In charge of rendering a GLTF inside the scene
function Seat()
{
	this.enabled = true;
	this.fov_factor = 1.0;
	this.seat_type = Seat.SEAT_TYPES.PARTICIPANT;
	this.cubemap_folder = "";
	this.participant = null;
	this.enter_path = [];
	this.proxy_node = "";
	this.hide_ghost = false;
	this.walking_start = false;
	this.rotating_seat = false; //used from participant

	this.focus_node = new SceneNode();

	this._target_node = null;
}

Seat.componentName = "Seat";
Seat.icon = [ 7,0 ];
Seat.type = ROOM_TYPES.SEAT;

Seat.SEAT_TYPES = {
	PARTICIPANT: 0,
	HOST: 1,
	SPECTATOR: 2
};

Seat.SEAT_TYPES_STR = [ "PARTICIPANT","HOST","SPECTATOR" ];
Seat.gizmos_visible = true;
Seat.allow_chair_click = true;

Object.defineProperty( Seat.prototype, "spectator", {
	set: function(v) {
		this.seat_type = v ? Seat.SEAT_TYPES.SPECTATOR : Seat.SEAT_TYPES.PARTICIPANT;
	},

	get: function()
	{
		return this.seat_type == Seat.SEAT_TYPES.SPECTATOR;
	}
});

Object.defineProperty( Seat.prototype, "seat_type_str", {
	set: function(v) {
		throw ("seat_type_str cannot be set");
	},

	get: function()
	{
		return Seat.SEAT_TYPES_STR[ this.seat_type ];
	}
});



//called from entity.testRayInteraction
Seat.prototype.testRay = function(ray, participant)
{
	return this._body_node &&
		this._body_node.visible &&
		this._body_node.testRay( ray, null, null, 0xFF, true );
}

//returns the first surface associated to this seat
Seat.prototype.getAssociatedSurface = function()
{
	const name = this.name;
	const surfaces = this.space.entities.filter(a => a.surface && a.surface.seat == name);
	if (surfaces.length)
		return surfaces[0].surface;
	return null;
}

//called from entity.processClick
Seat.prototype.processClick = function(e)
{
	const space = this.space;
	//pass action to anyone interested
	LEvent.trigger( space, "seat_clicked", this );
}

Seat.prototype.onAdded = function(parent)
{
	this.focus_node.position = [ 0,1,0.25 ];
	parent.node.addChild( this.focus_node );

	parent.seat = this;
	//add body
	if (!this._body_node)
	{
		const body = this._body_node = new SceneNode();
		body.mesh = "meshes/bust.obj";
		body.material = "ghost";
		body.position = [ 0,0.85,0 ]; //height from node center
	}

	//autoassign type
	if ( parent.type === ROOM_TYPES.NULL )
		parent.type = ROOM_TYPES.SEAT;

	parent.node.addChild( this._body_node );
}

Seat.prototype.onRemoved = function(parent)
{
	parent.seat = null;
	//remove body
	parent.node.removeChild( this._body_node );
}

Seat.prototype.serialize = function(o)
{
	o.fov_factor = this.fov_factor;
	o.seat_type = this.seat_type;
	o.proxy_node = this.proxy_node;
	o.hide_ghost = this.hide_ghost;
	o.walking_start = this.walking_start;
	o.rotating_seat = this.rotating_seat;
	if (this.cubemap_folder)
		o.cubemap_folder = this.cubemap_folder;
	o.enter_path = [];
	for (let i = 0; i < this.enter_path.length; ++i)
		o.enter_path[i] = vec3ToArray( this.enter_path[i] );
}

Seat.prototype.configure = function(o)
{
	this.fov_factor = o.fov_factor || 1;
	if ( o.seat_type != null)
		this.seat_type = o.seat_type;
	else  //legacy
		this.seat_type = o.spectator ? Seat.SEAT_TYPES.SPECTATOR : Seat.SEAT_TYPES.PARTICIPANT;
	if (o.cubemap_folder)
		this.cubemap_folder = o.cubemap_folder;
	this.proxy_node = o.proxy_node;
	this.hide_ghost = o.hide_ghost;
	this.walking_start = o.walking_start;
	this.rotating_seat = o.rotating_seat || false;
	this.enter_path = [];
	if (o.enter_path)
		for (let i = 0; i < o.enter_path.length; ++i)
			this.enter_path[i] = vec3.clone( o.enter_path[i] );
}

Seat.prototype.getProxyNode = function()
{
	if ( this._target_node != null && this._target_node.name == this.proxy_node )
		return this._target_node;

	let node = this.space.scene.root.findNodeByName(this.proxy_node);

	if (node == null && window.nativeEngine)
	{
		// Let's search in native scene root
		node = window.nativeEngine._room.findNodeByName(this.proxy_node);
		this._target_node = node;
		/*var e = this.entity;
		if (!e.node.native_handle)
			e.node.native_handle = TmrwModule.createEntity("PROXY", this.proxy_node, "", e.node.name, -1);

		if (e.node.native_handle)
		{
			e.node.native_render = false;
			e.node.material = "SURFACE_MATERIAL";
			this._target_node = e.node;
		}*/
	}
	else
		this._target_node = node;
	return this._target_node;
}

Seat.prototype.getInteractiveNodes = function(container)
{
	if (this.participant)
		return;

	//if( this.space.local_participant && !this.space.local_participant.seat )
	if ( this._body_node.visible )
		container.push(this._body_node);
	if ( Seat.allow_chair_click && this.proxy_node && this.enabled && ROOM_SETTINGS.call.allow_seat_change )
	{
		const node = this.getProxyNode();
		if (node)
			container.push(node);
	}
}

Seat.prototype.getSeatId = function()
{
	return Number(this.entity.name.replace(/\D/g,""));
}

//called from participant.enterSeat
Seat.prototype.onParticipantEnter = function(participant)
{
	this.participant = participant;

	if (this.entity && this.entity._native) {
		this.entity._native.setInteractive(false);
	}
}

Seat.prototype.onParticipantLeaves = function(participant)
{
	if (participant != this.participant)
		console.warn("participant leaving is not the one seated here");
	this.participant = null;

	if (this.entity && this.entity._native) {
		this.entity._native.setInteractive(true);
	}
}

Seat.prototype.preRender = function(view)
{
	if (!this.enabled || this.hide_ghost)
	{
		this.setBodyNodeVisibility(false);
		return;
	}

	if (!StaticMaterialsTable["ghost"])
	{
		view.addMaterial( "ghost", Seat.ghost_material_info );
		view.addMaterial( "ghost_spectator", Seat.ghost_spectator_material_info );
		view.addMaterial( "ghost_host", Seat.ghost_host_material_info );
	}

	//this._body_node.visible = !Boolean( this.space.local_participant && this.space.local_participant.seat );
	let visibility = Seat.gizmos_visible;
	if (this.participant)
		visibility = false;
	if (visibility != this._body_node.visible)
		this.setBodyNodeVisibility(visibility);

	if (this.seat_type == Seat.SEAT_TYPES.SPECTATOR)
		this._body_node.material = "ghost_spectator";
	else if (this.seat_type == Seat.SEAT_TYPES.HOST)
		this._body_node.material = "ghost_host";
	else
		this._body_node.material = "ghost";

	if (this._body_node_native)
		this._body_node_native.material = this._body_node.material;
}

Seat.prototype.setBodyNodeVisibility = function (set)
{
	this._body_node.visible = set;

	if (this.entity && this.entity._native)
	{
		if (!this._body_node_native)
			this._body_node_native = this.entity._native.getBodyNode();

		if (this._body_node_native)
			this._body_node_native.visible = set;
	}
}

Seat.prototype.setStreamTexture = function (texture) {
}

Seat.roles = [ "Participant","Host","Spectator" ];

Seat.prototype.renderGizmo = function(view,editor,selected)
{
	if(!Seat.gizmo_points)
	{
		var data = Seat.gizmo_points = new Float32Array(90);
		for(var i = 0; i < 90; i+=3)
		{
			var f = i/90;
			var r = 0.3; if(i==0) r=0.4;
			data[i] = Math.sin(f*2*Math.PI) * r;
			data[i+2] = Math.cos(f*2*Math.PI) * r;
		}
	}

	const model = this.entity.node.getGlobalMatrix();

	const gl = GL.ctx;

	if(editor.use_depth_in_gizmos)
		gl.enable(gl.DEPTH_TEST);
	else
		gl.disable(gl.DEPTH_TEST);

	//draw path
	view.renderer.color = selected ? [ 1, 2, 3, 1 ] : [ .3, .5, 1, 0.7 ];

	view.renderer.renderPoints(
		Seat.gizmo_points,
		null,
		view._last_camera,
		Seat.gizmo_points.length / 3,
		null,
		0.01,
		gl.LINE_LOOP,
		null,
		model
	);

	gl.disable(gl.DEPTH_TEST);
}

Seat.prototype.onRenderInspector = function(ctx, x,y,w,h, editor )
{
	const component = this;
	const that = this;
	const line_height = 24;

	Label.call(GUI, x, y,140,20, "FOV Factor" );
	this.fov_factor = GUI.Slider( x+120,y,w-140,20, this.fov_factor, 0.5, 2, 0, 1 );
	y += line_height;

	Label.call(GUI, x, y,140,20, "Seat Type" );
	this.seat_type = GUI.ComboLine( x+120,y,w-140,20, this.seat_type, Seat.roles );
	y += line_height;

	Label.call(GUI, x, y,140,line_height, "Proxy" );
	GUI.next_tooltip = "Proxy node name";
	component.proxy_node = GUI.TextField( x + 70,y,w-140,line_height, component.proxy_node || "", null, true );
	GUI.next_tooltip = "Select node";
	if ( Button.call(GUI, x + w - line_height,y,line_height,line_height, [ 6,4 ] ) )
	{
		const tool = editor.selectTool("select");
		editor.enableSelectNodeTool(function(node) {
			if (node && node.name)
				that.proxy_node = node.name;
		});
	}
	y += line_height + 10;

	this.hide_ghost = GUI.Toggle( x, y, w, 24, "Hide Ghost", this.hide_ghost );
	y += 30;

	this.walking_start = GUI.Toggle( x, y, w, 24, "Walking Start", this.walking_start );
	y += 30;

	this.rotating_seat = GUI.Toggle( x, y, w, 24, "Rotating Seat", this.rotating_seat );
	y += 30;

	if ( RoomCall.instance.debug_panel )
	{
		if ( Button.call(GUI, x, y, w, 24, "Test Participant" ))
		{
			RoomCall.instance.debug_panel.addDemoParticipant( this.entity.name, true );
		}
		y += 30;

		if ( this.participant )
		{
			Label.call(GUI, x, y, w, 24, "Participant: " + this.participant.getUsername() );
			y += 30;
		}
	}

	return y;
}

Seat.ghost_material_info = {
	"name": "ghost_body",
	"alphaMode": "BLEND",
	"opacity": 1,
	"albedo": [ 0,0,0 ],
	"emissive": [ 0.1,0.2,0.4 ],
	"textures": {
		"opacity": {
			"texture": "gradient.png"
		}
	},
	"flags": {
		"two_sided": false,
		"depth_write": false,
		"preAlpha": true
	},
	"additive": false
};

Seat.ghost_spectator_material_info = {
	"name": "ghost_body_spectator",
	"alphaMode": "BLEND",
	"opacity": 1,
	"albedo": [ 0,0,0 ],
	"emissive": [ 0.6,0.3,0.2 ],
	"textures": {
		"opacity": {
			"texture": "gradient.png"
		}
	},
	"flags": {
		"two_sided": false,
		"depth_write": false,
		"preAlpha": true
	},
	"additive": false
};

Seat.ghost_host_material_info = {
	"name": "ghost_body_host",
	"alphaMode": "BLEND",
	"opacity": 1,
	"albedo": [ 0,0,0 ],
	"emissive": [ 0.2,0.6,0.3 ],
	"textures": {
		"opacity": {
			"texture": "gradient.png"
		}
	},
	"flags": {
		"two_sided": false,
		"depth_write": false,
		"preAlpha": true
	},
	"additive": false
};

export default Seat;

