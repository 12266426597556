class RootPath {
    static get() {
        return RootPath.value;
    }
    static set(value) {
        RootPath.value = value;
    }
}
RootPath.value = "/data";
export default RootPath;
