import { XYZModules } from "@src/xyz/XYZModules";

/**
 *
 * @param {*} module
 * @param {string} name
 */
export function XYZRegisterModule(module, name) {
	/* Igor: can I access XYZLauncher from here??
	if(XYZLauncher.modules_initialized)
	{
		console.error("Modules already initialized, cannot register any new module: ", name);
		return;
	}
	*/
	XYZModules[name || module.name] = module;
}